import { Bank, City, CodeLibelle, Profession, TypeContrat } from 'types';
import { IdentityState } from 'reducers/identity/types';
import {
  HousingStatus,
  MaritalStatus,
  SituationFoyerState,
} from 'reducers/situationFoyer/types';
import {
  SituationProfessionnelleState,
  SituationProfessionnelle,
} from 'reducers/situationProfessionnelle/types';
import { Amount, RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import {
  chargesRessourcesRes,
  clientFileResult,
  emprunteur,
  SituationProfessionnelleRes,
} from 'types/clientFileDtos';
import { sortChildrenByAge, zipcodeNumbertoString } from 'utils/commun';
import { checkIfDatasAreOk as checkIfFoyerDatasAreOK } from 'reducers/situationFoyer/situationFoyer';
import { checkIfDatasAreOk as checkIfIdentityDatasAreOK } from 'reducers/identity/utils';
import { checkIfDatasAreOk as checkIfProfessionalDatasAreOK } from 'reducers/situationProfessionnelle/situationProfessionnelle';
import { isSelfEmployedWorker } from 'containers/communs/utils';
import { formatDateToMonthYear } from '../DateUtils';

const isSameBirthName = (lastName: string, birthName?: string | undefined): boolean => {
  if (!birthName || birthName === '' || lastName === birthName) return true;
  return false;
};

const isCoBorrowerMaritalStatusIsSameAsBorrower = (
  maritalStatus: MaritalStatus | undefined,
  coBorrower?: emprunteur,
): boolean => {
  if (
    coBorrower?.etatCivil?.situationFamiliale &&
    maritalStatus === coBorrower?.etatCivil.situationFamiliale
  )
    return true;
  return false;
};

export const mappingEmprunteur = (
  emprunteurData: emprunteur,
  coBorrower: boolean,
  nationaliteList: CodeLibelle[],
  departementList: CodeLibelle[],
  countryList: CodeLibelle[],
  contexteArrivee: string,
  hasProposition: boolean,
): IdentityState => {
  const mapEmprunteur: IdentityState = {
    lastName: emprunteurData.etatCivil?.nom?.trim(),
    firstName: emprunteurData.etatCivil?.prenom?.trim(),
    birthName: emprunteurData.etatCivil?.nomNaissance?.trim(),
    isBirthNameSameAsName: isSameBirthName(
      emprunteurData.etatCivil?.nom,
      emprunteurData.etatCivil?.nomNaissance,
    ),
    civility: emprunteurData.etatCivil?.civilite === 'MME' ? 'MME' : 'M',
    birthDate: new Date(emprunteurData.etatCivil.dateNaissance).toLocaleDateString(),
    nationality: nationaliteList.find(
      item => item.code === emprunteurData.etatCivil?.nationalite,
    ),
    birthCountry: {
      code: emprunteurData.etatCivil?.paysNaissance,
      libelle:
        countryList.find(item => item.code === emprunteurData.etatCivil?.paysNaissance)
          ?.libelle || emprunteurData.etatCivil?.paysNaissance,
    },
    birthCity: { code: '', libelle: emprunteurData.etatCivil?.communeNaissance },
    birthDepartment: {
      code: emprunteurData.etatCivil?.departementNaissance,
      libelle:
        departementList.find(
          item => item.code === emprunteurData.etatCivil?.departementNaissance,
        )?.libelle || emprunteurData.etatCivil?.departementNaissance,
    },
    hasCoBorrower: false,
    isDataOk: contexteArrivee !== 'ASRC' && !hasProposition,
  };

  if (
    countryList.some(item => item.code === emprunteurData.etatCivil?.paysNaissance) &&
    emprunteurData.etatCivil?.paysNaissance !== 'FR'
  ) {
    mapEmprunteur.birthDepartment = { code: '99', libelle: '' };
  }

  mapEmprunteur.isDataOk = checkIfIdentityDatasAreOK(mapEmprunteur);

  if (coBorrower) {
    mapEmprunteur.hasCoBorrower = true;
    sessionStorage.setItem('coBorrowerExists', 'true');
  } else sessionStorage.setItem('coBorrowerExists', 'false');

  return mapEmprunteur;
};

export const mappingSituationFoyer = (
  clientFile: clientFileResult,
  cityByZipCode: CodeLibelle[],
): SituationFoyerState => {
  const situationFoyer: SituationFoyerState = {
    addressZipCode: zipcodeNumbertoString(clientFile?.foyer?.codePostal),
    childrenInCharge: clientFile?.foyer?.nombreEnfants > 0,
    coBorrowerMaritalStatusIsSameAsBorrower: false,
    isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
    addressCity: cityByZipCode.find(cityFromList => {
      return (
        clientFile?.foyer?.ville?.toLocaleLowerCase() ===
        cityFromList?.libelle?.toLocaleLowerCase()
      );
    }) || { code: '', libelle: clientFile?.foyer?.ville?.toLocaleLowerCase() },
    maritalStatus: clientFile?.emprunteur?.etatCivil?.situationFamiliale as MaritalStatus,
    housingStatus: clientFile?.foyer?.logement as HousingStatus,
    startingYearLivingInCurrentHouse: new Date(
      clientFile?.foyer?.dateEntree,
    )?.getFullYear(),
    numberOfPersonInCurrentHouse: clientFile?.foyer?.nombrePersonneFoyer,
    childrenInChargeBirthYear: sortChildrenByAge(
      clientFile?.foyer?.datesNaissancesEnfants?.map((dateNaissance: Date, index) => {
        return {
          childId: index,
          birthYear: new Date(dateNaissance).getFullYear().toString(),
        };
      }),
    ),
  };
  situationFoyer.isDataOk = checkIfFoyerDatasAreOK(situationFoyer);

  if (clientFile.coEmprunteur) {
    situationFoyer.hasCoBorrower = true;
    situationFoyer.coBorrowerMaritalStatusIsSameAsBorrower =
      isCoBorrowerMaritalStatusIsSameAsBorrower(
        situationFoyer.maritalStatus,
        clientFile.coEmprunteur,
      );
    if (
      !situationFoyer.coBorrowerMaritalStatusIsSameAsBorrower &&
      clientFile?.coEmprunteur?.etatCivil?.situationFamiliale
    ) {
      situationFoyer.coBorrowerMaritalStatus = clientFile?.coEmprunteur?.etatCivil
        ?.situationFamiliale as MaritalStatus;
    } else {
      situationFoyer.coBorrowerMaritalStatus = situationFoyer.maritalStatus;
    }
  }

  return situationFoyer;
};

export const mappingSituationProfessionnelle = (
  clientFile: clientFileResult,
  jobs: Profession[],
  jobContracts: TypeContrat[],
  countryList: CodeLibelle[],
): SituationProfessionnelleState => {
  function setSituationPro(situationProRes: SituationProfessionnelleRes) {
    const situationPro: SituationProfessionnelle = {};
    situationPro.profession = jobs.find(
      item => item.code === situationProRes.categorieSocioProfessionnelle,
    );
    if (situationProRes.typeContrat) {
      situationPro.typeContrat = jobContracts.find(
        item => item.code === situationProRes.typeContrat?.toString(),
      );
    }
    if (situationProRes.dateEntree) {
      situationPro.currentJobOrSituationStartingDate = formatDateToMonthYear(
        situationProRes.dateEntree,
      );
    }
    if (situationProRes.numeroSiren) {
      situationPro.sirenNumber = situationProRes.numeroSiren.toString();
    }
    if (situationProRes.codeApe) situationPro.apeNumber = situationProRes.codeApe;
    if (situationProRes.paysEmployeur) {
      situationPro.employerCountry = countryList.find(
        item => item.code === situationProRes.paysEmployeur,
      );
    }
    if (isSelfEmployedWorker(situationPro.profession)) {
      situationPro.employerCountry = { code: 'FR', libelle: 'FRANCE' };
    }
    if (situationProRes.adresse?.codePostal) {
      situationPro.adresse = {
        codePostal: zipcodeNumbertoString(situationProRes.adresse?.codePostal),
        city: {
          code: zipcodeNumbertoString(situationProRes.adresse?.codePostal),
          libelle: situationProRes.adresse.ville,
        } as City,
      };
    }

    return situationPro;
  }

  const situationProfessionnelle: SituationProfessionnelleState = {
    borrower: setSituationPro(clientFile.emprunteur.situationProfessionnelle),
    hasCoBorrower: false,
    coBorrower: {},
    isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
  };

  if (clientFile.coEmprunteur?.situationProfessionnelle) {
    situationProfessionnelle.hasCoBorrower = true;
    situationProfessionnelle.coBorrower = setSituationPro(
      clientFile.coEmprunteur.situationProfessionnelle,
    );
  }

  situationProfessionnelle.isDataOk = checkIfProfessionalDatasAreOK(
    situationProfessionnelle,
  );

  return situationProfessionnelle;
};

export const mappingRevenueEtCharge = (
  clientFile: clientFileResult,
  banks: CodeLibelle[],
  ressources: CodeLibelle[],
  charges: CodeLibelle[],
  departments: CodeLibelle[],
): RessourcesMensuellesState => {
  const ressourcesMensuelles: RessourcesMensuellesState = {
    borrowerIncome: {},
    coBorrowerIncome: {},
    otherIncomes: [],
    monthlyCharges: [],
    banks: [],
    invalidInputs: [],
    isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
    hasCoBorrower: false,
    hasOtherIncomes: false,
    hasMonthlyCharges: false,
  };

  const ressourcesMensuellesData: chargesRessourcesRes = clientFile.chargesRessources;

  if (ressourcesMensuellesData.salaireNetEmprunteur) {
    ressourcesMensuelles.borrowerIncome = {
      monthlyIncomes: ressourcesMensuellesData.salaireNetEmprunteur?.toString(),
    };
  } else if (
    clientFile.emprunteur.situationProfessionnelle.categorieSocioProfessionnelle === 'IRE'
  ) {
    ressourcesMensuelles.borrowerIncome.monthlyIncomes = '0';
  }

  ressourcesMensuelles.borrowerIncome.reliableIncome =
    ressourcesMensuellesData.salaireFiabiliseEmprunteur?.toString();

  const retraiteIncome = ressourcesMensuellesData.autresRessources?.find(item => {
    return item.type === 'RE' && item.titulaire === 1;
  });

  if (retraiteIncome?.montant)
    ressourcesMensuelles.borrowerIncome.retirementMonthlyIncomes =
      retraiteIncome.montant.toString();

  clientFile.donneesBancaires.forEach((data, index) => {
    const goodBank = banks.find(item => item.libelle === data.banque) || {
      code: '0',
      libelle: 'Autre banque',
    };
    const banqueInfo: Bank = {
      code: goodBank.code,
      libelle: goodBank.libelle,
      year: new Date(data.dateOuverture).getFullYear().toString(),
      id: index.toString(),
    };

    ressourcesMensuelles.banks.push(banqueInfo);
  });

  const autreRessourceWithoutRetraitre = ressourcesMensuellesData.autresRessources.filter(
    item => {
      return !(item.type === 'RE' && item.titulaire !== 3);
    },
  );
  if (autreRessourceWithoutRetraitre.length > 0) {
    ressourcesMensuelles.hasOtherIncomes = true;
    autreRessourceWithoutRetraitre
      .filter(item => item.montant !== null)
      .forEach((ressource, index) => {
        const goodRessource = ressources.find(item => item.code === ressource.type) || {
          code: '',
          libelle: 'Non renseigné',
        }; // TODO gestion d'erreur

        const amount: Amount = {
          value: ressource.montant?.toString(),
          code: goodRessource.code,
          libelle: goodRessource.libelle,
          id: index,
        };

        ressourcesMensuelles.otherIncomes.push(amount);
      });
  }

  if (ressourcesMensuellesData.autresCharges.length > 0) {
    ressourcesMensuelles.hasMonthlyCharges = true;
    ressourcesMensuellesData.autresCharges
      .filter(item => item.montant !== null)
      .forEach((charge, index) => {
        const goodCharge = charges.find(item => item.code === charge.type) || {
          code: '',
          libelle: 'Non renseigné',
        }; // TODO gestion d'erreur

        const amount: Amount = {
          value: charge.montant.toString(),
          code: goodCharge.code,
          libelle: goodCharge.libelle,
          id: index,
        };

        ressourcesMensuelles.monthlyCharges.push(amount);
      });
  }

  if (clientFile.coEmprunteur) {
    ressourcesMensuelles.hasCoBorrower = true;
    if (ressourcesMensuellesData.salaireNetCoEmprunteur) {
      ressourcesMensuelles.coBorrowerIncome = {
        monthlyIncomes: ressourcesMensuellesData.salaireNetCoEmprunteur?.toString(),
      };
    } else if (
      clientFile.coEmprunteur.situationProfessionnelle.categorieSocioProfessionnelle ===
      'IRE'
    ) {
      ressourcesMensuelles.coBorrowerIncome.monthlyIncomes = '0';
    }

    ressourcesMensuelles.coBorrowerIncome.reliableIncome =
      ressourcesMensuellesData.salaireFiabiliseCoEmprunteur?.toString();

    const retraiteIncomeCo = ressourcesMensuellesData.autresRessources?.find(item => {
      return item.type === 'RE' && item.titulaire === 2;
    });

    if (retraiteIncomeCo?.montant)
      ressourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes =
        retraiteIncomeCo.montant.toString();
  }

  return ressourcesMensuelles;
};
