import styled from 'styled-components';

export const StyledDowloadCardWrapper = styled.div`
  box-sizing: border-box;
  width: 20.9rem;
  border: 1px solid #6f757c;
  border-radius: 8px;
  background-color: white;
`;

export const StyledDowloadCardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  align-items: center;
`;

export const StyledDowloadCardElement = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  align-items: center;
`;

export const StyledSpanDivider = styled.span`
  border: 1px solid #6f757c80;
  display: flex;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
`;

export default StyledDowloadCardWrapper;
