// import { GetSimulationResultApiResponse } from 'api/simulationService';
import {
  ConditionsFinancieresState,
  Insurance,
} from 'reducers/conditionsFinancieres/types';
import { LoanInformation } from 'types';
import { assurance, clientFileResult, clientFilesResult } from 'types/clientFileDtos';
import { ASSURANCE_LIST } from 'utils/constante';

const mappingAssurance = (assuranceData: assurance): Insurance => {
  const assuranceRep: Insurance = {
    insurance:
      assuranceData?.typeAssuranceChoisie === 'G'
        ? { code: 'GP', libelle: 'Groupe conseillée' }
        : ASSURANCE_LIST.find(
            item => item.code === assuranceData?.typeAssuranceChoisie,
          ) || undefined,
    deathInsuranceRate: assuranceData?.quotiteDeces,
    deathInsuranceChecked: assuranceData?.garantieDeces === 'O',
    jobLossInsuranceChecked: assuranceData?.garantiePerteEmploi === 'O',
    insuranceFranchiseDuration: Number(assuranceData?.delaiFranchise),
    coverageRate: assuranceData?.quotiteDeces,
    monthlyEstimatedBonus: assuranceData?.echeanceAssuranceDeleguee,
    ptiaInsuranceChecked: assuranceData?.garantiePTIA === 'O',
    ittIptInsuranceChecked: assuranceData?.garantieITT === 'O',
  };

  return assuranceRep;
};

export const mappingConditionFinanciere = (
  clientFile: clientFileResult,
): ConditionsFinancieresState => {
  const ConditionsFinancieres: ConditionsFinancieresState = {
    loanDuration: clientFile.proposition.dureeTotalPret,
    franchiseDuration: clientFile.proposition.dureeFranchise,
    hasLoanOption: false,
    nominalInterestRate: clientFile.proposition.tauxNominal.toString(),
    intermediationFeesRate:
      clientFile.proposition.pourcentageFraisIntermedation.toString(),
    borrowerInsurance: mappingAssurance(clientFile.proposition.assuranceEmprunteur),
    coBorrowerInsurance: mappingAssurance(clientFile.proposition.assuranceCoEmprunteur),
    isDataOk: clientFile.contexteArrivee !== 'ASRC' && !clientFile.proposition,
    invalidInputs: [],
  };

  return ConditionsFinancieres;
};

export const mappingResultatSimulation = (ClientFile: clientFileResult): any => {
  const objectFromSession = sessionStorage.getItem('projetDetail');
  let projetDetailFromSession;
  if (objectFromSession) {
    projetDetailFromSession = JSON.parse(objectFromSession) as clientFilesResult;
  }
  const resultatSimulation = {
    Success: true,
    ListeMessages: [],
    Resultat: {
      statut: 'OK',
      profil: ClientFile.profil,
      identifiantEmprunteur: ClientFile.identifiantEmprunteur,
      identifiantCoEmprunteur: ClientFile.identifiantCoEmprunteur,
      identifiantPropositionCommerciale:
        projetDetailFromSession?.idPropCial ||
        ClientFile.identifiantPropositionCommerciale,
      segmentation: ClientFile.proposition.segmentation,
      listeAlertes: ClientFile.listeAlertes,
      Proposition: ClientFile.proposition,
    },
  };

  return resultatSimulation;
};

export const mappingLoanInformationsByIntermediationFee = (
  clientFile: clientFileResult,
): LoanInformation => {
  const loanInformationsByInterFee = {
    montantTotalPret: clientFile.proposition.montantTotalPret.toString(),
    libelleSegmentation: clientFile.proposition.libelleSegmentation,
    fraisComOuverture: clientFile.proposition.fraisComOuverture.toString(),
    pourcentageFraisIntermediation:
      clientFile.proposition.pourcentageFraisIntermedation.toString(),
    fraisIntermediation: clientFile.proposition.fraisIntermedation.toString(),
    fraisActe: clientFile.proposition.fraisActe.toString(),
  } as LoanInformation;

  return loanInformationsByInterFee;
};
