import CustomInput from 'components/customInput/CustomInput';
import { addInInvalidInputs, removeFromInvalidInputs } from 'containers/communs/utils';
import { useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import checkPreviousInputValidation from 'utils/commun';

interface QuotiteProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
}

const Quotite: React.FC<QuotiteProps> = ({ state, dispatch, person }) => {
  const [isCoverageRateInputFocused, setIsCoverageRateInputFocused] =
    useState<boolean>(false);
  const [isCoverageRateInputValid, setIsCoverageRateInputValid] = useState<
    boolean | undefined
  >(
    checkPreviousInputValidation(
      'conditionsFinancieres',
      person === 'borrower'
        ? state.borrowerInsurance.coverageRate
        : state.coBorrowerInsurance.coverageRate,
    ),
  );
  const [coverageRateInputError, setCoverageRateInputError] = useState<string>('');

  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const getQuotiteValue = () => {
    if (person === 'borrower') {
      if (state.borrowerInsurance.coverageRate !== undefined) {
        return state.borrowerInsurance.coverageRate;
      }
      return '';
    }
    if (state.coBorrowerInsurance.coverageRate !== undefined) {
      return state.coBorrowerInsurance.coverageRate;
    }
    return '';
  };

  const handleCoverageRateChange = (value: string) => {
    const rate = parseInt(value, 10);
    if (Number.isNaN(rate)) {
      dispatch({
        type: 'setCoverageRate',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setCoverageRate',
        payload: { person, value: rate },
      });
    }
  };
  const handleCoverageRateBlur = () => {
    setIsCoverageRateInputFocused(false);
    const coverageRate =
      person === 'borrower'
        ? state.borrowerInsurance.coverageRate
        : state.coBorrowerInsurance.coverageRate;
    if (!notEmpty.test(coverageRate?.toString() || '')) {
      setIsCoverageRateInputValid(false);
      setCoverageRateInputError(messagesCheckInput.REQUIRED_VALUE);
      addInInvalidInputs(state, dispatch, 'quotite');
    } else if (Number.isNaN(coverageRate)) {
      setIsCoverageRateInputValid(false);
      setCoverageRateInputError(messagesCheckInput.INPUT_ERROR);
      addInInvalidInputs(state, dispatch, 'quotite');
    } else {
      setIsCoverageRateInputValid(true);
      setCoverageRateInputError('');
      removeFromInvalidInputs(state, dispatch, 'quotite');
    }
  };
  return (
    <CustomInput
      name="coverage-rate"
      label="Quotité couverture"
      value={getQuotiteValue()}
      onChange={newValue => {
        handleCoverageRateChange(newValue);
        setIsCoverageRateInputFocused(true);
      }}
      isValid={isCoverageRateInputValid}
      isFocused={isCoverageRateInputFocused}
      onBlur={handleCoverageRateBlur}
      onFocus={() => {
        setIsCoverageRateInputFocused(true);
      }}
      after="%"
      inputWidth="16.4rem"
      placeholder="0"
      className="bareme-input"
      error={coverageRateInputError}
    />
  );
};

export default Quotite;
