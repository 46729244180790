import CustomCheckButton from 'components/buttons/customCheckButton/CustomCheckButton';
import CustomInput from 'components/customInput/CustomInput';
import { CustomValidity } from 'components/designSystem/DesignSystemTypes';
import { useEffect, useState } from 'react';
import { RessourcesMensuellesAction } from 'reducers/ressourcesMensuelles/types';
import checkPreviousInputValidation from 'utils/commun';
import { checkValidity } from 'utils/InputValidation';
import { getMonthlyIncomesRegExpPatterns } from 'validation/donneesClient/ressourcesMensuellesValidation';

export type Person = 'borrower' | 'coBorrower';
interface MonthlyIncomesProps {
  income: string;
  dispatch: React.Dispatch<RessourcesMensuellesAction>;
  person: 'borrower' | 'coBorrower';
  incomeType?: 'monthlyIncomes' | 'retirementIncomes';
  showAucunBtn?: boolean;
  tooltipText?: string;
  label?: string;
  checkInputs: boolean;
  onCustomCheckboxButtonClick?: () => void;
}
const MonthlyIncomes: React.FC<MonthlyIncomesProps> = ({
  income,
  incomeType,
  dispatch,
  showAucunBtn,
  tooltipText,
  label,
  checkInputs,
  onCustomCheckboxButtonClick,
  person,
}) => {
  const aucunBtnCheckedInitialState = () => {
    if (showAucunBtn && income === '0') {
      return true;
    }
    return false;
  };

  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>(
    checkPreviousInputValidation('ressourcesMensuelles', income),
  );
  const [inputError, setInputError] = useState<string>('');
  const [isAucunBtnChecked, setIsAucunBtnChecked] = useState<boolean>(
    aucunBtnCheckedInitialState,
  );
  const [validityRules] = useState<CustomValidity[]>(
    getMonthlyIncomesRegExpPatterns(showAucunBtn || false),
  );

  const handleOnCheckClick = () => {
    if (incomeType === 'monthlyIncomes') {
      dispatch({
        type: 'setMonthlyIncomes',
        payload: { person, value: '0' },
      });
    }
    if (incomeType === 'retirementIncomes') {
      dispatch({
        type: 'setRetirementMonthlyIncomes',
        payload: { person, value: '0' },
      });
    }
    setIsAucunBtnChecked(true);
    setIsInputValid(true);
    setInputError('');
    if (onCustomCheckboxButtonClick) onCustomCheckboxButtonClick();
  };

  const handleOnChange = (value: string) => {
    setIsInputValid(undefined);
    setIsAucunBtnChecked(false);
    setIsInputFocused(true);

    if (incomeType === 'monthlyIncomes') {
      dispatch({
        type: 'setMonthlyIncomes',
        payload: { person, value },
      });
    }
    if (incomeType === 'retirementIncomes') {
      dispatch({
        type: 'setRetirementMonthlyIncomes',
        payload: { person, value },
      });
    }
  };

  const handleOnBlur = (value: string) => {
    setIsInputFocused(false);

    const errorMessage = checkValidity(value.replaceAll(' ', ''), validityRules);

    if (!errorMessage) {
      setIsInputValid(true);
      setInputError('');
    } else {
      setIsInputValid(false);
      setInputError(errorMessage);
    }
  };

  useEffect(() => {
    if (checkInputs && !isAucunBtnChecked) {
      handleOnBlur(income);
    }
  }, [checkInputs]);

  useEffect(() => {
    if (income && income !== '') {
      handleOnBlur(income);
    }
  }, []);

  return (
    <CustomInput
      name="revenus-mensuels"
      label={label}
      type="price"
      value={income}
      onChange={newValue => {
        handleOnChange(newValue);
      }}
      isValid={isInputValid}
      isFocused={isInputFocused}
      onBlur={value => {
        handleOnBlur(value);
      }}
      onFocus={() => {
        setIsInputFocused(true);
      }}
      after="€ / mois"
      inputWidth={showAucunBtn ? '37.7rem' : '46.4rem'}
      error={inputError}
      tooltip={tooltipText}
      maxLength={8}>
      {showAucunBtn && (
        <CustomCheckButton onClick={handleOnCheckClick} isChecked={isAucunBtnChecked}>
          Aucun revenu
        </CustomCheckButton>
      )}
    </CustomInput>
  );
};

export default MonthlyIncomes;
