import { getReferential } from 'api/referentialService';
import CustomInput from 'components/customInput/CustomInput';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import InformationArea from 'components/informationArea/InformationArea';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import ToggleButton from 'components/toggleButton/ToggleButton';
import { ToggleText } from 'containers/communs/style';
import WarningIcon from 'icons/WarningIcon';
import { useEffect, useState } from 'react';
import { CodeLibelle } from 'types';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../messages';
import { InformationreaText } from '../style';

interface IncidentsImpayesProps {
  onIncidentsOrImpayesToggleChange: (type: 'incident' | 'impaye', value: boolean) => void;
  onIncidentChecked: (typeIncident: CodeLibelle, value: boolean) => void;
  incidentPayment?: CodeLibelle[];
  hasIncident?: boolean;
  hasImpaye?: boolean;
  nbMonthImpayes?: string;
  onNbMonthImpayesChange: (value: number) => void;
  checkInputs: boolean;
}
const IncidentsImpayes: React.FC<IncidentsImpayesProps> = ({
  onIncidentsOrImpayesToggleChange,
  onIncidentChecked,
  incidentPayment,
  hasIncident,
  hasImpaye,
  nbMonthImpayes,
  onNbMonthImpayesChange,
  checkInputs,
}) => {
  const [nbMonthImpayesInput, setNbMonthImpayesInput] = useState<string>(
    nbMonthImpayes || '',
  );
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');
  const [incidentPaymentType, setIncidentPaymentType] = useState<CodeLibelle[]>([]);

  const numberReg = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const handleOnChange = (value: string) => {
    if (!numberReg.test(value)) return;

    setNbMonthImpayesInput(Number(value).toString());
    onNbMonthImpayesChange(parseInt(value, 10));
  };

  const handleOnBlur = (value: string) => {
    setIsInputFocused(false);
    if (!notEmpty.test(value)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.REQUIRED_VALUE);
    } else if (numberReg.test(value)) {
      if (Number(value) > 3) {
        setIsInputValid(false);
        setInputError(messagesCheckInput.MONTH_IMPAYES_MAX);
      } else if (Number(value) <= 0) {
        setIsInputValid(false);
        setInputError(messagesCheckInput.NON_ZERO_NUMBERS);
      } else {
        setIsInputValid(true);
        setInputError('');
      }
    } else {
      setIsInputValid(false);
      setInputError(messagesCheckInput.INPUT_ERROR);
    }
  };

  useEffect(() => {
    getReferential('difficultePaiement').then(resultat => {
      const result: CodeLibelle[] = [];

      if (resultat.Resultat) {
        resultat.Resultat.forEach(item => {
          result.push({ code: item.code, libelle: item.libelle });
          setIncidentPaymentType(result);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (checkInputs === true) {
      handleOnBlur(nbMonthImpayesInput);
    }
  }, [checkInputs]);

  const isIncidentChecked = (itemCode: string) => {
    if (incidentPayment) {
      const item = incidentPayment.find(element => element.code === itemCode);
      if (item) {
        return true;
      }
      return false;
    }
    return false;
  };

  const renderPaymentIncidents = (): React.ReactFragment => {
    return (
      <>
        <Space marginBottom="1.6rem" />
        {(!hasIncident || incidentPayment?.length === 0) && checkInputs ? (
          <>
            <ErrorMessageArea message={messagesCheckInput.PAYEMENT_INCIDENT_REQUIRED} />
            <Space marginTop="3.2rem" />
          </>
        ) : (
          <></>
        )}
        <InformationArea icon={<WarningIcon color="#2275D7" />} backgroundColor="blue">
          <InformationreaText>
            Veuillez renseigner les incidents de paiement non régularisés uniquement.
          </InformationreaText>
        </InformationArea>
        <Space marginBottom="3.2rem" />
        {incidentPaymentType &&
          incidentPaymentType.map((item, index) => {
            return (
              <div key={item.code}>
                <BaseCheckbox
                  checked={isIncidentChecked(item.code) ? true : null}
                  onCheckedChanged={e => {
                    const checkValue = (e as CustomEvent)?.detail?.value;
                    onIncidentChecked(item, checkValue);
                  }}>
                  <span>{item.libelle}</span>
                </BaseCheckbox>
                <Space marginBottom="2.4rem" />
              </div>
            );
          })}
      </>
    );
  };

  const renderPaymentDefault = (): React.ReactFragment => {
    return (
      <>
        <Space marginBottom="1.6rem" />
        <InformationArea icon={<WarningIcon color="#2275D7" />} backgroundColor="blue">
          <InformationreaText>
            Tous les types de crédits sont concernés : Immobilier, Consommation et
            Renouvelable.
          </InformationreaText>
        </InformationArea>

        <Space marginBottom="3.2rem" />

        <CustomInput
          name="nb-mois-impayes"
          label="Nombre total de mois avec impayés"
          value={nbMonthImpayesInput}
          onChange={newValue => {
            handleOnChange(newValue.slice(0, 2));
            setIsInputFocused(true);
          }}
          isValid={isInputValid}
          isFocused={isInputFocused}
          onBlur={value => {
            handleOnBlur(value);
          }}
          onFocus={() => {
            setIsInputFocused(true);
          }}
          after="mois"
          inputWidth="46.4rem"
          error={inputError}
        />
      </>
    );
  };

  return (
    <>
      <StepTitles
        title={messages.INCIDENTS_IMPAYES_TITLE}
        subTitle={messages.INCIDENTS_IMPAYES_SUBTITLE}
      />
      <ToggleButton
        id="incidents"
        isOn={hasIncident || false}
        handleToggle={() => {
          onIncidentsOrImpayesToggleChange('incident', !hasIncident);
        }}>
        <ToggleText>{`Présence d'incidents de paiement non régularisés`}</ToggleText>
      </ToggleButton>

      {hasIncident ? renderPaymentIncidents() : <></>}

      <Space marginBottom="5.6rem" />

      <ToggleButton
        width="71.2rem"
        id="impayes"
        isOn={hasImpaye || false}
        handleToggle={() => {
          onIncidentsOrImpayesToggleChange('impaye', !hasImpaye);
        }}>
        <ToggleText>{`Présence d'impayés sur les mensualités de crédits non régularisés`}</ToggleText>
      </ToggleButton>

      {hasImpaye ? renderPaymentDefault() : <></>}
    </>
  );
};

export default IncidentsImpayes;
