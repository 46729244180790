import React from 'react';
import parse from 'html-react-parser';
import { CalcPropositionDto } from 'types/FaisabiliteDTO';
import Space from 'components/space/Space';
import EditIcon from 'icons/EditIcon';
import WarningIcon from 'icons/WarningIcon';
import FormFooter from 'components/formFooter/FormFooter';
import { formatNumberByThousand } from 'utils/commun';
import InformationArea from 'components/informationArea/InformationArea';
import * as messages from './messages';
import {
  StyledSubTitle,
  StyledLabelValue,
  SummaryWrapper,
  StyledHorizontalWrapper,
  StyledDottedLine,
  Line,
} from './style';
import FinancingResult from './resultSteps/FinancingResult';
import AssuranceGroupe from './resultSteps/AssuranceGroupe';
import AssuranceIndividuelle from './resultSteps/AssuranceIndividuelle';

interface CalculetteResultProps {
  simulationResult: CalcPropositionDto;
  setDisplayForm: (boolean) => void;
}

const CalculetteResult: React.FC<CalculetteResultProps> = ({
  simulationResult,
  setDisplayForm,
}) => {
  const renderValueEuro = (value?: number) => {
    if (value !== null && value !== undefined) {
      return `${formatNumberByThousand(value.toFixed(2).toString())} €`;
    }
    return '';
  };

  const renderValueMonth = (value?: number) => {
    if (value !== null && value !== undefined) {
      return `${value.toString()} mois`;
    }
    return '';
  };

  const renderValuePercent = (value?: number) => {
    if (value !== null && value !== undefined) {
      if (value !== 100) {
        return `${value.toFixed(2).toString()} %`;
      }
      return `${value.toString()} %`;
    }
    return '';
  };

  const renderValueEuroByMonth = (value?: number) => {
    if (value !== null && value !== undefined) {
      return `${value.toFixed(2).toString()} € / mois`;
    }
    return '';
  };

  const montantTotalPret =
    simulationResult.montantTotalPret -
    simulationResult.fraisIntermedation -
    simulationResult.fraisComOuverture -
    simulationResult.fraisActe;

  window.scrollTo(0, 0);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StyledSubTitle>{messages.CALCULATOR_RESULT.subtitle}</StyledSubTitle>
        <SummaryWrapper>
          <StyledHorizontalWrapper>
            <StyledLabelValue>{messages.CALCULATOR_RESULT.clientNeeds}</StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValueEuro(montantTotalPret)}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
          <StyledHorizontalWrapper>
            <StyledLabelValue>{messages.CALCULATOR_fileFee}</StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValueEuro(simulationResult.fraisComOuverture)}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
          <StyledHorizontalWrapper>
            <StyledLabelValue>{messages.CALCULATOR_indermediation}</StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValuePercent(simulationResult.pourcentageFraisIntermedation)} -{' '}
              {renderValueEuro(simulationResult.fraisIntermedation)}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
          <StyledHorizontalWrapper>
            <StyledLabelValue>{messages.CALCULATOR_RESULT.warrantyFee}</StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValueEuro(simulationResult.fraisActe)}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
          <Line />
          <StyledHorizontalWrapper>
            <StyledLabelValue>{messages.CALCULATOR_RESULT.montantTotal}</StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValueEuro(simulationResult.montantTotalPret)}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
        </SummaryWrapper>
        <FinancingResult
          simulationResult={simulationResult}
          renderValuePercent={renderValuePercent}
          renderValueEuro={renderValueEuro}
          renderValueMonth={renderValueMonth}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '19.2rem' }}>
        <Space marginTop="7rem" />
        <InformationArea
          icon={<WarningIcon color="#2275D7" />}
          backgroundColor="blue"
          width="70rem"
          height="21.2rem">
          <p style={{ margin: '0', fontSize: '1.4rem' }}>
            {parse(messages.CALCULATOR_RESULT.infoSimulation)}
          </p>
        </InformationArea>

        {(simulationResult.assuranceEmprunteur.typeAssuranceChoisie === 'G' ||
          simulationResult.assuranceCoEmprunteur.typeAssuranceChoisie === 'G') && (
          <AssuranceGroupe
            simulationResult={simulationResult}
            renderValuePercent={renderValuePercent}
            renderValueEuro={renderValueEuro}
            renderValueEuroByMonth={renderValueEuroByMonth}
          />
        )}
        {(simulationResult.assuranceEmprunteur.typeAssuranceChoisie === 'D' ||
          simulationResult.assuranceCoEmprunteur.typeAssuranceChoisie === 'D') && (
          <AssuranceIndividuelle
            simulationResult={simulationResult}
            renderValuePercent={renderValuePercent}
            renderValueEuroByMonth={renderValueEuroByMonth}
          />
        )}
        <Space marginTop="1rem" />
        <p>{parse(messages.CALCULATOR_RESULT.infoSimulationEndPage)}</p>
        <FormFooter
          marginTop="4.8rem"
          onNextButtonClick={() => {
            setDisplayForm(true);
          }}
          nextButtonLabel="Modifier la simulation"
          nextButtonIcon={<EditIcon color="#fff" />}
          hideSaveBtn
          showPreviousButton={false}
        />
      </div>
    </div>
  );
};

export default CalculetteResult;
