import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';

interface ITTInsuranceProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
  isSG: boolean;
  isDisabled?: boolean;
}

const ITTInsurance: React.FC<ITTInsuranceProps> = ({
  person,
  state,
  dispatch,
  isSG,
  isDisabled,
}) => {
  const isITTChecked = (): boolean | undefined => {
    if (person === 'borrower') {
      return state.borrowerInsurance.ittIptInsuranceChecked || undefined;
    }
    return state.coBorrowerInsurance.ittIptInsuranceChecked || undefined;
  };

  const getQuotite = () => {
    const value =
      person === 'borrower'
        ? state.borrowerInsurance.deathInsuranceRate
        : state.coBorrowerInsurance.deathInsuranceRate;
    if (value && isITTChecked()) {
      return `${value}%`;
    }
    return '0%';
  };

  const uncheckJobLoss = () => {
    dispatch({
      type: 'setJobLossInsuranceChecked',
      payload: { person, value: false },
    });
  };

  const handleOnCheckChange = (e: Event) => {
    if (isDisabled) {
      return;
    }
    if ((e as CustomEvent)?.detail?.value) {
      dispatch({
        type: 'setIttIptInsuranceChecked',
        payload: { person, value: true },
      });
      if (isSG) {
        dispatch({
          type: 'setPtiaInsuranceChecked',
          payload: { person, value: true },
        });
      }
    } else {
      dispatch({
        type: 'setIttIptInsuranceChecked',
        payload: { person, value: false },
      });
      if (isSG) {
        dispatch({
          type: 'setPtiaInsuranceChecked',
          payload: { person, value: false },
        });
      }
      uncheckJobLoss();
    }
  };

  return (
    <div style={{ marginTop: '4.5rem', display: 'flex', alignContent: 'center' }}>
      <BaseCheckbox
        disabled={isDisabled}
        checked={isITTChecked()}
        onCheckedChanged={e => {
          handleOnCheckChange(e);
        }}
      />
      <span
        style={{
          marginLeft: '0.8rem',
          color: isDisabled ? '#B1B5B9' : '#4B4F54',
          minWidth: '16.5rem',
        }}>
        {`Garantie ITT/IPT ${getQuotite()}`}
      </span>
    </div>
  );
};

export default ITTInsurance;
