import Space from 'components/space/Space';
import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import AccessWebsiteIcon from 'icons/AccessWebsiteIcon';
import BaseButton from 'components/designSystem/BaseButton';
import AuthorizationService from 'api/AuthorizationService';
import CirclePersonIcon from 'icons/CirclePersonIcon';
import {
  StyledAccessBox,
  StyledAccessBoxButtonLabel,
  StyledAccessBoxDescription,
  StyledAccessBoxMainTitle,
  StyledBackground,
  StyledConnectionPanel,
  StyledConnectionPanelButtonLabel,
  StyledConnectionPanelText,
  StyledHighlightBoxCornerCircles,
  StyledHighlightBoxText,
  StyledLinkedinNewsLabel,
  StyledMainHighlightBox,
  StyledMainSidePanel,
  StyledMainTitle,
  StyledMentionLegalesText,
  StyledSecondaryPanel,
} from './style';

import * as messages from './messages';

const Login: React.FC = () => {
  return (
    <>
      <StyledBackground>
        <StyledMainSidePanel>
          <div style={{ display: 'flex', justifyContent: 'center', flex: '1' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '78.4rem',
              }}>
              <img
                style={{ height: '7.4rem', width: '17.1rem', marginBottom: '4rem' }}
                src={`${process.env.PUBLIC_URL}/logo_sygma.jpg`}
                alt="logo"
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '1',
                  justifyContent: 'center',
                }}>
                <StyledMainTitle>{messages.MAIN_TITLE_WELCOME}</StyledMainTitle>
                <StyledMainHighlightBox>
                  <StyledHighlightBoxCornerCircles
                    style={{ top: '-4.3rem', left: '-5.8rem' }}
                  />
                  <StyledHighlightBoxText>
                    {messages.HIGHLIGHT_BOX_DESCRIPTION}
                  </StyledHighlightBoxText>

                  <Space marginBottom="2.4rem" />

                  <StyledHighlightBoxText>
                    {messages.HIGHLIGHT_BOX_USAGE}
                  </StyledHighlightBoxText>
                  <StyledHighlightBoxCornerCircles
                    style={{ bottom: '-5.2rem', right: '-4.3rem' }}
                  />
                </StyledMainHighlightBox>

                <StyledAccessBox>
                  <StyledAccessBoxMainTitle>
                    {messages.ACCESS_BOX_MAIN_TITLE}
                  </StyledAccessBoxMainTitle>
                  <StyledAccessBoxDescription>
                    {messages.ACCESS_BOX_DESCRIPTION}
                  </StyledAccessBoxDescription>

                  <StyledSecondaryButton
                    onClick={() => {
                      window.open('https://personal-finance.bnpparibas/courtage-sygma/');
                    }}
                    width="53.9rem">
                    <AccessWebsiteIcon color="#4B4F54" />
                    <StyledAccessBoxButtonLabel>
                      {messages.ACCESS_BOX_BUTTON_LABEL}
                    </StyledAccessBoxButtonLabel>
                  </StyledSecondaryButton>
                </StyledAccessBox>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <StyledMentionLegalesText
              role="button"
              tabIndex={0}
              onClick={() => {
                window.location.href =
                  'https://personal-finance.bnpparibas/mentions-legales/';
              }}
              onKeyDown={() => {
                window.location.href =
                  'https://personal-finance.bnpparibas/mentions-legales/';
              }}>
              {messages.LEGAL_MENTIONS_LABEL}
            </StyledMentionLegalesText>

            <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
              <StyledLinkedinNewsLabel>
                {messages.LINKEDIN_NEWS_LABEL}
              </StyledLinkedinNewsLabel>
              <div
                style={{ cursor: 'pointer' }}
                role="button"
                tabIndex={0}
                onClick={() => {
                  window.open(
                    'https://www.linkedin.com/showcase/sygma-bnp-paribas-personal-finance/',
                  );
                }}
                onKeyDown={() => {
                  window.open(
                    'https://www.linkedin.com/showcase/sygma-bnp-paribas-personal-finance/',
                  );
                }}>
                <img
                  src={`${process.env.PUBLIC_URL}/Linkedin-logo.svg`}
                  alt="linkedin-logo"
                />
              </div>
            </div>
          </div>
        </StyledMainSidePanel>
        <StyledSecondaryPanel>
          <StyledConnectionPanel>
            <img
              style={{ height: '4.5rem', width: '27.1rem', marginBottom: '2.4rem' }}
              src={`${process.env.PUBLIC_URL}/SygmaConnectLogo.png`}
              alt="logo"
            />
            <StyledConnectionPanelText>
              {messages.CONNECTION_PANEL_DESC}
            </StyledConnectionPanelText>
            <BaseButton
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '6.4rem',
                width: '38.8rem',
                backgroundColor: '#268038',
              }}
              onClick={() => AuthorizationService.authorize('/callback')}>
              <CirclePersonIcon color="#FFFFFF" />
              <StyledConnectionPanelButtonLabel style={{ marginLeft: '1rem' }}>
                {messages.CONNECTION_PANEL_BUTTON_LABEL}
              </StyledConnectionPanelButtonLabel>
            </BaseButton>
          </StyledConnectionPanel>
        </StyledSecondaryPanel>
      </StyledBackground>
    </>
  );
};

export default Login;
