import { SessionStorageKeys } from 'types';
import { isUndefinedOrNull } from 'utils/commun';
import { getExistingStorageByKey } from 'utils/storage';
import { InterlocutorAction, InterlocutorState } from './type';

export const getInterlocutorInitialState = (
  storageKey: SessionStorageKeys,
): InterlocutorState => {
  const interlocutorDefaultValues: InterlocutorState = {
    isMIOB: undefined,
    miobName: '',
    isDataOk: false,
  };
  return getExistingStorageByKey(
    storageKey,
    interlocutorDefaultValues,
  ) as InterlocutorState;
};

const checkIfDataIsOk = (interlocutor: InterlocutorState): boolean => {
  const { interlocutorName, isMIOB, miobName } = interlocutor;
  if (!interlocutorName || interlocutorName.code === 'err') {
    return false;
  }
  if (isMIOB === undefined) return false;
  if (isMIOB) {
    if (isUndefinedOrNull(miobName) || miobName?.trim().length === 0) return false;
  }
  return true;
};

export const interlocutorReducer = (
  state: InterlocutorState,
  action: InterlocutorAction,
): InterlocutorState => {
  let newState = { ...state };
  switch (action.type) {
    case 'setInterlocutor': {
      newState = { ...action.paylaod.value };
      newState.isDataOk = checkIfDataIsOk(newState);
      return newState;
    }
    default:
      return newState;
  }
};
