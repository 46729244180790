import { useNavigate } from 'react-router';
import { CodeLibelle } from 'types';
import { clientFilesResult } from 'types/clientFileDtos';
import ResultCard from 'components/resultCard/ResultCard';
import { StyledResultCounter } from 'containers/search/style';
import ArrowRightCircleFullIcon from 'icons/ArrowRightCircleFullIcon';
import ArrowLeftCircleFullIcon from 'icons/ArrowLeftCircleFullIcon';
import {
  StyledSeeOtherFilesTitle,
  StyledSeeOtherFilesWrapper,
  StyledWaitingFilesCardBar,
  StyledWaitingFilesCardLabel,
  StyledWaitingFilesCardSection,
} from './styles';

interface WaitingFilesCardSectionProps {
  label: string;
  id: string;
  listeDossier: clientFilesResult[];
  niveauxProcess: CodeLibelle[];
  getFileDetailsAndNavigate: (
    numeroProjet: number,
    projetDetail: clientFilesResult,
  ) => void;
  showMore?: boolean;
  setShowMore: (key: string) => void;
}

const WaitingFilesCardSection: React.FC<WaitingFilesCardSectionProps> = ({
  label,
  id,
  listeDossier,
  niveauxProcess,
  getFileDetailsAndNavigate,
  showMore,
  setShowMore,
}) => {
  const navigate = useNavigate();

  const onShowMoreClick = () => {
    if (listeDossier.length <= 4) {
      setShowMore(id);
    } else {
      sessionStorage.setItem('listeDossier', JSON.stringify(listeDossier));
      navigate('/recherche');
    }
  };

  const onShowLessClick = () => {
    setShowMore('');
  };

  const renderNavNbShowFiles = () => {
    if (listeDossier.length <= 1) return null;
    if (showMore) {
      return (
        <StyledSeeOtherFilesWrapper
          onClick={() => onShowLessClick()}
          role="button"
          tabIndex={0}
          onKeyDown={() => onShowLessClick()}>
          <div style={{ width: ' 1.8rem', marginLeft: '1rem' }}>
            <ArrowLeftCircleFullIcon />
          </div>
          <StyledSeeOtherFilesTitle>Retour</StyledSeeOtherFilesTitle>
        </StyledSeeOtherFilesWrapper>
      );
    }

    return (
      <StyledSeeOtherFilesWrapper
        onClick={() => onShowMoreClick()}
        role="button"
        tabIndex={0}
        onKeyDown={() => onShowMoreClick()}>
        <StyledSeeOtherFilesTitle> Voir tous les dossiers</StyledSeeOtherFilesTitle>
        <div style={{ width: ' 1.8rem', marginLeft: '1rem' }}>
          <ArrowRightCircleFullIcon />
        </div>
      </StyledSeeOtherFilesWrapper>
    );
  };

  const renderlisteDossier = () => {
    if (showMore) {
      return (
        <div style={{ marginTop: '2rem' }}>
          {listeDossier.map(dossier => {
            return (
              <div key={dossier.IdProjet} style={{ marginBottom: '3rem' }}>
                <ResultCard
                  file={dossier}
                  niveauxProcess={niveauxProcess}
                  onClick={getFileDetailsAndNavigate}
                  inDashboard
                />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <>
        {' '}
        {listeDossier.length === 0 ? (
          <p>
            <i>Aucun dossier</i>
          </p>
        ) : (
          <div style={{ marginTop: '2rem' }}>
            <ResultCard
              file={listeDossier[0]}
              niveauxProcess={niveauxProcess}
              onClick={getFileDetailsAndNavigate}
              inDashboard
            />
          </div>
        )}
      </>
    );
  };
  return (
    <StyledWaitingFilesCardSection>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <StyledWaitingFilesCardLabel>{label}</StyledWaitingFilesCardLabel>
        {listeDossier?.length !== 0 && (
          <StyledResultCounter>{listeDossier?.length}</StyledResultCounter>
        )}
        <div style={{ width: '100%', marginTop: '1.8%', marginLeft: '0.8rem' }}>
          <StyledWaitingFilesCardBar />
        </div>
        {renderNavNbShowFiles()}
      </div>
      {renderlisteDossier()}
    </StyledWaitingFilesCardSection>
  );
};

export default WaitingFilesCardSection;
