const types = {
  normal: 'normal',
  bold: 'bold',
};

const sizes = {
  h1: '2.8rem',
  h2: '2.2rem',
  h3: '1.8rem',
  h4: '1.5rem',
  h5: '1.2rem',
  normalText: '1.5rem',
  smallText: '1.2rem',
  inputSize: '1.5rem',
  titleModal: '2.2rem',
  titleModalDocument: '2rem',
};

const fonts = {
  types,
  sizes,
};

export default fonts;
