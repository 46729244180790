import NextButton from 'components/buttons/nextButton/NextButton';
import CancelButton from 'components/buttons/cancelButton/CancelButton';
import Space from 'components/space/Space';
import CheckInCircle from 'icons/CheckInCircle';
import Modal from '../Modal';
import {
  PlainModalText,
  StyledBlackTitle,
  StyledGeneralModalContentContainer,
} from '../style';
import GeneralIllustrationDocuments from './Assets/GeneralIllustrationDocuments';

interface PjConfirmationModalProps {
  isVisible: boolean;
  accept: () => void;
  close: () => void;
}

const PjConfirmationModal: React.FC<PjConfirmationModalProps> = ({
  isVisible,
  accept,
  close,
}) => {
  return (
    <Modal isVisible={isVisible} height="48.8rem" width="60rem" onClose={close}>
      <StyledGeneralModalContentContainer
        style={{ marginLeft: '12.7rem', paddingBottom: '2rem' }}>
        <div style={{ marginLeft: '12.7rem' }}>
          <GeneralIllustrationDocuments />
        </div>

        <Space marginBottom="3.6rem" />

        <StyledBlackTitle>Des pièces ont été téléchargées.</StyledBlackTitle>

        <Space marginBottom="1.6rem" />

        <PlainModalText>
          Vous avez téléchargé des pièces justificatives sans nous les transmettre.
          <br />
          Souhaitez-vous poursuivre ?
        </PlainModalText>

        <Space marginBottom="4.8rem" />

        <div
          style={{
            display: 'flex',
            height: '4.8rem',
            marginLeft: '9.7rem',
          }}>
          <div style={{ marginRight: '1.6rem' }}>
            <CancelButton handleonClick={close} />
          </div>

          <NextButton
            isDisabled={false}
            handleonClick={accept}
            icon={<CheckInCircle color="white" />}
            label="Poursuivre"
          />
        </div>
      </StyledGeneralModalContentContainer>
    </Modal>
  );
};

export default PjConfirmationModal;
