import { useEffect } from 'react';
import { ValidityHtmlElement } from 'components/designSystem/DesignSystemTypes';
import { SessionStorageKeys } from 'types';

const useInputValidation = (
  stepName: SessionStorageKeys,
  className: string,
  depList: unknown[],
): void => {
  useEffect(() => {
    if (!sessionStorage.getItem(stepName)) {
      // pas besoin de vérifier les inputs si l'utilisateur n'a pas rempli l'étape donnée préalablement
      return;
    }
    setTimeout(() => {
      const inputToValidate: NodeListOf<HTMLInputElement> =
        document.body.querySelectorAll(`.${className}`);
      for (let i = 0; i < inputToValidate.length; i += 1) {
        const valid = inputToValidate[i].checkValidity();
        const currentElement = inputToValidate[i] as unknown as ValidityHtmlElement;
        if (valid) {
          currentElement.valid = true;
          currentElement.invalid = false;
        } else {
          currentElement.invalid = true;
          currentElement.valid = false;
        }
      }
    }, 1000);
  }, [...depList]);
};

export default useInputValidation;
