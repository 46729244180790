import { getBorrowerDebts } from 'api/referentialService';
import TextButton from 'components/buttons/textButton/TextButton';
import CustomInput from 'components/customInput/CustomInput';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import InformationArea from 'components/informationArea/InformationArea';
import CloseModalConfirmation from 'components/modals/ConfirmationModals/CloseModalConfirmation';
import Modal from 'components/modals/Modal';
import Space from 'components/space/Space';

import {
  StyledBtnWrapper,
  StyledCheckboxWrapper,
  StyledSelectionWrapper,
} from 'containers/communs/style';
import CrossCircle from 'icons/CrossCircle';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import WarningIcon from 'icons/WarningIcon';
import React, { useEffect, useState } from 'react';
import { Operation } from 'reducers/operationClient/types';
import colors from 'styles/color';
import { CodeLibelle } from 'types';
import { StyledNumericLabel, StyledSelectionModalAddLoanButton } from '../style';

interface DetteModalProps {
  isModalVisible: boolean;
  hasInsurance: boolean;
  alreadySelectedDebts: Operation[];
  onSaveSelectedDette: (newSelectedList: Operation[]) => void;
  onCloseModal: () => void;
}
const DetteModal: React.FC<DetteModalProps> = ({
  isModalVisible,
  hasInsurance,
  alreadySelectedDebts,
  onCloseModal,
  onSaveSelectedDette,
}) => {
  const [isCloseConfirmationVisible, setIsCloseConfirmationVisible] =
    useState<boolean>(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState<boolean>(false);
  const [debtsList, setDebtList] = useState<Operation[]>([]);
  const [selectedItems, setSelectedItems] = useState<Operation[]>([] as Operation[]);

  const updateDebtList = (debt: Operation) => {
    setDebtList(
      debtsList.map(debtFromList => {
        if (debtFromList.code === debt.code) {
          return debt;
        }
        return debtFromList;
      }),
    );
  };

  const isDetteChecked = (itemCode: string) => {
    if (selectedItems) {
      const item = selectedItems.find(element => element.code === itemCode);
      if (item) {
        return true;
      }
      return false;
    }
    return false;
  };

  const onChecked = (e: Event, item: CodeLibelle) => {
    const checkValue = (e as CustomEvent)?.detail?.value as boolean;

    if (checkValue) {
      const newArray = [
        ...selectedItems,
        { code: item.code, libelle: item.libelle, amount: 0 } as Operation,
      ];
      setSelectedItems(newArray);
    } else {
      setSelectedItems(
        selectedItems.filter(current => {
          return current.code !== item.code;
        }),
      );
    }
  };

  const initialNeedsValueMap = new Map<string, Operation>();

  const getBuybackDebts = async () => {
    const response = await getBorrowerDebts();
    let libelleBuyBackDebts = response.Resultat;

    if (!hasInsurance) {
      libelleBuyBackDebts = libelleBuyBackDebts.filter(buyBackDebt => {
        return buyBackDebt.ordreString !== 'I';
      });
    }

    const buyBackDebts = libelleBuyBackDebts.map(item => {
      const newItem: Operation = {
        code: item.code,
        uid: '',
        amount: 1,
        libelle: item.libelle,
        type: item.ordreString,
      };
      return newItem;
    });

    setDebtList(buyBackDebts);
  };

  const isDebtDisabled = (debt: Operation): boolean => {
    return (
      (hasInsurance || debt.code === 'DB' || debt.code === 'RD') &&
      alreadySelectedDebts.find(alreadySelectedDebt => {
        return alreadySelectedDebt.code.substring(0, 2) === debt.code;
      }) !== undefined
    );
  };

  const onModalAccept = () => {
    sessionStorage.removeItem('debtAdditionalInformation');
    const finalItemsSelection = [] as Operation[];

    debtsList.forEach(debt => {
      if (isDetteChecked(debt.code)) {
        for (let i = 0; i < debt.amount; i += 1) {
          const finalDebt = { ...debt };
          finalDebt.amount = 0;
          finalItemsSelection.push(finalDebt);
        }
      }
    });

    onSaveSelectedDette(finalItemsSelection);
    setSelectedItems([]);
    setDebtList([]);
  };

  useEffect(() => {
    let sumOfAllNeeds = alreadySelectedDebts.length;

    debtsList.forEach(item => {
      if (isDetteChecked(item.code)) {
        sumOfAllNeeds += item.amount;
      }
    });

    setIsSubmitButtonEnabled(
      selectedItems && selectedItems.length > 0 && sumOfAllNeeds <= 15,
    );
  }, [selectedItems, debtsList]);

  useEffect(() => {
    getBuybackDebts();
  }, [isModalVisible]);

  useEffect(() => {
    getBuybackDebts();
  }, [hasInsurance]);

  const getModalContent = () => {
    return (
      <>
        <p
          style={{
            margin: '5.2rem 9.6rem 1.6rem',
            fontWeight: 'bold',
            fontFamily: 'Ubuntu',
            fontSize: '2.4rem',
          }}>
          Sélectionnez les dettes à ajouter
        </p>
        <div
          style={{ marginLeft: '9.6rem', marginRight: '9.6rem', marginBottom: '1.6rem' }}>
          <InformationArea icon={<WarningIcon color="#2275D7" />} backgroundColor="blue">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Le nombre de dettes est limité à 15.
            </span>
          </InformationArea>
        </div>
        <div className="element-in-modal" role="button" tabIndex={0}>
          {' '}
        </div>
        {debtsList.map((item, index) => {
          initialNeedsValueMap.set(item.code, {
            libelle: item.libelle,
            code: item.code,
            amount: 1,
          } as Operation);
          return (
            <React.Fragment key={`Debt-${item.code}`}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginRight: '9.6rem',
                }}>
                <StyledSelectionWrapper isSelected={isDetteChecked(item.code)}>
                  <StyledCheckboxWrapper>
                    <BaseCheckbox
                      checked={isDetteChecked(item.code) ? true : null}
                      disabled={isDebtDisabled(item) ? true : undefined}
                      onCheckedChanged={e => {
                        onChecked(e, item);
                      }}>
                      <span>{item.libelle}</span>
                    </BaseCheckbox>
                  </StyledCheckboxWrapper>
                </StyledSelectionWrapper>
                <div>
                  <StyledNumericLabel>Quantité</StyledNumericLabel>
                  <Space marginBottom="0.4rem" />
                  <CustomInput
                    inputWidth="6.4rem"
                    name=""
                    isFocused={false}
                    isDisabled={hasInsurance || item.code === 'DB' || item.code === 'RD'}
                    value={item.amount ? item.amount : undefined}
                    onChange={newValue => {
                      const newItem = { ...item };
                      newItem.amount = Number(newValue);
                      updateDebtList(newItem);
                    }}
                  />
                </div>
              </div>
              {index !== debtsList.length - 1 ? <Space marginBottom="1.6rem" /> : <></>}
            </React.Fragment>
          );
        })}
        <StyledBtnWrapper>
          {isSubmitButtonEnabled ? (
            <StyledSelectionModalAddLoanButton
              className="selection-btn"
              tabIndex={0}
              onKeyDownCapture={event => {
                if (event.key === 'Enter') {
                  onModalAccept();
                }
              }}
              onClick={onModalAccept}>
              <PlusCircleIcon color="#ffffff" />
              <span style={{ marginLeft: '1rem' }}>Ajouter dettes sélectionnées</span>
            </StyledSelectionModalAddLoanButton>
          ) : (
            <StyledSelectionModalAddLoanButton disabled className="selection-btn">
              <PlusCircleIcon color="#ffffff" />
              <span style={{ marginLeft: '1rem' }}>Ajouter les dettes sélectionnées</span>
            </StyledSelectionModalAddLoanButton>
          )}
          <TextButton
            width="8.8rem"
            label="Fermer"
            onClick={() => {
              if (!selectedItems || selectedItems.length > 0) {
                setIsCloseConfirmationVisible(true);
              } else {
                onCloseModal();
              }
            }}>
            <CrossCircle color={colors.chartColors.mainGreen500} />{' '}
          </TextButton>
        </StyledBtnWrapper>
      </>
    );
  };

  return (
    <Modal
      width="67.7rem"
      height="75.6rem"
      isVisible={isModalVisible}
      onClose={() => {
        setIsCloseConfirmationVisible(true);
      }}>
      {getModalContent()}

      <CloseModalConfirmation
        isVisible={isCloseConfirmationVisible}
        accept={() => {
          sessionStorage.removeItem('debtAdditionalInformation');
          setIsCloseConfirmationVisible(false);
          setSelectedItems([]);
          setDebtList([]);
          onCloseModal();
        }}
        close={() => {
          (document.getElementsByClassName('element-in-modal')[0] as HTMLElement).focus();
          setIsCloseConfirmationVisible(false);
        }}
      />
    </Modal>
  );
};

export default DetteModal;
