import Space from 'components/space/Space';
import {
  Asset,
  CurrentAssetAction,
  CurrentLoanAction,
  Loan,
  Operation,
} from 'reducers/operationClient/types';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CodeLibelle } from 'types';
import FormFooter from 'components/formFooter/FormFooter';
import { updateSessionStorage } from 'utils/storage';
import GeneralConfirmationModal from 'components/modals/ConfirmationModals/GeneralConfirmationModal';
import {
  getOperationInitialState,
  operationReducer,
} from 'reducers/operationClient/operation';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import { getHomeLoanPurposes } from 'api/referentialService';
import { handleSaveAndExit, scrollToTopAndShowError } from 'utils/commun';
import WarningIcon from 'icons/WarningIcon';
import TextArea from 'components/textArea/TextArea';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import { StyledInfo } from 'containers/conditionsFinancieres/style';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import Loader from 'components/Loader';
import { StyledFormWrapper } from './style';
import AssetForm from './sections/AssetForm';
import LoanForm from './sections/LoanForm';
import OtherNeedsForm from './sections/OtherNeedsForm';
import IncidentsImpayes from './sections/IncidentsImpayes';
import AssetModal from './modals/AssetModal';
import LoanModal from './modals/LoanModal';
import BesoinModal from './modals/BesoinModal';
import DetteModal from './modals/DetteModal';

interface OperationClientFormProps {
  currentLoanReducerDispatch: React.Dispatch<CurrentLoanAction>;
  currentAssetReducerDispatch: React.Dispatch<CurrentAssetAction>;
  currentLoanState: Loan;
  currentAssetState: Asset;
}

const OperationClientForm: React.FC<OperationClientFormProps> = ({
  currentLoanReducerDispatch,
  currentAssetReducerDispatch,
  currentLoanState,
  currentAssetState,
}) => {
  const [state, operationReducerDispatch] = useReducer(
    operationReducer,
    getOperationInitialState('operationsClient'),
  );

  const [isNewAssetModalVisible, setIsNewAssetModalVisible] = useState<boolean>(false);
  const [isNewLoanModalVisible, setIsNewLoanModalVisible] = useState<boolean>(false);
  const [isNewNeedModalVisible, setIsNewNeedModalVisible] = useState<boolean>(false);
  const [isNewDebtModalVisible, setIsNewDebtModalVisible] = useState<boolean>(false);
  const [isGeneralConfirmationModalVisible, setIsGeneralConfirmationModalVisible] =
    useState<boolean>(false);
  const [tempIncidentPayment, SetTempIncidentPayment] = useState<CodeLibelle[]>([]);
  const [tempNbMoisImpayes, setTempNbMoisImpayes] = useState<number>(0);

  const [, setOtherNeedsList] = useState<Operation[]>([]);
  const [, setBuybackDebtsList] = useState<Operation[]>([]);

  const [homeLoanPurposes, setHomeLoanPurposes] = useState<CodeLibelle[]>([]);

  const [hasError, setHasError] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(false);

  const [needsCounter, setNeedsCounter] = useState<number>(1);
  const [needsAreOk, setNeedsAreOk] = useState<boolean>(true);
  const [debtsAreOk, setDebtsAreOk] = useState<boolean>(true);
  const otherNeedsRef = useRef<null | HTMLDivElement>(null);

  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const navigate = useNavigate();

  const onIncidentOrDefaultToggleChange = (
    type: 'incident' | 'impaye',
    value: boolean,
  ) => {
    if (type === 'incident') {
      if (!state.hasPaymentIncident) {
        operationReducerDispatch({
          type: 'setIncidentPaymentList',
          payload: tempIncidentPayment,
        });
      } else {
        SetTempIncidentPayment(state.incidentPaymentList);
        operationReducerDispatch({
          type: 'setIncidentPaymentList',
          payload: [],
        });
      }

      operationReducerDispatch({
        type: 'setHasPaymentIncident',
        payload: value,
      });
    } else {
      if (!state.hasPaymentDefault) {
        operationReducerDispatch({
          type: 'setNbPaymentDefault',
          payload: tempNbMoisImpayes,
        });
      } else {
        setTempNbMoisImpayes(state.nbPaymentDefault);
        operationReducerDispatch({
          type: 'setNbPaymentDefault',
          payload: 0,
        });
      }

      operationReducerDispatch({
        type: 'setHasPaymentDefault',
        payload: value,
      });
    }
  };

  const OnIncidentChecked = (typeIncident: CodeLibelle, value: boolean) => {
    if (value) {
      const newArray = [...state.incidentPaymentList, typeIncident];
      operationReducerDispatch({
        type: 'setIncidentPaymentList',
        payload: newArray,
      });
    } else {
      operationReducerDispatch({
        type: 'setIncidentPaymentList',
        payload: state.incidentPaymentList.filter(current => {
          return current.code !== typeIncident.code;
        }),
      });
    }
  };

  const onNbPaymentDefaultChange = (value: number) => {
    operationReducerDispatch({
      type: 'setNbPaymentDefault',
      payload: value,
    });
  };

  const saveSelectedItems = (
    newSelectedNeedsList: Operation[],
    reducerOperation: 'setNewNeedsList' | 'setBuybackDebtsList',
  ) => {
    const newSelectedItems =
      reducerOperation === 'setNewNeedsList'
        ? [...state.otherNeedsList]
        : [...state.buybackDebtsList];

    let internalCounter = needsCounter;

    const tempSelectedNeedsList = newSelectedNeedsList.map(need => {
      const newNeed = { ...need };
      newNeed.uid = `${newNeed.code}-${(internalCounter += 1)}`;
      return newNeed;
    });

    setNeedsCounter(internalCounter);

    tempSelectedNeedsList.forEach(newSelectedNeed => {
      newSelectedItems.push(newSelectedNeed);
    });

    operationReducerDispatch({
      type: reducerOperation,
      payload: newSelectedItems,
    });
  };

  const listHasDuplicateItems = (itemList: any[]): boolean => {
    const setFromList = [...new Set(itemList)];

    if (setFromList.length !== itemList.length) return true;
    return false;
  };

  const dataIsConsistent = (): boolean => {
    if (
      state.hasInsurance &&
      (listHasDuplicateItems(state.otherNeedsList.map(need => need.code)) ||
        listHasDuplicateItems(state.buybackDebtsList.map(debt => debt.code)))
    ) {
      return false;
    }
    return true;
  };

  const saveInSessionStorage = (): void => {
    updateSessionStorage('operationsClient', state);
  };

  const onSaveButtonClick = (): void => {
    setIsGeneralConfirmationModalVisible(true);
  };

  const closeModal = (): void => {
    setIsGeneralConfirmationModalVisible(false);
  };

  useEffect(() => {
    getHomeLoanPurposes().then(resultat => {
      const result: CodeLibelle[] = [];

      resultat.Resultat.forEach(item => {
        result.push({ code: item.code, libelle: item.libelle });
      });
      setHomeLoanPurposes(result);
    });
  }, []);

  return (
    <>
      {withSummaryCard(
        'Operation',
        <>
          {hasError ? (
            <>
              <Space marginTop="2.4rem" />
              <TextArea
                title={messagesCheckInput.CHECK_ERROR_TITLE}
                type="error"
                includeIcon>
                <span> {messagesCheckInput.CHECK_ERROR}</span>
              </TextArea>
              <Space marginTop="2.4rem" />
            </>
          ) : (
            <Space marginBottom="10.4rem" />
          )}
          <StyledFormWrapper>
            <AssetForm
              operationReducerDispatch={operationReducerDispatch}
              currentAssetReducerDispatch={currentAssetReducerDispatch}
              state={state}
              setModalVisibility={setIsNewAssetModalVisible}
              checkInputs={checkInputs}
              setIsEdited={setIsEdited}
            />

            <Space marginBottom="8.8rem" />

            <LoanForm
              operationReducerDispatch={operationReducerDispatch}
              currentLoanReducerDispatch={currentLoanReducerDispatch}
              currentLoanState={currentLoanState}
              state={state}
              setModalVisibility={setIsNewLoanModalVisible}
              checkInputs={checkInputs}
              setIsEdited={setIsEdited}
            />

            <Space marginBottom="8.8rem" />

            <OtherNeedsForm
              operationReducerDispatch={operationReducerDispatch}
              state={state}
              needsAreOk={needsAreOk}
              checkIfNeedsAreOk={listToCheck => {
                if (!listHasDuplicateItems(listToCheck.map(need => need.code))) {
                  setNeedsAreOk(true);
                }
              }}
              debtsAreOk={debtsAreOk}
              checkIfDebtsAreOk={listToCheck => {
                if (!listHasDuplicateItems(listToCheck.map(debt => debt.code))) {
                  setDebtsAreOk(true);
                }
              }}
              otherNeedsRef={otherNeedsRef}
              setNeedsModalVisibility={setIsNewNeedModalVisible}
              setDebtsModalVisibility={setIsNewDebtModalVisible}
              checkInputs={checkInputs}
            />

            <Space marginBottom="8.8rem" />

            <IncidentsImpayes
              onIncidentsOrImpayesToggleChange={onIncidentOrDefaultToggleChange}
              onIncidentChecked={OnIncidentChecked}
              incidentPayment={state.incidentPaymentList}
              hasIncident={state.hasPaymentIncident}
              hasImpaye={state.hasPaymentDefault}
              nbMonthImpayes={state.nbPaymentDefault.toString()}
              onNbMonthImpayesChange={onNbPaymentDefaultChange}
              checkInputs={checkInputs}
            />
          </StyledFormWrapper>

          <GeneralConfirmationModal
            isVisible={isGeneralConfirmationModalVisible}
            isDataOK={state.isDataOk}
            accept={() => {
              handleSaveAndExit({
                setErrorMessages,
                setIsLoading,
                setIsModalVisible: setIsGeneralConfirmationModalVisible,
                saveInSessionStorage,
                navigate,
              });
            }}
            close={closeModal}
          />

          {isNewAssetModalVisible ? (
            <AssetModal
              close={() => setIsNewAssetModalVisible(false)}
              operationReducerDispatch={operationReducerDispatch}
              currentAssetReducerDispatch={currentAssetReducerDispatch}
              currentAssetState={currentAssetState}
              state={state}
              homeLoanPurposes={homeLoanPurposes}
              isEdited={isEdited}
            />
          ) : null}

          {isNewLoanModalVisible ? (
            <LoanModal
              close={() => {
                setIsNewLoanModalVisible(false);
              }}
              operationReducerDispatch={operationReducerDispatch}
              currentLoanReducerDispatch={currentLoanReducerDispatch}
              currentLoanState={currentLoanState}
              state={state}
              isEdited={isEdited}
            />
          ) : null}

          <BesoinModal
            isModalVisible={isNewNeedModalVisible}
            hasInsurance={state.hasInsurance}
            alreadySelectedNeeds={state.otherNeedsList}
            onSaveSelectedBesoin={newSelectedNeedsList => {
              saveSelectedItems(newSelectedNeedsList, 'setNewNeedsList');

              setIsNewNeedModalVisible(false);
            }}
            onCloseModal={() => {
              setOtherNeedsList(state.otherNeedsList);

              setIsNewNeedModalVisible(false);
            }}
          />

          <DetteModal
            isModalVisible={isNewDebtModalVisible}
            hasInsurance={state.hasInsurance}
            alreadySelectedDebts={state.buybackDebtsList}
            onSaveSelectedDette={newSelectedDebtsList => {
              saveSelectedItems(newSelectedDebtsList, 'setBuybackDebtsList');

              setIsNewDebtModalVisible(false);
            }}
            onCloseModal={() => {
              setBuybackDebtsList(state.buybackDebtsList);

              setIsNewDebtModalVisible(false);
            }}
          />
        </>,
        {
          operationsClientState: state,
        },
      )}
      {errorMessages.length > 0 && (
        <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '10.4rem' }}>
          <WarningIcon />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {errorMessages.map(message => {
              return (
                <p
                  style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                  key={message.MessageId}>
                  {message.MessageLib}
                </p>
              );
            })}
          </div>
        </StyledInfo>
      )}
      <FormFooter
        showPreviousButton
        onSaveButtonClick={onSaveButtonClick}
        onPreviousButtonClick={() => {
          saveInSessionStorage();
          navigate('/donnees_client');
        }}
        onNextButtonClick={() => {
          if (state.isDataOk) {
            if (dataIsConsistent()) {
              saveInSessionStorage();
              sessionStorage.removeItem('loanInformationsByIntermediationFee');
              navigate('/conditions_financieres');
            } else {
              if (listHasDuplicateItems(state.buybackDebtsList.map(debt => debt.code))) {
                setDebtsAreOk(false);
              }
              if (listHasDuplicateItems(state.otherNeedsList.map(need => need.code))) {
                setNeedsAreOk(false);
              }
              otherNeedsRef.current?.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            scrollToTopAndShowError({ setHasError, setCheckInputs });
          }
        }}
      />
      {isLoading ? <Loader animationDuration={2} /> : null}
    </>
  );
};

export default OperationClientForm;
