import BackButton from 'components/buttons/backButton/BackButton';
import NextButton from 'components/buttons/nextButton/NextButton';
import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import CloudArrowIcon from 'icons/CloudArrowIcon';
import { StyledSaveLaterText, StyledSaveLaterWrapper } from './style';

interface FormFooterProps {
  onNextButtonClick?: () => void;
  onPreviousButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  isNextButtonDisabled?: boolean;
  showPreviousButton: boolean;
  nextButtonLabel?: string;
  nextButtonIcon?: React.ReactNode;
  previousButtonLabel?: string;
  marginTop?: string;
  saveBtn?: 'text' | 'btn';
  saveBtnLabel?: string;
  saveButtonIcon?: React.ReactNode;
  hideSaveBtn?: boolean;
}
const FormFooter: React.FC<FormFooterProps> = ({
  onNextButtonClick,
  onPreviousButtonClick,
  onSaveButtonClick,
  isNextButtonDisabled,
  showPreviousButton,
  nextButtonLabel,
  nextButtonIcon,
  previousButtonLabel,
  marginTop,
  saveBtn,
  saveBtnLabel,
  saveButtonIcon,
  hideSaveBtn,
}) => {
  const renderSaveBtnOrText = () => {
    if (hideSaveBtn) {
      return <div />;
    }
    if (saveBtn === 'btn') {
      return (
        <StyledSecondaryButton
          onClick={onSaveButtonClick}
          onKeyDown={event => {
            if (
              onSaveButtonClick &&
              (event.code === 'Enter' ||
                event.code === 'Space' ||
                event.code === 'NumpadEnter')
            )
              onSaveButtonClick();
          }}
          tabIndex={0}>
          {saveButtonIcon && (
            <div style={{ marginRight: '0.8rem' }}>{saveButtonIcon}</div>
          )}
          <span>{saveBtnLabel}</span>
        </StyledSecondaryButton>
      );
    }
    return (
      <StyledSaveLaterWrapper
        onClick={onSaveButtonClick}
        tabIndex={0}
        role="button"
        onKeyDown={event => {
          if (
            onSaveButtonClick &&
            (event.code === 'Enter' ||
              event.code === 'Space' ||
              event.code === 'NumpadEnter')
          )
            onSaveButtonClick();
        }}>
        <div style={{ margin: '1.5rem 0.8rem 0 0' }}>
          <CloudArrowIcon />
        </div>
        <StyledSaveLaterText>Sauvegarder et quitter</StyledSaveLaterText>
      </StyledSaveLaterWrapper>
    );
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: marginTop || '10.4rem',
        marginBottom: '11.6rem',
        paddingBottom: '1rem',
      }}>
      {renderSaveBtnOrText()}
      {showPreviousButton ? (
        <div style={{ display: 'flex', gap: '4.8rem' }}>
          <BackButton
            width="13.5rem"
            label={previousButtonLabel}
            handleonClick={() => {
              if (onPreviousButtonClick) {
                onPreviousButtonClick();
              }
            }}
          />
          <NextButton
            label={nextButtonLabel}
            icon={nextButtonIcon}
            isDisabled={isNextButtonDisabled}
            handleonClick={() => {
              if (onNextButtonClick) {
                onNextButtonClick();
              }
            }}
          />
        </div>
      ) : (
        <NextButton
          label={nextButtonLabel}
          icon={nextButtonIcon}
          isDisabled={isNextButtonDisabled}
          handleonClick={() => {
            if (onNextButtonClick) {
              onNextButtonClick();
            }
          }}
        />
      )}
    </div>
  );
};

FormFooter.defaultProps = {
  saveBtnLabel: 'Transmettre les pièces plus tard',
};

export default FormFooter;
