import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseRadioButtonDS extends HTMLElement {
  checked?: boolean | null;

  disabled?: boolean | null;
}

export const BaseRadioButton = createComponent(
  React,
  'base-radio-button',
  BaseRadioButtonDS,
  {
    onChecked: 'checked',
  },
);
