import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import BaseButton from 'components/designSystem/BaseButton';
import ActionValidatedIcon from 'icons/ActionPlusIcon';
import EditIcon from 'icons/EditIcon';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';

interface ValidateBtnProps {
  mode: 'view' | 'create';
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const ValidateBtn: React.FC<ValidateBtnProps> = ({
  mode,
  currentLoan,
  state,
  dispatch,
}) => {
  const handleBtnClicked = (isComplete: boolean) => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        const newItem = { ...item };
        newItem.nominalInterestRate = newItem.nominalInterestRate?.replaceAll(',', '.');
        return {
          ...newItem,
          isAdditionalInformationCompleted: isComplete,
          modificationInprogress: mode === 'view',
        };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  const renderBtn = () => {
    if (mode === 'view') {
      return (
        <>
          <StyledSecondaryButton
            onClick={() => handleBtnClicked(false)}
            style={{ display: 'flex', gap: '0.5rem' }}>
            <EditIcon />
            <span>Modifier</span>
          </StyledSecondaryButton>
        </>
      );
    }
    return (
      <>
        {currentLoan?.isDataOk ? (
          <BaseButton
            style={{ width: '13.7rem' }}
            tabIndex={0}
            onKeyDownCapture={event => {
              if (event.key === 'Enter') {
                handleBtnClicked(true);
              }
            }}
            onClick={() => handleBtnClicked(true)}>
            <ActionValidatedIcon />
            <span style={{ marginLeft: '1rem' }}>Valider</span>
          </BaseButton>
        ) : (
          <BaseButton disabled className="disabled-btn" style={{ width: '13.7rem' }}>
            <ActionValidatedIcon />
            <span style={{ marginLeft: '1rem' }}>Valider</span>
          </BaseButton>
        )}
      </>
    );
  };

  return <>{renderBtn()}</>;
};

export default ValidateBtn;
