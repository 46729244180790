import * as React from 'react';

interface HouseIconProps {
  width?: number;
  color?: string;
}

const HouseIcon: React.FC<HouseIconProps> = ({ width, color }) => {
  const baseWidth = 56;
  const baseHeight = 44;

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg width={width} height={scaledHeight} xmlns="http://www.w3.org/2000/svg">
      <title>C2F272F0-9ABF-440F-A625-F851D4914339</title>
      <path
        d="M55.503 26.43 29.183.476c-.644-.635-1.736-.635-2.38 0L.483 26.43c-.644.635-.644 1.683 0 2.345.644.634 1.708.634 2.38 0l6.496-6.4v19.97c0 .91.756 1.655 1.68 1.655h11.004c.924 0 1.68-.745 1.68-1.655v-10.95h8.456l.028 10.95c0 .91.756 1.655 1.68 1.655h11.06c.448 0 .868-.165 1.176-.496.308-.304.504-.745.476-1.159V22.403l6.496 6.427a1.67 1.67 0 0 0 1.176.496c.42 0 .868-.165 1.176-.496.728-.717.728-1.765.056-2.4ZM43.295 40.635h-7.7l-.028-10.95c0-.91-.756-1.655-1.68-1.655H22.07c-.448 0-.868.166-1.176.497a1.69 1.69 0 0 0-.504 1.158v10.95h-7.644V19.038l15.26-15.06 15.26 15.06.028 21.597Z"
        fill={color}
        fillRule="nonzero"
        transform={`scale(${scaleRatio})`}
      />
    </svg>
  );
};

HouseIcon.defaultProps = {
  width: 56,
  color: '#292C2E',
};

export default HouseIcon;
