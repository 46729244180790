import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseButtonDS extends HTMLElement {
  clickable?: boolean;

  disabled?: boolean | null;

  secondary?: boolean;

  class?: string;
}

const BaseButton = createComponent(React, 'base-button', BaseButtonDS, {});
export default BaseButton;
