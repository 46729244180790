import { useEffect } from 'react';
import AuthorizationService from 'api/AuthorizationService';

const Authorize: React.FC = () => {
  useEffect(() => {
    AuthorizationService.authorize('/silent-refresh');
  }, []);

  return <></>;
};

export default Authorize;
