import { v4 as uuidv4 } from 'uuid';
import TextButton from 'components/buttons/textButton/TextButton';
import Space from 'components/space/Space';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import React, { useEffect, useState } from 'react';
import { Bank } from 'types';
import {
  RessourcesMensuellesAction,
  RessourcesMensuellesState,
} from 'reducers/ressourcesMensuelles/types';
import { getBanks } from 'api/referentialService';
import useInputValidation from 'utils/useInputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import { ComboInputDS } from 'components/designSystem/ComboInput';
import BankInput from './BankInput';

interface InformationsBancairesProps {
  checkInputs: boolean;
  state: RessourcesMensuellesState;
  dispatch: React.Dispatch<RessourcesMensuellesAction>;
}
const InformationsBancaires: React.FC<InformationsBancairesProps> = ({
  checkInputs,
  state,
  dispatch,
}) => {
  const [bankAccounts, setBankAccounts] = useState<Bank[]>(
    state.banks?.length > 0
      ? state.banks
      : [{ code: '', libelle: '', year: '', id: uuidv4() }],
  );
  const [banksReferential, setBanksReferential] = useState<Bank[]>([]);

  useInputValidation('ressourcesMensuelles', 'show-input-ds-validation', [
    banksReferential,
  ]);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await getBanks();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: Bank[] = [];
          Resultat.forEach(item => {
            const bank: Bank = {
              code: item.code,
              libelle: item.libelle,
              year: '',
            };
            temp.push(bank);
          });
          setBanksReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchBanks();
  }, []);

  const addBankAccount = () => {
    setBankAccounts(prevState => {
      return [...prevState, { code: '', libelle: '', year: '', id: uuidv4() }];
    });
  };

  const deleteBank = (bankId: string, position: number) => {
    const newBankList = bankAccounts.filter(
      (item, index) => item.code !== bankId || index !== position,
    );

    dispatch({
      type: 'setBanks',
      payload: newBankList,
    });

    setBankAccounts(newBankList);
  };

  const onBankSelect = (e: Event, selectedBankIndex: number) => {
    const selectedBank = (e as CustomEvent).detail.value as Bank;
    const updatedBanks: Bank[] = [];
    bankAccounts.forEach((item, index) => {
      if (index === selectedBankIndex) {
        const newBank = selectedBank;
        newBank.year = item.year;
        newBank.code = selectedBank.code;
        updatedBanks.push({ ...newBank });
      } else {
        updatedBanks.push(item);
      }
    });
    setBankAccounts(updatedBanks);
    dispatch({
      type: 'setBanks',
      payload: updatedBanks,
    });
  };

  const onBankChange = (year: string, selectedBankIndex: number) => {
    const updatedBanks: Bank[] = [];
    bankAccounts.forEach((item, index) => {
      if (index === selectedBankIndex) {
        updatedBanks.push({ ...item, year });
      } else {
        updatedBanks.push(item);
      }
    });
    setBankAccounts(updatedBanks);
    dispatch({
      type: 'setBanks',
      payload: updatedBanks,
    });
  };

  const onBankBlur = (event: Event, selectedBankIndex: number) => {
    const updatedBanks: Bank[] = [];
    if (event && event.target) {
      bankAccounts.forEach((item, index) => {
        if (index === selectedBankIndex) {
          const bankAccoutMatchList = banksReferential?.find(bank => {
            return (
              bank.libelle.toUpperCase() ===
              ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
            );
          });
          let newBank;
          if (!bankAccoutMatchList) {
            newBank = { ...item };
            newBank.code = 'err';
            newBank.libelle = (event.target as ComboInputDS).value as string;
          } else {
            newBank = bankAccoutMatchList;
            newBank.year = item.year;
          }
          updatedBanks.push(newBank);
        } else {
          updatedBanks.push(item);
        }
      });
      setBankAccounts(updatedBanks);
      dispatch({
        type: 'setBanks',
        payload: updatedBanks,
      });
    }
  };

  const renderBankAccounts = () => {
    if (bankAccounts.length === 0) {
      return null;
    }
    return bankAccounts?.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <BankInput
            listBank={banksReferential}
            itemBank={item}
            indexBank={index}
            checkInputs={checkInputs}
            onBankBlur={onBankBlur}
            onBankChange={onBankChange}
            onBankSelect={onBankSelect}
            onDeleteBank={deleteBank}
          />
        </React.Fragment>
      );
    });
  };
  return (
    <>
      <p
        style={{
          color: '#292c2e',
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          fontSize: '1.6rem',
          margin: '0',
        }}>
        Informations bancaires
      </p>
      {(!bankAccounts || bankAccounts.length === 0) && checkInputs ? (
        <>
          <ErrorMessageArea message={messagesCheckInput.BANK_ACCOUNT_REQUIRED} />
          <Space marginTop="3.2rem" />
        </>
      ) : (
        <></>
      )}

      <Space marginTop="2rem" />
      {renderBankAccounts()}
      <Space marginTop="1.6rem" />
      <TextButton label="Ajouter un nouveau compte" onClick={addBankAccount}>
        <PlusCircleIcon />{' '}
      </TextButton>
    </>
  );
};

export default InformationsBancaires;
