import { City } from 'types';

export enum MaritalStatus {
  C = 'Célibataire / Union libre',
  S = 'Séparé(e)',
  P = 'Pacsé(e)',
  V = 'Veuf(ve)',
  M = 'Marié(e)',
  D = 'Divorcé(e)',
}
export enum HousingStatus {
  P = 'Propriétaire',
  L = 'Locataire',
  F = 'Logé(e) par la famille',
  E = "Logé(e) par l'employeur",
  D = 'Autre',
}
export interface ChildrenBirthYear {
  childId: number;
  birthYear: string;
}

export interface SituationFoyerState {
  addressZipCode: string;
  addressCity?: City;
  maritalStatus?: MaritalStatus;
  hasCoBorrower?: boolean;
  coBorrowerMaritalStatus?: MaritalStatus;
  coBorrowerMaritalStatusIsSameAsBorrower: boolean;
  housingStatus?: HousingStatus;
  startingYearLivingInCurrentHouse?: number;
  numberOfPersonInCurrentHouse?: number;
  childrenInCharge: boolean;
  childrenInChargeBirthYear?: ChildrenBirthYear[];
  isDataOk: boolean;
}

interface SetAddressZipCode {
  type: 'setAddressZipCode';
  payload: string;
}

interface SetAddressCity {
  type: 'setAddressCity';
  payload?: City;
}

interface SetMaritalStatus {
  type: 'setMaritalStatus';
  payload: MaritalStatus;
}

interface SetHasCoBorrower {
  type: 'setHasCoBorrower';
  payload: boolean;
}
interface SetCoBorrowerMaritalStatus {
  type: 'setCoBorrowerMaritalStatus';
  payload?: MaritalStatus;
}
interface SetCoBorrowerMaritalStatusIsSameAsBorrower {
  type: 'setCoBorrowerMaritalStatusIsSameAsBorrower';
  payload: boolean;
}
interface SetHousingStatus {
  type: 'setHousingStatus';
  payload: HousingStatus;
}
interface SetStartingYearLivingInCurrentHouse {
  type: 'setStartingYearLivingInCurrentHouse';
  payload: number | undefined;
}
interface SetNumberOfPersonInCurrentHouse {
  type: 'setNumberOfPersonInCurrentHouse';
  payload: number | undefined;
}
interface SetChildrenInCharge {
  type: 'setChildrenInCharge';
  payload: boolean;
}

interface SetChildrenInChargeBirthYear {
  type: 'setChildrenInChargeBirthYear';
  payload: ChildrenBirthYear[];
}

export type SituationFoyerAction =
  | SetAddressZipCode
  | SetAddressCity
  | SetMaritalStatus
  | SetHasCoBorrower
  | SetCoBorrowerMaritalStatus
  | SetCoBorrowerMaritalStatusIsSameAsBorrower
  | SetHousingStatus
  | SetStartingYearLivingInCurrentHouse
  | SetNumberOfPersonInCurrentHouse
  | SetChildrenInCharge
  | SetChildrenInChargeBirthYear;
