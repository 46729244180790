import {
  StyledInactiveSecondaryNavBarTile,
  StyledSecondaryNavBarText,
  StyledSecondaryNavBarTile,
  StyledSelectedSecondaryNavBarText,
  StyledSelectedSecondaryNavBarTile,
} from '../style';

interface SecondaryNavBarTileProps {
  label: string;
  isSelected: boolean;
  isEnabled: boolean;
  onclick: () => void;
}

const SecondaryNavBarTile: React.FC<SecondaryNavBarTileProps> = ({
  label,
  isSelected,
  isEnabled,
  onclick,
}) => {
  const renderEnabled = () => {
    return (
      <>
        {isSelected ? (
          <StyledSelectedSecondaryNavBarTile
            style={{ cursor: 'pointer' }}
            onKeyPress={onclick}
            onClick={onclick}
            role="button"
            tabIndex={0}>
            <StyledSelectedSecondaryNavBarText>{label}</StyledSelectedSecondaryNavBarText>
          </StyledSelectedSecondaryNavBarTile>
        ) : (
          <StyledSecondaryNavBarTile
            style={{ cursor: 'pointer' }}
            onKeyPress={onclick}
            onClick={onclick}
            role="button"
            tabIndex={0}>
            <StyledSecondaryNavBarText>{label}</StyledSecondaryNavBarText>
          </StyledSecondaryNavBarTile>
        )}
      </>
    );
  };

  return isEnabled ? (
    renderEnabled()
  ) : (
    <StyledInactiveSecondaryNavBarTile>
      <StyledSecondaryNavBarText>{label}</StyledSecondaryNavBarText>
    </StyledInactiveSecondaryNavBarTile>
  );
};

export default SecondaryNavBarTile;
