import styled from 'styled-components';

export const StyledBackground = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgba(84, 84, 84, 0.5);
  background-image: url(${() => process.env.PUBLIC_URL}/homepage_background.jpg);
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position-x: 45rem;
  background-position-y: -10rem;
`;

export const StyledMainSidePanel = styled.div`
  height: 100%;
  width: 50%;
  min-width: 96rem;
  border-radius: 0 6rem 6rem 0;
  background-color: #ffffff;
  padding: 3.2rem 8.8rem 1.6rem;
  display: flex;
  flex-direction: column;
`;

export const StyledMainTitle = styled.span`
  height: 9.6rem;
  width: 78.4rem;
  color: #292c2e;
  font-family: Ubuntu;
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 4.8rem;
  margin-bottom: 4rem;
`;

export const StyledMainHighlightBox = styled.div`
  position: relative;
  height: 33.2rem;
  width: 78.4rem;
  border-radius: 0 11rem 0 11rem;
  background-color: #eff8f0;
  padding: 5.6rem 6rem 5.6rem 8.8rem;
  margin-bottom: 4rem;
  overflow: hidden;
`;

export const StyledHighlightBoxCornerCircles = styled.div`
  box-sizing: border-box;
  height: 12.7rem;
  width: 12.7rem;
  border: 1.2rem solid #268038;
  border-radius: 100%;
  position: absolute;
`;

export const StyledHighlightBoxText = styled.span`
  color: #292c2e;
  font-family: 'ubuntu';
  font-size: 2.4rem;
  letter-spacing: 0;
  line-height: 4rem;
`;

export const StyledAccessBox = styled.div`
  height: 19.6rem;
  width: 78.4rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
  padding: 3.2rem 0 3.2rem 8.8rem;
  margin-bottom: 2rem;
`;

export const StyledAccessBoxMainTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.6rem;
  margin-bottom: 0.8rem;
`;

export const StyledAccessBoxDescription = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 2.6rem;
  margin-bottom: 1.6rem;
`;

export const StyledAccessBoxButtonLabel = styled.span`
  color: #268038;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.6rem;
  text-align: center;
  margin-left: 1.3rem;
`;

export const StyledMentionLegalesText = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledLinkedinNewsLabel = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  cusor: pointer;
`;

export const StyledSecondaryPanel = styled.div`
  width: 50%;
  padding: 8.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledConnectionPanel = styled.div`
  height: 37.2rem;
  width: 57.2rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8.8rem 0;
`;

export const StyledConnectionPanelText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 2.8rem;
  text-align: center;
  margin-bottom: 4rem;
`;

export const StyledConnectionPanelButtonLabel = styled.span`
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.6rem;
  text-align: center;
`;
