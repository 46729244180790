import Modal from 'components/modals/Modal';
import React, { useEffect, useState } from 'react';
import {
  CurrentLoanAction,
  Loan,
  OperationAction,
  OperationState,
} from 'reducers/operationClient/types';
import { saveOnlygoodLoanData } from 'reducers/operationClient/utils';
import Space from 'components/space/Space';
import { SelectElementInput } from 'components/designSystem/SelectElementInput';
import { SelectedButton } from 'components/designSystem/SelectButton';
import { ComboInput } from 'components/designSystem/ComboInput';
import CustomInput from 'components/customInput/CustomInput';
import { Bank, StatusInput } from 'types';
import { getLoaners } from 'api/referentialService';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import TextButton from 'components/buttons/textButton/TextButton';
import CrossCircle from 'icons/CrossCircle';
import {
  checkFormatDate,
  getDateFromInput,
  getMonthsRemaining,
  isDateIntheFuture,
} from 'utils/DateUtils';
import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import CloseModalConfirmation from 'components/modals/ConfirmationModals/CloseModalConfirmation';
import themeGlobal from 'styles/theme';
import { formatNumberByThousand } from 'utils/commun';
import { CREDIT_TYPE_LIST } from 'utils/constante';
import { v4 as uuidv4 } from 'uuid';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../messages';
import {
  StyledButtonContainer,
  StyledModalAddLoanButton,
  StyledModalContentWrapper,
  StyledModalMainTitle,
  StyledRadioButtonTitle,
  StyledExtraMarginSelectedButton,
  StyledAfterLabel,
  StyledDateInput,
  StyledDateContainer,
  StyledRadioButtonContainer,
  StyledInfoText,
  StyledBankComboInput,
} from '../style';

interface LoanModalProps {
  close: () => void;
  operationReducerDispatch: React.Dispatch<OperationAction>;
  currentLoanReducerDispatch: React.Dispatch<CurrentLoanAction>;
  currentLoanState: Loan;
  state: OperationState;
  isEdited: boolean;
}

const LoanModal: React.FC<LoanModalProps> = ({
  close,
  operationReducerDispatch,
  currentLoanReducerDispatch,
  currentLoanState,
  state,
  isEdited,
}) => {
  const [monthsRemainingOnLoan, setMonthsRemainingOnLoan] = useState<number>();
  const [isLoanDurationFocused, setIsLoanDurationFocused] = useState<boolean>(false);
  const [isLoanDurationValid, setIsLoanDurationValid] = useState<boolean>();
  const [loanDurationErrorMessage, setLoanDurationErrorMessage] = useState<string>();

  const [isBalanceFocused, setIsBalanceFocused] = useState<boolean>(false);
  const [isBalanceValid, setIsBalanceValid] = useState<boolean>();
  const [balanceErrorMessage, setBalanceErrorMessage] = useState<string>();

  const [isInitialCapitalFocused, setIsInitialCapitalFocused] = useState<boolean>(false);
  const [isInitialCapitalValid, setIsInitialCapitalValid] = useState<boolean>();
  const [initialCapitalErrorMessage, setInitialCapitalErrorMessage] = useState<string>();

  const [isMonthlyPaymentFocused, setIsMonthlyPaymentFocused] = useState<boolean>(false);
  const [isMonthlyPaymentValid, setIsMonthlyPaymentValid] = useState<boolean>();
  const [monthylPaymentErrorMessage, setMonthylPaymentErrorMessage] = useState<string>();

  const [initialCurrentLoan, setInitialCurrentLoan] = useState<Loan>();
  const [enableAddLoanButton, setEnableAddLoanButton] = useState<boolean>(false);

  const [statusDate, setStatusDate] = useState<StatusInput>();
  const [reloadStatusDate, setReloadStatusDate] = useState<boolean>(false);

  const [isCloseConfirmationVisible, setIsCloseConfirmationVisible] =
    useState<boolean>(false);

  const [banksReferential, setBanksReferential] = useState<Bank[]>([]);

  let currentLoan = { ...currentLoanState };

  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const CreditTypeList = CREDIT_TYPE_LIST;

  useEffect(() => {
    if (currentLoanState.isDataOk) {
      return setEnableAddLoanButton(true);
    }
    return setEnableAddLoanButton(false);
  }, [currentLoanState]);

  useEffect(() => {
    setIsLoanDurationValid(currentLoan.totalLoanDuration ? true : undefined);
    setIsBalanceValid(currentLoan.balance ? true : undefined);
    setIsInitialCapitalValid(currentLoan.initialLoanCapital ? true : undefined);
    setIsMonthlyPaymentValid(currentLoan.monthlyPayment ? true : undefined);

    setInitialCurrentLoan(currentLoan);
  }, []);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await getLoaners();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: Bank[] = [];
          Resultat.forEach(item => {
            const bank: Bank = {
              code: item.code,
              libelle: item.libelle,
              year: '',
            };
            temp.push(bank);
          });
          setBanksReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchBanks();
  }, []);

  const onCurrentLoanChange = () => {
    currentLoanReducerDispatch({
      type: 'setCurrentLoan',
      payload: currentLoan,
    });
  };

  const handleAddLoanButtonClick = (code: number) => {
    sessionStorage.removeItem('loanAdditionalInformation');

    const newState = { ...state };
    const existingLoan = newState.loanList.find(item => item.code === code);

    if (existingLoan) {
      for (let i = 0; i < newState.loanList.length; i += 1) {
        if (newState.loanList[i].code === existingLoan?.code) {
          newState.loanList[i] = saveOnlygoodLoanData(currentLoan);
        }
      }
    } else {
      currentLoan.code = uuidv4();
      onCurrentLoanChange();
      newState.loanList.push(saveOnlygoodLoanData(currentLoan));
    }

    operationReducerDispatch({
      type: 'setLoanList',
      payload: newState.loanList,
    });

    setMonthsRemainingOnLoan(undefined);

    currentLoan = {} as Loan;
    onCurrentLoanChange();
  };

  const checkIsDateValid = () => {
    if (!reloadStatusDate) return;

    setReloadStatusDate(false);
    if (!currentLoan.loanStartDate || currentLoan.loanStartDate === '') {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (
      getDateFromInput(currentLoan.loanStartDate).getFullYear() < 1900 ||
      !checkFormatDate(currentLoan.loanStartDate)
    ) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_INVALID,
      });
    } else if (!isDateIntheFuture(getDateFromInput(currentLoan.loanStartDate))) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_FUTUR,
      });
    } else {
      setStatusDate({
        status: 'valid',
        errorMessage: '',
      });
    }
  };

  const onDateBlur = () => {
    setStatusDate({
      status: 'none',
      errorMessage: '',
    });
    setReloadStatusDate(true);
  };

  const onLoanDurationBlur = () => {
    const loanDuration = currentLoan.totalLoanDuration
      ? currentLoan.totalLoanDuration.replace(/ /g, '')
      : '';
    const isRequired = currentLoan.buyBack || currentLoan.buyBack === undefined;

    if (!onlyNumbers.test(loanDuration)) {
      setIsLoanDurationValid(false);
      setLoanDurationErrorMessage(messagesCheckInput.ONLY_NUMBERS);
    } else if (Number(loanDuration) > 360) {
      setIsLoanDurationValid(false);
      setLoanDurationErrorMessage(messagesCheckInput.MODAL_CREDIT_LOAN_DURATION_TOO_LONG);
    } else if (isRequired) {
      if (!notEmpty.test(loanDuration)) {
        setIsLoanDurationValid(false);
        setLoanDurationErrorMessage(messagesCheckInput.REQUIRED_VALUE);
        return;
      }
      if (currentLoan.loanStartDate && currentLoan.totalLoanDuration) {
        const value =
          Number(currentLoan.totalLoanDuration) -
          getMonthsRemaining(getDateFromInput(currentLoan.loanStartDate), new Date());
        if (value <= 0) {
          setIsLoanDurationValid(false);
          setLoanDurationErrorMessage(
            messagesCheckInput.MODAL_CREDIT_LOAN_DURATION_UNDER_ZERO,
          );
          return;
        }
        setMonthsRemainingOnLoan(value);
      }
      setIsLoanDurationValid(true);
      setLoanDurationErrorMessage('');
    } else if (!notEmpty.test(loanDuration)) {
      setIsLoanDurationValid(undefined);
      setLoanDurationErrorMessage('');
    } else {
      setIsLoanDurationValid(true);
      setLoanDurationErrorMessage('');
    }
  };

  const onMonthylPaymentBlur = () => {
    const monthlyPayment = currentLoan.monthlyPayment
      ? currentLoan.monthlyPayment.replace(/ /g, '')
      : '';
    if (
      onlyNumbers.test(monthlyPayment) &&
      notEmpty.test(monthlyPayment) &&
      monthlyPayment !== '0'
    ) {
      currentLoan.monthlyPayment = monthlyPayment;
      onCurrentLoanChange();
      setIsMonthlyPaymentValid(true);
      setMonthylPaymentErrorMessage('');
    } else {
      currentLoan.monthlyPayment = undefined;
      onCurrentLoanChange();
      setMonthylPaymentErrorMessage(messagesCheckInput.INCORRECT_VALUE);
      setIsMonthlyPaymentValid(false);
    }
  };

  const initialCapitalIsRequired = () => {
    return currentLoan.buyBack || currentLoan.creditType?.code === 'R';
  };

  const onInitialCapitalBlur = () => {
    if (!currentLoan.initialLoanCapital || currentLoan.initialLoanCapital === '') {
      if (initialCapitalIsRequired()) {
        onCurrentLoanChange();
        setIsInitialCapitalValid(false);
        setInitialCapitalErrorMessage(messagesCheckInput.REQUIRED_VALUE);
      } else {
        onCurrentLoanChange();
        setIsInitialCapitalValid(undefined);
        setInitialCapitalErrorMessage('');
      }
      return;
    }
    const initialCapital = currentLoan.initialLoanCapital
      ? currentLoan.initialLoanCapital.replace(/ /g, '')
      : '';

    if (!onlyNumbers.test(initialCapital)) {
      currentLoan.initialLoanCapital = undefined;
      onCurrentLoanChange();
      setIsInitialCapitalValid(undefined);
      setInitialCapitalErrorMessage('');
      return;
    }

    if (
      (initialCapitalIsRequired() ||
        !(!currentLoan.initialLoanCapital || currentLoan.initialLoanCapital === '')) &&
      Number(currentLoan.initialLoanCapital) < Number(currentLoan.balance)
    ) {
      currentLoan.initialLoanCapital = initialCapital;
      onCurrentLoanChange();
      setIsInitialCapitalValid(false);
      setInitialCapitalErrorMessage(
        messagesCheckInput.MODAL_CREDIT_INITAL_CAPITAL_INFERIOR_BALANCE,
      );
    } else {
      currentLoan.initialLoanCapital = initialCapital;
      onCurrentLoanChange();
      setIsInitialCapitalValid(true);
      setInitialCapitalErrorMessage('');
    }
  };

  const onBalanceBlur = () => {
    const balance =
      currentLoan.balance !== undefined ? currentLoan.balance.replace(/ /g, '') : '';
    if (onlyNumbers.test(balance) && notEmpty.test(balance)) {
      currentLoan.balance = balance;
      onCurrentLoanChange();
      setIsBalanceValid(true);
      setBalanceErrorMessage('');
    } else {
      currentLoan.balance = undefined;
      onCurrentLoanChange();
      setIsBalanceValid(false);
      setBalanceErrorMessage(messagesCheckInput.INCORRECT_VALUE);
    }
    if (currentLoan.initialLoanCapital) {
      onInitialCapitalBlur();
    }
  };

  useEffect(() => {
    if (currentLoan.loanStartDate && currentLoan.totalLoanDuration) {
      setMonthsRemainingOnLoan(
        Number(currentLoan.totalLoanDuration) -
          getMonthsRemaining(getDateFromInput(currentLoan.loanStartDate), new Date()),
      );
      onLoanDurationBlur();
    } else setMonthsRemainingOnLoan(undefined);
  }, [currentLoan.loanStartDate, currentLoan.totalLoanDuration]);

  useEffect(() => {
    checkIsDateValid();
  }, [reloadStatusDate]);

  useEffect(() => {
    if (
      currentLoan.buyBack === false ||
      (currentLoan.buyBack === true && currentLoan.totalLoanDuration)
    )
      onLoanDurationBlur();
  }, [currentLoan.buyBack]);

  useEffect(() => {
    if (currentLoan.loanStartDate) onDateBlur();
    if (currentLoan.monthlyPayment) onMonthylPaymentBlur();
    if (currentLoan.totalLoanDuration) onLoanDurationBlur();
    if (currentLoan.balance) onBalanceBlur();
    if (currentLoan.initialLoanCapital) onInitialCapitalBlur();
  }, []);

  const renderLoanStartDate = () => {
    return (
      <>
        <StyledDateInput
          label="Date de début du prêt"
          class="identity-form-input birthdate-input"
          value={currentLoan.loanStartDate}
          onInputChange={e => {
            currentLoan.loanStartDate = (e.target as HTMLInputElement).value;
            onCurrentLoanChange();
          }}
          onBlur={onDateBlur}
          {...(statusDate?.status !== 'none' ? { status: statusDate?.status } : {})}
          {...(statusDate?.errorMessage !== ''
            ? { 'error-message': statusDate?.errorMessage }
            : {})}
        />
      </>
    );
  };

  const renderTotalLoanDuration = () => {
    return (
      <>
        <CustomInput
          name="duree-pret"
          label={`Durée totale du prêt${
            currentLoan.buyBack || currentLoan.buyBack === undefined
              ? ''
              : ' (facultatif)'
          }`}
          type="price"
          value={currentLoan.totalLoanDuration}
          onFocus={() => {
            setIsLoanDurationFocused(true);
          }}
          onChange={newValue => {
            if (onlyNumbers.test(newValue))
              currentLoan.totalLoanDuration = newValue.replaceAll(' ', '').slice(0, 3);
            onCurrentLoanChange();
          }}
          onBlur={() => {
            onLoanDurationBlur();
            setIsLoanDurationFocused(false);
          }}
          isValid={isLoanDurationValid}
          error={loanDurationErrorMessage}
          placeholder="0"
          isFocused={isLoanDurationFocused}
          after="mois"
          inputWidth="46.4rem"
        />
        {monthsRemainingOnLoan &&
        monthsRemainingOnLoan > 0 &&
        monthsRemainingOnLoan <= 360 &&
        (currentLoan.buyBack || currentLoan.buyBack === undefined) ? (
          <StyledAfterLabel>{`Durée restante : ${monthsRemainingOnLoan} mois`}</StyledAfterLabel>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderBalance = () => {
    return (
      <>
        <CustomInput
          name="solde"
          label="Solde"
          type="price"
          value={currentLoan.balance ? formatNumberByThousand(currentLoan.balance) : ''}
          onFocus={() => {
            setIsBalanceFocused(true);
          }}
          onChange={newValue => {
            const value = newValue.replaceAll(' ', '').slice(0, 8);
            if (onlyNumbers.test(value)) currentLoan.balance = value;
            onCurrentLoanChange();
          }}
          onBlur={() => {
            onBalanceBlur();
            setIsBalanceFocused(false);
          }}
          placeholder="0"
          isFocused={isBalanceFocused}
          isValid={isBalanceValid}
          error={balanceErrorMessage}
          after="€"
          inputWidth="46.4rem"
        />
        <StyledInfoText>{messages.MODAL_CREDIT_BALANCE_INFO}</StyledInfoText>

        <Space marginBottom={themeGlobal.layout.spacing.spacing_m} />
      </>
    );
  };

  const renderInitialCapital = () => {
    return (
      <>
        <CustomInput
          name="capital-initial"
          label={`Capital initial du prêt${
            currentLoan?.creditType?.code !== 'R' && currentLoan?.buyBack === false
              ? ' (facultatif)'
              : ''
          } `}
          type="price"
          value={
            currentLoan?.initialLoanCapital?.replace(
              /[0-9](?=(?:[0-9]{3})+(?![0-9]))/g,
              '$& ',
            ) || ''
          }
          onFocus={() => {
            setIsInitialCapitalFocused(true);
          }}
          onChange={newValue => {
            const value = newValue.replaceAll(' ', '').slice(0, 8);
            if (onlyNumbers.test(value)) currentLoan.initialLoanCapital = value;
            onCurrentLoanChange();
          }}
          onBlur={() => {
            onInitialCapitalBlur();
            setIsInitialCapitalFocused(false);
          }}
          placeholder="0"
          isFocused={isInitialCapitalFocused}
          isValid={isInitialCapitalValid}
          error={initialCapitalErrorMessage}
          after="€"
          inputWidth="46.4rem"
        />

        <Space marginBottom={themeGlobal.layout.spacing.spacing_m} />
      </>
    );
  };

  const renderRestructure = () => {
    return (
      <>
        <div>
          <StyledRadioButtonTitle>
            {messages.MODAL_CREDIT_RESTRUCTURE_TITLE}
          </StyledRadioButtonTitle>
          <StyledRadioButtonContainer>
            <BaseRadioButton
              id="restructuration-true"
              checked={currentLoan.restructure ? true : undefined}
              onClick={() => {
                currentLoan.restructure = true;
                onCurrentLoanChange();
              }}
            />
            <span>{messages.MODAL_RADIO_BUTTON_TRUE}</span>
            <BaseRadioButton
              id="restructuration-false"
              checked={currentLoan.restructure === false ? true : undefined}
              onClick={() => {
                currentLoan.restructure = false;
                onCurrentLoanChange();
              }}
            />
            <span>{messages.MODAL_RADIO_BUTTON_FALSE}</span>
          </StyledRadioButtonContainer>
        </div>
      </>
    );
  };

  const dataHasBeenModified = (): boolean => {
    return JSON.stringify(currentLoan) !== JSON.stringify(initialCurrentLoan);
  };

  const renderMortgage = () => {
    return (
      <>
        <Space marginBottom="2.4rem" />

        <div>
          <StyledRadioButtonTitle>
            {messages.MODAL_CREDIT_MORTGAGE_TITLE}
          </StyledRadioButtonTitle>
          <StyledRadioButtonContainer>
            <BaseRadioButton
              id="inscrit-true"
              checked={currentLoan.mortgage ? true : undefined}
              onClick={() => {
                currentLoan.mortgage = true;
                onCurrentLoanChange();
              }}
            />
            <span>{messages.MODAL_RADIO_BUTTON_TRUE}</span>
            <BaseRadioButton
              id="inscrit-false"
              checked={currentLoan.mortgage === false ? true : undefined}
              onClick={() => {
                currentLoan.mortgage = false;
                onCurrentLoanChange();
              }}
            />
            <span>{messages.MODAL_RADIO_BUTTON_FALSE}</span>
          </StyledRadioButtonContainer>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        isVisible
        onClose={() => {
          setIsCloseConfirmationVisible(true);
        }}>
        <StyledModalContentWrapper>
          <StyledModalMainTitle>{messages.MODAL_MAIN_TITLE}</StyledModalMainTitle>
          <div className="element-in-modal" role="button" tabIndex={0}>
            {' '}
          </div>

          <StyledRadioButtonTitle>
            {messages.MODAL_RADIO_BUTTONS_TITLE}
          </StyledRadioButtonTitle>
          <StyledRadioButtonContainer>
            <BaseRadioButton
              id="buyback-true"
              checked={currentLoan.buyBack ? true : undefined}
              onClick={() => {
                currentLoan.buyBack = true;
                onCurrentLoanChange();
              }}
            />
            <span>{messages.MODAL_RADIO_BUTTON_TRUE}</span>
            <BaseRadioButton
              id="buyback-false"
              checked={currentLoan.buyBack === false ? true : undefined}
              onClick={() => {
                currentLoan.buyBack = false;
                onCurrentLoanChange();
              }}
            />
            <span>{messages.MODAL_RADIO_BUTTON_FALSE}</span>
          </StyledRadioButtonContainer>

          <Space marginBottom="2.4rem" />
          <SelectElementInput
            class="credit-type"
            label={messages.MODAL_CREDIT_TYPE}
            data-id="credit_type"
            error-message={messagesCheckInput.MODAL_CREDIT_NOT_SELECTED}
            value={currentLoan.creditType?.code}
            onSelectedElementChange={e => {
              currentLoan.creditType = CreditTypeList.get(
                (e.target as HTMLSelectElement).value,
              );
              onCurrentLoanChange();
            }}>
            <StyledExtraMarginSelectedButton
              tabIndex={0}
              value="C"
              secondary
              className="civility-select"
              onKeyDownCapture={e => {
                if (
                  e.code === 'Enter' ||
                  e.code === 'Space' ||
                  e.code === 'NumpadEnter'
                ) {
                  e.preventDefault();
                  currentLoan.creditType = CreditTypeList.get(
                    (e.target as HTMLSelectElement).value,
                  );
                  onCurrentLoanChange();
                }
              }}>
              {messages.MODAL_CREDIT_TYPE_1}
            </StyledExtraMarginSelectedButton>
            <StyledExtraMarginSelectedButton
              tabIndex={0}
              value="I"
              secondary
              className="civility-select"
              onKeyDownCapture={e => {
                if (
                  e.code === 'Enter' ||
                  e.code === 'Space' ||
                  e.code === 'NumpadEnter'
                ) {
                  e.preventDefault();
                  currentLoan.creditType = CreditTypeList.get(
                    (e.target as HTMLSelectElement).value,
                  );
                  onCurrentLoanChange();
                }
              }}>
              {messages.MODAL_CREDIT_TYPE_2}
            </StyledExtraMarginSelectedButton>
            <SelectedButton
              tabIndex={0}
              value="R"
              secondary
              onKeyDownCapture={e => {
                if (
                  e.code === 'Enter' ||
                  e.code === 'Space' ||
                  e.code === 'NumpadEnter'
                ) {
                  e.preventDefault();
                  currentLoan.creditType = CreditTypeList.get(
                    (e.target as HTMLSelectElement).value,
                  );
                  onCurrentLoanChange();
                }
              }}>
              {messages.MODAL_CREDIT_TYPE_3}
            </SelectedButton>
          </SelectElementInput>

          <Space marginBottom="2.4rem" />

          <StyledBankComboInput>
            <ComboInput
              list-on-open
              required
              class="combo-input"
              shadow
              align-items="left"
              items={JSON.stringify(banksReferential)}
              onInputChange={e => {
                const libelle = (e.target as HTMLInputElement).value;
                currentLoan.loaner = banksReferential.find(bank => {
                  return bank.libelle === libelle;
                });
                onCurrentLoanChange();
              }}
              field="libelle"
              label="Organisme de crédit"
              placeholder="Choisir un organisme de crédit"
              value={currentLoan.loaner?.libelle}
            />
          </StyledBankComboInput>

          <StyledDateContainer>
            {currentLoan.creditType?.code !== 'R' && currentLoan.buyBack !== false ? (
              renderLoanStartDate()
            ) : (
              <></>
            )}
          </StyledDateContainer>

          {currentLoan.creditType?.code !== 'R' && currentLoan.buyBack !== false ? (
            <Space marginBottom="2.4rem" />
          ) : (
            <></>
          )}

          <CustomInput
            name="mensualite-pret"
            label="Mensualité du prêt"
            type="price"
            value={formatNumberByThousand(currentLoan.monthlyPayment || '')}
            onFocus={() => {
              setIsMonthlyPaymentFocused(true);
            }}
            onChange={newValue => {
              const value = newValue.replaceAll(' ', '').slice(0, 4);
              if (onlyNumbers.test(value)) currentLoan.monthlyPayment = value;
              onCurrentLoanChange();
            }}
            onBlur={() => {
              onMonthylPaymentBlur();
              setIsMonthlyPaymentFocused(false);
            }}
            placeholder="0"
            error={monthylPaymentErrorMessage}
            isFocused={isMonthlyPaymentFocused}
            isValid={isMonthlyPaymentValid}
            after="€ / mois"
            inputWidth="46.4rem"
          />

          <Space marginBottom="2.4rem" />

          {(currentLoan.creditType?.code !== 'R' || !currentLoan?.buyBack) &&
            renderTotalLoanDuration()}

          <Space marginBottom={themeGlobal.layout.spacing.spacing_m} />

          {renderBalance()}

          {renderInitialCapital()}

          {currentLoan.buyBack !== false && currentLoan.creditType?.code !== 'R' ? (
            <>
              {renderRestructure()}
              {currentLoan.creditType?.code !== 'C' ||
              currentLoan.restructure !== false ? (
                renderMortgage()
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {currentLoan.buyBack === false &&
            currentLoan.creditType?.code === 'I' &&
            renderMortgage()}

          <Space marginBottom="64px" />

          <StyledButtonContainer>
            {!enableAddLoanButton ? (
              <StyledModalAddLoanButton
                disabled
                className="selection-btn"
                onClick={close}>
                {!isEdited && <PlusCircleIcon color="#ffffff" />}
                <span style={{ marginLeft: '1rem' }}>
                  {isEdited ? messages.MODAL_MODIFY_BUTTON : messages.MODAL_ADD_LOAN}
                </span>
              </StyledModalAddLoanButton>
            ) : (
              <StyledModalAddLoanButton
                className="selection-btn"
                tabIndex={0}
                onKeyDownCapture={event => {
                  if (
                    event.key === 'Enter' ||
                    event.key === 'Space' ||
                    event.code === 'NumpadEnter'
                  ) {
                    close();
                    handleAddLoanButtonClick(currentLoan.code);
                  }
                }}
                onClick={() => {
                  close();
                  handleAddLoanButtonClick(currentLoan.code);
                }}>
                {!isEdited && <PlusCircleIcon color="#ffffff" />}
                <span style={{ marginLeft: '1rem' }}>
                  {isEdited ? messages.MODAL_MODIFY_BUTTON : messages.MODAL_ADD_LOAN}
                </span>
              </StyledModalAddLoanButton>
            )}

            <TextButton
              label={messages.MODAL_CLOSE}
              onClick={() => {
                if (dataHasBeenModified()) {
                  setIsCloseConfirmationVisible(true);
                } else {
                  currentLoan = {} as Loan;
                  setInitialCapitalErrorMessage('');
                  onCurrentLoanChange();
                  close();
                }
              }}>
              <CrossCircle color="rgb(40, 136, 60)" />
            </TextButton>
          </StyledButtonContainer>

          <CloseModalConfirmation
            isVisible={isCloseConfirmationVisible}
            accept={() => {
              sessionStorage.removeItem('loanAdditionalInformation');
              setMonthsRemainingOnLoan(undefined);
              currentLoan = {} as Loan;
              setInitialCapitalErrorMessage('');
              onCurrentLoanChange();

              setIsCloseConfirmationVisible(false);
              close();
            }}
            close={() => {
              (
                document.getElementsByClassName('element-in-modal')[0] as HTMLElement
              ).focus();
              setIsCloseConfirmationVisible(false);
            }}
          />
        </StyledModalContentWrapper>
      </Modal>
    </>
  );
};

export default LoanModal;
