import { getReferential } from 'api/referentialService';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { ValidityHtmlElement } from 'components/designSystem/DesignSystemTypes';
import { useEffect, useState } from 'react';
import {
  GuaranteeAdditionalData,
  GuaranteeAdditionalInformationAction,
  GuaranteeAdditionalInformationState,
} from 'reducers/guaranteeAdditionalInformation/type';

import { CodeLibelle } from 'types';

interface EvaluationTypeProps {
  currentGuarantee?: GuaranteeAdditionalData;
  state: GuaranteeAdditionalInformationState;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
}

const EvaluationType: React.FC<EvaluationTypeProps> = ({
  currentGuarantee,
  state,
  dispatch,
}) => {
  const inputId = 'evaluation-type';
  const [evaluationTypeInput, setEvaluationTypeInput] = useState<HTMLElement | null>();
  const [value, setValue] = useState<CodeLibelle | undefined>(
    currentGuarantee?.evaluationType,
  );
  const [loanObjects, setLoanObjects] = useState<CodeLibelle[]>();

  useEffect(() => {
    setEvaluationTypeInput(document.getElementById(inputId));
    getReferential('typeEvaluation').then(typeEvaluation => {
      setLoanObjects(typeEvaluation.Resultat);
    });
  }, []);

  const displayInputError = (errorMessage: string) => {
    if (evaluationTypeInput) {
      (evaluationTypeInput as unknown as ValidityHtmlElement).valid = false;
      (evaluationTypeInput as unknown as ValidityHtmlElement).invalid = true;
      (evaluationTypeInput as unknown as ValidityHtmlElement).errorMessage = errorMessage;
    }
  };
  const updateState = (val?: CodeLibelle) => {
    const data = state.guarantees.map(item => {
      if (item.code === currentGuarantee?.code) {
        return { ...item, evaluationType: val };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateGuaranteesInformation',
      payload: { data, guaranteeCode: currentGuarantee?.code },
    });
  };
  const onTypeSelect = (e: Event) => {
    const selectedType = (e as CustomEvent).detail.value as CodeLibelle;
    setValue(selectedType);
  };
  const onTypeBlur = (event: Event) => {
    if (event && event.target && (event.target as unknown as ComboInputDS).value === '') {
      displayInputError(`Le type d'évaluation est obligatoire`);
      updateState(undefined);
    } else {
      updateState(value);
    }
  };

  return (
    <>
      <ComboInput
        required
        id="evaluation-type"
        list-on-open
        class="combo-finalisation-projet"
        shadow
        align-items="left"
        items={JSON.stringify(loanObjects)}
        field="libelle"
        label="Type d'évaluation"
        placeholder="Choisir le type d'évaluation"
        onSelectedList={e => {
          onTypeSelect(e);
        }}
        onListBlur={e => {
          onTypeBlur(e);
        }}
        value={
          currentGuarantee?.evaluationType?.libelle
            ? currentGuarantee.evaluationType.libelle
            : ''
        }
      />
    </>
  );
};

export default EvaluationType;
