import {
  StyledActiveNavBarText,
  StyledInactiveNavBarText,
  StyledNavBarTile,
  StyledSelectedNavBarText,
  StyledSelectedNavBarTile,
} from '../style';

interface MainNavBarTileProps {
  label: string;
  isSelected: boolean;
  isEnabled: boolean;
  onclick: () => void;
}

const MainNavBarTile: React.FC<MainNavBarTileProps> = ({
  label,
  isSelected,
  isEnabled,
  onclick,
}) => {
  const renderEnabled = () => {
    return (
      <>
        {isSelected ? (
          <StyledSelectedNavBarTile
            style={{ cursor: 'pointer' }}
            onKeyPress={onclick}
            onClick={onclick}
            role="button"
            tabIndex={0}>
            <StyledSelectedNavBarText>{label}</StyledSelectedNavBarText>
          </StyledSelectedNavBarTile>
        ) : (
          <StyledNavBarTile
            style={{ cursor: 'pointer' }}
            onKeyPress={onclick}
            onClick={onclick}
            role="button"
            tabIndex={0}>
            <StyledActiveNavBarText>{label}</StyledActiveNavBarText>
          </StyledNavBarTile>
        )}
      </>
    );
  };

  return isEnabled ? (
    renderEnabled()
  ) : (
    <>
      <StyledNavBarTile>
        <StyledInactiveNavBarText>{label}</StyledInactiveNavBarText>
      </StyledNavBarTile>
    </>
  );
};

export default MainNavBarTile;
