import BreadCrumbLevel1 from 'components/breadcrumb/BreadCrumbLevel1';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import themeGlobal from 'styles/theme';
import colors from 'styles/color';
import Profil from 'containers/profil/Profil';
import Summary, { SummaryBlock } from 'containers/summary/Summary';
import { LoanInformation } from 'types';
import { OperationState } from 'reducers/operationClient/types';
import SideBar from 'components/sideBar/SideBar';
import { StyledMargin } from './style';

/**
 * Higher Order Component utilisé pour injecter certaines fonctionnalités (BrowserRouter, ThemeProvider, etc.)
 * dans les composants de tests
 * @param WrappedComponent composant.
 * @returns
 */
export const withCommunComponents = (WrappedComponent: React.ReactNode): ReactElement => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={themeGlobal}>{WrappedComponent}</ThemeProvider>
    </BrowserRouter>
  );
};

/**
 * Higher order component (HOC) utilisé pour injecter le logo, le filigrane, et rajouter des marges (gauche et droite) dans la page
 * @param WrappedComponent composant engloblé par le HOC
 * @param currentStep étape courante pour le filigrane
 * @returns
 */
export const wrapContentWithBreadcrumb = (
  WrappedComponent: React.ReactNode,
): ReactNode => {
  const WithBreadcrumb = () => {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    useEffect(() => {
      const currentPath = window.location.pathname;
      if (currentPath === '/donnees_client') {
        setCurrentStepIndex(1);
      } else if (currentPath === '/operation_client') {
        setCurrentStepIndex(2);
      } else if (currentPath === '/conditions_financieres') {
        setCurrentStepIndex(3);
      } else if (currentPath === '/resultat') {
        setCurrentStepIndex(3);
      } else if (currentPath === '/finalisation_projet') {
        setCurrentStepIndex(4);
      } else if (currentPath === '/transmission_pieces') {
        setCurrentStepIndex(5);
      } else if (currentPath === '/feedback') {
        setCurrentStepIndex(6);
      } else {
        setCurrentStepIndex(0);
      }
    }, [window.location.pathname]);

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img
            style={{ height: '10rem', width: '23rem', margin: '1.6rem' }}
            src={`${process.env.PUBLIC_URL}/logo_sygma.jpg`}
            alt="logo"
          />
          <Profil />
        </div>
        <StyledMargin>
          <BreadCrumbLevel1
            steps={[
              'Données client',
              'Opération client',
              'Conditions financières',
              'Finalisation du projet',
              'Transmission des pièces',
            ]}
            currentStep={currentStepIndex}
          />
          {WrappedComponent}
        </StyledMargin>
      </>
    );
  };
  return <WithBreadcrumb />;
};

export const wrapContentWithoutBreadcrumb = (
  componentToWrap: React.ReactNode,
  title?: string,
): ReactElement => {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <img
          style={{ height: '10rem', width: '23rem' }}
          src={`${process.env.PUBLIC_URL}/logo_sygma.jpg`}
          alt="logo"
        />
        {title ? (
          <h2
            style={{
              color: colors.chartColors.mainGreen500,
              width: '100%',
              textAlign: 'center',
            }}>
            {title}
          </h2>
        ) : null}
      </div>
      <div
        style={{
          marginTop: '2rem',
        }}>
        {componentToWrap}
      </div>
    </div>
  );
};

export const withSummaryCard = (
  step: SummaryBlock,
  componentToWrap: React.ReactNode,
  data?: {
    loanInformation?: LoanInformation;
    hideTotalFinancement?: boolean;
    operationsClientState?: OperationState;
  },
): ReactElement => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>{componentToWrap}</div>
      <Summary step={step} data={data} />
    </div>
  );
};

export const withSideBar = (componentToWrap: React.ReactNode): ReactElement => {
  return <SideBar>{componentToWrap}</SideBar>;
};

export const withProfile = (componentToWrap: React.ReactNode): ReactElement => {
  return (
    <div style={{ display: 'flex' }}>
      {componentToWrap}
      <Profil />
    </div>
  );
};
