import { Bordereau, SortEnum } from 'types';
import { filterBordereauList, sortBordereaux, updateBordereauFilters } from './utils';

export type BordereauKeys = 'perComi' | 'nomCourtier' | 'typeBordereau';

export const bordereauReducerInitialState = {
  bordereauCurrentOrdering: [],
  displayedBordereaux: [],
  nbBordereauxPerPage: 25,
  sortBordereauxByName: SortEnum.ASC,
  filterBordereauxByName: '',
  sortBordereauxByPerComi: SortEnum.ASC,
  filterBordereauxByPerComi: '',
  sortBordereauxByType: SortEnum.ASC,
  filterBordereauxByType: [],
  selectedBordereauFilter: '',
  bordereauCurrentSortingKey: undefined,
};

export interface BordereauState {
  bordereauCurrentOrdering: Bordereau[];
  displayedBordereaux: Bordereau[];
  nbBordereauxPerPage: number;
  sortBordereauxByName: SortEnum;
  filterBordereauxByName: string;
  sortBordereauxByPerComi: SortEnum;
  filterBordereauxByPerComi: string;
  sortBordereauxByType: SortEnum;
  filterBordereauxByType: string[];
  selectedBordereauFilter: string;
  bordereauCurrentSortingKey?: BordereauKeys;
}

interface SetBordereauCurrentOrdering {
  type: 'setBordereauCurrentOrdering';
  payload: Bordereau[];
}
interface SetDisplayedBordereaux {
  type: 'setDisplayedBordereaux';
  payload: Bordereau[];
}
interface SetNbBordereauxPerPage {
  type: 'setNbBordereauxPerPage';
  payload: number;
}
interface SetSortBordereauxByName {
  type: 'setSortBordereauxByName';
  payload: SortEnum;
}
interface SetFilterBordereauxByName {
  type: 'setFilterBordereauxByName';
  payload: string;
}
interface SetSortBordereauxByPerComi {
  type: 'setSortBordereauxByPerComi';
  payload: SortEnum;
}
interface SetFilterBordereauxByPerComi {
  type: 'setFilterBordereauxByPerComi';
  payload: string;
}
interface SetSortBordereauxByType {
  type: 'setSortBordereauxByType';
  payload: SortEnum;
}
interface SetFilterBordereauxByType {
  type: 'setFilterBordereauxByType';
  payload: string[];
}
interface SetSelectedBordereauFilter {
  type: 'setSelectedBordereauFilter';
  payload: string;
}
interface SetBordereauCurrentSortingKey {
  type: 'setBordereauCurrentSortingKey';
  payload: BordereauKeys;
}
interface SortDisplayedBordereaux {
  type: 'sortDisplayedBordereaux';
  payload: BordereauKeys;
}
interface ResetBordereauState {
  type: 'resetBordereauState';
}
interface SetInitialBordereauList {
  type: 'setInitialBordereauList';
  payload: Bordereau[];
}
interface FilterBordereauxList {
  type: 'filterBordereauxList';
}
export interface UpdateBordereauFiltersPayload {
  text: string;
  filteringKey: BordereauKeys;
}
interface UpdateFilters {
  type: 'updateFilters';
  payload: UpdateBordereauFiltersPayload;
}
interface ResetBordereauState {
  type: 'resetBordereauState';
}

type BordereaAction =
  | SetBordereauCurrentOrdering
  | SetDisplayedBordereaux
  | SetNbBordereauxPerPage
  | SetSortBordereauxByName
  | SetFilterBordereauxByName
  | SetSortBordereauxByPerComi
  | SetFilterBordereauxByPerComi
  | SetSortBordereauxByType
  | SetFilterBordereauxByType
  | SetSelectedBordereauFilter
  | SetBordereauCurrentSortingKey
  | SortDisplayedBordereaux
  | ResetBordereauState
  | SetInitialBordereauList
  | FilterBordereauxList
  | UpdateFilters
  | ResetBordereauState;

export const bordereauReducer = (
  state: BordereauState,
  action: BordereaAction,
): BordereauState => {
  switch (action.type) {
    case 'setBordereauCurrentOrdering':
      return { ...state, bordereauCurrentOrdering: action.payload };
    case 'setDisplayedBordereaux':
      return { ...state, displayedBordereaux: action.payload };
    case 'setNbBordereauxPerPage':
      return { ...state, nbBordereauxPerPage: action.payload };
    case 'setSortBordereauxByName':
      return { ...state, sortBordereauxByName: action.payload };
    case 'setFilterBordereauxByName':
      return { ...state, filterBordereauxByName: action.payload };
    case 'setSortBordereauxByPerComi':
      return { ...state, sortBordereauxByPerComi: action.payload };
    case 'setFilterBordereauxByPerComi':
      return { ...state, filterBordereauxByPerComi: action.payload };
    case 'setSortBordereauxByType':
      return { ...state, sortBordereauxByType: action.payload };
    case 'setFilterBordereauxByType':
      return { ...state, filterBordereauxByType: action.payload };
    case 'setSelectedBordereauFilter':
      return { ...state, selectedBordereauFilter: action.payload };
    case 'setBordereauCurrentSortingKey':
      return { ...state, bordereauCurrentSortingKey: action.payload };
    case 'setInitialBordereauList':
      return {
        ...state,
        bordereauCurrentOrdering: action.payload,
        displayedBordereaux: action.payload,
      };
    case 'filterBordereauxList':
      return filterBordereauList(state);
    case 'updateFilters':
      return updateBordereauFilters(state, action.payload);
    case 'resetBordereauState':
      return {
        ...bordereauReducerInitialState,
      };
    case 'sortDisplayedBordereaux':
      return sortBordereaux(state, action.payload);
    default:
      return state;
  }
};
