import { useNavigate } from 'react-router';
import FinalisationProjetForm from 'containers/finalisationProjet/FinalisationProjet';

const FinalisationProjet: React.FC = () => {
  const navigate = useNavigate();
  return (
    <FinalisationProjetForm
      onNextButtonClick={() => {
        navigate('/transmission_pieces');
      }}
      onBackButtonClick={() => {
        navigate('/resultat');
      }}
    />
  );
};

export default FinalisationProjet;
