import axios from 'axios';
import {
  GetUploadUrlApiResponse,
  GetUploadUrlApiRequestParams,
  UploadFileApiRequestBody,
  UploadFileApiResponse,
  TransfertAllApiResponse,
} from 'types';
import {
  AccesEspaceESignatureResp,
  PackContractuelReq,
  PackContractuelResp,
  RecupererDernierDocumentPackResp,
  SuiviESignatureResp,
} from 'types/packContractuelDtos';
import { transfertDocumentApiInstance, transfertOctroiInstance } from './axiosInstances';

export const getUploadUrl = (
  params: GetUploadUrlApiRequestParams,
): Promise<GetUploadUrlApiResponse> => {
  return transfertDocumentApiInstance
    .get(
      `/Session/Token?IdProjet=${params.IdProjet}&AdheNum=${params.AdheNum}&ContextApp=${params.ContextApp}`,
    )
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting the upload url. Reason: ${err}`;
      throw Error(message);
    });
};

export const uploadFile = (
  urlToUploadFile: string,
  body: UploadFileApiRequestBody,
): Promise<UploadFileApiResponse> => {
  const formData = new FormData();
  formData.append('fileContent', body.fileContent, body.fileName);
  formData.append('idChemise', body.chemiseId);
  formData.append('fileName', body.fileName);
  return axios
    .post(urlToUploadFile, formData, {
      headers: {
        'Content-Type': undefined,
        Accept: 'application/json',
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error sending file. Reason: ${err}`;
      throw Error(message);
    });
};

export const transfertAll = (
  urlToTransfert: string,
): Promise<TransfertAllApiResponse> => {
  return axios
    .get(urlToTransfert)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error transferring all files. Reason: ${err}`;
      throw Error(message);
    });
};

export const editPackContractuel = (
  request: PackContractuelReq,
): Promise<PackContractuelResp> => {
  return transfertOctroiInstance
    .post('/editPackContractuel', request)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error editing pack contractuel. Reason: ${err}`;
      throw Error(message);
    });
};

export const getSuiviESignature = (
  userId: string,
  numeroDossier: number,
): Promise<SuiviESignatureResp> => {
  return transfertOctroiInstance
    .get(`/suiviAvancementParcoursDemat?userId=${userId}&numeroProjet=${numeroDossier}`)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting the upload url. Reason: ${err}`;
      throw Error(message);
    });
};

export const getAccesEspaceESignature = (
  userId: string,
  identifiantEmprunteur: number,
  identifiantCoEmprunteur: number,
  numeroProjet: number,
  numeroContrat: number,
): Promise<AccesEspaceESignatureResp> => {
  const request = {
    userId,
    identifiantEmprunteur,
    identifiantCoEmprunteur,
    numeroProjet,
    numeroContrat,
  };
  return transfertOctroiInstance
    .post('/accesEspaceESignature ', request)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting espace e-signature url. Reason: ${err}`;
      throw Error(message);
    });
};

export const getRecupererDernierDocumentPack = (
  referenceCourrier: string,
  userId: string,
  numeroDossier?: number,
): Promise<RecupererDernierDocumentPackResp> => {
  return transfertOctroiInstance
    .get(
      `/recupererDernierDocumentPack?${
        referenceCourrier ? `referenceCourrier=${referenceCourrier}` : ''
      }&userId=${userId}&numeroProjet=${numeroDossier}`,
    )
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting the upload url. Reason: ${err}`;
      throw Error(message);
    });
};
