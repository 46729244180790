import Space from 'components/space/Space';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import { formatDisplayedFirstname } from 'utils/commun';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledMainDisplayCard,
} from 'containers/consultation/style';
import { IdentityState } from 'reducers/identity/types';
import * as messages from '../../messages';

interface IdentiteClientConsultationProps {
  isProjectEditable: boolean | undefined;
  borrowerState: IdentityState | undefined;
  coBorrowerState?: IdentityState | undefined;
}

const IdentiteClientConsultation: React.FC<IdentiteClientConsultationProps> = ({
  isProjectEditable,
  borrowerState,
  coBorrowerState,
}) => {
  const renderBorrowerInformation = (
    stakeholder: 'borrower' | 'coborrower',
    state: IdentityState,
  ) => {
    return (
      <>
        <StyledCardTitle>
          {stakeholder === 'borrower'
            ? messages.MAIN_CARD_BORROWER_TITLE
            : messages.MAIN_CARD_COBORROWER_TITLE}
        </StyledCardTitle>
        <Space marginBottom="1.6rem" />
        {state && (
          <>
            <StyledCardRow style={{ gap: '3.6rem', flexWrap: 'wrap' }}>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_CIVILITY}</StyledCardLabel>
                <StyledCardData>
                  {state.civility === 'MME' ? 'Madame' : 'Monsieur'}
                </StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_LAST_NAME}</StyledCardLabel>
                <StyledCardData>{state.lastName}</StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_FIRST_NAME}</StyledCardLabel>
                <StyledCardData>
                  {formatDisplayedFirstname(state.firstName || '')}
                </StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_BIRTH_DATE}</StyledCardLabel>
                <StyledCardData>{state.birthDate}</StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_BIRTH_COUNTRY}
                </StyledCardLabel>
                <StyledCardData>{state.birthCountry?.libelle}</StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_BIRTH_DEPARTMENT}
                </StyledCardLabel>
                <StyledCardData>{state.birthDepartment?.libelle}</StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_BIRTH_CITY}</StyledCardLabel>
                <StyledCardData>{state.birthCity?.libelle}</StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_NATIONALITY}</StyledCardLabel>
                <StyledCardData>{state.nationality?.libelle}</StyledCardData>
              </StyledCardTile>
            </StyledCardRow>
          </>
        )}
      </>
    );
  };

  return (
    <StyledMainDisplayCard>
      {borrowerState && renderBorrowerInformation('borrower', borrowerState)}

      {coBorrowerState && (
        <>
          <Space marginBottom="4rem" />
          {renderBorrowerInformation('coborrower', coBorrowerState)}
        </>
      )}

      <ConsultationEditButton
        isProjectEditable={isProjectEditable}
        navigateRoute="/donnees_client"
        navigateDonneesClientStep="1"
      />
    </StyledMainDisplayCard>
  );
};

export default IdentiteClientConsultation;
