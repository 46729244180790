import styled from 'styled-components';

export const StyledSaveLaterText = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  font-size: 1.6rem;
`;

export const StyledSaveLaterWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledComboInput = styled.div`
  margin-bottom: 2rem;
  width: 40rem;
`;
