import styled from 'styled-components';

const StyledLabel = styled.p`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;

export const StyledInputLabel = styled.span`
  margin: 0;
  height: 24px;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const StyledBadgeWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 1.2rem;
  padding-left: 2.4rem;
  margin-bottom: 0.8rem;
`;

export const StyledDebtCardBody = styled.div`
  margin: 0.8rem;
`;

export const StyledDeleteButtonText = styled.span`
  color: #c8102e;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
`;

export const StyledNotaryBlock = styled.div`
  box-sizing: border-box;
  width: 71.2rem;
  border: 1px solid #979797;
  border-radius: 0.6rem;
  padding-left: 3.2rem;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
`;

export default StyledLabel;
