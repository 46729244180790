import ChevronDown from 'icons/ChevronDown';
import React, { useEffect, useState } from 'react';
import { StyledCardLineContainer } from './style';

interface CardLineProps {
  children: React.ReactNode[];
  addChevron?: boolean;
  fillFlexSize?: number;
  onExpand: () => void;
  code: string;
  hidden: boolean;
}

const CardLine: React.FC<CardLineProps> = ({
  children,
  addChevron,
  fillFlexSize,
  onExpand,
  hidden,
  code,
}) => {
  const [realChildren, setRealChildren] = useState<React.ReactNode[]>();

  useEffect(() => {
    const tempChildren = [...children];

    while (tempChildren.length < 4) {
      tempChildren.push(<div style={{ flex: fillFlexSize }} />);
    }

    setRealChildren(tempChildren);
  }, []);

  const addChevronToTile = (child: React.ReactNode) => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div style={{ flex: 10 }}>{child}</div>

          <div
            style={{ display: 'flex', flex: 1, cursor: 'pointer', justifyContent: 'end' }}
            role="presentation"
            onClick={onExpand}>
            <ChevronDown />
          </div>
        </div>
      </>
    );
  };

  return (
    <StyledCardLineContainer>
      <>
        {!hidden
          ? realChildren?.map((child, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${index}-${code}`}>
                  {index === realChildren.length - 1 && addChevron
                    ? addChevronToTile(child)
                    : child}
                </React.Fragment>
              );
            })
          : null}
      </>
    </StyledCardLineContainer>
  );
};

CardLine.defaultProps = {
  addChevron: false,
  fillFlexSize: 1,
};

export default CardLine;
