import styled from 'styled-components';

export const StyledTooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  &:hover span {
    visibility: visible;
  }
`;

export const StyledTooltipText = styled.span<{
  topPosition: number;
  leftPosition: number;
  width?: string;
}>`
  visibility: hidden;
  width: ${({ width }) => width || '25rem'};
  background-color: #cce5ff;
  border-color: #d9edf7;
  color: #31708f;
  font-weight: 200;
  text-align: center;
  border-radius: 0.4rem;
  padding: 1rem;
  position: absolute;
  top: ${({ topPosition }) => `${topPosition}rem`};
  left: ${({ leftPosition }) => `${leftPosition}rem`};
  z-index: 0.9;
  white-space: pre-line;
`;

export const StyledQuestionMark = styled.span`
  height: 1.5rem;
  width: 1.5rem;
  background-color: #31708f;
  color: ${({ theme }) => theme.colors.chartColors.neutral0};
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 0.5rem;
`;
