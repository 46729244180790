import { GetSimulationResultApiResponse } from 'api/simulationService';
import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import Space from 'components/space/Space';
import EditIcon from 'icons/EditIcon';
import { useNavigate } from 'react-router';
import * as messages from '../messages';

interface ConsultationEditButtonProps {
  isProjectEditable: boolean | undefined;
  navigateRoute: string;
  navigateDonneesClientStep?: '1' | '2' | '3' | '4';
}

const ConsultationEditButton: React.FC<ConsultationEditButtonProps> = ({
  isProjectEditable,
  navigateRoute,
  navigateDonneesClientStep,
}) => {
  const navigate = useNavigate();
  const isfromApi = sessionStorage.getItem('contexteArrivee');
  const simulationResultObject = sessionStorage.getItem('simulationResult');
  const simulationResult = JSON.parse(
    simulationResultObject || 'null',
  ) as unknown as GetSimulationResultApiResponse;

  return isProjectEditable ? (
    <>
      <Space marginBottom="5.6rem" />
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <StyledSecondaryButton
          color="#008859"
          onClick={() => {
            if (isfromApi === 'ASRC' && !simulationResult?.Resultat?.Proposition) {
              sessionStorage.setItem('clientDataCurrentStep', '1');
              navigate('/donnees_client');
            } else if (simulationResult?.Resultat?.Proposition.nbAppelsRestants === 0) {
              sessionStorage.setItem('clientDataCurrentStep', '10');
              navigate('/resultat');
            } else {
              if (navigateDonneesClientStep)
                sessionStorage.setItem(
                  'clientDataCurrentStep',
                  navigateDonneesClientStep,
                );
              navigate(navigateRoute);
            }
          }}>
          <EditIcon color="#008859" />
          <span style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '0.8rem' }}>
            {messages.MAIN_CARD_EDIT_BUTTON_LABEL}
          </span>
        </StyledSecondaryButton>
      </div>
    </>
  ) : (
    <></>
  );
};

export default ConsultationEditButton;
