import { CustomValidity, Replacement } from 'components/designSystem/DesignSystemTypes';
import { MaritalStatus } from 'reducers/situationFoyer/types';
import { Department } from 'types';
import * as messagesCheckInput from 'utils/messagesCheckInput';

export const ValidationRules = {
  notEmpty: '.+',

  noNumbers: '^\\D*$',
  onlyNumbers: '^\\d*$',
  realNumbers: '^[\\d.,]*$',
  nonZeroNumbers: '^(?=.*[1-9])\\d*$',

  onlyNumbersAndAlphabeticChars: '^[a-zA-Z0-9]*$',
  alphabeticChars: 'a-zA-Z',
  noSpecialCharacters: `^[a-zA-Z\\d\\s]*$`,

  apeRegexp: '^\\d{4,4}[A-Z]$', // composé de 4 chiffres (min et max) et d'une seule lettre
  sirenRegexp: '^\\d{9,9}$', // composé uniquement de 9 chiffres (min et max)

  yearRegexp: '^\\d{4}$',
  nbPersonRegexp: '^\\d{1,2}$',
  addressRegexp: '^\\d+ \\D+',
  email: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
  mobilePhoneNumber: `[0][6-7]${`[\\s][0-9]{2}`.repeat(4)}`,
  zipCode: `^[0-9]{5}$`,
  zipCodeNoZero: '^(?!0{5})',
  dotAndComma: '[.,]',
  dotAndCommaThirdChar: '^.{2}[.,]',
  dotAndCommaSecondChar: '^.[.,]',
  dotAndCommaFourthChar: '^.{3}[.,]',
};

const dotAndComma = new RegExp(ValidationRules.dotAndComma);
const dotAndCommaThirdChar = new RegExp(ValidationRules.dotAndCommaThirdChar);
const dotAndCommaSecondChar = new RegExp(ValidationRules.dotAndCommaSecondChar);
const dotAndCommaFourthChar = new RegExp(ValidationRules.dotAndCommaFourthChar);

export const checkValidity = (
  value: string,
  validitiesToCheck: CustomValidity[],
): string | undefined => {
  // if all tests succeed, you should find nothing
  const result = validitiesToCheck.find(validity => {
    // if the test succeeds, don't return the element
    return !new RegExp(validity.regexp as string).test(value);
  });

  return result ? result.errorMessage : undefined;
};

export const specialCharactersReplacements = (): Replacement[] => {
  return [{ regexp: '[-]', replacement: 'T' }];
};

export const getNotEmpty = (
  componentName?: string,
  nozero?: boolean,
): CustomValidity | CustomValidity[] => {
  if (nozero) {
    return [
      {
        regexp: ValidationRules.notEmpty,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      },
      {
        regexp: ValidationRules.nonZeroNumbers,
        errorMessage: messagesCheckInput.NON_ZERO_NUMBERS,
      },
    ];
  }
  return {
    regexp: ValidationRules.notEmpty,
    errorMessage: messagesCheckInput.REQUIRED_VALUE,
  };
};

export const getFirstNameRegexPatterns = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: 'Le Prénom est un champ obligatoire.',
    },
    {
      regexp: ValidationRules.noNumbers,
      errorMessage: 'Le champ Prénom ne doit pas contenir de chiffre.',
    },
    {
      regexp: ValidationRules.noSpecialCharacters,
      errorMessage: 'Le champ Prénom ne doit pas contenir de caractères spéciaux.',
    },
  ];
};

export const getLastNameRegexPatterns = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: 'Le Nom est un champ obligatoire.',
    },
    {
      regexp: ValidationRules.noNumbers,
      errorMessage: 'Le champ Nom ne doit pas contenir de chiffre.',
    },
    {
      regexp: ValidationRules.noSpecialCharacters,
      errorMessage: 'Le champ Nom ne doit pas contenir de caractères spéciaux.',
    },
  ];
};

export const getBirthNameRegexPatterns = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: 'Le Nom de naissance est un champ obligatoire.',
    },
    {
      regexp: ValidationRules.noNumbers,
      errorMessage: 'Le champ Nom de naissance ne doit pas contenir de chiffre.',
    },
    {
      regexp: ValidationRules.noSpecialCharacters,
      errorMessage:
        'Le champ Nom de naissance ne doit pas contenir de caractères spéciaux.',
    },
  ];
};

export const getAddressRegexPatterns = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: "L'adresse est un champ obligatoire.",
    },
    {
      regexp: ValidationRules.addressRegexp,
      errorMessage: "Le format de l'adresse est incorrect.",
    },
  ];
};

export const getZipCodeRegexPatterns = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.zipCode,
      errorMessage: messagesCheckInput.INPUT_ERROR,
    },
    {
      regexp: ValidationRules.onlyNumbers,
      errorMessage: messagesCheckInput.INPUT_ERROR,
    },
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: messagesCheckInput.REQUIRED_VALUE,
    },
    {
      regexp: ValidationRules.zipCodeNoZero,
      errorMessage: messagesCheckInput.ZIPCODE_NO_ZERO,
    },
  ];
};

export const getCityRegexPatterns = (who?: string): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: 'Le champ Ville de naissance est obligatoire.',
    },
    {
      regexp: ValidationRules.noNumbers,
      errorMessage: `Le champ ville de naissance ${
        `${who} ` || ''
      }ne doit pas contenir de chiffre.`,
    },
    {
      regexp: ValidationRules.noSpecialCharacters,
      errorMessage: `Le champ ville de naissance ${
        `${who} ` || ''
      }ne doit pas contenir de caractères spéciaux.`,
    },
  ];
};
export const getYearRegexPattern = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.yearRegexp,
      errorMessage: "L'année doit être au format AAAA.",
    },
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: "La date d'entrée dans l'habitat est obligatoire.",
    },
  ];
};
export const getNbPersonRegexPattern = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.nbPersonRegexp,
      errorMessage: 'Le nombre de personnes est incorrect.',
    },
  ];
};

export const getSirenRegexPattern = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: 'Le N°SIREN de l’entreprise est obligatoire.',
    },
    {
      regexp: ValidationRules.sirenRegexp,
      errorMessage: 'Le N°SIREN de l’entreprise doit être un numérique de 9 chiffres.',
    },
  ];
};

export const getApeRegexPattern = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.apeRegexp,
      errorMessage:
        "Le code APE de l’entreprise doit être composé de 4 numériques suivis d'une majuscule.",
    },
  ];
};

export const getEmailRegexPattern = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: "L'email est un champ obligatoire.",
    },
    {
      regexp: ValidationRules.email,
      errorMessage: "Le format de l'email est incorrect.",
    },
  ];
};

export const getMobilePhoneNumberRegexpPattern = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: 'Le numéro de mobile est un champ obligatoire.',
    },
    {
      regexp: ValidationRules.mobilePhoneNumber,
      errorMessage: 'Le format de numéro de mobile est incorrect.',
    },
  ];
};

export const formatDisplayedDepartments = (
  departmentList: Department[],
): { libelle: string; code: string; displayedLabel: string }[] => {
  return departmentList.map(({ libelle, code }) => ({
    libelle,
    code,
    displayedLabel: libelle,
  }));
};

const NUMERIC_CHARACTERS = '0-9';
export const CELL_PHONE_PATTERN = `[0][6-7]${`[\\s][${NUMERIC_CHARACTERS}]{2}`.repeat(
  4,
)}`;
export const HOME_PHONE_PATTERN = `[0][1-9]${`[\\s][${NUMERIC_CHARACTERS}]{2}`.repeat(
  4,
)}`;

export const formatPhoneValue = (oldValue: string, newValue: string): string => {
  // if (newValue.replace(/ /g, '').length <= oldValue.replace(/ /g, '').length) {
  //   return newValue;
  // }

  const spacelessNewValue = newValue.replaceAll(' ', '');
  let reconstructedNumber = spacelessNewValue;

  if (/^[0-9]?[0-9]?([0-9]?[0-9]?){0,4}$/.test(spacelessNewValue)) {
    if (spacelessNewValue.length > 2) {
      reconstructedNumber = `${reconstructedNumber.slice(
        0,
        2,
      )} ${reconstructedNumber.slice(2)}`;
    }
    if (spacelessNewValue.length > 4) {
      reconstructedNumber = `${reconstructedNumber.slice(
        0,
        5,
      )} ${reconstructedNumber.slice(5)}`;
    }
    if (spacelessNewValue.length > 6) {
      reconstructedNumber = `${reconstructedNumber.slice(
        0,
        8,
      )} ${reconstructedNumber.slice(8)}`;
    }
    if (spacelessNewValue.length > 8) {
      reconstructedNumber = `${reconstructedNumber.slice(
        0,
        11,
      )} ${reconstructedNumber.slice(11)}`;
    }
    return reconstructedNumber;
  }

  return oldValue;
};

const backslash = '\\';

// prettier-disable
export const EMAIL_REGEX_PATTERN = new RegExp(
  `^(?!aaaa|abc|abcd|abcde|abcdef|azerty|bbbb|cccc|dns|host|hostmaster|ipadmin|masterdns|noc|nospam|postmaster|qwerty|root|security|sysadmin|usenet|webmaster)(?:[a-z0-9A-Z_-]+(?:${backslash}.[a-z0-9A-Z_-]+)*|")@(?!corp.aol.com|corp.aol.fr|mail.com|activexx.com|ahoo.fr|alicedsl.fr|anadoo.fr|aol.cm|aposte.net|ayahoo.com|caramail.fr|caramail.com|chello.fr|clubinternet.com|clubinternet.fr|com.com|corp.free.fr|euf.fr|fre.fr|gemail.com|geocitie.com|geocitiesyahoo.com|geocitis.com|gmail.fr|gocities.com|googl.com|homail.co.uk|homail.com|homail.fr|homtail.com|hormail.com|hotail.com|hotamail.fr|hotamil.com|hotmai.com|hotmai.fr|hotmailcom.com|hotmaill.com|hotmil.com|hoymail.co.uk|hoymail.fr|htomail.com|htomail.fr|hiahoo.com|jubii.co.uk|jubii.com|jubii.de|jubii.dk|jubii.es|jubii.fr|jubii.it|jubii.nl|jubiiconnect.dk|lapsote.net|lemel.fr|lfdv.fr|lycos.at|lycos.co.uk|lycos.de|lycos.de|lycos.es|lycos.fr|lycos.it|lycos.nl|lymedias.com|mobistar.be|mobistarmail.be|myahoo.com|myyahoo.com|oange.fr|online.net|orane.fr|business.fr|otmail.com|otmail.fr|pourriels.org|range.fr|ree.fr|tele.fr|tele2.be|tele2.fr|terra.es|tiscali.be|tiscali.fr|voila.fr|wanadoo.com|wandaoo.fr|wajunnadoo.fr|yafoo.com|yahll.com|yahll.com|yaho.com|yahomail.com|yahooguide.com|yahoonews.com|yahoosearch.com|yahos.com|yahow.com|yahu.com|yahuu.com|yahwho.com|yahwoo.com|yanoo.com|yaoo.fr|yauoo.com|yayoo.fr|yyahoo.com|ayhoo.com|carmail.com|geocties.com|goecities.com|hayoo.fr|ayoo.fr)(?:[a-z0-9A-Z-]+(?:${backslash}.[a-z0-9A-Z-]+)*|")${backslash}.(?:[a-z0-9A-Z-]+(?:${backslash}.[a-z0-9A-Z-]+)*|")$`,
  'i',
);

export const isEmailValid = (email: string): boolean => {
  // const parts = email.split('@');
  // if (parts.length !== 2) {
  //   return false;
  // }
  return EMAIL_REGEX_PATTERN.test(email);
};

export const isCellPhoneNumberValid = (mobilePhoneNumber: string): boolean => {
  return new RegExp(CELL_PHONE_PATTERN).test(mobilePhoneNumber);
};

export const isHomePhoneNumberValid = (homePhoneNumber: string): boolean => {
  const homePhoneReg = new RegExp(HOME_PHONE_PATTERN);
  const cellPhoneReg = new RegExp(CELL_PHONE_PATTERN);
  return homePhoneReg.test(homePhoneNumber) && !cellPhoneReg.test(homePhoneNumber);
};

export const isInARelationship = (maritalStatus?: MaritalStatus): boolean => {
  if (maritalStatus) {
    return maritalStatus.toString() === 'M' || maritalStatus.toString() === 'P';
  }
  return false;
};

export const checkMaritalSituation = (
  borrowerMaritalStatus?: MaritalStatus,
  coBorrowerMaritalStatus?: MaritalStatus,
): boolean => {
  if (borrowerMaritalStatus && coBorrowerMaritalStatus) {
    // If one of the borrower is in a relationship, the other must have the same marital situation
    if (
      isInARelationship(borrowerMaritalStatus) ||
      isInARelationship(coBorrowerMaritalStatus)
    ) {
      return borrowerMaritalStatus.toString() === coBorrowerMaritalStatus.toString();
    }
    // Otherwise they must both be single
    return (
      !isInARelationship(borrowerMaritalStatus) &&
      !isInARelationship(coBorrowerMaritalStatus)
    );
  }

  return false;
};

export const checkPercentageValue = (value: string): boolean => {
  if (dotAndCommaThirdChar.test(value) && value.length > 5) return true;
  if (dotAndCommaSecondChar.test(value) && value.length > 4) return true;
  if ((!dotAndComma.test(value) || dotAndCommaFourthChar.test(value)) && value.length > 3)
    return true;
  return false;
};
