import {
  StyledLoaderWrapper,
  StyledLoaderContent,
  StyledLoaderIcon,
  StyledLoaderBody,
  StyledText,
} from './styles';

export interface LoaderProps {
  animationDuration: number;
  text?: string;
}
const Loader: React.FC<LoaderProps> = ({ animationDuration, text }) => {
  return (
    <StyledLoaderWrapper data-testid="loader">
      <StyledLoaderContent>
        <StyledLoaderBody>
          <StyledLoaderIcon
            animationDuration={animationDuration}
            data-testid="loader-icon"
          />
          <StyledText>{text}</StyledText>
        </StyledLoaderBody>
      </StyledLoaderContent>
    </StyledLoaderWrapper>
  );
};

export default Loader;
