import Space from 'components/space/Space';
import React from 'react';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardTitle,
  StyleCardGrid,
  StyleCardGridItem,
} from 'containers/consultation/style';
import { DebtsAdditionalInformationState } from 'reducers/debtsAdditionalInformation/type';
import * as messages from '../../messages';

interface FinalisationDeptConsultationProps {
  debtAdditionalInformation: DebtsAdditionalInformationState;
}

const FinalisationDeptConsultation: React.FC<FinalisationDeptConsultationProps> = ({
  debtAdditionalInformation,
}) => {
  return (
    <>
      <StyledCardTitle>
        {messages.FINALISATION_INFO_DETTES_RACHETER.titre}
      </StyledCardTitle>
      <Space marginBottom="1.6rem" />

      {debtAdditionalInformation?.debts.map(dept => {
        return (
          <div key={dept.uid}>
            <StyleCardGrid>
              <StyleCardGridItem>
                <StyledCardLabel>{dept.libelle}</StyledCardLabel>
                <StyledCardData>{`${dept.amount.toLocaleString()} €`}</StyledCardData>
              </StyleCardGridItem>
              {dept.dueDate && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_DETTES_RACHETER.date}
                  </StyledCardLabel>
                  <StyledCardData>{dept.dueDate}</StyledCardData>
                </StyleCardGridItem>
              )}
            </StyleCardGrid>
            <Space marginBottom="1.6rem" />
          </div>
        );
      })}
      <Space marginBottom="1.6rem" />
    </>
  );
};

export default FinalisationDeptConsultation;
