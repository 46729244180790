import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import * as messages from '../messages';
import Email from './contact/Email';
import PhoneNumber from './contact/PhoneNumber';
import IdentityNumber from './identity/IdentityNumber';
import IdentityType from './identity/IdentityType';

interface CoBorrowerIdentityProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  checkInputs: boolean;
}
const CoBorrowerIdentity: React.FC<CoBorrowerIdentityProps> = ({
  state,
  dispatch,
  checkInputs,
}) => {
  return (
    <>
      <StyledTitle>{messages.IDENTITY_TEXT_COBORROWER}</StyledTitle>
      <Space marginTop="1.6rem" />
      <IdentityType
        state={state}
        dispatch={dispatch}
        person="coBorrower"
        checkInputs={checkInputs}
      />
      <Space marginTop="2.4rem" />
      <IdentityNumber
        state={state}
        dispatch={dispatch}
        person="coBorrower"
        checkInputs={checkInputs}
      />
      <Space marginTop="5.6rem" />
      <StyledTitle>{messages.CONTACT_TITLE_COBORROWER}</StyledTitle>
      <Space marginTop="1.6rem" />
      <Email
        state={state}
        dispatch={dispatch}
        person="coBorrower"
        checkInputs={checkInputs}
      />
      <Space marginTop="2.4rem" />
      <PhoneNumber
        state={state}
        dispatch={dispatch}
        person="coBorrower"
        checkInputs={checkInputs}
      />
    </>
  );
};

export default CoBorrowerIdentity;
