import { useEffect, useState } from 'react';
import ChemiseConsultation from 'components/chemise/chemiseConsultation';
import InformationArea from 'components/informationArea/InformationArea';
import ListPJNonTransmis from 'components/listPJNonTransmis/ListPJNonTransmis';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import LightIcon from 'icons/LightIcon';
import ListDocumentIcon from 'icons/ListDocumentIcon';
import {
  TransmissionPiecesAction,
  TransmissionPiecesState,
} from 'reducers/transmissionPieces/types';
import {
  CodeLibelle,
  ListePiecesJustificative,
  ListePiecesJustificativesPropCialUploadable,
  PJWithStatus,
} from 'types';
import * as messages from '../messages';

interface TransmissionPJForSGProps {
  chemisesUploadables: ListePiecesJustificativesPropCialUploadable[];
  chemisesNonUploadables: ListePiecesJustificative[];
  state: TransmissionPiecesState;
  dispatch: React.Dispatch<TransmissionPiecesAction>;
  disableUpload: boolean;
  allUploadAreError: string;
}
const TransmissionPJForSG: React.FC<TransmissionPJForSGProps> = ({
  chemisesUploadables,
  chemisesNonUploadables,
  state,
  dispatch,
  disableUpload,
  allUploadAreError,
}) => {
  const ACCEPTED_FILES_FORMAT = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];
  const ONE_MO_TO_BYTE = 1_048_576;
  const MAX_FILE_SIZE_IN_MO = 19 * ONE_MO_TO_BYTE; // 19 Mo maximum

  const [listePJNonTransmis, setListPJNonTransmis] = useState<string[]>([]);

  useEffect(() => {
    const listPJNonTransmisFromSession = sessionStorage.getItem('listePJNonTransmis');
    if (listPJNonTransmisFromSession) {
      const listPJNonTransmisObject = JSON.parse(
        listPJNonTransmisFromSession,
      ) as string[];

      setListPJNonTransmis(listPJNonTransmisObject);
    }
  }, [disableUpload]);

  const getChemiseFilesName = (id: string) => {
    const result: CodeLibelle[] = [];
    state.chemises.forEach(item => {
      if (item.id === id) {
        item.files.forEach(el => {
          result.push({ code: el.id, libelle: el.content.name });
        });
      }
    });
    return result;
  };

  const renderErrorMessage = () => {
    return (
      <>
        <span>Votre document n’a pas pu être téléchargé.</span>
        <br />
        <span>
          Veuillez vérifier le format ou la taille de votre document. Formats et taille
          acceptés : PDF, JPEG, WORD, EXCEL (sans macro), PNG - 19 Mo.
        </span>
      </>
    );
  };

  const renderChemisesUploadables = () => {
    const listPiece: PJWithStatus[] = [];
    if (
      !chemisesUploadables[0] ||
      chemisesUploadables[0].listePiecesJustificatives.length === 0
    ) {
      return null;
    }
    chemisesUploadables[0].listePiecesJustificatives.forEach(item => {
      const pieceName = `${item.pceJusLib[0]}${item.pceJusLib
        .substring(1)
        .toLowerCase()} ${item.cpltPceJusLib}`;
      listPiece.push({ libelle: pieceName });
    });
    const chemise = chemisesUploadables[0]; // pour un SG, il n'y a qu'une seule chemise, même si le retour de l'api est un Array
    return (
      <ChemiseConsultation
        id={chemise.chemId}
        libelle={chemise.chemLib}
        errorMessage={renderErrorMessage()}
        icon={<ListDocumentIcon />}
        acceptedFileFormat={ACCEPTED_FILES_FORMAT}
        maxFileSize={MAX_FILE_SIZE_IN_MO}
        listPiece={listPiece}
        listPJNonTransmis={listePJNonTransmis}
        isUploadable={!disableUpload}
        onUploadSuccess={value => {
          dispatch({
            type: 'setAddPieces',
            payload: { value },
          });
        }}
        onDelete={(chemiseId, fileId) => {
          dispatch({
            type: 'setDeletePieces',
            payload: { chemiseId, fileId },
          });
        }}
        uploadedFilesName={getChemiseFilesName(chemise.chemId)}
      />
    );
  };

  const renderChemisesNonUploadables = () => {
    const listPiece: PJWithStatus[] = [];
    chemisesNonUploadables.forEach(item => {
      const pieceName = `${item.pceJusLib[0]}${item.pceJusLib
        .substring(1)
        .toLowerCase()} ${item.cpltPceJusLib}`;
      listPiece.push({ libelle: pieceName });
    });
    return <ChemiseConsultation listPiece={listPiece} isUploadable={false} />;
  };

  return (
    <>
      <StepTitles
        title={messages.TITLE_CHEMISE_UPLOADABLE}
        subTitle={messages.SUB_TITLE_CHEMISE_UPLOADABLE}
      />
      <Space marginBottom="2.7rem" />
      <InformationArea icon={<LightIcon />} backgroundColor="#E5F5FD">
        <b>L’exhaustivité</b> des pièces justificatives à fournir favorise{' '}
        <b>le délai de traitement du dossier</b>.
      </InformationArea>
      <Space marginBottom="2.4rem" />
      <span style={{ color: '#6F757C' }}>{messages.ACCEPTED_FORMAT_TEXT_FOR_SG}</span>
      <Space marginBottom="0.8rem" />
      {renderChemisesUploadables()}
      {!allUploadAreError && listePJNonTransmis && listePJNonTransmis.length > 0 && (
        <>
          <Space marginTop="2rem" />
          <ListPJNonTransmis listPJ={listePJNonTransmis} />
        </>
      )}
      <Space marginTop="8rem" />
      <StepTitles
        title={messages.TITLE_CHEMISE_NON_UPLOADABLE}
        subTitle={messages.SUB_TITLE_CHEMISE_NON_UPLOADABLE}
      />
      <Space marginBottom="2.4rem" />
      {renderChemisesNonUploadables()}
    </>
  );
};

export default TransmissionPJForSG;
