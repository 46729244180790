import React from 'react';
import {
  StyledRectangle,
  StyledContent,
  StyledIcon,
  StyledContentWrapper,
  StyledTitleWrapper,
} from './style';

export interface InformationAreaProps {
  icon: React.ReactNode;
  backgroundColor: string;
  width?: string;
  height?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  titleBold?: boolean;
}
const InformationArea: React.FC<InformationAreaProps> = ({
  children,
  icon,
  backgroundColor,
  width,
  height,
  title,
  titleBold,
}) => {
  const renderContent = () => {
    if (title) {
      return (
        <StyledContentWrapper>
          <StyledTitleWrapper bold={titleBold}>
            {icon && <StyledIcon>{icon}</StyledIcon>}
            {title}
          </StyledTitleWrapper>
          <StyledContent>{children}</StyledContent>
        </StyledContentWrapper>
      );
    }
    return (
      <StyledContentWrapper alignIconWithText>
        {icon && <StyledIcon alignIconWithText>{icon}</StyledIcon>}
        <StyledContent>{children}</StyledContent>
      </StyledContentWrapper>
    );
  };

  return (
    <StyledRectangle
      backgroundColor={backgroundColor}
      data-testid="informationArea"
      width={width || '94rem'}
      height={height}>
      {renderContent()}
    </StyledRectangle>
  );
};

InformationArea.defaultProps = {
  width: '94rem',
  height: '6rem',
  title: '',
  titleBold: false,
  children: <></>,
};

export default InformationArea;
