import * as React from 'react';
import GenericIconProps from './GenericIcon';

const BankIcon: React.FC<GenericIconProps> = ({ width, color }) => {
  const baseWidth = 54;
  const baseHeight = 54;

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      xmlns="http://www.w3.org/2000/svg">
      <title>125B5112-6699-4203-9B97-626DFF9D6885</title>
      <path
        d="M.333 47.667c0-.737.597-1.334 1.334-1.334h50.666a1.333 1.333 0 0 1 0 2.667H1.667a1.333 1.333 0 0 1-1.334-1.333Zm5.334-5.334C5.667 41.597 6.264 41 7 41h40a1.333 1.333 0 0 1 0 2.667H7a1.333 1.333 0 0 1-1.333-1.334ZM3 9.777v1.89h48V9.753L27.085 3.66 3 9.777Zm-.656-2.584 24.084-6.117c.432-.11.884-.11 1.315 0L51.658 7.17a2.667 2.667 0 0 1 2.009 2.584v1.914A2.667 2.667 0 0 1 51 14.333H3a2.667 2.667 0 0 1-2.667-2.666v-1.89c0-1.22.828-2.284 2.01-2.584Zm10.075 11.14c-.736 0-1.333.597-1.333 1.334v16a1.333 1.333 0 0 0 2.666 0v-16c0-.737-.597-1.334-1.333-1.334Zm0-2.666a4 4 0 0 1 4 4v16a4 4 0 1 1-8 0v-16a4 4 0 0 1 4-4ZM27 18.333c-.736 0-1.333.597-1.333 1.334v16a1.333 1.333 0 0 0 2.666 0v-16c0-.737-.597-1.334-1.333-1.334Zm0-2.666a4 4 0 0 1 4 4v16a4 4 0 1 1-8 0v-16a4 4 0 0 1 4-4Zm14.752 2.666c-.736 0-1.333.597-1.333 1.334v16a1.333 1.333 0 0 0 2.667 0v-16c0-.737-.597-1.334-1.334-1.334Zm0-2.666a4 4 0 0 1 4 4v16a4 4 0 1 1-8 0v-16a4 4 0 0 1 4-4Z"
        fill={color}
        fillRule="nonzero"
        transform={`scale(${scaleRatio})`}
      />
    </svg>
  );
};

export default BankIcon;
