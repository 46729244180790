import React, { ReactNode } from 'react';

interface StepContentWrapperProps {
  children: ReactNode;
  width?: string;
}
const StepContentWrapper: React.FC<StepContentWrapperProps> = ({ children, width }) => {
  return <div style={{ width }}>{children}</div>;
};

StepContentWrapper.defaultProps = {
  width: '46.4rem',
};

export default StepContentWrapper;
