import { useEffect, useReducer, useRef, useState } from 'react';
import ToggleButton from 'components/toggleButton/ToggleButton';
import {
  CodeLibelle,
  Country,
  Department,
  FichageEmprunteursApiRequestBody,
  Nationality,
} from 'types';
import {
  coBorrowerIdentityReducer,
  getCoBorrowerInitialState,
} from 'reducers/identity/coBorrowerIdentity';
import {
  borrowerIdentityReducer,
  getBorrowerInitialState,
} from 'reducers/identity/borrowerIdentity';
import { updateSessionStorage } from 'utils/storage';
import StepTitles from 'components/titles/StepTitles';
import StepContentWrapper from 'components/wrapper/StepContentWrapper';
import { getDepartments, getNationalities } from 'api/referentialService';
import FormFooter from 'components/formFooter/FormFooter';
import Space from 'components/space/Space';
import ContentTitle from 'components/titles/ContentTitle';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import { Income } from 'reducers/ressourcesMensuelles/types';
import {
  SituationProfessionnelle,
  SituationProfessionnelleState,
} from 'reducers/situationProfessionnelle/types';
import { SituationFoyerState } from 'reducers/situationFoyer/types';
import { useNavigate } from 'react-router';
import fichageEmprunteurs from 'api/fichageEmprunteurs';
import {
  getEtatCivil,
  getIdentifiant,
  getNumeroContremarque,
  getNumeroProjet,
  getUserInfos,
  scrollToTopAndShowError,
} from 'utils/commun';
import { hasCoBorrower } from 'containers/communs/utils';
import BaseButton from 'components/designSystem/BaseButton';
import IllustrationDocuments from 'components/modals/ConfirmationModals/Assets/IllustrationDocuments';
import HomeIcon from 'icons/HomeIcon';
import FichageModal from 'components/modals/FichageModal';
import styled from 'styled-components';
import Loader from 'components/Loader';
import { StyledInfo } from 'containers/conditionsFinancieres/style';
import { StyledButtonLabel } from 'containers/search/style';
import WarningIcon from 'icons/WarningIcon';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import TextArea from 'components/textArea/TextArea';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from './messages';
import BorrowerIdentityForm from './BorrowerIdentityForm';
import CoBorrowerIdentityForm from './CoBorrowerIdentityForm';

export const fetchDepartments = async (): Promise<CodeLibelle[]> => {
  try {
    const response = await getDepartments();
    const departments: Department[] = [];
    response.Resultat.forEach(item => {
      departments.push({
        code: item.code.length === 1 ? `0${item.code}` : item.code,
        libelle: item.libelle
          .replaceAll('(', ' ')
          .replaceAll(')', '')
          .replaceAll('  ', ' '),
      });
    });
    return Promise.resolve(departments);
  } catch (err) {
    return Promise.reject();
  }
};
interface IdentityFormProps {
  onNextButtonClick: () => void;
  countryList: Country[];
}

const IdentityForm: React.FC<IdentityFormProps> = ({
  onNextButtonClick,
  countryList,
}) => {
  const navigate = useNavigate();

  const [borrowerState, borrowerReducerDispatch] = useReducer(
    borrowerIdentityReducer,
    getBorrowerInitialState('borrowerIdentity'),
  );
  const [coBorrowerState, coBorrowerReducerDispatch] = useReducer(
    coBorrowerIdentityReducer,
    getCoBorrowerInitialState('coBorrowerIdentity'),
  );
  const [showCoBorrower, setShowCoBorrower] = useState<boolean>(
    borrowerState.hasCoBorrower ?? true,
  );
  const [borrowFormIsOk, setBorrowerFormIsOK] = useState<boolean>(false);
  const [coBorrowFormIsOk, setCoBorrowerFormIsOK] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [nationalityList, setNationalityList] = useState<Nationality[]>([]);
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);

  const [hasError, setHasError] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(false);

  const childRef = useRef(null);

  const [showFICPFCCModal, setShowFICPFCCModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (departmentList.length === 0) {
      fetchDepartments().then(result => {
        setDepartmentList(result);
      });
    }
  }, []);

  useEffect(() => {
    const fetchNationalites = async () => {
      try {
        const response = await getNationalities();
        const { Resultat, Success } = response;
        if (Success) {
          const nationalities: Nationality[] = [];
          Resultat.forEach(item => {
            nationalities.push({ code: item.code, libelle: item.libelle });
          });
          setNationalityList(nationalities);
        }
      } catch (error) {
        // TODO: attendre l'us de gestion des erreurs
      }
    };
    fetchNationalites();
  }, []);

  useEffect(() => {
    borrowerReducerDispatch({
      type: 'setHasCoBorrower',
      payload: showCoBorrower,
    });
    updateSessionStorage('coBorrowerExists', showCoBorrower);
  }, [borrowFormIsOk, coBorrowFormIsOk, showCoBorrower]);

  const cleanupSituationFoyer = () => {
    const situationFoyer = sessionStorage.getItem('situationFoyer');

    if (situationFoyer) {
      const situationFoyerState = JSON.parse(situationFoyer) as SituationFoyerState;

      situationFoyerState.hasCoBorrower = false;
      situationFoyerState.coBorrowerMaritalStatus = undefined;
      situationFoyerState.coBorrowerMaritalStatusIsSameAsBorrower = false;

      sessionStorage.setItem('situationFoyer', JSON.stringify(situationFoyerState));
    }
  };

  const cleanupSituationProfessionnelle = () => {
    const situationProfessionnelle = sessionStorage.getItem('situationProfessionnelle');

    if (situationProfessionnelle) {
      const situationProfessionnelleState = JSON.parse(
        situationProfessionnelle,
      ) as SituationProfessionnelleState;

      situationProfessionnelleState.hasCoBorrower = false;
      situationProfessionnelleState.coBorrower = {} as SituationProfessionnelle;

      sessionStorage.setItem(
        'situationProfessionnelle',
        JSON.stringify(situationProfessionnelleState),
      );
    }
  };

  const cleanupRessourcesMensuelles = () => {
    const ressourcesMensuelles = sessionStorage.getItem('ressourcesMensuelles');

    if (ressourcesMensuelles) {
      const ressourcesMensuellesState = JSON.parse(ressourcesMensuelles);

      ressourcesMensuellesState.hasCoBorrower = false;
      ressourcesMensuellesState.coBorrowerIncome = {} as Income;

      sessionStorage.setItem(
        'ressourcesMensuelles',
        JSON.stringify(ressourcesMensuellesState),
      );
    }
  };

  const cleanup = () => {
    if (borrowerState.isBirthNameSameAsName) {
      borrowerState.birthName = '';
    }

    if (borrowerState.hasCoBorrower && coBorrowerState.isBirthNameSameAsName) {
      coBorrowerState.birthName = '';
    }

    if (!borrowerState.hasCoBorrower) {
      cleanupSituationFoyer();
      cleanupRessourcesMensuelles();
      cleanupSituationProfessionnelle();
    }
  };

  const saveInSessionStorage = (): void => {
    cleanup();

    updateSessionStorage('borrowerIdentity', borrowerState);

    if (borrowerState.hasCoBorrower) {
      updateSessionStorage('coBorrowerIdentity', coBorrowerState);
    } else {
      sessionStorage.removeItem('coBorrowerIdentity');
    }
  };

  const handleNextBtnClicked = async () => {
    setErrorMessages([]);
    setIsLoading(true);
    saveInSessionStorage();
    try {
      const requestBody: FichageEmprunteursApiRequestBody = {
        userId: getUserInfos().uid,
        numeroContreMarque: getNumeroContremarque(),
        identifiantEmprunteur: getIdentifiant('borrowerId'),
        identifiantCoEmprunteur: getIdentifiant('coBorrowerId'),
        numeroProjet: getNumeroProjet(),
        emprunteur: {
          etatCivil: getEtatCivil('borrower'),
        },
      };
      if (hasCoBorrower()) {
        requestBody.coEmprunteur = {
          etatCivil: getEtatCivil('coBorrower'),
        };
      }
      const result = await fichageEmprunteurs(requestBody);
      // si result.Success == false, affichage des messages d'erreur renvoyés par le back
      if (result.Success === false) {
        setErrorMessages(
          result.ListeMessages.filter(message => {
            return message.MessageType === 'E' || 'T';
          }),
        );
        setIsLoading(false);
      } else if (
        // si un des indFichage est à Oui (O), un des emprunteurs est fiché. on affiche le modal pour quitter.
        result.Resultat.indFichageFICPEmp === 'O' ||
        result.Resultat.indFichageFCCEmp === 'O' ||
        result.Resultat.indFichageFICPCoEmp === 'O' ||
        result.Resultat.indFichageFCCCoEmp === 'O'
      ) {
        setIsLoading(false);
        setShowFICPFCCModal(true);
      } else {
        // si tout est OK, on update la session storage puis on continue à l'étape suivante
        updateSessionStorage('borrowerId', result.Resultat.identifiantEmprunteur);
        if (hasCoBorrower()) {
          updateSessionStorage('coBorrowerId', result.Resultat.identifiantCoEmprunteur);
        }
        updateSessionStorage('numeroProjet', result.Resultat.numeroProjet);

        onNextButtonClick();
      }
    } catch (error) {
      console.error(error);
      alert(
        "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
      );
      setIsLoading(false);
    }
  };

  const StyledModalContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;

  const displayFICPFCCModal = () => {
    return (
      <FichageModal isVisible={showFICPFCCModal} height="48.8rem" width="68.8rem">
        <StyledModalContent>
          <div>
            <IllustrationDocuments />
          </div>

          <Space marginBottom="1.6rem" />

          <span
            style={{
              fontSize: '2.0rem',
            }}>{`Cher partenaire, nous ne sommes pas en mesure `}</span>
          <span
            style={{ fontWeight: 'bold', fontSize: '2.0rem' }}>{`de poursuivre l'étude de
              votre dossier.`}</span>

          <Space marginBottom="3.2rem" />

          <div>
            <BaseButton
              onClick={() => {
                navigate('/dashboard');
              }}
              style={{
                background: '#268038',
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.8rem',
              }}>
              <HomeIcon />
              <StyledButtonLabel style={{ marginLeft: '1rem' }}>
                {`Revenir à l'accueil`}
              </StyledButtonLabel>
            </BaseButton>
          </div>
        </StyledModalContent>
      </FichageModal>
    );
  };

  return (
    <>
      <StepTitles subTitle={messages.SUB_TITLE} />
      {hasError && (
        <>
          <Space marginTop="2.4rem" />
          <TextArea title={messagesCheckInput.CHECK_ERROR_TITLE} type="error" includeIcon>
            <span> {messagesCheckInput.CHECK_ERROR}</span>
          </TextArea>
        </>
      )}
      <Space marginTop="2.4rem" />
      <ContentTitle text={messages.EMPRUNTEUR} />
      <StepContentWrapper width="46.4rem">
        <BorrowerIdentityForm
          ref={childRef}
          isFormOk={val => {
            setBorrowerFormIsOK(val);
          }}
          countryList={countryList}
          departmentList={departmentList}
          nationalityList={nationalityList}
          borrowerState={borrowerState}
          checkInputs={checkInputs}
          borrowerReducerDispatch={borrowerReducerDispatch}
        />
        <Space marginTop="5.6rem" />
        <CoBorrowerTitle />
        <ToggleButton
          id="co-emprunteur"
          isOn={showCoBorrower}
          handleToggle={() => {
            borrowerReducerDispatch({
              type: 'setHasCoBorrower',
              payload: !showCoBorrower,
            });
            setShowCoBorrower(prevState => !prevState);
          }}>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '1rem',
              display: 'block',
              marginTop: '0.3rem',
              height: '3.2rem',
            }}>
            Informations personnelles co-emprunteur
          </span>
        </ToggleButton>

        <Space marginBottom="1.6rem" />
        {showCoBorrower ? (
          <CoBorrowerIdentityForm
            countryList={countryList}
            departmentList={departmentList}
            nationalityList={nationalityList}
            isFormOk={val => {
              setCoBorrowerFormIsOK(val);
            }}
            coBorrowerState={coBorrowerState}
            checkInputs={checkInputs}
            coBorrowerReducerDispatch={coBorrowerReducerDispatch}
          />
        ) : null}
      </StepContentWrapper>

      {errorMessages.length > 0 && (
        <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '10.4rem' }}>
          <WarningIcon />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {errorMessages.map(message => {
              return (
                <p
                  style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                  key={message.MessageId}>
                  {message.MessageLib}
                </p>
              );
            })}
          </div>
        </StyledInfo>
      )}

      <FormFooter
        showPreviousButton={false}
        saveBtn="btn"
        saveBtnLabel="Quitter et revenir à l'accueil"
        onSaveButtonClick={() => navigate('/dashboard')}
        saveButtonIcon={<HomeIcon color="#3a913f" />}
        onNextButtonClick={() => {
          if (showCoBorrower) {
            if (borrowFormIsOk && coBorrowFormIsOk) {
              handleNextBtnClicked();
            } else {
              scrollToTopAndShowError({ setHasError, setCheckInputs });
            }
          } else if (borrowFormIsOk) {
            handleNextBtnClicked();
          } else {
            scrollToTopAndShowError({ setHasError, setCheckInputs });
          }
        }}
      />
      {showFICPFCCModal && displayFICPFCCModal()}
      {isLoading ? <Loader animationDuration={2} /> : null}
    </>
  );
};

export default IdentityForm;
