import * as React from 'react';

const ChevronExpand: React.FC = () => (
  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg">
    <title>Ic/24px/Navigation/chevron-expand24px</title>
    <path
      d="M6.196 15.067a.645.645 0 0 1 .472-.21c.177 0 .347.076.472.21l4.86 5.21 4.86-5.21c.261-.28.684-.28.945 0 .26.28.26.732 0 1.011l-5.333 5.715a.645.645 0 0 1-.472.21.645.645 0 0 1-.472-.21l-5.332-5.715A.741.741 0 0 1 6 15.573c0-.19.07-.372.196-.506Zm0-6.131c.125.134.295.21.472.21a.645.645 0 0 0 .472-.21L12 3.726l4.86 5.21c.261.279.684.279.945 0a.752.752 0 0 0 0-1.012L12.472 2.21A.645.645 0 0 0 12 2a.645.645 0 0 0-.472.21L6.196 7.924A.741.741 0 0 0 6 8.43c0 .19.07.372.196.506Z"
      fill="#292C2E"
      fillRule="evenodd"
    />
  </svg>
);

export default ChevronExpand;
