import { CodeLibelle } from 'types';

export const ACCOMODATION_TYPE_LIST = new Map([
  ['MA', { code: 'MA', libelle: 'Maison' } as CodeLibelle],
  ['AP', { code: 'AP', libelle: 'Appartement' } as CodeLibelle],
]);

export const CREDIT_TYPE_LIST = new Map([
  ['C', { code: 'C', libelle: 'Consommation' } as CodeLibelle],
  ['I', { code: 'I', libelle: 'Immobilier' } as CodeLibelle],
  ['R', { code: 'R', libelle: 'Renouvelable' } as CodeLibelle],
]);

export const ASSURANCE_LIST = [
  { code: 'GC', libelle: 'Groupe conseillée' },
  { code: 'I', libelle: 'Individuelle' },
  { code: 'GP', libelle: 'Groupe personnalisée' },
  { code: 'S', libelle: 'Sans assurance' },
];

export const ASSURANCE_LIST_CALCULATOR = [
  { code: 'G', libelle: 'Groupe personnalisée' },
  { code: 'D', libelle: 'Individuelle' },
  { code: 'S', libelle: 'Sans assurance' },
];
