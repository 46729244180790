import BaseButton from 'components/designSystem/BaseButton';
import styled from 'styled-components';

const StyledSecondaryButton = styled(BaseButton)<{ width?: string; color?: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-color: ${({ color }) =>
    color || (({ theme }) => theme.colors.chartColors.mainGreen500)};
  background-color: #fff;
  color: ${({ color }) =>
    color || (({ theme }) => theme.colors.chartColors.mainGreen500)};
  height: 4.8rem;
  width: ${({ width }) => width || 'auto'};
`;

export default StyledSecondaryButton;
