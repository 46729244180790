import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import Space from 'components/space/Space';
import CrossCircle from 'icons/CrossCircle';
import React, { useEffect, useState } from 'react';
import { Bank, BankDebtType, CodeLibelle, StatusControlInput, StatusInput } from 'types';
import { StyledTitle } from 'containers/communs/style';
import {
  DebtsAdditionalInformationAction,
  DebtsAdditionalInformationState,
  DebtsCompleteData,
} from 'reducers/debtsAdditionalInformation/type';
import ExpandableCardTer from 'components/expandableCard/ExpandableCardTer';
import CustomInput from 'components/customInput/CustomInput';
import { StyledDeleteButtonText } from 'containers/finalisationProjet/style';
import ChevronUp from 'icons/ChevronUp';
import ChevronDown from 'icons/ChevronDown';
import { ValidationRules } from 'utils/InputValidation';
import { formatNumberByThousand } from 'utils/commun';
import { checkFormatDate, getDateFromInput, isDateIntheFuture } from 'utils/DateUtils';
import { DateInput } from 'components/designSystem/DateInput';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../../../messages';

interface BankDebtProps {
  banksReferential: Bank[];
  debt: DebtsCompleteData;
  itemBank: BankDebtType;
  state: DebtsAdditionalInformationState;
  totalBankDebtsMatchesDebtAmount: () => void;
  onDelete: (bankCode: string) => void;
  dispatch: React.Dispatch<DebtsAdditionalInformationAction>;
  checkInputs: boolean;
}
const BankAccount: React.FC<BankDebtProps> = ({
  banksReferential,
  debt,
  itemBank,
  state,
  totalBankDebtsMatchesDebtAmount,
  onDelete,
  dispatch,
  checkInputs,
}) => {
  const [isBankExpanded, setIsBankExpanded] = useState<boolean>(true);

  const [isAccountHolderFocused, setIsAccountHolderFocused] = useState<boolean>(false);
  const [statusAccountHolder, setStatusAccountHolder] = useState<StatusControlInput>();

  const [isAmountFocused, setIsAmountFocused] = useState<boolean>(false);
  const [statusAmount, setStatusAmount] = useState<StatusControlInput>();

  const [statusBankAccount, setStatusBankAccount] = useState<StatusInput>();

  const [isAccountNumberFocused, setIsAccountNumberFocused] = useState<boolean>(false);
  const [statusAccountNumber, setStatusAccountNumber] = useState<StatusControlInput>();

  const [reloadStatusDate, setReloadStatusDate] = useState<boolean>(false);
  const [statusDate, setStatusDate] = useState<StatusInput>();

  const notEmpty = new RegExp(ValidationRules.notEmpty);
  const noNumbers = new RegExp(ValidationRules.noNumbers);
  const noSpecialCharacters = new RegExp(ValidationRules.noSpecialCharacters);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const onlyNumbersAndAlphabeticChars = new RegExp(
    ValidationRules.onlyNumbersAndAlphabeticChars,
  );

  const checkIsDateValid = (): void => {
    if (reloadStatusDate === false) {
      return;
    }
    setReloadStatusDate(false);

    const value = getDateFromInput(itemBank.dueDate || '');

    if (itemBank.dueDate === undefined || itemBank.dueDate === '') {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (!checkFormatDate(itemBank.dueDate)) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_FORMAT,
      });
    } else if (value.getFullYear() >= 2100) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_OVER_2100,
      });
    } else if (isDateIntheFuture(value)) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_IN_THE_PAST,
      });
    } else if (!isDateIntheFuture(getDateFromInput(itemBank.dueDate))) {
      setStatusDate({
        status: 'valid',
        errorMessage: '',
      });
    } else {
      setStatusDate({
        status: 'valid',
        errorMessage: '',
      });
    }
  };

  const updateBankValue = (newBankValues: BankDebtType) => {
    const result = state.debts.map(debtFromState => {
      if (debtFromState.code === debt.code) {
        const updatedBanksList = debtFromState.banks?.map(bank => {
          if (bank.id === newBankValues.id) return newBankValues;
          return bank;
        });

        return { ...debtFromState, banks: updatedBanksList };
      }
      return debtFromState;
    });

    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data: result, debtCode: debt.code },
    });
  };

  const isBankDataComplete = (bank: BankDebtType | undefined) => {
    if (bank) {
      if (bank.accountHolder && bank.accountNumber && bank.amount && bank.dueDate) {
        return true;
      }
    }
    return false;
  };

  const onAccountHolderBlur = (value: string) => {
    setIsAccountHolderFocused(false);
    if (!notEmpty.test(value)) {
      setStatusAccountHolder({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (!noNumbers.test(value)) {
      setStatusAccountHolder({
        status: false,
        errorMessage: messagesCheckInput.NO_NUMBER,
      });
    } else if (!noSpecialCharacters.test(value)) {
      setStatusAccountHolder({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
    } else {
      setStatusAccountHolder({
        status: true,
        errorMessage: ``,
      });
    }
    const updatedCurrentBank = {
      ...itemBank,
      accountHolder: value,
    } as BankDebtType;
    updateBankValue(updatedCurrentBank);
  };

  const onAmountBlur = (value: string) => {
    const valWithoutSpace = value.replace(/ /g, '');
    setIsAmountFocused(false);
    if (!notEmpty.test(valWithoutSpace)) {
      setStatusAmount({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (!onlyNumbers.test(valWithoutSpace)) {
      setStatusAmount({
        status: false,
        errorMessage: messagesCheckInput.ONLY_NUMBERS,
      });
    } else {
      setStatusAmount({
        status: true,
        errorMessage: ``,
      });
    }
    const updatedCurrentBank = {
      ...itemBank,
      amount: valWithoutSpace,
    } as BankDebtType;
    updateBankValue(updatedCurrentBank);
  };

  const onBankAccountBlur = (value: string) => {
    const bankAccoutMatchList = banksReferential?.find(bank => {
      return bank.libelle.toUpperCase() === value.toUpperCase();
    });

    if (bankAccoutMatchList) {
      setStatusBankAccount({
        status: 'valid',
        errorMessage: ``,
      });
      const updatedCurrentBank = {
        ...itemBank,
        libelle: bankAccoutMatchList.libelle,
        code: bankAccoutMatchList.code,
      } as BankDebtType;
      updateBankValue(updatedCurrentBank);
    } else {
      setStatusBankAccount({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
      const updatedCurrentBank = {
        ...itemBank,
        code: 'err',
        libelle: value,
      } as BankDebtType;
      updateBankValue(updatedCurrentBank);
    }
  };

  const onAccountNumberBlur = (value: string) => {
    setIsAccountNumberFocused(false);
    if (!notEmpty.test(value)) {
      setStatusAccountNumber({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (!onlyNumbersAndAlphabeticChars.test(value)) {
      setStatusAccountNumber({
        status: false,
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
    } else {
      setStatusAccountNumber({
        status: true,
        errorMessage: ``,
      });
    }
    const updatedCurrentBank = {
      ...itemBank,
      accountNumber: value,
    } as BankDebtType;
    updateBankValue(updatedCurrentBank);
  };

  useEffect(() => {
    if (checkInputs) {
      onAccountHolderBlur(itemBank.accountHolder || '');
      onAmountBlur(itemBank.amount || '');
      onBankAccountBlur(itemBank.libelle || '');
      onAccountNumberBlur(itemBank.accountNumber || '');
      checkIsDateValid();
    }
  }, [checkInputs]);

  useEffect(() => {
    totalBankDebtsMatchesDebtAmount();
  }, [itemBank.amount]);

  useEffect(() => {
    if (itemBank.accountHolder) onAccountHolderBlur(itemBank.accountHolder || '');
    if (itemBank.amount) onAmountBlur(itemBank.amount || '');
    if (itemBank.accountNumber) onAccountNumberBlur(itemBank.accountNumber || '');
    if (itemBank.dueDate) setReloadStatusDate(true);
  }, []);

  useEffect(() => {
    if (banksReferential.length !== 0 && itemBank.libelle) {
      onBankAccountBlur(itemBank.libelle || '');
    }
  }, [banksReferential]);

  useEffect(() => {
    checkIsDateValid();
  }, [reloadStatusDate]);

  const onDateBlur = () => {
    setStatusDate({
      status: 'none',
      errorMessage: '',
    });
    setReloadStatusDate(true);
  };

  return (
    <>
      <ExpandableCardTer>
        <div style={{ marginLeft: '1.6rem' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <StyledTitle>{messages.DEBT_CARD_TITLE}</StyledTitle>
            <div
              style={{ cursor: 'pointer' }}
              onKeyPress={() => {
                if (isBankExpanded) setIsBankExpanded(false);
                else setIsBankExpanded(true);
              }}
              onClick={() => {
                if (isBankExpanded) setIsBankExpanded(false);
                else setIsBankExpanded(true);
              }}
              role="button"
              tabIndex={0}>
              {isBankExpanded ? <ChevronUp /> : <ChevronDown />}
            </div>
          </div>

          <Space marginBottom="2.4rem" />

          <div style={{ display: 'flex', gap: '2.4rem' }}>
            <CustomInput
              name="account_holder"
              label={messages.DEBT_CARD_ACCOUNT_HOLDER_LABEL}
              placeholder="M Jean Dupont"
              inputWidth="34.8rem"
              onChange={newValue => {
                setIsAccountHolderFocused(true);
                const updatedCurrentBank = {
                  ...itemBank,
                  accountHolder: newValue.slice(0, 25),
                } as BankDebtType;
                updateBankValue(updatedCurrentBank);
              }}
              onBlur={newValue => {
                onAccountHolderBlur(newValue);
              }}
              onFocus={() => setIsAccountHolderFocused(true)}
              isFocused={isAccountHolderFocused}
              value={itemBank.accountHolder}
              isValid={statusAccountHolder?.status}
              error={statusAccountHolder?.errorMessage}
            />

            <CustomInput
              name="amount"
              label={messages.DEBT_CARD_AMOUNT_LABEL}
              after="€"
              inputWidth="29.2rem"
              placeholder="0"
              type="price"
              onChange={newValue => {
                if (!onlyNumbers.test(newValue.replace(/ /g, ''))) return;
                const updatedCurrentBank = {
                  ...itemBank,
                  amount: newValue.replace(/ /g, ''),
                } as BankDebtType;
                updateBankValue(updatedCurrentBank);
              }}
              onBlur={newValue => {
                onAmountBlur(newValue);
              }}
              onFocus={() => setIsAmountFocused(true)}
              isFocused={isAmountFocused}
              value={formatNumberByThousand(itemBank.amount || '')}
              isValid={statusAmount?.status}
              error={statusAmount?.errorMessage}
            />
          </div>

          <div
            style={
              !isBankExpanded && !isBankDataComplete(itemBank) ? { display: 'none' } : {}
            }>
            <Space marginBottom="2.4rem" />

            <div style={{ display: 'flex', gap: '2.4rem' }}>
              <div style={{ width: '34.8rem' }}>
                <ComboInput
                  class="bank-input-finalisation show-input-ds-validation"
                  list-on-open
                  shadow
                  required
                  align-items="left"
                  items={JSON.stringify(banksReferential)}
                  field="libelle"
                  label={messages.DEBT_CARD_ADD_BANK_ACCOUNT_LABEL}
                  placeholder={"Choisir l'organisme"}
                  onSelectedList={(e: Event) => {
                    const newValue = (e as CustomEvent).detail.value as CodeLibelle;
                    const updatedCurrentBank = {
                      ...itemBank,
                      code: newValue.code,
                      libelle: newValue.libelle,
                    } as BankDebtType;
                    updateBankValue(updatedCurrentBank);
                  }}
                  onListBlur={(e: Event) => {
                    if (e && e.target) {
                      onBankAccountBlur(
                        (e.target as unknown as ComboInputDS).value as string,
                      );
                    }
                  }}
                  value={itemBank.libelle}
                  status={statusBankAccount?.status}
                  error-message={statusBankAccount?.errorMessage}
                />
              </div>

              <CustomInput
                name="account_number"
                label={messages.DEBT_CARD_ACCOUNT_NUMBER_LABEL}
                inputWidth="29.2rem"
                placeholder="0"
                onChange={newValue => {
                  setIsAccountNumberFocused(true);
                  if (!noSpecialCharacters.test(newValue)) return;
                  const updatedCurrentBank = {
                    ...itemBank,
                    accountNumber: newValue.replaceAll(' ', '').slice(0, 11),
                  } as BankDebtType;
                  updateBankValue(updatedCurrentBank);
                }}
                onBlur={newValue => {
                  onAccountNumberBlur(newValue);
                }}
                onFocus={() => {
                  setIsAccountNumberFocused(true);
                }}
                isFocused={isAccountNumberFocused}
                value={itemBank.accountNumber}
                isValid={statusAccountNumber?.status}
                error={statusAccountNumber?.errorMessage}
              />
            </div>

            <Space marginBottom="2.4rem" />

            <div
              style={{
                display: 'flex',
                gap: '2.4rem',
                justifyContent: 'space-between',
              }}>
              <DateInput
                class="show-input-ds-validation"
                label="Date d'exigibilité"
                onBlur={onDateBlur}
                onInputChange={e => {
                  const updatedCurrentBank = {
                    ...itemBank,
                    dueDate: (e.target as HTMLInputElement).value,
                  } as BankDebtType;
                  updateBankValue(updatedCurrentBank);
                }}
                value={itemBank.dueDate}
                {...(statusDate?.status !== 'none' ? { status: statusDate?.status } : {})}
                {...(statusDate?.errorMessage !== ''
                  ? { 'error-message': statusDate?.errorMessage }
                  : {})}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  cursor: 'pointer',
                }}
                onKeyPress={() => onDelete(itemBank.id || '')}
                onClick={() => onDelete(itemBank.id || '')}
                role="button"
                tabIndex={0}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.8rem',
                  }}>
                  <CrossCircle color="#D52726" />
                  <StyledDeleteButtonText>
                    {messages.DEBT_CARD_DELETE_BUTTON_LABEL}
                  </StyledDeleteButtonText>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Space marginBottom="2.4rem" />
      </ExpandableCardTer>
    </>
  );
};

export default BankAccount;
