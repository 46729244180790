const ExclamationPointIcon: React.FC = () => (
  <svg width={2} height={12}>
    <path
      d="M1 9.883c.552 0 1 .474 1 1.058C2 11.526 1.552 12 1 12s-1-.474-1-1.059c0-.584.448-1.058 1-1.058ZM1 0c.552 0 1 .474 1 1.059v5.645c0 .585-.448 1.06-1 1.06s-1-.475-1-1.06V1.06C0 .474.448 0 1 0Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default ExclamationPointIcon;
