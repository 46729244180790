import GenericIconProps from './GenericIcon';

const EyeIcon: React.FC<GenericIconProps> = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>A3A52F54-C037-40B5-92AB-F3FDB4B0AA61</title>
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Dashboard1"
        transform="translate(-319.000000, -355.000000)"
        fill="#268038"
        fillRule="nonzero">
        <g id="Statut/etat/mini" transform="translate(264.000000, 287.000000)">
          <g
            id="Button/Secondary/Outlined/Green/M/Enabled"
            transform="translate(39.000000, 56.000000)">
            <g id="Group" transform="translate(16.000000, 12.000000)">
              <g id="Group" transform="translate(2.000000, 6.000000)">
                <path
                  d="M19.8478947,5.59866843 C16.8878947,1.95911536 13.4978947,0.0215885773 10.0478947,0.000179441643 C6.60789474,-0.0212296941 3.18789474,1.87347882 0.157894737,5.48091818 C-0.0421052632,5.71641867 -0.0521052632,6.06966941 0.127894737,6.32657904 C1.48789474,8.24269669 3.05789474,9.69851791 4.80789474,10.661929 C6.41789474,11.5504082 8.13789474,12 9.92789474,12 C9.93789474,12 9.95789474,12 9.96789474,12 C13.7478947,11.9892954 17.4578947,9.9019047 19.8778947,6.42292015 C20.0478947,6.18741966 20.0378947,5.83416892 19.8478947,5.59866843 Z M9.95789474,10.6833382 C9.94789474,10.6833382 9.92789474,10.6833382 9.91789474,10.6833382 C7.63789474,10.6833382 4.36789474,9.84838186 1.42789474,5.96262373 C4.11789474,2.9118219 7.08789474,1.30613672 10.0378947,1.32754585 C12.9878947,1.34895499 15.9278947,2.98675387 18.5678947,6.06966941 C16.3778947,8.94919816 13.1978947,10.6726336 9.95789474,10.6833382 Z"
                  id="Shape"
                />
                <path
                  d="M9.93789474,2.35518437 C8.04789474,2.35518437 6.50789474,4.00368782 6.50789474,6.02685114 C6.50789474,8.05001446 8.04789474,9.69851791 9.93789474,9.69851791 C11.8278947,9.69851791 13.3678947,8.05001446 13.3678947,6.02685114 C13.3678947,4.00368782 11.8278947,2.35518437 9.93789474,2.35518437 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EyeIcon;
