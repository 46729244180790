import * as React from 'react';

const IllustrationDocuments: React.FC = () => (
  <svg
    width="186px"
    height="137px"
    viewBox="0 0 186 137"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>67BD6EA6-9E1F-404F-B093-6234386618C9</title>
    <g
      id="Parcours-montage-dossier-🗂"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="pop/up/informations/obligatoires"
        transform="translate(-254.000000, -61.000000)">
        <g id="Illustration/Documents" transform="translate(254.617179, 61.129855)">
          <g id="informations" transform="translate(0.000000, -0.000000)">
            <path
              d="M57.1493228,6.44592801 C63.4287103,0.789673591 73.2867079,-0.362081415 82.2588634,0.0880481489 C110.440819,1.50251222 135.880347,15.2402743 157.885436,30.7801565 C165.851608,36.4059751 173.694435,42.4739139 178.870124,50.215822 C189.397709,65.9863756 186.274034,87.8344792 171.637615,100.825763 C166.657356,105.246965 160.619853,108.63495 154.422161,111.640086 C143.402797,116.980769 131.515212,121.267412 119.006095,122.488048 C110.086802,123.357871 101.064988,122.648237 92.1905477,121.437212 C67.4174029,118.04442 43.1007951,110.544397 22.7184158,97.7068938 C13.7750943,92.0778715 5.34918142,85.0792378 1.6648469,75.9677183 C-2.01948761,66.8561989 0.338486481,55.3210281 9.46602476,49.9306865 C13.2416667,47.7008632 20.98357,43.8997675 37.1852172,33.7993285 C53.3868645,23.6988896 50.8779447,12.0845617 57.1493228,6.44592801 Z"
              id="Path"
              fill="#83CA87"
              fillRule="nonzero"
              opacity="0.1"
            />
            <g id="Group-5" transform="translate(19.528741, 0.389408)">
              <path
                d="M100.203641,117.580883 L80.9214369,125.04118 L52.7372407,135.934635 C51.6742301,133.960473 50.1056649,131.259695 48.1641809,128.053363 C41.4900894,116.990749 30.4447756,99.7845877 20.6143309,84.6640798 C9.53441639,67.6270772 0,53.2408732 0,53.2408732 L6.90284058,51.2397991 L63.2866111,34.8871216 L100.203641,117.580883 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.1"
              />
              <path
                d="M100.588093,116.2353 L81.3058891,123.695597 L53.1216929,134.589052 C52.0586824,132.61489 50.4901171,129.914112 48.5486331,126.70778 C41.8745417,115.645166 30.8292278,98.4390047 20.9987832,83.3184968 C9.91886867,66.2814943 0.384452274,51.8952902 0.384452274,51.8952902 L7.28729285,49.8942161 L63.6710633,33.5415386 L100.588093,116.2353 Z"
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
              <path
                d="M100.588093,116.2353 L81.3058891,123.695597 C70.5363977,125.938866 59.5466242,126.949427 48.5486331,126.70778 C41.8745417,115.645166 30.8292278,98.4390047 20.9987832,83.3184968 L7.28729285,49.8942161 L63.6710633,33.5415386 L100.588093,116.2353 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.1"
              />
              <path
                d="M114.93393,111.594962 C114.93393,111.594962 84.9774084,129.314367 40.2540753,125.316063 L4.59612693,38.3933263 L21.6831082,35.4580332 L80.7503556,25.31426 L114.264983,109.914905 L114.93393,111.594962 Z"
                id="Path"
                fill="#A6D8A9"
              />
              <path
                d="M114.264983,109.914905 C107.210283,110.260912 99.0522061,110.228234 90.8403055,109.249803 C81.9556135,108.207937 73.0286317,106.05116 65.3588088,102.102835 C58.9538339,98.8023121 53.4773113,95.2172947 48.7004918,90.9133515 C36.2807611,79.7411684 28.5936379,63.7594874 21.6907973,35.4580332 C18.7836574,18.403508 17.4476857,10.0999796 17.6828823,10.5474481 C19.3776623,13.771817 24.8229636,20.0629868 29.5129162,20.525002 C37.8593469,21.3472231 54.9410564,22.9436425 80.7580447,25.31426 L114.264983,109.914905 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.1"
              />
              <path
                d="M136.238352,105.055428 C136.238352,105.055428 114.147725,109.384361 91.6841783,106.718184 C82.7994862,105.670552 73.8628931,103.519542 66.2026816,99.5712167 C59.7977067,96.2706939 54.321184,92.6856765 49.5443645,88.3817332 C34.1662736,74.5414514 26.0524083,53.3542866 17.6828823,10.5474481 C17.6828823,10.5474481 54.3980745,19.4071508 90.6730688,0 C90.6730688,0 102.908262,76.3656774 136.238352,105.055428 Z"
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
              <path
                d="M91.6841783,106.718184 C82.7994862,105.670552 73.8628931,103.519542 66.2026816,99.5712167 C59.7977067,96.2706939 54.321184,92.6856765 49.5443645,88.3817332 C62.6330422,87.3187227 64.5149361,80.1621436 64.5149361,80.1621436 C74.2185115,95.5633017 91.6841783,106.718184 91.6841783,106.718184 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.1"
              />
              <path
                d="M36.6690579,26.2638571 C36.6690579,26.2638571 56.3914595,28.5148252 74.8509355,18.1423028 L76.1157834,22.2040411 C76.1157834,22.2040411 58.4559682,31.0099204 36.6690579,31.0099204 L36.6690579,26.2638571 Z"
                id="Path"
                fill="#83CA87"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M36.6690579,46.3111209 C36.6690579,46.3111209 56.2761239,44.7290998 78.1034017,34.1797294 L78.8415501,35.8674749 C78.8415501,35.8674749 70.8084198,45.9766474 36.6690579,48.6178346 L36.6690579,46.3111209 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M38.9930719,54.1308802 C38.9930719,54.1308802 58.6001378,52.5488591 80.4274157,42.0014109 L81.1655641,43.6891564 C81.1655641,43.6891564 73.1343561,53.8079403 38.9930719,56.4510496 L38.9930719,54.1308802 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M41.119093,62.5599963 C41.119093,62.5599963 60.7261589,60.9779752 82.5534368,50.430527 L83.2915851,52.1182725 C83.2915851,52.1182725 75.2488436,62.2312896 41.119093,64.8801657 L41.119093,62.5599963 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M43.6430221,69.7838545 C43.6430221,69.7838545 63.2500881,68.2018334 85.077366,57.6543853 L85.8155143,59.3421307 C85.8155143,59.3421307 77.7843063,69.4570701 43.6430221,72.1059462 L43.6430221,69.7838545 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M45.9689584,77.6113028 C45.9689584,77.6113028 65.5760244,76.0292817 87.4033022,65.4818336 L88.1414506,67.169579 C88.1414506,67.169579 80.1083203,77.2845184 45.9689584,79.9314723 L45.9689584,77.6113028 Z"
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.1"
              />
              <polygon
                id="Rectangle"
                fill="#83CA87"
                fillRule="nonzero"
                opacity="0.3"
                transform="translate(85.019250, 32.592925) rotate(-18.800000) translate(-85.019250, -32.592925) "
                points="82.1214414 29.6951158 87.9170594 29.6951158 87.9170594 35.4907339 82.1214414 35.4907339"
              />
              <polygon
                id="Rectangle"
                fill="#83CA87"
                fillRule="nonzero"
                opacity="0.3"
                transform="translate(88.140423, 40.755064) rotate(-18.800000) translate(-88.140423, -40.755064) "
                points="85.2426144 37.8572554 91.0382324 37.8572554 91.0382324 43.6528734 85.2426144 43.6528734"
              />
              <polygon
                id="Rectangle"
                fill="#83CA87"
                fillRule="nonzero"
                opacity="0.3"
                transform="translate(93.144042, 56.459833) rotate(-18.800000) translate(-93.144042, -56.459833) "
                points="90.2462327 53.5620242 96.0418507 53.5620242 96.0418507 59.3576423 90.2462327 59.3576423"
              />
              <polygon
                id="Rectangle"
                fill="#83CA87"
                fillRule="nonzero"
                opacity="0.3"
                transform="translate(96.014905, 64.888785) rotate(-18.800000) translate(-96.014905, -64.888785) "
                points="93.1170962 61.9909756 98.9127143 61.9909756 98.9127143 67.7865937 93.1170962 67.7865937"
              />
              <polygon
                id="Rectangle"
                fill="#83CA87"
                fillRule="nonzero"
                opacity="0.3"
                transform="translate(90.685262, 49.234140) rotate(-18.800000) translate(-90.685262, -49.234140) "
                points="87.7874532 46.3363312 93.5830712 46.3363312 93.5830712 52.1319493 87.7874532 52.1319493"
              />
              <path
                d="M85.0600656,33.014839 L82.466935,31.749991 C82.3922409,31.7135458 82.3350867,31.6489176 82.3080488,31.5703274 C82.2810109,31.4917373 82.2863047,31.4056246 82.3227654,31.3309381 L82.5976488,30.7657932 C82.6336994,30.6907318 82.6983103,30.6332353 82.7770505,30.6061458 C82.8557907,30.5790563 82.9421003,30.5846306 83.0167018,30.6216236 L84.7640374,31.4731854 L86.5882634,27.7324648 C86.6642089,27.5770444 86.8512079,27.5118521 87.0073164,27.5863729 L87.5724612,27.8631786 C87.6475226,27.8992292 87.7050191,27.9638401 87.7321086,28.0425803 C87.7591981,28.1213205 87.7536238,28.2076301 87.7166308,28.2822315 L85.4791186,32.8706694 C85.4426734,32.9453635 85.3780451,33.0025177 85.299455,33.0295556 C85.2208648,33.0565935 85.1347522,33.0512997 85.0600656,33.014839 L85.0600656,33.014839 Z"
                id="Path"
                fill="#3A913F"
                fillRule="nonzero"
              />
              <path
                d="M90.7249699,49.194513 L88.1318393,47.929665 C88.0571452,47.8932198 87.999991,47.8285915 87.9729531,47.7500014 C87.9459151,47.6714112 87.951209,47.5852986 87.9876697,47.510612 L88.262553,46.9454672 C88.3392287,46.7896768 88.5274454,46.725219 88.6835283,46.8012976 L90.4289416,47.6528594 L92.2512454,43.9082942 C92.3271909,43.7528738 92.5141899,43.6876815 92.6702984,43.7622023 L93.2335209,44.039008 C93.308554,44.0751075 93.3661324,44.1395882 93.3935427,44.2182126 C93.4209531,44.2968371 93.4159409,44.3831383 93.3796128,44.458061 L91.1421006,49.0464988 C91.0646804,49.2014407 90.8772762,49.2656203 90.7211253,49.1906684 L90.7249699,49.194513 Z"
                id="Path"
                fill="#3A913F"
                fillRule="nonzero"
              />
              <path
                d="M88.1818181,41.2190505 L85.5886875,39.9542026 C85.4332671,39.8782571 85.3680748,39.6912581 85.4425956,39.5351496 L85.7194013,38.9700047 C85.7554519,38.8949434 85.8200628,38.8374469 85.898803,38.8103574 C85.9775432,38.7832678 86.0638528,38.7888421 86.1384542,38.8258351 L87.8857898,39.6773969 L89.7100159,35.9309095 C89.7464611,35.8562154 89.8110893,35.7990612 89.8896795,35.7720233 C89.9682696,35.7449854 90.0543823,35.7502792 90.1290688,35.7867399 L90.6942137,36.0616233 C90.8491555,36.1390434 90.9133351,36.3264477 90.8383833,36.4825985 L88.5989488,41.0748809 C88.5625036,41.149575 88.4978753,41.2067293 88.4192852,41.2337672 C88.340695,41.2608051 88.2545824,41.2555112 88.1798958,41.2190505 L88.1818181,41.2190505 Z"
                id="Path"
                fill="#3A913F"
                fillRule="nonzero"
              />
              <path
                d="M93.1854644,56.4991062 L90.5923338,55.2342582 C90.5176397,55.197813 90.4604855,55.1331847 90.4334476,55.0545946 C90.4064097,54.9760044 90.4117035,54.8898918 90.4481642,54.8152052 L90.7230476,54.2500604 C90.7594928,54.1753663 90.824121,54.1182121 90.9027112,54.0911742 C90.9813014,54.0641362 91.067414,54.0694301 91.1421006,54.1058908 L92.8894362,54.9574526 L94.7136622,51.2167319 C94.7903854,51.0610618 94.977976,50.9959619 95.1346374,51.0706401 L95.69786,51.3474457 C95.7729214,51.3834963 95.8304179,51.4481073 95.8575074,51.5268474 C95.8845969,51.6055876 95.8790226,51.6918972 95.8420296,51.7664987 L93.6064397,56.3549366 C93.5290195,56.5098784 93.3416153,56.574058 93.1854644,56.4991062 L93.1854644,56.4991062 Z"
                id="Path"
                fill="#3A913F"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IllustrationDocuments;
