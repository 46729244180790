import GenericIconProps from './GenericIcon';

const PaperClipIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 8;
  const baseHeight = 13;
  const baseColor = 'none';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 8 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>53BBA29E-7BC3-469D-8382-F15159187A77</title>
      <g
        id="Consultation-dossier-📑"
        stroke="none"
        strokeWidth="1"
        fill={color || baseColor}
        fillRule="evenodd">
        <g
          id="Consultation-d'un-dossier/transferer-a-l'octroi/PJ/SG"
          transform="translate(-875.000000, -1043.000000)"
          fill="#4B4F54">
          <g id="chemise/revenus" transform="translate(264.000000, 806.000000)">
            <g
              id="titre-+-piece-demandees/3"
              transform="translate(120.000000, 24.000000)">
              <g id="statut/pj/1-copy-3" transform="translate(479.000000, 202.000000)">
                <g
                  id="Icon/24px/Action/timing"
                  transform="translate(12.754023, 11.276190)">
                  <path
                    d="M-1.77635684e-15,2.03174603 C-1.77635684e-15,0.909643683 1.07334034,0 2.39737274,0 C3.72140515,0 4.79474548,0.909643683 4.79474548,2.03174603 L4.79474548,9.34603175 C4.79474548,10.0192932 4.15074128,10.5650794 3.35632184,10.5650794 C2.5619024,10.5650794 1.91789819,10.0192932 1.91789819,9.34603175 L1.91789819,3.65714286 C1.91789819,3.43272239 2.13256626,3.25079365 2.39737274,3.25079365 C2.66217922,3.25079365 2.87684729,3.43272239 2.87684729,3.65714286 L2.87684729,9.34603175 C2.87684729,9.57045222 3.09151536,9.75238095 3.35632184,9.75238095 C3.62112832,9.75238095 3.83579639,9.57045222 3.83579639,9.34603175 L3.83579639,2.03174603 C3.8357964,1.59622226 3.5616344,1.19378169 3.11658457,0.976019804 C2.67153474,0.758257915 2.12321074,0.758257915 1.67816091,0.976019804 C1.23311108,1.19378169 0.958949086,1.59622226 0.958949097,2.03174603 L0.958949097,9.34603175 C0.958949097,10.4681341 2.03228943,11.3777778 3.35632184,11.3777778 C4.68035424,11.3777778 5.75369458,10.4681341 5.75369458,9.34603175 L5.75369458,3.65714286 C5.75369458,3.43272239 5.96836265,3.25079365 6.23316913,3.25079365 C6.49797561,3.25079365 6.71264368,3.43272239 6.71264368,3.65714286 L6.71264368,9.34603175 C6.71264368,10.916975 5.20996721,12.1904762 3.35632184,12.1904762 C1.50267647,12.1904762 -1.77635684e-15,10.916975 -1.77635684e-15,9.34603175 L-1.77635684e-15,2.03174603 Z"
                    id="paperclip"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PaperClipIcon;
