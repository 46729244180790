export const TITLE = 'Situation foyer';
export const SUB_TITLE =
  'Ces informations vont nous permettre de comprendre la situation actuelle de votre client.';
export const SITUATION_FOYER = 'Informations de la résidence principale';
export const SITUATION_FOYER_BORROWER = 'Information familiale emprunteur';
export const SITUATION_FOYER_COBORROWER = 'Information familiale co-emprunteur';
export const LOGEMENT = 'Information du logement';
export const NB_PEOPLE_FOYER = 'Nombre de personnes composant le foyer';
export const ENFANTS_CHARGE = 'Des enfants à charge';
export const INFO_ENFANTS_YEAR = "Renseigner l'année de naissance des enfants ";
export const INFO_ENFANTS_STYLED = 'du plus jeune au plus âgé';
export const INFO_ENFANTS_ALLOC =
  'ce qui permettra de calculer les allocations familiales.';
export const FAMILLE_SITUATION_COBORROWER =
  'La situation familiale du co-emprunteur est identique à celle de l’emprunteur';
