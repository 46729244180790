import ChevronLeft from 'icons/ChevronLeft';
import ChevronRight from 'icons/ChevronRight';

interface PaginatorProps {
  currentpage: number;
  setCurrentPage: (newCurrentPage: number) => void;
  maxpage: number;
}

const Paginator: React.FC<PaginatorProps> = ({
  currentpage,
  setCurrentPage,
  maxpage,
}) => {
  const pageLabel = `Page ${currentpage + 1} sur ${maxpage}`;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '3.2rem',
      }}>
      <button
        type="button"
        tabIndex={0}
        style={{ cursor: 'pointer', background: 'none', border: 'hidden' }}
        onClick={() => {
          if (currentpage - 1 >= 0) setCurrentPage(currentpage - 1);
        }}
        onKeyDown={() => {
          if (currentpage - 1 >= 0) setCurrentPage(currentpage - 1);
        }}>
        <ChevronLeft />
      </button>
      <span
        style={{
          color: '#292C2E',
          fontFamily: 'Open Sans',
          fontSize: '16px',
          fontWeight: 'bold',
        }}>
        {pageLabel}
      </span>
      <button
        type="button"
        tabIndex={0}
        style={{ cursor: 'pointer', background: 'none', border: 'hidden' }}
        onClick={() => {
          if (currentpage <= maxpage - 2) setCurrentPage(currentpage + 1);
        }}
        onKeyDown={() => {
          if (currentpage <= maxpage - 2) setCurrentPage(currentpage + 1);
        }}>
        <ChevronRight />
      </button>
    </div>
  );
};

export default Paginator;
