export const TITLE_CHEMISE_UPLOADABLE =
  'Pièces justificatives à fournir par le partenaire';
export const SUB_TITLE_CHEMISE_UPLOADABLE =
  'Veuillez déposer les pièces justificatives de votre client pour finaliser le dossier.';
export const ACCEPTED_FORMAT_TEXT = 'Formats et tailles acceptés : PDF, JPEG - 19 Mo';
export const ACCEPTED_FORMAT_TEXT_FOR_SG =
  'Formats et tailles acceptés : PDF, JPEG, WORD, EXCEL (sans macro), PNG - 19 Mo.';
export const TITLE_CHEMISE_NON_UPLOADABLE =
  'Pièces justificatives à fournir par un tiers';
export const SUB_TITLE_CHEMISE_NON_UPLOADABLE =
  'Les pièces justificatives à fournir par un tiers (votre client, l’organisme d’assurance, etc…).';

// EMISSION PACK CONTRACTUEL

export const TITLE_CONTRACT_DOCUMENTS = 'Emettre le pack contractuel';
export const SUB_TITLE_CONTRACT_DOCUMENTS =
  'L’édition du pack contractuel est possible uniquement en profil étude partagée.';
export const INFO_TEXT_CONTRACT_DOCUMENTS =
  'Vérifiez les informations ci-dessous, indispensables pour l’envoi et la conformité juridique du pack ';
export const EDIT_TYPE_CATEGORY_TITLE = 'Choix du mode d’édition de l’offre';
export const ADDRESS_INFO_CATEGORY_TITLE = 'Informations du domicile ';

export const RIB_TITLE = 'Relevé d’Identité Bancaire de prélèvement (RIB)';
export const COLLECTION_DAY_SUBTITLE = 'Choisir le jour de prélèvement';
export const RIB_SUBTITLE = 'Relevé d’Identité Bancaire (RIB)';
export const RIB_CONTAINER_TITLE = 'Informations du Relevé d’Identité Bancaire (RIB)';
export const UPLOAD_SUCCESS_MSG =
  'Vos pièces justificatives ont bien été transmises à l’octroi pour étude';

export const FAILED_EMISSION_MODAL_TITLE = 'Erreur d’édition du pack ';
export const FAILED_EMISSION_MODAL_CLOSE_BUTTON = 'Fermer';
export const FAILED_EMISSION_MODAL_CONTINUE_BUTTON = 'Terminer sans éditer le pack';
