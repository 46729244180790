export const TITLE = 'Finalisation du projet';
export const SUB_TITLE =
  'Les données complémentaires vont nous permettre de finaliser le projet.';
export const INFO_COMPL_TEXT =
  'En renseignant correctement les informations ci-dessous vous optimisez le délai d’édition de l’offre de prêt.';
export const EDITION_OFFER_TEXT =
  "Mode d'édition du pack contractuel souhaité par le client";
export const PACK_CONTRACTUEL_TEXT = 'Souhaitez-vous émettre le pack contractuel ?';
export const IDENTITY_TEXT = "Informations de la pièce d'identité emprunteur";
export const IDENTITY_TEXT_COBORROWER =
  "Informations de la pièce d'identité co-emprunteur";
export const IDENTITY_LABEL = "Type de pièce d'identité";
export const IDENTITY_PLACEHOLDER = "Choisir le type de pièce d'identité";
export const ID_NUMBER_LABEL = "N° pièce d'identité";
export const ADDITIONAL_ADDRESS_LABEL =
  "Complément d'adresse<span className='lightText'>(Facultatif)</span>";
export const CELL_PHONE_LABEL = 'Numéro de mobile';
export const HOME_PHONE_LABEL = 'Numéro du domicile';
export const INFO_TEXT_TITLE =
  "Ces données sont essentielles pour la conformité et l'envoi de l'offre";
export const ADDRESS_TITLE = 'Informations complémentaires du domicile';
export const CONTACT_TITLE = 'Informations des coordonnées emprunteur';
export const CONTACT_TITLE_COBORROWER = 'Informations des coordonnées co-emprunteur';
export const EMPLOYER_PLACEHOLDER = "Nom de l'employeur";
export const EMPLOYER_TITLE =
  'Informations complémentaires de la situation professionnelle emprunteur';
export const EMPLOYER_TITLE_FOR_COBORROWER =
  'Informations complémentaires de la situation professionnelle co-emprunteur';
export const HABILITATION_EP =
  'Votre habilitation Etude Partagée ne vous autorise pas à transférer un dossier non éligible au processus Etude Partagée. Si vous forcez le transfert, ce dossier sera traité dans le processus classique. Souhaitez-vous forcer le transfert ?';
export const HABILITATION_STANDARD =
  'Votre habilitation Standard ne vous autorise pas à transférer un dossier éligible au processus Etude Partagée. Si vous forcez le transfert, ce dossier sera traité dans le processus classique. Souhaitez-vous forcer le transfert ? ';

export const DEBT_CARD_TITLE = 'Détail du découvert bancaire';
export const DEBT_CARD_ACCOUNT_HOLDER_LABEL = 'Titulaire du compte';
export const DEBT_CARD_AMOUNT_LABEL = 'Montant';
export const DEBT_CARD_BANK_ACCOUNT_LABEL = 'Compte bancaire';
export const DEBT_CARD_ACCOUNT_NUMBER_LABEL = 'N° Compte';
export const DEBT_CARD_DUE_DATE_LABEL = "Date d'exigibilité";
export const DEBT_CARD_DELETE_BUTTON_LABEL = 'Supprimer';
export const DEBT_CARD_ADD_BANK_ACCOUNT_LABEL = 'Ajouter un compte bancaire';

export const INCORRECT_DATA =
  'Les données de ce bloc sont incorrectes, merci de les verifier.';

export const GUARANTEE = {
  complementary_infos: 'Informations complémentaires des biens pris en garantie',
  address_same: `L'adresse du bien est-elle identique à celle de l'emprunteur ? `,
};
