import { BasicQueryStringUtils, LocationLike } from '@openid/appauth';

class NoHashQueryStringUtils extends BasicQueryStringUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse(input: LocationLike, useHash: boolean): any {
    return super.parse(input, false);
  }
}

export default NoHashQueryStringUtils;
