import * as React from 'react';
import GenericIconProps from './GenericIcon';

const ErrorIcon: React.FC<GenericIconProps> = ({ color = '#C8102E' }) => (
  <svg width={20} height={18} xmlns="http://www.w3.org/2000/svg">
    <title>12949D31-2F05-4C15-AB26-A80519DEE05A</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10.053 0a2.034 2.034 0 0 1 1.688.89l.09.145 7.872 13.678c.395.685.396 1.504.004 2.19A2.144 2.144 0 0 1 17.826 18H2.124c-.769 0-1.456-.401-1.84-1.074a2.13 2.13 0 0 1 .01-2.144L8.272 1.028A2.035 2.035 0 0 1 10.05 0h.003Zm-.018 13.067c-.579 0-1.05.474-1.05 1.057 0 .584.471 1.058 1.05 1.058.578 0 1.05-.474 1.049-1.058 0-.583-.47-1.057-1.05-1.057Zm-.053-7.695c-.47 0-.85.384-.85.858v5.448c0 .474.38.858.85.858s.85-.384.85-.858V6.23a.854.854 0 0 0-.85-.858Z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default ErrorIcon;
