import { Bordereau, Partner } from 'types';

export const sortAscending = (
  listToSort: Partner[] | Bordereau[],
  sortingKey: string,
): Partner[] | Bordereau[] => {
  return listToSort?.sort((item1, item2) => {
    if (item1[sortingKey] > item2[sortingKey]) {
      return -1;
    }
    return 0;
  });
};
export const sortDescending = (
  listToSort: Partner[] | Bordereau[],
  sortingKey: string,
): Partner[] | Bordereau[] => {
  return listToSort?.sort((item1, item2) => {
    if (item1[sortingKey] < item2[sortingKey]) {
      return -1;
    }
    return 0;
  });
};

export const handleBordereauFilter = (
  bordereaux: Bordereau[],
  text: string,
  filteringKey: string,
  setState: (value: React.SetStateAction<Bordereau[]>) => void,
): void => {
  if (!text) {
    setState(bordereaux); // si aucun texte, on revient à l'état initial
  } else {
    const filteredResult = bordereaux.filter(item => {
      return item[filteringKey].toLowerCase().indexOf(text.toLowerCase()) >= 0;
    });
    setState(filteredResult);
  }
};

export const handleDateFilterChange = (
  filter: string,
  setSearchStartDate: (value: React.SetStateAction<Date>) => void,
  setSearchEndDate: (value: React.SetStateAction<Date>) => void,
): void => {
  if (filter === 'lastYear') {
    let newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() - 1);
    newDate.setMonth(0);
    setSearchStartDate(newDate);

    newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() - 1);
    newDate.setMonth(11);
    setSearchEndDate(newDate);
  }
  if (filter === 'lastMonth') {
    let newDate = new Date();
    newDate.setMonth(newDate.getMonth() - 1);
    setSearchStartDate(newDate);
    newDate = new Date();
    newDate.setMonth(newDate.getMonth() - 1);
    setSearchEndDate(newDate);
  }
};
