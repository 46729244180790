import * as React from 'react';

const ClockIcon: React.FC = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <title>Ic/24px/Action/clock-fast24px</title>
    <g transform="translate(2 2)" fill="#28883C" fillRule="nonzero">
      <path d="M18.4 9.275h-4.968a.476.476 0 0 1-.471-.485c0-.269.21-.485.471-.485H18.4c.262 0 .471.216.471.485 0 .27-.209.485-.47.485ZM19.529 12.042h-5.422a.476.476 0 0 1-.471-.485c0-.27.21-.485.47-.485h5.423c.262 0 .471.215.471.485s-.21.485-.471.485ZM18.4 14.58h-4.968a.476.476 0 0 1-.471-.484c0-.27.21-.485.471-.485H18.4c.262 0 .471.215.471.485s-.209.485-.47.485ZM8.522 17.713c-.174 0-.36-.006-.547-.018-2.932-.216-4.98-1.988-5.625-4.875-.611-2.754.867-5.036 2.327-6.15 1.582-1.209 3.682-1.538 5.625-.88 1.478.503 2.228 1.51 2.263 1.551a.497.497 0 0 1-.093.683.464.464 0 0 1-.663-.096c-.006-.006-.617-.814-1.803-1.215-1.653-.563-3.433-.288-4.765.736-1.227.94-2.478 2.85-1.966 5.15.54 2.437 2.28 3.94 4.77 4.12 2.822.203 3.933-1.444 3.98-1.515a.467.467 0 0 1 .65-.138c.216.15.28.449.135.67-.053.09-1.286 1.977-4.288 1.977Z" />
      <path d="M8.458 20c-2.996 0-6.824-1.976-8.016-5.635-.99-3.024-.163-5.305.965-7.311a9.542 9.542 0 0 1-1.11-1.186c-.164-.21-.367-.67.115-1.395.262-.383 1.222-1.437 1.868-1.216.314.108 1.041.683 1.582 1.132.471-.281 1.466-.79 2.88-1.078v-.533l-.815-.077a.486.486 0 0 1-.43-.45l-.11-1.73a.472.472 0 0 1 .127-.365A.474.474 0 0 1 5.858 0h4.52c.262 0 .471.216.471.485v1.73a.479.479 0 0 1-.436.486l-.884.077.075.899a.48.48 0 0 1-.43.527.475.475 0 0 1-.512-.444l-.11-1.383a.477.477 0 0 1 .11-.353.46.46 0 0 1 .32-.168l.919-.078V.976H6.358l.052.796.838.078c.244.024.43.234.43.485V3.72a.48.48 0 0 1-.4.479C5.263 4.52 4.1 5.37 4.088 5.378a.46.46 0 0 1-.576-.019c-.535-.455-1.198-.976-1.466-1.137a2.48 2.48 0 0 0-.587.467c-.285.293-.39.515-.413.604.61.773 1.239 1.258 1.245 1.264.192.15.244.425.122.64C.639 10.217.698 12.12 1.326 14.055c1.053 3.227 4.456 4.97 7.12 4.97 3.101 0 4.806-2.21 4.823-2.234a.469.469 0 0 1 .663-.09.501.501 0 0 1 .088.683C13.95 17.497 12.042 20 8.458 20Z" />
      <path d="M13.88 6.743a.483.483 0 0 1-.384-.198C12.641 5.34 10.517 4.21 9.133 4.21a.476.476 0 0 1-.471-.485c0-.27.21-.485.47-.485 1.665 0 4.108 1.305 5.126 2.736a.498.498 0 0 1-.099.683.51.51 0 0 1-.279.084ZM9.25 11.353a.464.464 0 0 1-.373-.191.496.496 0 0 1 .087-.683L11 8.862a.463.463 0 0 1 .664.09.496.496 0 0 1-.088.683L9.54 11.25a.479.479 0 0 1-.29.102Z" />
      <ellipse cx={8.278} cy={11.581} rx={1.152} ry={1.18} />
    </g>
  </svg>
);

export default ClockIcon;
