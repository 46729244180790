import styled from 'styled-components';
import SelectedMainNavBar from './selectedMainNavBar.png';

export const StyledStatusDossierContainer = styled.div`
  height: 48px;
  // width: 324px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding-right: 1.6rem;
`;

export const StyledAccentContainer = styled.div`
  height: 48px;
  width: 8px;
  background-color: #6f757c;
  border-radius: 0.8rem 0 0 0.8rem;
  margin-right: 1.6rem;
`;

export const StyledStatutText = styled.span`
  color: #6f757c;
  font-family: 'Open Sans';
  font-size: 14px;
  margin-right: 0.8rem;
`;

export const StyledFileInfoContainer = styled.div`
  // height: 40px;
  width: 864px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: #f2f3f3;
  padding: 0.9rem 1.6rem 0.9rem 1.6rem;
`;

export const StyledCreationDossierText = styled.span`
  color: #4b4f54;
  font-size: 12px;
  font-family: 'Open Sans';
`;

export const StyledFileInfoLabel = styled.span`
  color: #4b4f54;
  font-size: 14px;
  margin-right: 0.8rem;
`;

export const StyledLoanSummaryContainer = styled.div`
  height: 72px;
  display: flex;
`;

export const StyledLoanSummaryMainCard = styled.div`
  height: 72px;
  width: 145px;
  border-radius: 4px;
  background-color: #def1db;
  padding-top: 0.9rem;
`;

export const StyledLoanSummaryMainCardLabel = styled.span`
  color: #3a3d41;
  font-size: 14px;
`;

export const StyledLoanSummaryMainCardData = styled.span`
  color: #292c2e;
  font-size: 24px;
  font-weight: bold;
`;

export const StyledResumeButtonText = styled.span`
  margin-left: 0.8rem;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
`;

export const StyledCurrentStepLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 12px;
`;

export const StyledLoanSummaryMainCardDetail = styled.span`
  color: #3a3d41;
  font-size: 12px;
`;

export const StyledLoanSummaryCard = styled.div`
  height: 72px;
  border-radius: 4px;
  background-color: #f2f9f1;
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem 0 3.2rem;
`;

export const StyledLoanSummaryCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledLoanSummaryCardLabel = styled.span`
  color: #3a3d41;
  font-size: 14px;
`;

export const StyledLoanSummaryCardData = styled.span`
  color: #292c2e;
  font-size: 16px;
  font-weight: bold;
`;

export const StyledLoanDetailData = styled.span`
  color: #292c2e;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledProjectDetailsLongContainer = styled.div`
  height: 36px;
  background-color: #f2f9f1;
  padding: 8px 15px 8px 15px;
  display: flex;
  gap: 0.8rem;
  white-space: nowrap;
`;

export const StyledProjectDetailsShortContainer = styled.div`
  height: 36px;
  background-color: #f2f9f1;
  padding: 8px 15px 8px 15px;
  display: flex;
  gap: 0.8rem;
`;

export const StyledMainTitle = styled.span`
  height: 29px;
  color: #3a3d41;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
`;

export const StyledSecondaryTitle = styled.span`
  height: 29px;
  color: #6f757c;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
`;

export const StyledHorizontalBar = styled.div<{
  color?: string;
  width?: string;
}>`
  height: 0.3rem;
  border-bottom-width: ${props => (props.width ? props.width : '0.1rem')};
  border-bottom-color: ${props => (props.color ? props.color : '#008859')};
  border-bottom-style: solid;
  margin-right: 2.7rem;
  background-color: ${props => (props.color ? props.color : '#008859')};
  border-radius: 0.4rem;
`;

export const StyledMainNavBarContainer = styled.div`
  box-sizing: border-box;
  height: 59px;
  width: 864px;
  border: 1px solid #4b4f54;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;

export const StyledNavBarTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  z-index: 0;
`;

export const StyledSelectedNavBarTile = styled.div`
  width: 21.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem;
  border-left: 1px solid #4b4f54;
  background-image: url(${SelectedMainNavBar});
  background-position-x: -0.1rem;
  background-position-y: -0.1rem;
  background-repeat: no-repeat;
  z-index: 1;
  border-radius: 0.4rem;
`;

export const StyledInactiveNavBarText = styled.span`
  color: #6f757c;
  font-family: 'Open Sans';
  font-size: 16px;
`;

export const StyledActiveNavBarText = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
`;

export const StyledSelectedNavBarText = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
`;

export const StyledSecondaryNavBarContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const StyledSecondaryNavBarTile = styled.div`
  box-sizing: border-box;
  height: 26px;
  border: 1px solid #4b4f54;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem 0 1.6rem;
`;

export const StyledSelectedSecondaryNavBarTile = styled.div`
  box-sizing: border-box;
  height: 26px;
  border: 1px solid #008859;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem 0 1.6rem;
  background: #008859;
`;

export const StyledInactiveSecondaryNavBarTile = styled.div`
  box-sizing: border-box;
  height: 26px;
  border: 1px solid #b1b5b9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem 0 1.6rem;
  background: #f2f3f3;
`;

export const StyledSecondaryNavBarText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const StyledSelectedSecondaryNavBarText = styled.span`
  color: #fff;
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const StyledMainDisplayCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding: 2.4rem 3.2rem 3.2rem 3.2rem;
`;

export const StyledCardTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
`;

export const StyledCardRow = styled.div`
  display: flex;
  gap: 4rem;
`;

export const StyledCardTile = styled.div<{
  width?: string;
}>`
  display: flex;
  min-width: ${props => (props.width ? props.width : 'auto')};}
  flex-direction: column;
`;

export const StyledCardLabel = styled.span`
  color: #6f757c;
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const StyledCardData = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
`;

export const StyledTotalLabel = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
`;

export const StyledTotalData = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: bold;
`;

export const StyledNoOtherIncomeContainer = styled.div`
  height: 4.6rem;
  border-radius: 0.4rem;
  background-color: #f2f3f3;
  padding: 1.2rem 0 1.2rem 2.4rem;
`;

export const StyleNoDataContainer = styled.div`
  color: #292c2e;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
`;

export const StyleCardGrid = styled.div`
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
`;
export const StyleCardGridItem = styled.div`
  display: flex;
  width: calc(33% - 4rem);
  flex-direction: column;
`;

export const StyleNoChildContainer = styled.div`
  height: 4.8rem;
  width: 62.3rem;
  border-radius: 4px;
  background-color: #f2f3f3;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 1.2rem 1.2rem 1.2rem 2.4rem;
`;

export const StyleNavBarPJContainer = styled.div`
  height: 56px;
  width: 864px;
  display: flex;
  justify-content: space-between;
`;

export const StyledSelectedNavBarPJTile = styled.div`
  box-sizing: border-box;
  height: 56px;
  width: 433px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #008859;
  background-color: #f2f9f1;
`;

export const StyledNavBarPJTile = styled.div`
  box-sizing: border-box;
  height: 56px;
  width: 433px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #6f757c;
  background-color: #ffffff;
`;

export const StyledPJNavBarText = styled.span`
  color: #6f757c;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: bold;
`;

export const StyledSelectedPJNavBarText = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: bold;
`;

export const StyledStatusPieceContainer = styled.div<{
  color: string;
}>`
  display flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  background-color: ${props => props.color};
  gap: 0.4rem;
`;

export const StyledStatusPieceIconContainer = styled.div`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
`;

export const StyledStatusPieceText = styled.div`
cu
white-space: nowrap;
color: #4b4f54;
font-family: 'Open Sans';
font-size: 14px;
text-decoration: none;
margin-right: 0.8rem;
`;

export const StyledESIgnFollowupContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledESignFollowupStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const StyledESignFollowupInfoBubble = styled.div<{
  backgroundColor: string;
  borderColor: string;
}>`
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid ${props => props.borderColor};
  border-radius: 8px;
  padding: 1.6rem 1.6rem 1.6rem 1.8rem;
`;

export const StyledESignFollowupInfoBubbleText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
`;

export const StyledESignUpdateLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const StyledESignRefreshLabel = styled.div`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;
