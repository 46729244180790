import React, { useState } from 'react';
import CustomInput from 'components/customInput/CustomInput';
import { ValidationRules, checkPercentageValue } from 'utils/InputValidation';
import {
  CalculetteFinanciereAction,
  Proposition,
} from 'reducers/calculetteFinanciere/type';
import { StyledSectionTitle, StyledMarginDiv } from '../style';
import * as messages from '../messages';

interface SimulationFormProps {
  propositionState: Proposition;
  calculetteFinanciereDispatch: React.Dispatch<CalculetteFinanciereAction>;
}

const SimulationForm: React.FC<SimulationFormProps> = ({
  propositionState,
  calculetteFinanciereDispatch,
}) => {
  const [isLoanDurationInputValid, setIsLoanDurationInputValid] = useState<boolean>();
  const [isLoanDurationInputFocused, setIsLoanDurationInputFocused] =
    useState<boolean>(false);

  const [isFranchiseDurationInputValid, setIsFranchiseDurationInputValid] =
    useState<boolean>();
  const [isNominalInterestRateInputFocused, setIsNominalInterestRateInputFocused] =
    useState<boolean>(false);

  const [isNominalInterestRateInputValid, setIsNominalInterestRateInputValid] =
    useState<boolean>();
  const [isFranchiseDurationInputFocused, setIsFranchiseDurationInputFocused] =
    useState<boolean>(false);

  const [nominalInterestValue, setNominalInterestValue] = useState<
    string | undefined | number
  >(propositionState?.tauxNominal || '');

  const realNumbers = new RegExp(ValidationRules.realNumbers);

  const handleLoanDuration = (value: string) => {
    const rate = parseInt(value.slice(0, 3), 10);
    if (Number.isNaN(rate)) {
      calculetteFinanciereDispatch({
        type: 'setDureeSouhaitee',
        payload: undefined,
      });
    } else {
      calculetteFinanciereDispatch({
        type: 'setDureeSouhaitee',
        payload: rate,
      });
    }
  };

  const handleFranchiseDuration = (value: string) => {
    const rate = parseInt(value.slice(0, 3), 10);
    if (Number.isNaN(rate)) {
      calculetteFinanciereDispatch({
        type: 'setDureeFranchise',
        payload: undefined,
      });
    } else {
      calculetteFinanciereDispatch({
        type: 'setDureeFranchise',
        payload: rate,
      });
    }
  };

  const handleBorrowingRateChange = (value: string) => {
    if (!realNumbers.test(value)) {
      calculetteFinanciereDispatch({
        type: 'setTauxNominal',
        payload: undefined,
      });
      return;
    }
    if (checkPercentageValue(value)) {
      calculetteFinanciereDispatch({
        type: 'setTauxNominal',
        payload: undefined,
      });
      return;
    }

    setNominalInterestValue(value);
    const rate = Number(value.replaceAll(',', '.'));
    if (Number.isNaN(rate)) {
      calculetteFinanciereDispatch({
        type: 'setTauxNominal',
        payload: undefined,
      });
    } else {
      calculetteFinanciereDispatch({
        type: 'setTauxNominal',
        payload: rate,
      });
    }
  };

  const onLoanDurationBlur = () => {
    if (propositionState.dureeSouhaitee !== undefined) setIsLoanDurationInputValid(true);
  };

  const onFranchiseDurationBlur = () => {
    if (propositionState.dureeFranchise !== undefined)
      setIsFranchiseDurationInputValid(true);
  };

  const onNominalInterestRateBlur = () => {
    if (propositionState.tauxNominal !== undefined) {
      setIsNominalInterestRateInputValid(true);
    }
  };

  return (
    <>
      <StyledSectionTitle>{messages.CALCULATOR_FORM.simulationTitle}</StyledSectionTitle>
      <StyledMarginDiv>
        <CustomInput
          name="loan-duration"
          label={messages.CALCULATOR_loanDuration}
          value={propositionState.dureeSouhaitee}
          onChange={newValue => {
            handleLoanDuration(newValue);
          }}
          isValid={isLoanDurationInputValid}
          isFocused={isLoanDurationInputFocused}
          onBlur={() => {
            setIsLoanDurationInputFocused(false);
            onLoanDurationBlur();
          }}
          onFocus={() => {
            setIsLoanDurationInputFocused(true);
          }}
          after="mois"
          inputWidth="40rem"
          placeholder="0"
        />
      </StyledMarginDiv>
      <StyledMarginDiv>
        <CustomInput
          name="franchise-duration"
          label={messages.CALCULATOR_franchiseDuration}
          value={propositionState.dureeFranchise}
          onChange={newValue => {
            handleFranchiseDuration(newValue);
          }}
          isValid={isFranchiseDurationInputValid}
          isFocused={isFranchiseDurationInputFocused}
          onBlur={() => {
            setIsFranchiseDurationInputFocused(false);
            onFranchiseDurationBlur();
          }}
          onFocus={() => {
            setIsFranchiseDurationInputFocused(true);
          }}
          after="mois"
          inputWidth="40rem"
          placeholder="0"
        />
      </StyledMarginDiv>
      <StyledMarginDiv>
        <CustomInput
          name="borrowing-rate"
          label="Taux d'intérêt nominal"
          value={nominalInterestValue}
          onChange={newValue => {
            handleBorrowingRateChange(newValue);
          }}
          isValid={isNominalInterestRateInputValid}
          isFocused={isNominalInterestRateInputFocused}
          onBlur={() => {
            setIsNominalInterestRateInputFocused(false);
            onNominalInterestRateBlur();
          }}
          onFocus={() => {
            setIsNominalInterestRateInputFocused(true);
          }}
          after="%"
          inputWidth="17.2rem"
          placeholder="0"
        />
      </StyledMarginDiv>
    </>
  );
};

export default SimulationForm;
