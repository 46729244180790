import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { useEffect, useState } from 'react';
import {
  SituationProfessionnelleAction,
  SituationProfessionnelleState,
} from 'reducers/situationProfessionnelle/types';
import { StatusInput, TypeContrat } from 'types';
import { getNotEmpty } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface JobContractProps {
  state: SituationProfessionnelleState;
  dispatch: React.Dispatch<SituationProfessionnelleAction>;
  typeContratList: TypeContrat[];
  person: 'borrower' | 'coBorrower';
  checkInputs: boolean;
}
const JobContract: React.FC<JobContractProps> = ({
  state,
  dispatch,
  typeContratList,
  person,
  checkInputs,
}) => {
  const [statusContractBorrower, setStatusContractBorrower] = useState<StatusInput>();
  const [statusContractCoBorrower, setStatusContractCoBorrower] = useState<StatusInput>();

  const checkStatusContract = (typeParam: 'borrower' | 'coBorrower') => {
    const type =
      typeParam === 'borrower'
        ? state.borrower.typeContrat
        : state.coBorrower.typeContrat;
    if (person && type) {
      const contratMatchesListElement = typeContratList?.find(contrat => {
        return contrat.libelle.toUpperCase() === type.libelle.toUpperCase();
      });
      if (contratMatchesListElement) {
        dispatch({
          type: 'setTypeContrat',
          payload: { person: typeParam, value: contratMatchesListElement },
        });
        if (typeParam === 'borrower')
          setStatusContractBorrower({
            status: 'valid',
            errorMessage: ``,
          });
        else
          setStatusContractCoBorrower({
            status: 'valid',
            errorMessage: ``,
          });
      } else {
        dispatch({
          type: 'setTypeContrat',
          payload: {
            person: typeParam,
            value: {
              code: 'err',
              libelle: type.libelle,
              ordreString: '',
            },
          },
        });
        if (typeParam === 'borrower')
          setStatusContractBorrower({
            status: 'invalid',
            errorMessage: messagesCheckInput.INPUT_ERROR,
          });
        else
          setStatusContractCoBorrower({
            status: 'invalid',
            errorMessage: messagesCheckInput.INPUT_ERROR,
          });
      }
    }
    if (!type) {
      if (typeParam === 'borrower')
        setStatusContractBorrower({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
      else
        setStatusContractCoBorrower({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
    }
  };

  useEffect(() => {
    if (state.borrower.typeContrat) {
      checkStatusContract('borrower');
    }
    if (state.coBorrower.typeContrat) {
      checkStatusContract('coBorrower');
    }
  }, [typeContratList]);

  useEffect(() => {
    if (checkInputs) {
      checkStatusContract(person);
    }
  }, [checkInputs]);

  const onTypeContratSelect = (type: 'borrower' | 'coBorrower', e: Event) => {
    const selectedTypeContrat = (e as CustomEvent).detail.value as TypeContrat;
    dispatch({
      type: 'setTypeContrat',
      payload: { person: type, value: selectedTypeContrat },
    });
  };

  const handleOnBlur = (type: 'borrower' | 'coBorrower', event: Event) => {
    if (event && event.target) {
      const contratMatchesListElement = typeContratList?.find(contrat => {
        return (
          contrat.libelle.toUpperCase() ===
          ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
        );
      });
      if (contratMatchesListElement) {
        dispatch({
          type: 'setTypeContrat',
          payload: { person: type, value: contratMatchesListElement },
        });
      } else {
        dispatch({
          type: 'setTypeContrat',
          payload: {
            person: type,
            value: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
              ordreString: '',
            },
          },
        });
      }
    }
  };
  return (
    <ComboInput
      class="combo-input show-input-ds-validation"
      list-on-open
      shadow
      align-items="left"
      items={JSON.stringify(typeContratList)}
      field="libelle"
      label="Type de contrat"
      placeholder="Choisir le type de contrat"
      onSelectedList={e => {
        onTypeContratSelect(person, e);
      }}
      onListBlur={e => {
        handleOnBlur(person, e);
      }}
      value={
        person === 'borrower'
          ? state.borrower.typeContrat?.libelle || ''
          : state.coBorrower.typeContrat?.libelle || ''
      }
      status={
        person === 'borrower'
          ? statusContractBorrower?.status
          : statusContractCoBorrower?.status
      }
      error-message={
        person === 'borrower'
          ? statusContractBorrower?.errorMessage
          : statusContractCoBorrower?.errorMessage
      }
      customValidityPattern={JSON.stringify(getNotEmpty('Type de contrat'))}
    />
  );
};

export default JobContract;
