const CheckInCircle: React.FC<{ className?: string; color?: string; width?: number }> = ({
  className,
  color,
  width,
}) => {
  const baseWidth = 22;
  const baseHeight = 20;

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 22 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>2D251EC2-D7E6-4389-91A9-6ED547810E59</title>
      <g
        id="Parcours-montage-dossier-🗂"
        stroke="none"
        strokeWidth="1"
        fill={color}
        fillRule="evenodd">
        <g
          id="Simulation/-résultat"
          transform="translate(-250.000000, -354.000000)"
          fill={color || '#268038'}>
          <g
            id="Icon/Action/check2-circle/24px"
            transform="translate(250.000000, 354.000000)">
            <path
              d="M2.99199605,2.92877245 C6.26081469,-0.271263804 11.3174571,-0.922936384 15.3207975,1.33990356 C15.6933727,1.55374198 15.8197987,2.02190094 15.6038542,2.38807031 C15.3879097,2.75423969 14.9105679,2.8811161 14.5351278,2.67213546 C11.8609612,1.16059492 8.56622192,1.16072102 5.89217607,2.67246627 C3.21813023,4.18421151 1.57109532,6.97786834 1.57157832,10.0009493 C1.57157832,14.6738621 5.44089752,18.4620064 10.2139446,18.4620064 C14.9869918,18.4620064 18.856311,14.6738621 18.856311,10.0009493 C18.856311,9.57613905 19.2080673,9.2317623 19.6419806,9.2317623 C20.075894,9.2317623 20.4276503,9.57613905 20.4276503,10.0009493 C20.4270566,14.5266292 17.3217571,18.487712 12.8564959,19.6586231 C8.39123475,20.8295341 3.6793608,18.9183236 1.36828825,14.9988245 C-0.942784296,11.0793254 -0.276822582,6.1288087 2.99199605,2.92877245 Z M20.6570658,1.76449479 C20.8557963,1.56993352 21.1454522,1.49394869 21.4169231,1.56516306 C21.688394,1.63637743 21.9004368,1.84397183 21.9731772,2.10974746 C22.0459176,2.3755231 21.9683045,2.65910233 21.7695741,2.8536636 L21.7695741,2.8536636 L10.7701988,13.6222818 C10.6228004,13.7669531 10.4226613,13.8482593 10.2139446,13.8482593 C10.0052279,13.8482593 9.80508885,13.7669531 9.65769052,13.6222818 L9.65769052,13.6222818 L4.94367253,9.00715969 C4.74494207,8.81259842 4.66732898,8.52901919 4.74006938,8.26324355 C4.81280977,7.99746792 5.02485265,7.78987351 5.29632351,7.71865914 C5.56779436,7.64744478 5.85745032,7.72342961 6.05618077,7.91799088 L6.05618077,7.91799088 L10.2139446,11.9900669 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckInCircle;
