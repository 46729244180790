import * as React from 'react';

const TooltipBackground: React.FC = () => (
  <svg
    width={257}
    height={76}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>50FC139B-7EE5-4B1B-AB28-A9B976172CEF</title>
    <defs>
      <filter
        x="-6.7%"
        y="-25%"
        width="113.4%"
        height="157.1%"
        filterUnits="objectBoundingBox"
        id="a">
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={5}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M230 0a8 8 0 0 1 8 8v40a8 8 0 0 1-8 8H15.166a8 8 0 0 1-8-8l-.001-12.003-6.582-6.583a2 2 0 0 1 0-2.828l6.582-6.583V8a8 8 0 0 1 8-8H230Z"
        id="b"
      />
    </defs>
    <g transform="translate(9 8)" fill="none" fillRule="evenodd">
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#0066BF" xlinkHref="#b" />
    </g>
  </svg>
);

export default TooltipBackground;
