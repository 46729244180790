import GenericIconProps from './GenericIcon';

const ActionValidatedIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 20;
  const baseHeight = 20;
  const baseColor = '#fff';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <title>0EC6F938-59C1-4FB1-BAA7-08268F2D9E28</title>
      <path
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm0 1.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5Zm4.644 5.018a.75.75 0 0 1 0 1.06l-5.113 6.066a.75.75 0 0 1-1.061 0L5.695 10.62a.75.75 0 0 1 1.06-1.06L9 11.802l4.583-5.535a.75.75 0 0 1 1.06 0Z"
        fill={color || baseColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

ActionValidatedIcon.defaultProps = {
  color: '#fff',
};

export default ActionValidatedIcon;
