import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import { useEffect, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import checkPreviousInputValidation from 'utils/commun';
import { addInInvalidInputs, removeFromInvalidInputs } from '../communs/utils';

interface FranchiseDurationProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  franchiseMaxDuration?: string;
  checkInputs: boolean;
}

const FranchiseDuration: React.FC<FranchiseDurationProps> = ({
  state,
  dispatch,
  franchiseMaxDuration,
  checkInputs,
}) => {
  const [isFranchiseDurationInputFocused, setIsFranchiseDurationInputFocused] =
    useState<boolean>(false);
  const [isFranchiseDurationInputValid, setIsFranchiseDurationInputValid] = useState<
    boolean | undefined
  >(checkPreviousInputValidation('conditionsFinancieres', state.franchiseDuration));
  const [franchiseDurationInputError, setFranchiseDurationInputError] =
    useState<string>('');

  const checkFranchiseDurationInputValue = () => {
    if (Number.isNaN(franchiseMaxDuration) || !franchiseMaxDuration) {
      return;
    }
    if (
      state.franchiseDuration === undefined ||
      state.franchiseDuration < 0 ||
      state.franchiseDuration > parseInt(franchiseMaxDuration, 10)
    ) {
      setFranchiseDurationInputError(
        `La durée doit être comprise entre 0 et ${franchiseMaxDuration} mois.`,
      );
      addInInvalidInputs(state, dispatch, 'franchiseDuration');
      setIsFranchiseDurationInputValid(false);
    } else {
      setIsFranchiseDurationInputValid(true);
      setFranchiseDurationInputError('');
      removeFromInvalidInputs(state, dispatch, 'franchiseDuration');
    }
  };

  const handleFranchiseDurationChange = (value: string) => {
    const duration = parseInt(value.slice(0, 3), 10);
    if (Number.isNaN(duration)) {
      dispatch({
        type: 'setFranchiseDuration',
        payload: undefined,
      });
    } else {
      dispatch({
        type: 'setFranchiseDuration',
        payload: duration,
      });
    }
  };
  const handleFranchiseDurationBlur = () => {
    setIsFranchiseDurationInputFocused(false);
    checkFranchiseDurationInputValue();
  };

  const getFranchiseDuration = (): string => {
    if (!franchiseMaxDuration) {
      return 'Durée de la franchise';
    }
    return `Durée de la franchise 0 à ${franchiseMaxDuration} mois`;
  };

  useEffect(() => {
    if (checkInputs) {
      checkFranchiseDurationInputValue();
    }
  }, [franchiseMaxDuration]);

  return (
    <>
      <Space marginTop="2.4rem" />
      <CustomInput
        name="franchise-duration"
        label={getFranchiseDuration()}
        value={state.franchiseDuration !== undefined ? state.franchiseDuration : ''}
        onChange={newValue => {
          handleFranchiseDurationChange(newValue.replaceAll(' ', ''));
          setIsFranchiseDurationInputFocused(true);
        }}
        isValid={isFranchiseDurationInputValid}
        isFocused={isFranchiseDurationInputFocused}
        onBlur={handleFranchiseDurationBlur}
        onFocus={() => {
          setIsFranchiseDurationInputFocused(true);
        }}
        after="mois"
        inputWidth="46.4rem"
        placeholder="0"
        error={franchiseDurationInputError}
      />
    </>
  );
};

export default FranchiseDuration;
