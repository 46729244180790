import CustomInput from 'components/customInput/CustomInput';
import { useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';

interface LoanDurationProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanDuration: React.FC<LoanDurationProps> = ({ currentLoan, state, dispatch }) => {
  const [duration, setDuration] = useState<string>(currentLoan?.loanDuration || '');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');
  const durationReg = new RegExp('^\\d+$');

  const handleOnChange = (value: string) => {
    setIsInputValid(undefined);
    setDuration(value);
  };

  const updateState = (value?: string) => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, loanDuration: value };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  const handleOnBlur = () => {
    setIsInputFocused(false);
    if (durationReg.test(duration)) {
      setIsInputValid(true);
      setInputError('');
      updateState(duration);
    } else {
      setIsInputValid(false);
      setInputError('Durée incorrecte.');
      updateState(undefined);
    }
  };
  return (
    <CustomInput
      name="duration"
      label="Durée totale du prêt"
      type="price"
      placeholder="0"
      value={duration}
      onChange={newValue => {
        handleOnChange(newValue);
        setIsInputFocused(true);
      }}
      isValid={isInputValid}
      isFocused={isInputFocused}
      onBlur={handleOnBlur}
      onFocus={() => {
        setIsInputFocused(true);
      }}
      after="mois"
      inputWidth="46.4rem"
      error={inputError}
    />
  );
};

export default LoanDuration;
