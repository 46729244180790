import Space from 'components/space/Space';
import { hasCoBorrower } from 'containers/communs/utils';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledMainDisplayCard,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import { SituationProfessionnelleState } from 'reducers/situationProfessionnelle/types';
import * as messages from '../../messages';

interface SituationProfessionnelleConsultationProps {
  isProjectEditable: boolean | undefined;
}

const SituationProfessionnelleConsultation: React.FC<
  SituationProfessionnelleConsultationProps
> = ({ isProjectEditable }) => {
  const [situationProfessionnelle, setSituationProfessionnelle] =
    useState<SituationProfessionnelleState>();

  useEffect(() => {
    const situationProfessionnelleState = JSON.parse(
      sessionStorage.getItem('situationProfessionnelle') || '',
    ) as SituationProfessionnelleState;

    if (situationProfessionnelleState)
      setSituationProfessionnelle(situationProfessionnelleState);
  }, []);

  const renderBorrowerInformation = (stakeholder: 'borrower' | 'coborrower') => {
    const situationPro =
      stakeholder === 'borrower'
        ? situationProfessionnelle?.borrower
        : situationProfessionnelle?.coBorrower;

    return (
      <>
        <StyledCardTitle>
          {stakeholder === 'borrower'
            ? messages.MAIN_CARD_PROFESSIONAL_SITUATION_BORROWER_TITLE
            : messages.MAIN_CARD_PROFESSIONAL_SITUATION_COBORROWER_TITLE}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardRow style={{ flexWrap: 'wrap' }}>
          {situationPro?.profession?.libelle && (
            <StyledCardTile style={{ maxWidth: '16rem' }}>
              <StyledCardLabel>
                {messages.MAIN_CARD_LABEL_EMPLOYMENT_SITUATION}
              </StyledCardLabel>
              <StyledCardData>{situationPro?.profession?.libelle}</StyledCardData>
            </StyledCardTile>
          )}

          {situationPro?.typeContrat?.libelle && (
            <StyledCardTile>
              <StyledCardLabel>{messages.MAIN_CARD_LABEL_CONTRACT_TYPE}</StyledCardLabel>
              <StyledCardData>{situationPro?.typeContrat?.libelle}</StyledCardData>
            </StyledCardTile>
          )}

          {situationPro?.currentJobOrSituationStartingDate && (
            <StyledCardTile style={{ maxWidth: '25rem' }}>
              <StyledCardLabel>
                {messages.MAIN_CARD_LABEL_EMPLOYMENT_SITUATION_DURANTION}
              </StyledCardLabel>
              <StyledCardData>
                {situationPro?.currentJobOrSituationStartingDate}
              </StyledCardData>
            </StyledCardTile>
          )}

          {situationPro?.sirenNumber && (
            <StyledCardTile>
              <StyledCardLabel>{messages.MAIN_CARD_LABEL_SIREN_NUMBER}</StyledCardLabel>
              <StyledCardData>{situationPro?.sirenNumber}</StyledCardData>
            </StyledCardTile>
          )}

          {situationPro?.apeNumber && (
            <StyledCardTile>
              <StyledCardLabel style={{ width: '16rem' }}>
                {messages.MAIN_CARD_LABEL_APE_CODE}
              </StyledCardLabel>
              <StyledCardData>{situationPro?.apeNumber}</StyledCardData>
            </StyledCardTile>
          )}

          {situationPro?.employerCountry?.libelle && (
            <StyledCardTile>
              <StyledCardLabel style={{ width: '16rem' }}>
                {messages.MAIN_CARD_LABEL_EMPLOYMENT_COUNTRY}
              </StyledCardLabel>
              <StyledCardData>{situationPro?.employerCountry?.libelle}</StyledCardData>
            </StyledCardTile>
          )}

          {situationPro?.adresse?.codePostal && (
            <StyledCardTile>
              <StyledCardLabel style={{ width: '16rem' }}>
                {messages.MAIN_CARD_LABEL_EMPLOYMENT_ZIPCODE}
              </StyledCardLabel>
              <StyledCardData>{situationPro?.adresse?.codePostal}</StyledCardData>
            </StyledCardTile>
          )}

          {situationPro?.adresse?.city?.libelle && (
            <StyledCardTile>
              <StyledCardLabel style={{ width: '16rem' }}>
                {messages.MAIN_CARD_LABEL_EMPLOYMENT_CITY}
              </StyledCardLabel>
              <StyledCardData>{situationPro?.adresse?.city?.libelle}</StyledCardData>
            </StyledCardTile>
          )}
        </StyledCardRow>
      </>
    );
  };

  return (
    <StyledMainDisplayCard>
      {situationProfessionnelle && renderBorrowerInformation('borrower')}

      {situationProfessionnelle && hasCoBorrower() && (
        <>
          <Space marginBottom="4rem" />
          {renderBorrowerInformation('coborrower')}
        </>
      )}

      <Space marginBottom="5.6rem" />

      <ConsultationEditButton
        isProjectEditable={isProjectEditable}
        navigateRoute="/donnees_client"
        navigateDonneesClientStep="3"
      />
    </StyledMainDisplayCard>
  );
};

export default SituationProfessionnelleConsultation;
