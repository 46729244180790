import { ReactNode } from 'react';
import LightCustomInput from '../LightCustomInput';

interface StreetNumberInputProps {
  float?: boolean;
  name: string;
  label?: string | ReactNode;
  placeholder?: string;
  value?: string | number;
  onChange: (value: string) => void;
  maxSize?: number;
  isDisabled?: boolean;
  hideIcon?: boolean;
}

const StreetNumberInput: React.FC<StreetNumberInputProps> = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  maxSize,
  isDisabled,
  hideIcon,
}) => {
  const allowedValues = RegExp('^([0-9]+( |$)([BTQC]|BI|BIS|TE|TER)?)?$');

  const valueIsLegal = (newValue: string): boolean => {
    if (allowedValues && !newValue.match(allowedValues)) return false;
    if (maxSize && newValue.length > maxSize) return false;
    return true;
  };

  return (
    <LightCustomInput
      name={name}
      label={label}
      placeholder={placeholder}
      value={value?.toString()}
      onChange={newValue => {
        const uppedNewValue = newValue.toUpperCase();
        if (valueIsLegal(uppedNewValue)) {
          onChange(uppedNewValue);
        }
      }}
      isDisabled={isDisabled}
      hideIcon={hideIcon}
    />
  );
};

export default StreetNumberInput;
