import React from 'react';
import { CalcPropositionDto } from 'types/FaisabiliteDTO';
import Space from 'components/space/Space';
import * as messages from '../messages';
import {
  StyledLabelValue,
  StyledHorizontalWrapper,
  StyledDottedLine,
  StyledSubTitleCard,
  Line,
  SummaryWrapperFinancing,
} from '../style';

interface FinancingResultProps {
  simulationResult: CalcPropositionDto;
  renderValuePercent: (value?: string | number) => void;
  renderValueEuro: (value?: string | number) => void;
  renderValueMonth: (value?: string | number) => void;
}

const FinancingResult: React.FC<FinancingResultProps> = ({
  simulationResult,
  renderValuePercent,
  renderValueEuro,
  renderValueMonth,
}) => {
  const mensualiteSansAssurance =
    simulationResult?.echeancierSansAssurance.length > 1
      ? simulationResult?.echeancierSansAssurance[1].echeance
      : simulationResult?.echeancierSansAssurance[0].echeance;

  return (
    <SummaryWrapperFinancing>
      <StyledSubTitleCard>{messages.CALCULATOR_RESULT.planDetail}</StyledSubTitleCard>
      <Space marginTop="2rem" />
      <StyledHorizontalWrapper>
        <StyledLabelValue>{messages.CALCULATOR_loanDuration}</StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValueMonth(simulationResult.dureeTotalPret)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>{messages.CALCULATOR_franchiseDuration}</StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValueMonth(simulationResult.dureeFranchise)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>
          {messages.CALCULATOR_RESULT.mensualitySansAssurance}
        </StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValueEuro(mensualiteSansAssurance)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>{messages.CALCULATOR_nominalInterest}</StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValuePercent(simulationResult.tauxNominal)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>{messages.CALCULATOR_RESULT.taeg}</StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValuePercent(simulationResult.taeg)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>
          {messages.CALCULATOR_RESULT.totalCostSansAssurance}
        </StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValueEuro(simulationResult.coutTotalCredit)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <Line />
      <StyledHorizontalWrapper>
        <StyledLabelValue>{messages.CALCULATOR_RESULT.immoPart}</StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValuePercent(simulationResult.partImmo)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
    </SummaryWrapperFinancing>
  );
};

export default FinancingResult;
