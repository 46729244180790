import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';

interface JobLossInsuranceProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
  isDisabled?: boolean;
}

const JobLossInsurance: React.FC<JobLossInsuranceProps> = ({
  person,
  state,
  dispatch,
  isDisabled,
}) => {
  const isLostJobAssuranceChecked = (): boolean | undefined => {
    const jobLossChecked =
      person === 'borrower'
        ? state.borrowerInsurance.jobLossInsuranceChecked
        : state.coBorrowerInsurance.jobLossInsuranceChecked;
    return jobLossChecked || undefined;
  };

  const getQuotite = () => {
    const value =
      person === 'borrower'
        ? state.borrowerInsurance.deathInsuranceRate
        : state.coBorrowerInsurance.deathInsuranceRate;
    if (value && isLostJobAssuranceChecked()) {
      return `${value}%`;
    }
    return '0%';
  };

  const handleOnCheckChange = (e: Event) => {
    if (isDisabled) {
      return;
    }
    if ((e as CustomEvent)?.detail?.value) {
      dispatch({
        type: 'setJobLossInsuranceChecked',
        payload: { person, value: true },
      });
    } else {
      dispatch({
        type: 'setJobLossInsuranceChecked',
        payload: { person, value: false },
      });
    }
  };

  return (
    <div style={{ marginTop: '4.5rem', display: 'flex', alignContent: 'center' }}>
      <BaseCheckbox
        disabled={isDisabled}
        checked={isLostJobAssuranceChecked()}
        onCheckedChanged={e => {
          handleOnCheckChange(e);
        }}
      />
      <span
        style={{
          marginLeft: '0.8rem',
          color: isDisabled ? '#B1B5B9' : '#4B4F54',
          whiteSpace: 'nowrap',
        }}>
        {`Garantie perte d'emploi ${getQuotite()}`}
      </span>
    </div>
  );
};

export default JobLossInsurance;
