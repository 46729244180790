import { SelectElementInput } from 'components/designSystem/SelectElementInput';
import { SelectedButton } from 'components/designSystem/SelectButton';
import ToggleButton from 'components/toggleButton/ToggleButton';
import React, { useRef, useEffect, useState } from 'react';
import { DateInput } from 'components/designSystem/DateInput';
import CountryInput from 'components/countryInput/CountryInput';
import { ComboInputDS } from 'components/designSystem/ComboInput';
import BirthDepartment from 'components/birthDepartment/BirthDepartment';
import CityInput from 'components/cityInput/CityInput';
import {
  Country,
  Department,
  City,
  Nationality,
  StatusInput,
  StatusControlInput,
  CodeLibelle,
} from 'types';
import { Civility, IdentityAction, IdentityState } from 'reducers/identity/types';
import {
  ColumnFlexContainer,
  PlainText,
  StyledComboInput,
} from 'containers/communs/style';
import Space from 'components/space/Space';
import useInputValidation from 'utils/useInputValidation';
import { getCitiesByDepartment } from 'api/referentialService';
import {
  checkFormatDate,
  getAge,
  getDateFromInput,
  isAdult,
  isDateIntheFuture,
} from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import CustomInput from 'components/customInput/CustomInput';
import { StyledErrorMessage } from 'components/customInput/style';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface CoBorrowerIdentityFormProps {
  isFormOk: (val: boolean) => void;
  countryList: Country[];
  departmentList: Department[];
  coBorrowerState: IdentityState;
  checkInputs: boolean;
  coBorrowerReducerDispatch: React.Dispatch<IdentityAction>;
  nationalityList: Nationality[];
}

const CoBorrowerIdentityForm: React.FC<CoBorrowerIdentityFormProps> = ({
  isFormOk,
  countryList,
  departmentList,
  // handleBirthCountrySelect,
  coBorrowerState,
  checkInputs,
  coBorrowerReducerDispatch,
  nationalityList,
}) => {
  const [cityList, setCityList] = useState<City[]>([]);
  const [showCities, setShowCities] = useState<boolean>(false);

  const [isLastNameInputFocused, setIsLastNameInputFocused] = useState<boolean>(false);
  const [statusLastName, setStatusLastName] = useState<StatusControlInput>();
  const [isBirthNameInputFocused, setIsBirthNameInputFocused] = useState<boolean>(false);
  const [statusBirthName, setStatusBirthName] = useState<StatusControlInput>();
  const [isFirstNameInputFocused, setIsFirstNameInputFocused] = useState<boolean>(false);
  const [statusFirstName, setStatusFirstName] = useState<StatusControlInput>();
  const [statusBirthDate, setStatusBirthDate] = useState<StatusInput>();
  const [reloadStatusBirthDate, setReloadStatusBirthDate] = useState<boolean>(false);
  const [statusBirthCountry, setStatusBirthCountry] = useState<StatusInput>();
  const [statusBirthDepartment, setStatusBirthDepartment] = useState<StatusInput>();
  const [statusBirthCity, setStatusBirthCity] = useState<StatusInput>();
  const [isBirthCityOtherInputFocused, setIsBirthCityOtherInputFocused] =
    useState<boolean>(false);
  const [statusBirthCityOther, setStatusBirthCityOther] = useState<StatusControlInput>();
  const [statusNationality, setStatusNationality] = useState<StatusInput>();

  const nationalityRef = useRef(null);
  const birthCountryRef = useRef(null);
  const departmentRef = useRef(null);
  const cityRef = useRef(null);

  const noNumbers = new RegExp(ValidationRules.noNumbers);
  const noSpecialCharacters = new RegExp(ValidationRules.noSpecialCharacters);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  useInputValidation('coBorrowerIdentity', 'show-input-ds-validation', [
    cityList,
    departmentList,
  ]);

  const checkStatusLastName = () => {
    if (coBorrowerState.lastName === '' || coBorrowerState.lastName === undefined) {
      setStatusLastName({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }

    if (!noNumbers.test(coBorrowerState.lastName)) {
      setStatusLastName({
        status: false,
        errorMessage: messagesCheckInput.NO_NUMBER,
      });
      return;
    }

    if (!noSpecialCharacters.test(coBorrowerState.lastName)) {
      setStatusLastName({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
      return;
    }

    coBorrowerReducerDispatch({
      type: 'setLastName',
      payload: coBorrowerState.lastName.toUpperCase().trim(),
    });
    setStatusLastName({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusBirthName = () => {
    if (coBorrowerState.birthName === '' || coBorrowerState.birthName === undefined) {
      setStatusBirthName({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }

    if (!noNumbers.test(coBorrowerState.birthName)) {
      setStatusBirthName({
        status: false,
        errorMessage: messagesCheckInput.NO_NUMBER,
      });
      return;
    }

    if (!noSpecialCharacters.test(coBorrowerState.birthName)) {
      setStatusBirthName({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
      return;
    }

    coBorrowerReducerDispatch({
      type: 'setBirthName',
      payload: coBorrowerState.birthName.toUpperCase().trim(),
    });
    setStatusBirthName({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusFirstName = () => {
    if (coBorrowerState.firstName === '' || coBorrowerState.firstName === undefined) {
      setStatusFirstName({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }

    if (!noNumbers.test(coBorrowerState.firstName)) {
      setStatusFirstName({
        status: false,
        errorMessage: messagesCheckInput.NO_NUMBER,
      });
      return;
    }

    if (!noSpecialCharacters.test(coBorrowerState.firstName)) {
      setStatusFirstName({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
      return;
    }

    coBorrowerReducerDispatch({
      type: 'setFirstName',
      payload: coBorrowerState.firstName.toUpperCase().trim(),
    });
    setStatusFirstName({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusBirthDate = () => {
    if (reloadStatusBirthDate === true) {
      setReloadStatusBirthDate(false);
      if (coBorrowerState.birthDate === '' || coBorrowerState.birthDate === undefined) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
        return;
      }
      const birthDateString = coBorrowerState.birthDate as string;
      const birthDate = getDateFromInput(birthDateString);

      if (coBorrowerState.birthDate && !checkFormatDate(coBorrowerState.birthDate)) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_FORMAT,
        });
        return;
      }
      if (!isDateIntheFuture(getDateFromInput(`${coBorrowerState.birthDate}`))) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_FUTUR,
        });
        return;
      }
      if (!isAdult(birthDate) || birthDate.getFullYear() < 1901) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_TOO_YOUNG_OR_OLD,
        });
        return;
      }
      setStatusBirthDate({
        status: 'valid',
        errorMessage: ``,
      });
    }
  };

  const checkStatusBirthCountry = () => {
    if (
      birthCountryRef.current &&
      birthCountryRef.current !== null &&
      coBorrowerState.birthCountry
    ) {
      const countryMatchList = countryList?.find(country => {
        return country.libelle === coBorrowerState.birthCountry?.libelle;
      });
      if (countryMatchList) {
        coBorrowerReducerDispatch({
          type: 'setBirthCountry',
          payload: countryMatchList,
        });
        setStatusBirthCountry({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        coBorrowerReducerDispatch({
          type: 'setBirthCountry',
          payload: {
            code: 'err',
            libelle: coBorrowerState.birthCountry.libelle
              ? coBorrowerState.birthCountry.libelle
              : '',
          },
        });
        setStatusBirthCountry({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!coBorrowerState.birthCountry)
      setStatusBirthCountry({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  const checkStatusBirthDepartment = () => {
    if (
      departmentRef.current &&
      departmentRef.current !== null &&
      coBorrowerState.birthDepartment
    ) {
      const departmentMatchList = departmentList?.find(department => {
        return (
          department.libelle.toUpperCase() ===
          coBorrowerState.birthDepartment?.libelle.toUpperCase()
        );
      });
      if (departmentMatchList) {
        coBorrowerReducerDispatch({
          type: 'setBirthDepartment',
          payload: departmentMatchList,
        });
        setStatusBirthDepartment({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        coBorrowerReducerDispatch({
          type: 'setBirthDepartment',
          payload: {
            code: 'err',
            libelle: coBorrowerState.birthDepartment.libelle
              ? coBorrowerState.birthDepartment.libelle
              : '',
          },
        });
        setStatusBirthDepartment({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!coBorrowerState.birthDepartment)
      setStatusBirthDepartment({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  const checkStatusBirthCity = (cityByZipCodeList: CodeLibelle[]) => {
    if (cityRef.current && cityRef.current !== null && coBorrowerState.birthCity) {
      const cityMatchList = cityByZipCodeList?.find(city => {
        return (
          city.libelle.toUpperCase() === coBorrowerState.birthCity?.libelle.toUpperCase()
        );
      });
      if (cityMatchList) {
        coBorrowerReducerDispatch({
          type: 'setBirthCity',
          payload: cityMatchList,
        });
        setStatusBirthCity({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        coBorrowerReducerDispatch({
          type: 'setBirthCity',
          payload: {
            code: 'err',
            libelle: coBorrowerState.birthCity.libelle
              ? coBorrowerState.birthCity.libelle
              : '',
          },
        });
        setStatusBirthCity({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!coBorrowerState.birthCity)
      setStatusBirthCity({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  const checkStatusBirthCityOther = () => {
    if (coBorrowerState.birthCity) {
      if (notEmpty.test(coBorrowerState.birthCity.libelle)) {
        coBorrowerReducerDispatch({
          type: 'setBirthCity',
          payload: {
            code: '99',
            libelle: coBorrowerState.birthCity.libelle.slice(0, 25),
          },
        });
        setStatusBirthCityOther({
          status: true,
          errorMessage: ``,
        });
        return;
      }
      coBorrowerReducerDispatch({
        type: 'setBirthCity',
        payload: {
          code: 'err',
          libelle: '',
        },
      });
      setStatusBirthCityOther({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    }
    setStatusBirthCityOther({
      status: false,
      errorMessage: messagesCheckInput.REQUIRED_VALUE,
    });
  };

  const checkStatusNationality = () => {
    if (
      nationalityRef.current &&
      nationalityRef.current !== null &&
      coBorrowerState.nationality
    ) {
      const nationalityMatchList = nationalityList?.find(nationality => {
        return (
          nationality.libelle.toUpperCase() ===
          coBorrowerState.nationality?.libelle.toUpperCase()
        );
      });
      if (nationalityMatchList) {
        coBorrowerReducerDispatch({
          type: 'setNationality',
          payload: nationalityMatchList,
        });
        setStatusNationality({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        coBorrowerReducerDispatch({
          type: 'setNationality',
          payload: {
            code: 'err',
            libelle: coBorrowerState.nationality.libelle
              ? coBorrowerState.nationality.libelle
              : '',
          },
        });
        setStatusNationality({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!coBorrowerState.nationality)
      setStatusNationality({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  useEffect(() => {
    if (coBorrowerState.lastName !== '') {
      checkStatusLastName();
    }
    if (coBorrowerState.birthName !== '') {
      checkStatusBirthName();
    }
    if (coBorrowerState.firstName !== '') {
      checkStatusFirstName();
    }
    if (coBorrowerState.birthDate !== '' && coBorrowerState.birthDate !== undefined) {
      setReloadStatusBirthDate(true);
    }
    if (coBorrowerState.birthCountry) {
      checkStatusBirthCountry();
    }
    if (!showCities) {
      if (coBorrowerState.birthCity) {
        checkStatusBirthCityOther();
      }
    }
  }, []);

  useEffect(() => {
    if (checkInputs) {
      checkStatusLastName();
      if (!coBorrowerState.isBirthNameSameAsName) checkStatusBirthName();
      checkStatusFirstName();
      setReloadStatusBirthDate(true);
      checkStatusBirthCountry();
      if (departmentList.length !== 0) checkStatusBirthDepartment();
      if (cityList.length !== 0) checkStatusBirthCity(cityList);
      if (!showCities) {
        checkStatusBirthCityOther();
      }
      if (nationalityList.length !== 0) checkStatusNationality();
    }
  }, [checkInputs]);

  useEffect(() => {
    if (departmentList.length !== 0) {
      if (coBorrowerState.birthDepartment) {
        checkStatusBirthDepartment();
      }
    }
  }, [departmentList]);

  useEffect(() => {
    if (nationalityList.length !== 0) {
      if (coBorrowerState.nationality) {
        checkStatusNationality();
      }
    }
  }, [nationalityList]);

  useEffect(() => {
    if (
      coBorrowerState.birthDepartment?.code === '99' ||
      coBorrowerState.birthDepartment?.code === '00'
    ) {
      setShowCities(false);
    } else if (
      coBorrowerState.birthDepartment?.code &&
      coBorrowerState.birthDepartment?.code.length > 0 &&
      coBorrowerState.birthDepartment?.code !== 'err'
    ) {
      setShowCities(true);
      if (coBorrowerState.birthDepartment?.code === '98') {
        setCityList([{ code: '98000', libelle: 'Monaco' }]);
      } else {
        getCitiesByDepartment(coBorrowerState.birthDepartment?.code).then(
          cityByZipCodeList => {
            setStatusBirthCity({
              status: 'none',
              errorMessage: ``,
            });
            if (
              (coBorrowerState.birthCity && coBorrowerState.birthCity.libelle !== '') ||
              checkInputs
            )
              checkStatusBirthCity(cityByZipCodeList);
            setCityList(cityByZipCodeList);
          },
        );
      }
    } else if (coBorrowerState.birthCity) {
      setShowCities(true);
      setCityList([coBorrowerState.birthCity]);
    } else {
      setShowCities(false);
      setCityList([]);
    }
  }, [showCities, coBorrowerState.birthDepartment?.code]);

  useEffect(() => {
    if (coBorrowerState.isDataOk) {
      isFormOk(true);
    } else {
      isFormOk(false);
    }
  }, [coBorrowerState]);

  useEffect(() => {
    checkStatusBirthDate();
  }, [reloadStatusBirthDate]);

  const onBirthCountryBlur = (event: Event) => {
    if (birthCountryRef.current && birthCountryRef.current !== null) {
      if (event && event.target) {
        const countryMatchList = countryList?.find(country => {
          return (
            country.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (countryMatchList) {
          coBorrowerReducerDispatch({
            type: 'setBirthCountry',
            payload: countryMatchList,
          });
          if (countryMatchList.code === 'FR') {
            coBorrowerReducerDispatch({
              type: 'setBirthDepartment',
              payload: undefined,
            });
          } else {
            coBorrowerReducerDispatch({
              type: 'setBirthDepartment',
              payload: { code: '99', libelle: '' },
            });
            setCityList([]);
            setShowCities(false);
          }
          coBorrowerReducerDispatch({
            type: 'setBirthCity',
            payload: undefined,
          });
        } else {
          coBorrowerReducerDispatch({
            type: 'setBirthCountry',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
          coBorrowerReducerDispatch({
            type: 'setBirthCity',
            payload: undefined,
          });
          coBorrowerReducerDispatch({
            type: 'setBirthDepartment',
            payload: { code: '00', libelle: '' },
          });
          setCityList([]);
          setShowCities(false);
        }
      }
    }
  };

  const onDepartmentBlur = (event: Event) => {
    if (departmentRef.current && departmentRef.current !== null) {
      if (event && event.target) {
        const departmentMatchList = departmentList?.find(department => {
          return (
            department.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (!departmentMatchList) {
          coBorrowerReducerDispatch({
            type: 'setBirthDepartment',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
          setShowCities(false);
        } else {
          coBorrowerReducerDispatch({
            type: 'setBirthDepartment',
            payload: departmentMatchList,
          });
        }
      }
    }
  };

  const onCityBlur = (event: Event) => {
    if (cityRef.current && cityRef.current !== null) {
      if (event && event.target) {
        const cityMatchesListElement = cityList?.find(city => {
          return (
            city.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (cityMatchesListElement) {
          coBorrowerReducerDispatch({
            type: 'setBirthCity',
            payload: cityMatchesListElement,
          });
        } else {
          coBorrowerReducerDispatch({
            type: 'setBirthCity',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
        }
      }
    }
  };

  const onNationalityBlur = (event: Event) => {
    if (nationalityRef.current && nationalityRef.current !== null) {
      if (event && event.target) {
        const nationalityMatchList = nationalityList?.find(nationality => {
          return (
            nationality.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (!nationalityMatchList) {
          coBorrowerReducerDispatch({
            type: 'setNationality',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
        } else {
          coBorrowerReducerDispatch({
            type: 'setNationality',
            payload: nationalityMatchList,
          });
        }
      }
    }
  };

  const onBirthCountrySelect = (e: Event) => {
    const selectedBirthCountry = (e as CustomEvent).detail.value as Country;
    coBorrowerReducerDispatch({
      type: 'setBirthCountry',
      payload: selectedBirthCountry,
    });
    // dés que la selection du pays change, on réinitialise les champs département et ville
    if (selectedBirthCountry.code === 'FR') {
      coBorrowerReducerDispatch({
        type: 'setBirthDepartment',
        payload: undefined,
      });
    } else {
      coBorrowerReducerDispatch({
        type: 'setBirthDepartment',
        payload: { code: '99', libelle: '' },
      });
      coBorrowerReducerDispatch({
        type: 'setBirthCity',
        payload: undefined,
      });
      setCityList([]);
      setShowCities(false);
    }
    coBorrowerReducerDispatch({
      type: 'setBirthCity',
      payload: undefined,
    });
    // handleBirthCountrySelect(selectedBirthCountry.code);
  };

  const onDepartmentSelect = (e: Event) => {
    const selectedBirthDepartment = (e as CustomEvent).detail.value as Department;
    coBorrowerReducerDispatch({
      type: 'setBirthDepartment',
      payload: selectedBirthDepartment,
    });
    coBorrowerReducerDispatch({
      type: 'setBirthCity',
      payload: undefined,
    });
  };

  const onCitySelect = (e: Event) => {
    coBorrowerReducerDispatch({
      type: 'setBirthCity',
      payload: (e as CustomEvent).detail.value as City,
    });
  };

  const onNationalitySelect = (e: Event) => {
    const selectedNationality = (e as CustomEvent).detail.value as Country;
    coBorrowerReducerDispatch({
      type: 'setNationality',
      payload: selectedNationality,
    });
  };

  const onDateBlur = () => {
    setStatusBirthDate({
      status: 'none',
      errorMessage: ``,
    });
    setReloadStatusBirthDate(true);
  };

  return (
    <>
      <div className="element-accessible-keyboard">
        <SelectElementInput
          class="identity-selected-button identity-form-input"
          label="Civilité"
          data-id="civility"
          value={coBorrowerState.civility}
          onSelectedElementChange={e => {
            coBorrowerReducerDispatch({
              type: 'setCivility',
              payload: (e.target as HTMLSelectElement).value as Civility,
            });
          }}
          error-message="Veuillez choisir Madame ou Monsieur.">
          <SelectedButton
            tabIndex={0}
            onKeyDownCapture={e => {
              if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                e.preventDefault();
                coBorrowerReducerDispatch({
                  type: 'setCivility',
                  payload: (e.target as HTMLSelectElement).value as Civility,
                });
              }
            }}
            value="M"
            secondary
            className="civility-select">
            Monsieur
          </SelectedButton>
          <SelectedButton
            tabIndex={0}
            onKeyDownCapture={e => {
              if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                e.preventDefault();
                coBorrowerReducerDispatch({
                  type: 'setCivility',
                  payload: (e.target as HTMLSelectElement).value as Civility,
                });
              }
            }}
            value="MME"
            secondary>
            Madame
          </SelectedButton>
          {!coBorrowerState.civility && checkInputs ? (
            <>
              <StyledErrorMessage style={{ fontSize: '1.4rem' }}>
                {messagesCheckInput.REQUIRED_VALUE}
              </StyledErrorMessage>
            </>
          ) : (
            <></>
          )}
        </SelectElementInput>
      </div>

      <CustomInput
        name="last-name"
        label="Nom"
        placeholder="Nom"
        onChange={newValue => {
          setIsLastNameInputFocused(true);
          coBorrowerReducerDispatch({
            type: 'setLastName',
            payload: newValue.toUpperCase().slice(0, 25),
          });
        }}
        onBlur={checkStatusLastName}
        onFocus={() => {
          setIsLastNameInputFocused(true);
        }}
        isValid={statusLastName?.status}
        isFocused={isLastNameInputFocused}
        inputWidth="50rem"
        value={coBorrowerState.lastName}
        error={statusLastName?.errorMessage}
      />

      <Space marginBottom="8px" />

      <ToggleButton
        id="coBorrowerNameEqualsBirthName"
        isOn={coBorrowerState.isBirthNameSameAsName}
        handleToggle={() => {
          coBorrowerReducerDispatch({
            type: 'setIsBirthNameSameAsName',
            payload: !coBorrowerState.isBirthNameSameAsName,
          });
        }}>
        <div
          style={{
            marginRight: '0.8rem',
            marginTop: '0.3rem',
          }}>
          <PlainText>Le nom est identique au nom de naissance</PlainText>
        </div>
      </ToggleButton>

      <Space marginBottom="2.4rem" />

      {!coBorrowerState.isBirthNameSameAsName ? (
        <>
          <CustomInput
            name="birth-name"
            label="Nom de naissance"
            placeholder="Nom de naissance"
            onChange={newValue => {
              setIsBirthNameInputFocused(true);
              coBorrowerReducerDispatch({
                type: 'setBirthName',
                payload: newValue.toUpperCase().slice(0, 25),
              });
            }}
            onBlur={checkStatusBirthName}
            onFocus={() => {
              setIsBirthNameInputFocused(true);
            }}
            isValid={statusBirthName?.status}
            isFocused={isBirthNameInputFocused}
            inputWidth="50rem"
            value={coBorrowerState.birthName}
            error={statusBirthName?.errorMessage}
          />
          <Space marginBottom="2.4rem" />
        </>
      ) : (
        <></>
      )}

      <CustomInput
        name="first-name"
        label="Prénom"
        placeholder="Prénom"
        onChange={newValue => {
          setIsFirstNameInputFocused(true);
          coBorrowerReducerDispatch({
            type: 'setFirstName',
            payload: newValue.toUpperCase().slice(0, 15),
          });
        }}
        onBlur={checkStatusFirstName}
        onFocus={() => {
          setIsFirstNameInputFocused(true);
        }}
        isValid={statusFirstName?.status}
        isFocused={isFirstNameInputFocused}
        inputWidth="50rem"
        value={coBorrowerState.firstName}
        error={statusFirstName?.errorMessage}
      />
      <Space marginBottom="2.4rem" />
      <ColumnFlexContainer>
        <DateInput
          class="show-input-ds-validation"
          label="Date de naissance"
          onBlur={onDateBlur}
          onInputChange={e => {
            coBorrowerReducerDispatch({
              type: 'setBirthDate',
              payload: (e.target as HTMLInputElement).value,
            });
          }}
          value={coBorrowerState.birthDate}
          {...(statusBirthDate?.status !== 'none'
            ? { status: statusBirthDate?.status }
            : {})}
          {...(statusBirthDate?.errorMessage !== ''
            ? { 'error-message': statusBirthDate?.errorMessage }
            : {})}
        />

        {coBorrowerState.birthDate &&
        statusBirthDate?.status === 'valid' &&
        getAge(getDateFromInput(coBorrowerState.birthDate)) > 17 ? (
          <div style={{ marginTop: '0.8rem' }}>
            <PlainText>
              {getAge(getDateFromInput(coBorrowerState.birthDate))} ans
            </PlainText>
          </div>
        ) : (
          <></>
        )}
      </ColumnFlexContainer>

      <Space marginBottom="24px" />

      <StyledComboInput>
        <CountryInput
          countryRef={birthCountryRef}
          label="Pays de naissance"
          defaultValue={coBorrowerState.birthCountry?.libelle || ''}
          countries={countryList}
          onCountryBlur={onBirthCountryBlur}
          onCountrySelect={onBirthCountrySelect}
          className="show-input-ds-validation"
          status={statusBirthCountry?.status}
          errorMessage={statusBirthCountry?.errorMessage}
        />
      </StyledComboInput>
      <Space marginBottom="2.4rem" />
      {coBorrowerState.birthCountry?.code === 'FR' ? (
        <>
          <StyledComboInput>
            <BirthDepartment
              defaultValue={
                coBorrowerState.birthDepartment
                  ? coBorrowerState.birthDepartment.libelle
                  : ''
              }
              departmentRef={departmentRef}
              departments={departmentList}
              onDepartmentBlur={onDepartmentBlur}
              onDepartmentSelect={onDepartmentSelect}
              className="show-input-ds-validation"
              status={statusBirthDepartment?.status}
              errorMessage={statusBirthDepartment?.errorMessage}
            />
          </StyledComboInput>
          <Space marginBottom="2.4rem" />
        </>
      ) : null}
      {showCities ? (
        <CityInput
          label="Ville de naissance"
          cityRef={cityRef}
          defaultValue={coBorrowerState.birthCity?.libelle || ''}
          cities={cityList}
          onCitySelect={onCitySelect}
          onCityBlur={onCityBlur}
          className="show-input-ds-validation"
          status={statusBirthCity?.status}
          errorMessage={statusBirthCity?.errorMessage}
        />
      ) : (
        <CustomInput
          name="city-birth"
          label="Ville de naissance"
          onChange={newValue => {
            coBorrowerReducerDispatch({
              type: 'setBirthCity',
              payload: { code: '99', libelle: newValue.slice(0, 25) },
            });
            setIsBirthCityOtherInputFocused(true);
          }}
          onBlur={checkStatusBirthCityOther}
          onFocus={() => {
            setIsBirthCityOtherInputFocused(true);
          }}
          isValid={statusBirthCityOther?.status}
          isFocused={isBirthCityOtherInputFocused}
          inputWidth="50rem"
          value={coBorrowerState.birthCity?.libelle || ''}
          error={statusBirthCityOther?.errorMessage}
        />
      )}
      <Space marginBottom="2.4rem" />
      <StyledComboInput>
        <CountryInput
          countryRef={nationalityRef}
          label="Nationalité"
          defaultValue={coBorrowerState.nationality?.libelle || ''}
          countries={nationalityList}
          onCountryBlur={onNationalityBlur}
          onCountrySelect={onNationalitySelect}
          className="show-input-ds-validation"
          placeholder="ex: Française"
          status={statusNationality?.status}
          errorMessage={statusNationality?.errorMessage}
        />
      </StyledComboInput>
    </>
  );
};

export default CoBorrowerIdentityForm;
