import { SessionStorageKeys } from 'types/';
import { getExistingStorageByKey } from 'utils/storage';
import { IbanState, IbanAction } from './type';

const checkIfIbanIsEmpty = (iban: IbanState): boolean => {
  const {
    ibanPart1,
    ibanPart2,
    ibanPart3,
    ibanPart4,
    ibanPart5,
    ibanPart6,
    ibanPart7,
    ibanPart8,
    ibanPart9,
    bic,
  } = iban;
  if (
    ibanPart1 ||
    ibanPart2 ||
    ibanPart3 ||
    ibanPart4 ||
    ibanPart5 ||
    ibanPart6 ||
    ibanPart7 ||
    ibanPart8 ||
    ibanPart9 ||
    bic
  ) {
    return false;
  }
  return true;
};

const checkIfDataIsOk = (iban: IbanState): boolean => {
  const { ibanPart1, bic } = iban;
  if (!ibanPart1 || !bic) {
    return false;
  }
  return true;
};

export const getIbanInitialState = (storageKey: SessionStorageKeys): IbanState => {
  const ibanDefaultValues: IbanState = {
    ibanPart1: '',
    ibanPart2: '',
    ibanPart3: '',
    ibanPart4: '',
    ibanPart5: '',
    ibanPart6: '',
    ibanPart7: '',
    ibanPart8: '',
    ibanPart9: '',
    bic: '',
    isEmpty: true,
    isDataOk: false,
  };

  const ibanInitialState = getExistingStorageByKey(
    storageKey,
    ibanDefaultValues,
  ) as IbanState;
  ibanInitialState.isEmpty = checkIfIbanIsEmpty(ibanInitialState);
  ibanInitialState.isDataOk = checkIfDataIsOk(ibanInitialState);

  return ibanInitialState;
};

export const ibanReducer = (state: IbanState, action: IbanAction): IbanState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setIban': {
      newState[action.payload.partToUpdate] = action.payload.value;
      newState.isEmpty = checkIfIbanIsEmpty(newState);
      newState.isDataOk = checkIfDataIsOk(newState);
      return newState;
    }
    case 'SetPasteIban': {
      for (let i = 0; i < 9; i += 1) {
        const newIbanPart = action.payload.value?.slice(4 * i, 4 * i + 4);
        newState[`ibanPart${action.payload.partPaste + i + 1}`] = newIbanPart;
        if (action.payload.partPaste + i === 8) break;
      }
      newState.isEmpty = checkIfIbanIsEmpty(newState);
      newState.isDataOk = checkIfDataIsOk(newState);
      return newState;
    }
    default:
      return newState;
  }
};
