export const CALCULATOR_MAIN_TITLE = 'Bienvenue dans votre espace partenaire';
export const CALCULATOR_BREADCRUMBS_RETURN_HOME = "Retour à l'accueil";
export const CALCULATOR_indermediation = "Frais d'intermédiation";
export const CALCULATOR_fileFee = 'Frais de dossier';
export const CALCULATOR_loanDuration = 'Durée totale du prêt';
export const CALCULATOR_franchiseDuration = 'Durée de la franchise';
export const CALCULATOR_nominalInterest = "Taux d'intérêt nominal";
export const CALCULATOR_warrantyBorrower = 'Emprunteur';
export const CALCULATOR_FORM = {
  subtitle: 'Calculette financière',
  warrantyTitle: 'Sélectionnez le choix de la Garantie',
  radioWithWarranty: 'Avec Garantie',
  radioWithOutWarranty: 'Sans Garantie',
  financingTitle: 'Besoin de financement',
  financingInfo:
    'Renseignez le solde restant dû y compris le montant des pénalités pour remboursement par anticipation et les nouveaux besoins',
  immo: 'Nature Immobilière',
  conso: 'Nature Consommation',
  simulationTitle: 'Simulation',
  variousFeeTitle: 'Frais divers',
  warrantyStepTitle: 'Assurance du prêt',
  warrantyCoBorrower: 'Co-emprunteur',
  individuelleInsurance:
    "Vous avez sélectionné l'offre Assurance facultative pour votre client.<br/>Ce choix le sécurise en cas d'imprévus.",
  noInsurance:
    'Nous vous invitons à informer le client des risques auxquels il s’expose à ne pas y souscrire. <br/><br/>En <b>cas de Décès</b>, les ayants droit (conjoint, enfants,...) du client devront rembourser le solde du crédit.<br/><br/>En <b>cas d’Invalidité, de Maladie - Accident, ou de Chômage</b> le client devra continuer à rembourser chaque mois son crédit.',
};
export const CALCULATOR_RESULT = {
  infoSimulationEndPage:
    'Ce document est une simulation effectuée aux vues des éléments<br/> communiqués par le client et ne constitue en aucun cas un engagement<br/> du prêteur à consentir un crédit au client.<br/> (Données non contractuelles susceptibles de variations).',
  subtitle: 'Résultat de la simulation financière',
  clientNeeds: 'Besoin du client',
  warrantyFee: 'Frais de garantie',
  planDetail: 'Détail du plan de financement',
  mensualitySansAssurance: 'Mensualités sans assurance',
  taeg: 'TAEG Fixe',
  totalCostSansAssurance: 'Coût total du crédit sans assurance',
  immoPart: 'Part immobilier',
  infoSimulation: "Simulation d'assurance réalisée pour un client salarié âgé de 35 ans",
  assuranceGroupe: 'Assurance Groupe',
  assuranceDeces: 'Assurance Décès',
  assurancePTIA: 'Assurance PTIA',
  assuranceITT: 'Assurance ITT/IPT',
  jobLoss: "Perte d'emploi",
  franchiseDuration: 'Délai de franchise',
  primeMensuelle: 'Prime mensuelle',
  mensualiteAssurance: 'Mensualités avec assurance',
  taea: 'TAEA',
  insuranceTotalCost: "Coût total de l'assurance",
  creditTotalCost: 'Coût total du crédit avec assurance',
  assuranceIndividuelle: 'Assurance Individuelle',
  quotiteCouverture: 'Quotité couverture',
  primeMensuelleEstimee: 'Prime mensuelle estimée',
  montantTotal: 'Montant à financer',
};
