import { getHomeLoanPurposes } from 'api/referentialService';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { ValidityHtmlElement } from 'components/designSystem/DesignSystemTypes';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { CodeLibelle } from 'types';

interface LoanUsageProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanUsage: React.FC<LoanUsageProps> = ({ currentLoan, state, dispatch }) => {
  const inputId = 'loanUsage';
  const [loanUsageInput, setLoanUsageInput] = useState<HTMLElement | null>();
  const [value, setValue] = useState<CodeLibelle | undefined>(currentLoan?.loanUsage);
  const [loanUsages, setLoanUsages] = useState<CodeLibelle[]>([]);

  useEffect(() => {
    setLoanUsageInput(document.getElementById(inputId));

    const fetchHomeLoanPurposes = async () => {
      try {
        const response = await getHomeLoanPurposes();
        const { Resultat, Success } = response;
        const result: CodeLibelle[] = [];
        if (Success) {
          Resultat.forEach(item => {
            result.push({ code: item.code, libelle: item.libelle });
          });
          setLoanUsages(result);
        }
      } catch (error) {
        // TODO : handle error when ready in the Design
      }
    };
    fetchHomeLoanPurposes();
  }, []);

  const displayInputError = (errorMessage: string) => {
    if (loanUsageInput) {
      (loanUsageInput as unknown as ValidityHtmlElement).valid = false;
      (loanUsageInput as unknown as ValidityHtmlElement).invalid = true;
      (loanUsageInput as unknown as ValidityHtmlElement).errorMessage = errorMessage;
    }
  };
  const onLoanUsageSelect = (e: Event) => {
    setValue((e as CustomEvent).detail.value as CodeLibelle);
  };

  const onLoanUsageBlur = (event: Event) => {
    let data = [] as LoanAdditionalData[];
    if (event && event.target && (event.target as unknown as ComboInputDS).value === '') {
      displayInputError(`L'usage du bien est obligatoire`);
      data = state.loans.map(item => {
        if (item.code === currentLoan?.code) {
          return { ...item, loanUsage: undefined };
        }
        return item;
      });
    } else {
      data = state.loans.map(item => {
        if (item.code === currentLoan?.code) {
          return { ...item, loanUsage: value };
        }
        return item;
      });
    }
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  return (
    <>
      <ComboInput
        id="loanUsage"
        list-on-open
        className="combo-finalisation-projet"
        shadow
        required
        align-items="left"
        items={JSON.stringify(loanUsages)}
        field="libelle"
        label="Usage du bien"
        placeholder="Choisir l'usage du bien"
        onSelectedList={e => {
          onLoanUsageSelect(e);
        }}
        onListBlur={e => {
          onLoanUsageBlur(e);
        }}
        value={currentLoan?.loanUsage?.libelle ? currentLoan.loanUsage.libelle : ''}
      />
    </>
  );
};

export default LoanUsage;
