import { v4 as uuidv4 } from 'uuid';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ChemiseData } from 'reducers/transmissionPieces/types';
import TextButton from 'components/buttons/textButton/TextButton';
import {
  StyledDragAndDropZoneConsultationText,
  StyledDragAndDropZoneConsultationWrapper,
} from './style';

interface DropFileZoneProps {
  chemId: string;
  maxFileSize: number;
  acceptedFileFormat: string[];
  setChemiseHasError: (arg0: boolean) => void;
  onUploadSuccess: (data: ChemiseData) => void;
  onAddDocumentClicked: (chemiseId: string) => void;
  uploadHasError?: boolean;
}

const DropFileZone: React.FC<DropFileZoneProps> = ({
  chemId,
  maxFileSize,
  acceptedFileFormat,
  setChemiseHasError,
  onUploadSuccess,
  onAddDocumentClicked,
  uploadHasError,
}) => {
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      setChemiseHasError(true);
    }
    if (acceptedFiles.length > 0) {
      const chemise: ChemiseData = {
        id: chemId,
        files: [],
      };
      acceptedFiles.forEach(element => {
        chemise.files.push({ id: uuidv4(), content: element });
      });
      setChemiseHasError(false);
      onUploadSuccess(chemise);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { accept: acceptedFileFormat },
    maxSize: maxFileSize,
  });

  const message = 'Déposez le(s) fichier(s) dans cette zone ou ';
  return (
    <>
      <div
        className="dropzone-div"
        {...getRootProps({ onClick: event => event.stopPropagation() })}>
        <input className="dropzone-input" {...getInputProps()} />
        <div className="text-center">
          <StyledDragAndDropZoneConsultationWrapper hasError={uploadHasError || false}>
            <StyledDragAndDropZoneConsultationText>
              {message}
            </StyledDragAndDropZoneConsultationText>
            <TextButton
              label="sélectionnez-le(s)"
              textColor="#292c2e"
              width="auto"
              textDecoration="underline"
              onClick={() => {
                if (chemId && onAddDocumentClicked) {
                  onAddDocumentClicked(chemId);
                }
              }}
            />
          </StyledDragAndDropZoneConsultationWrapper>
        </div>
      </div>
    </>
  );
};

DropFileZone.defaultProps = {
  uploadHasError: false,
};

export default DropFileZone;
