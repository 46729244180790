const chartColors = {
  mainGreen500: '#28883C',
  mainGreenBnp500: '#008859',
  mainGreen50: '#E8F5E9',
  neutral0: '#FFFFFF',
  neutral50: '#F2F3F3',
  neutral100: '#DCDEE0',
  neutral200: '#C7CACD',
  neutral300: '#B1B5B9',
  neutral400: '#6F757C',
  neutral500: '#4B4F54',
  blue50: '#DCF1FC',
  error500: '#C8102E',
};

const shadows = {
  15: '0 0.2rem 1rem 0 rgba(0,0,0,0.15)',
  25: '0 0.2rem 2rem 0 rgba(0,0,0,0.15)',
};

const colors = {
  chartColors,
  shadows,
};

export default colors;
