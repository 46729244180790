import CustomInput from 'components/customInput/CustomInput';
import { addInInvalidInputs, removeFromInvalidInputs } from 'containers/communs/utils';
import { useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import checkPreviousInputValidation, { formatNumberByThousand } from 'utils/commun';
import { ValidationRules } from 'utils/InputValidation';

interface MonthlyEstimatedBonusProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
}

const MonthlyEstimatedBonus: React.FC<MonthlyEstimatedBonusProps> = ({
  state,
  dispatch,
  person,
}) => {
  const [isMonthlyEstimatedBonusInputFocused, setIsMonthlyEstimatedBonusInputFocused] =
    useState<boolean>(false);
  const [isMonthlyEstimatedBonusInputValid, setIsMonthlyEstimatedBonusInputValid] =
    useState<boolean | undefined>(
      checkPreviousInputValidation(
        'conditionsFinancieres',
        person === 'borrower'
          ? state.borrowerInsurance.monthlyEstimatedBonus
          : state.coBorrowerInsurance.monthlyEstimatedBonus,
      ),
    );
  const [monthlyEstimatedBonusInputError, setMonthlyEstimatedBonusInputError] =
    useState<string>('');
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const handleMonthlyEstimatedBonusChange = (value: string) => {
    let inputTrimmed = '';
    if (value) {
      inputTrimmed = value.replace(/ /g, '');
    }
    const rate = parseInt(inputTrimmed, 10);
    if (Number.isNaN(rate)) {
      dispatch({
        type: 'setMonthlyEstimatedBonus',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setMonthlyEstimatedBonus',
        payload: { person, value: rate },
      });
    }
  };

  const handleMonthlyEstimatedBonusBlur = () => {
    setIsMonthlyEstimatedBonusInputFocused(false);
    const monthlyBonus =
      person === 'borrower'
        ? state.borrowerInsurance.monthlyEstimatedBonus
        : state.coBorrowerInsurance.monthlyEstimatedBonus;
    if (!notEmpty.test(monthlyBonus?.toString() || '')) {
      setIsMonthlyEstimatedBonusInputValid(false);
      setMonthlyEstimatedBonusInputError(messagesCheckInput.REQUIRED_VALUE);
      addInInvalidInputs(state, dispatch, 'monthlyBonus');
    } else if (Number.isNaN(monthlyBonus)) {
      setIsMonthlyEstimatedBonusInputValid(false);
      setMonthlyEstimatedBonusInputError(messagesCheckInput.INPUT_ERROR);
      addInInvalidInputs(state, dispatch, 'monthlyBonus');
    } else {
      setIsMonthlyEstimatedBonusInputValid(true);
      setMonthlyEstimatedBonusInputError('');
      removeFromInvalidInputs(state, dispatch, 'monthlyBonus');
    }
  };

  const getMonthlyEstimatedBonusValue = () => {
    if (person === 'borrower') {
      if (state.borrowerInsurance.monthlyEstimatedBonus !== undefined) {
        return formatNumberByThousand(
          state.borrowerInsurance.monthlyEstimatedBonus.toString(),
        );
      }
      return '';
    }
    if (state.coBorrowerInsurance.monthlyEstimatedBonus !== undefined) {
      return formatNumberByThousand(
        state.coBorrowerInsurance.monthlyEstimatedBonus.toString(),
      );
    }
    return '';
  };

  return (
    <CustomInput
      name="monthly-estimated-bonus"
      label="Prime mensuelle estimée"
      value={getMonthlyEstimatedBonusValue()}
      onChange={newValue => {
        handleMonthlyEstimatedBonusChange(newValue);
        setIsMonthlyEstimatedBonusInputFocused(true);
      }}
      isValid={isMonthlyEstimatedBonusInputValid}
      isFocused={isMonthlyEstimatedBonusInputFocused}
      onBlur={handleMonthlyEstimatedBonusBlur}
      onFocus={() => {
        setIsMonthlyEstimatedBonusInputFocused(true);
      }}
      after="€ / mois"
      inputWidth="46.4rem"
      placeholder="0"
      error={monthlyEstimatedBonusInputError}
      type="price"
    />
  );
};

export default MonthlyEstimatedBonus;
