import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  Notary,
  GuaranteeAdditionalInformationAction,
} from 'reducers/guaranteeAdditionalInformation/type';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface NameProps {
  notary: Notary;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
}

const NotaryName: React.FC<NameProps> = ({ notary, dispatch }) => {
  const [name, setName] = useState<string>(notary?.name || '');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');

  const noNumbers = new RegExp(ValidationRules.noNumbers);
  const noSpecialCharacters = new RegExp(ValidationRules.noSpecialCharacters);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const handleOnBlur = (value: string) => {
    setIsInputFocused(false);
    const valWithoutSpace = value.replace(/ /g, '');
    if (!notEmpty.test(valWithoutSpace)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.REQUIRED_VALUE);
      const newNotary = { ...notary, name: undefined };
      dispatch({
        type: 'setUpdateNotaryInformation',
        payload: newNotary,
      });
    } else if (!noNumbers.test(valWithoutSpace)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.NO_NUMBER);
      const newNotary = { ...notary, name: undefined };
      dispatch({
        type: 'setUpdateNotaryInformation',
        payload: newNotary,
      });
    } else if (!noSpecialCharacters.test(valWithoutSpace)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.NO_SPECIAL_CHARACTER);
      const newNotary = { ...notary, name: undefined };
      dispatch({
        type: 'setUpdateNotaryInformation',
        payload: newNotary,
      });
    } else {
      setIsInputValid(true);
      setInputError('');
      const newNotary = { ...notary, name: value };
      dispatch({
        type: 'setUpdateNotaryInformation',
        payload: newNotary,
      });
    }
  };

  useEffect(() => {
    if (notEmpty.test(notary?.name || '')) handleOnBlur(notary?.name || '');
  }, []);

  return (
    <CustomInput
      name="street"
      label="Nom du notaire"
      placeholder=""
      value={name}
      onChange={newValue => {
        setName(newValue.slice(0, 32).toUpperCase());
        setIsInputFocused(true);
      }}
      isValid={isInputValid}
      isFocused={isInputFocused}
      onBlur={value => {
        handleOnBlur(value);
      }}
      onFocus={() => {
        setIsInputFocused(true);
      }}
      inputWidth="46.4rem"
      error={inputError}
    />
  );
};

export default NotaryName;
