import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import React, { useEffect, useState } from 'react';
import {
  GuaranteeAdditionalInformationAction,
  GuaranteeAdditionalInformationState,
} from 'reducers/guaranteeAdditionalInformation/type';
import { Asset, OperationState } from 'reducers/operationClient/types';
import GuaranteeAdditionalInformationCreateMode from './createMode/GuaranteeAdditionalInformationCreateMode';
import GuaranteeAdditionalInformationViewMode from './viewMode/GuaranteeAdditionalInformationViewMode';
import * as messages from '../messages';
import NotaryName from './createMode/additionalInformation/notaire/NotaryName';
import NotaireZipCodeAndCity from './createMode/additionalInformation/notaire/NotaireZipCodeAndCity';
import { StyledInputLabel, StyledNotaryBlock } from '../style';

interface GuaranteesProps {
  state: GuaranteeAdditionalInformationState;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
  stateIsCorrect: boolean;
}

const Guarantees: React.FC<GuaranteesProps> = ({ state, dispatch, stateIsCorrect }) => {
  const [guaranteeList, setGuaranteeList] = useState<Asset[]>([]);

  const getGuarantees = (): Asset[] => {
    let result: Asset[] = [];
    const operationsClientFromSessionStorage = sessionStorage.getItem('operationsClient');
    if (operationsClientFromSessionStorage) {
      try {
        result = (JSON.parse(operationsClientFromSessionStorage) as OperationState)
          .assetList;
      } catch (error) {
        return result;
      }
    }
    return result;
  };

  useEffect(() => {
    setGuaranteeList(getGuarantees());
  }, []);

  const renderNotaireInfo = () => {
    return (
      <StyledNotaryBlock>
        <StyledInputLabel style={{ fontWeight: 'bold' }}>
          Coordonnées du notaire
        </StyledInputLabel>
        <Space marginTop="1.6rem" />
        <NotaryName notary={state.notary} dispatch={dispatch} />
        <Space marginTop="2.4rem" />
        <NotaireZipCodeAndCity notary={state.notary} dispatch={dispatch} />
      </StyledNotaryBlock>
    );
  };

  const render = () => {
    if (state.guarantees.length === 0) {
      return null;
    }
    return state.guarantees.map(item => {
      const guarantee = guaranteeList.find(element => element.code === item.code);
      if (guarantee) {
        if (item.isAdditionalInformationCompleted) {
          return (
            <GuaranteeAdditionalInformationViewMode
              key={guarantee.code}
              currentGuarantee={guarantee}
              state={state}
              dispatch={dispatch}
            />
          );
        }
        return (
          <GuaranteeAdditionalInformationCreateMode
            key={guarantee.code}
            currentGuarantee={guarantee}
            state={state}
            dispatch={dispatch}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      {state.guarantees.length > 0 && (
        <>
          <StyledTitle>{messages.GUARANTEE.complementary_infos}</StyledTitle>
          <Space marginTop="2.4rem" />
          {!stateIsCorrect && <ErrorMessageArea message={messages.INCORRECT_DATA} />}
          {renderNotaireInfo()}
          <Space marginTop="2.4rem" />
        </>
      )}
      {render()}
    </>
  );
};

export default Guarantees;
