import * as React from 'react';

interface ChevronUpProps {
  color?: string;
}

const ChevronUp: React.FC<ChevronUpProps> = ({ color }) => (
  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg">
    <title>Ic/24px/Navigation/chevron-up24px</title>
    <path
      d="M11.455 7.21A.8.8 0 0 1 12 7a.8.8 0 0 1 .545.21l9.23 8.57c.3.279.3.731 0 1.01-.301.28-.789.28-1.09 0L12 8.726l-8.685 8.066c-.301.279-.789.279-1.09 0a.68.68 0 0 1 0-1.012l9.23-8.57Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

ChevronUp.defaultProps = {
  color: '#292C2E',
};

export default ChevronUp;
