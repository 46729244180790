import * as React from 'react';

const GeneralIllustrationDocuments: React.FC = () => (
  <svg
    width="205px"
    height="168px"
    viewBox="0 0 205 168"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>DB48EA70-890C-4DDD-B64A-7CC3859AB2C8</title>
    <defs>
      <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
        <stop stopColor="#808080" stopOpacity="0.25" offset="0%" />
        <stop stopColor="#808080" stopOpacity="0.12" offset="54%" />
        <stop stopColor="#808080" stopOpacity="0.22" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="42.5267443%"
        y1="99.1659674%"
        x2="57.3110678%"
        y2="0.586791248%"
        id="linearGradient-2">
        <stop stopColor="#808080" stopOpacity="0.25" offset="0%" />
        <stop stopColor="#808080" stopOpacity="0.12" offset="54%" />
        <stop stopColor="#808080" stopOpacity="0.1" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-3">
        <stop stopColor="#808080" stopOpacity="0.25" offset="0%" />
        <stop stopColor="#808080" stopOpacity="0.12" offset="54%" />
        <stop stopColor="#808080" stopOpacity="0.216673951" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="58.0906367%"
        y1="99.3751341%"
        x2="41.9407884%"
        y2="0.63021927%"
        id="linearGradient-4">
        <stop stopColor="#808080" stopOpacity="0.25" offset="0%" />
        <stop stopColor="#808080" stopOpacity="0.12" offset="54%" />
        <stop stopColor="#808080" stopOpacity="0.1" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-5">
        <stop stopColor="#808080" stopOpacity="0.25" offset="0%" />
        <stop stopColor="#808080" stopOpacity="0.12" offset="54%" />
        <stop stopColor="#808080" stopOpacity="0.1" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="linearGradient-6">
        <stop stopColor="#808080" stopOpacity="0.25" offset="0%" />
        <stop stopColor="#808080" stopOpacity="0.12" offset="54%" />
        <stop stopColor="#808080" stopOpacity="0.1" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50.0035258%"
        y1="100%"
        x2="50.0035258%"
        y2="0%"
        id="linearGradient-7">
        <stop stopColor="#808080" stopOpacity="0.25" offset="0%" />
        <stop stopColor="#808080" stopOpacity="0.12" offset="54%" />
        <stop stopColor="#808080" stopOpacity="0.1" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Parcours-montage-dossier-🗂"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="pop/up/sauvegarder/quitter"
        transform="translate(-199.000000, -44.000000)"
        fillRule="nonzero">
        <g id="Illustration/Documents" transform="translate(199.200000, 44.000000)">
          <g id="docs" transform="translate(-0.000000, 0.000000)">
            <path
              d="M63.8291401,39.1572752 C70.6687352,32.9704329 81.4062016,31.7106369 91.1787966,32.2029911 C121.874969,33.7501397 149.584054,48.7765797 173.552298,65.7741885 C182.229162,71.92774 190.771677,78.5648848 196.409108,87.0330266 C207.875899,104.282945 204.473549,128.180521 188.53136,142.390459 C183.106794,147.226394 176.530663,150.932191 169.780052,154.219225 C157.777609,160.060894 144.829488,164.749648 131.204386,166.084786 C121.48937,167.036204 111.662686,166.260001 101.996524,164.935376 C75.0132739,161.224322 48.5272907,153.020755 26.3265233,138.979023 C16.5853346,132.821967 7.40771461,125.166823 3.39468685,115.200593 C-0.6183409,105.234363 1.94999686,92.6171293 11.8918369,86.7211439 C16.004318,84.2821509 24.4369054,80.1244914 42.0839621,69.0765756 C59.7310189,58.0286598 56.9982689,45.3248438 63.8291401,39.1572752 Z"
              id="Path"
              fill="#83CA87"
              opacity="0.1"
            />
            <g id="undraw_hiring_cyhs" transform="translate(2.400000, 0.000000)">
              <g
                id="Group"
                opacity="0.5"
                transform="translate(79.127624, 0.000000)"
                fill="url(#linearGradient-1)">
                <path
                  d="M-5.27148697,20.150561 L98.7387071,20.150561 C99.8432766,20.150561 100.738707,21.0459915 100.738707,22.150561 L100.738707,96.3231288 C100.738707,97.4276983 99.8432766,98.3231288 98.7387071,98.3231288 L-5.27148697,98.3231288 C-6.37605647,98.3231288 -7.27148697,97.4276983 -7.27148697,96.3231288 L-7.27148697,22.150561 C-7.27148697,21.0459915 -6.37605647,20.150561 -5.27148697,20.150561 Z"
                  id="Rectangle"
                  transform="translate(46.733610, 59.236845) rotate(-81.420000) translate(-46.733610, -59.236845) "
                />
              </g>
              <path
                d="M75.1105428,20.7517077 L176.733095,20.7517077 C177.837665,20.7517077 178.733095,21.6471382 178.733095,22.7517077 L178.733095,94.9352321 C178.733095,96.0398016 177.837665,96.9352321 176.733095,96.9352321 L75.1105428,96.9352321 C74.0059733,96.9352321 73.1105428,96.0398016 73.1105428,94.9352321 L73.1105428,22.7517077 C73.1105428,21.6471382 74.0059733,20.7517077 75.1105428,20.7517077 Z"
                id="Rectangle"
                fill="#FFFFFF"
                transform="translate(125.921819, 58.843470) rotate(-81.420000) translate(-125.921819, -58.843470) "
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(126.268741, 53.495962) rotate(8.580000) translate(-126.268741, -53.495962) "
                points="98.7763309 51.8295465 153.76115 51.8295465 153.76115 55.1623783 98.7763309 55.1623783"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(125.025947, 61.732903) rotate(8.580000) translate(-125.025947, -61.732903) "
                points="97.533537 60.066487 152.518356 60.066487 152.518356 63.3993189 97.533537 63.3993189"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(143.425225, 29.122872) rotate(8.580000) translate(-143.425225, -29.122872) "
                points="129.262169 27.4564557 157.58828 27.4564557 157.58828 30.7892876 129.262169 30.7892876"
              />
              <polygon
                id="Rectangle"
                fill="#83CA87"
                transform="translate(142.183406, 37.361933) rotate(8.580000) translate(-142.183406, -37.361933) "
                points="130.519481 35.6955167 153.847331 35.6955167 153.847331 39.0283486 130.519481 39.0283486"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(120.487379, 69.474057) rotate(8.580000) translate(-120.487379, -69.474057) "
                points="96.3268144 67.8076411 144.647943 67.8076411 144.647943 71.140473 96.3268144 71.140473"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(115.947835, 77.215064) rotate(8.580000) translate(-115.947835, -77.215064) "
                points="95.1201029 75.548648 136.775568 75.548648 136.775568 78.8814799 95.1201029 78.8814799"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(112.233937, 85.079855) rotate(8.580000) translate(-112.233937, -85.079855) "
                points="93.9053353 83.4134394 130.562539 83.4134394 130.562539 86.7462713 93.9053353 86.7462713"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(115.933602, 94.063678) rotate(8.580000) translate(-115.933602, -94.063678) "
                points="92.606739 92.3972623 139.260465 92.3972623 139.260465 95.7300942 92.606739 95.7300942"
              />
              <polygon
                id="Rectangle"
                fill="#6C63FF"
                transform="translate(100.687693, 100.187747) rotate(8.580000) translate(-100.687693, -100.187747) "
                points="91.5238852 98.5213308 109.851501 98.5213308 109.851501 101.854163 91.5238852 101.854163"
              />
              <g
                id="Group"
                opacity="0.5"
                transform="translate(102.214737, 16.970014)"
                fill="url(#linearGradient-2)">
                <path
                  d="M22.1458686,12.9248364 C22.9359509,7.68249519 19.9157832,2.61129761 14.9300885,0.808738564 C9.94439368,-0.993820482 4.3794854,0.973475452 1.63441773,5.50899913 C-1.11064994,10.0445228 -0.272588244,15.8871344 3.63671351,19.4681582 C3.63671351,19.4839442 3.63671351,19.5017036 3.63671351,19.5174896 C3.94324695,19.917736 4.32623546,20.2531024 4.76344353,20.5041183 L4.78514936,20.4567602 C5.23307881,20.9757269 7.29513286,22.0097138 9.78735704,22.3787129 L9.84260825,22.3787129 C11.4212142,22.6194504 13.1754401,22.5977445 14.8033775,22.0097138 C14.8033775,22.0097138 14.8211368,21.9820882 14.8507357,21.9307835 C15.2595984,21.8087823 15.6460716,21.6215427 15.995225,21.3762982 L16.0307437,21.2539562 C19.3148063,19.6521489 21.6012439,16.5379014 22.1458686,12.9248364 L22.1458686,12.9248364 Z"
                  id="Path"
                />
              </g>
              <g
                id="Group"
                opacity="0.5"
                transform="translate(0.000000, 15.194083)"
                fill="url(#linearGradient-3)">
                <path
                  d="M10.2034774,5.71610384 L84.5985205,5.71610384 C85.70309,5.71610384 86.5985205,6.61153434 86.5985205,7.71610384 L86.5985205,112.03369 C86.5985205,113.138259 85.70309,114.03369 84.5985205,114.03369 L10.2034774,114.03369 C9.09890787,114.03369 8.20347737,113.138259 8.20347737,112.03369 L8.20347737,7.71610384 C8.20347737,6.61153434 9.09890787,5.71610384 10.2034774,5.71610384 Z"
                  id="Rectangle"
                  transform="translate(47.400999, 59.874897) rotate(-9.180542) translate(-47.400999, -59.874897) "
                />
              </g>
              <path
                d="M11.1537945,21.4411237 L83.3373188,21.4411237 C84.4418883,21.4411237 85.3373188,22.3365542 85.3373188,23.4411237 L85.3373188,125.063676 C85.3373188,126.168246 84.4418883,127.063676 83.3373188,127.063676 L11.1537945,127.063676 C10.049225,127.063676 9.15379446,126.168246 9.15379446,125.063676 L9.15379446,23.4411237 C9.15379446,22.3365542 10.049225,21.4411237 11.1537945,21.4411237 Z"
                id="Rectangle"
                fill="#FFFFFF"
                transform="translate(47.245557, 74.252400) rotate(-9.310000) translate(-47.245557, -74.252400) "
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(45.946306, 69.047471) rotate(-9.310000) translate(-45.946306, -69.047471) "
                points="18.4538963 67.3810546 73.4387156 67.3810546 73.4387156 70.7138865 18.4538963 70.7138865"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(47.293966, 77.269305) rotate(-9.310000) translate(-47.293966, -77.269305) "
                points="19.8015566 75.602889 74.786376 75.602889 74.786376 78.9357208 19.8015566 78.9357208"
              />
              <rect
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(54.789146, 40.583100) rotate(-9.310000) translate(-54.789146, -40.583100) "
                x="40.6260905"
                y="38.9166836"
                width="28.326111"
                height="3.33283186"
              />
              <rect
                id="Rectangle"
                fill="#83CA87"
                transform="translate(56.135833, 48.805093) rotate(-9.310000) translate(-56.135833, -48.805093) "
                x="44.4719078"
                y="47.1386776"
                width="23.3278498"
                height="3.33283186"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(45.353743, 86.029820) rotate(-9.310000) translate(-45.353743, -86.029820) "
                points="21.1931784 84.3634039 69.5143073 84.3634039 69.5143073 87.6962358 21.1931784 87.6962358"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(43.412227, 94.788547) rotate(-9.310000) translate(-43.412227, -94.788547) "
                points="22.5844939 93.1221313 64.2399591 93.1221313 64.2399591 96.4549631 22.5844939 96.4549631"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(42.293877, 103.415739) rotate(-9.310000) translate(-42.293877, -103.415739) "
                points="23.9652752 101.749323 60.6224792 101.749323 60.6224792 105.082155 23.9652752 105.082155"
              />
              <polygon
                id="Rectangle"
                fill="#E0E0E0"
                transform="translate(48.574195, 110.826806) rotate(-9.310000) translate(-48.574195, -110.826806) "
                points="25.2473321 109.16039 71.9010584 109.16039 71.9010584 112.493222 25.2473321 112.493222"
              />
              <polygon
                id="Rectangle"
                fill="#83CA87"
                transform="translate(35.946808, 121.340525) rotate(-9.310000) translate(-35.946808, -121.340525) "
                points="26.7830005 119.674109 45.1106159 119.674109 45.1106159 123.006941 26.7830005 123.006941"
              />
              <g
                id="Group"
                opacity="0.5"
                transform="translate(14.996757, 36.702589)"
                fill="url(#linearGradient-4)">
                <path
                  d="M22.8976797,9.75973144 C22.0152413,4.38157086 17.4592694,0.373979749 12.0124819,0.184733419 C6.56569432,-0.00451291227 1.74256062,3.67720514 0.488976997,8.98115053 C-0.764606622,14.2850959 1.89991651,19.7365162 6.85509644,22.0057673 L6.8708825,22.0550987 C7.29730113,22.3477638 7.77750775,22.5529918 8.28373485,22.6589155 L8.28373485,22.6056376 C8.88557838,22.9726634 11.2298083,23.3337696 13.7851767,22.9075459 L13.8404279,22.9075459 C15.4604723,22.6451027 17.1693132,22.0689115 18.5762458,20.9796734 C18.5762458,20.9796734 18.5762458,20.9481013 18.5959784,20.8889036 C18.9576648,20.6405312 19.2768417,20.3353474 19.5411687,19.9851516 C19.5411687,19.9404245 19.5411687,19.8970128 19.5411687,19.8549166 C22.2546137,17.248192 23.5100632,13.4722486 22.8976797,9.75973144 L22.8976797,9.75973144 Z"
                  id="Path"
                />
              </g>
              <g
                id="Group"
                opacity="0.5"
                transform="translate(55.645861, 32.558748)"
                fill="url(#linearGradient-5)">
                <rect
                  id="Rectangle"
                  x="0.12431522"
                  y="0.092743101"
                  width="78.1725679"
                  height="108.010194"
                  rx="2"
                />
              </g>
              <rect
                id="Rectangle"
                fill="#FFFFFF"
                x="56.7646976"
                y="33.4467141"
                width="76.1835243"
                height="105.622552"
                rx="2"
              />
              <g id="Group-8" transform="translate(66.301451, 42.227710)">
                <rect
                  id="Rectangle"
                  fill="url(#linearGradient-6)"
                  x="1.12434402e-13"
                  y="1.00387859e-14"
                  width="59.1609904"
                  height="33.6028322"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="0.631536579"
                  y="4.89122961"
                  width="57.7410927"
                  height="27.9246543"
                />
                <rect
                  id="Rectangle"
                  fill="url(#linearGradient-7)"
                  x="4.71603825"
                  y="7.54029244"
                  width="20.035392"
                  height="21.1402278"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  opacity="0.8"
                  x="5.20629148"
                  y="7.73102496"
                  width="18.931969"
                  height="20.1936293"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  opacity="0.2"
                  x="0.631536579"
                  y="0.631536579"
                  width="57.7410927"
                  height="4.25969303"
                />
                <rect
                  id="Rectangle"
                  fill="#E0E0E0"
                  x="31.8678726"
                  y="13.6465589"
                  width="21.9285889"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#F5F5F5"
                  x="31.8678726"
                  y="19.6412114"
                  width="21.9285889"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  opacity="0.2"
                  x="31.7110481"
                  y="16.6445916"
                  width="12.9359036"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  opacity="0.2"
                  x="31.7110481"
                  y="22.6392442"
                  width="8.83444793"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  x="42.1222182"
                  y="22.6392442"
                  width="8.83444793"
                  height="1.41989768"
                />
                <path
                  d="M15.7530936,21.8014339 L15.7530936,21.0851273 C17.9574606,20.5159622 19.3947288,18.3964636 19.1078598,16.1379489 C18.8209908,13.8794342 16.8994872,12.1865555 14.6228268,12.1865555 C12.3461664,12.1865555 10.4246629,13.8794342 10.1377939,16.1379489 C9.85092489,18.3964636 11.2881931,20.5159622 13.49256,21.0851273 L13.49256,21.8014339 C11.5801053,22.318337 10.2495372,24.0503896 10.2430429,26.0314574 L10.2430429,28.0094243 L19.0026107,28.0094243 L19.0026107,26.0314574 C18.9961164,24.0503896 17.6655483,22.318337 15.7530936,21.8014339 L15.7530936,21.8014339 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <circle
                  id="Oval"
                  fill="#FFFFFF"
                  cx="14.6228268"
                  cy="15.7177728"
                  r="4.80363393"
                />
              </g>
              <g
                id="Group-8"
                transform="translate(95.436017, 97.490526) scale(-1, 1) translate(-95.436017, -97.490526) translate(65.372859, 80.311579)">
                <rect
                  id="Rectangle"
                  fill="url(#linearGradient-6)"
                  x="1.12434402e-13"
                  y="1.00387859e-14"
                  width="59.1609904"
                  height="33.6028322"
                />
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="0.631536579"
                  y="4.89122961"
                  width="57.7410927"
                  height="27.9246543"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  opacity="0.2"
                  x="0.631536579"
                  y="0.631536579"
                  width="57.7410927"
                  height="4.25969303"
                />
                <rect
                  id="Rectangle"
                  fill="#E0E0E0"
                  x="31.7694452"
                  y="13.6154765"
                  width="21.9285889"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#F5F5F5"
                  x="31.7694452"
                  y="19.7325747"
                  width="21.9285889"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  opacity="0.2"
                  x="40.8464296"
                  y="16.5753627"
                  width="12.9359036"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  opacity="0.2"
                  x="44.7929445"
                  y="22.6924609"
                  width="8.83444793"
                  height="1.41989768"
                />
                <rect
                  id="Rectangle"
                  fill="#83CA87"
                  x="33.7427027"
                  y="22.6924609"
                  width="8.83444793"
                  height="1.41989768"
                />
              </g>
              <path
                d="M147.23933,122.517073 C145.559333,114.237659 138.359346,108 129.599947,108 C122.63996,108 116.639971,111.959415 113.639391,117.717073 C106.439404,118.437073 100.8,124.556488 100.8,132 C100.8,139.918829 107.278817,146.4 115.199974,146.4 L146.399916,146.4 C152.999319,146.4 158.4,140.999415 158.4,134.4 C158.42621,128.103498 153.525106,122.885172 147.23933,122.517073 Z M133.44,127.470141 L133.44,136.8 L125.76,136.8 L125.76,127.470141 L117.6,127.470141 L129.6,115.2 L141.6,127.47613 L133.44,127.470141 Z"
                id="Shape"
                fill="#3A913F"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GeneralIllustrationDocuments;
