import { TransfertOctroiApiRequestBody, TransfertOctroiApiResponse } from 'types';
import { transfertOctroiInstance } from './axiosInstances';

const transfertProject = (
  data: TransfertOctroiApiRequestBody,
): Promise<TransfertOctroiApiResponse> => {
  return transfertOctroiInstance
    .post('transfertOctroi', { ...data })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      console.error(err);
      const message = err.response ? err.response.data : `${err}`;
      throw Error(message);
    });
};

export default transfertProject;
