import * as React from 'react';

interface InsuranceIconProps {
  width?: number;
  color?: string;
}

const InsuranceIcon: React.FC<InsuranceIconProps> = ({ width, color }) => {
  const baseWidth = 54;
  const baseHeight = 54;

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg width={width} height={scaledHeight} xmlns="http://www.w3.org/2000/svg">
      <title>E2E1DD35-0677-4782-B5E5-4EBB3326D4FF</title>
      <path
        d="M26.671.333c.924 0 1.674.766 1.674 1.708v2.75c20.956 2.065 24.667 18.053 25.307 22.986a1.717 1.717 0 0 1-.68 1.608 1.643 1.643 0 0 1-1.71.153c-1.776-.876-4.219-1.249-7.265-1.105-3.744.177-6.155 1.25-7.314 1.865a1.65 1.65 0 0 1-1.15.544h-.078c-.092 0-.182-.008-.27-.022l.122.005c-.293 0-.588-.079-.848-.236-.215-.129-.436-.273-.67-.426-1.173-.77-2.757-1.809-5.435-1.97l-.009 18.43c0 .072 0 .151.002.232.011 1.046.03 2.992-1.186 4.471-1.29 1.575-3.077 2.34-5.46 2.34-1.845 0-3.557-.676-4.7-1.857-1.094-1.13-1.661-2.683-1.638-4.489.01-.736.022-1.269.036-1.584.067-1.49 1.03-1.802 1.438-1.867.808-.129 1.59.36 1.846 1.15.088.272.107.552.063.816-.007.133-.022.519-.034 1.53-.011.883.215 1.57.672 2.043.518.533 1.36.84 2.315.84 1.743 0 2.443-.565 2.896-1.117.422-.51.41-1.553.404-2.24l-.002-.268.006-18.386c-2.541.192-4.32.771-5.501 1.775a1.649 1.649 0 0 1-1.9.173 14.588 14.588 0 0 1-.852-.551c-1.054-.718-2.365-1.609-4.607-1.734-2.947-.166-6.609.42-9.556 1.527a1.647 1.647 0 0 1-1.602-.25c-.456-.36-.7-.934-.644-1.519.496-5.147 2.837-10.345 6.425-14.259 4.467-4.875 10.595-7.75 18.23-8.56V2.042c0-.944.751-1.708 1.675-1.708Zm5.652 8.54.13.217c1.304 2.17 2.37 5.122 3.19 8.843.715 3.24 1.098 6.388 1.295 8.505 1.597-.632 3.896-1.277 6.907-1.419 2.23-.105 4.222.04 5.957.432-.64-2.536-1.893-5.801-4.335-8.822-3.154-3.898-7.561-6.495-13.144-7.755Zm-5.456-.667-.056.032c-4.882 2.913-6.423 12.053-6.737 17.566 1.839-.714 4.144-1.056 7.02-1.056.297 0 .6.004.91.011 2.38.056 4.177.653 5.53 1.316-.205-1.992-.562-4.702-1.167-7.432-1.315-5.934-3.255-9.6-5.5-10.437Zm-5.49.692L21 8.987c-4.808 1.181-8.757 3.438-11.787 6.744-3.098 3.379-4.479 7.047-5.096 9.65 2.733-.701 5.694-1.035 8.211-.893 1.875.105 3.299.6 4.408 1.16.228-4.096 1.136-11.694 4.64-16.75Z"
        fill={color}
        fillRule="nonzero"
        transform={`scale(${scaleRatio})`}
      />
    </svg>
  );
};

InsuranceIcon.defaultProps = {
  width: 54,
  color: '#292C2E',
};

export default InsuranceIcon;
