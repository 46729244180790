import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  DebtsAdditionalInformationAction,
  DebtsAdditionalInformationState,
  DebtsCompleteData,
} from 'reducers/debtsAdditionalInformation/type';
import { formatNumberByThousand } from 'utils/commun';
import { checkFormatDate, getDateFromInput, isDateIntheFuture } from 'utils/DateUtils';
import { DateInput } from 'components/designSystem/DateInput';
import { StatusInput } from 'types';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface OtherDebtProps {
  debt: DebtsCompleteData;
  state: DebtsAdditionalInformationState;
  dispatch: React.Dispatch<DebtsAdditionalInformationAction>;
  checkInputs: boolean;
}

const OtherDebt: React.FC<OtherDebtProps> = ({ debt, state, dispatch, checkInputs }) => {
  const [reloadStatusDate, setReloadStatusDate] = useState<boolean>(false);
  const [statusDate, setStatusDate] = useState<StatusInput>();

  const updateValue = (value: string) => {
    const result = state.debts.map(item => {
      if (item.code === debt.code) {
        return { ...item, dueDate: value };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data: result, debtCode: debt.code },
    });
  };

  const checkIsDateValid = (): void => {
    if (reloadStatusDate === false) {
      return;
    }
    setReloadStatusDate(false);

    const value = getDateFromInput(debt.dueDate || '');

    if (debt.dueDate === undefined || debt.dueDate === '') {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (!checkFormatDate(debt.dueDate)) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_FORMAT,
      });
    } else if (value.getFullYear() >= 2100) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_OVER_2100,
      });
    } else if (isDateIntheFuture(value)) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_IN_THE_PAST,
      });
    } else if (!isDateIntheFuture(getDateFromInput(debt.dueDate))) {
      setStatusDate({
        status: 'valid',
        errorMessage: '',
      });
    } else {
      setStatusDate({
        status: 'valid',
        errorMessage: '',
      });
    }
  };

  useEffect(() => {
    if (debt.dueDate) setReloadStatusDate(true);
  }, []);

  useEffect(() => {
    if (checkInputs) setReloadStatusDate(true);
  }, [checkInputs]);

  useEffect(() => {
    checkIsDateValid();
  }, [reloadStatusDate]);

  const onDateBlur = () => {
    setStatusDate({
      status: 'none',
      errorMessage: '',
    });
    setReloadStatusDate(true);
  };

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <div>
        <CustomInput
          name="dueDate"
          label={debt.libelle}
          type="price"
          isDisabled
          value={formatNumberByThousand((debt.amount || '').toString())}
          after="€"
          inputWidth="50.4rem"
          isFocused={false}
        />
      </div>
      <div>
        <DateInput
          class="show-input-ds-validation"
          label="Date d'exigibilité"
          onBlur={onDateBlur}
          onInputChange={e => {
            updateValue((e.target as HTMLInputElement).value);
          }}
          value={debt.dueDate}
          {...(statusDate?.status !== 'none' ? { status: statusDate?.status } : {})}
          {...(statusDate?.errorMessage !== ''
            ? { 'error-message': statusDate?.errorMessage }
            : {})}
        />
      </div>
    </div>
  );
};

export default OtherDebt;
