import { DateInput } from 'components/designSystem/DateInput';
import { useEffect, useReducer, useState } from 'react';
import {
  borrowerIdentityReducer,
  getBorrowerInitialState,
} from 'reducers/identity/borrowerIdentity';
import { coBorrowerIdentityReducer } from 'reducers/identity/coBorrowerIdentity';
import {
  SituationProfessionnelleAction,
  SituationProfessionnelleState,
} from 'reducers/situationProfessionnelle/types';
import {
  checkCurrentSituationDate,
  getDateFromInput,
  isDateIntheFuture,
  isRightAge,
} from 'utils/DateUtils';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface SituationStartingDateProps {
  state: SituationProfessionnelleState;
  dispatch: React.Dispatch<SituationProfessionnelleAction>;
  person: 'borrower' | 'coBorrower';
  checkInputs: boolean;
}

interface StatusStartingDate {
  status: 'valid' | 'invalid' | '';
  errorMessage: string;
}

const SituationStartingDate: React.FC<SituationStartingDateProps> = ({
  state,
  dispatch,
  person,
  checkInputs,
}) => {
  const inputId = `${person}-currentSituationDate`;
  const [currentJobOrSituationStartingDate, setCurrentJobOrSituationStartingDate] =
    useState<string | undefined>(
      person === 'borrower'
        ? state.borrower.currentJobOrSituationStartingDate
        : state.coBorrower.currentJobOrSituationStartingDate,
    );

  const [statusStartingDate, setStatusStartingDate] = useState<StatusStartingDate>();

  const [reloadStatus, setReloadStatus] = useState<boolean>(false);

  const [borrowerState] = useReducer(
    borrowerIdentityReducer,
    getBorrowerInitialState('borrowerIdentity'),
  );
  const [coBorrowerState] = useReducer(
    coBorrowerIdentityReducer,
    getBorrowerInitialState('coBorrowerIdentity'),
  );

  const lastDayOfMonth = '31/';

  const checkStatusSituationStartingDate = () => {
    if (reloadStatus === true) {
      setReloadStatus(false);
      const birthDate =
        person === 'borrower' ? borrowerState.birthDate : coBorrowerState.birthDate;
      const currentSituationDate =
        person === 'borrower'
          ? state.borrower.currentJobOrSituationStartingDate
          : state.coBorrower.currentJobOrSituationStartingDate;

      if (currentSituationDate === '') {
        setStatusStartingDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
        return;
      }
      if (
        currentJobOrSituationStartingDate &&
        !checkCurrentSituationDate(currentJobOrSituationStartingDate)
      ) {
        setStatusStartingDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_FORMAT_MONTH,
        });
        return;
      }
      if (
        !isDateIntheFuture(
          getDateFromInput(`${lastDayOfMonth}${currentJobOrSituationStartingDate}`),
        )
      ) {
        setStatusStartingDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.SITUATION_STARTING_DATE_NO_FUTUR,
        });
        return;
      }
      if (
        !isRightAge(
          getDateFromInput(`${birthDate}`),
          getDateFromInput(`${lastDayOfMonth}${currentJobOrSituationStartingDate}`),
          16,
        )
      ) {
        setStatusStartingDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.SITUATION_STARTING_DATE_TOO_YOUNG,
        });
        return;
      }
      setStatusStartingDate({
        status: 'valid',
        errorMessage: '',
      });
    }
  };

  useEffect(() => {
    if (currentJobOrSituationStartingDate !== '') {
      setReloadStatus(true);
    }
  }, []);
  useEffect(() => {
    if (checkInputs) {
      setReloadStatus(true);
    }
  }, [checkInputs]);

  useEffect(() => {
    setCurrentJobOrSituationStartingDate(
      person === 'borrower'
        ? state.borrower.currentJobOrSituationStartingDate
        : state.coBorrower.currentJobOrSituationStartingDate,
    );
  }, [state]);

  useEffect(() => {
    checkStatusSituationStartingDate();
  }, [reloadStatus]);

  const handleDateBlur = () => {
    setStatusStartingDate({
      status: '',
      errorMessage: '',
    });
    setReloadStatus(true);
  };

  return (
    <>
      <DateInput
        id={inputId}
        no-day
        className="show-input-ds-validation"
        label="Chez l’employeur actuel (pour salarié) ou dans la même situation depuis (pour artisan, commerçant, profession libérale)"
        onBlur={handleDateBlur}
        onInputChange={e => {
          dispatch({
            type: 'setCurrentJobOrSituationStartingDate',
            payload: {
              person,
              value: (e.target as HTMLInputElement).value,
            },
          });
        }}
        value={
          person === 'borrower'
            ? state.borrower.currentJobOrSituationStartingDate
            : state.coBorrower.currentJobOrSituationStartingDate
        }
        {...(statusStartingDate?.status !== ''
          ? { status: statusStartingDate?.status }
          : {})}
        {...(statusStartingDate?.errorMessage !== ''
          ? { 'error-message': statusStartingDate?.errorMessage }
          : {})}
      />
    </>
  );
};

export default SituationStartingDate;
