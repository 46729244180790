const ProfilIcon: React.FC = () => (
  <svg
    width={40}
    height={40}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>C67D8677-9615-4139-B17E-8A765CF83259</title>
    <defs>
      <circle id="a" cx={20} cy={20} r={20} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#F5F9F6" xlinkHref="#a" />
      <g mask="url(#b)" fill="#A6D8A9">
        <g transform="translate(7.375 6.917)">
          <path d="M26.25 25.924v1.97c0 2.4-1.494 4.345-3.337 4.345H3.337C1.494 32.239 0 30.293 0 27.894v-1.97c0-2.45 1.144-4.642 2.878-5.479 2.814-1.36 6.24-2.206 10.247-2.206 4.006 0 7.433.847 10.247 2.206 1.734.837 2.878 3.028 2.878 5.48Zm-1.78-1.686c0-1.164-.688-2.193-1.717-2.581-2.621-.988-5.84-1.61-9.628-1.61-3.789 0-7.007.622-9.628 1.61-1.03.388-1.717 1.417-1.717 2.58v1.538c0 .874.697 1.583 1.557 1.583h19.576c.86 0 1.557-.709 1.557-1.583v-1.537ZM13.125 14.59c2.882 0 5.228-2.448 5.228-5.48V7.289c0-3.031-2.346-5.48-5.228-5.48-2.882 0-5.228 2.449-5.228 5.48v1.824c0 3.031 2.346 5.479 5.228 5.479Zm0 1.809c-3.875 0-7.007-3.268-7.007-7.288V7.288C6.118 3.268 9.25 0 13.125 0 17 0 20.132 3.268 20.132 7.288v1.824c0 4.02-3.132 7.288-7.007 7.288Z" />
          <path d="M1.625 23.848c.169-.69.34-1.148.515-1.372.348-.447.946-.617 1.357-.819 2.525-1.243 6.454-1.61 9.128-1.61 2.436 0 7.31.062 9.848 1.142 1.31.557 2.027 1.443 2.152 2.659v11.2h-23v-11.2Z" />
          <ellipse cx={13.125} cy={8.083} rx={6.5} ry={7} />
        </g>
      </g>
    </g>
  </svg>
);

export default ProfilIcon;
