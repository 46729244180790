import AuthorizationService from 'api/AuthorizationService';
import Space from 'components/space/Space';
import { isVip } from 'containers/communs/utils';
import {
  cleanFileSessionStorageData,
  userCanChangeApa,
  userHasSelectedApa,
} from 'utils/commun';
import getEnvironmentVariables from 'utils/EnvironmentVariables';
import HouseIcon from 'icons/HouseIcon';
import FileIcon from 'icons/FileIcon';
import LookingGlassIcon from 'icons/LookingGlassIcon';
import SimulationIcon from 'icons/SimulationIcon';
import FilingCabinetIcon from 'icons/FilingCabinetIcon';
import ContractPaper from 'icons/ContractPaper';
import InsuranceIcon from 'icons/InsuranceIcon';
import BankIcon from 'icons/BankIcon';
import BookIcon from 'icons/BookIcon';
import GraduateHatIcon from 'icons/GraduateHatIcon';
import FolderSearchIcon from 'icons/FolderSearchIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import PowerUpIcon from 'icons/PowerUpIcon';
import AccessWebsiteIcon from 'icons/AccessWebsiteIcon';
import {
  StyledIconContainer,
  StyledSIdeMenuLinkBouton,
  StyledSideMenuCategoryTitle,
  StyledSideMenuLink,
  StyledSideMenuLinkContainer,
  StyledSideMenuLinkGroup,
  StyledSideMenuMainContainer,
  StyledSideMenuSignOut,
  StyledSideMenuUnderlinedLink,
} from './style';
import * as messages from './messages';

interface SideBarProps {
  children: React.ReactNode;
}

const SideBar: React.FC<SideBarProps> = ({ children }) => {
  const iconColor = '#4B4F54';

  const location = useLocation();
  const navigate = useNavigate();

  const ebizURL = getEnvironmentVariables().ebizUrl;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <StyledSideMenuMainContainer>
        <img
          style={{ height: '7.8rem', width: '17.3rem' }}
          src={`${process.env.PUBLIC_URL}/logo_sygma.jpg`}
          alt="logo"
        />

        <Space marginBottom="6.4rem" />

        <StyledSideMenuLinkContainer
          onClick={() => {
            navigate('/dashboard');
          }}>
          <StyledIconContainer>
            <HouseIcon width={24} color={iconColor} />
          </StyledIconContainer>
          {location.pathname === '/dashboard' ? (
            <StyledSideMenuUnderlinedLink>
              {messages.SIDE_MENU_HOME}
            </StyledSideMenuUnderlinedLink>
          ) : (
            <StyledSideMenuLink>{messages.SIDE_MENU_HOME}</StyledSideMenuLink>
          )}
        </StyledSideMenuLinkContainer>

        <Space marginBottom="3.2rem" />

        <StyledSideMenuCategoryTitle>
          {messages.SIDE_MENU_MY_FILES}
        </StyledSideMenuCategoryTitle>
        <StyledSideMenuLinkGroup>
          {(!userCanChangeApa() || userHasSelectedApa()) && (
            <>
              <StyledSideMenuLinkContainer
                onClick={() => {
                  navigate('/donnees_client');
                  cleanFileSessionStorageData();
                }}>
                <StyledIconContainer>
                  <FileIcon />
                </StyledIconContainer>
                <StyledSideMenuLink>{messages.SIDE_MENU_CREATE_FILE}</StyledSideMenuLink>
              </StyledSideMenuLinkContainer>
              <StyledSideMenuLinkContainer
                onClick={() => {
                  navigate('/recherche');
                }}>
                <StyledIconContainer>
                  <LookingGlassIcon />
                </StyledIconContainer>
                {location.pathname === '/recherche' ? (
                  <StyledSideMenuUnderlinedLink>
                    {messages.SIDE_MENU_SEARCH_FILE}
                  </StyledSideMenuUnderlinedLink>
                ) : (
                  <StyledSideMenuLink>
                    {messages.SIDE_MENU_SEARCH_FILE}
                  </StyledSideMenuLink>
                )}
              </StyledSideMenuLinkContainer>
            </>
          )}
          {isVip() && (
            <StyledSideMenuLinkContainer
              onClick={() => {
                navigate('/bordereau');
              }}>
              <StyledIconContainer>
                <FolderSearchIcon />
              </StyledIconContainer>
              <StyledSideMenuLink>
                {messages.SIDE_MENU_RECHERCHE_BORDEREAU}
              </StyledSideMenuLink>
            </StyledSideMenuLinkContainer>
          )}
          <StyledSideMenuLinkContainer
            onClick={() => {
              navigate('/calculette');
            }}>
            <StyledIconContainer>
              <SimulationIcon color={iconColor} />
            </StyledIconContainer>
            {location.pathname === '/calculette' ? (
              <StyledSideMenuUnderlinedLink>
                {messages.SIDE_MENU_FINANCIAL_CALCULATOR}
              </StyledSideMenuUnderlinedLink>
            ) : (
              <StyledSideMenuLink>
                {messages.SIDE_MENU_FINANCIAL_CALCULATOR}
              </StyledSideMenuLink>
            )}
          </StyledSideMenuLinkContainer>
        </StyledSideMenuLinkGroup>

        <StyledSideMenuCategoryTitle>
          {messages.SIDE_MENU_MY_SYGMA_OFFERS}
        </StyledSideMenuCategoryTitle>
        <StyledSideMenuLinkGroup>
          <StyledSideMenuLinkContainer
            onClick={() => {
              window.open(`${ebizURL}/category/produits/`);
            }}>
            <StyledIconContainer>
              <FilingCabinetIcon />
            </StyledIconContainer>
            <StyledSideMenuLink>{messages.SIDE_MENU_PRODUCTS}</StyledSideMenuLink>
          </StyledSideMenuLinkContainer>
          <StyledSideMenuLinkContainer
            style={{ gap: '0.4rem', marginLeft: '0.4rem' }}
            onClick={() => {
              window.open(`${ebizURL}/category/tarification/`);
            }}>
            <StyledIconContainer>
              <ContractPaper color={iconColor} />
            </StyledIconContainer>
            <StyledSideMenuLink>{messages.SIDE_MENU_PRICING}</StyledSideMenuLink>
          </StyledSideMenuLinkContainer>
          <StyledSideMenuLinkContainer
            onClick={() => {
              window.open(`${ebizURL}/category/assurance/`);
            }}>
            <StyledIconContainer>
              <InsuranceIcon width={24} color={iconColor} />
            </StyledIconContainer>
            <StyledSideMenuLink>{messages.SIDE_MENU_INSURANCE}</StyledSideMenuLink>
          </StyledSideMenuLinkContainer>
        </StyledSideMenuLinkGroup>

        <StyledSideMenuCategoryTitle>
          {messages.SIDE_MENU_SIGMA_TOOLS}
        </StyledSideMenuCategoryTitle>
        <StyledSideMenuLinkGroup>
          <StyledSideMenuLinkContainer
            onClick={() => {
              window.open(`${ebizURL}/category/documentation/`);
            }}>
            <StyledIconContainer>
              <BookIcon />
            </StyledIconContainer>
            <StyledSideMenuLink>{messages.SIDE_MENU_DOCUMENTATION}</StyledSideMenuLink>
          </StyledSideMenuLinkContainer>
          <StyledSideMenuLinkContainer
            onClick={() => {
              window.open(`${ebizURL}/category/reglementation/`);
            }}>
            <StyledIconContainer>
              <BankIcon width={24} color={iconColor} />
            </StyledIconContainer>
            <StyledSideMenuLink>{messages.SIDE_MENU_LEGAL}</StyledSideMenuLink>
          </StyledSideMenuLinkContainer>
          <StyledSideMenuLinkContainer
            onClick={() => {
              window.open(`${ebizURL}/formation/`);
            }}>
            <StyledIconContainer>
              <GraduateHatIcon />
            </StyledIconContainer>
            <StyledSideMenuLink>{messages.SIDE_MENU_TRAINING}</StyledSideMenuLink>
          </StyledSideMenuLinkContainer>
          <StyledSIdeMenuLinkBouton
            onClick={() => {
              window.open(ebizURL);
            }}>
            <AccessWebsiteIcon color="#4B4F54" width={17} />
            {messages.SIDE_MENU_NEWS_AND_INFOS}
          </StyledSIdeMenuLinkBouton>
        </StyledSideMenuLinkGroup>

        <StyledSideMenuLinkContainer
          onClick={() => {
            AuthorizationService.disconnect(navigate);
          }}
          onKeyDown={() => {
            AuthorizationService.disconnect(navigate);
          }}>
          <StyledIconContainer>
            <PowerUpIcon />
          </StyledIconContainer>
          <StyledSideMenuSignOut>{messages.SIDE_MENU_LOGOUT}</StyledSideMenuSignOut>
        </StyledSideMenuLinkContainer>
      </StyledSideMenuMainContainer>
      {children}
    </div>
  );
};

export default SideBar;
