import CustomInput from 'components/customInput/CustomInput';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import { useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import { addInInvalidInputs, removeFromInvalidInputs } from 'containers/communs/utils';

interface DeathInsuranceProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
  selectedInsurance: 'GC' | 'GP';
  isSG: boolean;
  isCheckboxDisabled?: boolean;
  isInputDisabled?: boolean;
}

const DeathInsurance: React.FC<DeathInsuranceProps> = ({
  person,
  state,
  dispatch,
  selectedInsurance,
  isSG,
  isCheckboxDisabled,
  isInputDisabled,
}) => {
  const [isDeathInsuranceRateInputFocused, setIsDeathInsuranceRateInputFocused] =
    useState<boolean>(false);
  const [isDeathInsuranceRateInputValid, setIsDeathInsuranceRateInputValid] = useState<
    boolean | undefined
  >();
  const [deathInsuranceRateInputError, setDeathInsuranceRateInputError] =
    useState<string>('');

  const handleDeathInsuranceRateChange = (value: string) => {
    let rate = parseInt(value, 10);

    if (rate > 100) rate = 100;

    if (Number.isNaN(rate)) {
      dispatch({
        type: 'setDeathInsuranceRate',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setDeathInsuranceRate',
        payload: { person, value: rate },
      });
    }
  };

  const handleDeathInsuranceRateBlur = () => {
    if (selectedInsurance === 'GC' || isSG) {
      return;
    }
    setIsDeathInsuranceRateInputFocused(false);
    const deathInsuranceRate =
      person === 'borrower'
        ? state.borrowerInsurance.deathInsuranceRate
        : state.coBorrowerInsurance.deathInsuranceRate;

    if (
      deathInsuranceRate === undefined ||
      Number.isNaN(deathInsuranceRate) ||
      (!isSG && deathInsuranceRate <= 0)
    ) {
      setIsDeathInsuranceRateInputValid(false);
      setDeathInsuranceRateInputError('Saisie incorrecte.');
      addInInvalidInputs(state, dispatch, 'deathInsurance');
    } else {
      setIsDeathInsuranceRateInputValid(true);
      setDeathInsuranceRateInputError('');
      removeFromInvalidInputs(state, dispatch, 'deathInsurance');
    }
  };

  const getDeathInsuranceRateValue = () => {
    if (person === 'borrower') {
      return state.borrowerInsurance.deathInsuranceRate;
    }
    if (state.coBorrowerInsurance.deathInsuranceRate !== undefined) {
      return state.coBorrowerInsurance.deathInsuranceRate;
    }
    return '';
  };

  /**
   * Si la garantie est grisée, son checkbox est coché par défaut.
   * @returns
   */
  const isChecked = () => {
    if (isCheckboxDisabled) {
      return true;
    }
    return undefined;
  };

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <BaseCheckbox
        disabled={isCheckboxDisabled}
        checked={isChecked()}
        className="assurance-checkbox"
        onCheckedChanged={e => {
          if ((e as CustomEvent)?.detail?.value) {
            dispatch({
              type: 'setDeathInsuranceChecked',
              payload: { person, value: true },
            });
          } else {
            dispatch({
              type: 'setDeathInsuranceChecked',
              payload: { person, value: false },
            });
          }
        }}
      />
      <CustomInput
        name="death-insurance"
        isDisabled={isInputDisabled}
        label="Garantie décès"
        value={getDeathInsuranceRateValue()}
        onChange={newValue => {
          if (isInputDisabled) {
            return;
          }
          handleDeathInsuranceRateChange(newValue);
          setIsDeathInsuranceRateInputFocused(true);
        }}
        isValid={isDeathInsuranceRateInputValid}
        isFocused={isDeathInsuranceRateInputFocused}
        onBlur={handleDeathInsuranceRateBlur}
        onFocus={() => {
          if (isInputDisabled) {
            return;
          }
          setIsDeathInsuranceRateInputFocused(true);
        }}
        after="%"
        inputWidth="17.2rem"
        placeholder="0"
        className="bareme-input"
        error={deathInsuranceRateInputError}
      />
    </div>
  );
};

export default DeathInsurance;
