import styled from 'styled-components';

export const StyledRectangle = styled.div<{
  backgroundColor: string;
  width: string;
  height?: string;
}>`
  border-radius: 0.4rem;
  background-color: ${({ theme, backgroundColor }) => {
    if (backgroundColor === 'blue') {
      return theme.colors.chartColors.blue50;
    }
    return backgroundColor || theme.colors.chartColors.neutral0;
  }};
  max-width: ${({ width }) => width};
  display: flex;
  align-items: center;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.15);
`;

export const StyledContentWrapper = styled.div<{ alignIconWithText?: boolean }>`
  display: flex;
  padding: 1.8rem;
  flex-direction: ${({ alignIconWithText }) => (alignIconWithText ? 'row' : 'column')};
  align-items: ${({ alignIconWithText }) => (alignIconWithText ? 'flex-start' : 'unset')};
`;

export const StyledTitleWrapper = styled.div<{ bold?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export const StyledContent = styled.div`
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.chartColors.neutral500};
  width: 100%;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2rem;
`;

export const StyledIcon = styled.div<{ alignIconWithText?: boolean }>`
  margin-top: ${({ alignIconWithText }) => (alignIconWithText ? '' : '0.5rem')};
`;
