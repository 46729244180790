import Space from 'components/space/Space';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledHorizontalBar,
  StyledMainDisplayCard,
  StyledNoOtherIncomeContainer,
  StyledTotalData,
  StyledTotalLabel,
  StyleNoDataContainer,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import { OperationState } from 'reducers/operationClient/types';
import { displayNumber } from 'utils/commun';
import * as messages from '../../messages';

interface AutresBesoinsConsultationProps {
  isProjectEditable: boolean | undefined;
}

const AutresBesoinsConsultation: React.FC<AutresBesoinsConsultationProps> = ({
  isProjectEditable,
}) => {
  const [operationClient, setOperationClient] = useState<OperationState>();

  useEffect(() => {
    const operationsClientFromSession = sessionStorage.getItem('operationsClient');

    if (operationsClientFromSession) {
      const operationClientObject = JSON.parse(
        operationsClientFromSession,
      ) as OperationState;
      setOperationClient(operationClientObject);
    }
  }, []);

  const renderOtherNeed = () => {
    return (
      <>
        {operationClient && operationClient.otherNeedsList.length > 0 ? (
          <>
            <StyledCardTitle>{messages.MAIN_CARD_LABEL_OTHER_NEED}</StyledCardTitle>
            <Space marginBottom="1.6rem" />
            <StyledCardRow style={{ gap: '4rem', flexWrap: 'wrap' }}>
              {operationClient?.otherNeedsList.map(otherIncome => {
                return (
                  <StyledCardTile key={otherIncome.uid}>
                    <StyledCardLabel>{otherIncome.libelle}</StyledCardLabel>
                    <StyledCardData>{`${otherIncome.amount.toLocaleString()} €`}</StyledCardData>
                  </StyledCardTile>
                );
              })}
            </StyledCardRow>
          </>
        ) : (
          <>
            <Space marginBottom="4rem" />
            <StyledNoOtherIncomeContainer>
              <span style={{ fontSize: '1.6rem', color: '#4B4F54' }}>
                {messages.NO_OHTER_NEED}
              </span>
            </StyledNoOtherIncomeContainer>
          </>
        )}

        <Space marginBottom="4rem" />

        <StyledCardRow style={{ gap: '0.8rem' }}>
          <StyledTotalLabel>{messages.MAIN_CARD_LABEL_TOTAL_NEED}</StyledTotalLabel>
          <StyledTotalData>
            {`${
              operationClient?.totalOtherNeedsAmount
                ? displayNumber(operationClient.totalOtherNeedsAmount)
                : 0
            } €`}
          </StyledTotalData>
        </StyledCardRow>

        <StyledHorizontalBar style={{ height: '0.2rem' }} />
      </>
    );
  };

  const renderBuyBackDept = () => {
    return (
      <>
        {operationClient && operationClient.buybackDebtsList.length > 0 ? (
          <>
            <Space marginBottom="4.8rem" />

            <StyledCardTitle>{messages.MAIN_CARD_LABEL_BUY_BACK_DEPT}</StyledCardTitle>
            <Space marginBottom="1.6rem" />
            <StyledCardRow style={{ gap: '4rem', flexWrap: 'wrap' }}>
              {operationClient?.buybackDebtsList.map(otherIncome => {
                return (
                  <StyledCardTile key={otherIncome.uid}>
                    <StyledCardLabel>{otherIncome.libelle}</StyledCardLabel>
                    <StyledCardData>{`${otherIncome.amount.toLocaleString()} €`}</StyledCardData>
                  </StyledCardTile>
                );
              })}
            </StyledCardRow>
          </>
        ) : (
          <>
            <Space marginBottom="4rem" />
            <StyledNoOtherIncomeContainer>
              <span style={{ fontSize: '1.6rem', color: '#4B4F54' }}>
                {messages.NO_BUY_BACK_DEPT}
              </span>
            </StyledNoOtherIncomeContainer>
          </>
        )}

        <Space marginBottom="4rem" />

        <StyledCardRow style={{ gap: '0.8rem' }}>
          <StyledTotalLabel>
            {messages.MAIN_CARD_LABEL_TOTAL_BUY_BACK_DEPT}
          </StyledTotalLabel>
          <StyledTotalData>
            {`${
              operationClient?.totalDebtValue
                ? displayNumber(operationClient.totalDebtValue)
                : 0
            } €`}
          </StyledTotalData>
        </StyledCardRow>

        <StyledHorizontalBar style={{ height: '0.2rem' }} />
      </>
    );
  };

  return (
    <>
      {(operationClient && operationClient.buybackDebtsList.length > 0) ||
      (operationClient && operationClient.otherNeedsList.length > 0) ? (
        <StyledMainDisplayCard>
          {renderOtherNeed()}
          {renderBuyBackDept()}

          <Space marginBottom="5.6rem" />

          <ConsultationEditButton
            isProjectEditable={isProjectEditable}
            navigateRoute="/operation_client"
          />
        </StyledMainDisplayCard>
      ) : (
        <>
          <StyleNoDataContainer>{messages.NO_NEED}</StyleNoDataContainer>

          <Space marginBottom="2.4rem" />

          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <ConsultationEditButton
              isProjectEditable={isProjectEditable}
              navigateRoute="/operation_client"
            />
          </div>
        </>
      )}
    </>
  );
};

export default AutresBesoinsConsultation;
