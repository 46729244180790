import { SessionStorageKeys } from 'types';

export const updateSessionStorage = (
  storageKey: SessionStorageKeys,
  value: unknown,
): void => {
  try {
    if (typeof value === 'object') {
      sessionStorage.setItem(storageKey, JSON.stringify(value));
    } else {
      sessionStorage.setItem(storageKey, value as string);
    }
  } catch (error) {
    console.error(error);
  }
};

export const getExistingStorageByKey = (
  storageKey: SessionStorageKeys,
  defaultValues?: unknown,
): unknown => {
  const valuesFromStorage = sessionStorage.getItem(storageKey);
  if (valuesFromStorage) {
    try {
      return JSON.parse(valuesFromStorage);
    } catch (error) {
      console.error(error);
      return defaultValues;
    }
  }
  return defaultValues;
};
