import GenericIconProps from './GenericIcon';

const LookingGlassIcon: React.FC<GenericIconProps> = ({ color }) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>983C292E-3531-43C5-B7DC-27F4E89EB113</title>
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dashboard5.2" transform="translate(-290.000000, -283.000000)" fill={color}>
        <g id="Recherche/nom" transform="translate(264.000000, 257.000000)">
          <g id="Icon/24px/Action/search" transform="translate(26.000000, 26.000000)">
            <path
              d="M14.678078,12.9272087 C17.1884081,9.50167412 16.6370211,4.72071921 13.4128326,1.9565461 C10.1886441,-0.807627022 5.37956722,-0.622324747 2.37763963,2.38175168 C-0.624287958,5.38582811 -0.806148985,10.1950364 1.96033054,13.4172461 C4.72681006,16.6394558 9.50815829,17.1874216 12.9318957,14.674641 L12.9306457,14.674641 C12.9681443,14.724639 13.0081427,14.7721372 13.053141,14.8183854 L17.8654545,19.6306989 C18.3535172,20.1191068 19.1451031,20.1193866 19.6335109,19.6313239 C20.1219188,19.1432611 20.1221987,18.3516753 19.634136,17.8632674 L14.8218224,13.0509539 C14.7771337,13.0057178 14.7290813,12.9639331 14.678078,12.9259587 L14.678078,12.9272087 Z M15.0005655,8.12239489 C15.0005655,10.5785007 13.6902487,12.848036 11.5631988,14.0760889 C9.43614877,15.3041418 6.8155151,15.3041418 4.68846511,14.0760889 C2.56141512,12.848036 1.25109835,10.5785007 1.25109835,8.12239489 C1.25109835,4.32558441 4.32902146,1.24766141 8.12583193,1.24766141 C11.9226424,1.24766141 15.0005655,4.32558441 15.0005655,8.12239489 Z"
              id="search"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LookingGlassIcon.defaultProps = {
  color: '#4B4F54',
};

export default LookingGlassIcon;
