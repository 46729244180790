import { ReactNode, useEffect, useRef, useState } from 'react';
import colors from 'styles/color';
import { formatNumberByThousand } from 'utils/commun';
import { formatPhoneValue, ValidationRules } from 'utils/InputValidation';
import {
  Wrapper,
  StyledInput,
  StyledLabel,
  InputContainer,
  IconWrapper,
  StyledCheckIcon,
  StyledWarningIcon,
  LabelToolTipStyle,
  StyledChild,
  StyledErrorMessage,
  CustomInputWrapper,
  StyledTooltip,
  StyledErrorWrapper,
} from './style';

const numericKeyPressedAllowed = (key: string) => {
  const allowedNumericInputs = [
    'Escape',
    'Enter',
    'Tab',
    'F1',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'F10',
    'F11',
    'F12',
    'Backspace',
    'Delete',
    'ArrowRight',
    'ArrowLeft',
    'ArrowUp',
    'ArrowDown',
    'End',
    'Home',
  ];

  return (
    (!Number.isNaN(Number(key)) && key !== ' ') || allowedNumericInputs.includes(key)
  );
};

const selectionIsLengthZero = (start: number | null, end: number | null) => {
  return start === end;
};

const backSpaceDeletesSpace = (key: string, value: string, cursorPosition: number) => {
  return key === 'Backspace' && value.slice(cursorPosition - 1, cursorPosition) === ' ';
};

const deleteDeletesSpace = (key: string, value: string, cursorPosition: number) => {
  return key === 'Delete' && value.slice(cursorPosition, cursorPosition + 1) === ' ';
};

const numberDeletesSpace = (key: string, value: string, cursorPosition: number) => {
  return (
    key >= '0' && key <= '9' && value.slice(cursorPosition, cursorPosition + 1) === ' '
  );
};

export interface InputProps {
  name: string;
  label?: string | ReactNode;
  type?: string;
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  after?: ReactNode;
  value?: string | number;
  isValid?: boolean;
  isFocused: boolean;
  inputWidth?: string;
  onClick?: () => void;
  onFocus?: () => void;
  role?: string;
  error?: string;
  children?: ReactNode;
  tooltip?: string;
  notEditable?: boolean;
  isDisabled?: boolean;
  hideIcon?: boolean;
  nowrap?: boolean;
  maxLength?: number;
  parentInputRef?: React.RefObject<HTMLInputElement>;
  onPaste?: (value: string) => void;
  styleInput?: any;
}

const CustomInput: React.FC<InputProps> = ({
  type = 'text',
  name,
  label = '',
  after,
  onChange = () => undefined,
  className = '',
  placeholder,
  value,
  isValid,
  isFocused,
  onBlur = () => undefined,
  inputWidth,
  onClick,
  onFocus = () => undefined,
  role,
  error,
  children,
  tooltip,
  notEditable,
  isDisabled,
  hideIcon,
  nowrap,
  maxLength,
  parentInputRef,
  onPaste,
  styleInput,
}) => {
  const [cursorPosition, setCursorPosition] = useState<number | null>(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.selectionStart = cursorPosition;
      inputRef.current.selectionEnd = cursorPosition;
    }
  }, [value]);

  const handleNumericOnKeyDown = event => {
    if (numericKeyPressedAllowed(event.key)) {
      const cursorStart = event.currentTarget.selectionStart || 0;
      if (selectionIsLengthZero(cursorStart, event.currentTarget.selectionEnd)) {
        if (backSpaceDeletesSpace(event.key, event.currentTarget.value, cursorStart)) {
          event.currentTarget.setSelectionRange(cursorStart - 1, cursorStart - 1);
        } else if (
          deleteDeletesSpace(event.key, event.currentTarget.value, cursorStart)
        ) {
          event.currentTarget.setSelectionRange(cursorStart + 1, cursorStart + 1);
        }
        if (
          type === 'phone' &&
          numberDeletesSpace(event.key, event.currentTarget.value, cursorStart)
        ) {
          event.currentTarget.setSelectionRange(cursorStart + 1, cursorStart + 1);
        }
      }
    } else {
      event.preventDefault();
    }
  };

  const handleNumericOnChange = event => {
    if (isDisabled) {
      event.preventDefault();
      return;
    }

    let newValue = value as string;
    const trimmedValue = event.target.value.replaceAll(' ', '');
    if (
      onlyNumbers.test(trimmedValue) &&
      (!maxLength || trimmedValue.length <= maxLength)
    ) {
      newValue = formatNumberByThousand(event.target.value.replaceAll(' ', ''));
    }

    const sizeDelta = newValue.length - event.target.value.length;

    if (event.target.selectionStart !== null) {
      let offsettedCursor = event.target.selectionStart + sizeDelta;
      offsettedCursor = offsettedCursor < 0 ? 0 : offsettedCursor;
      setCursorPosition(offsettedCursor);
    }

    onChange(newValue);
  };

  const handlePhoneOnChange = event => {
    if (isDisabled) {
      event.preventDefault();
      return;
    }

    let newValue = (value as string).trim();
    const trimmedValue = event.target.value.replaceAll(' ', '');
    if (
      onlyNumbers.test(trimmedValue) &&
      (!maxLength || trimmedValue.length <= maxLength)
    ) {
      newValue = formatPhoneValue(trimmedValue, trimmedValue);
    }

    // indicates if formating added or removed spaces
    let sizeDelta = newValue.length - event.target.value.length;

    const { selectionStart } = event.target;

    // if space has been added after cursor when adding a character, do not offset cursor
    if (
      sizeDelta > 0 &&
      newValue.slice(0, selectionStart) === event.target.value.slice(0, selectionStart)
    ) {
      sizeDelta = 0;
    } else if (sizeDelta < 0) {
      sizeDelta = 0;
    }

    if (selectionStart !== null) {
      let offsettedCursor = selectionStart + sizeDelta;
      // reset cursor if out of bounds
      offsettedCursor = offsettedCursor < 0 ? 0 : offsettedCursor;
      setCursorPosition(offsettedCursor);
    }

    onChange(newValue);
  };

  const getInputComponent = (typeInput: string) => {
    if (typeInput === 'price') {
      return (
        <StyledInput
          isDisabled={isDisabled}
          tabIndex={0}
          id={name}
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          onClick={e => {
            if (isDisabled) {
              e.preventDefault();
            }
          }}
          onBlur={event => {
            if (isDisabled) {
              event.preventDefault();
              return;
            }
            onBlur(event.target.value);
          }}
          onFocus={e => {
            if (isDisabled) {
              e.preventDefault();
              return;
            }
            onFocus();
          }}
          value={formatNumberByThousand(value as string)}
          onKeyDown={handleNumericOnKeyDown}
          onChange={handleNumericOnChange}
          autoComplete="off"
          role={role}
        />
      );
    }

    if (typeInput === 'phone') {
      return (
        <StyledInput
          isDisabled={isDisabled}
          tabIndex={0}
          id={name}
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          onClick={e => {
            if (isDisabled) {
              e.preventDefault();
            }
          }}
          onBlur={event => {
            if (isDisabled) {
              event.preventDefault();
              return;
            }
            onBlur(event.target.value);
          }}
          onFocus={e => {
            if (isDisabled) {
              e.preventDefault();
              return;
            }
            onFocus();
          }}
          value={formatPhoneValue(value as string, value as string)}
          onKeyDown={handleNumericOnKeyDown}
          onChange={handlePhoneOnChange}
          autoComplete="off"
          role={role}
        />
      );
    }

    return (
      <StyledInput
        isDisabled={isDisabled}
        ref={parentInputRef}
        tabIndex={0}
        id={name}
        className={className}
        type={type}
        value={value}
        placeholder={placeholder}
        onBlur={event => onBlur(event.target.value)}
        onFocus={e => {
          if (isDisabled) {
            e.preventDefault();
            return;
          }
          onFocus();
        }}
        onClick={e => {
          if (isDisabled) {
            e.preventDefault();
          }
        }}
        onChange={event => {
          if (isDisabled) {
            event.preventDefault();
            return;
          }
          onChange(event.target.value);
        }}
        onPaste={e => {
          if (onPaste) {
            onPaste(e.clipboardData.getData('text/plain'));
          }
        }}
        autoComplete="off"
        role={role}
      />
    );
  };

  const renderLabel = () => {
    if (typeof label === 'string') {
      return (
        <StyledLabel
          isDisabled={isDisabled}
          htmlFor={name}
          onClick={e => {
            if (isDisabled) {
              e.preventDefault();
            }
          }}>
          {label}
        </StyledLabel>
      );
    }
    return (
      <div
        style={{
          marginBottom: '0.8rem',
          color: isDisabled
            ? colors.chartColors.neutral300
            : colors.chartColors.neutral500,
        }}>
        {label}
      </div>
    );
  };

  return (
    <Wrapper className={className}>
      <LabelToolTipStyle>{label && renderLabel()}</LabelToolTipStyle>
      <CustomInputWrapper>
        <InputContainer
          style={styleInput}
          isValid={isValid && !isDisabled}
          isDisabled={isDisabled}
          inputWidth={inputWidth}
          onClick={e => {
            if (isDisabled) {
              e.preventDefault();
              return;
            }
            if (onClick) {
              onClick();
            }
          }}
          isFocused={isFocused && !isDisabled}
          notEditable={notEditable}>
          {getInputComponent(type)}
          {hideIcon ? (
            <></>
          ) : (
            isValid &&
            !isDisabled && (
              <IconWrapper>
                <StyledCheckIcon />
              </IconWrapper>
            )
          )}
          {hideIcon ? (
            <></>
          ) : (
            isValid === false && (
              <IconWrapper>
                <StyledErrorWrapper>
                  <StyledWarningIcon />
                </StyledErrorWrapper>
              </IconWrapper>
            )
          )}
          {after && <IconWrapper>{after}</IconWrapper>}
        </InputContainer>
        {children && <StyledChild>{children}</StyledChild>}
      </CustomInputWrapper>
      {tooltip && <StyledTooltip>{tooltip}</StyledTooltip>}
      {error && (
        <StyledErrorMessage style={nowrap ? { whiteSpace: 'nowrap' } : {}}>
          {error}
        </StyledErrorMessage>
      )}
    </Wrapper>
  );
};

CustomInput.defaultProps = {
  label: '',
  type: 'text',
  placeholder: '',
  className: '',
  onChange: () => undefined,
  onBlur: () => undefined,
  after: null,
  value: '',
  isValid: undefined,
  inputWidth: '',
  onClick: () => undefined,
  onFocus: () => undefined,
  role: '',
  error: '',
  children: null,
  tooltip: '',
  notEditable: undefined,
  isDisabled: undefined,
  hideIcon: false,
  nowrap: false,
};

export default CustomInput;
