import * as React from 'react';

const CheckCircleFill: React.FC = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>check-circle-fill</title>
    <path
      fill="#268038"
      fillRule="evenodd"
      d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M14.6439394,6.26780122 C14.3509096,5.97504473 13.8760359,5.97526637 13.5832794,6.26829625 L13.5832794,6.26829625 L8.99979155,11.8034315 L6.75536921,9.55915746 C6.46246632,9.26627391 5.98759258,9.2662896 5.69470904,9.55919249 C5.40182549,9.85209538 5.40184118,10.3269691 5.69474407,10.6198527 L5.69474407,10.6198527 L8.46974407,13.3946693 C8.76273679,13.6876427 9.23778791,13.687524 9.53063418,13.3944043 L9.53063418,13.3944043 L14.6444345,7.32846128 C14.937191,7.03543139 14.9369693,6.56055771 14.6439394,6.26780122 Z"
      id="check-circle-fill"
    />
  </svg>
);

export default CheckCircleFill;
