import React from 'react';
import color from 'styles/color';

interface TextButtonProps {
  onClick: () => void;
  textColor?: string;
  label: string;
  children?: React.ReactNode;
  width?: string;
  marginTop?: string;
  justifyContent?: string;
  textDecoration?: string;
}
const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  textColor,
  label,
  children = null,
  width,
  marginTop,
  justifyContent,
  textDecoration,
}) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        display: 'flex',
        gap: '0.5rem',
        width,
        fontSize: '1.6rem',
        marginTop,
        textDecoration,
        justifyContent: justifyContent || 'unset',
      }}
      onClick={onClick}
      role="button"
      onKeyDown={e => {
        if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter')
          onClick();
      }}
      tabIndex={0}>
      {children && <>{children}</>}
      <span
        style={{
          color: textColor,
          fontWeight: 'bold',
        }}>
        {label}
      </span>
    </div>
  );
};

TextButton.defaultProps = {
  textColor: color.chartColors.mainGreen500,
  children: null,
  width: '25.6rem',
  marginTop: '0',
  justifyContent: 'unset',
  textDecoration: 'none',
};

export default TextButton;
