import { hasCoBorrower } from 'containers/communs/utils';
import { OperationState } from 'reducers/operationClient/types';
import { SessionStorageKeys } from 'types';
import { getExistingStorageByKey } from 'utils/storage';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from './type';

const checkConsomationCase = (loan: LoanAdditionalData): boolean => {
  if (loan.creditType?.code === 'C') {
    if (!loan.loanType) {
      return false;
    }
  }
  return true;
};

const checkImmoCase = (loan: LoanAdditionalData): boolean => {
  if (loan.creditType?.code === 'I') {
    if (!loan.loanUsage?.code) {
      return false;
    }
  }
  return true;
};

const checkRenewableCase = (loan: LoanAdditionalData): boolean => {
  if (loan.creditType?.code === 'R') {
    if (!loan.loanStartingDate || !loan.loanDuration) {
      return false;
    }
  }
  return true;
};

const checkIfDataIsOk = (loan: LoanAdditionalData): boolean => {
  if (!checkConsomationCase(loan)) return false;
  if (!checkImmoCase(loan)) return false;
  if (!checkRenewableCase(loan)) return false;

  const {
    loanObject,
    loanNumber,
    indemnityAmount,
    nominalInterestRate,
    nbMonthsForNotice,
    titulaire,
  } = loan;

  if (
    !loanNumber ||
    nominalInterestRate === undefined ||
    nominalInterestRate === '' ||
    nbMonthsForNotice === undefined ||
    (hasCoBorrower() && titulaire === undefined)
  ) {
    return false;
  }

  if (loan.creditType?.code !== 'R' && indemnityAmount === undefined) {
    return false;
  }

  if (loan.creditType?.code === 'C' && !loanObject?.code) {
    return false;
  }

  return true;
};

export const getLoanAdditionalInformationInitialState = (
  storageKey: SessionStorageKeys,
): LoanAdditionalInformationState => {
  const getLoans = (): LoanAdditionalData[] => {
    const loans: LoanAdditionalData[] = [];
    const operationsClientFromSessionStorage = sessionStorage.getItem('operationsClient');
    if (operationsClientFromSessionStorage) {
      try {
        const { loanList } = JSON.parse(
          operationsClientFromSessionStorage,
        ) as OperationState;
        loanList
          .filter(loan => loan.buyBack)
          .forEach(loan => {
            loans.push({ code: loan.code } as LoanAdditionalData);
          });
      } catch (error) {
        return loans;
      }
    }
    return loans;
  };

  const laonAdditionalInfoDefaultValues = {
    loans: getLoans(),
    isDataOk: false,
  };

  const defaultValues = getExistingStorageByKey(
    storageKey,
    laonAdditionalInfoDefaultValues,
  ) as LoanAdditionalInformationState;

  const checkedDefaultValues = {
    ...defaultValues,
    loans: defaultValues.loans.map(loan => {
      const newLoan = { ...loan };
      newLoan.isDataOk = checkIfDataIsOk(newLoan);
      newLoan.isAdditionalInformationCompleted = newLoan.isDataOk;
      return newLoan;
    }),
  };

  return checkedDefaultValues;
};

export const loanAdditionalInformationReducer = (
  state: LoanAdditionalInformationState,
  action: LoanAdditionalInformationAction,
): LoanAdditionalInformationState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setUpdateLoansInformation': {
      newState.loans = action.payload.data;
      if (action.payload.loanCode === undefined) {
        return newState;
      }
      const result = newState.loans.map(item => {
        if (item.code === action.payload.loanCode) {
          const loan = {
            ...item,
            isDataOk: checkIfDataIsOk(item),
          };
          return loan;
        }
        return item;
      });
      newState.loans = result;
      return newState;
    }
    default:
      return newState;
  }
};
