import { StyledErrorMessage } from 'components/customInput/style';
import { ComboInput } from 'components/designSystem/ComboInput';
import DeleteConfirmationModal from 'components/modals/ConfirmationModals/DeleteConfirmationModal';
import { BaseInputContainer } from 'containers/communs/style';
import CrossCircle from 'icons/CrossCircle';
import { useEffect, useState } from 'react';
import { Bank, StatusInput } from 'types';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import { getNotEmpty } from 'utils/InputValidation';
import BankStartingYear from './BankStartingYear';

interface BankInputProps {
  listBank: Bank[];
  itemBank: Bank;
  indexBank: number;
  checkInputs: boolean;
  onBankBlur: (event: Event, selectedBankIndex: number) => void;
  onBankChange: (year: string, selectedBankIndex: number) => void;
  onBankSelect: (e: Event, selectedBankIndex: number) => void;
  onDeleteBank: (idToDelete: string, selectedBankIndex: number) => void;
}

const BankInput: React.FC<BankInputProps> = ({
  listBank,
  itemBank,
  indexBank,
  checkInputs,
  onBankBlur,
  onBankChange,
  onBankSelect,
  onDeleteBank,
}) => {
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState<boolean>(false);

  const [reloadBankStatus, setReloadBankStatus] = useState<boolean>(false);

  const [yearWithErrors, setYearWithErrors] = useState<string[]>([]);
  const [yearInputError, setYearInputError] = useState<string>();
  const [statusBankAccount, setStatusBankAccount] = useState<StatusInput>();

  const alignInputAndBtn = (id: string) => {
    if (yearWithErrors.length === 0) {
      return true;
    }
    const result = yearWithErrors.find(item => item === id);
    if (result) {
      return false;
    }
    return true;
  };

  const checkStatusBankAccount = () => {
    if (!reloadBankStatus) return;
    setReloadBankStatus(false);
    if (itemBank) {
      const bankMatchList = listBank?.find(bank => {
        return bank.libelle === itemBank.libelle;
      });
      if (bankMatchList) {
        setStatusBankAccount({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        setStatusBankAccount({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
  };

  useEffect(() => {
    if (reloadBankStatus) {
      checkStatusBankAccount();
    }
  }, [reloadBankStatus]);

  useEffect(() => {
    if (listBank.length !== 0) {
      if (itemBank.libelle !== '') {
        setStatusBankAccount({
          status: 'none',
          errorMessage: ``,
        });
        setReloadBankStatus(true);
      }
    }
  }, [listBank]);

  useEffect(() => {
    if (checkInputs) {
      setStatusBankAccount({
        status: 'none',
        errorMessage: ``,
      });
      setReloadBankStatus(true);
    }
  }, [checkInputs]);

  useEffect(() => {
    if (itemBank.libelle) {
      setStatusBankAccount({
        status: 'none',
        errorMessage: ``,
      });
      setReloadBankStatus(true);
    }
  }, []);

  return (
    <>
      <div style={{ display: 'flex', gap: '0.8rem' }} dir="ltr">
        <ComboInput
          class="combo-input bank-input"
          list-on-open
          shadow
          align-items="left"
          items={JSON.stringify(listBank)}
          field="libelle"
          label="Compte bancaire"
          placeholder={"Choisir l'organisme"}
          onListBlur={(e: Event) => {
            onBankBlur(e, indexBank);
            setReloadBankStatus(true);
          }}
          onSelectedList={(e: Event) => {
            onBankSelect(e, indexBank);
            setReloadBankStatus(true);
          }}
          value={itemBank.libelle}
          valid={statusBankAccount?.status === 'valid'}
          status={statusBankAccount?.status}
          error-message={statusBankAccount?.errorMessage}
          customValidityPattern={JSON.stringify(getNotEmpty())}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <span style={{ paddingBottom: '0.9rem', fontSize: '1.6rem', color: '#4b4f54' }}>
            Ouvert depuis
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: alignInputAndBtn(itemBank.id || '') ? 'center' : '',
              gap: '1.6rem',
            }}>
            <BaseInputContainer componentWidth="11rem">
              <BankStartingYear
                currentBank={itemBank}
                updateState={year => {
                  onBankChange(year, indexBank);
                }}
                checkInputs={checkInputs}
                hasError={(error, id) => {
                  if (error) {
                    setYearWithErrors(prevState => {
                      return [...prevState, id];
                    });
                  } else {
                    setYearWithErrors(prevState => {
                      return prevState.filter(elt => elt !== id);
                    });
                  }
                }}
                setYearInputError={setYearInputError}
              />
            </BaseInputContainer>
            <div
              style={{
                display: 'flex',
                fontWeight: 'bold',
                fontSize: '1.2rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsDeleteConfirmationVisible(true);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={event => {
                if (
                  event.key === 'Enter' ||
                  event.key === 'Space' ||
                  event.code === 'NumpadEnter'
                ) {
                  setIsDeleteConfirmationVisible(true);
                }
              }}>
              <CrossCircle />
              <span
                style={{
                  marginTop: '0.3rem',
                  marginLeft: '0.5rem',
                  fontSize: '1.4rem',
                }}>
                Supprimer
              </span>
            </div>
          </div>
          <StyledErrorMessage style={{ fontSize: '1.4rem' }}>
            {yearInputError}
          </StyledErrorMessage>
        </div>
      </div>

      <DeleteConfirmationModal
        isVisible={isDeleteConfirmationVisible}
        accept={() => {
          onDeleteBank(itemBank.code, indexBank);
          setIsDeleteConfirmationVisible(false);
        }}
        close={() => {
          setIsDeleteConfirmationVisible(false);
        }}
      />
    </>
  );
};

export default BankInput;
