import styled from 'styled-components';

export const StyledTextAreaContainer = styled.div`
  background: ${props => props.color};
  padding: 1.8rem;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`;

export const StyledTextAreaTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
`;
