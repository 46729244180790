import NextButton from 'components/buttons/nextButton/NextButton';
import Space from 'components/space/Space';
import Modal from 'components/modals/Modal';
import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import {
  PlainModalText,
  StyledBlackTitle,
  StyledGeneralModalContentContainer,
} from '../style';
import GeneralIllustrationDocuments from './Assets/GeneralIllustrationDocuments';
import * as messages from './messages';

interface FailedReliabilityModalProps {
  type: 'address' | 'emp' | 'co-emp';
  correctionAdress?: string;
  isVisible: boolean;
  close: () => void;
  continueWithoutReliability: () => void;
}

const FailedReliabilityModal: React.FC<FailedReliabilityModalProps> = ({
  type,
  isVisible,
  correctionAdress,
  close,
  continueWithoutReliability,
}) => {
  let messageAddress = messages.FAILED_RELIABILITY_ADDRESS_CONTENT;
  if (correctionAdress)
    messageAddress = `${messages.CORRECTION_RELIABILITY_ADDRESS_CONTENT}\n\n${correctionAdress}`;
  let compMessageMail = "de l'emprunteur";
  if (type === 'co-emp') compMessageMail = 'du co-emprunteur';
  return (
    <Modal isVisible={isVisible} height="48.8rem" width="60rem" onClose={close}>
      <StyledGeneralModalContentContainer style={{ paddingBottom: '2.7rem' }}>
        <div style={{ marginLeft: '12.7rem' }}>
          <GeneralIllustrationDocuments />
        </div>

        <Space marginBottom="3.6rem" />

        <StyledBlackTitle>
          {type === 'address'
            ? messages.FAILED_RELIABILITY_ADDRESS_TITLE
            : `${messages.FAILED_RELIABILITY_MAIL_TITLE} ${compMessageMail}`}
        </StyledBlackTitle>

        <Space marginBottom="1.6rem" />

        <PlainModalText style={{ whiteSpace: 'pre-line' }}>
          {' '}
          {type === 'address'
            ? messageAddress
            : `${messages.FAILED_RELIABILITY_MAIL_CONTENT} ${compMessageMail} ?`}
        </PlainModalText>

        <Space marginBottom="4.8rem" />

        <div
          style={{
            display: 'flex',
            height: '4.8rem',
            marginLeft: '9.7rem',
          }}>
          <div style={{ marginRight: '1.6rem' }}>
            <StyledSecondaryButton onClick={() => close()}>
              {correctionAdress ? 'non' : 'oui'}
            </StyledSecondaryButton>{' '}
          </div>

          <NextButton
            isDisabled={false}
            handleonClick={continueWithoutReliability}
            label={correctionAdress ? 'oui' : 'non'}
          />
        </div>
      </StyledGeneralModalContentContainer>
    </Modal>
  );
};

export default FailedReliabilityModal;
