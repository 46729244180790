const TagIcon: React.FC = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>B0E3F22D-AA63-4114-96C6-65EE5E437F70</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dashboard5.1"
          transform="translate(-26.000000, -937.000000)"
          fill="#4B4F54"
          fillRule="nonzero">
          <g id="Menu-Copy-5" transform="translate(-446.000000, 0.000000)">
            <g id="Mes-liens-utiles" transform="translate(470.000000, 849.000000)">
              <g id="My-challenge" transform="translate(2.000000, 88.000000)">
                <g id="Group" transform="translate(0.000000, 0.000000)">
                  <path
                    d="M15.2751216,6.00033351 C15.0825288,6.00033351 14.8965771,5.96765455 14.7239077,5.88268926 C14.4516213,5.73890183 14.2723107,5.48400594 14.2258228,5.16375213 C14.0797179,4.16377596 14.3187986,3.34026616 16.0454929,1.64096024 C17.1944087,0.510268223 18.2569897,-0.0714172664 19.0406433,0.00701223776 C19.3660587,0.0396911978 19.631704,0.183478622 19.7910912,0.418767134 C20.1962002,1.02006 20.0766598,2.27493207 18.8546916,3.60169784 C18.6488165,3.82391477 18.2968365,3.84352215 18.0643969,3.6409126 C17.8385985,3.43830304 17.8186751,3.09190607 18.0245501,2.86315335 C18.8347682,1.98735722 18.9144618,1.34031381 18.8812561,1.1050253 C18.675381,1.11809688 18.017909,1.2618843 16.8357876,2.41871949 C15.3083273,3.92195165 15.2618393,4.4578866 15.3149684,4.90885625 C15.4743556,4.89578466 15.7599242,4.81081937 15.9724404,4.70624669 C16.2447268,4.56899506 16.5834246,4.68010353 16.7228883,4.95460679 C16.8623521,5.22257426 16.7494528,5.55589966 16.4705253,5.69315129 C16.2845736,5.77158079 15.7732065,6.00033351 15.2751216,6.00033351 Z"
                    id="Path"
                  />
                  <path
                    d="M7.15301751,20 C7.0666828,20 6.98698922,19.9934642 6.91393677,19.9803926 C6.52211,19.9215705 6.26310586,19.7189609 6.21661794,19.6797462 C6.20333568,19.6666746 6.19005341,19.6601388 6.17677115,19.6470672 L0.412268817,13.9739998 C0.372422026,13.934785 0.332575236,13.8824987 0.306010709,13.8302123 C-0.138945116,12.9478804 -0.0791749305,12.6276266 0.359139763,12.0590127 C0.57829711,11.7714379 3.58672978,8.69961561 5.54586364,6.71273484 C6.49554547,5.75197342 7.86361861,4.3729213 8.96604647,3.28144404 C10.6595351,1.60828128 10.7193052,1.58213811 10.9849505,1.58213811 C11.0845675,1.57560232 11.5228822,1.5102444 11.9412735,1.45142227 C14.6043673,1.06581054 15.4610733,0.974309455 15.7864887,1.05273896 C16.2181623,1.15731163 17.108074,1.93507088 17.5995177,2.38604053 C18.9808731,3.65398418 19.0672078,4.07227487 19.0937723,4.2095265 C19.2332361,4.90232045 18.7085867,8.12446592 18.5425584,9.09829893 C18.522635,9.20940739 18.469506,9.31398006 18.3898124,9.39240957 C18.0112679,9.7714855 9.17856269,18.67977 8.40819141,19.4313861 C7.94995332,19.8823557 7.50499749,20 7.15301751,20 Z M6.93386017,18.8497006 C7.05340054,18.9281301 7.28584015,18.9869522 7.61789673,18.6601626 C8.33513896,17.9542971 16.3709083,9.8564508 17.4666951,8.75190195 C17.7721871,6.94148756 18.0511147,4.85656991 17.9979856,4.44481501 C17.7854694,3.98730957 16.0056461,2.36643315 15.5208435,2.12460885 C15.0758877,2.10500147 13.0104957,2.40564791 12.1139429,2.53636375 C11.6357814,2.60825746 11.3701362,2.64093642 11.2173901,2.66054379 C10.10168,3.62784101 1.63423705,12.2485507 1.24905141,12.7256635 C1.1627167,12.8433077 1.12951104,12.8955941 1.11622878,12.9217373 C1.12951104,12.993631 1.20920462,13.1570258 1.26233368,13.2681342 L6.93386017,18.8497006 Z"
                    id="Shape"
                  />
                  <path
                    d="M4.13130258,13.2681342 C3.99183881,13.2681342 3.85237505,13.2158479 3.74611694,13.117811 C3.52695959,12.9086657 3.51367733,12.5622687 3.73283468,12.3400518 L8.94612308,6.9937739 C9.15863929,6.77809276 9.51061927,6.76502118 9.73641775,6.98070231 C9.9555751,7.18984766 9.96885736,7.53624463 9.74970002,7.75846156 L4.53641162,13.0982036 C4.43015351,13.2093121 4.27740748,13.2681342 4.13130258,13.2681342 Z"
                    id="Path"
                  />
                  <path
                    d="M5.6853274,14.9020822 C5.54586364,14.9020822 5.39975874,14.8497959 5.29350063,14.7386874 C5.07434328,14.5230063 5.07434328,14.1766093 5.29350063,13.9609282 L10.6130471,8.72575878 C10.8322045,8.51007764 11.1841845,8.51007764 11.4033418,8.72575878 C11.6224992,8.94143992 11.6224992,9.28783689 11.4033418,9.50351803 L6.07715417,14.7452232 C5.97089607,14.8497959 5.8314323,14.9020822 5.6853274,14.9020822 Z"
                    id="Path"
                  />
                  <path
                    d="M7.35225146,16.4314576 C7.2127877,16.4314576 7.0666828,16.3791712 6.96042469,16.2680628 C6.74126735,16.0523816 6.74126735,15.7059846 6.96042469,15.4903035 L9.62351851,12.8694509 C9.84267586,12.6537698 10.1946558,12.6537698 10.4138132,12.8694509 C10.6329705,13.0851321 10.6329705,13.431529 10.4138132,13.6472102 L7.75071937,16.2680628 C7.631179,16.3791712 7.49171523,16.4314576 7.35225146,16.4314576 Z"
                    id="Path"
                  />
                  <path
                    d="M14.7770367,7.26827716 C13.6148387,7.26827716 12.6651568,6.3401947 12.6651568,5.1898953 C12.6651568,4.03959591 13.6081975,3.11151344 14.7770367,3.11151344 C15.9458759,3.11151344 16.8889166,4.03959591 16.8889166,5.1898953 C16.8889166,6.3401947 15.9392348,7.26827716 14.7770367,7.26827716 Z M14.7770367,4.21606229 C14.2258228,4.21606229 13.780867,4.65396036 13.780867,5.19643109 C13.780867,5.73890183 14.2258228,6.1767999 14.7770367,6.1767999 C15.3282507,6.1767999 15.7732065,5.73890183 15.7732065,5.19643109 C15.7732065,4.65396036 15.3282507,4.21606229 14.7770367,4.21606229 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TagIcon;
