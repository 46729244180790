import AcquisitionValue from 'containers/finalisationProjet/blockGuarantees/createMode/additionalInformation/AcquisitionValue';
import { OperationState } from 'reducers/operationClient/types';
import { CodeLibelle, SessionStorageKeys } from 'types';
import { isUndefinedOrNull } from 'utils/commun';
import { ValidationRules } from 'utils/InputValidation';
import { getExistingStorageByKey } from 'utils/storage';
import {
  GuaranteeAdditionalData,
  GuaranteeAdditionalInformationState,
  GuaranteeAdditionalInformationAction,
} from './type';

export const getGuaranteeAdditionalInformationInitialState = (
  storageKey: SessionStorageKeys,
): GuaranteeAdditionalInformationState => {
  const getGuarantees = (): GuaranteeAdditionalData[] => {
    const guarantees: GuaranteeAdditionalData[] = [];
    const operationsClientFromSessionStorage = sessionStorage.getItem('operationsClient');
    if (operationsClientFromSessionStorage) {
      try {
        const { assetList } = JSON.parse(
          operationsClientFromSessionStorage,
        ) as OperationState;
        assetList.forEach(item => {
          guarantees.push({
            code: item.code,
            useBorrowerAddress: item.isAssetAddressBorrowerAddress,
          } as GuaranteeAdditionalData);
        });
      } catch (error) {
        return guarantees;
      }
    }
    return guarantees;
  };

  const guaranteeAdditionalInfoDefaultValues = {
    guarantees: getGuarantees(),
    isDataOk: false,
  };

  return getExistingStorageByKey(
    storageKey,
    guaranteeAdditionalInfoDefaultValues,
  ) as GuaranteeAdditionalInformationState;
};

const zipCode = new RegExp(ValidationRules.zipCode);
const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
const notEmpty = new RegExp(ValidationRules.notEmpty);

export const isZipCodeOkay = (zipCodeParam: string | undefined): boolean => {
  if (zipCodeParam === undefined) return false;
  if (!zipCode.test(zipCodeParam)) return false;
  if (!notEmpty.test(zipCodeParam)) return false;
  if (!onlyNumbers.test(zipCodeParam)) return false;
  if (!zipCodeNoZero.test(zipCodeParam)) return false;

  return true;
};

export const isAddressCityOkay = (addressCityParam: CodeLibelle | undefined): boolean => {
  if (addressCityParam === undefined || addressCityParam.code === '') return false;
  if (!notEmpty.test(addressCityParam.libelle)) return false;
  if (addressCityParam.code === 'err') return false;
  return true;
};

export const checkIfNotaireDatasAreOk = (
  guarantee: GuaranteeAdditionalInformationState,
): boolean => {
  const { notary } = guarantee;
  if (
    !notary ||
    !notary.name ||
    !isZipCodeOkay(notary.addressZipCode) ||
    !isAddressCityOkay(notary.addressCity)
  ) {
    return false;
  }
  return true;
};

const checkIfDataIsOk = (guarantee: GuaranteeAdditionalData): boolean => {
  if (!guarantee.useBorrowerAddress && guarantee.address) {
    if (!guarantee.address.isAddressOk) {
      return false;
    }
  }

  const {
    constructionYear,
    acquisitionYear,
    acquisitionValue,
    livingSpace,
    evaluationType,
  } = guarantee;
  if (
    !constructionYear ||
    !acquisitionYear ||
    isUndefinedOrNull(acquisitionValue) ||
    !AcquisitionValue ||
    isUndefinedOrNull(livingSpace) ||
    !evaluationType?.libelle
  ) {
    return false;
  }
  return true;
};

export const guaranteeAdditionalInformationReducer = (
  state: GuaranteeAdditionalInformationState,
  action: GuaranteeAdditionalInformationAction,
): GuaranteeAdditionalInformationState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setUpdateGuaranteesInformation': {
      newState.guarantees = action.payload.data;
      if (action.payload.guaranteeCode === undefined) {
        return newState;
      }
      const result = newState.guarantees.map(item => {
        if (item.code === action.payload.guaranteeCode) {
          const loan = {
            ...item,
            isDataOk: checkIfDataIsOk(item),
          };
          return loan;
        }
        return item;
      });
      newState.guarantees = result;
      return newState;
    }
    case 'setUpdateNotaryInformation': {
      newState.notary = action.payload;
      newState.notary.isDataOK = checkIfNotaireDatasAreOk(newState);
      return newState;
    }
    default:
      return newState;
  }
};
