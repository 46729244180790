import axios, { AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import localforage from 'localforage';
import { ConfigValues } from 'types';
import AuthorizationService from './AuthorizationService';

// TODO: Dette technique: dans les fichiers values.yaml, regrouper les propriétés qui ont le même nom de domaine dans une seule propriété.
// Et dans ce fichier, supprimer les instances axios et garder une seule instance axios pour tous les api qui ont le même nom de domaine

const getEnvValues = (): ConfigValues | null => {
  try {
    const values = sessionStorage.getItem('envVariables');
    if (values) {
      return JSON.parse(values);
    }
    console.error(`envVariables from session storage: ${values}`);
  } catch (error) {
    console.error(
      `error getting or parsing env variables from the sessionStorage data. Reason: ${error}`,
    );
  }
  return null;
};

const forageStore = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  name: 'my-cache',
});

const referentialCache = setupCache({
  store: forageStore,
  clearOnStale: true,
  maxAge: 24 * 60 * 60 * 1000,
  exclude: {
    paths: [RegExp('^((?!referential).)*$')],
    query: false,
  },
});

const cache = setupCache({
  maxAge: 24 * 60 * 60 * 1000,
  exclude: {
    query: false,
  },
});

const solarBridgeApiAxiosInstance = axios.create();

const getCommunConfigs = async (req: AxiosRequestConfig, baseUrlKey: string) => {
  let config = { ...req };
  const envValues = getEnvValues();
  config.baseURL = envValues && envValues[baseUrlKey] ? envValues[baseUrlKey] : '';
  config.timeout = Number(envValues?.timeout) || 30000;
  config.headers = {
    'Content-type': 'application/json',
  };

  config = await AuthorizationService.addAccessTokenToHeaders(config);
  return config;
};

solarBridgeApiAxiosInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'baseUrlApim')) };
  },
  error => {
    return Promise.reject(error);
  },
);

const govRefApiInstance = axios.create({ adapter: cache.adapter });

govRefApiInstance.interceptors.request.use(
  req => {
    const config = { ...req };
    const envValues = getEnvValues();
    config.baseURL = envValues?.govRefBaseUrl || '';
    config.timeout = Number(envValues?.timeout) || 30000;
    config.headers = {
      'Content-type': 'application/json',
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const transfertDocumentApiInstance = axios.create();

transfertDocumentApiInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'transfertDocumentBaseUrl')) };
  },
  error => {
    return Promise.reject(error);
  },
);

const consultationInstance = axios.create({ adapter: referentialCache.adapter });

consultationInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'consultationUrl')) };
  },
  error => {
    return Promise.reject(error);
  },
);

const faisabiliteInstance = axios.create();

faisabiliteInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'faisabiliteUrl')) };
  },
  error => {
    return Promise.reject(error);
  },
);

const transfertOctroiInstance = axios.create();

transfertOctroiInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'transfertOctroiUrl')) };
  },
  error => {
    return Promise.reject(error);
  },
);

const fichageEmprunteursInstance = axios.create();

fichageEmprunteursInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'fichageEmprunteursUrl')) };
  },
  error => {
    return Promise.reject(error);
  },
);

const SauvegardeInstance = axios.create();

SauvegardeInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'sauvegardeUrl')) };
  },
  error => {
    return Promise.reject(error);
  },
);

const SimulationAutonomeInstance = axios.create();

SimulationAutonomeInstance.interceptors.request.use(
  async req => {
    return { ...(await getCommunConfigs(req, 'simulationAutonomeUrl')) };
  },
  error => {
    return Promise.reject(error);
  },
);

export {
  solarBridgeApiAxiosInstance,
  govRefApiInstance,
  transfertDocumentApiInstance,
  consultationInstance,
  faisabiliteInstance,
  transfertOctroiInstance,
  fichageEmprunteursInstance,
  SauvegardeInstance,
  SimulationAutonomeInstance,
};
