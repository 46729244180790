import Space from 'components/space/Space';
import { clientFilesResult } from 'types/clientFileDtos';
import { useEffect, useState } from 'react';
import { CodeLibelle } from 'types';
import { getReferential } from 'api/referentialService';
import Loader from 'components/Loader';
import NavBack from 'components/navBack/NavBack';
import { useNavigate } from 'react-router-dom';
import { StyledMainTitle } from './style';
import * as messages from './messages';
import SearchBar from './Sections/SearchBar';
import SearchResult from './Sections/SearchResult';

const SearchForm: React.FC = () => {
  const [searchResult, setSearchResult] = useState<clientFilesResult[]>();
  const [fileStatusItems, setFileStatusItems] = useState<CodeLibelle[]>([]);

  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getReferential('statutAvancement').then(result => {
      if (result && result.Success) setFileStatusItems(result.Resultat);
    });

    const listeDossierFromSessionStorage = sessionStorage.getItem('listeDossier');
    if (listeDossierFromSessionStorage) {
      setSearchResult(JSON.parse(listeDossierFromSessionStorage) as clientFilesResult[]);
      setSearchFlag(true);
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {isLoading && <Loader animationDuration={0.9} />}

      <Space marginTop="6.3rem" />

      <StyledMainTitle>{messages.SEARCH_MAIN_TITLE}</StyledMainTitle>

      <Space marginTop="4.8rem" />

      <NavBack navigate={navigate} />

      <Space marginTop="4.8rem" />

      <SearchBar
        setResult={setSearchResult}
        fileStatusItems={fileStatusItems}
        setIsLoading={setIsLoading}
        setSearchFlag={setSearchFlag}
      />

      <Space marginTop="6.4rem" />

      <SearchResult
        results={searchResult}
        fileStatusItems={fileStatusItems}
        setIsLoading={setIsLoading}
        searchFlag={searchFlag}
      />

      <Space marginTop="6.4rem" />
    </div>
  );
};

export default SearchForm;
