// ********************************
// ** GENERAL PAGE ERROR MESSAGE **
// ********************************
export const CHECK_ERROR =
  'Une ou plusieurs données sont non renseignées ou incorrectes.';
export const CHECK_ERROR_TITLE =
  "Vérifiez la saisie de l'ensemble des champs pour continuer.";

// ********************************
// ***** GLOBAL ERROR MESSAGE *****
// ********************************
export const REQUIRED_VALUE = 'Ce champ est obligatoire.';
export const REQUIRED_VALUE_NO_WRAP = 'Ce champ\nest obligatoire.';
export const INPUT_ERROR = `Veuillez vérifier votre saisie.`;
export const NO_SPECIAL_CHARACTER =
  'Ce champ ne doit pas contenir de caractères spéciaux.';
export const NO_NUMBER = 'Ce champ ne doit pas contenir de chiffres.';
export const INCORRECT_VALUE = 'Cette valeur est incorrecte.';
export const ONLY_NUMBERS = 'Cette donnée doit être un nombre entier.';
export const NON_ZERO_NUMBERS =
  'Cette donnée doit être un nombre entier strictement positif.';
export const REAL_NUMBER = 'Cette donnée doit être un nombre réel.';
export const REAL_NUMBER_POSITIVE =
  'Donnée incorrecte, cette donnée ne peut être négative.';

// DATE
export const DATE_FORMAT = `Cette date est non valide (jj/mm/aaaa).`;
export const YEAR_FORMAT = `Le format de l'année est incorrecte (aaaa).`;
export const DATE_FORMAT_MONTH = `Cette date est non valide (mm/aaaa).`;
export const DATE_FUTUR = 'Cette date ne peut être postérieure à la date du jour.';
export const YEAR_FUTUR = "Cette donnée ne peut être postérieure à l'année en cours.";
export const DATE_INVALID = `Cette date est non valide.`;
export const DATE_BEFORE_BORN =
  "Cette donnée doit être postérieure à la date de naissance de l'emprunteur ou du co-emprunteur.";
// ZIP CODE
export const ZIPCODE = 'Le Code Postal est un champ obligatoire.';
export const ZIPCODE_ONLY_NUMBERS = 'Le Code Postal est un champ numérique.';
export const ZIPCODE_NO_ZERO = 'Le Code Postal ne peut pas être 0.';
export const ZIPCODE_LENGTH = 'Doit être composé de 5 caractères.';
export const WRONG_ZIPCODE = "Le Code Postal n'existe pas.";
// ADDRESS
export const ADRESS_STREET_NAME_REQUIRED =
  'Ce champ est obligatoire avec un type de voie.';
export const ADRESS_STREET_TYPE_REQUIRED =
  'Ce champ est obligatoire avec un nom de voie.';
export const ADDRESS_REQUIRED =
  "Au moins l'un de ces élements est obligatoire: \n - 'Type de voie' et 'Nom de la voie' \n - ou 'Complément d'adresse'\n - ou 'Lieu-dit ou boite postale' .";
export const ADDRESS_CITY_REQUIRED = "La ville de l'emprunteur est obligatoire.";

// ********************************
// ******* IDENTITE CLIENT ********
// ********************************
export const DATE_TOO_YOUNG_OR_OLD = `La date de naissance doit être supérieure au 01/01/1901 et l'emprunteur doit avoir plus de 18 ans.`;

// ********************************
// ******* SITUATION FOYER ********
// ********************************
export const LOGEMENT_ERROR = 'Veuillez choisir une situation de logement.';
export const NUMBER_PERSON_HOUSE =
  "Le nombre de personnes à charge ne peut pas être inférieur au nombre d'enfants à charge plus les emprunteurs.";
export const BIRTH_CHILDREN_YEAR_BEFORE_1970 =
  "L'année de naissance doit être postérieure à 1970.";
export const BIRTH_CHILDREN_EMPTY =
  "S'il existe des enfants à charge, leurs années de naissance doivent être renseignées.";
export const BIRTH_CHILDREN_TOO_MUCH = "Le nombre maximal d'enfants à charge est de 10.";
export const MARITAL_STATUS_COEMP_NEEDED =
  'Cette situation familiale nécessite un co-emprunteur.';
export const MARITAl_STATUS_INCOHERENT = 'La situation familiale choisie est incohérente';
export const MARTIAL_STATUS_REQUIRED = 'Veuillez choisir une situation familiale.';

// ********************************
// *** SITUATION PROFESIONNELLE ***
// ********************************
export const SIREN_REQUIRED = `Le N°SIREN de l’entreprise est obligatoire.`;
export const SIREN_FORMAT = `Le N°SIREN de l’entreprise doit être un numérique de 9 chiffres.`;
export const SIREN_NO_START_ZERO = `Le N°SIREN de l’entreprise ne doit pas commencer par un 0.`;
export const APE_REQUIRED = `Le code APE de l'entreprise est obligatoire.`;
export const APE_FORMAT = `Le code APE de l’entreprise doit être composé de 4 numériques suivis d'une majuscule.`;
export const APE_VALUE_ERROR =
  "Le code APE n'est pas parmi la liste des codes APE-NAF de l'INSEE";
export const SITUATION_STARTING_DATE_TOO_YOUNG = `La date d'entrée chez l'employeur ne doit pas être antérieure à la date de naissance plus 16 ans.`;
export const SITUATION_STARTING_DATE_NO_FUTUR = `La date d'entrée doit être antérieure à la date du jour.`;

// ********************************
// ****** REVENUS ET CHARGES ******
// ********************************
export const BANK_STARTING_YEAR_MINIMAL_AGE =
  "L'ancienneté bancaire doit être supérieure à la date de naissance de l'emprunteur le plus agé, plus 15 ans.";
export const BANK_ACCOUNT_REQUIRED = "La saisie d'un compte bancaire est obligatoire.";

// ********************************
// ******* OPERATION CLIENT *******
// ********************************
export const ASSET_REQUIRED =
  "Sur un dossier Avec Garantie, la saisie d'un bien est obligatoire.";
export const MODAL_CREDIT_NOT_SELECTED = 'Veuillez choisir un type de crédit.';
export const ASSETS_MORTGAGE_VALUE_ERROR =
  'La différence entre la valeur actuelle et la valeur des hypothèques doit être positive.';
export const LOAN_REQUIRED = "La présence d'au moins un prêt est obligatoire.";
export const MODAL_CREDIT_LOAN_DURATION_UNDER_ZERO =
  'La durée restante calculée est invalide.';
export const MODAL_CREDIT_LOAN_DURATION_TOO_LONG =
  'La durée totale du prêt doit être inférieure à 30 ans (360 mois).';
export const MODAL_CREDIT_INITAL_CAPITAL_INFERIOR_BALANCE =
  'Le capital initial ne peut pas être inférieur au solde.';
export const OTHER_NEEDS_ERROR_ZERO =
  'Le montant des besoins complémentaires doit être supérieur à 0 €.';
export const OTHER_NEEDS_ERROR_SIZE =
  'Le nombre de besoins complémentaires ne peut pas être supérieur à 15.';
export const BUY_BACK_ERROR_ZERO =
  'Le montant des dettes à racheter doit être supérieur à 0 €.';
export const BUY_BACK_ERROR_SIZE =
  'Le nombre de dettes à racheter ne peut pas être supérieur à 15.';
export const PAYEMENT_INCIDENT_REQUIRED =
  "Le nombre d'incidents de paiement doit être supérieur à 0.";

export const MONTH_IMPAYES_MAX = 'Le nombre de mois max est limité à 3.';

// ********************************
// **** CONDITIONS FINANCIERES ****
// ********************************
export const INTERMEDIATION_FEE_POSITIVE_NUMBER =
  'Donnée incorrecte,\nla valeur saisie ne peut être négative.';
export const BAREME_ERROR =
  'Aucun barème ne correspond aux critères de recherche. Veuillez modifier vos critères de recherche.';

// ********************************
// **** FINALISATION DU PROJET ****
// ********************************
export const IDENTITY_NUMBER_ERROR_LENGTH_12 =
  "Pour ce type de pièce d'identité,\nVeuillez saisir 12 caractères.";
export const IDENTITY_NUMBER_ERROR_LENGTH_MAX_12 =
  "Pour ce type de pièce d'identité,\nVeuillez saisir au maximum 12 caractères.";
export const IDENTITY_NUMBER_ERROR_LENGTH_9 =
  "Pour ce type de pièce d'identité,\nVeuillez saisir 9 caractères.";
export const IDENTITY_NUMBER_ERROR_LENGTH_9_10 =
  "Pour ce type de pièce d'identité,\nVeuillez saisir 9 ou 10 caractères.";
export const PHONE_NUMBER_REQUIRED =
  "Il est nécessaire d'avoir soit le numéro de mobile ou du domicile.";
export const PHONE_NUMBER_IDENTICAL =
  'Le téléphone portable du co-emprunteur doit être différent de celui de l’emprunteur';
export const PHONE_NUMBER_HOME_ERROR = 'Numéro du domicile incorrect.';
export const PHONE_NUMBER_CELL_ERROR = 'Numéro de mobile incorrect.';
export const DEBT_TOTAL_ERROR_MESSAGE =
  'La somme des montants renseignés ne correspond pas au montant total du découvert';
export const DATE_OVER_2100 = "La date d'exigibilité doit être inférieure à 2100.";
export const DATE_IN_THE_PAST =
  "La date d'exigibilité doit être supérieure à la date du jour.";
export const ACCOUNT_NUMBER_ERROR_MESSAGE = 'Le numéro de compte est trop long.';

// ********************************
// **** TRANSMISSION DES PIECES ***
// ********************************
