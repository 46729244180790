import TextButton from 'components/buttons/textButton/TextButton';
import CustomInput from 'components/customInput/CustomInput';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import DeleteConfirmationModal from 'components/modals/ConfirmationModals/DeleteConfirmationModal';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import ToggleButton from 'components/toggleButton/ToggleButton';
import TotalInfoBubble from 'components/totalInfoBubble/TotalInfoBubble';
import { StyledInfo } from 'containers/conditionsFinancieres/style';
import CrossCircle from 'icons/CrossCircle';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import WarningIcon from 'icons/WarningIcon';
import React, { useEffect, useState } from 'react';
import {
  Operation,
  OperationAction,
  OperationState,
} from 'reducers/operationClient/types';
import { displayNumber, formatNumberByThousand } from 'utils/commun';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import {
  StyledButtonSpacer,
  StyledStandardFlexContainer,
  StyledTotalInfoBubbleData,
  StyledTotalInfoBubbleText,
} from '../style';

interface OtherNeedsFormProps {
  operationReducerDispatch: React.Dispatch<OperationAction>;
  state: OperationState;
  needsAreOk: boolean;
  checkIfNeedsAreOk: (list: any[]) => void;
  debtsAreOk: boolean;
  checkIfDebtsAreOk: (list: any[]) => void;
  otherNeedsRef: React.MutableRefObject<HTMLDivElement | null>;
  setNeedsModalVisibility: (visibility: boolean) => void;
  setDebtsModalVisibility: (visibility: boolean) => void;
  checkInputs: boolean;
}

const OtherNeedsForm: React.FC<OtherNeedsFormProps> = ({
  operationReducerDispatch,
  state,
  needsAreOk,
  checkIfNeedsAreOk,
  debtsAreOk,
  checkIfDebtsAreOk,
  otherNeedsRef,
  setNeedsModalVisibility,
  setDebtsModalVisibility,
  checkInputs,
}) => {
  const [isDebtDeleteConfirmationVisible, setIsDebtDeleteConfirmationVisible] =
    useState<boolean>(false);
  const [isNeedDeleteConfirmationVisible, setIsNeedDeleteConfirmationVisible] =
    useState<boolean>(false);

  const [focusedElement, setFocusedElement] = useState<string>();
  const [otherNeedsList, setOtherNeedsList] = useState<Operation[]>([]);
  const [buybackDebtsList, setBuybackDebtsList] = useState<Operation[]>([]);
  const [hasOtherNeeds, setHasOtherNeeds] = useState<boolean>(
    state.hasOtherNeeds || false,
  );
  const [hasBuybackDebts, setHasBuybackDebts] = useState<boolean>(
    state.hasBuybackDebt || false,
  );

  const [tempDebtList, setTempDebtList] = useState<Operation[]>([]);
  const [tempNeedList, setTempNeedList] = useState<Operation[]>([]);
  const [itemToDelete, setItemToDelete] = useState<Operation>();

  useEffect(() => {
    if (state.hasOtherNeeds && state.otherNeedsList.length === 0) {
      setHasOtherNeeds(false);
      operationReducerDispatch({
        type: 'setHasOtherNeeds',
        payload: false,
      });
    }
    if (state.hasBuybackDebt && state.buybackDebtsList.length === 0) {
      setHasBuybackDebts(false);
      operationReducerDispatch({
        type: 'setHasBuybackDebts',
        payload: false,
      });
    }
  }, []);

  const changeNeedValue = (currentNeed: Operation, amount: number) => {
    if (!Number.isNaN(amount)) {
      const newState = { ...state };

      operationReducerDispatch({
        type: 'setNewNeedsList',
        payload: newState.otherNeedsList.map(need => {
          if (need.uid === currentNeed.uid) {
            return { ...need, amount };
          }
          return need;
        }),
      });
    }
  };

  const isElementFocused = (elementId: string): boolean => {
    return focusedElement === elementId;
  };

  const deleteNeed = (itemUid: string) => {
    setOtherNeedsList(
      otherNeedsList.filter(need => {
        return need.uid !== itemUid;
      }),
    );

    const filteredList = state.otherNeedsList.filter(need => {
      return need.uid !== itemUid;
    });

    operationReducerDispatch({
      type: 'setNewNeedsList',
      payload: filteredList,
    });

    checkIfNeedsAreOk(filteredList);
  };

  const renderOtherNeedsErrors = (): React.ReactFragment => {
    return (
      <>
        {(() => {
          if (
            !(
              state.otherNeedsList.length === 0 ||
              !state.otherNeedsList.find(otherNeed => otherNeed.amount <= 0)
            ) &&
            checkInputs
          ) {
            return (
              <>
                <ErrorMessageArea message={messagesCheckInput.OTHER_NEEDS_ERROR_ZERO} />
                <Space marginTop="3.2rem" />
              </>
            );
          }
          if (state.otherNeedsList.length >= 15) {
            return (
              <>
                <ErrorMessageArea message={messagesCheckInput.OTHER_NEEDS_ERROR_SIZE} />
                <Space marginTop="3.2rem" />
              </>
            );
          }
          return <></>;
        })()}
      </>
    );
  };

  const renderOtherNeedsInputs = (): React.ReactFragment => {
    return state.otherNeedsList.map(item => {
      return (
        <React.Fragment key={item.uid}>
          <CustomInput
            name={item.uid}
            label={item.libelle}
            type="price"
            value={formatNumberByThousand(item.amount.toString())}
            onFocus={() => {
              setFocusedElement(item.uid);
            }}
            onChange={newValue => {
              changeNeedValue(item, Number(newValue.replace(/ /g, '').slice(0, 8)));
            }}
            onBlur={() => {
              setFocusedElement(undefined);
            }}
            isFocused={isElementFocused(item.uid)}
            after="€"
            inputWidth="46.4rem">
            <div
              role="button"
              style={{ display: 'flex', cursor: 'pointer' }}
              onKeyDown={event => {
                if (
                  event.key === 'Enter' ||
                  event.key === 'Space' ||
                  event.code === 'NumpadEnter'
                ) {
                  setItemToDelete(item);
                  setIsNeedDeleteConfirmationVisible(true);
                }
              }}
              onClick={() => {
                setItemToDelete(item);
                setIsNeedDeleteConfirmationVisible(true);
              }}
              tabIndex={0}>
              <CrossCircle />
              <span
                style={{
                  marginLeft: '0.5rem',
                  fontSize: '1.4rem',
                  fontWeight: 'bold',
                  paddingTop: '0.2rem',
                }}>
                Supprimer
              </span>
            </div>
          </CustomInput>
          <Space marginBottom="24px" />
        </React.Fragment>
      );
    });
  };

  const renderOtherNeeds = (): React.ReactFragment => {
    return (
      <>
        <Space marginBottom="16px" />
        {renderOtherNeedsErrors()}
        {renderOtherNeedsInputs()}
        <TextButton
          label="Ajouter un nouveau besoin"
          width="300px"
          onClick={() => {
            setNeedsModalVisibility(true);
          }}>
          <StyledButtonSpacer />
          <PlusCircleIcon />
        </TextButton>
        <TotalInfoBubble
          total={state.totalOtherNeedsAmount ? state.totalOtherNeedsAmount : 0}>
          <StyledStandardFlexContainer>
            <StyledTotalInfoBubbleText>Total du besoin : </StyledTotalInfoBubbleText>
            <StyledTotalInfoBubbleData>
              {state.totalOtherNeedsAmount
                ? displayNumber(state.totalOtherNeedsAmount)
                : 0}{' '}
              €
            </StyledTotalInfoBubbleData>
          </StyledStandardFlexContainer>
        </TotalInfoBubble>
      </>
    );
  };

  const changeDebtValue = (currentDebt: Operation, amount: number) => {
    if (!Number.isNaN(amount)) {
      const newState = { ...state };
      const newDebt = { ...currentDebt };

      newDebt.amount = amount;

      const needIndex = newState.buybackDebtsList.findIndex(debt => {
        return debt.uid === newDebt.uid;
      });
      if (needIndex !== -1) {
        newState.buybackDebtsList[needIndex] = newDebt;
      } else {
        newState.buybackDebtsList.push(newDebt);
      }

      operationReducerDispatch({
        type: 'setBuybackDebtsList',
        payload: newState.buybackDebtsList,
      });
    }
  };

  const deleteDebt = (itemUid: string) => {
    sessionStorage.removeItem('debtAdditionalInformation');
    setBuybackDebtsList(
      buybackDebtsList.filter(debt => {
        return debt.uid !== itemUid;
      }),
    );

    const filteredList = state.buybackDebtsList.filter(need => {
      return need.uid !== itemUid;
    });

    operationReducerDispatch({
      type: 'setBuybackDebtsList',
      payload: filteredList,
    });

    checkIfDebtsAreOk(filteredList);
  };

  useEffect(() => {
    if (!state.hasInsurance) {
      let needsList = [...state.otherNeedsList];
      let debtsList = [...state.buybackDebtsList];

      needsList = needsList.filter(need => {
        return need.type !== 'I';
      });

      debtsList = debtsList.filter(debt => {
        return debt.type !== 'I';
      });

      setOtherNeedsList(needsList);
      setBuybackDebtsList(debtsList);

      operationReducerDispatch({
        type: 'setNewNeedsList',
        payload: needsList,
      });

      operationReducerDispatch({
        type: 'setBuybackDebtsList',
        payload: debtsList,
      });
    }
  }, [state.hasInsurance]);

  const renderBuybackDebtsErrors = (): React.ReactFragment => {
    return (
      <>
        {(() => {
          if (
            !(
              state.buybackDebtsList.length === 0 ||
              !state.buybackDebtsList.find(debt => debt.amount <= 0)
            ) &&
            checkInputs
          ) {
            return (
              <>
                <ErrorMessageArea message={messagesCheckInput.BUY_BACK_ERROR_ZERO} />
                <Space marginTop="3.2rem" />
              </>
            );
          }
          if (state.buybackDebtsList.length >= 15) {
            return (
              <>
                <ErrorMessageArea message={messagesCheckInput.BUY_BACK_ERROR_SIZE} />
                <Space marginTop="3.2rem" />
              </>
            );
          }
          return <></>;
        })()}
      </>
    );
  };

  const renderBuybackDebtsInputs = (): React.ReactFragment => {
    return state.buybackDebtsList.map(item => {
      return (
        <React.Fragment key={item.uid}>
          <CustomInput
            name={item.libelle}
            label={item.libelle}
            type="price"
            value={formatNumberByThousand(item.amount.toString())}
            onFocus={() => {
              setFocusedElement(item.uid);
            }}
            onChange={newValue => {
              changeDebtValue(item, Number(newValue.replace(/ /g, '').slice(0, 8)));
            }}
            onBlur={() => {
              setFocusedElement(undefined);
            }}
            isFocused={isElementFocused(item.uid)}
            after="€"
            inputWidth="46.4rem">
            <div
              role="button"
              style={{ display: 'flex', cursor: 'pointer' }}
              onKeyDown={event => {
                if (
                  event.key === 'Enter' ||
                  event.key === 'Space' ||
                  event.code === 'NumpadEnter'
                ) {
                  setItemToDelete(item);
                  setIsDebtDeleteConfirmationVisible(true);
                }
              }}
              onClick={() => {
                setItemToDelete(item);
                setIsDebtDeleteConfirmationVisible(true);
              }}
              tabIndex={0}>
              <CrossCircle />
              <span
                style={{
                  marginLeft: '0.5rem',
                  fontSize: '1.4rem',
                  fontWeight: 'bold',
                  paddingTop: '0.2rem',
                }}>
                Supprimer
              </span>
            </div>
          </CustomInput>
          <Space marginBottom="24px" />
        </React.Fragment>
      );
    });
  };

  const renderBuybackDebts = (): React.ReactFragment => {
    return (
      <>
        <Space marginBottom="16px" />
        {renderBuybackDebtsErrors()}
        {renderBuybackDebtsInputs()}

        <TextButton
          label="Ajouter une nouvelle dette"
          width="300px"
          onClick={() => {
            setDebtsModalVisibility(true);
          }}>
          <StyledButtonSpacer />
          <PlusCircleIcon />
        </TextButton>

        <TotalInfoBubble total={state.totalDebtValue ? state.totalDebtValue : 0}>
          <StyledStandardFlexContainer>
            <StyledTotalInfoBubbleText>Total des dettes : </StyledTotalInfoBubbleText>
            <StyledTotalInfoBubbleData>
              {state.totalDebtValue ? displayNumber(state.totalDebtValue) : 0} €
            </StyledTotalInfoBubbleData>
          </StyledStandardFlexContainer>
        </TotalInfoBubble>
      </>
    );
  };

  return (
    <>
      <div ref={otherNeedsRef}>
        <StepTitles title="Autres besoins" />

        <ToggleButton
          id="other-needs"
          isOn={hasOtherNeeds}
          handleToggle={() => {
            if (!hasOtherNeeds) {
              if (tempNeedList.length > 0) {
                operationReducerDispatch({
                  type: 'setNewNeedsList',
                  payload: tempNeedList,
                });
              }
            } else {
              setTempNeedList(state.otherNeedsList);
              operationReducerDispatch({
                type: 'setNewNeedsList',
                payload: [],
              });
            }

            operationReducerDispatch({
              type: 'setHasOtherNeeds',
              payload: !hasOtherNeeds,
            });
            setHasOtherNeeds(prevState => !prevState);
          }}>
          <span
            style={{
              marginRight: '1rem',
              marginTop: '0.3rem',
              height: '3.2rem',
              fontWeight: 'bold',
              fontSize: '1.6rem',
            }}>
            Des besoins complémentaires
          </span>
        </ToggleButton>

        {!needsAreOk && state.hasOtherNeeds && state.hasInsurance && (
          <StyledInfo
            style={{ padding: '0 1.8rem', marginTop: '1.6rem', width: 'fit-content' }}>
            <WarningIcon />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}>
                {`Dans un projet avec garantie vous ne pouvez avoir qu'un seul besoin de
                chaque type.`}
              </p>
            </div>
          </StyledInfo>
        )}

        {hasOtherNeeds ? renderOtherNeeds() : <></>}
      </div>

      <Space marginBottom="56px" />

      <div>
        <ToggleButton
          id="buyback-debts"
          isOn={hasBuybackDebts}
          handleToggle={() => {
            if (!hasBuybackDebts) {
              if (tempDebtList.length > 0) {
                operationReducerDispatch({
                  type: 'setBuybackDebtsList',
                  payload: tempDebtList,
                });
              }
            } else {
              setTempDebtList(state.buybackDebtsList);
              operationReducerDispatch({
                type: 'setBuybackDebtsList',
                payload: [],
              });
            }

            operationReducerDispatch({
              type: 'setHasBuybackDebts',
              payload: !hasBuybackDebts,
            });
            setHasBuybackDebts(prevState => !prevState);
          }}>
          <span
            style={{
              marginRight: '1rem',
              display: 'block',
              marginTop: '0.3rem',
              height: '3.2rem',
              fontWeight: 'bold',
              fontSize: '1.6rem',
            }}>
            Des dettes à racheter
          </span>
        </ToggleButton>

        {!debtsAreOk && state.hasBuybackDebt && state.hasInsurance && (
          <StyledInfo
            style={{ padding: '0 1.8rem', marginTop: '1.6rem', width: 'fit-content' }}>
            <WarningIcon />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}>
                {`Dans un projet avec garantie vous ne pouvez avoir qu'une seule dette de
                chaque type.`}
              </p>
            </div>
          </StyledInfo>
        )}

        {hasBuybackDebts ? renderBuybackDebts() : <></>}
      </div>

      <DeleteConfirmationModal
        isVisible={isDebtDeleteConfirmationVisible || isNeedDeleteConfirmationVisible}
        accept={(item: Operation) => {
          setIsDebtDeleteConfirmationVisible(false);
          setIsNeedDeleteConfirmationVisible(false);
          if (isDebtDeleteConfirmationVisible) deleteDebt(item.uid);
          else deleteNeed(item.uid);
        }}
        close={() => {
          setIsDebtDeleteConfirmationVisible(false);
          setIsNeedDeleteConfirmationVisible(false);
        }}
        item={itemToDelete}
      />
    </>
  );
};

export default OtherNeedsForm;
