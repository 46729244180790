import Space from 'components/space/Space';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import { CodeLibelle } from 'types';
import { useState } from 'react';
import InformationArea from 'components/informationArea/InformationArea';
import { hasCoBorrower } from 'containers/communs/utils';
import WarningIcon from 'icons/WarningIcon';
import {
  InfoText,
  ResultCard,
  ResultCardEntry,
  ResultCardSubTitle,
  ResultCardText,
  ResultCartContainer,
  ResultCartTitle,
  StyledDottedLine,
  StyledFullBar,
} from '../style';
import * as messages from '../messages';

export interface InsuranceFormDataView {
  type: string;
  isBorrower: boolean;
  insuranceData: CodeLibelle[];
}

interface InsuranceFormProps {
  ComplementaryInsuranceDataView: CodeLibelle[];
  borrowerInsurance: InsuranceFormDataView;
  coBorrowerInsurance: InsuranceFormDataView;
}

const InsuranceForm: React.FC<InsuranceFormProps> = ({
  ComplementaryInsuranceDataView,
  borrowerInsurance,
  coBorrowerInsurance,
}) => {
  const [borrowerHasNoInsurance, setBorrowerHasNoInsurance] = useState<boolean>(false);
  const [coBorrowerHasNoInsurance, setCoBorrowerHasNoInsurance] =
    useState<boolean>(false);

  const firstInsuranceTypeList = [] as InsuranceFormDataView[];
  const secondInsuranceTypeList = [] as InsuranceFormDataView[];

  const renderSpacing = (isNotLastItem: boolean) => {
    return <Space marginBottom={isNotLastItem ? '1.6rem' : '0'} />;
  };

  const renderGroupInsurance = (items: CodeLibelle[]) => {
    return items.map((item, index) => {
      return (
        <div key={item.libelle}>
          <ResultCardEntry>
            <ResultCardText>{item.libelle}</ResultCardText>
            {item.libelle !== 'Prime mensuelle' ? <StyledDottedLine /> : <></>}
            <ResultCardText>{item.code}</ResultCardText>
          </ResultCardEntry>

          {renderSpacing(index !== items.length - 1)}
        </div>
      );
    });
  };

  const renderIndividualInsurance = (items: CodeLibelle[]) => {
    return (
      <>
        {items.map((item, index) => {
          return (
            <div key={item.libelle}>
              <ResultCardEntry>
                <ResultCardText>{item.libelle}</ResultCardText>
                <StyledDottedLine />
                <ResultCardText>{item.code}</ResultCardText>
              </ResultCardEntry>

              {renderSpacing(index !== items.length - 1)}
            </div>
          );
        })}

        <Space marginBottom="2.4rem" />

        <ResultCardText>{messages.INDIVIDUAL_INSURANCE_FOOTER}</ResultCardText>
      </>
    );
  };

  const renderComplementaryInsuranceData = (complementaryData: CodeLibelle[]) => {
    return (
      <>
        <Space marginBottom="1.55rem" />
        <StyledFullBar style={{ marginRight: 0 }} />
        <Space marginBottom="1.55rem" />

        {complementaryData.map((item, index) => {
          return (
            <div key={item.libelle}>
              <ResultCardEntry>
                <ResultCardText>{item.libelle}</ResultCardText>
                <StyledDottedLine />
                <ResultCardText>{item.code}</ResultCardText>
              </ResultCardEntry>

              {renderSpacing(index !== complementaryData.length - 1)}
            </div>
          );
        })}
      </>
    );
  };

  const renderInsuranceCard = (insurances: InsuranceFormDataView[]) => {
    return (
      <>
        <ResultCard>
          <ResultCartTitle>
            {insurances?.at(0)?.type === 'G'
              ? messages.GROUP_INSURANCE_CARD_TITLE
              : messages.INDIVIDUAL_INSURANCE_CARD_TITLE}
          </ResultCartTitle>

          {insurances.map(insurance => {
            return (
              <div key={insurance.insuranceData.at(0)?.code}>
                <Space marginBottom="2.4rem" />

                {!insurance.isBorrower ? (
                  <CoBorrowerTitle width="47.6rem" />
                ) : (
                  <ResultCardSubTitle>
                    {messages.INSURANCE_BORROWER_SUBTITTLE}
                  </ResultCardSubTitle>
                )}

                <Space marginBottom="1.6rem" />

                {insurance.type === 'G'
                  ? renderGroupInsurance(insurance.insuranceData)
                  : renderIndividualInsurance(insurance.insuranceData)}
              </div>
            );
          })}

          {insurances?.at(0)?.type === 'G' ? (
            renderComplementaryInsuranceData(ComplementaryInsuranceDataView)
          ) : (
            <></>
          )}

          <ResultCartContainer />
        </ResultCard>
      </>
    );
  };

  const renderNoInsuranceInfoText = () => {
    return (
      <>
        <InfoText>{messages.NO_INSURANCE_INFO_TEXT_1}</InfoText>

        <Space marginBottom="1.6rem" />

        <InfoText>{messages.NO_INSURANCE_INFO_TEXT_2_1}</InfoText>
        <InfoText style={{ fontWeight: 'bold' }}>
          {messages.NO_INSURANCE_INFO_TEXT_2_2}
        </InfoText>
        <InfoText>{messages.NO_INSURANCE_INFO_TEXT_2_3}</InfoText>

        <Space marginBottom="1.6rem" />

        <InfoText>{messages.NO_INSURANCE_INFO_TEXT_3_1}</InfoText>
        <InfoText style={{ fontWeight: 'bold' }}>
          {messages.NO_INSURANCE_INFO_TEXT_3_2}
        </InfoText>
        <InfoText>{messages.NO_INSURANCE_INFO_TEXT_3_3}</InfoText>
      </>
    );
  };

  const renderEmptyCard = () => {
    return (
      <>
        <ResultCard>
          <ResultCartTitle>{messages.EMPTY_INSURANCE_CARD_TITLE}</ResultCartTitle>

          {borrowerHasNoInsurance ? (
            <>
              <Space marginBottom="2.4rem" />
              <ResultCardSubTitle>
                {messages.INSURANCE_BORROWER_SUBTITTLE}
              </ResultCardSubTitle>

              <Space marginBottom="1.6rem" />

              <InformationArea
                icon={<WarningIcon color="#2275D7" />}
                backgroundColor="blue"
                height="20rem">
                {renderNoInsuranceInfoText()}
              </InformationArea>
            </>
          ) : (
            <></>
          )}

          {coBorrowerHasNoInsurance ? (
            <>
              <Space marginBottom="2.4rem" />
              <CoBorrowerTitle width="47.6rem" />

              <Space marginBottom="1.6rem" />

              <InformationArea
                icon={<WarningIcon color="#2275D7" />}
                backgroundColor="blue">
                {renderNoInsuranceInfoText()}
              </InformationArea>
            </>
          ) : (
            <></>
          )}
        </ResultCard>
      </>
    );
  };

  const renderInsuranceCards = () => {
    return (
      <>
        {firstInsuranceTypeList.length > 0
          ? renderInsuranceCard(firstInsuranceTypeList)
          : renderEmptyCard()}

        {hasCoBorrower() ? <Space marginBottom="5.6rem" /> : <></>}

        {secondInsuranceTypeList.length > 0 ? (
          renderInsuranceCard(secondInsuranceTypeList)
        ) : (
          <></>
        )}

        {!(firstInsuranceTypeList.length > 0 && secondInsuranceTypeList.length > 0) ? (
          <Space marginBottom="5.6rem" />
        ) : (
          <></>
        )}

        {hasCoBorrower() && coBorrowerHasNoInsurance && !borrowerHasNoInsurance ? (
          renderEmptyCard()
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderInsuranceForm = () => {
    const tmpBorrowerInsurance = { ...borrowerInsurance };
    tmpBorrowerInsurance.isBorrower = true;

    if (tmpBorrowerInsurance.type === 'G' || tmpBorrowerInsurance.type === 'D') {
      firstInsuranceTypeList.push(tmpBorrowerInsurance);
    } else if (!borrowerHasNoInsurance) {
      setBorrowerHasNoInsurance(true);
    }

    if (hasCoBorrower()) {
      const tmpCoBorrowerInsurance = { ...coBorrowerInsurance };
      tmpCoBorrowerInsurance.isBorrower = false;

      if (
        tmpCoBorrowerInsurance.type === tmpBorrowerInsurance.type &&
        tmpCoBorrowerInsurance.type !== 'S'
      ) {
        firstInsuranceTypeList.push(tmpCoBorrowerInsurance);
      } else if (
        tmpCoBorrowerInsurance.type !== tmpBorrowerInsurance.type &&
        tmpCoBorrowerInsurance.type !== 'S'
      ) {
        secondInsuranceTypeList.push(tmpCoBorrowerInsurance);
      } else if (!coBorrowerHasNoInsurance) {
        setCoBorrowerHasNoInsurance(true);
      }
    }

    return <>{renderInsuranceCards()}</>;
  };

  return <>{renderInsuranceForm()}</>;
};

export default InsuranceForm;
