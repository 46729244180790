import styled from 'styled-components';

export const StyledModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  overflow-y: auto;
`;

export const StyledModalContent = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledModalContentBody = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.chartColors.neutral0};
  box-shadow: 0 0.2rem 1rem 0rem rgba(0, 0, 0, 0.15);
  border-radius: 0.8rem;
  margin-bottom: 5rem;
  padding-bottom: 1.1rem;
  margin-top: 5rem;
  padding-top: 0.01rem;
`;

export const StyledBlackTitle = styled.span`
  margin: 0;
  color: #292c2e;
  font-family: Ubuntu !important;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`;

export const PlainModalText = styled.span`
  color: #292c2e;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
`;

export const BoldPlainText = styled.span`
  margin-block-start: 0;
  margin-block-end: 0;
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
`;

export const StyledModalContentContainer = styled.div`
  margin-left: 7.2rem;
  margin-right: 7.2rem;
  margin-top: 6rem;
`;

export const StyledGeneralModalContentContainer = styled.div`
  margin-left: 7.2rem;
  margin-right: 7.2rem;
  margin-top: 4.4rem;
`;

export const StyledCloseModalContainer = styled.div`
  user-select: none;
  align-self: flex-end;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
  height: 32px;
  width: 32px;
  margin-bottom: 16px;
`;
