import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import Space from 'components/space/Space';
import Tooltip from 'components/tooltip/Tooltip';
import { StyledTitle } from 'containers/communs/style';
import WarningIcon from 'icons/WarningIcon';
import { useState } from 'react';
import { IbanAction, IbanState } from 'reducers/iban/type';
import { StyledInputLabel } from '../style';
import Bic from './Bic';
import IbanBlock from './IbanBlock';
import * as messages from '../messages';

export type IbanBlockInfo = {
  name: string;
  maxLenght: number;
  regex: string;
};

interface IbanProps {
  titleInside?: boolean;
  state: IbanState;
  dispatch: React.Dispatch<IbanAction>;
  stateIsCorrect?: boolean;
}

const Iban: React.FC<IbanProps> = ({ titleInside, state, dispatch, stateIsCorrect }) => {
  const [cursorPosition, setCursorPosition] = useState<number | undefined>();
  const twoCharsTwoDigitsRegex = `[A-Za-z]{2}[0-9]{2}`;
  const fourCharsOrDigitsRegex = `[A-Za-z0-9]`;
  const twoCharsOrDigitsRegex = '^\\d{2}$';
  const nbIbanParts: IbanBlockInfo[] = [
    { name: 'ibanPart1', maxLenght: 4, regex: twoCharsTwoDigitsRegex },
    { name: 'ibanPart2', maxLenght: 4, regex: fourCharsOrDigitsRegex },
    { name: 'ibanPart3', maxLenght: 4, regex: fourCharsOrDigitsRegex },
    { name: 'ibanPart4', maxLenght: 4, regex: fourCharsOrDigitsRegex },
    { name: 'ibanPart5', maxLenght: 4, regex: fourCharsOrDigitsRegex },
    { name: 'ibanPart6', maxLenght: 4, regex: fourCharsOrDigitsRegex },
    { name: 'ibanPart7', maxLenght: 4, regex: fourCharsOrDigitsRegex },
    { name: 'ibanPart8', maxLenght: 4, regex: fourCharsOrDigitsRegex },
    { name: 'ibanPart9', maxLenght: 2, regex: twoCharsOrDigitsRegex },
  ];

  const render = () => {
    return nbIbanParts.map((item, index) => {
      return (
        <IbanBlock
          cursorCurrentPosition={cursorPosition}
          updateCursorPosition={position => {
            setCursorPosition(position);
          }}
          blockIndex={index}
          key={item.name}
          ibanInfo={item}
          state={state}
          dispatch={dispatch}
        />
      );
    });
  };
  return (
    <>
      {!titleInside ? (
        <>
          <StyledTitle>
            Informations du Relevé d’Identité Bancaire de prélèvement (RIB)
            <span
              className="lightText"
              style={{ paddingLeft: '0.5rem', fontWeight: 'initial' }}>
              (Facultatif)
            </span>
          </StyledTitle>

          <Space marginBottom="2.4rem" />
        </>
      ) : null}
      {stateIsCorrect === false && <ErrorMessageArea message={messages.INCORRECT_DATA} />}
      <div
        style={{
          border: `0.1rem solid ${stateIsCorrect === false ? '#C8102E' : '#B1B5B9'}`,
          borderRadius: '0.8rem',
          padding: '2.4rem',
        }}>
        {titleInside ? (
          <StyledTitle>
            Informations du Relevé d’Identité Bancaire de prélèvement (RIB)
          </StyledTitle>
        ) : null}

        <Space marginBottom="2.4rem" />
        <div style={{ display: 'flex', gap: '1rem' }}>
          <StyledInputLabel>IBAN</StyledInputLabel>
          <Tooltip
            topPosition={-3}
            leftPosition={2}
            icon={<WarningIcon color="#2275D7" />}>
            Important pour compléter le mandat SEPA
          </Tooltip>
        </div>
        <Space marginTop=".8rem" />
        <div style={{ display: 'flex', gap: '0.8rem' }}>{render()}</div>
        <Space marginTop="2.4rem" />
        <Bic state={state} dispatch={dispatch} />
      </div>
    </>
  );
};

Iban.defaultProps = {
  titleInside: false,
};

export default Iban;
