import Space from 'components/space/Space';
import React from 'react';
import { StyledCardColorAccent, StyledCardContainer, StyledCardMainBody } from './style';

interface ExpandableCardProps {
  children: React.ReactNode;
  badge?: React.ReactNode;
  accentColor?: string;
}

const ExpandableCardTer: React.FC<ExpandableCardProps> = ({
  children,
  badge,
  accentColor,
}) => {
  return (
    <>
      <StyledCardContainer>
        {accentColor && <StyledCardColorAccent color={accentColor} />}
        <StyledCardMainBody>
          <Space marginBottom="1.6rem" />
          {badge ? <>{badge}</> : null}
          {children}
        </StyledCardMainBody>
      </StyledCardContainer>
    </>
  );
};

ExpandableCardTer.defaultProps = {
  accentColor: 'FFFFFF',
  badge: null,
};

export default ExpandableCardTer;
