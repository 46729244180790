import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import { useEffect, useState } from 'react';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import checkPreviousInputValidation from 'utils/commun';
import * as messages from '../messages';

interface EmployerNameInputProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  person: 'borrower' | 'coBorrower';
  isActiveProfession: boolean;
  checkInputs: boolean;
}

const EmployerNameInput: React.FC<EmployerNameInputProps> = ({
  state,
  dispatch,
  person,
  isActiveProfession,
  checkInputs,
}) => {
  const [isEmployerInputFocused, setIsEmployerInputFocused] = useState<boolean>(false);
  const [isEmployerInputValid, setIsEmployerInputValid] = useState<boolean | undefined>(
    checkPreviousInputValidation(
      'finalisationProjet',
      person === 'borrower' ? state.borrower.employerName : state.coBorrower.employerName,
    ),
  );
  const [employerInputError, setEmployerInputError] = useState<string>('');

  useEffect(() => {
    dispatch({
      type: 'setActiveProfession',
      payload: { person, value: isActiveProfession },
    });
  }, []);

  const handleEmployerNameChange = (value: string) => {
    if (!value) {
      dispatch({
        type: 'setEmployerName',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setEmployerName',
        payload: { person, value },
      });
    }
  };
  const handleEmployerNameBlur = () => {
    setIsEmployerInputFocused(false);
    const empName =
      person === 'borrower' ? state.borrower.employerName : state.coBorrower.employerName;
    if (empName) {
      setIsEmployerInputValid(true);
      setEmployerInputError('');
    } else {
      setIsEmployerInputValid(false);
      setEmployerInputError('Nom employeur incorrect.');
    }
  };

  useEffect(() => {
    if (checkInputs) handleEmployerNameBlur();
  }, [checkInputs]);

  return (
    <>
      <StyledTitle>
        {person === 'borrower'
          ? messages.EMPLOYER_TITLE
          : messages.EMPLOYER_TITLE_FOR_COBORROWER}
      </StyledTitle>
      <Space marginTop="1.6rem" />
      <CustomInput
        name={`${person}-employer`}
        label="Employeur"
        value={
          person === 'borrower'
            ? state.borrower.employerName
            : state.coBorrower.employerName
        }
        onChange={newValue => {
          handleEmployerNameChange(newValue.slice(0, 25));
          setIsEmployerInputFocused(true);
        }}
        isValid={isEmployerInputValid}
        isFocused={isEmployerInputFocused}
        onBlur={handleEmployerNameBlur}
        onFocus={() => {
          setIsEmployerInputFocused(true);
        }}
        inputWidth="64.8rem"
        placeholder={messages.EMPLOYER_PLACEHOLDER}
        error={employerInputError}
      />
    </>
  );
};

export default EmployerNameInput;
