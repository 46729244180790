import CityInput from 'components/cityInput/CityInput';
import BaseButton from 'components/designSystem/BaseButton';
import { BaseInput } from 'components/designSystem/BaseInput';
import { ComboInput } from 'components/designSystem/ComboInput';
import { DateInput } from 'components/designSystem/DateInput';
import { SelectedButton } from 'components/designSystem/SelectButton';
import styled from 'styled-components';

export const StyledSaveLaterText = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  font-size: 1.2rem;
`;

export const StyledSaveLaterWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledComboInput = styled.div`
  margin-bottom: 2rem;
  width: 40rem;
`;

export const StyledBankComboInput = styled.div`
  margin-bottom: 2.4rem;
  width: 40rem;
`;

export const StyledModalContentWrapper = styled.div`
  margin-left: 9.6rem;
  margin-right: 9.6rem;
  margin-top: 4.8rem;
  margin-bottom: 4.4rem;
  width: 72rem;
`;

export const StyledModalMainTitle = styled.p`
  height: 3.2rem;
  color: #292c2e;
  font-family: Ubuntu;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 3.2rem;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 4rem;
`;

export const StyledRadioButtonTitle = styled.div`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBaseButton = styled(BaseButton)``;

export const StyledCardLabel = styled.p`
  color: #6f757c;
  font-family: 'Open Sans';
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2rem;
  margin: 0;
`;

export const StyledCardData = styled.p`
  height: 2.4rem;
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledCardContentTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const StyledButtonSpacer = styled.div`
  margin-left: 2.4rem;
`;

export const StyledStandardFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTotalInfoBubbleText = styled.p`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  text-align: right;
  margin: 0;
  margin-right: 0.8rem;
`;

export const StyledTotalInfoBubbleData = styled.p`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.6rem;
  margin: 0;
`;

export const StyledFormWrapper = styled.div`
  width: 71.2rem;
`;

export const StyledModalAddLoanButton = styled(BaseButton)`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  font-size: 1.6rem;
  width: 25rem;
  margin-right: 4rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;

  ${({ disabled }) =>
    disabled &&
    `
      background: #B1B5B9;
      cursor: not-allowed;
    `};
`;

export const StyledSelectionModalAddLoanButton = styled(BaseButton)`
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1.6rem;

  ${({ disabled }) =>
    disabled &&
    `
    background: #B1B5B9;
    cursor: not-allowed;
  `};
`;

export const InformationreaTitle = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const InformationreaText = styled.p`
  margin: 0;
  font-size: 1.4rem;
`;

export const StyledModalRowContainer = styled.div`
  display: flex;
`;

export const StyledRankText = styled.p`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-right: 1.6rem;
  margin-bottom: 0;
`;

export const StyledAdressInput = styled(BaseInput)`
  max-width: 46.2rem;
`;

export const StyledZipCodeInput = styled(BaseInput)`
  width: 13rem;
  margin-right: 1.6rem;
`;

export const StyledCityInput = styled(CityInput)`
  flex: auto;
  max-width: 32.2rem;
`;

export const ShortComboInput = styled(ComboInput)`
  width: 22.5rem;
`;

export const StyledExtraMarginSelectedButton = styled(SelectedButton)`
  &:nth-child(1) {
    margin-left: 0.8rem;
  }
  &:nth-child(3) {
    margin-right: 0;
  }
`;

export const StyledAfterLabel = styled.p`
  margin-top: 1.6rem;
  margin-bottom: 0;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
`;

export const StyledDateContainer = styled.div`
  display: flex;
`;

export const StyledDateInput = styled(DateInput)``;

export const StyledRadioButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledRadioButtonWrapper = styled.div`
  display: flex;
  gap: 1.4rem;
`;

export const StyledInfoText = styled.p`
  margin-top: ${({ theme }) => theme.layout.spacing.spacing_xs};
  color: #6f757c;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const StyledNumericLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const StyledNumericInputWrapper = styled.div`
  width: 6.4rem;
  margin-right: 9.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
