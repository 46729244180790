import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import Space from 'components/space/Space';
import Iban from 'containers/finalisationProjet/blockIban/BlockIban';
import { StyledInputCategoryTitle } from 'containers/transmissionPJ/style';
import { Dispatch, useEffect, useState } from 'react';
import { EmissionPackAction, EmissionPackState } from 'reducers/emissionPack/types';
import { IbanAction, IbanState } from 'reducers/iban/type';
import * as messages from '../../../messages';

interface borrowersInfosProps {
  displayState: EmissionPackState;
  displayDispatch: Dispatch<EmissionPackAction>;
  state: EmissionPackState;
  dispatch: Dispatch<EmissionPackAction>;
  ibanState: IbanState;
  ibanDispatch: React.Dispatch<IbanAction>;
}

const Rib: React.FC<borrowersInfosProps> = ({
  displayState,
  displayDispatch,
  dispatch,
  ibanState,
  ibanDispatch,
}) => {
  const [hasIban, setHasIban] = useState<boolean>();

  useEffect(() => {
    setHasIban(!sessionStorage.getItem('iban'));
  }, []);

  useEffect(() => {
    displayDispatch({
      type: 'setIban',
      payload: ibanState,
    });

    if (ibanState.isDataOk) {
      dispatch({
        type: 'setIban',
        payload: ibanState,
      });
    } else {
      dispatch({
        type: 'setIban',
        payload: {} as IbanState,
      });
    }
  }, [ibanState]);

  const handleCollectionDayClick = (value: 4 | 10 | 15) => {
    displayDispatch({
      type: 'setPaymentDay',
      payload: value,
    });
    dispatch({
      type: 'setPaymentDay',
      payload: value,
    });
  };

  return (
    <>
      {hasIban && (
        <>
          <StyledInputCategoryTitle>{messages.RIB_TITLE}</StyledInputCategoryTitle>
          <Space marginTop="2.4rem" />
          <div style={{ maxWidth: '71.2rem' }}>
            <Space marginTop="3.4rem" />

            <span>{messages.RIB_SUBTITLE}</span>

            <Space marginTop="1.6rem" />

            <Iban titleInside state={ibanState} dispatch={ibanDispatch} />
          </div>
        </>
      )}

      <Space marginTop="2.4rem" />

      <span>{messages.COLLECTION_DAY_SUBTITLE}</span>

      <Space marginTop="1rem" />

      <div style={{ display: 'flex', gap: '3.7rem' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <BaseRadioButton
            id="pack-contractuel"
            checked={displayState.paymentDay === 4 ? true : undefined}
            onClick={() => {
              handleCollectionDayClick(4);
            }}
          />
          <span>4</span>
        </div>

        <div style={{ display: 'flex', gap: '1rem' }}>
          <BaseRadioButton
            id="pack-contractuel"
            checked={displayState.paymentDay === 10 ? true : undefined}
            onClick={() => {
              handleCollectionDayClick(10);
            }}
          />
          <span>10</span>
        </div>

        <div style={{ display: 'flex', gap: '1rem' }}>
          <BaseRadioButton
            id="pack-contractuel"
            checked={displayState.paymentDay === 15 ? true : undefined}
            onClick={() => {
              handleCollectionDayClick(15);
            }}
          />
          <span>15</span>
        </div>
      </div>
    </>
  );
};

export default Rib;
