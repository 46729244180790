import styled from 'styled-components';

export const StyledLoaderWrapper = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
`;

export const StyledLoaderContent = styled.div`
  background-color: ${({ theme }) => theme.colors.chartColors.neutral0};
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
  min-height: 20rem;
  border-radius: 0.8rem;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
`;

export const StyledLoaderBody = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
`;

export const StyledLoaderIcon = styled.div<{
  animationDuration: number;
}>`
  margin: 0 auto;
  border: 0.5rem solid #3a913f;
  border-top: ${({ theme }) => `0.5rem solid ${theme.colors.chartColors.neutral50}`};
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  animation: spin ${({ animationDuration }) => `${animationDuration}s`} linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledText = styled.div`
  margin-top: 1.6rem;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.3rem;
  color: ${({ theme }) => theme.colors.chartColors.neutral700};
`;
