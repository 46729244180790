import { AxiosResponse } from 'axios';
import { UserInfos } from 'types';
import {
  AdditionalFaisabiliteData,
  CancelFaisabiliteReq,
  PropositionOutDtoResp,
  UpdateFaisabiliteReq,
  CalculetteDtoResp,
} from 'types/FaisabiliteDTO';
import getFaisabiliteReq from 'utils/faisabiliteDtoUtil';
import { CalculetteFinanciereState } from 'reducers/calculetteFinanciere/type';
import { faisabiliteInstance, SimulationAutonomeInstance } from './axiosInstances';

export const getFaisabilite = (): Promise<AxiosResponse<PropositionOutDtoResp>> => {
  const {
    userId,
    Miob,
    proposition,
    chargesRessources,
    coEmprunteur,
    comportementFinancier,
    donneesBancaires,
    emprunteur,
    foyer,
    garantieHypothecaire,
    identifiantDossierExterne,
    numeroContreMarque,
    projet,
  } = getFaisabiliteReq();
  return faisabiliteInstance.post(`createSubscription`, {
    userId,
    Miob,
    proposition,
    chargesRessources,
    coEmprunteur,
    comportementFinancier,
    donneesBancaires,
    emprunteur,
    foyer,
    garantieHypothecaire,
    identifiantDossierExterne,
    numeroContreMarque,
    projet,
  });
};

export const updateFaisabilite = (
  additionalInfos: AdditionalFaisabiliteData,
): Promise<AxiosResponse<PropositionOutDtoResp>> => {
  return faisabiliteInstance.post(`updateSubscription`, {
    ...getFaisabiliteReq(),
    ...additionalInfos,
  } as UpdateFaisabiliteReq);
};

const getCancelFaisabiliteReq = (): CancelFaisabiliteReq | undefined => {
  const userInfosFromSession = sessionStorage.getItem('authenticatedUser');

  if (userInfosFromSession) {
    const userInfos = JSON.parse(userInfosFromSession) as UserInfos;
    const numeroProjet = Number(sessionStorage.getItem('numeroProjet'));

    if (
      userInfos.uid &&
      userInfos.numContremarque &&
      !Number.isNaN(userInfos.numContremarque) &&
      numeroProjet &&
      !Number.isNaN(numeroProjet)
    ) {
      return {
        userId: userInfos.uid.toString(),
        numeroContreMarque: userInfos.numContremarque,
        numeroProjet,
      } as CancelFaisabiliteReq;
    }
  }

  return undefined;
};

export const cancelFaisabilite = (): Promise<AxiosResponse<PropositionOutDtoResp>> => {
  const cancelFaisabiliteReq = getCancelFaisabiliteReq();

  return faisabiliteInstance.post(`cancelSubscription`, cancelFaisabiliteReq);
};

export const getSimulationAutonome = (
  calculetteFinanciere: CalculetteFinanciereState,
): Promise<AxiosResponse<CalculetteDtoResp>> => {
  const reqBody = calculetteFinanciere;
  reqBody.userId = JSON.parse(sessionStorage.getItem('authenticatedUser') || '{}')?.uid;
  return SimulationAutonomeInstance.post(`simulationAutonome`, reqBody);
};

export default getFaisabilite;
