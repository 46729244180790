import * as React from 'react';

const PlusCircleIconFull: React.FC = () => (
  <svg width={28} height={28} xmlns="http://www.w3.org/2000/svg">
    <title>FF257F9B-7B9A-46BA-8776-2F815DF4A7DA</title>
    <path
      d="M28 14c0 7.732-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0s14 6.268 14 14ZM14.875 7.875a.875.875 0 1 0-1.75 0v5.25h-5.25a.875.875 0 1 0 0 1.75h5.25v5.25a.875.875 0 1 0 1.75 0v-5.25h5.25a.875.875 0 1 0 0-1.75h-5.25v-5.25Z"
      fill="#268038"
      fillRule="evenodd"
    />
  </svg>
);

export default PlusCircleIconFull;
