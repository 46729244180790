import { SauvegardeDonneeRequestBody } from 'types/sauvegardeDonnee';
import { SauvegardeInstance } from './axiosInstances';

const sauvegardeDonnees = (data: SauvegardeDonneeRequestBody): Promise<any> => {
  return SauvegardeInstance.post('sauvegardeDonnees', { ...data })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      console.error(err);
      const message = err.response ? err.response.data : `${err}`;
      throw Error(message);
    });
};

export default sauvegardeDonnees;
