import { ConditionsFinancieresState } from 'reducers/conditionsFinancieres/types';
import { IdentityState } from 'reducers/identity/types';
import { Loan, Operation, OperationState } from 'reducers/operationClient/types';
import { RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import { SituationFoyerState } from 'reducers/situationFoyer/types';
import {
  SituationProfessionnelle,
  SituationProfessionnelleState,
} from 'reducers/situationProfessionnelle/types';
import { UserInfos } from 'types';
import { getDateFromInput } from './DateUtils';
import {
  AssuranceEmprunteur,
  AutresCharge,
  AutresRessource,
  BesoinsComplementaire,
  BienImmobilier,
  ChargesRessources,
  ComportementFinancier,
  Credit,
  Dette,
  DonneesBancaire,
  Emprunteur,
  EtatCivil,
  FaisabiliteReq,
  Foyer,
  GarantieHypothecaire,
  Palier,
  Projet,
  Proposition,
} from '../types/FaisabiliteDTO';
import { customReplaceAll } from './commun';

// Emprunteur

const forBorrower = true;
const forCoBorrower = false;

const getEtatCivil = (borrower: IdentityState, emprunteurIn: Emprunteur): Emprunteur => {
  const {
    lastName,
    firstName,
    isBirthNameSameAsName,
    birthName,
    civility,
    birthDate,
    nationality,
    birthCountry,
    birthCity,
    birthDepartment,
  } = borrower;

  const emprunteur = { ...emprunteurIn };
  emprunteur.etatCivil = {} as EtatCivil;

  emprunteur.etatCivil.civilite = civility || '';
  emprunteur.etatCivil.communeNaissance = birthCity?.libelle || '';
  emprunteur.etatCivil.dateNaissance = getDateFromInput(birthDate || '');
  emprunteur.etatCivil.departementNaissance = birthDepartment?.code || '';
  emprunteur.etatCivil.nationalite = nationality?.code || '';
  emprunteur.etatCivil.nom = lastName || '';
  emprunteur.etatCivil.nomNaissance = isBirthNameSameAsName
    ? lastName || ''
    : birthName || '';
  emprunteur.etatCivil.paysNaissance = birthCountry?.code || '';
  emprunteur.etatCivil.prenom = firstName || '';

  return emprunteur;
};

export const getSituationProfessionnelle = (
  situation: SituationProfessionnelle,
  emprunteurIn: Emprunteur,
): Emprunteur => {
  const {
    profession,
    typeContrat,
    currentJobOrSituationStartingDate,
    sirenNumber,
    apeNumber,
    adresse,
    employerCountry,
  } = situation;

  const emprunteur = { ...emprunteurIn };

  emprunteur.situationProfessionnelle = {
    categorieSocioProfessionnelle: '',
    typeContrat: 0,
  };
  emprunteur.situationProfessionnelle.categorieSocioProfessionnelle =
    profession?.code || '';
  if (apeNumber) emprunteur.situationProfessionnelle.codeApe = apeNumber;
  if (currentJobOrSituationStartingDate) {
    emprunteur.situationProfessionnelle.dateEntree = getDateFromInput(
      `01/${currentJobOrSituationStartingDate}`,
    );
  }
  if (sirenNumber) emprunteur.situationProfessionnelle.numeroSiren = Number(sirenNumber);
  if (employerCountry?.code)
    emprunteur.situationProfessionnelle.paysEmployeur = employerCountry?.code;
  emprunteur.situationProfessionnelle.adresse = {
    codePostal: adresse?.codePostal || '',
    ville: adresse?.city?.libelle || '',
  };
  emprunteur.situationProfessionnelle.typeContrat = Number(typeContrat?.code);

  return emprunteur;
};

const getEmprunteur = (isMainBorrower: boolean): Emprunteur => {
  let emprunteur = {} as Emprunteur;

  const situationProfessionnelleState = JSON.parse(
    sessionStorage.getItem('situationProfessionnelle') || '{}',
  ) as SituationProfessionnelleState;

  const situationFoyerState = JSON.parse(
    sessionStorage.getItem('situationFoyer') || '{}',
  ) as SituationFoyerState;

  const situationProfessionnelle = isMainBorrower
    ? situationProfessionnelleState.borrower
    : situationProfessionnelleState.coBorrower;

  const borrower = isMainBorrower
    ? (JSON.parse(sessionStorage.getItem('borrowerIdentity') || '') as IdentityState)
    : (JSON.parse(sessionStorage.getItem('coBorrowerIdentity') || '') as IdentityState);

  // Mapping

  emprunteur = getEtatCivil(borrower, emprunteur);
  emprunteur = getSituationProfessionnelle(situationProfessionnelle, emprunteur);

  const maritalStatus = isMainBorrower
    ? situationFoyerState.maritalStatus
    : situationFoyerState.coBorrowerMaritalStatus;

  emprunteur.etatCivil.situationFamiliale = maritalStatus || '';

  return emprunteur;
};

// Foyer

export const getFoyer = (foyerState: SituationFoyerState): Foyer => {
  const foyer = {} as Foyer;

  const {
    addressZipCode,
    addressCity,
    startingYearLivingInCurrentHouse,
    childrenInChargeBirthYear,
    housingStatus,
    numberOfPersonInCurrentHouse,
  } = foyerState;

  const dateEntree = new Date();
  dateEntree.setFullYear(startingYearLivingInCurrentHouse || 0);

  const datesNaissancesEnfants = [] as Date[];
  childrenInChargeBirthYear?.forEach(childrenBirthYear => {
    const dateNaissance = new Date();
    dateNaissance.setFullYear(Number(childrenBirthYear.birthYear));
    datesNaissancesEnfants.push(dateNaissance);
  });

  // Mapping

  foyer.codePostal = Number(addressZipCode);
  foyer.ville = addressCity?.libelle || '';
  foyer.dateEntree = dateEntree;
  foyer.datesNaissancesEnfants = datesNaissancesEnfants;
  foyer.logement = housingStatus as string;
  foyer.nombreEnfants = datesNaissancesEnfants.length;
  foyer.nombrePersonneFoyer = numberOfPersonInCurrentHouse || 0;

  return foyer;
};

// ChargesRessources

export const getChargesRessources = (): ChargesRessources => {
  const chargesRessources = {} as ChargesRessources;

  const ressourcesMensuelles = JSON.parse(
    sessionStorage.getItem('ressourcesMensuelles') || '{}',
  ) as RessourcesMensuellesState;

  const { monthlyCharges, otherIncomes, borrowerIncome, coBorrowerIncome } =
    ressourcesMensuelles;

  // Mapping

  chargesRessources.autresCharges = monthlyCharges.map(charge => {
    return {
      type: charge.code,
      montant: charge.value?.replaceAll(' ', '') || 0,
      // 1 = Emprunteur, 2 = Co-Emprunteur, 3 = les deux
      titulaire: 1,
    } as AutresCharge;
  });

  chargesRessources.autresRessources = otherIncomes.map(income => {
    return {
      type: income.code,
      montant: income.value?.replaceAll(' ', '') || 0,
      // 1 = Emprunteur, 2 = Co-Emprunteur, 3 = les deux
      titulaire: income.titulaire ? income.titulaire : 1,
    } as AutresRessource;
  });

  if (borrowerIncome.retirementMonthlyIncomes) {
    chargesRessources.autresRessources.push({
      type: 'RE',
      montant: Number(borrowerIncome.retirementMonthlyIncomes?.replaceAll(' ', '')),
      titulaire: 1,
    });
  }

  if (coBorrowerIncome.retirementMonthlyIncomes) {
    chargesRessources.autresRessources.push({
      type: 'RE',
      montant: Number(coBorrowerIncome.retirementMonthlyIncomes?.replaceAll(' ', '')),
      titulaire: 2,
    });
  }

  chargesRessources.salaireNetEmprunteur = Number(
    borrowerIncome.monthlyIncomes?.replaceAll(' ', ''),
  );
  chargesRessources.salaireNetCoEmprunteur = coBorrowerIncome?.monthlyIncomes
    ? Number(coBorrowerIncome.monthlyIncomes.replaceAll(' ', ''))
    : 0;

  return chargesRessources;
};

// Données Bancaires

export const getDonneesBancaires = (): DonneesBancaire[] => {
  const donneesBancaires = [] as DonneesBancaire[];

  const conditionsFinancieres = JSON.parse(
    sessionStorage.getItem('ressourcesMensuelles') || '',
  ) as RessourcesMensuellesState;

  conditionsFinancieres.banks.forEach(bank => {
    donneesBancaires.push({
      banque: bank.libelle,
      dateOuverture: getDateFromInput(`01/01/${bank.year}`),
    } as DonneesBancaire);
  });

  return donneesBancaires;
};

// Projet

export const getCredits = (loanList: Loan[]): Credit[] => {
  const credits = [] as Credit[];

  loanList.forEach(loan => {
    const {
      loanStartDate,
      monthlyPayment,
      initialLoanCapital,
      creditType,
      restructure,
      buyBack,
      balance,
      mortgage,
      loaner,
      totalLoanDuration,
    } = loan;

    const credit = {} as Credit;
    if (loanStartDate) credit.datePret = getDateFromInput(loanStartDate);
    if (totalLoanDuration) credit.dureeActualisee = Number(totalLoanDuration);
    if (initialLoanCapital)
      credit.montantInitialPret = Number(initialLoanCapital?.replaceAll(' ', ''));
    if (creditType) credit.nature = creditType.code;
    if (balance) credit.solde = Number(balance?.replaceAll(' ', ''));
    credit.indicateurInscrit = mortgage ? 'O' : 'N';
    credit.mensualite = Number(monthlyPayment?.replaceAll(' ', ''));
    credit.pretRestructureIndicateur = restructure ? 'O' : 'N';
    credit.rachatIndicateur = buyBack ? 'O' : 'N';
    credit.societe = loaner?.code || '';

    credits.push(credit);
  });

  return credits;
};

export const getComplementaires = (
  operations: Operation[],
): BesoinsComplementaire[] | Dette[] => {
  const Complementaires = [] as BesoinsComplementaire[] | Dette[];

  operations.forEach(operation => {
    Complementaires.push({ montant: operation.amount, type: operation.code });
  });

  return Complementaires;
};

const getProjet = (valeurFraisIntermediation?: number, inputType?: string): Projet => {
  const projet = {} as Projet;

  const operationsClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || '',
  ) as OperationState;

  const conditionsFinancieres = JSON.parse(
    sessionStorage.getItem('conditionsFinancieres') || '{}',
  ) as ConditionsFinancieresState;

  // Mapping

  projet.besoinsComplementaires = operationsClient.otherNeedsList.map(otherNeed => {
    return { type: otherNeed.type, montant: otherNeed.amount } as BesoinsComplementaire;
  });

  projet.typeGarantie = operationsClient.hasInsurance ? 'HYP' : 'SSG';
  projet.credits = getCredits(operationsClient.loanList);
  projet.besoinsComplementaires = getComplementaires(
    operationsClient.otherNeedsList,
  ) as BesoinsComplementaire[];
  projet.dettes = getComplementaires(operationsClient.buybackDebtsList) as Dette[];

  projet.valeurFraisIntermediation =
    valeurFraisIntermediation !== undefined
      ? valeurFraisIntermediation
      : Number(conditionsFinancieres.intermediationFeesRate?.replaceAll(',', '.'));

  projet.typeValeurFraisIntermediation =
    inputType || conditionsFinancieres.inputType || 'P';

  return projet;
};

export const getComportementFinancier = (): ComportementFinancier => {
  const comportementFinancier = {} as ComportementFinancier;

  const operationClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || '',
  ) as OperationState;

  comportementFinancier.nombreImpayesNonRegularisesDernierMoisTotal = 0;
  comportementFinancier.nombreImpayesNonRegularisesTotal = 0;
  comportementFinancier.nombreImpayesRegularisesTotal = 0;
  comportementFinancier.nombreMoisImpayesTotal = operationClient.nbPaymentDefault;

  comportementFinancier.incidentFinanciers = [];
  operationClient.incidentPaymentList?.forEach(item =>
    comportementFinancier.incidentFinanciers.push(item.code),
  );

  return comportementFinancier;
};

export const getGarantieHypothecaire = (
  foyerState: SituationFoyerState,
): GarantieHypothecaire => {
  const garantiesHypothecaires = {} as GarantieHypothecaire;

  const operationClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || '',
  ) as OperationState;

  if (operationClient.assetList.length > 0) {
    garantiesHypothecaires.biensImmobiliers = [];
    operationClient.assetList.forEach(asset => {
      garantiesHypothecaires.biensImmobiliers.push({
        codePostal: asset.isAssetAddressBorrowerAddress
          ? foyerState.addressZipCode
          : asset.zipCode || '0',
        adresseIdentiqueEmpIndicateur: asset.isAssetAddressBorrowerAddress ? 'O' : 'N',
        nature: asset.type?.code,
        prisEnGarantieIndicateur: 'O',
        rangHypothecaire: asset.inscriptionRank,
        usage: asset.usage?.code,
        valeurEstimee: Number(asset.currentValue?.replaceAll(' ', '')),
        valeurHypothequesConservees: asset.keptMortagagesValue
          ? Number(asset.keptMortagagesValue?.replaceAll(' ', ''))
          : '0',
      } as BienImmobilier);
    });

    return garantiesHypothecaires;
  }
  return {} as GarantieHypothecaire;
};

const getInsurance = (isMainBorrower: boolean): AssuranceEmprunteur => {
  const assurance = {} as AssuranceEmprunteur;

  const conditionsFinancieres = JSON.parse(
    sessionStorage.getItem('conditionsFinancieres') || '{}',
  ) as ConditionsFinancieresState;

  if (conditionsFinancieres.borrowerInsurance) {
    const insurance = isMainBorrower
      ? conditionsFinancieres.borrowerInsurance
      : conditionsFinancieres.coBorrowerInsurance;

    assurance.delaiFranchise = insurance?.insuranceGracePeriod?.code || '02';
    assurance.echeanceAssuranceDeleguee = 1;
    assurance.garantieDeces = insurance?.deathInsuranceChecked ? 'O' : 'N';
    assurance.garantiePerteEmploi = insurance?.jobLossInsuranceChecked ? 'O' : 'N';
    assurance.garantiePtia = insurance?.ptiaInsuranceChecked ? 'O' : 'N';
    assurance.quotiteDeces = insurance?.deathInsuranceRate || 0;
    assurance.garantieItt = insurance?.ittIptInsuranceChecked ? 'O' : 'N';
    assurance.quotiteItt = insurance?.ittIptInsuranceChecked
      ? insurance?.deathInsuranceRate
      : 0;
    assurance.quotitePerteEmploi = insurance?.jobLossInsuranceChecked
      ? insurance?.deathInsuranceRate
      : 0;
    assurance.quotitePtia = insurance?.ptiaInsuranceChecked
      ? insurance?.deathInsuranceRate
      : 0;
    assurance.typeAssuranceChoisie = insurance?.insurance?.code || '';
    if (
      assurance.typeAssuranceChoisie === 'GC' ||
      assurance.typeAssuranceChoisie === 'GP'
    )
      assurance.typeAssuranceChoisie = 'G';
    if (assurance.typeAssuranceChoisie === 'I') {
      assurance.typeAssuranceChoisie = 'D';
      assurance.garantieDeces = 'O';
      assurance.quotiteDeces = insurance.coverageRate || 0;
      if (insurance?.monthlyEstimatedBonus) {
        assurance.echeanceAssuranceDeleguee = insurance.monthlyEstimatedBonus;
      }
    }
  }
  return assurance;
};

const getProposition = (): Proposition => {
  const proposition = {} as Proposition;

  const identity = JSON.parse(
    sessionStorage.getItem('borrowerIdentity') || '',
  ) as IdentityState;

  const conditionsFinancieres = JSON.parse(
    sessionStorage.getItem('conditionsFinancieres') || '{}',
  ) as ConditionsFinancieresState;

  proposition.assuranceEmprunteur = getInsurance(forBorrower);
  if (identity.hasCoBorrower) {
    proposition.assuranceCoEmprunteur = getInsurance(forCoBorrower);
  }
  proposition.dureeSouhaitee = Number(conditionsFinancieres.loanDuration);
  proposition.dureeFranchise = Number(conditionsFinancieres.franchiseDuration || 0);
  proposition.tauxNominal = Number(
    customReplaceAll(conditionsFinancieres.nominalInterestRate || '', ',', '.'),
  );

  if (
    conditionsFinancieres.firstPalierDuration &&
    conditionsFinancieres.firstPalierMonthlyPayment
  ) {
    proposition.palier = {} as Palier;

    proposition.palier.dureePremierPalier = conditionsFinancieres.hasLoanOption
      ? Number(conditionsFinancieres.firstPalierDuration)
      : 0;
    proposition.palier.mensualitePremierPalier = conditionsFinancieres.hasLoanOption
      ? Number(conditionsFinancieres.firstPalierMonthlyPayment?.replaceAll(' ', ''))
      : 0;
  }

  return proposition;
};

export const getFaisabiliteReq = (
  valeurFraisIntermediation?: number,
  inputType?: string,
): FaisabiliteReq => {
  const request = {} as FaisabiliteReq;

  const identityState = JSON.parse(
    sessionStorage.getItem('borrowerIdentity') || '{}',
  ) as IdentityState;

  const authenticatedUser = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  const foyerState = JSON.parse(
    sessionStorage.getItem('situationFoyer') || '{}',
  ) as SituationFoyerState;

  // Mapping

  const { uid, numContremarque } = authenticatedUser;

  request.userId = uid;
  request.numeroContreMarque = numContremarque;

  request.emprunteur = getEmprunteur(forBorrower);
  if (identityState.hasCoBorrower) {
    request.coEmprunteur = getEmprunteur(forCoBorrower);
  }

  request.foyer = getFoyer(foyerState);

  request.chargesRessources = getChargesRessources();

  request.donneesBancaires = getDonneesBancaires();

  request.projet = getProjet(valeurFraisIntermediation, inputType);
  request.comportementFinancier = getComportementFinancier();
  request.garantieHypothecaire = getGarantieHypothecaire(foyerState);

  request.proposition = getProposition();

  return request;
};

export default getFaisabiliteReq;
