interface HomeIconProps {
  scale?: string;
  color?: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({ scale, color }) => (
  <svg
    width={32 * Number(scale)}
    height={32 * Number(scale)}
    xmlns="http://www.w3.org/2000/svg">
    <title>HomeIcon</title>
    <path
      transform={`scale(${scale})`}
      d="M29.097 18.148 16.563 5.564a.811.811 0 0 0-1.133 0L2.897 18.148a.817.817 0 0 0 0 1.137.811.811 0 0 0 1.133 0l3.093-3.103v9.682c0 .442.36.803.8.803h5.24c.44 0 .8-.361.8-.803v-5.309h4.027l.013 5.31c0 .44.36.802.8.802h5.267c.213 0 .413-.08.56-.241a.758.758 0 0 0 .227-.562v-9.668l3.093 3.115c.16.161.36.241.56.241.2 0 .413-.08.56-.24.347-.348.347-.856.027-1.164Zm-5.814 6.887h-3.666l-.014-5.309a.804.804 0 0 0-.8-.802h-5.626a.745.745 0 0 0-.56.24.827.827 0 0 0-.24.562v5.31h-3.64V14.563l7.266-7.302 7.267 7.302.013 10.471Z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

HomeIcon.defaultProps = {
  scale: '1',
  color: '#FFF',
};

export default HomeIcon;
