import { useState } from 'react';
import { useNavigate } from 'react-router';
import { CodeLibelle } from 'types';
import { clientFilesResult } from 'types/clientFileDtos';
import theme from 'styles/theme';
import BaseButton from 'components/designSystem/BaseButton';
import ResultCard from 'components/resultCard/ResultCard';
import EyeIcon from 'icons/EyeIcon';
import ArrowRightCircleFullIcon from 'icons/ArrowRightCircleFullIcon';
import { StyledButtonLabel, StyledResultCounter } from 'containers/search/style';
import { StyledClickableBackground } from 'containers/profil/style';
import { StyledSeeOtherFilesWrapper } from 'components/waitingFilesCard/styles';
import {
  StyledNoActionFilesCard,
  StyledVerticalBar,
  StyledNoActionFilesListContainer,
  StyledHorizontalBar,
  StyledTriangle,
  StyledSeeOtherFilesTitle,
} from './styles';

interface NoActionFilesCardProps {
  listeDossier: clientFilesResult[];
  niveauxProcess: CodeLibelle[];
  getFileDetailsAndNavigate: (
    numeroProjet: number,
    projetDetail: clientFilesResult,
  ) => void;
  mainColor: string;
  barColor: string;
  label: string;
  delai?: boolean;
  last?: boolean;
}

const NoActionFilesCard: React.FC<NoActionFilesCardProps> = ({
  listeDossier,
  niveauxProcess,
  getFileDetailsAndNavigate,
  mainColor,
  barColor,
  label,
  delai,
  last,
}) => {
  const [showListFiles, setShowListFiles] = useState<boolean>(false);

  const navigate = useNavigate();

  const onShowMoreClick = () => {
    sessionStorage.setItem('listeDossier', JSON.stringify(listeDossier));
    navigate('/recherche');
  };

  const renderNavNbShowFiles = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: '0 3.25rem',
          marginTop: '1.5rem',
          alignItems: 'baseline',
        }}>
        <StyledResultCounter>{listeDossier?.length}</StyledResultCounter>
        <StyledSeeOtherFilesWrapper
          onClick={() => onShowMoreClick()}
          role="button"
          tabIndex={0}
          onKeyDown={() => onShowMoreClick()}>
          <StyledSeeOtherFilesTitle>Voir tous les dossiers</StyledSeeOtherFilesTitle>
          <div style={{ width: ' 1.8rem', marginLeft: '1rem' }}>
            <ArrowRightCircleFullIcon />
          </div>
        </StyledSeeOtherFilesWrapper>
      </div>
    );
  };

  const renderListFiles = () => {
    return (
      <>
        <StyledNoActionFilesListContainer last={last}>
          {' '}
          <StyledHorizontalBar color={barColor} />
          <StyledTriangle color={barColor} last={last} />
          {listeDossier?.length >= 6 && renderNavNbShowFiles()}
          <div style={{ marginTop: '4rem', marginLeft: '3.2rem' }}>
            {listeDossier.slice(0, 5).map(dossier => {
              return (
                <div key={dossier.IdProjet} style={{ marginBottom: '3rem' }}>
                  <ResultCard
                    file={dossier}
                    niveauxProcess={niveauxProcess}
                    onClick={getFileDetailsAndNavigate}
                    inDashboard
                    delai={delai}
                  />
                </div>
              );
            })}
          </div>
        </StyledNoActionFilesListContainer>
        <StyledClickableBackground
          onClick={() => {
            setShowListFiles(false);
          }}
        />
      </>
    );
  };
  return (
    <div>
      <StyledNoActionFilesCard color={mainColor}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1.8rem' }}>
          <StyledVerticalBar color={barColor} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '1.6rem',
              gap: '1.6rem',
            }}>
            {label}
            {listeDossier.length === 0 ? (
              <p>
                <i>Aucun dossier</i>
              </p>
            ) : (
              <BaseButton
                disabled={listeDossier.length === 0 ? true : null}
                onClick={() => {
                  if (listeDossier?.length > 0) setShowListFiles(!showListFiles);
                }}
                style={{
                  background: mainColor,
                  display: 'flex',
                  width: 'fit-content',
                  alignItems: 'center',
                }}>
                <EyeIcon />
                <StyledButtonLabel
                  style={{
                    marginLeft: '1rem',
                    color: theme.colors.chartColors.mainGreenBnp500,
                  }}>
                  {listeDossier.length === 1
                    ? `Voir le dossier`
                    : `Voir les ${listeDossier.length} dossiers`}
                </StyledButtonLabel>
              </BaseButton>
            )}
          </div>
        </div>
      </StyledNoActionFilesCard>
      {showListFiles && renderListFiles()}
    </div>
  );
};

export default NoActionFilesCard;
