import { CodeLibelle } from 'types';
import { clientFileResp, clientFilesResp } from 'types/clientFileDtos';
import { getNumeroContremarque } from 'utils/commun';
import { getDateFromInput, getPastDayFromToday } from 'utils/DateUtils';
import AuthorizationService from './AuthorizationService';
import { consultationInstance } from './axiosInstances';

const getClientFile = (numeroProjet: number): Promise<clientFileResp> => {
  const uid = AuthorizationService.getUserInfo()?.uid;
  const requestURL = `dossierClient?numeroProjet=${numeroProjet}&userId=${uid}`;

  return consultationInstance
    .get(requestURL)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting the client files list. Reason: ${err}`;
      console.log(message);
    });
};

const addParam = (requestUrl: string, label: string, value?: string): string => {
  return value ? `${requestUrl}&${label}=${value}` : requestUrl;
};

export const getListeDossier = (
  nomClient?: string,
  prenomClient?: string,
  numeroDossier?: string,
  statutDossier?: CodeLibelle,
  dateFrom?: string,
  dateTo?: string,
  natureGarantie?: CodeLibelle,
  typeTraitement?: CodeLibelle,
): Promise<clientFilesResp> => {
  const userInfos = AuthorizationService.getUserInfo();
  const uid = userInfos?.uid;

  let requestUrl = `getListeDossier?contextApp=EBRO&userId=${uid}`;

  requestUrl = addParam(requestUrl, 'adheNum', getNumeroContremarque()?.toString());
  requestUrl = addParam(requestUrl, 'nomClient', nomClient);
  requestUrl = addParam(requestUrl, 'prenomClient', prenomClient);
  requestUrl = addParam(requestUrl, 'numeroProjetGed', numeroDossier);
  requestUrl = addParam(requestUrl, 'statutPropositionCommerciale', statutDossier?.code);
  requestUrl = addParam(
    requestUrl,
    'dateDebutCreation',
    dateFrom ? getDateFromInput(dateFrom).toDateString() : '',
  );
  requestUrl = addParam(
    requestUrl,
    'dateFinCreation',
    dateTo ? getDateFromInput(dateTo).toDateString() : '',
  );
  requestUrl = addParam(requestUrl, 'garInd', natureGarantie?.code);
  requestUrl = addParam(requestUrl, 'prcsSeNiv', typeTraitement?.code);

  const processAffectationCode = ['1', '2', '3', '4', '5', '6', '7', '8'];

  processAffectationCode.forEach(code => {
    requestUrl = `${requestUrl}&prosAffectCod=${code}`;
  });

  return consultationInstance
    .get(requestUrl)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting the client files list. Reason: ${err}`;
      alert(
        "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
      );
      console.error(message);
    });
};

export const getListeDossierDashboard = (dayPeriod: number): Promise<clientFilesResp> => {
  const userInfos = AuthorizationService.getUserInfo();
  const uid = userInfos?.uid;

  let requestUrl = `getListeDossier?contextApp=EBRO&userId=${uid}`;

  requestUrl = addParam(requestUrl, 'adheNum', getNumeroContremarque()?.toString());
  requestUrl = addParam(
    requestUrl,
    'dateDebutCreation',
    getPastDayFromToday(dayPeriod).toDateString(),
  );

  const processAffectationCode = ['1', '2', '3', '4', '5', '6', '7', '8'];

  processAffectationCode.forEach(code => {
    requestUrl = `${requestUrl}&prosAffectCod=${code}`;
  });

  return consultationInstance
    .get(requestUrl)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting the client files list. Reason: ${err}`;
      alert(
        "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
      );
      console.log(message);
    });
};

export const getClientFiles = (
  processAffectationCode: number[],
): Promise<clientFilesResp> => {
  const numContremarque = getNumeroContremarque();
  const uid = AuthorizationService.getUserInfo()?.uid;
  const numeroProjet = sessionStorage.getItem('numeroProjet');
  let requestUrl = `GetListeDossier?adheNum=${numContremarque}&contextApp=EBRO&userId=${uid}&numeroProjetGed=${numeroProjet}`;

  processAffectationCode.forEach(code => {
    requestUrl = `${requestUrl}&prosAffectCod=${code}`;
  });

  return consultationInstance
    .get(requestUrl)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting the client files list. Reason: ${err}`;
      console.log(message);
    });
};

export default getClientFile;
