import styled from 'styled-components';

export const MainTitle = styled.h2`
  color: #292c2e;
  margin: 0;
  font-size: 3.2rem;
  font-family: Ubuntu !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
`;

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.chartColors.neutral400};
  font-size: 1.6rem;
  margin: 0;
`;
