import styled from 'styled-components';

export const StyledInput = styled.input`
  padding: 2.6rem;
  border-radius: 1.6rem;
  border: hidden;
  outline: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  font-family: 'Open Sans';
  font-size: 16px;
  color: #4b4f54;
`;

export const StyledInputStyle = {
  padding: '2.6rem',
  borderRadius: '1.6rem',
  border: 'hidden',
  outline: 'none',
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.15)',
  fontFamily: 'Open Sans',
  fontSize: '16px',
  color: '#4b4f54',
} as React.CSSProperties;

export const StyledSelectInput = styled.div`
  padding: 2.6rem;
  border-radius: 1.6rem;
  border: #ffffff;
  outline-color: #008859;
  outline-width: 0.1rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`;

export const StyledSelectInputList = styled.div`
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0.8rem;
  border-radius: 1rem;
  border: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`;

export const StyledSelectInputText = styled.div`
  cursor: pointer;
  border-radius: 0.2rem;
`;

export const StyledSelectInputPlaceholderText = styled.div`
  cursor: pointer;
  border-radius: 0.2rem;
  color: #6f757c;
`;

export const StyledSelectText = styled.div`
  cursor: pointer;
  border-radius: 0.2rem;
  padding: 0.8rem;
`;

export const StyledDiv = styled.div``;
