import BaseDateInput from './BaseDateInput';
import { StyledInputStyle } from './style';

interface SearchDateInputProps {
  value?: string;
  setValue: (newValue: string) => void;
  placeholder: string;
  type: 'day' | 'month' | 'year';
  width?: string;
}

const SearchDateInput: React.FC<SearchDateInputProps> = ({
  value,
  setValue,
  placeholder,
  type,
  width,
}) => {
  return (
    <>
      <BaseDateInput
        value={value}
        setValue={setValue}
        placeholder={placeholder}
        type={type}
        style={{
          ...StyledInputStyle,
          width: width || '11.8rem',
          paddingLeft: '1.6rem',
          paddingRight: '1.6rem',
        }}
        focusedStyle={{
          border: 'solid',
          paddingLeft: '1.5rem',
          borderColor: '#008859',
          borderWidth: '0.1rem',
        }}
        validStyle={{
          border: 'solid',
          borderColor: '#008859',
          borderWidth: '0.1rem',
        }}
      />
    </>
  );
};

export default SearchDateInput;
