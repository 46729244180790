import { getLoanUsageTypes } from 'api/referentialService';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { ValidityHtmlElement } from 'components/designSystem/DesignSystemTypes';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { CodeLibelle } from 'types';

interface LoanObjectProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanObject: React.FC<LoanObjectProps> = ({ currentLoan, state, dispatch }) => {
  const inputId = 'loanObject';
  const [loanObjectInput, setLoanObjectInput] = useState<HTMLElement | null>();
  const [value, setValue] = useState<CodeLibelle | undefined>(currentLoan?.loanObject);
  const [usageTypes, setUsageTypes] = useState<CodeLibelle[]>([]);

  useEffect(() => {
    setLoanObjectInput(document.getElementById(inputId));
    const fetchLoanUsageTypes = async () => {
      try {
        const response = await getLoanUsageTypes();
        const result: CodeLibelle[] = [];
        response.Resultat.forEach(item => {
          result.push({ code: item.code, libelle: item.libelle });
        });
        setUsageTypes(result);
      } catch (error) {
        // TODO: gestion des erreurs lors du sprint dédié aux erreurs
      }
    };
    fetchLoanUsageTypes();
  }, []);

  const displayInputError = (errorMessage: string) => {
    if (loanObjectInput) {
      (loanObjectInput as unknown as ValidityHtmlElement).valid = false;
      (loanObjectInput as unknown as ValidityHtmlElement).invalid = true;
      (loanObjectInput as unknown as ValidityHtmlElement).errorMessage = errorMessage;
    }
  };

  const updateState = (val?: CodeLibelle) => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, loanObject: val };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  const onLoanObjectSelect = (event: Event) => {
    const selectedType = (event as CustomEvent).detail.value as string;
    const newValue = usageTypes.find(referentialType => {
      return referentialType.libelle === selectedType;
    });
    setValue(newValue);
  };

  const onLoanObjectBlur = (event: Event) => {
    if (event && event.target && (event.target as unknown as ComboInputDS).value === '') {
      displayInputError(`L'objet du crédit est obligatoire`);
      updateState(undefined);
    } else {
      updateState(value);
    }
  };

  return (
    <>
      <ComboInput
        id="loanObject"
        list-on-open
        className="combo-finalisation-projet"
        shadow
        required
        align-items="left"
        items={JSON.stringify(usageTypes)}
        field="libelle"
        label="Objet du crédit"
        placeholder="Choisir l'objet du crédit"
        onInputChange={e => {
          onLoanObjectSelect(e);
        }}
        onListBlur={e => {
          onLoanObjectBlur(e);
        }}
        value={currentLoan?.loanObject?.libelle ? currentLoan.loanObject.libelle : ''}
      />
    </>
  );
};

export default LoanObject;
