import {
  DebtsAdditionalInformationAction,
  DebtsAdditionalInformationState,
  DebtsCompleteData,
} from 'reducers/debtsAdditionalInformation/type';
import BankDebt from './bank/BankDebt';
import FamilyDebt from './FamilyDebt';
import OtherDebt from './OtherDebt';

interface DebtAdditionalInformationProps {
  debt: DebtsCompleteData;
  state: DebtsAdditionalInformationState;
  dispatch: React.Dispatch<DebtsAdditionalInformationAction>;
  checkInputs: boolean;
}
const DebtAdditionalInformation: React.FC<DebtAdditionalInformationProps> = ({
  debt,
  state,
  dispatch,
  checkInputs,
}) => {
  if (debt.code === 'DB') {
    return (
      <BankDebt debt={debt} state={state} dispatch={dispatch} checkInputs={checkInputs} />
    );
  }
  if (debt.code === 'RD') {
    return (
      <FamilyDebt
        debt={debt}
        state={state}
        dispatch={dispatch}
        checkInputs={checkInputs}
      />
    );
  }
  return (
    <OtherDebt debt={debt} state={state} dispatch={dispatch} checkInputs={checkInputs} />
  );
};

export default DebtAdditionalInformation;
