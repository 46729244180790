import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface PriorNoticeProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const PriorNotice: React.FC<PriorNoticeProps> = ({ currentLoan, state, dispatch }) => {
  const [isPriorNoticeDelayInputFocused, setIsPriorNoticeDelayInputFocused] =
    useState<boolean>(false);
  const [isPriorNoticeDelayInputValid, setIsPriorNoticeDelayInputValid] = useState<
    boolean | undefined
  >();
  const [priorNoticeDelayInputError, setPriorNoticeDelayInputError] =
    useState<string>('');
  const [value, setValue] = useState<string | undefined>(
    currentLoan?.nbMonthsForNotice !== undefined
      ? `${currentLoan?.nbMonthsForNotice}`
      : '',
  );
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const updateState = (val?: number) => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return {
          ...item,
          nbMonthsForNotice: val,
        };
      }
      return item;
    });

    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  const onChange = (val: string) => {
    if (!onlyNumbers.test(val)) return;

    setValue(val);
    updateState(Number(val));
  };

  const handleDelayBlur = () => {
    setIsPriorNoticeDelayInputFocused(false);

    if (!value || !notEmpty.test(value)) {
      setIsPriorNoticeDelayInputValid(false);
      setPriorNoticeDelayInputError(messagesCheckInput.REQUIRED_VALUE);
      updateState(undefined);
    } else if (!onlyNumbers.test(value)) {
      setIsPriorNoticeDelayInputValid(false);
      setPriorNoticeDelayInputError(messagesCheckInput.ONLY_NUMBERS);
      updateState(undefined);
    } else if (Number(value) < 0) {
      setIsPriorNoticeDelayInputValid(false);
      setPriorNoticeDelayInputError(messagesCheckInput.REAL_NUMBER_POSITIVE);
      updateState(undefined);
    } else {
      setIsPriorNoticeDelayInputValid(true);
      setPriorNoticeDelayInputError('');
    }
  };

  useEffect(() => {
    if (notEmpty.test(currentLoan.nbMonthsForNotice?.toString() || '')) handleDelayBlur();
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      <div style={{ marginRight: '1.8rem' }}>
        <CustomInput
          name="notice"
          label="Délai de préavis"
          value={value}
          onChange={newValue => {
            setIsPriorNoticeDelayInputFocused(true);
            onChange(newValue.replaceAll(' ', '').slice(0, 3));
          }}
          isValid={isPriorNoticeDelayInputValid}
          isFocused={isPriorNoticeDelayInputFocused}
          onBlur={handleDelayBlur}
          onFocus={() => {
            setIsPriorNoticeDelayInputFocused(true);
          }}
          after="mois"
          inputWidth="46.4rem"
          placeholder="0"
          error={priorNoticeDelayInputError}
          type="price"
        />
      </div>
    </div>
  );
};

export default PriorNotice;
