import { v4 as uuidv4 } from 'uuid';
import { OperationState } from 'reducers/operationClient/types';
import { Bank, BankDebtType, SessionStorageKeys } from 'types';
import { getExistingStorageByKey } from 'utils/storage';
import { checkFormatDate, getDateFromInput } from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import {
  DebtsAdditionalInformationState,
  DebtsAdditionalInformationAction,
  DebtsCompleteData,
} from './type';

const notEmpty = new RegExp(ValidationRules.notEmpty);
const noNumbers = new RegExp(ValidationRules.noNumbers);
const noSpecialCharacters = new RegExp(ValidationRules.noSpecialCharacters);
const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
const onlyNumbersAndAlphabeticChars = new RegExp(
  ValidationRules.onlyNumbersAndAlphabeticChars,
);

const checkIsDateValid = (date: string | undefined): boolean => {
  const value = getDateFromInput(date || '');

  if (date === undefined || date === '') return false;

  if (!checkFormatDate(date)) return false;

  if (value.getTime() < new Date().getTime()) {
    return false;
  }

  if (value.getFullYear() >= 2100) {
    return false;
  }

  return true;
};

const isBanksOk = (banks: BankDebtType[]) => {
  if (!banks || banks.length === 0) {
    return false;
  }
  const firstNotCompleteBank = banks.find(item => {
    if (
      !item.accountHolder ||
      !item.amount ||
      !item.code ||
      !item.libelle ||
      !item.accountNumber
    )
      return true;

    if (
      !notEmpty.test(item.accountHolder) ||
      !noNumbers.test(item.accountHolder) ||
      !noSpecialCharacters.test(item.accountHolder)
    ) {
      return true;
    }

    if (!notEmpty.test(item.amount) || !onlyNumbers.test(item.amount)) return true;
    if (item.code === 'err') return true;

    if (
      !notEmpty.test(item.accountNumber) ||
      !onlyNumbersAndAlphabeticChars.test(item.accountNumber) ||
      item.accountNumber.length > 11
    )
      return true;

    if (!checkIsDateValid(item.dueDate)) return true;
    return false;
  });

  if (firstNotCompleteBank === undefined) {
    return true;
  }

  return false;
};

const checkIfDataIsOk = (debt: DebtsCompleteData): boolean => {
  if (debt.code !== 'DB' && !checkIsDateValid(debt.dueDate)) {
    return false;
  }
  if (debt.code === 'RD') {
    const { civility, creditorName } = debt;
    if (
      !civility ||
      !civility.code ||
      !creditorName ||
      !notEmpty.test(creditorName) ||
      !noNumbers.test(creditorName) ||
      !noSpecialCharacters.test(creditorName)
    ) {
      return false;
    }
  }
  if (debt.code === 'DB') {
    if (!isBanksOk(debt.banks ? debt.banks : [])) {
      return false;
    }
  }
  if (!debt.doesTotalBankDebtsMatchAmount) return false;

  return true;
};

const addAdditionalDebtInformation = (
  debtsState: DebtsCompleteData[],
): DebtsCompleteData[] => {
  let newDebts = [...debtsState];
  if (newDebts) {
    const additionalDebtInfoStore = sessionStorage.getItem('debtAdditionalInformation');
    if (additionalDebtInfoStore) {
      const additionalDebtInfo = JSON.parse(
        additionalDebtInfoStore,
      ) as DebtsAdditionalInformationState;

      additionalDebtInfo.debts.forEach(debtAdditionalInfo => {
        newDebts = newDebts.map(initialDebt => {
          let debt = initialDebt;
          if (debt.code === debtAdditionalInfo.code) {
            debt = {
              ...debtAdditionalInfo,
              ...debt.banks,
            };
          }
          debt.isDataOk = checkIfDataIsOk(debt);
          return debt;
        });
      });
    }
  }

  return newDebts;
};

export const getDebtAdditionalInformationInitialState = (
  storageKey: SessionStorageKeys,
): DebtsAdditionalInformationState => {
  const getDebts = (): DebtsCompleteData[] => {
    const debts: DebtsCompleteData[] = [];
    const operationsClientFromSessionStorage = sessionStorage.getItem('operationsClient');
    if (operationsClientFromSessionStorage) {
      try {
        const { buybackDebtsList } = JSON.parse(
          operationsClientFromSessionStorage,
        ) as OperationState;
        buybackDebtsList.forEach(item => {
          const uniqueId = uuidv4();
          const newItem = { ...item } as DebtsCompleteData;

          if (item.code === 'DB') {
            newItem.banks = [{ id: uniqueId } as Bank];
          }

          debts.push(newItem as DebtsCompleteData);
        });
      } catch (error) {
        return debts;
      }
    }

    return addAdditionalDebtInformation(debts);
  };

  const debtsAdditionalInfoDefaultValues = {
    debts: getDebts(),
  };

  const debtsAdditionalInformationFromSession = getExistingStorageByKey(
    storageKey,
    debtsAdditionalInfoDefaultValues,
  ) as DebtsAdditionalInformationState;

  const checkedDebtAdditionalData = debtsAdditionalInformationFromSession.debts.map(
    debt => {
      const newDebt = { ...debt };
      newDebt.isDataOk = checkIfDataIsOk(debt);
      return newDebt;
    },
  );

  return checkedDebtAdditionalData && debtsAdditionalInfoDefaultValues;
};

export const debtAdditionalInformationReducer = (
  state: DebtsAdditionalInformationState,
  action: DebtsAdditionalInformationAction,
): DebtsAdditionalInformationState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setUpdateDebtsInformation': {
      newState.debts = action.payload.data;

      if (action.payload.debtCode === undefined) {
        return newState;
      }
      const result = newState.debts.map(item => {
        if (item.code === action.payload.debtCode) {
          const loan = { ...item };
          loan.isDataOk = checkIfDataIsOk(item);

          return loan;
        }
        return item;
      });

      newState.debts = result;
      return newState;
    }
    default:
      return newState;
  }
};
