import { SelectElementInput } from 'components/designSystem/SelectElementInput';
import { SelectedButton } from 'components/designSystem/SelectButton';
import ToggleButton from 'components/toggleButton/ToggleButton';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { DateInput } from 'components/designSystem/DateInput';
import CountryInput from 'components/countryInput/CountryInput';
import { Civility, IdentityAction, IdentityState } from 'reducers/identity/types';
import { ComboInputDS } from 'components/designSystem/ComboInput';
import {
  Department,
  City,
  Country,
  Nationality,
  StatusInput,
  StatusControlInput,
  CodeLibelle,
} from 'types';
import Space from 'components/space/Space';
import BirthDepartment from 'components/birthDepartment/BirthDepartment';
import CityInput from 'components/cityInput/CityInput';
import {
  ColumnFlexContainer,
  PlainText,
  StyledComboInput,
} from 'containers/communs/style';
import useInputValidation from 'utils/useInputValidation';
import { getCitiesByDepartment } from 'api/referentialService';
import {
  checkFormatDate,
  getAge,
  getDateFromInput,
  isAdult,
  isDateIntheFuture,
} from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import CustomInput from 'components/customInput/CustomInput';
import { StyledErrorMessage } from 'components/customInput/style';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface BorrowerIdentityFormProps {
  isFormOk: (val: boolean) => void;
  countryList: Country[];
  departmentList: Department[];
  borrowerState: IdentityState;
  checkInputs: boolean;
  borrowerReducerDispatch: React.Dispatch<IdentityAction>;
  nationalityList: Nationality[];
}
const BorrowerIdentityForm = forwardRef((props: BorrowerIdentityFormProps, ref) => {
  const [cityList, setCityList] = useState<City[]>([]);
  const [showCities, setShowCities] = useState<boolean>();

  const [isLastNameInputFocused, setIsLastNameInputFocused] = useState<boolean>(false);
  const [statusLastName, setStatusLastName] = useState<StatusControlInput>();
  const [isBirthNameInputFocused, setIsBirthNameInputFocused] = useState<boolean>(false);
  const [statusBirthName, setStatusBirthName] = useState<StatusControlInput>();
  const [isFirstNameInputFocused, setIsFirstNameInputFocused] = useState<boolean>(false);
  const [statusFirstName, setStatusFirstName] = useState<StatusControlInput>();
  const [statusBirthDate, setStatusBirthDate] = useState<StatusInput>();
  const [reloadStatusBirthDate, setReloadStatusBirthDate] = useState<boolean>(false);
  const [statusBirthCountry, setStatusBirthCountry] = useState<StatusInput>();
  const [statusBirthDepartment, setStatusBirthDepartment] = useState<StatusInput>();
  const [statusBirthCity, setStatusBirthCity] = useState<StatusInput>();
  const [isBirthCityOtherInputFocused, setIsBirthCityOtherInputFocused] =
    useState<boolean>(false);
  const [statusBirthCityOther, setStatusBirthCityOther] = useState<StatusControlInput>();
  const [statusNationality, setStatusNationality] = useState<StatusInput>();

  const nationalityRef = useRef(null);
  const birthCountryRef = useRef(null);
  const departmentRef = useRef(null);
  const cityRef = useRef(null);

  const noNumbers = new RegExp(ValidationRules.noNumbers);
  const noSpecialCharacters = new RegExp(ValidationRules.noSpecialCharacters);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  useInputValidation('borrowerIdentity', 'show-input-ds-validation', [
    cityList,
    props.departmentList,
  ]);

  const checkStatusLastName = () => {
    if (
      props.borrowerState.lastName === '' ||
      props.borrowerState.lastName === undefined
    ) {
      setStatusLastName({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }

    if (!noNumbers.test(props.borrowerState.lastName)) {
      setStatusLastName({
        status: false,
        errorMessage: messagesCheckInput.NO_NUMBER,
      });
      return;
    }

    if (!noSpecialCharacters.test(props.borrowerState.lastName)) {
      setStatusLastName({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
      return;
    }

    props.borrowerReducerDispatch({
      type: 'setLastName',
      payload: props.borrowerState.lastName.toUpperCase().trim(),
    });
    setStatusLastName({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusBirthName = () => {
    if (
      props.borrowerState.birthName === '' ||
      props.borrowerState.birthName === undefined
    ) {
      setStatusBirthName({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }

    if (!noNumbers.test(props.borrowerState.birthName)) {
      setStatusBirthName({
        status: false,
        errorMessage: messagesCheckInput.NO_NUMBER,
      });
      return;
    }

    if (!noSpecialCharacters.test(props.borrowerState.birthName)) {
      setStatusBirthName({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
      return;
    }

    props.borrowerReducerDispatch({
      type: 'setBirthName',
      payload: props.borrowerState.birthName.toUpperCase().trim(),
    });
    setStatusBirthName({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusFirstName = () => {
    if (
      props.borrowerState.firstName === '' ||
      props.borrowerState.firstName === undefined
    ) {
      setStatusFirstName({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }

    if (!noNumbers.test(props.borrowerState.firstName)) {
      setStatusFirstName({
        status: false,
        errorMessage: messagesCheckInput.NO_NUMBER,
      });
      return;
    }

    if (!noSpecialCharacters.test(props.borrowerState.firstName)) {
      setStatusFirstName({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
      return;
    }

    props.borrowerReducerDispatch({
      type: 'setFirstName',
      payload: props.borrowerState.firstName.toUpperCase().trim(),
    });
    setStatusFirstName({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusBirthDate = () => {
    if (reloadStatusBirthDate === true) {
      setReloadStatusBirthDate(false);
      if (
        props.borrowerState.birthDate === '' ||
        props.borrowerState.birthDate === undefined
      ) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
        return;
      }
      const birthDateString = props.borrowerState.birthDate as string;
      const birthDate = getDateFromInput(birthDateString);

      if (
        props.borrowerState.birthDate &&
        !checkFormatDate(props.borrowerState.birthDate)
      ) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_FORMAT,
        });
        return;
      }
      if (!isDateIntheFuture(getDateFromInput(`${props.borrowerState.birthDate}`))) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_FUTUR,
        });
        return;
      }
      if (!isAdult(birthDate) || birthDate.getFullYear() < 1901) {
        setStatusBirthDate({
          status: 'invalid',
          errorMessage: messagesCheckInput.DATE_TOO_YOUNG_OR_OLD,
        });
        return;
      }
      setStatusBirthDate({
        status: 'valid',
        errorMessage: ``,
      });
    }
  };

  const checkStatusBirthCountry = () => {
    if (
      birthCountryRef.current &&
      birthCountryRef.current !== null &&
      props.borrowerState.birthCountry
    ) {
      const countryMatchList = props.countryList?.find(country => {
        return country.libelle === props.borrowerState.birthCountry?.libelle;
      });
      if (countryMatchList) {
        props.borrowerReducerDispatch({
          type: 'setBirthCountry',
          payload: countryMatchList,
        });
        setStatusBirthCountry({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        props.borrowerReducerDispatch({
          type: 'setBirthCountry',
          payload: {
            code: 'err',
            libelle: props.borrowerState.birthCountry.libelle
              ? props.borrowerState.birthCountry.libelle
              : '',
          },
        });
        setStatusBirthCountry({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!props.borrowerState.birthCountry)
      setStatusBirthCountry({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  const checkStatusBirthDepartment = () => {
    if (
      departmentRef.current &&
      departmentRef.current !== null &&
      props.borrowerState.birthDepartment
    ) {
      const departmentMatchList = props.departmentList?.find(department => {
        return (
          department.libelle.toUpperCase() ===
          props.borrowerState.birthDepartment?.libelle.toUpperCase()
        );
      });
      if (departmentMatchList) {
        props.borrowerReducerDispatch({
          type: 'setBirthDepartment',
          payload: departmentMatchList,
        });
        setStatusBirthDepartment({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        props.borrowerReducerDispatch({
          type: 'setBirthDepartment',
          payload: {
            code: 'err',
            libelle: props.borrowerState.birthDepartment.libelle
              ? props.borrowerState.birthDepartment.libelle
              : '',
          },
        });
        setStatusBirthDepartment({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!props.borrowerState.birthDepartment)
      setStatusBirthDepartment({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  const checkStatusBirthCity = (cityByZipCodeList: CodeLibelle[]) => {
    if (cityRef.current && cityRef.current !== null && props.borrowerState.birthCity) {
      const cityMatchList = cityByZipCodeList?.find(city => {
        return (
          city.libelle.toUpperCase() ===
          props.borrowerState.birthCity?.libelle.toUpperCase()
        );
      });
      if (cityMatchList) {
        props.borrowerReducerDispatch({
          type: 'setBirthCity',
          payload: cityMatchList,
        });
        setStatusBirthCity({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        props.borrowerReducerDispatch({
          type: 'setBirthCity',
          payload: {
            code: 'err',
            libelle: props.borrowerState.birthCity.libelle
              ? props.borrowerState.birthCity.libelle
              : '',
          },
        });
        setStatusBirthCity({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!props.borrowerState.birthCity)
      setStatusBirthCity({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  const checkStatusBirthCityOther = () => {
    if (props.borrowerState.birthCity) {
      if (notEmpty.test(props.borrowerState.birthCity.libelle)) {
        props.borrowerReducerDispatch({
          type: 'setBirthCity',
          payload: {
            code: '99',
            libelle: props.borrowerState.birthCity.libelle.slice(0, 25),
          },
        });
        setStatusBirthCityOther({
          status: true,
          errorMessage: ``,
        });
        return;
      }
      props.borrowerReducerDispatch({
        type: 'setBirthCity',
        payload: {
          code: 'err',
          libelle: '',
        },
      });
      setStatusBirthCityOther({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    }
    setStatusBirthCityOther({
      status: false,
      errorMessage: messagesCheckInput.REQUIRED_VALUE,
    });
  };

  const checkStatusNationality = () => {
    if (
      nationalityRef.current &&
      nationalityRef.current !== null &&
      props.borrowerState.nationality
    ) {
      const nationalityMatchList = props.nationalityList?.find(nationality => {
        return (
          nationality.libelle.toUpperCase() ===
          props.borrowerState.nationality?.libelle.toUpperCase()
        );
      });
      if (nationalityMatchList) {
        props.borrowerReducerDispatch({
          type: 'setNationality',
          payload: nationalityMatchList,
        });
        setStatusNationality({
          status: 'valid',
          errorMessage: ``,
        });
      } else {
        props.borrowerReducerDispatch({
          type: 'setNationality',
          payload: {
            code: 'err',
            libelle: props.borrowerState.nationality.libelle
              ? props.borrowerState.nationality.libelle
              : '',
          },
        });
        setStatusNationality({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!props.borrowerState.nationality)
      setStatusNationality({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
  };

  useEffect(() => {
    if (props.borrowerState.lastName !== '') {
      checkStatusLastName();
    }
    if (props.borrowerState.birthName !== '') {
      checkStatusBirthName();
    }
    if (props.borrowerState.firstName !== '') {
      checkStatusFirstName();
    }
    if (
      props.borrowerState.birthDate !== '' &&
      props.borrowerState.birthDate !== undefined
    ) {
      setReloadStatusBirthDate(true);
    }
    if (props.borrowerState.birthCountry) {
      checkStatusBirthCountry();
    }
    if (!showCities) {
      if (props.borrowerState.birthCity) {
        checkStatusBirthCityOther();
      }
    }
  }, []);

  useEffect(() => {
    if (props.checkInputs) {
      checkStatusLastName();
      if (!props.borrowerState.isBirthNameSameAsName) checkStatusBirthName();
      checkStatusFirstName();
      setReloadStatusBirthDate(true);
      checkStatusBirthCountry();
      if (props.departmentList.length !== 0) checkStatusBirthDepartment();
      if (cityList.length !== 0) checkStatusBirthCity(cityList);
      if (!showCities) {
        checkStatusBirthCityOther();
      }
      if (props.nationalityList.length !== 0) checkStatusNationality();
    }
  }, [props.checkInputs]);

  useEffect(() => {
    if (props.departmentList.length !== 0) {
      if (props.borrowerState.birthDepartment) {
        checkStatusBirthDepartment();
      }
    }
  }, [props.departmentList]);

  useEffect(() => {
    if (props.nationalityList.length !== 0) {
      if (props.borrowerState.nationality) {
        checkStatusNationality();
      }
    }
  }, [props.nationalityList]);

  useEffect(() => {
    if (
      props.borrowerState.birthDepartment?.code === '99' ||
      props.borrowerState.birthDepartment?.code === '00'
    ) {
      setShowCities(false);
    } else if (
      props.borrowerState.birthDepartment?.code &&
      props.borrowerState.birthDepartment?.code.length > 0 &&
      props.borrowerState.birthDepartment?.code !== 'err'
    ) {
      setShowCities(true);
      if (props.borrowerState.birthDepartment?.code === '98') {
        setCityList([{ code: '98000', libelle: 'Monaco' }]);
      } else {
        getCitiesByDepartment(props.borrowerState.birthDepartment?.code).then(
          cityByZipCodeList => {
            setStatusBirthCity({
              status: 'none',
              errorMessage: ``,
            });
            if (
              (props.borrowerState.birthCity &&
                props.borrowerState.birthCity.libelle !== '') ||
              props.checkInputs
            )
              checkStatusBirthCity(cityByZipCodeList);
            setCityList(cityByZipCodeList);
          },
        );
      }
    } else if (props.borrowerState.birthCity) {
      setShowCities(true);
      setCityList([props.borrowerState.birthCity]);
    } else {
      setShowCities(false);
      setCityList([]);
    }
  }, [showCities, props.borrowerState.birthDepartment?.code]);

  useEffect(() => {
    if (props.borrowerState.isDataOk) {
      props.isFormOk(true);
    } else {
      props.isFormOk(false);
    }
  }, [props.borrowerState]);

  useEffect(() => {
    checkStatusBirthDate();
  }, [reloadStatusBirthDate]);

  const onBirthCountryBlur = (event: Event) => {
    if (birthCountryRef.current && birthCountryRef.current !== null) {
      if (event && event.target) {
        const countryMatchList = props.countryList?.find(country => {
          return (
            country.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (countryMatchList) {
          props.borrowerReducerDispatch({
            type: 'setBirthCountry',
            payload: countryMatchList,
          });
          if (countryMatchList.code === 'FR') {
            props.borrowerReducerDispatch({
              type: 'setBirthDepartment',
              payload: undefined,
            });
          } else {
            props.borrowerReducerDispatch({
              type: 'setBirthDepartment',
              payload: { code: '99', libelle: '' },
            });
            setCityList([]);
            setShowCities(false);
          }
          props.borrowerReducerDispatch({
            type: 'setBirthCity',
            payload: undefined,
          });
        } else {
          props.borrowerReducerDispatch({
            type: 'setBirthCountry',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
          props.borrowerReducerDispatch({
            type: 'setBirthCity',
            payload: undefined,
          });
          props.borrowerReducerDispatch({
            type: 'setBirthDepartment',
            payload: { code: '00', libelle: '' },
          });
          setCityList([]);
          setShowCities(false);
        }
      }
    }
  };

  const onDepartmentBlur = (event: Event) => {
    if (departmentRef.current && departmentRef.current !== null) {
      if (event && event.target) {
        const departmentMatchList = props.departmentList?.find(department => {
          return (
            department.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (!departmentMatchList) {
          props.borrowerReducerDispatch({
            type: 'setBirthDepartment',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
          setShowCities(false);
        } else {
          props.borrowerReducerDispatch({
            type: 'setBirthDepartment',
            payload: departmentMatchList,
          });
        }
      }
    }
  };

  const onCityBlur = (event: Event) => {
    if (cityRef.current && cityRef.current !== null) {
      if (event && event.target) {
        const cityMatchesListElement = cityList?.find(city => {
          return (
            city.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (cityMatchesListElement) {
          props.borrowerReducerDispatch({
            type: 'setBirthCity',
            payload: cityMatchesListElement,
          });
        } else {
          props.borrowerReducerDispatch({
            type: 'setBirthCity',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
        }
      }
    }
  };

  const onNationalityBlur = (event: Event) => {
    if (nationalityRef.current && nationalityRef.current !== null) {
      if (event && event.target) {
        const nationalityMatchList = props.nationalityList?.find(nationality => {
          return (
            nationality.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (!nationalityMatchList) {
          props.borrowerReducerDispatch({
            type: 'setNationality',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
        } else {
          props.borrowerReducerDispatch({
            type: 'setNationality',
            payload: nationalityMatchList,
          });
        }
      }
    }
  };

  const onBirthCountrySelect = (e: Event) => {
    const selectedBirthCountry = (e as CustomEvent).detail.value as Country;
    props.borrowerReducerDispatch({
      type: 'setBirthCountry',
      payload: selectedBirthCountry,
    });
    // dés que la selection du pays change, on réinitialise les champs département et ville
    if (selectedBirthCountry.code === 'FR') {
      props.borrowerReducerDispatch({
        type: 'setBirthDepartment',
        payload: undefined,
      });
    } else {
      props.borrowerReducerDispatch({
        type: 'setBirthDepartment',
        payload: { code: '99', libelle: '' },
      });
      props.borrowerReducerDispatch({
        type: 'setBirthCity',
        payload: undefined,
      });
      setCityList([]);
      setShowCities(false);
    }
    props.borrowerReducerDispatch({
      type: 'setBirthCity',
      payload: undefined,
    });
    // props.handleBirthCountrySelect(selectedBirthCountry.code);
  };

  const onDepartmentSelect = (e: Event) => {
    const selectedBirthDepartment = (e as CustomEvent).detail.value as Department;
    props.borrowerReducerDispatch({
      type: 'setBirthDepartment',
      payload: selectedBirthDepartment,
    });
    props.borrowerReducerDispatch({
      type: 'setBirthCity',
      payload: undefined,
    });
  };

  const onCitySelect = (e: Event) => {
    props.borrowerReducerDispatch({
      type: 'setBirthCity',
      payload: (e as CustomEvent).detail.value as City,
    });
  };

  const onNationalitySelect = (e: Event) => {
    const selectedNationality = (e as CustomEvent).detail.value as Country;
    props.borrowerReducerDispatch({
      type: 'setNationality',
      payload: selectedNationality,
    });
  };

  const onBirthDateBlur = () => {
    setStatusBirthDate({
      status: 'none',
      errorMessage: '',
    });
    setReloadStatusBirthDate(true);
  };

  return (
    <>
      <div className="element-accessible-keyboard">
        <SelectElementInput
          class="identity-selected-button identity-form-input"
          label="Civilité"
          data-id="civility"
          value={props.borrowerState.civility}
          onSelectedElementChange={e => {
            props.borrowerReducerDispatch({
              type: 'setCivility',
              payload: (e.target as HTMLSelectElement).value as Civility,
            });
          }}
          error-message={messagesCheckInput.REQUIRED_VALUE}>
          <SelectedButton
            tabIndex={0}
            onKeyDownCapture={e => {
              if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                e.preventDefault();
                props.borrowerReducerDispatch({
                  type: 'setCivility',
                  payload: (e.target as HTMLSelectElement).value as Civility,
                });
              }
            }}
            value="M"
            secondary
            className="civility-select">
            Monsieur
          </SelectedButton>
          <SelectedButton
            tabIndex={0}
            onKeyDownCapture={e => {
              if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                e.preventDefault();
                props.borrowerReducerDispatch({
                  type: 'setCivility',
                  payload: (e.target as HTMLSelectElement).value as Civility,
                });
              }
            }}
            value="MME"
            secondary>
            Madame
          </SelectedButton>
          {!props.borrowerState.civility && props.checkInputs ? (
            <>
              <StyledErrorMessage style={{ fontSize: '1.4rem' }}>
                {messagesCheckInput.REQUIRED_VALUE}
              </StyledErrorMessage>
            </>
          ) : (
            <></>
          )}
        </SelectElementInput>
      </div>

      <CustomInput
        name="last-name"
        label="Nom"
        placeholder="Nom"
        onChange={newValue => {
          setIsLastNameInputFocused(true);
          props.borrowerReducerDispatch({
            type: 'setLastName',
            payload: newValue.toUpperCase().slice(0, 25),
          });
        }}
        onBlur={checkStatusLastName}
        onFocus={() => {
          setIsLastNameInputFocused(true);
        }}
        isValid={statusLastName?.status}
        isFocused={isLastNameInputFocused}
        inputWidth="50rem"
        value={props.borrowerState.lastName}
        error={statusLastName?.errorMessage}
      />

      <Space marginBottom="8px" />

      <ToggleButton
        id="nameEqualsBirthName"
        isOn={props.borrowerState.isBirthNameSameAsName}
        handleToggle={() => {
          props.borrowerReducerDispatch({
            type: 'setIsBirthNameSameAsName',
            payload: !props.borrowerState.isBirthNameSameAsName,
          });
        }}>
        <div
          style={{
            marginRight: '0.8rem',
            marginTop: '0.3rem',
          }}>
          <PlainText>Le nom est identique au nom de naissance</PlainText>
        </div>
      </ToggleButton>

      <Space marginBottom="2.4rem" />

      {!props.borrowerState.isBirthNameSameAsName ? (
        <>
          <CustomInput
            name="birth-name"
            label="Nom de naissance"
            placeholder="Nom de naissance"
            onChange={newValue => {
              setIsBirthNameInputFocused(true);
              props.borrowerReducerDispatch({
                type: 'setBirthName',
                payload: newValue.toUpperCase().slice(0, 25),
              });
            }}
            onBlur={checkStatusBirthName}
            onFocus={() => {
              setIsBirthNameInputFocused(true);
            }}
            isValid={statusBirthName?.status}
            isFocused={isBirthNameInputFocused}
            inputWidth="50rem"
            value={props.borrowerState.birthName}
            error={statusBirthName?.errorMessage}
          />
          <Space marginBottom="2.4rem" />
        </>
      ) : (
        <></>
      )}

      <CustomInput
        name="first-name"
        label="Prénom"
        placeholder="Prénom"
        onChange={newValue => {
          setIsFirstNameInputFocused(true);
          props.borrowerReducerDispatch({
            type: 'setFirstName',
            payload: newValue.toUpperCase().slice(0, 15),
          });
        }}
        onBlur={checkStatusFirstName}
        onFocus={() => {
          setIsFirstNameInputFocused(true);
        }}
        isValid={statusFirstName?.status}
        isFocused={isFirstNameInputFocused}
        inputWidth="50rem"
        value={props.borrowerState.firstName}
        error={statusFirstName?.errorMessage}
      />
      <Space marginBottom="2.4rem" />
      <ColumnFlexContainer>
        <DateInput
          class="show-input-ds-validation"
          label="Date de naissance"
          onBlur={onBirthDateBlur}
          onInputChange={e => {
            props.borrowerReducerDispatch({
              type: 'setBirthDate',
              payload: (e.target as HTMLInputElement).value,
            });
          }}
          value={props.borrowerState.birthDate}
          {...(statusBirthDate?.status !== 'none'
            ? { status: statusBirthDate?.status }
            : {})}
          {...(statusBirthDate?.errorMessage !== ''
            ? { 'error-message': statusBirthDate?.errorMessage }
            : {})}
        />

        {props.borrowerState.birthDate &&
        statusBirthDate?.status === 'valid' &&
        getAge(getDateFromInput(props.borrowerState.birthDate)) > 17 ? (
          <div style={{ marginTop: '0.8rem' }}>
            <PlainText>
              {getAge(getDateFromInput(props.borrowerState.birthDate))} ans
            </PlainText>
          </div>
        ) : (
          <></>
        )}
      </ColumnFlexContainer>

      <Space marginBottom="24px" />

      <StyledComboInput>
        <CountryInput
          countryRef={birthCountryRef}
          label="Pays de naissance"
          defaultValue={props.borrowerState.birthCountry?.libelle || ''}
          countries={props.countryList}
          onCountryBlur={onBirthCountryBlur}
          onCountrySelect={onBirthCountrySelect}
          className="show-input-ds-validation"
          status={statusBirthCountry?.status}
          errorMessage={statusBirthCountry?.errorMessage}
        />
      </StyledComboInput>
      <Space marginBottom="2.4rem" />
      {props.borrowerState.birthCountry?.code === 'FR' ? (
        <>
          <StyledComboInput>
            <BirthDepartment
              defaultValue={
                props.borrowerState.birthDepartment
                  ? props.borrowerState.birthDepartment.libelle
                  : ''
              }
              departmentRef={departmentRef}
              departments={props.departmentList}
              onDepartmentBlur={onDepartmentBlur}
              onDepartmentSelect={onDepartmentSelect}
              className="show-input-ds-validation"
              status={statusBirthDepartment?.status}
              errorMessage={statusBirthDepartment?.errorMessage}
            />
          </StyledComboInput>
          <Space marginBottom="2.4rem" />
        </>
      ) : null}
      {showCities ? (
        <CityInput
          label="Ville de naissance"
          cityRef={cityRef}
          defaultValue={props.borrowerState.birthCity?.libelle || ''}
          cities={cityList}
          onCitySelect={onCitySelect}
          onCityBlur={onCityBlur}
          className="show-input-ds-validation"
          status={statusBirthCity?.status}
          errorMessage={statusBirthCity?.errorMessage}
        />
      ) : (
        <CustomInput
          name="city-birth"
          label="Ville de naissance"
          onChange={newValue => {
            props.borrowerReducerDispatch({
              type: 'setBirthCity',
              payload: { code: '99', libelle: newValue.slice(0, 25) },
            });
            setIsBirthCityOtherInputFocused(true);
          }}
          onBlur={checkStatusBirthCityOther}
          onFocus={() => {
            setIsBirthCityOtherInputFocused(true);
          }}
          isValid={statusBirthCityOther?.status}
          isFocused={isBirthCityOtherInputFocused}
          inputWidth="50rem"
          value={props.borrowerState.birthCity?.libelle || ''}
          error={statusBirthCityOther?.errorMessage}
        />
      )}
      <Space marginBottom="2.4rem" />
      <StyledComboInput>
        <CountryInput
          countryRef={nationalityRef}
          label="Nationalité"
          defaultValue={props.borrowerState.nationality?.libelle || ''}
          countries={props.nationalityList}
          onCountryBlur={onNationalityBlur}
          onCountrySelect={onNationalitySelect}
          className="show-input-ds-validation"
          placeholder="ex: Française"
          status={statusNationality?.status}
          errorMessage={statusNationality?.errorMessage}
        />
      </StyledComboInput>
    </>
  );
});

BorrowerIdentityForm.displayName = 'Borrower-Identity';

export default BorrowerIdentityForm;
