import styled from 'styled-components';

export const StyledResultCard = styled.button<{ color?: string }>`
  cursor: pointer;
  border: hidden;
  display: flex;
  height: 7.2rem;
  gap: 2rem;
  border-radius: 8px;
  background-color: ${props => props.color || '#ffffff'};
  justify-content: space-between;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
`;

export const StyledResultCardTile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledResultCardLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledResultCardData = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledResultCardTreatmentTag = styled.div<{ isGreen: boolean }>`
  display: flex;
  height: fit-content;
  width: fit-content;
  border-radius: 8px;
  background-color: ${props => (props.isGreen ? '#DEF1DB' : '#ffefd6')};
  padding: 0.2rem 1.6rem;
`;

export const StyledResultCardWithInsuranceTag = styled.div`
  height: fit-content;
  border-radius: 8px;
  background-color: #dcdee0;
  padding: 0.3rem 1.6rem;
`;

export const StyledResultCardWithoutInsuranceTag = styled.div`
  height: fit-content;
  box-sizing: border-box;
  border: 1px solid #b1b5b9;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 0.3rem 1.6rem;
`;

export const StyledResultCardCategoryTitle = styled.div`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
`;
