import { Partner, SortEnum } from 'types';
import {
  filterPartnerList,
  handleAllPartnerCheckboxChange,
  sortPartners,
  updateFilters,
} from './utils';

export const partnerReducerInitialState = {
  partnersCurrentOrdering: [],
  displayedPartners: [],
  nbPartnersPerPage: 10,
  sortPartnersByName: SortEnum.ASC,
  filterPartnersByName: '',
  sortPartnersByNumber: SortEnum.ASC,
  filterPartnersByNumber: '',
  sortPartnersById: SortEnum.ASC,
  filterPartnersById: '',
  idsOfCheckedParterns: [],
  isAllPartnersChecked: true,
  partnerCurrentSortingKey: undefined,
};

export interface PartnerState {
  partnersCurrentOrdering: Partner[];
  displayedPartners: Partner[];
  nbPartnersPerPage: number;
  sortPartnersByName: SortEnum;
  filterPartnersByName: string;
  sortPartnersByNumber: SortEnum;
  filterPartnersByNumber: string;
  sortPartnersById: SortEnum;
  filterPartnersById: string;
  idsOfCheckedParterns: string[];
  isAllPartnersChecked: boolean;
  partnerCurrentSortingKey?: PartnerKeys;
}

interface SetPartnersCurrentOrdering {
  type: 'setPartnersCurrentOrdering';
  payload: Partner[];
}
interface SetDisplayedPartners {
  type: 'setDisplayedPartners';
  payload: Partner[];
}
interface SetNbPartnersPerPage {
  type: 'setNbPartnersPerPage';
  payload: number;
}
interface SetSortOrderByName {
  type: 'setSortOrderByName';
  payload: SortEnum;
}
interface SetFilterPartnersByName {
  type: 'setFilterPartnersByName';
  payload: string;
}
interface SetSortOrderByNumber {
  type: 'setSortOrderByNumber';
  payload: SortEnum;
}
interface SetFilterPartnersByNumber {
  type: 'setFilterPartnersByNumber';
  payload: string;
}
interface SetSortOrderById {
  type: 'setSortOrderById';
  payload: SortEnum;
}
interface SetFilterPartnersById {
  type: 'setFilterPartnersById';
  payload: string;
}
interface SetIdsOfCheckedParterns {
  type: 'setIdsOfCheckedParterns';
  payload: string[];
}
interface SetIsAllPartnersChecked {
  type: 'setIsAllPartnersChecked';
  payload: boolean;
}
interface SetPartnerCurrentSortingKey {
  type: 'setPartnerCurrentSortingKey';
  payload: PartnerKeys;
}
interface SetInitialPartnerList {
  type: 'setInitialPartnerList';
  payload: Partner[];
}
interface FilterPartnersList {
  type: 'filterPartnersList';
}
interface ResetPartnerState {
  type: 'resetPartnerState';
}

interface HandleAllAPACheckboxChange {
  type: 'handleAllAPACheckboxChange';
}

export type PartnerKeys = 'idIntv' | 'numeroVendeur' | 'nomCourtier';
export interface UpdateFiltersPayload {
  text: string;
  filteringKey: PartnerKeys;
}
interface UpdateFilters {
  type: 'updateFilters';
  payload: UpdateFiltersPayload;
}
interface SortDisplayedPartners {
  type: 'sortDisplayedPartners';
  payload: PartnerKeys;
}
type PartnerAction =
  | SetPartnersCurrentOrdering
  | SetDisplayedPartners
  | SetNbPartnersPerPage
  | SetSortOrderByName
  | SetFilterPartnersByName
  | SetSortOrderByNumber
  | SetFilterPartnersByNumber
  | SetSortOrderById
  | SetFilterPartnersById
  | SetIdsOfCheckedParterns
  | SetIsAllPartnersChecked
  | SetPartnerCurrentSortingKey
  | SetInitialPartnerList
  | FilterPartnersList
  | ResetPartnerState
  | UpdateFilters
  | HandleAllAPACheckboxChange
  | SortDisplayedPartners;

export const partnerReducer = (
  state: PartnerState,
  action: PartnerAction,
): PartnerState => {
  switch (action.type) {
    case 'setPartnersCurrentOrdering':
      return {
        ...state,
        partnersCurrentOrdering: action.payload,
      };
    case 'setDisplayedPartners':
      return {
        ...state,
        displayedPartners: action.payload,
      };
    case 'setNbPartnersPerPage':
      return {
        ...state,
        nbPartnersPerPage: action.payload,
      };
    case 'setSortOrderByName':
      return {
        ...state,
        sortPartnersByName: action.payload,
      };
    case 'setFilterPartnersByName':
      return {
        ...state,
        filterPartnersByName: action.payload,
      };
    case 'setSortOrderByNumber':
      return {
        ...state,
        sortPartnersByNumber: action.payload,
      };
    case 'setFilterPartnersByNumber':
      return {
        ...state,
        filterPartnersByNumber: action.payload,
      };
    case 'setSortOrderById':
      return {
        ...state,
        sortPartnersById: action.payload,
      };
    case 'setFilterPartnersById':
      return {
        ...state,
        filterPartnersById: action.payload,
      };
    case 'setIdsOfCheckedParterns':
      return {
        ...state,
        idsOfCheckedParterns: action.payload,
      };
    case 'setIsAllPartnersChecked':
      return {
        ...state,
        isAllPartnersChecked: action.payload,
      };
    case 'setPartnerCurrentSortingKey':
      return {
        ...state,
        partnerCurrentSortingKey: action.payload,
      };
    case 'setInitialPartnerList':
      return {
        ...state,
        partnersCurrentOrdering: action.payload,
        displayedPartners: action.payload,
      };
    case 'filterPartnersList':
      return filterPartnerList(state);
    case 'resetPartnerState':
      return {
        ...partnerReducerInitialState,
      };
    case 'updateFilters':
      return updateFilters(state, action.payload);
    case 'handleAllAPACheckboxChange':
      return handleAllPartnerCheckboxChange(state);
    case 'sortDisplayedPartners':
      return sortPartners(state, action.payload);

    default:
      return state;
  }
};
