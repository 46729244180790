export const TITLE = 'Conditions financières';
export const SUB_TITLE =
  "Les informations sur les conditions financières vont nous permettre d'établir la faisabilité du projet.";
export const ASSURANCE_INFO_TEXT =
  "Il est de votre <b>devoir de conseiller</b> à votre client d'adhérer à la formule d'assurance la plus adaptée à son âge et à sa situation professionnelle.<br />Votre client conserve le choix d'adhérer à la formule qui lui convient, ou de ne pas adhérer à l'assurance facultative.";
export const NON_ASSURANCE_TEXT =
  'Nous vous invitons à informer le client des risques auxquels il s’expose à ne pas y souscrire. <br/><br/>En <b>cas de Décès</b>, les ayants droit (conjoint, enfants,...) du client devront rembourser le solde du crédit.<br/><br/>En <b>cas d’Invalidité, de Maladie - Accident, ou de Chômage</b> le client devra continuer à rembourser chaque mois son crédit.';
export const ASSURANCE_INDIVIDUELLE_TEXT =
  "Vous avez sélectionné l'offre Assurance facultative pour votre client.<br/>Ce choix le sécurise en cas d'imprévus.";
export const ITT_REQUIRED =
  "Vous devez cocher Garantie ITT/IPT pour pouvoir cocher la Garantie perte d'emploi";
export const PTIA_REQUIRED =
  'Vous devez cocher Garantie PTIA pour pouvoir cocher la Garantie ITT/IPT';
export const CHECKED_ZERO =
  'Vous avez coché une garantie dont la valeur est 0%, veuillez la décocher';
export const SIMULATION_REMAINING_WARNING_TITLE =
  'Il vous reste 1 tentative de simulation';
export const SIMULATION_REMAINING_WARNING_MESSAGE =
  'Vous avez bientôt atteint la limite du nombre de tentative de simulation (10 essais).';
