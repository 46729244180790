import styled from 'styled-components';

export const StyledChemiseWrapper = styled.div<{ hasError: boolean }>`
  box-sizing: border-box;
  border: ${({ hasError }) => `0.1rem solid ${hasError ? '#C8102E' : '#6f757c'}`};
  border-radius: 0.8rem;
  background-color: #ffffff;
  padding: 2.4rem 2.4rem 2.4rem 3rem;
  @media (min-width: 1400px) {
    min-width: 94.1rem;
  }
`;

export const StyledListPieceWrapper = styled.div`
  margin-left: 3rem;
  flex: 1;
  @media (min-width: 1400px) {
    min-width: 44.8rem;
  }
`;

export const StyledDocumentName = styled.p`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
`;

export const StyledErrorWrapper = styled.div`
  margin-top: 0.8rem;
  color: #c8102e;
  font-size: 14px;
`;

export const StyledHistoriqueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  color: #4b4f54;
  margin-top: 4.8rem;
  border-bottom: 0.1rem solid #c7cacd;
`;

export const StyledChemiseConsultationWrapper = styled.div<{ hasError: boolean }>`
  box-sizing: border-box;
  border: ${({ hasError }) => `0.1rem solid ${hasError ? '#C8102E' : '#6f757c'}`};
  border-radius: 0.8rem;
  background-color: #ffffff;
  padding: 2.4rem 2.4rem 2.4rem 3rem;
  @media (min-width: 1400px) {
    min-width: 94.1rem;
  }
`;

export const StyledBinIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
