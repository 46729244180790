import { isEmployedWorker, isOtherJob, isUnEmployed } from 'containers/communs/utils';
import { SituationProfessionnelleState } from './types';

export const saveOnlyGoodData = (
  situationProfessionelle: SituationProfessionnelleState,
): SituationProfessionnelleState => {
  const { borrower, hasCoBorrower, coBorrower, isDataOk } = situationProfessionelle;
  const sitProToSave: SituationProfessionnelleState = {
    borrower: {
      profession: borrower.profession,
      // si pas unEmployed, ajouter les autres infos.
      ...(!isUnEmployed(borrower.profession)
        ? {
            // si salarier ajouter le type de contrat
            ...(isEmployedWorker(borrower.profession) || isOtherJob(borrower.profession)
              ? { typeContrat: borrower.typeContrat }
              : { sirenNumber: borrower.sirenNumber }),
            currentJobOrSituationStartingDate: borrower.currentJobOrSituationStartingDate,
            employerCountry: borrower.employerCountry,
            ...(borrower.employerCountry?.code === 'FR'
              ? { apeNumber: borrower.apeNumber, adresse: borrower.adresse }
              : {}),
          }
        : {}),
    },
    hasCoBorrower,
    coBorrower: hasCoBorrower
      ? {
          profession: coBorrower.profession,
          // si pas unEmployed, ajouter les autres infos.
          ...(!isUnEmployed(coBorrower.profession)
            ? {
                // si salarier ajouter le type de contrat
                ...(isEmployedWorker(coBorrower.profession) ||
                isOtherJob(coBorrower.profession)
                  ? { typeContrat: coBorrower.typeContrat }
                  : { sirenNumber: coBorrower.sirenNumber }),
                currentJobOrSituationStartingDate:
                  coBorrower.currentJobOrSituationStartingDate,
                employerCountry: coBorrower.employerCountry,
                ...(coBorrower.employerCountry?.code === 'FR'
                  ? { apeNumber: coBorrower.apeNumber, adresse: coBorrower.adresse }
                  : {}),
              }
            : {}),
        }
      : {},
    isDataOk,
  };

  return sitProToSave;
};

export default saveOnlyGoodData;
