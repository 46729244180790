import { createComponent } from '@lit-labs/react';
import React from 'react';
import { DsStatus } from 'types';
import { CustomValidity, Replacement } from './DesignSystemTypes';

export class DateInputDS extends HTMLElement {
  required?: boolean;

  label?: string;

  class?: string;

  value: unknown;

  status?: DsStatus;

  date?: Date;

  replacement?: string | Replacement[];

  customValidityPattern?: string | CustomValidity[];
}

export const DateInput = createComponent(React, 'date-input', DateInputDS, {
  onInputChange: 'input-changed',
  onFocus: 'element-focused',
  onLetterChanged: 'letter-changed',
  onBlur: 'element-blured',
});
