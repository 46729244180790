import { ComboInput } from 'components/designSystem/ComboInput';
import { CustomValidity } from 'components/designSystem/DesignSystemTypes';
import { City } from 'types';
import { getNotEmpty } from 'utils/InputValidation';

interface CityInputProps {
  label: string;
  cityRef?: React.MutableRefObject<null>;
  cities: City[];
  onCitySelect: (event: Event) => void;
  onCityBlur?: (event: Event) => void;
  onCityFilter?: (event: Event) => void;
  defaultValue: string;
  className?: string;
  hasFocus?: boolean;
  customValidityPattern?: CustomValidity[];
  status?: 'none' | 'invalid' | 'valid';
  errorMessage?: string;
  valid?: boolean;
}
const CityInput: React.FC<CityInputProps> = ({
  label,
  cityRef,
  cities,
  onCitySelect,
  onCityBlur,
  onCityFilter,
  defaultValue,
  className,
  hasFocus,
  customValidityPattern,
  status,
  errorMessage,
  valid,
}) => {
  return (
    <ComboInput
      class={`combo-input ${className}`}
      ref={cityRef}
      list-on-open
      shadow
      align-items="left"
      items={JSON.stringify(cities)}
      field="libelle"
      label={label}
      placeholder="ex: Paris"
      onSelectedList={onCitySelect}
      onLetterChanged={onCityFilter}
      onListBlur={onCityBlur}
      value={defaultValue}
      hasFocus={hasFocus}
      valid={valid}
      {...(status !== 'none' ? { status } : {})}
      {...(errorMessage !== '' ? { 'error-message': errorMessage } : {})}
      customValidityPattern={JSON.stringify(customValidityPattern || getNotEmpty())}
    />
  );
};

export default CityInput;
