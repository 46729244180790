const SvgComponent: React.FC = () => {
  return (
    <svg
      width={24}
      height={24}
      style={{ verticalAlign: 'middle' }}
      xmlns="http://www.w3.org/2000/svg">
      <title>Ic/24px/Document/calendar24px</title>
      <path
        d="M15.765 2c.292 0 .53.236.53.526l-.001 1.368h2.294A2.405 2.405 0 0 1 21 6.293v13.31A2.405 2.405 0 0 1 18.588 22H5.412A2.405 2.405 0 0 1 3 19.602V6.292a2.405 2.405 0 0 1 2.412-2.397l2.293-.001V2.526c0-.29.238-.526.53-.526.293 0 .53.236.53.526l-.001 1.368h6.471V2.526c0-.29.237-.526.53-.526Zm4.176 8.631H4.058v8.971c0 .743.607 1.345 1.354 1.345h13.176c.747 0 1.353-.602 1.353-1.345v-8.971ZM7.705 4.947H5.412c-.747 0-1.353.603-1.353 1.345l-.001 3.286h15.883V6.292c0-.742-.606-1.345-1.353-1.345h-2.294v1.369c0 .29-.237.526-.53.526a.528.528 0 0 1-.529-.526V4.947H8.764v1.369c0 .29-.236.526-.529.526a.528.528 0 0 1-.53-.526V4.947Z"
        fill="#70AD47"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SvgComponent;
