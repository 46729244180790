import { CustomValidity } from 'components/designSystem/DesignSystemTypes';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

export const getMonthlyIncomesRegExpPatterns = (
  showAucunBtn: boolean,
): CustomValidity[] => {
  if (showAucunBtn)
    return [
      {
        regexp: ValidationRules.notEmpty,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      },
      {
        regexp: ValidationRules.onlyNumbers,
        errorMessage: messagesCheckInput.ONLY_NUMBERS,
      },
    ];
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: messagesCheckInput.REQUIRED_VALUE,
    },
    {
      regexp: ValidationRules.nonZeroNumbers,
      errorMessage: messagesCheckInput.NON_ZERO_NUMBERS,
    },
  ];
};

export const getOtherAmountsRegExpPatterns = (
  typeOfAmount: 'income' | 'charge',
): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: messagesCheckInput.REQUIRED_VALUE,
    },
    {
      regexp: ValidationRules.nonZeroNumbers,
      errorMessage: messagesCheckInput.NON_ZERO_NUMBERS,
    },
  ];
};

export const getBankAccountNameRegExpPatterns = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: messagesCheckInput.REQUIRED_VALUE,
    },
  ];
};

export const getOpenedSinceRegExpPatterns = (): CustomValidity[] => {
  return [
    {
      regexp: ValidationRules.notEmpty,
      errorMessage: messagesCheckInput.REQUIRED_VALUE,
    },
    {
      regexp: ValidationRules.nonZeroNumbers,
      errorMessage: messagesCheckInput.NON_ZERO_NUMBERS,
    },
    {
      regexp: ValidationRules.yearRegexp,
      errorMessage: messagesCheckInput.YEAR_FORMAT,
    },
  ];
};
