import { useState } from 'react';
import { StyledInput } from './style';

interface SearchInputProps {
  value?: string;
  setValue: (newValue: string) => void;
  placeholder: string;
  width?: string;
  style?: React.CSSProperties;
  icon?: React.ReactElement;
  startSearch?: () => void;
}

const SearchInput = ({
  value,
  setValue,
  placeholder,
  width,
  style,
  icon,
  startSearch,
}: SearchInputProps): React.ReactElement => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div style={{ position: 'relative' }}>
      {icon ? (
        <div style={{ position: 'absolute', top: '2.7rem', left: '2.6rem' }}>{icon}</div>
      ) : null}
      <StyledInput
        type="text"
        value={value}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        onChange={e => {
          setValue(e.target.value);
        }}
        onKeyDown={e => {
          if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            if (startSearch) startSearch();
          }
        }}
        placeholder={placeholder}
        style={{
          ...style,
          ...{
            width,
            paddingLeft: icon ? '6.2rem' : '1.6rem',
            border: isFocused || value ? 'solid' : 'hidden',
            borderColor: isFocused || value ? '#008859' : '#fff',
            borderWidth: isFocused || value ? '0.1rem' : '0',
          },
        }}
      />
    </div>
  );
};

SearchInput.defaultProps = {
  value: undefined,
  width: '38.9rem',
  style: {},
  icon: null,
  startSearch: undefined,
};

export default SearchInput;
