import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import { useEffect, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import { BaremesTarification } from 'types';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import checkPreviousInputValidation from 'utils/commun';
import { ValidationRules, checkPercentageValue } from 'utils/InputValidation';
import { addInInvalidInputs, removeFromInvalidInputs } from '../communs/utils';

interface BaremeProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  handleGetDefaulBaremeClick: () => BaremesTarification | undefined;
  checkInputs: boolean;
}

const Bareme: React.FC<BaremeProps> = ({
  state,
  dispatch,
  handleGetDefaulBaremeClick,
  checkInputs,
}) => {
  const [isNominalInterestRateInputFocused, setIsNominalInterestRateInputFocused] =
    useState<boolean>(false);
  const [isNominalInterestRateInputValid, setIsNominalInterestRateInputValid] = useState<
    boolean | undefined
  >(
    checkPreviousInputValidation(
      'conditionsFinancieres',
      state.nominalInterestRate?.toString() || '',
    ),
  );
  const [nominalInterestRateInputError, setNominalInterestRateInputError] =
    useState<string>('');
  const [baremeValue, setBaremeValue] = useState<string>(state.nominalInterestRate || '');

  useEffect(() => {
    setBaremeValue(state.nominalInterestRate || '');
  }, [state.nominalInterestRate]);

  const realNumbers = new RegExp(ValidationRules.realNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const handleBorrowingRateChange = (value: string) => {
    if (!realNumbers.test(value) || Number.isNaN(Number(value))) return;

    if (checkPercentageValue(value)) return;
    if (Number(value) < 0) {
      dispatch({
        type: 'setNominalInterestRate',
        payload: undefined,
      });
    } else {
      dispatch({
        type: 'setNominalInterestRate',
        payload: value.slice(0, 5),
      });
    }
  };

  const cleanInput = () => {
    setIsNominalInterestRateInputValid(true);
    setNominalInterestRateInputError('');
    removeFromInvalidInputs(state, dispatch, 'bareme');
  };

  const handleBorrowingRateBlur = () => {
    setIsNominalInterestRateInputFocused(false);
    if (!state.nominalInterestRate || !notEmpty.test(state.nominalInterestRate)) {
      setIsNominalInterestRateInputValid(false);
      setNominalInterestRateInputError(messagesCheckInput.REQUIRED_VALUE);
      addInInvalidInputs(state, dispatch, 'bareme');
    } else if (
      Number.isNaN(Number(state.nominalInterestRate.replaceAll(',', '.'))) ||
      Number(state.nominalInterestRate.replaceAll(',', '.')) < 0
    ) {
      setIsNominalInterestRateInputValid(false);
      setNominalInterestRateInputError(messagesCheckInput.INPUT_ERROR);
      addInInvalidInputs(state, dispatch, 'bareme');
    } else {
      cleanInput();
    }
  };

  /**
   * Get the default bareme
   */
  const handleOnClick = () => {
    const result = handleGetDefaulBaremeClick();
    if (result) {
      const tauxBareme = result.tauxBareme.toString();
      setBaremeValue(tauxBareme);
      dispatch({
        type: 'setNominalInterestRate',
        payload: tauxBareme,
      });

      cleanInput();
    } else {
      dispatch({
        type: 'setNominalInterestRate',
        payload: undefined,
      });
      setIsNominalInterestRateInputValid(false);
      setNominalInterestRateInputError(messagesCheckInput.BAREME_ERROR);
      addInInvalidInputs(state, dispatch, 'bareme');
    }
  };

  useEffect(() => {
    if (checkInputs) {
      handleBorrowingRateBlur();
    }
  }, [checkInputs]);

  return (
    <>
      <StyledTitle>Barème</StyledTitle>
      <Space marginTop="1.6rem" />
      <div style={{ display: 'flex', width: '46.4rem', gap: '1.6rem' }}>
        <CustomInput
          name="borrowing-rate"
          label="Taux d'intérêt nominal"
          value={baremeValue}
          onChange={newValue => {
            handleBorrowingRateChange(newValue.replaceAll(' ', '').replaceAll(',', '.'));
            setIsNominalInterestRateInputFocused(true);
          }}
          isValid={isNominalInterestRateInputValid}
          isFocused={isNominalInterestRateInputFocused}
          onBlur={handleBorrowingRateBlur}
          onFocus={() => {
            setIsNominalInterestRateInputFocused(true);
          }}
          after="%"
          inputWidth="17.2rem"
          placeholder="0"
          error={nominalInterestRateInputError}
        />
        <div style={{ marginTop: '3.2rem' }}>
          <StyledSecondaryButton width="27.6rem" onClick={handleOnClick}>
            <span style={{ fontSize: '1.4rem' }}>Appliquer le barème en vigueur</span>
          </StyledSecondaryButton>
        </div>
      </div>
    </>
  );
};

export default Bareme;
