import GenericIconProps from './GenericIcon';

const CirclePersonIcon: React.FC<GenericIconProps> = ({ width, color }) => {
  const baseWidth = 28;
  const baseHeight = 28;
  const baseColor = '#4B4F54';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>4297B214-25F0-469D-96DF-190BCB8B0A8A</title>
      <g
        id="Page-de-connexion"
        stroke="none"
        strokeWidth="1"
        fill={color || baseColor}
        fillRule="evenodd">
        <g
          id="Page-d'accueil-V2"
          transform="translate(-1364.000000, -597.000000)"
          fill={color || baseColor}>
          <g id="Connexion" transform="translate(722.402581, -6.000000)">
            <g
              transform="translate(431.597419, 360.000000)"
              id="Button/Primary/Green/L/Enabled">
              <g transform="translate(92.000000, 225.000000)">
                <g
                  id="Icon/User/person-circle/32px"
                  transform="translate(118.666667, 18.666667)">
                  <path
                    d="M13.3333334,0 C20.6971301,0 26.6666668,5.96953669 26.6666668,13.3333334 C26.6666668,20.6971301 20.6971301,26.6666668 13.3333334,26.6666668 C5.96953669,26.6666668 0,20.6971301 0,13.3333334 C0,5.96953669 5.96953669,0 13.3333334,0 Z M13.3333334,1.66666667 C8.84982244,1.66690538 4.76309789,4.23644385 2.81994017,8.27698943 C0.876782456,12.317535 1.42100817,17.1141655 4.22000002,20.6166668 C5.40333336,18.7100001 8.00833337,16.6666667 13.3333334,16.6666667 C18.6583334,16.6666667 21.2616668,18.7083334 22.4466668,20.6166668 C25.2456586,17.1141655 25.7898843,12.317535 23.8467266,8.27698943 C21.9035689,4.23644385 17.8168443,1.66690538 13.3333334,1.66666667 Z M13.3333334,5.00000002 C16.0947571,5.00000002 18.3333334,7.23857628 18.3333334,10 C18.3333334,12.7614238 16.0947571,15 13.3333334,15 C10.5719096,15 8.33333337,12.7614238 8.33333337,10 C8.33333337,7.23857628 10.5719096,5.00000002 13.3333334,5.00000002 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CirclePersonIcon;
