import { StyledInfo } from 'containers/conditionsFinancieres/style';
import WarningIcon from 'icons/WarningIcon';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessageArea: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <>
      <StyledInfo
        style={{ padding: '1.8rem', marginTop: '1.8rem', marginBottom: '1.8rem' }}>
        <WarningIcon />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '0.8rem',
          }}>
          {message}
        </div>
      </StyledInfo>
    </>
  );
};

export default ErrorMessageArea;
