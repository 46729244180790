const CheckCircleIcon: React.FC = () => (
  <svg width={14} height={14} xmlns="http://www.w3.org/2000/svg">
    <title>F7BF4039-F1AA-4E4A-9B7E-C9BF8877BDB5</title>
    <path
      d="M7 .333a6.667 6.667 0 1 1 0 13.334A6.667 6.667 0 0 1 7 .333Zm3.096 4.179a.5.5 0 0 0-.707 0l-3.056 3.69-1.496-1.496a.5.5 0 1 0-.707.707l1.85 1.85a.5.5 0 0 0 .707 0l3.41-4.044a.5.5 0 0 0-.001-.707Z"
      fill="#28883C"
      fillRule="evenodd"
    />
  </svg>
);

export default CheckCircleIcon;
