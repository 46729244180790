import { SessionStorageKeys } from 'types';
import { isUndefinedOrNull } from 'utils/commun';
import { getExistingStorageByKey } from 'utils/storage';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
  Insurance,
} from './types';

export const getConditionFinanciereInitialState = (
  storageKey: SessionStorageKeys,
): ConditionsFinancieresState => {
  const conditionFinanciereDefaultValues = {
    loanDuration: undefined,
    franchiseDuration: undefined,
    hasLoanOption: false,
    firstPalierDuration: undefined,
    firstPalierMonthlyPayment: '',
    nominalInterestRate: undefined,
    intermediationFeesRate: undefined,
    borrowerInsurance: {
      insurance: undefined,
      deathInsuranceRate: undefined,
      insuranceFranchiseDuration: undefined,
      coverageRate: undefined,
      monthlyEstimatedBonus: undefined,
      ptiaInsuranceChecked: undefined,
      ittIptInsuranceChecked: undefined,
      insuranceGracePeriod: undefined,
    },
    coBorrowerInsurance: {
      insurance: undefined,
      deathInsuranceRate: undefined,
      insuranceFranchiseDuration: undefined,
      coverageRate: undefined,
      monthlyEstimatedBonus: undefined,
      ptiaInsuranceChecked: undefined,
      ittIptInsuranceChecked: undefined,
      insuranceGracePeriod: undefined,
    },
    invalidInputs: [],
    isDataOk: false,
  };
  return getExistingStorageByKey(
    storageKey,
    conditionFinanciereDefaultValues,
  ) as ConditionsFinancieresState;
};

const isInsuranceOK = (val: Insurance): boolean => {
  switch (val.insurance?.code) {
    case 'I':
      if (isUndefinedOrNull(val.coverageRate)) {
        return false;
      }
      if (isUndefinedOrNull(val.monthlyEstimatedBonus)) {
        return false;
      }
      break;
    case 'GP':
      if (val.deathInsuranceChecked && isUndefinedOrNull(val.deathInsuranceRate)) {
        return false;
      }
      if (!val.insuranceGracePeriod) {
        return false;
      }
      break;
    case undefined:
      return false;
    default:
      return true;
  }
  return true;
};

const checkIfDatasAreOk = (state: ConditionsFinancieresState): boolean => {
  if (!state.invalidInputs || state.invalidInputs.length > 0) {
    return false;
  }
  if (!state.intermediationFeesRate) {
    return false;
  }

  if (isUndefinedOrNull(state.loanDuration)) {
    return false;
  }
  if (isUndefinedOrNull(state.franchiseDuration)) {
    return false;
  }
  if (state.hasLoanOption) {
    if (isUndefinedOrNull(state.firstPalierDuration)) {
      return false;
    }
    if (isUndefinedOrNull(state.firstPalierMonthlyPayment)) {
      return false;
    }
  }
  if (isUndefinedOrNull(state.nominalInterestRate)) {
    return false;
  }
  if (!state.borrowerInsurance.insurance) {
    return false;
  }
  if (!isInsuranceOK(state.borrowerInsurance)) {
    return false;
  }
  const coBorrower = sessionStorage.getItem('coBorrowerExists');
  if (coBorrower && JSON.parse(coBorrower) === true) {
    if (!state.coBorrowerInsurance.insurance) {
      return false;
    }
    if (!isInsuranceOK(state.coBorrowerInsurance)) {
      return false;
    }
  }
  return true;
};

export const conditionFinanciereReducer = (
  state: ConditionsFinancieresState,
  action: ConditionsFinancieresAction,
): ConditionsFinancieresState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setLoanDuration':
      newState.loanDuration = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setFranchiseDuration':
      newState.franchiseDuration = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasLoanOption':
      newState.hasLoanOption = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setFirstLevelDuration':
      newState.firstPalierDuration = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setFirstLevelMonthlyPayment':
      newState.firstPalierMonthlyPayment = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setNominalInterestRate':
      newState.nominalInterestRate = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setIntermediationFeesRate':
      newState.intermediationFeesRate = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setInsuranceType':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.insurance = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.insurance = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setDeathInsuranceRate':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.deathInsuranceRate = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.deathInsuranceRate = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setDeathInsuranceChecked':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.deathInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.deathInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setPtiaInsuranceChecked':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.ptiaInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.ptiaInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setIttIptInsuranceChecked':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.ittIptInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.ittIptInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setJobLossInsuranceChecked':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.jobLossInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.jobLossInsuranceChecked = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setInsuranceFranchiseDuration':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.insuranceFranchiseDuration = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.insuranceFranchiseDuration = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setCoverageRate':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.coverageRate = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.coverageRate = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setMonthlyEstimatedBonus':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.monthlyEstimatedBonus = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.monthlyEstimatedBonus = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setInsuranceGracePeriod':
      if (action.payload?.person === 'borrower') {
        newState.borrowerInsurance.insuranceGracePeriod = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      } else {
        newState.coBorrowerInsurance.insuranceGracePeriod = action.payload.value;
        newState.isDataOk = checkIfDatasAreOk(newState);
      }
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setInputType':
      newState.inputType = action.payload;
      return newState;
    default:
      return state;
  }
};
