import ExpandableCardTer from 'components/expandableCard/ExpandableCardTer';
import Space from 'components/space/Space';
import Tag from 'components/tag/Tag';
import { sortLongText } from 'containers/finalisationProjet/utils';
import {
  StyledCardContentTile,
  StyledCardData,
  StyledCardLabel,
} from 'containers/operationClient/style';
import ActionEdit from 'icons/ActionEdit';
import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import React, { useState } from 'react';
import {
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { Loan } from 'reducers/operationClient/types';
import { formatNumberByThousand } from 'utils/commun';
import { getDateFromInput } from 'utils/DateUtils';
import { StyledBadgeWrapper } from '../../style';
import LoanAdditionalInformation from './additionalInformation';
import { renderTypeCreditLabel } from '../../utils';

interface LoanAdditionalInformationCreateModeProps {
  currentLoan: Loan;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanAdditionalInformationCreateMode: React.FC<
  LoanAdditionalInformationCreateModeProps
> = ({ currentLoan, state, dispatch }) => {
  const currentAdditionalLoan = state.loans.find(
    element => element.code === currentLoan.code,
  );
  const [expandCard, setExpandCard] = useState(
    currentAdditionalLoan?.modificationInprogress || false,
  );

  const renderTag = (loan: Loan, text: string, type: 'restructure' | 'morgage') => {
    const result = type === 'restructure' ? loan.restructure : loan.mortgage;
    return result ? (
      <>
        <Tag accentColor={loan.buyBack ? '#28833C' : '#B1B5B9'} label={text} />
        <Space marginBottom="0.8rem" />
      </>
    ) : null;
  };

  const getCollapsableContent = () => {
    if (currentLoan.creditType?.code !== 'R') {
      return (
        <tr style={{ display: expandCard ? '' : 'none' }}>
          <td>
            {currentLoan.loanStartDate && (
              <StyledCardContentTile>
                <StyledCardLabel>Date de début du prêt</StyledCardLabel>
                <StyledCardData>
                  {currentLoan.loanStartDate
                    ? getDateFromInput(currentLoan.loanStartDate).toLocaleDateString(
                        'fr-FR',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        },
                      )
                    : 'Invalid date'}
                </StyledCardData>
              </StyledCardContentTile>
            )}
          </td>
          <td>
            {currentLoan.totalLoanDuration && (
              <StyledCardContentTile>
                <StyledCardLabel>Durée totale du prêt</StyledCardLabel>
                <StyledCardData>{currentLoan.totalLoanDuration} mois</StyledCardData>
              </StyledCardContentTile>
            )}
          </td>
          <td>
            {currentLoan.initialLoanCapital && (
              <StyledCardContentTile>
                <StyledCardLabel>Capital Initial du prêt</StyledCardLabel>
                <StyledCardData>
                  {currentLoan.initialLoanCapital
                    ? formatNumberByThousand(currentLoan.initialLoanCapital.toString())
                    : ''}{' '}
                  €
                </StyledCardData>
              </StyledCardContentTile>
            )}
          </td>
          <td>
            <StyledCardContentTile>
              {renderTag(currentLoan, 'Restructuration', 'restructure')}
              {renderTag(currentLoan, 'Inscription hypothecaire', 'morgage')}
            </StyledCardContentTile>
          </td>
        </tr>
      );
    }
    return null;
  };

  const getBadge = () => {
    return (
      <StyledBadgeWrapper
        style={{
          color: '#E78A08',
        }}>
        <ActionEdit />
        <span>Données à compléter</span>
      </StyledBadgeWrapper>
    );
  };

  if (!currentAdditionalLoan) {
    return null;
  }

  return (
    <>
      <ExpandableCardTer accentColor="#E78A08" badge={getBadge()}>
        <table
          style={{
            width: '100%',
            marginLeft: '2.4rem',
            tableLayout: 'fixed',
            paddingRight: '2rem',
          }}>
          <tbody>
            <tr className={currentLoan.creditType?.code !== 'R' ? 'table-td-space' : ''}>
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Organisme de crédit</StyledCardLabel>
                  <StyledCardData>
                    {sortLongText(currentLoan.loaner?.libelle)}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Type de crédit</StyledCardLabel>
                  <StyledCardData>
                    {renderTypeCreditLabel(currentLoan.creditType?.code || '')}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Mensualité du prêt</StyledCardLabel>
                  <StyledCardData>
                    {formatNumberByThousand(
                      currentLoan.monthlyPayment !== undefined
                        ? currentLoan.monthlyPayment
                        : '',
                    )}{' '}
                    € / mois
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Solde</StyledCardLabel>
                  <StyledCardData>
                    {currentLoan.balance
                      ? `${formatNumberByThousand(currentLoan.balance)} €`
                      : ''}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td
                style={{ width: '3%', cursor: 'pointer' }}
                role="presentation"
                onKeyDown={() => setExpandCard(prev => !prev)}
                onClick={() => setExpandCard(prev => !prev)}>
                {expandCard ? <ChevronUp /> : <ChevronDown />}
              </td>
            </tr>
            {getCollapsableContent()}
          </tbody>
        </table>
        {expandCard ? (
          <>
            {currentAdditionalLoan && (
              <div style={{ marginLeft: '2.9rem' }}>
                <Space marginTop="3.2rem" />
                <LoanAdditionalInformation
                  currentLoan={currentAdditionalLoan}
                  creditType={currentLoan.creditType}
                  state={state}
                  dispatch={dispatch}
                />
              </div>
            )}
          </>
        ) : (
          <Space marginTop="3.2rem" />
        )}
      </ExpandableCardTer>
    </>
  );
};

export default LoanAdditionalInformationCreateMode;
