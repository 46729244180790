import {
  getBordereauDocument,
  getBordereaux,
  getPartenerList,
} from 'api/eBordereauService';
import Commission from 'components/bordereau/Commission';
import Pagination from 'components/bordereau/Pagination';
import PartnerList from 'components/bordereau/PartnerList';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Partner, GetBordereauApiRequestBody } from 'types';
import Loader from 'components/Loader';
import BordereauList from 'components/bordereau/BordereauList';
import {
  PartnerKeys,
  partnerReducer,
  partnerReducerInitialState,
} from 'reducers/ebordereau/partner';
import {
  bordereauReducer,
  bordereauReducerInitialState,
} from 'reducers/ebordereau/bordereau';
import AuthorizationService from 'api/AuthorizationService';
import { handleDateFilterChange } from './utils';

const EbordereauContainer: React.FC = () => {
  const [partnerState, partnerDispatch] = useReducer(
    partnerReducer,
    partnerReducerInitialState,
  );
  const [bordereauState, bordereauDispatch] = useReducer(
    bordereauReducer,
    bordereauReducerInitialState,
  );

  const [isLoadingPartnersList, setIsLoadingPartnersList] = useState<boolean>(false);
  const [isLoadingBordereaux, setIsLoadingBordereaux] = useState<boolean>(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);
  const [errorPartner, setErrorPartner] = useState<string>('');
  const [errorBordereau, setErrorBordereau] = useState<string>('');
  const [errorDocument, setErrorDocument] = useState<string>('');
  const [displayBordereauZone, setDisplayBordereauZone] = useState<boolean>(false);

  // #region Filter&Pagination states
  const [currentPartnerPagination, setCurrentPartnerPagination] = useState<number>(1); // numéro de pagination active pour la liste des partenaires
  const [currentBordereauPagination, setCurrentBordereauPagination] = useState<number>(1); // numéro de pagination active pour la liste des bordereaux
  const [searchStartDate, setSearchStartDate] = useState<Date>(new Date());
  const [searchEndDate, setSearchEndDate] = useState<Date>(new Date());
  // #endregion

  const bordereauRef = useRef<null | HTMLDivElement>(null); // scroll to the bordereau section
  const [resetComponent, setResetComponent] = useState<boolean>(false);

  const navigate = useNavigate();

  const titleStyle = {
    fontSize: '1.7rem',
    color: '#70AD47',
    fontWeight: 700,
    width: '20rem',
  };

  /**
   * Get all the ids and marked them as checked by default.
   * When the page load, by default, all the partners are checked
   * @param partnersList
   */
  const setDefaultCheckedIdsAPA = (partnersList: Partner[]) => {
    const ids: string[] = [];
    for (let i = 0; i < partnersList.length; i += 1) {
      ids.push(partnersList[i].idAPA);
    }
    partnerDispatch({ type: 'setIdsOfCheckedParterns', payload: ids });
  };

  /**
   * Call the api to get the list of partners (APA)
   */
  const getPartners = async () => {
    try {
      setIsLoadingPartnersList(true);
      setErrorPartner('');
      const result = await getPartenerList({
        userId: AuthorizationService.getUserInfo()?.uid || '',
        contextApp: 'EBOR',
      });
      partnerDispatch({ type: 'setInitialPartnerList', payload: result });
      setDefaultCheckedIdsAPA(result);
    } catch (err) {
      setErrorPartner(
        `Erreur lors de la recupération de la liste des APA. Raison:${err}`,
      );
      partnerDispatch({ type: 'setInitialPartnerList', payload: [] });
    } finally {
      setIsLoadingPartnersList(false);
    }
  };

  /**
   * Always fetch the list of partners from the server and hide the bordereau zone when
   * the component is first rendered
   */
  useEffect(() => {
    getPartners();
    setDisplayBordereauZone(false);
  }, []);

  /**
   * Always fetch the list of partners from the server and hide the bordereau zone when
   * the reset button is clicked. The reset click is tracked by the state resetComponent
   */
  useEffect(() => {
    getPartners();
    setDisplayBordereauZone(false);
  }, [resetComponent]);

  /**
   * When one of the filter changed:
   * -update the displayed items
   * -reset the nb items to display to it's default value
   * -reset the pagination current page to it's default value (1)
   */
  useEffect(() => {
    partnerDispatch({ type: 'filterPartnersList' });
    // after every filter, we reset the nb items per page and the current pagination to their initial value
    partnerDispatch({ type: 'setNbPartnersPerPage', payload: 10 });
    setCurrentPartnerPagination(1);
  }, [
    partnerState.filterPartnersById,
    partnerState.filterPartnersByName,
    partnerState.filterPartnersByNumber,
  ]);

  /**
   * Returns the id of the checked partner. This ids are used in the body
   * of the api fetch bordereau
   * @returns array containing id of partners
   */
  const getIdPartners = (): string[] => {
    const ids: string[] = [];
    partnerState.displayedPartners.forEach(item => {
      if (partnerState.idsOfCheckedParterns.indexOf(item.idAPA) >= 0) {
        ids.push(item.idAPA);
      }
    });
    return ids;
  };

  /**
   * Call the api to fetch bordereau list
   */
  const fetchBordereau = async () => {
    setDisplayBordereauZone(true);
    const requestBody: GetBordereauApiRequestBody = {
      infosCourtier: {
        userId: AuthorizationService.getUserInfo()?.uid || '',
        contextApp: 'EBOR',
      },
      dateMin: new Date(searchStartDate.getFullYear(), searchStartDate.getMonth(), 2),
      dateMax: new Date(searchEndDate.getFullYear(), searchEndDate.getMonth() + 1, 1),
      listeAPAsGroupJur: getIdPartners(),
    };
    try {
      setIsLoadingBordereaux(true);
      setErrorBordereau('');
      const response = await getBordereaux(requestBody);
      bordereauDispatch({ type: 'setInitialBordereauList', payload: response.result });
      setIsLoadingBordereaux(false);
      const typeBordereau: string[] = [];
      response.result.forEach(item => {
        if (typeBordereau.indexOf(item.typeBordereau) < 0) {
          typeBordereau.push(item.typeBordereau);
        }
      });
      bordereauDispatch({ type: 'setFilterBordereauxByType', payload: typeBordereau });
    } catch (err) {
      setErrorBordereau(`Erreur lors de la recupération des bordereaux. Raison: ${err}`);
      setIsLoadingBordereaux(false);
    } finally {
      bordereauRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /**
   * Reset all the state of the component to their initial values
   */
  const resetPage = () => {
    setDisplayBordereauZone(false);
    partnerDispatch({ type: 'resetPartnerState' });
    bordereauDispatch({ type: 'resetBordereauState' });

    setCurrentPartnerPagination(1);
    setCurrentBordereauPagination(1);
    setSearchStartDate(new Date());
    setSearchEndDate(new Date());
    setResetComponent(true);
  };

  /**
   * When one of the filter changed:
   * -update the displayed items
   * -reset the nb items to display to it's default value
   * -reset the pagination current page to it's default value (1)
   */
  useEffect(() => {
    bordereauDispatch({ type: 'filterBordereauxList' });
    // after every filter, we reset the nb items per page and the current pagination to their initial value
    bordereauDispatch({ type: 'setNbBordereauxPerPage', payload: 25 });
    setCurrentBordereauPagination(1);
  }, [
    bordereauState.filterBordereauxByName,
    bordereauState.filterBordereauxByPerComi,
    bordereauState.selectedBordereauFilter,
  ]);

  /**
   * Call the api to get the document content
   * @param reference
   * @param partenaireProprietaireTab
   * @param numeroFacture
   */
  const handleDownloadDocument = async (
    reference: string,
    partenaireProprietaireTab: string,
    numeroFacture: string,
  ) => {
    setIsLoadingDocument(true);
    setErrorDocument('');
    try {
      const requestBody = {
        courtier: {
          userId: AuthorizationService.getUserInfo()?.uid || '',
          contextApp: 'EBOR',
        },
        reference,
        partenaireProprietaireTab,
      };
      const response = await getBordereauDocument(requestBody);
      if (response.success) {
        const buffer = Buffer.from(response.result.documentFusionne, 'base64');
        const blob = new Blob([buffer], {
          type: 'application/pdf;base64',
        });
        const blobURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = `bordereau_${numeroFacture}.${response.result.extension.toLocaleLowerCase()}`;
        link.setAttribute('hidden', 'true');
        link.click();
        window.URL.revokeObjectURL(blobURL);
      }
    } catch (err) {
      setErrorDocument(`Erreur lors du téléchargement du document. Raison:${err}`);
    } finally {
      setIsLoadingDocument(false);
    }
  };

  /**
   * Returns the select options for the number of items per page for the bordereau list
   * @returns
   */
  const getBordereauNbItemsPageSelectOptions = (): number[] => {
    const result: number[] = [];
    if (partnerState.displayedPartners.length > 25) {
      result.push(25);
      result.push(50);
    }
    return result;
  };

  /**
   * Returns the html content of the bordereau zone
   * @returns jsx
   */
  const renderBordereauContent = () => {
    if (errorBordereau) {
      return (
        <div
          style={{
            backgroundColor: '#f2dede',
            borderColor: '#f2dede',
            padding: '1rem',
            height: '4rem',
            fontSize: '1.1rem',
          }}>
          {errorBordereau}
        </div>
      );
    }
    if (bordereauState.bordereauCurrentOrdering.length === 0) {
      return (
        <div
          style={{
            color: '#31708f',
            backgroundColor: '#d9edf7',
            borderColor: '#bce8f1',
            padding: '1rem',
            fontSize: '1.1rem',
            height: '4rem',
          }}>
          Aucun bordereau trouvé.
        </div>
      );
    }
    return (
      <>
        <BordereauList
          bordereauList={bordereauState.displayedBordereaux.slice(
            bordereauState.nbBordereauxPerPage * (currentBordereauPagination - 1),
            bordereauState.nbBordereauxPerPage * currentBordereauPagination,
          )}
          typeBordereauFilters={bordereauState.filterBordereauxByType}
          handleSortBordereauListByPerComi={() => {
            bordereauDispatch({
              type: 'sortDisplayedBordereaux',
              payload: 'perComi',
            });
          }}
          handleSortBordereauListByName={() => {
            bordereauDispatch({
              type: 'sortDisplayedBordereaux',
              payload: 'nomCourtier',
            });
          }}
          handleSortBordereauListByType={() => {
            bordereauDispatch({
              type: 'sortDisplayedBordereaux',
              payload: 'typeBordereau',
            });
          }}
          onFilter={(text, filteringKey) => {
            bordereauDispatch({
              type: 'updateFilters',
              payload: { text, filteringKey },
            });
          }}
          onTypeBordereauSelect={text => {
            bordereauDispatch({
              type: 'setSelectedBordereauFilter',
              payload: text,
            });
          }}
          onDownloadDocumentClick={(
            reference,
            partenaireProprietaireTab,
            numeroFacture,
          ) => {
            handleDownloadDocument(reference, partenaireProprietaireTab, numeroFacture);
          }}
          activeSortingKey={bordereauState.bordereauCurrentSortingKey}
          perComiSortOrder={bordereauState.sortBordereauxByPerComi}
          nameSortOrder={bordereauState.sortBordereauxByName}
          typeSortOrder={bordereauState.sortBordereauxByType}
        />
        <Pagination
          totalItemsToPaginate={bordereauState.displayedBordereaux.length}
          nbItemsPerPage={bordereauState.nbBordereauxPerPage}
          activePage={currentBordereauPagination}
          firstDisplayedItemIndex={
            bordereauState.nbBordereauxPerPage * (currentBordereauPagination - 1) + 1
          }
          onPageChange={pageNumber => {
            setCurrentBordereauPagination(pageNumber);
          }}
          onNbItemsToDisplayChange={nbItems => {
            bordereauDispatch({
              type: 'setNbBordereauxPerPage',
              payload: bordereauState.displayedBordereaux.length
                ? nbItems
                : bordereauState.displayedBordereaux.length,
            });
            setCurrentBordereauPagination(1); // on revient à la premiére pagination dés qu'on change le nb d'éléments à afficher
          }}
          nbItemsPerPageOptions={getBordereauNbItemsPageSelectOptions()}
        />
      </>
    );
  };

  /**
   * Returs the html of the bordereau zone depending on some conditions
   * @returns jsx
   */
  const renderListBordereau = () => {
    if (!displayBordereauZone) {
      return null;
    }
    if (isLoadingBordereaux) {
      return (
        <Loader text="Chargement de la liste des bordereaux" animationDuration={2} />
      );
    }
    return (
      <div style={{ display: 'flex' }}>
        <div id="titles">
          <div style={{ ...titleStyle, marginTop: '3rem' }}>
            {bordereauState.displayedBordereaux.length === 0
              ? ''
              : 'Bordereaux de commissionnement'}
          </div>
        </div>
        <div id="content" style={{ width: '100%' }}>
          {renderBordereauContent()}
        </div>
      </div>
    );
  };

  /**
   * Update the value of the filtering keys
   * @param text filtering text
   * @param filteringKey filtering key to update
   */
  const updateFilters = (text: string, filteringKey: PartnerKeys) => {
    partnerDispatch({ type: 'updateFilters', payload: { text, filteringKey } });
  };

  /**
   * Handle the checkbox change of a partner form the list of partners.
   * By default, all the partners are checked and their ids are stored in an array (idsOfCheckedParterns).
   * When a partner is unchecked, it's removed from the list of checked partners (idsOfCheckedParterns).
   * @param idAPA id of a partner
   */
  const handleAPACheck = (idAPA: string) => {
    const indexOfCurrentIdAPA = partnerState.idsOfCheckedParterns.indexOf(idAPA);
    if (indexOfCurrentIdAPA >= 0) {
      const newIds = partnerState.idsOfCheckedParterns.filter(item => {
        return item !== idAPA;
      });
      partnerDispatch({ type: 'setIdsOfCheckedParterns', payload: newIds });
    } else {
      const newIds = [...partnerState.idsOfCheckedParterns, idAPA];
      partnerDispatch({ type: 'setIdsOfCheckedParterns', payload: newIds });
    }
  };

  /**
   * Returns the select options for the number of items per page for the partner list
   * @returns
   */
  const getPartnerNbItemsPageSelectOptions = (): number[] => {
    const result: number[] = [];
    if (partnerState.displayedPartners.length > 10) {
      result.push(10);
      result.push(25);
    }
    if (partnerState.displayedPartners.length > 25) {
      result.push(50);
    }
    return result;
  };

  /**
   * Returs the html of the partner zone depending on some conditions
   * @returns jsx
   */
  const renderListApa = () => {
    if (isLoadingPartnersList) {
      return (
        <Loader text="Chargement de la liste des partenaires" animationDuration={0.9} />
      );
    }
    return (
      <>
        <div style={{ display: 'flex' }}>
          <div id="titles">
            <div
              style={{
                ...titleStyle,
                marginTop: '5rem',
              }}>
              Commissions
            </div>
            <div
              style={{
                ...titleStyle,
                marginTop: '3rem',
              }}>
              Liste de mes partenaires
            </div>
          </div>
          <div id="content">
            <Commission
              startDate={searchStartDate}
              endDate={searchEndDate}
              onStartDateChange={newDate => {
                setSearchStartDate(newDate);
              }}
              onEndDateChange={newDate => {
                setSearchEndDate(newDate);
              }}
              onFindBordereauClick={() => fetchBordereau()}
              onDateFilterChange={filterType => {
                handleDateFilterChange(filterType, setSearchStartDate, setSearchEndDate);
              }}
              onResetClick={resetPage}
              isBtnDisabled={isLoadingPartnersList}
              onGoHomeClick={() => {
                navigate('/dashboard');
              }}
            />
            {errorPartner ? (
              <div
                style={{
                  backgroundColor: '#f2dede',
                  borderColor: '#f2dede',
                  padding: '1rem',
                  height: '4rem',
                  fontSize: '1.1rem',
                }}>
                {errorPartner}
              </div>
            ) : (
              <>
                <PartnerList
                  partnerList={partnerState.displayedPartners.slice(
                    partnerState.nbPartnersPerPage * (currentPartnerPagination - 1),
                    partnerState.nbPartnersPerPage * currentPartnerPagination,
                  )}
                  handleSortPartnerListByName={() => {
                    partnerDispatch({
                      type: 'sortDisplayedPartners',
                      payload: 'nomCourtier',
                    });
                  }}
                  handleSortPartnerListByNumber={() => {
                    partnerDispatch({
                      type: 'sortDisplayedPartners',
                      payload: 'numeroVendeur',
                    });
                  }}
                  handleSortPartnerListByIdIntv={() => {
                    partnerDispatch({ type: 'sortDisplayedPartners', payload: 'idIntv' });
                  }}
                  onFilter={(text, filteringKey) => {
                    updateFilters(text, filteringKey);
                  }}
                  checkAllCheckBox={partnerState.isAllPartnersChecked}
                  onAllCheckboxCheck={() => {
                    partnerDispatch({ type: 'handleAllAPACheckboxChange' });
                  }}
                  onAPACheck={handleAPACheck}
                  checkedIdsAPA={partnerState.idsOfCheckedParterns}
                  nameSortOrder={partnerState.sortPartnersByName}
                  numberSortOrder={partnerState.sortPartnersByNumber}
                  activeSortingKey={partnerState.partnerCurrentSortingKey}
                  idSortOrder={partnerState.sortPartnersById}
                />
                <Pagination
                  totalItemsToPaginate={partnerState.displayedPartners.length}
                  nbItemsPerPage={partnerState.nbPartnersPerPage}
                  activePage={currentPartnerPagination}
                  firstDisplayedItemIndex={
                    partnerState.nbPartnersPerPage * (currentPartnerPagination - 1) + 1
                  }
                  onPageChange={pageNumber => {
                    setCurrentPartnerPagination(pageNumber);
                  }}
                  onNbItemsToDisplayChange={nbItems => {
                    partnerDispatch({
                      type: 'setNbPartnersPerPage',
                      payload: partnerState.displayedPartners.length
                        ? nbItems
                        : partnerState.displayedPartners.length,
                    });
                    setCurrentPartnerPagination(1); // on revient à la premiére pagination dés qu'on change le nb d'éléments à afficher
                  }}
                  nbItemsPerPageOptions={getPartnerNbItemsPageSelectOptions()}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  /**
   * Rendres a loader when the document api is laoding
   * @returns jsx
   */
  const renderDocumentLoading = () => {
    if (isLoadingDocument) {
      return <Loader text="Chargement du document en cours..." animationDuration={0.9} />;
    }
    if (errorDocument) {
      return <p>{errorDocument}</p>;
    }
    return null;
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 5.4rem',
      }}>
      {renderListApa()}
      <div
        ref={bordereauRef}
        style={{
          marginBottom: '2rem',
        }}
      />
      {renderListBordereau()}
      {renderDocumentLoading()}
    </div>
  );
};

export default EbordereauContainer;
