import ExpandableCardTer from 'components/expandableCard/ExpandableCardTer';
import Space from 'components/space/Space';
import {
  StyledBadgeWrapper,
  StyledInputLabel,
} from 'containers/finalisationProjet/style';
import { getHypothequeValue, sortLongText } from 'containers/finalisationProjet/utils';
import {
  StyledCardContentTile,
  StyledCardData,
  StyledCardLabel,
} from 'containers/operationClient/style';
import ActionValidatedIcon from 'icons/ActionPlusIcon';
import ChevronDown from 'icons/ChevronDown';
import { useState } from 'react';
import {
  GuaranteeAdditionalInformationAction,
  GuaranteeAdditionalInformationState,
} from 'reducers/guaranteeAdditionalInformation/type';
import { Asset } from 'reducers/operationClient/types';
import { formatNumberByThousand, getFullAddress } from 'utils/commun';
import ValidateBtn from '../createMode/additionalInformation/ValidateBtn';

interface GuaranteeAdditionalInformationViewModeProps {
  currentGuarantee: Asset;
  state: GuaranteeAdditionalInformationState;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
}

const GuaranteeAdditionalInformationViewMode: React.FC<
  GuaranteeAdditionalInformationViewModeProps
> = ({ currentGuarantee, state, dispatch }) => {
  const guarantee = state.guarantees.find(item => item.code === currentGuarantee.code);

  const [expandCard, setExpandCard] = useState(false);
  if (!guarantee) {
    return null;
  }

  const getCollapsableContent = () => {
    return (
      <>
        <tr className="table-td-space" style={{ display: expandCard ? '' : 'none' }}>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>
                <span>{`Rang de l'inscription`}</span>
                <br />
                du bien
              </StyledCardLabel>
              <StyledCardData style={{ marginTop: '1.8rem' }}>
                {currentGuarantee.inscriptionRank}
              </StyledCardData>
            </StyledCardContentTile>
          </td>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>
                <span>Valeur des hypothèques</span>
                <br /> conservées
              </StyledCardLabel>
              <StyledCardData style={{ marginTop: '1.8rem' }}>
                {currentGuarantee.keptMortagagesValue} €
              </StyledCardData>
            </StyledCardContentTile>
          </td>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>
                <span>Valeur libre</span> <br />
                {`d'hypotèque retenue`}
              </StyledCardLabel>
              <StyledCardData style={{ marginTop: '1.8rem' }}>
                {getHypothequeValue(
                  currentGuarantee.inscriptionRank,
                  currentGuarantee.currentValue,
                  currentGuarantee.keptMortagagesValue,
                )}{' '}
                €
              </StyledCardData>
            </StyledCardContentTile>
          </td>
          <td />
        </tr>
        {!guarantee?.useBorrowerAddress && (
          <tr className="table-td-space" style={{ display: expandCard ? '' : 'none' }}>
            <td>
              <StyledCardContentTile>
                <StyledCardLabel>Adresse du bien</StyledCardLabel>
                <StyledCardData>{getFullAddress(guarantee.address || {})}</StyledCardData>
              </StyledCardContentTile>
            </td>
            <td>
              <StyledCardContentTile>
                <StyledCardLabel>Code postal</StyledCardLabel>
                <StyledCardData>
                  {guarantee.address?.zipCode || currentGuarantee.zipCode}
                </StyledCardData>
              </StyledCardContentTile>
            </td>
            <td>
              <StyledCardContentTile>
                <StyledCardLabel>Ville</StyledCardLabel>
                <StyledCardData>
                  {guarantee.address?.city || currentGuarantee.city}
                </StyledCardData>
              </StyledCardContentTile>
            </td>
            <td />
          </tr>
        )}
        <tr className="table-td-space" style={{ display: expandCard ? '' : 'none' }}>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>Année de construction</StyledCardLabel>
              <StyledCardData>{guarantee.constructionYear}</StyledCardData>
            </StyledCardContentTile>
          </td>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>{`Année d'acquisition`}</StyledCardLabel>
              <StyledCardData>{guarantee.acquisitionYear}</StyledCardData>
            </StyledCardContentTile>
          </td>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>{`Valeur d'acquisition`}</StyledCardLabel>
              <StyledCardData>
                {formatNumberByThousand(guarantee.acquisitionValue || '')} €
              </StyledCardData>
            </StyledCardContentTile>
          </td>
          <td />
        </tr>
        <tr style={{ display: expandCard ? '' : 'none' }}>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>Surface habitable</StyledCardLabel>
              <StyledCardData>{guarantee.livingSpace} m2</StyledCardData>
            </StyledCardContentTile>
          </td>
          <td>
            <StyledCardContentTile>
              <StyledCardLabel>{`Type d'évaluation`}</StyledCardLabel>
              <StyledCardData>{guarantee.evaluationType?.libelle}</StyledCardData>
            </StyledCardContentTile>
          </td>
          <td />
        </tr>
      </>
    );
  };

  const getBadge = () => {
    return (
      <StyledBadgeWrapper style={{ color: '#28833C' }}>
        <ActionValidatedIcon color="#28833C" />
        <span>Données renseignées</span>
      </StyledBadgeWrapper>
    );
  };

  return (
    <>
      <ExpandableCardTer accentColor="#28833C" badge={getBadge()}>
        <table style={{ width: '100%', marginLeft: '2.4rem', tableLayout: 'fixed' }}>
          <tbody>
            <tr className="table-td-space">
              <td style={{ width: '25%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Type de bien</StyledCardLabel>
                  <StyledCardData>
                    {sortLongText(currentGuarantee.type?.libelle)}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '25%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Usage du bien</StyledCardLabel>
                  <StyledCardData>
                    {sortLongText(currentGuarantee.usage?.libelle)}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '25%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Valeur du bien</StyledCardLabel>
                  <StyledCardData>
                    {formatNumberByThousand(currentGuarantee.currentValue || '')} €
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td
                style={{ width: '5%', cursor: 'pointer' }}
                role="presentation"
                onKeyDown={() => setExpandCard(prev => !prev)}
                onClick={() => setExpandCard(prev => !prev)}>
                <ChevronDown />
              </td>
            </tr>
            {getCollapsableContent()}
          </tbody>
        </table>
        {expandCard ? (
          <>
            <Space marginTop="4rem" />
            {guarantee?.useBorrowerAddress ? (
              <StyledInputLabel style={{ marginLeft: '2.4rem' }}>
                L’adresse du bien est identique à celle de l’emprunteur
              </StyledInputLabel>
            ) : (
              <></>
            )}
            <Space marginTop="4rem" />
            <div style={{ width: '14.2rem', marginLeft: '2.4rem' }}>
              <ValidateBtn
                mode="view"
                currentGuarantee={guarantee}
                state={state}
                dispatch={dispatch}
              />
            </div>
            <Space marginTop="3.2rem" />
          </>
        ) : null}
      </ExpandableCardTer>
      <Space marginBottom="2.4rem" />
    </>
  );
};

export default GuaranteeAdditionalInformationViewMode;
