import BaseButton from 'components/designSystem/BaseButton';
import styled from 'styled-components';

export const StyledSideMenuMainContainer = styled.div`
  min-height: 113.7rem;
  min-width: 22.4rem;
  background: #f2f3f3;
  margin-right: 4rem;
  padding: 2.4rem 1.2rem 2.4rem 2.4rem;
`;

export const StyledSideMenuUnderlinedLink = styled.div`
  white-space: nowrap;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
`;

export const StyledSideMenuCategoryTitle = styled.span`
  font-weight: bold;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 18px;
`;

export const StyledSideMenuLinkContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  &:hover {
    font-weight: bold;
  }
`;

export const StyledSideMenuLinkGroup = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 4.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const StyledSideMenuLink = styled.div`
  white-space: nowrap;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  text-decoration: none;
`;

export const StyledSideMenuSignOut = styled.div`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 14px;
  text-decoration: underline;
`;

export const StyledIconContainer = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSIdeMenuLinkBouton = styled(BaseButton)<{
  color?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-color: ${({ color }) =>
    color || (({ theme }) => theme.colors.chartColors.mainGreen500)};
  background-color: transparent;
  color: ${({ color }) =>
    color || (({ theme }) => theme.colors.chartColors.mainGreen500)};
  height: 3.2rem;
  width: 19.4rem;
  right: 0.9rem;
  padding: 0;
  gap: 1.1rem;
  font-size: 1.4rem;
`;
