export const SEARCH_MAIN_TITLE = 'Bienvenue dans votre espace partenaire';
export const SEARCH_BREADCRUMBS_RETURN_HOME = "Retour à l'accueil";
export const SEARCH_FILE_TITLE = 'Rechercher un dossier';
export const SEARCH_RESULT_TITLE = 'Résultat de la recherche';

export const SEARCH_PLACEHOLDER_LAST_NAME = 'Rechercher par le nom';
export const SEARCH_PLACEHOLDER_FIRST_NAME = 'Rechercher par le prénom';
export const SEARCH_PLACEHOLDER_FILE_NUMBER = 'Rechercher par le N° de dossier';
export const SEARCH_PLACEHOLDER_FILE_STATUS = 'Rechercher par statut du dossier';

export const SEARCH_PLACEHOLDER_GARANTEE_NATURE = 'Rechercher par nature de Garantie';
export const SEARCH_PLACEHOLDER_TREATMENT_TYPE = 'Rechercher par type de traitement';

export const SEARCH_DATE_FROM = 'Date du';
export const SEARCH_DATE_TO = 'au';

export const SEARCH_BUTTON_LABEL = 'Rechercher le dossier';

export const SEARCH_TOGGLE_LABEL = 'Plus de critères';

export const FILES_FOUND_LABEL = 'dossier(s) trouvé(s)';

export const RESUME_BUTTON_LABEL = 'Reprendre la saisie du dossier';

export const ERROR_MESSAGES_NO_CRITERIA =
  'Au moins un critère de recherche doit être saisi';
