import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from 'icons/CloseIcon';
import {
  StyledModalContent,
  StyledModalWrapper,
  StyledModalContentBody,
  StyledCloseModalContainer,
} from './style';

export interface ModalProps {
  onClose?: () => void;
  children?: React.ReactNode;
  isVisible: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
  closeOutside?: boolean;
}
const Modal: React.FC<ModalProps> = ({
  isVisible,
  onClose = () => null,
  children,
  width,
  height,
  backgroundColor,
  closeOutside,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (isVisible && modalRef.current) {
      const modalElement = modalRef.current as HTMLElement;
      const focusableElements = modalElement.querySelectorAll(
        'base-button, div[role="button"]',
      );
      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;
      firstElement?.focus();
      const handleTabKeyPress = event => {
        if (event.key === 'Tab') {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      };
      const handleEscapeKeyPress = event => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      modalElement.addEventListener('keydown', handleTabKeyPress);
      modalElement.addEventListener('keydown', handleEscapeKeyPress);
      return () => {
        modalElement.removeEventListener('keydown', handleTabKeyPress);
        modalElement.removeEventListener('keydown', handleEscapeKeyPress);
      };
    }
    return () => {
      //
    };
  }, [isVisible]);

  return isVisible
    ? ReactDOM.createPortal(
        <StyledModalWrapper
          data-testid="modal"
          ref={modalRef}
          onClick={closeOutside ? onClose : e => e.stopPropagation()}>
          <StyledModalContent
            width={width || '94rem'}
            height={height || '100%'}
            onClick={e => e.stopPropagation()}>
            <div style={{ paddingBottom: '5rem' }}>
              <StyledModalContentBody
                backgroundColor={backgroundColor}
                {...(closeOutside
                  ? { style: { position: 'relative', borderRadius: '2.5rem' } }
                  : {})}>
                {closeOutside && (
                  <StyledCloseModalContainer onClick={e => onClose()}>
                    <CloseIcon />
                  </StyledCloseModalContainer>
                )}
                {children}
              </StyledModalContentBody>
            </div>
          </StyledModalContent>
        </StyledModalWrapper>,
        document.body,
      )
    : null;
};

Modal.defaultProps = {
  width: '94rem',
  height: '100%',
  children: <></>,
  onClose: () => {
    console.log('unimplemented onClose');
  },
  backgroundColor: '#FFFFFF',
  closeOutside: false,
};
export default Modal;
