import * as React from 'react';

const NextIcon: React.FC = () => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
    <title>AB6E6E4E-54BF-4CD4-B427-8E7A724E8AEB</title>
    <path
      d="M1.25 10a8.75 8.75 0 1 0 17.5 0 8.75 8.75 0 0 0-17.5 0ZM20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10ZM5.625 9.375a.625.625 0 1 0 0 1.25h7.241l-2.684 2.682a.626.626 0 1 0 .886.886l3.75-3.75a.625.625 0 0 0 0-.886l-3.75-3.75a.626.626 0 1 0-.886.885l2.684 2.683H5.625Z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

export default NextIcon;
