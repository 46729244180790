import React from 'react';
import GenericIconProps from './GenericIcon';

const ArrowLeftCircleFullIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 18;
  const baseHeight = 18;
  const baseColor = '#4b4f54';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>ArrowLeftCircleFullIcon</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dashboard2"
          transform="translate(-956.000000, -552.000000)"
          fill={color || baseColor}>
          <g id="Group-2" transform="translate(264.000000, 471.000000)">
            <g
              id="Icon/24px/Navigation/arrow-right-Copy"
              transform="translate(692.666667, 81.503846)">
              <path
                d="M8.33333333,0 C3.73096042,0 0,3.80988458 0,8.50961538 C0,13.2093462 3.73096042,17.0192308 8.33333333,17.0192308 C12.9357062,17.0192308 16.6666667,13.2093462 16.6666667,8.50961538 C16.6666667,3.80988458 12.9357062,0 8.33333333,0 Z M11.9791667,7.97776442 C12.266815,7.97776442 12.5,8.21588221 12.5,8.50961538 C12.5,8.80334856 12.266815,9.04146635 11.9791667,9.04146635 L5.94479167,9.04146635 L8.18125,11.3241707 C8.31299169,11.4586992 8.36444267,11.6547788 8.31622187,11.8385482 C8.26800106,12.0223176 8.12743436,12.1658578 7.94747187,12.2150987 C7.76750938,12.2643395 7.57549169,12.2118002 7.44375,12.0772716 L4.31875,8.88616587 C4.2207899,8.78638609 4.16573564,8.650904 4.16573564,8.50961538 C4.16573564,8.36832677 4.2207899,8.23284468 4.31875,8.1330649 L7.44375,4.94195913 C7.647405,4.73399605 7.977595,4.73399605 8.18125,4.94195913 C8.384905,5.14992222 8.384905,5.48709701 8.18125,5.6950601 L5.94479167,7.97776442 L11.9791667,7.97776442 Z"
                id="arrow-left-circle-fill"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeftCircleFullIcon;
