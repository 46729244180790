import { hasCoBorrower, isSansGarantie } from 'containers/communs/utils';
import {
  DebtsAdditionalInformationState,
  DebtsCompleteData,
} from 'reducers/debtsAdditionalInformation/type';
import { FinalisationProjetState } from 'reducers/finalisationProjet/types';
import {
  GuaranteeAdditionalData,
  GuaranteeAdditionalInformationState,
  Notary,
} from 'reducers/guaranteeAdditionalInformation/type';
import { IbanState } from 'reducers/iban/type';
import { InterlocutorState } from 'reducers/interlocutor/type';
import {
  LoanAdditionalData,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { Asset, Loan, Operation, OperationState } from 'reducers/operationClient/types';
import {
  BiensImmobilier,
  Coordonnees,
  Credit,
  DecouvertsBancaire,
  Dette,
  FullAddress,
  Notaire,
  PersonAdditionnalInformation,
  PieceIdentite,
  SimulationComplementApiRequestBody,
  SituationProfessionnelle,
} from 'types';
import { formatNumberByThousand } from 'utils/commun';
import { getDateFromInput } from 'utils/DateUtils';

export const getHypothequeValue = (
  inscriptionRank?: string,
  currentValue?: string,
  keptMortagagesValue?: string,
): string => {
  if (!Number.isNaN(inscriptionRank) && Number(inscriptionRank) > 1) {
    if (!Number.isNaN(currentValue) && !Number.isNaN(keptMortagagesValue)) {
      const result = Number(currentValue) - Number(keptMortagagesValue);
      return formatNumberByThousand(result.toString());
    }
    return '';
  }
  if (!Number.isNaN(currentValue)) {
    return formatNumberByThousand(currentValue || '');
  }
  return '';
};

/**
 * Si le texte paramétre est supérieur à 14, on le coupe au 13 caractére
 * @param text texte à couper si besoin
 * @returns texte coupé ou non selon sa taille
 */
export const sortLongText = (text?: string): string => {
  if (!text) {
    return '';
  }
  if (text.length > 14) {
    return `${text.trim().substring(0, 14)}...`;
  }
  return text;
};

export const renderTypeCreditLabel = (code: string): string => {
  if (code === 'R') {
    return 'Renouvelable';
  }
  if (code === 'C') {
    return 'Consommation';
  }
  if (code === 'I') {
    return 'Immobilier';
  }
  return code;
};

const getCredits = (
  loans: LoanAdditionalData[],
  operationsClient: OperationState | null,
): Credit[] => {
  const getCurrentLoanBaseData = (code: number): Loan | undefined => {
    if (
      operationsClient &&
      operationsClient.loanList &&
      operationsClient.loanList.length > 0
    ) {
      return operationsClient.loanList.find(element => element.code === code);
    }
    return undefined;
  };

  const getTitulaire = (titulaire: string) => {
    switch (titulaire) {
      case 'borrower':
        return '1';
      case 'coBorrower':
        return '2';
      case 'both':
        return '3';
      default:
        return '';
    }
  };

  const result: Credit[] = [];
  const loanTypeMap = new Map();
  loanTypeMap.set('creditAffecte', 'A');
  loanTypeMap.set('personnel', 'P');
  loanTypeMap.set('other', 'L');

  loans.forEach(item => {
    const loan = getCurrentLoanBaseData(item.code);
    result.push({
      taux: item.nominalInterestRate,
      sousType: loanTypeMap.get(item.loanType) || '',
      usageBien: item.loanUsage?.code,
      indemniteRemboursementAnticipe: item.indemnityAmount || '0',
      delaisPreavis: item.nbMonthsForNotice,
      titulaire: getTitulaire(item.titulaire ? item.titulaire.toString() : ''),
      societe: loan?.loaner?.code,
      solde: loan?.balance ? loan.balance.replace(/ /g, '') : undefined,
      mensualite: loan?.monthlyPayment,
      nature: loan?.creditType?.code,
      pretRestructureIndicateur: loan?.restructure ? 'O' : 'N',
      indicateurInscrit: loan?.mortgage ? 'O' : 'N',
      montantInitialPret: loan?.initialLoanCapital
        ? loan.initialLoanCapital.replace(/ /g, '')
        : undefined,
      datePret:
        item.creditType?.code !== 'R'
          ? getDateFromInput(loan?.loanStartDate || '')
          : getDateFromInput(item.loanStartingDate || ''),
      dureeActualisee:
        item.creditType?.code !== 'R' ? loan?.totalLoanDuration : item.loanDuration,
      natureBien: item?.loanObject?.code,
      rachatIndicateur: loan?.buyBack ? 'O' : 'N',
      numeroPret: item.loanNumber,
    } as Credit);
  });
  return result;
};

const getDebts = (
  debts: DebtsCompleteData[],
  operationsClient: OperationState,
): Dette[] => {
  const result: Dette[] = [];
  const getCurrentDebtBaseData = (code: string): Operation | undefined => {
    if (
      operationsClient &&
      operationsClient.buybackDebtsList &&
      operationsClient.buybackDebtsList.length > 0
    ) {
      return operationsClient.buybackDebtsList.find(element => element.code === code);
    }
    return undefined;
  };
  debts.forEach(debt => {
    const currentDebt = getCurrentDebtBaseData(debt.code);

    const tempDebt = {} as Dette;
    tempDebt.civiliteCreancier = debt.civility?.code;
    tempDebt.identiteCreancier = debt.creditorName;
    if (debt.dueDate) tempDebt.dateExigibilite = getDateFromInput(debt.dueDate || '');
    tempDebt.montant = currentDebt?.amount.toString();
    tempDebt.type = currentDebt?.code;
    if (debt.banks) {
      tempDebt.decouvertsBancaires = [];
      debt.banks.forEach(bank => {
        tempDebt.decouvertsBancaires?.push({
          dateExigibilite: getDateFromInput(bank.dueDate || ''),
          montant: bank.amount || '',
          nomEtablissementBancaire: bank.libelle || '',
          numeroCompteBancaire: bank.accountNumber || '',
          titulaireCompteBancaire: bank.accountHolder || '',
        } as DecouvertsBancaire);
      });
    }

    result.push(tempDebt);
  });
  return result;
};

const getCoEmprunteur = (
  finalisationState: FinalisationProjetState,
): PersonAdditionnalInformation | undefined => {
  if (hasCoBorrower()) {
    return {
      pieceIdentite: {
        typeIdentite: finalisationState.coBorrower.identityType?.code || '',
        numeroIdentite: finalisationState.coBorrower.identityNumber || '',
      } as PieceIdentite,
      coordonnees: {
        adresse: {
          complement: finalisationState.coBorrower.address.additionalInformation || '',
          adresse: finalisationState.coBorrower.address.street || '',
        },
        telephonePortable:
          finalisationState.coBorrower.cellPhoneNumber?.replaceAll(' ', '') || '',
        telephoneDomicile:
          finalisationState.coBorrower.homePhoneNumber?.replaceAll(' ', '') || '',
        email: finalisationState.coBorrower.email || '',
      } as Coordonnees,
      situationProfessionnelle: {
        employeur: finalisationState.coBorrower.employerName || '',
      } as SituationProfessionnelle,
    } as PersonAdditionnalInformation;
  }
  return undefined;
};

const getBiensImmobiliers = (
  guarantees: GuaranteeAdditionalData[],
  operationsClient: OperationState,
  borrowerAddress: FullAddress,
): BiensImmobilier[] => {
  const result: BiensImmobilier[] = [];
  const getCurrentAssetBaseData = (code: number): Asset | undefined => {
    if (
      operationsClient &&
      operationsClient.assetList &&
      operationsClient.assetList.length > 0
    ) {
      return operationsClient.assetList.find(element => element.code === code);
    }
    return undefined;
  };
  guarantees.forEach(item => {
    const currentAsset = getCurrentAssetBaseData(item.code);
    result.push({
      adresseIdentiqueEmpIndicateur: item.useBorrowerAddress ? 'O' : 'N',
      adresse: item.useBorrowerAddress
        ? {
            numero: borrowerAddress.streetNumber || '',
            typeVoie: borrowerAddress.streetType?.code || '',
            nomVoie: borrowerAddress.street || '',
            boitePostalLieuDit: borrowerAddress.POBoxOrHamlet || '',
            complement: borrowerAddress.additionalInformation || '',
            codePostal: Number(borrowerAddress.zipCode),
            ville: borrowerAddress.city || '',
          }
        : {
            numero: item.address?.streetNumber || '',
            typeVoie: item.address?.streetType?.code || '',
            nomVoie: item.address?.street || '',
            boitePostalLieuDit: item.address?.POBoxOrHamlet || '',
            complement: item.address?.additionalInformation || '',
            codePostal: Number(item.address?.zipCode),
            ville: item.address?.city || '',
          },
      anneeConstruction: item.constructionYear,
      anneeAcquisition: item.acquisitionYear,
      surfaceHabitable: item.livingSpace,
      valeurAcquisition: item.acquisitionValue,
      valeurEstimee: currentAsset?.currentValue,
      methodeEvaluationBien: item.evaluationType?.code,
      nature: currentAsset?.type?.code,
      usage: currentAsset?.usage?.code,
      prisGarantieIndicateur: 'O',
      rangHypotheque: currentAsset?.inscriptionRank,
      valeurHypothequesConservees: currentAsset?.keptMortagagesValue,
    } as BiensImmobilier);
  });
  return result;
};

const getNotaryInformation = (notary: Notary): Notaire | undefined => {
  const result = {} as Notaire;
  result.nom = notary?.name || '';
  result.departement =
    notary?.departmentCode?.substring(0, 2) || notary?.addressCity?.code.substring(0, 2);
  result.ville = notary?.addressCity?.libelle || '';

  return result;
};

export const getSimulationComplementRequestBody = (
  finalisationProjetState: FinalisationProjetState,
  guaranteeState: GuaranteeAdditionalInformationState,
  loanState: LoanAdditionalInformationState,
  debtState: DebtsAdditionalInformationState,
  ibanState: IbanState,
  interlocutorState: InterlocutorState,
): SimulationComplementApiRequestBody => {
  const valFromSS = sessionStorage.getItem('operationsClient');
  let operationsClient: OperationState = {} as OperationState;
  if (valFromSS) {
    operationsClient = JSON.parse(valFromSS);
  }
  const reqBody: SimulationComplementApiRequestBody = {
    miob: interlocutorState.miobName || '',
    // eslint-disable-next-line no-nested-ternary
    demandeClientEsignatureIndicateur: isSansGarantie()
      ? finalisationProjetState.editionType === 'e-signature'
        ? 'O'
        : 'N'
      : '',
    emprunteur: {
      pieceIdentite: {
        typeIdentite: finalisationProjetState.borrower.identityType?.code || '',
        numeroIdentite: finalisationProjetState.borrower.identityNumber || '',
      },
      coordonnees: {
        adresse: {
          numero: finalisationProjetState.borrower.address.streetNumber || '',
          typeVoie: finalisationProjetState.borrower.address.streetType?.code || '',
          nomVoie: finalisationProjetState.borrower.address.street || '',
          boitePostalLieuDit:
            finalisationProjetState.borrower.address.POBoxOrHamlet || '',

          complement:
            finalisationProjetState.borrower.address.additionalInformation || '',
          codePostal: Number(finalisationProjetState.borrower.address.zipCode),
          ville: finalisationProjetState.borrower.address.city || '',
        },
        telephonePortable:
          finalisationProjetState.borrower.cellPhoneNumber?.replaceAll(' ', '') || '',
        telephoneDomicile:
          finalisationProjetState.borrower.homePhoneNumber?.replaceAll(' ', '') || '',
        email: finalisationProjetState.borrower.email || '',
      },
      coordonneesBancaires: {
        bic: ibanState.bic || '',
        iban: ibanState.isDataOk
          ? `${ibanState.ibanPart1 || ''}${ibanState.ibanPart2 || ''}${
              ibanState.ibanPart3 || ''
            }${ibanState.ibanPart4 || ''}${ibanState.ibanPart5 || ''}${
              ibanState.ibanPart6 || ''
            }${ibanState.ibanPart7 || ''}${ibanState.ibanPart8 || ''}${
              ibanState.ibanPart9 || ''
            }`
          : '',
      },
      situationProfessionnelle: {
        employeur: finalisationProjetState.borrower.employerName || '',
      },
    },
    coEmprunteur: getCoEmprunteur(finalisationProjetState),
    projet: {
      credits: getCredits(loanState.loans, operationsClient),
      dettes: getDebts(debtState.debts, operationsClient),
    },
    garantieHypothecaire: {
      notaire: isSansGarantie() ? undefined : getNotaryInformation(guaranteeState.notary),
      biensImmobiliers: getBiensImmobiliers(
        guaranteeState.guarantees,
        operationsClient,
        finalisationProjetState.borrower.address,
      ),
    },
  };
  return reqBody;
};
