import {
  StyledPJNavBarText,
  StyledSelectedPJNavBarText,
  StyledNavBarPJTile,
  StyledSelectedNavBarPJTile,
} from '../style';

interface NavBarPJTileProps {
  label: string;
  isSelected: boolean;
  isEnabled: boolean;
  onclick: () => void;
}

const NavBarPJTile: React.FC<NavBarPJTileProps> = ({
  label,
  isSelected,
  isEnabled,
  onclick,
}) => {
  const renderEnabled = () => {
    return (
      <>
        {isSelected ? (
          <StyledSelectedNavBarPJTile
            style={{ cursor: 'pointer' }}
            onKeyPress={onclick}
            onClick={onclick}
            role="button"
            tabIndex={0}>
            <StyledSelectedPJNavBarText>{label}</StyledSelectedPJNavBarText>
          </StyledSelectedNavBarPJTile>
        ) : (
          <StyledNavBarPJTile
            style={{ cursor: 'pointer' }}
            onKeyPress={onclick}
            onClick={onclick}
            role="button"
            tabIndex={0}>
            <StyledPJNavBarText>{label}</StyledPJNavBarText>
          </StyledNavBarPJTile>
        )}
      </>
    );
  };

  return isEnabled ? (
    renderEnabled()
  ) : (
    <StyledNavBarPJTile>
      <StyledPJNavBarText>{label}</StyledPJNavBarText>
    </StyledNavBarPJTile>
  );
};

export default NavBarPJTile;
