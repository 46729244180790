import { StyledInfoBubble, StyledInfoBubbleTitle } from './style';

interface TotalInfoBubbleProps {
  children?: React.ReactNode;
  total: number;
  label?: string;
}

const TotalInfoBubble: React.FC<TotalInfoBubbleProps> = ({ children, total, label }) => {
  return (
    <>
      {total ? (
        <>
          <StyledInfoBubbleTitle>{label}</StyledInfoBubbleTitle>
          <StyledInfoBubble>{children}</StyledInfoBubble>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TotalInfoBubble;
