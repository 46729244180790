import GenericIconProps from './GenericIcon';

const StarIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 10;
  const baseHeight = 10;
  const baseColor = '#185123';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>Star</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dashboard2"
          transform="translate(-828.000000, -603.000000)"
          fill={color || baseColor}>
          <g id="Group-2" transform="translate(264.000000, 471.000000)">
            <g id="Dossier" transform="translate(32.000000, 120.669231)">
              <g id="Tag/traitement2.2.2" transform="translate(361.000000, 11.232692)">
                <polygon
                  id="Star"
                  points="176 7.65865385 173.061074 9.23642331 173.622359 5.89465396 171.244717 3.52799977 174.530537 3.04044219 176 0 177.469463 3.04044219 180.755283 3.52799977 178.377641 5.89465396 178.938926 9.23642331"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StarIcon;
