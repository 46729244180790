import {
  StyledStatusPieceContainer,
  StyledStatusPieceIconContainer,
  StyledStatusPieceText,
} from '../style';

export interface StatusPieceTileProps {
  label: string;
  icon: React.ReactNode;
  color: string;
}

const StatusPieceTile: React.FC<StatusPieceTileProps> = ({ label, icon, color }) => {
  return (
    <>
      <StyledStatusPieceContainer color={color}>
        <StyledStatusPieceIconContainer>{icon}</StyledStatusPieceIconContainer>
        <StyledStatusPieceText>{label}</StyledStatusPieceText>
      </StyledStatusPieceContainer>
    </>
  );
};

export default StatusPieceTile;
