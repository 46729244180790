import * as React from 'react';

const QuitArrow: React.FC = () => (
  <svg
    width="20px"
    height="16px"
    viewBox="0 0 20 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>1BD1F270-B55F-41C1-A17F-7A01469B3D33</title>
    <g
      id="Parcours-montage-dossier-🗂"
      stroke="none"
      strokeWidth="1"
      fill="#FFFFFF"
      fillRule="evenodd">
      <g
        id="pop/up/sauvegarder/quitter"
        transform="translate(-300.000000, -412.000000)"
        fill="#FFFFFF">
        <g
          id="Button/Primary/Green/M/Enabled"
          transform="translate(282.000000, 396.000000)">
          <g id="Group" transform="translate(18.000000, 12.000000)">
            <g
              id="Ic/24px/Action/plus-circle-Copy-2"
              transform="translate(0.000000, 4.000000)">
              <path
                d="M12.6666667,5.55111512e-14 C13.7712362,5.55111512e-14 14.6666667,0.8954305 14.6666667,2 L14.6666667,4.66666667 C14.6666667,5.0348565 14.3681898,5.33333333 14,5.33333333 C13.6318102,5.33333333 13.3333333,5.0348565 13.3333333,4.66666667 L13.3333333,2 C13.3333333,1.63181017 13.0348565,1.33333333 12.6666667,1.33333333 L2,1.33333333 C1.63181017,1.33333333 1.33333333,1.63181017 1.33333333,2 L1.33333333,14 C1.33333333,14.3681898 1.63181017,14.6666667 2,14.6666667 L12.6666667,14.6666667 C13.0348565,14.6666667 13.3333333,14.3681898 13.3333333,14 L13.3333333,11.3333333 C13.3333333,10.9651435 13.6318102,10.6666667 14,10.6666667 C14.3681898,10.6666667 14.6666667,10.9651435 14.6666667,11.3333333 L14.6666667,14 C14.6666667,15.1045695 13.7712362,16 12.6666667,16 L2,16 C0.8954305,16 -2.0206059e-14,15.1045695 -2.0206059e-14,14 L-2.0206059e-14,2 C-2.0206059e-14,0.8954305 0.8954305,5.55111512e-14 2,5.55111512e-14 L12.6666667,5.55111512e-14 Z M10.472,3.528 C10.7326784,3.7886784 10.7326784,4.2113216 10.472,4.472 L7.60933333,7.33333333 L19.3333333,7.33333333 C19.7015232,7.33333333 20,7.63181017 20,8 C20,8.36818983 19.7015232,8.66666667 19.3333333,8.66666667 L7.60933333,8.66666667 L10.472,11.528 C10.6406294,11.6966294 10.7064866,11.942412 10.644764,12.172764 C10.5830414,12.403116 10.403116,12.5830414 10.172764,12.644764 C9.942412,12.7064866 9.69662936,12.6406294 9.528,12.472 L5.528,8.472 C5.40261107,8.34692765 5.33214162,8.17710301 5.33214162,8 C5.33214162,7.82289699 5.40261107,7.65307235 5.528,7.528 L9.528,3.528 C9.7886784,3.2673216 10.2113216,3.2673216 10.472,3.528 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default QuitArrow;
