const CheckIcon: React.FC<{ className?: string; color?: string }> = ({
  className,
  color,
}) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill={color || 'currentColor'}
      fillRule="evenodd"
      d="M8.531 17.198L20.724 5.215a.75.75 0 111.052 1.07l-12.719 12.5a.75.75 0 01-1.051 0l-5.782-5.682a.75.75 0 011.052-1.07l5.255 5.165z"
    />
  </svg>
);

export default CheckIcon;
