import NextIcon from 'icons/NextIcon';
import StyledNextButton from './style';

interface NextButtonProps {
  isDisabled?: boolean;
  handleonClick: () => void;
  label?: string;
  icon?: React.ReactNode;
}

const NextButton: React.FC<NextButtonProps> = ({
  isDisabled,
  handleonClick,
  label,
  icon,
}) => {
  return (
    <>
      {isDisabled ? (
        <StyledNextButton disabled>
          {icon}
          <span style={{ marginLeft: '1rem' }}>{label}</span>
        </StyledNextButton>
      ) : (
        <StyledNextButton
          onClick={handleonClick}
          onKeyDown={e => {
            if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter')
              handleonClick();
          }}
          tabIndex={0}>
          {icon}
          <span style={{ marginLeft: icon ? '1rem' : '', whiteSpace: 'nowrap' }}>
            {label}
          </span>
        </StyledNextButton>
      )}
    </>
  );
};

NextButton.defaultProps = {
  label: 'Suivant',
  icon: <NextIcon />,
};

export default NextButton;
