import GenericIconProps from './GenericIcon';

const ContractPaper: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 24;
  const baseHeight = 24;

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5338331,2.8114057 C18.7642264,2.21110555 18.1971478,2.72136068 17.4882995,3.54177089 C17.2553922,3.81190595 16.0807294,5.37268634 14.7541705,7.15357679 L14.7541705,0.60030015 C14.7541705,0.270135068 14.4807576,0 14.1465862,0 L4.58726096,0 C4.4252385,0 4.27334244,0.060030015 4.1518256,0.180090045 L0.172148866,4.10205103 C0.0607584234,4.21210605 0,4.37218609 0,4.53226613 L0,19.3996998 C0,19.7298649 0.273412905,20 0.607584234,20 L14.1364598,20 C14.4706312,20 14.7440441,19.7298649 14.7440441,19.3996998 L14.7440441,11.1855928 L19.6857292,4.59229615 C19.705982,4.57228614 19.7161084,4.54227114 19.7363612,4.51225613 C19.9895213,4.012006 20.2528078,3.36168084 19.5338331,2.8114057 Z M1.21516847,5.13256628 L4.48599693,5.13256628 C4.82016825,5.13256628 5.09358116,4.86243122 5.09358116,4.53226613 L5.09358116,1.2006003 L13.5288756,1.2006003 L13.5288756,8.7843922 C11.9694094,10.8754377 10.5213336,12.8364182 10.3896904,13.0165083 C10.3491848,13.0765383 10.3188056,13.1365683 10.2985528,13.2066033 L9.72134774,15.6578289 C9.67071572,15.857929 9.8833702,15.827914 9.96438143,15.7778889 L12.1618111,14.5472736 C12.2225695,14.5072536 12.2833279,14.4672336 12.3238335,14.4072036 L13.5187492,12.8164082 L13.5187492,18.7893947 L1.21516847,18.7893947 L1.21516847,5.13256628 Z"
        id="Shape"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M6.89608105,14.3971986 L3.56449417,14.3971986 C3.23032284,14.3971986 2.96703634,14.6673337 2.96703634,14.9974987 C2.96703634,15.3276638 3.23032284,15.5977989 3.56449417,15.5977989 L6.89608105,15.5977989 C7.22012598,15.5977989 7.49353888,15.3276638 7.49353888,14.9974987 C7.49353888,14.6673337 7.23025238,14.3971986 6.89608105,14.3971986 Z"
        id="Path"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M7.95935346,10.9754877 L3.51386215,10.9754877 C3.18981723,10.9754877 2.91640432,11.2456228 2.91640432,11.5757879 C2.91640432,11.905953 3.17969082,12.176088 3.51386215,12.176088 L7.95935346,12.176088 C8.28339839,12.176088 8.55681129,11.905953 8.55681129,11.5757879 C8.55681129,11.2456228 8.28339839,10.9754877 7.95935346,10.9754877 Z"
        id="Path"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M3.62525259,8.54427214 L11.2301819,8.54427214 C11.5542268,8.54427214 11.8276397,8.27413707 11.8276397,7.94397199 C11.8276397,7.6138069 11.5643532,7.34367184 11.2301819,7.34367184 L3.62525259,7.34367184 C3.30120767,7.34367184 3.02779476,7.6138069 3.02779476,7.94397199 C3.02779476,8.27413707 3.29108127,8.54427214 3.62525259,8.54427214 Z"
        id="Path"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

ContractPaper.defaultProps = {
  color: '#FFF',
};

export default ContractPaper;
