const QuestionMark: React.FC = () => {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
      <title>Ic/24px/Action/question-circle24px</title>
      <path
        d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 1.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5Zm-.224 12.011c.761 0 1.287.485 1.287 1.175 0 .666-.524 1.159-1.286 1.159-.73 0-1.262-.492-1.262-1.159 0-.69.531-1.175 1.261-1.175Zm.23-8.886c1.746 0 3.341.912 3.341 2.801 0 1.349-.793 1.992-1.554 2.57-.922.699-1.263.96-1.263 1.858v.131c0 .173-.14.313-.313.313h-1.013a.313.313 0 0 1-.313-.308l-.004-.271c-.047-1.159.62-1.873 1.46-2.484.74-.555 1.207-.92 1.207-1.714 0-1.031-.785-1.46-1.643-1.46-1.002 0-1.565.598-1.677 1.418-.023.171-.16.312-.333.312H8.87a.296.296 0 0 1-.301-.309c.118-2.12 1.853-2.857 3.437-2.857Z"
        fill="#292C2E"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default QuestionMark;
