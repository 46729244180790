export const SIDE_MENU_HOME = 'Accueil';

export const SIDE_MENU_MY_FILES = 'Mes dossiers';
export const SIDE_MENU_CREATE_FILE = 'Créer un dossier';
export const SIDE_MENU_SEARCH_FILE = 'Rechercher un dossier';
export const SIDE_MENU_RECHERCHE_BORDEREAU = 'Rechercher bordereaux';
export const SIDE_MENU_FINANCIAL_CALCULATOR = 'Calculette financière';

export const SIDE_MENU_MY_SYGMA_OFFERS = 'Mes offres';
export const SIDE_MENU_PRODUCTS = 'Produits';
export const SIDE_MENU_PRICING = 'Tarification';
export const SIDE_MENU_INSURANCE = 'Assurance';

export const SIDE_MENU_SIGMA_TOOLS = 'Mes outils';
export const SIDE_MENU_DOCUMENTATION = 'Documentation';
export const SIDE_MENU_LEGAL = 'Règlementation';
export const SIDE_MENU_TRAINING = 'Formation';
export const SIDE_MENU_NEWS_AND_INFOS = 'Voir les nouveautés';
export const SIDE_MENU_ABOUT_US = 'À propos de nous';

export const SIDE_MENU_MY_USEFUL_LINKS = 'Mes liens utiles';
export const SIDE_MENU_BNPP_PF = 'BNPP PF';
export const SIDE_MENU_MY_CHALLENGE = 'My challenge';

export const SIDE_MENU_LOGOUT = 'Déconnexion';
