const ActionEdit: React.FC = () => (
  <svg width={13} height={13} xmlns="http://www.w3.org/2000/svg">
    <title>32A7F6F5-26E3-43FF-AE30-DFF19A09215D</title>
    <path
      d="M5.872 1.256a.542.542 0 0 1 0 1.084H1.726a.643.643 0 0 0-.643.642v8.292c0 .355.288.643.643.643h8.292a.643.643 0 0 0 .642-.643V7.128a.542.542 0 0 1 1.084 0v4.146c0 .953-.773 1.726-1.726 1.726H1.726A1.726 1.726 0 0 1 0 11.274V2.982c0-.953.773-1.726 1.726-1.726h4.146Zm5.835 1.047a.715.715 0 1 0-1.01-1.01l-5.52 5.52L4.84 8.16l1.347-.336 5.52-5.52Zm.766-1.776c.703.702.703 1.84 0 2.542L6.847 8.696a.542.542 0 0 1-.252.142l-2.369.592a.542.542 0 0 1-.656-.656l.592-2.37a.542.542 0 0 1 .142-.251L9.931.527a1.798 1.798 0 0 1 2.542 0Z"
      fill="#E78A08"
      fillRule="evenodd"
    />
  </svg>
);

export default ActionEdit;
