import { useEffect, useState } from 'react';
import Space from 'components/space/Space';
import {
  GuaranteeAdditionalInformationAction,
  GuaranteeAdditionalInformationState,
} from 'reducers/guaranteeAdditionalInformation/type';
import {
  StyledCardContentTile,
  StyledCardData,
  StyledCardLabel,
} from 'containers/operationClient/style';
import {
  StyledBadgeWrapper,
  StyledInputLabel,
} from 'containers/finalisationProjet/style';
import ActionValidatedIcon from 'icons/ActionPlusIcon';
import ActionEdit from 'icons/ActionEdit';
import { Asset } from 'reducers/operationClient/types';
import ExpandableCardTer from 'components/expandableCard/ExpandableCardTer';
import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import { getHypothequeValue, sortLongText } from 'containers/finalisationProjet/utils';
import { formatNumberByThousand } from 'utils/commun';
import AddressBlock from 'components/addressBlock/AddressBlock';
import { FullAddress } from 'types';
import CustructionYear from './additionalInformation/CustructionYear';
import AcquisitionYear from './additionalInformation/AcquisitionYear';
import LivingSpace from './additionalInformation/LivingSpace';
import EvaluationType from './additionalInformation/EvaluationType';
import ValidateBtn from './additionalInformation/ValidateBtn';
import AcquisitionValue from './additionalInformation/AcquisitionValue';

interface GuaranteeAdditionalInformationCreateModeProps {
  currentGuarantee: Asset;
  state: GuaranteeAdditionalInformationState;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
}

const GuaranteeAdditionalInformationCreateMode: React.FC<
  GuaranteeAdditionalInformationCreateModeProps
> = ({ currentGuarantee, state, dispatch }) => {
  const guarantee = state.guarantees.find(item => item.code === currentGuarantee.code);

  useEffect(() => {
    if (guarantee) {
      guarantee.useBorrowerAddress = currentGuarantee.isAssetAddressBorrowerAddress;
    }
  }, []);

  const [expandCard, setExpandCard] = useState(
    guarantee?.modificationInprogress || false,
  );
  const [currentGuaranteeAddress, setCurrentGaranteeAddress] = useState<FullAddress>({});

  const handleAddressChange = (newAddress: FullAddress) => {
    const data = state.guarantees.map(item => {
      if (item.code === currentGuarantee?.code) {
        setCurrentGaranteeAddress({ ...item.address, ...newAddress });
        return { ...item, address: { ...item.address, ...newAddress } };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateGuaranteesInformation',
      payload: { data, guaranteeCode: currentGuarantee?.code },
    });
  };

  useEffect(() => {
    handleAddressChange({ zipCode: currentGuarantee.zipCode });
  }, []);

  const renderAddress = () => {
    if (!guarantee?.useBorrowerAddress) {
      return (
        <>
          <Space marginTop="3.2rem" />

          <StyledInputLabel style={{ fontWeight: 'bold' }}>
            Renseignez l’adresse du bien :
          </StyledInputLabel>
          <Space marginTop="1.6rem" />
          <AddressBlock
            address={currentGuaranteeAddress}
            setAddress={handleAddressChange}
            lockedInputs={['zipCode']}
          />
        </>
      );
    }
    return null;
  };

  const getCollapsableContent = () => {
    return (
      <tr style={{ display: expandCard ? '' : 'none' }}>
        <td>
          <StyledCardContentTile>
            <StyledCardLabel>
              <span>{`Rang de l'inscription`}</span>
              <br />
              du bien
            </StyledCardLabel>
            <StyledCardData style={{ marginTop: '1.8rem' }}>
              {currentGuarantee.inscriptionRank}
            </StyledCardData>
          </StyledCardContentTile>
        </td>
        <td>
          <StyledCardContentTile>
            <StyledCardLabel>
              <span>Valeur des hypothèques</span>
              <br /> conservées
            </StyledCardLabel>
            <StyledCardData style={{ marginTop: '1.8rem' }}>
              {currentGuarantee.keptMortagagesValue}
            </StyledCardData>
          </StyledCardContentTile>
        </td>
        <td>
          <StyledCardContentTile>
            <StyledCardLabel>
              <span>Valeur libre</span> <br />
              {`d'hypotèque retenue`}
            </StyledCardLabel>
            <StyledCardData style={{ marginTop: '1.8rem' }}>
              {getHypothequeValue(
                currentGuarantee.inscriptionRank,
                currentGuarantee.currentValue,
                currentGuarantee.keptMortagagesValue,
              )}
            </StyledCardData>
          </StyledCardContentTile>
        </td>
        <td />
      </tr>
    );
  };

  const getBadge = (isComplete: boolean) => {
    if (isComplete) {
      return (
        <StyledBadgeWrapper style={{ color: '#28833C' }}>
          <ActionValidatedIcon color="#28833C" />
          <span>Données renseignées</span>
        </StyledBadgeWrapper>
      );
    }
    return (
      <StyledBadgeWrapper
        style={{
          color: '#E78A08',
        }}>
        <ActionEdit />
        <span>Données à compléter</span>
      </StyledBadgeWrapper>
    );
  };

  if (!guarantee) {
    return null;
  }

  return (
    <>
      <ExpandableCardTer
        accentColor={guarantee?.isAdditionalInformationCompleted ? '#28833C' : '#E78A08'}
        badge={getBadge(guarantee.isAdditionalInformationCompleted)}>
        <table
          style={{
            width: '100%',
            marginLeft: '2.4rem',
            tableLayout: 'fixed',
            paddingRight: '2rem',
          }}>
          <tbody>
            <tr className="table-td-space">
              <td style={{ width: '25%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Type de bien</StyledCardLabel>
                  <StyledCardData>
                    {sortLongText(currentGuarantee.type?.libelle)}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '25%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Usage du bien</StyledCardLabel>
                  <StyledCardData>
                    {sortLongText(currentGuarantee.usage?.libelle)}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '25%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Valeur du bien</StyledCardLabel>
                  <StyledCardData>
                    {formatNumberByThousand(currentGuarantee.currentValue || '')} €
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td
                style={{ width: '3%', cursor: 'pointer' }}
                role="presentation"
                onKeyDown={() => setExpandCard(prev => !prev)}
                onClick={() => setExpandCard(prev => !prev)}>
                {expandCard ? <ChevronUp /> : <ChevronDown />}
              </td>
            </tr>
            {getCollapsableContent()}
          </tbody>
        </table>
        {expandCard && (
          <>
            <div style={{ marginLeft: '2.4rem' }}>
              <>{renderAddress()}</>
              <Space marginTop="3.6rem" />
              <StyledInputLabel style={{ fontWeight: 'bold' }}>
                Renseignez les caractéristiques du bien :
              </StyledInputLabel>
              <Space marginTop="1.6rem" />
              <CustructionYear
                currentGuarantee={guarantee}
                state={state}
                dispatch={dispatch}
              />
              <Space marginTop="2.4rem" />
              <AcquisitionYear
                currentGuarantee={guarantee}
                state={state}
                dispatch={dispatch}
              />
              <Space marginTop="2.4rem" />
              <AcquisitionValue
                currentGuarantee={guarantee}
                state={state}
                dispatch={dispatch}
              />
              <Space marginTop="2.4rem" />
              <LivingSpace
                currentGuarantee={guarantee}
                state={state}
                dispatch={dispatch}
              />
              <Space marginTop="2.4rem" />
              <EvaluationType
                currentGuarantee={guarantee}
                state={state}
                dispatch={dispatch}
              />
              <Space marginTop="3.2rem" />
              {guarantee?.useBorrowerAddress ? (
                <StyledInputLabel>
                  L’adresse du bien est identique à celle de l’emprunteur
                </StyledInputLabel>
              ) : (
                <></>
              )}
              <Space marginTop="3.2rem" />
              <ValidateBtn
                mode="create"
                currentGuarantee={guarantee}
                state={state}
                dispatch={dispatch}
              />
              <Space marginTop="3.2rem" />
            </div>
          </>
        )}
      </ExpandableCardTer>
      <Space marginBottom="2rem" />
    </>
  );
};

export default GuaranteeAdditionalInformationCreateMode;
