export const SAVE_QUIT_TITLE = 'Souhaitez-vous sauvegarder et quitter ?';
export const SAVE_QUIT_DATA_OK = `Les informations saisies seront sauvegardées et vous serez redirigé vers l'accueil.`;
export const SAVE_QUIT_DATA_KO = `Seules les données saisies jusqu'à la page précédente seront sauvegardées. Vous serez redirigé vers l'accueil.`;

export const FAILED_RELIABILITY_ADDRESS_TITLE =
  "Adresse  - Saisie non trouvée - Confirmation adresse postale de l'emprunteur";
export const FAILED_RELIABILITY_ADDRESS_CONTENT =
  "Souhaitez-vous vérifier l'adresse postale de l'emprunteur ?";
export const CORRECTION_RELIABILITY_ADDRESS_CONTENT =
  "L'adresse que vous avez saisie a été corrigée automatiquement.\nVoulez-vous continuer avec cette adresse ?";

export const FAILED_RELIABILITY_MAIL_TITLE =
  'Email - Saisie non trouvée - Confirmation adresse email';
export const FAILED_RELIABILITY_MAIL_CONTENT = "Souhaitez-vous vérifier l'adresse email";
