import styled from 'styled-components';

export const StyledInputCategoryTitle = styled.span`
  color: #292c2e;
  font-size: 16px;
  font-weight: bold;
`;

export const StyledBorrowerDisplay = styled.div`
  box-sizing: border-box;
  height: 5.6rem;
  width: fit-content;
  border: 1px solid #b1b5b9;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
`;

export const StyledDisabledText = styled.span`
  color: #b1b5b9;
  font-size: 16px;
`;

export const StyledRibContainer = styled.div`
  height: 328px;
  width: 712px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`;

export const StyledFailedModalIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledFailedModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const StyledFailedModalErrorTitle = styled.span`
  color: #d52726;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: bold;
`;

export const StyledFailedModalText = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 18px;
`;

export const StyledFailedModalButtonsContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: flex-end;
`;

export const StyledDisabledRadioButton = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: solid 1px #cccccc;
  background-color: #f2f3f3;
`;
