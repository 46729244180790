import BaseButton from 'components/designSystem/BaseButton';
import styled from 'styled-components';

export const StyledCardContainer = styled.div`
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
`;

export const StyledCardRowContainer = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  flex-grow: 1;
`;

export const StyledCardLastColumnContainer = styled.div`
  justify-content: end;
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-left: 2.4rem;
`;

export const StyledCardMainBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 2.2rem;
`;

export const StyledCardColumnContainer = styled.div<{
  order?: number;
}>`
  order: ${props => (props.order ? props.order : 1)};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 2.4rem;
`;

export const StyledHiddenContentContainer = styled.div`
  content-visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

export const StyledCardColorAccent = styled.div`
  width: 0.8rem;
  border-radius: 0.8rem 0 0 0.8rem;
  background-color: ${props => (props.color ? props.color : '#B1B5B9')};
`;

export const StyledCardContentContainer = styled.div`
  display: flex;
  align-items: center stretch;
  flex: auto;
`;

export const StyledCardExpandContainer = styled.div`
  display: flex;
  width: 2rem;
`;

export const StyledEditCardButton = styled(BaseButton)`
  margin-right: 4rem;
  margin-left: 2.4rem;
`;

export const StyledCardLineContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
