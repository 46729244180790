import styled from 'styled-components';

export const StyledWaitingFilesCard = styled.div`
  box-sizing: border-box;
  height: 53.2rem;
  width: 80.1rem;
  border: 1px solid #6f757c;
  border-radius: 4px;
  padding: 0 3.25rem;
`;

export const StyledWaitingFilesCardTitle = styled.div`
  padding-top: 2.45rem;
  padding-bottom: 2.4rem;
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
`;

export const StyledWaitingFilesCardSection = styled.div`
  height: 15.1rem;
`;

export const StyledWaitingFilesCardLabel = styled.div`
  flex-shrink: 0;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;

export const StyledWaitingFilesCardBar = styled.div`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #c7cacd;
`;
export const StyledSeeOtherFilesWrapper = styled.span`
  cursor: pointer;
  display: flex;
`;
export const StyledSeeOtherFilesTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
  white-space: nowrap;
  margin-left: 0.8rem;
`;
