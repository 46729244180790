import ExpandableCardTer from 'components/expandableCard/ExpandableCardTer';
import ShowInformationBar from 'components/showInformationBar/showInformationBar';
import Space from 'components/space/Space';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledHorizontalBar,
  StyledTotalData,
  StyledTotalLabel,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import { Loan, OperationState } from 'reducers/operationClient/types';
import { formatValues } from 'utils/commun';
import * as messages from '../../messages';

interface PretsConsultationProps {
  isProjectEditable: boolean | undefined;
}

const PretsConsultation: React.FC<PretsConsultationProps> = ({ isProjectEditable }) => {
  const [buybackLoans, setBuybackLoans] = useState<Loan[]>();
  const [keptLoans, setKeptLoans] = useState<Loan[]>();
  const [buybackExpandedRow, setBuybackExpandedRow] = useState<number>();
  const [keptExpandedRow, setKeptExpandedRow] = useState<number>();
  const [totalBuybackMonthlyPayment, settotalBuybackMonthlyPayment] = useState<number>();
  const [totalKeptMonthlyPayment, setTotalKeptMonthlyPayment] = useState<number>();
  const [totalBuybackBalance, setTotalBuybackBalance] = useState<number>();
  const [totalKeptBalance, setTotalKeptBalance] = useState<number>();

  useEffect(() => {
    const operationClientState = JSON.parse(
      sessionStorage.getItem('operationsClient') || '',
    ) as OperationState;

    if (operationClientState) {
      setBuybackLoans(
        operationClientState.loanList.filter(loan => {
          return loan.buyBack;
        }),
      );
      setKeptLoans(
        operationClientState.loanList.filter(loan => {
          return !loan.buyBack;
        }),
      );
      settotalBuybackMonthlyPayment(operationClientState.buybackLoansMonthlyPaymentTotal);
      setTotalKeptMonthlyPayment(operationClientState.keepLoansMonthlyPaymentTotal);
      setTotalBuybackBalance(operationClientState.buybackLoansTotal);
      setTotalKeptBalance(operationClientState.keepLoansTotal);
    }
  }, []);

  const isBuybackRowExpanded = (index: number) => {
    return index === buybackExpandedRow;
  };

  const isKeptRowExpanded = (index: number) => {
    return index === keptExpandedRow;
  };

  const renderExpandedRow = (loan: Loan, isRowExpanded: boolean) => {
    return (
      isRowExpanded && (
        <>
          <Space marginBottom="3.2rem" />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledCardRow>
              {loan.loanStartDate && (
                <StyledCardTile width="17.5rem">
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LOAN_LABEL_START_DATE}
                  </StyledCardLabel>
                  <StyledCardData>{loan.loanStartDate}</StyledCardData>
                </StyledCardTile>
              )}
              {loan.initialLoanCapital && (
                <StyledCardTile width="13.6rem">
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LOAN_LABEL_INITIAL_CAPITAL}
                  </StyledCardLabel>
                  <StyledCardData>
                    {formatValues(loan.initialLoanCapital, '€')}
                  </StyledCardData>
                </StyledCardTile>
              )}
              {loan.totalLoanDuration && (
                <StyledCardTile>
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LOAN_LABEL_TOTAL_DURATION}
                  </StyledCardLabel>
                  <StyledCardData>
                    {formatValues(loan.totalLoanDuration, 'mois')}
                  </StyledCardData>
                </StyledCardTile>
              )}
            </StyledCardRow>
            <StyledCardTile style={{ justifyContent: 'flex-end' }}>
              <StyledCardData>
                {loan.mortgage ? 'Incription hypothecaire' : null}
              </StyledCardData>
            </StyledCardTile>
          </div>
        </>
      )
    );
  };

  const renderLoan = (loan: Loan, index: number) => {
    const isRowExpanded = loan.buyBack
      ? isBuybackRowExpanded(loan.code)
      : isKeptRowExpanded(loan.code);

    const setExpandedRow = loan.buyBack ? setBuybackExpandedRow : setKeptExpandedRow;

    const actualLoanIndex = loan.buyBack
      ? index + 1
      : (buybackLoans?.length || 0) + index + 1;

    return (
      <div key={loan.code}>
        <Space marginBottom="3.2rem" />

        <StyledCardTitle>{`${messages.MAIN_CARD_LOAN_SUBTITLE}${actualLoanIndex}`}</StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledCardRow>
            <StyledCardTile width="17.5rem">
              <StyledCardLabel>{messages.MAIN_CARD_LOAN_LABEL_LOANER}</StyledCardLabel>
              <StyledCardData>{loan.loaner?.libelle}</StyledCardData>
            </StyledCardTile>
            <StyledCardTile width="13.6rem">
              <StyledCardLabel>
                {messages.MAIN_CARD_LOAN_LABEL_CREDIT_TYPE}
              </StyledCardLabel>
              <StyledCardData>{loan.creditType?.libelle}</StyledCardData>
            </StyledCardTile>
            <StyledCardTile>
              <StyledCardLabel>
                {messages.MAIN_CARD_LOAN_LABEL_MONTHLY_PAYMENT}
              </StyledCardLabel>
              <StyledCardData>{formatValues(loan.monthlyPayment, '€')}</StyledCardData>
            </StyledCardTile>
            {loan.balance && (
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LOAN_LABEL_BALANCE}</StyledCardLabel>
                <StyledCardData>{formatValues(loan.balance, '€')}</StyledCardData>
              </StyledCardTile>
            )}
          </StyledCardRow>
          <StyledCardTile style={{ justifyContent: 'flex-end' }}>
            <StyledCardData>{loan.restructure ? 'Restructuration' : null}</StyledCardData>
          </StyledCardTile>
        </div>

        {loan.loanStartDate ||
        loan.initialLoanCapital ||
        loan.totalLoanDuration ||
        loan.mortgage ? (
          <>
            {renderExpandedRow(loan, isRowExpanded)}

            <Space marginBottom="3.2rem" />

            <ShowInformationBar
              isRowExpanded={isRowExpanded}
              code={loan.code}
              setExpandedRow={setExpandedRow}
            />
          </>
        ) : (
          <>
            <Space marginBottom="3.2rem" />
            <StyledHorizontalBar
              color="#979797"
              style={{ height: '0.2rem', marginRight: '0' }}
            />
          </>
        )}
      </div>
    );
  };

  const renderLoanList = (type: 'buyback' | 'kept') => {
    const loanList = type === 'buyback' ? buybackLoans : keptLoans;

    const totalBalance = type === 'buyback' ? totalBuybackBalance : totalKeptBalance;
    const totalMonthlyPayment =
      type === 'buyback' ? totalBuybackMonthlyPayment : totalKeptMonthlyPayment;

    return (
      <ExpandableCardTer accentColor={type === 'buyback' ? '#008859' : '#B1B5B9'}>
        <div
          style={{ marginLeft: '2.4rem', marginBottom: '3.2rem', marginRight: '3.2rem' }}>
          <StyledCardTitle>
            {`${messages.MAIN_CARD_LOAN_TITLE}
            ${type === 'buyback' ? 'racheter' : 'conserver'}`}
          </StyledCardTitle>

          {loanList?.map((loan, index) => {
            return renderLoan(loan, index);
          })}

          <Space marginBottom="4rem" />

          <StyledCardRow style={{ gap: '0.8rem', justifyContent: 'space-between' }}>
            <div>
              <StyledTotalLabel>
                {messages.MAIN_CARD_LOAN_LABEL_TOTAL_MONTHLY_PAYMENT}
              </StyledTotalLabel>
              <StyledTotalData>
                {formatValues(totalMonthlyPayment?.toString(), '€')}
              </StyledTotalData>
            </div>
            <div>
              <StyledTotalLabel>
                {messages.MAIN_CARD_LOAN_LABEL_TOTAL_BALANCE}
              </StyledTotalLabel>
              <StyledTotalData>
                {formatValues(totalBalance?.toString(), '€')}
              </StyledTotalData>
            </div>
          </StyledCardRow>

          <StyledHorizontalBar style={{ height: '0.2rem', marginRight: '0' }} />

          <ConsultationEditButton
            isProjectEditable={isProjectEditable}
            navigateRoute="/operation_client"
          />
        </div>
      </ExpandableCardTer>
    );
  };

  return (
    <>
      {renderLoanList('buyback')}

      <Space marginBottom="4.8rem" />

      {keptLoans && keptLoans.length > 0 && renderLoanList('kept')}
    </>
  );
};

export default PretsConsultation;
