import Space from 'components/space/Space';
import React from 'react';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardTitle,
  StyleCardGrid,
  StyleCardGridItem,
} from 'containers/consultation/style';
import { IbanState } from 'reducers/iban/type';
import * as messages from '../../messages';

interface FinalisationIbanConsultationProps {
  iban: IbanState;
}

const FinalisationIbanConsultation: React.FC<FinalisationIbanConsultationProps> = ({
  iban,
}) => {
  return (
    <>
      <StyledCardTitle>{messages.FINALISATION_IBAN}</StyledCardTitle>
      <Space marginBottom="1.6rem" />

      <StyleCardGrid>
        <StyleCardGridItem style={{ width: 'auto' }}>
          <StyledCardLabel>IBAN</StyledCardLabel>
          <StyledCardData>
            {`${iban.ibanPart1} ${iban.ibanPart2} ${iban.ibanPart3} ${iban.ibanPart4} `}
            {`${iban.ibanPart5} ${iban.ibanPart6} ${iban.ibanPart7}`}
          </StyledCardData>
        </StyleCardGridItem>
        <StyleCardGridItem>
          <StyledCardLabel>BIC</StyledCardLabel>
          <StyledCardData>{iban.bic}</StyledCardData>
        </StyleCardGridItem>
      </StyleCardGrid>
    </>
  );
};

export default FinalisationIbanConsultation;
