import {
  AuthorizationNotifier,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  LocalStorageBackend,
  RedirectRequestHandler,
  TokenRequest,
} from '@openid/appauth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthorizationService from 'api/AuthorizationService';
import getEnvironmentVariables from 'utils/EnvironmentVariables';
import NoHashQueryStringUtils from 'utils/NoHashQueryStringUtils';

const Callback: React.FC = () => {
  const navigate = useNavigate();

  const handleFailedAuthentication = (error?: any) => {
    console.log(error);
    alert("Une erreur s'est produite lors de l'authentification, veuillez rééssayer.");
    navigate('/');
  };

  useEffect(() => {
    const authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto(),
    );

    const notifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

    notifier.setAuthorizationListener((request, response, error) => {
      console.log(request, response, error);
      if (response) {
        const envVariables = getEnvironmentVariables();
        let extras;
        if (request && request.internal) {
          extras = {};
          extras.code_verifier = request.internal.code_verifier;
          extras.client_secret = envVariables.pingClientSecret;
        }

        const tokenRequest = new TokenRequest({
          client_id: getEnvironmentVariables().pingClientId,
          redirect_uri: `${window.location.origin}/callback`,
          grant_type: 'authorization_code',
          code: response.code,
          extras,
        });

        console.log('getting config from issuer');
        AuthorizationServiceConfiguration.fetchFromIssuer(
          envVariables.pingDomain,
          new FetchRequestor(),
        )
          .then(oResponse => {
            console.log('perfoming token request');
            const configuration = oResponse;
            return tokenHandler.performTokenRequest(configuration, tokenRequest);
          })
          .then(oResponse => {
            console.log('saving token and navigating');
            sessionStorage.setItem('isUserAuthenticated', 'true');
            AuthorizationService.saveTokenInfo(oResponse)
              .then(
                () => {
                  let redirectUrl = '/dashboard';
                  const session_redirect_url = sessionStorage.getItem('redirect_url');
                  if (session_redirect_url != null) {
                    redirectUrl = session_redirect_url;
                    sessionStorage.removeItem('redirect_url');
                  }
                  navigate(redirectUrl);
                },
                e => {
                  handleFailedAuthentication(e);
                },
              )
              .catch(e => {
                handleFailedAuthentication(e);
              });
          })
          .catch(e => {
            handleFailedAuthentication(e);
          });
      }
      if (error) {
        handleFailedAuthentication(error);
      }
    });

    authorizationHandler
      .completeAuthorizationRequestIfPossible()
      .then(() => console.log('Completing authentication if possible'))
      .catch(error => {
        handleFailedAuthentication(error);
      });
  }, []);

  return <></>;
};

export default Callback;
