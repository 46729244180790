import { StyledBinIconWrapper } from 'components/chemise/style';
import DeleteConfirmationModal from 'components/modals/ConfirmationModals/DeleteConfirmationModal';
import BinIcon from 'icons/BinIcon';
import { ReactChild, useState } from 'react';

interface DeleteIconProps {
  icon?: ReactChild;
  onDeleteClick: () => void;
}

const DeleteIcon: React.FC<DeleteIconProps> = ({ icon, onDeleteClick }) => {
  const [isConfimationModalVisible, setIsConfirmationModalVisible] =
    useState<boolean>(false);

  return (
    <>
      {icon || (
        <StyledBinIconWrapper
          onClick={() => {
            setIsConfirmationModalVisible(true);
          }}>
          <BinIcon />
        </StyledBinIconWrapper>
      )}
      <DeleteConfirmationModal
        isVisible={isConfimationModalVisible}
        accept={() => {
          if (onDeleteClick) onDeleteClick();
          setIsConfirmationModalVisible(false);
        }}
        close={() => {
          setIsConfirmationModalVisible(false);
        }}
      />
    </>
  );
};

export default DeleteIcon;
