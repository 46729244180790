import styled from 'styled-components';

export const StyledContentContainer = styled.div`
  width: 54rem;
`;

export const StopLightMessageContainer = styled.div<{
  borderColor: string;
  backgroundColor: string;
}>`
  box-sizing: border-box;
  width: 540px;
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 24px;
  display: flex;
  align-items: center;
`;

export const StopLightMessageText = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.4rem;
  white-space: pre-line;
`;

export const ResultCard = styled.div`
  width: 54rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding: 2.4rem 3.2rem 2.8rem 3.2rem;
`;

export const ResultCartTitle = styled.span`
  color: #292c2e;
  font-family: Ubuntu !important;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
`;

export const ResultCardSubTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;

export const ResultCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const ResultCardEntry = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
`;

export const ResultCardText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export const StyledDottedLine = styled.div`
  align-self: end;
  flex-grow: 1;
  height: 0.1rem;
  margin: 0;
  margin-bottom: 0.55rem;
  background-image: linear-gradient(to right, #c7cacd 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
`;

export const StyledFullBar = styled.div`
  height: 0.1rem;
  flex-grow: 1;
  border-bottom-width: 1px;
  border-bottom-color: #b1b5b9;
  border-bottom-style: solid;
  margin-right: 2.7rem;
`;

export const FooterText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: pre-line;
`;

export const InfoText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;
