import TextButton from 'components/buttons/textButton/TextButton';
import BaseButton from 'components/designSystem/BaseButton';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import CloseModalConfirmation from 'components/modals/ConfirmationModals/CloseModalConfirmation';
import Modal from 'components/modals/Modal';
import CrossCircle from 'icons/CrossCircle';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import colors from 'styles/color';
import React, { useEffect, useState } from 'react';
import { StyledNumericLabel } from 'containers/operationClient/style';
import Space from 'components/space/Space';
import CustomInput from 'components/customInput/CustomInput';
import { Amount } from 'reducers/ressourcesMensuelles/types';
import { v4 as uuidv4 } from 'uuid';
import {
  StyledBtnWrapper,
  StyledCheckboxWrapper,
  StyledSelectionWrapper,
} from 'containers/communs/style';
import { CodeLibelle } from 'types';
import InformationArea from 'components/informationArea/InformationArea';
import WarningIcon from 'icons/WarningIcon';

export type SelectableModalItem = CodeLibelle & {
  isSelected: boolean;
  quantity: number;
};

interface OtherInputsModalProps {
  referential: CodeLibelle[];
  inputType: 'Income' | 'Charge';
  isModalVisible: boolean;
  alreadySelectedInputs: Amount[];
  onSaveSelectedInputs: (newSelectedInputs: Amount[]) => void;
  onCloseModal: () => void;
}
const OtherInputsModal: React.FC<OtherInputsModalProps> = ({
  referential,
  inputType,
  isModalVisible,
  alreadySelectedInputs,
  onSaveSelectedInputs,
  onCloseModal,
}) => {
  const [isCloseConfirmationVisible, setIsCloseConfirmationVisible] =
    useState<boolean>(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState<boolean>(false);
  const [selectableInputs, setSelectableInputs] = useState<SelectableModalItem[]>([]);

  const isAnyItemSelected = (): boolean => {
    return (
      selectableInputs.find(selectableInput => {
        return selectableInput.isSelected;
      }) !== undefined
    );
  };

  const updateInputList = input => {
    setSelectableInputs(
      selectableInputs.map(inputFromList => {
        if (inputFromList.code === input.code) {
          return input;
        }
        return inputFromList;
      }),
    );
  };

  const handleCheckboxClick = (item: SelectableModalItem) => {
    const newItem = { ...item };

    newItem.isSelected = !newItem.isSelected;

    updateInputList(newItem);
  };

  const returnSelection = () => {
    const finalItemsSelection = [] as Amount[];

    selectableInputs.forEach(input => {
      if (input.isSelected) {
        for (let i = 0; i < input.quantity; i += 1) {
          const finalInput = { ...input } as unknown as Amount;

          finalInput.value = '';
          finalItemsSelection.push(finalInput);
        }
      }
    });

    onSaveSelectedInputs(finalItemsSelection);
    setSelectableInputs([]);
  };

  useEffect(() => {
    let sumOfAllNeeds = alreadySelectedInputs.length;

    selectableInputs.forEach(item => {
      if (item.isSelected) {
        sumOfAllNeeds += item.quantity;
      }
    });

    const selectedItems = selectableInputs.filter(input => {
      return input.isSelected;
    });

    if (selectedItems.length > 0) {
      setIsSubmitButtonEnabled(true);
    } else {
      setIsSubmitButtonEnabled(false);
    }

    setIsSubmitButtonEnabled(
      selectedItems && selectedItems.length > 0 && sumOfAllNeeds <= 20,
    );
  }, [selectableInputs]);

  useEffect(() => {
    const temp: SelectableModalItem[] = [];

    (referential as SelectableModalItem[]).forEach(item => {
      const newItem = { ...item };
      newItem.quantity = 1;
      newItem.isSelected = false;
      temp.push(newItem);
    });

    setSelectableInputs(temp);
  }, [isModalVisible]);

  const renderOtherInputsModalContent = () => {
    return (
      <>
        <p
          style={{
            margin: '2.4rem 9.6rem',
            fontWeight: 'bold',
            fontFamily: 'Ubuntu',
            fontSize: '2.4rem',
          }}>
          {`Sélectionnez les ${inputType === 'Charge' ? 'charges' : 'revenus'} à ajouter`}
        </p>
        <div
          style={{ marginLeft: '9.6rem', marginRight: '9.6rem', marginBottom: '1.6rem' }}>
          <InformationArea icon={<WarningIcon color="#2275D7" />} backgroundColor="blue">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Le nombre de {inputType === 'Charge' ? 'charges' : 'revenus'} est limité à
              20.
            </span>
          </InformationArea>
        </div>
        <div className="element-in-modal" role="button" tabIndex={0}>
          {' '}
        </div>

        {selectableInputs.map(item => {
          return (
            <React.Fragment key={uuidv4()}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginRight: '9.6rem',
                }}>
                <StyledSelectionWrapper isSelected={item.isSelected}>
                  <StyledCheckboxWrapper>
                    <BaseCheckbox
                      checked={item.isSelected ? true : null}
                      onCheckedChanged={() => {
                        handleCheckboxClick(item);
                      }}>
                      <span>{item.libelle}</span>
                    </BaseCheckbox>
                  </StyledCheckboxWrapper>
                </StyledSelectionWrapper>
                <div>
                  <StyledNumericLabel>Quantité</StyledNumericLabel>
                  <Space marginBottom="0.4rem" />
                  <CustomInput
                    inputWidth="6.4rem"
                    name=""
                    isFocused={false}
                    value={item.quantity ? item.quantity : undefined}
                    onChange={newValue => {
                      const newItem = { ...item };
                      newItem.quantity = Number(newValue);
                      updateInputList(newItem);
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <StyledBtnWrapper>
          {isSubmitButtonEnabled ? (
            <BaseButton
              className="selection-btn"
              tabIndex={0}
              onKeyDownCapture={event => {
                if (event.key === 'Enter') {
                  returnSelection();
                }
              }}
              onClick={returnSelection}>
              <PlusCircleIcon color="#ffffff" />
              <span style={{ marginLeft: '1rem' }}>
                {`Ajouter les ${
                  inputType === 'Charge' ? 'charges' : 'revenus'
                } sélectionnées`}
              </span>
            </BaseButton>
          ) : (
            <BaseButton disabled className="selection-btn disabled-btn">
              <PlusCircleIcon color="#ffffff" />
              <span style={{ marginLeft: '1rem' }}>
                {`Ajouter les ${
                  inputType === 'Charge' ? 'charges' : 'revenus'
                } sélectionnées`}
              </span>
            </BaseButton>
          )}
          <TextButton
            width="8.8rem"
            label="Fermer"
            onClick={() => {
              if (isAnyItemSelected()) {
                setIsCloseConfirmationVisible(true);
              } else {
                onCloseModal();
              }
            }}>
            <CrossCircle color={colors.chartColors.mainGreen500} />{' '}
          </TextButton>
        </StyledBtnWrapper>
      </>
    );
  };

  return (
    <Modal
      isVisible={isModalVisible}
      width="67.6rem"
      onClose={() => {
        setIsCloseConfirmationVisible(true);
      }}>
      {renderOtherInputsModalContent()}

      <CloseModalConfirmation
        isVisible={isCloseConfirmationVisible}
        accept={() => {
          setIsCloseConfirmationVisible(false);
          onCloseModal();
        }}
        close={() => {
          (document.getElementsByClassName('element-in-modal')[0] as HTMLElement).focus();
          setIsCloseConfirmationVisible(false);
        }}
      />
    </Modal>
  );
};

export default OtherInputsModal;
