import { SessionStorageKeys } from 'types';
import { getExistingStorageByKey } from 'utils/storage';
import { v4 as uuidv4 } from 'uuid';
import {
  Asset,
  CurrentAssetAction,
  CurrentLoanAction,
  Loan,
  OperationAction,
  OperationState,
} from './types';
import {
  checkIfDatasAreOk,
  checkIfCurrentLoanDatasAreOk,
  checkIfCurrentAssetDatasAreOk,
} from './utils';

export const getCurrentLoanInitialState = (storageKey: SessionStorageKeys): Loan => {
  const currentLoanDefaultValues = {
    code: uuidv4(),
    isDataOk: false,
  };

  const valuesFromStorage = localStorage.getItem(storageKey);
  if (valuesFromStorage) {
    try {
      return JSON.parse(valuesFromStorage);
    } catch (error) {
      console.error(error);
      return currentLoanDefaultValues;
    }
  }
  return currentLoanDefaultValues;
};

export const getCurrentAssetInitialState = (storageKey: SessionStorageKeys): Asset => {
  const currentAssetDefaultValues = {
    code: -1, // Lucas, pourquoi le code est -1? Tu peux stp mettre un commentaire pour une meilleure compréhension?
    isDataOk: false,
  };

  const valuesFromStorage = localStorage.getItem(storageKey);
  if (valuesFromStorage) {
    try {
      return JSON.parse(valuesFromStorage);
    } catch (error) {
      console.error(error);
      return currentAssetDefaultValues;
    }
  }
  return currentAssetDefaultValues;
};

export const getOperationInitialState = (
  storageKey: SessionStorageKeys,
): OperationState => {
  const operationDefaultValues = {
    loanList: [],
    assetList: [],
    otherNeedsList: [],
    buybackDebtsList: [],
    buybackLoansTotal: 0,
    buybackLoansMonthlyPaymentTotal: 0,
    keepLoansTotal: 0,
    keepLoansMonthlyPaymentTotal: 0,
    totalAssetsValue: 0,
    totalOtherNeedsAmount: 0,
    totalDebtValue: 0,
    hasInsurance: false,
    hasOtherNeeds: false,
    hasBuybackDebt: false,
    incidentPaymentList: [],
    hasPaymentIncident: false,
    hasPaymentDefault: false,
    nbPaymentDefault: 0,
    isDataOk: false,
  };

  return getExistingStorageByKey(storageKey, operationDefaultValues) as OperationState;
};

const calculateLoansTotals = (state: OperationState): OperationState => {
  const newState = { ...state };

  let buybackLoansTotal = 0;
  let BuybackLoansMonthlyPaymentTotal = 0;
  let KeepLoansTotal = 0;
  let KeepLoansMonthlyPaymentTotal = 0;

  state.loanList.forEach(item => {
    const balance = Number(item.balance?.replaceAll(' ', ''));
    const monthlyPayment = Number(item.monthlyPayment?.replaceAll(' ', ''));

    if (!Number.isNaN(balance)) {
      if (item.buyBack) {
        buybackLoansTotal += balance;
      } else {
        KeepLoansTotal += balance;
      }
    }

    if (!Number.isNaN(monthlyPayment)) {
      if (item.buyBack) {
        BuybackLoansMonthlyPaymentTotal += monthlyPayment;
      } else {
        KeepLoansMonthlyPaymentTotal += monthlyPayment;
      }
    }
  });

  newState.buybackLoansTotal = buybackLoansTotal;
  newState.buybackLoansMonthlyPaymentTotal = BuybackLoansMonthlyPaymentTotal;
  newState.keepLoansTotal = KeepLoansTotal;
  newState.keepLoansMonthlyPaymentTotal = KeepLoansMonthlyPaymentTotal;
  return newState;
};

const calculateAssetsTotals = (state: OperationState): OperationState => {
  const newState = { ...state };

  let AssetsTotal = 0;

  state.assetList.forEach(item => {
    const assetValue = Number(item.currentValue?.replaceAll(' ', ''));

    if (!Number.isNaN(assetValue)) {
      AssetsTotal += assetValue;
    }
  });

  newState.totalAssetsValue = AssetsTotal;
  return newState;
};

const calculateOtherNeedsTotals = (state: OperationState): OperationState => {
  const newState = { ...state };

  let OtherNeedsTotal = 0;

  state.otherNeedsList.forEach(item => {
    OtherNeedsTotal += item.amount;
  });

  newState.totalOtherNeedsAmount = OtherNeedsTotal;
  return newState;
};

const calculateBuybackDebtTotals = (state: OperationState): OperationState => {
  const newState = { ...state };

  let BuybackDebtTotal = 0;

  state.buybackDebtsList.forEach(item => {
    BuybackDebtTotal += item.amount;
  });

  newState.totalDebtValue = BuybackDebtTotal;
  return newState;
};

export const currentLoanReducer = (state: Loan, action: CurrentLoanAction): Loan => {
  let newState = { ...state };
  switch (action.type) {
    case 'setCurrentLoan':
      newState = action.payload;
      newState.isDataOk = checkIfCurrentLoanDatasAreOk(newState);
      return newState;
    default:
      return state;
  }
};

export const currentAssetReducer = (state: Asset, action: CurrentAssetAction): Asset => {
  let newState = { ...state };
  switch (action.type) {
    case 'setCurrentAsset':
      newState = action.payload;

      if (newState.zipCode?.length === 0) {
        newState.city = '';
      }

      newState.isDataOk = checkIfCurrentAssetDatasAreOk(newState);
      return newState;
    default:
      return state;
  }
};

export const operationReducer = (
  state: OperationState,
  action: OperationAction,
): OperationState => {
  let newState = { ...state };
  switch (action.type) {
    case 'setLoanList':
      newState.loanList = action.payload;
      newState = calculateLoansTotals(newState);
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setAssetList':
      newState.assetList = action.payload;
      newState = calculateAssetsTotals(newState);
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setNewNeedsList':
      newState.otherNeedsList = action.payload;
      newState = calculateOtherNeedsTotals(newState);
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBuybackDebtsList':
      newState.buybackDebtsList = action.payload;
      newState = calculateBuybackDebtTotals(newState);
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasInsurance':
      newState.hasInsurance = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasOtherNeeds':
      newState.hasOtherNeeds = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasBuybackDebts':
      newState.hasBuybackDebt = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setIncidentPaymentList':
      newState.incidentPaymentList = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasPaymentIncident':
      newState.hasPaymentIncident = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasPaymentDefault':
      newState.hasPaymentDefault = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setNbPaymentDefault':
      newState.nbPaymentDefault = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    default:
      return state;
  }
};
