import { createComponent } from '@lit-labs/react';
import React from 'react';

export class BaseCheckboxDS extends HTMLElement {
  class?: string;

  checked: unknown;

  label?: string;

  disabled?: boolean;
}

export const BaseCheckbox = createComponent(React, 'base-checkbox', BaseCheckboxDS, {
  onToggleChanged: 'toggle-changed',
  onCheckedChanged: 'checked-changed',
});
