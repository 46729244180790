import { getCountries } from 'api/referentialService';
import BreadCrumbLevel2 from 'components/breadcrumb/BreadCrumbLevel2';
import Loader from 'components/Loader';
import Space from 'components/space/Space';
import IdentityForm from 'containers/donneesClient/identity/IdentityForm';
import RessourcesMensuellesForm from 'containers/donneesClient/ressourcesMensuelles/RessourcesMensuellesForm';
import SituationFoyerForm from 'containers/donneesClient/situationFoyer/SituationFoyer';
import SituationProfessionnelleForm from 'containers/donneesClient/situationProfessionnelle/SituationProfessionnelle';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Country } from 'types';

const DonneesClientForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [, setError] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentStep(
      sessionStorage.getItem('clientDataCurrentStep')
        ? Number(sessionStorage.getItem('clientDataCurrentStep'))
        : 1,
    );

    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await getCountries();
        const countries: Country[] = [];
        response.Resultat.forEach(item => {
          const cleanLibelle = item.libelle
            .replaceAll('(', ' ')
            .replaceAll(')', '')
            .replaceAll('  ', ' ');
          countries.push({ code: item.code, libelle: cleanLibelle });
        });
        setCountryList(countries);
      } catch (err) {
        setError(`${err}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCountries();
  }, []);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <IdentityForm
            countryList={countryList}
            onNextButtonClick={() => {
              sessionStorage.setItem(
                'clientDataCurrentStep',
                (currentStep + 1).toString(),
              );
              setCurrentStep(prevState => prevState + 1);
            }}
          />
        );
      case 2:
        return (
          <SituationFoyerForm
            onNextButtonClick={() => {
              sessionStorage.setItem(
                'clientDataCurrentStep',
                (currentStep + 1).toString(),
              );
              setCurrentStep(prevState => prevState + 1);
            }}
            onBackButtonClick={() => {
              sessionStorage.setItem(
                'clientDataCurrentStep',
                (currentStep - 1).toString(),
              );
              setCurrentStep(prevState => prevState - 1);
            }}
          />
        );
      case 3:
        return (
          <SituationProfessionnelleForm
            onNextButtonClick={() => {
              sessionStorage.setItem(
                'clientDataCurrentStep',
                (currentStep + 1).toString(),
              );
              setCurrentStep(prevState => prevState + 1);
            }}
            onBackButtonClick={() => {
              sessionStorage.setItem(
                'clientDataCurrentStep',
                (currentStep - 1).toString(),
              );
              setCurrentStep(prevState => prevState - 1);
            }}
            countryList={countryList}
          />
        );
      case 4:
        return (
          <RessourcesMensuellesForm
            onNextButtonClick={() => {
              navigate('/operation_client');
            }}
            onBackButtonClick={() => {
              sessionStorage.setItem(
                'clientDataCurrentStep',
                (currentStep - 1).toString(),
              );
              setCurrentStep(prevState => prevState - 1);
            }}
          />
        );
      default:
        return <p>Step does not exist</p>;
    }
  };
  return (
    <>
      <Space marginBottom="11.2rem" />
      <BreadCrumbLevel2
        steps={[
          'Identité client',
          'Situation foyer',
          'Situation professionnelle',
          'Revenus et charges',
        ]}
        currentStep={currentStep}
      />
      <Space marginBottom="4rem" />
      {isLoading ? <Loader animationDuration={2} /> : renderStep()}
    </>
  );
};

export default DonneesClientForm;
