import jsPDF from 'jspdf';
import {
  courtierPage,
  identityPage,
  loanPage,
  foyerProPage,
  deptNeedPage,
  comportementSynthesePage,
  ressourcePage,
  garantieIbanPage,
  recapPage,
} from './pageDossierClient';
import * as messages from '../messages';

const dossierClientPdf = (): File => {
  // eslint-disable-next-line new-cap
  let doc = new jsPDF();

  const img = new Image();
  img.src = `${process.env.PUBLIC_URL}/logo_sygma.jpg`;

  doc.addImage(img, 'jpeg', 0, 0, 60, 25);
  // Titre page PDF
  doc.setFontSize(16);
  doc.setFont('Helvetica', 'bold');
  doc.text(`${messages.CLIENT_FILE.mainTitle}`, 140, 25, {
    maxWidth: 160,
    align: 'center',
  });

  doc = courtierPage(doc);
  doc = identityPage(doc);
  doc = foyerProPage(doc);
  doc = ressourcePage(doc);
  doc = loanPage(doc);
  doc = deptNeedPage(doc);
  doc = comportementSynthesePage(doc);
  doc = garantieIbanPage(doc);
  doc = recapPage(doc);

  doc.save(
    `${messages.CLIENT_FILE.dlName}_${sessionStorage.getItem('numeroProjet')}.pdf`,
  );

  return new File(
    [doc.output('blob')],
    `${messages.CLIENT_FILE.dlName}_${sessionStorage.getItem('numeroProjet')}.pdf`,
  );
};

export default dossierClientPdf;
