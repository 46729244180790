import { StyledQuestionMark, StyledTooltipText, StyledTooltipWrapper } from './style';

interface TooltipProps {
  children?: React.ReactNode;
  leftPosition: number;
  topPosition: number;
  icon?: React.ReactNode;
  width?: string;
}
const Tooltip: React.FC<TooltipProps> = ({
  children,
  leftPosition,
  topPosition,
  icon,
  width,
}) => {
  return (
    <StyledTooltipWrapper>
      {icon ? <>{icon}</> : <StyledQuestionMark>?</StyledQuestionMark>}
      <StyledTooltipText
        topPosition={topPosition}
        leftPosition={leftPosition}
        width={width}>
        {children}
      </StyledTooltipText>
    </StyledTooltipWrapper>
  );
};

export default Tooltip;
