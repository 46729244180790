import { v4 as uuidv4 } from 'uuid';
import ChemiseConsultation from 'components/chemise/chemiseConsultation';
import InformationArea from 'components/informationArea/InformationArea';
import Modal from 'components/modals/Modal';
import Space from 'components/space/Space';
import Loader from 'components/Loader';
import FormFooter from 'components/formFooter/FormFooter';
import DropFileZone from 'components/dropFileZone';
import Uploader from 'components/uploader';
import { StyledTitle } from 'containers/communs/style';
import PaperPlaneIcon from 'icons/PaperPlaneIcon';
import CheckInCircle from 'icons/CheckInCircle';
import ErrorIcon from 'icons/ErrorIcon';
import ListDocumentIcon from 'icons/ListDocumentIcon';
import { useEffect, useReducer, useState } from 'react';
import {
  getTransmissionPiecesInitialState,
  transmissionPiecesReducer,
} from 'reducers/transmissionPieces/transmissionPieces';
import { getUploadUrl, transfertAll, uploadFile } from 'api/transfertDocumentService';
import { ChemiseData } from 'reducers/transmissionPieces/types';
import {
  CodeLibelle,
  GetUploadUrlApiRequestParams,
  PJWithStatus,
  PiecesJustificatives,
  UploadFileApiRequestBody,
  UploadFileApiResponse,
  UserInfos,
} from 'types';
import { StyledErrorWrapper } from 'components/chemise/style';
import * as messages from '../messages';
import ZoneCommentaire from './zoneCommentaire';

const ACCEPTED_FILES_FORMAT = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const OngletAutresJustificatives: React.FC = () => {
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [filesAlreadyUploaded, setFilesAlreadyUploaded] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showFileExplorer, setShowFileExplorer] = useState<boolean>(false);
  const [uploadHasError, setUploadHasError] = useState<boolean>(false);
  const [uploadApiErrorMessage, setUploadApiErrorMessage] = useState<string>('');
  const [transmissionPiecesState, transmissionPiecesDispatch] = useReducer(
    transmissionPiecesReducer,
    getTransmissionPiecesInitialState('transmissionPieces'),
  );

  const [sendingFiles, setSendingFiles] = useState<boolean>(false);

  const piecesJustificatives = JSON.parse(
    sessionStorage.getItem('piecesJustificatives') || '{}',
  ) as PiecesJustificatives;

  const ONE_MO_TO_BYTE = 1_048_576;
  const MAX_FILE_SIZE_IN_MO = 19 * ONE_MO_TO_BYTE; // 19 Mo maximum

  const chemise =
    piecesJustificatives.listePiecesJustificativesPropCialUploadable.listeChemises.find(
      item => item.chemId === '010',
    );

  const listPiece: PJWithStatus[] = [];

  chemise?.listePiecesJustificatives.forEach(item => {
    const pieceName = `${item.pceJusLib[0]}${item.pceJusLib.substring(1).toLowerCase()} ${
      item.cpltPceJusLib
    } ${item.pceJusCmtLib}`;
    listPiece.push({ libelle: pieceName, status: item.statPceJusLib });
  });

  useEffect(() => {
    if (transmissionPiecesState.isDataOk && !filesAlreadyUploaded) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [transmissionPiecesState, filesAlreadyUploaded]);

  const renderErrorMessage = () => {
    return (
      <>
        <span>Votre document n’a pas pu être téléchargé.</span>
        <br />
        <span>
          Veuillez vérifier le format ou la taille de votre document. Formats et taille
          acceptés : PDF, JPEG, WORD, EXCEL (sans macro), PNG - 19 Mo.
        </span>
      </>
    );
  };

  const renderSuccessUploadMessage = () => {
    return (
      <Modal
        backgroundColor="transparent"
        isVisible={showSuccessMessage}
        height="6.4rem"
        width="70rem">
        <div
          style={{
            border: '0.1rem solid #008859',
            borderRadius: '0.4rem',
            backgroundColor: '#F2F9F1',
            textAlign: 'center',
            display: 'flex',
            height: '6.4rem',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
          }}>
          <CheckInCircle color="green" />
          <span
            style={{
              color: '#008859',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              margin: '0',
            }}>
            {messages.UPLOAD_SUCCESS_MSG}
          </span>
        </div>
      </Modal>
    );
  };

  const renderAPIErrorMessage = () => {
    if (uploadApiErrorMessage) {
      return (
        <>
          <Space marginTop="4rem" />
          <InformationArea backgroundColor="#FEF2F4" icon={<ErrorIcon />} width="100%">
            <span> {uploadApiErrorMessage}</span>
          </InformationArea>
        </>
      );
    }
    return null;
  };

  const getSendFilePromise = (
    currentChemise: ChemiseData,
    url: string,
  ): Promise<UploadFileApiResponse>[] => {
    const promises: Promise<UploadFileApiResponse>[] = [];
    currentChemise.files.forEach(item => {
      const currentPromise = new Promise<UploadFileApiResponse>((resolve, reject) => {
        const body: UploadFileApiRequestBody = {
          fileName: item.content.name,
          chemiseId: currentChemise.id,
          fileContent: item.content,
        };
        uploadFile(url, body)
          .then(resp => resolve(resp))
          .catch(err => {
            console.log(`erreur sur le fichier ${body.fileName}`, err);
            resolve(err);
          });
      });
      promises.push(currentPromise);
    });
    return promises;
  };

  const sendFiles = async () => {
    setSendingFiles(true);
    setUploadApiErrorMessage('');
    try {
      const authenticatedUser = JSON.parse(
        sessionStorage.getItem('authenticatedUser') || '',
      ) as UserInfos;
      const { numContremarque } = authenticatedUser;
      const numeroProjet = sessionStorage.getItem('numeroProjet');
      if (!numeroProjet) {
        throw Error('Numero de projet non défini.');
      }
      const params: GetUploadUrlApiRequestParams = {
        IdProjet: parseInt(numeroProjet, 10),
        AdheNum: `${numContremarque}` || '',
        ContextApp: 'APTD',
      };
      const response = await getUploadUrl(params);
      if (response.success) {
        const uploadApiUrl = response.result;
        let promises: Promise<UploadFileApiResponse>[] = [];
        transmissionPiecesState.chemises.forEach(item => {
          promises = [...promises, ...getSendFilePromise(item, uploadApiUrl)];
        });
        if (promises.length > 0) {
          await Promise.all(promises);
          // l'url de l'upload est le même que celui du getTransfertAll (domaine et paramétre identiques).
          // Il faut juste changer le path single-file en getTransfertAll.
          // Disclaimer: ne pas hésiter à proposer autre chose si cette maniére de faire ne convient pas.
          const transfertApiUrl = uploadApiUrl.replace('single-file', 'getTransfertAll');
          await transfertAll(transfertApiUrl);
          sessionStorage.setItem('documentsHaveBeenSent', 'yes');
          setFilesAlreadyUploaded(true);
          setShowSuccessMessage(true);
          // on affiche le message de succés de l'upload pendant 5 secondes
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
        }
      } else {
        console.error(response.errors);
        setUploadApiErrorMessage(`${response.errors[0].MessageLib}`);
      }
    } catch (error) {
      console.error(error);
      setUploadApiErrorMessage(`${error}`);
    } finally {
      setSendingFiles(false);
    }
  };

  const getChemiseFilesName = (id: string) => {
    const result: CodeLibelle[] = [];
    transmissionPiecesState.chemises.forEach(item => {
      if (item.id === id) {
        item.files.forEach(el => {
          result.push({ code: el.id, libelle: el.content.name });
        });
      }
    });
    return result;
  };

  const renderChemisesUploadables = () => {
    // const listPiece: string[] = [];
    // const statusPiece = new Map<string, string>();

    if (!chemise || chemise.listePiecesJustificatives.length === 0) {
      return null;
    }
    // chemise.listePiecesJustificatives.forEach(item => {
    //   const pieceName = `${item.pceJusLib[0]}${item.pceJusLib
    //     .substring(1)
    //     .toLowerCase()}`;
    //   listPiece.push(pieceName);
    //   statusPiece.set(pieceName, item.statPceJusLib);
    // });
    return (
      <>
        <ChemiseConsultation
          id={chemise.chemId}
          libelle={chemise.chemLib}
          icon={<ListDocumentIcon />}
          documentsUploadTransferes={
            chemise.documentsUploadTransferes.listeDocumentUpldTranChe
          }
          acceptedFileFormat={ACCEPTED_FILES_FORMAT}
          maxFileSize={MAX_FILE_SIZE_IN_MO}
          listPiece={listPiece}
          isUploadable={false}
          isConsultation
          onUploadSuccess={value => {
            transmissionPiecesDispatch({
              type: 'setAddPieces',
              payload: { value },
            });
          }}
          onDelete={(chemiseId, fileId) => {
            transmissionPiecesDispatch({
              type: 'setDeletePieces',
              payload: { chemiseId, fileId },
            });
          }}
          uploadedFilesName={getChemiseFilesName(chemise.chemId)}
          errorMessage={renderErrorMessage()}
        />
      </>
    );
  };

  const onUploadSuccess = (value: ChemiseData) => {
    transmissionPiecesDispatch({
      type: 'setAddPieces',
      payload: { value },
    });
  };

  const handleUploadedFiles = (files: File[]) => {
    if (files.length > 0) {
      const fileWithErros = files.find(item => item.size > MAX_FILE_SIZE_IN_MO);
      if (fileWithErros) {
        setUploadHasError(true);
        return;
      }
      const chemiseAJ: ChemiseData = {
        id: chemise?.chemId || '',
        files: [],
      };
      files.forEach(element => {
        chemiseAJ.files.push({ id: uuidv4(), content: element });
      });
      onUploadSuccess(chemiseAJ);
    }
  };

  return (
    <>
      <Space marginBottom="2.4rem" />
      <Space marginBottom="0.8rem" />
      {renderChemisesUploadables()}
      {sendingFiles ? <Loader animationDuration={0.9} /> : null}
      <Space marginTop="6.4rem" />
      <ZoneCommentaire
        onUploadSuccess={onUploadSuccess}
        listPiece={listPiece.map(piece => piece.libelle)}
      />
      <Space marginTop="2rem" />
      <StyledTitle style={{ margin: '0', fontSize: '2rem' }}>
        Espace téléchargement
      </StyledTitle>
      <span style={{ color: '#6F757C' }}>{messages.ACCEPTED_FORMAT_TEXT_FOR_SG}</span>
      <Space marginTop="2.4rem" />
      <DropFileZone
        chemId={chemise?.chemId || ''}
        maxFileSize={MAX_FILE_SIZE_IN_MO}
        acceptedFileFormat={ACCEPTED_FILES_FORMAT}
        uploadHasError={uploadHasError}
        setChemiseHasError={setUploadHasError}
        onUploadSuccess={onUploadSuccess}
        onAddDocumentClicked={() => {
          setUploadHasError(false);
          setShowFileExplorer(!showFileExplorer);
        }}
      />
      <Space marginTop="2rem" />
      <Uploader
        onUploadCancel={() => setShowFileExplorer(false)}
        onUploadComplete={files => {
          handleUploadedFiles(files);
        }}
        triggerUpload={showFileExplorer}
        acceptedFileFormat={ACCEPTED_FILES_FORMAT}
      />
      {uploadHasError ? (
        <StyledErrorWrapper>{renderErrorMessage()}</StyledErrorWrapper>
      ) : null}
      {renderSuccessUploadMessage()}
      {renderAPIErrorMessage()}
      <FormFooter
        hideSaveBtn
        isNextButtonDisabled={isBtnDisabled}
        showPreviousButton={false}
        onNextButtonClick={() => {
          sendFiles();
        }}
        nextButtonLabel="Transmettre les pièces"
        nextButtonIcon={<PaperPlaneIcon />}
      />
    </>
  );
};

export default OngletAutresJustificatives;
