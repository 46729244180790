import {
  GetBankssApiResponse,
  GetChargesApiResponse,
  GetCountriesApiResponse,
  GetDepartmentsApiResponse,
  GetFamilySituationApiResponse,
  GetJobContractsApiResponse,
  GetJobsApiResponse,
  GetLoanUsageTypesApiResponse,
  GetLodgingsApiResponse,
  GetNationalitiesApiResponse,
  GetRessourcesApiResponse,
  GetUsageBienApiResponse,
  ReferentialApiResponse,
  City,
  LoanerAddressResp,
  OperationReferentialApiResponse,
  Adresse,
  adresseFiabiliseApiResponse,
  DqeEmailApiREsponse,
} from 'types';
import axiosRetry from 'axios-retry';
import AuthorizationService from './AuthorizationService';
import { consultationInstance, govRefApiInstance } from './axiosInstances';

const sortReferentialByOrdre = (
  apiResponse: ReferentialApiResponse,
): ReferentialApiResponse => {
  const result = { ...apiResponse };
  const removeItemWithNoCode = result.Resultat.filter(item => {
    return item.code !== '';
  });
  if (removeItemWithNoCode && removeItemWithNoCode.length > 0) {
    removeItemWithNoCode.sort((a, b) => {
      if (a.ordre > b.ordre) {
        return 1;
      }
      return -1;
    });
  }
  result.Resultat = removeItemWithNoCode;
  return result;
};

const REFERENTIAL_RETRY_NUMBER = 2;

axiosRetry(govRefApiInstance, {
  retries: REFERENTIAL_RETRY_NUMBER,
  shouldResetTimeout: true,
  retryDelay: () => 1000,
  retryCondition: () => true,
});

export const getCountries = (): Promise<GetCountriesApiResponse> => {
  const reqUrl = `referential?referentialType=countries&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      const result = { ...response.data };
      const removeItemWithNoCode = result.Resultat.filter(item => {
        return item.code !== '';
      });
      result.Resultat = removeItemWithNoCode;
      return Promise.resolve(result);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the countries list. Reason: ${err}`;
      console.error(message);
    });
};

export const getDepartments = (): Promise<GetDepartmentsApiResponse> => {
  const reqUrl = `referential?referentialType=departments&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the departments list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getBanks = (): Promise<GetBankssApiResponse> => {
  const reqUrl = `referential?referentialType=banks&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the banks list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getLoaners = (): Promise<ReferentialApiResponse> => {
  const reqUrl = `referential?referentialType=listeEtablissementPreteur&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the loaners list. Reason: ${err}`;
      console.error(message);
    });
};

export const getCharges = (): Promise<GetChargesApiResponse> => {
  const reqUrl = `referential?referentialType=charges&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the charges list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getFamilySituation = (): Promise<GetFamilySituationApiResponse> => {
  const reqUrl = `referential?referentialType=familysituation&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the family situation list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getJobContracts = (): Promise<GetJobContractsApiResponse> => {
  const reqUrl = `referential?referentialType=jobscontracts&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the jobContracts list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getJobs = (): Promise<GetJobsApiResponse> => {
  const reqUrl = `referential?referentialType=jobs&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the jobs list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};
export const getLodgings = (): Promise<GetLodgingsApiResponse> => {
  const reqUrl = `referential?referentialType=lodgings&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the lodgings list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getRessources = (): Promise<GetRessourcesApiResponse> => {
  const reqUrl = `referential?referentialType=ressources&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the ressources list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getStreets = (): Promise<ReferentialApiResponse> => {
  const reqUrl = `referential?referentialType=typeVoie&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.data
        ? err.response.data
        : `Error getting segmentation types. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getCitiesByZipCode = (zipCode: string): Promise<City[]> => {
  return consultationInstance
    .get(`getLocalites?CodePostal=${zipCode}`)
    .then(response => {
      const cityList = [] as City[];

      response.data.Resultat.forEach(city => {
        cityList.push({ libelle: city.nom, code: city.code });
      });

      return Promise.resolve(cityList);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the ressources list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getZipCodeBycityAndDepartement = (
  city: string,
  codeDepartement: string,
): Promise<City[]> => {
  return govRefApiInstance
    .get(`/communes?nom=${city}&codeDepartement=${codeDepartement}&fields=codesPostaux`)
    .then(response => {
      const cityList = [] as City[];

      response.data.forEach(element => {
        cityList.push({ libelle: element.nom, code: element.codesPostaux[0] }); // TODO : remplacer le CP de la réponse par le CP de la requete
      });
      return Promise.resolve(cityList);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the ressources list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getCitiesByDepartmentReq = (department: string): Promise<City[]> => {
  return govRefApiInstance
    .get(`/departements/${department}/communes`)
    .then(response => {
      const cityList = [] as City[];
      response.data.forEach(city => {
        cityList.push({ libelle: city.nom, code: city.code });
      });

      return Promise.resolve(cityList);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the cities by department list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getCitiesByDepartment = (department: string): Promise<City[]> => {
  if (department === '97') {
    return Promise.all(['971', '972', '973', '974', '976'].map(getCitiesByDepartmentReq))
      .then(multipleDepartmentsCityList => {
        const finalCitiesByDepartment = [] as City[];

        multipleDepartmentsCityList.forEach(singleDepartmentCityList => {
          singleDepartmentCityList.forEach(city => {
            finalCitiesByDepartment.push(city);
          });
        });

        finalCitiesByDepartment.sort((a, b) => {
          return a.libelle > b.libelle ? 1 : -1;
        });

        return Promise.resolve(finalCitiesByDepartment);
      })
      .catch(err => {
        alert('Echec du chargement de la page, merci de contacter votre administrateur.');
        const message = err.response
          ? err.response.data
          : `Error getting the cities by department list. Reason: ${err}`;
        console.error(message);
        return Promise.reject();
      });
  }
  return getCitiesByDepartmentReq(department);
};

export const fiabiliserAdresse = (
  adresse: Adresse,
  idEmprunteur: number,
): Promise<adresseFiabiliseApiResponse> => {
  const userInfos = AuthorizationService.getUserInfo();
  const userId = userInfos?.uid;

  return consultationInstance
    .post('fiabiliserAdresse ', {
      userId,
      pays: 'FR',
      ...adresse,
      adresse: 'string',
      identifiantEmprunteur: idEmprunteur,
    })
    .then(response => {
      return Promise.resolve(response.data as adresseFiabiliseApiResponse);
    })
    .catch(err => {
      alert('Echec fiabilisation, merci de contacter votre administrateur.');
      console.error(err);
      const message = err.response ? err.response.data : `${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const fiabiliserAdresseEmail = (
  email: string,
  idEmprunteur: number,
): Promise<DqeEmailApiREsponse> => {
  const userInfos = AuthorizationService.getUserInfo();
  const userId = userInfos?.uid;

  return consultationInstance
    .post('fiabiliserAdresseEmail', {
      userId,
      email,
      identifiantEmprunteur: idEmprunteur,
    })
    .then(response => {
      return Promise.resolve(response.data as DqeEmailApiREsponse);
    })
    .catch(err => {
      alert('Echec fiabilisation, merci de contacter votre administrateur.');
      console.error(err);
      const message = err.response ? err.response.data : `${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getHomeLoanPurposes = (): Promise<GetUsageBienApiResponse> => {
  const reqUrl = `referential?referentialType=usagebiens&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(sortReferentialByOrdre(response.data));
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the usage biens list. Reason: ${err}`;
      console.error(message);
      return Promise.reject();
    });
};

export const getNationalities = (): Promise<GetNationalitiesApiResponse> => {
  const reqUrl = `referential?referentialType=nationalities&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the nationalities list. Reason: ${err}`;
      console.error(message);
    });
};

export const getLoanerAddress = (loanerCode: number): Promise<LoanerAddressResp> => {
  const reqUrl = `getAdressEtab?codeEtablissement=${loanerCode}`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the loaner's address. Reason: ${err}`;
      console.error(message);
    });
};

export const getBorrowerOtherNeeds = (): Promise<OperationReferentialApiResponse> => {
  const reqUrl = `referential?referentialType=additionalLoanPurpose&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.data
        ? err.response.data
        : `Error getting debt types. Reason: ${err}`;
      console.error(message);
    });
};

export const getBorrowerDebts = (): Promise<OperationReferentialApiResponse> => {
  const reqUrl = `referential?referentialType=debt&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.data
        ? err.response.data
        : `Error getting debt types. Reason: ${err}`;
      console.error(message);
    });
};

export const getLoanUsageTypes = (): Promise<GetLoanUsageTypesApiResponse> => {
  const reqUrl = `referential?referentialType=natureCredit&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.data
        ? err.response.data
        : `Error getting natureCredit types. Reason: ${err}`;
      console.error(message);
    });
};

export const getDepartmentCodeByZipCode = (zipCode: string): Promise<string> => {
  return govRefApiInstance
    .get(`communes?codePostal=${zipCode}`)
    .then(response => {
      if (response && response.data && response.data.length > 0) {
        return Promise.resolve(response.data[0]['code']);
      }
      return Promise.resolve('');
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.response
        ? err.response.data
        : `Error getting the department code by zipCode. Reason: ${err}`;
      console.error(message);
    });
};

export const getSegmentation = (): Promise<ReferentialApiResponse> => {
  const reqUrl = `referential?referentialType=segmentation&referentialValue=all`;
  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.data
        ? err.response.data
        : `Error getting segmentation types. Reason: ${err}`;
      console.error(message);
    });
};

export const getReferential = (
  referentialType: string,
): Promise<ReferentialApiResponse> => {
  const reqUrl = `referential?referentialType=${referentialType}&referentialValue=all`;

  return consultationInstance
    .get(reqUrl, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.data
        ? err.response.data
        : `Error getting ${referentialType}. Reason: ${err}`;
      console.error(message);
    });
};

export const getCodeAPE = (): Promise<any> => {
  const reqURL = `referential?ReferentialType=codeApeNafInsee&ReferentialValue=all`;
  return consultationInstance
    .get(reqURL, {
      'axios-retry': {
        retries: REFERENTIAL_RETRY_NUMBER,
        shouldResetTimeout: true,
        retryCondition: () => true,
      },
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      alert('Echec du chargement de la page, merci de contacter votre administrateur.');
      const message = err.data
        ? err.response.data
        : `Error getting APE code list. Reason: ${err}`;
      console.error(message);
    });
};
