interface ContentTitleProps {
  text: string;
}
const ContentTitle: React.FC<ContentTitleProps> = ({ text }) => {
  return (
    <p
      style={{
        fontWeight: 'bold',
        margin: '0',
        marginBottom: '1.6rem',
        fontSize: '1.6rem',
      }}>
      {text}
    </p>
  );
};

export default ContentTitle;
