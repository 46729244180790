import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import ToggleButton from 'components/toggleButton/ToggleButton';
import { useEffect, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import colors from 'styles/color';
import { formatNumberByThousand } from 'utils/commun';
import { addInInvalidInputs, removeFromInvalidInputs } from '../communs/utils';

interface LoanOptionProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  palierMax?: number;
}

const LoanOption: React.FC<LoanOptionProps> = ({ state, dispatch, palierMax }) => {
  const [showOption, setShowOption] = useState<boolean>(state.hasLoanOption);

  const [isFirstLevelDurationInputFocused, setIsFirstLevelDurationInputFocused] =
    useState<boolean>(false);
  const [isFirstLevelDurationInputValid, setIsFirstLevelDurationInputValid] =
    useState<boolean>();
  const [firstLevelDurationInputError, setFirstLevelDurationInputError] =
    useState<string>('');

  const [
    isFirstLevelMonthlyPaymentInputFocused,
    setIsFirstLevelMonthlyPaymentInputFocused,
  ] = useState<boolean>(false);
  const [isFirstLevelMonthlyPaymentInputValid, setIsFirstLevelMonthlyPaymentInputValid] =
    useState<boolean>();
  const [firstLevelMonthlyPaymentInputError, setFirstLevelMonthlyPaymentInputError] =
    useState<string>('');

  useEffect(() => {
    if (!state.hasLoanOption) {
      dispatch({
        type: 'setFirstLevelDuration',
        payload: undefined,
      });
      dispatch({
        type: 'setFirstLevelMonthlyPayment',
        payload: undefined,
      });
    }
  }, []);

  const handleFirstLevelDurationChange = (value: string) => {
    const duration = parseInt(value, 10);
    if (Number.isNaN(duration)) {
      dispatch({
        type: 'setFirstLevelDuration',
        payload: undefined,
      });
    } else {
      dispatch({
        type: 'setFirstLevelDuration',
        payload: duration,
      });
    }
  };

  const handleFirstLevelDurationBlur = () => {
    setIsFirstLevelDurationInputFocused(false);
    if (
      state.firstPalierDuration === undefined ||
      Number.isNaN(state.firstPalierDuration)
    ) {
      setIsFirstLevelDurationInputValid(false);
      setFirstLevelDurationInputError('Durée incorrecte.');
      addInInvalidInputs(state, dispatch, 'palierDuration');
    } else if (palierMax && state.firstPalierDuration > palierMax) {
      setIsFirstLevelDurationInputValid(false);
      setFirstLevelDurationInputError(
        `La durée doit être comprise entre 0 et ${palierMax} mois.`,
      );
      addInInvalidInputs(state, dispatch, 'palierDuration');
    } else {
      setIsFirstLevelDurationInputValid(true);
      setFirstLevelDurationInputError('');
      removeFromInvalidInputs(state, dispatch, 'palierDuration');
    }
  };

  const handleFirstLevelMonthlyPaymentChange = (value: string) => {
    let inputTrimmed = '';
    if (value) {
      inputTrimmed = value.replace(/ /g, '');
    }
    const duration = parseInt(inputTrimmed, 10);
    if (Number.isNaN(duration)) {
      dispatch({
        type: 'setFirstLevelMonthlyPayment',
        payload: undefined,
      });
    } else {
      dispatch({
        type: 'setFirstLevelMonthlyPayment',
        payload: duration.toString(),
      });
    }
  };
  const handleFirstLevelMonthlyPaymentBlur = () => {
    setIsFirstLevelMonthlyPaymentInputFocused(false);
    if (
      state.firstPalierMonthlyPayment === undefined ||
      Number.isNaN(state.firstPalierMonthlyPayment)
    ) {
      setIsFirstLevelMonthlyPaymentInputValid(false);
      setFirstLevelMonthlyPaymentInputError('Mensualité incorrecte.');
    } else {
      setIsFirstLevelMonthlyPaymentInputValid(true);
      setFirstLevelMonthlyPaymentInputError('');
    }
  };

  return (
    <>
      <ToggleButton
        id="options"
        isOn={showOption}
        handleToggle={() => {
          dispatch({ type: 'setHasLoanOption', payload: !showOption });
          setShowOption(prevState => !prevState);
        }}>
        <span
          style={{
            marginRight: '1rem',
            display: 'block',
            marginTop: '0.3rem',
            height: '3.2rem',
            fontSize: '1.6rem',
            color: colors.chartColors.neutral500,
          }}>
          Option prêt à palier
        </span>
      </ToggleButton>
      {showOption && (
        <>
          <Space marginTop="2.4rem" />
          <CustomInput
            name="first-level-duration"
            label={
              palierMax
                ? `Durée du premier palier 0 à ${palierMax} mois`
                : 'Durée du premier palier'
            }
            value={
              state.firstPalierDuration !== undefined ? state.firstPalierDuration : ''
            }
            onChange={newValue => {
              handleFirstLevelDurationChange(newValue);
              setIsFirstLevelDurationInputFocused(true);
            }}
            isValid={isFirstLevelDurationInputValid}
            isFocused={isFirstLevelDurationInputFocused}
            onBlur={handleFirstLevelDurationBlur}
            onFocus={() => {
              setIsFirstLevelDurationInputFocused(true);
            }}
            after="mois"
            inputWidth="46.4rem"
            placeholder="0"
            error={firstLevelDurationInputError}
          />
          <Space marginTop="2.4rem" />
          <CustomInput
            name="first-level-monthly-payment"
            label="Mensualité du premier palier"
            value={
              state.firstPalierMonthlyPayment !== undefined
                ? formatNumberByThousand(state.firstPalierMonthlyPayment)
                : ''
            }
            onChange={newValue => {
              handleFirstLevelMonthlyPaymentChange(newValue);
              setIsFirstLevelMonthlyPaymentInputFocused(true);
            }}
            isValid={isFirstLevelMonthlyPaymentInputValid}
            isFocused={isFirstLevelMonthlyPaymentInputFocused}
            onBlur={handleFirstLevelMonthlyPaymentBlur}
            onFocus={() => {
              setIsFirstLevelMonthlyPaymentInputFocused(true);
            }}
            after="€ / mois"
            inputWidth="46.4rem"
            placeholder="0"
            error={firstLevelMonthlyPaymentInputError}
          />
        </>
      )}
    </>
  );
};

export default LoanOption;
