import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import Space from 'components/space/Space';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import { StyledTitle } from 'containers/communs/style';
import { isSansGarantie } from 'containers/communs/utils';
import { useEffect, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import { CodeLibelle, InsuranceResult } from 'types';
import InsuranceChoice from './insuranceChoice';

interface CoBorrowerInsuranceProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  getGroupInsuranceInformation: () => void;
  selectedInsurance?: CodeLibelle;
  setSelectedInsurance: (selectedInsurance?: CodeLibelle) => void;
  checkInputs: boolean;
  insuranceResponse?: InsuranceResult;
}
const CoBorrowerInsurance: React.FC<CoBorrowerInsuranceProps> = ({
  state,
  dispatch,
  getGroupInsuranceInformation,
  selectedInsurance,
  setSelectedInsurance,
  checkInputs,
  insuranceResponse,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (checkInputs) {
      if (!selectedInsurance) {
        setErrorMessage("Veuillez selectionner l'une des options ci-dessous.");
      } else if (!state.coBorrowerInsurance.deathInsuranceRate && !isSansGarantie()) {
        setErrorMessage('La quotité de décès doit etre supérieure à 0');
      } else {
        setErrorMessage(undefined);
      }
    } else {
      setErrorMessage(undefined);
    }
  }, [checkInputs, selectedInsurance]);

  return (
    <>
      <CoBorrowerTitle />
      <Space marginBottom="1.6rem" />
      {errorMessage && (
        <>
          <ErrorMessageArea message={errorMessage} />
          <Space marginBottom="1.6rem" />
        </>
      )}
      <StyledTitle>Co-emprunteur</StyledTitle>
      <Space marginBottom="1.6rem" />
      <InsuranceChoice
        state={state}
        dispatch={dispatch}
        person="coBorrower"
        getGroupInsuranceInformation={getGroupInsuranceInformation}
        selectedInsurance={selectedInsurance}
        setSelectedInsurance={setSelectedInsurance}
        checkInputs={checkInputs}
        insuranceResponse={insuranceResponse}
      />
    </>
  );
};

export default CoBorrowerInsurance;
