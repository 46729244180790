import CustomInput from 'components/customInput/CustomInput';
import { useState } from 'react';
import {
  AssuranceEmprunteur,
  CalculetteFinanciereAction,
} from 'reducers/calculetteFinanciere/type';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface MonthlyEstimatedBonusProps {
  person: 'borrower' | 'coBorrower';
  dispatch: React.Dispatch<CalculetteFinanciereAction>;
  getPersonWarranty: AssuranceEmprunteur;
  getValueByThousand: (value: number | undefined) => string | undefined;
}

const MonthlyEstimatedBonus: React.FC<MonthlyEstimatedBonusProps> = ({
  person,
  dispatch,
  getPersonWarranty,
  getValueByThousand,
}) => {
  const [isMonthlyEstimatedBonusInputFocused, setIsMonthlyEstimatedBonusInputFocused] =
    useState<boolean>(false);
  const [isMonthlyEstimatedBonusInputValid, setIsMonthlyEstimatedBonusInputValid] =
    useState<boolean>();
  const [monthlyEstimatedBonusInputError, setMonthlyEstimatedBonusInputError] =
    useState<string>('');

  const handleMonthlyEstimatedBonusChange = (value: string) => {
    let inputTrimmed = '';
    if (value) {
      inputTrimmed = value.replace(/ /g, '').slice(0, 4);
    }
    const rate = parseInt(inputTrimmed, 10);
    if (Number.isNaN(rate)) {
      dispatch({
        type: 'setEcheanceAssuranceDeleguee',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setEcheanceAssuranceDeleguee',
        payload: { person, value: rate },
      });
    }
  };

  const handleMonthlyEstimatedBonusBlur = () => {
    setIsMonthlyEstimatedBonusInputFocused(false);
    if (
      Number.isNaN(getPersonWarranty.echeanceAssuranceDeleguee) ||
      (getPersonWarranty.echeanceAssuranceDeleguee !== undefined &&
        getPersonWarranty.echeanceAssuranceDeleguee <= 0)
    ) {
      setIsMonthlyEstimatedBonusInputValid(false);
      setMonthlyEstimatedBonusInputError(messagesCheckInput.REQUIRED_VALUE);
    } else if (Number.isNaN(getPersonWarranty.echeanceAssuranceDeleguee)) {
      setIsMonthlyEstimatedBonusInputValid(false);
      setMonthlyEstimatedBonusInputError(messagesCheckInput.INPUT_ERROR);
    } else {
      setIsMonthlyEstimatedBonusInputValid(true);
      setMonthlyEstimatedBonusInputError('');
    }
  };

  return (
    <CustomInput
      name="monthly-estimated-bonus"
      label="Prime mensuelle estimée"
      value={getValueByThousand(getPersonWarranty.echeanceAssuranceDeleguee)}
      onChange={newValue => {
        handleMonthlyEstimatedBonusChange(newValue);
        setIsMonthlyEstimatedBonusInputFocused(true);
      }}
      isValid={isMonthlyEstimatedBonusInputValid}
      isFocused={isMonthlyEstimatedBonusInputFocused}
      onBlur={handleMonthlyEstimatedBonusBlur}
      onFocus={() => {
        setIsMonthlyEstimatedBonusInputFocused(true);
      }}
      after="€ / mois"
      inputWidth="25rem"
      placeholder="0"
      error={monthlyEstimatedBonusInputError}
      type="price"
    />
  );
};

export default MonthlyEstimatedBonus;
