import { createComponent } from '@lit-labs/react';
import React from 'react';
import { DsStatus } from 'types';
import { CustomValidity, Replacement } from './DesignSystemTypes';

export class BaseInputDS extends HTMLElement {
  required?: boolean;

  maxLength?: string;

  minLength?: string;

  class?: string;

  value: unknown;

  label?: string;

  replacement?: string | Replacement[];

  customValidityPattern?: string | CustomValidity[];

  invalid?: boolean;

  valid?: boolean;

  status?: DsStatus;
}

export const BaseInput = createComponent(React, 'base-input', BaseInputDS, {
  onInputChange: 'input-changed',
  onFocus: 'element-focused',
  onListBlur: 'element-blured',
});
