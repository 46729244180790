import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { isEmployedWorker, isSelfEmployedWorker } from 'containers/communs/utils';
import { useEffect, useState } from 'react';
import {
  SituationProfessionnelleAction,
  SituationProfessionnelleState,
} from 'reducers/situationProfessionnelle/types';
import { Profession, CodeLibelle, StatusInput } from 'types';
import { getNotEmpty } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

type Job = CodeLibelle & {
  ordreString: string;
};
interface ProfessionOrSituationProps {
  state: SituationProfessionnelleState;
  dispatch: React.Dispatch<SituationProfessionnelleAction>;
  person: 'borrower' | 'coBorrower';
  jobsReferential: Job[];
  checkInputs: boolean;
}
const ProfessionOrSituation: React.FC<ProfessionOrSituationProps> = ({
  state,
  dispatch,
  person,
  jobsReferential,
  checkInputs,
}) => {
  const [statusProfessionBorrower, setStatusProfessionBorrower] = useState<StatusInput>();
  const [statusProfessionCoBorrower, setStatusProfessionCoBorrower] =
    useState<StatusInput>();

  const checkStatusProfession = (typeParam: 'borrower' | 'coBorrower') => {
    const type =
      typeParam === 'borrower' ? state.borrower.profession : state.coBorrower.profession;
    if (person && type) {
      const ProfessionMatchesListElement = jobsReferential?.find(job => {
        return job.libelle.toUpperCase() === type.libelle.toUpperCase();
      });
      if (ProfessionMatchesListElement) {
        dispatch({
          type: 'setProfession',
          payload: { person: typeParam, value: ProfessionMatchesListElement },
        });
        if (typeParam === 'borrower')
          setStatusProfessionBorrower({
            status: 'valid',
            errorMessage: ``,
          });
        else
          setStatusProfessionCoBorrower({
            status: 'valid',
            errorMessage: ``,
          });
      } else {
        dispatch({
          type: 'setProfession',
          payload: {
            person: typeParam,
            value: {
              code: 'err',
              libelle: type.libelle,
              ordreString: '',
            },
          },
        });
        if (typeParam === 'borrower')
          setStatusProfessionBorrower({
            status: 'invalid',
            errorMessage: messagesCheckInput.INPUT_ERROR,
          });
        else
          setStatusProfessionCoBorrower({
            status: 'invalid',
            errorMessage: messagesCheckInput.INPUT_ERROR,
          });
      }
    }
    if (!type) {
      if (typeParam === 'borrower')
        setStatusProfessionBorrower({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
      else
        setStatusProfessionCoBorrower({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
    }
  };

  useEffect(() => {
    if (state.borrower.profession) {
      checkStatusProfession('borrower');
    }
    if (state.coBorrower.profession) {
      checkStatusProfession('coBorrower');
    }
  }, [jobsReferential]);

  useEffect(() => {
    if (checkInputs) {
      checkStatusProfession(person);
    }
  }, [checkInputs]);

  const onProfessionSelect = (type: 'borrower' | 'coBorrower', e: Event) => {
    const selectedProfession = (e as CustomEvent).detail.value as Profession;
    dispatch({
      type: 'setProfession',
      payload: { person: type, value: selectedProfession },
    });

    if (!isEmployedWorker(selectedProfession)) {
      dispatch({
        type: 'setTypeContrat',
        payload: { person: type, value: undefined },
      });
    }
    if (isSelfEmployedWorker(selectedProfession)) {
      dispatch({
        type: 'setEmployerCountry',
        payload: { person: type, value: { code: 'FR', libelle: 'FRANCE' } },
      });
    }
  };

  const onProfessionBlur = (type: 'borrower' | 'coBorrower', event: Event) => {
    if (event && event.target) {
      const ProfessionMatchesListElement = jobsReferential?.find(job => {
        return (
          job.libelle.toUpperCase() ===
          ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
        );
      });
      if (ProfessionMatchesListElement) {
        dispatch({
          type: 'setProfession',
          payload: { person: type, value: ProfessionMatchesListElement },
        });
      } else {
        dispatch({
          type: 'setProfession',
          payload: {
            person: type,
            value: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
              ordreString: '',
            },
          },
        });
      }
    }
  };

  return (
    <ComboInput
      class="combo-input show-input-ds-validation"
      list-on-open
      shadow
      align-items="left"
      items={JSON.stringify(jobsReferential)}
      field="libelle"
      label="Profession ou situation"
      placeholder="ex: Artisan"
      onSelectedList={e => {
        onProfessionSelect(person, e);
      }}
      onListBlur={e => {
        onProfessionBlur(person, e);
      }}
      value={
        person === 'borrower'
          ? state.borrower.profession?.libelle || ''
          : state.coBorrower.profession?.libelle || ''
      }
      status={
        person === 'borrower'
          ? statusProfessionBorrower?.status
          : statusProfessionCoBorrower?.status
      }
      error-message={
        person === 'borrower'
          ? statusProfessionBorrower?.errorMessage
          : statusProfessionCoBorrower?.errorMessage
      }
      customValidityPattern={JSON.stringify(getNotEmpty('Profession ou situation'))}
    />
  );
};

export default ProfessionOrSituation;
