import {
  SimulationComplementApiRequestBody,
  SimulationComplementApiResponse,
} from 'types';
import { transfertOctroiInstance } from './axiosInstances';

const simulationComplement = (
  data: SimulationComplementApiRequestBody,
): Promise<SimulationComplementApiResponse> => {
  return transfertOctroiInstance
    .post('/simulationComplement', { ...data })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      console.error(err);
      const message = err.response ? err.response.data : `${err}`;
      throw Error(message);
    });
};

export default simulationComplement;
