import Space from 'components/space/Space';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import { hasCoBorrower, INACTIVE_PROFESSION } from 'containers/communs/utils';
import { useReducer } from 'react';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import {
  getSituationProfessionnelleInitialState,
  situationProfessionnelleReducer,
} from 'reducers/situationProfessionnelle/situationProfessionnelle';
import EmployerNameInput from './EmployerNameInput';

interface EmployerNameProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  checkInputs: boolean;
}

const EmployerName: React.FC<EmployerNameProps> = ({ state, dispatch, checkInputs }) => {
  const [situationProfessionnelleState] = useReducer(
    situationProfessionnelleReducer,
    getSituationProfessionnelleInitialState('situationProfessionnelle'),
  );

  const renderNameInput = (type: 'borrower' | 'coBorrower') => {
    return (
      <EmployerNameInput
        state={state}
        dispatch={dispatch}
        person={type}
        isActiveProfession
        checkInputs={checkInputs}
      />
    );
  };

  /**
   * Affiche le champ "Nom employeur" uniquement pour les professions actives.
   * On vérifie que la profession de l'utilisateur n'est pas une profession INACTIVE
   */
  const displayEmployerNameInput = (type: 'borrower' | 'coBorrower') => {
    const professionCode =
      type === 'borrower'
        ? situationProfessionnelleState.borrower.profession?.code
        : situationProfessionnelleState.coBorrower.profession?.code;
    const inactiveProfession = INACTIVE_PROFESSION.find(item => {
      return professionCode === item.code;
    });
    if (!inactiveProfession && type === 'borrower') {
      return <>{renderNameInput(type)}</>;
    }
    if (!inactiveProfession && type === 'coBorrower') {
      return (
        <>
          <Space marginTop="5.6rem" />
          <CoBorrowerTitle width="64.8rem" />
          {renderNameInput(type)}
        </>
      );
    }
    return null;
  };
  return (
    <>
      {displayEmployerNameInput('borrower')}
      {hasCoBorrower() && <>{displayEmployerNameInput('coBorrower')}</>}
    </>
  );
};

export default EmployerName;
