import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  GuaranteeAdditionalData,
  GuaranteeAdditionalInformationAction,
  GuaranteeAdditionalInformationState,
} from 'reducers/guaranteeAdditionalInformation/type';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface LivingSpaceProps {
  currentGuarantee?: GuaranteeAdditionalData;
  state: GuaranteeAdditionalInformationState;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
}

const LivingSpace: React.FC<LivingSpaceProps> = ({
  currentGuarantee,
  state,
  dispatch,
}) => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');
  const [value, setValue] = useState<string>(currentGuarantee?.livingSpace || '');

  const notEmpty = new RegExp(ValidationRules.notEmpty);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);

  const updateState = (val?: string) => {
    const data = state.guarantees.map(item => {
      if (item.code === currentGuarantee?.code) {
        return { ...item, livingSpace: val };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateGuaranteesInformation',
      payload: { data, guaranteeCode: currentGuarantee?.code },
    });
  };

  const handleBlur = (newValue: string) => {
    setIsInputFocused(false);
    if (!notEmpty.test(newValue)) {
      setInputError(messagesCheckInput.REQUIRED_VALUE);
      setIsInputValid(false);
      updateState(undefined);
    } else if (!onlyNumbers.test(newValue)) {
      setInputError(messagesCheckInput.ONLY_NUMBERS);
      setIsInputValid(false);
      updateState(undefined);
    } else {
      setIsInputValid(true);
      setInputError('');
      updateState(value);
    }
  };

  useEffect(() => {
    if (notEmpty.test(currentGuarantee?.livingSpace || ''))
      handleBlur(currentGuarantee?.livingSpace || '');
  }, []);

  return (
    <>
      <CustomInput
        name="living-space"
        label="Surface habitable"
        value={value}
        onChange={newValue => {
          if (onlyNumbers.test(newValue.replaceAll(' ', '')))
            setValue(newValue.replaceAll(' ', '').slice(0, 4));
          setIsInputFocused(true);
        }}
        isValid={isInputValid}
        isFocused={isInputFocused}
        onBlur={newValue => {
          handleBlur(newValue);
        }}
        onFocus={() => {
          setIsInputFocused(true);
        }}
        inputWidth="20.8rem"
        placeholder="0"
        after="m2"
        error={inputError}
      />
    </>
  );
};

export default LivingSpace;
