import Space from 'components/space/Space';
import { StopLightMessageContainer, StopLightMessageText } from '../style';

interface StopLightFormProps {
  profile: 'VERT' | 'ORANGE' | 'ROUGE';
  message: string[];
}

const StopLightForm: React.FC<StopLightFormProps> = ({ profile, message }) => {
  const renderMessageContainer = () => {
    let formatedMessage = '';
    message.forEach((sentence, index) => {
      formatedMessage = `${formatedMessage}${sentence}${
        index !== message.length - 1 ? '\n' : ''
      }`;
    });

    switch (profile) {
      case 'VERT':
        return (
          <>
            <StopLightMessageContainer borderColor="#268038" backgroundColor="#EFF8F0">
              <StopLightMessageText>{formatedMessage}</StopLightMessageText>
            </StopLightMessageContainer>
          </>
        );
      case 'ORANGE':
        return (
          <>
            <StopLightMessageContainer borderColor="#E78A08" backgroundColor="#FEF4E5">
              <StopLightMessageText>{formatedMessage}</StopLightMessageText>
            </StopLightMessageContainer>
          </>
        );
      case 'ROUGE':
        return (
          <>
            <StopLightMessageContainer borderColor="#C8102E" backgroundColor="#FEF2F4">
              <StopLightMessageText>{formatedMessage}</StopLightMessageText>
            </StopLightMessageContainer>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Space marginBottom="4rem" />
      {renderMessageContainer()}
    </>
  );
};

export default StopLightForm;
