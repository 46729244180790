import * as React from 'react';

interface EditIconProps {
  color?: string;
}

const EditIcon: React.FC<EditIconProps> = ({ color }) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
    <title>AE7AA3FF-1884-4F76-AF7A-78C272F475BC</title>
    <path
      d="M9.034 1.933a.833.833 0 1 1 0 1.666H2.656a.989.989 0 0 0-.99.99v12.755c0 .547.443.99.99.99h12.756a.989.989 0 0 0 .989-.99v-6.378a.833.833 0 0 1 1.666 0v6.378A2.656 2.656 0 0 1 15.412 20H2.656A2.656 2.656 0 0 1 0 17.344V4.588a2.656 2.656 0 0 1 2.656-2.655h6.378Zm8.977 1.61a1.1 1.1 0 0 0-1.555-1.554L7.964 10.48l-.519 2.074 2.074-.519 8.492-8.492ZM19.19.81a2.766 2.766 0 0 1 0 3.912l-8.656 8.656a.833.833 0 0 1-.387.22l-3.645.91a.833.833 0 0 1-1.01-1.01l.91-3.645a.833.833 0 0 1 .22-.387L15.278.81a2.766 2.766 0 0 1 3.912 0Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

EditIcon.defaultProps = {
  color: '#268038',
};

export default EditIcon;
