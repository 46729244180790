import { FichageEmprunteursApiRequestBody, FichageEmprunteursApiResponse } from 'types';
import { fichageEmprunteursInstance } from './axiosInstances';

const fichageEmprunteurs = (
  data: FichageEmprunteursApiRequestBody,
): Promise<FichageEmprunteursApiResponse> => {
  return fichageEmprunteursInstance
    .post('fichageEmprunteurs', { ...data })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      console.error(err);
      const message = err.response ? err.response.data : `${err}`;
      throw Error(message);
    });
};

export default fichageEmprunteurs;
