import StyledButtonCheck from './style';

export interface CustomCheckButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  isChecked?: boolean;
  value?: string;
  height?: string;
  width?: string;
}

const CustomCheckButton = ({
  onClick = () => null,
  isChecked = false,
  className = '',
  children,
  value,
  height,
  width,
}: CustomCheckButtonProps): React.ReactElement => {
  const handleClick = () => {
    onClick();
  };

  return (
    <StyledButtonCheck
      type="button"
      onClick={handleClick}
      className={className}
      value={value}
      height={height}
      width={width}
      isChecked={isChecked}
      tabIndex={0}>
      {children}
    </StyledButtonCheck>
  );
};

CustomCheckButton.defaultProps = {
  onClick: () => null,
  isChecked: false,
  className: '',
  children: null,
  value: '',
  height: '',
  width: '',
};

export default CustomCheckButton;
