import React, { useState } from 'react';
import CustomInput from 'components/customInput/CustomInput';
import { ValidationRules, checkPercentageValue } from 'utils/InputValidation';
import { CalculetteFinanciereAction, Projet } from 'reducers/calculetteFinanciere/type';
import ArrowLeftRightIcon from 'icons/ArrowLeftRightIcon';
import { StyledSectionTitle, StyledMarginDiv, StyledRectangle } from '../style';
import * as messages from '../messages';

interface AdditionnalFeeFormProps {
  projectState: Projet;
  calculetteFinanciereDispatch: React.Dispatch<CalculetteFinanciereAction>;
  getValueByThousand: (value: number | undefined | string) => string | undefined;
}

const AdditionnalFeeForm: React.FC<AdditionnalFeeFormProps> = ({
  projectState,
  calculetteFinanciereDispatch,
  getValueByThousand,
}) => {
  const [isFileFeeFocused, setIsFileFeeFocused] = useState<boolean>(false);
  const [isFileFeeValid, setIsFileFeeValid] = useState<boolean>();

  const [inputType, setInputType] = useState<string>(
    projectState.typeValeurFraisIntermediation || 'percentage',
  );

  const [isIntermediationFeeInputValid, setIsIntermediationFeeInputValid] =
    useState<boolean>();
  const [isIntermediationFeeInputFocused, setIsIntermediationFeeInputFocused] =
    useState<boolean>(false);

  const [interestRateValue, setInterestRateValue] = useState<string | undefined | number>(
    projectState?.valeurFraisIntermediation || '',
  );

  const realNumbers = new RegExp(ValidationRules.realNumbers);

  const handleIntermediationFeeChange = (value: string) => {
    if (!realNumbers.test(value)) {
      calculetteFinanciereDispatch({
        type: 'setValeurFraisIntermediation',
        payload: undefined,
      });
      return;
    }

    if (
      projectState.typeValeurFraisIntermediation === 'P' &&
      checkPercentageValue(value)
    ) {
      calculetteFinanciereDispatch({
        type: 'setValeurFraisIntermediation',
        payload: undefined,
      });
      return;
    }
    if (value.length > 5) {
      calculetteFinanciereDispatch({
        type: 'setValeurFraisIntermediation',
        payload: undefined,
      });
      return;
    }

    setInterestRateValue(value);
    const rate = Number(value.replaceAll(',', '.'));
    if (Number.isNaN(rate)) {
      calculetteFinanciereDispatch({
        type: 'setValeurFraisIntermediation',
        payload: undefined,
      });
    } else {
      calculetteFinanciereDispatch({
        type: 'setValeurFraisIntermediation',
        payload: rate,
      });
    }
  };

  const changeInputType = () => {
    if (inputType === 'amount') {
      setInputType('percentage');
      calculetteFinanciereDispatch({
        type: 'setTypeValeurFraisIntermediation',
        payload: 'P',
      });
      calculetteFinanciereDispatch({
        type: 'setValeurFraisIntermediation',
        payload: undefined,
      });
      setInterestRateValue(undefined);
    } else {
      setInputType('amount');
      calculetteFinanciereDispatch({
        type: 'setTypeValeurFraisIntermediation',
        payload: 'M',
      });
      calculetteFinanciereDispatch({
        type: 'setValeurFraisIntermediation',
        payload: undefined,
      });
      setInterestRateValue(undefined);
    }
  };

  const handleFileFee = (value: string) => {
    const rate = parseInt(value.replaceAll(' ', '').slice(0, 10), 10);
    if (Number.isNaN(rate)) {
      calculetteFinanciereDispatch({
        type: 'setFraisDossier',
        payload: undefined,
      });
    } else {
      calculetteFinanciereDispatch({
        type: 'setFraisDossier',
        payload: rate,
      });
    }
  };

  const onFileFeeBlur = () => {
    if (projectState.valeurFraisIntermediation !== undefined) setIsFileFeeValid(true);
  };

  const onIntermediationFeeBlur = () => {
    if (projectState.valeurFraisIntermediation !== undefined) {
      setIsIntermediationFeeInputValid(true);
    }
  };

  return (
    <>
      <StyledSectionTitle>{messages.CALCULATOR_FORM.variousFeeTitle}</StyledSectionTitle>
      <StyledMarginDiv>
        <CustomInput
          name="intermediation-fees-rate"
          label={messages.CALCULATOR_indermediation}
          value={
            inputType === 'amount'
              ? getValueByThousand(interestRateValue)
              : interestRateValue
          }
          onChange={newValue => {
            handleIntermediationFeeChange(newValue.replaceAll(' ', ''));
            setIsIntermediationFeeInputFocused(true);
          }}
          isValid={isIntermediationFeeInputValid}
          isFocused={isIntermediationFeeInputFocused}
          onBlur={() => {
            setIsIntermediationFeeInputFocused(false);
            onIntermediationFeeBlur();
          }}
          onFocus={() => {
            setIsIntermediationFeeInputFocused(true);
          }}
          after={inputType === 'amount' ? '€' : '%'}
          inputWidth="16.4rem"
          placeholder="0"
          className="bareme-input">
          <StyledRectangle onClick={changeInputType}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <ArrowLeftRightIcon />
            </div>
            <span style={{ fontWeight: 'bold' }}>
              {`saisir en ${inputType === 'amount' ? '%' : '€'} `}{' '}
            </span>
          </StyledRectangle>
        </CustomInput>
      </StyledMarginDiv>
      <StyledMarginDiv>
        <CustomInput
          name="file-fee"
          label={messages.CALCULATOR_fileFee}
          type="price"
          value={getValueByThousand(projectState.fraisDossier)}
          onFocus={() => {
            setIsFileFeeFocused(true);
          }}
          onChange={newValue => {
            handleFileFee(newValue);
          }}
          onBlur={() => {
            setIsFileFeeFocused(false);
            onFileFeeBlur();
          }}
          placeholder="0"
          isFocused={isFileFeeFocused}
          isValid={isFileFeeValid}
          after="€"
          inputWidth="40rem"
        />
      </StyledMarginDiv>
    </>
  );
};

export default AdditionnalFeeForm;
