import Space from 'components/space/Space';
import { hasCoBorrower } from 'containers/communs/utils';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyleNoChildContainer,
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledMainDisplayCard,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import {
  HousingStatus,
  MaritalStatus,
  SituationFoyerState,
} from 'reducers/situationFoyer/types';
import * as messages from '../../messages';

interface SituationFoyerConsultationProps {
  isProjectEditable: boolean | undefined;
}

const SituationFoyerConsultation: React.FC<SituationFoyerConsultationProps> = ({
  isProjectEditable,
}) => {
  const [situationFoyer, setSituationFoyer] = useState<SituationFoyerState>();

  useEffect(() => {
    const situationFoyerState = JSON.parse(
      sessionStorage.getItem('situationFoyer') || '{}',
    ) as SituationFoyerState;

    if (situationFoyerState) setSituationFoyer(situationFoyerState);
  }, []);

  const renderChildBirthYearData = () => {
    const perChunk = 5; // items per chunk

    const result = situationFoyer?.childrenInChargeBirthYear?.reduce(
      (onGoingArray: string[], item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        const resultArray = onGoingArray;
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = item.birthYear; // start a new chunk
        } else resultArray[chunkIndex] += `    ${item.birthYear}`;

        return resultArray;
      },
      [],
    );
    return (
      <>
        {result?.map(batch => {
          return (
            <StyledCardData key={batch} style={{ wordSpacing: '1.2rem' }}>
              {batch}
            </StyledCardData>
          );
        })}
      </>
    );
  };

  const renderHomeInformation = () => {
    return (
      <>
        <StyledCardTitle>{messages.MAIN_CARD_HOME_SITUATION_TITLE}</StyledCardTitle>
        <Space marginBottom="1.6rem" />
        {situationFoyer && (
          <>
            <StyledCardRow style={{ justifyContent: 'space-between' }}>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_ZIP_CODE}</StyledCardLabel>
                <StyledCardData>{situationFoyer?.addressZipCode}</StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_CITY}</StyledCardLabel>
                <StyledCardData>{situationFoyer?.addressCity?.libelle}</StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_LODGING_SITUATION}
                </StyledCardLabel>
                <StyledCardData>
                  {situationFoyer?.housingStatus &&
                    HousingStatus[situationFoyer?.housingStatus]}
                </StyledCardData>
              </StyledCardTile>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_LODGING_DURATION}
                </StyledCardLabel>
                <StyledCardData>
                  {situationFoyer?.startingYearLivingInCurrentHouse}
                </StyledCardData>
              </StyledCardTile>
            </StyledCardRow>
            <Space marginBottom="3.6rem" />
            <StyledCardRow>
              <StyledCardTile>
                <StyledCardLabel style={{ width: '16rem' }}>
                  {messages.MAIN_CARD_LABEL_LODGING_PERSON_AMOUNT}
                </StyledCardLabel>
                <StyledCardData>
                  {situationFoyer?.numberOfPersonInCurrentHouse}
                </StyledCardData>
              </StyledCardTile>
              {!situationFoyer?.childrenInCharge ? (
                <StyleNoChildContainer>
                  {messages.MAIN_CARD_LABEL_NO_CHILDREN}
                </StyleNoChildContainer>
              ) : (
                <StyledCardTile>
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LABEL_CHILDREN_BIRTH_YEARS}
                  </StyledCardLabel>
                  {renderChildBirthYearData()}
                </StyledCardTile>
              )}
            </StyledCardRow>
          </>
        )}
      </>
    );
  };

  const renderBorrowerInformation = (stakeholder: 'borrower' | 'coborrower') => {
    const maritalStatus =
      stakeholder === 'borrower'
        ? situationFoyer?.maritalStatus
        : situationFoyer?.coBorrowerMaritalStatus;

    return (
      <>
        <Space marginBottom="4.8rem" />
        <StyledCardTitle>
          {stakeholder === 'borrower'
            ? messages.MAIN_CARD_HOME_SITUATION_BORROWER_TITLE
            : messages.MAIN_CARD_HOME_SITUATION_COBORROWER_TITLE}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardTile>
          <StyledCardLabel style={{ width: '16rem' }}>
            {messages.MAIN_CARD_LABEL_HOME_SITUATION}
          </StyledCardLabel>
          <StyledCardData>{maritalStatus && MaritalStatus[maritalStatus]}</StyledCardData>
        </StyledCardTile>
      </>
    );
  };

  return (
    <StyledMainDisplayCard>
      {situationFoyer && renderHomeInformation()}
      {situationFoyer && renderBorrowerInformation('borrower')}
      {situationFoyer && hasCoBorrower() && renderBorrowerInformation('coborrower')}

      <ConsultationEditButton
        isProjectEditable={isProjectEditable}
        navigateRoute="/donnees_client"
        navigateDonneesClientStep="2"
      />
    </StyledMainDisplayCard>
  );
};

export default SituationFoyerConsultation;
