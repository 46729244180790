import * as React from 'react';

const NavigationRight: React.FC = () => (
  <svg width={20} height={12} xmlns="http://www.w3.org/2000/svg">
    <title>C11CF539-C9FC-4F8D-977C-1FED51F2488B</title>
    <path
      d="M0 6c0-.368.32-.667.714-.667H17.56L13.065 1.14a.636.636 0 0 1 0-.944.752.752 0 0 1 1.011 0l5.714 5.333c.135.125.21.295.21.472a.645.645 0 0 1-.21.472l-5.714 5.333a.752.752 0 0 1-1.011 0 .636.636 0 0 1 0-.944l4.495-4.194H.714C.32 6.667 0 6.368 0 6Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default NavigationRight;
