import { getCitiesByZipCode, getDepartmentCodeByZipCode } from 'api/referentialService';
import CityInput from 'components/cityInput/CityInput';
import CustomInput from 'components/customInput/CustomInput';
import { ComboInputDS } from 'components/designSystem/ComboInput';
import { useEffect, useRef, useState } from 'react';
import {
  GuaranteeAdditionalInformationAction,
  Notary,
} from 'reducers/guaranteeAdditionalInformation/type';
import { City, CodeLibelle, StatusControlInput, StatusInput } from 'types';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface ZipCodeAndCityProps {
  notary: Notary;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
  checkInputs?: boolean;
}

const NotaireZipCodeAndCity: React.FC<ZipCodeAndCityProps> = ({
  notary,
  dispatch,
  checkInputs,
}) => {
  const [isZipCodeInputFocused, setIsZipCodeInputFocused] = useState<boolean>(false);
  const [statusZipCode, setStatusZipCode] = useState<StatusControlInput>();
  const [cityList, setCityList] = useState<City[]>([]);
  const [isCityInvalid, setIsCityInvalid] = useState<boolean>();
  const [statusAddressCity, setStatusAddressCity] = useState<StatusInput>();

  const cityRef = useRef(null);

  const zipCode = new RegExp(ValidationRules.zipCode);
  const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const onZipCodeChange = async (val: string) => {
    if (!onlyNumbers.test(val)) return;
    try {
      let departmentCode;
      if (val.length === 5) departmentCode = await getDepartmentCodeByZipCode(val);
      const newNotary = { ...notary, addressZipCode: val, departmentCode };

      dispatch({
        type: 'setUpdateNotaryInformation',
        payload: newNotary,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onCityChange = (val: CodeLibelle | undefined) => {
    const newNotary = { ...notary, addressCity: val };
    dispatch({
      type: 'setUpdateNotaryInformation',
      payload: newNotary,
    });
  };

  const checkStatusZipCode = () => {
    if (!notary?.addressZipCode) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE,
      });
      return;
    }
    if (!zipCode.test(notary?.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE,
      });
      return;
    }
    if (!notEmpty.test(notary?.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE,
      });
      return;
    }
    if (!onlyNumbers.test(notary?.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_ONLY_NUMBERS,
      });
      return;
    }
    if (!zipCodeNoZero.test(notary?.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_NO_ZERO,
      });
      return;
    }
    if (cityList.length === 0) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.WRONG_ZIPCODE,
      });
      return;
    }
    onZipCodeChange(notary?.addressZipCode.slice(0, 5).trim());

    setStatusZipCode({
      status: true,
      errorMessage: '',
    });
  };

  useEffect(() => {
    if (notary?.addressZipCode && notary?.addressZipCode.length === 5) {
      checkStatusZipCode();
    }
  }, [cityList]);

  const checkStatusAddressCity = (cityByZipCodeList: CodeLibelle[]) => {
    setStatusAddressCity({
      status: 'none',
      errorMessage: '',
    });
    if (cityRef.current && cityRef.current !== null && notary?.addressCity) {
      const cityMatchesListElement = cityByZipCodeList?.find(city => {
        return city.libelle.toUpperCase() === notary?.addressCity?.libelle.toUpperCase();
      });

      if (cityMatchesListElement) {
        onCityChange(cityMatchesListElement);
        setStatusAddressCity({
          status: 'valid',
          errorMessage: '',
        });
      } else {
        onCityChange({
          code: 'err',
          libelle: notary?.addressCity.libelle ? notary?.addressCity.libelle : '',
        });
        setStatusAddressCity({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!notary?.addressCity)
      setStatusAddressCity({
        status: 'invalid',
        errorMessage: messagesCheckInput.ADDRESS_CITY_REQUIRED,
      });
  };

  const onCitySelect = (e: Event) => {
    const selectedCity = (e as CustomEvent).detail.value as City;
    onCityChange(selectedCity);
  };

  const onCityBlur = (event: Event) => {
    if (cityRef.current && cityRef.current !== null) {
      if (event && event.target) {
        const cityMatchesListElement = cityList?.find(city => {
          return (
            city.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (cityMatchesListElement) {
          onCityChange(cityMatchesListElement);
        } else {
          onCityChange({
            code: 'err',
            libelle: (event.target as unknown as ComboInputDS).value as string,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (notary?.addressZipCode?.length === 5) {
      getCitiesByZipCode(notary?.addressZipCode).then(cityByZipCodeList => {
        if ((notary.addressCity && notary.addressCity.libelle !== '') || checkInputs)
          checkStatusAddressCity(cityByZipCodeList);
        setCityList(cityByZipCodeList);
      });
    } else if (notary?.addressCity) {
      setCityList([notary?.addressCity]);
    } else {
      setIsCityInvalid(true);
      (cityRef.current as any).valid = !isCityInvalid;

      setCityList([]);
    }
  }, [notary?.addressZipCode]);

  useEffect(() => {
    if (checkInputs) {
      checkStatusZipCode();
      checkStatusAddressCity(cityList);
    }
  }, [checkInputs]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '15.6rem', marginRight: '1.6rem' }}>
        <CustomInput
          name="zip-code"
          label="Code postal"
          placeholder="75001"
          onChange={newValue => {
            onZipCodeChange(newValue.slice(0, 5));
          }}
          onBlur={checkStatusZipCode}
          onFocus={() => {
            setIsZipCodeInputFocused(true);
          }}
          isValid={statusZipCode?.status}
          isFocused={isZipCodeInputFocused}
          value={notary?.addressZipCode}
          error={statusZipCode?.errorMessage}
        />
      </div>

      <CityInput
        label="Ville"
        cityRef={cityRef}
        defaultValue={notary?.addressCity?.libelle || ''}
        cities={cityList}
        onCitySelect={onCitySelect}
        onCityBlur={onCityBlur}
        className="show-input-ds-validation"
        status={statusAddressCity?.status}
        errorMessage={statusAddressCity?.errorMessage}
      />
    </div>
  );
};

export default NotaireZipCodeAndCity;
