import GenericIconProps from './GenericIcon';

const DownloadIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 24;
  const baseHeight = 24;
  const baseColor = '#292C2E';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <title>Ic/24px/Navigation/download24px</title>
      <path
        d="M21.375 14.525c.345 0 .625.29.625.648v3.237c0 1.43-1.12 2.59-2.5 2.59h-15C3.12 21 2 19.84 2 18.41v-3.237c0-.358.28-.648.625-.648s.625.29.625.648v3.237c0 .715.56 1.295 1.25 1.295h15c.69 0 1.25-.58 1.25-1.295v-3.237c0-.358.28-.648.625-.648ZM12 3c.345 0 .625.29.625.647v11.387l2.682-2.78a.61.61 0 0 1 .885 0 .665.665 0 0 1 0 .917l-3.75 3.885a.614.614 0 0 1-.442.19.614.614 0 0 1-.443-.19l-3.75-3.885a.665.665 0 0 1 0-.917.61.61 0 0 1 .885 0l2.683 2.78V3.647c0-.357.28-.647.625-.647Z"
        fill={color || baseColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default DownloadIcon;
