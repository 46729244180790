import ActionCrossIcon from 'icons/ActionCrossIcon';
import { StyledDeleteButtonContainer, StyledTagContainer, StyledTagLabel } from './style';

interface FilterTagProps {
  label: string;
  onDelete: () => void;
}

const FilterTag: React.FC<FilterTagProps> = ({ label, onDelete }) => {
  return (
    <StyledTagContainer>
      <StyledTagLabel>{label}</StyledTagLabel>
      <StyledDeleteButtonContainer
        role="button"
        tabIndex={0}
        onClick={onDelete}
        onKeyPress={onDelete}>
        <ActionCrossIcon />
      </StyledDeleteButtonContainer>
    </StyledTagContainer>
  );
};

export default FilterTag;
