import NextButton from 'components/buttons/nextButton/NextButton';
import CancelButton from 'components/buttons/cancelButton/CancelButton';
import Space from 'components/space/Space';
import QuitArrow from './Assets/QuitArrow';
import Modal from '../Modal';
import {
  PlainModalText,
  StyledBlackTitle,
  StyledGeneralModalContentContainer,
} from '../style';
import GeneralIllustrationDocuments from './Assets/GeneralIllustrationDocuments';
import * as messages from './messages';

interface GeneralConfirmationModalProps {
  isVisible: boolean;
  isDataOK?: boolean;
  accept: () => void;
  close: () => void;
}

const GeneralConfirmationModal: React.FC<GeneralConfirmationModalProps> = ({
  isVisible,
  isDataOK,
  accept,
  close,
}) => {
  return (
    <Modal isVisible={isVisible} height="48.8rem" width="60rem" onClose={close}>
      <StyledGeneralModalContentContainer style={{ paddingBottom: '2.7rem' }}>
        <div style={{ marginLeft: '12.7rem' }}>
          <GeneralIllustrationDocuments />
        </div>

        <Space marginBottom="3.6rem" />

        <StyledBlackTitle>{messages.SAVE_QUIT_TITLE}</StyledBlackTitle>

        <Space marginBottom="1.6rem" />

        <PlainModalText>
          {isDataOK ? messages.SAVE_QUIT_DATA_OK : messages.SAVE_QUIT_DATA_KO}
        </PlainModalText>

        <Space marginBottom="4.8rem" />

        <div
          style={{
            display: 'flex',
            height: '4.8rem',
            marginLeft: '9.7rem',
          }}>
          <div style={{ marginRight: '1.6rem' }}>
            <CancelButton handleonClick={close} />
          </div>

          <NextButton
            isDisabled={false}
            handleonClick={accept}
            icon={<QuitArrow />}
            label="Sauvegarder et quitter"
          />
        </div>
      </StyledGeneralModalContentContainer>
    </Modal>
  );
};

export default GeneralConfirmationModal;
