import React from 'react';
import GenericIconProps from './GenericIcon';

const ArrowRightCircleFullIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 18;
  const baseHeight = 18;
  const baseColor = '#4b4f54';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>ArrowRightCircleFullIcon</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dashboard1"
          transform="translate(-1013.000000, -699.000000)"
          fill={color || baseColor}>
          <g
            id="Dossier/traitement/avant/decision2"
            transform="translate(264.000000, 471.000000)">
            <g
              id="Icon/24px/Navigation/arrow-right-Copy"
              transform="translate(749.666667, 228.666667)">
              <path
                d="M8.33333333,2.84217094e-14 C12.9357062,2.84217094e-14 16.6666667,3.73096042 16.6666667,8.33333333 C16.6666667,12.9357062 12.9357062,16.6666667 8.33333333,16.6666667 C3.73096042,16.6666667 0,12.9357062 0,8.33333333 C0,3.73096042 3.73096042,2.84217094e-14 8.33333333,2.84217094e-14 Z M4.6875,7.8125 C4.39985169,7.8125 4.16666667,8.04568503 4.16666667,8.33333333 C4.16666667,8.62098164 4.39985169,8.85416667 4.6875,8.85416667 L10.721875,8.85416667 L8.48541667,11.0895833 C8.35367498,11.221325 8.302224,11.4133427 8.3504448,11.5933052 C8.3986656,11.7732677 8.53923231,11.9138344 8.7191948,11.9620552 C8.89915729,12.010276 9.09117498,11.958825 9.22291667,11.8270833 L12.3479167,8.70208333 C12.4458768,8.60437056 12.500931,8.47169506 12.500931,8.33333333 C12.500931,8.1949716 12.4458768,8.06229611 12.3479167,7.96458333 L9.22291667,4.83958333 C9.09117498,4.70784165 8.89915729,4.65639066 8.7191948,4.70461147 C8.53923231,4.75283227 8.3986656,4.89339898 8.3504448,5.07336147 C8.302224,5.25332396 8.35367498,5.44534165 8.48541667,5.57708333 L10.721875,7.8125 L4.6875,7.8125 Z"
                id="arrow-right-circle-fill"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowRightCircleFullIcon;
