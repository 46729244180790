import { hasCoBorrower } from 'containers/communs/utils';
import { IdentityState } from 'reducers/identity/types';
import { RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import { SituationFoyerState } from 'reducers/situationFoyer/types';
import { SituationProfessionnelleState } from 'reducers/situationProfessionnelle/types';
import { GetInsuranceApiRequest, GetInsuranceApiResponse, Ressource } from 'types';
import { getAge, getDateFromInput } from 'utils/DateUtils';
import { consultationInstance } from './axiosInstances';

const getGroupeInsuranceInformationRequest = (
  identifiantEmprunteur: number,
  identifiantCoEmprunteur: number,
  mtAFin: number,
  dureeSouhaitee: number,
  isSansGarantie: boolean,
): GetInsuranceApiRequest => {
  const request = {} as GetInsuranceApiRequest;

  let borrower = {} as IdentityState;
  let result = sessionStorage.getItem('borrowerIdentity');
  if (result) {
    borrower = JSON.parse(result) as IdentityState;
  }

  let situationFoyer = {} as SituationFoyerState;
  result = sessionStorage.getItem('situationFoyer');
  if (result) {
    situationFoyer = JSON.parse(result) as SituationFoyerState;
  }

  let situationProfessionnelle = {} as SituationProfessionnelleState;
  result = sessionStorage.getItem('situationProfessionnelle');
  if (result) {
    situationProfessionnelle = JSON.parse(result) as SituationProfessionnelleState;
  }

  let ressourcesMensuelles = {} as RessourcesMensuellesState;
  result = sessionStorage.getItem('ressourcesMensuelles');
  if (result) {
    ressourcesMensuelles = JSON.parse(result) as RessourcesMensuellesState;
  }

  request.contextApp = 'EBRO';
  request.emprunteur = {
    ageEmp: getAge(getDateFromInput(borrower.birthDate || '')),
    codeCsp: situationProfessionnelle.borrower.profession?.code || '',
    codePost: Number(situationFoyer.addressZipCode),
    dateNaiss: getDateFromInput(borrower.birthDate || ''),
    idPros: identifiantEmprunteur,
    idStatutSal: situationProfessionnelle.borrower.typeContrat?.ordreString || '',
    indTypeAssu: 'G',
    idTypeContTrav: situationProfessionnelle.borrower.typeContrat?.code || null,
  };

  let mtDeclare =
    Number(ressourcesMensuelles.borrowerIncome.monthlyIncomes?.replaceAll(' ', '')) || 0;
  if (ressourcesMensuelles.borrowerIncome.retirementMonthlyIncomes) {
    mtDeclare += Number(
      ressourcesMensuelles.borrowerIncome.retirementMonthlyIncomes?.replaceAll(' ', ''),
    );
  }

  request.listeRessNonPartEmprunteur = [
    {
      mtDeclare,
    },
  ];

  if (hasCoBorrower()) {
    let coBorrower = {} as IdentityState;

    result = sessionStorage.getItem('coBorrowerIdentity');
    if (result) {
      coBorrower = JSON.parse(result) as IdentityState;
    }

    request.coEmprunteur = {
      ageEmp: getAge(getDateFromInput(coBorrower.birthDate || '')),
      codeCsp: situationProfessionnelle.coBorrower.profession?.code || '',
      codePost: Number(situationFoyer.addressZipCode),
      dateNaiss: getDateFromInput(coBorrower.birthDate || ''),
      idPros: identifiantCoEmprunteur,
      idStatutSal: situationProfessionnelle.coBorrower.typeContrat?.ordreString || '',
      indTypeAssu: 'G',
      idTypeContTrav: situationProfessionnelle.coBorrower.typeContrat?.code || null,
    };

    mtDeclare = Number(
      ressourcesMensuelles.coBorrowerIncome.monthlyIncomes?.replaceAll(' ', ''),
    );
    if (ressourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes) {
      mtDeclare += Number(
        ressourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes?.replaceAll(
          ' ',
          '',
        ),
      );
    }

    request.listeRessNonPartCoEmprunteur = [
      {
        mtDeclare,
      },
    ];
  }
  request.propositionCommercial = { mtAFin, dureeSouhaitee };
  request.typeGar = isSansGarantie ? 'SSG' : 'HYP';

  const ressources = [] as Ressource[];

  ressourcesMensuelles.otherIncomes.forEach(otherIncome => {
    ressources.push({
      idDetlChrgRess: otherIncome.code,
      montant: Number(otherIncome.value),
      idProsTitu: identifiantEmprunteur,
      idProsCjtTitu: identifiantCoEmprunteur,
    });
  });

  request.listeRessPart = ressources;

  return request;
};

const getGroupeInsuranceInformation = (
  identifiantEmprunteur: number,
  identifiantCoEmprunteur: number,
  mtAFin: number,
  dureeSouhaitee: number,
  isSansGarantie: boolean,
): Promise<GetInsuranceApiResponse> => {
  return consultationInstance
    .post(
      '/getDonneesAssurance',
      getGroupeInsuranceInformationRequest(
        identifiantEmprunteur,
        identifiantCoEmprunteur,
        mtAFin,
        dureeSouhaitee,
        isSansGarantie,
      ),
    )
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(error => {
      console.error(error);
    });
};

export default getGroupeInsuranceInformation;
