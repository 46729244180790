import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import Tooltip from 'components/tooltip/Tooltip';
import { StyledInputLabel } from 'containers/finalisationProjet/style';
import WarningIcon from 'icons/WarningIcon';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface LoanNumberProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanNumber: React.FC<LoanNumberProps> = ({ currentLoan, state, dispatch }) => {
  const [isLoanNumberInputFocused, setIsLoanNumberInputFocused] =
    useState<boolean>(false);
  const [isLoanNumberInputValid, setIsLoanNumberInputValid] = useState<
    boolean | undefined
  >();
  const [loanNumberInputError, setLoanNumberInputError] = useState<string>('');
  const [value, setValue] = useState<string>(currentLoan?.loanNumber || '');

  const onlyNumbersAndAlphabeticChars = new RegExp(
    ValidationRules.onlyNumbersAndAlphabeticChars,
  );
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const updateState = (val?: string) => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, loanNumber: val };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  const handleLoanNumberBlur = () => {
    setIsLoanNumberInputFocused(false);
    if (!notEmpty.test(value)) {
      setLoanNumberInputError(messagesCheckInput.REQUIRED_VALUE);
      setIsLoanNumberInputValid(false);
    } else if (!onlyNumbersAndAlphabeticChars.test(value)) {
      setLoanNumberInputError(messagesCheckInput.NO_SPECIAL_CHARACTER);
      setIsLoanNumberInputValid(false);
    } else {
      setIsLoanNumberInputValid(true);
      setLoanNumberInputError('');
    }
    updateState(value);
  };

  useEffect(() => {
    if (notEmpty.test(currentLoan?.loanNumber || '')) handleLoanNumberBlur();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <StyledInputLabel>N° de prêt</StyledInputLabel>
        <Tooltip topPosition={-3} leftPosition={2} icon={<WarningIcon color="#2275D7" />}>
          Merci de saisir le N° de prêt visible sur justificatif fourni
        </Tooltip>
      </div>
      <Space marginTop=".8rem" />
      <CustomInput
        name="loan-number"
        value={value}
        onChange={newValue => {
          if (onlyNumbersAndAlphabeticChars.test(newValue))
            setValue(newValue.toUpperCase().slice(0, 32));
          setIsLoanNumberInputFocused(true);
        }}
        isValid={isLoanNumberInputValid}
        isFocused={isLoanNumberInputFocused}
        onBlur={handleLoanNumberBlur}
        onFocus={() => {
          setIsLoanNumberInputFocused(true);
        }}
        inputWidth="20.8rem"
        placeholder="0"
        error={loanNumberInputError}
      />
    </>
  );
};

export default LoanNumber;
