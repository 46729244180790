import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import { StatusControlInput } from 'types';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../../messages';

interface IdentityNumberProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  person: 'borrower' | 'coBorrower';
  checkInputs?: boolean;
}
const IdentityNumber: React.FC<IdentityNumberProps> = ({
  state,
  dispatch,
  person,
  checkInputs,
}) => {
  const [idNumberInputFocused, setIdNumberInputFocused] = useState<boolean>(false);
  const [idNumber, setIdNumber] = useState<string>(
    (person === 'borrower'
      ? state.borrower.identityNumber
      : state.coBorrower.identityNumber) || '',
  );
  const [statusIdNumber, setStatusIdNumber] = useState<StatusControlInput>();

  const notEmpty = new RegExp(ValidationRules.notEmpty);
  const onlyNumbersAndAlphabeticChars = new RegExp(
    ValidationRules.onlyNumbersAndAlphabeticChars,
  );

  const handleIdNumberChange = (value: string) => {
    if (!onlyNumbersAndAlphabeticChars.test(value)) return;
    const idType =
      person === 'borrower' ? state.borrower.identityType : state.coBorrower.identityType;
    let newValue;
    if (idType?.code === 'I' || idType?.code === 'E') newValue = value.slice(0, 12);
    else if (idType?.code === 'S' || idType?.code === 'T') newValue = value.slice(0, 10);
    else newValue = value.slice(0, 9);
    setIdNumber(newValue);

    if (!value) {
      dispatch({
        type: 'setIdentityNumber',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setIdentityNumber',
        payload: { person, value: newValue },
      });
    }
  };

  const handleIdNumberBlur = () => {
    setIdNumberInputFocused(false);
    const id =
      person === 'borrower'
        ? state.borrower.identityNumber
        : state.coBorrower.identityNumber;
    const idType =
      person === 'borrower' ? state.borrower.identityType : state.coBorrower.identityType;

    if (!notEmpty.test(id || '')) {
      setStatusIdNumber({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }
    if (!onlyNumbersAndAlphabeticChars.test(id || '')) {
      setStatusIdNumber({
        status: false,
        errorMessage: messagesCheckInput.NO_SPECIAL_CHARACTER,
      });
      return;
    }
    if (idType?.code === 'I' && id?.length !== 12) {
      setStatusIdNumber({
        status: false,
        errorMessage: messagesCheckInput.IDENTITY_NUMBER_ERROR_LENGTH_12,
      });
      return;
    }
    if (idType?.code === 'E' && id?.length && id?.length > 12) {
      setStatusIdNumber({
        status: false,
        errorMessage: messagesCheckInput.IDENTITY_NUMBER_ERROR_LENGTH_MAX_12,
      });
      return;
    }
    if ((idType?.code === 'N' || idType?.code === 'P') && id?.length !== 9) {
      setStatusIdNumber({
        status: false,
        errorMessage: messagesCheckInput.IDENTITY_NUMBER_ERROR_LENGTH_9,
      });
      return;
    }
    if (
      (idType?.code === 'S' || idType?.code === 'T') &&
      id?.length !== 9 &&
      id?.length !== 10
    ) {
      setStatusIdNumber({
        status: false,
        errorMessage: messagesCheckInput.IDENTITY_NUMBER_ERROR_LENGTH_9_10,
      });
      return;
    }

    setStatusIdNumber({
      status: true,
      errorMessage: '',
    });
  };

  const shouldDisplayInput = (): boolean => {
    if (
      person === 'borrower' &&
      state.borrower &&
      state.borrower.identityType &&
      state.borrower.identityType.code !== 'A'
    )
      return true;
    if (
      person === 'coBorrower' &&
      state.coBorrower &&
      state.coBorrower.identityType &&
      state.coBorrower.identityType.code !== 'A'
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (checkInputs) {
      handleIdNumberBlur();
    }
  }, [checkInputs]);

  useEffect(() => {
    const id =
      person === 'borrower'
        ? state.borrower.identityNumber
        : state.coBorrower.identityNumber;
    if (notEmpty.test(id || '')) {
      handleIdNumberBlur();
    }
  }, [state.borrower.identityType || state.coBorrower.identityType]);

  return (
    <>
      {shouldDisplayInput() && (
        <CustomInput
          label={messages.ID_NUMBER_LABEL}
          name={`${person}-idNumber`}
          placeholder="ex: 000000000000"
          inputWidth="20.8rem"
          onChange={newValue => {
            handleIdNumberChange(newValue.replaceAll(' ', '').toUpperCase());
            setIdNumberInputFocused(true);
          }}
          onBlur={handleIdNumberBlur}
          onFocus={() => {
            setIdNumberInputFocused(true);
          }}
          value={idNumber}
          isFocused={idNumberInputFocused}
          isValid={statusIdNumber?.status}
          error={statusIdNumber?.errorMessage}
        />
      )}
    </>
  );
};

export default IdentityNumber;
