interface PlusCircleIconProps {
  color?: string;
}
const PlusCircleIcon: React.FC<PlusCircleIconProps> = ({ color }) => (
  <svg width={21} height={20} xmlns="http://www.w3.org/2000/svg">
    <title>DB13951D-ABC9-4BDC-BF1E-8A760CE3E41A</title>
    <path
      d="M10.075 0c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10Zm0 1.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5Zm0 3.75c.345 0 .625.28.625.625v3.75h3.75a.625.625 0 0 1 0 1.25H10.7v3.75a.625.625 0 0 1-1.25 0v-3.75H5.7a.625.625 0 1 1 0-1.25h3.75v-3.75c0-.345.28-.625.625-.625Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

PlusCircleIcon.defaultProps = {
  color: '#28883C',
};

export default PlusCircleIcon;
