import {
  Circle,
  CurrentStep,
  FuturCircle,
  FuturStep,
  Label,
  PastStep,
  StyledCheckIcon,
  Steps,
  Wrapper,
  PastCircle,
} from './style';

export interface IBreadcrumbProps {
  steps: string[];
  currentStep: number;
}

const Breadcrumb = ({ steps, currentStep = 0 }: IBreadcrumbProps): React.ReactElement => (
  <Steps>
    {steps.map((item, index) => {
      if (index < currentStep - 1) {
        return (
          <PastStep key={item}>
            <Wrapper>
              <PastCircle>
                <StyledCheckIcon />
              </PastCircle>
              <Label>{item}</Label>
            </Wrapper>
          </PastStep>
        );
      }
      if (index === currentStep - 1) {
        return (
          <CurrentStep key={item}>
            <Wrapper>
              <Circle>{currentStep}</Circle>
              <Label>{item}</Label>
            </Wrapper>
          </CurrentStep>
        );
      }
      return (
        <FuturStep key={item}>
          <Wrapper>
            <FuturCircle />
            <Label>{item}</Label>
          </Wrapper>
        </FuturStep>
      );
    })}
  </Steps>
);

export default Breadcrumb;
