import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useRef, useState } from 'react';
import { ValidationRules } from 'utils/InputValidation';
import { IbanAction, IbanState } from 'reducers/iban/type';
import { IbanBlockInfo } from './BlockIban';

interface IbanBlockProps {
  ibanInfo: IbanBlockInfo;
  state: IbanState;
  dispatch: React.Dispatch<IbanAction>;
  cursorCurrentPosition?: number;
  blockIndex: number;
  updateCursorPosition: (index: number) => void;
}

const IbanBlock: React.FC<IbanBlockProps> = ({
  ibanInfo,
  state,
  dispatch,
  blockIndex,
  cursorCurrentPosition,
  updateCursorPosition,
}) => {
  const getDefaultValue = (): string => {
    switch (blockIndex) {
      case 0:
        return state.ibanPart1 || '';
      case 1:
        return state.ibanPart2 || '';
      case 2:
        return state.ibanPart3 || '';
      case 3:
        return state.ibanPart4 || '';
      case 4:
        return state.ibanPart5 || '';
      case 5:
        return state.ibanPart6 || '';
      case 6:
        return state.ibanPart7 || '';
      case 7:
        return state.ibanPart8 || '';
      case 8:
        return state.ibanPart9 || '';
      default:
        return '';
    }
  };

  const [value, setValue] = useState<string>(getDefaultValue());
  const ibanRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [, setInputError] = useState<string>('');

  const notEmpty = new RegExp(ValidationRules.notEmpty);

  useEffect(() => {
    if (blockIndex === cursorCurrentPosition) {
      setIsInputFocused(true);
      if (ibanRef && ibanRef.current) {
        ibanRef.current.focus();
      }
    }
  }, [cursorCurrentPosition, blockIndex]);

  useEffect(() => {
    const newVal = getDefaultValue();
    setValue(newVal);
    const reg = new RegExp(ibanInfo.regex);
    if (notEmpty.test(newVal)) {
      if (reg.test(newVal)) {
        setIsInputValid(true);
        setInputError('');
      } else {
        setIsInputValid(false);
        setInputError('Valeur incorrecte.');
      }
    }
  }, [getDefaultValue()]);

  const handleIbanBlockChange = (val: string) => {
    if (val.length <= ibanInfo.maxLenght) {
      setValue(val.toUpperCase());
    }
    if (val.length === ibanInfo.maxLenght) {
      updateCursorPosition(blockIndex + 1);
    }
    if (val.length === 0) {
      updateCursorPosition(blockIndex - 1);
    }
  };

  const OnIbanPaste = (valPasted: string) => {
    let newValue = value;

    // position cursor
    const cursorPositionStart = ibanRef.current?.selectionStart || 0;
    const cursorPositionEnd = ibanRef.current?.selectionEnd || 0;
    if (cursorPositionStart !== cursorPositionEnd) {
      if (cursorPositionStart < cursorPositionEnd)
        newValue = value.slice(0, cursorPositionStart) + value.slice(cursorPositionEnd);
      if (cursorPositionEnd < cursorPositionStart)
        newValue = value.slice(0, cursorPositionEnd) + value.slice(cursorPositionStart);
    } else {
      newValue = value.slice(0, cursorPositionStart);
    }
    newValue += valPasted;

    setValue(
      newValue
        .replace(/[^a-zA-Z0-9]+/g, '')
        .toUpperCase()
        .slice(0, 4),
    );
    dispatch({
      type: 'SetPasteIban',
      payload: {
        partPaste: blockIndex,
        value: newValue
          .replace(/[^a-zA-Z0-9]+/g, '')
          .toUpperCase()
          .slice(0, Math.trunc(34 - 4 * blockIndex)),
      },
    });
    updateCursorPosition(blockIndex + Math.trunc(valPasted.length / 4));
  };

  const handleIbanBlockBlur = () => {
    setIsInputFocused(false);
    const reg = new RegExp(ibanInfo.regex);
    if (reg.test(value)) {
      setIsInputValid(true);
      setInputError('');
    } else {
      setIsInputValid(false);
      setInputError('Valeur incorrecte.');
    }
    if (value === '') {
      setIsInputValid(undefined);
      setInputError('');
    }
    dispatch({
      type: 'setIban',
      payload: { partToUpdate: ibanInfo.name, value },
    });
  };

  return (
    <div style={{ width: '6.6rem' }}>
      <CustomInput
        hideIcon
        name="iban"
        value={value}
        onChange={handleIbanBlockChange}
        isValid={isInputValid}
        isFocused={isInputFocused}
        onBlur={handleIbanBlockBlur}
        onFocus={() => {
          setIsInputFocused(true);
        }}
        onPaste={OnIbanPaste}
        inputWidth="6.6rem"
        parentInputRef={ibanRef}
        styleInput={{ padding: '0px 0.5rem' }}
      />
    </div>
  );
};

export default IbanBlock;
