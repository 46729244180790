import { GetSimulationResultApiResponse } from 'api/simulationService';
import Space from 'components/space/Space';
import { hasCoBorrower } from 'containers/communs/utils';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledMainDisplayCard,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import { ConditionsFinancieresState } from 'reducers/conditionsFinancieres/types';
import { formatValues } from 'utils/commun';
import * as messages from '../../messages';

interface ConditionsFinancieresConsultationProps {
  isProjectEditable?: boolean;
}

const ConditionsFinancieresConsultation: React.FC<
  ConditionsFinancieresConsultationProps
> = ({ isProjectEditable }) => {
  const [conditionsFinancieres, setconditionsFinancieres] =
    useState<ConditionsFinancieresState>();
  const [simulationResponse, setSimulationResponse] =
    useState<GetSimulationResultApiResponse>();

  useEffect(() => {
    const conditionsFinancieresObject = sessionStorage.getItem('conditionsFinancieres');

    if (conditionsFinancieresObject) {
      const conditionsFinancieresResult = JSON.parse(
        conditionsFinancieresObject,
      ) as ConditionsFinancieresState;
      setconditionsFinancieres(conditionsFinancieresResult);
    }

    const simulationResultObject = sessionStorage.getItem('simulationResult');

    if (simulationResultObject) {
      const simulationResult = JSON.parse(
        simulationResultObject,
      ) as GetSimulationResultApiResponse;
      setSimulationResponse(simulationResult);
    }
  }, []);

  const renderIntermediationFees = () => {
    const intermediationFeesRate = Number(
      conditionsFinancieres?.intermediationFeesRate,
    ).toLocaleString('fr-FR');

    const intermediationString = `${intermediationFeesRate}%`;

    return simulationResponse?.Resultat.Proposition.fraisIntermedation
      ? `${intermediationString} = ${formatValues(
          simulationResponse.Resultat.Proposition.fraisIntermedation,
          '€',
        )}`
      : intermediationString;
  };

  return (
    <>
      <StyledMainDisplayCard>
        <StyledCardTitle>{messages.MAIN_CARD_CONDITIONS_TITLE_FEES}</StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardRow>
          <StyledCardTile>
            <StyledCardLabel>{messages.MAIN_CARD_CONDITIONS_TITLE_FEES}</StyledCardLabel>
            <StyledCardData>{renderIntermediationFees()}</StyledCardData>
          </StyledCardTile>
        </StyledCardRow>

        <Space marginBottom="4rem" />

        <StyledCardTitle>{messages.MAIN_CARD_CONDITIONS_TITLE_DURATION}</StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardRow>
          <StyledCardTile>
            <StyledCardLabel>
              {messages.MAIN_CARD_CONDITIONS_LABEL_LOAN_DURATION}
            </StyledCardLabel>
            <StyledCardData>
              {formatValues(conditionsFinancieres?.loanDuration?.toString(), 'mois')}
            </StyledCardData>
          </StyledCardTile>
          <StyledCardTile>
            <StyledCardLabel>
              {messages.MAIN_CARD_CONDITIONS_LABEL_FRANCHISE_DURATION}
            </StyledCardLabel>
            <StyledCardData>
              {formatValues(conditionsFinancieres?.franchiseDuration?.toString(), 'mois')}
            </StyledCardData>
          </StyledCardTile>
          {conditionsFinancieres?.firstPalierDuration && (
            <StyledCardTile>
              <StyledCardLabel>
                {messages.MAIN_CARD_CONDITIONS_LABEL_FIRST_STEP_DURATION}
              </StyledCardLabel>
              <StyledCardData>
                {formatValues(
                  conditionsFinancieres?.firstPalierDuration?.toString(),
                  'mois',
                )}
              </StyledCardData>
            </StyledCardTile>
          )}
        </StyledCardRow>

        {conditionsFinancieres?.firstPalierMonthlyPayment && (
          <>
            <Space marginBottom="3.2rem" />

            <StyledCardRow>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_CONDITIONS_LABEL_FIRST_PAYMENT_AMOUNT}
                </StyledCardLabel>
                <StyledCardData>
                  {formatValues(
                    conditionsFinancieres?.firstPalierMonthlyPayment?.toString(),
                    '€ / mois',
                  )}
                </StyledCardData>
              </StyledCardTile>
            </StyledCardRow>
          </>
        )}

        <Space marginBottom="4rem" />

        <StyledCardTitle>{messages.MAIN_CARD_CONDITIONS_TITLE_BAREME}</StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardRow>
          <StyledCardTile>
            <StyledCardLabel>
              {messages.MAIN_CARD_CONDITIONS_LABEL_INTEREST_RATE}
            </StyledCardLabel>
            <StyledCardData>
              {formatValues(conditionsFinancieres?.nominalInterestRate?.toString(), '%')}
            </StyledCardData>
          </StyledCardTile>
        </StyledCardRow>

        <Space marginBottom="4rem" />

        <StyledCardTitle>
          {messages.MAIN_CARD_CONDITIONS_TITLE_LOAN_INSURANCE}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardRow>
          <StyledCardTile>
            <StyledCardLabel>
              {messages.MAIN_CARD_CONDITIONS_LABEL_BORROWER}
            </StyledCardLabel>
            <StyledCardData>
              {conditionsFinancieres?.borrowerInsurance?.insurance?.libelle}
            </StyledCardData>
          </StyledCardTile>
        </StyledCardRow>

        {hasCoBorrower() && conditionsFinancieres?.coBorrowerInsurance?.insurance && (
          <>
            <Space marginBottom="3.2rem" />

            <StyledCardRow>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_CONDITIONS_LABEL_COBORROWER}
                </StyledCardLabel>
                <StyledCardData>
                  {conditionsFinancieres?.coBorrowerInsurance.insurance?.libelle}
                </StyledCardData>
              </StyledCardTile>
            </StyledCardRow>
          </>
        )}

        <ConsultationEditButton
          isProjectEditable={isProjectEditable}
          navigateRoute="/conditions_financieres"
        />
      </StyledMainDisplayCard>
    </>
  );
};

export default ConditionsFinancieresConsultation;
