export const FEEDBACK_MAIN_TITLE_TRANSMITED_CLIENT_FILE =
  'Le dossier a bien été transmis à notre service Etudes';
export const FEEDBACK_MAIN_TITLE_TRANSMITED_CLIENT_FILE_AND_DOCUMENTS =
  'Le dossier et les pièces justificatives ont bien été transmis à l’octroi pour étude';

export const FEEDBACK_MAIN_TITLE_TRANSMITED_CLIENT_FILE_AND_PARTIAL_DOCUMENTS =
  'Transmission partielle';

export const FEEDBACK_SUB_TITLE =
  "Suivez l’avancement de votre dossier depuis l'écran d'accueil";

export const FEEDBACK_SUB_TITLE_PARTIAL_SEND_1 =
  "Votre dossier et les pièces justificatives ont été transmis à l'octroi.";

export const FEEDBACK_SUB_TITLE_PARTIAL_SEND_2 =
  'Cependant nous avons rencontré des difficultés avec le traitement de certains documents.';

export const FEEDBACK_SUCCESS_MESSAGE =
  'Le pack contractuel papier a été émis avec succès.';

export const FEEDBACK_WARNING_TITLE = 'Transmission des pièces justificatives';
export const FEEDBACK_WARNING_MESSAGE =
  'Merci de nous envoyer l’ensemble des pièces justificatives attendues pour une prise en charge rapide de votre dossier.';
export const FEEDBACK_WARNING_ALL_FAIL_TITLE =
  'Votre dossier a bien été transmis à l’octroi, cependant nous avons rencontré un problème avec les pièces justificatives, qui n’ont pas pu être traitées. Nous vous prions de bien vouloir les renvoyer ultérieurement';
export const FEEDBACK_TRANSMISSION_RECAP = 'Fiche de transmission';
export const FEEDBACK_CLIENT_FILE = 'Dossier client';

export const FEEDBACK_NEXT_BUTTON_LABEL_DONE = "Terminer et retour à l'accueil";
export const FEEDBACK_NEXT_BUTTON_LABEL_SIGN = 'Terminer et accéder à la e-signature';
