import { useEffect, useState } from 'react';
import { formatDateForInput } from 'utils/DateUtils';

interface BaseDateInputProps {
  value?: string;
  setValue: (newValue: string) => void;
  placeholder: string;
  type: 'day' | 'month' | 'year';
  style: React.CSSProperties;
  focusedStyle?: React.CSSProperties;
  validStyle?: React.CSSProperties;
}

const getDateInputMaxLength = (type: 'day' | 'month' | 'year'): number => {
  switch (type) {
    case 'day':
      return 10;
    case 'month':
      return 7;
    case 'year':
      return 4;
    default:
      return 0;
  }
};

const BaseDateInput: React.FC<BaseDateInputProps> = ({
  value,
  setValue,
  placeholder,
  type,
  style,
  focusedStyle,
  validStyle,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  const currentFocusedStyle = isFocused ? focusedStyle : undefined;
  const currentValidStyle = isValid ? validStyle : undefined;
  const currentStyle = currentFocusedStyle
    ? { ...style, ...focusedStyle }
    : { ...style, ...currentValidStyle };

  const onValueChange = (changedValue: string) => {
    const lastChar = changedValue.charAt(changedValue.length - 1);

    if (
      (lastChar >= '0' && lastChar <= '9') ||
      lastChar === '/' ||
      changedValue.length === 0
    ) {
      const newValue = formatDateForInput(changedValue, type);

      setValue(newValue);
    }
  };

  useEffect(() => {
    if (value) setIsValid(true);
    else setIsValid(undefined);
  }, [value]);

  return (
    <>
      <input
        value={value}
        placeholder={placeholder}
        style={{ ...currentStyle, ...{ outline: 'none' } }}
        maxLength={getDateInputMaxLength(type)}
        onFocus={() => {
          setIsValid(undefined);
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          if (value) setIsValid(true);
        }}
        onChange={e => {
          onValueChange(e.target.value);
        }}
      />
    </>
  );
};

export default BaseDateInput;
