const LeftArrow = (): React.ReactElement => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
    <title>3296A3C6-D7EF-495F-970E-9835CC09E762</title>
    <path
      d="M1.25 10a8.75 8.75 0 1 0 17.5 0 8.75 8.75 0 0 0-17.5 0ZM20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-5.625-.625a.625.625 0 1 1 0 1.25H7.134l2.684 2.682a.626.626 0 0 1-.886.886l-3.75-3.75a.625.625 0 0 1 0-.886l3.75-3.75a.626.626 0 0 1 .886.885L7.134 9.375h7.241Z"
      fill="#28883C"
      fillRule="evenodd"
    />
  </svg>
);

export default LeftArrow;
