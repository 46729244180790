const ArrowLeftRightIcon: React.FC = () => (
  <svg width={15} height={17} xmlns="http://www.w3.org/2000/svg">
    <title>ACB5032D-F5E0-43CD-B3DD-4A8556725435</title>
    <path
      d="M.001 12.218c0 .294.24.532.536.532H13.17L9.8 16.092a.529.529 0 0 0 0 .752.54.54 0 0 0 .758 0l4.286-4.25a.529.529 0 0 0 0-.752l-4.286-4.25a.54.54 0 0 0-.758 0 .529.529 0 0 0 0 .753l3.371 3.342H.537a.533.533 0 0 0-.536.531Zm14.998-7.436c0 .293-.24.53-.536.53H1.83L5.2 8.656a.529.529 0 0 1-.24.89.54.54 0 0 1-.518-.138L.157 5.157a.529.529 0 0 1 0-.752L4.443.155a.54.54 0 0 1 .758 0c.21.208.21.545 0 .753L1.83 4.25h12.633c.296 0 .536.238.536.532Z"
      fill="#268038"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowLeftRightIcon;
