import styled from 'styled-components';

export const StyledTagContainer = styled.div`
  height: 2.4rem;
  width: fit-content;
  border-radius: 0.4rem 0.4rem 0.4rem 0.2rem;
  background-color: ${props => (props.color ? props.color : '#B1B5B9')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
`;

export const StyledTagLabel = styled.p`
  height: 1.6rem;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 1.2rem;
  letter-spacing: 0;
  line-height: 1.6rem;
  text-align: center;
  white-space: nowrap;
`;
