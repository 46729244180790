import GenericIconProps from './GenericIcon';

const FileIcon: React.FC<GenericIconProps> = ({ color }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>E0BC3154-1729-416C-A088-4039E2F143A1</title>
    <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Dashboard5.2"
        transform="translate(-1114.000000, -281.000000)"
        fill={color}
        fillRule="nonzero">
        <g id="Recherche/statut" transform="translate(1090.000000, 257.000000)">
          <g
            id="Icon/24px/Document/folder-search-Copy"
            transform="translate(24.000000, 24.000000)">
            <g id="Group" transform="translate(5.000000, 2.000000)">
              <path
                d="M14.5,0.60030015 C14.5,0.270135068 14.2312972,0 13.9028826,0 L4.5082361,0 C4.3490048,0 4.19972546,0.060030015 4.08030199,0.180090045 L0.179135209,4.10205103 C0.0597117364,4.21210605 0,4.37218609 0,4.53226613 L0,19.3996998 C0,19.7298649 0.268702814,20 0.597117364,20 L13.8929307,20 C14.2213452,20 14.490048,19.7298649 14.490048,19.3996998 L14.490048,11.3556778 C14.490048,11.3156578 14.490048,11.2856428 14.4800961,11.2556278 L14.5,0.60030015 Z M13.3057653,13.0265133 L13.3057653,18.7993997 L1.20418668,18.7993997 L1.20418668,5.13256628 L4.4186685,5.13256628 C4.74708305,5.13256628 5.01578586,4.86243122 5.01578586,4.53226613 L5.01578586,1.2006003 L13.3057653,1.2006003 L13.2958133,12.9064532 C13.2958133,12.9464732 13.2958133,12.9864932 13.3057653,13.0265133 Z"
                id="Shape"
              />
              <path
                d="M10.8874399,14.3471736 L3.61256005,14.3671836 C3.29409746,14.3671836 3.04529856,14.6373187 3.04529856,14.9674837 C3.04529856,15.2976488 3.30404942,15.5677839 3.61256005,15.5677839 L10.8874399,15.5477739 C11.2059025,15.5477739 11.4547014,15.2776388 11.4547014,14.9474737 C11.4646534,14.6073037 11.2059025,14.3471736 10.8874399,14.3471736 Z"
                id="Path"
              />
              <path
                d="M3.63246397,12.166083 L10.8874399,12.146073 C11.1959506,12.146073 11.4547014,11.875938 11.4547014,11.5457729 C11.4547014,11.2156078 11.1959506,10.9454727 10.8874399,10.9454727 L3.63246397,10.9654827 C3.32395333,10.9654827 3.06520247,11.2356178 3.06520247,11.5657829 C3.06520247,11.895948 3.31400137,12.166083 3.63246397,12.166083 Z"
                id="Path"
              />
              <path
                d="M10.8973919,7.36368184 L3.63246397,7.36368184 C3.31400137,7.36368184 3.05525051,7.63381691 3.05525051,7.96398199 C3.05525051,8.29414707 3.31400137,8.56428214 3.63246397,8.56428214 L10.8874399,8.56428214 C11.2059025,8.56428214 11.4646534,8.29414707 11.4646534,7.96398199 C11.4646534,7.63381691 11.2059025,7.36368184 10.8973919,7.36368184 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

FileIcon.defaultProps = {
  color: '#4B4F54',
};

export default FileIcon;
