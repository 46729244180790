import { ComboInput } from 'components/designSystem/ComboInput';
import { Country } from 'types';
import { getNotEmpty } from 'utils/InputValidation';

interface CountryInputProps {
  countryRef?: React.MutableRefObject<null>;
  countries: Country[];
  onCountryBlur?: (event: Event) => void;
  onCountrySelect: (event: Event) => void;
  onCountryFilter?: (event: Event) => void;
  defaultValue: string;
  label: string;
  placeholder?: string;
  className?: string;
  status?: 'none' | 'invalid' | 'valid';
  errorMessage?: string;
}
const CountryInput: React.FC<CountryInputProps> = ({
  countryRef,
  countries,
  onCountryBlur,
  onCountrySelect,
  onCountryFilter,
  defaultValue,
  label,
  placeholder,
  className,
  status,
  errorMessage,
}) => {
  return (
    <ComboInput
      class={`combo-input ${className}`}
      ref={countryRef}
      list-on-open
      shadow
      align-items="left"
      items={JSON.stringify(countries)}
      field="libelle"
      label={label}
      placeholder={placeholder || 'France'}
      onListBlur={onCountryBlur}
      onLetterChanged={onCountryFilter}
      onSelectedList={onCountrySelect}
      value={defaultValue}
      {...(status !== 'none' ? { status } : {})}
      {...(errorMessage !== '' ? { 'error-message': errorMessage } : {})}
      customValidityPattern={JSON.stringify(getNotEmpty())}
    />
  );
};
CountryInput.defaultProps = {
  className: '',
};
export default CountryInput;
