import React from 'react';
import { CalcPropositionDto } from 'types/FaisabiliteDTO';
import Space from 'components/space/Space';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import * as messages from '../messages';
import {
  StyledLabelValue,
  StyledHorizontalWrapper,
  StyledDottedLine,
  StyledSubTitleCard,
  Line,
  SummaryWrapperAssurance,
  StyledSubSectionCard,
} from '../style';

const delayItems = [
  { code: '01', libelle: '30 jours' },
  { code: '02', libelle: '90 jours' },
];

interface AssuranceGroupeProps {
  simulationResult: CalcPropositionDto;
  renderValuePercent: (value?: string | number) => void;
  renderValueEuro: (value?: string | number) => void;
  renderValueEuroByMonth: (value?: string | number) => void;
}

const AssuranceGroupe: React.FC<AssuranceGroupeProps> = ({
  simulationResult,
  renderValuePercent,
  renderValueEuro,
  renderValueEuroByMonth,
}) => {
  const monthlyPayment =
    simulationResult?.echeancierAvecAssurance.length > 1
      ? simulationResult?.echeancierAvecAssurance[1].echeance
      : simulationResult?.echeancierAvecAssurance[0].echeance;

  const primeMensuelleBorrower =
    simulationResult?.echeancierAssuranceEmprunteur.length > 1
      ? simulationResult?.echeancierAssuranceEmprunteur[1].echeance
      : simulationResult?.echeancierAssuranceEmprunteur[0].echeance;

  const primeMensuelleCoBorrower =
    simulationResult?.echeancierAssuranceEmprunteur.length > 1
      ? simulationResult?.echeancierAssuranceCoEmprunteur[1].echeance
      : simulationResult?.echeancierAssuranceCoEmprunteur[0].echeance;

  const borrowerinfo = [
    {
      label: messages.CALCULATOR_RESULT.assuranceDeces,
      value: renderValuePercent(simulationResult.assuranceEmprunteur.quotiteDeces),
    },
    {
      label: messages.CALCULATOR_RESULT.assurancePTIA,
      value: renderValuePercent(simulationResult.assuranceEmprunteur.quotitePTIA),
    },
    {
      label: messages.CALCULATOR_RESULT.assuranceITT,
      value: renderValuePercent(simulationResult.assuranceEmprunteur.quotiteITT),
    },
    {
      label: messages.CALCULATOR_RESULT.jobLoss,
      value: renderValuePercent(simulationResult.assuranceEmprunteur.quotitePerteEmploi),
    },
    {
      label: messages.CALCULATOR_RESULT.franchiseDuration,
      value: delayItems.find(
        item => item.code === simulationResult.assuranceEmprunteur.delaiFranchise,
      )?.libelle,
    },
    {
      label: messages.CALCULATOR_RESULT.primeMensuelle,
      value: renderValueEuroByMonth(primeMensuelleBorrower),
    },
  ];

  const coBorrowerinfo = [
    {
      label: messages.CALCULATOR_RESULT.assuranceDeces,
      value: renderValuePercent(simulationResult.assuranceCoEmprunteur.quotiteDeces),
    },
    {
      label: messages.CALCULATOR_RESULT.assurancePTIA,
      value: renderValuePercent(simulationResult.assuranceCoEmprunteur.quotitePTIA),
    },
    {
      label: messages.CALCULATOR_RESULT.assuranceITT,
      value: renderValuePercent(simulationResult.assuranceCoEmprunteur.quotiteITT),
    },
    {
      label: messages.CALCULATOR_RESULT.jobLoss,
      value: renderValuePercent(
        simulationResult.assuranceCoEmprunteur.quotitePerteEmploi,
      ),
    },
    {
      label: messages.CALCULATOR_RESULT.franchiseDuration,
      value: delayItems.find(
        item => item.code === simulationResult.assuranceCoEmprunteur.delaiFranchise,
      )?.libelle,
    },
    {
      label: messages.CALCULATOR_RESULT.primeMensuelle,
      value: renderValueEuroByMonth(primeMensuelleCoBorrower),
    },
  ];

  const totalCreditWithInsurance =
    simulationResult.coutTotalCredit + simulationResult.coutTotalAssurance;

  const renderBorrowerinfo = () => {
    return (
      <>
        {borrowerinfo.map(info => (
          <StyledHorizontalWrapper key={info.label}>
            <StyledLabelValue>{info.label}</StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {info.value}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
        ))}
      </>
    );
  };

  const rendeCoBorrowerinfo = () => {
    return (
      <>
        {coBorrowerinfo.map(info => (
          <StyledHorizontalWrapper key={info.label}>
            <StyledLabelValue>{info.label}</StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {info.value}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
        ))}
      </>
    );
  };
  return (
    <SummaryWrapperAssurance>
      <StyledSubTitleCard>
        {messages.CALCULATOR_RESULT.assuranceGroupe}
      </StyledSubTitleCard>
      <Space marginTop="2rem" />
      {simulationResult.assuranceEmprunteur.typeAssuranceChoisie === 'G' && (
        <>
          <StyledSubSectionCard>
            {messages.CALCULATOR_warrantyBorrower}
          </StyledSubSectionCard>
          <Space marginTop="2rem" />
          {renderBorrowerinfo()}
        </>
      )}
      {/* Check si co-emprunteur */}
      {simulationResult.assuranceCoEmprunteur.typeAssuranceChoisie === 'G' && (
        <>
          <CoBorrowerTitle />
          {rendeCoBorrowerinfo()}
        </>
      )}
      <Line />
      <StyledHorizontalWrapper>
        <StyledLabelValue>
          {messages.CALCULATOR_RESULT.mensualiteAssurance}
        </StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValueEuroByMonth(monthlyPayment)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>{messages.CALCULATOR_RESULT.taea}</StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValuePercent(simulationResult.taea)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>
          {messages.CALCULATOR_RESULT.insuranceTotalCost}
        </StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValueEuro(simulationResult.coutTotalAssurance)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
      <StyledHorizontalWrapper>
        <StyledLabelValue>{messages.CALCULATOR_RESULT.creditTotalCost}</StyledLabelValue>
        <StyledDottedLine />
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>
          {renderValueEuro(totalCreditWithInsurance)}
        </StyledLabelValue>
      </StyledHorizontalWrapper>
    </SummaryWrapperAssurance>
  );
};

export default AssuranceGroupe;
