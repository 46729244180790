import styled from 'styled-components';

export const StyledSelectionWrapper = styled.div<{ isSelected: boolean }>`
  height: 4.8rem;
  width: 48.4rem;
  border: 0.1rem solid
    ${({ isSelected, theme }) =>
      isSelected
        ? theme.colors.chartColors.mainGreen500
        : theme.colors.chartColors.neutral500};
  border-radius: 0.4rem;
  margin: 2.4rem 9.6rem;
  display: flex;
  align-items: center;
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.chartColors.mainGreen50
      : theme.colors.chartColors.neutral0};
`;

export const StyledCheckboxWrapper = styled.div`
  padding-left: 1.2rem;
  color: ${({ theme }) => theme.colors.chartColors.neutral500};
`;

export const StyledBtnWrapper = styled.div`
  display: flex;
  margin-top: 6.4rem;
  margin-left: 9.4rem;
  margin-bottom: 4.4rem;
  gap: 4.2rem;
  align-items: center;
`;

export const StyledSumWrapper = styled.div`
  height: 5.6rem;
  width: 94rem;
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.chartColors.mainGreen50};
  color: ${({ theme }) => theme.colors.chartColors.neutral500};
  font-size: 1.6rem;
  & div {
    padding: 1.6rem 0 1.6rem 3.2rem;
  }
`;
