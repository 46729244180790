interface RefreshIconProps {
  color?: string;
}

const RefreshIcon: React.FC<RefreshIconProps> = ({ color }) => {
  return (
    <svg
      width="24px"
      height="19px"
      viewBox="0 0 24 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>RefreshIcon</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
        <g
          id="Dashboard5.3"
          transform="translate(-1267.000000, -474.000000)"
          fill={color}>
          <g id="Button/ghost/green/M" transform="translate(1267.000000, 471.000000)">
            <g
              id="Icon/24px/Navigation/refresh"
              transform="translate(0.000000, 3.000000)">
              <path
                d="M0.388666046,11.0838534 C0.237845254,11.0837959 0.100663349,10.9949159 0.0367011069,10.8558159 C-0.0272611356,10.7167159 -0.00646309838,10.5524934 0.0900622258,10.4344735 L3.14764092,6.69657943 C3.22151239,6.60636549 3.33090165,6.55421074 3.44624474,6.55421074 C3.56158783,6.55421074 3.6709771,6.60636549 3.74484856,6.69657943 L6.80242726,10.4344735 C6.89895258,10.5524934 6.91975062,10.7167159 6.85578838,10.8558159 C6.79182614,10.9949159 6.65464423,11.0837959 6.50382344,11.0838534 L4.37938168,11.0838534 C4.98025626,14.0726446 7.21289377,16.4445224 10.1196716,17.1821414 C13.0264495,17.9197604 16.0908595,16.8920523 17.9985153,14.5398216 C18.1719129,14.3109371 18.4539991,14.1964626 18.7344472,14.2411702 C19.0148954,14.2858777 19.2491395,14.4826629 19.3455645,14.7545611 C19.4419896,15.0264593 19.3852726,15.3302633 19.1975962,15.5471524 C16.8721515,18.4144232 13.1185171,19.6374562 9.59067801,18.6773395 C6.06283894,17.7172228 3.40867858,14.7502806 2.79771457,11.0838534 Z M12,1.58073285 C9.58628579,1.58073285 7.42762901,2.70051723 6.00148472,4.46017839 C5.8280871,4.6890629 5.54600093,4.80353741 5.26555279,4.75882985 C4.98510464,4.71412228 4.75086049,4.51733712 4.65443545,4.24543891 C4.55801041,3.97354069 4.61472736,3.66973672 4.80240376,3.45284761 C7.12784848,0.585576789 10.8814829,-0.637456224 14.409322,0.322660488 C17.9371611,1.2827772 20.5913214,4.24971937 21.2022854,7.91614657 L23.611334,7.91614657 C23.7621547,7.91620415 23.8993367,8.00508409 23.9632989,8.1441841 C24.0272611,8.28328411 24.0064631,8.44750658 23.9099378,8.56552648 L20.8523591,12.3034206 C20.7784876,12.3936345 20.6690983,12.4457893 20.5537553,12.4457893 C20.4384122,12.4457893 20.3290229,12.3936345 20.2551514,12.3034206 L17.1975727,8.56552648 C17.1010474,8.44750658 17.0802494,8.28328411 17.1442116,8.1441841 C17.2081739,8.00508409 17.3453558,7.91620415 17.4961766,7.91614657 L19.6206183,7.91614657 C18.8803711,4.22908608 15.6953259,1.58119346 12,1.58073285 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

RefreshIcon.defaultProps = {
  color: '#008859',
};

export default RefreshIcon;
