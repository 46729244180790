import styled from 'styled-components';

export const StyledDateStart = styled.div``;
export const StyledDateEnd = styled.div`
  margin-left: 3rem;
  display: flex;
`;
export const StyledBorder = styled.div`
  padding: 0.9rem 1.2rem;
  font-size: 1.4rem;
  color: #555;
  background-color: ${({ theme }) => theme.colors.chartColors.neutral0};
  background-image: none;
  border: ${({ theme }) => `0.1rem solid ${theme.colors.chartColors.neutral200}`};
  border-radius: 0.4rem;
  box-shadow: inset 0 0.1rem 0.1rem rgb(0 0 0 / 8%);
`;

export const StledButtonWrapper = styled.div`
  margin-left: 3rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledTable = styled.table`
  border: 0.1rem solid #ccc;
  border-radius: 0.4rem;
`;
