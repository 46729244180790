import CheckIcon from 'icons/CheckIcon';
import styled from 'styled-components';

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 5.6rem;
  height: 3.2rem;
  background: grey;
  border-radius: 10rem;
  position: relative;
  transition: background-color 0.2s;
  text-align: center;
`;

export const ToggleCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const ToggleCheckIcon = styled(CheckIcon)`
  height: 1.5rem;
  width: 1.5rem;
  color: #3a913f;
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
`;
