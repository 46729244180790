interface TransfertIconProps {
  color?: string;
}

const TransfertIcon: React.FC<TransfertIconProps> = ({ color }) => (
  <svg width={16} height={20} xmlns="http://www.w3.org/2000/svg">
    <title>38E7F781-1DF5-47C4-9121-DECDCE258F5E</title>
    <path
      d="M4.667 6.25c.368 0 .666.28.666.624 0 .346-.298.625-.666.625H2c-.368 0-.667.28-.667.625v10c0 .345.299.625.667.625h12c.368 0 .667-.28.667-.625v-10c0-.345-.299-.625-.667-.625h-2.667c-.368 0-.666-.28-.666-.625s.298-.625.666-.625H14c1.105 0 2 .84 2 1.875v10c0 1.035-.895 1.875-2 1.875H2c-1.105 0-2-.84-2-1.875v-10C0 7.09.895 6.25 2 6.25h2.667ZM8-.002a.69.69 0 0 1 .472.183l4 3.75c.26.244.26.64 0 .885a.7.7 0 0 1-.944 0L8.667 2.133v10.991c0 .345-.299.625-.667.625-.368 0-.667-.28-.667-.625V2.134L4.472 4.816a.698.698 0 0 1-.645.162.648.648 0 0 1-.472-.443.6.6 0 0 1 .173-.604l4-3.75A.69.69 0 0 1 8 0Z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

TransfertIcon.defaultProps = {
  color: '#fff',
};

export default TransfertIcon;
