const FilingCabinetIcon: React.FC = () => {
  return (
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>9F779C4F-40F9-4D77-B06B-0102255DDD82</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dashboard5.1"
          transform="translate(-26.000000, -462.000000)"
          fill="#4B4F54">
          <g id="Menu-Copy-5" transform="translate(-446.000000, 0.000000)">
            <g id="Mes-offres-Sygma" transform="translate(470.000000, 413.000000)">
              <g id="Produits" transform="translate(2.000000, 48.000000)">
                <g
                  id="Icon/24px/Action/archive"
                  transform="translate(0.000000, 1.000000)">
                  <path
                    d="M0,1.28571429 C0,0.575633893 0.559644063,0 1.25,0 L18.75,0 C19.4403559,0 20,0.575633893 20,1.28571429 L20,3.85714286 C20,4.56722325 19.4403559,5.14285714 18.75,5.14285714 L18.75,14.7857143 C18.75,16.5609153 17.3508898,18 15.625,18 L4.375,18 C2.64911016,18 1.25,16.5609153 1.25,14.7857143 L1.25,5.14285714 C0.559644063,5.14285714 0,4.56722325 0,3.85714286 L0,1.28571429 Z M2.5,5.14285714 L2.5,14.7857143 C2.5,15.8508349 3.33946609,16.7142857 4.375,16.7142857 L15.625,16.7142857 C16.6605339,16.7142857 17.5,15.8508349 17.5,14.7857143 L17.5,5.14285714 L2.5,5.14285714 Z M18.75,1.28571429 L1.25,1.28571429 L1.25,3.85714286 L18.75,3.85714286 L18.75,1.28571429 Z M6.25,8.35714286 C6.25,8.00210266 6.52982203,7.71428571 6.875,7.71428571 L13.125,7.71428571 C13.470178,7.71428571 13.75,8.00210266 13.75,8.35714286 C13.75,8.71218305 13.470178,9 13.125,9 L6.875,9 C6.52982203,9 6.25,8.71218305 6.25,8.35714286 Z"
                    id="archive"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FilingCabinetIcon;
