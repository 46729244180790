import parse from 'html-react-parser';
import Space from 'components/space/Space';
import { MainTitle, SubTitle } from './style';

interface StepTitlesProps {
  title?: string;
  subTitle?: string;
}
const StepTitles: React.FC<StepTitlesProps> = ({ title, subTitle }) => {
  return (
    <>
      <MainTitle>{title}</MainTitle>
      <Space marginTop="0.4rem" />
      <SubTitle>{subTitle ? parse(subTitle) : null}</SubTitle>
      <Space marginTop="2.4rem" />
    </>
  );
};

export default StepTitles;
