import styled from 'styled-components';

export const StyledRadioButton = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledRadioButtonWrapper = styled.div`
  display: flex;
`;

export const StyledEqualSign = styled.span`
  height: 4rem;
  width: 2rem;
  color: #3a3d41;
  font-size: 3.2rem;
  letter-spacing: 0;
  line-height: 2rem;
  text-align: center;
`;

export const StyledFlexContainer = styled.div<{
  width?: string;
  gap?: string;
  align?: string;
}>`
  display: flex;
  width: ${({ width }) => `${width || ''}`};
  gap: ${({ gap }) => `${gap || ''}`};
  align-items: ${({ align }) => `${align || ''}`};
`;

export const StyledRectangle = styled.div`
  height: 3.2rem;
  width: 16.8rem;
  border: 1px solid #268038;
  border-radius: 1.6rem;
  color: #268038;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const StyledLine = styled.div`
  box-sizing: border-box;
  height: 0.1rem;
  width: 46.4rem;
  border: 0.1rem solid #c7cacd;
`;

export const StyledAssuranceIndividuelleText = styled.p`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #fef2f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`;

export const StyledInfoSimulation = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding-left: 1.8rem;
  margin-top: 10.4rem;
  background-color: #fef4e5;
  height: 80px;
  width: 940px;
`;

export const StyledContentSimulation = styled.p`
  height: 20px;
  width: 804.39px;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0 0 0 1rem;
`;

export const StyledErrorText = styled.span`
  color: red;
  font-size: 1.4rem;
  white-space: nowrap;
`;
