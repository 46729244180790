import CheckIcon from 'icons/CheckIcon';
import styled from 'styled-components';

export const Steps = styled.ul`
  position: relative;
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 7.8rem;
`;

export const Wrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
`;

export const Label = styled.span`
  position: absolute;
  top: 3.5rem;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 1.2rem;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 1.5rem;
  height: 1.5rem;
  stroke: #fff;
  color: #fff;
`;

const Step = styled.li`
  position: relative;
  text-align: center;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.chartColors.mainGreen500};

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    top: 1.4rem;
    height: 0.2rem;
    left: 1.7rem;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  }

  &:last-child {
    flex-grow: 0;

    &::after {
      content: none;
    }
  }
`;

export const PastStep = styled(Step)``;

export const CurrentStep = styled(Step)`
  ${Label} {
    font-weight: bold;
  }

  &::after {
    background-color: ${({ theme }) => theme.colors.chartColors.neutral200};
  }
`;

export const FuturStep = styled(Step)`
  color: ${({ theme }) => theme.colors.chartColors.neutral200};

  ${Wrapper} {
    padding: 0.75rem;
  }

  &::after {
    background-color: ${({ theme }) => theme.colors.chartColors.neutral200};
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: white;
  color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  font-size: 1.6rem;
  font-weight: bold;
  border: 0.2rem solid ${({ theme }) => theme.colors.chartColors.mainGreen500};
`;

export const PastCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
`;

export const FuturCircle = styled(Circle)`
  border-color: ${({ theme }) => theme.colors.chartColors.neutral100};
  background-color: ${({ theme }) => theme.colors.chartColors.neutral100};
  height: 1.6rem;
  width: 1.6rem;
`;

export const BreadCrumbLevel2Item = styled.p`
  width: 22.9rem;
  float: left;
  margin-left: 0.5rem;
  flex-grow: 1;
  position: relative;
  &:after {
    content: '';
    border-radius: 0.2rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 0;
    height: 0.8rem;
  }
`;

export const BreadCrumbLevel2CurrentItem = styled(BreadCrumbLevel2Item)<{
  weight?: string;
}>`
  color: #3a913f;
  font-weight: bold;
  &:after {
    box-sizing: border-box;
    border: 2px solid #268038;
    border-radius: 2px;
  }
`;

export const BreadCrumbLevel2PastItem = styled(BreadCrumbLevel2Item)<{
  weight?: string;
}>`
  color: #3a913f;
  &:after {
    background: #28883c;
  }
`;

export const BreadCrumbLevel2FutureItem = styled(BreadCrumbLevel2Item)`
  color: #b1b5b9;
  &:after {
    background: ${({ theme }) => theme.colors.chartColors.neutral300};
  }
`;
