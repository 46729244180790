import GenericIconProps from './GenericIcon';

const HourglassIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 24;
  const baseHeight = 24;
  const baseColor = '#4b4f54';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;
  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>HourglassIcon</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dashboard5.1"
          transform="translate(-1114.000000, -387.000000)"
          fill={color || baseColor}
          fillRule="nonzero">
          <g
            id="Recherche/statuttraitement"
            transform="translate(1090.000000, 363.000000)">
            <g
              id="Icon/24px/Document/folder-search-Copy"
              transform="translate(24.000000, 24.000000)">
              <g id="Group" transform="translate(6.000000, 2.000000)">
                <path
                  d="M9.80622537,11.24 C9.38787388,10.59 9.06606504,9.78 8.99097631,9.52 C9.01243023,9.42 8.94806847,9.14 10.2245769,7.66 C11.9516176,5.64 12.112522,4.15 11.9516176,3.25 C11.7799862,2.31 11.1792764,1.54 10.181669,0.96 C9.06606504,0.32 7.693014,0 5.95524628,0 C5.94451932,0 5.93379235,0 5.92306539,0 C4.03512021,0 2.60843437,0.42 1.29974509,1.35 C-0.631107932,2.73 -0.405841745,4.94 1.94336277,7.93 C2.95169713,9.21 2.99460497,9.5 2.98387801,9.54 C2.98387801,9.55 2.97315105,9.57 2.96242409,9.61 C2.61916133,10.5 1.78245835,11.84 1.11738675,12.92 L0.977936256,13.14 C0.0768715111,14.6 -0.19130252,16.3 0.269956814,17.57 C0.570311729,18.41 1.18174852,19.02 2.0184515,19.34 C3.21987116,19.8 4.66801093,20 6.04106197,20 C7.47847477,20 8.84079885,19.79 9.78477144,19.44 C10.7180171,19.09 11.3830887,18.48 11.7048975,17.66 C12.1768838,16.46 11.9087098,14.81 10.9647372,13.13 C10.6107475,12.49 10.181669,11.82 9.80622537,11.24 Z M10.5034778,17.24 C10.2996656,17.74 9.91349498,18.09 9.31278515,18.32 C7.72519488,18.91 4.55001435,19.02 2.52261868,18.23 C2.02917846,18.04 1.6859157,17.69 1.50355736,17.18 C1.1602946,16.24 1.39628775,14.89 2.11499415,13.74 L2.25444465,13.52 C2.98387801,12.34 3.79912707,11.01 4.18529767,10.02 C4.43201778,9.38 4.29256728,8.86 2.99460497,7.22 C0.473769077,4.03 1.26756421,2.89 2.08281327,2.3 C3.15550939,1.53 4.30329424,1.2 5.91233843,1.2 C5.92306539,1.2 5.93379235,1.2 5.94451932,1.2 C7.43556693,1.2 8.59407874,1.46 9.48441653,1.98 C10.170942,2.38 10.5571127,2.86 10.6751092,3.45 C10.9003754,4.7 9.85986017,6.15 9.2162425,6.91 C7.68228704,8.71 7.73592184,9.01 7.70374096,9.49 C7.67156008,9.94 7.91828019,10.45 8.72280228,11.79 C9.19478857,12.59 9.48441653,13.09 9.83840625,13.7 C10.5892935,15.05 10.8467406,16.37 10.5034778,17.24 Z"
                  id="Shape"
                />
                <path
                  d="M6.55595611,12.41 C6.47014042,12.36 6.41650561,12.34 6.3628708,12.33 C6.26632815,12.3 6.1697855,12.28 6.06251589,12.28 C5.96597324,12.28 5.88015755,12.3 5.79434186,12.32 L5.75143401,12.32 C4.48565259,12.87 2.90878928,14.24 2.52261868,15.22 C1.85754708,16.89 3.28423293,17.95 5.91233843,17.99 C9.44150868,18.04 9.94567586,16.39 9.49514349,15.24 C9.10897288,14.22 7.17811986,12.79 6.55595611,12.41 Z"
                  id="Path"
                />
                <ellipse id="Oval" cx="6.03033501" cy="10.74" rx="1" ry="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HourglassIcon;
