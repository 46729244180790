import * as React from 'react';
import GenericIconProps from './GenericIcon';

interface ArrowLeftCircleIconProps {
  color?: string;
}

const ArrowLeftCircleIcon: React.FC<ArrowLeftCircleIconProps> = ({ color }) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <title>Ic/24px/Action/arrow-left-circle24px</title>
    <path
      d="M3.25 12a8.75 8.75 0 1 0 17.5 0 8.75 8.75 0 0 0-17.5 0ZM22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-5.625-.625a.625.625 0 0 1 0 1.25H9.134l2.684 2.682a.626.626 0 0 1-.886.885l-3.75-3.75a.625.625 0 0 1 0-.885l3.75-3.75a.626.626 0 0 1 .886.885l-2.684 2.683h7.241Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

ArrowLeftCircleIcon.defaultProps = {
  color: '#292C2E',
};

export const ArrowLeftCircleIconAJ: React.FC<GenericIconProps> = ({ width, color }) => {
  const baseWidth = 24;
  const baseHeight = 24;
  const baseColor = '#4B4F54';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>C5627021-A8C0-4828-9B88-3B6D93876682</title>
      <g
        id="Consultation-dossier-📑"
        stroke="none"
        strokeWidth="1"
        fill={color || baseColor}
        fillRule="evenodd">
        <g
          id="Consultation-d'un-dossier/transferer-a-l'octroi/autres-justificatifs/SG.1"
          transform="translate(-266.000000, -1656.000000)"
          fill="#4B4F54">
          <g id="espace/commentaire" transform="translate(264.000000, 1492.000000)">
            <g
              id="Icon/32px/Action/arrow-right-circle"
              transform="translate(16.000000, 178.000000) rotate(180.000000) translate(-16.000000, -178.000000) translate(2.000000, 164.000000)">
              <path
                d="M1.75,14 C1.75,20.7654882 7.23451181,26.25 14,26.25 C20.7654882,26.25 26.25,20.7654882 26.25,14 C26.25,7.23451181 20.7654882,1.75 14,1.75 C7.23451181,1.75 1.75,7.23451181 1.75,14 Z M28,14 C28,21.7319865 21.7319865,28 14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 C21.7319865,0 28,6.2680135 28,14 Z M7.875,13.125 C7.39175084,13.125 7,13.5167508 7,14 C7,14.4832492 7.39175084,14.875 7.875,14.875 L18.01275,14.875 L14.2555,18.6305 C14.034174,18.851826 13.9477363,19.1744158 14.0287473,19.4767527 C14.1097582,19.7790897 14.3459103,20.0152418 14.6482473,20.0962527 C14.9505842,20.1772637 15.273174,20.090826 15.4945,19.8695 L20.7445,14.6195 C20.909073,14.4553425 21.0015641,14.2324477 21.0015641,14 C21.0015641,13.7675523 20.909073,13.5446575 20.7445,13.3805 L15.4945,8.1305 C15.273174,7.90917397 14.9505842,7.82273631 14.6482473,7.90374726 C14.3459103,7.98475821 14.1097582,8.22091028 14.0287473,8.52324726 C13.9477363,8.82558425 14.034174,9.14817397 14.2555,9.3695 L18.01275,13.125 L7.875,13.125 Z"
                id="arrow-right-circle"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeftCircleIcon;
