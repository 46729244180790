import TextButton from 'components/buttons/textButton/TextButton';
import { v4 as uuidv4 } from 'uuid';
import CityInput from 'components/cityInput/CityInput';
import { SelectedButton } from 'components/designSystem/SelectButton';
import { SelectElementInput } from 'components/designSystem/SelectElementInput';
import StepTitles from 'components/titles/StepTitles';
import ToggleButton from 'components/toggleButton/ToggleButton';
import StepContentWrapper from 'components/wrapper/StepContentWrapper';
import Space from 'components/space/Space';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
  getSituationFoyerInitialState,
  situationFoyerReducer,
} from 'reducers/situationFoyer/situationFoyer';
import {
  ChildrenBirthYear,
  HousingStatus,
  MaritalStatus,
} from 'reducers/situationFoyer/types';
import { City, CodeLibelle, StatusControlInput, StatusInput } from 'types';
import FormFooter from 'components/formFooter/FormFooter';
import { updateSessionStorage } from 'utils/storage';
import InformationArea from 'components/informationArea/InformationArea';
import { ComboInputDS } from 'components/designSystem/ComboInput';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import ContentTitle from 'components/titles/ContentTitle';
import { getDateFromInput, isDateIntheFuture } from 'utils/DateUtils';
import { useNavigate } from 'react-router';
import {
  borrowerIdentityReducer,
  getBorrowerInitialState,
} from 'reducers/identity/borrowerIdentity';
import {
  getCitiesByZipCode,
  getFamilySituation,
  getLodgings,
} from 'api/referentialService';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import useInputValidation from 'utils/useInputValidation';
import { checkMaritalSituation, ValidationRules } from 'utils/InputValidation';
import GeneralConfirmationModal from 'components/modals/ConfirmationModals/GeneralConfirmationModal';
import { BaseInputContainer } from 'containers/communs/style';
import WarningIcon from 'icons/WarningIcon';
import CustomInput from 'components/customInput/CustomInput';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import TextArea from 'components/textArea/TextArea';
import { StyledErrorMessage } from 'components/customInput/style';
import {
  getBirthYearOlderBorrower,
  handleSaveAndExit,
  scrollToTopAndShowError,
  sortChildrenByAge,
} from 'utils/commun';
import { ErrorMessage as ErrorMsg } from 'types/FaisabiliteDTO';
import { StyledInfo } from 'containers/conditionsFinancieres/style';
import Loader from 'components/Loader';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import ChildrenInCharge from './ChildrenInCharge';
import * as messages from './messages';
import { StyledTextLabel } from './style';

interface SituationFoyerFormProps {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
}
const SituationFoyerForm: React.FC<SituationFoyerFormProps> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {
  const navigate = useNavigate();

  const [situationFoyerState, situationFoyerReducerDispatch] = useReducer(
    situationFoyerReducer,
    getSituationFoyerInitialState('situationFoyer'),
  );
  const [borrowerState] = useReducer(
    borrowerIdentityReducer,
    getBorrowerInitialState('borrowerIdentity'),
  );

  const cityRef = useRef(null);
  const maritalStatusRef = useRef(null);
  const coBorrowerMaritalStatusRef = useRef(null);
  const housingStatusRef = useRef(null);
  const [cityList, setCityList] = useState<City[]>([]);
  const [isCityInvalid, setIsCityInvalid] = useState<boolean>();

  const [isZipCodeInputFocused, setIsZipCodeInputFocused] = useState<boolean>(false);
  const [statusZipCode, setStatusZipCode] = useState<StatusControlInput>();
  const [statusAddressCity, setStatusAddressCity] = useState<StatusInput>();
  const [isYearInputFocused, setIsYearInputFocused] = useState<boolean>(false);
  const [
    statusStartingYearLivingInCurrentHouse,
    setStatusStartingYearLivingInCurrentHouse,
  ] = useState<StatusControlInput>();
  const [
    isNumberOfPersonInCurrentHouseInputFocused,
    setIsNumberOfPersonInCurrentHouseInputFocused,
  ] = useState<boolean>(false);
  const [statusNumberOfPersonInCurrentHouse, setStatusNumberOfPersonInCurrentHouse] =
    useState<StatusControlInput>();

  const [showChildrenInCharge, setShowChildrenInCharge] = useState<boolean>(
    situationFoyerState.childrenInCharge,
  );
  const [
    coBorrowerMaritalStatusIsSameAsBorrower,
    setCoBorrowerMaritalStatusIsSameAsBorrower,
  ] = useState<boolean>(situationFoyerState.coBorrowerMaritalStatusIsSameAsBorrower);
  const [childrenInCharge, setChildrenInCharge] = useState<ChildrenBirthYear[]>(
    situationFoyerState.childrenInChargeBirthYear || [],
  );

  const [familySituationReferential, setFamilySituationReferential] = useState<
    CodeLibelle[]
  >([]);

  const [isGeneralConfirmationModalVisible, setIsGeneralConfirmationModalVisible] =
    useState<boolean>(false);

  const [lodgingsReferential, setLodgingsReferential] = useState<CodeLibelle[]>([]);

  const [displayMaritalError, setDisplayMaritalError] = useState<boolean>();
  const [maritalErrorMessage, setMaritalErrorMessage] = useState<string>('');

  const [hasError, setHasError] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<ErrorMsg[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const firstDayOfMonth = '01/';
  const firstMonth = '01/';

  const zipCode = new RegExp(ValidationRules.zipCode);
  const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);
  const yearRegexp = new RegExp(ValidationRules.yearRegexp);

  useInputValidation('situationFoyer', 'show-input-ds-validation', [
    lodgingsReferential,
    familySituationReferential,
  ]);

  const checkStatusZipCode = () => {
    if (!situationFoyerState.addressZipCode) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE_NO_WRAP,
      });
      return;
    }
    if (!zipCode.test(situationFoyerState.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
      return;
    }
    if (!notEmpty.test(situationFoyerState.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE_NO_WRAP,
      });
      return;
    }
    if (!onlyNumbers.test(situationFoyerState.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_ONLY_NUMBERS,
      });
      return;
    }
    if (!zipCodeNoZero.test(situationFoyerState.addressZipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_NO_ZERO,
      });
      return;
    }
    if (cityList.length === 0) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.WRONG_ZIPCODE,
      });
      return;
    }
    situationFoyerReducerDispatch({
      type: 'setAddressZipCode',
      payload: situationFoyerState.addressZipCode.slice(0, 5).trim(),
    });
    setStatusZipCode({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusAddressCity = (cityByZipCodeList: CodeLibelle[]) => {
    setStatusAddressCity({
      status: 'none',
      errorMessage: '',
    });
    if (cityRef.current && cityRef.current !== null && situationFoyerState.addressCity) {
      const cityMatchesListElement = cityByZipCodeList?.find(city => {
        return (
          city.libelle.toUpperCase() ===
          situationFoyerState.addressCity?.libelle.toUpperCase()
        );
      });

      if (cityMatchesListElement) {
        situationFoyerReducerDispatch({
          type: 'setAddressCity',
          payload: cityMatchesListElement,
        });
        setStatusAddressCity({
          status: 'valid',
          errorMessage: '',
        });
      } else {
        situationFoyerReducerDispatch({
          type: 'setAddressCity',
          payload: {
            code: 'err',
            libelle: situationFoyerState.addressCity.libelle
              ? situationFoyerState.addressCity.libelle
              : '',
          },
        });
        setStatusAddressCity({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!situationFoyerState.addressCity)
      setStatusAddressCity({
        status: 'invalid',
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
  };

  const checkStatusStartingYearLivingInCurrentHouse = () => {
    setIsYearInputFocused(true);
    const olderBorrower = getBirthYearOlderBorrower();

    if (!situationFoyerState.startingYearLivingInCurrentHouse) {
      setStatusStartingYearLivingInCurrentHouse({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (
      !yearRegexp.test(situationFoyerState.startingYearLivingInCurrentHouse.toString())
    ) {
      setStatusStartingYearLivingInCurrentHouse({
        status: false,
        errorMessage: messagesCheckInput.YEAR_FORMAT,
      });
    } else if (
      !isDateIntheFuture(
        getDateFromInput(
          `${firstDayOfMonth}${firstMonth}${situationFoyerState.startingYearLivingInCurrentHouse}`,
        ),
      )
    ) {
      setStatusStartingYearLivingInCurrentHouse({
        status: false,
        errorMessage: messagesCheckInput.YEAR_FUTUR,
      });
    } else if (olderBorrower > situationFoyerState.startingYearLivingInCurrentHouse) {
      setStatusStartingYearLivingInCurrentHouse({
        status: false,
        errorMessage: messagesCheckInput.DATE_BEFORE_BORN,
      });
    } else {
      setStatusStartingYearLivingInCurrentHouse({
        status: true,
        errorMessage: '',
      });
    }
    situationFoyerReducerDispatch({
      type: 'setStartingYearLivingInCurrentHouse',
      payload: situationFoyerState.startingYearLivingInCurrentHouse,
    });
  };

  const checkStatusNumberOfPersonInCurrentHouse = () => {
    const actualNbPerson =
      1 +
      (borrowerState.hasCoBorrower ? 1 : 0) +
      (situationFoyerState.childrenInChargeBirthYear && showChildrenInCharge
        ? situationFoyerState.childrenInChargeBirthYear.length
        : 0);
    if (situationFoyerState.numberOfPersonInCurrentHouse === undefined) {
      setStatusNumberOfPersonInCurrentHouse({
        status: false,
        errorMessage: messagesCheckInput.NUMBER_PERSON_HOUSE,
      });
    } else if (
      !onlyNumbers.test(situationFoyerState.numberOfPersonInCurrentHouse.toString())
    ) {
      setStatusNumberOfPersonInCurrentHouse({
        status: false,
        errorMessage: messagesCheckInput.ONLY_NUMBERS,
      });
    } else if (actualNbPerson > situationFoyerState.numberOfPersonInCurrentHouse) {
      setStatusNumberOfPersonInCurrentHouse({
        status: false,
        errorMessage: messagesCheckInput.NUMBER_PERSON_HOUSE,
      });
    } else {
      setStatusNumberOfPersonInCurrentHouse({
        status: true,
        errorMessage: '',
      });

      situationFoyerReducerDispatch({
        type: 'setNumberOfPersonInCurrentHouse',
        payload: Number.isNaN(Number(situationFoyerState.numberOfPersonInCurrentHouse))
          ? undefined
          : Number(
              situationFoyerState.numberOfPersonInCurrentHouse.toString().slice(0, 2),
            ),
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchFamilySituation = async () => {
      try {
        const response = await getFamilySituation();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: CodeLibelle[] = [];
          Resultat.forEach(item => {
            temp.push({ code: item.code, libelle: item.libelle });
          });
          setFamilySituationReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchFamilySituation();
  }, []);

  useEffect(() => {
    const fetchLodgings = async () => {
      try {
        const response = await getLodgings();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: CodeLibelle[] = [];
          Resultat.forEach(item => {
            temp.push({ code: item.code, libelle: item.libelle });
          });
          setLodgingsReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchLodgings();
  }, []);

  useEffect(() => {
    if (situationFoyerState.addressZipCode.length === 5) {
      getCitiesByZipCode(situationFoyerState.addressZipCode).then(cityByZipCodeList => {
        if (
          (situationFoyerState.addressCity &&
            situationFoyerState.addressCity.libelle !== '') ||
          checkInputs
        )
          checkStatusAddressCity(cityByZipCodeList);
        setCityList(cityByZipCodeList);
      });
    } else if (situationFoyerState.addressCity) {
      setCityList([situationFoyerState.addressCity]);
    } else {
      setIsCityInvalid(true);
      (cityRef.current as any).valid = !isCityInvalid;

      setCityList([]);
    }
  }, [situationFoyerState.addressZipCode]);

  useEffect(() => {
    if (situationFoyerState.addressZipCode !== '' && cityList.length !== 0) {
      checkStatusZipCode();
    }
    if (situationFoyerState.startingYearLivingInCurrentHouse !== undefined) {
      checkStatusStartingYearLivingInCurrentHouse();
    }
    if (situationFoyerState.numberOfPersonInCurrentHouse !== undefined) {
      checkStatusNumberOfPersonInCurrentHouse();
    }
  }, []);

  useEffect(() => {
    if (cityRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (cityRef.current as any).valid = !isCityInvalid;
    }
  }, [isCityInvalid]);

  useEffect(() => {
    situationFoyerReducerDispatch({
      type: 'setHasCoBorrower',
      payload: borrowerState.hasCoBorrower || false,
    });
  }, [borrowerState]);

  useEffect(() => {
    situationFoyerReducerDispatch({
      type: 'setChildrenInCharge',
      payload: showChildrenInCharge,
    });
  }, [showChildrenInCharge]);

  useEffect(() => {
    situationFoyerReducerDispatch({
      type: 'setCoBorrowerMaritalStatusIsSameAsBorrower',
      payload: coBorrowerMaritalStatusIsSameAsBorrower,
    });
    if (coBorrowerMaritalStatusIsSameAsBorrower) {
      setMaritalErrorMessage('');
      setDisplayMaritalError(false);
    }
  }, [coBorrowerMaritalStatusIsSameAsBorrower]);

  useEffect(() => {
    if (situationFoyerState.numberOfPersonInCurrentHouse) {
      checkStatusNumberOfPersonInCurrentHouse();
    }
  }, [situationFoyerState.numberOfPersonInCurrentHouse]);

  useEffect(() => {
    if (situationFoyerState.childrenInChargeBirthYear) {
      if (situationFoyerState.numberOfPersonInCurrentHouse) {
        checkStatusNumberOfPersonInCurrentHouse();
      }
    }
  }, [situationFoyerState.childrenInChargeBirthYear]);

  useEffect(() => {
    if (situationFoyerState.numberOfPersonInCurrentHouse) {
      checkStatusNumberOfPersonInCurrentHouse();
    }
  }, [showChildrenInCharge]);

  useEffect(() => {
    if (
      !borrowerState.hasCoBorrower &&
      (situationFoyerState.maritalStatus?.toString() === 'M' ||
        situationFoyerState.maritalStatus?.toString() === 'P')
    ) {
      setDisplayMaritalError(true);
      setMaritalErrorMessage(messagesCheckInput.MARITAL_STATUS_COEMP_NEEDED);
    } else if (
      situationFoyerState.maritalStatus &&
      situationFoyerState.coBorrowerMaritalStatus &&
      borrowerState.hasCoBorrower &&
      !coBorrowerMaritalStatusIsSameAsBorrower &&
      !checkMaritalSituation(
        situationFoyerState.maritalStatus,
        situationFoyerState.coBorrowerMaritalStatus,
      )
    ) {
      setDisplayMaritalError(true);
      setMaritalErrorMessage(messagesCheckInput.MARITAl_STATUS_INCOHERENT);
    } else {
      setDisplayMaritalError(false);
      setMaritalErrorMessage('');
    }
  }, [situationFoyerState.maritalStatus, situationFoyerState.coBorrowerMaritalStatus]);

  useEffect(() => {
    if (checkInputs) {
      checkStatusZipCode();
      checkStatusStartingYearLivingInCurrentHouse();
      checkStatusNumberOfPersonInCurrentHouse();
      checkStatusAddressCity(cityList);
    }
  }, [checkInputs]);

  useEffect(() => {
    if (
      situationFoyerState.addressZipCode &&
      situationFoyerState.addressZipCode.length === 5
    ) {
      checkStatusZipCode();
    }
  }, [cityList]);

  const onCitySelect = (e: Event) => {
    const selectedCity = (e as CustomEvent).detail.value as City;
    situationFoyerReducerDispatch({
      type: 'setAddressCity',
      payload: selectedCity,
    });
  };

  const onCityBlur = (event: Event) => {
    if (cityRef.current && cityRef.current !== null) {
      if (event && event.target) {
        const cityMatchesListElement = cityList?.find(city => {
          return (
            city.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (cityMatchesListElement) {
          situationFoyerReducerDispatch({
            type: 'setAddressCity',
            payload: cityMatchesListElement,
          });
        } else {
          situationFoyerReducerDispatch({
            type: 'setAddressCity',
            payload: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          });
        }
      }
    }
  };

  const onBirthYearChange = (childId: number, age: string) => {
    const newState = [...childrenInCharge];
    const currentChild = newState.find(item => {
      return item.childId === childId;
    });
    if (currentChild) {
      currentChild.birthYear = age;

      situationFoyerReducerDispatch({
        type: 'setChildrenInChargeBirthYear',
        payload: newState,
      });
    }
    setChildrenInCharge(newState);
  };

  const onSaveButtonClick = (): void => {
    setIsGeneralConfirmationModalVisible(true);
  };

  const cleanup = () => {
    if (!situationFoyerState.childrenInCharge) {
      situationFoyerState.childrenInChargeBirthYear = [] as ChildrenBirthYear[];
    }

    if (
      situationFoyerState.hasCoBorrower &&
      situationFoyerState.coBorrowerMaritalStatusIsSameAsBorrower
    ) {
      situationFoyerState.coBorrowerMaritalStatus = situationFoyerState.maritalStatus;
    }
  };

  const saveInSessionStorage = (): void => {
    cleanup();
    const situationFoyerToSave = situationFoyerState;
    if (situationFoyerToSave.childrenInChargeBirthYear)
      situationFoyerToSave.childrenInChargeBirthYear = sortChildrenByAge(
        situationFoyerToSave.childrenInChargeBirthYear,
      );
    updateSessionStorage('situationFoyer', situationFoyerToSave);
  };

  const getSelectButtonClassname = (index: number) => {
    let classes = '';
    if (index === 0 || index === 3) {
      classes += ' situation-foyer-selected-button';
    } else {
      classes +=
        ' situation-foyer-selected-button situation-foyer-selected-button-margin';
    }
    if (index > 2) {
      classes += ' situation-foyer-selected-button-margin-top';
    }

    return classes;
  };

  const closeModal = (): void => {
    setIsGeneralConfirmationModalVisible(false);
  };

  const toggleTextStyle = {
    marginRight: '0.8rem',
    fontSize: '1.6rem',
    color: '#4B4F54',
    display: 'block',
    marginTop: '0.3rem',
    height: '3.2rem',
  };

  const renderChildrenInCharge = (): React.ReactNode => {
    if (childrenInCharge.length === 0) {
      return (
        <TextButton
          width="29rem"
          marginTop="2.4rem"
          label="Ajouter une année de naissance"
          onClick={() => {
            setChildrenInCharge(prevState => {
              return [
                ...prevState,
                { childId: childrenInCharge.length + 1, birthYear: '' },
              ];
            });
          }}>
          <PlusCircleIcon />
        </TextButton>
      );
    }
    return childrenInCharge.map((child, index) => (
      <React.Fragment key={child.childId}>
        <ChildrenInCharge
          child={child}
          checkInputs={checkInputs}
          updateState={year => {
            onBirthYearChange(child.childId, year);
          }}
          onDelete={childId => {
            const newState = childrenInCharge.filter(item => {
              return item.childId !== childId;
            });
            situationFoyerReducerDispatch({
              type: 'setChildrenInChargeBirthYear',
              payload: newState,
            });
            setChildrenInCharge(newState);
          }}
        />
        {index === childrenInCharge.length - 1 && childrenInCharge.length < 10 ? (
          <div style={{ marginBottom: '1.8rem' }}>
            <TextButton
              label="Ajouter une année de naissance"
              width="29rem"
              marginTop="2.4rem"
              key={uuidv4()}
              onClick={() =>
                setChildrenInCharge(prevState => {
                  return [
                    ...prevState,
                    { childId: childrenInCharge.length + 1, birthYear: '' },
                  ];
                })
              }>
              <PlusCircleIcon />
            </TextButton>
          </div>
        ) : null}
      </React.Fragment>
    ));
  };

  return (
    <>
      {withSummaryCard(
        'Identity',
        <>
          <StepTitles subTitle={messages.SUB_TITLE} />
          {hasError && (
            <>
              <Space marginTop="2.4rem" />
              <TextArea
                title={messagesCheckInput.CHECK_ERROR_TITLE}
                type="error"
                includeIcon>
                <span> {messagesCheckInput.CHECK_ERROR}</span>
              </TextArea>
            </>
          )}
          <Space marginTop="2.4rem" />
          <ContentTitle text={messages.SITUATION_FOYER} />
          <StepContentWrapper width="63.9rem">
            <Space marginBottom="1rem" />
            <div style={{ display: 'flex' }}>
              <div style={{ width: '15.6rem', marginRight: '1.6rem' }}>
                <CustomInput
                  name="zip-code"
                  label="Code postal"
                  placeholder="ex: 75001"
                  onChange={newValue => {
                    setIsZipCodeInputFocused(true);
                    let value = situationFoyerState.addressZipCode;
                    if (onlyNumbers.test(newValue)) value = newValue;
                    situationFoyerReducerDispatch({
                      type: 'setAddressZipCode',
                      payload: value.slice(0, 5),
                    });
                  }}
                  onBlur={checkStatusZipCode}
                  onFocus={() => {
                    setIsZipCodeInputFocused(true);
                  }}
                  isValid={statusZipCode?.status}
                  isFocused={isZipCodeInputFocused}
                  value={situationFoyerState.addressZipCode}
                  error={statusZipCode?.errorMessage}
                />
              </div>

              <CityInput
                label="Ville"
                cityRef={cityRef}
                defaultValue={situationFoyerState.addressCity?.libelle || ''}
                cities={cityList}
                onCitySelect={onCitySelect}
                onCityBlur={onCityBlur}
                className="show-input-ds-validation"
                status={statusAddressCity?.status}
                errorMessage={statusAddressCity?.errorMessage}
              />
            </div>

            <Space marginBottom="2.4rem" />
            <div className="element-accessible-keyboard">
              <SelectElementInput
                ref={housingStatusRef}
                class="show-input-ds-validation"
                label="Situation du logement"
                data-id="housingStatus"
                value={situationFoyerState.housingStatus || ''}
                onSelectedElementChange={e => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (housingStatusRef.current as any).invalid = false;

                  situationFoyerReducerDispatch({
                    type: 'setHousingStatus',
                    payload: (e.target as HTMLSelectElement).value as HousingStatus,
                  });
                }}
                error-message={messagesCheckInput.REQUIRED_VALUE}>
                {lodgingsReferential.map((item, index) => {
                  return (
                    <SelectedButton
                      tabIndex={0}
                      onKeyDownCapture={e => {
                        if (
                          e.code === 'Enter' ||
                          e.code === 'Space' ||
                          e.code === 'NumpadEnter'
                        ) {
                          e.preventDefault();
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (housingStatusRef.current as any).invalid = false;

                          situationFoyerReducerDispatch({
                            type: 'setHousingStatus',
                            payload: (e.target as HTMLSelectElement)
                              .value as HousingStatus,
                          });
                        }
                      }}
                      selected={
                        item.code === situationFoyerState.housingStatus ? true : undefined
                      }
                      key={item.code}
                      value={item.code}
                      secondary
                      className={getSelectButtonClassname(index)}>
                      {item.libelle}
                    </SelectedButton>
                  );
                })}
                {!situationFoyerState.housingStatus && checkInputs ? (
                  <>
                    <StyledErrorMessage style={{ fontSize: '1.4rem' }}>
                      {messagesCheckInput.LOGEMENT_ERROR}
                    </StyledErrorMessage>
                  </>
                ) : (
                  <></>
                )}
              </SelectElementInput>
            </div>

            <Space marginBottom="2.4rem" />
            <BaseInputContainer componentWidth="11.1rem">
              <CustomInput
                name="starting-year-living-in-current-house"
                label="Dans votre logement actuel depuis"
                placeholder="AAAA"
                onChange={newValue => {
                  let value = situationFoyerState.startingYearLivingInCurrentHouse;
                  if (onlyNumbers.test(newValue)) value = Number(newValue.slice(0, 4));
                  setIsYearInputFocused(true);
                  situationFoyerReducerDispatch({
                    type: 'setStartingYearLivingInCurrentHouse',
                    payload: value,
                  });
                }}
                isValid={statusStartingYearLivingInCurrentHouse?.status}
                isFocused={isYearInputFocused}
                onBlur={checkStatusStartingYearLivingInCurrentHouse}
                onFocus={() => {
                  setIsYearInputFocused(true);
                }}
                inputWidth="10.6rem"
                value={situationFoyerState.startingYearLivingInCurrentHouse || ''}
                error={statusStartingYearLivingInCurrentHouse?.errorMessage}
              />
            </BaseInputContainer>
            <Space marginBottom="1.6rem" />
            <BaseInputContainer
              componentWidth="6rem"
              style={{ display: 'flex', gap: '1.6rem' }}>
              <StyledTextLabel>{messages.NB_PEOPLE_FOYER}</StyledTextLabel>
              <CustomInput
                name="number-person-current-house"
                placeholder="0"
                onChange={newValue => {
                  setIsNumberOfPersonInCurrentHouseInputFocused(true);
                  let value = situationFoyerState.numberOfPersonInCurrentHouse;
                  if (onlyNumbers.test(newValue)) value = Number(newValue.slice(0, 2));
                  situationFoyerReducerDispatch({
                    type: 'setNumberOfPersonInCurrentHouse',
                    payload: value,
                  });
                }}
                inputWidth="10rem"
                onBlur={checkStatusNumberOfPersonInCurrentHouse}
                onFocus={() => {
                  setIsNumberOfPersonInCurrentHouseInputFocused(true);
                }}
                isValid={statusNumberOfPersonInCurrentHouse?.status}
                isFocused={isNumberOfPersonInCurrentHouseInputFocused}
                value={situationFoyerState.numberOfPersonInCurrentHouse}
                error={statusNumberOfPersonInCurrentHouse?.errorMessage}
              />
            </BaseInputContainer>
            <Space marginBottom="2.4rem" />
          </StepContentWrapper>
          <ToggleButton
            id="children-in-charge"
            isOn={showChildrenInCharge}
            handleToggle={() => {
              setShowChildrenInCharge(prevState => !prevState);
            }}>
            <span style={toggleTextStyle}>{messages.ENFANTS_CHARGE}</span>
          </ToggleButton>
          <Space marginBottom="2.4rem" />
          {showChildrenInCharge ? (
            <>
              <InformationArea
                icon={<WarningIcon color="#2275D7" />}
                backgroundColor="blue"
                height="0"
                title={
                  <p style={{ margin: '1.2rem 0 1.2rem 0', fontSize: '1.4rem' }}>
                    {messages.INFO_ENFANTS_YEAR}
                    <span style={{ fontWeight: 'bold' }}>
                      {messages.INFO_ENFANTS_STYLED}
                    </span>
                    ,{messages.INFO_ENFANTS_ALLOC}
                  </p>
                }
              />
              {childrenInCharge.length === 0 && checkInputs ? (
                <>
                  <Space marginTop="1rem" />
                  <ErrorMessageArea message={messagesCheckInput.BIRTH_CHILDREN_EMPTY} />
                </>
              ) : (
                <Space marginBottom="2rem" />
              )}
              {childrenInCharge.length > 10 && checkInputs ? (
                <>
                  <Space marginTop="1rem" />
                  <ErrorMessageArea
                    message={messagesCheckInput.BIRTH_CHILDREN_TOO_MUCH}
                  />
                </>
              ) : (
                <Space marginBottom="2rem" />
              )}
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '1.6rem',
                }}>
                {renderChildrenInCharge()}
              </div>
            </>
          ) : null}

          <Space marginBottom="5.6rem" />

          <StepContentWrapper width="63.9rem">
            <ContentTitle text={messages.SITUATION_FOYER_BORROWER} />
            <div className="element-accessible-keyboard">
              <SelectElementInput
                className="show-input-ds-validation"
                ref={maritalStatusRef}
                label="Situation familiale"
                data-id="maritalStatus"
                value={situationFoyerState.maritalStatus || ''}
                onSelectedElementChange={e => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (maritalStatusRef.current as any).invalid = false;

                  situationFoyerReducerDispatch({
                    type: 'setMaritalStatus',
                    payload: (e.target as HTMLSelectElement).value as MaritalStatus,
                  });
                }}
                error-message={messagesCheckInput.MARTIAL_STATUS_REQUIRED}>
                {familySituationReferential.map((item, index) => {
                  return (
                    <React.Fragment key={item.code}>
                      <SelectedButton
                        tabIndex={0}
                        onKeyDownCapture={e => {
                          if (
                            e.code === 'Enter' ||
                            e.code === 'Space' ||
                            e.code === 'NumpadEnter'
                          ) {
                            e.preventDefault();
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (maritalStatusRef.current as any).invalid = false;

                            situationFoyerReducerDispatch({
                              type: 'setMaritalStatus',
                              payload: (e.target as HTMLSelectElement)
                                .value as MaritalStatus,
                            });
                          }
                        }}
                        selected={
                          item.code === situationFoyerState.maritalStatus
                            ? true
                            : undefined
                        }
                        value={item.code}
                        secondary
                        className={getSelectButtonClassname(index)}>
                        {item.libelle}
                      </SelectedButton>
                    </React.Fragment>
                  );
                })}
                {!situationFoyerState.maritalStatus && checkInputs ? (
                  <>
                    <StyledErrorMessage style={{ fontSize: '1.4rem' }}>
                      {messagesCheckInput.MARTIAL_STATUS_REQUIRED}
                    </StyledErrorMessage>
                  </>
                ) : (
                  <></>
                )}
              </SelectElementInput>
            </div>

            {borrowerState.hasCoBorrower && (
              <>
                <Space marginBottom="3.2rem" />
                <CoBorrowerTitle width="100%" />
                <ToggleButton
                  id="coborrower-marital-status"
                  isOn={coBorrowerMaritalStatusIsSameAsBorrower}
                  handleToggle={() =>
                    setCoBorrowerMaritalStatusIsSameAsBorrower(prevState => !prevState)
                  }>
                  <span style={toggleTextStyle}>
                    {messages.FAMILLE_SITUATION_COBORROWER}
                  </span>
                </ToggleButton>
                <Space marginBottom="1.6rem" />
                {!coBorrowerMaritalStatusIsSameAsBorrower && (
                  <>
                    <ContentTitle text={messages.SITUATION_FOYER_COBORROWER} />
                    <div className="element-accessible-keyboard">
                      <SelectElementInput
                        className="show-input-ds-validation"
                        ref={coBorrowerMaritalStatusRef}
                        label="Situation familiale"
                        data-id="coBorrowerMaritalStatus"
                        value={situationFoyerState.coBorrowerMaritalStatus || ''}
                        onSelectedElementChange={e => {
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (coBorrowerMaritalStatusRef.current as any).invalid = false;

                          situationFoyerReducerDispatch({
                            type: 'setCoBorrowerMaritalStatus',
                            payload: (e.target as HTMLSelectElement)
                              .value as MaritalStatus,
                          });
                        }}
                        error-message={messagesCheckInput.MARTIAL_STATUS_REQUIRED}>
                        {familySituationReferential.map((item, index) => {
                          return (
                            <SelectedButton
                              tabIndex={0}
                              onKeyDownCapture={e => {
                                if (
                                  e.code === 'Enter' ||
                                  e.code === 'Space' ||
                                  e.code === 'NumpadEnter'
                                ) {
                                  e.preventDefault();
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (maritalStatusRef.current as any).invalid = false;

                                  situationFoyerReducerDispatch({
                                    type: 'setCoBorrowerMaritalStatus',
                                    payload: (e.target as HTMLSelectElement)
                                      .value as MaritalStatus,
                                  });
                                }
                              }}
                              key={item.code}
                              selected={
                                item.code === situationFoyerState.coBorrowerMaritalStatus
                                  ? true
                                  : undefined
                              }
                              value={item.code}
                              secondary
                              className={getSelectButtonClassname(index)}>
                              {item.libelle}
                            </SelectedButton>
                          );
                        })}
                        {!situationFoyerState.coBorrowerMaritalStatus && checkInputs ? (
                          <>
                            <StyledErrorMessage style={{ fontSize: '1.4rem' }}>
                              {messagesCheckInput.MARTIAL_STATUS_REQUIRED}
                            </StyledErrorMessage>
                          </>
                        ) : (
                          <></>
                        )}
                      </SelectElementInput>
                    </div>
                  </>
                )}
              </>
            )}

            {displayMaritalError && <ErrorMessageArea message={maritalErrorMessage} />}
          </StepContentWrapper>
        </>,
      )}

      <GeneralConfirmationModal
        isVisible={isGeneralConfirmationModalVisible}
        isDataOK={situationFoyerState.isDataOk}
        accept={() => {
          handleSaveAndExit({
            setErrorMessages,
            setIsLoading,
            setIsModalVisible: setIsGeneralConfirmationModalVisible,
            saveInSessionStorage,
            navigate,
          });
        }}
        close={closeModal}
      />
      {errorMessages.length > 0 && (
        <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '10.4rem' }}>
          <WarningIcon />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {errorMessages.map(message => {
              return (
                <p
                  style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                  key={message.MessageId}>
                  {message.MessageLib}
                </p>
              );
            })}
          </div>
        </StyledInfo>
      )}

      <FormFooter
        showPreviousButton
        onSaveButtonClick={onSaveButtonClick}
        onNextButtonClick={() => {
          if (situationFoyerState.isDataOk) {
            saveInSessionStorage();
            onNextButtonClick();
          } else {
            scrollToTopAndShowError({ setHasError, setCheckInputs });
          }
        }}
        onPreviousButtonClick={onBackButtonClick}
      />
      {isLoading ? <Loader animationDuration={2} /> : null}
    </>
  );
};

export default SituationFoyerForm;
