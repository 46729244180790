import styled from 'styled-components';

export const SummaryWrapper = styled.div`
  width: 28rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: 0.1rem;
  width: 24.8rem;
  border-bottom: 0.1rem solid #c7cacd;
`;

export const StyledLabelValue = styled.p`
  margin: 0;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const StyledHorizontalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
