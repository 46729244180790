import CustomInput from 'components/customInput/CustomInput';
import { CustomValidity } from 'components/designSystem/DesignSystemTypes';
import DeleteConfirmationModal from 'components/modals/ConfirmationModals/DeleteConfirmationModal';
import { addInInvalidInputs, removeFromInvalidInputs } from 'containers/communs/utils';
import CrossCircle from 'icons/CrossCircle';
import { useEffect, useState } from 'react';
import {
  RessourcesMensuellesAction,
  RessourcesMensuellesState,
} from 'reducers/ressourcesMensuelles/types';
import colors from 'styles/color';
import checkPreviousInputValidation, { formatNumberByThousand } from 'utils/commun';
import { checkValidity, ValidationRules } from 'utils/InputValidation';
import { getOtherAmountsRegExpPatterns } from 'validation/donneesClient/ressourcesMensuellesValidation';

interface AmountInputProps {
  name: string;
  state: RessourcesMensuellesState;
  dispatch: React.Dispatch<RessourcesMensuellesAction>;
  label?: string;
  itemId: number;
  checkInputs: boolean;
  typeOfAmount: 'income' | 'charge';
  onDeleteIncome?: (idToDelete: number) => void;
  disableDeleteAction?: boolean;
}
const AmountInput: React.FC<AmountInputProps> = ({
  name,
  state,
  typeOfAmount,
  dispatch,
  label,
  itemId,
  checkInputs,
  onDeleteIncome,
  disableDeleteAction,
}) => {
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState<boolean>(false);

  const getAmount = () => {
    const AmountList =
      typeOfAmount === 'charge' ? state.monthlyCharges : state.otherIncomes;

    return AmountList.find(amount => {
      return amount.id === itemId;
    });
  };

  const currentItem =
    typeOfAmount === 'income'
      ? state.otherIncomes.find(item => item.id === itemId)
      : state.monthlyCharges.find(item => item.id === itemId);

  const [amount, setAmount] = useState<string>(getAmount()?.value || '');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>(
    checkPreviousInputValidation('ressourcesMensuelles', currentItem?.value || ''),
  );
  const [inputError, setInputError] = useState<string>('');
  const [validityRules] = useState<CustomValidity[]>(
    getOtherAmountsRegExpPatterns(typeOfAmount),
  );

  const onlyNumbers = RegExp(ValidationRules.onlyNumbers);

  const handleOnChange = (value: string) => {
    setIsInputValid(undefined);
    if (!onlyNumbers.test(value)) return;

    setAmount(formatNumberByThousand(value.slice(0, 8)));

    const errorMessage = checkValidity(value, validityRules);

    if (!errorMessage) {
      dispatch({
        type: typeOfAmount === 'charge' ? 'setAmountForCharge' : 'setAmountForIncome',
        payload: { id: itemId, value: value.slice(0, 8) },
      });
    } else {
      dispatch({
        type: typeOfAmount === 'charge' ? 'setAmountForCharge' : 'setAmountForIncome',
        payload: { id: itemId, value: undefined },
      });
    }
  };

  const cleanInput = () => {
    setIsInputValid(true);
    setInputError('');
    removeFromInvalidInputs(
      state,
      dispatch,
      typeOfAmount === 'charge' ? 'otherCharge' : 'otherIncome',
    );
  };

  const handleOnBlur = (value: string) => {
    setIsInputFocused(false);

    const errorMessage = checkValidity(value.replaceAll(' ', ''), validityRules);

    if (!errorMessage) {
      cleanInput();
    } else {
      setIsInputValid(false);
      setInputError(errorMessage);
      addInInvalidInputs(
        state,
        dispatch,
        typeOfAmount === 'charge' ? 'otherCharge' : 'otherIncome',
      );
    }
  };

  useEffect(() => {
    if (checkInputs) {
      handleOnBlur(amount);
    }
  }, [checkInputs]);

  useEffect(() => {
    if (amount && amount !== '') {
      handleOnBlur(amount);
    }
  }, []);

  return (
    <CustomInput
      name={name}
      label={label}
      type="price"
      value={formatNumberByThousand(amount)}
      onChange={newValue => {
        handleOnChange(newValue.replaceAll(' ', ''));
        setIsInputFocused(true);
      }}
      isValid={isInputValid}
      isFocused={isInputFocused}
      onBlur={handleOnBlur}
      onFocus={() => {
        setIsInputFocused(true);
      }}
      after="€ / mois"
      inputWidth="46.4rem"
      error={inputError}>
      <div
        role="button"
        style={{
          display: 'flex',
          cursor: disableDeleteAction ? 'not-allowed' : 'pointer',
          color: disableDeleteAction ? colors.chartColors.neutral300 : '',
        }}
        onClick={() => {
          if (disableDeleteAction) {
            return;
          }
          setIsDeleteConfirmationVisible(true);
        }}
        onKeyDown={event => {
          if (disableDeleteAction) {
            return;
          }
          if (
            event.key === 'Enter' ||
            event.key === 'Space' ||
            event.code === 'NumpadEnter'
          ) {
            setIsDeleteConfirmationVisible(true);
          }
        }}
        tabIndex={0}>
        <CrossCircle color={disableDeleteAction ? colors.chartColors.neutral300 : ''} />
        <span
          style={{
            marginLeft: '0.5rem',
            fontSize: '1.4rem',
            fontWeight: 'bold',
            paddingTop: '0.2rem',
          }}>
          Supprimer
        </span>
      </div>

      <DeleteConfirmationModal
        isVisible={isDeleteConfirmationVisible}
        accept={() => {
          if (onDeleteIncome) {
            onDeleteIncome(itemId);
            cleanInput();
          }
        }}
        close={() => {
          setIsDeleteConfirmationVisible(false);
        }}
      />
    </CustomInput>
  );
};

export default AmountInput;
