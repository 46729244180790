import React from 'react';
import { CalcPropositionDto } from 'types/FaisabiliteDTO';
import Space from 'components/space/Space';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import * as messages from '../messages';
import {
  StyledLabelValue,
  StyledHorizontalWrapper,
  StyledDottedLine,
  StyledSubTitleCard,
  SummaryWrapperAssurance,
  StyledSubSectionCard,
} from '../style';

interface AssuranceIndividuelleProps {
  simulationResult: CalcPropositionDto;
  renderValuePercent: (value?: string | number) => void;
  renderValueEuroByMonth: (value?: string | number) => void;
}

const AssuranceIndividuelle: React.FC<AssuranceIndividuelleProps> = ({
  simulationResult,
  renderValuePercent,
  renderValueEuroByMonth,
}) => {
  return (
    <SummaryWrapperAssurance>
      <StyledSubTitleCard>
        {messages.CALCULATOR_RESULT.assuranceIndividuelle}
      </StyledSubTitleCard>
      <Space marginTop="2rem" />
      {simulationResult.assuranceEmprunteur.typeAssuranceChoisie === 'D' && (
        <>
          <StyledSubSectionCard>
            {messages.CALCULATOR_warrantyBorrower}
          </StyledSubSectionCard>
          <Space marginTop="2rem" />
          <StyledHorizontalWrapper>
            <StyledLabelValue>
              {messages.CALCULATOR_RESULT.quotiteCouverture}
            </StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValuePercent(simulationResult.assuranceEmprunteur.quotiteDeces)}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
          <StyledHorizontalWrapper>
            <StyledLabelValue>
              {messages.CALCULATOR_RESULT.primeMensuelleEstimee}
            </StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValueEuroByMonth(
                simulationResult.assuranceEmprunteur.echeanceAssuranceDeleguee,
              )}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
        </>
      )}
      {/* Check si co-emprunteur */}
      {simulationResult.assuranceCoEmprunteur.typeAssuranceChoisie === 'D' && (
        <>
          <CoBorrowerTitle />
          <StyledHorizontalWrapper>
            <StyledLabelValue>
              {messages.CALCULATOR_RESULT.quotiteCouverture}
            </StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValuePercent(simulationResult.assuranceCoEmprunteur.quotiteDeces)}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
          <StyledHorizontalWrapper>
            <StyledLabelValue>
              {messages.CALCULATOR_RESULT.primeMensuelleEstimee}
            </StyledLabelValue>
            <StyledDottedLine />
            <StyledLabelValue
              style={{
                fontWeight: 'bold',
              }}>
              {renderValueEuroByMonth(
                simulationResult.assuranceCoEmprunteur.echeanceAssuranceDeleguee,
              )}
            </StyledLabelValue>
          </StyledHorizontalWrapper>
        </>
      )}
    </SummaryWrapperAssurance>
  );
};

export default AssuranceIndividuelle;
