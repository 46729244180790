import { OperationState } from 'reducers/operationClient/types';
import { RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import { SituationFoyerState } from 'reducers/situationFoyer/types';
import {
  SituationProfessionnelle,
  SituationProfessionnelleState,
} from 'reducers/situationProfessionnelle/types';
import { UserInfos } from 'types';
import { AdditionalFaisabiliteData } from 'types/FaisabiliteDTO';
import {
  BesoinsComplementaire,
  Dette,
  Projet,
  SauvegardeDonneeRequestBody,
  SaveSituationProfessionnelle,
} from 'types/sauvegardeDonnee';
import { getDateFromInput } from 'utils/DateUtils';
import { getEtatCivil } from 'utils/commun';
import {
  getChargesRessources,
  getComplementaires,
  getComportementFinancier,
  getCredits,
  getDonneesBancaires,
  getFoyer,
  getGarantieHypothecaire,
} from 'utils/faisabiliteDtoUtil';

const saveSituationPro = (situationPro: SituationProfessionnelle) => {
  const {
    profession,
    typeContrat,
    currentJobOrSituationStartingDate,
    sirenNumber,
    apeNumber,
    adresse,
    employerCountry,
  } = situationPro;

  const reqSiutationPro = {} as SaveSituationProfessionnelle;

  if (profession) reqSiutationPro.categorieSocioProfessionnelle = profession?.code || '';
  if (apeNumber) reqSiutationPro.codeApe = apeNumber;
  if (currentJobOrSituationStartingDate) {
    reqSiutationPro.dateEntree = getDateFromInput(
      `01/${currentJobOrSituationStartingDate}`,
    );
  }
  if (sirenNumber) reqSiutationPro.numeroSiren = Number(sirenNumber);
  if (adresse?.codePostal !== '99999') {
    reqSiutationPro.paysEmployeur = 'FR';
  }
  if (adresse) {
    reqSiutationPro.adresse = {
      codePostal: Number(adresse?.codePostal) || 0,
      ville: adresse?.city?.libelle || '',
    };
  }
  if (typeContrat) reqSiutationPro.typeContrat = Number(typeContrat?.code);
  if (employerCountry?.code) reqSiutationPro.paysEmployeur = employerCountry.code;

  return reqSiutationPro;
};

const saveProjet = (operationsClient: OperationState) => {
  const projet = {} as Projet;

  projet.besoinsComplementaires = operationsClient.otherNeedsList.map(otherNeed => {
    return { type: otherNeed.type, montant: otherNeed.amount } as BesoinsComplementaire;
  });

  projet.typeGarantie = operationsClient.hasInsurance ? 'HYP' : 'SSG';
  projet.credits = getCredits(operationsClient.loanList);
  projet.besoinsComplementaires = getComplementaires(
    operationsClient.otherNeedsList,
  ) as BesoinsComplementaire[];
  projet.dettes = getComplementaires(operationsClient.buybackDebtsList) as Dette[];

  return projet;
};

const mappingSauvegardeDonnees = (
  projectInformation: AdditionalFaisabiliteData,
): SauvegardeDonneeRequestBody => {
  const reqBody = { ...projectInformation } as SauvegardeDonneeRequestBody;

  const authenticatedUser = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  reqBody.userId = authenticatedUser.uid;
  reqBody.numeroContreMarque = authenticatedUser.numContremarque;
  reqBody.emprunteur = {};

  reqBody.emprunteur.etatCivil = getEtatCivil('borrower');

  const hasCoBorrower = JSON.parse(
    sessionStorage.getItem('coBorrowerExists') || 'null',
  ) as boolean;

  const situationFoyerState = JSON.parse(
    sessionStorage.getItem('situationFoyer') || 'null',
  ) as SituationFoyerState;

  if (situationFoyerState?.isDataOk) {
    reqBody.foyer = getFoyer(situationFoyerState);
    reqBody.emprunteur.etatCivil.situationFamiliale = situationFoyerState.maritalStatus;
  }

  const situationProfessionnelleState = JSON.parse(
    sessionStorage.getItem('situationProfessionnelle') || 'null',
  ) as SituationProfessionnelleState;

  if (
    situationProfessionnelleState &&
    situationProfessionnelleState.borrower.profession
  ) {
    reqBody.emprunteur.situationProfessionnelle = saveSituationPro(
      situationProfessionnelleState.borrower,
    );
  }

  if (hasCoBorrower) {
    reqBody.coEmprunteur = {};
    reqBody.coEmprunteur.etatCivil = getEtatCivil('coBorrower');
    reqBody.coEmprunteur.etatCivil.situationFamiliale =
      situationFoyerState.coBorrowerMaritalStatus;

    if (
      situationProfessionnelleState &&
      situationProfessionnelleState.coBorrower.profession
    ) {
      reqBody.coEmprunteur.situationProfessionnelle = saveSituationPro(
        situationProfessionnelleState.coBorrower,
      );
    }
  }

  const ressourcesMensuelles = JSON.parse(
    sessionStorage.getItem('ressourcesMensuelles') || 'null',
  ) as RessourcesMensuellesState;

  if (ressourcesMensuelles && ressourcesMensuelles.banks.length > 0) {
    reqBody.chargesRessources = getChargesRessources();
    reqBody.donneesBancaires = getDonneesBancaires();
  }

  const operationClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || 'null',
  ) as OperationState;

  if (
    operationClient &&
    operationClient.buybackLoansTotal &&
    operationClient.buybackLoansTotal > 0
  ) {
    reqBody.projet = saveProjet(operationClient);
    reqBody.comportementFinancier = getComportementFinancier();
    reqBody.garantieHypothecaire = getGarantieHypothecaire(situationFoyerState);
  }

  return reqBody;
};

export default mappingSauvegardeDonnees;
