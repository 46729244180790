import GenericIconProps from './GenericIcon';

const CrossCircle: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 24;
  const baseHeight = 24;
  const baseColor = '#4A4A4A';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <title>Ic/24px/Action/cross circle24px</title>
      <path
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-1.395a8.605 8.605 0 1 0 0-17.21 8.605 8.605 0 0 0 0 17.21Zm3.284-11.889a.698.698 0 0 1 0 .987L12.987 12l2.297 2.297a.698.698 0 0 1-.987.987L12 12.987l-2.297 2.297a.698.698 0 0 1-.987-.987L11.013 12 8.716 9.703a.698.698 0 1 1 .987-.987L12 11.013l2.297-2.297a.698.698 0 0 1 .987 0Z"
        fill={color || baseColor}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CrossCircle;
