export const STATUT_DOSSIER = 'Statut du dossier :';
export const PRISE_EN_CHARGE = 'Pris en charge par :';
export const DATE_CREATION_LABEL = 'Date de création du dossier : ';
export const FILE_INFO_BORROWER = 'Emprunteur';
export const FILE_INFO_COBORROWER = 'Co emprunteur';
export const FILE_INFO_NUMBER = 'N° Dossier';

export const LOAN_SUMMARY_MAIN_LABEL = 'Montant à financer';
export const LOAN_SUMMARY_NEED_LABEL = 'Besoin du client';
export const LOAN_SUMMARY_PROCESSING_FEES_LABEL = 'Frais de dossier';
export const LOAN_SUMMARY_FEES_LABEL = "Frais d'intermédiation";
export const LOAN_SUMMARY_GARANTEE_LABEL = 'Frais de garantie';

export const LOAN_DETAILS_TYPE = 'Type de projet';
export const LOAN_DETAILS_SEGMENTATION = 'Segmentation';
export const LOAN_DETAILS_TREATMENT = 'Traitement';

export const RESUME_BUTTON_LABEL = 'Reprendre la saisie du dossier';

export const PROJECT_RECAP_MAIN_TITLE = 'Récapitulatif du projet';
export const PROJECT_RECAP_SECONDARY_TITLE = 'Transmission des pièces';

export const NO_OTHER_INCOMES = 'Aucun autre revenu mensuel';
export const NO_OHTER_NEED = 'Aucun Besoin Complémentaire';
export const NO_BUY_BACK_DEPT = 'Aucune dette à racheter';
export const NO_NEED = 'Aucun besoin complémentaire renseigné';

export const MAIN_NAVBAR_TAB_1 = 'Données client';
export const MAIN_NAVBAR_TAB_2 = 'Opération client';
export const MAIN_NAVBAR_TAB_3 = 'Conditions Financières';
export const MAIN_NAVBAR_TAB_4 = 'Finalisation du projet';

export const SECODARY_NAVBAR_TAB_1_1 = 'Identité client';
export const SECODARY_NAVBAR_TAB_1_2 = 'Situation Foyer';
export const SECODARY_NAVBAR_TAB_1_3 = 'Situation Professionnelle';
export const SECODARY_NAVBAR_TAB_1_4 = 'Revenus et charges';

export const SECODARY_NAVBAR_TAB_2_1 = 'Garantie';
export const SECODARY_NAVBAR_TAB_2_2 = 'Inventaire des prêts';
export const SECODARY_NAVBAR_TAB_2_3 = 'Autres besoins';
export const SECODARY_NAVBAR_TAB_2_4 = 'Incidents et impayés';

export const SECODARY_NAVBAR_TAB_3_1 = 'Conditions financières';
export const SECODARY_NAVBAR_TAB_3_2 = 'Résultat de la faisabilité';

export const PJ_NAVBAR_TAB_1 = 'Pièces justificatives';
export const PJ_NAVBAR_TAB_2 = 'Autres justificatifs';

export const MAIN_CARD_BORROWER_TITLE = 'Informations personnelles emprunteur';
export const MAIN_CARD_COBORROWER_TITLE = 'Informations personnelles co-emprunteur';
export const MAIN_CARD_HOME_SITUATION_TITLE = 'Informations de la résidence principale';
export const MAIN_CARD_HOME_SITUATION_BORROWER_TITLE = 'Information familiale emprunteur';
export const MAIN_CARD_HOME_SITUATION_COBORROWER_TITLE =
  'Information familiale co-emprunteur';
export const MAIN_CARD_PROFESSIONAL_SITUATION_BORROWER_TITLE =
  'Informations professionnelles emprunteur';
export const MAIN_CARD_PROFESSIONAL_SITUATION_COBORROWER_TITLE =
  'Informations professionnelles co-emprunteur';

export const MAIN_CARD_LABEL_CIVILITY = 'Civilité';
export const MAIN_CARD_LABEL_LAST_NAME = 'Nom';
export const MAIN_CARD_LABEL_FIRST_NAME = 'Prénom';
export const MAIN_CARD_LABEL_BIRTH_DATE = 'Date de naissance';
export const MAIN_CARD_LABEL_BIRTH_COUNTRY = 'Pays de naissance';
export const MAIN_CARD_LABEL_BIRTH_DEPARTMENT = 'Département de naissance';
export const MAIN_CARD_LABEL_BIRTH_CITY = 'Ville de naissance';
export const MAIN_CARD_LABEL_NATIONALITY = 'Nationalité';
export const MAIN_CARD_LABEL_BIRTH_NAME = 'Nom de naissance';

export const MAIN_CARD_LABEL_ZIP_CODE = 'Code postal';
export const MAIN_CARD_LABEL_CITY = 'Ville';
export const MAIN_CARD_LABEL_LODGING_SITUATION = 'Situation logement';
export const MAIN_CARD_LABEL_LODGING_DURATION = 'Dans le logement actuel depuis';
export const MAIN_CARD_LABEL_LODGING_PERSON_AMOUNT =
  'Nombre de personnes composant le foyer';
export const MAIN_CARD_LABEL_CHILDREN_BIRTH_YEARS = 'Année de naissance des enfants';
export const MAIN_CARD_LABEL_NO_CHILDREN = 'Aucun enfant à charge';
export const MAIN_CARD_LABEL_HOME_SITUATION = 'Situation familiale';

export const MAIN_CARD_LABEL_EMPLOYMENT_SITUATION = 'Profession ou situation';
export const MAIN_CARD_LABEL_CONTRACT_TYPE = 'Type de contrat';
export const MAIN_CARD_LABEL_EMPLOYMENT_SITUATION_DURANTION =
  "Chez l'employeur actuel ou dans la même situation depuis";
export const MAIN_CARD_LABEL_SIREN_NUMBER = 'N° de SIREN';
export const MAIN_CARD_LABEL_APE_CODE = 'Code APE';
export const MAIN_CARD_LABEL_EMPLOYMENT_COUNTRY = 'Pays';
export const MAIN_CARD_LABEL_EMPLOYMENT_ZIPCODE = 'Code Postal';
export const MAIN_CARD_LABEL_EMPLOYMENT_CITY = 'Ville';

export const MAIN_CARD_LABEL_BORROWER_INCOME = 'Information revenu emprunteur';
export const MAIN_CARD_LABEL_COBORROWER_INCOME = 'Information revenu co-emprunteur';
export const MAIN_CARD_LABEL_MONTHLY_INCOME = 'Revenu mensuel';
export const MAIN_CARD_LABEL_RELIABLE_INCOME = "Revenu retenu à l'octroi";
export const MAIN_CARD_LABEL_MONTHLY_RETIREMENT_INCOME = 'Retraite mensuelle';
export const MAIN_CARD_LABEL_OTHER_MONTHLY_INCOMES = 'Autres revenus mensuels';
export const MAIN_CARD_LABEL_TOTAL_INCOMES = 'Total des revenus : ';
export const MAIN_CARD_LABEL_TOTAL_RELIABLE_INCOMES =
  "Total des revenus retenus à l'octroi : ";
export const MAIN_CARD_LABEL_MONTHLY_CHARGES = 'Charges mensuelles';
export const MAIN_CARD_LABEL_TOTAL_CHARGES = 'Total des charges : ';
export const MAIN_CARD_LABEL_BANK_INFORMATION = 'Informations bancaires';
export const MAIN_CARD_LABEL_BANK_ACCOUNT = 'Compte bancaire';
export const MAIN_CARD_LABEL_BANk_ACCOUNT_OPENED_SINCE = 'Ouvert depuis';

export const MAIN_CARD_LABEL_ASSET = 'Bien';
export const MAIN_CARD_LABEL_ASSET_TYPE = 'Type de bien';
export const MAIN_CARD_LABEL_ASSET_USAGE = 'Usage du bien';
export const MAIN_CARD_LABEL_ASSET_VALUE = 'Valeur du bien';
export const MAIN_CARD_LABEL_INSCRIPTION_RANK = "Rang de l'inscription du bien";
export const MAIN_CARD_LABEL_KEPT_MORTGAGES_VALUE = 'Valeur des hypothèques conservées';
export const MAIN_CARD_LABEL_REMAINING_MORTAGAGES_VALUE =
  "Valeur libre d'hypothèque retenue";
export const NO_ASSET = 'Aucune garantie renseignée';

export const MAIN_CARD_LOAN_TITLE = 'Informations des prêts à ';
export const MAIN_CARD_LOAN_SUBTITLE = 'Prêt ';
export const MAIN_CARD_LOAN_LABEL_LOANER = 'Organisme de crédit';
export const MAIN_CARD_LOAN_LABEL_CREDIT_TYPE = 'Type de crédit';
export const MAIN_CARD_LOAN_LABEL_MONTHLY_PAYMENT = 'Mensualité du prêt';
export const MAIN_CARD_LOAN_LABEL_BALANCE = 'Solde';
export const MAIN_CARD_LOAN_LABEL_START_DATE = 'Date de début du prêt';
export const MAIN_CARD_LOAN_LABEL_INITIAL_CAPITAL = 'Capital initial du prêt';
export const MAIN_CARD_LOAN_LABEL_TOTAL_DURATION = 'Durée totale du prêt';
export const MAIN_CARD_LOAN_LABEL_TOTAL_MONTHLY_PAYMENT = 'Total des mensualités : ';
export const MAIN_CARD_LOAN_LABEL_TOTAL_BALANCE = 'Total des soldes : ';

export const MAIN_CARD_TITLE_UNRESOLVED_PAYMENT_INCIDENT =
  'Incidents de paiement non régularisés';
export const MAIN_CARD_LABEL_LATE_PAYMENT = 'Retard de paiement';
export const MAIN_CARD_LABEL_SEIZURE = 'Présence de saisie';
export const MAIN_CARD_TITLE_UNRESOLVED_PAYMENT_DEFAULT =
  'Impayés sur les mensualités de crédits non régularisés';
export const MAIN_CARD_LABEL_TOTAL_UNRESOLVED_DEFAULTS_OCCURENCES =
  'Nombre total de mois avec impayés non regularisés';
export const NO_PAYMENT_INCIDENT = 'Aucun incident et impayé renseignés';

export const MAIN_CARD_LABEL_OTHER_NEED = 'Besoins complémentaires';
export const MAIN_CARD_LABEL_TOTAL_NEED = 'Total du besoin';
export const MAIN_CARD_LABEL_BUY_BACK_DEPT = 'Dettes à racheter';
export const MAIN_CARD_LABEL_TOTAL_BUY_BACK_DEPT = 'Total des dettes';

export const MAIN_CARD_CONDITIONS_TITLE_FEES = "Frais d'intermédiation";
export const MAIN_CARD_CONDITIONS_TITLE_DURATION = 'Durée applicable';
export const MAIN_CARD_CONDITIONS_LABEL_LOAN_DURATION = 'Durée totale du prêt';
export const MAIN_CARD_CONDITIONS_LABEL_FRANCHISE_DURATION = 'Durée de la franchise';
export const MAIN_CARD_CONDITIONS_LABEL_FIRST_STEP_DURATION = 'Durée du premier palier';
export const MAIN_CARD_CONDITIONS_LABEL_FIRST_PAYMENT_AMOUNT =
  'Mensualité du premier palier';
export const MAIN_CARD_CONDITIONS_TITLE_BAREME = 'Barème';
export const MAIN_CARD_CONDITIONS_LABEL_INTEREST_RATE = "Taux d'intérêt nominal";
export const MAIN_CARD_CONDITIONS_TITLE_LOAN_INSURANCE = 'Assurance du prêt';
export const MAIN_CARD_CONDITIONS_LABEL_BORROWER = 'Emprunteur';
export const MAIN_CARD_CONDITIONS_LABEL_COBORROWER = 'Co-emprunteur';

export const FINALISATION_EMISSION_PACK_CONTRACTUEL = "Émission de l'offre de crédit";
export const FINALISATION_EDITION_MODE =
  "Mode d'édition de l'offre de crédit souhaité par le client";
export const FINALISATION_INFO_CARTE_IDENTITE_EMPRUNTEUR = {
  titre: "Information de la pièce d'identité emprunteur",
  coTitre: "Information de la pièce d'identité co-emprunteur",
  type: "Type de pièce d'identité",
  numero: 'N° de pièce d’identité',
};
export const FINALISATION_INFO_COMPLEMANT_DOMICILE = {
  titre: 'Information Complémentaire du domicile',
  adresse: 'Adresse',
  compl: "Complément d'adresse",
  boiteLieu: 'Lieu-dit ou boite postale',
};
export const FINALISATION_INFO_COORDONNEES_EMPRUNTEUR = {
  titre: 'Information des coordonnées emprunteur',
  coTitre: 'Information des coordonnées co-emprunteur',
  mail: 'Adresse mail',
  cellPhone: 'Numéro de mobile',
  homePhone: 'Numéro du domicile',
};
export const FINALISATION_INFO_PROFESSIONNEL = {
  titre: 'Informations complémentaires de la situation professionnelle emprunteur',
  coTitre: 'Informations complémentaires de la situation professionnelle co-emprunteur',
  label: 'Employeur',
};
export const FINALISATION_INFO_GARANTIE = {
  titre: 'Informations complémentaires des biens pris en garantie',
  notaire_title: 'Coordonnées du notaire',
  bien: 'Bien',
  adresse: 'Adresse du bien',
  notaire: 'Nom du notaire',
  constructionYear: 'Année de construction',
  aquisitionYear: "Année d'acquisition",
  aquisitionValue: "Valeur d'aquisition",
  surface: 'Surface habitable',
  evalutationType: "Type d'évaluation",
};
export const FINALISATION_INFO_PRETS_RACHETER = {
  titre: 'Informations complémentaires des prêts à racheter',
  pret: 'Prêt',
  date: 'Date de début du prêt',
  dureeTotal: 'Durée total du prêt',
  dureeRestante: 'Durée restante',
  titulaire: 'Titulaire',
  type: 'Sous type de crédit',
  objetCredit: 'Objet du crédit',
  numero: 'N° de prêt',
  indemnite: 'indemnités RA',
  taux: "Taux d'intérêt nominal",
  delaiPreavis: 'Délai de préavis',
  adresse: "adresse de l'établissement bancaire:",
};
export const FINALISATION_INFO_DETTES_RACHETER = {
  titre: 'Informations complémentaires des dettes à racheter',
  date: "Date d'exigilité",
};
export const FINALISATION_IBAN = "Relevé d'Identité Bancaire du prélèvement (RIB)";

export const FINALISATION_INTERLOCUTEURS = {
  titre: 'Interlocuteurs partenaires',
  monteur: 'Interlocuteur chargé du montage du dossier',
  vendeur: 'Interlocuteur ayant réalisé la découverte client',
  miob: 'Nom du MIOB',
};

export const MAIN_CARD_EDIT_BUTTON_LABEL = 'Modifier les informations';

export const TRANSMISSION_PJ_MAIN_TITLE =
  'Pièces justificatives à fournir par le partenaire';
export const TRANSMISSION_AUTRE_PJ_MAIN_TITLE =
  'Autres justificatifs à fournir par le partenaire';
export const SUB_TITLE_CHEMISE_UPLOADABLE =
  'Veuillez déposer les pièces justificatives de votre client pour finaliser le dossier.';
export const ACCEPTED_FORMAT_TEXT = 'Formats et tailles acceptés : PDF, JPEG - 19 Mo';
export const ACCEPTED_FORMAT_TEXT_FOR_SG =
  'Formats et tailles acceptés : PDF, JPEG, WORD, EXCEL (sans macro), PNG - 19 Mo.';
export const HIDE_VALID_PIECE = 'Masquer pièce conforme';
export const TITLE_CHEMISE_NON_UPLOADABLE =
  'Pièces justificatives à fournir par un tiers';
export const SUB_TITLE_CHEMISE_NON_UPLOADABLE =
  'Les pièces justificatives à fournir par un tiers (votre client, l’organisme d’assurance, etc…).';
export const UPLOAD_SUCCESS_MSG =
  'Vos pièces justificatives ont bien été transmises à l’octroi pour étude';

export const ESIGN_ACCESS = 'Espace e-signature';

export const MAIN_COMMENT_TITLE = 'Espace commentaire';
