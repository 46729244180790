import { GetLoanInformationApiResponse } from 'types';
import getFaisabiliteReq from 'utils/faisabiliteDtoUtil';
import { faisabiliteInstance } from './axiosInstances';

const getLoanInformationByIntermediationFee = (
  valeurFraisIntermediation?: number,
  inputType?: string,
  numeroProjet?: number,
  identifiantEmprunteur?: number,
  identifiantCoEmprunteur?: number,
): Promise<GetLoanInformationApiResponse> => {
  return faisabiliteInstance
    .post('/getSimulationLimite', {
      ...getFaisabiliteReq(valeurFraisIntermediation, inputType),
      numeroProjet,
      identifiantEmprunteur,
      identifiantCoEmprunteur,
    })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      console.error(err);
      return Promise.reject(err);
    });
};

export default getLoanInformationByIntermediationFee;
