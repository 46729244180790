import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    /* 1rem = 10px */
    font-size: 62.5%;
    height: 100%;
  }
  
  body {
    width: 100%;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 1.6rem;
  }

  body.fontLoaded {
    font-family: 'Open Sans', Helvetica, sans-serif;
  }

  * {
    box-sizing: border-box;
    font-family: 'Open Sans', Helvetica, sans-serif;
  }
`;

// Message d'erreur
export const ErrorMsg = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.chartColors.error};
  justify-content: center;
  font-size: 1.5rem;
`;

export default GlobalStyle;
