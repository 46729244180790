import { getCitiesByZipCode } from 'api/referentialService';
import CityInput from 'components/cityInput/CityInput';
import CustomInput from 'components/customInput/CustomInput';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { useEffect, useState } from 'react';
import {
  SituationProfessionnelleAction,
  SituationProfessionnelleState,
} from 'reducers/situationProfessionnelle/types';
import Space from 'components/space/Space';
import { isSelfEmployedWorker } from 'containers/communs/utils';
import { City, CodeLibelle, Country, StatusControlInput, StatusInput } from 'types';
import { ValidationRules, getNotEmpty } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import { fetchDepartments } from '../identity/IdentityForm';

interface EmployerCountryProps {
  state: SituationProfessionnelleState;
  dispatch: React.Dispatch<SituationProfessionnelleAction>;
  person: 'borrower' | 'coBorrower';
  checkInputs: boolean;
  countryList: Country[];
}
const EmployerCountry: React.FC<EmployerCountryProps> = ({
  state,
  dispatch,
  person,
  checkInputs,
  countryList,
}) => {
  const [departmentList, setDepartmentList] = useState<CodeLibelle[]>([]);

  const [isZipCodeInputFocused, setIsZipCodeInputFocused] = useState<boolean>(false);
  const [statusZipCode, setStatusZipCode] = useState<StatusControlInput>();
  const [statusAddressCity, setStatusAddressCity] = useState<StatusInput>();
  const [statusBorrowerCountry, setStatusBorrowerCountry] = useState<StatusInput>();
  const [statusCoBorrowerCountry, setStatusCoBorrowerCountry] = useState<StatusInput>();
  const [cityList, setCityList] = useState<City[]>([]);

  const zipCode = new RegExp(ValidationRules.zipCode);
  const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const personState = person === 'borrower' ? state.borrower : state.coBorrower;

  const checkStatusAddressCity = (cityByZipCodeList: CodeLibelle[]) => {
    setStatusAddressCity({
      status: 'none',
      errorMessage: '',
    });
    const address =
      person === 'borrower' ? state.borrower.adresse : state.coBorrower.adresse;
    if (address?.city) {
      const cityMatchesListElement = cityByZipCodeList?.find(city => {
        return city.libelle.toUpperCase() === address.city?.libelle.toUpperCase();
      });
      if (cityMatchesListElement) {
        dispatch({
          type: 'setEmployerCity',
          payload: { person, value: cityMatchesListElement },
        });
        setStatusAddressCity({
          status: 'valid',
          errorMessage: '',
        });
      } else {
        dispatch({
          type: 'setEmployerCity',
          payload: {
            person,
            value: {
              code: 'err',
              libelle: address.city.libelle ? address.city.libelle : '',
            },
          },
        });
        setStatusAddressCity({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!address?.city) {
      setStatusAddressCity({
        status: 'invalid',
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    }
  };

  const checkStatusZipCode = () => {
    const address =
      person === 'borrower' ? state.borrower.adresse : state.coBorrower.adresse;

    if (!address?.codePostal) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }
    if (!zipCode.test(address?.codePostal)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
      return;
    }
    if (!notEmpty.test(address?.codePostal)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      return;
    }
    if (!onlyNumbers.test(address?.codePostal)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_ONLY_NUMBERS,
      });
      return;
    }
    if (!zipCodeNoZero.test(address?.codePostal)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_NO_ZERO,
      });
      return;
    }
    if (cityList.length === 0) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.WRONG_ZIPCODE,
      });
      return;
    }

    dispatch({
      type: 'setEmployerZipCode',
      payload: { person, value: address?.codePostal.slice(0, 5).trim() },
    });
    setStatusZipCode({
      status: true,
      errorMessage: '',
    });
  };

  const checkStatusCountry = (typeParam: 'borrower' | 'coBorrower') => {
    const type =
      typeParam === 'borrower'
        ? state.borrower.employerCountry
        : state.coBorrower.employerCountry;
    if (person && type) {
      const countryMatchesListElement = countryList?.find(job => {
        return job.libelle.toUpperCase() === type.libelle.toUpperCase();
      });
      if (countryMatchesListElement) {
        dispatch({
          type: 'setEmployerCountry',
          payload: { person: typeParam, value: countryMatchesListElement },
        });
        if (typeParam === 'borrower')
          setStatusBorrowerCountry({
            status: 'valid',
            errorMessage: ``,
          });
        else
          setStatusCoBorrowerCountry({
            status: 'valid',
            errorMessage: ``,
          });
      } else {
        dispatch({
          type: 'setEmployerCountry',
          payload: {
            person: typeParam,
            value: {
              code: 'err',
              libelle: type.libelle,
            },
          },
        });
        if (typeParam === 'borrower')
          setStatusBorrowerCountry({
            status: 'invalid',
            errorMessage: messagesCheckInput.INPUT_ERROR,
          });
        else
          setStatusCoBorrowerCountry({
            status: 'invalid',
            errorMessage: messagesCheckInput.INPUT_ERROR,
          });
      }
    }
    if (!type) {
      if (typeParam === 'borrower')
        setStatusBorrowerCountry({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
      else
        setStatusCoBorrowerCountry({
          status: 'invalid',
          errorMessage: messagesCheckInput.REQUIRED_VALUE,
        });
    }
  };

  useEffect(() => {
    if (departmentList.length === 0) {
      fetchDepartments().then(result => {
        setDepartmentList(result);
      });
    }
  }, []);

  useEffect(() => {
    if (checkInputs) {
      checkStatusZipCode();
      checkStatusAddressCity(cityList);
      checkStatusCountry(person);
    }
  }, [checkInputs]);

  useEffect(() => {
    const address =
      person === 'borrower' ? state.borrower.adresse : state.coBorrower.adresse;

    if (address?.codePostal?.length === 5) {
      getCitiesByZipCode(address?.codePostal).then(cityByZipCodeList => {
        if (address.city && address.city.libelle !== '' && checkInputs)
          checkStatusAddressCity(cityByZipCodeList);
        setCityList(cityByZipCodeList);
      });
    } else if (address?.city) {
      setCityList([address?.city]);
    } else {
      setCityList([]);
    }
  }, [state.borrower.adresse?.codePostal, state.coBorrower.adresse?.codePostal]);

  useEffect(() => {
    const address =
      person === 'borrower' ? state.borrower.adresse : state.coBorrower.adresse;

    if (address?.codePostal && address?.codePostal.length === 5) {
      checkStatusZipCode();
    }
  }, [cityList]);

  useEffect(() => {
    if (state.borrower.employerCountry) {
      checkStatusCountry('borrower');
    }
    if (state.coBorrower.employerCountry) {
      checkStatusCountry('coBorrower');
    }
  }, [countryList]);

  const onCountrySelect = (type: 'borrower' | 'coBorrower', e: Event) => {
    const selectedCountry = (e as CustomEvent).detail.value as Country;
    dispatch({
      type: 'setEmployerCountry',
      payload: { person: type, value: selectedCountry },
    });
  };

  const onCountryBlur = (type: 'borrower' | 'coBorrower', event: Event) => {
    if (event && event.target) {
      const countryMatchesListElement = countryList?.find(job => {
        return (
          job.libelle.toUpperCase() ===
          ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
        );
      });
      if (countryMatchesListElement) {
        dispatch({
          type: 'setEmployerCountry',
          payload: { person: type, value: countryMatchesListElement },
        });
      } else {
        dispatch({
          type: 'setEmployerCountry',
          payload: {
            person: type,
            value: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            },
          },
        });
      }
    }
  };

  const onCitySelect = (e: Event) => {
    const selectedCity = (e as CustomEvent).detail.value as City;
    dispatch({
      type: 'setEmployerCity',
      payload: { person, value: selectedCity },
    });
  };

  const onCityBlur = (event: Event) => {
    if (event && event.target) {
      const cityMatchesListElement = cityList?.find(city => {
        return (
          city.libelle.toUpperCase() ===
          ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
        );
      });
      if (cityMatchesListElement) {
        dispatch({
          type: 'setEmployerCity',
          payload: { person, value: cityMatchesListElement },
        });
      } else {
        dispatch({
          type: 'setEmployerCity',
          payload: {
            person,
            value: {
              code: 'err',
              libelle: (event.target as unknown as ComboInputDS).value as string,
            } as CodeLibelle,
          },
        });
      }
    }
  };

  const isDisabled = () => {
    const isDisabledBoolean =
      person === 'borrower'
        ? isSelfEmployedWorker(state.borrower?.profession)
        : isSelfEmployedWorker(state.coBorrower?.profession);
    return isDisabledBoolean ? true : undefined;
  };

  return (
    <>
      <ComboInput
        class="combo-input show-input-ds-validation"
        list-on-open
        shadow
        align-items="left"
        items={JSON.stringify(countryList)}
        field="libelle"
        label="Pays employeur"
        placeholder="ex: France"
        onSelectedList={e => {
          onCountrySelect(person, e);
        }}
        onListBlur={e => {
          onCountryBlur(person, e);
        }}
        disabled={isDisabled()}
        value={
          person === 'borrower'
            ? state.borrower.employerCountry?.libelle || ''
            : state.coBorrower.employerCountry?.libelle || ''
        }
        defaultValue="France"
        status={
          person === 'borrower'
            ? statusBorrowerCountry?.status
            : statusCoBorrowerCountry?.status
        }
        error-message={
          person === 'borrower'
            ? statusBorrowerCountry?.errorMessage
            : statusCoBorrowerCountry?.errorMessage
        }
        customValidityPattern={JSON.stringify(getNotEmpty('Pays employeur'))}
      />
      <Space marginBottom="2.4rem" />
      {['FR', '', 'err'].includes(personState.employerCountry?.code || '') && (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '15.6rem', marginRight: '1.6rem' }}>
            <CustomInput
              name="zip-code"
              label="Code postal"
              placeholder="ex: 75001"
              onChange={newValue => {
                setIsZipCodeInputFocused(true);
                let value =
                  person === 'borrower'
                    ? state.borrower.adresse?.codePostal
                    : state.coBorrower.adresse?.codePostal;
                if (onlyNumbers.test(newValue)) value = newValue;
                dispatch({
                  type: 'setEmployerZipCode',
                  payload: { person, value: value?.slice(0, 5) },
                });
              }}
              onBlur={checkStatusZipCode}
              onFocus={() => {
                setIsZipCodeInputFocused(true);
              }}
              isValid={statusZipCode?.status}
              isFocused={isZipCodeInputFocused}
              value={
                person === 'borrower'
                  ? state.borrower.adresse?.codePostal
                  : state.coBorrower.adresse?.codePostal
              }
              error={statusZipCode?.errorMessage}
            />
          </div>

          <CityInput
            label="Ville"
            defaultValue={
              (person === 'borrower'
                ? state.borrower.adresse?.city?.libelle
                : state.coBorrower.adresse?.city?.libelle) || ''
            }
            cities={cityList}
            onCitySelect={onCitySelect}
            onCityBlur={onCityBlur}
            className="show-input-ds-validation"
            status={statusAddressCity?.status}
            errorMessage={statusAddressCity?.errorMessage}
          />
        </div>
      )}
    </>
  );
};

export default EmployerCountry;
