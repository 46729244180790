import Space from 'components/space/Space';
import React from 'react';
import {
  StyleCardGrid,
  StyleCardGridItem,
  StyledCardData,
  StyledCardLabel,
  StyledCardTitle,
  StyledHorizontalBar,
} from 'containers/consultation/style';
import { Loan } from 'reducers/operationClient/types';
import { LoanAdditionalInformationState } from 'reducers/loanAdditionalInformation/type';
import { formatValues } from 'utils/commun';
import { getDateFromInput, getMonthsRemaining } from 'utils/DateUtils';
import * as messages from '../../messages';

interface FinalisationPretRacheterConsulationProps {
  loansInfo: Loan[];
  loanAdditionalInformation: LoanAdditionalInformationState;
}

const FinalisationPretRacheterConsulation: React.FC<
  FinalisationPretRacheterConsulationProps
> = ({ loansInfo, loanAdditionalInformation }) => {
  const renderBorrower = (titulaire: any): string => {
    if (!titulaire || titulaire === 'borrower') {
      return 'Emprunteur';
    }
    if (titulaire === 'coBorrower') {
      return 'Co-Emprunteur';
    }
    return 'Les deux';
  };

  const renderSubTypeCredit = (sousType: any): string => {
    if (!sousType || sousType === 'creditAffecte') {
      return 'Crédit affecté';
    }
    if (sousType === 'personnel') return 'Prêt personnel';
    return 'Autre';
  };

  return (
    <>
      <StyledCardTitle>{messages.FINALISATION_INFO_PRETS_RACHETER.titre}</StyledCardTitle>
      <Space marginBottom="1.6rem" />

      {loansInfo?.map((loan, index) => {
        const loanMoreInfo = loanAdditionalInformation?.loans.find(element => {
          return element.code === loan.code;
        });

        const getAddressBanque = (): string => {
          let fullAddress = ``;

          if (loanMoreInfo?.adresse?.streetNumber)
            fullAddress = `${loanMoreInfo?.adresse.streetNumber} `;
          if (loanMoreInfo?.adresse?.streetType)
            fullAddress = `${fullAddress}${loanMoreInfo?.adresse.streetType.libelle} `;
          if (loanMoreInfo?.adresse?.street)
            fullAddress = `${fullAddress}${loanMoreInfo?.adresse.street} `;

          return fullAddress;
        };
        return (
          <div key={loan.code}>
            {index > 0 && (
              <>
                <StyledHorizontalBar
                  color="#979797"
                  style={{ height: '0.2rem', marginRight: '0' }}
                />
                <Space marginBottom="1.6rem" />
              </>
            )}

            <StyledCardTitle>
              {`${messages.FINALISATION_INFO_PRETS_RACHETER.pret} ${index + 1}`}
            </StyledCardTitle>
            <Space marginBottom="1.6rem" />

            <StyleCardGrid>
              <StyleCardGridItem>
                <StyledCardLabel>{messages.MAIN_CARD_LOAN_LABEL_LOANER}</StyledCardLabel>
                <StyledCardData>{loan.loaner?.libelle}</StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LOAN_LABEL_CREDIT_TYPE}
                </StyledCardLabel>
                <StyledCardData>{loan.creditType?.libelle}</StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LOAN_LABEL_MONTHLY_PAYMENT}
                </StyledCardLabel>
                <StyledCardData>{formatValues(loan.monthlyPayment, '€')}</StyledCardData>
              </StyleCardGridItem>
              {loan.balance && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LOAN_LABEL_BALANCE}
                  </StyledCardLabel>
                  <StyledCardData>{formatValues(loan.balance, '€')}</StyledCardData>
                </StyleCardGridItem>
              )}
              {loan.initialLoanCapital && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LOAN_LABEL_INITIAL_CAPITAL}
                  </StyledCardLabel>
                  <StyledCardData>
                    {formatValues(loan.initialLoanCapital, '€')}
                  </StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.loanStartingDate && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.date}
                  </StyledCardLabel>
                  <StyledCardData>{loanMoreInfo?.loanStartingDate}</StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.loanDuration && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.dureeTotal}
                  </StyledCardLabel>
                  <StyledCardData>{`${loanMoreInfo?.loanDuration} mois`}</StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.loanStartingDate && loanMoreInfo?.loanDuration && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.dureeRestante}
                  </StyledCardLabel>
                  <StyledCardData>
                    {`${
                      Number(loanMoreInfo?.loanDuration) -
                      getMonthsRemaining(
                        getDateFromInput(loanMoreInfo?.loanStartingDate),
                        new Date(),
                      )
                    } mois`}
                  </StyledCardData>
                </StyleCardGridItem>
              )}
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_PRETS_RACHETER.titulaire}
                </StyledCardLabel>
                <StyledCardData>{renderBorrower(loanMoreInfo?.titulaire)}</StyledCardData>
              </StyleCardGridItem>

              {loanMoreInfo?.loanType && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.type}
                  </StyledCardLabel>
                  <StyledCardData>
                    {renderSubTypeCredit(loanMoreInfo?.loanType)}
                  </StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.loanObject && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.objetCredit}
                  </StyledCardLabel>
                  <StyledCardData>{loanMoreInfo?.loanObject.libelle}</StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.loanNumber && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.numero}
                  </StyledCardLabel>
                  <StyledCardData>{loanMoreInfo?.loanNumber}</StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.indemnityAmount && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.indemnite}
                  </StyledCardLabel>
                  <StyledCardData>
                    {`${loanMoreInfo?.indemnityAmount?.toLocaleString()} €`}
                  </StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.nominalInterestRate && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.taux}
                  </StyledCardLabel>
                  <StyledCardData>{`${loanMoreInfo?.nominalInterestRate}%`}</StyledCardData>
                </StyleCardGridItem>
              )}
              {loanMoreInfo?.nbMonthsForNotice && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_PRETS_RACHETER.delaiPreavis}
                  </StyledCardLabel>
                  <StyledCardData>{`${loanMoreInfo?.nbMonthsForNotice} mois`}</StyledCardData>
                </StyleCardGridItem>
              )}
              <StyleCardGridItem style={{ justifyContent: 'flex-end' }}>
                <StyledCardData style={{ fontWeight: 'bold' }}>
                  {loan.restructure ? 'Restructuration' : null}
                </StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem style={{ justifyContent: 'flex-end' }}>
                <StyledCardData style={{ fontWeight: 'bold' }}>
                  {loan.mortgage ? 'Incription hypothecaire' : null}
                </StyledCardData>
              </StyleCardGridItem>
            </StyleCardGrid>
            {loanMoreInfo?.adresse && (
              <>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_PRETS_RACHETER.adresse}
                </StyledCardLabel>
                <Space marginBottom="0.8rem" />
                <StyleCardGrid>
                  {getAddressBanque() &&
                    getAddressBanque().replaceAll(' ', '') !== '' && (
                      <StyleCardGridItem>
                        <StyledCardLabel>
                          {messages.FINALISATION_INFO_COMPLEMANT_DOMICILE.adresse}
                        </StyledCardLabel>
                        <StyledCardData>{getAddressBanque()}</StyledCardData>
                      </StyleCardGridItem>
                    )}
                  {loanMoreInfo?.adresse?.additionalInformation && (
                    <StyleCardGridItem>
                      <StyledCardLabel>
                        {messages.FINALISATION_INFO_COMPLEMANT_DOMICILE.compl}
                      </StyledCardLabel>
                      <StyledCardData>
                        {loanMoreInfo?.adresse?.additionalInformation}
                      </StyledCardData>
                    </StyleCardGridItem>
                  )}
                  {loanMoreInfo?.adresse?.POBoxOrHamlet && (
                    <StyleCardGridItem>
                      <StyledCardLabel>
                        {messages.FINALISATION_INFO_COMPLEMANT_DOMICILE.boiteLieu}
                      </StyledCardLabel>
                      <StyledCardData>
                        {loanMoreInfo?.adresse?.POBoxOrHamlet}
                      </StyledCardData>
                    </StyleCardGridItem>
                  )}
                  <StyleCardGridItem>
                    <StyledCardLabel>{messages.MAIN_CARD_LABEL_ZIP_CODE}</StyledCardLabel>
                    <StyledCardData>{loanMoreInfo?.adresse?.zipCode}</StyledCardData>
                  </StyleCardGridItem>
                  <StyleCardGridItem>
                    <StyledCardLabel>{messages.MAIN_CARD_LABEL_CITY}</StyledCardLabel>
                    <StyledCardData>{loanMoreInfo?.adresse?.city}</StyledCardData>
                  </StyleCardGridItem>
                </StyleCardGrid>
              </>
            )}
            <Space marginBottom="1.6rem" />
          </div>
        );
      })}
    </>
  );
};

export default FinalisationPretRacheterConsulation;
