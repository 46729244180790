import { StyledHorizontalBar } from 'containers/consultation/style';
import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import { useState } from 'react';
import { CSSProperties } from 'styled-components';
import { CodeLibelle } from 'types';
import {
  StyledSelectInput,
  StyledSelectInputList,
  StyledSelectInputPlaceholderText,
  StyledSelectInputText,
  StyledSelectText,
} from './style';

interface SearchSelectInputProps {
  width?: string;
  value: CodeLibelle | undefined;
  setValue: (value: CodeLibelle) => void;
  placeholder?: string;
  icon?: React.ReactElement;
  items: CodeLibelle[];
}

const SearchSelectInput: React.FC<SearchSelectInputProps> = ({
  width,
  value,
  setValue,
  placeholder,
  icon,
  items,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [highlightedElement, setHighlightedElement] = useState<string>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const isHighlightedElement = (itemCode: string): boolean => {
    if (itemCode !== highlightedElement) return false;
    return true;
  };

  const highlightedStyle = { background: '#DEF1DB' } as CSSProperties;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <div style={{ position: 'relative' }}>
        {icon ? (
          <div
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '2.7rem',
              left: '2.6rem',
            }}>
            {icon}
          </div>
        ) : null}
        <StyledSelectInput
          style={{
            cursor: 'pointer',
            width,
            paddingLeft: icon ? '6.2rem' : '1.6rem',
            border: isFocused || value?.libelle ? 'solid' : 'hidden',
            borderColor: isFocused || value?.libelle ? '#008859' : '#fff',
            borderWidth: isFocused || value?.libelle ? '0.1rem' : '0',
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
            setTimeout(() => {
              setIsExpanded(false);
            }, 250);
          }}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          role="button"
          tabIndex={0}>
          {!value ? (
            <StyledSelectInputPlaceholderText unselectable="on">
              {placeholder}
            </StyledSelectInputPlaceholderText>
          ) : (
            <StyledSelectInputText>{value.libelle}</StyledSelectInputText>
          )}
        </StyledSelectInput>
        <div style={{ position: 'absolute', top: '2.7rem', right: '2.6rem' }}>
          {isExpanded ? <ChevronUp color="" /> : <ChevronDown />}
        </div>
      </div>

      {isExpanded && (
        <StyledSelectInputList
          style={{
            position: 'absolute',
            top: '8rem',
            width,
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            zIndex: 1,
          }}>
          {items.map((item, index) => {
            return (
              <>
                <StyledSelectText
                  style={isHighlightedElement(item.code) ? highlightedStyle : {}}
                  key={item.code}
                  onMouseEnter={() => {
                    setHighlightedElement(item.code);
                  }}
                  onMouseLeave={() => {
                    setHighlightedElement(undefined);
                  }}
                  onKeyPress={() => {
                    setValue(item);
                    setIsExpanded(!isExpanded);
                  }}
                  onClick={() => {
                    setValue(item);
                    setIsExpanded(!isExpanded);
                  }}
                  role="button"
                  tabIndex={0}>
                  {item.libelle}
                </StyledSelectText>
                {index !== items.length - 1 && (
                  <StyledHorizontalBar style={{ margin: '0' }} color="#e0e0e0" />
                )}
              </>
            );
          })}
        </StyledSelectInputList>
      )}
    </div>
  );
};

SearchSelectInput.defaultProps = {
  width: '38.9rem',
  icon: undefined,
};

export default SearchSelectInput;
