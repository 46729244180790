import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import { StyledInputLabel } from 'containers/finalisationProjet/style';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';

interface LoanAdditionalInformationProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const TitulaireChoice: React.FC<LoanAdditionalInformationProps> = ({
  currentLoan,
  state,
  dispatch,
}) => {
  const [titulaire, setTitulaire] = useState<
    'borrower' | 'coBorrower' | 'both' | undefined
  >();

  useEffect(() => {
    if (currentLoan) {
      setTitulaire(currentLoan.titulaire);
    }
  }, []);

  useEffect(() => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, titulaire };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  }, [titulaire]);

  const renderTitulaireChoice = () => {
    return (
      <>
        <StyledInputLabel>Titulaire</StyledInputLabel>
        <div style={{ display: 'flex', gap: '3.7rem' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="borrower"
              checked={titulaire === 'borrower' ? true : undefined}
              onClick={() => {
                if (titulaire !== 'borrower') {
                  setTitulaire('borrower');
                } else {
                  setTitulaire(undefined);
                }
              }}
            />
            <StyledInputLabel>Emprunteur</StyledInputLabel>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="coBorrower"
              checked={titulaire === 'coBorrower' ? true : undefined}
              onClick={() => {
                if (titulaire !== 'coBorrower') {
                  setTitulaire('coBorrower');
                } else {
                  setTitulaire(undefined);
                }
              }}
            />
            <StyledInputLabel>Co-emprunteur</StyledInputLabel>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="both"
              checked={titulaire === 'both' ? true : undefined}
              onClick={() => {
                if (titulaire !== 'both') {
                  setTitulaire('both');
                } else {
                  setTitulaire(undefined);
                }
              }}
            />
            <StyledInputLabel>Les deux</StyledInputLabel>
          </div>
        </div>
      </>
    );
  };
  return <>{renderTitulaireChoice()}</>;
};

export default TitulaireChoice;
