import GenericIconProps from './GenericIcon';

const ClockIcon2: React.FC<GenericIconProps> = ({ width, color }) => {
  const baseWidth = 20;
  const baseHeight = 20;
  const baseColor = '#1068D0';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;
  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>155FEDC7-3ED7-4540-BCF0-0693284F16BC</title>
      <g
        id="Consultation-dossier-📑"
        stroke="none"
        strokeWidth="1"
        fill={color || baseColor}
        fillRule="evenodd">
        <g
          id="Consultation-d'un-dossier/transferer-a-l'octroi/PJ/AG/1"
          transform="translate(-282.000000, -406.000000)"
          fill={color || baseColor}>
          <g id="statut-e-signature" transform="translate(264.000000, 388.000000)">
            <g id="Texte-icone" transform="translate(18.000000, 16.000000)">
              <g id="Icon/24px/Action/clock" transform="translate(0.000000, 2.000000)">
                <path
                  d="M10,-2.22044605e-16 C15.5228476,-2.22044605e-16 20,4.47715252 20,10 C20,15.5228476 15.5228476,20 10,20 C4.47715252,20 0,15.5228476 0,10 C0,4.47715252 4.47715252,-2.22044605e-16 10,-2.22044605e-16 Z M10,1.25000001 C5.16750846,1.25000001 1.25000001,5.16750846 1.25000001,10 C1.25000001,14.8324916 5.16750846,18.75 10,18.75 C14.8324916,18.75 18.75,14.8324916 18.75,10 C18.75,5.16750846 14.8324916,1.25000001 10,1.25000001 Z M9.37500004,3.75000002 C9.72017801,3.75000002 10,4.02982205 10,4.37500002 L10,10.8875 L14.0600001,13.2075001 C14.3506278,13.3828239 14.4482078,13.7580537 14.2798105,14.0527489 C14.1114133,14.3474441 13.7385978,14.4538764 13.4400001,14.2925001 L9.06500004,11.7925 C8.87027937,11.6812724 8.75007237,11.4742493 8.75000004,11.25 L8.75000004,4.37500002 C8.75000004,4.02982205 9.02982207,3.75000002 9.37500004,3.75000002 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ClockIcon2;
