import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { isSansGarantie } from 'containers/communs/utils';
import { useEffect, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import { CodeLibelle, StatusInput } from 'types';

interface FranchiseTimeLimitProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
  isSG: boolean;
  isDisabled?: boolean;
  checkInputs: boolean;
}

const FranchiseTimeLimit: React.FC<FranchiseTimeLimitProps> = ({
  state,
  dispatch,
  person,
  isSG,
  isDisabled,
  checkInputs,
}) => {
  const delayItems = [
    { code: '01', libelle: '30 jours' },
    { code: '02', libelle: '90 jours' },
  ];

  const [selectableDelayItems, setSelectableDelayItems] = useState<string>(
    JSON.stringify(delayItems),
  );

  const [validityStatus, setValidityStatus] = useState<StatusInput>();

  // En AG si perte d'emploi est coché, on ne propose que 90j de franchise
  useEffect(() => {
    const insurance =
      person === 'borrower' ? state.borrowerInsurance : state.coBorrowerInsurance;

    if (!isSG) {
      if (insurance.insuranceGracePeriod) {
        setValidityStatus({
          status: 'invalid',
          errorMessage: 'Le délai de franchise est obligatoire',
        });
      }

      dispatch({
        type: 'setInsuranceGracePeriod',
        payload: { person, value: undefined },
      });

      if (!insurance.jobLossInsuranceChecked) {
        setSelectableDelayItems(JSON.stringify(delayItems));
      } else {
        setSelectableDelayItems(`[${JSON.stringify(delayItems[1])}]`);
      }
    }
  }, [
    person === 'borrower'
      ? state.borrowerInsurance.jobLossInsuranceChecked
      : state.coBorrowerInsurance.jobLossInsuranceChecked,
  ]);

  const checkValidity = () => {
    const insurance =
      person === 'borrower' ? state.borrowerInsurance : state.coBorrowerInsurance;

    if (!insurance.insuranceGracePeriod) {
      setValidityStatus({
        status: 'invalid',
        errorMessage: 'Le délai de franchise est obligatoire',
      });
    } else {
      setValidityStatus({ status: 'valid', errorMessage: '' });
    }
  };

  useEffect(() => {
    if (isDisabled) {
      dispatch({
        type: 'setInsuranceGracePeriod',
        payload: { person, value: delayItems[1] },
      });
    }
  }, []);

  useEffect(() => {
    if (!isSansGarantie() && checkInputs) {
      checkValidity();
    }
  }, [checkInputs, state]);

  const onGracePeriodSelect = (e: Event) => {
    const selectedGracePeriod = (e as CustomEvent).detail.value as CodeLibelle;
    dispatch({
      type: 'setInsuranceGracePeriod',
      payload: { person, value: selectedGracePeriod },
    });
  };

  const onGracePeriodBlur = (event: Event) => {
    if (event && event.target && (event.target as unknown as ComboInputDS).value === '') {
      dispatch({
        type: 'setInsuranceGracePeriod',
        payload: { person, value: undefined },
      });
    }
  };

  /**
   * La valeur par défaut sélectionnée est 90 jours, si le délai de franchise est désactivée (donc pour un SG)
   * @returns
   */
  const getDefaultValue = () => {
    const insurance =
      person === 'borrower' ? state.borrowerInsurance : state.coBorrowerInsurance;

    if (isDisabled) {
      return delayItems[1].libelle;
    }
    return insurance.insuranceGracePeriod?.libelle || '';
  };

  return (
    <ComboInput
      disabled={isDisabled}
      required={!isSansGarantie()}
      list-on-open
      shadow
      align-items="left"
      items={selectableDelayItems}
      field="libelle"
      label="Délai de franchise"
      placeholder="0"
      onSelectedList={e => {
        onGracePeriodSelect(e);
      }}
      onListBlur={e => {
        onGracePeriodBlur(e);
        checkValidity();
      }}
      value={getDefaultValue()}
      {...(validityStatus?.status !== 'none' ? { status: validityStatus?.status } : {})}
      {...(validityStatus?.errorMessage !== ''
        ? { 'error-message': validityStatus?.errorMessage }
        : {})}
      className="bareme-input"
    />
  );
};

export default FranchiseTimeLimit;
