const RightArrow = (): React.ReactElement => {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
      <title>Ic/24px/Navigation/arrow-right-short24px</title>
      <path
        d="M6 12c0-.394.336-.714.75-.714h8.69L12.219 8.22a.691.691 0 0 1 0-1.012.778.778 0 0 1 1.062 0l4.5 4.285c.141.134.22.316.22.506s-.079.372-.22.506l-4.5 4.285a.778.778 0 0 1-1.062 0 .691.691 0 0 1 0-1.012l3.22-3.065H6.75c-.414 0-.75-.32-.75-.714Z"
        fill="#292C2E"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default RightArrow;
