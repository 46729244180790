import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  GuaranteeAdditionalData,
  GuaranteeAdditionalInformationAction,
  GuaranteeAdditionalInformationState,
} from 'reducers/guaranteeAdditionalInformation/type';
import { formatNumberByThousand } from 'utils/commun';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface AcquisitionValueProps {
  currentGuarantee?: GuaranteeAdditionalData;
  state: GuaranteeAdditionalInformationState;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
}

const AcquisitionValue: React.FC<AcquisitionValueProps> = ({
  currentGuarantee,
  state,
  dispatch,
}) => {
  const [acquisitionAmount, setAcquisitionAmount] = useState<string>(
    currentGuarantee?.acquisitionValue || '',
  );
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');

  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  useEffect(() => {
    if (acquisitionAmount === '0') {
      setIsInputValid(true);
      setInputError('');
    }
  }, [acquisitionAmount]);

  const handleOnBlur = (value: string) => {
    setIsInputFocused(false);
    const valWithoutSpace = value.replace(/ /g, '');
    if (!notEmpty.test(valWithoutSpace)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.REQUIRED_VALUE);
      const data = state.guarantees.map(item => {
        if (item.code === currentGuarantee?.code) {
          return { ...item, acquisitionValue: undefined };
        }
        return item;
      });
      dispatch({
        type: 'setUpdateGuaranteesInformation',
        payload: { data, guaranteeCode: currentGuarantee?.code },
      });
    } else if (!onlyNumbers.test(valWithoutSpace)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.ONLY_NUMBERS);
      const data = state.guarantees.map(item => {
        if (item.code === currentGuarantee?.code) {
          return { ...item, acquisitionValue: undefined };
        }
        return item;
      });
      dispatch({
        type: 'setUpdateGuaranteesInformation',
        payload: { data, guaranteeCode: currentGuarantee?.code },
      });
    } else {
      setIsInputValid(true);
      setInputError('');
      const data = state.guarantees.map(item => {
        if (item.code === currentGuarantee?.code) {
          return { ...item, acquisitionValue: valWithoutSpace };
        }
        return item;
      });
      dispatch({
        type: 'setUpdateGuaranteesInformation',
        payload: { data, guaranteeCode: currentGuarantee?.code },
      });
    }
  };

  useEffect(() => {
    if (notEmpty.test(currentGuarantee?.acquisitionValue || ''))
      handleOnBlur(currentGuarantee?.acquisitionValue || '');
  }, []);

  return (
    <CustomInput
      name="acquistionValue"
      label="Valeur d'acquisition"
      type="price"
      placeholder="0"
      value={formatNumberByThousand(acquisitionAmount || '')}
      onChange={newValue => {
        if (onlyNumbers.test(newValue.replaceAll(' ', '')))
          setAcquisitionAmount(newValue.replaceAll(' ', '').slice(0, 8));
        setIsInputFocused(true);
      }}
      isValid={isInputValid}
      isFocused={isInputFocused}
      onBlur={value => {
        handleOnBlur(value);
      }}
      onFocus={() => {
        setIsInputFocused(true);
      }}
      after="€"
      inputWidth="46rem"
      error={inputError}
    />
  );
};

export default AcquisitionValue;
