import * as React from 'react';

const NavigationLeft: React.FC = () => (
  <svg width={20} height={12} xmlns="http://www.w3.org/2000/svg">
    <title>3FA89DA7-F545-4DF3-B999-DE24DFA02307</title>
    <path
      d="M20 6c0-.368-.32-.667-.714-.667H2.44L6.935 1.14a.636.636 0 0 0 0-.944.752.752 0 0 0-1.011 0L.21 5.528A.645.645 0 0 0 0 6c0 .177.075.347.21.472l5.714 5.333c.279.26.732.26 1.011 0a.636.636 0 0 0 0-.944L2.44 6.667h16.846c.394 0 .714-.299.714-.667Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default NavigationLeft;
