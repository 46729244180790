import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import InformationArea from 'components/informationArea/InformationArea';
import Space from 'components/space/Space';
import parse from 'html-react-parser';
import { useEffect, useReducer, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import { CodeLibelle, InsuranceResult } from 'types';
import { isSansGarantie } from 'containers/communs/utils';
import {
  getOperationInitialState,
  operationReducer,
} from 'reducers/operationClient/operation';
import { ASSURANCE_LIST } from 'utils/constante';
import WarningIcon from 'icons/WarningIcon';
import {
  StyledAssuranceIndividuelleText,
  StyledErrorText,
  StyledLine,
  StyledRadioButton,
  StyledRadioButtonWrapper,
} from '../../style';
import * as messages from '../../messages';
import DeathInsurance from './DeathInsurance';
import PTIAInsurance from './PTIAInsurance';
import ITTInsurance from './ITTInsurance';
import JobLossInsurance from './JobLossInsurance';
import Quotite from './Quotite';
import MonthlyEstimatedBonus from './MonthlyEstimatedBonus';
import FranchiseTimeLimit from './FranchiseTimeLimit';

interface InsuranceChoiceProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
  getGroupInsuranceInformation: () => void;
  selectedInsurance?: CodeLibelle;
  setSelectedInsurance: (selectedInsurance?: CodeLibelle) => void;
  checkInputs: boolean;
  insuranceResponse?: InsuranceResult;
}

const InsuranceChoice: React.FC<InsuranceChoiceProps> = ({
  state,
  dispatch,
  person,
  getGroupInsuranceInformation,
  selectedInsurance,
  setSelectedInsurance,
  checkInputs,
  insuranceResponse,
}) => {
  const [isITTRequired, setIsITTRequired] = useState<boolean>(false);
  const [isPTIARequired, setIsPTIARequired] = useState<boolean>(false);
  const [checkedEmptyValue, setCheckedEmptyValue] = useState<boolean>(false);
  const [insuranceChoiceHasBeenClicked, setInsuranceChoiceHasBeenClicked] =
    useState<boolean>();
  const insuranceItems = ASSURANCE_LIST;

  const [operationsClientState] = useReducer(
    operationReducer,
    getOperationInitialState('operationsClient'),
  );

  const getInfoIsSansGarantie = () => {
    const infoFromSessionStorage = isSansGarantie();
    if (infoFromSessionStorage !== undefined) {
      return infoFromSessionStorage;
    }
    return !operationsClientState.hasInsurance;
  };

  const isSG = getInfoIsSansGarantie();

  // Chargement de l'assurance au moment du render
  useEffect(() => {
    if (!selectedInsurance) {
      const defaultInsurance =
        person === 'borrower'
          ? state.borrowerInsurance?.insurance
          : state.coBorrowerInsurance?.insurance;
      setSelectedInsurance(defaultInsurance);
    }
  }, []);

  // Gestion de l'affichage des messages d'erreur
  useEffect(() => {
    const insurance =
      person === 'borrower' ? state.borrowerInsurance : state.coBorrowerInsurance;

    const ptiaChecked = insurance.ptiaInsuranceChecked;
    const ittChecked = insurance.ittIptInsuranceChecked;
    const jobLossChecked = insurance.jobLossInsuranceChecked;

    // si l'assurance parente (PTIA) n'est pas cochée, et que l'utilisateur coche l'assurance ITT/IPT, on affiche une erreur
    if (!ptiaChecked && ittChecked) {
      setIsPTIARequired(true);
    } else {
      setIsPTIARequired(false);
    }

    // Si une case est cochée alors que sa quotité est de 0, on affiche une erreur
    // peut etre obsolete, je laisse parce que ceinture et bretelles
    if (insurance.ptiaInsuranceChecked && insurance.deathInsuranceRate === 0) {
      setCheckedEmptyValue(true);
    } else {
      setCheckedEmptyValue(false);
      if (insurance.ittIptInsuranceChecked && insurance.deathInsuranceRate === 0) {
        setCheckedEmptyValue(true);
      } else {
        setCheckedEmptyValue(false);
      }
    }

    // si l'assurance parente (ITT) n'est pas cochée, et que l'utilisateur coche l'assurance Perte Emploi, on affiche une erreur
    if (!ittChecked && jobLossChecked) {
      setIsITTRequired(true);
    } else {
      setIsITTRequired(false);
    }
  }, [state]);

  // Déclenche l'appel assurance pour GC et GP
  useEffect(() => {
    if (
      insuranceChoiceHasBeenClicked &&
      (selectedInsurance?.code === 'GC' || selectedInsurance?.code === 'GP')
    ) {
      getGroupInsuranceInformation();
    }
  }, [selectedInsurance]);

  const onCheckedChanged = (event: React.MouseEvent<HTMLInputElement>) => {
    insuranceItems.forEach(item => {
      const idOfCheckedChanged = (event.currentTarget as HTMLInputElement).id;
      if (item.code === idOfCheckedChanged) {
        setSelectedInsurance(item); // déclenche l'appel dans useEffect
        dispatch({
          type: 'setInsuranceType',
          payload: { person, value: item },
        });
      }
    });
    setInsuranceChoiceHasBeenClicked(true);
  };

  /**
   * Le input Garantie décés est grisé pour les cas suivants:
   * -Groupe Conseillée (AG et SG)
   * -Groupe Personnalisée (SG uniquement)
   * @returns boolean ou undefined
   */
  const isDeathInsuranceInputDisabled = () => {
    if (selectedInsurance?.code === 'GC' || isSG) {
      return true;
    }
    return undefined;
  };

  /**
   * L'assurance PTIA est désactivée si:
   * -on choisit Groupe Conseillée (AG ou SG) ou
   * -l'assurance décés n'est pas cochée
   * @returns
   */
  const isPTIADisabled = () => {
    // insurances are forbidden if they are not reccomended in the API response
    const isPTIAInsuranceForbidden =
      person === 'borrower'
        ? insuranceResponse?.borrower.assuranceEmprunteurPreconisee.garantiePTIA === 'N'
        : insuranceResponse?.coBorrower.assuranceEmprunteurPreconisee.garantiePTIA ===
          'N';

    if (selectedInsurance?.code === 'GC' || isPTIAInsuranceForbidden) {
      return true;
    }

    const isDeathInsuranceChecked =
      person === 'borrower'
        ? state.borrowerInsurance.deathInsuranceChecked
        : state.coBorrowerInsurance.deathInsuranceChecked;

    if (isDeathInsuranceChecked) {
      return undefined;
    }
    return true;
  };

  /**
   * L'assurance ITT est désactivée si :
   * -on choisit Groupe Conseillée (AG ou SG) ou
   * -l'assurance décés n'est pas cochée ou
   * -l'assurance ptia n'est pas cochée
   * @returns
   */
  const isITTDisabled = () => {
    // insurances are forbidden if they are not reccomended in the API response
    const isITTInsuranceForbidden =
      person === 'borrower'
        ? insuranceResponse?.borrower.assuranceEmprunteurPreconisee.garantieITT === 'N'
        : insuranceResponse?.coBorrower.assuranceEmprunteurPreconisee.garantieITT === 'N';

    if (selectedInsurance?.code === 'GC' || isITTInsuranceForbidden) {
      return true;
    }
    const isDeathInsuranceChecked =
      person === 'borrower'
        ? state.borrowerInsurance.deathInsuranceChecked
        : state.coBorrowerInsurance.deathInsuranceChecked;
    const isPTIAChecked =
      person === 'borrower'
        ? state.borrowerInsurance.ptiaInsuranceChecked
        : state.coBorrowerInsurance.ptiaInsuranceChecked;

    if (isDeathInsuranceChecked && (isPTIAChecked || isSG)) {
      return undefined;
    }
    return true;
  };

  /**
   * L'assurance perte emploi est désactivée si :
   * -on choisit Groupe Conseillée (AG ou SG) ou
   * -l'assurance décés n'est pas cochée ou
   * -l'assurance ptia n'est pas cochée ou
   * -l'assurance itt n'est pas cochée
   * @returns
   */
  const isJobLossDisabled = () => {
    // insurances are forbidden if they are not reccomended in the API response
    const isJobLossInsuranceForbidden =
      person === 'borrower'
        ? insuranceResponse?.borrower.assuranceEmprunteurPreconisee
            .garantiePerteEmploi === 'N'
        : insuranceResponse?.coBorrower.assuranceEmprunteurPreconisee
            .garantiePerteEmploi === 'N';

    if (selectedInsurance?.code === 'GC' || isJobLossInsuranceForbidden) {
      return true;
    }

    const isDeathInsuranceChecked =
      person === 'borrower'
        ? state.borrowerInsurance.deathInsuranceChecked
        : state.coBorrowerInsurance.deathInsuranceChecked;
    const isPTIAChecked =
      person === 'borrower'
        ? state.borrowerInsurance.ptiaInsuranceChecked
        : state.coBorrowerInsurance.ptiaInsuranceChecked;
    const isITTChecked =
      person === 'borrower'
        ? state.borrowerInsurance.ittIptInsuranceChecked
        : state.coBorrowerInsurance.ittIptInsuranceChecked;
    if (isITTChecked && isPTIAChecked && isDeathInsuranceChecked) {
      return undefined;
    }
    return true;
  };

  /**
   * Le délai de franchise est désactivé si on est SG (dans les 2 cas Groupe Conseillée et Groupe Personnalisée)
   */
  const isFranchiseLimitDisabled = () => {
    if (isSG) {
      return true;
    }
    return undefined;
  };

  const renderInsuranceItems = (startIndex: number, endIndex: number) => {
    const itemsToRender = insuranceItems.slice(startIndex, endIndex);
    return (
      <>
        {itemsToRender.map(item => {
          return (
            <StyledRadioButton key={item.code}>
              <BaseRadioButton
                id={item.code}
                onClick={onCheckedChanged}
                checked={selectedInsurance?.code === item.code ? true : undefined}
              />
              <span>{item.libelle}</span>
            </StyledRadioButton>
          );
        })}
      </>
    );
  };

  const renderErrorMessage = () => {
    if (isITTRequired) {
      return <StyledErrorText>{messages.ITT_REQUIRED}</StyledErrorText>;
    }
    if (isPTIARequired) {
      return <StyledErrorText>{messages.PTIA_REQUIRED}</StyledErrorText>;
    }
    if (checkedEmptyValue) {
      return <StyledErrorText>{messages.CHECKED_ZERO}</StyledErrorText>;
    }
    return null;
  };

  const renderInputsByInsuranceType = () => {
    switch (selectedInsurance?.code) {
      case 'GP':
      case 'GC':
        return (
          <>
            <div style={{ display: 'flex', gap: '5.5rem' }}>
              <DeathInsurance
                state={state}
                person={person}
                dispatch={dispatch}
                selectedInsurance={selectedInsurance?.code}
                isSG={isSG}
                isCheckboxDisabled
                isInputDisabled={isDeathInsuranceInputDisabled()}
              />
              <PTIAInsurance
                person={person}
                state={state}
                isSG={isSG}
                dispatch={dispatch}
                isDisabled={isPTIADisabled()}
              />
            </div>
            <Space marginBottom="2.4rem" />
            <div style={{ display: 'flex', gap: '5.6rem' }}>
              <ITTInsurance
                person={person}
                state={state}
                dispatch={dispatch}
                isSG={isSG}
                isDisabled={isITTDisabled()}
              />
              <JobLossInsurance
                person={person}
                state={state}
                dispatch={dispatch}
                isDisabled={isJobLossDisabled()}
              />
            </div>
            {renderErrorMessage()}
            <Space marginBottom="2.4rem" />
            <FranchiseTimeLimit
              person={person}
              state={state}
              dispatch={dispatch}
              isSG={isSG}
              isDisabled={isFranchiseLimitDisabled()}
              checkInputs={checkInputs}
            />
            <Space marginBottom="2.4rem" />
            <StyledAssuranceIndividuelleText>
              {parse(messages.ASSURANCE_INDIVIDUELLE_TEXT)}
            </StyledAssuranceIndividuelleText>
          </>
        );
      case 'I':
        return (
          <>
            <Quotite state={state} person={person} dispatch={dispatch} />
            <Space marginBottom="2.4rem" />
            <MonthlyEstimatedBonus state={state} person={person} dispatch={dispatch} />
            <Space marginBottom="2.4rem" />
            <StyledAssuranceIndividuelleText>
              {parse(messages.ASSURANCE_INDIVIDUELLE_TEXT)}
            </StyledAssuranceIndividuelleText>
          </>
        );
      case 'S':
        return (
          <InformationArea
            icon={<WarningIcon color="#2275D7" />}
            backgroundColor="blue"
            width="46.4rem"
            height="21.2rem">
            <p style={{ margin: '0', fontSize: '1.4rem' }}>
              {parse(messages.NON_ASSURANCE_TEXT)}
            </p>
          </InformationArea>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '2.4rem', flexDirection: 'column' }}>
        <StyledRadioButtonWrapper style={{ gap: '8.3rem' }}>
          {renderInsuranceItems(0, 2)}
        </StyledRadioButtonWrapper>
        <StyledRadioButtonWrapper style={{ gap: '5.3rem' }}>
          {renderInsuranceItems(2, 4)}
        </StyledRadioButtonWrapper>
      </div>
      <Space marginBottom="1.6rem" />
      <StyledLine />
      <Space marginBottom="4rem" />
      {renderInputsByInsuranceType()}
    </>
  );
};

export default InsuranceChoice;
