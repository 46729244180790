import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { formatNumberByThousand } from 'utils/commun';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface IndemnityProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const Indemnity: React.FC<IndemnityProps> = ({ currentLoan, state, dispatch }) => {
  const [indemnityAmount, setIndemnityAmount] = useState<string>(
    currentLoan?.indemnityAmount?.toString() || '',
  );
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');

  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const updateState = (val?: number) => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, indemnityAmount: val };
      }
      return item;
    });

    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  const handleOnChange = (value: string) => {
    if (!onlyNumbers.test(value)) return;

    setIndemnityAmount(value.slice(0, 8));

    updateState(
      Number.isNaN(Number(value)) || value === '' ? undefined : parseInt(value, 10),
    );
  };

  const handleOnBlur = (value: string) => {
    setIsInputFocused(false);
    const valWithoutSpace = value.replace(/ /g, '');

    if (!notEmpty.test(valWithoutSpace)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.REQUIRED_VALUE);
      updateState(undefined);
    } else if (!onlyNumbers.test(valWithoutSpace)) {
      setIsInputValid(false);
      setInputError(messagesCheckInput.ONLY_NUMBERS);
      updateState(undefined);
    } else {
      setIsInputValid(true);
      setInputError('');
    }
  };

  useEffect(() => {
    if (currentLoan.indemnityAmount) {
      if (notEmpty.test(currentLoan.indemnityAmount.toString()))
        handleOnBlur(currentLoan.indemnityAmount.toString());
    }
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      <div style={{ marginRight: '1.8rem' }}>
        <CustomInput
          name="indemnity"
          label="Indemnités de Remboursement par Anticipation"
          type="price"
          placeholder="0"
          value={formatNumberByThousand(indemnityAmount || '')}
          onChange={newValue => {
            handleOnChange(newValue.replaceAll(' ', '').slice(0, 8));
            setIsInputFocused(true);
          }}
          isValid={isInputValid}
          isFocused={isInputFocused}
          onBlur={value => {
            handleOnBlur(value);
          }}
          onFocus={() => {
            setIsInputFocused(true);
          }}
          after="€"
          inputWidth="46.4rem"
          error={inputError}
        />
      </div>
    </div>
  );
};

export default Indemnity;
