import { hasCoBorrower, isEtudePartagee, isSansGarantie } from 'containers/communs/utils';
import { SessionStorageKeys } from 'types';
import { isUndefinedOrNull } from 'utils/commun';
import { getExistingStorageByKey } from 'utils/storage';
import { ValidationRules } from 'utils/InputValidation';
import { FinalisationProjetAction, FinalisationProjetState } from './types';

const notEmpty = new RegExp(ValidationRules.notEmpty);
const onlyNumbersAndAlphabeticChars = new RegExp(
  ValidationRules.onlyNumbersAndAlphabeticChars,
);

export const getFinalisationProjetInitialState = (
  storageKey: SessionStorageKeys,
): FinalisationProjetState => {
  const finalisationProjetDefaultValues = {
    packContractuel: true,
    editionType: 'e-signature',
    borrower: {
      identityType: undefined,
      identityNumber: undefined,
      address: {},
      email: undefined,
      phoneNumbers: [{ code: '1', libelle: '' }],
    },
    coBorrower: {
      identityType: undefined,
      identityNumber: undefined,
      address: {},
      email: undefined,
      phoneNumbers: [{ code: '1', libelle: '' }],
    },
    employerName: undefined,
    invalidInputs: [],
    isDataOk: false,
  };
  return getExistingStorageByKey(
    storageKey,
    finalisationProjetDefaultValues,
  ) as FinalisationProjetState;
};

const checkCellPhone = (
  state: FinalisationProjetState,
  person: 'borrower' | 'coBorrower',
): boolean => {
  const cellPhoneNumber =
    person === 'borrower'
      ? state.borrower.cellPhoneNumber
      : state.coBorrower.cellPhoneNumber;
  if (!cellPhoneNumber) return false;
  if (state.borrower.cellPhoneNumber === state.coBorrower.cellPhoneNumber) return false;

  return true;
};

const checkPhones = (state: FinalisationProjetState): boolean => {
  if (state.editionType === 'papier' || !isSansGarantie()) {
    if (!checkCellPhone(state, 'borrower') && !state.borrower.homePhoneNumber) {
      return false;
    }
  } else if (!checkCellPhone(state, 'borrower')) {
    return false;
  }
  return true;
};

const checkIdNumber = (state: FinalisationProjetState, type: string): boolean => {
  const person = type === 'borrower' ? state.borrower : state.coBorrower;
  if (
    person.identityType?.code !== 'A' &&
    (!notEmpty.test(person.identityNumber || '') ||
      !onlyNumbersAndAlphabeticChars.test(person.identityNumber || ''))
  ) {
    return false;
  }

  if (person.identityType?.code === 'I' && person.identityNumber?.length !== 12) {
    return false;
  }
  if (
    (person.identityType?.code === 'N' || person.identityType?.code === 'P') &&
    person.identityNumber?.length !== 9
  ) {
    return false;
  }
  if (
    (person.identityType?.code === 'S' || person.identityType?.code === 'T') &&
    person.identityNumber?.length !== 9 &&
    person.identityNumber?.length !== 10
  ) {
    return false;
  }
  if (
    person.identityType?.code === 'E' &&
    person.identityNumber &&
    person.identityNumber.length > 12
  ) {
    return false;
  }

  return true;
};

const checkEmployer = (state: FinalisationProjetState, type: string): boolean => {
  const person = type === 'borrower' ? state.borrower : state.coBorrower;
  if (person.activeProfession && !person.employerName) {
    return false;
  }
  return true;
};

/**
 * Vérifie les données complémentaires de l'emprunteur
 * @param state
 * @returns
 */
export const checkBorrowerIdentityData = (state: FinalisationProjetState): boolean => {
  if (!state.borrower.identityType) {
    return false;
  }

  if (!checkIdNumber(state, 'borrower')) {
    return false;
  }
  if (!state.borrower.address.isAddressOk) {
    return false;
  }

  if (state.editionType === 'e-signature' && !state.borrower.email) {
    return false;
  }
  if (!checkPhones(state)) return false;

  return true;
};

/**
 * Vérifie les données complémentaires du co-emprunteur
 * @param state
 * @returns
 */
export const checkCoBorrowerIdentityData = (state: FinalisationProjetState): boolean => {
  if (!state.coBorrower.identityType) {
    return false;
  }
  if (!checkIdNumber(state, 'coBorrower')) {
    return false;
  }
  if (state.editionType === 'e-signature' && !state.coBorrower.email) {
    return false;
  }
  if (state.editionType === 'e-signature' && !checkCellPhone(state, 'coBorrower'))
    return false;

  return true;
};

const checkIfDatasAreOk = (state: FinalisationProjetState): boolean => {
  if (state.invalidInputs.length > 0) {
    return false;
  }
  if (isSansGarantie()) {
    if (isUndefinedOrNull(state.packContractuel) && isEtudePartagee()) {
      return false;
    }
    if (isUndefinedOrNull(state.editionType)) return false;
  }

  if (!checkBorrowerIdentityData(state) || !checkEmployer(state, 'borrower')) {
    return false;
  }

  if (
    hasCoBorrower() &&
    (!checkCoBorrowerIdentityData(state) || !checkEmployer(state, 'coBorrower'))
  ) {
    return false;
  }

  return true;
};

export const finalisationProjetReducer = (
  state: FinalisationProjetState,
  action: FinalisationProjetAction,
): FinalisationProjetState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setPackContractuel':
      newState.packContractuel = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setEditionType':
      newState.editionType = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setIdentityType':
      if (action.payload.person === 'borrower') {
        newState.borrower.identityType = action.payload.value;
      } else {
        newState.coBorrower.identityType = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setIdentityNumber':
      if (action.payload.person === 'borrower') {
        newState.borrower.identityNumber = action.payload.value;
      } else {
        newState.coBorrower.identityNumber = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setAddress':
      if (action.payload.person === 'borrower') {
        newState.borrower.address = action.payload.value;
      } else {
        newState.coBorrower.address = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setEmail':
      if (action.payload.person === 'borrower') {
        newState.borrower.email = action.payload.value;
      } else {
        newState.coBorrower.email = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setCellPhoneNumber':
      if (action.payload.person === 'borrower') {
        newState.borrower.cellPhoneNumber = action.payload.value;
      } else {
        newState.coBorrower.cellPhoneNumber = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHomePhoneNumber':
      if (action.payload.person === 'borrower') {
        newState.borrower.homePhoneNumber = action.payload.value;
      } else {
        newState.coBorrower.homePhoneNumber = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setEmployerName':
      if (action.payload.person === 'borrower') {
        newState.borrower.employerName = action.payload.value;
      } else {
        newState.coBorrower.employerName = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setActiveProfession':
      if (action.payload.person === 'borrower') {
        newState.borrower.activeProfession = action.payload.value;
      } else {
        newState.coBorrower.activeProfession = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    default:
      return state;
  }
};
