import { useLocation } from 'react-router-dom';
import ArrowLeftCircleIcon from 'icons/ArrowLeftCircleIcon';
import StyledBreadcrumbText from './style';
import * as messages from './messages';

interface NavBackProps {
  navigate: (route: string | number) => void;
}

const NavBack: React.FC<NavBackProps> = ({ navigate }) => {
  const location = useLocation();
  return (
    <div
      style={{ display: 'flex', gap: '1rem', cursor: 'pointer' }}
      onKeyPress={() => {
        navigate(location.state?.prevPath || '/dashboard');
      }}
      onClick={() => {
        navigate(location.state?.prevPath || '/dashboard');
      }}
      role="button"
      tabIndex={0}>
      <ArrowLeftCircleIcon color="#4b4f54" />
      <StyledBreadcrumbText>
        {location.state?.prevPath
          ? messages.NAV_BACK_RECHERCHE
          : messages.NAV_BACK_ACCUEIL}
      </StyledBreadcrumbText>
    </div>
  );
};

export default NavBack;
