const CloudArrowIcon: React.FC = () => {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
      <title>Ic/24px/Action/cloud-arrow-up24px</title>
      <path
        d="M12 5c3.363 0 6.154 2.545 6.457 5.828 1.99.286 3.543 1.983 3.543 4.065C22 17.179 20.128 19 17.859 19H6.726C4.135 19 2 16.92 2 14.314c0-2.244 1.582-4.102 3.677-4.573.18-1.098.873-2.193 1.83-3.033A6.84 6.84 0 0 1 12 5Zm0 1.273a5.604 5.604 0 0 0-3.676 1.4V7.67c-.947.832-1.442 1.833-1.442 2.617v.57l-.556.063c-1.746.194-3.076 1.654-3.076 3.393 0 1.867 1.537 3.413 3.476 3.413H17.86c1.616 0 2.891-1.288 2.891-2.834 0-1.548-1.275-2.836-2.891-2.836h-.625v-.636C17.235 8.595 14.91 6.273 12 6.273Zm0 2.544a.62.62 0 0 1 .443.187l2.5 2.545c.158.161.22.396.161.616a.632.632 0 0 1-.442.45.618.618 0 0 1-.605-.164l-1.432-1.46v4.827a.63.63 0 0 1-.625.637.63.63 0 0 1-.625-.637v-4.827l-1.433 1.46a.618.618 0 0 1-.885 0 .645.645 0 0 1 0-.902l2.5-2.545A.62.62 0 0 1 12 8.817Z"
        fill="#3a913f"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CloudArrowIcon;
