import { CodeLibelle } from 'types';
import Space from 'components/space/Space';
import {
  ResultCard,
  ResultCardEntry,
  ResultCardText,
  ResultCartContainer,
  ResultCartTitle,
  StyledDottedLine,
  StyledFullBar,
} from '../style';
import * as messages from '../messages';

export interface FinancingFormProps {
  view: CodeLibelle[];
}

const FinancingForm: React.FC<FinancingFormProps> = ({ view }) => {
  const renderFinancingContent = (items: CodeLibelle[]) => {
    return items.map((item, index) => {
      if (index === items.length - 2) {
        return (
          <div key={item.libelle}>
            <ResultCardEntry>
              <ResultCardText>{item.libelle}</ResultCardText>
              <StyledDottedLine />
              <ResultCardText>{item.code}</ResultCardText>
            </ResultCardEntry>
            <Space marginBottom="1.55rem" />
            <StyledFullBar />
            <Space marginBottom="1.55rem" />
            <ResultCardEntry>
              <ResultCardText>{items.at(items.length - 1)?.libelle}</ResultCardText>
              <StyledDottedLine />
              <ResultCardText>{items.at(items.length - 1)?.code}</ResultCardText>
            </ResultCardEntry>
          </div>
        );
      }
      if (index !== items.length - 1) {
        return (
          <ResultCardEntry key={item.libelle}>
            <ResultCardText>{item.libelle}</ResultCardText>
            <StyledDottedLine />
            <ResultCardText>{item.code}</ResultCardText>
          </ResultCardEntry>
        );
      }
      return null;
    });
  };

  return (
    <>
      <ResultCard>
        <ResultCartTitle>{messages.FINANCING_CARD_TITLE}</ResultCartTitle>

        <Space marginBottom="2.4rem" />

        <ResultCartContainer>{renderFinancingContent(view)}</ResultCartContainer>
      </ResultCard>
    </>
  );
};

export default FinancingForm;
