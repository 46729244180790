import colors from 'styles/color';
import { ToggleWrapper, ToggleCheckIcon } from './style';

interface ToggleButtonProps {
  isOn: boolean;
  id: string;
  handleToggle: () => void;
  children?: React.ReactNode;
  width?: string;
  isDisabled?: boolean;
}
const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  isOn,
  handleToggle,
  id,
  width,
  isDisabled,
}) => {
  const getBackgroundColor = () => {
    if (isDisabled) {
      return colors.chartColors.neutral300;
    }
    if (isOn) {
      return colors.chartColors.mainGreen500;
    }
    return '';
  };
  return (
    <div>
      <label htmlFor={id} style={{ display: 'flex', width }}>
        {children}
        <div
          role="button"
          tabIndex={0}
          onKeyDownCapture={e => {
            if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
              e.preventDefault();
              handleToggle();
            }
          }}>
          <ToggleWrapper
            style={{
              background: getBackgroundColor(),
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}>
            <input
              tabIndex={0}
              checked={isOn}
              onChange={handleToggle}
              className="toggle-checkbox"
              id={id}
              type="checkbox"
            />
            <div className="toggle-button">
              {isOn ? (
                <ToggleCheckIcon
                  color={isDisabled ? colors.chartColors.neutral300 : ''}
                />
              ) : null}
            </div>
          </ToggleWrapper>
        </div>
      </label>
    </div>
  );
};

ToggleButton.defaultProps = {
  width: 'fit-content',
};

export default ToggleButton;
