import { formatDateFr } from 'utils/DateUtils';
import jsPDF from 'jspdf';
import { IdentityState } from 'reducers/identity/types';
import { PiecesJustificatives, UserInfos } from 'types';
import * as messages from '../messages';

const piecesJustifPdf = (): File => {
  const userInfos = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  const borrowerIdentity = JSON.parse(
    sessionStorage.getItem('borrowerIdentity') || '{}',
  ) as IdentityState;

  const coBorrowerIdentity = JSON.parse(
    sessionStorage.getItem('coBorrowerIdentity') || '{}',
  ) as IdentityState;

  const piecesJustif = JSON.parse(
    sessionStorage.getItem('piecesJustificatives') || '{}',
  ) as PiecesJustificatives;

  const img = new Image();
  img.src = `${process.env.PUBLIC_URL}/logo_sygma.jpg`;

  const pieces: { piece: string; statut: string }[] = [];

  piecesJustif.listePiecesJustificativesPropCialUploadable.listeChemises.forEach(
    chemise =>
      chemise.listePiecesJustificatives.forEach(item => {
        const pieceName = `${item.pceJusLib[0]}${item.pceJusLib
          .substring(1)
          .toLowerCase()} ${item.cpltPceJusLib}`;
        const piecesTemp = {
          piece: pieceName,
          statut: item.statPceJusLib,
        };
        pieces.push(piecesTemp);
      }),
  );

  piecesJustif.listePiecesJustificativesPropCialNonUploadables.forEach(item => {
    const piecesTemp = { piece: item.pceJusLib, statut: item.statPceJusLib };
    pieces.push(piecesTemp);
  });

  let hauteur = 25;
  const INTERLIGNE = 10;
  const COLONNE_1 = 15;
  const COLONNE_2 = 50;
  const COLONNE_RIGHT = 180;
  const COLONNE_TITLE = 100;
  const COLONNE_DATE = 170;

  // eslint-disable-next-line new-cap
  const doc = new jsPDF();

  // Titre page PDF
  doc.setFontSize(20);
  doc.setFont('Helvetica', 'bold');
  doc.text(`${messages.PIECES_JUSTIF.mainTitle}`, COLONNE_TITLE, hauteur, {
    maxWidth: 160,
    align: 'center',
  });
  hauteur += INTERLIGNE;
  doc.setFontSize(12);
  doc.setFont('Helvetica', 'normal');
  doc.text(`${messages.PIECES_JUSTIF.subTitle}`, COLONNE_TITLE, hauteur, {
    maxWidth: 160,
    align: 'center',
  });

  // Date du PDF
  doc.setFontSize(10);
  doc.setFont('Helvetica', 'italic');
  doc.text(
    `${messages.TRANSMI_SHEET.editedDate} ${formatDateFr(new Date())}`,
    COLONNE_DATE,
    hauteur,
  );

  // Création premier rectangle d'infos
  doc.rect(10, 50, 190, 35);

  hauteur += 25;
  // Contenu premier rectangle
  doc.setFontSize(14);
  doc.setFont('Helvetica', 'italic');
  doc.text(`${messages.COMMON_PDF.file}`, COLONNE_1, hauteur);
  const textWidth = doc.getTextWidth(`${messages.COMMON_PDF.file}`);
  doc.line(15, hauteur + 1, 15 + textWidth, hauteur + 1);

  hauteur += 6;
  doc.setFontSize(12);
  doc.setFont('Helvetica', 'normal');
  doc.text(`${sessionStorage.getItem('numeroProjet')}`, COLONNE_2, hauteur);
  hauteur += 6;
  doc.text(
    `${borrowerIdentity.civility} ${borrowerIdentity.lastName} ${borrowerIdentity.firstName}`,
    COLONNE_2,
    hauteur,
  );
  if (coBorrowerIdentity.civility !== undefined) {
    hauteur += 6;
    doc.text(
      `${coBorrowerIdentity.civility} ${coBorrowerIdentity.lastName} ${coBorrowerIdentity.firstName}`,
      COLONNE_2,
      hauteur,
    );
    hauteur -= 6;
  }

  hauteur += 28;
  doc.setFontSize(14);
  doc.setFont('Helvetica', 'italic');
  doc.text(`${messages.PIECES_JUSTIF.libelle}`, COLONNE_1, hauteur);
  doc.text(`${messages.PIECES_JUSTIF.statut}`, COLONNE_RIGHT, hauteur);
  console.log(hauteur);
  doc.line(15, hauteur + 3, COLONNE_1 + 185, hauteur + 3);

  if (pieces.length > 0) {
    hauteur += INTERLIGNE;
    doc.setFontSize(10);
    doc.setFont('Helvetica', 'normal');
    for (let i = 0; i < pieces.length; i += 1, hauteur += INTERLIGNE) {
      if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 1.5) {
        hauteur = 25;
        doc.addPage('a4', 'p');
      }
      doc.text(pieces[i].piece, COLONNE_1, hauteur);
      doc.setFontSize(11);
      doc.text(pieces[i].statut, COLONNE_RIGHT, hauteur);
    }
  }

  doc.setFontSize(10);
  doc.text(`${messages.TRANSMI_SHEET.legal_mention_1}`, 50, 275);
  doc.text(`${messages.TRANSMI_SHEET.legal_mention_2}`, 35, 280);
  doc.text(`${messages.TRANSMI_SHEET.legal_mention_3}`, 60, 285);

  doc.save(
    `${messages.PIECES_JUSTIF.dlName}${userInfos.prenomTiersAPA}-${
      userInfos.nomTiersAPA
    }_${sessionStorage.getItem('numeroProjet')}.pdf`,
  );

  doc.output('dataurlnewwindow');

  return new File(
    [doc.output('blob')],
    `${messages.PIECES_JUSTIF.dlName}${userInfos.prenomTiersAPA}-${
      userInfos.nomTiersAPA
    }_${sessionStorage.getItem('numeroProjet')}.pdf`,
  );
};

export default piecesJustifPdf;
