import { useRef } from 'react';
import SvgComponent from 'icons/Calendar';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { CSSObject } from 'styled-components';
import colors from 'styles/color';
import BaseButton from 'components/designSystem/BaseButton';
import { StledButtonWrapper, StyledBorder, StyledDateEnd } from './style';

registerLocale('fr', fr);

interface CommissionProps {
  startDate: Date;
  endDate: Date;
  isBtnDisabled: boolean;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  onFindBordereauClick: () => void;
  onResetClick: () => void;
  onGoHomeClick: () => void;
  onDateFilterChange: (filter: string) => void;
}

const Commission: React.FC<CommissionProps> = ({
  startDate,
  endDate,
  isBtnDisabled,
  onStartDateChange,
  onEndDateChange,
  onFindBordereauClick,
  onResetClick,
  onGoHomeClick,
  onDateFilterChange,
}) => {
  // eslint-disable-next-line
  const minDateRef: any = useRef();
  // eslint-disable-next-line
  const maxDateRef: any = useRef();
  const minDateValue = new Date(`01-01-${new Date().getFullYear() - 1}`); // la date minimale pour le input date est le 1er janvier de l'année précédente
  const maxDateValue = new Date(); // la date maximale pour le input date est la date du jour

  const dateTextStyle: CSSObject = {
    cursor: 'pointer',
    color: '#70AD47',
    fontWeight: 'bold',
    backgroundColor: colors.chartColors.neutral0,
    border: 'none',
  };
  const dateTextBorderStyle: CSSObject = {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    display: 'flex',
  };
  const calendarIconStyle: CSSObject = {
    width: '5rem',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: '3rem',
          marginTop: '5rem',
          height: '4rem',
        }}>
        <StyledBorder style={dateTextBorderStyle}>
          <button
            type="button"
            style={dateTextStyle}
            onClick={() => {
              minDateRef?.current?.setFocus(true);
            }}
            onKeyPress={() => {
              minDateRef?.current?.setFocus(true);
            }}>
            Du
          </button>
        </StyledBorder>
        <DatePicker
          data-testid="startDate"
          style={{ width: '5.0rem' }}
          locale="fr"
          dateFormat="MM/yyyy"
          showMonthYearPicker
          selected={startDate}
          onChange={(date: Date) => {
            onStartDateChange(date);
          }}
          minDate={minDateValue}
          maxDate={maxDateValue}
          ref={minDateRef}
        />
        <StyledBorder
          style={calendarIconStyle}
          onClick={() => {
            minDateRef?.current?.setFocus(true);
          }}
          onKeyPress={() => {
            minDateRef?.current?.setFocus(true);
          }}>
          <SvgComponent />
        </StyledBorder>
        <StyledDateEnd>
          <StyledBorder style={dateTextBorderStyle}>
            <button
              type="button"
              style={dateTextStyle}
              onClick={() => {
                maxDateRef?.current?.setFocus(true);
              }}
              onKeyPress={() => {
                maxDateRef?.current?.setFocus(true);
              }}>
              Au
            </button>
          </StyledBorder>
          <DatePicker
            data-testid="endDate"
            style={{ width: '5.0rem' }}
            locale="fr"
            dateFormat="MM/yyyy"
            showMonthYearPicker
            selected={endDate}
            onChange={(date: Date) => {
              onEndDateChange(date);
            }}
            minDate={minDateValue}
            maxDate={maxDateValue}
            ref={maxDateRef}
          />
          <StyledBorder
            style={calendarIconStyle}
            onClick={() => {
              maxDateRef?.current?.setFocus(true);
            }}
            onKeyPress={() => {
              maxDateRef?.current?.setFocus(true);
            }}>
            <SvgComponent />
          </StyledBorder>
        </StyledDateEnd>
        <StledButtonWrapper>
          <select
            id="date"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              onDateFilterChange(e.target.value);
            }}
            defaultValue="default">
            <option value="default">Préselection Dates</option>
            <option value="lastYear">Année précédente</option>
            <option value="lastMonth">Mois précédent</option>
          </select>
          <BaseButton
            onClick={onFindBordereauClick}
            disabled={isBtnDisabled || undefined}
            class="recherche_bordereau_btn commission_btn">
            Rechercher les bordereaux
          </BaseButton>
          <BaseButton
            onClick={onResetClick}
            disabled={isBtnDisabled || undefined}
            secondary
            class="commission_btn">
            Réinitialiser
          </BaseButton>
          <BaseButton
            onClick={onGoHomeClick}
            disabled={isBtnDisabled || undefined}
            secondary
            class="commission_btn">
            Accueil
          </BaseButton>
        </StledButtonWrapper>
      </div>
    </>
  );
};

export default Commission;
