import parse from 'html-react-parser';
import CustomInput from 'components/customInput/CustomInput';
import { addInInvalidInputs, removeFromInvalidInputs } from 'containers/communs/utils';
import { useEffect, useState } from 'react';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import checkPreviousInputValidation from 'utils/commun';
import { isEmailValid } from 'utils/InputValidation';

interface EmailProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  person: 'borrower' | 'coBorrower';
  checkInputs: boolean;
}

const Email: React.FC<EmailProps> = ({ state, dispatch, person, checkInputs }) => {
  const [isEmailInputFocused, setIsEmailInputFocused] = useState<boolean>(false);
  const [isEmailInputValid, setIsEmailInputValid] = useState<boolean | undefined>(
    checkPreviousInputValidation(
      'finalisationProjet',
      person === 'borrower' ? state.borrower.email : state.coBorrower.email,
    ),
  );
  const [emailInputError, setEmailInputError] = useState<string>('');

  const handleEmailChange = (value: string) => {
    if (!value) {
      dispatch({
        type: 'setEmail',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setEmail',
        payload: { person, value },
      });
    }
  };

  const cleanInput = () => {
    setEmailInputError('');
    removeFromInvalidInputs(
      state,
      dispatch,
      person === 'borrower' ? 'emailBorrower' : 'emailCoBorrower',
    );
  };

  const handleEmailBlur = () => {
    setIsEmailInputFocused(false);
    const email = person === 'borrower' ? state.borrower.email : state.coBorrower.email;
    if (state.editionType !== 'e-signature' && !email) {
      setIsEmailInputValid(undefined);
      cleanInput();
      return;
    }
    if (email && isEmailValid(email)) {
      setIsEmailInputValid(true);
      cleanInput();
    } else {
      setIsEmailInputValid(false);
      setEmailInputError('Adresse e-mail incorrecte.');
      addInInvalidInputs(
        state,
        dispatch,
        person === 'borrower' ? 'emailBorrower' : 'emailCoBorrower',
      );
    }
  };

  // put label on state && refaire check validity
  const renderLabel = () => {
    if (state.editionType === 'e-signature') {
      return 'Adresse e-mail';
    }
    return 'Adresse e-mail <span className="lightText">(Facultatif)</span>';
  };

  useEffect(() => {
    if (state.editionType !== 'e-signature') {
      cleanInput();
    }
  }, []);

  useEffect(() => {
    if (checkInputs && state.editionType) {
      handleEmailBlur();
    }
  }, [checkInputs, state.editionType]);

  return (
    <CustomInput
      name={`${person}-email`}
      label={parse(renderLabel())}
      value={person === 'borrower' ? state.borrower.email : state.coBorrower.email}
      onChange={newValue => {
        handleEmailChange(newValue);
        setIsEmailInputFocused(true);
      }}
      isValid={isEmailInputValid}
      isFocused={isEmailInputFocused}
      onBlur={handleEmailBlur}
      onFocus={() => {
        setIsEmailInputFocused(true);
      }}
      inputWidth="64.8rem"
      placeholder="exemple@exemple.com"
      error={emailInputError}
    />
  );
};

export default Email;
