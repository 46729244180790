import AddressBlock from 'components/addressBlock/AddressBlock';
import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import { useEffect } from 'react';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import { SituationFoyerState } from 'reducers/situationFoyer/types';
import { FullAddress } from 'types';
import * as messages from '../messages';
import Email from './contact/Email';
import PhoneNumber from './contact/PhoneNumber';
import IdentityNumber from './identity/IdentityNumber';
import IdentityType from './identity/IdentityType';

interface BorrowerIdentityProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  checkInputs: boolean;
}
const BorrowerIdentity: React.FC<BorrowerIdentityProps> = ({
  state,
  dispatch,
  checkInputs,
}) => {
  const onAddressChange = (newAddress: FullAddress) => {
    dispatch({
      type: 'setAddress',
      payload: { person: 'borrower', value: newAddress },
    });
  };

  useEffect(() => {
    const situationFoyerFromStorage = sessionStorage.getItem('situationFoyer');
    if (situationFoyerFromStorage) {
      const borrowerIdentity = JSON.parse(
        situationFoyerFromStorage,
      ) as SituationFoyerState;
      if (!state.borrower.address.zipCode || !state.borrower.address.city) {
        const newAddress = { ...state?.borrower?.address };
        newAddress.zipCode = borrowerIdentity.addressZipCode;
        newAddress.city = borrowerIdentity.addressCity?.libelle;
        onAddressChange(newAddress);
      }
    }
  }, []);

  return (
    <>
      <StyledTitle>{messages.IDENTITY_TEXT}</StyledTitle>
      <Space marginTop="1.6rem" />
      <IdentityType
        state={state}
        dispatch={dispatch}
        person="borrower"
        checkInputs={checkInputs}
      />
      <Space marginTop="2.4rem" />
      <IdentityNumber
        state={state}
        dispatch={dispatch}
        person="borrower"
        checkInputs={checkInputs}
      />
      <Space marginTop="5.6rem" />

      <StyledTitle>{messages.ADDRESS_TITLE}</StyledTitle>
      <Space marginTop="1.6rem" />
      <AddressBlock
        address={state.borrower.address}
        setAddress={onAddressChange}
        alternateCityInput
        lockedInputs={['zipCode', 'city']}
        checkInputs={checkInputs}
      />

      <Space marginTop="5.6rem" />
      <StyledTitle>{messages.CONTACT_TITLE}</StyledTitle>
      <Space marginTop="1.6rem" />
      <Email
        state={state}
        dispatch={dispatch}
        person="borrower"
        checkInputs={checkInputs}
      />
      <Space marginTop="2.4rem" />
      <PhoneNumber
        state={state}
        dispatch={dispatch}
        person="borrower"
        checkInputs={checkInputs}
      />
    </>
  );
};

export default BorrowerIdentity;
