import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import { OperationState } from 'reducers/operationClient/types';
import {
  RessourcesMensuellesAction,
  RessourcesMensuellesState,
} from 'reducers/ressourcesMensuelles/types';
import { HousingStatus, SituationFoyerState } from 'reducers/situationFoyer/types';
import { Profession, CodeLibelle, UserInfos } from 'types';
import { getExistingStorageByKey } from 'utils/storage';

type StateToValidate =
  | ConditionsFinancieresState
  | FinalisationProjetState
  | RessourcesMensuellesState;
type ActionsForValidation = React.Dispatch<
  ConditionsFinancieresAction | FinalisationProjetAction | RessourcesMensuellesAction
>;
export const addInInvalidInputs = (
  state: StateToValidate,
  dispatch: ActionsForValidation,
  inputName: string,
): void => {
  const invalidInputs = state.invalidInputs?.length > 0 ? [...state.invalidInputs] : [];
  const index = invalidInputs.indexOf(inputName);
  if (index < 0) {
    dispatch({
      type: 'setInvalidInputs',
      payload: [...invalidInputs, inputName],
    });
  }
};

export const removeFromInvalidInputs = (
  state: ConditionsFinancieresState | FinalisationProjetState | RessourcesMensuellesState,
  dispatch: React.Dispatch<
    ConditionsFinancieresAction | FinalisationProjetAction | RessourcesMensuellesAction
  >,
  inputName: string,
): void => {
  if (!state.invalidInputs || state.invalidInputs?.length === 0) {
    return;
  }
  const invalidInputs = [...state.invalidInputs];
  const index = invalidInputs.indexOf(inputName);
  if (index < 0) {
    return;
  }
  invalidInputs.splice(index, 1);
  dispatch({
    type: 'setInvalidInputs',
    payload: [...invalidInputs],
  });
};

export const isSansGarantie = (): boolean | undefined => {
  const operationsClientFromSessionStorage = sessionStorage.getItem('operationsClient');
  if (operationsClientFromSessionStorage) {
    try {
      const result = JSON.parse(operationsClientFromSessionStorage) as OperationState;
      if (result) {
        return !result.hasInsurance;
      }
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};

export const isEtudePartagee = (): boolean => {
  const authenticatedUser = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  return authenticatedUser.codeHabilitation === 'E';
};

export const isVip = (): boolean => {
  const authenticatedUser = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  return authenticatedUser.codeHabilitation === 'V';
};

export const INACTIVE_PROFESSION: CodeLibelle[] = [
  {
    code: 'ISP',
    libelle: 'SANS PROFESSION',
  },
  {
    code: 'IRE',
    libelle: 'RETRAITE',
  },
  {
    code: 'ICH',
    libelle: 'CHOMEUR AYANT DEJA TRAVAILLE',
  },
  {
    code: 'ICJ',
    libelle: "CHOMEUR N'AYANT JAMAIS TRAVAILLE",
  },
];

export const hasCoBorrower = (): boolean => {
  const coBorrower = sessionStorage.getItem('coBorrowerExists');
  if (coBorrower && JSON.parse(coBorrower) === true) {
    return true;
  }
  return false;
};

/**
 * Renvoie true si l'utilisateur est un travailleur non salarié (par exemple un libéral). false sinon.
 * Pour cela, on vérfie le code de la profession ou l'attribut ordreString
 * @returns boolean
 */
export const isSelfEmployedWorker = (profession?: Profession): boolean => {
  if (!profession) {
    return false;
  }
  const jobsCodeWithSirenNumber = ['NAR', 'NLA', 'NLM', 'NCO', 'NEA', 'NIN'];
  const SELF_EMPLOYED_WORKER_CODE = '2';
  if (
    jobsCodeWithSirenNumber.indexOf(profession.code) >= 0 ||
    profession.ordreString === SELF_EMPLOYED_WORKER_CODE
  ) {
    return true;
  }
  return false;
};

/**
 * Renvoie true si l'utilisateur est un inactif(par exemple chomeur ou retraite). false sinon.
 * Pour cela, on vérfie le code de la profession ou l'attribut ordreString
 * @param profession
 * @returns
 */
export const isUnEmployed = (profession?: Profession): boolean => {
  if (!profession) {
    return false;
  }
  const jobsCodeForUnEmployment = ['ICH', 'ICJ', 'ISP', 'IRE'];
  const UNEMPLOYED_CODE = '3';
  if (
    jobsCodeForUnEmployment.indexOf(profession.code) >= 0 ||
    profession.ordreString === UNEMPLOYED_CODE
  ) {
    return true;
  }
  return false;
};

/**
 * Renvoie true si l'utilisateur est un salarié. false sinon.
 * Pour cela, on vérfie le code de la profession ou l'attribut ordreString
 * @param profession
 * @returns
 */
export const isEmployedWorker = (profession?: Profession): boolean => {
  if (!profession) {
    return false;
  }
  const jobsCodeForEmployed = ['SAM', 'SCH', 'SCM', 'SCS', 'SEA', 'SEB', 'SEC', 'SEI'];
  const EMPLOYED_CODE = '1';
  if (
    profession.ordreString === EMPLOYED_CODE ||
    jobsCodeForEmployed.indexOf(profession.code) >= 0
  ) {
    return true;
  }
  return false;
};

/**
 * Renvoie true si l'utilisateur est autre que "inactif", "salarié", "non-salarié". C'est le statut "Autre". false sinon
 * @param profession
 * @returns
 */
export const isOtherJob = (profession?: Profession): boolean => {
  if (!profession) {
    return false;
  }
  const codeForOtherJobs = ['SAU'];
  const OTHER_JOB_CODE = '4';
  if (
    profession.ordreString === OTHER_JOB_CODE ||
    codeForOtherJobs.indexOf(profession.code) >= 0
  ) {
    return true;
  }
  return false;
};

/**
 * Renvoie true si l'utilisateur est un inactif(par exemple chomeur), mais pas retraité. false sinon.
 * Pour cela, on vérfie le code de la profession ou l'attribut ordreString
 * @param profession
 * @returns
 */
export const isUnEmployedExceptRetired = (profession?: Profession): boolean => {
  if (!profession) {
    return false;
  }
  const jobsCodeForUnEmployment = ['ICH', 'ICJ', 'ISP'];
  if (jobsCodeForUnEmployment.indexOf(profession.code) >= 0) {
    return true;
  }
  return false;
};

/**
 * Renvoie true si l'utilisateur est un locataire. false sinon
 * @returns booléen
 */
export const userIsRentingHouse = (): boolean => {
  try {
    const situationFoyer = getExistingStorageByKey(
      'situationFoyer',
    ) as SituationFoyerState;
    if (
      situationFoyer &&
      situationFoyer.housingStatus &&
      HousingStatus[situationFoyer.housingStatus] === HousingStatus.L
    ) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};
