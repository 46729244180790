import { DateInput } from 'components/designSystem/DateInput';
import { useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';

interface LoanStartingDateProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanStartingDate: React.FC<LoanStartingDateProps> = ({
  currentLoan,
  state,
  dispatch,
}) => {
  const [startingDate, setStartingDate] = useState<string>(
    currentLoan?.loanStartingDate || '',
  );
  const onDateChange = (e: Event) => {
    setStartingDate((e.target as HTMLInputElement).value);
  };

  const onDateBlur = () => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, loanStartingDate: startingDate };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  return (
    <DateInput
      class="show-input-ds-validation"
      label="Date de début du prêt"
      onBlur={onDateBlur}
      onInputChange={onDateChange}
      value={startingDate}
    />
  );
};

export default LoanStartingDate;
