import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import React, { useEffect, useState } from 'react';
import {
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { Loan, OperationState } from 'reducers/operationClient/types';
import LoanAdditionalInformationCreateMode from './createMode';
import LoanAdditionalInformationViewMode from './viewMode';
import * as messages from '../messages';

interface LoanProps {
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
  stateIsCorrect: boolean;
}

const Loans: React.FC<LoanProps> = ({ state, dispatch, stateIsCorrect }) => {
  const [loanList, setLoanList] = useState<Loan[]>([]);
  const getLoans = (): Loan[] => {
    let result: Loan[] = [];
    const operationsClientFromSessionStorage = sessionStorage.getItem('operationsClient');
    if (operationsClientFromSessionStorage) {
      try {
        result = (
          JSON.parse(operationsClientFromSessionStorage) as OperationState
        ).loanList.filter(loan => loan.buyBack);
      } catch (error) {
        return result;
      }
    }
    return result;
  };

  useEffect(() => {
    setLoanList(getLoans());
  }, []);

  const render = () => {
    if (state.loans.length === 0) {
      return null;
    }
    return state.loans.map(item => {
      const loan = loanList.find(element => element.code === item.code);
      if (loan) {
        if (item.isAdditionalInformationCompleted) {
          return (
            <React.Fragment key={item.code}>
              <Space marginTop="2.4rem" />
              <LoanAdditionalInformationViewMode
                key={loan.code}
                currentLoan={loan}
                state={state}
                dispatch={dispatch}
              />
            </React.Fragment>
          );
        }
        return (
          <React.Fragment key={item.code}>
            <Space marginTop="2.4rem" />
            <LoanAdditionalInformationCreateMode
              key={loan.code}
              currentLoan={loan}
              state={state}
              dispatch={dispatch}
            />
          </React.Fragment>
        );
      }
      return null;
    });
  };

  return (
    <>
      {state.loans.length > 0 && (
        <>
          <StyledTitle>Informations complémentaires des prêts à racheter</StyledTitle>
          <Space marginTop="2.4rem" />
          {!stateIsCorrect && <ErrorMessageArea message={messages.INCORRECT_DATA} />}
        </>
      )}
      {render()}
    </>
  );
};

export default Loans;
