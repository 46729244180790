import Space from 'components/space/Space';
import { StyledHorizontalBar, StyledMainTitle, StyledSecondaryTitle } from '../style';

interface RecapTransmissionNavProps {
  label: string;
  isSelected: boolean;
  isEnabled: boolean;
  onclick?: () => void;
}

const RecapTransmissionNav: React.FC<RecapTransmissionNavProps> = ({
  label,
  isSelected,
  isEnabled,
  onclick,
}) => {
  const renderEnabled = () => {
    return (
      <>
        {isSelected ? (
          <>
            <StyledMainTitle
              style={{ cursor: 'pointer' }}
              onClick={onclick}
              role="button"
              tabIndex={0}>
              {label}
            </StyledMainTitle>
            <Space marginBottom="0.8rem" />
            <StyledHorizontalBar style={{ width: 'max', marginRight: '0' }} />
          </>
        ) : (
          <StyledSecondaryTitle
            style={{ cursor: 'pointer' }}
            onClick={onclick}
            role="button"
            tabIndex={0}>
            {label}
          </StyledSecondaryTitle>
        )}
      </>
    );
  };

  return isEnabled ? (
    renderEnabled()
  ) : (
    <StyledSecondaryTitle>{label}</StyledSecondaryTitle>
  );
};

export default RecapTransmissionNav;
