import { clientFileResult, clientFilesResult } from 'types/clientFileDtos';
import getClientFile from 'api/clientFileService';
import {
  CodeLibelle,
  CodeLibelleDetail,
  PiecesJustificatives,
  Profession,
  TypeContrat,
} from 'types';
import {
  getBanks,
  getBorrowerDebts,
  getBorrowerOtherNeeds,
  getCharges,
  getCitiesByZipCode,
  getCountries,
  getDepartments,
  getHomeLoanPurposes,
  getJobContracts,
  getJobs,
  getLoaners,
  getLoanUsageTypes,
  getNationalities,
  getReferential,
  getRessources,
  getStreets,
} from 'api/referentialService';
import getRefEmprunteur from 'utils/hookReferential';
import { IbanState } from 'reducers/iban/type';
import { cleanFileSessionStorageData, zipcodeNumbertoString } from '../commun';
import {
  mappingEmprunteur,
  mappingRevenueEtCharge,
  mappingSituationFoyer,
  mappingSituationProfessionnelle,
} from './mappingDonneesClient';
import mappingOperationClient from './mappingOperationClient';
import {
  mappingConditionFinanciere,
  mappingLoanInformationsByIntermediationFee,
  mappingResultatSimulation,
} from './mappingConditionFinancieres';
import {
  mappingDeptAdditionnalInformation,
  mappingFinalisationProjet,
  mappingGuaranteeAdditionalInformation,
  mappingIban,
  mappingInterlocutor,
  mappingLoanAdditionalInfomation,
} from './mappingFinalisationProjet';

export const mappingGetClientFiles = async (
  clientFile: clientFileResult,
  StatutMaitre: string,
): Promise<void> => {
  let nationality: CodeLibelle[] = [];
  let departements: CodeLibelle[] = [];
  let countries: CodeLibelle[] = [];
  const jobsList: Profession[] = [];
  const jobContractsList: TypeContrat[] = [];
  let banks: CodeLibelle[] = [];
  let ressources: CodeLibelle[] = [];
  let charges: CodeLibelle[] = [];
  let usageBiens: CodeLibelle[] = [];
  let usageCredit: CodeLibelle[] = [];
  let listeEtablissementPreteur: CodeLibelle[] = [];
  let incidentPaymentType: CodeLibelle[] = [];
  const additionalLoanPurpose: CodeLibelleDetail[] = [];
  const debtList: CodeLibelleDetail[] = [];
  let identityTypeList: CodeLibelle[] = [];
  let typeEvaluation: CodeLibelle[] = [];
  let typeVoie: CodeLibelle[] = [];

  if (clientFile.contexteArrivee)
    sessionStorage.setItem('contexteArrivee', clientFile.contexteArrivee);

  if (clientFile.etudieur) sessionStorage.setItem('etudieur', clientFile.etudieur);

  if (clientFile.emprunteur) {
    const refEmprunteur = await getRefEmprunteur([
      getCountries(),
      getDepartments(),
      getNationalities(),
    ]);

    [countries, departements, nationality] = refEmprunteur;

    const borrower = mappingEmprunteur(
      clientFile.emprunteur,
      !!clientFile.coEmprunteur,
      nationality,
      departements,
      countries,
      clientFile.contexteArrivee,
      !!clientFile.proposition,
    );
    sessionStorage.setItem('borrowerIdentity', JSON.stringify(borrower));
  }

  if (clientFile.coEmprunteur) {
    const coBorrower = mappingEmprunteur(
      clientFile.coEmprunteur,
      true,
      nationality,
      departements,
      countries,
      clientFile.contexteArrivee,
      !!clientFile.proposition,
    );
    sessionStorage.setItem('coBorrowerIdentity', JSON.stringify(coBorrower));
  }

  if (clientFile.foyer?.ville) {
    const cityByZipCode = await getCitiesByZipCode(
      zipcodeNumbertoString(clientFile.foyer.codePostal),
    );
    const situationFoyer = mappingSituationFoyer(clientFile, cityByZipCode);
    sessionStorage.setItem('situationFoyer', JSON.stringify(situationFoyer));
  }

  if (
    clientFile.emprunteur.situationProfessionnelle &&
    !(
      clientFile.contexteArrivee !== 'ASRC' &&
      !clientFile.emprunteur.situationProfessionnelle.categorieSocioProfessionnelle
    )
  ) {
    // [jobContractsList] = await getRefEmprunteur([getJobContracts()]);
    try {
      const response = await getJobContracts();
      const { Resultat, Success } = response;
      if (Success) {
        Resultat.forEach(item => {
          jobContractsList.push({
            code: item.code,
            libelle: item.libelle,
            ordreString: item.ordreString,
          });
        });
      }
    } catch (error) {
      //  TODO: à gérer lors du sprint de la gestion des erreurs
      console.error(error);
    }
    try {
      const response = await getJobs();
      const { Resultat, Success } = response;
      if (Success) {
        Resultat.forEach(item => {
          jobsList.push({
            code: item.code,
            libelle: item.libelle,
            ordreString: item.ordreString,
          });
        });
      }
    } catch (error) {
      //  TODO: à gérer lors du sprint de la gestion des erreurs
      console.error(error);
    }

    const situationProfessionnelle = mappingSituationProfessionnelle(
      clientFile,
      jobsList,
      jobContractsList,
      countries,
    );
    sessionStorage.setItem(
      'situationProfessionnelle',
      JSON.stringify(situationProfessionnelle),
    );
  }

  if (
    clientFile.chargesRessources &&
    clientFile.donneesBancaires &&
    !(clientFile.contexteArrivee !== 'ASRC' && clientFile.donneesBancaires.length === 0)
  ) {
    [banks, ressources, charges] = await getRefEmprunteur([
      getBanks(),
      getRessources(),
      getCharges(),
    ]);
    const ressourcesMensuelles = mappingRevenueEtCharge(
      clientFile,
      banks,
      ressources,
      charges,
      departements,
    );
    sessionStorage.setItem('ressourcesMensuelles', JSON.stringify(ressourcesMensuelles));
  }

  if (clientFile.projet.credits.length !== 0) {
    [usageCredit, usageBiens, listeEtablissementPreteur, incidentPaymentType] =
      await getRefEmprunteur([
        getLoanUsageTypes(),
        getHomeLoanPurposes(),
        getLoaners(),
        getReferential('difficultePaiement'),
      ]);
    try {
      const response = await getBorrowerOtherNeeds();
      const { Resultat, Success } = response;
      if (Success) {
        Resultat.forEach(item => {
          additionalLoanPurpose.push({
            code: item.code,
            libelle: item.libelle,
            ordreString: item.ordreString,
          });
        });
      }
    } catch (error) {
      //  TODO: à gérer lors du sprint de la gestion des erreurs
      console.error(error);
    }
    try {
      const response = await getBorrowerDebts();
      const { Resultat, Success } = response;
      if (Success) {
        Resultat.forEach(item => {
          debtList.push({
            code: item.code,
            libelle: item.libelle,
            ordreString: item.ordreString,
          });
        });
      }
    } catch (error) {
      //  TODO: à gérer lors du sprint de la gestion des erreurs
      console.error(error);
    }
    const operationClient = mappingOperationClient(
      clientFile,
      usageBiens,
      listeEtablissementPreteur,
      additionalLoanPurpose,
      debtList,
      incidentPaymentType,
    );
    sessionStorage.setItem('operationsClient', JSON.stringify(operationClient));
  }

  if (clientFile.projet?.pourcentageFraisIntermediation) {
    const pourcentageFraisIntermediation = {
      value: clientFile.projet?.pourcentageFraisIntermediation.toString(),
      type: 'P',
    };
    sessionStorage.setItem(
      'pourcentageFraisIntermediation',
      JSON.stringify(pourcentageFraisIntermediation),
    );
  }

  if (clientFile.proposition) {
    const conditionsFinancieres = mappingConditionFinanciere(clientFile);
    sessionStorage.setItem(
      'conditionsFinancieres',
      JSON.stringify(conditionsFinancieres),
    );
    const resultatSimulation = mappingResultatSimulation(clientFile);
    sessionStorage.setItem('simulationResult', JSON.stringify(resultatSimulation));

    const loanInformationsByInterFee =
      mappingLoanInformationsByIntermediationFee(clientFile);
    sessionStorage.setItem(
      'loanInformationsByIntermediationFee',
      JSON.stringify(loanInformationsByInterFee),
    );
  }

  if (clientFile.emprunteur?.coordonnees?.adresse) {
    [identityTypeList, typeEvaluation, typeVoie] = await getRefEmprunteur([
      getReferential('identitydocumenttypes'),
      getReferential('typeEvaluation'),
      getStreets(),
    ]);

    const finalisationProjet = mappingFinalisationProjet(
      clientFile,
      identityTypeList,
      typeVoie,
    );
    sessionStorage.setItem('finalisationProjet', JSON.stringify(finalisationProjet));

    if (clientFile.garantieHypothecaire?.biensImmobiliers) {
      const guaranteeAdditionalInformation = await mappingGuaranteeAdditionalInformation(
        clientFile,
        typeEvaluation,
        typeVoie,
      );
      sessionStorage.setItem(
        'guaranteeAdditionalInformation',
        JSON.stringify(guaranteeAdditionalInformation),
      );
    }

    if (clientFile.projet.dettes && clientFile.projet.dettes.length > 0) {
      const debtAdditionalInformation = mappingDeptAdditionnalInformation(
        clientFile,
        debtList,
        banks,
      );
      sessionStorage.setItem(
        'debtAdditionalInformation',
        JSON.stringify(debtAdditionalInformation),
      );
    }

    if (clientFile.projet.credits && clientFile.projet.credits.length > 0) {
      const loanAdditionalInformation = mappingLoanAdditionalInfomation(
        clientFile.projet.credits,
        usageCredit,
        usageBiens,
        typeVoie,
        !!clientFile.coEmprunteur,
        clientFile.contexteArrivee,
        !!clientFile.proposition,
        StatutMaitre,
      );
      sessionStorage.setItem(
        'loanAdditionalInformation',
        JSON.stringify(loanAdditionalInformation),
      );
    }

    if (clientFile.emprunteur.coordonneesBancaires != null) {
      let iban = { isEmpty: true } as IbanState;
      iban = mappingIban(
        clientFile.emprunteur.coordonneesBancaires,
        clientFile.contexteArrivee,
        !!clientFile.proposition,
      );
      sessionStorage.setItem('iban', JSON.stringify(iban));
    }

    if (
      clientFile.monteur ||
      clientFile.vendeur ||
      clientFile.miob ||
      clientFile.analyste
    ) {
      const interlocutor = mappingInterlocutor(clientFile);
      sessionStorage.setItem('interlocutor', JSON.stringify(interlocutor));
    }
  }

  if (
    clientFile.listePiecesJustificativesPropCialUploadable?.listeChemises &&
    clientFile.listePiecesJustificativesPropCialUploadable?.listeChemises.length > 0 &&
    clientFile.listePiecesJustificativesPropCialNonUploadables &&
    clientFile.listePiecesJustificativesPropCialNonUploadables.length > 0
  ) {
    const PiecesJustificativesData: PiecesJustificatives = {
      listePiecesJustificativesPropCialUploadable:
        clientFile.listePiecesJustificativesPropCialUploadable,
      listePiecesJustificativesPropCialNonUploadables:
        clientFile.listePiecesJustificativesPropCialNonUploadables,
    };
    sessionStorage.setItem(
      'piecesJustificatives',
      JSON.stringify(PiecesJustificativesData),
    );
  }
};

export const getExistingClientFile = async (
  numeroProjet: number,
  projetDetail: clientFilesResult,
): Promise<void> => {
  cleanFileSessionStorageData();

  const clientFile = await getClientFile(numeroProjet);

  // reserve usage futur si la consulation de dossier est multi APA
  // if (clientFile.Resultat && clientFile.Resultat.numeroContremarque) {
  //   sessionStorage.setItem(
  //     'selectedApa',
  //     clientFile.Resultat.numeroContremarque.toString(),
  //   );
  // }

  sessionStorage.setItem('numeroProjet', `${numeroProjet}`);
  sessionStorage.setItem('borrowerId', `${projetDetail.IdPros}`);
  if (projetDetail.IdProsCjt) {
    sessionStorage.setItem('coBorrowerId', `${projetDetail.IdProsCjt}`);
  }
  sessionStorage.setItem('projetDetail', JSON.stringify(projetDetail));

  await mappingGetClientFiles(clientFile.Resultat, projetDetail.StatutMaitre);
};
