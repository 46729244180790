import { getRecupererDernierDocumentPack } from 'api/transfertDocumentService';
import { StyledErrorWrapper } from 'components/chemise/style';
import Space from 'components/space/Space';
import { isSansGarantie } from 'containers/communs/utils';
import DownloadIcon from 'icons/DownloadIcon';
import { useState } from 'react';
import { CodeLibelle } from 'types';
import { getNumeroProjet, getUserInfos } from 'utils/commun';
import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import dossierClientPdf from './pdfExport/pdfDossierClient';
import transmissionPdf from './pdfExport/pdfFicheTransmission';
import piecesJustifPdf from './pdfExport/pdfPiecesJustif';
import * as messages from './messages';
import StyledDowloadCardWrapper, {
  StyledDowloadCardElement,
  StyledDowloadCardTitle,
  StyledSpanDivider,
} from './style';

interface DownloadCardProps {
  projectStatus?: CodeLibelle;
}

const DownloadCard: React.FC<DownloadCardProps> = ({ projectStatus }) => {
  const [noDocMessage, setNoDocMessage] = useState<string>('');
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const isSG = isSansGarantie();

  const downloadFile = (pdfString: string, fileName: string, fileId: number) => {
    const buffer = Buffer.from(pdfString, 'base64');
    const blob = new Blob([buffer], {
      type: 'application/pdf;base64',
    });
    const blobURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = `${fileName}_${fileId}.pdf`;
    link.setAttribute('hidden', 'true');
    link.click();
    window.URL.revokeObjectURL(blobURL);
  };

  const handleLastDDP = async () => {
    setNoDocMessage('');
    try {
      const response = await getRecupererDernierDocumentPack(
        'DDPCH',
        getUserInfos().uid,
        getNumeroProjet(),
      );
      const { Resultat, Success } = response;
      if (Success && Resultat.listeDocuments[0]?.docuConPdf) {
        downloadFile(
          Resultat.listeDocuments[0].docuConPdf,
          'DDP',
          Resultat.listeDocuments[0].idEditDocu,
        );
      } else {
        setNoDocMessage('Aucune DDP disponible \nen téléchargement');
      }
    } catch (error) {
      //  TODO: à gérer lors du sprint de la gestion des erreurs
      console.error(error);
    }
  };

  const handleLastoffre = async () => {
    setNoDocMessage('');
    const userInfo = getUserInfos();
    const numeroProjet = getNumeroProjet();
    try {
      const response = await getRecupererDernierDocumentPack(
        isSG ? 'OCCRC' : '',
        userInfo.uid,
        numeroProjet,
      );
      const { Resultat, Success } = response;
      if (Success && Resultat.listeDocuments[0]?.docuConPdf) {
        const docName = `pdfPackContractuel_${getUserInfos().uid}`;
        downloadFile(Resultat.listeDocuments[0].docuConPdf, docName, numeroProjet || 0);
      } else {
        setNoDocMessage('Aucune offre disponible en téléchargement');
      }
    } catch (error) {
      //  TODO: à gérer lors du sprint de la gestion des erreurs
      console.error(error);
    }
  };

  const handleAttestationHonneur = async () => {
    setNoDocMessage('');
    const numeroProjet = getNumeroProjet();
    try {
      const response = await getRecupererDernierDocumentPack(
        'ATHON',
        getUserInfos().uid,
        numeroProjet,
      );
      const { Resultat, Success } = response;
      if (Success && Resultat.listeDocuments[0]?.docuConPdf) {
        downloadFile(
          Resultat.listeDocuments[0].docuConPdf,
          messages.DL_ATTESTATION_HONNEUR,
          numeroProjet || 0,
        );
      } else {
        setNoDocMessage(
          "Aucune Attestation sur l'honneur disponible \nen téléchargement",
        );
      }
    } catch (error) {
      //  TODO: à gérer lors du sprint de la gestion des erreurs
      console.error(error);
    }
  };

  const handleTransmission = async () => {
    setNoDocMessage('');
    transmissionPdf();
  };

  const handleDossierClient = async () => {
    setNoDocMessage('');
    dossierClientPdf();
  };

  const handlePiecesJustif = async () => {
    setNoDocMessage('');
    piecesJustifPdf();
  };

  return (
    <>
      <StyledDowloadCardWrapper>
        <Space marginBottom="1.8rem" />
        <StyledDowloadCardTitle>
          {messages.DL_PROJECT_DOC}
          <div
            style={{ display: 'flex', gap: '1rem', cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {
              setShowOptions(prevState => !prevState);
              setNoDocMessage('');
            }}
            onClick={() => {
              setShowOptions(prevState => !prevState);
              setNoDocMessage('');
            }}>
            {showOptions ? <ChevronUp /> : <ChevronDown />}
          </div>
        </StyledDowloadCardTitle>
        <Space marginBottom="1.6rem" />
        {showOptions ? (
          <>
            <StyledDowloadCardElement onClick={handleTransmission}>
              {messages.DL_TRANSMISSION_SHEET}
              <DownloadIcon width={15} />
            </StyledDowloadCardElement>
            <Space marginBottom="1.6rem" />
            <StyledDowloadCardElement onClick={handleDossierClient}>
              {messages.DL_DOSSIER_CLIENT}
              <DownloadIcon width={15} />
            </StyledDowloadCardElement>
            <Space marginBottom="1.6rem" />
            <StyledDowloadCardElement onClick={handlePiecesJustif}>
              {messages.DL_PIECE_JUSTIF}
              <DownloadIcon width={15} />
            </StyledDowloadCardElement>
            <Space marginBottom="1.6rem" />
            <StyledDowloadCardElement onClick={handleAttestationHonneur}>
              {messages.DL_ATTESTATION_HONNEUR}
              <DownloadIcon width={15} />
            </StyledDowloadCardElement>
            <Space marginBottom="1.6rem" />
            <StyledSpanDivider />
            <Space marginBottom="1.6rem" />
            <StyledDowloadCardElement onClick={handleLastoffre}>
              {messages.DL_DOE}
              <DownloadIcon width={15} />
            </StyledDowloadCardElement>
            {!isSG && (
              <div>
                <Space marginBottom="1.6rem" />
                <StyledDowloadCardElement onClick={handleLastDDP}>
                  {messages.DL_DPP}
                  <DownloadIcon width={15} />
                </StyledDowloadCardElement>
              </div>
            )}
            <Space marginBottom="1.8rem" />
          </>
        ) : null}
      </StyledDowloadCardWrapper>
      <div style={{ display: 'flex', width: '20.9rem', marginLeft: '0.5rem' }}>
        <StyledErrorWrapper>{noDocMessage}</StyledErrorWrapper>
      </div>
    </>
  );
};

export default DownloadCard;
