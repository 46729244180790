import Tooltip from 'components/tooltip/Tooltip';
import ChevronContact from 'icons/ChevronExpand';
import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import { ReactNode } from 'react';
import { Partner, SortEnum, PartnerSortingKey } from 'types';
import { PartnerKeys } from 'reducers/ebordereau/partner';

interface PartnerListProps {
  partnerList?: Partner[];
  handleSortPartnerListByName: () => void;
  handleSortPartnerListByNumber: () => void;
  handleSortPartnerListByIdIntv: () => void;
  onFilter: (text: string, filteringKey: PartnerKeys) => void;
  onAllCheckboxCheck: () => void;
  checkAllCheckBox: boolean;
  onAPACheck: (idAPA: string) => void;
  checkedIdsAPA: string[];
  nameSortOrder?: SortEnum;
  numberSortOrder?: SortEnum;
  idSortOrder?: SortEnum;
  activeSortingKey?: PartnerSortingKey;
}
const PartnerList: React.FC<PartnerListProps> = ({
  partnerList,
  handleSortPartnerListByName,
  handleSortPartnerListByNumber,
  handleSortPartnerListByIdIntv,
  onFilter,
  onAllCheckboxCheck,
  checkAllCheckBox,
  onAPACheck,
  checkedIdsAPA,
  nameSortOrder,
  numberSortOrder,
  idSortOrder,
  activeSortingKey,
}) => {
  const thStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem',
  };
  const isIdAPAChecked = (idAPA: string) => {
    if (checkedIdsAPA.indexOf(idAPA) >= 0) {
      return true;
    }
    return false;
  };
  const renderListApa = (): ReactNode => {
    return partnerList?.map((apa, index) => {
      return (
        <tr
          key={apa.idIntv.toString()}
          className={index % 2 === 0 ? 'grayBackground' : ''}>
          <td>
            <input
              type="checkbox"
              onChange={() => {
                onAPACheck(apa.idAPA);
              }}
              checked={isIdAPAChecked(apa.idAPA)}
            />
          </td>
          <td>{apa.nomCourtier}</td>
          <td>{apa.numeroVendeur}</td>
          <td>{apa.idIntv}</td>
        </tr>
      );
    });
  };

  const renderSortingIcon = (sortOrder?: SortEnum) => {
    if (sortOrder === SortEnum.ASC) {
      return <ChevronUp />;
    }
    if (sortOrder === SortEnum.DESC) {
      return <ChevronDown />;
    }
    return null;
  };

  return (
    <div>
      <table className="apaAndBordereauTable">
        <thead>
          <tr>
            <th style={{ width: '3.6rem' }}>
              <input
                type="checkbox"
                checked={checkAllCheckBox}
                onChange={onAllCheckboxCheck}
              />
            </th>
            <th>
              <div
                role="button"
                tabIndex={0}
                onClick={handleSortPartnerListByName}
                onKeyPress={handleSortPartnerListByName}
                style={thStyle}>
                <div>
                  <span>Nom du partenaire</span>
                  <Tooltip topPosition={-9} leftPosition={-10}>
                    Vous pouvez retrouver ce nom sur le bordereau de commissionnement
                    entre le n° de TVA intracommunautaire et le numéro de la facture
                  </Tooltip>
                </div>
                {activeSortingKey === 'nomCourtier' ? (
                  renderSortingIcon(nameSortOrder)
                ) : (
                  <ChevronContact />
                )}
              </div>

              <div>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  className="styledBorder"
                  onChange={e => {
                    onFilter(e.target.value, 'nomCourtier');
                  }}
                />
              </div>
            </th>
            <th>
              <div
                role="button"
                tabIndex={0}
                onClick={handleSortPartnerListByNumber}
                onKeyPress={handleSortPartnerListByNumber}
                style={thStyle}>
                <div>
                  <span>Numéro vendeur</span>
                  <Tooltip topPosition={-14.5} leftPosition={-10}>
                    Vous pouvez retrouver ce n° composé de 7 chiffres dans le libellé du
                    virement au niveau du motif de paiement après le chiffre 183.
                    <img
                      src={`${process.env.PUBLIC_URL}/vendeur_tooltip.jpg`}
                      alt="vendeur tooltip"
                    />
                  </Tooltip>
                </div>
                {activeSortingKey === 'numeroVendeur' ? (
                  renderSortingIcon(numberSortOrder)
                ) : (
                  <ChevronContact />
                )}
              </div>

              <div>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  className="styledBorder"
                  onChange={e => {
                    onFilter(e.target.value, 'numeroVendeur');
                  }}
                />
              </div>
            </th>
            <th>
              <div
                role="button"
                tabIndex={0}
                onClick={handleSortPartnerListByIdIntv}
                onKeyPress={handleSortPartnerListByIdIntv}
                style={thStyle}>
                <div>
                  <span>Id intervenant</span>
                  <Tooltip leftPosition={-10} topPosition={-14.5}>
                    Vous pouvez retrouver ce n° qui est composé de 6 chiffres dans le
                    libellé du virement au niveau du motif de paiement après les 000.
                    <img
                      src={`${process.env.PUBLIC_URL}/intervenant_tooltip.jpg`}
                      alt="vendeur tooltip"
                    />
                  </Tooltip>
                </div>
                {activeSortingKey === 'idIntv' ? (
                  renderSortingIcon(idSortOrder)
                ) : (
                  <ChevronContact />
                )}
              </div>

              <div>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  className="styledBorder"
                  onChange={e => {
                    onFilter(e.target.value, 'idIntv');
                  }}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>{renderListApa()}</tbody>
      </table>
    </div>
  );
};

export default PartnerList;
