import styled from 'styled-components';

export const StyledMainTitle = styled.span`
  color: #292c2e;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-weight: bold;
`;

export const StyledSecondaryTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: bold;
`;

export const StyledButtonLabel = styled.span`
  height: fit-content;
  margin: 0.4rem 0 0.4rem 0;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
`;

export const StyledStandardText = styled.span`
  height: fit-content;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
`;

export const StyledFlexRow = styled.span`
  display: flex;
  gap: 2.4rem;
  align-items: center;
`;

export const StyledFiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 121.5rem;
`;

export const StyledExtraFiltersAndTagsContainer = styled.div`
  display: flex;
  gap: 4rem;
  align-items: start;
`;

export const StyledTagsContainer = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-wrap: wrap;
`;

export const StyledRefreshFiltersButton = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  height: fit-content;
`;

export const StyledRefreshButtonText = styled.span`
  color: #008859;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
`;

export const StyledResultCounter = styled.div`
  height: 24px;
  border-radius: 4px;
  background-color: #4b4f54;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 16px;
  text-align: center;
  margin: 0 0.8rem;
  padding: 0 0.6rem;
`;

export const StyledResultFoundText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
`;
