import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { ValidationRules, checkPercentageValue } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface InterestRateProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const InterestRate: React.FC<InterestRateProps> = ({ currentLoan, state, dispatch }) => {
  const [interestRateInputFocused, setInterestRateInputFocused] =
    useState<boolean>(false);
  const [interestRateInputValid, setInterestRateInputValid] = useState<
    boolean | undefined
  >();
  const [interestRateInputError, setInterestRateInputError] = useState<string>('');
  const [interestRateValue, setInterestRateValue] = useState<string | undefined>(
    currentLoan?.nominalInterestRate || undefined,
  );

  const realNumbers = new RegExp(ValidationRules.realNumbers);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const updateState = (value?: string) => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return {
          ...item,
          nominalInterestRate: value,
        };
      }
      return item;
    });

    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  };

  const handleInterestRateChange = (value: string) => {
    if (
      !realNumbers.test(value) ||
      Number.isNaN(Number(value.replaceAll(',', '.'))) ||
      checkPercentageValue(value)
    )
      return;

    setInterestRateValue(value);

    updateState(value.replaceAll(',', '.'));
  };

  const handleBorrowingRateBlur = () => {
    setInterestRateInputFocused(false);

    if (!notEmpty.test(interestRateValue || '')) {
      setInterestRateInputValid(false);
      setInterestRateInputError(messagesCheckInput.REQUIRED_VALUE);
      updateState(undefined);
    } else if (
      !realNumbers.test(interestRateValue || '') ||
      Number.isNaN(Number(interestRateValue?.replaceAll(',', '.')))
    ) {
      setInterestRateInputValid(false);
      setInterestRateInputError(messagesCheckInput.REAL_NUMBER);
      updateState(undefined);
    } else if (Number(interestRateValue?.replaceAll(',', '.')) < 0) {
      setInterestRateInputValid(false);
      setInterestRateInputError(messagesCheckInput.REAL_NUMBER_POSITIVE);
      updateState(undefined);
    } else {
      setInterestRateInputValid(true);
      setInterestRateInputError('');
    }
  };

  useEffect(() => {
    if (notEmpty.test(currentLoan.nominalInterestRate || '')) handleBorrowingRateBlur();
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      <div style={{ marginRight: '1.8rem' }}>
        <CustomInput
          name="borrowing-rate"
          label="Taux d'intérêt nominal"
          value={interestRateValue}
          onChange={newValue => {
            handleInterestRateChange(newValue.replaceAll(' ', '').slice(0, 6));
            setInterestRateInputFocused(true);
          }}
          isValid={interestRateInputValid}
          isFocused={interestRateInputFocused}
          onBlur={handleBorrowingRateBlur}
          onFocus={() => {
            setInterestRateInputFocused(true);
          }}
          after="%"
          inputWidth="17.2rem"
          placeholder="0"
          error={interestRateInputError}
        />
      </div>
    </div>
  );
};

export default InterestRate;
