import CustomInput from 'components/customInput/CustomInput';
import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import { useEffect, useState } from 'react';
import { InterlocutorAction, InterlocutorState } from 'reducers/interlocutor/type';
import { CodeLibelle, StatusInput, UserInfos } from 'types';
import { StyledErrorMessage } from 'components/customInput/style';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import { ValidationRules } from 'utils/InputValidation';
import { StyledInputLabel } from '../style';

interface InterlocutorProps {
  state: InterlocutorState;
  dispatch: React.Dispatch<InterlocutorAction>;
  userInfos: UserInfos | null;
  checkInputs: boolean;
}

const Interlocutor: React.FC<InterlocutorProps> = ({
  state,
  dispatch,
  userInfos,
  checkInputs,
}) => {
  const getDefaultValue = () => {
    if (state.isMIOB === true) {
      return 'oui';
    }
    if (state.isMIOB === false) {
      return 'non';
    }
    return undefined;
  };
  const [isMIOB, setIsMIOB] = useState<'oui' | 'non' | undefined>(getDefaultValue());
  const [miobName, setMiobName] = useState<string>(state.miobName || '');
  const [miobErrorMessage, setMiobErrorMessage] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');
  const [apa, setApa] = useState<CodeLibelle | undefined>(state.interlocutorName);
  const [statusApa, setStatusApa] = useState<StatusInput>();

  const notEmpty = new RegExp(ValidationRules.notEmpty);

  useEffect(() => {
    const newState = { ...state, isMIOB: isMIOB ? isMIOB === 'oui' : undefined };
    dispatch({
      type: 'setInterlocutor',
      paylaod: { value: newState },
    });
  }, [isMIOB]);

  const getListApa = () => {
    const names: CodeLibelle[] = [];
    if (userInfos && userInfos.lstTierAPA?.length > 0) {
      userInfos.lstTierAPA.forEach(item => {
        names.push({
          code: item.tierRang.toString(),
          libelle: `${item.tierPrenom} ${item.tierNom}`,
        });
      });
    }
    return names;
  };

  const handleOnChange = (value: string) => {
    setIsInputValid(undefined);
    setMiobName(value);
  };

  const checkMiobValid = () => {
    if (state.isMIOB) setMiobErrorMessage('');
    else setMiobErrorMessage(messagesCheckInput.REQUIRED_VALUE);
  };

  const onMiobBlur = (value: string) => {
    setIsInputFocused(false);
    const valWithoutSpace = value.replace(/ /g, '');
    if (valWithoutSpace.length > 0) {
      setIsInputValid(true);
      setInputError('');
      const newState = { ...state, miobName };
      dispatch({
        type: 'setInterlocutor',
        paylaod: { value: newState },
      });
    } else {
      setIsInputValid(false);
      setInputError(messagesCheckInput.REQUIRED_VALUE);
      const newState = { ...state, miobName: undefined };
      dispatch({
        type: 'setInterlocutor',
        paylaod: { value: newState },
      });
    }
  };

  const onNameSelect = (e: Event) => {
    const selectedName = (e as CustomEvent).detail.value as CodeLibelle;
    const newState = { ...state, interlocutorName: selectedName };
    setApa(selectedName);
    dispatch({
      type: 'setInterlocutor',
      paylaod: { value: newState },
    });
  };

  const onListApaBlur = (value: string) => {
    const wrongApa = { code: 'err', libelle: value } as CodeLibelle;

    if (!notEmpty.test(value)) {
      setStatusApa({
        status: 'invalid',
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      const newState = { ...state, interlocutorName: wrongApa };
      dispatch({
        type: 'setInterlocutor',
        paylaod: { value: newState },
      });
      return;
    }

    const apaMatchList = getListApa()?.find(item => {
      return item.libelle.toUpperCase() === value.toUpperCase();
    });

    if (!apaMatchList) {
      setStatusApa({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });

      const newState = { ...state, interlocutorName: wrongApa };

      dispatch({
        type: 'setInterlocutor',
        paylaod: { value: newState },
      });
    } else {
      setStatusApa({
        status: 'valid',
        errorMessage: ``,
      });
      const newState = {
        ...state,
        interlocutorName: apa,
      };
      dispatch({
        type: 'setInterlocutor',
        paylaod: { value: newState },
      });
    }
  };

  useEffect(() => {
    if (checkInputs) {
      checkMiobValid();
      onMiobBlur(state.miobName || '');
      onListApaBlur(state.interlocutorName?.libelle || '');
    }
  }, [checkInputs]);

  useEffect(() => {
    if (state.isMIOB) checkMiobValid();
    onMiobBlur(state.miobName || '');
    if (state.interlocutorName?.libelle)
      onListApaBlur(state.interlocutorName?.libelle || '');
  }, []);

  const renderAnalyste = () => {
    if (userInfos && userInfos.codeHabilitation === 'E') {
      return (
        <>
          <CustomInput
            name="ep"
            label="Analyste EP"
            type="text"
            isDisabled
            value={`${userInfos.prenomTiersAPA} ${userInfos.nomTiersAPA}`}
            inputWidth="20.8rem"
            isFocused={false}
          />
          <Space marginTop="2.4rem" />
        </>
      );
    }
    return null;
  };

  const renderInterlocutor = () => {
    if (userInfos && userInfos.codeHabilitation !== 'E') {
      return (
        <>
          <CustomInput
            name="name"
            label="Interlocuteur chargé du montage du dossier"
            type="text"
            isDisabled
            value={`${userInfos.prenomTiersAPA} ${userInfos.nomTiersAPA}`}
            inputWidth="20.8rem"
            isFocused={false}
          />
          <Space marginTop="2.4rem" />
        </>
      );
    }
    return null;
  };

  const renderListApa = () => {
    const apaList = getListApa();
    if (apaList.length > 0) {
      return (
        <>
          <ComboInput
            id="name-dropDown"
            class="combo-input"
            list-on-open
            required
            shadow
            field="libelle"
            align-items="left"
            items={JSON.stringify(apaList)}
            placeholder="Choisir l'interlocuteur"
            label="Interlocuteur ayant réalisé la découverte client"
            onSelectedList={e => {
              onNameSelect(e);
            }}
            onListBlur={e => {
              onListApaBlur((e.target as unknown as ComboInputDS).value as string);
            }}
            value={state.interlocutorName?.libelle || ''}
            status={statusApa?.status}
            error-message={statusApa?.errorMessage}
          />
          <Space marginBottom="2.4rem" />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <div
        style={{
          border: `0.1rem solid #B1B5B9`,
          borderRadius: '0.8rem',
          padding: '2.4rem',
        }}>
        <StyledTitle>Interlocuteurs partenaires</StyledTitle>
        <Space marginBottom="2.4rem" />
        {renderInterlocutor()}
        {renderListApa()}
        {renderAnalyste()}
        <StyledInputLabel>Le dossier provient-il d’un MIOB ?</StyledInputLabel>
        <Space marginTop="1rem" />
        <div style={{ display: 'flex', gap: '3.7rem' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="oui"
              checked={isMIOB === 'oui' ? true : undefined}
              onClick={() => {
                if (isMIOB !== 'oui') {
                  setIsMIOB('oui');
                } else {
                  setIsMIOB(undefined);
                }
              }}
            />
            <StyledInputLabel>Oui</StyledInputLabel>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="non"
              checked={isMIOB === 'non' ? true : undefined}
              onClick={() => {
                if (isMIOB !== 'non') {
                  setIsMIOB('non');
                } else {
                  setIsMIOB(undefined);
                }
              }}
            />
            <StyledInputLabel>Non</StyledInputLabel>
          </div>
        </div>
        {!isMIOB && checkInputs && (
          <>
            <StyledErrorMessage>{miobErrorMessage}</StyledErrorMessage>
          </>
        )}
        {isMIOB === 'oui' ? (
          <>
            <Space marginTop="2.4rem" />
            <CustomInput
              name="moibName"
              label="Nom"
              value={miobName}
              onChange={newValue => {
                handleOnChange(newValue);
                setIsInputFocused(true);
              }}
              isValid={isInputValid}
              isFocused={isInputFocused}
              onBlur={value => {
                onMiobBlur(value);
              }}
              onFocus={() => {
                setIsInputFocused(true);
              }}
              inputWidth="28.8rem"
              error={inputError}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default Interlocutor;
