import { useEffect, useState } from 'react';
import { ComboInput } from 'components/designSystem/ComboInput';
import {
  AssuranceEmprunteur,
  CalculetteFinanciereAction,
} from 'reducers/calculetteFinanciere/type';
import { CodeLibelle, StatusInput } from 'types';

const delayItems = [
  { code: '01', libelle: '30 jours' },
  { code: '02', libelle: '90 jours' },
];
interface FranchiseTimeLimitProps {
  person: 'borrower' | 'coBorrower';
  dispatch: React.Dispatch<CalculetteFinanciereAction>;
  getPersonWarranty: AssuranceEmprunteur;
  isSG: boolean;
}

const FranchiseTimeLimit: React.FC<FranchiseTimeLimitProps> = ({
  person,
  dispatch,
  isSG,
  getPersonWarranty,
}) => {
  const [validityStatus, setValidityStatus] = useState<StatusInput>();

  const onGracePeriodSelect = (e: Event) => {
    const selectedGracePeriod = (e as CustomEvent).detail.value as CodeLibelle;
    dispatch({
      type: 'setDelaiFranchise',
      payload: { person, value: selectedGracePeriod.code },
    });
    setValidityStatus({
      status: 'valid',
      errorMessage: '',
    });
  };

  /**
   * La valeur par défaut sélectionnée est 90 jours, si le délai de franchise est désactivée (donc pour un SG)
   * @returns
   */
  const getDefaultValue = () => {
    if (getPersonWarranty.delaiFranchise === '01') {
      return delayItems[0].libelle;
    }
    if (getPersonWarranty.delaiFranchise === '02') {
      return delayItems[1].libelle;
    }
    if (isSG) {
      dispatch({
        type: 'setDelaiFranchise',
        payload: { person, value: delayItems[1].code },
      });
      return delayItems[1].libelle;
    }
    return '';
  };

  useEffect(() => {
    if (getPersonWarranty.garantiePerteEmploi === 'N') {
      dispatch({
        type: 'setDelaiFranchise',
        payload: { person, value: '' },
      });
      setValidityStatus({
        status: 'invalid',
        errorMessage: 'Le délai de franchise est obligatoire',
      });
    }

    if (getPersonWarranty.garantiePerteEmploi === 'O') {
      dispatch({
        type: 'setDelaiFranchise',
        payload: { person, value: delayItems[1].code },
      });
      setValidityStatus({
        status: 'valid',
        errorMessage: '',
      });
    }
  }, [getPersonWarranty.garantiePerteEmploi]);

  const isDisabled = () => {
    if (isSG) return true;
    if (!isSG && getPersonWarranty.garantiePerteEmploi === 'O') return true;
    return undefined;
  };

  return (
    <ComboInput
      disabled={isDisabled()}
      required={isSG}
      list-on-open
      shadow
      align-items="left"
      items={JSON.stringify(delayItems)}
      field="libelle"
      label="Délai de franchise"
      placeholder="0"
      onSelectedList={e => {
        onGracePeriodSelect(e);
      }}
      value={getDefaultValue()}
      {...(validityStatus?.status !== 'none' ? { status: validityStatus?.status } : {})}
      {...(validityStatus?.errorMessage !== ''
        ? { 'error-message': validityStatus?.errorMessage }
        : {})}
      className="bareme-input"
    />
  );
};

export default FranchiseTimeLimit;
