import { v4 as uuidv4 } from 'uuid';
import { CodeLibelle, PJWithStatus } from 'types';
import DropFileZone from 'components/dropFileZone';
import Space from 'components/space/Space';
import Uploader from 'components/uploader';
import StatusPieceTile, {
  StatusPieceTileProps,
} from 'containers/consultation/components/StatusPiecesTile';
import { formatDateFrHour } from 'utils/DateUtils';
import { StyledTitle } from 'containers/communs/style';
import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import HourglassIcon from 'icons/HourglassIcon';
import CrossCircle from 'icons/CrossCircle';
import CheckInCircle from 'icons/CheckInCircle';
import PaperClipIcon from 'icons/PaperClipIcon';
import ActionPlusIcon from 'icons/ActionPlusIcon';
import React, { useEffect, useState } from 'react';
import { DocumentInfosTransferes } from 'types/clientFileDtos';
import { ChemiseData } from 'reducers/transmissionPieces/types';
import { StyledHorizontalBar } from 'containers/consultation/style';
import DeleteIcon from 'components/deleteIcon/DeleteIcon';
import {
  StyledChemiseConsultationWrapper,
  StyledDocumentName,
  StyledErrorWrapper,
  StyledHistoriqueWrapper,
} from './style';

export interface ChemiseConsultationProps {
  id?: string;
  libelle?: string;
  icon?: React.ReactNode;
  documentsUploadTransferes?: DocumentInfosTransferes[] | undefined;
  listPiece: PJWithStatus[];
  listPJNonTransmis?: string[];
  isUploadable: boolean;
  errorMessage?: React.ReactNode;
  uploadedFilesName?: CodeLibelle[];
  maxFileSize?: number;
  acceptedFileFormat?: string[];
  onUploadSuccess?: (data: ChemiseData) => void;
  onDelete?: (chemiseId: string, fileId: string) => void;
  hideValidPiece?: boolean;
  isConsultation?: boolean;
}

const ChemiseConsultation: React.FC<ChemiseConsultationProps> = ({
  id,
  libelle,
  icon,
  documentsUploadTransferes,
  maxFileSize,
  acceptedFileFormat,
  listPiece,
  listPJNonTransmis,
  isUploadable,
  onUploadSuccess = () => undefined,
  onDelete = () => undefined,
  errorMessage,
  uploadedFilesName,
  hideValidPiece,
  isConsultation,
}) => {
  const [showHistory, setShowHistory] = useState<boolean>(true);
  const [showUploadZone, setShowUploadZone] = useState<boolean>(true);
  const [showFileExplorer, setShowFileExplorer] = useState<boolean>(false);
  const [chemiseHasError, setChemiseHasError] = useState<boolean>(false);
  const [allStatusValid, setAllStatusValid] = useState<boolean>(false);

  const statusinfo = new Map<string, StatusPieceTileProps>([
    [
      'Attendue',
      { icon: <HourglassIcon width={16} />, label: 'Pièce en attente', color: '#ffefd6' },
    ],
    [
      'Impasse',
      { icon: <PaperClipIcon />, label: 'Pièce impasse octroi', color: '#E5F5FD' },
    ],
    [
      'Reçue non conforme',
      { icon: <CrossCircle width={13} />, label: 'Pièce non conforme', color: '#ffc7cd' },
    ],
    [
      'Annulée',
      { icon: <CrossCircle width={13} />, label: 'Pièce annulée', color: '#DCDEE0' },
    ],
    [
      'Levée',
      { icon: <CheckInCircle width={14} />, label: 'Pièce conforme', color: '#def1db' },
    ],
  ]);

  useEffect(() => {
    if (listPiece && listPiece.length > 0) {
      const listStatus = [...listPiece.values()];
      const tempAllstatusValid =
        listStatus.filter(piece => piece.status && piece.status !== 'Levée').length === 0;

      setAllStatusValid(tempAllstatusValid);
      if (tempAllstatusValid) setShowUploadZone(false);
    }
  }, [listPiece]);

  const handleUploadedFiles = (files: File[]) => {
    if (files.length > 0) {
      const fileWithErros = files.find(
        item => item.size > (maxFileSize || 19 * 1_048_576),
      );
      if (fileWithErros) {
        setChemiseHasError(true);
        return;
      }
      const chemise: ChemiseData = {
        id: id || '',
        files: [],
      };
      files.forEach(element => {
        chemise.files.push({ id: uuidv4(), content: element });
      });
      onUploadSuccess(chemise);
    }
  };

  const renderListPiece = () => {
    return listPiece.map((item, index) => {
      return (
        <StyledDocumentName key={item.libelle}>{`- ${item.libelle}`}</StyledDocumentName>
      );
    });
  };

  const renderListPieceWithStatus = () => {
    return listPiece.map((item, index) => {
      const statuspieceItem = item.status;
      let propsForStatus: StatusPieceTileProps = {
        label: 'error status',
        icon: <CrossCircle width={13} />,
        color: '#ffc7cd',
      };
      if (statuspieceItem) {
        if (hideValidPiece && statuspieceItem === 'Levée') return null;

        const hasPropsForStatus = statusinfo.get(statuspieceItem);
        if (hasPropsForStatus !== undefined) propsForStatus = hasPropsForStatus;
      }
      const key = item.libelle + index;

      return (
        <div key={key}>
          <div
            style={{
              display: 'flex',
              gap: '1.2rem',
            }}>
            <div style={{ width: '41.5rem' }}>
              <StyledDocumentName
              // style={{ maxWidth: '42rem' }}
              >{`- ${item.libelle}`}</StyledDocumentName>
              <p>{item.commantaire}</p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'left',
                // flexWrap: 'wrap',
                // alignContent: 'center',
              }}>
              {propsForStatus && <StatusPieceTile {...propsForStatus} />}
            </div>
          </div>
          <Space marginTop="2rem" />
        </div>
      );
    });
  };

  const renderNames = () => {
    return uploadedFilesName?.map(item => {
      const isFailed = listPJNonTransmis?.some(piece => piece === item.libelle);
      return isUploadable ? (
        <div
          key={item.code}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <p style={{ fontWeight: 'bold' }}>{item.libelle}</p>
          <div style={{ display: 'flex', alignItems: 'center' }} />
          <DeleteIcon onDeleteClick={() => onDelete(id || '', item.code)} />
        </div>
      ) : (
        <div
          key={item.code}
          style={{
            display: 'flex',
            gap: '0.8rem',
          }}>
          <p style={{ fontWeight: 'bold', color: isFailed ? '#c8102e' : '#268038' }}>
            {item.libelle}
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isFailed ? (
              <CrossCircle width={18} color="#c8102e" />
            ) : (
              <ActionPlusIcon width={15} color="#268038" />
            )}
          </div>
          {!isFailed && (
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'normal',
                color: '#268038',
                fontSize: '14px',
              }}>
              Transmis avec succès
            </p>
          )}
        </div>
      );
    });
  };

  const RenderHistorique = () => {
    return (
      <>
        <StyledHorizontalBar
          color="#979797"
          style={{ height: '0.2rem', marginRight: '0' }}
        />
        {documentsUploadTransferes?.map(item => {
          return (
            <div
              key={item.docuPceNom}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <p>{item.docuPceNom}</p>
              <p
                style={{
                  justifyContent: 'end',
                  fontSize: '12px',
                  alignItems: 'center',
                  display: 'flex',
                }}>
                {formatDateFrHour(item.docuPceTranTime)}
              </p>
            </div>
          );
        })}
      </>
    );
  };

  const renderUploadedFilesName = () => {
    return (
      <div>
        <StyledHistoriqueWrapper>
          <p style={{ margin: '0', marginBottom: '1rem' }}>
            Historique des pièces justificatives
          </p>
          <div
            style={{ display: 'flex', gap: '1rem', cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            onKeyDown={() => {
              setShowHistory(prevState => !prevState);
            }}
            onClick={() => {
              setShowHistory(prevState => !prevState);
            }}>
            {showHistory ? (
              <>
                <ChevronUp />
                <span>Masquer les informations</span>
              </>
            ) : (
              <>
                <ChevronDown />
                <span>Afficher les informations</span>
              </>
            )}
          </div>
        </StyledHistoriqueWrapper>
        {showHistory ? (
          <>
            {renderNames()}
            {documentsUploadTransferes !== undefined &&
              documentsUploadTransferes?.length > 0 &&
              RenderHistorique()}
          </>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <StyledChemiseConsultationWrapper hasError={chemiseHasError || false}>
        <>
          <div style={{ display: 'flex' }}>
            {icon}
            <div
              style={{
                marginLeft: icon ? '3rem' : '0rem',
                marginRight: icon ? '3rem' : '0rem',
                minWidth: '44.8rem',
                flex: '1',
              }}>
              <StyledTitle style={{ margin: '0', fontSize: '2rem' }}>
                {libelle}
              </StyledTitle>
              <Space marginTop="2rem" />
              {isConsultation ? renderListPieceWithStatus() : renderListPiece()}
              {isUploadable && (
                <>
                  <Space marginTop="2rem" />
                  <div
                    style={{
                      display: 'flex',
                      gap: '1.2rem',
                      alignItems: 'center',
                    }}>
                    <StyledTitle style={{ margin: '0', fontSize: '2rem' }}>
                      Espace téléchargement
                    </StyledTitle>
                    {allStatusValid && (
                      <div
                        style={{ display: 'flex', gap: '1rem', cursor: 'pointer' }}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {
                          setShowUploadZone(prevState => !prevState);
                        }}
                        onClick={() => {
                          setShowUploadZone(prevState => !prevState);
                        }}>
                        {showUploadZone ? (
                          <>
                            <ChevronUp />
                          </>
                        ) : (
                          <>
                            <ChevronDown />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <Space marginTop="2rem" />
                  {showUploadZone && (
                    <>
                      <DropFileZone
                        chemId={id || ''}
                        maxFileSize={maxFileSize || 19 * 1_048_576}
                        acceptedFileFormat={acceptedFileFormat || ['']}
                        setChemiseHasError={setChemiseHasError}
                        onUploadSuccess={onUploadSuccess}
                        onAddDocumentClicked={() => {
                          setChemiseHasError(false);
                          setShowFileExplorer(!showFileExplorer);
                        }}
                      />
                      <Space marginTop="7rem" />
                      <Uploader
                        onUploadCancel={() => setShowFileExplorer(false)}
                        onUploadComplete={files => {
                          handleUploadedFiles(files);
                        }}
                        triggerUpload={showFileExplorer}
                        acceptedFileFormat={acceptedFileFormat || ['']}
                      />
                    </>
                  )}
                </>
              )}
              <>
                {(uploadedFilesName && uploadedFilesName.length > 0) ||
                (documentsUploadTransferes !== undefined &&
                  documentsUploadTransferes?.length > 0)
                  ? renderUploadedFilesName()
                  : null}
              </>
            </div>
          </div>
        </>
      </StyledChemiseConsultationWrapper>
      {chemiseHasError ? <StyledErrorWrapper>{errorMessage}</StyledErrorWrapper> : null}
    </>
  );
};

ChemiseConsultation.defaultProps = {
  id: '',
  libelle: '',
  icon: null,
  documentsUploadTransferes: undefined,
  errorMessage: null,
  uploadedFilesName: [],
  maxFileSize: 19 * 1_048_576,
  acceptedFileFormat: [''],
  listPJNonTransmis: [],
  hideValidPiece: false,
  isConsultation: false,
  onUploadSuccess: () => {
    console.log('default props');
  },
  onDelete: () => {
    console.log('default props');
  },
};

export default ChemiseConsultation;
