import { Dispatch, SetStateAction, useState } from 'react';
import { SuiviESignature } from 'types/packContractuelDtos';
import { getExistingClientFile } from 'utils/mapping/mapping';
import { clientFilesResult } from 'types/clientFileDtos';
import AuthorizationService from 'api/AuthorizationService';
import RefreshIcon from 'icons/RefreshIcon';
import { getSuiviESignature } from 'api/transfertDocumentService';
import { StyledESignRefreshLabel } from '../style';

interface RefreshContainerProps {
  numeroDossier: number;
  projetDetail?: clientFilesResult;
  refreshTransPieceComponent: Dispatch<SetStateAction<number>>;
  setSuiviESignature: Dispatch<SetStateAction<SuiviESignature>>;
}

const RefreshContainer: React.FC<RefreshContainerProps> = ({
  numeroDossier,
  projetDetail,
  setSuiviESignature,
  refreshTransPieceComponent,
}) => {
  const [followUpIsBeingCalled, setFollowUpIsBeingCalled] = useState<boolean>(false);

  const callFollowUp = async () => {
    setFollowUpIsBeingCalled(true);
    const userId = AuthorizationService.getUserInfo()?.uid || '';

    try {
      if (projetDetail) await getExistingClientFile(numeroDossier, projetDetail);
      const resSuivieEsignature = await getSuiviESignature(userId, numeroDossier);
      if (resSuivieEsignature.Success && resSuivieEsignature.Resultat) {
        setSuiviESignature(resSuivieEsignature.Resultat);
      }
      setFollowUpIsBeingCalled(false);
      refreshTransPieceComponent(prevState => prevState + 1);
    } catch (error) {
      console.error(error);
      alert(
        "Une erreur technique s'est produite. L'accès au suivi de la e-signature n'est pas disponible.",
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '1.6rem',
        alignItems: 'center',
        marginTop: '2rem',
        marginBottom: '2rem',
      }}>
      <StyledESignRefreshLabel
        style={{
          cursor: followUpIsBeingCalled ? 'not-allowed' : 'pointer',
          color: followUpIsBeingCalled ? '#6F757C' : '#268038',
        }}
        onKeyPress={() => {
          callFollowUp();
        }}
        onClick={() => {
          callFollowUp();
        }}
        role="button"
        tabIndex={0}>
        <RefreshIcon color={followUpIsBeingCalled ? '#6F757C' : '#268038'} />
        Actualiser les données
      </StyledESignRefreshLabel>
    </div>
  );
};

export default RefreshContainer;
