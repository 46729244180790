import Space from 'components/space/Space';
import React from 'react';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardTitle,
  StyleCardGrid,
  StyleCardGridItem,
} from 'containers/consultation/style';
import { UserInfos } from 'types';
import { InterlocutorState } from 'reducers/interlocutor/type';
import * as messages from '../../messages';

interface FinalisationInterlocuteurConsultationPros {
  user: UserInfos;
  interlocutor: InterlocutorState;
}

const FinalisationInterlocuteurConsultation: React.FC<
  FinalisationInterlocuteurConsultationPros
> = ({ user, interlocutor }) => {
  return (
    <>
      <StyledCardTitle>{messages.FINALISATION_INTERLOCUTEURS.titre}</StyledCardTitle>
      <Space marginBottom="1.6rem" />

      <StyleCardGrid>
        {interlocutor.monteurName && (
          <StyleCardGridItem>
            <StyledCardLabel>
              {messages.FINALISATION_INTERLOCUTEURS.monteur}
            </StyledCardLabel>
            <StyledCardData>{interlocutor.monteurName}</StyledCardData>
          </StyleCardGridItem>
        )}
        {interlocutor.interlocutorName && (
          <StyleCardGridItem>
            <StyledCardLabel>
              {messages.FINALISATION_INTERLOCUTEURS.vendeur}
            </StyledCardLabel>
            <StyledCardData>{interlocutor.interlocutorName.libelle}</StyledCardData>
          </StyleCardGridItem>
        )}
        {interlocutor.isMIOB && (
          <StyleCardGridItem
            style={{ width: 'calc(33% + 0.8rem)', wordWrap: 'break-word' }}>
            <StyledCardLabel>{messages.FINALISATION_INTERLOCUTEURS.miob}</StyledCardLabel>
            <StyledCardData>{interlocutor.miobName}</StyledCardData>
          </StyleCardGridItem>
        )}
      </StyleCardGrid>
    </>
  );
};

export default FinalisationInterlocuteurConsultation;
