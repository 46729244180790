import { useState } from 'react';
import { CodeLibelle, StatusInfoDashboard } from 'types';
import { clientFilesResult } from 'types/clientFileDtos';
import WaitingFilesCardSection from './WaitingFilesCardSection';
import { StyledWaitingFilesCard, StyledWaitingFilesCardTitle } from './styles';

interface WaitingFilesCardProps {
  niveauxProcess: CodeLibelle[];
  type: 'avant' | 'après';
  getFileDetailsAndNavigate: (
    numeroProjet: number,
    projetDetail: clientFilesResult,
  ) => void;
  listStatusInfo: StatusInfoDashboard[];
}

const WaitingFilesCard: React.FC<WaitingFilesCardProps> = ({
  type,
  niveauxProcess,
  getFileDetailsAndNavigate,
  listStatusInfo,
}) => {
  const [showMore, setShowMore] = useState('');

  const renderSection = () => {
    if (showMore) {
      const goodSection = listStatusInfo.find(statusInfo => statusInfo.id === showMore);
      if (goodSection) {
        return (
          <WaitingFilesCardSection
            label={goodSection.label}
            id={goodSection.id}
            showMore={showMore === goodSection.id}
            listeDossier={goodSection.listeDossier}
            niveauxProcess={niveauxProcess}
            getFileDetailsAndNavigate={getFileDetailsAndNavigate}
            setShowMore={setShowMore}
          />
        );
      }
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {listStatusInfo.map(statusInfo => {
          return (
            <WaitingFilesCardSection
              key={statusInfo.id}
              label={statusInfo.label}
              id={statusInfo.id}
              // showMore={showMore === 'H'}
              listeDossier={statusInfo.listeDossier}
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
              setShowMore={setShowMore}
            />
          );
        })}
      </div>
    );
  };

  return (
    <StyledWaitingFilesCard>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledWaitingFilesCardTitle>
          {`Dossiers en attente d'action ${type} édition de l’offre`}
        </StyledWaitingFilesCardTitle>
      </div>
      {renderSection()}
    </StyledWaitingFilesCard>
  );
};

export default WaitingFilesCard;
