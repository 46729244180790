import Space from 'components/space/Space';
import { hasCoBorrower } from 'containers/communs/utils';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyleCardGrid,
  StyleCardGridItem,
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledESIgnFollowupContainer,
  StyledHorizontalBar,
  StyledMainDisplayCard,
  StyledNoOtherIncomeContainer,
  StyledTotalData,
  StyledTotalLabel,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import { RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import {
  calculateTotalCharges,
  calculateTotalIncomes,
  calculateTotalReliableIncomes,
  formatValues,
} from 'utils/commun';
import * as messages from '../../messages';

interface RevenusEtChargesConsultationProps {
  isProjectEditable: boolean | undefined;
}

const RevenusEtChargesConsultation: React.FC<RevenusEtChargesConsultationProps> = ({
  isProjectEditable,
}) => {
  const [incomesAndExpenditures, setIncomesAndExpenditures] =
    useState<RessourcesMensuellesState>();
  const [totalIncomes, setTotalIncomes] = useState<string>();
  const [totalReliableIncomes, setTotalReliableIncomes] = useState<string>();
  const [totalCharges, setTotalCharges] = useState<string>();

  useEffect(() => {
    const incomesAndExpendituresFromSession =
      sessionStorage.getItem('ressourcesMensuelles');

    if (incomesAndExpendituresFromSession) {
      const incomesAndExpendituresObject = JSON.parse(
        incomesAndExpendituresFromSession,
      ) as RessourcesMensuellesState;
      setIncomesAndExpenditures(incomesAndExpendituresObject);

      setTotalIncomes(
        Number(calculateTotalIncomes(incomesAndExpendituresObject)).toLocaleString(),
      );
      setTotalReliableIncomes(
        Number(
          calculateTotalReliableIncomes(incomesAndExpendituresObject),
        ).toLocaleString(),
      );
      setTotalCharges(
        Number(calculateTotalCharges(incomesAndExpendituresObject)).toLocaleString(),
      );
    }
  }, []);

  const renderDirectIncomes = (borrower: boolean) => {
    const directIncomes = borrower
      ? incomesAndExpenditures?.borrowerIncome
      : incomesAndExpenditures?.coBorrowerIncome;

    return (
      <>
        {!borrower && <Space marginBottom="4.8rem" />}

        <StyledCardTitle>
          {borrower
            ? messages.MAIN_CARD_LABEL_BORROWER_INCOME
            : messages.MAIN_CARD_LABEL_COBORROWER_INCOME}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardRow style={{ gap: '4rem' }}>
          {directIncomes?.monthlyIncomes && (
            <>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_MONTHLY_INCOME}
                </StyledCardLabel>
                <StyledCardData>
                  {formatValues(directIncomes?.monthlyIncomes, '€')}
                </StyledCardData>
              </StyledCardTile>
              {directIncomes.reliableIncome && (
                <StyledCardTile>
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LABEL_RELIABLE_INCOME}
                  </StyledCardLabel>
                  <StyledCardData>
                    {formatValues(directIncomes?.reliableIncome, '€')}
                  </StyledCardData>
                </StyledCardTile>
              )}
            </>
          )}

          {directIncomes?.retirementMonthlyIncomes && (
            <StyledCardTile>
              <StyledCardLabel>
                {messages.MAIN_CARD_LABEL_MONTHLY_RETIREMENT_INCOME}
              </StyledCardLabel>
              <StyledCardData>
                {formatValues(directIncomes?.retirementMonthlyIncomes, '€')}
              </StyledCardData>
            </StyledCardTile>
          )}
        </StyledCardRow>
      </>
    );
  };

  const renderIncomes = () => {
    const borrower = true;
    const coBorrower = false;

    return (
      <>
        {renderDirectIncomes(borrower)}
        {hasCoBorrower() && renderDirectIncomes(coBorrower)}

        {incomesAndExpenditures?.hasOtherIncomes ? (
          <>
            <Space marginBottom="4.8rem" />

            <StyledCardTitle>
              {messages.MAIN_CARD_LABEL_OTHER_MONTHLY_INCOMES}
            </StyledCardTitle>
            <Space marginBottom="1.6rem" />
            <StyledCardRow style={{ gap: '4rem', flexWrap: 'wrap' }}>
              {incomesAndExpenditures.otherIncomes.map(otherIncome => {
                return (
                  <StyledCardTile key={otherIncome.code}>
                    <StyledCardLabel>{otherIncome.libelle}</StyledCardLabel>
                    <StyledCardData>
                      {formatValues(otherIncome.value, '€')}
                    </StyledCardData>
                  </StyledCardTile>
                );
              })}
            </StyledCardRow>
          </>
        ) : (
          <>
            <Space marginBottom="4rem" />
            <StyledNoOtherIncomeContainer>
              <span style={{ fontSize: '1.6rem', color: '#4B4F54' }}>
                {messages.NO_OTHER_INCOMES}
              </span>
            </StyledNoOtherIncomeContainer>
          </>
        )}

        <Space marginBottom="3.2rem" />

        <StyledESIgnFollowupContainer style={{ marginRight: '2.8rem' }}>
          <StyledCardRow style={{ gap: '0.8rem' }}>
            <StyledTotalLabel>{messages.MAIN_CARD_LABEL_TOTAL_INCOMES}</StyledTotalLabel>
            <StyledTotalData>{`${totalIncomes} €`}</StyledTotalData>
          </StyledCardRow>

          {(incomesAndExpenditures?.borrowerIncome?.reliableIncome ||
            incomesAndExpenditures?.coBorrowerIncome?.reliableIncome) && (
            <StyledCardRow style={{ gap: '0.8rem' }}>
              <StyledTotalLabel>
                {messages.MAIN_CARD_LABEL_TOTAL_RELIABLE_INCOMES}
              </StyledTotalLabel>
              <StyledTotalData>{`${totalReliableIncomes} €`}</StyledTotalData>
            </StyledCardRow>
          )}
        </StyledESIgnFollowupContainer>

        <StyledHorizontalBar style={{ height: '0.2rem' }} />
      </>
    );
  };

  const renderExpenditures = () => {
    return (
      <>
        {incomesAndExpenditures?.hasMonthlyCharges && (
          <>
            <Space marginBottom="4.8rem" />

            <StyledCardTitle>{messages.MAIN_CARD_LABEL_MONTHLY_CHARGES}</StyledCardTitle>
            <Space marginBottom="1.6rem" />
            <StyledCardRow style={{ gap: '4rem', flexWrap: 'wrap' }}>
              {incomesAndExpenditures.monthlyCharges.map(monthlyCharge => {
                return (
                  <StyledCardTile key={monthlyCharge.code}>
                    <StyledCardLabel>{monthlyCharge.libelle}</StyledCardLabel>
                    <StyledCardData>
                      {formatValues(monthlyCharge.value, '€')}
                    </StyledCardData>
                  </StyledCardTile>
                );
              })}
            </StyledCardRow>
          </>
        )}

        <Space marginBottom="3.2rem" />

        <StyledCardRow style={{ gap: '0.8rem' }}>
          <StyledTotalLabel>{messages.MAIN_CARD_LABEL_TOTAL_CHARGES}</StyledTotalLabel>
          <StyledTotalData>{`${totalCharges} €`}</StyledTotalData>
        </StyledCardRow>

        <StyledHorizontalBar style={{ height: '0.2rem' }} />
      </>
    );
  };

  const renderBankingInformation = () => {
    return (
      <>
        {incomesAndExpenditures?.banks && (
          <>
            <Space marginBottom="4.8rem" />

            <StyledCardTitle>{messages.MAIN_CARD_LABEL_BANK_INFORMATION}</StyledCardTitle>
            <Space marginBottom="1.6rem" />
            {incomesAndExpenditures.banks.map(bank => {
              return (
                <div key={bank.code}>
                  <StyleCardGrid>
                    <StyleCardGridItem>
                      <StyledCardLabel>
                        {messages.MAIN_CARD_LABEL_BANK_ACCOUNT}
                      </StyledCardLabel>
                      <StyledCardData>{bank.libelle}</StyledCardData>
                    </StyleCardGridItem>
                    <StyleCardGridItem key={bank.code}>
                      <StyledCardLabel>
                        {messages.MAIN_CARD_LABEL_BANk_ACCOUNT_OPENED_SINCE}
                      </StyledCardLabel>
                      <StyledCardData>{bank.year}</StyledCardData>
                    </StyleCardGridItem>
                  </StyleCardGrid>
                  <Space marginBottom="4.4rem" />
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <StyledMainDisplayCard>
        {renderIncomes()}
        {renderExpenditures()}
        {renderBankingInformation()}

        <Space marginBottom="5.6rem" />

        <ConsultationEditButton
          isProjectEditable={isProjectEditable}
          navigateRoute="/donnees_client"
          navigateDonneesClientStep="4"
        />
      </StyledMainDisplayCard>
    </>
  );
};

export default RevenusEtChargesConsultation;
