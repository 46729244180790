import styled from 'styled-components';

export const StyledTagContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  border: 1px solid #979797;
  border-radius: 8px;
  align-items: center;
  padding: 0.6rem 0.8rem;
`;

export const StyledTagLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  margin-right: 0.8rem;
`;

export const StyledDeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
