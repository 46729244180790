import { StyledTagContainer, StyledTagLabel } from './style';

interface ExpandableCardProps {
  label?: string;
  accentColor?: string;
}

const Tag: React.FC<ExpandableCardProps> = ({ label, accentColor }) => {
  return (
    <>
      <StyledTagContainer color={accentColor}>
        <StyledTagLabel>{label}</StyledTagLabel>
      </StyledTagContainer>
    </>
  );
};

export default Tag;
