interface SpaceProps {
  marginTop?: string;
  marginBottom?: string;
}
const Space: React.FC<SpaceProps> = ({ marginTop, marginBottom }) => {
  return (
    <div
      style={{
        marginTop: marginTop ? `${marginTop}` : '0',
        marginBottom: marginBottom ? `${marginBottom}` : '0',
      }}
    />
  );
};

export default Space;
