export const TITLE = 'Résultat de la faisabilité';
export const SUB_TITLE = '';

export const FINANCING_CARD_TITLE = 'Détail du plan de financement';

export const GROUP_INSURANCE_CARD_TITLE = 'Assurance Groupe';
export const INDIVIDUAL_INSURANCE_CARD_TITLE = 'Assurance Individuelle';
export const EMPTY_INSURANCE_CARD_TITLE = 'Sans Assurance';
export const INSURANCE_BORROWER_SUBTITTLE = 'Emprunteur';
export const INSURANCE_COBORROWER_SUBTITTLE = 'Co-emprunteur';
export const INDIVIDUAL_INSURANCE_FOOTER =
  "Vous avez fait le choix d’une couverture d'assurance individuelle. Nous vous laissons le soin de constituer le dossier auprès d'un organisme de votre choix.";

export const NO_INSURANCE_INFO_TEXT_1 =
  "Nous vous invitons à informer le client des risques auxquels il s'expose à ne pas y souscrire.";

export const NO_INSURANCE_INFO_TEXT_2_1 = 'En ';
export const NO_INSURANCE_INFO_TEXT_2_2 = 'cas de Décès';
export const NO_INSURANCE_INFO_TEXT_2_3 =
  ', les ayants droit (conjoint, enfants, ...) du client devront rembourser le solde du crédit.';

export const NO_INSURANCE_INFO_TEXT_3_1 = 'En ';
export const NO_INSURANCE_INFO_TEXT_3_2 =
  "cas d'Invalidité, de Maladie - Accident, ou de chômage ";
export const NO_INSURANCE_INFO_TEXT_3_3 =
  'le client devra continuer à rembourser chaque mois son crédit.';

export const PREVIOUS_BUTTON_LABEL = 'Retour';
export const EXIT_BUTTON_LABEL = `Clôturer dossier et revenir à l'accueil`;
export const NEXT_BUTTON_LABEL = 'Suivant';
export const RESULT_FOOTER = `Ce document est une simulation effectuée aux vues des éléments communiqués par le client et ne constitue en aucun cas un engagement du prêteur à consentir un crédit au client.\n(Données non contractuelles susceptibles de variations).`;
