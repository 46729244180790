import Space from 'components/space/Space';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledHorizontalBar,
  StyledMainDisplayCard,
  StyledNoOtherIncomeContainer,
  StyleNoDataContainer,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import { OperationState } from 'reducers/operationClient/types';
import * as messages from '../../messages';

interface IncidentsImpayesConsultationProps {
  isProjectEditable: boolean | undefined;
}

const IncidentsImpayesConsultation: React.FC<IncidentsImpayesConsultationProps> = ({
  isProjectEditable,
}) => {
  const [operationState, setOperationState] = useState<OperationState>();

  const LIST_FIRST_INCIDENT = ['SP', 'SA', 'RA'];
  const LIST_SECOND_INCIDENT = ['RD', 'MI', 'AT', 'RL'];

  useEffect(() => {
    const operationClientState = JSON.parse(
      sessionStorage.getItem('operationsClient') || '',
    ) as OperationState;

    if (operationClientState) setOperationState(operationClientState);
  }, []);

  const renderIncidentsImpayes = () => {
    return (
      <>
        <StyledCardTitle>
          {messages.MAIN_CARD_TITLE_UNRESOLVED_PAYMENT_INCIDENT}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyledCardRow style={{ flexWrap: 'wrap' }}>
          {operationState?.incidentPaymentList.map(incident => {
            if (LIST_FIRST_INCIDENT.includes(incident.code)) {
              return (
                <StyledCardTile key={incident.code}>
                  <StyledCardData>{incident.libelle}</StyledCardData>
                </StyledCardTile>
              );
            }
            return null;
          })}
        </StyledCardRow>

        {operationState?.incidentPaymentList.find(incident =>
          LIST_FIRST_INCIDENT.includes(incident.code),
        ) &&
          operationState?.incidentPaymentList.find(incident =>
            LIST_SECOND_INCIDENT.includes(incident.code),
          ) && (
            <>
              <Space marginBottom="1.6rem" />
              <StyledHorizontalBar
                color="#B1B5B9"
                style={{ height: '0.1rem', marginRight: '0' }}
              />
              <Space marginBottom="1.6rem" />
            </>
          )}

        <StyledCardRow style={{ flexWrap: 'wrap' }}>
          {operationState?.incidentPaymentList.map(incident => {
            if (LIST_SECOND_INCIDENT.includes(incident.code)) {
              return (
                <StyledCardTile key={incident.code}>
                  <StyledCardData>{incident.libelle}</StyledCardData>
                </StyledCardTile>
              );
            }
            return null;
          })}
        </StyledCardRow>

        <Space marginBottom="3.2rem" />

        {operationState?.nbPaymentDefault ? (
          <>
            <StyledCardTitle>
              {messages.MAIN_CARD_TITLE_UNRESOLVED_PAYMENT_DEFAULT}
            </StyledCardTitle>
            <Space marginBottom="1.6rem" />
            <StyledCardRow>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_TOTAL_UNRESOLVED_DEFAULTS_OCCURENCES}
                </StyledCardLabel>
                <StyledCardData>{operationState?.nbPaymentDefault || '0'}</StyledCardData>
              </StyledCardTile>
            </StyledCardRow>
          </>
        ) : (
          <>
            <StyledNoOtherIncomeContainer>
              <span style={{ fontSize: '1.6rem', color: '#4B4F54' }}>
                {messages.NO_OTHER_INCOMES}
              </span>
            </StyledNoOtherIncomeContainer>
          </>
        )}
      </>
    );
  };

  return operationState?.hasPaymentIncident || operationState?.hasPaymentDefault ? (
    <>
      <StyledMainDisplayCard>
        {renderIncidentsImpayes()}

        <ConsultationEditButton
          isProjectEditable={isProjectEditable}
          navigateRoute="/operation_client"
        />
      </StyledMainDisplayCard>
    </>
  ) : (
    <>
      <StyleNoDataContainer>{messages.NO_PAYMENT_INCIDENT}</StyleNoDataContainer>

      <Space marginBottom="2.4rem" />

      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <ConsultationEditButton
          isProjectEditable={isProjectEditable}
          navigateRoute="/operation_client"
        />
      </div>
    </>
  );
};

export default IncidentsImpayesConsultation;
