import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import Space from 'components/space/Space';
import { StyledInputLabel } from 'containers/finalisationProjet/style';
import { useEffect, useState } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';

interface LoanTypeChoiceProps {
  currentLoan: LoanAdditionalData;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanTypeChoice: React.FC<LoanTypeChoiceProps> = ({
  currentLoan,
  state,
  dispatch,
}) => {
  const [loanType, setLoanType] = useState<
    'creditAffecte' | 'personnel' | 'other' | undefined
  >(currentLoan?.loanType);

  useEffect(() => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, loanType };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data, loanCode: currentLoan.code },
    });
  }, [loanType]);

  const setSelectedLoanType = (selectedType: 'creditAffecte' | 'personnel' | 'other') => {
    if (loanType !== selectedType) {
      setLoanType(selectedType);
    } else {
      setLoanType(undefined);
    }
  };

  const renderLoanTypeChoice = () => {
    return (
      <>
        <StyledInputLabel>Sous-type de crédit</StyledInputLabel>
        <Space marginTop="1rem" />
        <div style={{ display: 'flex', gap: '3.7rem' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="creditAffecte"
              checked={loanType === 'creditAffecte' ? true : undefined}
              onClick={() => {
                setSelectedLoanType('creditAffecte');
              }}
            />
            <StyledInputLabel>Crédit affecté</StyledInputLabel>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="personnel"
              checked={loanType === 'personnel' ? true : undefined}
              onClick={() => {
                setSelectedLoanType('personnel');
              }}
            />
            <StyledInputLabel>Prêt personnel</StyledInputLabel>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="other"
              checked={loanType === 'other' ? true : undefined}
              onClick={() => {
                setSelectedLoanType('other');
              }}
            />
            <StyledInputLabel>Autre</StyledInputLabel>
          </div>
        </div>
      </>
    );
  };
  return <>{renderLoanTypeChoice()}</>;
};

export default LoanTypeChoice;
