import CardLine from 'components/expandableCard/CardLine';
import CardTile from 'components/expandableCard/CardTile';
import ExpandableCardTer from 'components/expandableCard/ExpandableCardTer';
import { StyledBadgeWrapper } from 'containers/finalisationProjet/style';
import { sortLongText } from 'containers/finalisationProjet/utils';
import { getDateFromInput, getMonthsRemaining } from 'utils/DateUtils';
import ActionValidatedIcon from 'icons/ActionPlusIcon';
import React, { Dispatch, useEffect, useState } from 'react';
import {
  EmissionPackAction,
  EmissionPackCardInfo,
  EmissionPackState,
} from 'reducers/emissionPack/types';
import { LoanAdditionalInformationState } from 'reducers/loanAdditionalInformation/type';
import { OperationState } from 'reducers/operationClient/types';
import Space from 'components/space/Space';
import { getLoanerAddress } from 'api/referentialService';
import { FullAddress } from 'types';
import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import EditIcon from 'icons/EditIcon';
import BaseButton from 'components/designSystem/BaseButton';
import { formatNumberByThousand, getFullAddress } from 'utils/commun';
import AddressBlock, { checkifAddressOk } from 'components/addressBlock/AddressBlock';

interface borrowersInfosProps {
  displayState: EmissionPackState;
  displayDispatch: Dispatch<EmissionPackAction>;
  dispatch: Dispatch<EmissionPackAction>;
}

const LoanCards: React.FC<borrowersInfosProps> = ({
  displayState,
  displayDispatch,
  dispatch,
}) => {
  const [expandedCard, setExpandedCard] = useState<number>(-1);
  const [editedCard, setEditedCard] = useState<number>(-1);

  const handleFullAddressClick = (loanerIndex: number, value: FullAddress) => {
    displayDispatch({
      type: 'setLoanerFullAddress',
      payload: { loanerIndex, value },
    });

    if (
      displayState.buybackLoans &&
      displayState.buybackLoans[loanerIndex] &&
      displayState.buybackLoans[loanerIndex].address.isAddressOk
    ) {
      dispatch({
        type: 'setLoanerFullAddress',
        payload: { loanerIndex, value },
      });
    } else {
      dispatch({
        type: 'setLoanerFullAddress',
        payload: { loanerIndex, value: {} as FullAddress },
      });
    }
  };

  const getLoanCardViews = (): void => {
    const cardViews = [] as EmissionPackCardInfo[];

    const operationClient = sessionStorage.getItem('operationsClient');
    const operationState = JSON.parse(operationClient || '') as OperationState;

    const loansAdditionalInfos = sessionStorage.getItem('loanAdditionalInformation');
    const loansAdditionalInfosState = JSON.parse(
      loansAdditionalInfos || '',
    ) as LoanAdditionalInformationState;

    operationState.loanList.forEach(loan => {
      if (loan.buyBack && loan.creditType?.code === 'R') {
        const loanAdditionalInfo = loansAdditionalInfosState.loans.find(
          additionalInfos => {
            return additionalInfos.code === loan.code;
          },
        );

        const address = { isAddressOk: false } as FullAddress;

        cardViews.push({
          loan,
          loanAdditionalInfo,
          address,
        } as EmissionPackCardInfo);
      }
    });

    const validatedLoans = [...cardViews];

    cardViews.forEach(async (cardview, index) => {
      const result = await getLoanerAddress(Number(cardview.loan.loaner?.code));
      const newCardView = { ...cardview };
      if (result.Success && result.Resultat.etabPstCod.toString() !== '0') {
        newCardView.address.streetNumber = result.Resultat.etabNumVoie;
        newCardView.address.streetType = {
          code: result.Resultat.etabTypeVoie,
          libelle: '',
        };
        newCardView.address.street = result.Resultat.etabNomVoie;
        newCardView.address.additionalInformation = result.Resultat.etabAdrLig2;
        newCardView.address.zipCode = result.Resultat.etabPstCod;
        newCardView.address.city = result.Resultat.etabLoca;
        if (checkifAddressOk(newCardView.address)) {
          newCardView.address.isAddressOk = true;
          validatedLoans[index] = newCardView;
        } else {
          newCardView.address.street = '';
          newCardView.address.additionalInformation = '';
          newCardView.address.zipCode = '';
          newCardView.address.city = '';
          newCardView.address.isAddressOk = false;
          validatedLoans[index] = newCardView;
        }
      } else {
        newCardView.address.street = '';
        newCardView.address.additionalInformation = '';
        newCardView.address.zipCode = '';
        newCardView.address.city = '';
        newCardView.address.isAddressOk = false;
        validatedLoans[index] = newCardView;
      }

      dispatch({
        type: 'setBuybackLoans',
        payload: validatedLoans,
      });

      displayDispatch({
        type: 'setBuybackLoans',
        payload: cardViews,
      });
    });
  };

  useEffect(() => {
    getLoanCardViews();
  }, []);

  const getBadge = (valid: boolean) => {
    if (valid) {
      return (
        <StyledBadgeWrapper style={{ color: '#28833C' }}>
          <ActionValidatedIcon color="#28833C" />
          <span>Données renseignées</span>
        </StyledBadgeWrapper>
      );
    }
    return (
      <StyledBadgeWrapper style={{ color: '#E78A08' }}>
        <ActionValidatedIcon color="#E78A08" />
        <span>Données à compléter</span>
      </StyledBadgeWrapper>
    );
  };

  const getTitulaire = (titulaire: 'borrower' | 'coBorrower' | 'both' | undefined) => {
    switch (titulaire) {
      case 'borrower':
        return sortLongText('Emprunteur');
      case 'coBorrower':
        return sortLongText('Co-emprunteur');
      case 'both':
        return sortLongText('Les deux');
      default:
        return '';
    }
  };

  const isCardHidden = (loan: EmissionPackCardInfo): boolean => {
    return loan.loan.code !== expandedCard;
  };

  const isCardEdited = (loan: EmissionPackCardInfo): boolean => {
    return loan.loan.code === editedCard;
  };

  const getRemainingMonths = (loan: EmissionPackCardInfo) => {
    if (loan.loan.loanStartDate && loan.loan.totalLoanDuration) {
      return (
        Number(loan.loan.totalLoanDuration) -
        getMonthsRemaining(getDateFromInput(loan.loan.loanStartDate || ''), new Date())
      ).toString();
    }
    return '';
  };

  const renderAddressTiles = (loan: EmissionPackCardInfo) => {
    return (
      <>
        <CardLine
          hidden={isCardHidden(loan)}
          code={`5-${loan.loan.code}`}
          onExpand={() => {
            if (expandedCard !== loan.loan.code) setExpandedCard(loan.loan.code);
            else setExpandedCard(-1);
          }}>
          <CardTile label="Adresse" data={getFullAddress(loan.address)} />
          <CardTile label="Code postal" data={loan.address.zipCode?.toString()} />
          <CardTile label="Ville" data={loan.address.city} />
        </CardLine>

        {!isCardHidden(loan) ? <Space marginBottom="3.2rem" /> : null}
      </>
    );
  };

  const renderAddressForm = (loanerIndex: number) => {
    return (
      <>
        <AddressBlock
          address={displayState.buybackLoans?.at(loanerIndex)?.address || {}}
          setAddress={newAddress => {
            handleFullAddressClick(loanerIndex, newAddress);
          }}
          alternateCityInput
        />
      </>
    );
  };

  const renderAddressSection = (
    loan: EmissionPackCardInfo,
    loanIndex: number,
    cardMode: 'create' | 'view',
  ) => {
    return cardMode === 'create' && !isCardHidden(loan)
      ? renderAddressForm(loanIndex)
      : renderAddressTiles(loan);
  };

  const renderCardButton = (loan: EmissionPackCardInfo, mode: 'create' | 'view') => {
    if (mode === 'create') {
      return (
        <>
          <Space marginBottom="4rem" />
          {loan.address.isAddressOk ? (
            <BaseButton
              style={{ width: '13.7rem' }}
              tabIndex={0}
              onKeyDownCapture={event => {
                if (event.key === 'Enter') {
                  setEditedCard(-1);
                }
              }}
              onClick={() => setEditedCard(-1)}>
              <ActionValidatedIcon />
              <span style={{ marginLeft: '1rem' }}>Valider</span>
            </BaseButton>
          ) : (
            <BaseButton disabled className="disabled-btn" style={{ width: '13.7rem' }}>
              <ActionValidatedIcon />
              <span style={{ marginLeft: '1rem' }}>Valider</span>
            </BaseButton>
          )}
        </>
      );
    }
    return (
      <>
        <StyledSecondaryButton
          onClick={() => setEditedCard(loan.loan.code)}
          style={{ display: 'flex', gap: '0.5rem', width: '13.7rem' }}>
          <EditIcon />
          <span>Modifier</span>
        </StyledSecondaryButton>
      </>
    );
  };

  const renderLoanCard = (loan: EmissionPackCardInfo, loanIndex: number) => {
    const cardMode = !loan.address || isCardEdited(loan) ? 'create' : 'view';

    return (
      <>
        <ExpandableCardTer
          accentColor={!loan.address.isAddressOk ? '#E78A08' : '#28833C'}
          badge={getBadge(loan.address.isAddressOk || false)}>
          <div style={{ marginLeft: '2.4rem' }}>
            <CardLine
              addChevron
              hidden={false}
              code={`1-${loan.loan.code}`}
              onExpand={() => {
                if (expandedCard !== loan.loan.code) setExpandedCard(loan.loan.code);
                else setExpandedCard(-1);
              }}>
              <CardTile label="Organisme de crédit" data={loan.loan.loaner?.libelle} />
              <CardTile label="Type de crédit" data={loan.loan.creditType?.libelle} />
              <CardTile
                label="Mensualité du prêt"
                data={formatNumberByThousand(loan.loan.monthlyPayment || '')}
                unit="€"
              />
              <CardTile
                label="Solde"
                data={formatNumberByThousand(loan.loan.balance || '')}
                unit="€"
              />
            </CardLine>

            {!isCardHidden(loan) ? <Space marginBottom="3.2rem" /> : null}

            <CardLine
              hidden={isCardHidden(loan)}
              code={`2-${loan.loan.code}`}
              onExpand={() => {
                if (expandedCard !== loan.loan.code) setExpandedCard(loan.loan.code);
                else setExpandedCard(-1);
              }}>
              <CardTile
                label="Capital initial du prêt "
                data={formatNumberByThousand(loan.loan.initialLoanCapital || '')}
                unit="€"
              />
              <CardTile
                label="Titulaire"
                data={getTitulaire(loan.loanAdditionalInfo?.titulaire)}
              />
              <CardTile label="Date de début du prêt" data={loan.loan.loanStartDate} />
            </CardLine>

            {!isCardHidden(loan) ? <Space marginBottom="3.2rem" /> : null}

            <CardLine
              hidden={isCardHidden(loan)}
              fillFlexSize={2}
              code={`3-${loan.loan.code}`}
              onExpand={() => {
                if (expandedCard !== loan.loan.code) setExpandedCard(loan.loan.code);
                else setExpandedCard(-1);
              }}>
              <CardTile
                label="Durée totale du prêt"
                flexSize={3}
                data={loan.loan.totalLoanDuration}
                unit="mois"
              />
              <CardTile
                label="Durée restante"
                flexSize={3}
                data={getRemainingMonths(loan)}
                unit="mois"
              />
              <CardTile
                label="Indemnités de Remboursement par Anticipation"
                flexSize={4}
                data={formatNumberByThousand(
                  loan.loanAdditionalInfo?.indemnityAmount?.toString() || '',
                )}
                unit="€"
              />
            </CardLine>

            {!isCardHidden(loan) ? <Space marginBottom="3.2rem" /> : null}

            <CardLine
              hidden={isCardHidden(loan)}
              code={`4-${loan.loan.code}`}
              onExpand={() => {
                if (expandedCard !== loan.loan.code) setExpandedCard(loan.loan.code);
                else setExpandedCard(-1);
              }}>
              <CardTile label="N° de prêt" data={loan.loanAdditionalInfo?.loanNumber} />
              <CardTile
                label="Taux d’intérêt nominal"
                data={loan.loanAdditionalInfo?.nominalInterestRate}
                unit="%"
              />
              <CardTile
                label="Délai de préavis"
                data={loan.loanAdditionalInfo?.nbMonthsForNotice?.toString()}
                unit="mois"
              />
            </CardLine>

            {!isCardHidden(loan) ? <Space marginBottom="3.2rem" /> : null}

            {loan.loan.creditType?.code === 'R'
              ? renderAddressSection(loan, loanIndex, cardMode)
              : null}

            {!isCardHidden(loan) ? renderCardButton(loan, cardMode) : null}

            <Space marginBottom="3.2rem" />
          </div>
        </ExpandableCardTer>
      </>
    );

    return <></>;
  };

  const renderLoanCards = () => {
    return (
      <>
        <div style={{ maxWidth: '71.2rem' }}>
          {displayState.buybackLoans?.map((loan, index) => {
            return (
              <React.Fragment key={loan.loan.code}>
                {renderLoanCard(loan, index)}
                <Space marginBottom="2.4rem" />
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  };

  return <>{renderLoanCards()}</>;
};

export default LoanCards;
