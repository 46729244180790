import axios from 'axios';
import { ConfigValues } from 'types';

const getEnvVariables = async (): Promise<ConfigValues> => {
  try {
    const response = await axios.get('/assets/config.json', {});
    return await Promise.resolve(response.data);
  } catch (err) {
    throw Error(
      `Error occured when getting config from the /assets/config.json from the server. Reason: ${err}`,
    );
  }
};

export default getEnvVariables;
