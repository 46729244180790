import { CodeLibelle } from 'types';
import {
  checkFormatDate,
  getDateFromInput,
  isAdult,
  isDateIntheFuture,
} from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import { IdentityState } from './types';

const noNumbers = new RegExp(ValidationRules.noNumbers);
const noSpecialCharacters = new RegExp(ValidationRules.noSpecialCharacters);
const notEmpty = new RegExp(ValidationRules.notEmpty);

const isLastNameOkay = (lastNameParam: string | undefined): boolean => {
  if (lastNameParam === '' || lastNameParam === undefined) return false;
  if (!noNumbers.test(lastNameParam)) return false;
  if (!noSpecialCharacters.test(lastNameParam)) return false;
  return true;
};

const isBirthNameOkay = (birthNameParam: string | undefined): boolean => {
  if (birthNameParam === '' || birthNameParam === undefined) return false;
  if (!noNumbers.test(birthNameParam)) return false;
  if (!noSpecialCharacters.test(birthNameParam)) return false;

  return true;
};

const isFirstNameOkay = (firstNameParam: string | undefined): boolean => {
  if (firstNameParam === '' || firstNameParam === undefined) return false;
  if (!noNumbers.test(firstNameParam)) return false;
  if (!noSpecialCharacters.test(firstNameParam)) return false;
  return true;
};

const isBirthDateOkay = (birthDateParam: string | undefined): boolean => {
  if (birthDateParam === '' || birthDateParam === undefined) return false;
  const birthDateString = birthDateParam as string;
  const birthDate = getDateFromInput(birthDateString);

  if (birthDate && !checkFormatDate(birthDateParam)) return false;
  if (!isDateIntheFuture(birthDate)) return false;
  if (!isAdult(birthDate) || birthDate.getFullYear() < 1901) return false;

  return true;
};

export const isCountryOkay = (countryParam: CodeLibelle | undefined): boolean => {
  if (countryParam?.code === '' || countryParam === undefined) return false;
  if (countryParam.code === 'err') return false;
  return true;
};

const isBirthDepartmenytOkay = (
  birthCountryCodeParam: CodeLibelle | undefined,
  birthDepartmentParam: CodeLibelle | undefined,
): boolean => {
  if (!birthCountryCodeParam || birthCountryCodeParam.code === 'FR') {
    // si le pays de naissance est la france, le département est obligatoire
    if (!birthDepartmentParam || birthDepartmentParam.code === 'err') return false;
  }
  return true;
};

const isBirthCityOkay = (birthCityParam: CodeLibelle | undefined): boolean => {
  if (birthCityParam === undefined || birthCityParam?.libelle === '') return false;
  if (!notEmpty.test(birthCityParam.libelle)) return false;
  if (!noNumbers.test(birthCityParam.libelle)) return false;
  if (birthCityParam.code === '99' && !noSpecialCharacters.test(birthCityParam?.libelle))
    return false;
  if (birthCityParam.code === 'err') return false;
  return true;
};

const isNationalityOkay = (nationalityParam: CodeLibelle | undefined): boolean => {
  if (nationalityParam?.code === '' || nationalityParam === undefined) return false;
  if (nationalityParam.code === 'err') return false;
  return true;
};

export const checkIfDatasAreOk = (state: IdentityState): boolean => {
  const {
    civility,
    lastName,
    firstName,
    isBirthNameSameAsName,
    birthName,
    birthDate,
    nationality,
    birthCountry,
    birthDepartment,
    birthCity,
  } = state;
  if (
    civility &&
    isLastNameOkay(lastName) &&
    isFirstNameOkay(firstName) &&
    (isBirthNameSameAsName || isBirthNameOkay(birthName)) &&
    isBirthDateOkay(birthDate) &&
    isCountryOkay(birthCountry) &&
    isBirthDepartmenytOkay(birthCountry, birthDepartment) &&
    isBirthCityOkay(birthCity) &&
    isNationalityOkay(nationality)
  ) {
    return true;
  }
  return false;
};

export default isBirthDateOkay;
