import {
  clientFilesResult,
  ListePiecesJustificativesPropCialUploadableGetDossier,
} from './clientFileDtos';
import { ErrorMessage, EtatCivil } from './FaisabiliteDTO';

export type CodeLibelle = {
  code: string;
  libelle: string;
};

export type CodeLibelleDetail = {
  code: string;
  libelle: string;
  ordreString: string;
};

export interface Assurance {
  typeAssuranceChoisie: string;
  garantieDeces: string;
  quotiteDeces: number;
  garantiePTIA: string;
  quotitePTIA: string;
  garantieITT: string;
  quotiteITT: number;
  garantiePerteEmploi: string;
  quotitePerteEmploi: string;
  delaiFranchise: string;
  echeanceAssuranceDeleguee: number;
}

export enum AssuranceEnum {
  GroupeConseillee,
  GroupePersonnalisee,
  Individuelle,
  SansAssurance,
}

export type Bank = CodeLibelle & {
  year?: string;
  id?: string;
};

export type BankDebtType = Bank & {
  accountNumber?: string;
  accountHolder?: string;
  amount?: string;
  dueDate?: string;
};

export interface Bordereau {
  reference: string;
  partenaireProprietaire: string;
  nomCourtier: string;
  perComi: string;
  typeBordereau: string;
  numeroFacture: string;
}

export interface ConfigValues {
  baseUrlApim: string;
  govRefBaseUrl: string;
  transfertDocumentBaseUrl: string;
  pingDomain: string;
  pingClientId: string;
  pingClientSecret: string;
  consultationUrl: string;
  faisabiliteUrl: string;
  transfertOctroiUrl: string;
  fichageEmprunteursUrl: string;
  ebizUrl: string;
  timeout?: number;
}

export interface Echeancier {
  duree: number;
  echeance: number;
}

export interface GetBordereauApiRequestBody {
  infosCourtier: InfosCourtier;
  dateMin: Date;
  dateMax: Date;
  listeAPAsGroupJur: string[];
}

export interface GetBordereauApiResponse {
  result: Bordereau[];
  success: boolean;
  error: string[];
}

export interface GetBordereauDocumentApiRequestBody {
  courtier: InfosCourtier;
  reference: string;
  partenaireProprietaireTab: string;
}

export interface GetBordereauDocumentApiResponse {
  result: {
    affichagePartiel: boolean;
    documentFusionne: string;
    extension: string;
  };
  success: boolean;
  errors: string[];
}

export interface GetPartenerApiRequestBody {
  userId: string;
  contextApp: string;
}

export interface InfosCourtier {
  userId: string;
  contextApp: string;
}

export interface Ressource {
  idDetlChrgRess: string;
  montant: number;
  idProsTitu: number;
  idProsCjtTitu: number;
}

export interface GetInsuranceApiRequest {
  contextApp: string;
  emprunteur: {
    ageEmp: number;
    codeCsp: string;
    codePost: number;
    dateNaiss: Date;
    idPros: number;
    idStatutSal: string;
    indTypeAssu: string;
    idTypeContTrav: string | null;
  };
  coEmprunteur: {
    ageEmp: number;
    codeCsp: string;
    codePost: number;
    dateNaiss: Date;
    idPros: number;
    idStatutSal: string;
    indTypeAssu: string;
    idTypeContTrav: string | null;
  };
  listeRessNonPartEmprunteur: [
    {
      mtDeclare: number;
    },
  ];
  listeRessNonPartCoEmprunteur: [
    {
      mtDeclare: number;
    },
  ];
  propositionCommercial: {
    mtAFin: number;
    dureeSouhaitee: number;
  };
  listeRessPart: Ressource[];
  typeGar: string;
}

export interface InsuranceResult {
  borrower: {
    assuranceEmprunteurPreconisee: Assurance;
  };
  coBorrower: {
    assuranceEmprunteurPreconisee: Assurance;
  };
}

export interface GetInsuranceApiResponse {
  Resultat: InsuranceResult;
  Success: boolean;
  ListeMessages: string[];
}

export interface ProduitCommercial {
  idProduitCommercial: string;
  libelleProduitCommercial: string;
  dureeTauxFixeVente: string;
  dureeMin: string;
  dureeMax: string;
  dureeTotMax: string;
  dureeDiffereVente: string;
}

export interface DureesPalier1Autorisees {
  pretTotDurMin: string;
  pretTotDurMax: string;
  palDur1Max: string;
}

export interface BaremesTarification {
  tauxBareme: number;
  dureeMinPret: number;
  dureeMaxPret: number;
  montantAutoriseMin: number;
  montantAutoriseMax: number;
}

export interface LoanInformation {
  statut: string;
  numeroProjet: string;
  identifiantEmprunteur: string;
  identifiantCoEmprunteur: string;
  libelleSegmentation: string;
  niveauSegmentation: string;
  profilClient: string;
  fraisComOuverture: string;
  fraisTenuCompte: string;
  fraisActe: string;
  fraisIntermediation: string;
  pourcentageFraisIntermediation: string;
  montantTotalPret: string;
  produitCommercial: ProduitCommercial;
  dureesPalier1Autorisees: DureesPalier1Autorisees[];
  baremesTarification: BaremesTarification[];
}

export interface GetLoanInformationApiResponse {
  Resultat: LoanInformation;
  Success: boolean;
  ListeMessages: ErrorMessage[];
}

export interface MinMax {
  min: string;
  max: string;
}

export interface Partner {
  idAPA: string;
  numeroVendeur: string;
  idIntv: number;
  nomCourtier: string;
}

export type Profession = CodeLibelleDetail;

export type Resultat = CodeLibelle & { ordre: number; ordreString: string };

type ResultatOperation = Resultat & { amount: number; type: string };

export interface ReferentialApiResponse {
  Resultat: Resultat[];
  Success: boolean;
  ListeMessages: string[];
}

export interface OperationReferentialApiResponse {
  Resultat: ResultatOperation[];
  Success: boolean;
  ListeMessages: string[];
}

export interface ResultatDqeEmail {
  email: string;
  identifiantEmprunteur: number;
  userId: string;
}

export interface DqeEmailApiREsponse {
  Resultat: ResultatDqeEmail;
  Success: boolean;
  ListeMessages: ErrorMessage[];
}

export interface ReferentialSuccessResponse {
  Success: boolean;
  ListeMessages: ListeMessage[];
}

export type GetCountriesApiResponse = ReferentialApiResponse;
export type GetDepartmentsApiResponse = ReferentialApiResponse;
export type GetBankssApiResponse = ReferentialApiResponse;
export type GetChargesApiResponse = ReferentialApiResponse;
export type GetFamilySituationApiResponse = ReferentialApiResponse;
export type GetJobContractsApiResponse = ReferentialApiResponse;
export type GetJobsApiResponse = ReferentialApiResponse;
export type GetLodgingsApiResponse = ReferentialApiResponse;
export type GetRessourcesApiResponse = ReferentialApiResponse;
export type GetHomeLoanPurposesApiResponse = ReferentialApiResponse;
export type GetUsageBienApiResponse = ReferentialApiResponse;
export type GetNationalitiesApiResponse = ReferentialApiResponse;
export type GetLoanUsageTypesApiResponse = ReferentialApiResponse;

export type Country = CodeLibelle;

export type Department = CodeLibelle;

export type City = CodeLibelle;

export type Nationality = CodeLibelle;

export enum SortEnum {
  ASC = 1,
  DESC,
}

export type SessionStorageKeys =
  | 'borrowerIdentity'
  | 'coBorrowerIdentity'
  | 'situationProfessionnelle'
  | 'situationFoyer'
  | 'ressourcesMensuelles'
  | 'operationsClient'
  | 'loan'
  | 'asset'
  | 'conditionsFinancieres'
  | 'loanInformationsByIntermediationFee'
  | 'intermediationFeeRate'
  | 'coBorrowerExists'
  | 'simulationResult'
  | 'finalisationProjet'
  | 'loanAdditionalInformation'
  | 'guaranteeAdditionalInformation'
  | 'debtAdditionalInformation'
  | 'iban'
  | 'interlocutor'
  | 'numeroProjet'
  | 'borrowerId'
  | 'coBorrowerId'
  | 'transmissionOctroiInformation'
  | 'emissionPack'
  | 'transmissionPieces';

export interface OrionErrorMessage {
  MessageId: number;
  MessageType: 'E' | 'I' | 'T';
  MessageLib: string;
}

export type TypeContrat = CodeLibelleDetail;

export interface User {
  uid: string;
  sub: string;
  given_name: string;
  family_name: string;
}

export interface DecodedAuthToken {
  scope: [];
  client_id: string;
  iss: string;
  aud: string;
  sub: string;
  uid: string;
  operational_vendor_number_list: [];
  partner_domain: string;
  phone_number: string;
  account_status: string;
  given_name: string;
  login: string;
  family_name: string;
  email: string;
  exp: number;
}

export interface UserInfos {
  uid: string;
  codeHabilitation: string;
  codePostalCourtier: number;
  dmatInd: string;
  habiESignInd: string;
  habiPackInd: string;
  idAPA: number;
  idAPADossier?: number;
  lstTierAPA: LstTierApa[];
  nomCourtier: string;
  nomTiersAPA: string;
  numContremarque: number;
  prenomTiersAPA: string;
  rangTiers: number;
  villeCourtier: string;
}

export interface GetAuthorizationResp {
  Resultat: UserInfos;
  Success: boolean;
  ListeMessages: [];
}

export interface AuthorizedApa {
  idAPA: number;
  numContremarqueAPA: number;
  nomAPA: string;
  codePostalAPA: number;
  villeAPA: string;
  libAPA: string;
}

export interface GetAuthorizedApaResp {
  Resultat: AuthorizedApa[];
  Success: boolean;
  ListeMessages: [];
}

export interface LstTierApa {
  tierRang: number;
  tierNom: string;
  tierPrenom: string;
  tierEmail: string;
  tierNumTel: string;
}

export default DecodedAuthToken;

export type PartnerSortingKey = 'nomCourtier' | 'numeroVendeur' | 'idIntv';
export type BordereauSortingKey = 'nomCourtier' | 'perComi' | 'typeBordereau';

export type SortingCommunParameters = {
  listToSort: Partner[] | Bordereau[];
  sortingKey: PartnerSortingKey | BordereauSortingKey;
  sortEnumState: (value: React.SetStateAction<SortEnum>) => void;
  activeSortingKeyState: (
    value: React.SetStateAction<PartnerSortingKey | BordereauSortingKey | undefined>,
  ) => void;
  sortEnumValue: SortEnum;
  displayedItemsState: (value: React.SetStateAction<Partner[] | Bordereau[]>) => void;
  currentOrderingState: (value: React.SetStateAction<Partner[] | Bordereau[]>) => void;
};

export interface PJWithStatus {
  libelle: string;
  commantaire?: string;
  status?: string;
}
export interface ListePiecesJustificative {
  pceJusLib: string;
  cpltPceJusLib: string;
  statPceJusLib: string;
  pceJusCmtLib: string;
}

export interface ListePiecesJustificativesPropCialUploadable {
  chemId: string;
  chemLib: string;
  listePiecesJustificatives: ListePiecesJustificative[];
}

export interface TransfertOctroiResultat {
  listePiecesJustificativesPropCialUploadable: ListePiecesJustificativesPropCialUploadable[];
  listePiecesJustificativesPropCialNonUploadables: ListePiecesJustificative[];
}

export interface PiecesJustificatives {
  listePiecesJustificativesPropCialUploadable: ListePiecesJustificativesPropCialUploadableGetDossier;
  listePiecesJustificativesPropCialNonUploadables: ListePiecesJustificative[];
}

export interface TransfertOctroiApiResponse {
  Resultat: TransfertOctroiResultat;
  Success: boolean;
  ListeMessages: OrionErrorMessage[];
}

export interface TransfertOctroiApiRequestBody {
  numeroContremarque: string;
  numeroProjet: string;
  userId: string;
  indicateurEtudeClassique: string;
}

export class Address {
  streetNumber?: number;

  address?: string;

  addressAdditionalInfo?: string;

  zipCode?: number;

  city?: string;

  protected getAddress = (): string => {
    return `${this.streetNumber} ${this.address}`;
  };
}

export interface FullAddress {
  streetNumber?: string;
  streetType?: CodeLibelle;
  street?: string;
  additionalInformation?: string;
  POBoxOrHamlet?: string;
  zipCode?: string;
  city?: string;
  isAddressOk?: boolean;
}

export interface Rib {
  iban: string;
  bic: string;
}
export interface GetUploadUrlApiRequestParams {
  IdProjet: number;
  AdheNum?: string;
  ContextApp: string;
}

export interface GetUploadUrlApiResponse {
  success: boolean;
  errors: {
    MessageId: number;
    MessageType: 'E' | 'I' | 'T';
    MessageLib: string;
  }[];
  result: string;
}

export interface UploadFileApiRequestBody {
  fileName: string;
  chemiseId: string;
  fileContent: File;
}

interface HttpStatus {
  status: number;
}

interface LoanerAddress {
  etabAdrLig2: string;
  etabAdrLig4: string;
  etabNumVoie: string;
  etabNomVoie: string;
  etabTypeVoie: string;
  etabLoca: string;
  etabPstCod: string;
}

export interface LoanerAddressResp {
  Success: boolean;
  ListeMessages: {
    MessageId: number;
    MessageType: 'E' | 'I' | 'T';
    MessageLib: string;
  }[];
  Resultat: LoanerAddress;
}

export type UploadFileApiResponse = HttpStatus;
export type TransfertAllApiResponse = HttpStatus;

export interface PieceIdentite {
  typeIdentite: string;
  numeroIdentite: string;
  dateIdentite?: string;
  dateFinIdentite?: string;
  lieuIdentite?: string;
}

export interface Adresse {
  complement?: string;
  designationBatiment?: string;
  numero?: string;
  typeVoie?: string;
  nomVoie?: string;
  boitePostalLieuDit?: string;
  codePostal?: number;
  ville?: string;
}

export interface Coordonnees {
  adresse: Adresse;
  telephoneDomicile?: string;
  telephonePortable: string;
  email: string;
}

export interface CoordonneesBancaires {
  bic: string;
  iban: string;
}

export interface SituationProfessionnelle {
  telephoneEmployeur?: string;
  employeur: string;
  adresse?: Adresse;
}

export interface PersonAdditionnalInformation {
  pieceIdentite?: PieceIdentite;
  coordonnees?: Coordonnees;
  coordonneesBancaires?: CoordonneesBancaires;
  situationProfessionnelle?: SituationProfessionnelle;
}

export interface Credit {
  taux?: string;
  societe?: string;
  solde?: string;
  mensualite?: string;
  nature?: string;
  pretRestructureIndicateur?: string;
  indicateurInscrit?: string;
  montantInitialPret?: string;
  datePret?: Date;
  dureeActualisee?: string;
  numeroPret?: string;
  sousType?: string;
  natureBien?: string;
  usageBien?: string;
  indemniteRemboursementAnticipe?: string;
  delaisPreavis?: string;
  titulaire?: string;
  rachatIndicateur?: string;
}

export interface DecouvertsBancaire {
  nomEtablissementBancaire: string;
  numeroCompteBancaire: string;
  titulaireCompteBancaire: string;
  montant: string;
  dateExigibilite: Date;
}

export interface Dette {
  type?: string;
  montant?: string;
  reference?: string;
  civiliteCreancier?: string;
  identiteCreancier?: string;
  dateExigibilite?: Date;
  decouvertsBancaires?: DecouvertsBancaire[];
  adresse?: Adresse;
}

export interface Projet {
  credits: Credit[];
  dettes: Dette[];
  jour_prelevement?: number;
}

export interface Notaire {
  nom?: string;
  departement?: string;
  ville?: string;
}

export interface BiensImmobilier {
  nature: string;
  usage: string;
  prisGarantieIndicateur: string;
  droitEmprunteur: string;
  adresseIdentiqueEmpIndicateur: string;
  adresse: Adresse;
  cadastre: string;
  anneeConstruction: string;
  anneeAcquisition: string;
  surfaceHabitable: string;
  valeurAcquisition: string;
  valeurEstimee: string;
  methodeEvaluationBien: string;
  rangHypotheque: string;
  valeurHypothequesConservees: string;
}

export interface GarantieHypothecaire {
  notaire?: Notaire;
  biensImmobiliers?: BiensImmobilier[];
}

export interface SimulationComplementApiRequestBody {
  numeroContreMarque?: number;
  userId?: string;
  numeroProjet?: number;
  miob?: string;
  idApaVendeur?: number;
  rangTiersApaVendeur?: number;
  demandeClientEsignatureIndicateur?: string;
  emprunteur?: PersonAdditionnalInformation;
  coEmprunteur?: PersonAdditionnalInformation;
  projet?: Projet;
  garantieHypothecaire?: GarantieHypothecaire;
}

export interface ListeMessage {
  MessageId: number;
  MessageType: string;
  MessageLib: string;
}

export interface SimulationComplementApiResponse {
  Resultat: Record<string, unknown>;
  Success: boolean;
  ListeMessages: ListeMessage[];
}

export interface StatusInput {
  status: 'valid' | 'invalid' | 'none';
  errorMessage: string;
}

export interface StatusControlInput {
  status?: boolean;
  errorMessage?: string;
}

export type DsStatus = 'valid' | 'invalid' | 'pending' | 'none';

interface FichageEmprunteur {
  statut: string;
  identifiantEmprunteur: number;
  identifiantCoEmprunteur: number;
  numeroProjet: number;
  indFichageFICPEmp: string;
  indFichageFCCEmp: string;
  indFichageFICPCoEmp: string;
  indFichageFCCCoEmp: string;
}
export interface FichageEmprunteursApiResponse {
  Resultat: FichageEmprunteur;
  Success: boolean;
  ListeMessages: ErrorMessage[];
}

export interface FichageEmprunteursApiRequestBody {
  userId: string;
  numeroContreMarque?: number;
  identifiantEmprunteur?: number;
  identifiantCoEmprunteur?: number;
  numeroProjet?: number;
  emprunteur: {
    etatCivil: EtatCivil;
  };
  coEmprunteur?: {
    etatCivil: EtatCivil;
  };
}

export interface StatusInfoDashboard {
  label: string;
  id: string;
  listeDossier: clientFilesResult[];
}

export interface adresseFiabilise {
  userId: string;
  pays: string;
  identifiantEmprunteur: number;
  complement: string;
  designationBatiment: string;
  numero: string;
  typeVoie: string;
  nomVoie: string;
  adresse: string;
  boitePostalLieuDit: string;
  codePostal: number;
  ville: string;
}

export interface adresseFiabiliseApiResponse {
  Resultat: adresseFiabilise;
  Success: boolean;
  ListeMessages: ErrorMessage[];
}
