import BaseButton from 'components/designSystem/BaseButton';
import styled from 'styled-components';

export const StyledCommentWrapper = styled.div`
  box-sizing: border-box;
  height: 42.5rem;
  border: 0.1rem solid #dcdee0;
  border-radius: 0.8rem;
  background-color: #f2f3f3;
  padding: 2.4rem 2.4rem 2.4rem 3rem;
`;

export const StyledTitleWrapper = styled.div`
  box-sizing: border-box;
  border: 0.1rem solid #dcdee0;
  border-radius: 0.8rem;
  background-color: #f2f3f3;
  margin-bottom: 1rem;
`;

export const StyledCommentCountWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  color: #4b4f54;
  font-size: 14px;
`;

export const StyledTitleCountWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  color: #4b4f54;
  font-size: 14px;
  padding-right: 1rem;
`;

export const StyledCommentTextArea = styled.textarea`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  resize: none;
  background-color: #f2f3f3;
  border: none;
  flex: 1;
  padding-left: 1rem;
`;

export const StyledCommentNumberPGWrapper = styled.div`
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 6px;
  background-color: #3a3d41;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAddCommentButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 4.8rem;
  width: auto;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.15);
  color: #008859;
  color: ${({ color }) => color || '#008859'};
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
`;

export const StyledTagsContainer = styled.div`
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  justify-content: space-between;
  width: 90%;
  height: 3.6rem;
  flex-wrap: wrap;
`;

export const StyledPiecesSelectedContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 3.6rem;
  border: 1px solid #979797;
  border-radius: 8px;
  align-items: center;
  padding: 0.6rem 0.8rem;
`;

export const StyledTagLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  margin-right: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledNavArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledDeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledErrorMessage = styled.p`
  color: #c8102e;
  margin-top: 4px;
  white-space: pre-line;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  text-align: end;
`;
