import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import {
  GuaranteeAdditionalData,
  GuaranteeAdditionalInformationAction,
  GuaranteeAdditionalInformationState,
} from 'reducers/guaranteeAdditionalInformation/type';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface CustructionYearProps {
  currentGuarantee?: GuaranteeAdditionalData;
  state: GuaranteeAdditionalInformationState;
  dispatch: React.Dispatch<GuaranteeAdditionalInformationAction>;
}

const CustructionYear: React.FC<CustructionYearProps> = ({
  currentGuarantee,
  state,
  dispatch,
}) => {
  const [isYearInputFocused, setIsYearInputFocused] = useState<boolean>(false);
  const [isYearInputValid, setIsYearInputValid] = useState<boolean | undefined>();
  const [yearInputError, setYearInputError] = useState<string>('');
  const [value, setValue] = useState<string>(currentGuarantee?.constructionYear || '');

  const notEmpty = new RegExp(ValidationRules.notEmpty);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const yearRegexp = new RegExp(ValidationRules.yearRegexp);

  const updateState = (val?: string) => {
    const data = state.guarantees.map(item => {
      if (item.code === currentGuarantee?.code) {
        return { ...item, constructionYear: val };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateGuaranteesInformation',
      payload: { data, guaranteeCode: currentGuarantee?.code },
    });
  };

  const handleBlur = () => {
    setIsYearInputFocused(false);
    if (!notEmpty.test(value)) {
      setYearInputError(messagesCheckInput.REQUIRED_VALUE);
      setIsYearInputValid(false);
      updateState(undefined);
    } else if (!yearRegexp.test(value)) {
      setYearInputError(messagesCheckInput.YEAR_FORMAT);
      setIsYearInputValid(false);
      updateState(undefined);
    } else {
      setIsYearInputValid(true);
      setYearInputError('');
      updateState(value);
    }
  };

  useEffect(() => {
    if (notEmpty.test(currentGuarantee?.constructionYear || '')) handleBlur();
  }, []);

  return (
    <>
      <CustomInput
        name="construction-year"
        label="Année de construction"
        value={value}
        onChange={newValue => {
          setIsYearInputFocused(true);
          if (onlyNumbers.test(newValue)) setValue(newValue.slice(0, 4));
        }}
        isValid={isYearInputValid}
        isFocused={isYearInputFocused}
        onBlur={handleBlur}
        onFocus={() => {
          setIsYearInputFocused(true);
        }}
        inputWidth="20.8rem"
        placeholder="aaaa"
        error={yearInputError}
      />
    </>
  );
};

export default CustructionYear;
