import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import ArrowLeftCircleIcon from 'icons/ArrowLeftCircleIcon';
import color from 'styles/color';

interface BackButtonProps {
  handleonClick: () => void;
  label?: string;
  width?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ width, label, handleonClick }) => {
  return (
    <>
      <StyledSecondaryButton
        onClick={handleonClick}
        onKeyDown={e => {
          if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter')
            handleonClick();
        }}
        width={width}
        tabIndex={0}>
        <ArrowLeftCircleIcon color={color.chartColors.mainGreen500} />
        <span style={{ marginLeft: '1rem' }}>{label || 'Retour'}</span>
      </StyledSecondaryButton>
    </>
  );
};

export default BackButton;
