interface ChevronLeftProps {
  color?: string;
}

const ChevronLeft: React.FC<ChevronLeftProps> = ({ color }) => {
  return (
    <svg
      width="12px"
      height="22px"
      viewBox="0 0 12 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>6C252813-41EF-458B-9B96-4DD3676C2528</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
        <g
          id="Dashboard5.2"
          transform="translate(-607.000000, -1334.000000)"
          fill="#4B4F54">
          <g id="Pagination" transform="translate(597.000000, 1329.000000)">
            <g
              id="Icon/24px/Navigation/chevron-left"
              transform="translate(10.666667, 5.333333)">
              <path
                d="M-4.98519966,5.64659474 C-4.7628865,5.44604415 -4.4610272,5.33333333 -4.14623075,5.33333333 C-3.8314343,5.33333333 -3.529575,5.44604415 -3.30726183,5.64659474 L5.33364402,13.4240809 L13.9745499,5.64659474 C14.4378996,5.22965837 15.189138,5.22965838 15.6524877,5.64659475 C16.1158374,6.06353113 16.1158374,6.73951856 15.6524877,7.15645495 L6.17261294,15.6867386 C5.95029977,15.8872892 5.64844047,16 5.33364402,16 C5.01884757,16 4.71698827,15.8872892 4.4946751,15.6867386 L-4.98519966,7.15645495 C-5.20807555,6.95641071 -5.33333333,6.6847884 -5.33333333,6.40152485 C-5.33333333,6.11826129 -5.20807555,5.84663899 -4.98519966,5.64659474 Z"
                id="Path-Copy"
                transform="translate(5.333333, 10.666667) rotate(-270.000000) translate(-5.333333, -10.666667) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronLeft.defaultProps = {
  color: '#4B4F54',
};

export default ChevronLeft;
