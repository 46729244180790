import Space from 'components/space/Space';
import React from 'react';
import {
  StyleCardGrid,
  StyleCardGridItem,
  StyledCardData,
  StyledCardLabel,
  StyledCardTitle,
  StyledHorizontalBar,
} from 'containers/consultation/style';
import { Asset } from 'reducers/operationClient/types';
import { GuaranteeAdditionalInformationState } from 'reducers/guaranteeAdditionalInformation/type';
import { formatNumberByThousand, formatValues, getFullAddress } from 'utils/commun';
import * as messages from '../../messages';

interface FinalisationGarantieConsulationProps {
  guaranteeInfo: Asset[];
  guaranteeAdditionalInformation: GuaranteeAdditionalInformationState;
}

const FinalisationGarantieConsulation: React.FC<FinalisationGarantieConsulationProps> = ({
  guaranteeInfo,
  guaranteeAdditionalInformation,
}) => {
  return (
    <>
      <StyledCardTitle>{messages.FINALISATION_INFO_GARANTIE.titre}</StyledCardTitle>
      <Space marginBottom="1.6rem" />

      <StyledCardTitle>
        {messages.FINALISATION_INFO_GARANTIE.notaire_title}
      </StyledCardTitle>
      <Space marginBottom="1.6rem" />
      <StyleCardGrid>
        <StyleCardGridItem>
          <StyledCardLabel>{messages.FINALISATION_INFO_GARANTIE.notaire}</StyledCardLabel>
          <StyledCardData>{guaranteeAdditionalInformation.notary.name}</StyledCardData>
        </StyleCardGridItem>
        <StyleCardGridItem>
          <StyledCardLabel>{messages.MAIN_CARD_LABEL_ZIP_CODE}</StyledCardLabel>
          <StyledCardData>
            {guaranteeAdditionalInformation.notary.addressZipCode}
          </StyledCardData>
        </StyleCardGridItem>
        <StyleCardGridItem>
          <StyledCardLabel>{messages.MAIN_CARD_LABEL_CITY}</StyledCardLabel>
          <StyledCardData>
            {guaranteeAdditionalInformation.notary.addressCity?.libelle}
          </StyledCardData>
        </StyleCardGridItem>
      </StyleCardGrid>
      <Space marginBottom="1.6rem" />

      {guaranteeInfo?.map((guarantee, index) => {
        const guaranteeMoreInfo = guaranteeAdditionalInformation?.guarantees.find(
          element => {
            return element.code === guarantee.code;
          },
        );
        const leftOverAfterMortgage =
          Number(guarantee.currentValue) - Number(guarantee.keptMortagagesValue);
        return (
          <div key={guarantee.code}>
            {index > 0 && (
              <>
                <StyledHorizontalBar
                  color="#979797"
                  style={{ height: '0.2rem', marginRight: '0' }}
                />
                <Space marginBottom="1.6rem" />
              </>
            )}

            <StyledCardTitle>
              {`${messages.FINALISATION_INFO_GARANTIE.bien} ${index + 1}`}
            </StyledCardTitle>
            <Space marginBottom="1.6rem" />
            <StyleCardGrid>
              <StyleCardGridItem>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_ASSET_TYPE}</StyledCardLabel>
                <StyledCardData>{guarantee.type?.libelle}</StyledCardData>
              </StyleCardGridItem>

              <StyleCardGridItem>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_ASSET_USAGE}</StyledCardLabel>
                <StyledCardData>{guarantee.usage?.libelle}</StyledCardData>
              </StyleCardGridItem>

              <StyleCardGridItem>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_ASSET_VALUE}</StyledCardLabel>
                <StyledCardData>
                  {formatValues(guarantee.currentValue, '€')}
                </StyledCardData>
              </StyleCardGridItem>

              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_INSCRIPTION_RANK}
                </StyledCardLabel>
                <StyledCardData>{guarantee.inscriptionRank}</StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_KEPT_MORTGAGES_VALUE}
                </StyledCardLabel>
                <StyledCardData>
                  {formatValues(guarantee.keptMortagagesValue, '€')}
                </StyledCardData>
              </StyleCardGridItem>

              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.MAIN_CARD_LABEL_REMAINING_MORTAGAGES_VALUE}
                </StyledCardLabel>
                <StyledCardData>
                  {formatValues(leftOverAfterMortgage.toString(), '€')}
                </StyledCardData>
              </StyleCardGridItem>

              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_GARANTIE.adresse}
                </StyledCardLabel>
                <StyledCardData>
                  {getFullAddress(guaranteeMoreInfo?.address || {})}
                </StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_ZIP_CODE}</StyledCardLabel>
                <StyledCardData>{guaranteeMoreInfo?.address?.zipCode}</StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem>
                <StyledCardLabel>{messages.MAIN_CARD_LABEL_CITY}</StyledCardLabel>
                <StyledCardData>{guaranteeMoreInfo?.address?.city}</StyledCardData>
              </StyleCardGridItem>
            </StyleCardGrid>
            <Space marginBottom="1.6rem" />
            <Space marginBottom="1.6rem" />
            <StyleCardGrid>
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_GARANTIE.constructionYear}
                </StyledCardLabel>
                <StyledCardData>{guaranteeMoreInfo?.constructionYear}</StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_GARANTIE.aquisitionYear}
                </StyledCardLabel>
                <StyledCardData>{guaranteeMoreInfo?.acquisitionYear}</StyledCardData>
              </StyleCardGridItem>
              {guaranteeMoreInfo?.acquisitionValue && (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_GARANTIE.aquisitionValue}
                  </StyledCardLabel>
                  <StyledCardData>{`${formatNumberByThousand(
                    guaranteeMoreInfo?.acquisitionValue,
                  )} €`}</StyledCardData>
                </StyleCardGridItem>
              )}
            </StyleCardGrid>
            <Space marginBottom="1.6rem" />
            <StyleCardGrid>
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_GARANTIE.surface}
                </StyledCardLabel>
                <StyledCardData>{guaranteeMoreInfo?.livingSpace}</StyledCardData>
              </StyleCardGridItem>
              <StyleCardGridItem>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_GARANTIE.evalutationType}
                </StyledCardLabel>
                <StyledCardData>
                  {guaranteeMoreInfo?.evaluationType?.libelle}
                </StyledCardData>
              </StyleCardGridItem>
            </StyleCardGrid>
            <Space marginBottom="1.6rem" />
          </div>
        );
      })}
    </>
  );
};

export default FinalisationGarantieConsulation;
