import NextButton from 'components/buttons/nextButton/NextButton';
import Space from 'components/space/Space';
import ListPJNonTransmis from 'components/listPJNonTransmis/ListPJNonTransmis';
import CheckInCircle from 'icons/CheckInCircle';
import ErrorIcon from 'icons/ErrorIcon';
import { useEffect, useState } from 'react';
import { FinalisationProjetState } from 'reducers/finalisationProjet/types';
import { getNumeroProjet, getUserInfos } from 'utils/commun';
import { useLocation, useNavigate } from 'react-router';
import DownloadIcon from 'icons/DownloadIcon';
import transmissionPdf from 'components/downloadCard/pdfExport/pdfFicheTransmission';
import dossierClientPdf from 'components/downloadCard/pdfExport/pdfDossierClient';
import {
  StyledBodyContainer,
  StyledEmissionSuccessContainer,
  StyledEmissionSuccessText,
  StyledFooterContainer,
  StyledMainFeedbackContainer,
  StyledSubTitle,
  StyledTitle,
  StyledWarningContainer,
  StyledWarningContent,
  StyledWarningText,
  StyledWarningTitle,
  StyledDowloadCardWrapper,
  StyledDowloadCardElement,
} from './style';
import * as messages from './messages';

enum DocumentSend {
  TRUE = 'yes',
  FALSE = 'no',
  ALLFAIL = 'allFail',
}

const FeedbackForm: React.FC = () => {
  const [editionType, setEditionType] = useState<'e-signature' | 'papier'>();
  const [documentsHaveBeenSent, setDocumentsHaveBeenSent] = useState<DocumentSend>(
    DocumentSend.FALSE,
  );

  const [eSignatureUrl, setESignatureUrl] = useState<string>();
  const [paperPackUrl, setPaperPackUrl] = useState<string>();
  const [listePJNonTransmis, setListPJNonTransmis] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    const finalisationFromSession = sessionStorage.getItem('finalisationProjet');
    if (finalisationFromSession) {
      const finalisationObject = JSON.parse(
        finalisationFromSession,
      ) as FinalisationProjetState;

      setEditionType(finalisationObject.editionType);
    }

    const listPJNonTransmisFromSession = sessionStorage.getItem('listePJNonTransmis');
    if (listPJNonTransmisFromSession) {
      const listPJNonTransmisObject = JSON.parse(
        listPJNonTransmisFromSession,
      ) as string[];

      setListPJNonTransmis(listPJNonTransmisObject);
    }

    const documentsHaveBeenSentFromSession = sessionStorage.getItem(
      'documentsHaveBeenSent',
    );
    if (documentsHaveBeenSentFromSession) {
      setDocumentsHaveBeenSent(documentsHaveBeenSentFromSession as DocumentSend);
    }

    const ESignatureLinkFromSession = sessionStorage.getItem('eSignatureLink');
    if (ESignatureLinkFromSession) {
      const ESignatureLink = JSON.parse(ESignatureLinkFromSession) as string;
      if (ESignatureLink) setESignatureUrl(ESignatureLink);
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.linkSource) {
      setPaperPackUrl(location.state.linkSource);
    }
  }, [location.state]);

  const renderTitle = () => {
    let title = messages.FEEDBACK_MAIN_TITLE_TRANSMITED_CLIENT_FILE;

    if (documentsHaveBeenSent === DocumentSend.TRUE) {
      title = messages.FEEDBACK_MAIN_TITLE_TRANSMITED_CLIENT_FILE_AND_DOCUMENTS;
      if (listePJNonTransmis && listePJNonTransmis.length > 0)
        title = messages.FEEDBACK_MAIN_TITLE_TRANSMITED_CLIENT_FILE_AND_PARTIAL_DOCUMENTS;
    }
    return (
      <>
        <StyledTitle>{title}</StyledTitle>
      </>
    );
  };

  const renderSubTitle = () => {
    if (listePJNonTransmis && listePJNonTransmis.length > 0)
      return (
        <>
          <Space marginBottom="1.6rem" />
          <StyledSubTitle>{messages.FEEDBACK_SUB_TITLE_PARTIAL_SEND_1}</StyledSubTitle>
          <StyledSubTitle>{messages.FEEDBACK_SUB_TITLE_PARTIAL_SEND_2}</StyledSubTitle>
        </>
      );
    return (
      <>
        <Space marginBottom="1.6rem" />
        <StyledSubTitle>{messages.FEEDBACK_SUB_TITLE}</StyledSubTitle>
      </>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <>
        <Space marginBottom="2.4rem" />

        <StyledEmissionSuccessContainer>
          <a
            href={paperPackUrl}
            download={`pdfPackContractuel_${getUserInfos().uid}_${getNumeroProjet()}`}>
            <StyledEmissionSuccessText>
              {messages.FEEDBACK_SUCCESS_MESSAGE}
            </StyledEmissionSuccessText>
          </a>
        </StyledEmissionSuccessContainer>
      </>
    );
  };

  const renderWarningMessage = () => {
    return (
      <>
        <Space marginBottom="4rem" />

        <StyledWarningContainer>
          <div style={{ marginTop: '0.3rem' }}>
            <ErrorIcon />
          </div>
          <StyledWarningContent>
            {documentsHaveBeenSent === DocumentSend.FALSE ? (
              <>
                <StyledWarningTitle>{messages.FEEDBACK_WARNING_TITLE}</StyledWarningTitle>
                <StyledWarningText>{messages.FEEDBACK_WARNING_MESSAGE}</StyledWarningText>
              </>
            ) : (
              <StyledWarningTitle>
                {messages.FEEDBACK_WARNING_ALL_FAIL_TITLE}
              </StyledWarningTitle>
            )}
          </StyledWarningContent>
        </StyledWarningContainer>
      </>
    );
  };

  const renderListePJNonTransmis = () => {
    return (
      <>
        <Space marginTop="2rem" />
        <ListPJNonTransmis listPJ={listePJNonTransmis} />
      </>
    );
  };

  const navigate = useNavigate();

  const onNextButtonClick = () => {
    if (eSignatureUrl && editionType === 'e-signature') {
      const win = window.open(eSignatureUrl, '_blank');
      win?.focus();
    }

    navigate('/dashboard');
  };

  const renderNextButton = () => {
    const buttonLabel =
      editionType === 'e-signature' && eSignatureUrl
        ? messages.FEEDBACK_NEXT_BUTTON_LABEL_SIGN
        : messages.FEEDBACK_NEXT_BUTTON_LABEL_DONE;

    return (
      <NextButton
        label={buttonLabel}
        isDisabled={false}
        handleonClick={onNextButtonClick}
      />
    );
  };

  const handleTransmission = async () => {
    transmissionPdf();
  };

  const handleDossierClient = async () => {
    dossierClientPdf();
  };

  return (
    <>
      <StyledMainFeedbackContainer>
        <StyledBodyContainer>
          <CheckInCircle width={64} />

          <Space marginBottom="2.4rem" />

          {renderTitle()}

          {documentsHaveBeenSent === DocumentSend.TRUE && renderSubTitle()}

          {editionType === 'papier' && paperPackUrl && renderSuccessMessage()}

          {documentsHaveBeenSent !== DocumentSend.TRUE && renderWarningMessage()}
          {documentsHaveBeenSent === DocumentSend.TRUE &&
            listePJNonTransmis &&
            listePJNonTransmis.length > 0 &&
            renderListePJNonTransmis()}
        </StyledBodyContainer>

        <Space marginBottom="11.2rem" />

        <StyledFooterContainer>
          <StyledDowloadCardWrapper>
            <Space marginBottom="1.8rem" />
            <StyledDowloadCardElement onClick={handleTransmission}>
              {messages.FEEDBACK_TRANSMISSION_RECAP}
              <DownloadIcon width={15} />
            </StyledDowloadCardElement>
            <Space marginBottom="1.6rem" />
            <StyledDowloadCardElement onClick={handleDossierClient}>
              {messages.FEEDBACK_CLIENT_FILE}
              <DownloadIcon width={15} />
            </StyledDowloadCardElement>
            <Space marginBottom="1.8rem" />
          </StyledDowloadCardWrapper>

          {renderNextButton()}
        </StyledFooterContainer>
      </StyledMainFeedbackContainer>
    </>
  );
};

export default FeedbackForm;
