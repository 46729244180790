import FormFooter from 'components/formFooter/FormFooter';
import Space from 'components/space/Space';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import ContentTitle from 'components/titles/ContentTitle';
import StepTitles from 'components/titles/StepTitles';
import StepContentWrapper from 'components/wrapper/StepContentWrapper';
import React, { useState, useEffect, useReducer } from 'react';
import {
  getRessourcesMensuellesInitialState,
  ressourcesMensuellesReducer,
} from 'reducers/ressourcesMensuelles/ressourcesMensuelles';
import { RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import switchRetirementIncome from 'reducers/ressourcesMensuelles/utils';
import { updateSessionStorage } from 'utils/storage';
import {
  borrowerIdentityReducer,
  getBorrowerInitialState,
} from 'reducers/identity/borrowerIdentity';
import {
  getSituationProfessionnelleInitialState,
  situationProfessionnelleReducer,
} from 'reducers/situationProfessionnelle/situationProfessionnelle';
import { useNavigate } from 'react-router';
import GeneralConfirmationModal from 'components/modals/ConfirmationModals/GeneralConfirmationModal';
import { isUnEmployedExceptRetired } from 'containers/communs/utils';
import TextArea from 'components/textArea/TextArea';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import { handleSaveAndExit, scrollToTopAndShowError } from 'utils/commun';
import WarningIcon from 'icons/WarningIcon';
import { StyledInfo } from 'containers/conditionsFinancieres/style';
import Loader from 'components/Loader';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import InformationsBancaires from './InformationsBancaires';
import MonthlyIncomes from './MonthlyIncomes';
import * as messages from './messages';
import OtherCharges from './OtherCharges';
import OtherIncomes from './OtherIncomes';

interface RessourcesMensuellesFormProps {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
}
const RessourcesMensuellesForm: React.FC<RessourcesMensuellesFormProps> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {
  const navigate = useNavigate();

  const [hasError, setHasError] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(false);

  const [isGeneralConfirmationModalVisible, setIsGeneralConfirmationModalVisible] =
    useState<boolean>(false);

  const [ressourcesMensuellesState, ressourcesMensuellesDispatch] = useReducer(
    ressourcesMensuellesReducer,
    getRessourcesMensuellesInitialState('ressourcesMensuelles'),
  );
  const [situationProfessionnelleState] = useReducer(
    situationProfessionnelleReducer,
    getSituationProfessionnelleInitialState('situationProfessionnelle'),
  );

  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [borrowerState] = useReducer(
    borrowerIdentityReducer,
    getBorrowerInitialState('borrowerIdentity'),
  );

  useEffect(() => {
    const ressourcesMensuellesFromSession =
      sessionStorage.getItem('ressourcesMensuelles');

    if (ressourcesMensuellesFromSession) {
      const newRessourcesMensuellesState = switchRetirementIncome(
        situationProfessionnelleState,
        JSON.parse(ressourcesMensuellesFromSession) as RessourcesMensuellesState,
      );

      ressourcesMensuellesDispatch({
        type: 'setSwitchRetirement',
        payload: newRessourcesMensuellesState,
      });
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ressourcesMensuellesDispatch({
      type: 'setHasCoBorrower',
      payload: borrowerState.hasCoBorrower || false,
    });
  }, [borrowerState]);

  const cleanup = () => {
    if (!ressourcesMensuellesState.hasOtherIncomes) {
      ressourcesMensuellesState.otherIncomes = [];
    }

    if (!ressourcesMensuellesState.hasMonthlyCharges) {
      ressourcesMensuellesState.monthlyCharges = [];
    }
  };

  const saveInSessionStorage = (): void => {
    cleanup();

    const ressourcesMensuellestoSave = ressourcesMensuellesState;
    if (
      ressourcesMensuellesState.hasOtherIncomes &&
      ressourcesMensuellesState.otherIncomes.length === 0
    ) {
      ressourcesMensuellestoSave.hasOtherIncomes = false;
    }
    updateSessionStorage('ressourcesMensuelles', ressourcesMensuellesState);
  };

  const onSaveButtonClick = (): void => {
    setIsGeneralConfirmationModalVisible(true);
  };

  const closeModal = (): void => {
    setIsGeneralConfirmationModalVisible(false);
  };

  const RETIREMENT_CODE = 'IRE';

  useEffect(() => {
    if (isUnEmployedExceptRetired(situationProfessionnelleState.borrower.profession)) {
      ressourcesMensuellesDispatch({
        type: 'setMonthlyIncomes',
        payload: { person: 'borrower', value: '0' },
      });
      ressourcesMensuellesDispatch({
        type: 'setRetirementMonthlyIncomes',
        payload: { person: 'borrower', value: undefined },
      });
    } else if (
      isUnEmployedExceptRetired(situationProfessionnelleState.coBorrower.profession)
    ) {
      ressourcesMensuellesDispatch({
        type: 'setMonthlyIncomes',
        payload: { person: 'coBorrower', value: '0' },
      });
      ressourcesMensuellesDispatch({
        type: 'setRetirementMonthlyIncomes',
        payload: { person: 'coBorrower', value: undefined },
      });
    }
  }, []);

  const displayMonthlyIncomes = (person: 'borrower' | 'coBorrower') => {
    const prof =
      person === 'borrower'
        ? situationProfessionnelleState.borrower.profession
        : situationProfessionnelleState.coBorrower.profession;
    if (isUnEmployedExceptRetired(prof)) {
      return null;
    }

    const income =
      person === 'borrower'
        ? ressourcesMensuellesState.borrowerIncome.monthlyIncomes
        : ressourcesMensuellesState.coBorrowerIncome.monthlyIncomes;

    return (
      <>
        <MonthlyIncomes
          label="Revenu mensuel"
          incomeType="monthlyIncomes"
          person={person}
          income={income || ''}
          checkInputs={checkInputs}
          dispatch={ressourcesMensuellesDispatch}
          tooltipText="Revenu mensuel ramené sur 12 mois (salaire, activité libérale, etc)."
          showAucunBtn={prof?.code === RETIREMENT_CODE}
          onCustomCheckboxButtonClick={() => {
            ressourcesMensuellesDispatch({
              type: 'setMonthlyIncomes',
              payload: { person, value: '0' },
            });
          }}
        />
        <Space marginBottom="2.4rem" />
      </>
    );
  };

  const displayRetirementIncomes = (person: 'borrower' | 'coBorrower') => {
    const prof =
      person === 'borrower'
        ? situationProfessionnelleState.borrower.profession
        : situationProfessionnelleState.coBorrower.profession;
    if (prof?.code === 'IRE') {
      const income =
        person === 'borrower'
          ? ressourcesMensuellesState.borrowerIncome.retirementMonthlyIncomes
          : ressourcesMensuellesState.coBorrowerIncome.retirementMonthlyIncomes;
      return (
        <>
          <MonthlyIncomes
            label="Retraites mensuelles"
            incomeType="retirementIncomes"
            person={person}
            checkInputs={checkInputs}
            income={income || ''}
            dispatch={ressourcesMensuellesDispatch}
            tooltipText="Retraite(s), pré-retraite(s)."
          />
          <Space marginTop="2.4rem" />
        </>
      );
    }
    return null;
  };

  return (
    <>
      {withSummaryCard(
        'Identity',
        <>
          <StepTitles subTitle={messages.SUB_TITLE} />
          {hasError && (
            <>
              <Space marginTop="2.4rem" />
              <TextArea
                title={messagesCheckInput.CHECK_ERROR_TITLE}
                type="error"
                includeIcon>
                <span> {messagesCheckInput.CHECK_ERROR}</span>
              </TextArea>
              <Space marginTop="2.4rem" />
            </>
          )}
          <ContentTitle text={messages.RESSOURCES_MENSUELLES} />
          <StepContentWrapper width="46.4rem">
            {displayMonthlyIncomes('borrower')}
            <Space marginBottom="2.4rem" />
            {displayRetirementIncomes('borrower')}
            <Space marginBottom="5.6rem" />
            {borrowerState.hasCoBorrower && (
              <>
                <CoBorrowerTitle />
                <p style={{ fontWeight: 'bold', margin: '0', marginBottom: '1.6rem' }}>
                  {messages.REVENUS_CO_BORROWER}
                </p>
                {displayMonthlyIncomes('coBorrower')}
                {displayRetirementIncomes('coBorrower')}
              </>
            )}

            <OtherIncomes
              checkInputs={checkInputs}
              state={ressourcesMensuellesState}
              dispatch={ressourcesMensuellesDispatch}
            />
            <Space marginTop="5.6rem" />
            <OtherCharges
              checkInputs={checkInputs}
              state={ressourcesMensuellesState}
              dispatch={ressourcesMensuellesDispatch}
            />
            <Space marginTop="5.6rem" />
          </StepContentWrapper>
          <StepContentWrapper width="60rem">
            <InformationsBancaires
              checkInputs={checkInputs}
              state={ressourcesMensuellesState}
              dispatch={ressourcesMensuellesDispatch}
            />
            <Space marginTop="5.6rem" />
          </StepContentWrapper>
        </>,
      )}

      <GeneralConfirmationModal
        isVisible={isGeneralConfirmationModalVisible}
        isDataOK={ressourcesMensuellesState.isDataOk}
        accept={() => {
          handleSaveAndExit({
            setErrorMessages,
            setIsLoading,
            setIsModalVisible: setIsGeneralConfirmationModalVisible,
            saveInSessionStorage,
            navigate,
          });
        }}
        close={closeModal}
      />

      {errorMessages.length > 0 && (
        <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '10.4rem' }}>
          <WarningIcon />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {errorMessages.map(message => {
              return (
                <p
                  style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                  key={message.MessageId}>
                  {message.MessageLib}
                </p>
              );
            })}
          </div>
        </StyledInfo>
      )}

      <FormFooter
        showPreviousButton
        onSaveButtonClick={onSaveButtonClick}
        onNextButtonClick={() => {
          if (ressourcesMensuellesState.isDataOk) {
            saveInSessionStorage();
            onNextButtonClick();
            setHasError(false);
            setCheckInputs(false);
          } else {
            scrollToTopAndShowError({ setHasError, setCheckInputs });
          }
        }}
        onPreviousButtonClick={onBackButtonClick}
      />
      {isLoading ? <Loader animationDuration={2} /> : null}
    </>
  );
};

export default RessourcesMensuellesForm;
