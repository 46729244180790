import Space from 'components/space/Space';
import { StyledHorizontalBar } from 'containers/consultation/style';
import SmallChevronDown from 'icons/SmallChevronDown';
import SmallChevronUp from 'icons/SmallChevronUp';
import * as messages from './messages';

interface ShowInformationBarProps {
  isRowExpanded: boolean;
  code: number;
  setExpandedRow: (expandedRow: number | undefined) => void;
}

const ShowInformationBar: React.FC<ShowInformationBarProps> = ({
  isRowExpanded,
  code,
  setExpandedRow,
}) => {
  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        onKeyPress={() => {
          if (isRowExpanded) setExpandedRow(undefined);
          else setExpandedRow(code);
        }}
        onClick={() => {
          if (isRowExpanded) setExpandedRow(undefined);
          else setExpandedRow(code);
        }}
        role="button"
        tabIndex={0}>
        {isRowExpanded ? <SmallChevronUp /> : <SmallChevronDown />}
        <span style={{ marginLeft: '1.2rem', color: '#4B4F54', fontSize: '1.4rem' }}>
          {isRowExpanded
            ? messages.MAIN_CARD_ROW_HIDE_LABEL
            : messages.MAIN_CARD_ROW_EXPAND_LABEL}
        </span>
      </div>
      <Space marginBottom="1.6rem" />
      <StyledHorizontalBar
        color="#979797"
        style={{ height: '0.2rem', marginRight: '0' }}
      />
    </>
  );
};

export default ShowInformationBar;
