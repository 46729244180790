import CheckCircleFill from 'icons/CheckCircleFill';
import ExclamationPointIcon from 'icons/ExclamationPointIcon';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  width: 100%;
`;

export const ButtonStyle = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: flex-end;
  width: 100%;
`;

export const StyledChild = styled.div`
  width: auto;
`;

export const LabelToolTipStyle = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: flex-end;
  width: 100%;
`;

export const StyledTooltip = styled.span`
  color: #6f757c;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0;
  margin-top: 0.4rem;
`;

export const CustomInputWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  width: 100%;
`;

export const StyledInput = styled.input<{ isDisabled?: boolean }>`
  flex-grow: 1;
  margin: 0;
  border: none;
  color: ${({ isDisabled }) => (isDisabled ? '#B1B5B9' : 'currentColor')};
  background-color: transparent;
  line-height: 1.5;
  min-width: 0;
  font-size: 1.6rem;

  &:active,
  &:focus {
    border-color: none;
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.chartColors.neutral300};
  }
`;

export const InputContainer = styled.div<{
  isValid?: boolean;
  isFocused: boolean;
  inputWidth?: string;
  notEditable?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;
  position: relative;
  width: ${({ inputWidth }) => inputWidth || '16.4rem'};
  height: 4.8rem;
  padding: 0 1rem;
  border: 0.1rem solid
    ${({ isValid, isFocused, isDisabled, theme }) => {
      if (isValid) {
        return theme.colors.chartColors.mainGreen500;
      }
      if (isDisabled) {
        return theme.colors.chartColors.neutral300;
      }
      if (isValid === false) {
        return theme.colors.chartColors.error500;
      }
      if (isFocused) {
        return '#4a91e2';
      }
      return theme.colors.chartColors.neutral400;
    }};
  border-radius: 0.4rem;
  transition: border-color 0.2s;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  &:focus {
    border-color: #4a91e2;
  }
  ${StyledInput} {
    cursor: ${({ notEditable, isDisabled }) =>
      notEditable || isDisabled ? 'not-allowed' : 'pointer'};
  }
  background-color: ${({ isDisabled, theme }) =>
    isDisabled && theme.colors.chartColors.neutral50};
`;

export const StyledLabel = styled.label<{
  isDisabled?: boolean;
}>`
  color: ${({ theme, isDisabled }) =>
    isDisabled ? '#B1B5B9' : theme.colors.chartColors.neutral500};
  margin-bottom: 0.8rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.chartColors.neutral500};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.8rem;
  white-space: nowrap;
  pointer-events: none;
`;

export const IconBeforeWrapper = styled(IconWrapper)`
  margin: 0;
`;

export const StyledCheckIcon = styled(CheckCircleFill)`
  color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  height: 1.6rem;
`;

export const StyledWarningIcon = styled(ExclamationPointIcon)`
  color: ${({ theme }) => theme.colors.chartColors.error500};
`;

export const StyledErrorWrapper = styled.div`
  height: 2rem;
  width: 2rem;
  background-color: red;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  padding-top: 0.4rem;
`;

export const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.chartColors.error500};
  margin-top: 4px;
  white-space: pre-line;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
`;
