import * as React from 'react';

const InfoFillIcon: React.FC = () => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <title>Ic/24px/Action/info24px</title>
    <path
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 .952a9.048 9.048 0 1 0 0 18.096 9.048 9.048 0 0 0 0-18.096Zm0 7.46c.438 0 .794.356.794.794v5.556a.794.794 0 0 1-.694.787l-.1.007a.794.794 0 0 1-.787-.695l-.007-.1v-5.555c0-.404.303-.738.694-.787l.1-.006Zm0-3.968a1.19 1.19 0 0 1 1.184 1.07l.006.12a1.19 1.19 0 0 1-1.068 1.185L12 8.825a1.19 1.19 0 0 1 0-2.38Z"
      fill="#292C2E"
      fillRule="evenodd"
    />
  </svg>
);

export default InfoFillIcon;
