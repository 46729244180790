import CheckCircleIcon from 'icons/CheckCircleIcon';
import React from 'react';
import {
  BreadCrumbLevel2CurrentItem,
  BreadCrumbLevel2PastItem,
  BreadCrumbLevel2FutureItem,
} from './style';

interface BreadCrumbLevel2Props {
  steps: string[];
  currentStep: number;
}
const BreadCrumbLevel2: React.FC<BreadCrumbLevel2Props> = ({ steps, currentStep }) => {
  const renderTitle = (index: number, stepName: string) => {
    if (currentStep === index + 1) {
      return (
        <BreadCrumbLevel2CurrentItem>
          {index + 1}. {stepName}
        </BreadCrumbLevel2CurrentItem>
      );
    }
    if (currentStep > index + 1) {
      return (
        <BreadCrumbLevel2PastItem>
          <CheckCircleIcon /> {stepName}
        </BreadCrumbLevel2PastItem>
      );
    }
    return (
      <BreadCrumbLevel2FutureItem>
        {index + 1}. {stepName}
      </BreadCrumbLevel2FutureItem>
    );
  };
  return (
    <div style={{ display: 'flex', fontSize: '1.6rem' }}>
      {steps.map((item, index) => {
        return <React.Fragment key={item}>{renderTitle(index, item)}</React.Fragment>;
      })}
    </div>
  );
};

export default BreadCrumbLevel2;
