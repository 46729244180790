import OperationClientForm from 'containers/operationClient/OperationClient';
import { useReducer } from 'react';
import {
  currentAssetReducer,
  currentLoanReducer,
  getCurrentAssetInitialState,
  getCurrentLoanInitialState,
} from 'reducers/operationClient/operation';

const OperationClient: React.FC = () => {
  const [currentLoanState, currentLoanReducerDispatch] = useReducer(
    currentLoanReducer,
    getCurrentLoanInitialState('loan'),
  );
  const [currentAssetState, currentAssetReducerDispatch] = useReducer(
    currentAssetReducer,
    getCurrentAssetInitialState('asset'),
  );

  return (
    <OperationClientForm
      currentLoanReducerDispatch={currentLoanReducerDispatch}
      currentAssetReducerDispatch={currentAssetReducerDispatch}
      currentLoanState={currentLoanState}
      currentAssetState={currentAssetState}
    />
  );
};

export default OperationClient;
