import styled from 'styled-components';

export const StyledDashboardMainTitle = styled.span`
  color: #292c2e;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-weight: bold;
`;

export const StyledButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
`;
