import { hasCoBorrower } from 'containers/communs/utils';
import { EmissionPackState } from 'reducers/emissionPack/types';
import { Rib } from 'types';
import {
  PackContractuelCredit,
  PackContractuelProjet,
  PackContractuelReq,
  PackContractuelStakeholder,
} from 'types/packContractuelDtos';

export const getFullIban = (state: EmissionPackState): string => {
  return `${state.iban?.ibanPart1}${state.iban?.ibanPart2}${state.iban?.ibanPart3}${state.iban?.ibanPart4}${state.iban?.ibanPart5}${state.iban?.ibanPart6}${state.iban?.ibanPart7}`;
};

export const getRib = (state: EmissionPackState): Rib => {
  const rib = {} as Rib;

  rib.iban = getFullIban(state);
  rib.bic = state.iban?.bic || '';

  return rib;
};

export const getEmprunteur = (state: EmissionPackState): PackContractuelStakeholder => {
  const stakeholder = {} as PackContractuelStakeholder;

  stakeholder.adresse = {
    numero: state.address?.streetNumber || '',
    typeVoie: state.address?.streetType?.code || '',
    nomVoie: state.address?.street || '',
    boitePostalLieuDit: state.address?.POBoxOrHamlet || '',
    codePostal: Number(state.address?.zipCode),
    complement: state.address?.additionalInformation || '',
    ville: state.address?.city || '',
  };
  stakeholder.coordonneesBancaires = getRib(state);
  stakeholder.email = state.borrowerEmail || '';
  stakeholder.telephonePortable = state.borrowerPhoneNumber?.replaceAll(' ', '') || '';

  return stakeholder;
};

export const getCoEmprunteur = (state: EmissionPackState): PackContractuelStakeholder => {
  const stakeholder = {} as PackContractuelStakeholder;

  stakeholder.adresse = {
    numero: state.address?.streetNumber || '',
    typeVoie: state.address?.streetType?.code || '',
    nomVoie: state.address?.street || '',
    boitePostalLieuDit: state.address?.POBoxOrHamlet || '',
    codePostal: Number(state.address?.zipCode),
    complement: state.address?.additionalInformation || '',
    ville: state.address?.city || '',
  };
  stakeholder.coordonneesBancaires = getRib(state);
  stakeholder.email = state.coBorrowerEmail || '';
  stakeholder.telephonePortable = state.coBorrowerPhoneNumber?.replaceAll(' ', '') || '';

  return stakeholder;
};

export const getCredits = (state: EmissionPackState): PackContractuelCredit[] => {
  const credits = [] as PackContractuelCredit[];

  state.buybackLoans?.forEach(stateCredit => {
    const credit = {} as PackContractuelCredit;

    credit.adresse = {
      numero: stateCredit.address.streetNumber || '',
      typeVoie: stateCredit.address.streetType?.code || '',
      nomVoie: stateCredit.address.street || '',
      boitePostalLieuDit: stateCredit.address.POBoxOrHamlet || '',
      codePostal: Number(stateCredit.address.zipCode),
      complement: stateCredit.address.additionalInformation || '',
      ville: stateCredit.address.city || '',
    };
    credit.mensualite = Number(stateCredit.loan.monthlyPayment);
    credit.montantInitialPret = Number(stateCredit.loan.initialLoanCapital);
    credit.nature = stateCredit.loan.creditType?.code || '';
    credit.societe = Number(stateCredit.loan.loaner?.code);
    credit.solde = Number(stateCredit.loan.balance);

    credits.push(credit);
  });

  return credits;
};

export const getProjet = (state: EmissionPackState): PackContractuelProjet => {
  const projet = {} as PackContractuelProjet;

  projet.credits = getCredits(state);

  return projet;
};

export const getPackContractuelReq = (
  state: EmissionPackState,
  userId: string,
  numeroProjet: number,
  identifiantEmprunteur: number,
  identifiantCoEmprunteur: number,
  identifiantPropositionCommerciale: number,
): PackContractuelReq => {
  const request = {} as PackContractuelReq;

  request.userId = userId;
  request.numeroProjet = numeroProjet;
  request.identifiantEmprunteur = identifiantEmprunteur;
  request.identifiantCoEmprunteur = identifiantCoEmprunteur || 0;
  request.identifiantPropositionCommerciale = identifiantPropositionCommerciale;
  request.jourPrelevement = state.paymentDay || -1;
  request.modeAppel = state.editionType === 'e-signature' ? 'D' : 'P';
  request.premierSignataire = state.firstStakeholder === 'borrower' ? 'P1' : 'P2';
  request.projet = getProjet(state);
  request.emprunteur = getEmprunteur(state);
  if (hasCoBorrower()) request.coEmprunteur = getCoEmprunteur(state);
  else request.coEmprunteur = null;

  return request;
};
