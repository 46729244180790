import { ComboInput } from 'components/designSystem/ComboInput';
import { Department } from 'types';
import { formatDisplayedDepartments, getNotEmpty } from 'utils/InputValidation';

interface BirthDepartmentProps {
  departmentRef: React.MutableRefObject<null>;
  departments: Department[];
  onDepartmentBlur: (event: Event) => void;
  onDepartmentSelect: (event: Event) => void;
  defaultValue: string;
  className?: string;
  status?: 'none' | 'invalid' | 'valid';
  errorMessage?: string;
}
const BirthDepartment: React.FC<BirthDepartmentProps> = ({
  departmentRef,
  departments,
  onDepartmentBlur,
  onDepartmentSelect,
  defaultValue,
  className,
  status,
  errorMessage,
}) => {
  return (
    <ComboInput
      class={`combo-input ${className}`}
      ref={departmentRef}
      list-on-open
      shadow
      align-items="left"
      items={JSON.stringify(formatDisplayedDepartments(departments))}
      field="displayedLabel"
      label="Département de naissance"
      placeholder="Choisir un département"
      onListBlur={onDepartmentBlur}
      onSelectedList={onDepartmentSelect}
      value={defaultValue}
      {...(status !== 'none' ? { status } : {})}
      {...(errorMessage !== '' ? { 'error-message': errorMessage } : {})}
      customValidityPattern={JSON.stringify(getNotEmpty('Département de naissance'))}
    />
  );
};

export default BirthDepartment;
