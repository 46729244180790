import styled from 'styled-components';

export const StyledAddressBlockMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const StyledAddressBlockRow = styled.div`
  display: flex;
  gap: 1.6rem;
`;

export const StyledAddressBlockInputContainer = styled.div``;
