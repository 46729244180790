import CrossCircle from 'icons/CrossCircle';
import StyledNextButton from './style';

interface DeleteButtonProps {
  isDisabled: boolean;
  handleonClick: () => void;
  label?: string;
  icon?: React.ReactNode;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  isDisabled,
  handleonClick,
  label,
  icon,
}) => {
  return (
    <>
      {isDisabled ? (
        <StyledNextButton disabled onClick={handleonClick}>
          {icon}
          <span style={{ marginLeft: '1rem' }}>{label}</span>
        </StyledNextButton>
      ) : (
        <StyledNextButton
          onClick={handleonClick}
          onKeyDown={e => {
            if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter')
              handleonClick();
          }}
          tabIndex={0}>
          {icon}
          <span style={{ marginLeft: '1rem', whiteSpace: 'nowrap' }}>{label}</span>
        </StyledNextButton>
      )}
    </>
  );
};

DeleteButton.defaultProps = {
  label: 'Supprimer',
  icon: <CrossCircle color="#FFFFFF" />,
};

export default DeleteButton;
