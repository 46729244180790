import FormFooter from 'components/formFooter/FormFooter';
import GeneralConfirmationModal from 'components/modals/ConfirmationModals/GeneralConfirmationModal';
import FailedReliabilityModal from 'components/modals/ConfirmationModals/FailedreliabilityModal';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import { useEffect, useReducer, useState } from 'react';
import { updateSessionStorage } from 'utils/storage';
import { useNavigate } from 'react-router';
import {
  finalisationProjetReducer,
  getFinalisationProjetInitialState,
} from 'reducers/finalisationProjet/finalisationProjet';
import TransfertIcon from 'icons/TransfertIcon';
import {
  getGuaranteeAdditionalInformationInitialState,
  guaranteeAdditionalInformationReducer,
} from 'reducers/guaranteeAdditionalInformation/guaranteeAdditionalInformation';
import {
  getLoanAdditionalInformationInitialState,
  loanAdditionalInformationReducer,
} from 'reducers/loanAdditionalInformation/loanAdditionalInformation';
import {
  debtAdditionalInformationReducer,
  getDebtAdditionalInformationInitialState,
} from 'reducers/debtsAdditionalInformation/debtAdditionalInformation';
import { getIbanInitialState, ibanReducer } from 'reducers/iban/iban';
import {
  getInterlocutorInitialState,
  interlocutorReducer,
} from 'reducers/interlocutor/interlocutor';
import { TransfertOctroiApiRequestBody, UserInfos } from 'types';
import { getNumeroAPA } from 'utils/commun';
import { isSansGarantie } from 'containers/communs/utils';
import transfertProject from 'api/transfertOctroi';
import { fiabiliserAdresseEmail } from 'api/referentialService';
import Loader from 'components/Loader';
import TextArea from 'components/textArea/TextArea';
import InformationArea from 'components/informationArea/InformationArea';
import ErrorIcon from 'icons/ErrorIcon';
import { PropositionOutDtoResp } from 'types/FaisabiliteDTO';
import simulationComplement from 'api/simulationComplement';
import { IbanState } from 'reducers/iban/type';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import BlockIdentity from './blockIdentity/BlockIdentity';
import * as messages from './messages';
import EmployerName from './blockEmployer';
import Guarantees from './blockGuarantees/Guarantees';
import Loans from './blockLoans/BlockLoans';
import Debts from './blockDebts/BlockDebts';
import Iban from './blockIban/BlockIban';
import Interlocutor from './blockInterlocutor';
import HabilitationModal from './HabilitationModal';
import { getSimulationComplementRequestBody } from './utils';

interface FinalisationProjetFormProps {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
}

const FinalisationProjetForm: React.FC<FinalisationProjetFormProps> = ({
  onNextButtonClick,
  onBackButtonClick,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string[] | string>('');

  const [hasErrorTopMessage, setHasErrorTopMessage] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(false);

  const [isGeneralConfirmationModalVisible, setIsGeneralConfirmationModalVisible] =
    useState<boolean>(false);
  const [isHabilitationModalVisible, setIsHabilitationModalVisible] =
    useState<boolean>(false);

  const [isFailedReliabilityModalVisible, setIsFailedReliabilityModalVisible] =
    useState<boolean>(false);
  const [typeFiabilisationMail, setTypeFiabilisationMail] = useState<'emp' | 'co-emp'>(
    'emp',
  );

  const [savedIsEtudeClassique, setSavedIsEtudeClassique] = useState<boolean>(false);

  const [finalisationProjetState, finalisationProjetDispatch] = useReducer(
    finalisationProjetReducer,
    getFinalisationProjetInitialState('finalisationProjet'),
  );

  const [guaranteeState, guaranteeDispatch] = useReducer(
    guaranteeAdditionalInformationReducer,
    getGuaranteeAdditionalInformationInitialState('guaranteeAdditionalInformation'),
  );

  const [loanState, loanDispatch] = useReducer(
    loanAdditionalInformationReducer,
    getLoanAdditionalInformationInitialState('loanAdditionalInformation'),
  );

  const [debtState, debtDispatch] = useReducer(
    debtAdditionalInformationReducer,
    getDebtAdditionalInformationInitialState('debtAdditionalInformation'),
  );

  const [ibanState, ibanDispatch] = useReducer(ibanReducer, getIbanInitialState('iban'));

  const [interlocutorState, interlocutorDispatch] = useReducer(
    interlocutorReducer,
    getInterlocutorInitialState('interlocutor'),
  );

  const [processTreatment, setProcessTreatment] = useState<string>('');

  const [simulation, setSimulation] = useState<PropositionOutDtoResp>(
    {} as PropositionOutDtoResp,
  );

  type ErrorLocations = 'garantee' | 'loan' | 'iban';
  const [errorLocation, setErrorLocation] = useState<ErrorLocations>();

  const ibanIsEmptyOrNull = (iban: IbanState): boolean => {
    if (
      iban.bic ||
      iban.ibanPart1 ||
      iban.ibanPart2 ||
      iban.ibanPart3 ||
      iban.ibanPart4 ||
      iban.ibanPart5 ||
      iban.ibanPart6 ||
      iban.ibanPart7
    ) {
      return false;
    }
    return true;
  };

  const runAutoChecks = () => {
    if (
      guaranteeState.guarantees.find(garantee => {
        return !garantee.isDataOk;
      }) ||
      (guaranteeState.guarantees.length > 0 && !guaranteeState.notary.isDataOK)
    ) {
      setErrorLocation('garantee');
    } else if (
      loanState.loans.find(loan => {
        return !loan.isDataOk;
      })
    ) {
      setErrorLocation('loan');
    } else if (!(ibanIsEmptyOrNull(ibanState) || ibanState.isDataOk)) {
      setErrorLocation('iban');
    } else {
      setErrorLocation(undefined);
    }
  };

  useEffect(() => {
    const simultationFromSession = sessionStorage.getItem('simulationResult');
    const simulationResult = JSON.parse(
      simultationFromSession || '{}',
    ) as PropositionOutDtoResp;
    setSimulation(simulationResult);
  }, []);

  useEffect(() => {
    if (
      errorLocation === 'garantee' &&
      !guaranteeState.guarantees.find(garantee => {
        return !garantee.isDataOk;
      }) &&
      guaranteeState.notary.isDataOK
    ) {
      runAutoChecks();
    }
  }, [guaranteeState]);

  useEffect(() => {
    if (
      errorLocation === 'loan' &&
      !loanState.loans.find(loan => {
        return !loan.isDataOk;
      })
    ) {
      runAutoChecks();
    }
  }, [loanState]);

  useEffect(() => {
    if (errorLocation === 'iban' && ibanState.isDataOk) {
      runAutoChecks();
    }
  }, [ibanState]);

  const WARNING_CODES_FOR_STANDARD_HABILIATATION = [
    '4',
    '5',
    '6',
    'A',
    'B',
    'C',
    'D',
    'E',
  ];
  const WARNING_CODES_FOR_EP_HABILIATATION = ['1', '2', '3', '7', '8', '9'];

  const authenticatedUser = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '',
  ) as UserInfos;

  const { uid, numContremarque, codeHabilitation } = authenticatedUser;
  const numeroProjet = sessionStorage.getItem('numeroProjet');

  const saveInSessionStorage = (): void => {
    try {
      updateSessionStorage('finalisationProjet', finalisationProjetState);
      updateSessionStorage('guaranteeAdditionalInformation', guaranteeState);
      updateSessionStorage('loanAdditionalInformation', loanState);
      updateSessionStorage('debtAdditionalInformation', debtState);
      if (!ibanIsEmptyOrNull(ibanState)) {
        updateSessionStorage('iban', ibanState);
      }
      updateSessionStorage('interlocutor', interlocutorState);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const simulationResultFromSessionStorage = sessionStorage.getItem('simulationResult');
    if (simulationResultFromSessionStorage) {
      try {
        const valueFromSS = JSON.parse(
          simulationResultFromSessionStorage,
        ) as PropositionOutDtoResp;
        setProcessTreatment(valueFromSS?.Resultat?.Proposition?.codeProcessTraitement);
      } catch (error) {
        console.error(
          `Erreur lors de la recupération du résultat de simulation depuis la session storage.Cause:${error}`,
        );
      }
    }
  }, []);

  useEffect(() => {
    if (checkInputs) runAutoChecks();
  }, [checkInputs]);

  const saveAndExit = (): void => {
    saveInSessionStorage();
    navigate('/dashboard');
  };

  const transfertToOctroi = async (isEtudeClassique: boolean): Promise<void> => {
    try {
      const reqBody: TransfertOctroiApiRequestBody = {
        userId: uid,
        numeroContremarque: `${numContremarque}`,
        numeroProjet: numeroProjet || '',
        indicateurEtudeClassique: isEtudeClassique ? 'O' : 'N',
      };
      const response = await transfertProject(reqBody);
      if (response.Success === false) {
        let errorMsg = 'Erreur survenue';
        if (response.ListeMessages && response.ListeMessages.length > 0) {
          errorMsg = response.ListeMessages[0].MessageLib;
          setHasError(errorMsg);
        }
        return Promise.reject(Error('legitimateError'));
      }
      setHasError('');
      updateSessionStorage('transmissionOctroiInformation', response.Resultat);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(
        typeof error === 'string' ? `${error}` : JSON.stringify(error),
      );
    }
  };

  const saveAdditionnalInformation = async (): Promise<void> => {
    // vérifier la valeur de demandeClientEsignatureIndicateur à envoyer
    try {
      const reqBody = getSimulationComplementRequestBody(
        finalisationProjetState,
        guaranteeState,
        loanState,
        debtState,
        ibanState,
        interlocutorState,
      );
      reqBody.numeroContreMarque = numContremarque;
      reqBody.userId = uid;
      reqBody.numeroProjet = parseInt(numeroProjet || '0', 10);
      reqBody.idApaVendeur = getNumeroAPA();
      reqBody.rangTiersApaVendeur = Number(interlocutorState.interlocutorName?.code);
      const response = await simulationComplement(reqBody);
      if (response.Success === false) {
        let errorMsg = 'Erreur survenue';
        if (response.ListeMessages && response.ListeMessages.length > 0) {
          errorMsg = response.ListeMessages[0].MessageLib;
          setHasError(errorMsg);
        }
        return Promise.reject(Error('legitimateError'));
      }
      setHasError('');
      updateSessionStorage('transmissionOctroiInformation', response.Resultat);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Vérifie si l'habilitation du courtier est OK pour continuer. Si son habilitation ne permet pas de continuer,
   * on lui afficher un modal de confirmation s'il veut continuer
   * @returns
   */
  const isHabilitationCorrect = (): boolean | undefined => {
    if (!processTreatment) {
      console.error('Missing processTreatment to call api TransfertOctroi');
      return undefined;
    }
    if (!uid || !numContremarque || !codeHabilitation || !numeroProjet) {
      console.error(
        'Missing uid or numContremarque or codeHabilitation or numeroProjet to call api TransfertOctroi',
      );
      return undefined;
    }
    if (
      codeHabilitation === 'S' &&
      WARNING_CODES_FOR_STANDARD_HABILIATATION.indexOf(processTreatment) >= 0
    ) {
      return false;
    }
    if (
      codeHabilitation === 'E' &&
      WARNING_CODES_FOR_EP_HABILIATATION.indexOf(processTreatment) >= 0
    ) {
      return false;
    }
    return true;
  };

  const totalBankDebtsMatchesDebtAmount = (): boolean => {
    const tempDebtState = { ...debtState };

    const tempDebt = tempDebtState.debts.find(debts => {
      return debts.code === 'DB';
    });

    if (tempDebt) {
      let totalBankDebts = 0;

      tempDebt.banks?.forEach(bank => {
        totalBankDebts += Number(bank.amount);
      });

      tempDebt.totalBankDebts = totalBankDebts;
      tempDebt.doesTotalBankDebtsMatchAmount = Number(tempDebt.amount) === totalBankDebts;

      tempDebtState.debts = tempDebtState.debts.map(debt => {
        if (debt.code === tempDebt.code) {
          const newDebt = { ...debt, totalBankDebts };
          return newDebt;
        }
        return debt;
      });

      debtDispatch({
        type: 'setUpdateDebtsInformation',
        payload: { data: tempDebtState.debts, debtCode: tempDebt.code },
      });

      updateSessionStorage('debtAdditionalInformation', tempDebtState);

      return tempDebt.doesTotalBankDebtsMatchAmount;
    }

    return true;
  };

  /**
   * Appel de 2 apis:
   * -sauvegarde des données complémentaires (SimulateComplement)
   * -transfert à l'octroi
   */
  const callApis = async (isEtudeClassique: boolean) => {
    if (totalBankDebtsMatchesDebtAmount())
      try {
        setIsLoading(true);
        await saveAdditionnalInformation();
        await transfertToOctroi(isEtudeClassique);
        setIsLoading(false);
        onNextButtonClick();
      } catch (error) {
        console.error(error);
        if ((error as Error).message !== 'legitimateError') {
          setHasError(
            "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
          );
        }
        setIsLoading(false);
      }
  };

  const fiabilisationAdresseMail = async (
    listEmail: {
      mail: string;
      id: number;
    }[],
    isEtudeClassique: boolean,
  ): Promise<void> => {
    const PromiseList = listEmail.map(item => fiabiliserAdresseEmail(item.mail, item.id));

    Promise.all(PromiseList)
      .then(promiseResponce => {
        if (!promiseResponce.some(response => !response.Success)) {
          setHasError('');
          callApis(isEtudeClassique);
        } else {
          setIsLoading(false);
          let whomWrongMail: 'address' | 'emp' | 'co-emp' = 'emp';
          let nbrErrorMail = 0;
          let errorMessage = '';

          promiseResponce.forEach(response => {
            if (response.Success) return null;
            if (
              response.Resultat.identifiantEmprunteur ===
              simulation.Resultat?.identifiantEmprunteur
            ) {
              errorMessage += `emprunteur: ${response.ListeMessages[0].MessageLib}\n`;
              nbrErrorMail += 1;
            }
            if (
              response.Resultat.identifiantEmprunteur ===
              simulation.Resultat?.identifiantCoEmprunteur
            ) {
              errorMessage += `co-emprunteur: ${response.ListeMessages[0].MessageLib}\n`;
              whomWrongMail = 'co-emp';
              nbrErrorMail += 1;
            }
            return null;
          });
          if (nbrErrorMail === 2) whomWrongMail = 'emp';
          setHasError(errorMessage);
          setTypeFiabilisationMail(whomWrongMail);
          setIsFailedReliabilityModalVisible(true);
          setSavedIsEtudeClassique(isEtudeClassique);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
        setHasError(
          "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
        );
      });
  };

  const callFiabilisation = async (isEtudeClassique: boolean) => {
    const listEmail = new Array<{ mail: string; id: number }>();
    if (finalisationProjetState.borrower?.email) {
      listEmail.push({
        mail: finalisationProjetState.borrower?.email,
        id: simulation.Resultat?.identifiantEmprunteur,
      });
    }
    if (finalisationProjetState.coBorrower?.email) {
      listEmail.push({
        mail: finalisationProjetState.coBorrower?.email,
        id: simulation.Resultat?.identifiantCoEmprunteur,
      });
    }

    if (listEmail.length === 0) callApis(isEtudeClassique);
    else fiabilisationAdresseMail(listEmail, isEtudeClassique);
  };

  /**
   * 1-sauvegarde en session storage de toutes les informations renseignées par le courtier
   * 2-vérifie que le courtier a la bonne habilitation pour transférer à l'octroi. Sinon, on lui affiche un modal de confirmation qu'il veut bien continuer
   * 3-appelle l'api de sauvegarde des données complémentaires, puis l'api de transfert à l'octroi
   *
   * @returns
   */
  const handleNextBtnClicked = async () => {
    const guaranteesOk = !guaranteeState.guarantees.find(item => item.isDataOk !== true);
    const loansOk = !loanState.loans.find(item => item.isDataOk !== true);
    const debtsOk = !debtState.debts.find(item => item.isDataOk !== true);
    runAutoChecks();
    if (
      finalisationProjetState.isDataOk &&
      guaranteesOk &&
      (isSansGarantie() || guaranteeState.notary?.isDataOK) &&
      loansOk &&
      debtsOk &&
      (ibanIsEmptyOrNull(ibanState) || ibanState.isDataOk) &&
      interlocutorState.isDataOk
    ) {
      if (ibanIsEmptyOrNull(ibanState)) {
        sessionStorage.removeItem('iban');
      }
      saveInSessionStorage();
      const habilitationIsIncorrect = isHabilitationCorrect();
      if (habilitationIsIncorrect === undefined) {
        setHasError("Données manquantes pour vérifier l'habilitation.");
        setIsLoading(false);
        return;
      }
      if (habilitationIsIncorrect === false) {
        setIsHabilitationModalVisible(true);
        return;
      }
      callFiabilisation(false);
    } else {
      window.scroll(0, 0);
      setHasErrorTopMessage(true);
      setCheckInputs(true);
      setIsLoading(false);
    }
  };

  const renderHabilitationModal = () => {
    if (!authenticatedUser) {
      return null;
    }
    const getModalTitle = (): string => {
      if (
        codeHabilitation === 'S' &&
        WARNING_CODES_FOR_STANDARD_HABILIATATION.indexOf(processTreatment) >= 0
      ) {
        return 'Habilitation Standard';
      }
      if (
        codeHabilitation === 'E' &&
        WARNING_CODES_FOR_EP_HABILIATATION.indexOf(processTreatment) >= 0
      ) {
        return 'Habilitation Etude Partagée';
      }
      return '';
    };
    return (
      <HabilitationModal
        title={getModalTitle()}
        isVisible={isHabilitationModalVisible}
        close={() => setIsHabilitationModalVisible(false)}
        accept={() => {
          setIsHabilitationModalVisible(false);
          callFiabilisation(true);
        }}
        body={
          authenticatedUser.codeHabilitation === 'E'
            ? messages.HABILITATION_EP
            : messages.HABILITATION_STANDARD
        }
      />
    );
  };

  return (
    <>
      {withSummaryCard(
        'Finalisation',
        <>
          {hasErrorTopMessage ? (
            <>
              <Space marginTop="3.2rem" />
              <TextArea
                title={messagesCheckInput.CHECK_ERROR_TITLE}
                type="error"
                includeIcon>
                <span> {messagesCheckInput.CHECK_ERROR}</span>
              </TextArea>
              <Space marginTop="3.2rem" />
            </>
          ) : (
            <Space marginBottom="10rem" />
          )}
          <div style={{ width: '71.2rem' }}>
            <StepTitles title={messages.TITLE} subTitle={messages.SUB_TITLE} />
            <Space marginTop="8.2rem" />
            <BlockIdentity
              state={finalisationProjetState}
              dispatch={finalisationProjetDispatch}
              checkInputs={checkInputs}
            />
            <Space marginTop="5.6rem" />
            <EmployerName
              state={finalisationProjetState}
              dispatch={finalisationProjetDispatch}
              checkInputs={checkInputs}
            />
            <Space marginTop="5.6rem" />
            <Guarantees
              state={guaranteeState}
              dispatch={guaranteeDispatch}
              stateIsCorrect={errorLocation !== 'garantee'}
            />
            <Space marginTop="5.6rem" />
            <Loans
              state={loanState}
              dispatch={loanDispatch}
              stateIsCorrect={errorLocation !== 'loan'}
            />
            <Space marginTop="5.6rem" />
            <Debts state={debtState} dispatch={debtDispatch} checkInputs={checkInputs} />
            <Space marginTop="5.6rem" />
            <Iban
              state={ibanState}
              dispatch={ibanDispatch}
              stateIsCorrect={ibanState.isEmpty || errorLocation !== 'iban'}
            />
            <Space marginTop="5.6rem" />
            <Interlocutor
              state={interlocutorState}
              dispatch={interlocutorDispatch}
              userInfos={authenticatedUser}
              checkInputs={checkInputs}
            />
            {renderHabilitationModal()}
            <GeneralConfirmationModal
              isVisible={isGeneralConfirmationModalVisible}
              accept={saveAndExit}
              close={() => {
                setIsGeneralConfirmationModalVisible(false);
              }}
            />
            <FailedReliabilityModal
              type={typeFiabilisationMail}
              isVisible={isFailedReliabilityModalVisible}
              close={() => setIsFailedReliabilityModalVisible(false)}
              continueWithoutReliability={() => {
                setIsFailedReliabilityModalVisible(false);
                setIsLoading(true);
                callApis(savedIsEtudeClassique);
              }}
            />{' '}
          </div>
        </>,
      )}
      <>{isLoading && <Loader animationDuration={0.9} />}</>
      <>
        {(hasError || hasError) && (
          <>
            <Space marginTop="3.2rem" />
            <InformationArea backgroundColor="#FEF2F4" icon={<ErrorIcon />} width="100%">
              <span style={{ whiteSpace: 'pre-line' }}> {hasError}</span>
            </InformationArea>
          </>
        )}
      </>
      <FormFooter
        showPreviousButton
        hideSaveBtn
        onNextButtonClick={() => {
          setIsLoading(true);
          handleNextBtnClicked();
        }}
        nextButtonLabel="Transmettre le projet à l'octroi"
        nextButtonIcon={<TransfertIcon />}
        onPreviousButtonClick={onBackButtonClick}
      />
    </>
  );
};

export default FinalisationProjetForm;
