import { checkifAddressOk } from 'components/addressBlock/AddressBlock';
import { hasCoBorrower, isSansGarantie } from 'containers/communs/utils';
import { IbanState } from 'reducers/iban/type';
import { SessionStorageKeys } from 'types';
import { getExistingStorageByKey } from 'utils/storage';
import { EmissionPackAction, EmissionPackState } from './types';

const checkESignCase = (state: EmissionPackState): boolean => {
  if (
    state.editionType === 'e-signature' &&
    !(state.borrowerEmail && state.borrowerPhoneNumber)
  )
    return false;

  if (
    state.editionType === 'e-signature' &&
    hasCoBorrower() &&
    !(state.coBorrowerEmail && state.coBorrowerPhoneNumber)
  )
    return false;
  return true;
};

const checkLoansAddresses = (state: EmissionPackState) => {
  let areAddressesValid = true;

  state.buybackLoans?.forEach(loan => {
    if (!(loan.address && loan.address.isAddressOk)) areAddressesValid = false;
  });

  return areAddressesValid;
};

const checkAddress = (state: EmissionPackState) => {
  if (!state.address || !state.address.isAddressOk) return false;
  return true;
};

const checkIfDatasAreOk = (state: EmissionPackState): boolean => {
  if (!checkESignCase(state)) return false;
  if (!checkLoansAddresses(state)) return false;
  if (isSansGarantie() && !checkAddress(state)) return false;
  if (hasCoBorrower() && state.editionType === 'e-signature' && !state.firstStakeholder)
    return false;
  if (!state.iban?.isDataOk) return false;
  if (!state.paymentDay) return false;
  return true;
};

export const getEmissionPackInitialState = (
  storageKey: SessionStorageKeys,
): EmissionPackState => {
  const emissionPackDefaultValues = {
    editionType: '',
    borrowerEmail: '',
    coBorrowerEmail: '',
    borrowerPhoneNumber: '',
    coBorrowerPhoneNumber: '',
    firstStakeholder: '',
    address: {},
    buybackLoans: [],
    paymentDay: undefined,
    iban: {} as IbanState,
    isDataOk: false,
  };

  const initialState = getExistingStorageByKey(
    storageKey,
    emissionPackDefaultValues,
  ) as EmissionPackState;

  if (initialState.address) {
    initialState.address.isAddressOk = checkifAddressOk(initialState.address || {});
  }
  initialState.isDataOk = checkIfDatasAreOk(initialState);
  return initialState;
};

export const emissionPackReducer = (
  state: EmissionPackState,
  action: EmissionPackAction,
): EmissionPackState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setEditionType':
      newState.editionType = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBorrowerPhoneNumber':
      newState.borrowerPhoneNumber = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setCoBorrowerPhoneNumber':
      newState.coBorrowerPhoneNumber = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBorrowerEmail':
      newState.borrowerEmail = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setCoBorrowerEmail':
      newState.coBorrowerEmail = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setAddress':
      if (newState.address) {
        newState.address = action.payload;
        if (newState.address)
          newState.address.isAddressOk = checkifAddressOk(newState.address);
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setFirstStakeHolder':
      newState.firstStakeholder = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBuybackLoans':
      newState.buybackLoans = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setLoanerFullAddress':
      if (newState.buybackLoans) {
        newState.buybackLoans[action.payload.loanerIndex].address = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setPaymentDay':
      newState.paymentDay = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setIban': {
      newState.iban = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }

    default:
      return state;
  }
};
