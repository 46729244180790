import GenericIconProps from './GenericIcon';

const CommentIcon: React.FC<GenericIconProps> = ({ width }) => {
  const baseWidth = 48;
  const baseHeight = 48;

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>9056CD02-926C-4011-9116-02FA603BDA90</title>
      <g
        id="Consultation-dossier-📑"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Consultation-d'un-dossier/transferer-a-l'octroi/autres-justificatifs/SG"
          transform="translate(-288.000000, -1736.000000)">
          <g id="espace/commentaire" transform="translate(264.000000, 1492.000000)">
            <g id="message" transform="translate(24.000000, 244.000000)">
              <circle id="Oval" fill="#D8D8D8" cx="24" cy="24" r="24" />
              <g
                id="Icon/24px/Media/chat"
                transform="translate(12.000000, 12.000000)"
                fill="#4B4F54"
                fillRule="nonzero">
                <g id="_x34_8" transform="translate(2.000000, 3.000000)">
                  <path
                    d="M15.85,0 L4.15,0 C1.86,0 0,1.86027714 0,4.15704388 L0,9.01039261 C0,11.2967667 1.86,13.1674365 4.15,13.1674365 L8.45,13.1674365 L8.45,17.3764434 C8.45,17.6258661 8.59,17.8545035 8.81,17.948037 C8.89,17.9792148 8.97,18 9.05,18 C9.2,18 9.35,17.9376443 9.46,17.8337182 L14.26,13.1674365 L15.85,13.1674365 C18.14,13.1674365 20,11.3071594 20,9.01039261 L20,4.15704388 C20,1.86027714 18.14,0 15.85,0 Z M18.8,9.01039261 C18.8,10.6108545 17.48,11.9203233 15.85,11.9203233 L14.03,11.9203233 C13.88,11.9203233 13.73,11.982679 13.62,12.0866051 L9.66,15.9422633 L9.66,12.5438799 C9.66,12.3775982 9.6,12.221709 9.48,12.1073903 C9.37,11.9930716 9.21,11.9203233 9.06,11.9203233 L4.15,11.9203233 C2.52,11.9203233 1.2,10.6212471 1.2,9.01039261 L1.2,4.15704388 C1.2,2.55658199 2.52,1.24711316 4.15,1.24711316 L15.85,1.24711316 C17.48,1.24711316 18.8,2.54618938 18.8,4.15704388 L18.8,9.01039261 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CommentIcon;
