interface CoBorrowerTitleProps {
  width?: string;
}
const CoBorrowerTitle: React.FC<CoBorrowerTitleProps> = ({ width }) => {
  return (
    <div
      style={{
        borderBottom: '0.4rem solid #28883c',
        marginBottom: '1.6rem',
        width,
      }}>
      <span
        style={{
          borderRadius: '0.4rem 0.4rem 0.4rem 0.2rem',
          borderBottom: 'none',
          backgroundColor: '#28883c',
          color: '#fff',
          fontWeight: 'bold',
          width: '14rem',
          height: '2.4rem',
          display: 'block',
          marginBottom: '-0.4rem',
          paddingLeft: '0.8rem',
          paddingRight: '0.8rem',
          textAlign: 'center',
        }}>
        Co-emprunteur
      </span>
    </div>
  );
};

CoBorrowerTitle.defaultProps = {
  width: '46.4rem',
};

export default CoBorrowerTitle;
