import React from 'react';
import InformationArea from 'components/informationArea/InformationArea';
import ErrorIcon from 'icons/ErrorIcon';

export interface listPJNonTransmisProps {
  listPJ: string[];
}

const ListPJNonTransmis: React.FC<listPJNonTransmisProps> = ({ listPJ }) => {
  const title = 'Erreur de transfert uniquement pour les pièces jointes ci-dessous';
  const info = '  Besoin d’aide ? Contacter votre interlocuteur commercial';
  return (
    <div style={{ width: '94rem' }}>
      <InformationArea
        title={title}
        titleBold
        backgroundColor="#fef4e5"
        icon={<ErrorIcon color="#E78A08" />}
        width="94rem">
        <div style={{ fontSize: '14px' }}>
          <ul>
            {listPJ.map(pjname => {
              return <li key={pjname}>{pjname}</li>;
            })}
          </ul>
          <p style={{ paddingInlineStart: '2rem', color: '#292C2E' }}>{info}</p>
        </div>
      </InformationArea>
    </div>
  );
};

export default ListPJNonTransmis;
