import TextButton from 'components/buttons/textButton/TextButton';
import Space from 'components/space/Space';
import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import CrossCircle from 'icons/CrossCircle';
import React, { useState } from 'react';
import {
  StyledCardColorAccent,
  StyledCardColumnContainer,
  StyledCardContainer,
  StyledCardExpandContainer,
  StyledCardLastColumnContainer,
  StyledCardMainBody,
  StyledCardRowContainer,
  StyledEditCardButton,
  StyledHiddenContentContainer,
} from './style';

interface ExpandableCardProps {
  children: React.ReactNode[];
  collapsedContent?: React.ReactNode[];
  accentColor?: string;
  type: string;
  index: number;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

const ExpandableCard: React.FC<ExpandableCardProps> = ({
  children,
  collapsedContent,
  accentColor,
  type,
  index,
  onEdit,
  onDelete,
}) => {
  const [isHidden, setIsHidden] = useState(true);

  const changeDisplayMode = () => {
    setIsHidden(!isHidden);
  };

  return (
    <React.Fragment key={`ExpandableCard-${type}-${index}`}>
      <StyledCardContainer>
        <StyledCardContainer>
          <StyledCardColorAccent color={accentColor} />
        </StyledCardContainer>

        <StyledCardMainBody>
          <Space marginBottom="1.6rem" />

          <StyledCardRowContainer>
            {children?.map((child, i) => {
              return (
                <React.Fragment
                  key={`ExpandableCard-Visible-${type}-${index}-${index + i}`}>
                  {child ? (
                    <StyledCardColumnContainer>
                      <StyledCardRowContainer>
                        <span>{child}</span>
                        {children.length - 1 === i && (
                          <StyledCardLastColumnContainer>
                            <StyledCardExpandContainer>
                              <TextButton label="" onClick={changeDisplayMode}>
                                {isHidden ? <ChevronDown /> : <ChevronUp />}
                              </TextButton>
                            </StyledCardExpandContainer>
                          </StyledCardLastColumnContainer>
                        )}
                      </StyledCardRowContainer>
                    </StyledCardColumnContainer>
                  ) : null}
                </React.Fragment>
              );
            })}
          </StyledCardRowContainer>

          {(collapsedContent || isHidden) && <Space marginBottom="2.2rem" />}

          <StyledCardRowContainer hidden={isHidden}>
            {collapsedContent?.map((hiddenChild, i) => {
              return (
                <React.Fragment
                  key={`ExpandableCard-Hidden-${type}-${index}-${index + i}`}>
                  <StyledCardColumnContainer order={hiddenChild ? 1 : 2}>
                    <span>{hiddenChild}</span>
                  </StyledCardColumnContainer>
                </React.Fragment>
              );
            })}
          </StyledCardRowContainer>

          <StyledHiddenContentContainer hidden={isHidden}>
            <Space marginBottom="4rem" />
            <StyledCardRowContainer>
              <StyledEditCardButton onClick={() => onEdit(index)}>
                Modifier
              </StyledEditCardButton>

              <TextButton
                textColor="black"
                label="Supprimer"
                onClick={() => onDelete(index)}>
                <CrossCircle color="black" />
              </TextButton>
            </StyledCardRowContainer>

            <Space marginBottom="3.2rem" />
          </StyledHiddenContentContainer>
        </StyledCardMainBody>
      </StyledCardContainer>
    </React.Fragment>
  );
};

ExpandableCard.defaultProps = {
  collapsedContent: undefined,
  accentColor: 'FFFFFF',
};

export default ExpandableCard;
