import { getReferential } from 'api/referentialService';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import { ValidityHtmlElement } from 'components/designSystem/DesignSystemTypes';
import { useEffect, useState } from 'react';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import { CodeLibelle, StatusInput } from 'types';
import * as messages from '../../messages';

interface IdentityTypeProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  person: 'borrower' | 'coBorrower';
  checkInputs?: boolean;
}
const IdentityType: React.FC<IdentityTypeProps> = ({
  state,
  dispatch,
  person,
  checkInputs,
}) => {
  const identityTypeInputId = `${person}-identityType`;
  const [identityTypeInput, setIdentityTypeInput] = useState<HTMLElement | null>();
  const [identityTypes, setIdentityTypes] = useState<CodeLibelle[]>();
  const [statusIdType, setStatusIdType] = useState<StatusInput>();

  useEffect(() => {
    setIdentityTypeInput(document.getElementById(identityTypeInputId));
    getReferential('identitydocumenttypes').then(identityDocumentTypes => {
      setIdentityTypes(
        identityDocumentTypes.Resultat.map(identityType => {
          const newIdentityType = { ...identityType };
          newIdentityType.libelle = newIdentityType.libelle
            .replaceAll('(', ' ')
            .replaceAll(')', '')
            .replaceAll('  ', ' ');

          return newIdentityType;
        }),
      );
    });
  }, []);

  const displayInputError = (errorMessage: string) => {
    if (identityTypeInput) {
      (identityTypeInput as unknown as ValidityHtmlElement).valid = false;
      (identityTypeInput as unknown as ValidityHtmlElement).invalid = true;
      (identityTypeInput as unknown as ValidityHtmlElement).errorMessage = errorMessage;
    }
  };

  const onIdentityTypeSelect = (e: Event) => {
    const selectedIdentity = (e as CustomEvent).detail.value as CodeLibelle;
    dispatch({
      type: 'setIdentityType',
      payload: { person, value: selectedIdentity },
    });
  };

  const onIdentityTypeBlur = (event: Event) => {
    if (event && event.target && (event.target as unknown as ComboInputDS).value === '') {
      displayInputError(`Le type de pièce d'identité est obligatoire`);
      dispatch({
        type: 'setIdentityType',
        payload: { person, value: undefined },
      });
    }
  };

  useEffect(() => {
    const idType =
      person === 'borrower' ? state.borrower.identityType : state.coBorrower.identityType;
    if (checkInputs && !idType) {
      setStatusIdType({
        status: 'invalid',
        errorMessage: `Le type de pièce d'identité est obligatoire`,
      });
      dispatch({
        type: 'setIdentityType',
        payload: { person, value: undefined },
      });
    }
  }, [checkInputs]);

  return (
    <>
      <ComboInput
        id={identityTypeInputId}
        list-on-open
        required
        shadow
        align-items="left"
        items={JSON.stringify(identityTypes)}
        field="libelle"
        label={messages.IDENTITY_LABEL}
        placeholder={messages.IDENTITY_PLACEHOLDER}
        onSelectedList={e => {
          onIdentityTypeSelect(e);
        }}
        onListBlur={e => {
          onIdentityTypeBlur(e);
        }}
        className="identity-type"
        value={
          person === 'borrower'
            ? state.borrower.identityType?.libelle || ''
            : state.coBorrower.identityType?.libelle || ''
        }
        status={statusIdType?.status}
        {...(statusIdType?.errorMessage !== ''
          ? { 'error-message': statusIdType?.errorMessage }
          : {})}
      />
    </>
  );
};

export default IdentityType;
