import AddressBlock from 'components/addressBlock/AddressBlock';
import CustomInput from 'components/customInput/CustomInput';
import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import InformationArea from 'components/informationArea/InformationArea';
import Space from 'components/space/Space';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import StepTitles from 'components/titles/StepTitles';
import { hasCoBorrower } from 'containers/communs/utils';
import {
  StyledBorrowerDisplay,
  StyledDisabledRadioButton,
  StyledDisabledText,
  StyledInputCategoryTitle,
} from 'containers/transmissionPJ/style';
import WarningIcon from 'icons/WarningIcon';
import { Dispatch, useEffect, useState } from 'react';
import { EmissionPackAction, EmissionPackState } from 'reducers/emissionPack/types';
import { FinalisationProjetState } from 'reducers/finalisationProjet/types';
import { IdentityState } from 'reducers/identity/types';
import { SituationFoyerState } from 'reducers/situationFoyer/types';
import { FullAddress } from 'types';
import {
  formatDisplayedFirstname,
  getAuthenticatedUserHabilitations,
} from 'utils/commun';
import {
  checkValidity,
  formatPhoneValue,
  getEmailRegexPattern,
  getMobilePhoneNumberRegexpPattern,
  getZipCodeRegexPatterns,
  isEmailValid,
  isCellPhoneNumberValid,
} from 'utils/InputValidation';
import * as messages from '../../../messages';

interface borrowersInfosProps {
  displayState: EmissionPackState;
  displayDispatch: Dispatch<EmissionPackAction>;
  dispatch: Dispatch<EmissionPackAction>;
  fiabilisationAdressErrorMessage?: string;
  fiabilisationBorrowerEmailError?: string;
  fiabilisationCoBorrowerEmailError?: string;
}

const BorrowersInfos: React.FC<borrowersInfosProps> = ({
  displayState,
  displayDispatch,
  dispatch,
  fiabilisationAdressErrorMessage,
  fiabilisationBorrowerEmailError,
  fiabilisationCoBorrowerEmailError,
}) => {
  const [borrowerFullName, setBorrowerFullName] = useState<string>();
  const [coBorrowerFullName, setCoBorrowerFullName] = useState<string>();

  // Component States
  const [isBorrowerEmailSelected, setIsBorrowerEmailSelected] = useState<boolean>(false);
  const [isBorrowerEmailValid, setIsBorrowerEmailValid] = useState<boolean>();
  const [borrowerEmailError, setBorrowerEmailError] = useState<string>();

  const [isCoBorrowerEmailSelected, setIsCoBorrowerEmailSelected] =
    useState<boolean>(false);
  const [isCoBorrowerEmailValid, setIsCoBorrowerEmailValid] = useState<boolean>();
  const [coBorrowerEmailError, setCoBorrowerEmailError] = useState<string>();

  const [isBorrowerMobileSelected, setIsBorrowerMobileSelected] =
    useState<boolean>(false);
  const [isBorrowerMobileValid, setIsBorrowerMobileValid] = useState<boolean>();
  const [borrowerMobileError, setBorrowerMobileError] = useState<string>();

  const [isCoBorrowerMobileSelected, setIsCoBorrowerMobileSelected] =
    useState<boolean>(false);
  const [isCoBorrowerMobileValid, setIsCoBorrowerMobileValid] = useState<boolean>();
  const [coBorrowerMobileError, setCoBorrowerMobileError] = useState<string>();

  const [isEditionTypeLocked, setIsEditionTypeLocked] = useState<boolean>(false);

  useEffect(() => {
    setBorrowerEmailError(fiabilisationBorrowerEmailError);
    setIsBorrowerEmailValid(false);
  }, [fiabilisationBorrowerEmailError]);

  useEffect(() => {
    setCoBorrowerEmailError(fiabilisationCoBorrowerEmailError);
    setIsCoBorrowerEmailValid(false);
  }, [fiabilisationCoBorrowerEmailError]);

  const handleFirstStakeholderChange = (stakeholder: 'borrower' | 'coBorrower') => {
    displayDispatch({
      type: 'setFirstStakeHolder',
      payload: stakeholder,
    });
    dispatch({
      type: 'setFirstStakeHolder',
      payload: stakeholder,
    });
  };

  const isZipCodeValid = (zipCode: number): boolean => {
    return !checkValidity(zipCode.toString(), getZipCodeRegexPatterns());
  };

  const handleCityChange = (newValue: string) => {
    dispatch({
      type: 'setCity',
      payload: newValue,
    });
    displayDispatch({
      type: 'setCity',
      payload: newValue,
    });
  };

  const handleZipCodeChange = (newValue: number) => {
    displayDispatch({
      type: 'setZipCode',
      payload: newValue,
    });

    if (isZipCodeValid(newValue)) {
      dispatch({
        type: 'setZipCode',
        payload: newValue,
      });
    } else {
      dispatch({
        type: 'setZipCode',
        payload: undefined,
      });
    }
  };

  const handleAddressChange = (newValue: FullAddress) => {
    displayDispatch({
      type: 'setAddress',
      payload: newValue,
    });
    dispatch({
      type: 'setAddress',
      payload: newValue,
    });
  };

  const handleEmailChange = (person: 'borrower' | 'coBorrower', newValue: string) => {
    if (person === 'borrower') {
      displayDispatch({
        type: 'setBorrowerEmail',
        payload: newValue,
      });

      if (isEmailValid(newValue)) {
        dispatch({
          type: 'setBorrowerEmail',
          payload: newValue,
        });
      } else {
        dispatch({
          type: 'setBorrowerEmail',
          payload: undefined,
        });
      }
    } else {
      displayDispatch({
        type: 'setCoBorrowerEmail',
        payload: newValue,
      });

      if (isEmailValid(newValue)) {
        dispatch({
          type: 'setCoBorrowerEmail',
          payload: newValue,
        });
      } else {
        dispatch({
          type: 'setCoBorrowerEmail',
          payload: undefined,
        });
      }
    }
  };

  const onEmailBlur = (person: 'borrower' | 'coBorrower'): void => {
    if (person === 'borrower') {
      const errorMessage = checkValidity(
        displayState.borrowerEmail || '',
        getEmailRegexPattern(),
      );
      setBorrowerEmailError(errorMessage);
      setIsBorrowerEmailValid(isEmailValid(displayState.borrowerEmail || ''));
      setIsBorrowerEmailSelected(false);
      return;
    }
    const errorMessage = checkValidity(
      displayState.coBorrowerEmail || '',
      getEmailRegexPattern(),
    );
    setCoBorrowerEmailError(errorMessage);
    setIsCoBorrowerEmailValid(isEmailValid(displayState.borrowerEmail || ''));
    setIsCoBorrowerEmailSelected(false);
  };

  const onEmailFocus = (person: 'borrower' | 'coBorrower'): void => {
    if (person === 'borrower') {
      setIsBorrowerEmailSelected(true);
      return;
    }
    setIsCoBorrowerEmailSelected(true);
  };

  const handleMobileChange = (person: 'borrower' | 'coBorrower', newValue: string) => {
    if (person === 'borrower') {
      displayDispatch({
        type: 'setBorrowerPhoneNumber',
        payload: newValue,
      });

      if (isCellPhoneNumberValid(newValue)) {
        dispatch({
          type: 'setBorrowerPhoneNumber',
          payload: newValue,
        });
      } else {
        dispatch({
          type: 'setBorrowerPhoneNumber',
          payload: undefined,
        });
      }
    } else {
      displayDispatch({
        type: 'setCoBorrowerPhoneNumber',
        payload: newValue,
      });

      if (isCellPhoneNumberValid(newValue)) {
        dispatch({
          type: 'setCoBorrowerPhoneNumber',
          payload: newValue,
        });
      } else {
        dispatch({
          type: 'setCoBorrowerPhoneNumber',
          payload: undefined,
        });
      }
    }
  };

  const onMobileBlur = (person: 'borrower' | 'coBorrower'): void => {
    if (person === 'borrower') {
      const errorMessage = checkValidity(
        displayState.borrowerPhoneNumber || '',
        getMobilePhoneNumberRegexpPattern(),
      );
      setBorrowerMobileError(errorMessage);
      setIsBorrowerMobileValid(!errorMessage);
      setIsBorrowerMobileSelected(false);
      return;
    }
    const errorMessage = checkValidity(
      displayState.coBorrowerPhoneNumber || '',
      getMobilePhoneNumberRegexpPattern(),
    );
    setCoBorrowerMobileError(errorMessage);
    setIsCoBorrowerMobileValid(!errorMessage);
    setIsCoBorrowerMobileSelected(false);
  };

  const onMobileFocus = (person: 'borrower' | 'coBorrower'): void => {
    if (person === 'borrower') {
      setIsBorrowerMobileSelected(true);
      return;
    }
    setIsCoBorrowerMobileSelected(true);
  };

  const handleEditTypeChange = (type: 'e-signature' | 'papier') => {
    displayDispatch({
      type: 'setEditionType',
      payload: type,
    });
    dispatch({
      type: 'setEditionType',
      payload: type,
    });
  };

  useEffect(() => {
    const borrowerInSession = sessionStorage.getItem('borrowerIdentity');
    if (borrowerInSession) {
      const borrower = JSON.parse(borrowerInSession) as IdentityState;

      setBorrowerFullName(
        `${borrower.civility} ${borrower.lastName} ${formatDisplayedFirstname(
          borrower.firstName || '',
        )}`,
      );
    }

    const coBorrowerInSession = sessionStorage.getItem('coBorrowerIdentity');
    if (coBorrowerInSession) {
      const coBorrower = JSON.parse(coBorrowerInSession) as IdentityState;

      setCoBorrowerFullName(
        `${coBorrower.civility} ${coBorrower.lastName} ${formatDisplayedFirstname(
          coBorrower.firstName || '',
        )}`,
      );
    } else {
      handleFirstStakeholderChange('borrower');
    }

    const sessionSituationFoyer = sessionStorage.getItem('situationFoyer');
    if (sessionSituationFoyer) {
      const situationFoyer = JSON.parse(sessionSituationFoyer) as SituationFoyerState;

      handleZipCodeChange(Number(situationFoyer.addressZipCode));
      handleCityChange(situationFoyer.addressCity?.libelle || '');
    }

    const sessionFinalisation = sessionStorage.getItem('finalisationProjet');
    if (sessionFinalisation) {
      const finalisation = JSON.parse(sessionFinalisation) as FinalisationProjetState;

      if (finalisation.editionType) {
        handleEditTypeChange(finalisation.editionType);
        const { dmatInd, habiPackInd } = getAuthenticatedUserHabilitations();

        if (dmatInd && habiPackInd) {
          setIsEditionTypeLocked(false);
        } else setIsEditionTypeLocked(true);
      }

      handleEmailChange('borrower', finalisation.borrower.email || '');
      handleMobileChange('borrower', finalisation.borrower.cellPhoneNumber || '');
      handleAddressChange(finalisation.borrower.address);

      if (hasCoBorrower()) {
        handleEmailChange('coBorrower', finalisation.coBorrower.email || '');
        handleMobileChange('coBorrower', finalisation.coBorrower.cellPhoneNumber || '');
      }
    }
  }, []);

  const displayAddressBlock = () => {
    return (
      <>
        <StyledInputCategoryTitle>
          {messages.ADDRESS_INFO_CATEGORY_TITLE}
        </StyledInputCategoryTitle>

        <Space marginBottom="1.6rem" />
        {fiabilisationAdressErrorMessage && (
          <ErrorMessageArea message={fiabilisationAdressErrorMessage} />
        )}

        <div style={{ maxWidth: '64.6rem' }}>
          <AddressBlock
            address={displayState.address || {}}
            setAddress={handleAddressChange}
            alternateCityInput
            lockedInputs={['zipCode', 'city']}
          />
        </div>
        <Space marginBottom="5.4rem" />
      </>
    );
  };

  const displayESignatureElements = (stakeholder: 'borrower' | 'coBorrower') => {
    return (
      <>
        <CustomInput
          name={`${stakeholder}-email`}
          label={<span>Adresse e-mail</span>}
          value={
            stakeholder === 'borrower'
              ? displayState.borrowerEmail
              : displayState.coBorrowerEmail
          }
          onChange={newValue => {
            handleEmailChange(stakeholder, newValue);
            setIsBorrowerEmailSelected(true);
          }}
          isValid={
            stakeholder === 'borrower' ? isBorrowerEmailValid : isCoBorrowerEmailValid
          }
          isFocused={
            stakeholder === 'borrower'
              ? isBorrowerEmailSelected
              : isCoBorrowerEmailSelected
          }
          onBlur={() => onEmailBlur(stakeholder)}
          onFocus={() => onEmailFocus(stakeholder)}
          inputWidth="64.8rem"
          placeholder="exemple@exemple.com"
          error={stakeholder === 'borrower' ? borrowerEmailError : coBorrowerEmailError}
        />

        <Space marginBottom="2.4rem" />

        <CustomInput
          name={`${stakeholder}-Mobile`}
          label={<span>Numéro de mobile</span>}
          value={
            stakeholder === 'borrower'
              ? displayState.borrowerPhoneNumber
              : displayState.coBorrowerPhoneNumber
          }
          onChange={newValue => {
            if (newValue.length <= 14) {
              const currentPhoneNumber =
                stakeholder === 'borrower'
                  ? displayState.borrowerPhoneNumber || ''
                  : displayState.coBorrowerPhoneNumber || '';

              handleMobileChange(
                stakeholder,
                formatPhoneValue(currentPhoneNumber, newValue),
              );
              setIsBorrowerMobileSelected(true);
            }
          }}
          isValid={
            stakeholder === 'borrower' ? isBorrowerMobileValid : isCoBorrowerMobileValid
          }
          isFocused={
            stakeholder === 'borrower'
              ? isBorrowerMobileSelected
              : isCoBorrowerMobileSelected
          }
          onBlur={() => onMobileBlur(stakeholder)}
          onFocus={() => {
            onMobileFocus(stakeholder);
          }}
          inputWidth="64.8rem"
          placeholder="06 00 00 00 00"
          error={stakeholder === 'borrower' ? borrowerMobileError : coBorrowerMobileError}
        />
      </>
    );
  };

  const renderFirstStakeholder = (stakeholder: 'borrower' | 'coBorrower') => {
    return (
      <div style={{ display: 'flex', gap: '1rem' }}>
        <BaseRadioButton
          id="pack-contractuel"
          checked={displayState.firstStakeholder === stakeholder ? true : undefined}
          onClick={() => {
            handleFirstStakeholderChange(stakeholder);
          }}
        />
        <span>Signataire 1</span>
      </div>
    );
  };

  const displayStakeholderInformation = (stakeholder: 'borrower' | 'coBorrower') => {
    return (
      <>
        {stakeholder === 'coBorrower' ? <CoBorrowerTitle /> : null}

        <StyledInputCategoryTitle>
          {`Informations ${stakeholder === 'borrower' ? 'emprunteur' : 'co-emprunteur'}`}
        </StyledInputCategoryTitle>

        <Space marginBottom="1.6rem" />

        <StyledBorrowerDisplay>
          <StyledDisabledText>
            {stakeholder === 'borrower' ? borrowerFullName : coBorrowerFullName}
          </StyledDisabledText>
        </StyledBorrowerDisplay>

        <Space marginBottom="2.4rem" />

        {displayState.editionType === 'e-signature'
          ? displayESignatureElements(stakeholder)
          : null}

        <Space marginBottom="2.4rem" />

        {hasCoBorrower() && displayState.editionType === 'e-signature'
          ? renderFirstStakeholder(stakeholder)
          : null}

        <Space marginBottom="5.4rem" />

        {stakeholder === 'borrower' ? displayAddressBlock() : null}
      </>
    );
  };

  return (
    <>
      <StepTitles
        title={messages.TITLE_CONTRACT_DOCUMENTS}
        subTitle={messages.SUB_TITLE_CONTRACT_DOCUMENTS}
      />

      <InformationArea
        icon={<WarningIcon color="#2275D7" />}
        backgroundColor="blue"
        height="0"
        title={
          <span style={{ fontWeight: 'bold' }}>
            {messages.INFO_TEXT_CONTRACT_DOCUMENTS}
          </span>
        }
      />

      <Space marginBottom="3.2rem" />

      <StyledInputCategoryTitle>
        {messages.EDIT_TYPE_CATEGORY_TITLE}
      </StyledInputCategoryTitle>

      <Space marginTop="2.4rem" />

      <div style={{ display: 'flex', gap: '3.7rem' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {isEditionTypeLocked && displayState.editionType !== 'e-signature' ? (
            <StyledDisabledRadioButton />
          ) : (
            <BaseRadioButton
              id="pack-contractuel"
              checked={displayState.editionType === 'e-signature' ? true : undefined}
              onClick={() => {
                handleEditTypeChange('e-signature');
              }}
            />
          )}
          <span>e-Signature</span>
        </div>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {isEditionTypeLocked && displayState.editionType !== 'papier' ? (
            <StyledDisabledRadioButton />
          ) : (
            <BaseRadioButton
              id="pack-contractuel"
              checked={displayState.editionType === 'papier' ? true : undefined}
              onClick={() => {
                handleEditTypeChange('papier');
              }}
            />
          )}
          <span>Papier</span>
        </div>
      </div>

      <Space marginBottom="5.4rem" />

      {displayStakeholderInformation('borrower')}
      {hasCoBorrower() ? displayStakeholderInformation('coBorrower') : null}
    </>
  );
};

export default BorrowersInfos;
