import React from 'react';

interface ActionCrossIconProps {
  color?: string;
}

const ActionCrossIcon: React.FC<ActionCrossIconProps> = ({ color }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>ActionCross</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard5.3" transform="translate(-815.000000, -471.000000)" fill={color}>
          <g id="Tag/justificatif-Copy" transform="translate(630.000000, 465.000000)">
            <g id="Group-7" transform="translate(8.000000, 6.000000)">
              <g id="Icon/24px/Action/cross" transform="translate(177.686292, 0.686292)">
                <path
                  d="M11.3137085,3.3137085 C11.8659932,3.3137085 12.3137085,3.76142375 12.3137085,4.3137085 L12.3137085,10.3137085 L18.3137085,10.3137085 C18.8659932,10.3137085 19.3137085,10.7614237 19.3137085,11.3137085 C19.3137085,11.8659932 18.8659932,12.3137085 18.3137085,12.3137085 L12.3137085,12.3137085 L12.3137085,18.3137085 C12.3137085,18.8659932 11.8659932,19.3137085 11.3137085,19.3137085 C10.7614237,19.3137085 10.3137085,18.8659932 10.3137085,18.3137085 L10.3137085,12.3137085 L4.3137085,12.3137085 C3.76142375,12.3137085 3.3137085,11.8659932 3.3137085,11.3137085 C3.3137085,10.7614237 3.76142375,10.3137085 4.3137085,10.3137085 L10.3137085,10.3137085 L10.3137085,4.3137085 C10.3137085,3.76142375 10.7614237,3.3137085 11.3137085,3.3137085 Z"
                  id="plus"
                  transform="translate(11.313708, 11.313708) scale(-1, 1) rotate(-315.000000) translate(-11.313708, -11.313708) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>{' '}
    </svg>
  );
};

ActionCrossIcon.defaultProps = {
  color: '#4B4F54',
};

export default ActionCrossIcon;
