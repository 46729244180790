import colors from 'styles/color';
import layout from 'styles/layout';
import fonts from 'styles/font';

const themeGlobal = {
  colors,
  layout,
  fonts,
};

export default themeGlobal;
