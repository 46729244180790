import { getBorrowerOtherNeeds } from 'api/referentialService';
import TextButton from 'components/buttons/textButton/TextButton';
import CustomInput from 'components/customInput/CustomInput';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import InformationArea from 'components/informationArea/InformationArea';
import CloseModalConfirmation from 'components/modals/ConfirmationModals/CloseModalConfirmation';
import Modal from 'components/modals/Modal';
import Space from 'components/space/Space';
import {
  StyledBtnWrapper,
  StyledCheckboxWrapper,
  StyledSelectionWrapper,
} from 'containers/communs/style';
import CrossCircle from 'icons/CrossCircle';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import WarningIcon from 'icons/WarningIcon';
import React, { useEffect, useState } from 'react';
import { Operation } from 'reducers/operationClient/types';
import colors from 'styles/color';
import { StyledNumericLabel, StyledSelectionModalAddLoanButton } from '../style';

interface BesoinModalProps {
  isModalVisible: boolean;
  hasInsurance: boolean;
  alreadySelectedNeeds: Operation[];
  onSaveSelectedBesoin: (newSelectedList: Operation[]) => void;
  onCloseModal: () => void;
}
const BesoinModal: React.FC<BesoinModalProps> = ({
  isModalVisible,
  hasInsurance,
  alreadySelectedNeeds,
  onSaveSelectedBesoin,
  onCloseModal,
}) => {
  const [isCloseConfirmationVisible, setIsCloseConfirmationVisible] =
    useState<boolean>(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState<boolean>(false);
  const [otherNeedsList, setOtherNeedsList] = useState<Operation[]>([]);
  const [selectedItems, setSelectedItems] = useState<Operation[]>([]);

  const updateOtherNeedsList = (need: Operation) => {
    setOtherNeedsList(
      otherNeedsList.map(needFromList => {
        if (needFromList.code === need.code) {
          return need;
        }
        return needFromList;
      }),
    );
  };

  const isBesoinChecked = (itemCode: string) => {
    if (selectedItems) {
      const item = selectedItems.find(element => element.code === itemCode);
      if (item) {
        return true;
      }
      return false;
    }
    return false;
  };

  const onChecked = (e: Event, item: Operation) => {
    const checkValue = (e as CustomEvent)?.detail?.value as boolean;

    if (checkValue) {
      const newArray = [...selectedItems, item];
      setSelectedItems(newArray);
    } else {
      setSelectedItems(
        selectedItems.filter(current => {
          return current.code !== item.code;
        }),
      );
    }
  };

  const getOtherNeeds = async () => {
    const response = await getBorrowerOtherNeeds();
    let LibelleNeeds = response.Resultat;

    if (!hasInsurance) {
      LibelleNeeds = LibelleNeeds.filter(LibelleNeed => {
        return LibelleNeed.ordreString !== 'I';
      });
    }

    const otherNeeds = LibelleNeeds.map(item => {
      const newItem: Operation = {
        code: item.code,
        uid: '',
        amount: 1,
        libelle: item.libelle,
        type: item.ordreString,
      };
      return newItem;
    });

    setOtherNeedsList(otherNeeds);
  };

  const onModalAccept = () => {
    const finalItemsSelection = [] as Operation[];

    otherNeedsList.forEach(need => {
      if (isBesoinChecked(need.code)) {
        for (let i = 0; i < need.amount; i += 1) {
          const finalNeed = { ...need };
          finalNeed.amount = 0;
          finalItemsSelection.push(finalNeed);
        }
      }
    });

    onSaveSelectedBesoin(finalItemsSelection);
    setSelectedItems([]);
    setOtherNeedsList([]);
  };

  useEffect(() => {
    let sumOfAllNeeds = alreadySelectedNeeds.length;

    otherNeedsList.forEach(item => {
      if (isBesoinChecked(item.code)) {
        sumOfAllNeeds += item.amount;
      }
    });

    setIsSubmitButtonEnabled(
      selectedItems && selectedItems.length > 0 && sumOfAllNeeds <= 15,
    );
  }, [selectedItems, otherNeedsList]);

  useEffect(() => {
    getOtherNeeds();
  }, [isModalVisible]);

  useEffect(() => {
    getOtherNeeds();
  }, [hasInsurance]);

  const getModalContent = () => {
    return (
      <>
        <p
          style={{
            margin: '5.2rem 9.6rem 1.6rem',
            fontWeight: 'bold',
            fontFamily: 'Ubuntu',
            fontSize: '2.4rem',
          }}>
          Sélectionnez les besoins à ajouter
        </p>
        <div
          style={{ marginLeft: '9.6rem', marginRight: '9.6rem', marginBottom: '1.6rem' }}>
          <InformationArea icon={<WarningIcon color="#2275D7" />} backgroundColor="blue">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Le nombre de besoins est limité à 15.
            </span>
          </InformationArea>
        </div>
        <div className="element-in-modal" role="button" tabIndex={0}>
          {' '}
        </div>
        {otherNeedsList.map((item, index) => {
          return (
            <div key={`otherNeed-${item.code}`}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginRight: '9.6rem',
                }}>
                <StyledSelectionWrapper isSelected={isBesoinChecked(item.code)}>
                  <StyledCheckboxWrapper>
                    <BaseCheckbox
                      checked={isBesoinChecked(item.code) ? true : null}
                      disabled={
                        hasInsurance &&
                        alreadySelectedNeeds.find(alreadySelectedNeed => {
                          return alreadySelectedNeed.code.substring(0, 2) === item.code;
                        }) !== undefined
                          ? true
                          : undefined
                      }
                      onCheckedChanged={e => {
                        onChecked(e, item);
                      }}>
                      <span>{item.libelle}</span>
                    </BaseCheckbox>
                  </StyledCheckboxWrapper>
                </StyledSelectionWrapper>
                <div>
                  <StyledNumericLabel>Quantité</StyledNumericLabel>
                  <Space marginBottom="0.4rem" />
                  <CustomInput
                    inputWidth="6.4rem"
                    name=""
                    isFocused={false}
                    isDisabled={hasInsurance}
                    value={item.amount ? item.amount : undefined}
                    onChange={newValue => {
                      const newItem = { ...item };
                      newItem.amount = Number(newValue);
                      updateOtherNeedsList(newItem);
                    }}
                  />
                </div>
              </div>
              {index !== otherNeedsList.length - 1 ? (
                <Space marginBottom="1.6rem" />
              ) : (
                <></>
              )}
            </div>
          );
        })}
        <StyledBtnWrapper>
          {isSubmitButtonEnabled ? (
            <StyledSelectionModalAddLoanButton
              className="selection-btn"
              tabIndex={0}
              onKeyDownCapture={event => {
                if (event.key === 'Enter') {
                  onModalAccept();
                }
              }}
              onClick={onModalAccept}>
              <PlusCircleIcon color="#ffffff" />
              <span style={{ marginLeft: '1rem' }}>Ajouter les besoins sélectionnés</span>
            </StyledSelectionModalAddLoanButton>
          ) : (
            <StyledSelectionModalAddLoanButton disabled className="selection-btn">
              <PlusCircleIcon color="#ffffff" />
              <span style={{ marginLeft: '1rem' }}>Ajouter les besoins sélectionnés</span>
            </StyledSelectionModalAddLoanButton>
          )}

          <TextButton
            width="8.8rem"
            label="Fermer"
            onClick={() => {
              if (!selectedItems || selectedItems.length > 0) {
                setIsCloseConfirmationVisible(true);
              } else {
                onCloseModal();
              }
            }}>
            <CrossCircle color={colors.chartColors.mainGreen500} />{' '}
          </TextButton>
        </StyledBtnWrapper>
      </>
    );
  };

  return (
    <Modal
      width="67.7rem"
      height="97.2rem"
      isVisible={isModalVisible}
      onClose={() => {
        setIsCloseConfirmationVisible(true);
      }}>
      {getModalContent()}

      <CloseModalConfirmation
        isVisible={isCloseConfirmationVisible}
        accept={() => {
          setIsCloseConfirmationVisible(false);
          setSelectedItems([]);
          setOtherNeedsList([]);
          onCloseModal();
        }}
        close={() => {
          (document.getElementsByClassName('element-in-modal')[0] as HTMLElement).focus();
          setIsCloseConfirmationVisible(false);
        }}
      />
    </Modal>
  );
};

export default BesoinModal;
