import { Country, SessionStorageKeys } from 'types';
import { getExistingStorageByKey } from 'utils/storage';
import { IdentityAction, IdentityState } from './types';
import { checkIfDatasAreOk } from './utils';

export const getBorrowerInitialState = (
  storageKey: SessionStorageKeys,
): IdentityState => {
  const borrowerIdentityDefaultValues = {
    lastName: '',
    firstName: '',
    isBirthNameSameAsName: false,
    birthName: '',
    civility: undefined,
    birthDate: undefined,
    nationality: undefined,
    birthCountry: { code: 'FR', libelle: 'FRANCE' } as Country,
    birthCity: undefined,
    birthDepartment: undefined,
    hasCoBorrower: undefined,
    isDataOk: false,
  };
  return getExistingStorageByKey(
    storageKey,
    borrowerIdentityDefaultValues,
  ) as IdentityState;
};

export const borrowerIdentityReducer = (
  state: IdentityState & { hasCoBorrower: boolean },
  action: IdentityAction,
): IdentityState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setCivility':
      newState.civility = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setLastName':
      newState.lastName = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setIsBirthNameSameAsName':
      newState.isBirthNameSameAsName = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBirthName':
      newState.birthName = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setFirstName':
      newState.firstName = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBirthDate':
      newState.birthDate = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setNationality':
      newState.nationality = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBirthCountry':
      newState.birthCountry = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBirthDepartment':
      newState.birthDepartment = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBirthCity':
      newState.birthCity = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasCoBorrower':
      newState.hasCoBorrower = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    default:
      return state;
  }
};
