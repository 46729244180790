import { sortLongText } from 'containers/finalisationProjet/utils';
import { StyledCardData, StyledCardLabel } from 'containers/operationClient/style';

interface CardTileProps {
  label: string;
  flexSize?: number;
  data?: string;
  unit?: string;
}

const CardTile: React.FC<CardTileProps> = ({ label, flexSize, data, unit }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: flexSize || 1,
          order: data ? '0' : '4',
        }}>
        {data ? (
          <>
            <StyledCardLabel>{label}</StyledCardLabel>
            <StyledCardData>{`${sortLongText(data)}${
              unit ? ` ${unit}` : ''
            }`}</StyledCardData>
          </>
        ) : null}
      </div>
    </>
  );
};

export default CardTile;
