import React from 'react';
import parse from 'html-react-parser';
import {
  CalculetteFinanciereAction,
  CalculetteFinanciereState,
} from 'reducers/calculetteFinanciere/type';
import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import { ASSURANCE_LIST_CALCULATOR } from 'utils/constante';
import WarningIcon from 'icons/WarningIcon';
import InformationArea from 'components/informationArea/InformationArea';
import DeathInsurance from './insuranceSteps/DeathInsurance';
import PTIAInsurance from './insuranceSteps/PTIAInsurance';
import ITTInsurance from './insuranceSteps/ITTInsurance';
import JobLossInsurance from './insuranceSteps/JobLossInsurance';
import FranchiseTimeLimit from './insuranceSteps/FranchiseTimeLimit';
import Quotite from './insuranceSteps/Quotite';
import MonthlyEstimatedBonus from './insuranceSteps/MonthlyEstimatedBonus';
import {
  StyledSectionTitle,
  StyledMarginDiv,
  StyledSubSectionTitle,
  StyledRadioButtonContainer,
  StyledLine,
  StyledAssuranceIndividuelleText,
  StyledMarginDivInsurance,
} from '../style';
import * as messages from '../messages';

const insuranceItems = ASSURANCE_LIST_CALCULATOR;

const persons = {
  borrower: 'borrower',
  coBorrower: 'coBorrower',
};
interface InsuranceFormProps {
  state: CalculetteFinanciereState;
  person: 'borrower' | 'coBorrower';
  calculetteFinanciereDispatch: React.Dispatch<CalculetteFinanciereAction>;
  getValueByThousand: (value: number | undefined) => string | undefined;
}

const InsuranceForm: React.FC<InsuranceFormProps> = ({
  state,
  person,
  calculetteFinanciereDispatch,
  getValueByThousand,
}) => {
  const getPersonWarranty =
    person === persons.borrower
      ? state.proposition.assuranceEmprunteur
      : state.proposition.assuranceCoEmprunteur;

  const handleInsuranceChoice = (item: string) => {
    calculetteFinanciereDispatch({
      type: 'setTypeAssuranceChoisie',
      payload: { person, value: item },
    });
    if (item === 'S') {
      calculetteFinanciereDispatch({
        type: 'reinitilizeInsuranceByPerson',
        payload: { person },
      });
      calculetteFinanciereDispatch({
        type: 'setTypeAssuranceChoisie',
        payload: { person, value: item },
      });
    }
  };
  const isSG = state.projet.typeGarantie === 'SSG';

  const getCheckedItem = (item): boolean | undefined => {
    return getPersonWarranty.typeAssuranceChoisie === item.code ? true : undefined;
  };

  const renderInsuranceItems = () => {
    return (
      <StyledRadioButtonContainer>
        {insuranceItems.map(item => {
          return (
            <React.Fragment key={item.code}>
              <BaseRadioButton
                id={item.code}
                onClick={() => {
                  handleInsuranceChoice(item.code);
                }}
                checked={getCheckedItem(item)}
              />
              <span style={{ paddingRight: '6rem' }}>{item.libelle}</span>
            </React.Fragment>
          );
        })}
      </StyledRadioButtonContainer>
    );
  };

  const renderInputsByInsuranceType = () => {
    switch (getPersonWarranty?.typeAssuranceChoisie) {
      case 'G':
        return (
          <>
            <StyledMarginDivInsurance>
              <DeathInsurance
                person={person}
                dispatch={calculetteFinanciereDispatch}
                getPersonWarranty={getPersonWarranty}
                isSG={isSG}
              />
              <PTIAInsurance
                person={person}
                dispatch={calculetteFinanciereDispatch}
                getPersonWarranty={getPersonWarranty}
              />
            </StyledMarginDivInsurance>
            <StyledMarginDivInsurance>
              <ITTInsurance
                person={person}
                dispatch={calculetteFinanciereDispatch}
                getPersonWarranty={getPersonWarranty}
              />
              <JobLossInsurance
                person={person}
                dispatch={calculetteFinanciereDispatch}
                getPersonWarranty={getPersonWarranty}
                isSG={isSG}
              />
            </StyledMarginDivInsurance>
            <StyledMarginDivInsurance>
              <FranchiseTimeLimit
                person={person}
                dispatch={calculetteFinanciereDispatch}
                getPersonWarranty={getPersonWarranty}
                isSG={isSG}
              />
            </StyledMarginDivInsurance>
            <StyledMarginDivInsurance>
              <StyledAssuranceIndividuelleText>
                {parse(messages.CALCULATOR_FORM.individuelleInsurance)}
              </StyledAssuranceIndividuelleText>
            </StyledMarginDivInsurance>
          </>
        );
      case 'D':
        return (
          <>
            <StyledMarginDivInsurance>
              <Quotite
                getPersonWarranty={getPersonWarranty}
                person={person}
                dispatch={calculetteFinanciereDispatch}
              />
            </StyledMarginDivInsurance>
            <StyledMarginDivInsurance>
              <MonthlyEstimatedBonus
                getPersonWarranty={getPersonWarranty}
                person={person}
                dispatch={calculetteFinanciereDispatch}
                getValueByThousand={getValueByThousand}
              />
            </StyledMarginDivInsurance>
            <StyledMarginDivInsurance>
              <StyledAssuranceIndividuelleText>
                {parse(messages.CALCULATOR_FORM.individuelleInsurance)}
              </StyledAssuranceIndividuelleText>
            </StyledMarginDivInsurance>
          </>
        );
      case 'S':
        return (
          <StyledMarginDivInsurance>
            <InformationArea
              icon={<WarningIcon color="#2275D7" />}
              backgroundColor="blue"
              width="46.4rem"
              height="21.2rem">
              <p style={{ margin: '0', fontSize: '1.4rem' }}>
                {parse(messages.CALCULATOR_FORM.noInsurance)}
              </p>
            </InformationArea>
          </StyledMarginDivInsurance>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <StyledSectionTitle>
        {messages.CALCULATOR_FORM.warrantyStepTitle}
      </StyledSectionTitle>
      <StyledSubSectionTitle>
        {persons.borrower === person
          ? messages.CALCULATOR_warrantyBorrower
          : messages.CALCULATOR_FORM.warrantyCoBorrower}
      </StyledSubSectionTitle>
      {renderInsuranceItems()}
      <StyledMarginDiv>
        <StyledLine />
      </StyledMarginDiv>
      {renderInputsByInsuranceType()}
    </>
  );
};

export default InsuranceForm;
