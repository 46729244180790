import React, { useEffect, useRef } from 'react';

export interface UploaderProps {
  onUploadComplete: (files: File[]) => void;
  triggerUpload: boolean;
  onUploadCancel: () => void;
  acceptedFileFormat: string[];
}
const Uploader: React.FC<UploaderProps> = ({
  onUploadComplete,
  triggerUpload,
  onUploadCancel,
  acceptedFileFormat,
}) => {
  const inputElement = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (triggerUpload) {
      inputElement?.current?.click();
    }
    return () => {
      if (inputElement.current?.files?.length === 0) {
        // no file selected => user may cancel the action
        onUploadCancel();
      }
    };
  }, [triggerUpload]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      if (e.target.files.length === 0) {
        onUploadCancel();
        return;
      }
      const files: File[] = [];
      for (let i = 0; i < e.target.files.length; i += 1) {
        files.push(e.target.files[i]);
      }
      onUploadComplete(files);
      if (inputElement && inputElement.current) {
        inputElement.current.value = ''; // clear the input value so that the user can select the same file
      }
    }
  };

  return (
    <input
      data-testid="uploader"
      type="file"
      ref={inputElement}
      hidden
      onChange={handleFileUpload}
      multiple
      accept={acceptedFileFormat.join()}
    />
  );
};

export default Uploader;
