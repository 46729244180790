import AccessWebsiteIcon from 'icons/AccessWebsiteIcon';
import ActionCrossIcon from 'icons/ActionCrossIcon';
import ActionEdit from 'icons/ActionEdit';
import ActionPlusIcon from 'icons/ActionPlusIcon';
import ActionSignIcon from 'icons/ActionSign';
import ArrowLeftCircleFullIcon from 'icons/ArrowLeftCircleFullIcon';
import ArrowLeftCircleIcon from 'icons/ArrowLeftCircleIcon';
import ArrowLeftRightIcon from 'icons/ArrowLeftRightIcon';
import ArrowRightCircleFullIcon from 'icons/ArrowRightCircleFullIcon';
import BankIcon from 'icons/BankIcon';
import BookIcon from 'icons/BookIcon';
import Calendar from 'icons/Calendar';
import CheckCircleFill from 'icons/CheckCircleFill';
import CheckCircleIcon from 'icons/CheckCircleIcon';
import CheckInCircle from 'icons/CheckInCircle';
import ChevronDown from 'icons/ChevronDown';
import ChevronExpand from 'icons/ChevronExpand';
import ChevronLeft from 'icons/ChevronLeft';
import ChevronRight from 'icons/ChevronRight';
import ChevronUp from 'icons/ChevronUp';
import CirclePersonIcon from 'icons/CirclePersonIcon';
import ClockIcon from 'icons/ClockIcon';
import CloudArrowIcon from 'icons/CloudArrowIcon';
import ContractPaper from 'icons/ContractPaper';
import CreditIcon from 'icons/CreditIcon';
import CrossCircle from 'icons/CrossCircle';
import DownloadIcon from 'icons/DownloadIcon';
import EditIcon from 'icons/EditIcon';
import ErrorIcon from 'icons/ErrorIcon';
import ESignatureIcon from 'icons/ESignatureIcon';
import ExclamationPointIcon from 'icons/ExclamationPointIcon';
import FileIcon from 'icons/FileIcon';
import FilingCabinetIcon from 'icons/FilingCabinetIcon';
import FolderIcon from 'icons/FolderIcon';
import FolderSearchIcon from 'icons/FolderSearchIcon';
import GraduateHatIcon from 'icons/GraduateHatIcon';
import GuaranteeIcon from 'icons/GuaranteeIcon';
import HomeIcon from 'icons/HomeIcon';
import HourglassIcon from 'icons/HourglassIcon';
import HouseIcon from 'icons/HouseIcon';
import IdentityIcon from 'icons/IdentityIcon';
import IncomeIcon from 'icons/IncomeIcon';
import InfoFillIcon from 'icons/InfoFillIcon';
import InsuranceIcon from 'icons/InsuranceIcon';
import LeftArrow from 'icons/LeftArrow';
import LightIcon from 'icons/LightIcon';
import ListDocumentIcon from 'icons/ListDocumentIcon';
import LookingGlassIcon from 'icons/LookingGlassIcon';
import MoneyIcon from 'icons/MoneyIcon';
import NavigationLeft from 'icons/NavigationLeft';
import NavigationRight from 'icons/NavigationRight';
import NextIcon from 'icons/NextIcon';
import PaperClipIcon from 'icons/PaperClipIcon';
import PeopleGroupIcon from 'icons/PeopleGroupIcon';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import PlusCircleIconFull from 'icons/PlusCircleIconFull';
import PowerUpIcon from 'icons/PowerUpIcon';
import ProfilIcon from 'icons/ProfilIcon';
import QuestionMark from 'icons/QuestionMark';
import ReassuranceIcon from 'icons/ReassuranceIcon';
import RefreshIcon from 'icons/RefreshIcon';
import RightArrow from 'icons/RightArrow';
import SimulationIcon from 'icons/SimulationIcon';
import SmallChevronDown from 'icons/SmallChevronDown';
import SmallChevronUp from 'icons/SmallChevronUp';
import StarIcon from 'icons/StarIcon';
import SuitcaseIcon from 'icons/SuitcaseIcon';
import TagIcon from 'icons/TagIcon';
import ThumbsUpIcon from 'icons/ThumbsUp';
import TooltipBackground from 'icons/TooltipBackground';
import TransfertIcon from 'icons/TransfertIcon';
import WarningIcon from 'icons/WarningIcon';
import WebPageIcon from 'icons/WebPageIcon';

const Utils: React.FC = () => {
  const iconList = [
    { title: 'ActionEdit', icon: <ActionEdit /> },
    { title: 'ActionPlusIcon', icon: <ActionPlusIcon color="#000" /> },
    { title: 'ActionSign', icon: <ActionSignIcon color="#000" /> },
    { title: 'ArrowLeftCircleIcon', icon: <ArrowLeftCircleIcon /> },
    { title: 'ArrowLeftRightIcon', icon: <ArrowLeftRightIcon /> },
    { title: 'ArrowRightCircleFullIcon', icon: <ArrowRightCircleFullIcon /> },
    { title: 'ArrowLeftCircleFullIcon', icon: <ArrowLeftCircleFullIcon /> },
    { title: 'BankIcon', icon: <BankIcon /> },
    { title: 'Calendar', icon: <Calendar /> },
    { title: 'CheckCircleFill', icon: <CheckCircleFill /> },
    { title: 'CheckCircleIcon', icon: <CheckCircleIcon /> },
    { title: 'CheckInCircle', icon: <CheckInCircle color="" /> },
    { title: 'ChevronDown', icon: <ChevronDown /> },
    { title: 'ChevronUp', icon: <ChevronUp /> },
    { title: 'ChevronLeft', icon: <ChevronLeft /> },
    { title: 'ChevronRight', icon: <ChevronRight /> },
    { title: 'ChevronExpand', icon: <ChevronExpand /> },
    { title: 'ClockIcon', icon: <ClockIcon /> },
    { title: 'CouldArrowIcon', icon: <CloudArrowIcon /> },
    { title: 'ContractPaper', icon: <ContractPaper color="#000" /> },
    { title: 'CreditIcon', icon: <CreditIcon /> },
    { title: 'CrossCircle', icon: <CrossCircle /> },
    { title: 'DownloadIcon', icon: <DownloadIcon /> },
    { title: 'EditIcon', icon: <EditIcon /> },
    { title: 'ErrorIcon', icon: <ErrorIcon /> },
    { title: 'ESignatureIcon', icon: <ESignatureIcon color="#000" /> },
    { title: 'ExclamationPointIcon', icon: <ExclamationPointIcon /> },
    { title: 'FileIcon', icon: <FileIcon /> },
    { title: 'FolderIcon', icon: <FolderIcon /> },
    { title: 'FolderSearchIcon', icon: <FolderSearchIcon /> },
    { title: 'GuaranteeIcon', icon: <GuaranteeIcon /> },
    { title: 'HomeIcon', icon: <HomeIcon /> },
    { title: 'HouseIcon', icon: <HouseIcon /> },
    { title: 'IdentityIcon', icon: <IdentityIcon /> },
    { title: 'IncomeIcon', icon: <IncomeIcon /> },
    { title: 'InfoFillIcon', icon: <InfoFillIcon /> },
    { title: 'InsuranceIcon', icon: <InsuranceIcon /> },
    { title: 'LeftArrow', icon: <LeftArrow /> },
    { title: 'LightIcon', icon: <LightIcon /> },
    { title: 'ListDocumentIcon', icon: <ListDocumentIcon /> },
    { title: 'LookingGlassIcon', icon: <LookingGlassIcon /> },
    { title: 'MoneyIcon', icon: <MoneyIcon /> },
    { title: 'NavigationLeft', icon: <NavigationLeft /> },
    { title: 'NavigationRight', icon: <NavigationRight /> },
    { title: 'NextIcon', icon: <NextIcon /> },
    { title: 'PlusCircleIcon', icon: <PlusCircleIcon /> },
    { title: 'PlusCircleIconFull', icon: <PlusCircleIconFull /> },
    { title: 'ProfilIcon', icon: <ProfilIcon /> },
    { title: 'QuestionMark', icon: <QuestionMark /> },
    { title: 'ReassuranceIcon', icon: <ReassuranceIcon /> },
    { title: 'RightArrow', icon: <RightArrow /> },
    { title: 'SimulationIcon', icon: <SimulationIcon /> },
    { title: 'SmallChevronDown', icon: <SmallChevronDown /> },
    { title: 'SmallChevronUp', icon: <SmallChevronUp /> },
    { title: 'ThumbsUpIcon', icon: <ThumbsUpIcon color="#000" /> },
    { title: 'TooltipBackground', icon: <TooltipBackground /> },
    { title: 'TransfertIcon', icon: <TransfertIcon color="#000" /> },
    { title: 'WarningIcon', icon: <WarningIcon /> },
    { title: 'HourglassIcon', icon: <HourglassIcon /> },
    { title: 'ActionCrossIcon', icon: <ActionCrossIcon /> },
    { title: 'RefreshIcon', icon: <RefreshIcon /> },
    { title: 'PowerUpIcon', icon: <PowerUpIcon /> },
    { title: 'PaperClipIcon', icon: <PaperClipIcon /> },
    { title: 'AccessWebsiteIcon', icon: <AccessWebsiteIcon /> },
    { title: 'CirclePersonIcon', icon: <CirclePersonIcon /> },
    { title: 'StarIcon', icon: <StarIcon /> },
    { title: 'FilingCabinetIcon', icon: <FilingCabinetIcon /> },
    { title: 'SuitcaseIcon', icon: <SuitcaseIcon /> },
    { title: 'BookIcon', icon: <BookIcon /> },
    { title: 'PeopleGroupIcon', icon: <PeopleGroupIcon /> },
    { title: 'WebPageIcon', icon: <WebPageIcon /> },
    { title: 'TagIcon', icon: <TagIcon /> },
    { title: 'GraduateHatIcon', icon: <GraduateHatIcon /> },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.6rem',
        marginTop: '3.2rem',
        marginBottom: '3.2rem',
      }}>
      {iconList.map(icon => {
        return (
          <div key={icon.title} style={{ display: 'flex', gap: '0.8rem' }}>
            <span>{icon.title}</span>
            {icon.icon}
          </div>
        );
      })}
    </div>
  );
};

export default Utils;
