import { getCitiesByZipCode, getStreets } from 'api/referentialService';
import CityInput from 'components/cityInput/CityInput';
import CustomInput from 'components/customInput/CustomInput';
import LightCustomInput from 'components/customInput/LightCustomInput';
import { StyledErrorMessage } from 'components/customInput/style';
import StreetNumberInput from 'components/customInput/types/StreetNumberInput';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import Space from 'components/space/Space';
import { useEffect, useRef, useState } from 'react';
import { CodeLibelle, FullAddress, StatusControlInput, StatusInput } from 'types';
import { ValidationRules } from 'utils/InputValidation';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import {
  StyledAddressBlockInputContainer,
  StyledAddressBlockMainContainer,
  StyledAddressBlockRow,
} from './style';

export type AddressBlockInputs =
  | 'streetNumber'
  | 'streetType'
  | 'street'
  | 'additionalInformation'
  | 'POBoxOrHamlet'
  | 'zipCode'
  | 'city';

interface AddressBlockInterface {
  address: FullAddress;
  setAddress: (newAddress: FullAddress) => void;
  alternateCityInput?: boolean;
  lockedInputs?: AddressBlockInputs[];
  checkInputs?: boolean;
}

const zipCode = new RegExp(ValidationRules.zipCode);
const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
const notEmpty = new RegExp(ValidationRules.notEmpty);

const isZipCodeOkay = (zipCodeParam: string | undefined): boolean => {
  if (zipCodeParam === undefined) return false;
  if (!zipCode.test(zipCodeParam)) return false;
  if (!notEmpty.test(zipCodeParam)) return false;
  if (!onlyNumbers.test(zipCodeParam)) return false;
  if (!zipCodeNoZero.test(zipCodeParam)) return false;

  return true;
};

const isAddressCityOkay = (addressCityParam: string | undefined): boolean => {
  if (addressCityParam === undefined || addressCityParam === '') return false;
  if (!notEmpty.test(addressCityParam)) return false;
  return true;
};

export const checkifAddressOk = (addressToCheck: FullAddress): boolean => {
  const newAddress = { ...addressToCheck };
  let isAddressOk = true;
  if (
    !(
      (newAddress.street && newAddress.streetType?.code) ||
      newAddress.additionalInformation ||
      newAddress.POBoxOrHamlet
    )
  )
    isAddressOk = false;

  if (
    (newAddress.street !== '' &&
      newAddress.street &&
      (!newAddress.streetType ||
        newAddress.streetType.code === '' ||
        newAddress.streetType.code === 'err')) ||
    (!newAddress.street &&
      newAddress.streetType &&
      newAddress.streetType.code !== '' &&
      newAddress.streetType.code !== 'err')
  ) {
    isAddressOk = false;
  }

  if (newAddress.streetType?.code === 'err') return false;

  if (!isZipCodeOkay(newAddress.zipCode) || !isAddressCityOkay(newAddress.city))
    isAddressOk = false;

  return isAddressOk;
};

const AddressBlock: React.FC<AddressBlockInterface> = ({
  address,
  setAddress,
  alternateCityInput,
  lockedInputs,
  checkInputs,
}) => {
  const [streetTypeReferential, setStreetTypeReferential] = useState<CodeLibelle[]>();
  const [statusStreetType, setStatusStreetType] = useState<StatusInput>();
  const [statusStreet, setStatusStreet] = useState<StatusControlInput>();
  const [isZipCodeInputFocused, setIsZipCodeInputFocused] = useState<boolean>(false);
  const [statusZipCode, setStatusZipCode] = useState<StatusControlInput>();
  const [isCityInvalid, setIsCityInvalid] = useState<boolean>();
  const [statusAddressCity, setStatusAddressCity] = useState<StatusInput>();
  const [citiesList, setCitiesList] = useState<CodeLibelle[]>([]);
  const [cityFromList, setCityFromList] = useState<CodeLibelle>();

  const streetTypeRef = useRef(null);
  const cityRef = useRef(null);

  const handleAddressValidity = (addressToCheck: FullAddress) => {
    const newAddress = { ...addressToCheck };

    if (checkifAddressOk(newAddress)) {
      newAddress.isAddressOk = true;
    } else {
      newAddress.isAddressOk = false;
    }

    setAddress(newAddress);
  };

  useEffect(() => {
    handleAddressValidity(address);
  }, []);

  const onStreetNumberChange = (newStreetNumber: string) => {
    const newAddress = { ...address };
    newAddress.streetNumber = newStreetNumber;
    handleAddressValidity(newAddress);
  };

  const onStreetTypeChange = (newValue: CodeLibelle | undefined) => {
    const newAddress = { ...address };
    newAddress.streetType = newValue as CodeLibelle;
    handleAddressValidity(newAddress);
  };

  const onStreetChange = (newStreet: string) => {
    const newAddress = { ...address };
    newAddress.street = newStreet;
    handleAddressValidity(newAddress);
  };

  const onAdditionalInformationChange = (newAdditionalInformation: string) => {
    const newAddress = { ...address };
    newAddress.additionalInformation = newAdditionalInformation;
    handleAddressValidity(newAddress);
  };

  const onPOBoxOrHamletChange = (newPOBoxOrHamletChange: string) => {
    const newAddress = { ...address };
    newAddress.POBoxOrHamlet = newPOBoxOrHamletChange;
    handleAddressValidity(newAddress);
  };

  const onZipCodeChange = (newZipCode: string) => {
    const newAddress = { ...address };
    newAddress.zipCode = newZipCode.slice(0, 5).trim();
    handleAddressValidity(newAddress);

    if (!alternateCityInput && newAddress.zipCode && newAddress.zipCode.length === 5) {
      getCitiesByZipCode(newAddress.zipCode).then(response => {
        setCitiesList(response as CodeLibelle[]);
      });
    }
  };

  const onCityChange = (newCity?: string) => {
    const newAddress = { ...address };
    newAddress.city = newCity;
    handleAddressValidity(newAddress);
  };

  const onStreetTypeCheck = (value: string) => {
    const cityMatchesListElement = streetTypeReferential?.find(streetType => {
      return streetType.libelle.toUpperCase() === value.toUpperCase();
    });

    if (cityMatchesListElement) {
      onStreetTypeChange(cityMatchesListElement);
    } else if (value === '') {
      onStreetTypeChange(undefined);
    } else {
      onStreetTypeChange({ code: 'err', libelle: value });
    }
  };

  const checkStatusZipCode = () => {
    if (!address.zipCode) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE,
      });
      return;
    }
    if (!zipCode.test(address.zipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE,
      });
      return;
    }
    if (!notEmpty.test(address.zipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE,
      });
      return;
    }
    if (!onlyNumbers.test(address.zipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_ONLY_NUMBERS,
      });
      return;
    }
    if (!zipCodeNoZero.test(address.zipCode)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_NO_ZERO,
      });
      return;
    }
    if (citiesList.length === 0 && !alternateCityInput) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.WRONG_ZIPCODE,
      });
      return;
    }

    setStatusZipCode({
      status: true,
      errorMessage: '',
    });
  };

  useEffect(() => {
    if (address.zipCode && address.zipCode.length === 5) {
      checkStatusZipCode();
    }
  }, [citiesList]);

  const checkStatusAddressCity = (cityByZipCodeList: CodeLibelle[]) => {
    setStatusAddressCity({
      status: 'none',
      errorMessage: '',
    });
    if (cityRef.current && cityRef.current !== null && address.city) {
      const cityMatchesListElement = cityByZipCodeList?.find(city => {
        return city.libelle.toUpperCase() === address.city?.toUpperCase();
      });

      if (cityMatchesListElement) {
        onCityChange(cityMatchesListElement.libelle);
        setStatusAddressCity({
          status: 'valid',
          errorMessage: '',
        });
      } else {
        onCityChange('');
        setStatusAddressCity({
          status: 'invalid',
          errorMessage: messagesCheckInput.INPUT_ERROR,
        });
      }
    }
    if (!address.city) {
      if (cityFromList) {
        onCityChange(cityFromList.libelle);
        setStatusAddressCity({
          status: 'valid',
          errorMessage: '',
        });
      } else {
        onCityChange(undefined);
        setStatusAddressCity({
          status: 'invalid',
          errorMessage: messagesCheckInput.ADDRESS_CITY_REQUIRED,
        });
      }
    }
  };

  const onStreetTypeSelect = (e: Event) => {
    const streetTypeMatchesListElement = streetTypeReferential?.find(streetType => {
      return streetType.libelle === (e.target as unknown as ComboInputDS).value;
    });

    if (streetTypeMatchesListElement) {
      onStreetTypeChange(streetTypeMatchesListElement);
    }
  };

  const onCitySelected = (newValue: CodeLibelle | undefined) => {
    setCityFromList(newValue);
    if (!newValue) {
      onCityChange(undefined);
    } else {
      onCityChange(newValue.libelle);
    }
  };

  const isInputLocked = (inputToCheck: AddressBlockInputs): boolean => {
    if (
      lockedInputs?.find(lockedInput => {
        return lockedInput === inputToCheck;
      })
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getStreets().then(response => {
      setStreetTypeReferential(response.Resultat);
    });
  }, []);

  useEffect(() => {
    if (!isInputLocked('city')) {
      if (!alternateCityInput && address.zipCode && address.zipCode.length === 5) {
        getCitiesByZipCode(address.zipCode).then(cityByZipCodeList => {
          checkStatusAddressCity(cityByZipCodeList);
          setCitiesList(cityByZipCodeList);
        });
      } else if (cityFromList) {
        setCitiesList([cityFromList]);
        checkStatusAddressCity([cityFromList]);
      } else if (!alternateCityInput) {
        setIsCityInvalid(true);
        onCityChange(undefined);

        setCitiesList([]);
      }
    }
  }, [address.zipCode]);

  useEffect(() => {
    if (address.streetType?.code === 'err') {
      setStatusStreetType({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
      if (address.street !== '')
        setStatusStreet({
          status: true,
          errorMessage: '',
        });
      else
        setStatusStreet({
          status: undefined,
          errorMessage: '',
        });
      return;
    }
    if (address.street === '' || !address.street) {
      if (!address.streetType || address.streetType.code === '') {
        setStatusStreet({
          status: undefined,
          errorMessage: '',
        });
        setStatusStreetType({
          status: 'none',
          errorMessage: '',
        });
      } else {
        setStatusStreet({
          status: false,
          errorMessage: messagesCheckInput.ADRESS_STREET_NAME_REQUIRED,
        });
        setStatusStreetType({
          status: 'valid',
          errorMessage: '',
        });
      }
      return;
    }
    if (address.streetType && address.streetType.code !== '') {
      setStatusStreet({
        status: true,
        errorMessage: '',
      });
      setStatusStreetType({
        status: 'none',
        errorMessage: '',
      });
      setStatusStreetType({
        status: 'valid',
        errorMessage: '',
      });
      return;
    }
    if (address.streetType?.code === '' || !address.streetType) {
      setStatusStreet({
        status: true,
        errorMessage: '',
      });
      setStatusStreetType({
        status: 'invalid',
        errorMessage: messagesCheckInput.ADRESS_STREET_TYPE_REQUIRED,
      });
    }
  }, [address.streetType, address.street]);

  const onCityBlur = (event: Event) => {
    if (cityRef.current && cityRef.current !== null) {
      if (event && event.target) {
        const cityMatchesListElement = citiesList?.find(city => {
          return (
            city.libelle.toUpperCase() ===
            ((event.target as unknown as ComboInputDS).value as string).toUpperCase()
          );
        });
        if (cityMatchesListElement) {
          onCityChange(cityMatchesListElement.libelle);
        } else {
          onCityChange(undefined);
        }
      }
    }
  };

  const renderCityInput = () => {
    return alternateCityInput ? (
      <LightCustomInput
        name=""
        label="Ville"
        placeholder="ex: Paris"
        value={address.city}
        onChange={onCityChange}
        isDisabled={isInputLocked('city')}
      />
    ) : (
      <CityInput
        label="Ville"
        defaultValue={cityFromList?.libelle || ''}
        cities={citiesList}
        onCitySelect={e => {
          onCitySelected((e as CustomEvent).detail.value as CodeLibelle);
        }}
        onCityBlur={onCityBlur}
        className="show-input-ds-validation city-input-finalisation-projet"
        cityRef={cityRef}
        valid={!isCityInvalid}
        status={statusAddressCity?.status}
        errorMessage={statusAddressCity?.errorMessage}
      />
    );
  };

  return (
    <>
      {!(
        address.street ||
        address.streetType?.code ||
        address.streetType?.code === 'err' ||
        address.additionalInformation ||
        address.POBoxOrHamlet
      ) && checkInputs ? (
        <>
          <StyledErrorMessage>{messagesCheckInput.ADDRESS_REQUIRED}</StyledErrorMessage>
          <Space marginTop="1.6rem" />
        </>
      ) : null}
      <StyledAddressBlockMainContainer>
        <StyledAddressBlockRow>
          <StyledAddressBlockInputContainer style={{ flex: '2' }}>
            <StreetNumberInput
              name=""
              label="N° rue"
              placeholder="ex: 23"
              value={address.streetNumber}
              onChange={newValue => {
                onStreetNumberChange(newValue);
              }}
              isDisabled={isInputLocked('streetNumber')}
            />
          </StyledAddressBlockInputContainer>
          <StyledAddressBlockInputContainer style={{ flex: '3' }}>
            <ComboInput
              list-on-open
              shadow
              align-items="left"
              items={JSON.stringify(streetTypeReferential)}
              field="libelle"
              label="Type de voie"
              placeholder="ex: rue"
              ref={streetTypeRef}
              onSelectedList={onStreetTypeSelect}
              onListBlur={e => {
                onStreetTypeCheck((e.target as unknown as ComboInputDS).value as string);
              }}
              value={address.streetType?.libelle || ''}
              disabled={isInputLocked('streetType') || undefined}
              status={statusStreetType?.status}
              required={statusStreet?.status}
              {...(statusStreetType?.errorMessage !== ''
                ? { 'error-message': statusStreetType?.errorMessage }
                : {})}
            />
          </StyledAddressBlockInputContainer>
          <StyledAddressBlockInputContainer style={{ flex: '6' }}>
            <LightCustomInput
              name=""
              label="Nom de la voie"
              placeholder="ex: Saint Michel"
              value={address.street}
              onChange={onStreetChange}
              isDisabled={isInputLocked('street')}
              error={statusStreet?.errorMessage}
              isValid={statusStreet?.status}
            />
          </StyledAddressBlockInputContainer>
        </StyledAddressBlockRow>
        <StyledAddressBlockRow>
          <StyledAddressBlockInputContainer style={{ flex: '1' }}>
            <LightCustomInput
              name=""
              label="Complément d'adresse"
              placeholder="Digicode, Bâtiment, étage..."
              value={address.additionalInformation}
              onChange={onAdditionalInformationChange}
              isDisabled={isInputLocked('additionalInformation')}
            />
          </StyledAddressBlockInputContainer>
        </StyledAddressBlockRow>
        <StyledAddressBlockRow>
          <StyledAddressBlockInputContainer style={{ flex: '1' }}>
            <LightCustomInput
              name=""
              label="Lieu-dit ou boite postale"
              value={address.POBoxOrHamlet}
              onChange={onPOBoxOrHamletChange}
              isDisabled={isInputLocked('POBoxOrHamlet')}
            />
          </StyledAddressBlockInputContainer>
        </StyledAddressBlockRow>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '15.6rem', marginRight: '1.6rem' }}>
            <CustomInput
              name="zip-code"
              label="Code postal"
              placeholder="75001"
              onChange={newValue => {
                onZipCodeChange(newValue);
              }}
              onBlur={e => {
                if (!isInputLocked('zipCode')) checkStatusZipCode();
              }}
              onFocus={() => {
                setIsZipCodeInputFocused(true);
              }}
              isDisabled={isInputLocked('zipCode')}
              isValid={statusZipCode?.status}
              isFocused={isZipCodeInputFocused}
              value={address.zipCode}
              error={statusZipCode?.errorMessage}
            />
          </div>
          {renderCityInput()}
        </div>
      </StyledAddressBlockMainContainer>
    </>
  );
};

export default AddressBlock;
