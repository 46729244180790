import styled from 'styled-components';

export const StyledSaveLaterText = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  font-size: 1.2rem;
`;

export const StyledSaveLaterWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledComboInput = styled.div`
  width: 40rem;
`;

export const StyledMargin = styled.div`
  margin: 0 10rem;
  max-width: 144rem;
  @media (min-width: 1400px) {
    margin: 0 25rem;
  }
`;

export const StyledSelectionWrapper = styled.div<{ isSelected: boolean }>`
  height: 4.8rem;
  width: 48.4rem;
  border: 0.1rem solid
    ${({ isSelected, theme }) =>
      isSelected
        ? theme.colors.chartColors.mainGreen500
        : theme.colors.chartColors.neutral500};
  border-radius: 0.4rem;
  margin: 0rem 1.6rem 0 9.6rem;
  display: flex;
  align-items: center;
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.colors.chartColors.mainGreen50
      : theme.colors.chartColors.neutral0};
`;

export const StyledCheckboxWrapper = styled.div`
  padding-left: 1.2rem;
  color: ${({ theme }) => theme.colors.chartColors.neutral500};
`;

export const StyledBtnWrapper = styled.div`
  display: flex;
  margin-top: 6.4rem;
  margin-left: 9.4rem;
  margin-bottom: 4.4rem;
  gap: 4.2rem;
  align-items: center;
`;

export const ToggleText = styled.span`
  margin-right: 1rem;
  display: block;
  margin-top: 0.3rem;
  height: 3.2rem;
  font-weight: bold;
  font-size: 1.6rem;
`;

export const FlexContainer = styled.div`
  display: flex;
  size: 200px;
`;

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlainText = styled.span`
  color: ${({ theme }) => theme.colors.chartColors.neutral500};
  font-size: 16px;
`;

export const BaseInputContainer = styled.div<{ componentWidth: string }>`
  base-input {
    --width-component: ${({ componentWidth }) => componentWidth};
  }
`;

export const StyledTitle = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
`;
