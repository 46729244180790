import jsPDF from 'jspdf';
import { UserInfos } from 'types';
import { InterlocutorState } from 'reducers/interlocutor/type';
import { IdentityState } from 'reducers/identity/types';
import { PropositionOutDtoResp } from 'types/FaisabiliteDTO';
import { FinalisationProjetState } from 'reducers/finalisationProjet/types';
import {
  HousingStatus,
  MaritalStatus,
  SituationFoyerState,
} from 'reducers/situationFoyer/types';
import { RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import { Asset, OperationState } from 'reducers/operationClient/types';
import { SituationProfessionnelleState } from 'reducers/situationProfessionnelle/types';
import { LoanAdditionalInformationState } from 'reducers/loanAdditionalInformation/type';
import { DebtsAdditionalInformationState } from 'reducers/debtsAdditionalInformation/type';
import { IbanState } from 'reducers/iban/type';
import {
  calculateTotalCharges,
  calculateTotalIncomes,
  determineProjectType,
  formatNumberByThousand,
} from 'utils/commun';
import * as messages from '../messages';

const COLONNE_1 = 5;
const COLONNE_2 = 45;
const COLONNE_3 = 100;
const COLONNE_4 = 150;
const COLONNE_5 = 165;
const COLONNE_LAST = 205;

const COLONNE_3_BIS = 75;
const COLONNE_4_BIS = 140;

const MAX_WIDTH_2 = COLONNE_3 - COLONNE_2;
const MAX_WIDTH_3 = COLONNE_4 - COLONNE_3;

const INTERLIGNE = 10;
const POLICE_SIZE = {
  title: 14,
  data: 10,
  table: 8,
};

export const courtierPage = (doc: jsPDF): jsPDF => {
  const simulationResult = JSON.parse(
    sessionStorage.getItem('simulationResult') || '{}',
  ) as PropositionOutDtoResp;

  const userInfos = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  const interlocutor = JSON.parse(
    sessionStorage.getItem('interlocutor') || '{}',
  ) as InterlocutorState;

  const operationClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || '{}',
  ) as OperationState;

  const newDoc = doc;

  let hauteur = 50;

  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.setFont('Helvetica', 'normal');

  newDoc.text(messages.CLIENT_FILE.dossier_number, COLONNE_1, hauteur);
  newDoc.text(`${sessionStorage.getItem('numeroProjet')}`, COLONNE_2, hauteur);
  newDoc.text(messages.CLIENT_FILE.projet, COLONNE_3, hauteur);
  newDoc.text(
    determineProjectType(
      operationClient?.hasInsurance,
      simulationResult?.Resultat?.Proposition.idSecteurLegislatif.toString(),
    ),
    COLONNE_4,
    hauteur,
  );

  hauteur += 10;
  newDoc.text(messages.CLIENT_FILE.segment, COLONNE_1, hauteur);
  newDoc.text(
    simulationResult.Resultat.Proposition.libelleSegmentation,
    COLONNE_2,
    hauteur,
  );
  newDoc.text(messages.CLIENT_FILE.processTraitement, COLONNE_3, hauteur);
  newDoc.text(
    simulationResult.Resultat.Proposition.processTraitement,
    COLONNE_4,
    hauteur,
  );

  // Bloc Coutier
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');
  hauteur += 15;
  newDoc.text('Courtier', COLONNE_1, hauteur);

  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.setFont('Helvetica', 'normal');

  hauteur += 10;
  newDoc.text(messages.CLIENT_FILE.coutier_number, COLONNE_1, hauteur);
  newDoc.text(`${userInfos.numContremarque}`, COLONNE_2, hauteur);
  newDoc.text(messages.CLIENT_FILE.zip_code, COLONNE_3, hauteur);
  newDoc.text(`${userInfos.codePostalCourtier}`, COLONNE_4, hauteur);

  hauteur += 10;
  newDoc.text(messages.CLIENT_FILE.Name, COLONNE_1, hauteur);
  newDoc.text(userInfos.nomCourtier, COLONNE_2, hauteur, {
    maxWidth: MAX_WIDTH_2,
  });
  newDoc.text(messages.CLIENT_FILE.city, COLONNE_3, hauteur);
  newDoc.text(userInfos.villeCourtier, COLONNE_4, hauteur, {
    maxWidth: newDoc.internal.pageSize.width - COLONNE_4 - 5,
  });

  hauteur += 10;
  newDoc.text(messages.CLIENT_FILE.miob, COLONNE_1, hauteur);
  if (interlocutor.isMIOB) doc.text(`${interlocutor.miobName}`, COLONNE_2, hauteur);

  // bloc interlocuteur

  doc.setFontSize(POLICE_SIZE.title);
  doc.setFont('Helvetica', 'bold');
  hauteur += 15;
  doc.text('Dossier suivi par ', COLONNE_1, hauteur);

  doc.setFontSize(POLICE_SIZE.data);
  doc.setFont('Helvetica', 'normal');

  hauteur += 10;
  doc.text(messages.CLIENT_FILE.monteur, COLONNE_1, hauteur);
  if (interlocutor.monteurName)
    doc.text(`${interlocutor.monteurName}`, COLONNE_2, hauteur);

  hauteur += 10;
  doc.text(messages.CLIENT_FILE.vendeur, COLONNE_1, hauteur);
  if (interlocutor.interlocutorName)
    doc.text(`${interlocutor.interlocutorName.libelle}`, COLONNE_2, hauteur);

  hauteur += 10;
  // doc.text(messages.CLIENT_FILE.analyste, COLONNE_1, hauteur);
  // if (interlocutor.analysteName)
  //   doc.text(`${interlocutor.analysteName}`, COLONNE_2, hauteur);

  return doc;
};

export const identityPage = (doc: jsPDF): jsPDF => {
  const borrowerIdentity = JSON.parse(
    sessionStorage.getItem('borrowerIdentity') || '{}',
  ) as IdentityState;

  const { hasCoBorrower } = borrowerIdentity;

  const coBorrowerIdentity = JSON.parse(
    sessionStorage.getItem('coBorrowerIdentity') || '{}',
  ) as IdentityState;

  const situationFoyer = JSON.parse(
    sessionStorage.getItem('situationFoyer') || '{}',
  ) as SituationFoyerState;

  const finalisationProjet = JSON.parse(
    sessionStorage.getItem('finalisationProjet') || '{}',
  ) as FinalisationProjetState;

  doc.addPage();

  const newDoc = doc;

  let hauteur = 20;

  // Bloc Données clients
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(`INFORMATIONS EMPRUNTEUR(S)`, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);

  newDoc.text(messages.CLIENT_FILE.info, COLONNE_1, hauteur);
  newDoc.text(messages.CLIENT_FILE.borrower, COLONNE_2, hauteur);
  if (hasCoBorrower) newDoc.text(messages.CLIENT_FILE.coBorrower, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.setFont('Helvetica', 'normal');

  newDoc.text(messages.CLIENT_FILE.identity.title, COLONNE_1, hauteur);
  newDoc.text(
    borrowerIdentity.civility === 'MME' ? 'Madame' : 'Monsieur',
    COLONNE_2,
    hauteur,
  );
  if (hasCoBorrower)
    newDoc.text(
      coBorrowerIdentity.civility === 'MME' ? 'Madame' : 'Monsieur',
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.Name, COLONNE_1, hauteur);
  newDoc.text(`${borrowerIdentity.lastName}`, COLONNE_2, hauteur, {
    maxWidth: MAX_WIDTH_2,
  });
  if (hasCoBorrower)
    newDoc.text(`${coBorrowerIdentity.lastName}`, COLONNE_3, hauteur, {
      maxWidth: MAX_WIDTH_3,
    });
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.prenom, COLONNE_1, hauteur);
  newDoc.text(`${borrowerIdentity.firstName}`, COLONNE_2, hauteur, {
    maxWidth: MAX_WIDTH_2,
  });
  if (hasCoBorrower)
    newDoc.text(`${coBorrowerIdentity.firstName}`, COLONNE_3, hauteur, {
      maxWidth: MAX_WIDTH_3,
    });
  hauteur += INTERLIGNE;

  if (
    !borrowerIdentity.isBirthNameSameAsName ||
    !coBorrowerIdentity.isBirthNameSameAsName
  ) {
    newDoc.text(messages.CLIENT_FILE.identity.birth_name, COLONNE_1, hauteur);
    if (
      !borrowerIdentity.isBirthNameSameAsName &&
      borrowerIdentity.birthName &&
      borrowerIdentity.birthName !== ''
    ) {
      newDoc.text(`${borrowerIdentity.birthName}`, COLONNE_2, hauteur);
    }
    if (
      !coBorrowerIdentity.isBirthNameSameAsName &&
      coBorrowerIdentity.birthName &&
      coBorrowerIdentity.birthName !== ''
    ) {
      newDoc.text(`${coBorrowerIdentity.birthName}`, COLONNE_3, hauteur);
    }
    hauteur += INTERLIGNE;
  }

  newDoc.text(messages.CLIENT_FILE.identity.birth_date, COLONNE_1, hauteur);
  newDoc.text(`${borrowerIdentity.birthDate}`, COLONNE_2, hauteur);
  if (hasCoBorrower) newDoc.text(`${coBorrowerIdentity.birthDate}`, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.birth_dept, COLONNE_1, hauteur);
  newDoc.text(`${borrowerIdentity.birthDepartment?.libelle}`, COLONNE_2, hauteur);
  if (hasCoBorrower)
    newDoc.text(`${coBorrowerIdentity.birthDepartment?.libelle}`, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.birt_city, COLONNE_1, hauteur);
  newDoc.text(`${borrowerIdentity.birthCity?.libelle}`, COLONNE_2, hauteur, {
    maxWidth: MAX_WIDTH_2,
  });
  if (hasCoBorrower)
    newDoc.text(`${coBorrowerIdentity.birthCity?.libelle}`, COLONNE_3, hauteur, {
      maxWidth: MAX_WIDTH_3,
    });
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.birth_country, COLONNE_1, hauteur);
  newDoc.text(`${borrowerIdentity.birthCountry?.libelle}`, COLONNE_2, hauteur);
  if (hasCoBorrower)
    newDoc.text(`${coBorrowerIdentity.birthCountry?.libelle}`, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.nationality, COLONNE_1, hauteur);
  newDoc.text(`${borrowerIdentity.nationality?.libelle || ''}`, COLONNE_2, hauteur);
  if (hasCoBorrower)
    newDoc.text(`${coBorrowerIdentity.nationality?.libelle || ''}`, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.sit_famille, COLONNE_1, hauteur);
  if (situationFoyer.maritalStatus)
    newDoc.text(`${MaritalStatus[situationFoyer.maritalStatus]}`, COLONNE_2, hauteur);
  if (situationFoyer.coBorrowerMaritalStatus)
    newDoc.text(
      `${MaritalStatus[situationFoyer.coBorrowerMaritalStatus]}`,
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.identity_paper, COLONNE_1, hauteur);
  newDoc.text(
    `${finalisationProjet.borrower.identityType?.libelle || ''}`,
    COLONNE_2,
    hauteur,
  );
  if (hasCoBorrower)
    newDoc.text(
      `${finalisationProjet.coBorrower.identityType?.libelle || ''}`,
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.identity.paper_number, COLONNE_1, hauteur);
  newDoc.text(`${finalisationProjet.borrower.identityNumber || ''}`, COLONNE_2, hauteur);
  if (hasCoBorrower)
    newDoc.text(
      `${finalisationProjet.coBorrower.identityNumber || ''}`,
      COLONNE_3,
      hauteur,
    );

  // Bloc adresse
  hauteur += 30;
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.adresse.title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.setFont('Helvetica', 'normal');

  newDoc.text(messages.CLIENT_FILE.adresse.cplt, COLONNE_1, hauteur);
  newDoc.text(
    finalisationProjet.borrower?.address?.additionalInformation || '',
    COLONNE_2,
    hauteur,
  );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.adresse.street || '', COLONNE_1, hauteur);
  newDoc.text(
    `${finalisationProjet?.borrower.address.streetNumber || ''} ${
      finalisationProjet?.borrower.address.streetType?.libelle || ''
    } ${finalisationProjet?.borrower.address.street || ''}`,
    COLONNE_2,
    hauteur,
  );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.adresse.post_box, COLONNE_1, hauteur);
  newDoc.text(
    finalisationProjet.borrower?.address?.POBoxOrHamlet || '',
    COLONNE_2,
    hauteur,
  );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.zip_code, COLONNE_1, hauteur);
  newDoc.text(finalisationProjet.borrower?.address?.zipCode || '', COLONNE_2, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.city, COLONNE_1, hauteur);
  newDoc.text(finalisationProjet.borrower?.address?.city || '', COLONNE_2, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.adresse.mail, COLONNE_1, hauteur);
  newDoc.text(finalisationProjet.borrower?.email || '', COLONNE_2, hauteur);
  if (hasCoBorrower)
    newDoc.text(`${finalisationProjet.coBorrower?.email || ''}`, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.adresse.fix_phone, COLONNE_1, hauteur);
  newDoc.text(finalisationProjet.borrower?.homePhoneNumber || '', COLONNE_2, hauteur);
  if (hasCoBorrower)
    newDoc.text(
      `${finalisationProjet.coBorrower?.homePhoneNumber || ''}`,
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.adresse.mobile_phone, COLONNE_1, hauteur);
  newDoc.text(finalisationProjet.borrower?.cellPhoneNumber || '', COLONNE_2, hauteur);
  if (hasCoBorrower)
    newDoc.text(
      `${finalisationProjet.coBorrower?.cellPhoneNumber || ''}`,
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  return newDoc;
};

export const foyerProPage = (doc: jsPDF): jsPDF => {
  const situationFoyer = JSON.parse(
    sessionStorage.getItem('situationFoyer') || '{}',
  ) as SituationFoyerState;

  const situationProfessionnelle = JSON.parse(
    sessionStorage.getItem('situationProfessionnelle') || '{}',
  ) as SituationProfessionnelleState;

  const finalisationProjet = JSON.parse(
    sessionStorage.getItem('finalisationProjet') || '{}',
  ) as FinalisationProjetState;

  const newDoc = doc;

  newDoc.addPage();

  let hauteur = 20;

  // Bloc Siutation Foyer
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(`COMPLEMENTS D'INFORMATION EMPRUNTEUR`, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.text(messages.CLIENT_FILE.adresse.habitat, COLONNE_1, hauteur);
  newDoc.text(messages.CLIENT_FILE.famile.title, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.setFont('Helvetica', 'normal');

  newDoc.text(messages.CLIENT_FILE.adresse.logement, COLONNE_1, hauteur);
  if (situationFoyer.housingStatus)
    newDoc.text(
      `${HousingStatus[situationFoyer?.housingStatus] || ''}`,
      COLONNE_2,
      hauteur,
    );

  newDoc.text(messages.CLIENT_FILE.famile.number_foyer, COLONNE_3, hauteur);
  newDoc.text(
    `${situationFoyer.numberOfPersonInCurrentHouse || 1}`,
    COLONNE_LAST,
    hauteur,
    { align: 'right' },
  );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.adresse.entry_date, COLONNE_1, hauteur);
  newDoc.text(
    `${situationFoyer.startingYearLivingInCurrentHouse || ''}`,
    COLONNE_2,
    hauteur,
  );

  newDoc.text(messages.CLIENT_FILE.famile.Number_child, COLONNE_3, hauteur);
  if (situationFoyer.childrenInCharge) {
    newDoc.text(
      `${situationFoyer.childrenInChargeBirthYear?.length}`,
      COLONNE_LAST,
      hauteur,
      { align: 'right' },
    );
    hauteur += INTERLIGNE;

    // gestion affichage année de naissance enfant
    newDoc.text(messages.CLIENT_FILE.famile.birthdate_child, COLONNE_3, hauteur);

    const perChunk = 3; // items per chunk

    const result = situationFoyer?.childrenInChargeBirthYear?.reduce(
      (onGoingArray: string[], item, index) => {
        const chunkIndex = Math.floor(index / perChunk);
        const resultArray = onGoingArray;
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = item.birthYear; // start a new chunk
        } else resultArray[chunkIndex] += `    ${item.birthYear}`;

        return resultArray;
      },
      [],
    );

    newDoc.text(result || '', COLONNE_LAST, hauteur, { align: 'right' });
  } else newDoc.text('0', COLONNE_LAST, hauteur, { align: 'right' });

  hauteur = 105;

  // Block Siutation Professionnel
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.profession.title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.text(messages.CLIENT_FILE.info, COLONNE_1, hauteur);
  newDoc.text(messages.CLIENT_FILE.borrower, COLONNE_2, hauteur);
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(messages.CLIENT_FILE.coBorrower, COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.setFont('Helvetica', 'normal');

  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.text(messages.CLIENT_FILE.profession.category, COLONNE_1, hauteur);
  newDoc.text(
    situationProfessionnelle?.borrower?.profession?.libelle || '',
    COLONNE_2,
    hauteur,
    { maxWidth: MAX_WIDTH_2 },
  );
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(
      situationProfessionnelle?.coBorrower?.profession?.libelle || '',
      COLONNE_3,
      hauteur,
      { maxWidth: MAX_WIDTH_3 },
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.profession.contrat, COLONNE_1, hauteur);
  newDoc.text(
    situationProfessionnelle?.borrower?.typeContrat?.libelle || '',
    COLONNE_2,
    hauteur,
  );
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(
      situationProfessionnelle?.coBorrower?.typeContrat?.libelle || '',
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.profession.employeur, COLONNE_1, hauteur);
  newDoc.text(finalisationProjet.borrower?.employerName || '', COLONNE_2, hauteur);
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(finalisationProjet?.coBorrower?.employerName || '', COLONNE_3, hauteur);
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.profession.job_date, COLONNE_1, hauteur);
  newDoc.text(
    situationProfessionnelle?.borrower?.currentJobOrSituationStartingDate || '',
    COLONNE_2,
    hauteur,
  );
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(
      situationProfessionnelle?.coBorrower?.currentJobOrSituationStartingDate || '',
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  // SIREN ET APE
  const ECARTAPE = 25;
  newDoc.text(messages.CLIENT_FILE.profession.SIREN_APE, COLONNE_1, hauteur);
  newDoc.text(
    `${situationProfessionnelle?.borrower?.sirenNumber || ''}`,
    COLONNE_2,
    hauteur,
  );
  newDoc.text(
    situationProfessionnelle?.borrower?.apeNumber ? `/` : '',
    COLONNE_2 + ECARTAPE - 5,
    hauteur,
  );
  newDoc.text(
    `${situationProfessionnelle?.borrower?.apeNumber || ''}`,
    COLONNE_2 + ECARTAPE,
    hauteur,
  );
  if (situationProfessionnelle.hasCoBorrower) {
    newDoc.text(
      `${situationProfessionnelle?.coBorrower?.sirenNumber || ''}`,
      COLONNE_3,
      hauteur,
    );
    newDoc.text(
      situationProfessionnelle?.coBorrower?.apeNumber ? `/` : '',
      COLONNE_3 + ECARTAPE - 5,
      hauteur,
    );
    newDoc.text(
      `${situationProfessionnelle?.coBorrower?.apeNumber || ''}`,
      COLONNE_3 + ECARTAPE,
      hauteur,
    );
  }
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.profession.zip_code, COLONNE_1, hauteur);
  newDoc.text(
    situationProfessionnelle?.borrower?.adresse?.codePostal || '',
    COLONNE_2,
    hauteur,
  );
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(
      situationProfessionnelle?.coBorrower?.adresse?.codePostal || '',
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.profession.city, COLONNE_1, hauteur);
  newDoc.text(
    situationProfessionnelle?.borrower?.adresse?.city?.libelle || '',
    COLONNE_2,
    hauteur,
    { maxWidth: MAX_WIDTH_2 },
  );
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(
      situationProfessionnelle?.coBorrower?.adresse?.city?.libelle || '',
      COLONNE_3,
      hauteur,
      { maxWidth: MAX_WIDTH_3 },
    );
  hauteur += INTERLIGNE;

  newDoc.text(messages.CLIENT_FILE.profession.country, COLONNE_1, hauteur);
  newDoc.text(
    situationProfessionnelle?.borrower?.employerCountry?.libelle || '',
    COLONNE_2,
    hauteur,
  );
  if (situationProfessionnelle.hasCoBorrower)
    newDoc.text(
      situationProfessionnelle?.coBorrower?.employerCountry?.libelle || '',
      COLONNE_3,
      hauteur,
    );
  hauteur += INTERLIGNE;

  return newDoc;
};

export const ressourcePage = (doc: jsPDF): jsPDF => {
  const ressourcesMensuelles = JSON.parse(
    sessionStorage.getItem('ressourcesMensuelles') || '{}',
  ) as RessourcesMensuellesState;

  const { hasCoBorrower } = ressourcesMensuelles;

  doc.addPage();

  const newDoc = doc;

  let hauteur = 20;

  // Bloc Revenue
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.revenue.title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.setFont('Helvetica', 'normal');

  newDoc.text(messages.CLIENT_FILE.revenue.salaire_emp, COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(
      ressourcesMensuelles.borrowerIncome?.monthlyIncomes || '0',
    )} €`,
    COLONNE_2,
    hauteur,
  );
  if (hasCoBorrower) {
    newDoc.text(messages.CLIENT_FILE.revenue.salaire_coemp, COLONNE_3, hauteur);
    newDoc.text(
      `${formatNumberByThousand(
        ressourcesMensuelles.coBorrowerIncome?.monthlyIncomes || '0',
      )} €`,
      COLONNE_4,
      hauteur,
    );
  }
  hauteur += INTERLIGNE;

  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.revenue.type_revenue, COLONNE_1, hauteur);
  newDoc.text(messages.CLIENT_FILE.revenue.value, COLONNE_3, hauteur, {
    align: 'right',
  });

  newDoc.setFont('Helvetica', 'normal');

  if (ressourcesMensuelles.borrowerIncome.retirementMonthlyIncomes) {
    hauteur += INTERLIGNE;
    newDoc.text(messages.CLIENT_FILE.revenue.retraite, COLONNE_1, hauteur);
    newDoc.text(
      `${formatNumberByThousand(
        ressourcesMensuelles.borrowerIncome?.retirementMonthlyIncomes || '0',
      )} €`,
      COLONNE_3,
      hauteur,
      { align: 'right' },
    );
  }
  if (ressourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes) {
    hauteur += INTERLIGNE;
    newDoc.text(messages.CLIENT_FILE.revenue.retraite, COLONNE_1, hauteur);
    newDoc.text(
      `${formatNumberByThousand(
        ressourcesMensuelles.coBorrowerIncome?.retirementMonthlyIncomes || '0',
      )} €`,
      COLONNE_3,
      hauteur,
      { align: 'right' },
    );
  }

  ressourcesMensuelles.otherIncomes?.forEach(income => {
    hauteur += INTERLIGNE;
    if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 1.5) {
      hauteur = 20;
      newDoc.addPage('a4', 'p');
    }

    newDoc.text(income.libelle || '', COLONNE_1, hauteur);
    newDoc.text(
      `${formatNumberByThousand(income.value?.toString() || '0')} €`,
      COLONNE_3,
      hauteur,
      { align: 'right' },
    );
  });

  hauteur += INTERLIGNE;
  newDoc.setFont('Helvetica', 'bold');
  newDoc.text(messages.CLIENT_FILE.revenue.total_revenue, COLONNE_1, hauteur);

  newDoc.setFont('Helvetica', 'normal');
  newDoc.text(
    `${formatNumberByThousand(
      Number(calculateTotalIncomes(ressourcesMensuelles)).toString() || '0',
    )} €`,
    COLONNE_3,
    hauteur,
    { align: 'right' },
  );

  // CHARGE MENSUELLE
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');
  hauteur += 20;

  if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 4) {
    hauteur = 20;
    newDoc.addPage('a4', 'p');
  }

  newDoc.text(messages.CLIENT_FILE.revenue.charge_title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);

  newDoc.text(messages.CLIENT_FILE.revenue.type_charge, COLONNE_1, hauteur);
  newDoc.text(messages.CLIENT_FILE.revenue.value, COLONNE_3, hauteur, {
    align: 'right',
  });

  newDoc.setFont('Helvetica', 'normal');

  ressourcesMensuelles.monthlyCharges?.forEach(charge => {
    hauteur += INTERLIGNE;
    if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 1.5) {
      hauteur = 20;
      newDoc.addPage('a4', 'p');
    }

    newDoc.text(charge.libelle || '', COLONNE_1, hauteur);
    newDoc.text(
      `${formatNumberByThousand(charge.value?.toString() || '0')} €`,
      COLONNE_3,
      hauteur,
      { align: 'right' },
    );
  });

  hauteur += INTERLIGNE;
  newDoc.setFont('Helvetica', 'bold');
  newDoc.text(messages.CLIENT_FILE.revenue.total_charge, COLONNE_1, hauteur);

  newDoc.setFont('Helvetica', 'normal');
  newDoc.text(
    `${formatNumberByThousand(
      Number(calculateTotalCharges(ressourcesMensuelles)).toString() || '0',
    )} €`,
    COLONNE_3,
    hauteur,
    { align: 'right' },
  );

  // DONNER BANQUAIRE
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');
  hauteur += 20;

  if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 4) {
    hauteur = 20;
    newDoc.addPage('a4', 'p');
  }

  newDoc.text(messages.CLIENT_FILE.revenue.banque_title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);

  newDoc.text(messages.CLIENT_FILE.revenue.banque, COLONNE_1, hauteur);
  newDoc.text(messages.CLIENT_FILE.revenue.annee_ouverture, COLONNE_3, hauteur, {
    align: 'right',
  });

  newDoc.setFont('Helvetica', 'normal');

  ressourcesMensuelles.banks?.forEach(bank => {
    hauteur += INTERLIGNE;
    if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 1.5) {
      hauteur = 20;
      newDoc.addPage('a4', 'p');
    }
    newDoc.text(bank.libelle || '', COLONNE_1, hauteur);
    newDoc.text(bank.year || '', COLONNE_3, hauteur, { align: 'right' });
  });

  return newDoc;
};

export const loanPage = (doc: jsPDF): jsPDF => {
  const operationClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || '{}',
  ) as OperationState;

  const loanAdditionalInformation = JSON.parse(
    sessionStorage.getItem('loanAdditionalInformation') || '{}',
  ) as LoanAdditionalInformationState;

  const sizeCol = 19;
  const { loanList } = operationClient;

  const newDoc = doc;

  newDoc.addPage('a4', 'l');

  let hauteur = 20;

  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.pret.title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.table);

  newDoc.text(messages.CLIENT_FILE.pret.numero, COLONNE_1, hauteur, {
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.crediteur, COLONNE_1 + sizeCol, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.solde, COLONNE_1 + sizeCol * 2, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.IRA, COLONNE_1 + sizeCol * 3, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.mensualite, COLONNE_1 + sizeCol * 4, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.type_pret, COLONNE_1 + sizeCol * 5, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.date_debut, COLONNE_1 + sizeCol * 6, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.taux, COLONNE_1 + sizeCol * 7, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.preavis, COLONNE_1 + sizeCol * 8, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.buyback, COLONNE_1 + sizeCol * 9, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.capital, COLONNE_1 + sizeCol * 10, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(
    messages.CLIENT_FILE.pret.restructuration,
    COLONNE_1 + sizeCol * 11,
    hauteur,
    {
      align: 'center',
      maxWidth: sizeCol,
    },
  );
  newDoc.text(messages.CLIENT_FILE.pret.duree, COLONNE_1 + sizeCol * 12, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.morgage, COLONNE_1 + sizeCol * 13, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });
  newDoc.text(messages.CLIENT_FILE.pret.nature, COLONNE_1 + sizeCol * 14, hauteur, {
    align: 'center',
    maxWidth: sizeCol,
  });

  newDoc.setFont('Helvetica', 'normal');

  loanList?.forEach(loan => {
    const otherdata = loanAdditionalInformation?.loans?.find(
      loanAddionalData => loanAddionalData.code === loan.code,
    );

    hauteur += INTERLIGNE * 1.5;

    if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 1.5) {
      hauteur = 20;
      newDoc.addPage('a4', 'l');
    }

    newDoc.text(`${otherdata?.loanNumber?.slice(0, 12) || ''}`, COLONNE_1, hauteur, {
      maxWidth: sizeCol / 2,
    });
    newDoc.text(loan.loaner?.libelle || '', COLONNE_1 + sizeCol, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });
    newDoc.text(
      `${formatNumberByThousand(loan.balance || '0')} €`,
      COLONNE_1 + sizeCol * 2,
      hauteur,
      {
        align: 'center',
        maxWidth: sizeCol,
      },
    );
    newDoc.text(
      loan?.buyBack
        ? `${formatNumberByThousand(otherdata?.indemnityAmount?.toString() || '0')} € `
        : '',
      COLONNE_1 + sizeCol * 3,
      hauteur,
      {
        align: 'center',
        maxWidth: sizeCol,
      },
    );
    newDoc.text(
      `${formatNumberByThousand(loan.monthlyPayment || '0')} €`,
      COLONNE_1 + sizeCol * 4,
      hauteur,
      {
        align: 'center',
        maxWidth: sizeCol,
      },
    );
    newDoc.text(loan.creditType?.libelle || '', COLONNE_1 + sizeCol * 5, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });
    newDoc.text(loan.loanStartDate || '', COLONNE_1 + sizeCol * 6, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });
    newDoc.text(
      loan?.buyBack ? `${otherdata?.nominalInterestRate || 0}%` : '',
      COLONNE_1 + sizeCol * 7,
      hauteur,
      {
        align: 'center',
        maxWidth: sizeCol,
      },
    );
    newDoc.text(
      `${otherdata?.nbMonthsForNotice || 0} mois`,
      COLONNE_1 + sizeCol * 8,
      hauteur,
      {
        align: 'center',
        maxWidth: sizeCol,
      },
    );
    newDoc.text(loan?.buyBack ? 'oui' : 'non', COLONNE_1 + sizeCol * 9, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });
    newDoc.text(
      `${formatNumberByThousand(loan.initialLoanCapital || '0')} €`,
      COLONNE_1 + sizeCol * 10,
      hauteur,
      {
        align: 'center',
        maxWidth: sizeCol,
      },
    );
    if (loan?.buyBack) {
      newDoc.text(loan.restructure ? 'oui' : 'non', COLONNE_1 + sizeCol * 11, hauteur, {
        align: 'center',
        maxWidth: sizeCol,
      });
    }
    newDoc.text(
      `${formatNumberByThousand(loan.totalLoanDuration || '0')} mois`,
      COLONNE_1 + sizeCol * 12,
      hauteur,
      {
        align: 'center',
        maxWidth: sizeCol,
      },
    );
    if (loan?.buyBack) {
      newDoc.text(loan.mortgage ? 'oui' : 'non', COLONNE_1 + sizeCol * 13, hauteur, {
        align: 'center',
        maxWidth: sizeCol,
      });
    }
    newDoc.text(otherdata?.loanObject?.libelle || '', COLONNE_1 + sizeCol * 14, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });
  });

  return newDoc;
};

export const deptNeedPage = (doc: jsPDF): jsPDF => {
  const operationClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || '{}',
  ) as OperationState;

  const listDebt = JSON.parse(
    sessionStorage.getItem('debtAdditionalInformation') || '{}',
  ) as DebtsAdditionalInformationState;

  const { otherNeedsList, hasOtherNeeds } = operationClient;

  const newDoc = doc;

  const sizeCol = 50;
  let hauteur = 20;

  newDoc.addPage('a4', 'p');

  // Bloc autres besoins

  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.besoin.title, COLONNE_1, hauteur);

  hauteur += 15;
  if (hasOtherNeeds) {
    newDoc.setFontSize(POLICE_SIZE.data);
    newDoc.text(messages.CLIENT_FILE.besoin.type, COLONNE_1, hauteur);
    newDoc.text(messages.CLIENT_FILE.besoin.value, COLONNE_3, hauteur);

    newDoc.setFont('Helvetica', 'normal');

    otherNeedsList?.forEach(need => {
      hauteur += INTERLIGNE;

      if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 1.5) {
        hauteur = 20;
        newDoc.addPage();
      }

      newDoc.text(need.libelle, COLONNE_1, hauteur);
      newDoc.text(
        `${formatNumberByThousand(need.amount?.toString() || '0')} €`,
        COLONNE_3,
        hauteur,
      );
    });
  } else {
    newDoc.setFontSize(POLICE_SIZE.data);
    newDoc.setFont('Helvetica', 'italic');
    newDoc.text(messages.CLIENT_FILE.besoin.none, COLONNE_1, hauteur);
  }

  // Bloc Dettes
  hauteur += 20;

  if (hauteur > doc.internal.pageSize.height - 4 * INTERLIGNE) {
    hauteur = 20;
    newDoc.addPage();
  }

  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.dette.title, COLONNE_1, hauteur);

  hauteur += 15;
  if (listDebt?.debts) {
    newDoc.setFontSize(POLICE_SIZE.table);

    newDoc.text(messages.CLIENT_FILE.dette.nature, COLONNE_1, hauteur, {
      maxWidth: sizeCol,
    });
    newDoc.text(messages.CLIENT_FILE.dette.creancier, COLONNE_1 + sizeCol, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });
    newDoc.text(messages.CLIENT_FILE.dette.valeur, COLONNE_1 + sizeCol * 2, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });
    newDoc.text(messages.CLIENT_FILE.dette.date, COLONNE_1 + sizeCol * 3, hauteur, {
      align: 'center',
      maxWidth: sizeCol,
    });

    newDoc.setFont('Helvetica', 'normal');

    listDebt?.debts?.forEach(debt => {
      hauteur += INTERLIGNE * 1.5;

      if (hauteur > doc.internal.pageSize.height - INTERLIGNE * 1.5) {
        hauteur = 20;
        newDoc.addPage();
      }

      newDoc.text(debt.libelle, COLONNE_1, hauteur, {
        maxWidth: sizeCol / 1.5,
      });
      newDoc.text(debt.creditorName || '', COLONNE_1 + sizeCol, hauteur, {
        align: 'center',
        maxWidth: sizeCol,
      });
      newDoc.text(
        `${formatNumberByThousand(debt.amount?.toString() || '0')} €`,
        COLONNE_1 + sizeCol * 2,
        hauteur,
        {
          align: 'center',
          maxWidth: sizeCol,
        },
      );

      newDoc.text(debt.dueDate || '', COLONNE_1 + sizeCol * 3, hauteur, {
        align: 'center',
        maxWidth: sizeCol,
      });
      if (debt.banks && debt.banks.length > 0) {
        newDoc.setFont('Helvetica', 'bold');
        newDoc.text(
          messages.CLIENT_FILE.dette.banque,
          COLONNE_1 + sizeCol / 2,
          hauteur + INTERLIGNE,
          {
            align: 'right',
          },
        );
        newDoc.setFont('Helvetica', 'normal');

        debt?.banks?.forEach(bank => {
          hauteur += INTERLIGNE;

          if (hauteur > doc.internal.pageSize.height - INTERLIGNE) {
            hauteur = 20;
            newDoc.addPage();
          }

          newDoc.text(
            bank.libelle || ' banque non renseigné',
            COLONNE_1 + sizeCol * 1,
            hauteur,
            {
              align: 'center',
              maxWidth: sizeCol,
            },
          );
          newDoc.text(
            `${formatNumberByThousand(bank.amount?.toString() || '0')} €`,
            COLONNE_1 + sizeCol * 2,
            hauteur,
            {
              align: 'center',
              maxWidth: sizeCol,
            },
          );
        });
      }
    });
  } else {
    newDoc.setFontSize(POLICE_SIZE.data);
    newDoc.setFont('Helvetica', 'italic');
    newDoc.text(messages.CLIENT_FILE.dette.none, COLONNE_1, hauteur);
  }

  return newDoc;
};

export const comportementSynthesePage = (doc: jsPDF): jsPDF => {
  const operationClient = JSON.parse(
    sessionStorage.getItem('operationsClient') || '{}',
  ) as OperationState;

  doc.addPage();

  const newDoc = doc;

  const decalleCol = 10;
  let hauteur = 20;

  // Bloc Comportement financier
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.comportement.title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);

  newDoc.text(messages.CLIENT_FILE.comportement.incident, COLONNE_1, hauteur);
  newDoc.setFont('Helvetica', 'normal');
  newDoc.text(operationClient.hasPaymentIncident ? 'oui' : 'non', COLONNE_3, hauteur);

  if (operationClient.hasPaymentIncident) {
    operationClient.incidentPaymentList?.forEach((incident, index) => {
      hauteur += index % 2 === 0 ? INTERLIGNE : 0;
      newDoc.text(
        incident.libelle,
        index % 2 === 0 ? COLONNE_1 + 10 : COLONNE_3,
        hauteur,
      );
    });
  }

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);

  newDoc.text(messages.CLIENT_FILE.comportement.impayer, COLONNE_1, hauteur);
  newDoc.setFont('Helvetica', 'normal');
  newDoc.text(operationClient.hasPaymentDefault ? 'oui' : 'non', COLONNE_3, hauteur);
  if (operationClient.hasPaymentDefault) {
    hauteur += INTERLIGNE;
    newDoc.text(
      messages.CLIENT_FILE.comportement.nombre_impayer,
      COLONNE_1 + 10,
      hauteur,
    );
    newDoc.text(operationClient.nbPaymentDefault.toString(), COLONNE_3, hauteur);
  }

  // Bloc Synthese du Besoin
  hauteur += 20;
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text(messages.CLIENT_FILE.synthèse.title, COLONNE_1, hauteur);

  hauteur += 15;
  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.text(
    messages.CLIENT_FILE.synthèse.mensualites,
    COLONNE_3 - decalleCol,
    hauteur,
    {
      align: 'center',
    },
  );
  newDoc.text(messages.CLIENT_FILE.synthèse.encours, COLONNE_4, hauteur, {
    align: 'center',
  });

  hauteur += INTERLIGNE;
  newDoc.setFont('Helvetica', 'normal');
  newDoc.text(messages.CLIENT_FILE.synthèse.pret, COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(
      (
        operationClient.keepLoansMonthlyPaymentTotal +
        (operationClient.buybackLoansMonthlyPaymentTotal || 0)
      ).toString() || '0',
    )} €`,
    COLONNE_3 - decalleCol,
    hauteur,
    {
      align: 'center',
    },
  );
  newDoc.text(
    `${formatNumberByThousand(
      (
        (operationClient.buybackLoansTotal || 0) + (operationClient.keepLoansTotal || 0)
      ).toString() || '0',
    )} €`,
    COLONNE_4,
    hauteur,
    {
      align: 'center',
    },
  );

  hauteur += 15;
  newDoc.text(messages.CLIENT_FILE.synthèse.dettes, COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(operationClient.totalDebtValue.toString() || '0')} €`,
    COLONNE_4,
    hauteur,
    {
      align: 'center',
    },
  );

  hauteur += 15;
  newDoc.text(messages.CLIENT_FILE.synthèse.besoin, COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(
      operationClient.totalOtherNeedsAmount.toString() || '0',
    )} €`,
    COLONNE_4,
    hauteur,
    {
      align: 'center',
    },
  );

  hauteur += 15;
  newDoc.setFont('Helvetica', 'bold');
  newDoc.text(
    messages.CLIENT_FILE.synthèse.total.mensualite_rachetter,
    COLONNE_3 - decalleCol,
    hauteur,
    {
      align: 'center',
    },
  );
  newDoc.text(messages.CLIENT_FILE.synthèse.total.besoin, COLONNE_4, hauteur, {
    align: 'center',
  });

  hauteur += 15;
  newDoc.setFont('Helvetica', 'normal');
  newDoc.text(messages.CLIENT_FILE.synthèse.buyback, COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(
      (operationClient.buybackLoansMonthlyPaymentTotal || 0).toString() || '0',
    )} €`,
    COLONNE_3 - decalleCol,
    hauteur,
    {
      align: 'center',
    },
  );
  newDoc.text(
    `${formatNumberByThousand(
      (
        (operationClient.buybackLoansTotal || 0) +
        operationClient.totalOtherNeedsAmount +
        operationClient.totalDebtValue
      ).toString() || '0',
    )} €`,
    COLONNE_4,
    hauteur,
    {
      align: 'center',
    },
  );

  hauteur += 15;
  newDoc.setFont('Helvetica', 'bold');
  newDoc.text(
    messages.CLIENT_FILE.synthèse.total.mensualite_non_rachetter,
    COLONNE_3 - decalleCol,
    hauteur,
    {
      align: 'center',
    },
  );
  newDoc.text(messages.CLIENT_FILE.synthèse.total.encours, COLONNE_4, hauteur, {
    align: 'center',
  });

  hauteur += 15;
  newDoc.setFont('Helvetica', 'normal');
  newDoc.text(messages.CLIENT_FILE.synthèse.keep, COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(
      operationClient.keepLoansMonthlyPaymentTotal.toString() || '0',
    )} €`,
    COLONNE_3 - decalleCol,
    hauteur,
    {
      align: 'center',
    },
  );
  newDoc.text(
    `${formatNumberByThousand(
      (operationClient.keepLoansTotal || 0).toString() || '0',
    )} €`,
    COLONNE_4,
    hauteur,
    {
      align: 'center',
    },
  );
  return newDoc;
};

export const garantieIbanPage = (doc: jsPDF): jsPDF => {
  // ===== GET DATA =====

  const operation = JSON.parse(
    sessionStorage.getItem('operationsClient') || '{}',
  ) as OperationState;

  const iban = JSON.parse(sessionStorage.getItem('iban') || '{}') as IbanState;

  // ===== PROCESS =====

  const writeAssetLine = (deltaHauteur: number, AssetIndex: number, asset: Asset) => {
    const remainingMortgageValue =
      Number(asset.currentValue) - Number(asset.keptMortagagesValue || '0');

    const currentValue = formatNumberByThousand(asset.currentValue || '');
    const keptMortagagesValue = formatNumberByThousand(asset.keptMortagagesValue || '');
    const usage = (asset.usage?.libelle || '').toLocaleLowerCase();

    doc.text(`Bien ${AssetIndex + 1}`, COLONNE_1, deltaHauteur);
    doc.text(asset.type?.libelle || '', COLONNE_2 - 10, deltaHauteur);
    doc.text(usage || '', COLONNE_3_BIS, deltaHauteur);
    doc.text(`${currentValue || ''} €`, COLONNE_4_BIS, deltaHauteur);
    doc.text(`${keptMortagagesValue || '0'} €`, COLONNE_5 + 20, deltaHauteur);

    doc.text(
      `${formatNumberByThousand(remainingMortgageValue.toString())} €`,
      COLONNE_LAST + 30,
      deltaHauteur,
    );
  };

  // ===== WRITE =====

  const newDoc = doc;
  newDoc.addPage('a4', 'l');

  let hauteur = 35;

  // Bloc Garantie
  newDoc.setFontSize(POLICE_SIZE.title);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text('BIENS PRIS EN GARANTIE', COLONNE_1, hauteur);

  hauteur += 20;

  if (operation?.assetList.length > 0) {
    newDoc.setFontSize(POLICE_SIZE.data);
    newDoc.setFont('Helvetica', 'bold');

    newDoc.text('Bien', COLONNE_1, hauteur);
    newDoc.text('Nature du bien', COLONNE_2 - 10, hauteur);
    newDoc.text('Usage du bien', COLONNE_3_BIS, hauteur);
    newDoc.text('Valeur du bien', COLONNE_4_BIS, hauteur);
    newDoc.text('Valeur des hypothèques conservées', COLONNE_5 + 20, hauteur, {
      maxWidth: COLONNE_LAST - COLONNE_5 + 10,
    });
    newDoc.text("Valeur libre d'hypothèque retenue", COLONNE_LAST + 30, hauteur, {
      maxWidth: 60,
    });

    hauteur += INTERLIGNE;

    newDoc.setFont('Helvetica', 'normal');

    operation.assetList?.forEach((asset, assetIndex) => {
      writeAssetLine(hauteur, assetIndex, asset);
      if (assetIndex < operation.assetList.length - 1) {
        hauteur += INTERLIGNE;
      }
    });
  } else {
    newDoc.setFontSize(POLICE_SIZE.data);
    newDoc.setFont('Helvetica', 'italic');
    newDoc.text('Aucune garantie renseignée', COLONNE_1, hauteur);
  }

  // Bloc Iban
  if (iban.bic && iban.ibanPart1) {
    hauteur += 20;

    newDoc.setFontSize(POLICE_SIZE.title);
    newDoc.setFont('Helvetica', 'bold');

    newDoc.text('MISE EN PLACE', COLONNE_1, hauteur);

    newDoc.setFont('Helvetica', 'normal');

    hauteur += 20;

    newDoc.setFontSize(POLICE_SIZE.data);
    newDoc.setFont('Helvetica', 'normal');

    hauteur += 15;

    newDoc.text('BIC', COLONNE_1, hauteur);
    newDoc.text(`${iban.bic}`, COLONNE_3, hauteur);

    hauteur += 15;

    newDoc.text('IBAN', COLONNE_1, hauteur);

    newDoc.text(
      `${iban.ibanPart1} ${iban.ibanPart2} ${iban.ibanPart3} ${iban.ibanPart4} ${iban.ibanPart5} ${iban.ibanPart6} ${iban.ibanPart7}`,
      COLONNE_3,
      hauteur,
    );
  }

  return doc;
};

export const recapPage = (doc: jsPDF): jsPDF => {
  // ===== GET DATA =====

  const operation = JSON.parse(
    sessionStorage.getItem('operationsClient') || '{}',
  ) as OperationState;

  const simulationFromSession = JSON.parse(
    sessionStorage.getItem('simulationResult') || '{}',
  ) as PropositionOutDtoResp;

  const proposition = simulationFromSession?.Resultat.Proposition;

  // ===== PROCESS =====

  let totalNeeds = 0;
  if (operation?.totalOtherNeedsAmount) {
    totalNeeds += operation.totalOtherNeedsAmount;
  }
  if (operation?.totalDebtValue) {
    totalNeeds += operation.totalDebtValue;
  }
  if (operation?.buybackLoansTotal) {
    totalNeeds += operation.buybackLoansTotal;
  }

  // ===== WRITE =====

  const newDoc = doc;
  newDoc.addPage('a4', 'p');

  let hauteur = 35;

  newDoc.setFontSize(POLICE_SIZE.data);
  newDoc.setFont('Helvetica', 'bold');

  newDoc.text('Prêt immo', COLONNE_3, hauteur);

  hauteur += INTERLIGNE;

  newDoc.setFont('Helvetica', 'normal');

  newDoc.text('Besoin client', COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(totalNeeds.toString() || '0')} €`,
    COLONNE_3,
    hauteur,
  );

  hauteur += INTERLIGNE;

  newDoc.text('Frais de dossier', COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(proposition.fraisComOuverture?.toString() || '0')} €`,
    COLONNE_3,
    hauteur,
  );

  hauteur += INTERLIGNE;

  newDoc.text("Frais d'intermediation", COLONNE_1, hauteur);
  newDoc.text(`${proposition.pourcentageFraisIntermedation} %`, COLONNE_3 - 20, hauteur, {
    align: 'right',
  });
  newDoc.text(
    `${formatNumberByThousand(proposition.fraisIntermedation?.toString() || '0')} €`,
    COLONNE_3,
    hauteur,
  );

  hauteur += INTERLIGNE;

  newDoc.text('Frais de garantie', COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(proposition.fraisActe?.toString() || '0')} €`,
    COLONNE_3,
    hauteur,
  );

  hauteur += INTERLIGNE;

  newDoc.setFont('Helvetica', 'bold');

  newDoc.text('Montant total', COLONNE_1, hauteur);
  newDoc.text(
    `${formatNumberByThousand(proposition.montantTotalPret?.toString() || '0')} €`,
    COLONNE_3,
    hauteur,
  );

  hauteur += 15;

  return doc;
};
