import CustomInput from 'components/customInput/CustomInput';
import { ComboInput, ComboInputDS } from 'components/designSystem/ComboInput';
import Space from 'components/space/Space';
import { useEffect, useState } from 'react';
import {
  DebtsAdditionalInformationAction,
  DebtsAdditionalInformationState,
  DebtsCompleteData,
} from 'reducers/debtsAdditionalInformation/type';
import { CodeLibelle, StatusInput } from 'types';
import { formatNumberByThousand } from 'utils/commun';
import { checkFormatDate, getDateFromInput, isDateIntheFuture } from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import { DateInput } from 'components/designSystem/DateInput';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface FamilyDebtProps {
  debt: DebtsCompleteData;
  state: DebtsAdditionalInformationState;
  dispatch: React.Dispatch<DebtsAdditionalInformationAction>;
  checkInputs: boolean;
}

const FamilyDebt: React.FC<FamilyDebtProps> = ({
  debt,
  state,
  dispatch,
  checkInputs,
}) => {
  const [civility, setCivility] = useState<CodeLibelle | undefined>(debt.civility);

  const [statusCivility, setStatusCivility] = useState<StatusInput>();
  const [isCreditorInputFocused, setIsCreditorInputFocused] = useState<boolean>(false);
  const [isCreditorInputValid, setIsCreditorInputValid] = useState<boolean | undefined>();
  const [creditorInputError, setCreditorInputError] = useState<string>('');

  const [reloadStatusDate, setReloadStatusDate] = useState<boolean>(false);
  const [statusDate, setStatusDate] = useState<StatusInput>();

  const notEmpty = new RegExp(ValidationRules.notEmpty);
  const noNumbers = new RegExp(ValidationRules.noNumbers);
  const noSpecialCharacters = new RegExp(ValidationRules.noSpecialCharacters);

  const updateValue = (value: string) => {
    const result = state.debts.map(item => {
      if (item.code === debt.code) {
        return { ...item, dueDate: value };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data: result, debtCode: debt.code },
    });
  };

  const checkIsDateValid = (): void => {
    if (reloadStatusDate === false) {
      return;
    }
    setReloadStatusDate(false);

    const value = getDateFromInput(debt.dueDate || '');

    if (debt.dueDate === undefined || debt.dueDate === '') {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (!checkFormatDate(debt.dueDate)) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_INVALID,
      });
    } else if (value.getFullYear() >= 2100) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_OVER_2100,
      });
    } else if (isDateIntheFuture(value)) {
      setStatusDate({
        status: 'invalid',
        errorMessage: messagesCheckInput.DATE_IN_THE_PAST,
      });
    } else if (!isDateIntheFuture(getDateFromInput(debt.dueDate))) {
      setStatusDate({
        status: 'valid',
        errorMessage: '',
      });
    } else {
      setStatusDate({
        status: 'valid',
        errorMessage: '',
      });
    }
  };

  const onCivilitySelect = (e: Event) => {
    const selectedType = (e as CustomEvent).detail.value as CodeLibelle;
    setCivility(selectedType);
  };

  const onCivilityBlur = (value: string) => {
    let data = [] as DebtsCompleteData[];
    if (value === '') {
      setCivility(undefined);
      setStatusCivility({
        status: 'invalid',
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
      data = state.debts.map(item => {
        if (item.code === debt.code) {
          return { ...item, civility: undefined };
        }
        return item;
      });
    } else {
      setStatusCivility({
        status: 'valid',
        errorMessage: '',
      });
      data = state.debts.map(item => {
        if (item.code === debt.code) {
          return { ...item, civility };
        }
        return item;
      });
    }
    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data, debtCode: debt.code },
    });
  };

  const handleCreditorOnChange = (value: string) => {
    setIsCreditorInputValid(undefined);

    const result = state.debts.map(item => {
      if (item.code === debt.code) {
        return { ...item, creditorName: value.slice(0, 25) };
      }
      return item;
    });

    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data: result, debtCode: debt.code },
    });
  };

  const handleCreditorOnBlur = (value: string) => {
    setIsCreditorInputFocused(false);
    const valWithoutSpace = value.replace(/ /g, '');
    if (!valWithoutSpace || !notEmpty.test(valWithoutSpace)) {
      setIsCreditorInputValid(false);
      setCreditorInputError(messagesCheckInput.REQUIRED_VALUE);
    } else if (!noNumbers.test(value)) {
      setIsCreditorInputValid(false);
      setCreditorInputError(messagesCheckInput.NO_NUMBER);
    } else if (!noSpecialCharacters.test(value)) {
      setIsCreditorInputValid(false);
      setCreditorInputError(messagesCheckInput.NO_SPECIAL_CHARACTER);
    } else {
      setIsCreditorInputValid(true);
      setCreditorInputError('');
    }

    const data = state.debts.map(item => {
      if (item.code === debt?.code) {
        return { ...item, value };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data, debtCode: debt.code },
    });
  };

  useEffect(() => {
    if (checkInputs) {
      setReloadStatusDate(true);
      onCivilityBlur(debt.civility?.libelle || '');
      handleCreditorOnBlur(debt.creditorName || '');
    }
  }, [checkInputs]);

  useEffect(() => {
    if (debt.dueDate) setReloadStatusDate(true);
    if (debt.civility?.libelle) onCivilityBlur(debt.civility?.libelle || '');
    if (debt.creditorName) handleCreditorOnBlur(debt.creditorName || '');
  }, []);

  useEffect(() => {
    checkIsDateValid();
  }, [reloadStatusDate]);

  const onDateBlur = () => {
    setStatusDate({
      status: 'none',
      errorMessage: '',
    });
    setReloadStatusDate(true);
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div>
          <CustomInput
            name="familyDebt"
            label={debt.libelle}
            type="price"
            isDisabled
            value={formatNumberByThousand(debt.amount.toString() || '')}
            after="€"
            inputWidth="50.4rem"
            isFocused={false}
          />
        </div>
        <div>
          <DateInput
            class="show-input-ds-validation"
            label="Date d'exigibilité"
            onBlur={onDateBlur}
            onInputChange={e => {
              updateValue((e.target as HTMLInputElement).value);
            }}
            value={debt.dueDate}
            {...(statusDate?.status !== 'none' ? { status: statusDate?.status } : {})}
            {...(statusDate?.errorMessage !== ''
              ? { 'error-message': statusDate?.errorMessage }
              : {})}
          />
        </div>
      </div>
      <Space marginBottom="1.6rem" />
      <div style={{ display: 'flex', gap: '1.6rem' }}>
        <div style={{ width: '15rem', textAlign: 'left' }}>
          <ComboInput
            id="familyDebt-dropDown"
            list-on-open
            required
            class="additionalDebtCivility"
            shadow
            align-items="left"
            items={JSON.stringify([
              { code: 'Mr', libelle: 'Mr' },
              { code: 'Mme', libelle: 'Mme' },
            ])}
            field="libelle"
            label="Civilité"
            placeholder="ex: M"
            onSelectedList={e => {
              onCivilitySelect(e);
            }}
            onListBlur={e => {
              onCivilityBlur((e.target as unknown as ComboInputDS).value as string);
            }}
            value={debt.civility?.libelle || ''}
            {...(statusCivility?.status !== 'none'
              ? { status: statusCivility?.status }
              : {})}
            {...(statusCivility?.errorMessage !== ''
              ? { 'error-message': statusCivility?.errorMessage }
              : {})}
          />
        </div>
        <CustomInput
          name="creditor"
          label="Nom du créancier"
          type="text"
          placeholder="Nom du créancier"
          value={debt.creditorName}
          onChange={newValue => {
            handleCreditorOnChange(newValue);
            setIsCreditorInputFocused(true);
          }}
          isValid={isCreditorInputValid}
          isFocused={isCreditorInputFocused}
          onBlur={value => {
            handleCreditorOnBlur(value);
          }}
          onFocus={() => {
            setIsCreditorInputFocused(true);
          }}
          inputWidth="56.5rem"
          error={creditorInputError}
        />
      </div>
    </>
  );
};

export default FamilyDebt;
