import BaseButton from 'components/designSystem/BaseButton';
import styled from 'styled-components';

const StyledNextButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.chartColors.error500};
  border-color: ${({ theme }) => theme.colors.chartColors.error500};
  height: 4.8rem;
  width: auto;
`;

export default StyledNextButton;
