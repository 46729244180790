const BookIcon: React.FC = () => {
  return (
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>53B4EC48-A341-484B-A148-1B76E0341055</title>
      <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dashboard5.1"
          transform="translate(-26.000000, -700.000000)"
          fill="#4B4F54">
          <g id="Menu-Copy-5" transform="translate(-446.000000, 0.000000)">
            <g id="Ma-comm-Sygma" transform="translate(470.000000, 651.000000)">
              <g id="Actualites-et-infos" transform="translate(2.000000, 48.000000)">
                <g id="🎨-Color" transform="translate(0.000000, 1.000000)">
                  <path d="M0.952380952,0.958668209 L0.952380952,14.202492 L7.14285714,14.202492 C8.07514931,14.202492 8.91859597,14.5838799 9.52380952,15.1989086 L9.52380952,4.2666653 C9.52380952,2.44105101 8.03274041,0.958668209 6.19047619,0.958668209 L0.952380952,0.958668209 Z M6.19047619,0 C7.84852569,0 9.28770978,0.937757213 10.0003098,2.31054832 C10.7122902,0.937757213 12.1514743,0 13.8095238,0 L19.5238095,0 C19.7868023,0 20,0.214605189 20,0.479334105 L20,14.6818261 C20,14.946555 19.7868023,15.1611602 19.5238095,15.1611602 L12.8571429,15.1611602 C11.5957091,15.1611602 10.5653501,16.1332632 10.4816768,17.3608188 L10.4761905,17.5223245 C10.4761905,17.8063743 10.2880658,17.9641798 10.0790385,17.9957409 L10,18.0016586 C9.76190476,18.0016586 9.52380952,17.8418806 9.52380952,17.5223245 C9.52380952,16.2196315 8.45913589,15.1611602 7.14285714,15.1611602 L0.476190476,15.1611602 C0.213197738,15.1611602 0,14.946555 0,14.6818261 L0,0.479334105 C0,0.214605189 0.213197738,0 0.476190476,0 L6.19047619,0 Z M19.047619,0.958668209 L13.8095238,0.958668209 C11.9672596,0.958668209 10.4761905,2.44105101 10.4761905,4.2666653 L10.4761905,15.1989086 C11.081404,14.5838799 11.9248507,14.202492 12.8571429,14.202492 L19.047619,14.202492 L19.047619,0.958668209 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BookIcon;
