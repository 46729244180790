import GenericIconProps from './GenericIcon';

const PaperPlaneIcon: React.FC<GenericIconProps> = ({ color, width }) => {
  const baseWidth = 20;
  const baseHeight = 20;
  const baseColor = 'none';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>CE2E1DC6-C7AE-4C18-B8ED-2E544C1F6E53</title>
      <g
        id="Consultation-dossier-📑"
        stroke="none"
        strokeWidth="1"
        fill={color || baseColor}
        fillRule="evenodd">
        <g
          id="Consultation-d'un-dossier/transferer-a-l'octroi/PJ/AG/2.2"
          transform="translate(-922.000000, -5812.000000)"
          fill="#FFFFFF">
          <g
            id="Button/Primary/Green/M/Enabled"
            transform="translate(904.000000, 5798.000000)">
            <g id="🎨-Color" transform="translate(18.000000, 14.000000)">
              <path
                d="M19.524705,-0.000136976156 C19.5548638,-0.000178527238 19.5844482,0.00272196902 19.6131824,0.00828883425 C19.6446348,0.0147780871 19.6693309,0.0221313342 19.6931503,0.0313747993 C19.6998672,0.0346780314 19.7072756,0.0377102715 19.7146192,0.0409369705 C19.7367157,0.0502119856 19.7573641,0.061369022 19.7770646,0.0739318161 C19.7808192,0.0765320665 19.7844623,0.078907219 19.7880794,0.0813373536 C19.8574979,0.128073467 19.9145349,0.193049413 19.9518549,0.268851463 C19.9559123,0.278283264 19.9599097,0.287245979 19.9636165,0.296299736 C19.9715107,0.313556816 19.9779311,0.332054272 19.983182,0.350994492 C19.9833214,0.355082608 19.9843574,0.359081936 19.9853406,0.363091556 C19.9913988,0.383361542 19.9951054,0.404136583 19.997372,0.425287686 C19.997155,0.433274374 19.9978233,0.441323796 19.9982879,0.449382775 C20.0002463,0.466249198 20.0002418,0.483640795 19.9992342,0.501196952 C19.9979516,0.511256354 19.997154,0.520983355 19.9960589,0.530683136 C19.992505,0.564722972 19.9845389,0.599427063 19.9723488,0.634255984 L19.9876651,0.580875018 C19.9851962,0.591965223 19.982327,0.602984247 19.9790573,0.613909003 L19.9723488,0.634255984 L13.3059931,19.6811176 C13.1635265,20.0881676 12.5965804,20.1112976 12.4214296,19.7172057 L8.68627596,11.3127121 L0.282792394,7.57848512 C-0.0831476262,7.41584399 -0.0893425513,6.91537697 0.237544275,6.73064403 L0.318880254,6.69391558 L19.3656111,0.0275140277 C19.3810771,0.0221008892 19.3965184,0.0175206762 19.411902,0.0137401903 C19.419658,0.0119757095 19.4277775,0.0102550933 19.4359287,0.00874822562 C19.4628571,0.00327518301 19.4895532,0.000498245003 19.5158365,-4.0030961e-05 Z M18.383,2.289 L9.615,11.057 L12.8032268,18.2333892 L18.383,2.289 Z M17.712,1.614 L1.76564632,7.19573293 L8.942,10.384 L17.712,1.614 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PaperPlaneIcon;
