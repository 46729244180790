import styled from 'styled-components';

export const StyledDragAndDropZoneConsultationWrapper = styled.div<{ hasError: boolean }>`
  box-sizing: border-box;
  height: 104px;
  display: flex;
  border: ${({ hasError }) => `1px dashed  ${hasError ? '#C8102E' : '#008859'}`};
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #f2f9f1;
`;

export const StyledDragAndDropZoneConsultationText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-right: 5px;
`;
