import GenericIconProps from './GenericIcon';

const AccessWebsiteIcon: React.FC<GenericIconProps> = ({ width, color }) => {
  const baseWidth = 23;
  const baseHeight = 23;
  const baseColor = '#4B4F54';

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      fill={color || baseColor}
      viewBox="0 0 23 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>0C54CEEA-3881-4929-87FB-F4FE8797A772</title>
      <g
        id="Page-de-connexion"
        stroke="none"
        strokeWidth="1"
        fill={color}
        fillRule="evenodd">
        <g
          id="Page-d'accueil-V2"
          transform="translate(-204.000000, -855.000000)"
          fill="#268038">
          <g id="particulier" transform="translate(88.000000, 730.000000)">
            <g
              id="Button/Secondary/Outlined/White/L/Enabled"
              transform="translate(88.000000, 108.000000)">
              <g id="Group" transform="translate(28.333333, 15.000000)">
                <g
                  id="Ic/36px/Action/plus-circle-Copy-11"
                  transform="translate(0.000000, 1.997554)">
                  <path
                    d="M9.63662595,1.33577969 C10.2077141,1.33577969 10.6706726,1.73236631 10.6706726,2.22158069 C10.6706726,2.71079508 10.2077141,3.10738169 9.63662595,3.10738169 L2.67067257,3.10738169 L2.6169728,3.10936519 C2.16558508,3.1643192 1.81521258,3.56526189 1.81521258,4.02067091 L1.81521258,20.0050499 C1.81521258,20.4954904 2.22156175,20.893071 2.72281886,20.893071 L18.6261557,20.893071 C19.1274128,20.893071 19.533762,20.4954904 19.533762,20.0050499 L19.533762,12.9008814 C19.533762,12.410441 19.9401112,12.0128604 20.4413683,12.0128604 C20.9426254,12.0128604 21.3489746,12.410441 21.3489746,12.9008814 L21.3489746,20.0050499 C21.3489746,21.4763714 20.1299271,22.669113 18.6261557,22.669113 L2.72281886,22.669113 C1.21904753,22.669113 -7.10542736e-15,21.4763714 -7.10542736e-15,20.0050499 L-3.55271368e-15,4.02067091 C-3.55271368e-15,2.54934945 1.21904753,1.35660775 2.72281886,1.35660775 L9.63662595,1.33577969 Z M14.2097936,1.18343378e-05 L21.7692052,0.0377597163 C21.9958524,0.0386534035 22.213828,0.129569578 22.3748689,0.290610497 C22.5359099,0.451651416 22.6270107,0.6693494 22.6279049,0.895830588 L22.6656817,8.46750205 C22.6681396,8.93894531 22.2876716,9.31913445 21.8158827,9.31667826 C21.3440938,9.31422207 20.9596408,8.93005065 20.9571829,8.45860739 L20.9313426,2.94504304 L10.3772706,13.4913798 C10.0458221,13.8225854 9.50550099,13.8198957 9.17070359,13.4850983 C8.83590619,13.1503009 8.83296933,12.6106209 9.16441781,12.2794153 L19.7184898,1.73307853 L14.2186943,1.70725892 C13.7469054,1.70480273 13.3624524,1.32063131 13.3599946,0.849188047 C13.3575367,0.377744782 13.7380047,-0.00244435806 14.2097936,1.18343378e-05 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

AccessWebsiteIcon.defaultProps = {
  color: '#4B4F54',
};

export default AccessWebsiteIcon;
