import NextButton from 'components/buttons/nextButton/NextButton';
import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import Modal from 'components/modals/Modal';
import Space from 'components/space/Space';
import {
  StyledFailedModalButtonsContainer,
  StyledFailedModalErrorTitle,
  StyledFailedModalIconContainer,
  StyledFailedModalText,
  StyledFailedModalTextContainer,
} from 'containers/transmissionPJ/style';
import CrossCircle from 'icons/CrossCircle';
import ESignatureIcon from 'icons/ESignatureIcon';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import * as messages from '../../../messages';

interface FailedEmissionPackModalProps {
  isVisible: boolean;
  errorMessages?: ErrorMessage[];
  close: () => void;
  isRetryEnabled: boolean;
  continueWithoutPackEmission: () => void;
}

const FailedEmissionPackModal: React.FC<FailedEmissionPackModalProps> = ({
  isVisible,
  errorMessages,
  close,
  isRetryEnabled,
  continueWithoutPackEmission,
}) => {
  const renderRetryButton = () => {
    return (
      <>
        {isRetryEnabled ? (
          <StyledSecondaryButton onClick={() => close()}>
            {messages.FAILED_EMISSION_MODAL_CLOSE_BUTTON}
          </StyledSecondaryButton>
        ) : (
          <StyledSecondaryButton style={{ cursor: 'not-allowed' }}>
            {messages.FAILED_EMISSION_MODAL_CLOSE_BUTTON}
          </StyledSecondaryButton>
        )}
      </>
    );
  };

  return (
    <>
      <Modal isVisible={isVisible} height="38.4rem" width="60rem">
        <div style={{ margin: '5.6rem 7.2rem 4rem 7.2rem' }}>
          <StyledFailedModalIconContainer>
            <ESignatureIcon color="#4A4A4A" />
            <div style={{ position: 'absolute', top: '4rem', right: '26.5rem' }}>
              <CrossCircle color="#D52726" />
            </div>
          </StyledFailedModalIconContainer>

          <Space marginBottom="4.4rem" />

          <StyledFailedModalTextContainer>
            <StyledFailedModalErrorTitle>
              {messages.FAILED_EMISSION_MODAL_TITLE}
            </StyledFailedModalErrorTitle>
            <StyledFailedModalText>
              {errorMessages?.at(0)?.MessageLib}
            </StyledFailedModalText>
          </StyledFailedModalTextContainer>

          <Space marginBottom="4.8rem" />

          <StyledFailedModalButtonsContainer>
            {renderRetryButton()}
            <NextButton
              isDisabled={false}
              handleonClick={continueWithoutPackEmission}
              label={messages.FAILED_EMISSION_MODAL_CONTINUE_BUTTON}
            />
          </StyledFailedModalButtonsContainer>
        </div>
      </Modal>
    </>
  );
};

FailedEmissionPackModal.defaultProps = {
  isVisible: false,
  errorMessages: [{ MessageLib: 'Une erreur technique est survenue.' } as ErrorMessage],
};

export default FailedEmissionPackModal;
