import { getListeDossier } from 'api/clientFileService';
import { getReferential } from 'api/referentialService';
import BaseButton from 'components/designSystem/BaseButton';
import FilterTag from 'components/filterTag/FilterTag';
import SearchDateInput from 'components/searchInput/SearchDateInput';
import SearchInput from 'components/searchInput/SearchInput';
import SearchSelectInput from 'components/searchInput/SearchSelectnput';
import Space from 'components/space/Space';
import ToggleButton from 'components/toggleButton/ToggleButton';
import FolderSearchIcon from 'icons/FolderSearchIcon';
import HomeIcon from 'icons/HomeIcon';
import HourglassIcon from 'icons/HourglassIcon';
import LookingGlassIcon from 'icons/LookingGlassIcon';
import RefreshIcon from 'icons/RefreshIcon';
import { useEffect, useState } from 'react';
import theme from 'styles/theme';
import { CodeLibelle } from 'types';
import { clientFilesResult } from 'types/clientFileDtos';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import InformationArea from 'components/informationArea/InformationArea';
import ErrorIcon from 'icons/ErrorIcon';
import * as messages from '../messages';
import {
  StyledButtonLabel,
  StyledExtraFiltersAndTagsContainer,
  StyledFiltersRow,
  StyledFlexRow,
  StyledRefreshButtonText,
  StyledRefreshFiltersButton,
  StyledSecondaryTitle,
  StyledStandardText,
  StyledTagsContainer,
} from '../style';

interface SearchBarProps {
  setResult: (result: clientFilesResult[]) => void;
  fileStatusItems: CodeLibelle[];
  setIsLoading: (isLoading: boolean) => void;
  setSearchFlag: (flag: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  setResult,
  fileStatusItems,
  setIsLoading,
  setSearchFlag,
}) => {
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(false);
  const [status, setStatus] = useState<CodeLibelle>();
  const [garanteeNature, setGaranteeNature] = useState<CodeLibelle>();
  const [treatmentType, setTreatmentType] = useState<CodeLibelle>();
  const [treatmentTypeItems, setTreatmentTypeItems] = useState<CodeLibelle[]>([]);

  const [lastName, setLastName] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [fileNumber, setFileNumber] = useState<string>();
  const [dateFrom, setDateFrom] = useState<string>();
  const [dateTo, setDateTo] = useState<string>();
  const [errorMissingCriteria, setErrorMissingCriteria] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>('');

  const garanteeNatureItems = [
    { code: 'O', libelle: 'Avec Garantie' },
    { code: 'N', libelle: 'Sans Garantie' },
  ] as CodeLibelle[];

  useEffect(() => {
    getReferential('niveauProcess').then(result => {
      if (result && result.Success) setTreatmentTypeItems(result.Resultat);
    });
  }, []);

  const clearResults = () => {
    setResult([]);
    setSearchFlag(false);
    setErrorMissingCriteria(false);
    setHasError('');
    sessionStorage.removeItem('listeDossier');
  };

  const hasTags = (): boolean => {
    return (
      !!lastName ||
      !!firstName ||
      !!fileNumber ||
      !!status ||
      !!dateFrom ||
      !!dateTo ||
      !!garanteeNature ||
      !!treatmentType
    );
  };

  const search = () => {
    setHasError('');
    if (hasTags()) {
      sessionStorage.removeItem('listeDossier');
      setIsLoading(true);
      getListeDossier(
        lastName?.trim(),
        firstName?.trim(),
        fileNumber?.trim(),
        status,
        dateFrom,
        dateTo,
        garanteeNature,
        treatmentType,
      )
        .then(result => {
          setIsLoading(false);
          if (result && result.Success) {
            setResult(result.Resultat.Dossiers);
            setSearchFlag(true);
          }
          if (result && result.Success === false) {
            let errorMsg =
              "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.";
            if (result.ListeMessages && result.ListeMessages.length > 0) {
              errorMsg = result.ListeMessages[0].MessageLib;
              setHasError(errorMsg);
            }
          }
        })
        .catch(error => {
          setHasError(
            "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
          );
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrorMissingCriteria(true);
    }
  };

  const resetAdditionalFilters = () => {
    setDateFrom('');
    setDateTo('');
    setGaranteeNature(undefined);
    setTreatmentType(undefined);
  };

  const resetAllFilters = () => {
    setLastName('');
    setFirstName('');
    setFileNumber('');
    setStatus(undefined);
    resetAdditionalFilters();
  };

  const renderTags = () => {
    return (
      <>
        {firstName && (
          <FilterTag
            label={firstName}
            onDelete={() => {
              setFirstName('');
              clearResults();
            }}
          />
        )}
        {lastName && (
          <FilterTag
            label={lastName}
            onDelete={() => {
              setLastName('');
              clearResults();
            }}
          />
        )}
        {fileNumber && (
          <FilterTag
            label={fileNumber}
            onDelete={() => {
              setFileNumber('');
              clearResults();
            }}
          />
        )}
        {status?.libelle && (
          <FilterTag
            label={status.libelle}
            onDelete={() => {
              setStatus(undefined);
              clearResults();
            }}
          />
        )}
        {dateFrom && (
          <FilterTag
            label={`A partir du ${dateFrom}`}
            onDelete={() => {
              setDateFrom('');
              clearResults();
            }}
          />
        )}
        {dateTo && (
          <FilterTag
            label={`Jusqu'au ${dateTo}`}
            onDelete={() => {
              setDateTo('');
              clearResults();
            }}
          />
        )}
        {garanteeNature?.libelle && (
          <FilterTag
            label={garanteeNature.libelle}
            onDelete={() => {
              setGaranteeNature(undefined);
              clearResults();
            }}
          />
        )}
        {treatmentType?.libelle && (
          <FilterTag
            label={treatmentType.libelle}
            onDelete={() => {
              setTreatmentType(undefined);
              clearResults();
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <StyledSecondaryTitle>{messages.SEARCH_FILE_TITLE}</StyledSecondaryTitle>

      <Space marginBottom="1.6rem" />

      {errorMissingCriteria && !hasTags() ? (
        <div style={{ width: '45rem' }}>
          <ErrorMessageArea message={messages.ERROR_MESSAGES_NO_CRITERIA} />
        </div>
      ) : null}
      {hasError && (
        <>
          <Space marginTop="1rem" />
          <InformationArea backgroundColor="#FEF2F4" icon={<ErrorIcon />} width="100%">
            <span> {hasError}</span>
          </InformationArea>
          <Space marginTop="1rem" />
        </>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '3.2rem' }}>
        <StyledFlexRow>
          <SearchInput
            value={lastName}
            setValue={setLastName}
            placeholder={messages.SEARCH_PLACEHOLDER_LAST_NAME}
            icon={<LookingGlassIcon />}
            startSearch={search}
          />
          <SearchInput
            value={firstName}
            setValue={setFirstName}
            placeholder={messages.SEARCH_PLACEHOLDER_FIRST_NAME}
            icon={<LookingGlassIcon />}
            startSearch={search}
          />
          <SearchInput
            value={fileNumber}
            setValue={setFileNumber}
            placeholder={messages.SEARCH_PLACEHOLDER_FILE_NUMBER}
            icon={<FolderSearchIcon />}
            startSearch={search}
          />
          <BaseButton
            onClick={search}
            style={{
              width: '21.8rem',
              background: theme.colors.chartColors.mainGreenBnp500,
            }}>
            <StyledButtonLabel>{messages.SEARCH_BUTTON_LABEL}</StyledButtonLabel>
          </BaseButton>
        </StyledFlexRow>

        <StyledFlexRow>
          <SearchSelectInput
            icon={<FolderSearchIcon />}
            items={fileStatusItems}
            value={status}
            setValue={setStatus}
            placeholder={messages.SEARCH_PLACEHOLDER_FILE_STATUS}
          />
          {showAdditionalFilters && (
            <>
              <div
                style={{
                  display: 'flex',
                  gap: '1.6rem',
                  alignItems: 'center',
                  width: '38.9rem',
                }}>
                <StyledStandardText>{messages.SEARCH_DATE_FROM}</StyledStandardText>

                <SearchDateInput
                  value={dateFrom}
                  setValue={setDateFrom}
                  placeholder="01/01/1901"
                  type="day"
                />
                <StyledStandardText>{messages.SEARCH_DATE_TO}</StyledStandardText>
                <SearchDateInput
                  value={dateTo}
                  setValue={setDateTo}
                  placeholder="01/01/1901"
                  type="day"
                />
              </div>

              <SearchSelectInput
                icon={<HomeIcon color="#4B4F54" scale="0.75" />}
                items={garanteeNatureItems}
                value={garanteeNature}
                setValue={setGaranteeNature}
                placeholder={messages.SEARCH_PLACEHOLDER_GARANTEE_NATURE}
              />

              <SearchSelectInput
                icon={<HourglassIcon />}
                items={treatmentTypeItems}
                value={treatmentType}
                setValue={setTreatmentType}
                placeholder={messages.SEARCH_PLACEHOLDER_TREATMENT_TYPE}
              />
            </>
          )}
        </StyledFlexRow>

        <StyledFiltersRow>
          <StyledExtraFiltersAndTagsContainer>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
              <StyledStandardText style={{ whiteSpace: 'nowrap' }}>
                {messages.SEARCH_TOGGLE_LABEL}
              </StyledStandardText>
              <ToggleButton
                isOn={showAdditionalFilters}
                id="additional-filters-toggle"
                handleToggle={() => {
                  if (showAdditionalFilters) resetAdditionalFilters();
                  setShowAdditionalFilters(!showAdditionalFilters);
                }}
              />
            </div>
            <StyledTagsContainer>{renderTags()}</StyledTagsContainer>
          </StyledExtraFiltersAndTagsContainer>
          {hasTags() && (
            <StyledRefreshFiltersButton
              role="button"
              tabIndex={0}
              onClick={() => {
                resetAllFilters();
                clearResults();
              }}
              onKeyDown={() => {
                resetAllFilters();
                clearResults();
              }}>
              <RefreshIcon />
              <StyledRefreshButtonText>
                Réinitialiser la recherche
              </StyledRefreshButtonText>
            </StyledRefreshFiltersButton>
          )}
        </StyledFiltersRow>
      </div>
    </>
  );
};

export default SearchBar;
