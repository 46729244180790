import ChevronDown from 'icons/ChevronDown';
import ChevronUp from 'icons/ChevronUp';
import ChevronExpand from 'icons/ChevronExpand';
import DownloadIcon from 'icons/DownloadIcon';
import { SortEnum, BordereauSortingKey, Bordereau } from 'types';
import { BordereauKeys } from 'reducers/ebordereau/bordereau';

interface BordereauListProps {
  bordereauList: Bordereau[];
  typeBordereauFilters?: string[];
  handleSortBordereauListByPerComi?: () => void;
  handleSortBordereauListByName?: () => void;
  handleSortBordereauListByType?: () => void;
  onFilter?: (text: string, filteringKey: BordereauKeys) => void;
  onTypeBordereauSelect?: (type: string) => void;
  onDownloadDocumentClick?: (
    reference: string,
    partenaireProprietaireTab: string,
    numeroFacture: string,
  ) => void;
  activeSortingKey?: BordereauSortingKey;
  perComiSortOrder?: SortEnum;
  nameSortOrder?: SortEnum;
  typeSortOrder?: SortEnum;
}

const BordereauList: React.FC<BordereauListProps> = ({
  bordereauList,
  typeBordereauFilters,
  handleSortBordereauListByPerComi,
  handleSortBordereauListByName,
  handleSortBordereauListByType,
  onFilter,
  onTypeBordereauSelect,
  onDownloadDocumentClick,
  activeSortingKey,
  perComiSortOrder,
  nameSortOrder,
  typeSortOrder,
}) => {
  const thStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem',
  };

  const renderSortingIcon = (sortOrder?: SortEnum) => {
    if (sortOrder === SortEnum.ASC) {
      return <ChevronUp />;
    }
    if (sortOrder === SortEnum.DESC) {
      return <ChevronDown />;
    }
    return null;
  };

  const renderListBordereau = () => {
    if (bordereauList.length === 0) {
      return (
        <tr>
          <td colSpan={3}>Aucun résultat trouvé</td>
        </tr>
      );
    }
    return bordereauList.map((bordereau, index) => {
      return (
        <tr key={bordereau.reference} className={index % 2 === 0 ? 'grayBackground' : ''}>
          <td>{bordereau.perComi}</td>
          <td>{bordereau.nomCourtier}</td>
          <td>{bordereau.typeBordereau}</td>
          <td>
            <div
              style={{ cursor: 'pointer' }}
              onKeyPress={() => {
                if (onDownloadDocumentClick) {
                  onDownloadDocumentClick(
                    bordereau.reference,
                    bordereau.partenaireProprietaire,
                    bordereau.numeroFacture,
                  );
                }
              }}
              onClick={() => {
                if (onDownloadDocumentClick) {
                  onDownloadDocumentClick(
                    bordereau.reference,
                    bordereau.partenaireProprietaire,
                    bordereau.numeroFacture,
                  );
                }
              }}
              role="button"
              tabIndex={0}>
              <DownloadIcon />
            </div>
          </td>
        </tr>
      );
    });
  };
  const renderTypeBorderauDropdownFilter = () => {
    if (!typeBordereauFilters) {
      return null;
    }
    return typeBordereauFilters.map(item => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
  };
  return (
    <>
      <table data-testid="apaTable" className="apaAndBordereauTable">
        <thead>
          <tr>
            <th>
              <div
                style={{ padding: '0.8rem', textAlign: 'left', ...thStyle }}
                role="button"
                tabIndex={0}
                onClick={handleSortBordereauListByPerComi}
                onKeyPress={handleSortBordereauListByPerComi}>
                <div>
                  <span>Période de commissionnement</span>
                </div>
                {activeSortingKey === 'perComi' ? (
                  renderSortingIcon(perComiSortOrder)
                ) : (
                  <ChevronExpand />
                )}
              </div>

              <div>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  className="styledBorder"
                  onChange={e => {
                    if (onFilter) {
                      onFilter(e.target.value, 'perComi');
                    }
                  }}
                />
              </div>
            </th>
            <th>
              <div
                style={{ padding: '0.8rem', textAlign: 'left', ...thStyle }}
                role="button"
                tabIndex={0}
                onClick={handleSortBordereauListByName}
                onKeyPress={handleSortBordereauListByName}>
                <div>
                  <span>Nom du partenaire</span>
                </div>
                {activeSortingKey === 'nomCourtier' ? (
                  renderSortingIcon(nameSortOrder)
                ) : (
                  <ChevronExpand />
                )}
              </div>

              <div>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  className="styledBorder"
                  onChange={e => {
                    if (onFilter) {
                      onFilter(e.target.value, 'nomCourtier');
                    }
                  }}
                />
              </div>
            </th>
            <th>
              <div
                style={{ padding: '0.8rem', textAlign: 'left', ...thStyle }}
                role="button"
                tabIndex={0}
                onClick={handleSortBordereauListByType}
                onKeyPress={handleSortBordereauListByType}>
                <div>
                  <span>Type de bordereau</span>
                </div>
                {activeSortingKey === 'typeBordereau' ? (
                  renderSortingIcon(typeSortOrder)
                ) : (
                  <ChevronExpand />
                )}
              </div>

              <div>
                <select
                  style={{ width: '100%' }}
                  className="styledBorder"
                  onChange={e => {
                    if (onTypeBordereauSelect) {
                      onTypeBordereauSelect(e.target.value);
                    }
                  }}>
                  <option>{}</option>
                  {renderTypeBorderauDropdownFilter()}
                </select>
              </div>
            </th>
            <th style={{ width: '4rem' }}>{}</th>
          </tr>
        </thead>
        <tbody>{renderListBordereau()}</tbody>
      </table>
    </>
  );
};

export default BordereauList;
