import styled from 'styled-components';

export const StyledMainTitle = styled.span`
  color: #292c2e;
  font-size: 24px;
  font-family: 'Ubuntu';
  font-weight: bold;
`;

export const StyledBreadcrumbText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const StyledSubTitle = styled.span`
  height: 32px;
  color: #292c2e;
  font-family: 'Ubuntu';
  font-size: 24px;
  font-weight: bold;
`;

export const StyledCalculatorContainer = styled.div`
  width: 118rem;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 5rem;
`;

export const StyledSectionTitle = styled.div`
  height: 24px;
  width: 29rem;
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin: 4rem 0 1rem 4rem;
`;

export const StyledSubSectionTitle = styled.div`
  height: 24px;
  width: 29rem;
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin: 2rem 0 1rem 4rem;
`;

export const StyledRadioButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0 1rem 4rem;
`;

export const StyledMarginDiv = styled.div`
  margin: 2rem 0 1rem 4rem;
`;

export const StyledRectangle = styled.div`
  height: 3.2rem;
  width: 16.8rem;
  border: 1px solid #268038;
  border-radius: 1.6rem;
  color: #268038;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const StyledLine = styled.div`
  box-sizing: border-box;
  height: 0.1rem;
  width: 61rem;
  border: 0.1rem solid #c7cacd;
`;

export const StyledAssuranceIndividuelleText = styled.p`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
`;

export const StyledMarginDivInsurance = styled.div`
  margin: 2rem 0 1rem 4rem;
  display: flex;
  gap: 5.5rem;
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #fef2f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  margin: 2rem 4rem 1rem 4rem;
  padding-left: 1rem;
`;

export const SummaryWrapper = styled.div`
  width: 54rem;
  height: 25rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  border: 0.1rem solid #6f757c;
  padding: 1.6rem;
  margin-top: 4rem;
`;

export const SummaryWrapperFinancing = styled.div`
  width: 54rem;
  height: 38rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
  margin-top: 4rem;
`;

export const SummaryWrapperAssurance = styled.div`
  width: 54rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1rem 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
  margin-top: 4rem;
`;

export const StyledLabelValue = styled.p`
  margin: 0;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const StyledHorizontalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem 2rem 2rem;
`;

export const StyledDottedLine = styled.div`
  align-self: end;
  flex-grow: 1;
  height: 0.1rem;
  margin: 0;
  margin-bottom: 0.55rem;
  background-image: linear-gradient(to right, #c7cacd 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
`;

export const StyledSubTitleCard = styled.span`
  height: 20px;
  color: #292c2e;
  font-size: 20px;
  font-weight: bold;
  margin-left: 2rem;
`;

export const Line = styled.div`
  box-sizing: border-box;
  height: 0.1rem;
  width: 50rem;
  border-bottom: 0.1rem solid #c7cacd;
`;

export const StyledSubSectionCard = styled.span`
  height: 20px;
  color: #292c2e;
  font-size: 16px;
  font-weight: bold;
  margin-left: 2rem;
`;
