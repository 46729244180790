import GenericIconProps from './GenericIcon';

const WarningIcon: React.FC<GenericIconProps> = ({ color }) => (
  <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg">
    <title>8D64614B-EF8E-4E78-A3E8-E5EE966756AB</title>
    <path
      d="M10,0 C15.5228498,0 20,4.47715014 20,10 C20,15.5228497 15.52285,20 10,20 C4.4771502,20 0,15.5228499 0,10 C0,4.4771498 4.47715012,0 10,0 Z M10.0000003,8.41269829 L9.90044647,8.41888195 C9.50911544,8.46785543 9.20634959,8.8017447 9.20634959,9.2063491 L9.20634959,9.2063491 L9.20634959,14.7619047 L9.20634959,14.7619047 L9.21253325,14.8614586 C9.26150672,15.2527896 9.59539599,15.5555555 10.0000004,15.5555555 L10.0000004,15.5555555 L10.0995543,15.5493718 C10.4908854,15.5003983 10.7936512,15.1665091 10.7936512,14.7619047 L10.7936512,14.7619047 L10.7936512,9.2063491 C10.7936512,8.76802766 10.4383218,8.41269829 10.0000003,8.41269829 L10.0000003,8.41269829 Z M10.0000004,4.44444412 C9.34251862,4.44444412 8.80952417,4.97743858 8.80952417,5.63492034 C8.80952417,6.2924021 9.34251862,6.82539655 10.0000004,6.82539655 L10.0000004,6.82539655 L10.1217196,6.81925025 C10.7220244,6.75828596 11.1904766,6.25130943 11.1904766,5.63492028 L11.1904766,5.63492028 L11.1843303,5.51320107 C11.123366,4.91289628 10.6163895,4.44444412 10.0000004,4.44444412 Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

WarningIcon.defaultProps = {
  color: '#C8102E',
};

export default WarningIcon;
