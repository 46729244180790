import {
  GetBordereauApiRequestBody,
  GetBordereauApiResponse,
  GetBordereauDocumentApiRequestBody,
  GetBordereauDocumentApiResponse,
  GetPartenerApiRequestBody,
  Partner,
} from 'types';
import { solarBridgeApiAxiosInstance } from './axiosInstances';

export const getPartenerList = (data: GetPartenerApiRequestBody): Promise<Partner[]> => {
  return solarBridgeApiAxiosInstance
    .post('/Bordereau/RecupererListeApa', { ...data })
    .then(response => {
      return Promise.resolve(response.data.result);
    })
    .catch(err => {
      console.error(err);
      const message = err.response ? err.response.data : `${err}`;
      throw Error(message);
    });
};

export const getBordereaux = (
  data: GetBordereauApiRequestBody,
): Promise<GetBordereauApiResponse> => {
  return solarBridgeApiAxiosInstance
    .post('/Bordereau/RestituerBordereaux', { ...data })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response ? err.response.data : `${err}`;
      throw Error(message);
    });
};

export const getBordereauDocument = (
  data: GetBordereauDocumentApiRequestBody,
): Promise<GetBordereauDocumentApiResponse> => {
  return solarBridgeApiAxiosInstance
    .post('/Bordereau/RecupererBordereauRC', { ...data })
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch(err => {
      const message = err.response
        ? err.response.data
        : `Error getting bordereau document. Reason: ${err}`;
      throw Error(message);
    });
};
