import CustomInput from 'components/customInput/CustomInput';
import { useEffect, useState } from 'react';
import { Bank } from 'types';
import { getDateFromInput, isDateIntheFuture, isRightAge } from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import { getBirthYearOlderBorrower } from 'utils/commun';
import * as messagesCheckInput from 'utils/messagesCheckInput';

interface BankStartinYearProps {
  currentBank: Bank;
  checkInputs: boolean;
  updateState: (year: string) => void;
  hasError: (error: boolean, id: string) => void;
  setYearInputError: (error: string) => void;
}

const BankStartingYear: React.FC<BankStartinYearProps> = ({
  currentBank,
  checkInputs,
  updateState,
  hasError,
  setYearInputError,
}) => {
  const [isYearInputFocused, setIsYearInputFocused] = useState<boolean>(false);
  const [isYearInputValid, setIsYearInputValid] = useState<boolean | undefined>();
  const [value, setValue] = useState<string>(currentBank.year || '');

  const yearRegexp = '^\\d{4}$';
  const firstDayOfMonth = '01/';
  const firstMonth = '01/';

  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);

  const checkStatusYear = () => {
    setIsYearInputFocused(false);
    if (!value) {
      setYearInputError(messagesCheckInput.REQUIRED_VALUE);
      setIsYearInputValid(false);
      updateState(value);
      if (currentBank.id) {
        hasError(true, currentBank.id);
      }
    } else if (!new RegExp(yearRegexp).test(value)) {
      setYearInputError(messagesCheckInput.YEAR_FORMAT);
      setIsYearInputValid(false);
      updateState(value);
      if (currentBank.id) {
        hasError(true, currentBank.id);
      }
    } else if (
      !isRightAge(
        getDateFromInput(`${firstDayOfMonth}${firstMonth}${getBirthYearOlderBorrower()}`),
        getDateFromInput(`${firstDayOfMonth}${firstMonth}${value}`),
        15,
      )
    ) {
      setYearInputError(messagesCheckInput.BANK_STARTING_YEAR_MINIMAL_AGE);
      setIsYearInputValid(false);
      updateState(value);
      if (currentBank.id) {
        hasError(true, currentBank.id);
      }
    } else if (
      !isDateIntheFuture(getDateFromInput(`${firstDayOfMonth}${firstMonth}${value}`))
    ) {
      setYearInputError(messagesCheckInput.YEAR_FUTUR);
      setIsYearInputValid(false);
      updateState(value);
      if (currentBank.id) {
        hasError(true, currentBank.id);
      }
    } else {
      setIsYearInputValid(true);
      setYearInputError('');
      updateState(value);
      if (currentBank.id) {
        hasError(false, currentBank.id);
      }
    }
  };

  useEffect(() => {
    if (value) {
      checkStatusYear();
    }
  }, []);

  useEffect(() => {
    if (checkInputs) {
      checkStatusYear();
    }
  }, [checkInputs]);

  return (
    <>
      <CustomInput
        name="bank-starting-year"
        label=""
        value={value}
        onChange={newValue => {
          if (onlyNumbers.test(newValue)) setValue(newValue.slice(0, 4));
          setIsYearInputFocused(true);
        }}
        isValid={isYearInputValid}
        isFocused={isYearInputFocused}
        onBlur={checkStatusYear}
        onFocus={() => {
          setIsYearInputFocused(true);
        }}
        inputWidth="10.6rem"
        placeholder="aaaa"
      />
    </>
  );
};

export default BankStartingYear;
