import * as React from 'react';

const MoneyIcon: React.FC = () => (
  <svg width={54} height={54} xmlns="http://www.w3.org/2000/svg">
    <title>76AA6663-5742-4DC8-8FF1-A59479A582A5</title>
    <g fill="#292C2E" fillRule="nonzero">
      <path d="M13.357 15.36c-.354 0-.709-.147-.966-.409a1.39 1.39 0 0 1 .016-1.948L24.787.725a1.338 1.338 0 0 1 1.802-.081l6.15 5.074c.58.475.676 1.36.193 1.948a1.342 1.342 0 0 1-1.915.197L25.8 3.573l-11.51 11.41c-.242.246-.58.377-.934.377Z" />
      <path d="M40.66 15.36c-.564 0-1.095-.36-1.289-.933l-2.736-8.07-14.585 8.807a1.34 1.34 0 0 1-1.868-.491 1.389 1.389 0 0 1 .483-1.9L36.683 3.1a1.374 1.374 0 0 1 1.16-.114c.385.147.675.458.82.85l3.268 9.676c.242.72-.129 1.506-.837 1.751a.843.843 0 0 1-.435.098Z" />
      <path d="M43.203 53.667H10.057c-.097 0-2.431-.099-4.782-1.343C3.022 51.13.333 48.657.333 43.681c0-14.52.016-22.918.016-23 0-.05 0-.098.017-.148.29-2.75 2.64-7.94 9.353-7.94h34.917c.418-.015 3.686-.015 6.197 2.407 1.884 1.817 2.834 4.47 2.834 7.89v21.2c0 .098-.113 9.577-10.464 9.577ZM10.137 50.9h33.066c7.646 0 7.743-6.515 7.743-6.794V22.907c0-2.62-.66-4.6-1.964-5.877-1.77-1.735-4.234-1.67-4.266-1.67H9.72c-5.65 0-6.536 4.6-6.649 5.403 0 .933-.016 9.183-.016 22.901 0 6.794 6.584 7.22 7.083 7.236Z" />
      <path d="M31.66 43.992c-.933 0-4.507-.18-7.115-2.718-1.867-1.817-2.817-4.37-2.817-7.595 0-3.143.934-5.664 2.769-7.465 2.962-2.93 7.196-2.832 7.373-2.832.756.016 1.336.655 1.32 1.424a1.365 1.365 0 0 1-1.352 1.342h-.033c-.064 0-3.3-.032-5.425 2.063-1.272 1.26-1.915 3.11-1.915 5.468 0 2.439.66 4.305 1.964 5.582 2.108 2.063 5.328 1.964 5.36 1.964.74-.032 1.385.557 1.417 1.326.032.753-.547 1.408-1.288 1.44h-.258Z" />
      <path d="M30.373 32.336H19.442a1.362 1.362 0 0 1-1.352-1.375c0-.753.611-1.375 1.352-1.375h10.914c.757 0 1.353.622 1.353 1.375s-.596 1.375-1.336 1.375ZM30.405 37.82H19.522a1.362 1.362 0 0 1-1.352-1.375c0-.753.612-1.375 1.352-1.375h10.883c.756 0 1.352.622 1.352 1.375s-.596 1.375-1.352 1.375Z" />
    </g>
  </svg>
);

export default MoneyIcon;
