import TextButton from 'components/buttons/textButton/TextButton';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import ExpandableCard from 'components/expandableCard/ExpandableCard';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import ToggleButton from 'components/toggleButton/ToggleButton';
import TotalInfoBubble from 'components/totalInfoBubble/TotalInfoBubble';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import React, { useState } from 'react';
import {
  Asset,
  CurrentAssetAction,
  OperationAction,
  OperationState,
} from 'reducers/operationClient/types';
import { displayNumber, formatNumberByThousand } from 'utils/commun';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../messages';
import {
  StyledButtonSpacer,
  StyledCardContentTile,
  StyledCardData,
  StyledCardLabel,
  StyledStandardFlexContainer,
  StyledTotalInfoBubbleData,
  StyledTotalInfoBubbleText,
} from '../style';

interface AssetFormProps {
  operationReducerDispatch: React.Dispatch<OperationAction>;
  currentAssetReducerDispatch: React.Dispatch<CurrentAssetAction>;
  state: OperationState;
  setModalVisibility: (visibility: boolean) => void;
  checkInputs: boolean;
  setIsEdited: (isEdited: boolean) => void;
}

const AssetForm: React.FC<AssetFormProps> = ({
  operationReducerDispatch,
  currentAssetReducerDispatch,
  state,
  setModalVisibility,
  checkInputs,
  setIsEdited,
}) => {
  const [hasInsurance, setHasInsurance] = useState<boolean>(state.hasInsurance || false);

  const [tempAssetList, setTempAssetList] = useState<Asset[]>([]);

  const onAssetCardEdit = (index: number) => {
    const currentAsset = state.assetList[index];
    currentAsset.code = index;

    currentAssetReducerDispatch({
      type: 'setCurrentAsset',
      payload: currentAsset,
    });

    setModalVisibility(true);
    setIsEdited(true);
  };

  const onAssetCardDelete = (index: number) => {
    sessionStorage.removeItem('guaranteeAdditionalInformation');
    state.assetList.splice(index, 1);

    operationReducerDispatch({
      type: 'setAssetList',
      payload: state.assetList,
    });
  };

  const renderAssets = (): React.ReactFragment => {
    return state.assetList?.map((asset, index) => {
      return (
        <React.Fragment key={asset.code}>
          <ExpandableCard
            accentColor={asset.isDataOk ? '#28833C' : '#E78A08'}
            key={asset.code}
            type="Asset"
            index={index}
            onEdit={onAssetCardEdit}
            onDelete={onAssetCardDelete}>
            <StyledCardContentTile>
              <StyledCardLabel>{messages.ASSET_FORM.ASSET_TYPE}</StyledCardLabel>
              <StyledCardData>{asset.type?.libelle}</StyledCardData>
            </StyledCardContentTile>

            <StyledCardContentTile>
              <StyledCardLabel>{messages.ASSET_FORM.ASSET_USAGE}</StyledCardLabel>
              <StyledCardData>{asset.usage?.libelle}</StyledCardData>
            </StyledCardContentTile>

            <StyledCardContentTile>
              <StyledCardLabel>{messages.ASSET_FORM.ASSET_VALUE}</StyledCardLabel>
              <StyledCardData>
                {formatNumberByThousand(
                  asset.currentValue !== undefined ? asset.currentValue : '',
                )}{' '}
                €
              </StyledCardData>
            </StyledCardContentTile>
          </ExpandableCard>
          <Space marginBottom="2.4rem" />
        </React.Fragment>
      );
    });
  };

  const renderInsurance = (): React.ReactFragment => {
    return (
      <>
        <Space marginBottom="24px" />

        {(!state.assetList || state.assetList.length === 0) && checkInputs ? (
          <>
            <ErrorMessageArea message={messagesCheckInput.ASSET_REQUIRED} />
            <Space marginTop="3.2rem" />
          </>
        ) : (
          <></>
        )}

        <TextButton
          label={messages.ASSET_FORM.ASSET_BUTTON_ADD}
          onClick={() => {
            currentAssetReducerDispatch({
              type: 'setCurrentAsset',
              payload: {} as Asset,
            });

            setModalVisibility(true);
            setIsEdited(false);
          }}>
          <StyledButtonSpacer />
          <PlusCircleIcon />
        </TextButton>

        <Space marginBottom="24px" />

        {renderAssets()}

        <Space marginBottom="40px" />

        <TotalInfoBubble total={state.totalAssetsValue ? state.totalAssetsValue : 0}>
          <StyledStandardFlexContainer>
            <StyledTotalInfoBubbleText>
              {messages.ASSET_FORM.ASSET_TOTAL}
            </StyledTotalInfoBubbleText>
            <StyledTotalInfoBubbleData>
              {state.totalAssetsValue ? displayNumber(state.totalAssetsValue) : 0} €
            </StyledTotalInfoBubbleData>
          </StyledStandardFlexContainer>
        </TotalInfoBubble>
      </>
    );
  };

  return (
    <>
      <StepTitles
        title={messages.ASSET_FORM.ASSET_STEPTITLE_WARRANPTY}
        subTitle={messages.ASSET_FORM.ASSET_SUBTITLE_WARRANPTY}
      />

      <ToggleButton
        id="other-incomes"
        isOn={hasInsurance}
        handleToggle={() => {
          if (!hasInsurance) {
            if (tempAssetList.length > 0) {
              operationReducerDispatch({
                type: 'setAssetList',
                payload: tempAssetList,
              });
            }
          } else {
            setTempAssetList(state.assetList);
            operationReducerDispatch({
              type: 'setAssetList',
              payload: [],
            });
          }
          operationReducerDispatch({
            type: 'setHasInsurance',
            payload: !hasInsurance,
          });
          setHasInsurance(prevState => !prevState);
        }}>
        <span
          style={{
            marginRight: '1rem',
            display: 'block',
            marginTop: '0.3rem',
            height: '3.2rem',
            fontWeight: 'bold',
            fontSize: '1.6rem',
          }}>
          {messages.ASSET_FORM.ASSET_WARRANPTY}
        </span>
      </ToggleButton>

      {hasInsurance ? renderInsurance() : <></>}
    </>
  );
};

export default AssetForm;
