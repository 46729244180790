import ShowInformationBar from 'components/showInformationBar/showInformationBar';
import Space from 'components/space/Space';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledMainDisplayCard,
  StyleNoDataContainer,
} from 'containers/consultation/style';
import { useEffect, useState } from 'react';
import { Asset, OperationState } from 'reducers/operationClient/types';
import { formatValues } from 'utils/commun';
import * as messages from '../../messages';

interface GarantieConsultationProps {
  isProjectEditable: boolean | undefined;
}

const GarantieConsultation: React.FC<GarantieConsultationProps> = ({
  isProjectEditable,
}) => {
  const [garanties, setGaranties] = useState<Asset[]>();
  const [expandedRow, setExpandedRow] = useState<number>();

  useEffect(() => {
    const operationClientState = JSON.parse(
      sessionStorage.getItem('operationsClient') || '',
    ) as OperationState;

    if (operationClientState) setGaranties(operationClientState.assetList);
  }, []);

  const isRowExpanded = (index: number) => {
    return index === expandedRow;
  };

  const renderGaranties = () => {
    return garanties?.map((garantie, index) => {
      const leftOverAfterMortgage =
        Number(garantie.currentValue) - Number(garantie.keptMortagagesValue);
      return (
        <>
          <StyledCardTitle>{`${messages.MAIN_CARD_LABEL_ASSET} ${
            index + 1
          }`}</StyledCardTitle>

          <Space marginBottom="1.6rem" />

          <StyledCardRow>
            <StyledCardTile>
              <StyledCardLabel>{messages.MAIN_CARD_LABEL_ASSET_TYPE}</StyledCardLabel>
              <StyledCardData>{garantie.type?.libelle}</StyledCardData>
            </StyledCardTile>

            <StyledCardTile>
              <StyledCardLabel>{messages.MAIN_CARD_LABEL_ASSET_USAGE}</StyledCardLabel>
              <StyledCardData>{garantie.usage?.libelle}</StyledCardData>
            </StyledCardTile>

            <StyledCardTile>
              <StyledCardLabel>{messages.MAIN_CARD_LABEL_ASSET_VALUE}</StyledCardLabel>
              <StyledCardData>{formatValues(garantie.currentValue, '€')}</StyledCardData>
            </StyledCardTile>

            <StyledCardTile>
              <StyledCardLabel>
                {messages.MAIN_CARD_LABEL_INSCRIPTION_RANK}
              </StyledCardLabel>
              <StyledCardData>{garantie.inscriptionRank}</StyledCardData>
            </StyledCardTile>
          </StyledCardRow>

          {isRowExpanded(garantie.code) && (
            <>
              <Space marginBottom="3.6rem" />
              <StyledCardRow>
                <StyledCardTile>
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LABEL_KEPT_MORTGAGES_VALUE}
                  </StyledCardLabel>
                  <StyledCardData>
                    {formatValues(garantie.keptMortagagesValue, '€')}
                  </StyledCardData>
                </StyledCardTile>

                <StyledCardTile>
                  <StyledCardLabel>
                    {messages.MAIN_CARD_LABEL_REMAINING_MORTAGAGES_VALUE}
                  </StyledCardLabel>
                  <StyledCardData>
                    {formatValues(leftOverAfterMortgage.toString(), '€')}
                  </StyledCardData>
                </StyledCardTile>
              </StyledCardRow>
            </>
          )}

          <Space marginBottom="3.2rem" />

          <ShowInformationBar
            isRowExpanded={isRowExpanded(garantie.code)}
            code={garantie.code}
            setExpandedRow={setExpandedRow}
          />

          <Space marginBottom="3.6rem" />
        </>
      );
    });
  };

  return (
    <>
      {garanties?.length ? (
        <>
          <StyledMainDisplayCard>
            {renderGaranties()}

            <ConsultationEditButton
              isProjectEditable={isProjectEditable}
              navigateRoute="/operation_client"
            />
          </StyledMainDisplayCard>
        </>
      ) : (
        <>
          <StyleNoDataContainer>{messages.NO_ASSET}</StyleNoDataContainer>

          <Space marginBottom="2.4rem" />

          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <ConsultationEditButton
              isProjectEditable={isProjectEditable}
              navigateRoute="/operation_client"
            />
          </div>
        </>
      )}
    </>
  );
};

export default GarantieConsultation;
