import { editPackContractuel } from 'api/transfertDocumentService';
import FormFooter from 'components/formFooter/FormFooter';
import Loader from 'components/Loader';
import Space from 'components/space/Space';
import ActionSignIcon from 'icons/ActionSign';
import { useReducer, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  emissionPackReducer,
  getEmissionPackInitialState,
} from 'reducers/emissionPack/emissionPack';
import { FinalisationProjetState } from 'reducers/finalisationProjet/types';
import { ErrorMessage, PropositionOutDtoResp } from 'types/FaisabiliteDTO';
import { PackContractuelResp } from 'types/packContractuelDtos';
import {
  getNumeroProjet,
  projectHasGarantee,
  getUserInfos,
  zipcodeNumbertoString,
} from 'utils/commun';
import { getIbanInitialState, ibanReducer } from 'reducers/iban/iban';
import PjConfirmationModal from 'components/modals/ConfirmationModals/PjConfirmationModal';
import {
  fiabiliserAdresse,
  fiabiliserAdresseEmail,
  getStreets,
} from 'api/referentialService';
import { Adresse, CodeLibelle } from 'types';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import FailedEmissionPackModal from './modals/failedPackEmissionModal';
import BorrowersInfos from './sections/borrowersInfos';
import LoanCards from './sections/loanCards';
import Rib from './sections/rib';
import { getPackContractuelReq } from './utils';
import FailedReliabilityModal from '../../../../components/modals/ConfirmationModals/FailedreliabilityModal';

interface EmissionPackProps {
  isBtnDisabled: boolean;
}

const EmissionPack: React.FC<EmissionPackProps> = ({ isBtnDisabled }) => {
  const [displayState, displayDispatch] = useReducer(
    emissionPackReducer,
    getEmissionPackInitialState('emissionPack'),
  );

  const [state, dispatch] = useReducer(
    emissionPackReducer,
    getEmissionPackInitialState('emissionPack'),
  );

  const [ibanState, ibanDispatch] = useReducer(ibanReducer, getIbanInitialState('iban'));

  const [isFailedReliabilityModalVisible, setIsFailedReliabilityModalVisible] =
    useState<boolean>(false);
  const [isFailedEmissionModalVisible, setIsFailedEmissionModalVisible] =
    useState<boolean>(false);
  const [isFailedEmissionModalRetryEnabled, setIsFailedEmissionModalRetryEnabled] =
    useState<boolean>(true);
  const [isPjConfirmationModalVisible, setIsPjConfirmationModalVisible] =
    useState<boolean>(false);
  const [isTerminatedWithoutEdit, setIsTerminatedWithoutEdit] = useState<boolean>(false);

  const [fiabilisationAdressErrorMessage, setFiabilisationAdressErrorMessage] =
    useState<string>();
  const [fiabilisationBorrowerEmailError, setFiabilisationBorrowerEmailError] =
    useState<string>();
  const [fiabilisationCoBorrowerEmailError, setFiabilisationCoBorrowerEmailError] =
    useState<string>();

  const [technicalErrorMessage, setTechnicalErrorMessage] = useState<string>();

  const [simulation, setSimulation] = useState<PropositionOutDtoResp>(
    {} as PropositionOutDtoResp,
  );

  const [reliabilityFailType, setReliabilityFailType] = useState<
    'address' | 'emp' | 'co-emp'
  >('address');
  const [correctionAddress, setCorrectionAddress] = useState<Adresse>();

  const [streetTypeReferential, setStreetTypeReferential] = useState<CodeLibelle[]>();

  const errorMessageRef = useRef<null | HTMLDivElement>(null);

  const closeModal = (): void => {
    setIsPjConfirmationModalVisible(false);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [packEmissionResp, setPackEmissionResp] = useState<PackContractuelResp>();

  useEffect(() => {
    const simultationFromSession = sessionStorage.getItem('simulationResult');
    const simulationResult = JSON.parse(
      simultationFromSession || '{}',
    ) as PropositionOutDtoResp;
    setSimulation(simulationResult);
  }, []);

  useEffect(() => {
    getStreets().then(response => {
      setStreetTypeReferential(response.Resultat);
    });
  }, []);

  const hasTechnicalError = (errors: ErrorMessage[]): number => {
    if (errors?.length > 0) {
      return errors.findIndex(error => {
        if (error) {
          return error.MessageType !== 'I';
        }
        return false;
      });
    }
    return -1;
  };

  const navigate = useNavigate();

  const finishWithoutEdit = (): void => {
    navigate('/feedback');
  };

  const runPackEditionRequest = (adress?: Adresse) => {
    const reqState = state;

    if (adress && Object.keys(adress).length !== 0 && reqState.address) {
      reqState.address.streetNumber = adress.numero;
      reqState.address.streetType = streetTypeReferential?.find(voie => {
        return voie.code === adress?.typeVoie;
      });
      reqState.address.street = adress.nomVoie;
      reqState.address.additionalInformation = adress.complement;
      reqState.address.POBoxOrHamlet = adress.boitePostalLieuDit;
      reqState.address.city = adress.ville;
      reqState.address.zipCode = zipcodeNumbertoString(adress.codePostal);
    }
    editPackContractuel(
      getPackContractuelReq(
        reqState,
        getUserInfos().uid,
        getNumeroProjet() || 0,
        simulation.Resultat.identifiantEmprunteur,
        simulation.Resultat.identifiantCoEmprunteur,
        simulation.Resultat.identifiantPropositionCommerciale,
      ),
    )
      .then(response => {
        setPackEmissionResp(response);

        if (!response.Success && hasTechnicalError(response.ListeMessages) !== -1) {
          setIsFailedEmissionModalVisible(true);
        } else if (response.Resultat.statut === 'OK') {
          if (response.Resultat.outputByteField) {
            const linkSource = `data:application/pdf;base64,${response.Resultat.outputByteField}`;

            navigate('/feedback', { state: { linkSource, test: 'test' } });
          }
          if (response.Resultat.urlDocSpace) {
            sessionStorage.setItem(
              'eSignatureLink',
              JSON.stringify(response.Resultat.urlDocSpace),
            );
          }

          navigate('/feedback');
        }
      })
      .catch(error => {
        console.error(error);
        alert(
          "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
        );
      })
      .finally(() => {
        setIsFailedEmissionModalRetryEnabled(true);
        setIsLoading(false);
      });
  };

  const concaTAddresse = (adress: Adresse): string => {
    if (Object.keys(adress).length === 0) return '';
    let concatAdress = '';

    if (adress.numero) concatAdress += `${adress.numero} `;
    if (adress.typeVoie) concatAdress += `${adress.typeVoie} `;
    if (adress.nomVoie) concatAdress += `${adress.nomVoie} `;
    if (adress.complement) concatAdress += `${adress.complement} `;
    if (adress.boitePostalLieuDit) concatAdress += `${adress.boitePostalLieuDit} `;
    if (adress.codePostal) concatAdress += `${adress.codePostal} `;
    if (adress.ville) concatAdress += `${adress.ville} `;

    return concatAdress.trim();
  };

  const runFiabiliserAdresse = () => {
    const stateAddress = state.address;

    if (stateAddress) {
      const convertedAdresse: Adresse = {
        boitePostalLieuDit: stateAddress.POBoxOrHamlet,
        codePostal: Number(stateAddress.zipCode),
        complement: stateAddress.additionalInformation,
        nomVoie: stateAddress.street,
        numero: stateAddress.streetNumber,
        typeVoie: stateAddress.streetType?.code,
        ville: stateAddress.city,
      };

      setFiabilisationAdressErrorMessage(undefined);
      setTechnicalErrorMessage(undefined);
      fiabiliserAdresse(convertedAdresse, simulation.Resultat?.identifiantEmprunteur)
        .then(fiabilisationResponse => {
          if (fiabilisationResponse.Success) {
            const resultAdress = {
              pays: fiabilisationResponse.Resultat.pays,
              complement: fiabilisationResponse.Resultat.complement,
              designationBatiment: fiabilisationResponse.Resultat.designationBatiment,
              numero: fiabilisationResponse.Resultat.numero,
              typeVoie: fiabilisationResponse.Resultat.typeVoie,
              nomVoie: fiabilisationResponse.Resultat.nomVoie,
              adresse: fiabilisationResponse.Resultat.adresse,
              boitePostalLieuDit: fiabilisationResponse.Resultat.boitePostalLieuDit,
              codePostal: fiabilisationResponse.Resultat.codePostal,
              ville: fiabilisationResponse.Resultat.ville,
            } as Adresse;
            if (
              concaTAddresse(resultAdress).toUpperCase() !==
              concaTAddresse(convertedAdresse).toUpperCase()
            ) {
              setIsLoading(false);
              setReliabilityFailType('address');
              setIsFailedReliabilityModalVisible(true);
              setCorrectionAddress(resultAdress);
            } else {
              setCorrectionAddress({});
              runPackEditionRequest();
            }
          } else {
            setIsLoading(false);

            if (fiabilisationResponse.ListeMessages.at(0)?.MessageType !== 'T') {
              // TODO => a ameliorer : gestoin différente en focntion de message d'erreur
              setReliabilityFailType('address');
              setCorrectionAddress({});
              setIsFailedReliabilityModalVisible(true);
              errorMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
              setFiabilisationAdressErrorMessage(
                fiabilisationResponse.ListeMessages.at(0)?.MessageLib,
              );
            } else {
              setTechnicalErrorMessage(
                'Une erreur technique est survenue, contactez votre administrateur',
              );
            }
          }
        })
        .catch(error => {
          setIsLoading(false);
          console.error(error);
          setTechnicalErrorMessage(
            'Une erreur technique est survenue, contactez votre administrateur',
          );
        });
    }
  };

  const fiabilisationAdresseMail = async (
    listEmail: {
      mail: string;
      id: number;
    }[],
  ): Promise<void> => {
    const PromiseList = listEmail.map(item => fiabiliserAdresseEmail(item.mail, item.id));

    Promise.all(PromiseList)
      .then(promiseResponce => {
        if (!promiseResponce.some(response => !response.Success)) {
          setFiabilisationBorrowerEmailError(undefined);
          setFiabilisationCoBorrowerEmailError(undefined);
          runFiabiliserAdresse();
        } else {
          setIsLoading(false);
          let whomWrongMail: 'address' | 'emp' | 'co-emp' = 'emp';
          let nbrErrorMail = 0;

          promiseResponce.forEach(response => {
            if (response.Success) return null;
            if (
              response.Resultat.identifiantEmprunteur ===
              simulation.Resultat?.identifiantEmprunteur
            ) {
              setFiabilisationBorrowerEmailError(
                response.ListeMessages.at(0)?.MessageLib,
              );
              nbrErrorMail += 1;
            }
            if (
              response.Resultat.identifiantEmprunteur ===
              simulation.Resultat?.identifiantCoEmprunteur
            ) {
              setFiabilisationCoBorrowerEmailError(
                response.ListeMessages.at(0)?.MessageLib,
              );
              whomWrongMail = 'co-emp';
              nbrErrorMail += 1;
            }
            return null;
          });
          if (nbrErrorMail === 2) whomWrongMail = 'emp';
          setReliabilityFailType(whomWrongMail);
          setIsFailedReliabilityModalVisible(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
        setTechnicalErrorMessage(
          "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
        );
      });
  };

  const editPack = () => {
    setIsLoading(true);
    setIsFailedEmissionModalRetryEnabled(false);
    const listEmail = new Array<{ mail: string; id: number }>();
    if (displayState.borrowerEmail)
      listEmail.push({
        mail: displayState.borrowerEmail,
        id: simulation.Resultat?.identifiantEmprunteur,
      });
    if (displayState.coBorrowerEmail)
      listEmail.push({
        mail: displayState.coBorrowerEmail,
        id: simulation.Resultat?.identifiantCoEmprunteur,
      });

    if (listEmail.length === 0) runFiabiliserAdresse();
    else fiabilisationAdresseMail(listEmail);
  };

  const sessionFinalisation = sessionStorage.getItem('finalisationProjet');
  let editContractDocuments = false;
  if (sessionFinalisation) {
    const finalisation = JSON.parse(sessionFinalisation) as FinalisationProjetState;
    editContractDocuments = finalisation.packContractuel;
  }

  const displayPJModal = isPjConfirmationModalVisible && !isBtnDisabled;

  const terminatedWithoutEdit = () => {
    if (!isBtnDisabled) {
      setIsPjConfirmationModalVisible(true);
      setIsTerminatedWithoutEdit(true);
    } else {
      navigate('/feedback');
    }
  };

  const terminatewithEdit = () => {
    if (!isBtnDisabled) {
      setIsTerminatedWithoutEdit(false);
      setIsPjConfirmationModalVisible(true);
    } else {
      editPack();
    }
  };

  return (
    <>
      {isLoading && <Loader animationDuration={0.9} />}

      <div ref={errorMessageRef} style={{ maxWidth: '94rem' }}>
        {!projectHasGarantee() && editContractDocuments && (
          <>
            <BorrowersInfos
              displayState={displayState}
              displayDispatch={displayDispatch}
              dispatch={dispatch}
              fiabilisationAdressErrorMessage={fiabilisationAdressErrorMessage}
              fiabilisationBorrowerEmailError={fiabilisationBorrowerEmailError}
              fiabilisationCoBorrowerEmailError={fiabilisationCoBorrowerEmailError}
            />
          </>
        )}

        <LoanCards
          displayState={displayState}
          displayDispatch={displayDispatch}
          dispatch={dispatch}
        />
      </div>

      <Space marginBottom="5.6rem" />

      <Rib
        displayState={displayState}
        displayDispatch={displayDispatch}
        state={state}
        dispatch={dispatch}
        ibanState={ibanState}
        ibanDispatch={ibanDispatch}
      />

      <FailedEmissionPackModal
        isVisible={isFailedEmissionModalVisible}
        errorMessages={packEmissionResp?.ListeMessages}
        close={() => setIsFailedEmissionModalVisible(false)}
        isRetryEnabled={isFailedEmissionModalRetryEnabled}
        continueWithoutPackEmission={() => {
          setIsFailedEmissionModalVisible(false);
          navigate('/feedback');
        }}
      />

      <FailedReliabilityModal
        type={reliabilityFailType}
        isVisible={isFailedReliabilityModalVisible}
        correctionAdress={concaTAddresse(correctionAddress || {})}
        close={() => setIsFailedReliabilityModalVisible(false)}
        continueWithoutReliability={() => {
          setIsFailedReliabilityModalVisible(false);
          setIsLoading(true);
          if (reliabilityFailType === 'address') {
            runPackEditionRequest(correctionAddress || undefined);
          } else runFiabiliserAdresse();
        }}
      />

      {technicalErrorMessage && <ErrorMessageArea message={technicalErrorMessage} />}

      <PjConfirmationModal
        isVisible={displayPJModal}
        accept={isTerminatedWithoutEdit ? finishWithoutEdit : editPack}
        close={closeModal}
      />
      <FormFooter
        saveBtn="btn"
        onSaveButtonClick={terminatedWithoutEdit}
        saveBtnLabel="Terminer sans éditer le pack"
        isNextButtonDisabled={!(state.isDataOk && ibanState.isDataOk) || false}
        showPreviousButton={false}
        nextButtonLabel="Éditer le pack"
        nextButtonIcon={<ActionSignIcon />}
        onNextButtonClick={terminatewithEdit}
      />
    </>
  );
};

export default EmissionPack;
