import { ConfigValues } from '../types';

const getEnvironmentVariables = (): ConfigValues => {
  const values = sessionStorage.getItem('envVariables');

  if (values) {
    return JSON.parse(values);
  }

  throw new Error('error getting or parsing envVariables from the sessionStorage data.');
};

export default getEnvironmentVariables;
