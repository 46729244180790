import {
  isEmployedWorker,
  isOtherJob,
  isSelfEmployedWorker,
  isUnEmployed,
} from 'containers/communs/utils';
import { IdentityState } from 'reducers/identity/types';
import { isAddressCityOkay, isZipCodeOkay } from 'reducers/situationFoyer/situationFoyer';
import { isCountryOkay } from 'reducers/identity/utils';
import { CodeLibelle, SessionStorageKeys } from 'types';
import {
  checkCurrentSituationDate,
  getDateFromInput,
  isDateIntheFuture,
  isRightAge,
} from 'utils/DateUtils';
import { getApeRegexPattern, getSirenRegexPattern } from 'utils/InputValidation';
import { getExistingStorageByKey } from 'utils/storage';
import {
  SituationProfessionnelle,
  SituationProfessionnelleAction,
  SituationProfessionnelleState,
} from './types';

const lastDayOfMonth = '31/';

export const getSituationProfessionnelleInitialState = (
  storageKey: SessionStorageKeys,
): SituationProfessionnelleState => {
  const situationProDefaultValues = {
    borrower: {
      profession: undefined,
      typeContrat: undefined,
      currentJobOrSituationStartingDate: '',
      sirenNumber: '',
      apeNumber: '',
      employerCountry: { code: 'FR', libelle: 'FRANCE' },
      coBorrowerProfession: undefined,
    },
    coBorrower: {
      profession: undefined,
      typeContrat: undefined,
      currentJobOrSituationStartingDate: '',
      sirenNumber: '',
      apeNumber: '',
      employerCountry: { code: 'FR', libelle: 'FRANCE' },
      coBorrowerProfession: undefined,
    },

    isDataOk: false,
  };
  return getExistingStorageByKey(
    storageKey,
    situationProDefaultValues,
  ) as SituationProfessionnelleState;
};

const checkContratType = (contratTypeParam: CodeLibelle | undefined) => {
  if (!contratTypeParam || contratTypeParam.code === 'err') return false;
  return true;
};

const checkCurrentJobOrSituationStartingDate = (
  currentJobOrSituationStartingDateParam: string | undefined,
  person: 'borrower' | 'coBorrower',
) => {
  const identity = JSON.parse(
    sessionStorage.getItem(
      person === 'borrower' ? 'borrowerIdentity' : 'coBorrowerIdentity',
    ) || '{}',
  ) as IdentityState;

  if (currentJobOrSituationStartingDateParam === undefined) return false;
  if (!checkCurrentSituationDate(currentJobOrSituationStartingDateParam)) return false;
  if (
    !isDateIntheFuture(
      getDateFromInput(`${lastDayOfMonth}${currentJobOrSituationStartingDateParam}`),
    )
  )
    return false;
  if (
    !isRightAge(
      getDateFromInput(`${identity.birthDate}`),
      getDateFromInput(`${lastDayOfMonth}${currentJobOrSituationStartingDateParam}`),
      16,
    )
  )
    return false;
  return true;
};
const checkApeNumbner = (apeNumber: string | undefined) => {
  if (
    getApeRegexPattern().find(customValidity => {
      return !apeNumber?.match(customValidity.regexp as string);
    })
  ) {
    return false;
  }
  return true;
};

const checkAdress = (address: {
  codePostal?: string | undefined;
  city?: CodeLibelle | undefined;
}) => {
  if (
    !address ||
    !isZipCodeOkay(address.codePostal || '') ||
    !isAddressCityOkay(address.city) ||
    address.city?.code === 'err'
  )
    return false;
  return true;
};

const checkDataForSelfEmployedWorker = (state: SituationProfessionnelle) => {
  const { sirenNumber, apeNumber, employerCountry } = state;
  if (!sirenNumber || !apeNumber || !employerCountry) {
    return false;
  }

  if (employerCountry.code !== 'FR') {
    return false;
  }

  // If we find a corresponding regExp that doesn't match SIREN
  if (
    getSirenRegexPattern().find(customValidity => {
      return !sirenNumber?.match(customValidity.regexp as string);
    })
  ) {
    return false;
  }

  if (sirenNumber.at(0) === '0') {
    return false;
  }

  // If we find a corresponding regExp that doesn't match APE
  if (!checkApeNumbner(apeNumber)) {
    return false;
  }
  return true;
};

const checkDataForEmployedWorkerOrOtherJob = (
  state: SituationProfessionnelle,
  person: 'borrower' | 'coBorrower',
) => {
  const { typeContrat, apeNumber, employerCountry } = state;

  if (employerCountry?.code !== 'FR') {
    return true;
  }
  if (!checkContratType(typeContrat)) {
    return false;
  }
  if (!checkApeNumbner(apeNumber)) {
    return false;
  }
  return true;
};

const checkIfProfessionDatasAreOk = (
  state: SituationProfessionnelle,
  person: 'borrower' | 'coBorrower',
): boolean => {
  const { profession, adresse, currentJobOrSituationStartingDate, employerCountry } =
    state;
  if (isUnEmployed(profession)) {
    // si non-salarié, les autres champs sont cachés
    return true;
  }
  if (!profession || profession.code === 'err') {
    return false;
  }

  if (
    !checkCurrentJobOrSituationStartingDate(currentJobOrSituationStartingDate, person)
  ) {
    return false;
  }
  if (!employerCountry || !isCountryOkay(employerCountry)) {
    return false;
  }

  if (employerCountry.code === 'FR' && (!adresse || !checkAdress(adresse))) {
    return false;
  }

  if (isSelfEmployedWorker(profession)) {
    if (!checkDataForSelfEmployedWorker(state)) {
      return false;
    }
  }
  if (isEmployedWorker(profession) || isOtherJob(profession)) {
    if (!checkDataForEmployedWorkerOrOtherJob(state, person)) {
      return false;
    }
  }

  return true;
};

export const checkIfDatasAreOk = (state: SituationProfessionnelleState): boolean => {
  const { borrower, hasCoBorrower, coBorrower } = state;
  if (hasCoBorrower) {
    return (
      checkIfProfessionDatasAreOk(borrower, 'borrower') &&
      checkIfProfessionDatasAreOk(coBorrower, 'coBorrower')
    );
  }
  return checkIfProfessionDatasAreOk(borrower, 'borrower');
};
export const situationProfessionnelleReducer = (
  state: SituationProfessionnelleState,
  action: SituationProfessionnelleAction,
): SituationProfessionnelleState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setProfession': {
      if (action.payload.person === 'borrower') {
        newState.borrower.profession = action.payload.value;
      } else {
        newState.coBorrower.profession = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setTypeContrat': {
      if (action.payload.person === 'borrower') {
        newState.borrower.typeContrat = action.payload.value;
      } else {
        newState.coBorrower.typeContrat = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setCurrentJobOrSituationStartingDate': {
      if (action.payload.person === 'borrower') {
        newState.borrower.currentJobOrSituationStartingDate = action.payload.value;
      } else {
        newState.coBorrower.currentJobOrSituationStartingDate = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setSirenNumber': {
      if (action.payload.person === 'borrower') {
        newState.borrower.sirenNumber = action.payload.value;
      } else {
        newState.coBorrower.sirenNumber = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setApeNumber': {
      if (action.payload.person === 'borrower') {
        newState.borrower.apeNumber = action.payload.value;
      } else {
        newState.coBorrower.apeNumber = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setEmployerCountry': {
      if (action.payload.person === 'borrower') {
        newState.borrower.employerCountry = action.payload.value;
      } else {
        newState.coBorrower.employerCountry = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setEmployerZipCode': {
      if (action.payload.person === 'borrower') {
        if (!newState.borrower.adresse?.codePostal) newState.borrower.adresse = {};
        newState.borrower.adresse.codePostal = action.payload.value;
      } else {
        if (!newState.coBorrower.adresse?.codePostal) newState.coBorrower.adresse = {};
        newState.coBorrower.adresse.codePostal = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setEmployerCity': {
      if (action.payload.person === 'borrower') {
        if (!newState.borrower.adresse) newState.borrower.adresse = {};
        newState.borrower.adresse.city = action.payload.value;
      } else {
        if (!newState.coBorrower.adresse) newState.coBorrower.adresse = {};
        newState.coBorrower.adresse.city = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setHasCoBorrower': {
      newState.hasCoBorrower = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    default:
      return state;
  }
};
