import React from 'react';
import ReactDOM from 'react-dom';
import { StyledModalContent, StyledModalWrapper } from './style';

// Disclaimer: ce modal est à 95% équivalent au composant <Modal/>. Il est créé car le composant <Modal> a des attributs css qui n'ont pas forcément nécessaires.
// Afin d'éviter de créer des régressions partout, ce modal est créé en éliminant les css non nécessaires. Dette technique: harmoniser FichageModal et Modal pour faire qu'un seul modal.

export interface ModalProps {
  children?: React.ReactNode;
  isVisible: boolean;
  width?: string;
  height?: string;
}
const FichageModal: React.FC<ModalProps> = ({ isVisible, children, width, height }) => {
  return isVisible
    ? ReactDOM.createPortal(
        <StyledModalWrapper data-testid="modal">
          <StyledModalContent
            width={width || '94rem'}
            height={height || '100%'}
            style={{ marginTop: '0' }}>
            {children}
          </StyledModalContent>
        </StyledModalWrapper>,
        document.body,
      )
    : null;
};

FichageModal.defaultProps = {
  width: '94rem',
  height: '100%',
  children: <></>,
};
export default FichageModal;
