import {
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  LocalStorageBackend,
  RedirectRequestHandler,
  RevokeTokenRequest,
  TokenResponse,
} from '@openid/appauth';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import NoHashQueryStringUtils from 'utils/NoHashQueryStringUtils';
import { decodeToken } from 'react-jwt';
import getEnvironmentVariables from 'utils/EnvironmentVariables';
import DecodedAuthToken, {
  GetAuthorizationResp,
  GetAuthorizedApaResp,
  UserInfos,
} from 'types';
import { NavigateFunction } from 'react-router';
import { consultationInstance } from './axiosInstances';

/* This class handles OIDC access token requests
to use it, simply call authorize() to first login or addAccessTokenToHeaders()
when performing a request that needs an access token. */
export default class AuthorizationService {
  private static myInstance: AuthorizationService;

  private accessToken = '';

  private expiresAt = '';

  private isRefreshingToken = false;

  /* Class is setup as a singleton, this returns the existing instance
  or creates one if none exists, this allows us to store the token in memory
  as per security recommendations and in the scope of the service only */
  private static getInstance(): AuthorizationService {
    if (!AuthorizationService.myInstance) {
      AuthorizationService.myInstance = new AuthorizationService();
    }

    return AuthorizationService.myInstance;
  }

  private static authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  );

  private static getAuthorizationServiceConfig =
    async (): Promise<AuthorizationServiceConfiguration> => {
      return AuthorizationServiceConfiguration.fetchFromIssuer(
        getEnvironmentVariables().pingDomain,
        new FetchRequestor(),
      );
    };

  /* initializes the authentication process, you need to pass it the page
  you want to navigate to once the process is over. This works by sending
  a request to the authentication server which responds by redirecting you
  to their login page and back to the specified redirect_url once login
  is successful */
  static authorize = (redirectPage: string): void => {
    sessionStorage.setItem('isUserAuthenticated', 'false');
    this.getAuthorizationServiceConfig()
      .then(AuthConfig => {
        const authRequest = new AuthorizationRequest({
          client_id: getEnvironmentVariables().pingClientId,
          redirect_uri: `${window.location.origin}${redirectPage}`,
          scope: 'openid',
          response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
          state: '',
        });

        const extras: any = {};
        if (redirectPage === '/silent-refresh') {
          extras.prompt = 'none';
          extras.usernameEditable = false;
        } else {
          extras.prompt = 'login';
        }

        authRequest.extras = extras;

        this.authorizationHandler.performAuthorizationRequest(AuthConfig, authRequest);
      })
      .catch(error => {
        console.log(error.name);
        alert(
          "Une erreur s'est produite lors de l'appel vers /openid-configuration. Veuillez contacter votre administrateur.",
        );
      });
  };

  static saveTokenInfo = async (
    tokenResponse: TokenResponse,
    onReject?: () => void,
  ): Promise<void> => {
    const instance = AuthorizationService.getInstance();

    instance.accessToken = tokenResponse.accessToken;

    if (tokenResponse.expiresIn) {
      instance.expiresAt = (
        new Date().getTime() +
        // 950 = 1000 (convert to ms) * 0.95 (tolerance margin for token lifetime)
        tokenResponse.expiresIn * 950
      ).toString();
    } else {
      // TODO : Throw error and invalidate token
      instance.expiresAt = '0';
    }

    const tokenInfos = decodeToken<DecodedAuthToken>(instance.accessToken);

    if (tokenInfos) {
      const selectedApa = sessionStorage.getItem('selectedApa');
      const currentUser = (
        JSON.parse(sessionStorage.getItem('authenticatedUser') || '{}') as UserInfos
      )?.uid;

      console.log(currentUser, tokenInfos);
      if (!currentUser || !tokenInfos.sub || currentUser === tokenInfos.sub) {
        const authzInfo = selectedApa
          ? await AuthorizationService.getAuthorization(tokenInfos.sub, selectedApa)
          : await AuthorizationService.getAuthorization(tokenInfos.sub);
        let userInfos = {} as UserInfos;
        const oldAuthInfo = this.getUserInfo();
        userInfos = { ...authzInfo.data.Resultat, uid: tokenInfos.sub };
        if (selectedApa) userInfos.numContremarque = Number(selectedApa);
        if (oldAuthInfo && oldAuthInfo.idAPADossier) {
          userInfos.idAPADossier = oldAuthInfo.idAPADossier;
        }
        try {
          sessionStorage.setItem('authenticatedUser', JSON.stringify(userInfos));
        } catch (error) {
          console.error(error);
        }
      } else return Promise.reject(new Error('different_login'));
    }

    return Promise.resolve();
  };

  static delay = async (ms: number): Promise<void> =>
    new Promise(res => setTimeout(res, ms));

  /* Adds the access token to request headers.
  This function handles the silent token refresh process
  by opening a hidden iFrame that absobs the redirection */
  static addAccessTokenToHeaders = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> => {
    if (window.location.href.indexOf('localhost') === -1) {
      const instance = AuthorizationService.getInstance();
      if (AuthorizationService.tokenExpired()) {
        console.log('Requesting new token');

        if (!instance.isRefreshingToken) {
          console.log('refreshing token');
          instance.isRefreshingToken = true;
          await instance.silentlyRefreshToken();
          console.log('done refreshing token');
          instance.isRefreshingToken = false;
        } else {
          console.log('waiting for token to refresh');
          await this.delay(10000);
          console.log('done waiting');
        }
      }

      const result: AxiosRequestConfig = config;
      result.headers.authorization = `Bearer ${
        AuthorizationService.getInstance().accessToken
      }`;
      return result;
    }

    return config;
  };

  static tokenExpired = (): boolean => {
    const instance = AuthorizationService.getInstance();
    if (Number(instance.expiresAt) < new Date().getTime()) {
      // console.log('Token has expired');
      return true;
    }
    // console.log('Token is valid');
    return false;
  };

  /* This works by opening a hidden iFrame on the /authorize route.
  The page then calls the authorize() function from this service with
  /silent-refresh as the redirect_uri. Once redirected to /silent-refresh,
  the page performs the token request and emits an event when the token 
  arrives. Upon recieving the event, this function stores the token and
  resolves, allowing addAccessTokenToHeaders() to continue its execution */
  private silentlyRefreshToken = (): Promise<void> => {
    return new Promise<void>((res, rej) => {
      const iframe = window.document.createElement('iframe');

      iframe.setAttribute('width', '0');
      iframe.setAttribute('height', '0');
      iframe.style.display = 'none';

      let iframeEventHandler: (e: MessageEvent) => void;

      const removeIframe = () => {
        if (window.document.body.contains(iframe)) {
          window.document.body.removeChild(iframe);
          window.removeEventListener('tokenArrived', iframeEventHandler, false);
        }
      };

      // eslint-disable-next-line func-names
      iframeEventHandler = async function (e: CustomEventInit<TokenResponse>) {
        // if the iframe sends back a result
        if (e.detail) {
          await AuthorizationService.saveTokenInfo(e.detail);

          // otherwise it means that the authentication session has expired
        } else {
          sessionStorage.setItem('redirect_url', window.location.pathname);
          // console.log(`saved pathname : ${window.location.pathname}`);
          AuthorizationService.authorize('/callback');
          rej(new Error('Authentication session has expired'));
        }
        window.removeEventListener('tokenArrived', iframeEventHandler, false);
        removeIframe();
        res();
      };

      // console.log('Adding token event listener');
      window.addEventListener('tokenArrived', iframeEventHandler, false);
      window.document.body.appendChild(iframe);
      iframe.setAttribute('src', `${window.location.origin}/authorize`);

      // eslint-disable-next-line
      const timeoutSetTimeoutId = setTimeout(() => {
        window.removeEventListener('tokenArrived', iframeEventHandler, false);
        res();
        removeIframe();
      }, 10000);
    });
  };

  static revokeToken = (): void => {
    this.getAuthorizationServiceConfig().then(authConfig => {
      // console.log(`token : ${AuthorizationService.getInstance().accessToken}`);
      const enValues = getEnvironmentVariables();
      const revokeTokenRequest = new RevokeTokenRequest({
        token: AuthorizationService.getInstance().accessToken,
        client_id: enValues.pingClientId,
        client_secret: enValues.pingClientSecret,
      });

      const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
      tokenHandler.performRevokeTokenRequest(authConfig, revokeTokenRequest).then(() => {
        // console.log('Token revoked');
      });
    });
  };

  static performPingEndSessionRequest = (): void => {
    const enValues = getEnvironmentVariables();
    // const data = {
    //   id_token_hint: enValues.pingClientId,
    //   post_logout_redirect_uri: window.location.origin,
    //   client_secret: enValues.pingClientSecret,
    // };
    axios.get(`${enValues.pingDomain}/idp/startSLO.ping`);
  };

  static getUserInfoRequest = async (): Promise<AxiosResponse<any>> => {
    const config: AxiosRequestConfig = { headers: {} };
    const headersWithToken = await this.addAccessTokenToHeaders(config);
    return axios.get(
      `${getEnvironmentVariables().pingDomain}/idp/userinfo.openid`,
      headersWithToken,
    );
  };

  static getAuthorization = (
    userId: string,
    adheNum?: string,
  ): Promise<AxiosResponse<GetAuthorizationResp>> => {
    let url = `selDetlDonAppo?userId=${userId}&contextApp=EBRO`;

    if (adheNum) url = `${url}&adheNum=${adheNum}`;

    return consultationInstance.get(url);
  };

  static getUserInfo = (): UserInfos | null => {
    const authenticatedUser = sessionStorage.getItem('authenticatedUser');
    if (authenticatedUser) {
      const userObject: UserInfos = JSON.parse(authenticatedUser);
      return userObject;
    }
    return null;
  };

  static getAuthorizedApas = (): Promise<AxiosResponse<GetAuthorizedApaResp>> => {
    return consultationInstance.get(
      `selListApaLienCou?userId=${this.getUserInfo()?.uid}&contextApp=EBRO`,
    );
  };

  static disconnect = (navigate: NavigateFunction): void => {
    const envVariables = sessionStorage.getItem('envVariables');
    sessionStorage.clear();
    sessionStorage.setItem('envVariables', envVariables || '');
    navigate('/');
  };
}
