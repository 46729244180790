import { SessionStorageKeys } from 'types';
import { getExistingStorageByKey } from 'utils/storage';
import { TransmissionPiecesAction, TransmissionPiecesState } from './types';

export const getTransmissionPiecesInitialState = (
  storageKey: SessionStorageKeys,
): TransmissionPiecesState => {
  const defaultValues = {
    guarantee: undefined,
    chemises: [],
    isDataOk: false,
  };
  return getExistingStorageByKey(storageKey, defaultValues) as TransmissionPiecesState;
};

const checkDataIsOk = (state: TransmissionPiecesState) => {
  return state.chemises.length > 0 || false;
};

export const transmissionPiecesReducer = (
  state: TransmissionPiecesState,
  action: TransmissionPiecesAction,
): TransmissionPiecesState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setAddPieces':
      newState.chemises.push(action.payload.value);
      newState.isDataOk = checkDataIsOk(newState);
      return newState;
    case 'setDeletePieces': {
      const newChemises = state.chemises.map(item => {
        if (item.id === action.payload.chemiseId) {
          const filteredFiles = item.files.filter(el => el.id !== action.payload.fileId);
          return { ...item, files: filteredFiles };
        }
        return item;
      });
      newState.chemises = newChemises;
      newState.isDataOk = checkDataIsOk(newState);
      return newState;
    }
    default:
      return state;
  }
};
