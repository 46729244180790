import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';

interface PTIAInsuranceProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  person: 'borrower' | 'coBorrower';
  isSG: boolean;
  isDisabled?: boolean;
}

const PTIAInsurance: React.FC<PTIAInsuranceProps> = ({
  person,
  state,
  dispatch,
  isSG,
  isDisabled,
}) => {
  const isPTIAChecked = (): boolean | undefined => {
    if (person === 'borrower') {
      return state.borrowerInsurance.ptiaInsuranceChecked || undefined;
    }
    return state.coBorrowerInsurance.ptiaInsuranceChecked || undefined;
  };

  const getQuotite = () => {
    const currentInsurance =
      person === 'borrower' ? state.borrowerInsurance : state.coBorrowerInsurance;

    const value = currentInsurance.deathInsuranceRate;
    if (value && isPTIAChecked()) {
      return `${value}%`;
    }
    return '0%';
  };

  const uncheckITTAndJobLoss = () => {
    dispatch({
      type: 'setIttIptInsuranceChecked',
      payload: { person, value: false },
    });
    dispatch({
      type: 'setJobLossInsuranceChecked',
      payload: { person, value: false },
    });
  };

  const handleOnCheckChange = (e: Event) => {
    if (isDisabled) {
      return;
    }
    if ((e as CustomEvent)?.detail?.value) {
      dispatch({
        type: 'setPtiaInsuranceChecked',
        payload: { person, value: true },
      });
      if (isSG) {
        dispatch({
          type: 'setIttIptInsuranceChecked',
          payload: { person, value: true },
        });
      }
    } else {
      dispatch({
        type: 'setPtiaInsuranceChecked',
        payload: { person, value: false },
      });
      if (isSG) {
        dispatch({
          type: 'setIttIptInsuranceChecked',
          payload: { person, value: false },
        });
      }
      uncheckITTAndJobLoss();
    }
  };

  return (
    <div style={{ marginTop: '4.5rem', display: 'flex', alignContent: 'center' }}>
      <BaseCheckbox
        disabled={isDisabled}
        checked={isPTIAChecked()}
        onCheckedChanged={handleOnCheckChange}
      />
      <span
        style={{
          marginLeft: '0.8rem',
          color: isDisabled ? '#B1B5B9' : '#4B4F54',
        }}>
        {`Garantie PTIA ${getQuotite()}`}
      </span>
    </div>
  );
};

export default PTIAInsurance;
