import TextButton from 'components/buttons/textButton/TextButton';
import ErrorMessageArea from 'components/errorMessage/ErrorMessage';
import ExpandableCard from 'components/expandableCard/ExpandableCard';
import Space from 'components/space/Space';
import Tag from 'components/tag/Tag';
import StepTitles from 'components/titles/StepTitles';
import TotalInfoBubble from 'components/totalInfoBubble/TotalInfoBubble';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import React from 'react';
import {
  CurrentLoanAction,
  Loan,
  OperationAction,
  OperationState,
} from 'reducers/operationClient/types';
import { displayNumber, formatNumberByThousand } from 'utils/commun';
import { getDateFromInput } from 'utils/DateUtils';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../messages';
import {
  StyledButtonSpacer,
  StyledCardContentTile,
  StyledCardData,
  StyledCardLabel,
  StyledStandardFlexContainer,
  StyledTotalInfoBubbleData,
  StyledTotalInfoBubbleText,
} from '../style';

interface LoanFormProps {
  operationReducerDispatch: React.Dispatch<OperationAction>;
  currentLoanReducerDispatch: React.Dispatch<CurrentLoanAction>;
  currentLoanState: Loan;
  state: OperationState;
  setModalVisibility: (visibility: boolean) => void;
  setIsEdited: (isEdited: boolean) => void;
  checkInputs: boolean;
}

const LoanForm: React.FC<LoanFormProps> = ({
  operationReducerDispatch,
  currentLoanReducerDispatch,
  currentLoanState,
  state,
  setModalVisibility,
  checkInputs,
  setIsEdited,
}) => {
  let currentLoan = { ...currentLoanState };

  const onCurrentLoanChange = () => {
    currentLoanReducerDispatch({
      type: 'setCurrentLoan',
      payload: currentLoan,
    });
  };

  const onLoanCardEdit = (loanCode: number) => {
    const result = state.loanList.find(item => item.code === loanCode);
    if (result) {
      currentLoan = result;
    }
    onCurrentLoanChange();
    setModalVisibility(true);
    setIsEdited(true);
  };

  const onLoanCardDelete = (index: number) => {
    sessionStorage.removeItem('loanAdditionalInformation');
    state.loanList.splice(index, 1);

    operationReducerDispatch({
      type: 'setLoanList',
      payload: state.loanList,
    });
  };

  const renderRestructureTag = (loan: Loan) => {
    return loan.restructure ? (
      <>
        <Tag accentColor={loan.buyBack ? '#28833C' : '#B1B5B9'} label="Restructuration" />
        <Space marginBottom="8px" />
      </>
    ) : (
      <></>
    );
  };

  const renderMortgageTag = (loan: Loan) => {
    return loan.mortgage ? (
      <>
        <Tag
          accentColor={loan.buyBack ? '#28833C' : '#B1B5B9'}
          label="Inscription hypothecaire"
        />
        <Space marginBottom="8px" />
      </>
    ) : (
      <></>
    );
  };

  const determineLoanCardAccentColor = (loan: Loan): string => {
    if (!loan.isDataOk) return '#E78A08';
    if (!loan.buyBack) return '#B1B5B9';
    return '#28833C';
  };

  const renderLoanCard = (index: number, loan: Loan): React.ReactFragment => {
    return (
      <React.Fragment key={index}>
        <ExpandableCard
          accentColor={determineLoanCardAccentColor(loan)}
          type="Loan"
          index={index}
          onEdit={() => {
            onLoanCardEdit(loan.code);
          }}
          onDelete={onLoanCardDelete}
          collapsedContent={[
            loan.loanStartDate ? (
              <>
                <StyledCardContentTile>
                  <StyledCardLabel>{messages.LOAN_FORM.LOAN_START_DATE}</StyledCardLabel>
                  <StyledCardData>
                    {loan.loanStartDate
                      ? getDateFromInput(loan.loanStartDate).toLocaleDateString('fr-FR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                      : 'Invalid date'}
                  </StyledCardData>
                </StyledCardContentTile>
              </>
            ) : null,

            loan.totalLoanDuration ? (
              <>
                <StyledCardContentTile>
                  <StyledCardLabel>{messages.LOAN_FORM.LOAN_DURATION}</StyledCardLabel>
                  <StyledCardData>{loan.totalLoanDuration} mois</StyledCardData>
                </StyledCardContentTile>
              </>
            ) : null,

            loan.initialLoanCapital ? (
              <>
                <StyledCardContentTile>
                  <StyledCardLabel>
                    {messages.LOAN_FORM.LOAN_INITIAL_CAPITAL}
                  </StyledCardLabel>
                  <StyledCardData>
                    {loan.initialLoanCapital
                      ? formatNumberByThousand(loan.initialLoanCapital.toString())
                      : ''}{' '}
                    €
                  </StyledCardData>
                </StyledCardContentTile>
              </>
            ) : null,

            <>
              <StyledCardContentTile>
                {renderRestructureTag(loan)}
                {renderMortgageTag(loan)}
              </StyledCardContentTile>
            </>,
          ]}>
          <StyledCardContentTile>
            <StyledCardLabel>
              {messages.LOAN_FORM.LOAN_CREDIT_ORGANISATION}
            </StyledCardLabel>
            <StyledCardData>{loan.loaner?.libelle}</StyledCardData>
          </StyledCardContentTile>

          <StyledCardContentTile>
            <StyledCardLabel>{messages.LOAN_FORM.LOAN_TYPE}</StyledCardLabel>
            <StyledCardData>{loan.creditType?.libelle}</StyledCardData>
          </StyledCardContentTile>

          <StyledCardContentTile>
            <StyledCardLabel>{messages.LOAN_FORM.LOAN_MENSUALITY}</StyledCardLabel>
            <StyledCardData>
              {formatNumberByThousand(
                loan.monthlyPayment !== undefined ? loan.monthlyPayment : '',
              )}{' '}
              € / mois
            </StyledCardData>
          </StyledCardContentTile>

          {loan.balance ? (
            <StyledCardContentTile>
              <StyledCardLabel>{messages.LOAN_FORM.LOAN_REMAINING}</StyledCardLabel>
              <StyledCardData>
                {Number(loan.balance.replace(/ /g, '')).toLocaleString()} €
              </StyledCardData>
            </StyledCardContentTile>
          ) : (
            <></>
          )}
        </ExpandableCard>
        <Space marginBottom="2.4rem" />
      </React.Fragment>
    );
  };

  const renderBuybackLoans = () => {
    return state.loanList.map((item, index) => {
      if (item.buyBack) {
        return renderLoanCard(index, item);
      }
      return null;
    });
  };

  const renderKeepLoans = () => {
    return state.loanList?.map((item, index) => {
      if (!item.buyBack) {
        return renderLoanCard(index, item);
      }
      return null;
    });
  };

  return (
    <>
      <StepTitles title={messages.MAIN_TITLE_1} subTitle={messages.SUB_TITLE_1} />
      {(!state.loanList || state.loanList.length === 0) && checkInputs ? (
        <>
          <ErrorMessageArea message={messagesCheckInput.LOAN_REQUIRED} />
          <Space marginTop="3.2rem" />
        </>
      ) : (
        <></>
      )}
      <TextButton
        label="Ajouter un nouveau prêt"
        onClick={() => {
          setModalVisibility(true);
          setIsEdited(false);
        }}>
        <StyledButtonSpacer />
        <PlusCircleIcon />
      </TextButton>

      <Space marginBottom="32px" />

      {state.loanList.some(loan => {
        return loan.buyBack;
      }) ? (
        <StyledCardData>{messages.LOAN_FORM.LOAN_REBUY_INFORMATION}</StyledCardData>
      ) : (
        <></>
      )}

      <Space marginBottom="16px" />

      {renderBuybackLoans()}

      <Space marginBottom="16px" />

      <TotalInfoBubble
        label={messages.TOTAL_INFO_BUBBLE_TITLE_1}
        total={
          state.buybackLoansMonthlyPaymentTotal
            ? state.buybackLoansMonthlyPaymentTotal
            : 0
        }>
        <StyledStandardFlexContainer>
          <StyledTotalInfoBubbleText>
            {messages.LOAN_FORM.LOAN_TOTAL_MENSUALITY}
          </StyledTotalInfoBubbleText>
          <StyledTotalInfoBubbleData>
            {state.buybackLoansMonthlyPaymentTotal
              ? displayNumber(state.buybackLoansMonthlyPaymentTotal)
              : 0}
            €
          </StyledTotalInfoBubbleData>
        </StyledStandardFlexContainer>
        <StyledStandardFlexContainer>
          <StyledTotalInfoBubbleText>
            {messages.LOAN_FORM.LOAN_TOTAL_REMAINING}
          </StyledTotalInfoBubbleText>
          <StyledTotalInfoBubbleData>
            {state.buybackLoansTotal ? displayNumber(state.buybackLoansTotal) : 0}€
          </StyledTotalInfoBubbleData>
        </StyledStandardFlexContainer>
      </TotalInfoBubble>

      {state.loanList.some(loan => {
        return !loan.buyBack;
      }) ? (
        <>
          <Space marginBottom="56px" />
          <StyledCardData>{messages.LOAN_FORM.LOAN_KEEPING_INFORMATION}</StyledCardData>
        </>
      ) : (
        <></>
      )}

      <Space marginBottom="16px" />

      {renderKeepLoans()}

      <Space marginBottom="16px" />

      <TotalInfoBubble
        label={messages.TOTAL_INFO_BUBBLE_TITLE_2}
        total={
          state.keepLoansMonthlyPaymentTotal ? state.keepLoansMonthlyPaymentTotal : 0
        }>
        <StyledStandardFlexContainer>
          <StyledTotalInfoBubbleText>
            {messages.LOAN_FORM.LOAN_TOTAL_MENSUALITY}
          </StyledTotalInfoBubbleText>
          <StyledTotalInfoBubbleData>
            {state.keepLoansMonthlyPaymentTotal
              ? displayNumber(state.keepLoansMonthlyPaymentTotal)
              : 0}
            €
          </StyledTotalInfoBubbleData>
        </StyledStandardFlexContainer>
        <StyledStandardFlexContainer>
          <StyledTotalInfoBubbleText>
            {messages.LOAN_FORM.LOAN_TOTAL_REMAINING}
          </StyledTotalInfoBubbleText>
          <StyledTotalInfoBubbleData>
            {state.keepLoansTotal ? displayNumber(state.keepLoansTotal) : 0}€
          </StyledTotalInfoBubbleData>
        </StyledStandardFlexContainer>
      </TotalInfoBubble>
    </>
  );
};

export default LoanForm;
