import { CodeLibelle, SessionStorageKeys } from 'types';
import { getDateFromInput, isDateIntheFuture } from 'utils/DateUtils';
import { checkMaritalSituation, ValidationRules } from 'utils/InputValidation';
import { getExistingStorageByKey } from 'utils/storage';
import { getBirthYearOlderBorrower } from 'utils/commun';
import { ChildrenBirthYear, SituationFoyerAction, SituationFoyerState } from './types';

const firstDayOfMonth = '01/';
const firstMonth = '01/';

const zipCode = new RegExp(ValidationRules.zipCode);
const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
const notEmpty = new RegExp(ValidationRules.notEmpty);
const yearRegexp = new RegExp(ValidationRules.yearRegexp);

export const isZipCodeOkay = (zipCodeParam: string): boolean => {
  if (zipCodeParam === undefined) return false;
  if (!zipCode.test(zipCodeParam)) return false;
  if (!notEmpty.test(zipCodeParam)) return false;
  if (!onlyNumbers.test(zipCodeParam)) return false;
  if (!zipCodeNoZero.test(zipCodeParam)) return false;

  return true;
};

export const isAddressCityOkay = (addressCityParam: CodeLibelle | undefined): boolean => {
  if (addressCityParam === undefined || addressCityParam.code === '') return false;
  if (!notEmpty.test(addressCityParam.libelle)) return false;
  if (addressCityParam.code === 'err') return false;
  return true;
};

const isDateOkay = (year: number | undefined): boolean => {
  const olderBorrower = getBirthYearOlderBorrower();
  if (year === undefined) return false;
  if (!yearRegexp.test(year.toString())) return false;
  if (!isDateIntheFuture(getDateFromInput(`${firstDayOfMonth}${firstMonth}${year}`)))
    return false;
  if (olderBorrower > year) return false;
  return true;
};

const isBirthDateOkay = (
  year: number | undefined,
  hasCoBorrower: boolean | undefined,
): boolean => {
  const olderBorrower = getBirthYearOlderBorrower();

  if (year === undefined) return false;
  if (!yearRegexp.test(year.toString())) return false;
  if (!isDateIntheFuture(getDateFromInput(`${firstDayOfMonth}${firstMonth}${year}`)))
    return false;
  if (year <= 1970) return false;
  if (olderBorrower > year) return false;
  return true;
};

const checkChildrenInCharge = (
  childrenInChargeBirthYear: ChildrenBirthYear[] | undefined,
  hasCoBorrower: boolean | undefined,
): boolean => {
  if (!childrenInChargeBirthYear) return false;
  if (childrenInChargeBirthYear.length === 0) return false;
  if (childrenInChargeBirthYear.length > 10) return false;
  let areChildrenInChargeBirthYearOkay = true;

  childrenInChargeBirthYear.map(child => {
    if (!isBirthDateOkay(Number(child.birthYear), hasCoBorrower))
      areChildrenInChargeBirthYearOkay = false;
    return areChildrenInChargeBirthYearOkay;
  });

  return areChildrenInChargeBirthYearOkay;
};

export const getSituationFoyerInitialState = (
  storageKey: SessionStorageKeys,
): SituationFoyerState => {
  const situationFoyerDefaultValues = {
    addressZipCode: '',
    addressCity: undefined,
    maritalStatus: undefined,
    coBorrowerMaritalStatus: undefined,
    coBorrowerMaritalStatusIsSameAsBorrower: false,
    housingStatus: undefined,
    startingYearLivingInCurrentHouse: undefined,
    numberOfPersonInCurrentHouse: undefined,
    childrenInCharge: false,
    childrenInChargeBirthYear: [],
    isDataOk: false,
  };
  return getExistingStorageByKey(
    storageKey,
    situationFoyerDefaultValues,
  ) as SituationFoyerState;
};

export const checkIfDatasAreOk = (state: SituationFoyerState): boolean => {
  const {
    addressZipCode,
    addressCity,
    maritalStatus,
    coBorrowerMaritalStatus,
    coBorrowerMaritalStatusIsSameAsBorrower,
    housingStatus,
    startingYearLivingInCurrentHouse,
    numberOfPersonInCurrentHouse,
    childrenInCharge,
    childrenInChargeBirthYear,
    hasCoBorrower,
  } = state;
  if (
    hasCoBorrower &&
    !coBorrowerMaritalStatusIsSameAsBorrower &&
    (!coBorrowerMaritalStatus ||
      !checkMaritalSituation(maritalStatus, coBorrowerMaritalStatus))
  ) {
    return false;
  }
  if (
    isZipCodeOkay(addressZipCode) &&
    isAddressCityOkay(addressCity) &&
    maritalStatus &&
    housingStatus &&
    isDateOkay(startingYearLivingInCurrentHouse) &&
    numberOfPersonInCurrentHouse
  ) {
    const actualNbPersonInCurrentHouse =
      1 +
      (hasCoBorrower ? 1 : 0) +
      (childrenInChargeBirthYear && childrenInCharge
        ? childrenInChargeBirthYear.length
        : 0);

    if (actualNbPersonInCurrentHouse > Number(numberOfPersonInCurrentHouse)) {
      return false;
    }

    if (
      !hasCoBorrower &&
      (maritalStatus.toString() === 'M' || maritalStatus.toString() === 'P')
    ) {
      return false;
    }

    if (
      childrenInCharge &&
      !checkChildrenInCharge(childrenInChargeBirthYear, hasCoBorrower)
    ) {
      return false;
    }
    return true;
  }
  return false;
};
export const situationFoyerReducer = (
  state: SituationFoyerState,
  action: SituationFoyerAction,
): SituationFoyerState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setAddressZipCode':
      newState.addressZipCode = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setAddressCity':
      newState.addressCity = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setMaritalStatus':
      newState.maritalStatus = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasCoBorrower': {
      newState.hasCoBorrower = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setCoBorrowerMaritalStatus':
      newState.coBorrowerMaritalStatus = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setCoBorrowerMaritalStatusIsSameAsBorrower':
      newState.coBorrowerMaritalStatusIsSameAsBorrower = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHousingStatus':
      newState.housingStatus = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setStartingYearLivingInCurrentHouse':
      newState.startingYearLivingInCurrentHouse = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setNumberOfPersonInCurrentHouse':
      newState.numberOfPersonInCurrentHouse = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setChildrenInCharge':
      newState.childrenInCharge = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setChildrenInChargeBirthYear':
      newState.childrenInChargeBirthYear = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    default:
      return state;
  }
};
