import { SelectElementInput } from 'components/designSystem/SelectElementInput';
import Modal from 'components/modals/Modal';
import Space from 'components/space/Space';
import React, { useEffect, useReducer, useState } from 'react';
import { SelectedButton } from 'components/designSystem/SelectButton';
import CustomInput from 'components/customInput/CustomInput';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import CrossCircle from 'icons/CrossCircle';
import TextButton from 'components/buttons/textButton/TextButton';
import {
  Asset,
  CurrentAssetAction,
  OperationAction,
  OperationState,
} from 'reducers/operationClient/types';
import { CodeLibelle, City, StatusControlInput } from 'types';
import { getNotEmpty, ValidationRules } from 'utils/InputValidation';
import CloseModalConfirmation from 'components/modals/ConfirmationModals/CloseModalConfirmation';
import { formatNumberByThousand } from 'utils/commun';
import { v4 as uuidv4 } from 'uuid';
import { ACCOMODATION_TYPE_LIST } from 'utils/constante';
import {
  getSituationFoyerInitialState,
  situationFoyerReducer,
} from 'reducers/situationFoyer/situationFoyer';
import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import { getCitiesByZipCode } from 'api/referentialService';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../messages';
import {
  ShortComboInput,
  StyledButtonContainer,
  StyledModalAddLoanButton,
  StyledModalContentWrapper,
  StyledModalMainTitle,
  StyledModalRowContainer,
  StyledExtraMarginSelectedButton,
  StyledRadioButtonTitle,
  StyledRadioButtonWrapper,
  StyledRadioButtonContainer,
} from '../style';

interface AssetModalProps {
  close: () => void;
  operationReducerDispatch: React.Dispatch<OperationAction>;
  currentAssetReducerDispatch: React.Dispatch<CurrentAssetAction>;
  currentAssetState: Asset;
  state: OperationState;
  homeLoanPurposes: CodeLibelle[];
  isEdited: boolean;
}

const AssetModal: React.FC<AssetModalProps> = ({
  close,
  operationReducerDispatch,
  currentAssetReducerDispatch,
  currentAssetState,
  state,
  homeLoanPurposes,
  isEdited,
}) => {
  const [isCurrentValueFocused, setIsCurrentValueFocused] = useState<boolean>(false);
  const [statusCurrentValue, setStatusCurrentValue] = useState<StatusControlInput>();
  const [currentValue, setCurrentValue] = useState<string>(
    currentAssetState?.currentValue || '',
  );

  const [isKeptMortgagesFocused, setIsKeptMortgagesFocused] = useState<boolean>(false);
  const [statusKeptMortgages, setStatusKeptMortgages] = useState<StatusControlInput>();
  const [keptMortgages, setKeptMortgages] = useState<string>(
    currentAssetState?.keptMortagagesValue || '',
  );

  const [isInscriptionRankFocused, setisInscriptionRankFocused] =
    useState<boolean>(false);
  const [statusInscriptionRank, setStatusInscriptionRank] =
    useState<StatusControlInput>();
  const [inscriptionRank, setInscriptionRank] = useState<string>(
    currentAssetState?.inscriptionRank || '',
  );

  const [cityList, setCityList] = useState<City[]>([]);
  const [isZipCodeFocused, setIsZipCodeFocused] = useState<boolean>(false);
  const [statusZipCode, setStatusZipCode] = useState<StatusControlInput>();
  const [zipCode, setZipCode] = useState<string>(
    currentAssetState.isAssetAddressBorrowerAddress
      ? ''
      : currentAssetState?.zipCode || '',
  );

  const [isHomeLoanPurposeButtonSelected, setIsHomeLoanPurposeButtonSelected] =
    useState<boolean>(false);
  const [statusOtherUsage, setStatusOtherUsage] = useState<string>('');
  const [enableAddAssetButton, setEnableAddAssetButton] = useState<boolean>(false);
  const [remainingHomeLoanPurposes, setRemainingHomeLoanPurposes] = useState<
    CodeLibelle[]
  >([]);
  const [remainingMortgageValue, setRemainingMortgageValue] = useState<number>();
  const [displayRemainingMortgage, setDisplayRemainingMortgage] = useState<boolean>();
  const [initialAsset, setInitialAsset] = useState<Asset>({} as Asset);
  const [currentAsset, setCurrentAsset] = useState<Asset>({} as Asset);

  const [isCloseConfirmationVisible, setIsCloseConfirmationVisible] =
    useState<boolean>(false);

  const [situationFoyerState] = useReducer(
    situationFoyerReducer,
    getSituationFoyerInitialState('situationFoyer'),
  );

  const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const nonZeroNumbers = new RegExp(ValidationRules.nonZeroNumbers);
  const zipCodeRegex = new RegExp(ValidationRules.zipCode);
  const notEmpty = new RegExp(ValidationRules.notEmpty);

  const accomodationTypeList = ACCOMODATION_TYPE_LIST;

  const handleAddAssetButtonClick = () => {
    sessionStorage.removeItem('guaranteeAdditionalInformation');

    const newState = { ...state };
    const tempCurrentAsset = { ...currentAsset };

    tempCurrentAsset.code = uuidv4();

    if (tempCurrentAsset.isAssetAddressBorrowerAddress === true) {
      tempCurrentAsset.zipCode = situationFoyerState.addressZipCode;

      setCurrentAsset(tempCurrentAsset);
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: tempCurrentAsset,
      });
    }

    if (currentAsset.code < newState.assetList.length && currentAsset.code !== -1) {
      newState.assetList[currentAsset.code] = currentAsset;
    } else {
      newState.assetList.push(tempCurrentAsset);
    }
    setCurrentAsset({} as Asset);

    operationReducerDispatch({
      type: 'setAssetList',
      payload: newState.assetList,
    });
  };

  const onCurrentValueBlur = (value: string, firstCheck?: boolean) => {
    const valWithoutSpace = value.replace(/ /g, '');
    if (!notEmpty.test(valWithoutSpace)) {
      setStatusCurrentValue({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, currentValue: undefined },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, currentValue: undefined });
    } else if (!nonZeroNumbers.test(valWithoutSpace)) {
      setStatusCurrentValue({
        status: false,
        errorMessage: messagesCheckInput.NON_ZERO_NUMBERS,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, currentValue: undefined },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, currentValue: undefined });
    } else {
      setStatusCurrentValue({
        status: true,
        errorMessage: '',
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, currentValue: valWithoutSpace },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, currentValue: valWithoutSpace });
    }
  };

  const onInscriptionRankBlur = (value: string, firstCheck?: boolean) => {
    setisInscriptionRankFocused(false);
    const valWithoutSpace = value.replace(/ /g, '');

    if (!notEmpty.test(valWithoutSpace)) {
      setStatusInscriptionRank({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, inscriptionRank: undefined },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, inscriptionRank: undefined });
    } else if (!nonZeroNumbers.test(valWithoutSpace)) {
      setStatusInscriptionRank({
        status: false,
        errorMessage: messagesCheckInput.NON_ZERO_NUMBERS,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, inscriptionRank: undefined },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, inscriptionRank: undefined });
    } else {
      setStatusInscriptionRank({
        status: true,
        errorMessage: '',
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, inscriptionRank: valWithoutSpace },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, inscriptionRank: valWithoutSpace });
    }
  };

  const onKeptMortgagesBlur = (value: string, firstCheck?: boolean) => {
    setIsKeptMortgagesFocused(false);
    let remainingValue;
    const valWithoutSpace = value.replace(/ /g, '');
    if (currentAsset.currentValue && currentAsset.keptMortagagesValue) {
      remainingValue =
        Number(currentAsset.currentValue.replace(/ /g, '')) -
        Number(currentAsset.keptMortagagesValue.replace(/ /g, ''));
    }

    if (!notEmpty.test(valWithoutSpace)) {
      setStatusKeptMortgages({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, keptMortagagesValue: undefined },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, keptMortagagesValue: undefined });
    } else if (!onlyNumbers.test(valWithoutSpace)) {
      setStatusKeptMortgages({
        status: false,
        errorMessage: messagesCheckInput.ONLY_NUMBERS,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, keptMortagagesValue: undefined },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, keptMortagagesValue: undefined });
    } else if (remainingValue !== undefined && remainingValue < 0) {
      setStatusKeptMortgages({
        status: false,
        errorMessage: messagesCheckInput.ASSETS_MORTGAGE_VALUE_ERROR,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, keptMortagagesValue: valWithoutSpace },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, keptMortagagesValue: valWithoutSpace });
    } else {
      setStatusKeptMortgages({
        status: true,
        errorMessage: '',
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, keptMortagagesValue: valWithoutSpace },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, keptMortagagesValue: valWithoutSpace });
    }
  };

  const onZipCodeBlur = (value: string, firstCheck?: boolean) => {
    const valWithoutSpace = value.replace(/ /g, '');
    if (!notEmpty.test(valWithoutSpace)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, zipCode: undefined },
      });
      if (firstCheck !== true) setCurrentAsset({ ...currentAsset, zipCode: undefined });
    } else if (!onlyNumbers.test(valWithoutSpace)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_ONLY_NUMBERS,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, zipCode: undefined },
      });
      if (firstCheck !== true) setCurrentAsset({ ...currentAsset, zipCode: undefined });
    } else if (!zipCodeRegex.test(valWithoutSpace)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.INPUT_ERROR,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, zipCode: undefined },
      });
      if (firstCheck !== true) setCurrentAsset({ ...currentAsset, zipCode: undefined });
    } else if (!zipCodeNoZero.test(valWithoutSpace)) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.ZIPCODE_NO_ZERO,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, zipCode: undefined },
      });
      if (firstCheck !== true) setCurrentAsset({ ...currentAsset, zipCode: undefined });
    } else if (cityList.length === 0) {
      setStatusZipCode({
        status: false,
        errorMessage: messagesCheckInput.WRONG_ZIPCODE,
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, zipCode: undefined },
      });
      if (firstCheck !== true) setCurrentAsset({ ...currentAsset, zipCode: undefined });
    } else {
      setStatusZipCode({
        status: true,
        errorMessage: '',
      });
      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: { ...currentAsset, zipCode: valWithoutSpace },
      });
      if (firstCheck !== true)
        setCurrentAsset({ ...currentAsset, zipCode: valWithoutSpace });
    }
  };

  const isUsageAButton = (): boolean => {
    return (
      currentAsset.usage?.code === 'RP' ||
      currentAsset.usage?.code === 'RS' ||
      currentAsset.usage?.code === 'LP'
    );
  };

  const dataHasBeenModified = (): boolean => {
    return JSON.stringify(currentAsset) !== JSON.stringify(initialAsset);
  };

  const initializeAndCheck = (): void => {
    setInitialAsset(currentAssetState);
    setCurrentAsset(currentAssetState);

    if (currentAssetState?.currentValue !== undefined)
      onCurrentValueBlur(currentAssetState?.currentValue || '', true);
    if (currentAssetState?.inscriptionRank !== undefined)
      onInscriptionRankBlur(currentAssetState?.inscriptionRank || '', true);
    if (currentAssetState?.keptMortagagesValue !== undefined)
      onKeptMortgagesBlur(currentAssetState?.keptMortagagesValue || '', true);
  };

  useEffect(() => {
    if (notEmpty.test(zipCode)) {
      if (zipCode.length === 5) {
        getCitiesByZipCode(zipCode).then(cityByZipCodeList => {
          setCityList(cityByZipCodeList);
        });
      }
    }
  }, [currentAsset.zipCode]);

  useEffect(() => {
    if (currentAssetState.isDataOk) {
      return setEnableAddAssetButton(true);
    }
    return setEnableAddAssetButton(false);
  }, [currentAssetState]);

  useEffect(() => {
    if (currentAsset.usage && isUsageAButton()) {
      setIsHomeLoanPurposeButtonSelected(true);
      setStatusOtherUsage('');
    } else {
      setIsHomeLoanPurposeButtonSelected(false);
      setStatusOtherUsage('none');
    }
  }, [currentAsset.usage]);

  useEffect(() => {
    if (currentAsset.inscriptionRank === '1') {
      const tempCurrentAsset = { ...currentAsset };
      tempCurrentAsset.keptMortagagesValue = undefined;
      setCurrentAsset(tempCurrentAsset);

      currentAssetReducerDispatch({
        type: 'setCurrentAsset',
        payload: tempCurrentAsset,
      });
    }
  }, [currentAsset.inscriptionRank]);

  useEffect(() => {
    if (currentAsset.currentValue && currentAsset.keptMortagagesValue) {
      const remainingValue =
        Number(currentAsset.currentValue.replace(/ /g, '')) -
        Number(currentAsset.keptMortagagesValue.replace(/ /g, ''));
      if (remainingValue < 0) {
        setStatusKeptMortgages({
          status: false,
          errorMessage: messagesCheckInput.ASSETS_MORTGAGE_VALUE_ERROR,
        });
      } else {
        setStatusKeptMortgages({
          status: true,
          errorMessage: '',
        });
      }
      setRemainingMortgageValue(remainingValue);
      setDisplayRemainingMortgage(true);
    } else {
      setDisplayRemainingMortgage(false);
    }
  }, [currentAsset.currentValue, currentAsset.keptMortagagesValue]);

  useEffect(() => {
    if (currentAsset.code === undefined || currentAsset.code === -1) {
      setStatusCurrentValue({
        status: undefined,
      });
    } else {
      setStatusCurrentValue({
        status: true,
      });
    }

    if (currentAssetState) {
      initializeAndCheck();
    }
  }, []);

  const mainHomeLoanPurposesCode: CodeLibelle[] = [
    { code: 'RP', libelle: 'RESIDENCE PRINCIPALE' },
    { code: 'RS', libelle: 'RESIDENCE SECONDAIRE' },
    { code: 'LP', libelle: 'LOCATIF' },
  ];

  /**
   * Renvoie les "usages du bien" à l'exception des 3 principales (Résidence principale-RP, Résidence secondaire-RS, LOCATIF-LP)
   * @returns
   */
  const getOtherHomeLoanPurposes = (allCodes: CodeLibelle[]) => {
    const codesToExclude: string[] = [];
    mainHomeLoanPurposesCode.forEach(item => {
      codesToExclude.push(item.code.toLowerCase());
    });
    return allCodes.filter(item => {
      return codesToExclude.indexOf(item.code.toLowerCase()) === -1;
    });
  };

  useEffect(() => {
    setRemainingHomeLoanPurposes(getOtherHomeLoanPurposes(homeLoanPurposes));
  }, [homeLoanPurposes]);

  const renderKeptMortgagesValue = () => {
    return (
      <>
        {' '}
        <CustomInput
          name="kept-mortgages-value"
          label="Valeur des hypothèques conservées"
          type="price"
          after="€"
          inputWidth="46.4rem"
          placeholder="0"
          value={formatNumberByThousand(keptMortgages || '')}
          onFocus={() => {
            setIsKeptMortgagesFocused(true);
          }}
          onChange={newValue => {
            setIsKeptMortgagesFocused(true);
            if (!onlyNumbers.test(newValue.replaceAll(' ', ''))) return;

            setKeptMortgages(newValue.replaceAll(' ', '').slice(0, 8));

            const tempCurrentAsset = { ...currentAsset };
            tempCurrentAsset.keptMortagagesValue = newValue
              .replaceAll(' ', '')
              .slice(0, 8);
            setCurrentAsset(tempCurrentAsset);

            currentAssetReducerDispatch({
              type: 'setCurrentAsset',
              payload: tempCurrentAsset,
            });
          }}
          onBlur={newValue => {
            onKeptMortgagesBlur(newValue);
          }}
          isFocused={isKeptMortgagesFocused}
          isValid={statusKeptMortgages?.status}
          error={statusKeptMortgages?.errorMessage}
        />
        {displayRemainingMortgage &&
        remainingMortgageValue !== undefined &&
        remainingMortgageValue >= 0 ? (
          <>
            <Space marginBottom="1.6rem" />
            <span
              style={{
                color: '#4B4F54',
              }}>{`Valeur libre d'hypothèque retenue : ${formatNumberByThousand(
              remainingMortgageValue.toString(),
            )}€`}</span>
          </>
        ) : null}
      </>
    );
  };

  const renderAddress = () => {
    return (
      <>
        <CustomInput
          name="zip-code"
          label="Code postal"
          placeholder="ex: 75001"
          onChange={newValue => {
            setIsZipCodeFocused(true);
            if (!onlyNumbers.test(newValue)) return;

            setZipCode(newValue.slice(0, 5));

            const tempCurrentAsset = { ...currentAsset };
            tempCurrentAsset.zipCode = newValue.slice(0, 5);

            setCurrentAsset(tempCurrentAsset);

            currentAssetReducerDispatch({
              type: 'setCurrentAsset',
              payload: tempCurrentAsset,
            });
          }}
          onBlur={onZipCodeBlur}
          onFocus={() => {
            setIsZipCodeFocused(true);
          }}
          isValid={statusZipCode?.status}
          isFocused={isZipCodeFocused}
          value={zipCode || ''}
          error={statusZipCode?.errorMessage}
        />
      </>
    );
  };

  return (
    <Modal
      isVisible
      onClose={() => {
        setIsCloseConfirmationVisible(true);
      }}>
      <StyledModalContentWrapper>
        <StyledModalMainTitle>{messages.MODAL_ASSET_PATRI_INFO}</StyledModalMainTitle>
        <div className="element-in-modal" role="button" tabIndex={0}>
          {' '}
        </div>

        <Space marginBottom="2.4rem" />
        <SelectElementInput
          class="credit-type"
          label="Type de bien"
          data-id="asset_type"
          error-message={messagesCheckInput.MODAL_CREDIT_NOT_SELECTED}
          value={currentAsset.type?.code}
          onClick={e => {
            const tempCurrentAsset = { ...currentAssetState };
            const code = (e.target as HTMLSelectElement).value as string;
            tempCurrentAsset.type = accomodationTypeList.get(code);
            setCurrentAsset(tempCurrentAsset);

            currentAssetReducerDispatch({
              type: 'setCurrentAsset',
              payload: tempCurrentAsset,
            });
          }}>
          <StyledExtraMarginSelectedButton
            tabIndex={0}
            value="MA"
            secondary
            onKeyDownCapture={e => {
              if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                e.preventDefault();
                const tempCurrentAsset = { ...currentAssetState };
                const code = (e.target as HTMLSelectElement).value as string;
                tempCurrentAsset.type = accomodationTypeList.get(code);
                setCurrentAsset(tempCurrentAsset);

                currentAssetReducerDispatch({
                  type: 'setCurrentAsset',
                  payload: tempCurrentAsset,
                });
              }
            }}>
            {(accomodationTypeList.get('MA') as CodeLibelle).libelle}
          </StyledExtraMarginSelectedButton>
          <SelectedButton
            tabIndex={0}
            value="AP"
            secondary
            onKeyDownCapture={e => {
              if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                e.preventDefault();
                const tempCurrentAsset = { ...currentAssetState };
                const code = (e.target as HTMLSelectElement).value as string;
                tempCurrentAsset.type = accomodationTypeList.get(code);
                setCurrentAsset(tempCurrentAsset);

                currentAssetReducerDispatch({
                  type: 'setCurrentAsset',
                  payload: tempCurrentAsset,
                });
              }
            }}>
            {(accomodationTypeList.get('AP') as CodeLibelle).libelle}
          </SelectedButton>
        </SelectElementInput>

        <Space marginBottom="2.4rem" />

        {homeLoanPurposes.length > 1 && (
          <>
            <SelectElementInput
              class="credit-type"
              label="Usage du bien"
              data-id="usage"
              error-message={messagesCheckInput.MODAL_CREDIT_NOT_SELECTED}
              value={currentAsset.usage?.code}
              onSelectedElementChange={e => {
                const tempCurrentAsset = { ...currentAssetState };
                const code = (e.target as HTMLSelectElement).value;

                tempCurrentAsset.usage = homeLoanPurposes.find(purpose => {
                  return purpose.code === code;
                });

                setCurrentAsset(tempCurrentAsset);

                currentAssetReducerDispatch({
                  type: 'setCurrentAsset',
                  payload: tempCurrentAsset,
                });
              }}>
              <StyledExtraMarginSelectedButton
                tabIndex={0}
                value="RP"
                secondary
                onKeyDownCapture={e => {
                  if (
                    e.code === 'Enter' ||
                    e.code === 'Space' ||
                    e.code === 'NumpadEnter'
                  ) {
                    e.preventDefault();
                    const tempCurrentAsset = { ...currentAssetState };
                    const code = (e.target as HTMLSelectElement).value as string;
                    tempCurrentAsset.usage = homeLoanPurposes.find(purpose => {
                      return purpose.code === code;
                    });

                    setCurrentAsset(tempCurrentAsset);

                    currentAssetReducerDispatch({
                      type: 'setCurrentAsset',
                      payload: tempCurrentAsset,
                    });
                  }
                }}>
                {messages.MODAL_ASSET_RESIDENCE_PRINCIPALE}
              </StyledExtraMarginSelectedButton>
              <StyledExtraMarginSelectedButton
                tabIndex={0}
                value="LP"
                secondary
                onKeyDownCapture={e => {
                  if (
                    e.code === 'Enter' ||
                    e.code === 'Space' ||
                    e.code === 'NumpadEnter'
                  ) {
                    e.preventDefault();
                    const tempCurrentAsset = { ...currentAssetState };
                    const code = (e.target as HTMLSelectElement).value as string;
                    tempCurrentAsset.usage = homeLoanPurposes.find(purpose => {
                      return purpose.code === code;
                    });

                    setCurrentAsset(tempCurrentAsset);

                    currentAssetReducerDispatch({
                      type: 'setCurrentAsset',
                      payload: tempCurrentAsset,
                    });
                  }
                }}>
                {messages.MODAL_ASSET_LOCATIF}
              </StyledExtraMarginSelectedButton>
              <SelectedButton
                tabIndex={0}
                value="RS"
                secondary
                onKeyDownCapture={e => {
                  if (
                    e.code === 'Enter' ||
                    e.code === 'Space' ||
                    e.code === 'NumpadEnter'
                  ) {
                    e.preventDefault();
                    const tempCurrentAsset = { ...currentAssetState };
                    const code = (e.target as HTMLSelectElement).value as string;
                    tempCurrentAsset.usage = homeLoanPurposes.find(purpose => {
                      return purpose.code === code;
                    });

                    setCurrentAsset(tempCurrentAsset);

                    currentAssetReducerDispatch({
                      type: 'setCurrentAsset',
                      payload: tempCurrentAsset,
                    });
                  }
                }}>
                {messages.MODAL_ASSET_RESIDENCE_SECONDAIRE}
              </SelectedButton>
            </SelectElementInput>
            <Space marginBottom="1.6rem" />
            <StyledModalRowContainer>
              <ShortComboInput
                list-on-open
                class="combo-input show-input-ds-validation"
                shadow
                tabIndex={-1}
                align-items="left"
                items={JSON.stringify(remainingHomeLoanPurposes)}
                onInputChange={e => {
                  const tempCurrentAsset = { ...currentAsset };
                  const libelle = (e.target as HTMLSelectElement).value;
                  tempCurrentAsset.usage = homeLoanPurposes.find(purpose => {
                    return purpose.libelle === libelle;
                  });

                  setCurrentAsset(tempCurrentAsset);

                  currentAssetReducerDispatch({
                    type: 'setCurrentAsset',
                    payload: tempCurrentAsset,
                  });
                }}
                field="libelle"
                placeholder="ex: Locatif résidence secondaire"
                value={
                  isHomeLoanPurposeButtonSelected ? '' : currentAsset.usage?.libelle || ''
                }
                {...(statusOtherUsage ? { status: 'none' } : {})}
                customValidityPattern={JSON.stringify(getNotEmpty(`Usage du bien`))}
              />
            </StyledModalRowContainer>
          </>
        )}

        <Space marginBottom="2.4rem" />

        <CustomInput
          name="current-value"
          label="Valeur actuelle"
          type="price"
          after="€"
          placeholder="0"
          inputWidth="46.4rem"
          value={formatNumberByThousand(currentValue || '')}
          onChange={newValue => {
            setIsCurrentValueFocused(true);
            if (!onlyNumbers.test(newValue.replaceAll(' ', ''))) return;

            setCurrentValue(newValue.replaceAll(' ', '').slice(0, 8));

            const tempCurrentAsset = { ...currentAsset };
            tempCurrentAsset.currentValue = newValue.replaceAll(' ', '').slice(0, 8);
            setCurrentAsset(tempCurrentAsset);

            currentAssetReducerDispatch({
              type: 'setCurrentAsset',
              payload: tempCurrentAsset,
            });
          }}
          onBlur={value => {
            onCurrentValueBlur(value);
            setIsCurrentValueFocused(false);
          }}
          onFocus={() => {
            setIsCurrentValueFocused(true);
          }}
          isFocused={isCurrentValueFocused}
          isValid={statusCurrentValue?.status}
          error={statusCurrentValue?.errorMessage}
        />

        <Space marginBottom="2.4rem" />

        <CustomInput
          name="inscription-rank"
          label="Rang de l'inscription"
          placeholder="ex: 1"
          inputWidth="8rem"
          onChange={newValue => {
            setisInscriptionRankFocused(true);
            if (!onlyNumbers.test(newValue)) return;

            const tempCurrentAsset = { ...currentAsset };

            setInscriptionRank(newValue.slice(0, 1));
            tempCurrentAsset.inscriptionRank = newValue.slice(0, 1);
            setCurrentAsset(tempCurrentAsset);

            currentAssetReducerDispatch({
              type: 'setCurrentAsset',
              payload: tempCurrentAsset,
            });
          }}
          onBlur={onInscriptionRankBlur}
          onFocus={() => {
            setisInscriptionRankFocused(true);
          }}
          isFocused={isInscriptionRankFocused}
          value={inscriptionRank}
          isValid={statusInscriptionRank?.status}
          error={statusInscriptionRank?.errorMessage}
        />

        <Space marginBottom="2.4rem" />

        {Number(currentAsset.inscriptionRank) > 1 ? renderKeptMortgagesValue() : <></>}

        <Space marginBottom="2.4rem" />

        <div>
          <StyledRadioButtonTitle>
            {messages.MODAL_ASSET_PATRIMOINE_ADRESSE}
          </StyledRadioButtonTitle>
          <StyledRadioButtonWrapper>
            <StyledRadioButtonContainer>
              <BaseRadioButton
                tabIndex={0}
                id="asset-address-borrower-address-true"
                checked={currentAsset.isAssetAddressBorrowerAddress ? true : undefined}
                onClick={() => {
                  const tempCurrentAsset = { ...currentAsset };
                  tempCurrentAsset.isAssetAddressBorrowerAddress =
                    tempCurrentAsset.isAssetAddressBorrowerAddress ? undefined : true;
                  if (tempCurrentAsset.isAssetAddressBorrowerAddress) {
                    tempCurrentAsset.zipCode = situationFoyerState.addressZipCode;
                  }
                  setCurrentAsset(tempCurrentAsset);

                  currentAssetReducerDispatch({
                    type: 'setCurrentAsset',
                    payload: tempCurrentAsset,
                  });
                }}
              />
              <span>{messages.MODAL_RADIO_BUTTON_TRUE}</span>
            </StyledRadioButtonContainer>
            <StyledRadioButtonContainer>
              <BaseRadioButton
                id="asset-address-borrower-address-false"
                checked={
                  currentAsset.isAssetAddressBorrowerAddress === false ? true : undefined
                }
                onClick={() => {
                  const tempCurrentAsset = { ...currentAsset };
                  tempCurrentAsset.isAssetAddressBorrowerAddress =
                    tempCurrentAsset.isAssetAddressBorrowerAddress === false
                      ? undefined
                      : false;
                  tempCurrentAsset.zipCode = undefined;
                  setCurrentAsset(tempCurrentAsset);

                  currentAssetReducerDispatch({
                    type: 'setCurrentAsset',
                    payload: tempCurrentAsset,
                  });
                }}
              />
              <span>{messages.MODAL_RADIO_BUTTON_FALSE}</span>
            </StyledRadioButtonContainer>
          </StyledRadioButtonWrapper>
        </div>

        <Space marginBottom="2.4rem" />

        {currentAsset.isAssetAddressBorrowerAddress === false ? renderAddress() : <></>}

        <Space marginBottom="6.4rem" />

        <StyledButtonContainer>
          {!enableAddAssetButton ? (
            <StyledModalAddLoanButton disabled className="selection-btn" onClick={close}>
              <PlusCircleIcon color="#ffffff" />
              <span style={{ marginLeft: '1rem' }}>
                {isEdited ? messages.MODAL_MODIFY_BUTTON : messages.MODAL_ADD_ASSET}
              </span>
            </StyledModalAddLoanButton>
          ) : (
            <StyledModalAddLoanButton
              className="selection-btn"
              tabIndex={0}
              onKeyDownCapture={event => {
                if (
                  event.key === 'Enter' ||
                  event.key === 'Space' ||
                  event.code === 'NumpadEnter'
                ) {
                  handleAddAssetButtonClick();
                  close();
                }
              }}
              onClick={() => {
                handleAddAssetButtonClick();
                close();
              }}>
              {!isEdited && <PlusCircleIcon color="#ffffff" />}
              <span style={{ marginLeft: '1rem' }}>
                {isEdited ? messages.MODAL_MODIFY_BUTTON : messages.MODAL_ADD_ASSET}
              </span>
            </StyledModalAddLoanButton>
          )}

          <TextButton
            label={messages.MODAL_CLOSE}
            onClick={() => {
              if (dataHasBeenModified()) {
                setIsCloseConfirmationVisible(true);
              } else {
                close();
              }
            }}>
            <CrossCircle color="rgb(40, 136, 60)" />
          </TextButton>
        </StyledButtonContainer>

        <CloseModalConfirmation
          isVisible={isCloseConfirmationVisible}
          accept={() => {
            setCurrentAsset({} as Asset);

            currentAssetReducerDispatch({
              type: 'setCurrentAsset',
              payload: {} as Asset,
            });

            sessionStorage.removeItem('guaranteeAdditionalInformation');

            setIsCloseConfirmationVisible(false);
            close();
          }}
          close={() => {
            (
              document.getElementsByClassName('element-in-modal')[0] as HTMLElement
            ).focus();
            setIsCloseConfirmationVisible(false);
          }}
        />
      </StyledModalContentWrapper>
    </Modal>
  );
};

export default AssetModal;
