import * as React from 'react';

const FolderIcon: React.FC = () => (
  <svg width={54} height={44} xmlns="http://www.w3.org/2000/svg">
    <title>69935ADE-2CE8-4E46-BFF4-A720C1DEA598</title>
    <g fill="#292C2E" fillRule="nonzero">
      <path d="M10.914 41.597A4.984 4.984 0 0 1 9.6 39.202L1.04 13.415c-.925-2.787.755-3.8 2.11-3.8H39.44c1.234 0 2.296.41 3.162 1.222a4.764 4.764 0 0 1 1.323 2.135l8.74 26.337c1.179 3.55-.734 3.55-1.364 3.55H13.976c-1.196-.001-2.225-.425-3.062-1.262Zm39.677-1.08L41.59 13.765a12.786 12.786 0 0 0-.344-.806 1.362 1.362 0 0 0-.305-.364c-.145-.119-.353-.359-1.022-.36l-35.887.001c-.445.01-.735.33-.439 1.122l8.79 25.975a.426.426 0 0 0 .047.094c.134.206.265.332.379.474.002.002.326.257.488.333.287.134.867.218 1.423.218l35.973-.007c.08 0 .076.082.144.074-.001-.105-.062.554-.246-.002Z" />
      <path d="M51.301 43.333H13.977c-1.31 0-2.443-.466-3.364-1.388a5.499 5.499 0 0 1-1.438-2.605L.623 13.575c-.547-1.65-.218-2.672.155-3.24.492-.749 1.38-1.195 2.373-1.195H39.44c1.342 0 2.506.45 3.453 1.339a5.276 5.276 0 0 1 1.455 2.344l8.736 26.325c.516 1.556.536 2.663.06 3.386-.526.8-1.448.8-1.842.8ZM3.151 10.09c-.705 0-1.323.297-1.65.793-.361.55-.377 1.371-.044 2.373l8.568 25.814.006.025a4.51 4.51 0 0 0 1.186 2.156c.752.752 1.68 1.133 2.76 1.133h37.324c.3 0 .858 0 1.12-.399.2-.305.334-.992-.172-2.519l-8.747-26.36a4.271 4.271 0 0 0-1.19-1.912c-.783-.733-1.748-1.104-2.872-1.104H3.151Zm47.571 31.128c-.182 0-.331-.1-.443-.298l-35.56.007c-.508 0-1.193-.067-1.601-.258-.154-.072-.567-.359-.647-.458a3.308 3.308 0 0 0-.1-.119 3.208 3.208 0 0 1-.306-.393.926.926 0 0 1-.1-.205L3.176 13.52c-.198-.532-.198-.978.009-1.313.17-.28.468-.438.836-.446l35.899-.004c.76.004 1.082.278 1.253.426l.035.03c.162.133.354.366.429.524.11.222.331.757.368.867l8.885 26.405c.022.01.044.02.065.034l.32.04.005.856-.136.017c-.094.165-.224.251-.392.261h-.029Zm-37.614-1.67c.107.083.293.217.364.25.19.089.68.178 1.246.178l35.218-.007-8.763-26.041c-.024-.07-.227-.562-.318-.744l-.003-.005a1.033 1.033 0 0 0-.178-.204l-.054-.046c-.114-.096-.253-.217-.703-.218H4.032c-.068.001-.106.019-.112.025-.008.021-.021.162.084.445L12.8 39.17c.066.098.14.182.218.271l.091.106Z" />
      <path d="M52.329 41.385c-.672 0-1.324-5.067-1.324-5.788L50.79 8.043c0-.43-.128-.43-.17-.43H27.242c-.497 0-.945-.325-1.131-.82l-1.093-2.928c-2.849-.088-8.48-.205-10.123.017-.735.098-.784 1.673-.784 1.688v4.71c0 .72-.546 1.306-1.218 1.306-.672 0-1.218-.586-1.218-1.306V5.562c0-1.344.61-3.965 2.919-4.275 2.375-.318 10.936-.022 11.3-.009.483.017.911.338 1.09.82l1.082 2.903h22.551c1.048 0 2.607.81 2.607 3.044l-.08 33.452c-.002.72-.144-.112-.816-.112Z" />
      <path d="M53.094 42.266c-.195 0-.341-.143-.413-.213-.115-.113-.207-.192-.352-.192-.495 0-.893-.503-1.35-3.009-.254-1.402-.417-2.864-.417-3.255l-.216-27.51H27.243c-.683 0-1.288-.44-1.542-1.12l-.984-2.64c-3.699-.109-8.363-.166-9.765.023-.2.028-.38.695-.397 1.232v4.696c0 .982-.746 1.781-1.661 1.781-.916 0-1.661-.8-1.661-1.781V5.562c0-1.488.691-4.395 3.306-4.745C16.877.504 24.989.772 25.905.803c.661.024 1.245.462 1.49 1.119l.972 2.603h22.25c1.225 0 3.05.937 3.05 3.518l-.082 33.454c0 .232 0 .582-.304.726a.438.438 0 0 1-.187.043Zm-.644-1.35c.092.01.175.028.252.054l.08-32.928c0-2.03-1.415-2.568-2.164-2.568h-22.85l-1.194-3.2a.777.777 0 0 0-.694-.522c-.912-.031-8.96-.299-11.23.005-2.003.268-2.53 2.605-2.53 3.803v4.716c0 .458.347.83.774.83.427 0 .774-.372.774-.83V5.57c.002-.09.04-2.01 1.173-2.161 1.919-.257 8.826-.062 10.19-.02l.292.008 1.2 3.218a.774.774 0 0 0 .72.522h23.375c.184 0 .613.087.613.906l.216 27.55c.002.847.575 4.681 1.003 5.325Z" />
    </g>
  </svg>
);

export default FolderIcon;
