import Space from 'components/space/Space';
import { hasCoBorrower } from 'containers/communs/utils';
import { useEffect } from 'react';
import {
  LoanAdditionalData,
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { CodeLibelle } from 'types';
import Indemnity from './Indemnity';
import InterestRate from './InterestRate';
import LoanDuration from './LoanDuration';
import LoanNumber from './LoanNumber';
import LoanObject from './LoanObject';
import LoanStartingDate from './LoanStartingDate';
import LoanTypeChoice from './LoanTypeChoice';
import LoanUsage from './LoanUsage';
import PriorNotice from './PriorNotice';
import TitulaireChoice from './TitulaireChoice';
import ValidateBtn from './ValidateBtn';

interface LoanAdditionalInformationProps {
  currentLoan: LoanAdditionalData;
  creditType?: CodeLibelle;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}
const LoanAdditionalInformation: React.FC<LoanAdditionalInformationProps> = ({
  currentLoan,
  creditType,
  state,
  dispatch,
}) => {
  useEffect(() => {
    const data = state.loans.map(item => {
      if (item.code === currentLoan?.code) {
        return { ...item, creditType };
      }
      return item;
    });
    dispatch({
      type: 'setUpdateLoansInformation',
      payload: { data },
    });
  }, []);

  return (
    <>
      {hasCoBorrower() && (
        <>
          <TitulaireChoice currentLoan={currentLoan} state={state} dispatch={dispatch} />
          <Space marginTop="2.4rem" />
        </>
      )}
      {creditType?.code === 'R' && (
        <>
          <LoanStartingDate currentLoan={currentLoan} state={state} dispatch={dispatch} />
          <Space marginTop="2.4rem" />
          <LoanDuration currentLoan={currentLoan} state={state} dispatch={dispatch} />
        </>
      )}
      {creditType?.code === 'C' && (
        <>
          <Space marginTop="2.4rem" />
          <LoanTypeChoice currentLoan={currentLoan} state={state} dispatch={dispatch} />
        </>
      )}
      {creditType?.code === 'I' && (
        <>
          <Space marginTop="2.4rem" />
          <LoanUsage currentLoan={currentLoan} state={state} dispatch={dispatch} />
        </>
      )}
      {creditType?.code === 'C' && (
        <>
          <Space marginTop="2.4rem" />
          <LoanObject currentLoan={currentLoan} state={state} dispatch={dispatch} />
        </>
      )}
      <Space marginTop="2.4rem" />
      <LoanNumber currentLoan={currentLoan} state={state} dispatch={dispatch} />
      {creditType?.code !== 'R' && (
        <>
          <Space marginTop="2.4rem" />
          <Indemnity currentLoan={currentLoan} state={state} dispatch={dispatch} />
        </>
      )}

      <Space marginTop="2.4rem" />
      <InterestRate currentLoan={currentLoan} state={state} dispatch={dispatch} />

      <Space marginTop="2.4rem" />
      <PriorNotice currentLoan={currentLoan} state={state} dispatch={dispatch} />
      <Space marginTop="4rem" />
      <ValidateBtn
        mode="create"
        currentLoan={currentLoan}
        state={state}
        dispatch={dispatch}
      />
      <Space marginTop="3.2rem" />
    </>
  );
};

export default LoanAdditionalInformation;
