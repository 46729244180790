import ChemiseConsultation from 'components/chemise/chemiseConsultation';
import InformationArea from 'components/informationArea/InformationArea';
import Modal from 'components/modals/Modal';
import Space from 'components/space/Space';
import Loader from 'components/Loader';
import FormFooter from 'components/formFooter/FormFooter';
import StepTitles from 'components/titles/StepTitles';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import { isSansGarantie } from 'containers/communs/utils';
import ListPJNonTransmis from 'components/listPJNonTransmis/ListPJNonTransmis';
import CheckInCircle from 'icons/CheckInCircle';
import ErrorIcon from 'icons/ErrorIcon';
import PaperPlaneIcon from 'icons/PaperPlaneIcon';
import ListDocumentIcon from 'icons/ListDocumentIcon';
import IdentityIcon from 'icons/IdentityIcon';
import HouseIcon from 'icons/HouseIcon';
import IncomeIcon from 'icons/IncomeIcon';
import BankIcon from 'icons/BankIcon';
import CreditIcon from 'icons/CreditIcon';
import MoneyIcon from 'icons/MoneyIcon';
import GuaranteeIcon from 'icons/GuaranteeIcon';
import InsuranceIcon from 'icons/InsuranceIcon';
import FolderIcon from 'icons/FolderIcon';
import { useEffect, useReducer, useState } from 'react';
import {
  getTransmissionPiecesInitialState,
  transmissionPiecesReducer,
} from 'reducers/transmissionPieces/transmissionPieces';
import { getUploadUrl, transfertAll, uploadFile } from 'api/transfertDocumentService';
import { ChemiseData } from 'reducers/transmissionPieces/types';
import {
  CodeLibelle,
  GetUploadUrlApiRequestParams,
  PJWithStatus,
  PiecesJustificatives,
  UploadFileApiRequestBody,
  UploadFileApiResponse,
  UserInfos,
} from 'types';
import * as messages from '../messages';

const OngletPiecesJustificatives: React.FC = () => {
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [hideValidPiece, setHideValidPiece] = useState<boolean>(false);
  const [filesAlreadyUploaded, setFilesAlreadyUploaded] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [uploadApiErrorMessage, setUploadApiErrorMessage] = useState<string>('');
  const [transmissionPiecesState, transmissionPiecesDispatch] = useReducer(
    transmissionPiecesReducer,
    getTransmissionPiecesInitialState('transmissionPieces'),
  );

  const [listePJNonTransmis, setListPJNonTransmis] = useState<string[]>([]);

  const [sendingFiles, setSendingFiles] = useState<boolean>(false);

  useEffect(() => {
    if (transmissionPiecesState.isDataOk && !filesAlreadyUploaded) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [transmissionPiecesState, filesAlreadyUploaded]);

  useEffect(() => {
    sessionStorage.setItem('listePJNonTransmis', JSON.stringify(listePJNonTransmis));
  }, [listePJNonTransmis]);

  const piecesJustificatives = JSON.parse(
    sessionStorage.getItem('piecesJustificatives') || '{}',
  ) as PiecesJustificatives;

  const ACCEPTED_FILES_FORMAT_SG = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const ACCEPTED_FILES_FORMAT_AG = ['application/pdf', 'image/jpeg'];

  const getChemiseIcon = (chemiseId: string) => {
    switch (chemiseId) {
      case '002':
        return <IdentityIcon />;
      case '003':
        return <HouseIcon />;
      case '005':
        return <IncomeIcon />;
      case '004':
        return <BankIcon />;
      case '006':
        return <CreditIcon />;
      case '007':
        return <MoneyIcon />;
      case '008':
        return <GuaranteeIcon />;
      case '009':
        return <InsuranceIcon />;
      case '010':
        return <FolderIcon />;
      default:
        return null;
    }
  };

  const ONE_MO_TO_BYTE = 1_048_576;
  const MAX_FILE_SIZE_IN_MO = 19 * ONE_MO_TO_BYTE; // 19 Mo maximum

  const isSG = isSansGarantie();

  const renderSGErrorMessage = () => {
    return (
      <>
        <span>Votre document n’a pas pu être téléchargé.</span>
        <br />
        <span>
          Veuillez vérifier le format ou la taille de votre document. Formats et taille
          acceptés : PDF, JPEG, WORD, EXCEL (sans macro), PNG - 19 Mo.
        </span>
      </>
    );
  };

  const renderAGErrorMessage = () => {
    return (
      <>
        <span>Votre document n’a pas pu être téléchargé.</span>
        <br />
        <span>
          Veuillez vérifier le format ou la taille de votre document. Formats et taille
          acceptés : PDF, JPEG - 19 Mo.
        </span>
      </>
    );
  };

  const renderAPIErrorMessage = () => {
    if (uploadApiErrorMessage) {
      return (
        <>
          <Space marginTop="4rem" />
          <InformationArea backgroundColor="#FEF2F4" icon={<ErrorIcon />} width="100%">
            <span> {uploadApiErrorMessage}</span>
          </InformationArea>
        </>
      );
    }
    return null;
  };

  const renderSuccessUploadMessage = () => {
    return (
      <Modal
        backgroundColor="transparent"
        isVisible={showSuccessMessage}
        height="6.4rem"
        width="70rem">
        <div
          style={{
            border: '0.1rem solid #008859',
            borderRadius: '0.4rem',
            backgroundColor: '#F2F9F1',
            textAlign: 'center',
            display: 'flex',
            height: '6.4rem',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
          }}>
          <CheckInCircle color="green" />
          <span
            style={{
              color: '#008859',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              margin: '0',
            }}>
            {messages.UPLOAD_SUCCESS_MSG}
          </span>
        </div>
      </Modal>
    );
  };

  const getChemiseFilesName = (id: string) => {
    const result: CodeLibelle[] = [];
    transmissionPiecesState.chemises.forEach(item => {
      if (item.id === id) {
        item.files.forEach(el => {
          result.push({ code: el.id, libelle: el.content.name });
        });
      }
    });
    return result;
  };

  const getSendFilePromise = (
    currentChemise: ChemiseData,
    url: string,
  ): Promise<UploadFileApiResponse>[] => {
    const promises: Promise<UploadFileApiResponse>[] = [];
    currentChemise.files.forEach(item => {
      const currentPromise = new Promise<UploadFileApiResponse>((resolve, reject) => {
        const body: UploadFileApiRequestBody = {
          fileName: item.content.name,
          chemiseId: currentChemise.id,
          fileContent: item.content,
        };
        uploadFile(url, body)
          .then(resp => resolve(resp))
          .catch(err => {
            console.log(`erreur sur le fichier ${body.fileName}`, err);
            setListPJNonTransmis(previsousState => [...previsousState, body.fileName]);
            resolve(err);
          });
      });
      promises.push(currentPromise);
    });
    return promises;
  };

  const sendFiles = async () => {
    setSendingFiles(true);
    setUploadApiErrorMessage('');
    try {
      const authenticatedUser = JSON.parse(
        sessionStorage.getItem('authenticatedUser') || '',
      ) as UserInfos;
      const { numContremarque } = authenticatedUser;
      const numeroProjet = sessionStorage.getItem('numeroProjet');
      if (!numeroProjet) {
        throw Error('Numero de projet non défini.');
      }
      const params: GetUploadUrlApiRequestParams = {
        IdProjet: parseInt(numeroProjet, 10),
        AdheNum: `${numContremarque}` || '',
        ContextApp: 'APTD',
      };
      const response = await getUploadUrl(params);
      if (response.success) {
        sessionStorage.removeItem('listePJNonTransmis');
        const uploadApiUrl = response.result;
        let promises: Promise<UploadFileApiResponse | string>[] = [];
        transmissionPiecesState.chemises.forEach(item => {
          promises = [...promises, ...getSendFilePromise(item, uploadApiUrl)];
        });
        if (promises.length > 0) {
          const allPromise = await Promise.all(promises);
          if (!allPromise.some(promise => promise === ''))
            throw Error(
              "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
            );
          // l'url de l'upload est le même que celui du getTransfertAll (domaine et paramétre identiques).
          // Il faut juste changer le path single-file en getTransfertAll.
          // Disclaimer: ne pas hésiter à proposer autre chose si cette maniére de faire ne convient pas.
          const transfertApiUrl = uploadApiUrl.replace('single-file', 'getTransfertAll');
          await transfertAll(transfertApiUrl);
          sessionStorage.setItem('documentsHaveBeenSent', 'yes');
          setFilesAlreadyUploaded(true);
          setShowSuccessMessage(true);
          // on affiche le message de succés de l'upload pendant 5 secondes
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
        }
      } else {
        console.error(response.errors);
        setUploadApiErrorMessage(`${response.errors[0].MessageLib}`);
        setIsBtnDisabled(false);
      }
    } catch (errors) {
      console.error(errors);
      setUploadApiErrorMessage(`${errors}`);
      setIsBtnDisabled(false);
    } finally {
      setSendingFiles(false);
    }
  };

  const renderChemisesUploadablesSG = () => {
    const listPiece: PJWithStatus[] = [];

    const chemisesUploadables =
      piecesJustificatives?.listePiecesJustificativesPropCialUploadable?.listeChemises;
    if (
      !chemisesUploadables ||
      !chemisesUploadables[0] ||
      chemisesUploadables[0]?.listePiecesJustificatives.length === 0
    ) {
      return null;
    }
    chemisesUploadables[0].listePiecesJustificatives.forEach(item => {
      const pieceName = `${item.pceJusLib[0]}${item.pceJusLib
        .substring(1)
        .toLowerCase()} ${item.cpltPceJusLib}`;
      listPiece.push({
        libelle: pieceName,
        status: item.statPceJusLib,
        ...(item.pceJusCmtLib ? { commantaire: item.pceJusCmtLib } : {}),
      });
    });
    const chemise = chemisesUploadables[0]; // pour un SG, il n'y a qu'une seule chemise, même si le retour de l'api est un Array
    return (
      <>
        <ChemiseConsultation
          id={chemise.chemId}
          libelle={chemise.chemLib}
          icon={<ListDocumentIcon />}
          hideValidPiece={hideValidPiece}
          documentsUploadTransferes={
            chemise.documentsUploadTransferes.listeDocumentUpldTranChe
          }
          acceptedFileFormat={ACCEPTED_FILES_FORMAT_SG}
          maxFileSize={MAX_FILE_SIZE_IN_MO}
          listPiece={listPiece}
          listPJNonTransmis={listePJNonTransmis}
          isConsultation
          isUploadable={!filesAlreadyUploaded}
          onUploadSuccess={value => {
            transmissionPiecesDispatch({
              type: 'setAddPieces',
              payload: { value },
            });
          }}
          onDelete={(chemiseId, fileId) => {
            transmissionPiecesDispatch({
              type: 'setDeletePieces',
              payload: { chemiseId, fileId },
            });
          }}
          uploadedFilesName={getChemiseFilesName(chemise.chemId)}
          errorMessage={renderSGErrorMessage()}
        />
      </>
    );
  };

  const renderChemisesUploadablesAG = () => {
    const chemisesUploadables =
      piecesJustificatives?.listePiecesJustificativesPropCialUploadable?.listeChemises;
    return chemisesUploadables
      ?.filter(chemise => chemise.chemId !== '010')
      .map(chemise => {
        const listPiece: PJWithStatus[] = [];

        if (!chemise || chemise.listePiecesJustificatives.length === 0) {
          return null;
        }
        chemise.listePiecesJustificatives.forEach(item => {
          const pieceName = `${item.pceJusLib[0]}${item.pceJusLib
            .substring(1)
            .toLowerCase()} ${item.cpltPceJusLib}`;
          listPiece.push({
            libelle: pieceName,
            status: item.statPceJusLib,
            ...(item.pceJusCmtLib ? { commantaire: item.pceJusCmtLib } : {}),
          });
        });

        return (
          <div key={chemise.chemId}>
            <ChemiseConsultation
              id={chemise.chemId}
              libelle={chemise.chemLib}
              icon={getChemiseIcon(chemise.chemId)}
              hideValidPiece={hideValidPiece}
              documentsUploadTransferes={
                chemise.documentsUploadTransferes.listeDocumentUpldTranChe
              }
              acceptedFileFormat={ACCEPTED_FILES_FORMAT_AG}
              maxFileSize={MAX_FILE_SIZE_IN_MO}
              listPiece={listPiece}
              listPJNonTransmis={listePJNonTransmis}
              isConsultation
              isUploadable={!filesAlreadyUploaded}
              onUploadSuccess={value => {
                transmissionPiecesDispatch({
                  type: 'setAddPieces',
                  payload: { value },
                });
              }}
              onDelete={(chemiseId, fileId) => {
                transmissionPiecesDispatch({
                  type: 'setDeletePieces',
                  payload: { chemiseId, fileId },
                });
              }}
              uploadedFilesName={getChemiseFilesName(chemise.chemId)}
              errorMessage={renderAGErrorMessage()}
            />
            <Space marginTop="3.2rem" />
          </div>
        );
      });
  };

  const renderChemisesNonUploadables = () => {
    const listPiece: PJWithStatus[] = [];
    piecesJustificatives?.listePiecesJustificativesPropCialNonUploadables?.forEach(
      item => {
        const pieceName = `${item.pceJusLib[0]}${item.pceJusLib
          .substring(1)
          .toLowerCase()} ${item.cpltPceJusLib}`;
        listPiece.push({
          libelle: pieceName,
          status: item.statPceJusLib,
          ...(item.pceJusCmtLib ? { commantaire: item.pceJusCmtLib } : {}),
        });
      },
    );
    return (
      <ChemiseConsultation listPiece={listPiece} isUploadable={false} isConsultation />
    );
  };

  return (
    <>
      <Space marginBottom="2.4rem" />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ color: '#6F757C' }}>
          {isSG ? messages.ACCEPTED_FORMAT_TEXT_FOR_SG : messages.ACCEPTED_FORMAT_TEXT}
        </span>
        <div style={{ display: 'flex' }}>
          <span style={{ marginRight: '1rem' }}>{messages.HIDE_VALID_PIECE}</span>
          <BaseCheckbox
            onCheckedChanged={e => {
              setHideValidPiece((e.target as HTMLInputElement).checked);
            }}
          />
        </div>
      </div>
      <Space marginBottom="0.8rem" />
      {isSG ? renderChemisesUploadablesSG() : renderChemisesUploadablesAG()}
      {sendingFiles ? <Loader animationDuration={0.9} /> : null}
      {renderSuccessUploadMessage()}
      {renderAPIErrorMessage()}
      {!uploadApiErrorMessage && listePJNonTransmis && listePJNonTransmis.length > 0 && (
        <>
          <Space marginTop="2rem" />
          <ListPJNonTransmis listPJ={listePJNonTransmis} />
        </>
      )}
      <FormFooter
        hideSaveBtn
        isNextButtonDisabled={isBtnDisabled}
        showPreviousButton={false}
        onNextButtonClick={() => {
          setIsBtnDisabled(true);
          sendFiles();
        }}
        nextButtonLabel="Transmettre les pièces"
        nextButtonIcon={<PaperPlaneIcon />}
      />
      <Space marginTop="8rem" />
      <StepTitles
        title={messages.TITLE_CHEMISE_NON_UPLOADABLE}
        subTitle={messages.SUB_TITLE_CHEMISE_NON_UPLOADABLE}
      />
      <Space marginBottom="2.4rem" />
      {renderChemisesNonUploadables()}
      <Space marginBottom="2.4rem" />
    </>
  );
};

export default OngletPiecesJustificatives;
