import ExpandableCardTer from 'components/expandableCard/ExpandableCardTer';
import Space from 'components/space/Space';
import Tag from 'components/tag/Tag';
import { StyledBadgeWrapper } from 'containers/finalisationProjet/style';
import { sortLongText } from 'containers/finalisationProjet/utils';
import {
  StyledCardContentTile,
  StyledCardData,
  StyledCardLabel,
} from 'containers/operationClient/style';
import ActionValidatedIcon from 'icons/ActionPlusIcon';
import ChevronDown from 'icons/ChevronDown';
import { useState } from 'react';
import {
  LoanAdditionalInformationAction,
  LoanAdditionalInformationState,
} from 'reducers/loanAdditionalInformation/type';
import { Loan } from 'reducers/operationClient/types';
import { formatNumberByThousand } from 'utils/commun';
import { renderTypeCreditLabel } from '../../utils';
import ValidateBtn from '../createMode/additionalInformation/ValidateBtn';

interface LoanAdditionalInformationViewModeProps {
  currentLoan: Loan;
  state: LoanAdditionalInformationState;
  dispatch: React.Dispatch<LoanAdditionalInformationAction>;
}

const LoanAdditionalInformationViewMode: React.FC<
  LoanAdditionalInformationViewModeProps
> = ({ currentLoan, state, dispatch }) => {
  const loan = state.loans.find(item => item.code === currentLoan.code);
  const [expandCard, setExpandCard] = useState(false);
  if (!loan) {
    return null;
  }
  const renderTag = (loanObj: Loan, text: string, type: 'restructure' | 'morgage') => {
    const result = type === 'restructure' ? loanObj.restructure : loanObj.mortgage;
    return result ? (
      <>
        <Tag accentColor={loanObj.buyBack ? '#28833C' : '#B1B5B9'} label={text} />
        <Space marginBottom="0.8rem" />
      </>
    ) : null;
  };

  const renderBorrower = (): string => {
    if (!loan.titulaire || loan.titulaire === 'borrower') {
      return 'Emprunteur';
    }
    if (loan.titulaire === 'coBorrower') {
      return 'Co-Emprunteur';
    }
    return 'Les deux';
  };

  const renderTitulaire = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>Titulaire</StyledCardLabel>
        <StyledCardData>{renderBorrower()}</StyledCardData>
      </StyledCardContentTile>
    );
  };

  const renderLoanStartingDate = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>Date de début du prêt</StyledCardLabel>
        <StyledCardData>
          {loan.loanStartingDate || currentLoan.loanStartDate}
        </StyledCardData>
      </StyledCardContentTile>
    );
  };

  const renderLoanDuration = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>Durée totale du prêt</StyledCardLabel>
        <StyledCardData>
          {loan.loanDuration || currentLoan.totalLoanDuration} mois
        </StyledCardData>
      </StyledCardContentTile>
    );
  };

  const renderInitialCapital = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>Capital du prêt</StyledCardLabel>
        <StyledCardData>
          {formatNumberByThousand(
            currentLoan.initialLoanCapital !== undefined
              ? currentLoan.initialLoanCapital
              : '0',
          )}{' '}
          €
        </StyledCardData>
      </StyledCardContentTile>
    );
  };

  const renderLoanNumber = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>Numéro du prêt</StyledCardLabel>
        <StyledCardData>{loan.loanNumber}</StyledCardData>
      </StyledCardContentTile>
    );
  };

  const renderIndemnity = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>Indemnités</StyledCardLabel>
        <StyledCardData>
          {formatNumberByThousand(
            loan.indemnityAmount ? loan.indemnityAmount.toString() : '0',
          )}{' '}
          €
        </StyledCardData>
      </StyledCardContentTile>
    );
  };

  const renderInterest = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>{`Taux d'intérêt nominal`}</StyledCardLabel>
        <StyledCardData>
          {loan.nominalInterestRate && `${loan.nominalInterestRate} %`}
        </StyledCardData>
      </StyledCardContentTile>
    );
  };

  const renderTags = () => {
    return (
      <StyledCardContentTile>
        {renderTag(currentLoan, 'Restructuration', 'restructure')}
        {renderTag(currentLoan, 'Inscription hypothecaire', 'morgage')}
      </StyledCardContentTile>
    );
  };

  const renderDelay = () => {
    return (
      <StyledCardContentTile>
        <StyledCardLabel>Délai de préavis</StyledCardLabel>
        <StyledCardData>
          {loan.nbMonthsForNotice && `${loan.nbMonthsForNotice} mois`}
        </StyledCardData>
      </StyledCardContentTile>
    );
  };

  const getCollapsableContent = () => {
    if (currentLoan.creditType?.code === 'R') {
      return (
        <>
          <tr className="table-td-space" style={{ display: expandCard ? '' : 'none' }}>
            <td>{renderTitulaire()}</td>
            <td>{renderLoanStartingDate()}</td>
            <td>{renderLoanDuration()}</td>
            <td>{renderInitialCapital()}</td>
            <td />
          </tr>
          <tr
            className={currentLoan.restructure ? 'table-td-space' : ''}
            style={{ display: expandCard ? '' : 'none' }}>
            <td>{renderLoanNumber()}</td>
            <td>{renderIndemnity()}</td>
            <td>{renderInterest()}</td>
            {currentLoan.restructure ? <td>{renderTags()}</td> : <td>{renderDelay()}</td>}
          </tr>
          {currentLoan.restructure ? (
            <tr style={{ display: expandCard ? '' : 'none' }}>
              <td>{renderDelay()}</td>
            </tr>
          ) : null}
        </>
      );
    }
    return (
      <>
        <tr className="table-td-space" style={{ display: expandCard ? '' : 'none' }}>
          <td>{renderLoanStartingDate()}</td>
          <td>{renderLoanDuration()}</td>
          <td>{renderInitialCapital()}</td>
          {currentLoan.restructure ? <td>{renderTags()}</td> : <td>{renderDelay()}</td>}
          <td />
        </tr>
        <tr
          className={currentLoan.restructure ? 'table-td-space' : ''}
          style={{ display: expandCard ? '' : 'none' }}>
          <td>{renderTitulaire()}</td>
          <td>{renderLoanNumber()}</td>
          <td>{renderIndemnity()}</td>
          <td>{renderInterest()}</td>
        </tr>
        {currentLoan.restructure ? (
          <tr style={{ display: expandCard ? '' : 'none' }}>
            <td>{renderDelay()}</td>
          </tr>
        ) : null}
      </>
    );
  };

  const getBadge = () => {
    return (
      <StyledBadgeWrapper style={{ color: '#28833C' }}>
        <ActionValidatedIcon color="#28833C" />
        <span>Données renseignées</span>
      </StyledBadgeWrapper>
    );
  };

  return (
    <>
      <ExpandableCardTer accentColor="#28833C" badge={getBadge()}>
        <table style={{ width: '100%', marginLeft: '2.4rem', tableLayout: 'fixed' }}>
          <tbody>
            <tr className="table-td-space">
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Organisme de crédit</StyledCardLabel>
                  <StyledCardData>
                    {sortLongText(currentLoan.loaner?.libelle)}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Type de crédit</StyledCardLabel>
                  <StyledCardData>
                    {renderTypeCreditLabel(currentLoan.creditType?.code || '')}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Mensualité du prêt</StyledCardLabel>
                  <StyledCardData>
                    {formatNumberByThousand(
                      currentLoan.monthlyPayment !== undefined
                        ? currentLoan.monthlyPayment
                        : '',
                    )}{' '}
                    € / mois
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td style={{ width: '23%' }}>
                <StyledCardContentTile>
                  <StyledCardLabel>Solde</StyledCardLabel>
                  <StyledCardData>
                    {currentLoan.balance
                      ? `${Number(currentLoan.balance).toLocaleString('FR-fr')} €`
                      : ''}
                  </StyledCardData>
                </StyledCardContentTile>
              </td>
              <td
                style={{ width: '6%', cursor: 'pointer' }}
                role="presentation"
                onKeyDown={() => setExpandCard(prev => !prev)}
                onClick={() => setExpandCard(prev => !prev)}>
                <ChevronDown />
              </td>
            </tr>
            {getCollapsableContent()}
          </tbody>
        </table>
        {expandCard ? (
          <>
            <Space marginTop="4rem" />
            <div style={{ width: '14.2rem', marginLeft: '2.4rem' }}>
              <ValidateBtn
                mode="view"
                currentLoan={loan}
                state={state}
                dispatch={dispatch}
              />
            </div>
            <Space marginTop="3.2rem" />
          </>
        ) : null}
      </ExpandableCardTer>
      <Space marginBottom="2.4rem" />
    </>
  );
};

export default LoanAdditionalInformationViewMode;
