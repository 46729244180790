import React, { useEffect } from 'react';
import Space from 'components/space/Space';
import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import { formatNumberByThousand } from 'utils/commun';
import {
  CalculetteFinanciereAction,
  CalculetteFinanciereState,
} from 'reducers/calculetteFinanciere/type';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import { useNavigate } from 'react-router-dom';
import FormFooter from 'components/formFooter/FormFooter';
import SimulationIcon from 'icons/SimulationIcon';
import WarningIcon from 'icons/WarningIcon';
import FinancingForm from './calculetteSteps/FinancingForm';
import SimulationForm from './calculetteSteps/SimulationForm';
import AdditionnalFeeForm from './calculetteSteps/AdditionnalFeeForm';
import InsuranceForm from './calculetteSteps/InsuranceForm';
import * as messages from './messages';
import {
  StyledCalculatorContainer,
  StyledSubTitle,
  StyledRadioButtonContainer,
  StyledSectionTitle,
  StyledInfo,
} from './style';

interface CalculetteFormProps {
  calculetteFinanciereDispatch: React.Dispatch<CalculetteFinanciereAction>;
  calculetteFinanciereState: CalculetteFinanciereState;
  getSimulation: () => void;
  errorMessages: ErrorMessage[];
}

const CalculetteForm: React.FC<CalculetteFormProps> = ({
  calculetteFinanciereDispatch,
  calculetteFinanciereState,
  getSimulation,
  errorMessages,
}) => {
  const navigate = useNavigate();

  const removePreviousInputs = () => {
    calculetteFinanciereDispatch({
      type: 'reinitilizeInsurance',
    });
  };

  const onCheckWarranty = () => {
    removePreviousInputs();
    calculetteFinanciereDispatch({
      type: 'setTypeGarantie',
      payload: 'HYP',
    });
  };

  const onCheckNoWarranty = () => {
    removePreviousInputs();
    calculetteFinanciereDispatch({
      type: 'setTypeGarantie',
      payload: 'SSG',
    });
  };

  const getValueByThousand = (value: number | undefined | string) => {
    if (value !== undefined) {
      return formatNumberByThousand(value.toString());
    }
    return undefined;
  };

  useEffect(() => {
    if (calculetteFinanciereState.projet.typeGarantie === undefined) {
      calculetteFinanciereDispatch({
        type: 'setTypeGarantie',
        payload: 'SSG',
      });
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledSubTitle>{messages.CALCULATOR_FORM.subtitle}</StyledSubTitle>
      <Space marginTop="3rem" />
      <StyledCalculatorContainer>
        <StyledSectionTitle>{messages.CALCULATOR_FORM.warrantyTitle}</StyledSectionTitle>
        <StyledRadioButtonContainer>
          <BaseRadioButton
            id="warranty-true"
            checked={
              calculetteFinanciereState.projet.typeGarantie === 'HYP' ? true : undefined
            }
            onClick={onCheckWarranty}
          />
          <span style={{ paddingRight: '6rem' }}>
            {messages.CALCULATOR_FORM.radioWithWarranty}
          </span>
          <BaseRadioButton
            id="warranty-false"
            checked={
              calculetteFinanciereState.projet.typeGarantie === 'SSG' ? true : undefined
            }
            onClick={onCheckNoWarranty}
          />
          <span>{messages.CALCULATOR_FORM.radioWithOutWarranty}</span>
        </StyledRadioButtonContainer>
        <FinancingForm
          projectState={calculetteFinanciereState.projet}
          calculetteFinanciereDispatch={calculetteFinanciereDispatch}
          getValueByThousand={getValueByThousand}
        />
        <SimulationForm
          propositionState={calculetteFinanciereState.proposition}
          calculetteFinanciereDispatch={calculetteFinanciereDispatch}
        />
        <AdditionnalFeeForm
          projectState={calculetteFinanciereState.projet}
          calculetteFinanciereDispatch={calculetteFinanciereDispatch}
          getValueByThousand={getValueByThousand}
        />
        <InsuranceForm
          state={calculetteFinanciereState}
          person="borrower"
          calculetteFinanciereDispatch={calculetteFinanciereDispatch}
          getValueByThousand={getValueByThousand}
        />
        <InsuranceForm
          state={calculetteFinanciereState}
          person="coBorrower"
          calculetteFinanciereDispatch={calculetteFinanciereDispatch}
          getValueByThousand={getValueByThousand}
        />
        {errorMessages.length > 0 && (
          <>
            {errorMessages.map(message => (
              <StyledInfo key={message.MessageId}>
                <WarningIcon />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p
                    style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                    key={message.MessageId}>
                    {message.MessageLib}
                  </p>
                </div>
              </StyledInfo>
            ))}
          </>
        )}
      </StyledCalculatorContainer>

      <FormFooter
        marginTop="4.8rem"
        showPreviousButton
        onNextButtonClick={() => {
          getSimulation();
        }}
        nextButtonLabel="Lancer la simulation"
        nextButtonIcon={<SimulationIcon color="#fff" />}
        onPreviousButtonClick={() => {
          navigate('/dashboard');
        }}
        hideSaveBtn
      />
    </div>
  );
};

export default CalculetteForm;
