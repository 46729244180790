export const getDateFromInput = (dateString: string): Date => {
  const day = dateString.slice(0, 3);
  const month = dateString.slice(3, 5);
  const year = dateString.slice(6, 10);

  const newDateString = `${month}/${day}/${year} 12:00:00`;

  return new Date(newDateString);
};

export const getMonthsRemaining = (earlierDate: Date, laterDate: Date): number => {
  const yearDifference = laterDate.getFullYear() - earlierDate.getFullYear();
  return (
    laterDate.getMonth() -
    earlierDate.getMonth() +
    yearDifference * 12 +
    (laterDate.getDay() > earlierDate.getDay() ? 0 : 1)
  );
};

export const getAge = (birthDate: Date): number => {
  const ageInMonths = getMonthsRemaining(birthDate, new Date());

  return Math.floor(ageInMonths / 12);
};

export const isRightAge = (birthDate: Date, compareDate: Date, age: number): boolean => {
  const limitDate = compareDate;

  limitDate.setFullYear(limitDate.getFullYear() - age);

  const oneDayInMs = 3600000;
  limitDate.setTime(limitDate.getTime() - oneDayInMs);

  if (birthDate.getTime() < limitDate.getTime()) {
    return true;
  }
  return false;
};

export const isAdult = (birthDate: Date): boolean => {
  if (isRightAge(birthDate, new Date(), 18)) {
    return true;
  }

  return false;
};

export const checkCurrentSituationDate = (date: string): boolean => {
  const dateSplit = date.split('/');
  if (dateSplit.length !== 2) {
    return false;
  }
  const month = dateSplit[0];
  if (month.length !== 2) {
    return false;
  }
  const year = dateSplit[1];
  if (year.length !== 4) {
    return false;
  }
  const reg = new RegExp(/^(0[1-9]|1[0-2])$/);
  return reg.test(month);
};

export const checkFormatDate = (date: string): boolean => {
  const dateSplit = date.split('/');

  const regDay = new RegExp(/^(0[1-9]|[12]\d|3[01])$/);
  const regMonth = new RegExp(/^(0[1-9]|1[0-2])$/);

  if (dateSplit.length !== 3) {
    return false;
  }
  const day = dateSplit[0];
  if (day.length !== 2) {
    return false;
  }
  if (!regDay.test(day)) {
    return false;
  }
  const month = dateSplit[1];
  if (month.length !== 2) {
    return false;
  }
  const year = dateSplit[2];
  if (year.length !== 4) {
    return false;
  }
  const d = new Date(Number(year), Number(month) - 1, Number(day));
  if (
    d.getFullYear() !== Number(year) ||
    d.getMonth() !== Number(month) - 1 ||
    d.getDate() !== Number(day)
  ) {
    return false;
  }

  return regMonth.test(month);
};

export const formatDateFr = (date: Date | undefined): string => {
  if (date === undefined) return '';

  const tempDate = new Date(date);

  const day = tempDate.getDate() < 10 ? `0${tempDate.getDate()}` : tempDate.getDate();
  const month =
    tempDate.getMonth() + 1 < 10
      ? `0${tempDate.getMonth() + 1}`
      : tempDate.getMonth() + 1;
  const formatedDate = `${day}/${month}/${tempDate.getFullYear()}`;

  return `${formatedDate}`;
};

export const isDateIntheFuture = (date?: Date): boolean => {
  if (date) return date.getTime() < Date.now();
  return false;
};

export const formatDateFrHour = (date: Date | undefined): string => {
  if (date === undefined) return '';

  const tempDate = new Date(date);

  const day = tempDate.getDate() < 10 ? `0${tempDate.getDate()}` : tempDate.getDate();
  const month =
    tempDate.getMonth() + 1 < 10
      ? `0${tempDate.getMonth() + 1}`
      : tempDate.getMonth() + 1;
  const hours =
    tempDate.getHours() < 10 ? `0${tempDate.getHours()}` : tempDate.getHours();
  const minutes =
    tempDate.getMinutes() < 10 ? `0${tempDate.getMinutes()}` : tempDate.getMinutes();

  const formatedDate = `${day}/${month}/${tempDate.getFullYear()}`;
  return `${formatedDate} à ${hours}h${minutes}`;
};

export const formatDateToMonthYear = (date: Date | undefined): string => {
  if (date === undefined) return '';

  const tempDate = new Date(date);

  const month =
    tempDate.getMonth() + 1 < 10
      ? `0${tempDate.getMonth() + 1}`
      : tempDate.getMonth() + 1;

  return `${month}/${tempDate.getFullYear()}`;
};

export const formatDateForInput = (
  dateString: string,
  type: 'day' | 'month' | 'year',
): string => {
  let newString = dateString;

  const lastCharIsSlash = newString.charAt(newString.length - 1) === '/';
  if (lastCharIsSlash) {
    // removes slashes at the end of the string
    newString = newString.slice(0, newString.length - 1);
  }

  const typeIsMonthOrDay = type !== 'year';
  if (typeIsMonthOrDay) {
    // adds slash between day and month if necessary
    if (!newString.includes('/') && newString.length > 2) {
      newString = `${newString.slice(0, 2)}/${newString.slice(2)}`;
    }
  }

  if (type === 'day') {
    // adds another slash between month and year if necessary
    if (newString.charAt(5) !== '/' && newString.length > 5) {
      newString = `${newString.slice(0, 5)}/${newString.slice(5)}`;
    }
  }

  return newString;
};

export const dateForPdf = (): string => {
  const tempDate = new Date();

  const day = tempDate.getDate() < 10 ? `0${tempDate.getDate()}` : tempDate.getDate();
  const month =
    tempDate.getMonth() + 1 < 10
      ? `0${tempDate.getMonth() + 1}`
      : tempDate.getMonth() + 1;
  const hours =
    tempDate.getHours() < 10 ? `0${tempDate.getHours()}` : tempDate.getHours();
  const minutes =
    tempDate.getMinutes() < 10 ? `0${tempDate.getMinutes()}` : tempDate.getMinutes();
  const secondes =
    tempDate.getSeconds() < 10 ? `0${tempDate.getSeconds()}` : tempDate.getSeconds();

  const formatedDate = `${day}${month}${tempDate.getFullYear()}${hours}${minutes}${secondes}`;
  return formatedDate;
};

export const getPastDayFromToday = (nbrDay: number): Date => {
  const today = new Date();
  return new Date(today.setDate(today.getDate() - nbrDay));
};
