import BaseButton from 'components/designSystem/BaseButton';
import styled from 'styled-components';

const StyledNextButton = styled(BaseButton)`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.chartColors.mainGreen500};
  height: 4.8rem;
  width: auto;
  ${({ disabled }) =>
    disabled &&
    `
    background: #B1B5B9;
    cursor: not-allowed;
  `};
`;

export default StyledNextButton;
