import * as React from 'react';

const SmallChevronDown: React.FC = () => (
  <svg
    width="14px"
    height="8px"
    viewBox="0 0 14 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>B0AA54F6-7D7E-49C3-A70B-5C15B6F086B6</title>
    <g
      id="Consultation-dossier-📑"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="Consultation-d'un-dossier/en-cours-de-montage/operation/client/1"
        transform="translate(-299.000000, -1112.000000)"
        fill="#4B4F54">
        <g id="card/bien" transform="translate(264.000000, 686.000000)">
          <g id="replier/information/1-copy" transform="translate(32.000000, 420.000000)">
            <g
              id="Icon/24px/Navigation/chevron-up"
              transform="translate(10.370370, 10.000000) rotate(180.000000) translate(-10.370370, -10.000000) translate(3.456790, 6.666667)">
              <path
                d="M0.225642193,0.195788381 C0.36973406,0.0704442592 0.565383607,1.77635684e-15 0.769418342,1.77635684e-15 C0.973453078,1.77635684e-15 1.16910263,0.0704442592 1.31319449,0.195788381 L6.91378162,5.0567172 L12.5143687,0.195788381 C12.814688,-0.0647968513 13.3016018,-0.0647968481 13.601921,0.195788388 C13.9022403,0.456373625 13.9022403,0.878865769 13.601921,1.13945101 L7.45755777,6.47087829 C7.3134659,6.59622241 7.11781636,6.66666667 6.91378162,6.66666667 C6.70974688,6.66666667 6.51409734,6.59622241 6.37000547,6.47087829 L0.225642193,1.13945101 C0.0811855995,1.01442336 0,0.844659416 0,0.667619695 C0,0.490579975 0.0811855995,0.320816033 0.225642193,0.195788381 Z"
                id="Path-Copy-2"
                transform="translate(6.913580, 3.333333) rotate(-180.000000) translate(-6.913580, -3.333333) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SmallChevronDown;
