import { getCharges } from 'api/referentialService';
import TextButton from 'components/buttons/textButton/TextButton';
import Space from 'components/space/Space';
import ToggleButton from 'components/toggleButton/ToggleButton';
import { removeFromInvalidInputs, userIsRentingHouse } from 'containers/communs/utils';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import React, { useEffect, useState } from 'react';
import {
  Amount,
  RessourcesMensuellesAction,
  RessourcesMensuellesState,
} from 'reducers/ressourcesMensuelles/types';
import { CodeLibelle } from 'types';
import { calculateTotalCharges, formatNumberByThousand } from 'utils/commun';
import AmountInput from './AmountInput';
import OtherInputsModal from './modals/OtherInputsModal';
import { StyledSumWrapper } from './style';

interface OtherChargesProps {
  checkInputs: boolean;
  state: RessourcesMensuellesState;
  dispatch: React.Dispatch<RessourcesMensuellesAction>;
}
const OtherCharges: React.FC<OtherChargesProps> = ({ checkInputs, state, dispatch }) => {
  const [userHasOtherCharges, setUserHasOtherCharges] = useState<boolean>(
    state.hasMonthlyCharges || false,
  );
  const [chargesReferential, setChargesReferential] = useState<CodeLibelle[]>([]);
  const [isOtherChargesModalVisible, setIsOtherChargesModalVisible] =
    useState<boolean>(false);
  const [totalCharges, setTotalCharges] = useState<number>(0);
  const [showRentingCharge, setShowRentingCharge] = useState<boolean>(false);

  const RENT_CHARGE_CODE = 'LA';

  /**
   * Si l'utilisateur a choisi "Locataire" dans la page "Situation foyer", au niveau de la page "Ressources et charges", on
   * doit afficher automatiquement la charge "Location résidence principale"
   * @param data
   */
  const displayDefaultRentingCharge = (data: CodeLibelle[]) => {
    const isRenting = userIsRentingHouse();
    setShowRentingCharge(isRenting);
    const rentingCharge = state.monthlyCharges.find(
      item => item.code === RENT_CHARGE_CODE,
    );
    if (isRenting && !rentingCharge) {
      setUserHasOtherCharges(true);
      const charges = [...state.monthlyCharges];
      charges.push({
        id: charges.length + 1,
        code: RENT_CHARGE_CODE,
        libelle: data.find(item => item.code === RENT_CHARGE_CODE)?.libelle || '',
        value: '',
      });
      dispatch({
        type: 'setHasMonthlyCharges',
        payload: true,
      });
      dispatch({
        type: 'setMonthlyCharges',
        payload: charges,
      });
    }
  };

  useEffect(() => {
    if (state.hasMonthlyCharges && state.monthlyCharges.length === 0) {
      dispatch({ type: 'setHasMonthlyCharges', payload: !userHasOtherCharges });
      setUserHasOtherCharges(prevState => !prevState);
    }

    const fetchRessources = async () => {
      try {
        const response = await getCharges();
        const { Resultat, Success } = response;
        if (Success) {
          const data: CodeLibelle[] = [];
          Resultat.forEach(item => {
            data.push({ code: item.code, libelle: item.libelle });
          });
          setChargesReferential(data);
          displayDefaultRentingCharge(data);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchRessources();
  }, []);

  useEffect(() => {
    setTotalCharges(calculateTotalCharges(state));
  }, [state.monthlyCharges]);

  const renderSumOfCharges = (): React.ReactNode => {
    if (totalCharges === 0) {
      return null;
    }
    return (
      <>
        <Space marginTop="3.65rem" />
        <StyledSumWrapper>
          <div>
            <span style={{ fontSize: '1.8rem' }}>
              Total des charges :{' '}
              <b style={{ fontSize: '2.0rem' }}>{`${formatNumberByThousand(
                totalCharges.toString(),
              )} €`}</b>
            </span>
          </div>
        </StyledSumWrapper>
      </>
    );
  };

  const deleteCharge = (incomeToDelete: number) => {
    dispatch({
      type: 'setMonthlyCharges',
      payload: state.monthlyCharges.filter(item => {
        return item.id !== incomeToDelete;
      }),
    });
  };

  const saveSelectedItems = (
    newSelectedItemsList: Amount[],
    reducerOperation: 'setMonthlyCharges' | 'setOtherIncomes',
  ) => {
    const existingSelectedItems =
      reducerOperation === 'setMonthlyCharges'
        ? [...state.monthlyCharges]
        : [...state.otherIncomes];

    let id = existingSelectedItems.length + 1;
    const newSelectedItems = newSelectedItemsList.map(charge => {
      const newCharge = { ...charge };
      newCharge.id = id;
      id += 1;
      return newCharge;
    });
    newSelectedItems.forEach(newSelectedNeed => {
      existingSelectedItems.push(newSelectedNeed);
    });

    dispatch({
      type: reducerOperation,
      payload: existingSelectedItems,
    });
  };

  /**
   * Si l'utilisateur est locataire:
   *  -si on a un seul input "Location résidence principale", alors on ne peut pas le supprimer. Le bouton "Supprimer" associé à cet input est grisé.
   *  -s'il ajoute plus d'un input "Location résidence principale", alors le bouton "Supprimer" associé à cet input est actif
   * @param chargeCode
   * @returns
   */
  const disableDeleteAction = (chargeCode: string) => {
    if (!showRentingCharge) {
      return false;
    }
    const allRentingCharges = state.monthlyCharges.filter(
      item => item.code === RENT_CHARGE_CODE,
    );
    if (
      allRentingCharges &&
      allRentingCharges.length === 1 &&
      allRentingCharges[0].code === chargeCode
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!state.hasMonthlyCharges) removeFromInvalidInputs(state, dispatch, 'otherCharge');
    if (state.hasMonthlyCharges && userHasOtherCharges !== state.hasMonthlyCharges)
      setUserHasOtherCharges(state.hasMonthlyCharges);
  }, [state.hasMonthlyCharges]);

  const renderSelectedOtherChargesInput = () => {
    return (
      <>
        {state.monthlyCharges.map(item => {
          return (
            <React.Fragment key={item.id}>
              <AmountInput
                name="charges-mensuelles"
                itemId={item.id}
                checkInputs={checkInputs}
                typeOfAmount="charge"
                label={item.libelle}
                state={state}
                dispatch={dispatch}
                onDeleteIncome={deleteCharge}
                disableDeleteAction={disableDeleteAction(item.code)}
              />
              <Space marginBottom="2.4rem" />
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Space marginTop="2.4rem" />
      <ToggleButton
        id="other-charges"
        isOn={showRentingCharge || userHasOtherCharges}
        isDisabled={showRentingCharge}
        handleToggle={() => {
          if (showRentingCharge) {
            return;
          }
          dispatch({ type: 'setHasMonthlyCharges', payload: !userHasOtherCharges });
          setUserHasOtherCharges(prevState => !prevState);
        }}>
        <span
          style={{
            marginRight: '1rem',
            display: 'block',
            marginTop: '0.3rem',
            height: '3.2rem',
            fontWeight: 'bold',
            fontSize: '1.6rem',
          }}>
          Des charges mensuelles
        </span>
      </ToggleButton>
      <Space marginTop="1.6rem" />
      {state.monthlyCharges.length > 0 && userHasOtherCharges ? (
        <>{renderSelectedOtherChargesInput()}</>
      ) : null}
      {userHasOtherCharges && (
        <>
          <Space marginTop="2.8rem" />
          <TextButton
            label="Ajouter une nouvelle charge"
            onClick={() => {
              setIsOtherChargesModalVisible(true);
            }}>
            <PlusCircleIcon />
          </TextButton>
        </>
      )}
      {renderSumOfCharges()}

      <OtherInputsModal
        isModalVisible={isOtherChargesModalVisible}
        alreadySelectedInputs={state.monthlyCharges}
        onSaveSelectedInputs={(newSelectedCharges: Amount[]) => {
          setIsOtherChargesModalVisible(false);
          saveSelectedItems(newSelectedCharges, 'setMonthlyCharges');
        }}
        onCloseModal={(): void => {
          setIsOtherChargesModalVisible(false);
        }}
        referential={chargesReferential}
        inputType="Charge"
      />
    </>
  );
};

export default OtherCharges;
