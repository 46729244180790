const PowerUpIcon: React.FC = () => {
  return (
    <svg
      width="18px"
      height="20px"
      viewBox="0 0 18 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>Icon/24px/Action/start</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menu-Copy-5" transform="translate(-27.000000, -1057.000000)">
          <g id="Deconnexion" transform="translate(24.000000, 1055.000000)">
            <g id="Icon/24px/Action/start" transform="translate(-470.000000, 0.000000)">
              <g
                id="_x31_"
                transform="translate(473.000000, 2.000000)"
                fill="#4B4F54"
                fillRule="nonzero">
                <g id="Group">
                  <path
                    d="M9.12928177,8.14 C9.45745856,8.14 9.72596685,7.87 9.72596685,7.54 L9.72596685,0.6 C9.72596685,0.27 9.45745856,0 9.12928177,0 C8.80110497,0 8.53259669,0.27 8.53259669,0.6 L8.53259669,7.54 C8.53259669,7.88 8.80110497,8.14 9.12928177,8.14 Z"
                    id="Path"
                  />
                  <path
                    d="M15.1856354,4.37 C14.9469613,4.14 14.5690608,4.15 14.3403315,4.39 C14.1116022,4.63 14.121547,5.01 14.360221,5.24 C15.9414365,6.74 16.8066298,8.77 16.8066298,10.95 C16.8066298,15.28 13.3060773,18.8 9,18.8 C4.69392265,18.8 1.19337017,15.28 1.19337017,10.95 C1.19337017,8.72 2.13812155,6.59 3.78895028,5.1 C4.03756906,4.88 4.05745856,4.5 3.83867403,4.25 C3.6198895,4 3.24198895,3.98 2.99337017,4.2 C1.0839779,5.92 0,8.37 0,10.94 C0,15.94 4.03756906,20 9,20 C13.9624309,20 18,15.94 18,10.95 C18,8.43 17.0055249,6.09 15.1856354,4.37 Z"
                    id="Path"
                  />
                </g>
              </g>
              <g id="hourglass-bottom" transform="translate(0.000000, 1268.286767)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PowerUpIcon;
