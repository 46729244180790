import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';

interface CancelButtonProps {
  handleonClick: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = ({ handleonClick }) => {
  return (
    <>
      <StyledSecondaryButton
        onClick={handleonClick}
        onKeyDown={e => {
          if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter')
            handleonClick();
        }}
        width="9.7"
        tabIndex={0}>
        <span>Annuler</span>
      </StyledSecondaryButton>
    </>
  );
};

export default CancelButton;
