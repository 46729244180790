import Space from 'components/space/Space';
import { isSansGarantie } from 'containers/communs/utils';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import {
  StyleCardGrid,
  StyleCardGridItem,
  StyledCardData,
  StyledCardLabel,
  StyledCardRow,
  StyledCardTile,
  StyledCardTitle,
  StyledMainDisplayCard,
} from 'containers/consultation/style';
import { OperationState } from 'reducers/operationClient/types';
import { DebtsAdditionalInformationState } from 'reducers/debtsAdditionalInformation/type';
import { FinalisationProjetState } from 'reducers/finalisationProjet/types';
import { GuaranteeAdditionalInformationState } from 'reducers/guaranteeAdditionalInformation/type';
import { IbanState } from 'reducers/iban/type';
import { InterlocutorState } from 'reducers/interlocutor/type';
import { LoanAdditionalInformationState } from 'reducers/loanAdditionalInformation/type';
import { UserInfos } from 'types';
import * as messages from '../messages';
import FinalisationDeptConsultation from './finalisationDeptConsultation/finalisationDeptConsultation';
import FinalisationGarantieConsulation from './finalisationGarantieConsultation/finalisationGarantieConsultation';
import FinalisationIbanConsultation from './finalisationIbanConsulatation/finalisationIbanConsulatation';
import FinalisationInterlocuteurConsultation from './finalisationInterlocuteurConsultation/finalisationInterlocuteurConsultation';
import FinalisationPretRacheterConsulation from './finalisationPretRacheterConsultation/finalisationPretRacheterConsultation';

interface FinalisationProjetConsultationProps {
  isProjectEditable: boolean | undefined;
}

const FinalisationProjetConsultation: React.FC<FinalisationProjetConsultationProps> = ({
  isProjectEditable,
}) => {
  const finalisationProjet = JSON.parse(
    sessionStorage.getItem('finalisationProjet') || '{}',
  ) as FinalisationProjetState;

  const operationClientState = JSON.parse(
    sessionStorage.getItem('operationsClient') || '{}',
  ) as OperationState;

  const guaranteeAdditionalInformation = JSON.parse(
    sessionStorage.getItem('guaranteeAdditionalInformation') || '{}',
  ) as GuaranteeAdditionalInformationState;

  const debtAdditionalInformation = JSON.parse(
    sessionStorage.getItem('debtAdditionalInformation') || '{}',
  ) as DebtsAdditionalInformationState;

  const loanAdditionalInformation = JSON.parse(
    sessionStorage.getItem('loanAdditionalInformation') || '{}',
  ) as LoanAdditionalInformationState;

  const iban = JSON.parse(sessionStorage.getItem('iban') || '{}') as IbanState;

  const authenticatedUser = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  const interlocutor = JSON.parse(
    sessionStorage.getItem('interlocutor') || '{}',
  ) as InterlocutorState;

  const renderFinalisationProjetInfo = () => {
    const getAddressBorrower = (): string => {
      return `${finalisationProjet?.borrower.address.streetNumber} ${finalisationProjet?.borrower.address.streetType?.libelle} ${finalisationProjet?.borrower.address.street}`;
    };
    return (
      <>
        {isSansGarantie() && (
          <>
            <StyledCardTitle>{messages.FINALISATION_EDITION_MODE}</StyledCardTitle>
            <Space marginBottom="1.6rem" />
            <StyledCardData>{finalisationProjet?.editionType}</StyledCardData>
            <Space marginBottom="3.2rem" />
          </>
        )}

        <StyledCardTitle>
          {messages.FINALISATION_INFO_CARTE_IDENTITE_EMPRUNTEUR.titre}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyleCardGrid>
          <StyleCardGridItem>
            <StyledCardLabel>
              {messages.FINALISATION_INFO_CARTE_IDENTITE_EMPRUNTEUR.type}
            </StyledCardLabel>
            <StyledCardData>
              {finalisationProjet?.borrower.identityType?.libelle}
            </StyledCardData>
          </StyleCardGridItem>
          <StyleCardGridItem>
            <StyledCardLabel>
              {messages.FINALISATION_INFO_CARTE_IDENTITE_EMPRUNTEUR.numero}
            </StyledCardLabel>
            <StyledCardData>{finalisationProjet?.borrower.identityNumber}</StyledCardData>
          </StyleCardGridItem>
        </StyleCardGrid>

        <Space marginBottom="3.2rem" />

        <StyledCardTitle>
          {messages.FINALISATION_INFO_COMPLEMANT_DOMICILE.titre}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyleCardGrid>
          <StyleCardGridItem>
            <StyledCardLabel>
              {messages.FINALISATION_INFO_COMPLEMANT_DOMICILE.adresse}
            </StyledCardLabel>
            <StyledCardData>{getAddressBorrower()}</StyledCardData>
          </StyleCardGridItem>
          {finalisationProjet?.borrower.address.additionalInformation && (
            <StyleCardGridItem>
              <StyledCardLabel>
                {messages.FINALISATION_INFO_COMPLEMANT_DOMICILE.compl}
              </StyledCardLabel>
              <StyledCardData>
                {finalisationProjet?.borrower.address.additionalInformation}
              </StyledCardData>
            </StyleCardGridItem>
          )}
          {finalisationProjet?.borrower.address.POBoxOrHamlet && (
            <StyleCardGridItem>
              <StyledCardLabel>
                {messages.FINALISATION_INFO_COMPLEMANT_DOMICILE.boiteLieu}
              </StyledCardLabel>
              <StyledCardData>
                {finalisationProjet?.borrower.address.POBoxOrHamlet}
              </StyledCardData>
            </StyleCardGridItem>
          )}
          <StyleCardGridItem>
            <StyledCardLabel>{messages.MAIN_CARD_LABEL_ZIP_CODE}</StyledCardLabel>
            <StyledCardData>
              {finalisationProjet?.borrower.address.zipCode}
            </StyledCardData>
          </StyleCardGridItem>
          <StyleCardGridItem>
            <StyledCardLabel>{messages.MAIN_CARD_LABEL_CITY}</StyledCardLabel>
            <StyledCardData>{finalisationProjet?.borrower.address.city}</StyledCardData>
          </StyleCardGridItem>
        </StyleCardGrid>

        <Space marginBottom="3.2rem" />

        {(finalisationProjet?.borrower.email ||
          finalisationProjet?.borrower.cellPhoneNumber ||
          finalisationProjet?.borrower.homePhoneNumber) && (
          <>
            <StyledCardTitle>
              {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.titre}
            </StyledCardTitle>

            <Space marginBottom="1.6rem" />

            <StyledCardRow>
              {finalisationProjet?.borrower.email ? (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.mail}
                  </StyledCardLabel>
                  <StyledCardData>{finalisationProjet?.borrower.email}</StyledCardData>
                </StyleCardGridItem>
              ) : (
                <></>
              )}
              {finalisationProjet?.borrower.cellPhoneNumber ? (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.cellPhone}
                  </StyledCardLabel>
                  <StyledCardData>
                    {finalisationProjet?.borrower.cellPhoneNumber}
                  </StyledCardData>
                </StyleCardGridItem>
              ) : (
                <></>
              )}
              {finalisationProjet?.borrower.homePhoneNumber ? (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.homePhone}
                  </StyledCardLabel>
                  <StyledCardData>
                    {finalisationProjet?.borrower.homePhoneNumber}
                  </StyledCardData>
                </StyleCardGridItem>
              ) : (
                <></>
              )}
            </StyledCardRow>

            <Space marginBottom="3.2rem" />
          </>
        )}

        {finalisationProjet?.borrower.employerName && (
          <>
            <StyledCardTitle>
              {messages.FINALISATION_INFO_PROFESSIONNEL.titre}
            </StyledCardTitle>

            <Space marginBottom="1.6rem" />

            <StyledCardRow>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_PROFESSIONNEL.label}
                </StyledCardLabel>
                <StyledCardData>
                  {finalisationProjet?.borrower.employerName}
                </StyledCardData>
              </StyledCardTile>
            </StyledCardRow>

            <Space marginBottom="3.2rem" />
          </>
        )}
      </>
    );
  };

  const renderCoBorrowerInfo = () => {
    return (
      <>
        <StyledCardTitle>
          {messages.FINALISATION_INFO_CARTE_IDENTITE_EMPRUNTEUR.coTitre}
        </StyledCardTitle>

        <Space marginBottom="1.6rem" />

        <StyleCardGrid>
          <StyleCardGridItem>
            <StyledCardLabel>
              {messages.FINALISATION_INFO_CARTE_IDENTITE_EMPRUNTEUR.type}
            </StyledCardLabel>
            <StyledCardData>
              {finalisationProjet?.coBorrower?.identityType?.libelle}
            </StyledCardData>
          </StyleCardGridItem>
          <StyleCardGridItem>
            <StyledCardLabel>
              {messages.FINALISATION_INFO_CARTE_IDENTITE_EMPRUNTEUR.numero}
            </StyledCardLabel>
            <StyledCardData>
              {finalisationProjet?.coBorrower?.identityNumber}
            </StyledCardData>
          </StyleCardGridItem>
        </StyleCardGrid>

        <Space marginBottom="3.2rem" />

        {(finalisationProjet?.coBorrower?.email ||
          finalisationProjet?.coBorrower.cellPhoneNumber ||
          finalisationProjet?.coBorrower.homePhoneNumber) && (
          <>
            <StyledCardTitle>
              {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.coTitre}
            </StyledCardTitle>

            <Space marginBottom="1.6rem" />

            <StyledCardRow>
              {finalisationProjet?.coBorrower?.email ? (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.mail}
                  </StyledCardLabel>
                  <StyledCardData>{finalisationProjet?.coBorrower.email}</StyledCardData>
                </StyleCardGridItem>
              ) : (
                <></>
              )}
              {finalisationProjet?.coBorrower?.cellPhoneNumber ? (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.cellPhone}
                  </StyledCardLabel>
                  <StyledCardData>
                    {finalisationProjet?.coBorrower.cellPhoneNumber}
                  </StyledCardData>
                </StyleCardGridItem>
              ) : (
                <></>
              )}
              {finalisationProjet?.coBorrower?.homePhoneNumber ? (
                <StyleCardGridItem>
                  <StyledCardLabel>
                    {messages.FINALISATION_INFO_COORDONNEES_EMPRUNTEUR.homePhone}
                  </StyledCardLabel>
                  <StyledCardData>
                    {finalisationProjet?.coBorrower.homePhoneNumber}
                  </StyledCardData>
                </StyleCardGridItem>
              ) : (
                <></>
              )}
            </StyledCardRow>

            <Space marginBottom="3.2rem" />
          </>
        )}

        {finalisationProjet?.coBorrower?.employerName && (
          <>
            <StyledCardTitle>
              {messages.FINALISATION_INFO_PROFESSIONNEL.coTitre}
            </StyledCardTitle>

            <Space marginBottom="1.6rem" />

            <StyledCardRow>
              <StyledCardTile>
                <StyledCardLabel>
                  {messages.FINALISATION_INFO_PROFESSIONNEL.label}
                </StyledCardLabel>
                <StyledCardData>
                  {finalisationProjet?.coBorrower.employerName}
                </StyledCardData>
              </StyledCardTile>
            </StyledCardRow>

            <Space marginBottom="3.2rem" />
          </>
        )}
      </>
    );
  };

  return (
    <StyledMainDisplayCard>
      {renderFinalisationProjetInfo()}

      {finalisationProjet?.coBorrower?.identityType && renderCoBorrowerInfo()}

      {guaranteeAdditionalInformation?.guarantees?.length > 0 && (
        <FinalisationGarantieConsulation
          guaranteeInfo={operationClientState.assetList}
          guaranteeAdditionalInformation={guaranteeAdditionalInformation}
        />
      )}

      {loanAdditionalInformation?.loans?.length > 0 &&
        operationClientState.loanList.filter(loan => {
          return loan.buyBack;
        })?.length > 0 && (
          <>
            <Space marginBottom="3.2rem" />
            <FinalisationPretRacheterConsulation
              loansInfo={operationClientState.loanList.filter(loan => {
                return loan.buyBack;
              })}
              loanAdditionalInformation={loanAdditionalInformation}
            />
          </>
        )}

      {debtAdditionalInformation?.debts?.length > 0 && (
        <>
          <Space marginBottom="3.2rem" />
          <FinalisationDeptConsultation
            debtAdditionalInformation={debtAdditionalInformation}
          />
        </>
      )}

      {iban && !iban.isEmpty && (
        <>
          <Space marginBottom="3.2rem" />
          <FinalisationIbanConsultation iban={iban} />
        </>
      )}

      {interlocutor && (
        <>
          <Space marginBottom="3.2rem" />
          <FinalisationInterlocuteurConsultation
            user={authenticatedUser}
            interlocutor={interlocutor}
          />
        </>
      )}

      <ConsultationEditButton
        isProjectEditable={isProjectEditable}
        navigateRoute="/finalisation_projet"
      />
    </StyledMainDisplayCard>
  );
};

export default FinalisationProjetConsultation;
