import React, { useState } from 'react';
import InformationArea from 'components/informationArea/InformationArea';
import WarningIcon from 'icons/WarningIcon';
import CustomInput from 'components/customInput/CustomInput';
import { CalculetteFinanciereAction, Projet } from 'reducers/calculetteFinanciere/type';
import { StyledSectionTitle, StyledMarginDiv } from '../style';
import * as messages from '../messages';

interface FinancingFormProps {
  projectState: Projet;
  calculetteFinanciereDispatch: React.Dispatch<CalculetteFinanciereAction>;
  getValueByThousand: (value: number | undefined) => string | undefined;
}

const FinancingForm: React.FC<FinancingFormProps> = ({
  projectState,
  calculetteFinanciereDispatch,
  getValueByThousand,
}) => {
  const [isNatureImmoFocused, setIsNatureImmoFocused] = useState<boolean>(false);
  const [isnatureImmoValid, setIsnatureImmoValid] = useState<boolean>();

  const [isNatureConsoFocused, setIsNatureConsoFocused] = useState<boolean>(false);
  const [isnatureConsoValid, setIsnatureConsoValid] = useState<boolean>();

  const handleNatureImmo = (value: string) => {
    const rate = parseInt(value.replaceAll(' ', '').slice(0, 10), 10);
    if (Number.isNaN(rate) && rate.toString().length < 11) {
      calculetteFinanciereDispatch({
        type: 'setBesoinNatureImmobiliere',
        payload: undefined,
      });
    } else {
      calculetteFinanciereDispatch({
        type: 'setBesoinNatureImmobiliere',
        payload: rate,
      });
    }
  };

  const handleNatureConso = (value: string) => {
    const rate = parseInt(value.replaceAll(' ', '').slice(0, 10), 10);
    if (Number.isNaN(rate)) {
      calculetteFinanciereDispatch({
        type: 'setBesoinNatureConsommation',
        payload: undefined,
      });
    } else {
      calculetteFinanciereDispatch({
        type: 'setBesoinNatureConsommation',
        payload: rate,
      });
    }
  };

  const onNatureImmoBlur = () => {
    if (projectState.besoinNatureImmobiliere !== undefined) setIsnatureImmoValid(true);
  };

  const onNatureConsoBlur = () => {
    if (projectState.besoinNatureConsommation !== undefined) setIsnatureConsoValid(true);
  };

  return (
    <>
      <StyledSectionTitle>{messages.CALCULATOR_FORM.financingTitle}</StyledSectionTitle>
      <StyledMarginDiv>
        <InformationArea
          title={messages.CALCULATOR_FORM.financingInfo}
          backgroundColor="#DEF6FD"
          icon={<WarningIcon color="#0066BF" />}
          width="90%"
          height="56px"
        />
      </StyledMarginDiv>
      <StyledMarginDiv>
        <CustomInput
          name="nature-immo"
          label={messages.CALCULATOR_FORM.immo}
          type="price"
          value={getValueByThousand(projectState?.besoinNatureImmobiliere)}
          onFocus={() => {
            setIsNatureImmoFocused(true);
          }}
          onChange={newValue => {
            handleNatureImmo(newValue);
          }}
          onBlur={() => {
            setIsNatureImmoFocused(false);
            onNatureImmoBlur();
          }}
          placeholder="0"
          isFocused={isNatureImmoFocused}
          isValid={isnatureImmoValid}
          after="€"
          inputWidth="40rem"
        />
      </StyledMarginDiv>
      <StyledMarginDiv>
        <CustomInput
          name="nature-conso"
          label={messages.CALCULATOR_FORM.conso}
          type="price"
          value={getValueByThousand(projectState.besoinNatureConsommation)}
          onFocus={() => {
            setIsNatureConsoFocused(true);
          }}
          onChange={newValue => {
            handleNatureConso(newValue);
          }}
          onBlur={() => {
            setIsNatureConsoFocused(false);
            onNatureConsoBlur();
          }}
          placeholder="0"
          isFocused={isNatureConsoFocused}
          isValid={isnatureConsoValid}
          after="€"
          inputWidth="40rem"
        />
      </StyledMarginDiv>
    </>
  );
};

export default FinancingForm;
