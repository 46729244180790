import { getRessources } from 'api/referentialService';
import TextButton from 'components/buttons/textButton/TextButton';
import { StyledTooltip } from 'components/customInput/style';
import InformationArea from 'components/informationArea/InformationArea';
import Space from 'components/space/Space';
import ToggleButton from 'components/toggleButton/ToggleButton';
import { removeFromInvalidInputs } from 'containers/communs/utils';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import WarningIcon from 'icons/WarningIcon';
import React, { useState, useEffect } from 'react';
import {
  Amount,
  RessourcesMensuellesAction,
  RessourcesMensuellesState,
} from 'reducers/ressourcesMensuelles/types';
import { CodeLibelle } from 'types';
import { calculateTotalIncomes, formatNumberByThousand } from 'utils/commun';
import AmountInput from './AmountInput';
import OtherInputsModal from './modals/OtherInputsModal';
import { StyledSumWrapper } from './style';

interface OtherIncomesProps {
  checkInputs: boolean;
  state: RessourcesMensuellesState;
  dispatch: React.Dispatch<RessourcesMensuellesAction>;
}
const OtherIncomes: React.FC<OtherIncomesProps> = ({ checkInputs, state, dispatch }) => {
  const [userHasOtherIncomes, setUserHasOtherIncomes] = useState<boolean>(
    state.hasOtherIncomes || false,
  );
  const [isOtherIncomesModalVisible, setIsOtherIncomesModalVisible] =
    useState<boolean>(false);
  const [ressourcesReferential, setRessourcesReferential] = useState<CodeLibelle[]>([]);
  const [totalIncomes, setTotalIncomes] = useState<string>('0');

  const renderOtherIncomes = (): React.ReactNode => {
    if (userHasOtherIncomes) {
      return (
        <>
          <Space marginTop="1.6rem" />
          <div style={{ width: '94rem' }}>
            <InformationArea
              icon={<WarningIcon color="#2275D7" />}
              backgroundColor="blue"
              title={
                <p style={{ margin: '0', fontWeight: 'bold' }}>
                  Quels sont les autres revenus ?
                </p>
              }>
              <p style={{ margin: '0', marginTop: '0.5rem', fontSize: '1.4rem' }}>
                {`Il s'agit des allocations familiales, des revenus locatifs, des pensions alimentaires perçues, des rentes d'invalidité, etc.`}
              </p>
            </InformationArea>
          </div>
          <Space marginTop="2.8rem" />
        </>
      );
    }
    return null;
  };

  const renderSumOfIncomes = (): React.ReactNode => {
    if (totalIncomes === '0') {
      return null;
    }
    return (
      <>
        <Space marginTop="3.65rem" />
        <StyledSumWrapper>
          <div>
            <span style={{ fontSize: '1.8rem' }}>
              Total des revenus :{' '}
              <b style={{ fontSize: '2.0rem' }}>{`${formatNumberByThousand(
                totalIncomes,
              )}€`}</b>
            </span>
          </div>
        </StyledSumWrapper>
      </>
    );
  };

  const deleteIncome = (incomeToDelete: number) => {
    dispatch({
      type: 'setOtherIncomes',
      payload: state.otherIncomes.filter(item => {
        return item.id !== incomeToDelete;
      }),
    });
  };

  const saveSelectedItems = (
    newSelectedItemsList: Amount[],
    reducerOperation: 'setMonthlyCharges' | 'setOtherIncomes',
  ) => {
    const existingSelectedItems =
      reducerOperation === 'setMonthlyCharges'
        ? [...state.monthlyCharges]
        : [...state.otherIncomes];

    let id = existingSelectedItems.length + 1;
    const newSelectedItems = newSelectedItemsList.map(income => {
      const newIncome = { ...income };
      newIncome.id = id;
      id += 1;
      return newIncome;
    });

    newSelectedItems.forEach(newSelectedNeed => {
      existingSelectedItems.push(newSelectedNeed);
    });

    dispatch({
      type: reducerOperation,
      payload: existingSelectedItems,
    });
  };

  const renderSelectedOtherIncomesInput = () => {
    return state.otherIncomes.map(item => {
      return (
        <React.Fragment key={item.id}>
          <AmountInput
            name="autres-revenus"
            typeOfAmount="income"
            itemId={item.id}
            label={item.libelle}
            checkInputs={checkInputs}
            state={state}
            dispatch={dispatch}
            onDeleteIncome={deleteIncome}
          />
          {item.libelle === 'Retraite' ? (
            <>
              <Space marginTop="0.4rem" />
              <StyledTooltip>Retraite(s), pré-retraite(s).</StyledTooltip>
            </>
          ) : (
            <></>
          )}
          <Space marginBottom="2.4rem" />
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    setTotalIncomes(calculateTotalIncomes(state).toString());
  }, [state.otherIncomes, { ...state.borrowerIncome }, { ...state.coBorrowerIncome }]);

  useEffect(() => {
    const fetchRessources = async () => {
      try {
        const response = await getRessources();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: CodeLibelle[] = [];
          Resultat.forEach(item => {
            temp.push({ code: item.code, libelle: item.libelle });
          });
          setRessourcesReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchRessources();
  }, []);

  useEffect(() => {
    if (!state.hasOtherIncomes) removeFromInvalidInputs(state, dispatch, 'otherIncome');
    if (state.hasOtherIncomes && userHasOtherIncomes !== state.hasOtherIncomes)
      setUserHasOtherIncomes(state.hasOtherIncomes);
  }, [state.hasOtherIncomes]);

  return (
    <>
      <Space marginTop="5.6rem" />
      <ToggleButton
        id="other-incomes"
        isOn={userHasOtherIncomes}
        handleToggle={() => {
          dispatch({ type: 'setHasOtherIncomes', payload: !userHasOtherIncomes });
          setUserHasOtherIncomes(prevState => !prevState);
        }}>
        <span
          style={{
            marginRight: '1rem',
            display: 'block',
            marginTop: '0.3rem',
            height: '3.2rem',
            fontWeight: 'bold',
            fontSize: '1.6rem',
          }}>{`Déclarer d'autres revenus`}</span>
      </ToggleButton>
      {renderOtherIncomes()}
      {state.otherIncomes.length > 0 && userHasOtherIncomes ? (
        <>{renderSelectedOtherIncomesInput()}</>
      ) : null}
      {userHasOtherIncomes && (
        <>
          <Space marginTop="2.8rem" />
          <TextButton
            label="Ajouter un nouveau revenu"
            onClick={() => {
              setIsOtherIncomesModalVisible(true);
            }}>
            <PlusCircleIcon />
          </TextButton>
        </>
      )}
      {renderSumOfIncomes()}

      <OtherInputsModal
        isModalVisible={isOtherIncomesModalVisible}
        alreadySelectedInputs={state.otherIncomes}
        onSaveSelectedInputs={(newSelectedCharges: Amount[]) => {
          setIsOtherIncomesModalVisible(false);
          saveSelectedItems(newSelectedCharges, 'setOtherIncomes');
        }}
        onCloseModal={(): void => {
          setIsOtherIncomesModalVisible(false);
        }}
        referential={ressourcesReferential}
        inputType="Income"
      />
    </>
  );
};

export default OtherIncomes;
