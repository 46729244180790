import React from 'react';
import EbordereauContainer from './EbordereauContainer';

const EBordereauContainer: React.FC = () => {
  return (
    <>
      <EbordereauContainer />
    </>
  );
};

export default EBordereauContainer;
