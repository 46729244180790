import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import {
  AssuranceEmprunteur,
  CalculetteFinanciereAction,
} from 'reducers/calculetteFinanciere/type';

interface ITTInsuranceProps {
  person: 'borrower' | 'coBorrower';
  dispatch: React.Dispatch<CalculetteFinanciereAction>;
  getPersonWarranty: AssuranceEmprunteur;
}

const ITTInsurance: React.FC<ITTInsuranceProps> = ({
  person,
  dispatch,
  getPersonWarranty,
}) => {
  const uncheckJobLossIfSG = () => {
    // le décoche de ITT entraine automatiquement
    // le décoche de l'assurance perte emploi et la suppression de sa valeur
    dispatch({
      type: 'setGarantiePerteEmploi',
      payload: { person, value: 'N' },
    });
    dispatch({
      type: 'setQuotitePerteEmploi',
      payload: { person, value: undefined },
    });
  };

  /**
   * Sinon, la quotité est égale à la valeur de l'assurance décés
   * @returns
   */
  const getQuotite = () => {
    const value = getPersonWarranty.quotiteITT;
    if (value) {
      return `${value}%`;
    }
    return '0%';
  };

  const isITTChecked = (): boolean | undefined => {
    return getPersonWarranty?.garantieITT === 'O' ? true : undefined;
  };

  return (
    <div style={{ marginTop: '4.5rem', display: 'flex', alignContent: 'center' }}>
      <BaseCheckbox
        checked={isITTChecked()}
        onCheckedChanged={e => {
          if ((e as CustomEvent)?.detail?.value) {
            dispatch({
              type: 'setGarantieITT',
              payload: { person, value: 'O' },
            });
            dispatch({
              type: 'setQuotiteITT',
              payload: { person, value: getPersonWarranty.quotiteDeces },
            });
          } else {
            dispatch({
              type: 'setGarantieITT',
              payload: { person, value: 'N' },
            });
            dispatch({
              type: 'setQuotiteITT',
              payload: { person, value: undefined },
            });
            uncheckJobLossIfSG();
          }
        }}
      />
      <span
        style={{
          marginLeft: '0.8rem',
          color: '#4B4F54',
        }}>
        {`Garantie ITT/IPT ${getQuotite()}`}
      </span>
    </div>
  );
};

export default ITTInsurance;
