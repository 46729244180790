import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import CommentTile from 'components/commentTile/commentTile';
import CommentSearchPieceJustificatif from 'components/commentTile/CommentSearchPieceJustificatif';
import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import { ChemiseData } from 'reducers/transmissionPieces/types';
import { dateForPdf } from 'utils/DateUtils';
import CommentNavPieceJustificatif from 'components/commentTile/CommentNavPieceJustificatif';
import * as messages from '../messages';

export interface ZoneCommantaireProps {
  listPiece: string[];
  onUploadSuccess: (data: ChemiseData) => void;
}
export interface ListPiece {
  label: string;
  value: string;
}

const ZoneCommantaire: React.FC<ZoneCommantaireProps> = ({
  listPiece,
  onUploadSuccess,
}) => {
  const [commentText, setCommentText] = useState<string>('');
  const [missingComment, setMissingComment] = useState<boolean>(false);
  const [titleText, setTitleText] = useState<string>('');
  const [missingTitle, setMissingTitle] = useState<boolean>(false);
  const [carCountComment, setCarCountComment] = useState<number>(0);
  const [carCountTitle, setCarCountTitle] = useState<number>(0);
  const [PieceSelected, setPieceSelected] = useState<readonly ListPiece[]>([]);

  useEffect(() => {
    setCarCountComment(commentText.length);
  }, [commentText]);

  useEffect(() => {
    setCarCountTitle(titleText.length);
  }, [titleText]);

  const commentToPdf = (comment: string): File => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    // doc.__private__.setPdfVersion('1.4');
    doc.setFontSize(16);

    const pageHeight = doc.internal.pageSize.height;
    const lineSize = 30;
    let y = 7;

    const addtext = (text: string) => {
      const splitText = doc.splitTextToSize(text, doc.internal.pageSize.width - lineSize);
      splitText.push('');
      splitText.forEach(element => {
        if (y > pageHeight - lineSize) {
          y = 10;
          doc.addPage();
        }
        doc.text(element, 15, y);
        y += 7;
      });
    };

    addtext("JOD2 / Justificatifs d'opérations / Commentaires");
    doc.setFontSize(12);
    addtext('Libellé(s) du justificatif(s) : ');
    PieceSelected.forEach(piece => {
      addtext(piece.value);
    });
    addtext('Commentaires : ');
    addtext(comment);

    return new File([doc.output('blob')], `${titleText}-${dateForPdf()}.pdf`);
  };

  const handleCommentAdd = (comment: string) => {
    setMissingComment(false);
    setMissingTitle(false);
    if (comment !== '' && titleText !== '') {
      const pdf = commentToPdf(comment);
      const chemiseAj: ChemiseData = {
        id: '010',
        files: [{ id: uuidv4(), content: pdf }],
      };
      onUploadSuccess(chemiseAj);
      setCommentText('');
      setTitleText('');
      setPieceSelected([]);
    }
    if (comment === '') {
      setMissingComment(true);
    }
    if (titleText === '') {
      setMissingTitle(true);
    }
  };

  return (
    <>
      <StyledTitle style={{ margin: '0', fontSize: '2rem' }}>
        {messages.MAIN_COMMENT_TITLE}
      </StyledTitle>
      <CommentSearchPieceJustificatif
        listPiece={listPiece}
        selectedPiece={PieceSelected}
        setSelectedPiece={setPieceSelected}
      />
      <Space marginTop="4rem" />
      <CommentNavPieceJustificatif
        selectedPiece={PieceSelected}
        setSelectedPiece={setPieceSelected}
      />
      <Space marginTop="1.6rem" />
      <CommentTile
        comment={commentText}
        setComment={setCommentText}
        title={titleText}
        setTitle={setTitleText}
        carCountComment={carCountComment}
        carCountTitle={carCountTitle}
        pieceCount={PieceSelected.length}
        onAddCommentClick={value => {
          handleCommentAdd(value);
        }}
        missingComment={missingComment}
        missingTitle={missingTitle}
      />
    </>
  );
};

export default ZoneCommantaire;
