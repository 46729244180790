import { useEffect, useState } from 'react';
import { BaseRadioButton } from 'components/designSystem/BaseRadioButton';
import InformationArea from 'components/informationArea/InformationArea';
import Space from 'components/space/Space';
import parse from 'html-react-parser';
import { StyledTitle } from 'containers/communs/style';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import {
  addInInvalidInputs,
  isEtudePartagee,
  isSansGarantie,
  removeFromInvalidInputs,
} from 'containers/communs/utils';
import { getAuthenticatedUserHabilitations } from 'utils/commun';
import LightIcon from 'icons/LightIcon';
import { StyledErrorMessage } from 'components/customInput/style';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../messages';
import BorrowerIdentity from './BorrowerIdentity';
import CoBorrowerIdentity from './CoBorrowerIdentity';

interface BlockIdentityProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  checkInputs: boolean;
}
const BlockIdentity: React.FC<BlockIdentityProps> = ({
  state,
  dispatch,
  checkInputs,
}) => {
  const [isPackContractuelChecked, setIsPackContractuelChecked] = useState<
    boolean | undefined
  >();
  const [isPackContractuelUnChecked, setIsPackContractuelUnChecked] = useState<
    boolean | undefined
  >();
  const [isEsignatureChecked, setIsEsignatureChecked] = useState<boolean | undefined>();
  const [isPapierSignatureChecked, setIsPaperSignatureChecked] = useState<
    boolean | undefined
  >();

  const { dmatInd, habiPackInd } = getAuthenticatedUserHabilitations();

  useEffect(() => {
    dispatch({
      type: 'setPackContractuel',
      payload: isPackContractuelChecked || false,
    });
  }, [isPackContractuelChecked]);

  useEffect(() => {
    let editType: 'e-signature' | 'papier' | undefined;
    if (isEsignatureChecked) {
      editType = 'e-signature';
    } else if (isPapierSignatureChecked) {
      editType = 'papier';
    }

    dispatch({
      type: 'setEditionType',
      payload: editType,
    });
  }, [isPapierSignatureChecked, isEsignatureChecked]);

  const userHasHabilitations = () => {
    return (dmatInd || habiPackInd) && isEtudePartagee();
  };

  useEffect(() => {
    if (state.editionType === 'papier') {
      setIsPaperSignatureChecked(true);
    } else if (state.editionType === 'e-signature') {
      setIsEsignatureChecked(true);
    }
    if (isSansGarantie() && userHasHabilitations())
      addInInvalidInputs(state, dispatch, 'PackContract');
  }, []);

  const renderPackCheckbox = () => {
    return (
      <>
        <StyledTitle>{messages.PACK_CONTRACTUEL_TEXT}</StyledTitle>
        <Space marginTop="2.4rem" />
        <div style={{ display: 'flex', gap: '3.7rem' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="pack-contractuel"
              checked={isPackContractuelChecked ? true : undefined}
              onClick={() => {
                if (!isPackContractuelChecked) {
                  removeFromInvalidInputs(state, dispatch, 'PackContract');
                  setIsPackContractuelChecked(true);
                } else {
                  addInInvalidInputs(state, dispatch, 'PackContract');
                  setIsPackContractuelChecked(undefined);
                }
                setIsPackContractuelUnChecked(undefined);
              }}
            />
            <span>Oui</span>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="pack-contractuel"
              checked={isPackContractuelUnChecked ? true : undefined}
              onClick={() => {
                removeFromInvalidInputs(state, dispatch, 'PackContract');
                if (!isPackContractuelUnChecked) {
                  setIsPackContractuelUnChecked(true);
                } else {
                  addInInvalidInputs(state, dispatch, 'PackContract');
                  setIsPackContractuelUnChecked(undefined);
                }
                setIsPackContractuelChecked(undefined);
              }}
            />
            <span>Non</span>
          </div>
        </div>
        {!isPackContractuelChecked && !isPackContractuelUnChecked && checkInputs && (
          <>
            <Space marginTop="2.4rem" />
            <StyledErrorMessage>{messagesCheckInput.REQUIRED_VALUE}</StyledErrorMessage>
          </>
        )}
      </>
    );
  };

  const renderEdition = () => {
    return (
      <>
        <StyledTitle>{messages.EDITION_OFFER_TEXT}</StyledTitle>
        <Space marginTop="2.4rem" />
        <div style={{ display: 'flex', gap: '3.7rem' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="edition-offer"
              checked={isEsignatureChecked ? true : undefined}
              onClick={() => {
                if (!isEsignatureChecked) {
                  setIsEsignatureChecked(true);
                } else {
                  setIsEsignatureChecked(undefined);
                }
                setIsPaperSignatureChecked(undefined);
              }}
            />
            <span>E-Signature</span>
          </div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <BaseRadioButton
              id="edition-offer"
              checked={isPapierSignatureChecked ? true : undefined}
              onClick={() => {
                if (!isPapierSignatureChecked) {
                  setIsPaperSignatureChecked(true);
                } else {
                  setIsPaperSignatureChecked(undefined);
                }
                setIsEsignatureChecked(undefined);
              }}
            />
            <span>Papier</span>
          </div>
        </div>
        {!isEsignatureChecked && !isPapierSignatureChecked && checkInputs && (
          <>
            <Space marginTop="2.4rem" />
            <StyledErrorMessage>{messagesCheckInput.REQUIRED_VALUE}</StyledErrorMessage>
          </>
        )}
      </>
    );
  };

  const renderCoBorrowerIdentity = () => {
    const coBorrower = sessionStorage.getItem('coBorrowerExists');
    if (coBorrower && JSON.parse(coBorrower) === true) {
      return (
        <>
          <Space marginTop="5.6rem" />
          <CoBorrowerTitle width="46.4rem" />
          <Space marginTop="2.4rem" />
          <CoBorrowerIdentity
            state={state}
            dispatch={dispatch}
            checkInputs={checkInputs}
          />
        </>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        border: `1px solid #1068D0`,
        borderRadius: '0.4rem',
      }}>
      <div
        style={{ margin: '0 3rem 3rem 3rem', display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginTop: '-5rem', width: '64.8rem' }}>
          <InformationArea icon={<LightIcon />} backgroundColor="blue" height="21.2rem">
            <p style={{ margin: '0', fontWeight: 'bold' }}>{messages.INFO_TEXT_TITLE}</p>
            <p style={{ margin: '0', marginTop: '0.5rem', fontSize: '1.4rem' }}>
              {parse(messages.INFO_COMPL_TEXT)}
            </p>
          </InformationArea>
        </div>
        <div>
          <Space marginTop="3.2rem" />
          {isSansGarantie() && (
            <>
              {userHasHabilitations() && (
                <>
                  {renderPackCheckbox()}
                  <Space marginTop="5.6rem" />
                </>
              )}
              {renderEdition()}
              <Space marginTop="5.6rem" />
            </>
          )}
          {!isSansGarantie() && <Space marginTop="3.6rem" />}
          <BorrowerIdentity state={state} dispatch={dispatch} checkInputs={checkInputs} />
          {renderCoBorrowerIdentity()}
        </div>
      </div>
    </div>
  );
};

export default BlockIdentity;
