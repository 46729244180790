import * as React from 'react';

const IncomeIcon: React.FC = () => (
  <svg width={41} height={54} xmlns="http://www.w3.org/2000/svg">
    <title>C6419B08-819E-4BE0-9D60-E1CE95C86353</title>
    <g fill="#292C2E" fillRule="nonzero">
      <path d="M25.333.333H7A6.667 6.667 0 0 0 .333 7v40A6.667 6.667 0 0 0 7 53.667h26.667A6.667 6.667 0 0 0 40.333 47V15.333l-15-15Zm0 3.334v6.666a5 5 0 0 0 5 5H37V47a3.333 3.333 0 0 1-3.333 3.333H7A3.333 3.333 0 0 1 3.667 47V7A3.333 3.333 0 0 1 7 3.667h18.333Z" />
      <path d="M18.74 11.768a4.686 4.686 0 0 0-1.714-.324c-2.101 0-3.905 1.394-4.658 3.371h-1.281a.753.753 0 1 0 0 1.507h.93a11.41 11.41 0 0 0 0 1.101h-.93a.753.753 0 1 0 0 1.507h1.283c.753 1.974 2.557 3.37 4.658 3.37.48 0 .955-.073 1.414-.217a.753.753 0 1 0-.455-1.438c-.31.098-.633.146-.961.146-1.266 0-2.378-.744-3-1.861h3.195a.753.753 0 1 0 0-1.507h-3.686a9.131 9.131 0 0 1 0-1.101h3.688a.753.753 0 1 0 0-1.507h-3.196c.623-1.117 1.733-1.861 3-1.861.402 0 .794.073 1.166.219a.753.753 0 1 0 .547-1.405ZM30.333 28.333c0 .737-.597 1.334-1.333 1.334H11.667a1.333 1.333 0 1 1 0-2.667H29c.736 0 1.333.597 1.333 1.333ZM30.333 35c0 .736-.597 1.333-1.333 1.333H11.667a1.333 1.333 0 0 1 0-2.666H29c.736 0 1.333.597 1.333 1.333ZM21.444 41.667c0 .736-.597 1.333-1.333 1.333h-8.444a1.333 1.333 0 1 1 0-2.667h8.444c.736 0 1.333.597 1.333 1.334Z" />
    </g>
  </svg>
);

export default IncomeIcon;
