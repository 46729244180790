import {
  CodeLibelle,
  OperationReferentialApiResponse,
  Profession,
  ReferentialApiResponse,
} from 'types';

export const getRefEmprunteur = async (
  listgetReferentiel: Promise<ReferentialApiResponse | OperationReferentialApiResponse>[],
): Promise<CodeLibelle[][]> => {
  const listCodeLibelle = Promise.allSettled(listgetReferentiel).then(result => {
    const listRef = result.map(refRes => {
      const refCodeLibelle: (CodeLibelle | Profession)[] = [];

      if (refRes.status === 'fulfilled' && refRes.value.Success) {
        refRes.value.Resultat.forEach(item => {
          refCodeLibelle.push({
            code: item.code,
            libelle: item.libelle,
            // ...(item.ordreString !== '' ? { ordreString: item.ordreString } : {}),
          });
        });
      }

      return refCodeLibelle;
    });
    return listRef;
  });

  return listCodeLibelle;
};

export default getRefEmprunteur;
