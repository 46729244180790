import { Bordereau, Partner, SortEnum } from 'types';
import {
  BordereauKeys,
  BordereauState,
  UpdateBordereauFiltersPayload,
} from './bordereau';
import { PartnerKeys, PartnerState, UpdateFiltersPayload } from './partner';

export const filterPartnerList = (state: PartnerState): PartnerState => {
  const newState = { ...state };
  if (
    !newState.filterPartnersById &&
    !newState.filterPartnersByName &&
    !newState.filterPartnersByNumber
  ) {
    newState.displayedPartners = newState.partnersCurrentOrdering;
  } else {
    let result = [...newState.partnersCurrentOrdering];
    if (newState.filterPartnersById) {
      result = result.filter(item => {
        return (
          item.idIntv
            .toString()
            .toLowerCase()
            .indexOf(newState.filterPartnersById.toLowerCase()) >= 0
        );
      });
    }
    if (newState.filterPartnersByNumber) {
      result = result.filter(item => {
        return (
          item.numeroVendeur
            .toLowerCase()
            .indexOf(newState.filterPartnersByNumber.toLowerCase()) >= 0
        );
      });
    }
    if (newState.filterPartnersByName) {
      result = result.filter(item => {
        return (
          item.nomCourtier
            .toLowerCase()
            .indexOf(newState.filterPartnersByName.toLowerCase()) >= 0
        );
      });
    }
    newState.displayedPartners = result;
  }
  return newState;
};

export const updateFilters = (
  state: PartnerState,
  payload: UpdateFiltersPayload,
): PartnerState => {
  const newState = { ...state };
  if (payload.filteringKey === 'numeroVendeur') {
    newState.filterPartnersByNumber = payload.text;
  }
  if (payload.filteringKey === 'nomCourtier') {
    newState.filterPartnersByName = payload.text;
  }
  if (payload.filteringKey === 'idIntv') {
    newState.filterPartnersById = payload.text;
  }
  return newState;
};

export const handleAllPartnerCheckboxChange = (state: PartnerState): PartnerState => {
  const newState = { ...state };
  const ids: string[] = [];
  if (!newState.isAllPartnersChecked) {
    newState.idsOfCheckedParterns = [];
  } else {
    newState.displayedPartners.forEach(item => {
      ids.push(item.idAPA);
    });
    newState.idsOfCheckedParterns = ids;
  }
  newState.isAllPartnersChecked = !newState.isAllPartnersChecked;

  return newState;
};

const sortAscending = (
  listToSort: Partner[] | Bordereau[],
  sortingKey: string,
): Partner[] | Bordereau[] => {
  return listToSort?.sort((item1, item2) => {
    if (item1[sortingKey] > item2[sortingKey]) {
      return -1;
    }
    return 0;
  });
};
const sortDescending = (
  listToSort: Partner[] | Bordereau[],
  sortingKey: string,
): Partner[] | Bordereau[] => {
  return listToSort?.sort((item1, item2) => {
    if (item1[sortingKey] < item2[sortingKey]) {
      return -1;
    }
    return 0;
  });
};

export const sortPartners = (
  state: PartnerState,
  sortingKey: PartnerKeys,
): PartnerState => {
  const newState = { ...state };
  let sortResult: Partner[] = [];
  if (sortingKey === 'numeroVendeur') {
    if (newState.sortPartnersByNumber === SortEnum.ASC) {
      sortResult = sortAscending(newState.displayedPartners, sortingKey) as Partner[];
      newState.sortPartnersByNumber = SortEnum.DESC;
    } else {
      sortResult = sortDescending(newState.displayedPartners, sortingKey) as Partner[];
      newState.sortPartnersByNumber = SortEnum.ASC;
    }
  }
  if (sortingKey === 'nomCourtier') {
    if (newState.sortPartnersByName === SortEnum.ASC) {
      sortResult = sortAscending(newState.displayedPartners, sortingKey) as Partner[];
      newState.sortPartnersByName = SortEnum.DESC;
    } else {
      sortResult = sortDescending(newState.displayedPartners, sortingKey) as Partner[];
      newState.sortPartnersByName = SortEnum.ASC;
    }
  }
  if (sortingKey === 'idIntv') {
    if (newState.sortPartnersById === SortEnum.ASC) {
      sortResult = sortAscending(newState.displayedPartners, sortingKey) as Partner[];
      newState.sortPartnersById = SortEnum.DESC;
    } else {
      sortResult = sortDescending(newState.displayedPartners, sortingKey) as Partner[];
      newState.sortPartnersById = SortEnum.ASC;
    }
  }

  newState.partnerCurrentSortingKey = sortingKey;
  newState.partnersCurrentOrdering = sortResult;
  newState.displayedPartners = sortResult;
  return newState;
};

export const filterBordereauList = (state: BordereauState): BordereauState => {
  const newState = { ...state };
  if (
    !state.filterBordereauxByName &&
    !state.selectedBordereauFilter &&
    !state.filterBordereauxByPerComi
  ) {
    newState.displayedBordereaux = state.bordereauCurrentOrdering;
    // setDisplayedBordereaux(bordereauCurrentOrdering);
  } else {
    let result = [...newState.bordereauCurrentOrdering];
    if (state.filterBordereauxByName) {
      result = result.filter(item => {
        return (
          item.nomCourtier
            .toLowerCase()
            .indexOf(state.filterBordereauxByName.toLowerCase()) >= 0
        );
      });
    }
    if (state.filterBordereauxByPerComi) {
      result = result.filter(item => {
        return (
          item.perComi
            .toLowerCase()
            .indexOf(state.filterBordereauxByPerComi.toLowerCase()) >= 0
        );
      });
    }
    if (state.selectedBordereauFilter) {
      result = result.filter(item => {
        return (
          item.typeBordereau
            .toLowerCase()
            .indexOf(state.selectedBordereauFilter.toLowerCase()) >= 0
        );
      });
    }
    newState.displayedBordereaux = result;
    // setDisplayedBordereaux(result);
  }
  return newState;
};

export const updateBordereauFilters = (
  state: BordereauState,
  payload: UpdateBordereauFiltersPayload,
): BordereauState => {
  const newState = { ...state };
  if (payload.filteringKey === 'perComi') {
    newState.filterBordereauxByPerComi = payload.text;
  }
  if (payload.filteringKey === 'nomCourtier') {
    newState.filterBordereauxByName = payload.text;
  }
  return newState;
};

export const sortBordereaux = (
  state: BordereauState,
  sortingKey: BordereauKeys,
): BordereauState => {
  const newState = { ...state };
  let sortResult: Bordereau[] = [];
  if (sortingKey === 'nomCourtier') {
    if (newState.sortBordereauxByName === SortEnum.ASC) {
      sortResult = sortAscending(newState.displayedBordereaux, sortingKey) as Bordereau[];
      newState.sortBordereauxByName = SortEnum.DESC;
    } else {
      sortResult = sortDescending(
        newState.displayedBordereaux,
        sortingKey,
      ) as Bordereau[];
      newState.sortBordereauxByName = SortEnum.ASC;
    }
  }
  if (sortingKey === 'perComi') {
    if (newState.sortBordereauxByPerComi === SortEnum.ASC) {
      sortResult = sortAscending(newState.displayedBordereaux, sortingKey) as Bordereau[];
      newState.sortBordereauxByPerComi = SortEnum.DESC;
    } else {
      sortResult = sortDescending(
        newState.displayedBordereaux,
        sortingKey,
      ) as Bordereau[];
      newState.sortBordereauxByPerComi = SortEnum.ASC;
    }
  }
  if (sortingKey === 'typeBordereau') {
    if (newState.sortBordereauxByType === SortEnum.ASC) {
      sortResult = sortAscending(newState.displayedBordereaux, sortingKey) as Bordereau[];
      newState.sortBordereauxByType = SortEnum.DESC;
    } else {
      sortResult = sortDescending(
        newState.displayedBordereaux,
        sortingKey,
      ) as Bordereau[];
      newState.sortBordereauxByType = SortEnum.ASC;
    }
  }

  newState.bordereauCurrentSortingKey = sortingKey;
  newState.bordereauCurrentOrdering = sortResult;
  newState.displayedBordereaux = sortResult;
  return newState;
};
