import { ReactNode, useState } from 'react';
import CustomInput from './CustomInput';

interface LightCustomInputProps {
  name: string;
  className?: string;
  label?: string | ReactNode;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  allowedValues?: RegExp;
  maxSize?: number;
  error?: string;
  isValid?: boolean;
  isDisabled?: boolean;
  hideIcon?: boolean;
}

const LightCustomInput: React.FC<LightCustomInputProps> = ({
  name,
  className,
  label,
  placeholder,
  value,
  onChange,
  allowedValues,
  maxSize,
  error,
  isValid,
  isDisabled,
  hideIcon,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const valueIsLegal = (newValue: string): boolean => {
    if (allowedValues && !newValue.match(allowedValues)) return false;
    if (maxSize && newValue.length > maxSize) return false;
    return true;
  };

  return (
    <CustomInput
      name={name}
      className={`light-custom-input${className ? ` ${className}` : ''}`}
      label={label}
      placeholder={placeholder}
      value={value}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onChange={newValue => {
        if (valueIsLegal(newValue)) {
          onChange(newValue);
        }
      }}
      inputWidth="100%"
      isFocused={isFocused}
      isValid={isValid}
      error={error}
      isDisabled={isDisabled}
      hideIcon={hideIcon}
    />
  );
};

export default LightCustomInput;
