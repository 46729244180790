import { Dispatch } from 'react';
import Select, { components, ControlProps } from 'react-select';
import LookingGlassIcon from 'icons/LookingGlassIcon';
import { ListPiece } from 'containers/consultation/transmissionPiecesConsultation/zoneCommentaire';

interface CommentTileProps {
  listPiece: string[];
  selectedPiece: readonly ListPiece[];
  setSelectedPiece: Dispatch<React.SetStateAction<readonly ListPiece[]>>;
}

const Control: React.FC = ({ children, ...props }: ControlProps) => (
  <components.Control {...props}>
    <div style={{ marginLeft: '18px', marginRight: '4px' }}>
      <LookingGlassIcon />
    </div>
    {children}
  </components.Control>
);

const CommentSearchPieceJustificatif: React.FC<CommentTileProps> = ({
  listPiece,
  selectedPiece,
  setSelectedPiece,
}) => {
  return (
    <>
      <Select
        isMulti
        controlShouldRenderValue={false}
        components={{
          Control,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        options={listPiece.map(item => {
          return { label: item, value: item };
        })}
        placeholder="Rechercher un justificatif"
        value={selectedPiece}
        onChange={(newValue, action) => {
          if (action.action === 'pop-value') {
            return;
          }
          setSelectedPiece(newValue);
        }}
      />
    </>
  );
};

export default CommentSearchPieceJustificatif;
