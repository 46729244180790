import * as React from 'react';

const GuaranteeIcon: React.FC = () => (
  <svg width={38} height={54} xmlns="http://www.w3.org/2000/svg">
    <title>B5E785BB-6623-4665-BE6B-219253734F0E</title>
    <path
      d="M18.636.333c10.109 0 18.303 7.805 18.303 17.432 0 5.856-3.032 11.038-7.683 14.199L32.08 52.23c.147 1.054-1.05 1.803-2.007 1.256L18.636 46.95 7.201 53.486c-.957.547-2.155-.202-2.008-1.256L8.02 31.966C3.367 28.805.333 23.622.333 17.765.333 8.138 8.528.333 18.636.333Zm0 34.863c-2.918 0-5.677-.65-8.125-1.807L8.2 49.953l9.75-5.571a1.39 1.39 0 0 1 1.371 0l9.75 5.57-2.307-16.565a18.978 18.978 0 0 1-8.129 1.81Zm0-32.323C10.001 2.873 3 9.54 3 17.765c0 8.224 7 14.892 15.636 14.892a16.14 16.14 0 0 0 8.331-2.288c.084-.069.178-.127.28-.174 4.234-2.663 7.026-7.236 7.026-12.43 0-8.225-7-14.892-15.637-14.892Z"
      fill="#4B4F54"
      fillRule="evenodd"
    />
  </svg>
);

export default GuaranteeIcon;
