import styled from 'styled-components';

export const StyledMainFeedbackContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12.8rem;
  flex-direction: column;
`;

export const StyledBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.span`
  color: #268038;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: bold;
`;

export const StyledSubTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: bold;
`;

export const StyledEmissionSuccessContainer = styled.div`
  border-radius: 4px;
  background-color: #eff8f0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
  display: flex;
  align-items: center;
`;

export const StyledEmissionSuccessText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const StyledWarningContainer = styled.div`
  border-radius: 4px;
  background-color: #fef2f4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
  display: flex;
  gap: 1rem;
`;

export const StyledWarningContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledWarningTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
`;

export const StyledWarningText = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
`;

export const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const StyledDowloadCardWrapper = styled.div`
  box-sizing: border-box;
  width: 20.9rem;
  border: 1px solid #6f757c;
  border-radius: 8px;
`;

export const StyledDowloadCardElement = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  align-items: center;
`;
