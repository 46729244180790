export const MAIN_TITLE_WELCOME = `Bienvenue aux professionnels, partenaires de Sygma `;

export const HIGHLIGHT_BOX_DESCRIPTION =
  'Sygma Connect est un espace unique conçu pour une collaboration et une expérience de travail optimale.';
export const HIGHLIGHT_BOX_USAGE =
  'Créez vos dossiers, suivez vos projets, vos financements et accédez à vos informations commerciales dans un environnement élaboré pour vous.';

export const ACCESS_BOX_MAIN_TITLE = 'Vous êtes un particulier ?';
export const ACCESS_BOX_DESCRIPTION = `Vous souhaitez en savoir plus sur l'entreprise ou contacter nos équipes :`;
export const ACCESS_BOX_BUTTON_LABEL = `Accès au site Sygma BNP Paribas Personal Finance`;

export const LEGAL_MENTIONS_LABEL = 'Mentions Légales';
export const LINKEDIN_NEWS_LABEL = 'Retrouvez nos actualités sur';

export const CONNECTION_PANEL_DESC = 'Accédez à votre espace personnel et sécurisé.';
export const CONNECTION_PANEL_BUTTON_LABEL = 'Se connecter';
