import { GetSimulationResultApiResponse } from 'api/simulationService';
import ConsultationEditButton from 'containers/consultation/components/consultationEditButton';
import { StyledMainDisplayCard } from 'containers/consultation/style';
import { renderResultBody } from 'containers/result/ResultForm';
import { useEffect, useState } from 'react';

interface ResultatsConsultationProps {
  isProjectEditable?: boolean;
}

const ResultatsConsultation: React.FC<ResultatsConsultationProps> = ({
  isProjectEditable,
}) => {
  const [simulationResponse, setSimulationResponse] =
    useState<GetSimulationResultApiResponse>();

  useEffect(() => {
    const simulationResultObject = sessionStorage.getItem('simulationResult');

    if (simulationResultObject) {
      const simulationResult = JSON.parse(
        simulationResultObject,
      ) as GetSimulationResultApiResponse;
      setSimulationResponse(simulationResult);
    }
  }, []);

  return (
    <>
      <StyledMainDisplayCard>
        {simulationResponse && renderResultBody(simulationResponse)}

        <ConsultationEditButton
          isProjectEditable={isProjectEditable}
          navigateRoute="/resultat"
        />
      </StyledMainDisplayCard>
    </>
  );
};

export default ResultatsConsultation;
