import Space from 'components/space/Space';
import CommentIcon from 'icons/CommentIcon';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import { Dispatch, SetStateAction } from 'react';
import {
  StyledCommentWrapper,
  StyledCommentCountWrapper,
  StyledCommentTextArea,
  StyledCommentNumberPGWrapper,
  StyledAddCommentButton,
  StyledTitleWrapper,
  StyledTitleCountWrapper,
  StyledErrorMessage,
} from './style';
import * as messages from './messages';

interface CommentTileProps {
  comment: string;
  setComment: Dispatch<SetStateAction<string>>;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  carCountComment: number;
  carCountTitle: number;
  onAddCommentClick: (comment: string) => void;
  pieceCount: number;
  missingComment: boolean;
  missingTitle: boolean;
}

const CommentTile: React.FC<CommentTileProps> = ({
  comment,
  setComment,
  title,
  setTitle,
  carCountComment,
  carCountTitle,
  onAddCommentClick,
  pieceCount,
  missingComment,
  missingTitle,
}) => {
  const visibility = pieceCount === 0 ? 'hidden' : 'visible';

  return (
    <>
      <StyledTitleWrapper>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <StyledTitleCountWrapper>
            {`${carCountTitle}/49 caractères`}
          </StyledTitleCountWrapper>
          <StyledCommentTextArea
            rows={1}
            cols={86}
            placeholder="Écrire un titre..."
            value={title}
            maxLength={49}
            onChange={e => {
              setTitle(e.target.value);
            }}
          />
        </div>
      </StyledTitleWrapper>
      <StyledCommentWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <StyledCommentCountWrapper>
            {`${carCountComment}/1000 caractères`}
          </StyledCommentCountWrapper>
          <div style={{ display: 'flex' }}>
            <CommentIcon />
            <div
              style={{ display: 'flex', flexDirection: 'column', marginLeft: '1.6rem' }}>
              <Space marginTop="0.8rem" />
              <StyledCommentTextArea
                rows={12}
                cols={86}
                placeholder="Écrire un commentaire..."
                value={comment}
                maxLength={1000}
                onChange={e => {
                  setComment(e.target.value);
                }}
              />
            </div>
          </div>
          <Space marginTop="4rem" />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', visibility }}>
              <StyledCommentNumberPGWrapper>{pieceCount}</StyledCommentNumberPGWrapper>
              <div style={{ color: '#4b4f54', fontSize: '14px', marginLeft: '0.8rem' }}>
                {' '}
                {messages.SELECTED_PIECES}{' '}
              </div>
            </div>
            <StyledAddCommentButton
              onClick={() => onAddCommentClick(comment)}
              style={{ display: 'flex', gap: '0.5rem' }}>
              <PlusCircleIcon />
              <span>{messages.ADD_COMMENT}</span>
            </StyledAddCommentButton>
          </div>
        </div>
      </StyledCommentWrapper>
      {missingTitle ? (
        <StyledErrorMessage>{messages.MISSING_TITLE_ERROR}</StyledErrorMessage>
      ) : null}
      {missingComment ? (
        <StyledErrorMessage>{messages.MISSING_COMMENT_ERROR}</StyledErrorMessage>
      ) : null}
    </>
  );
};

export default CommentTile;
