import { createComponent } from '@lit-labs/react';
import React from 'react';
import { DsStatus } from 'types';
import { CustomValidity, Replacement } from './DesignSystemTypes';

export class ComboInputDS extends HTMLElement {
  label?: string;

  required?: boolean;

  items?: string;

  filteredItems?: unknown[];

  class?: string;

  placeholder?: string;

  value: unknown;

  filteredValue: unknown;

  replacement?: string | Replacement[];

  shadow?: boolean;

  maxLength?: string;

  pattern?: string;

  selected?: boolean;

  field?: string;

  disabled?: boolean;

  status?: DsStatus;

  invalid?: boolean;

  valid?: boolean;

  customValidityPattern?: string | CustomValidity[];

  hasFocus?: boolean;

  filtration?: ({ displayedLabel }: { displayedLabel: string }) => boolean;
}

export const ComboInput = createComponent(React, 'combo-input', ComboInputDS, {
  onSelectedList: 'selected-list',
  onListBlur: 'element-blured',
  onInputChange: 'input-changed',
  onFocus: 'element-focused',
  onLetterChanged: 'letter-changed',
  onItemsChanged: 'items-changed',
});
