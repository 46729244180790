import CustomInput from 'components/customInput/CustomInput';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import { useState, useEffect } from 'react';
import {
  AssuranceEmprunteur,
  CalculetteFinanciereAction,
} from 'reducers/calculetteFinanciere/type';

interface DeathInsuranceProps {
  person: 'borrower' | 'coBorrower';
  dispatch: React.Dispatch<CalculetteFinanciereAction>;
  getPersonWarranty: AssuranceEmprunteur;
  isSG: boolean;
}

const DeathInsurance: React.FC<DeathInsuranceProps> = ({
  person,
  dispatch,
  getPersonWarranty,
  isSG,
}) => {
  const [isDeathInsuranceRateInputFocused, setIsDeathInsuranceRateInputFocused] =
    useState<boolean>(false);
  const [isDeathInsuranceRateInputValid, setIsDeathInsuranceRateInputValid] = useState<
    boolean | undefined
  >();
  const [deathInsuranceRateInputError, setDeathInsuranceRateInputError] =
    useState<string>('');

  const handleDeathInsuranceRateChange = (value: string) => {
    const rate = parseInt(value, 10);
    if (Number.isNaN(rate)) {
      dispatch({
        type: 'setQuotiteDeces',
        payload: { person, value: undefined },
      });
      dispatch({
        type: 'setGarantieDeces',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setQuotiteDeces',
        payload: { person, value: rate },
      });
      dispatch({
        type: 'setGarantieDeces',
        payload: { person, value: 'O' },
      });
    }
  };

  const handleDeathInsuranceRateBlur = () => {
    if (isSG) {
      return;
    }
    setIsDeathInsuranceRateInputFocused(false);
    if (
      getPersonWarranty.quotiteDeces === undefined ||
      Number.isNaN(getPersonWarranty.quotiteDeces) ||
      (!isSG && getPersonWarranty.quotiteDeces <= 0)
    ) {
      setIsDeathInsuranceRateInputValid(false);
      setDeathInsuranceRateInputError('Saisie incorrecte.');
    } else {
      setIsDeathInsuranceRateInputValid(true);
      setDeathInsuranceRateInputError('');
    }
    if (!isSG) {
      dispatch({
        type: 'setGarantiePTIA',
        payload: { person, value: 'O' },
      });
      dispatch({
        type: 'setQuotitePTIA',
        payload: { person, value: getPersonWarranty.quotiteDeces },
      });
      dispatch({
        type: 'setGarantieITT',
        payload: { person, value: 'O' },
      });
      dispatch({
        type: 'setQuotiteITT',
        payload: { person, value: getPersonWarranty.quotiteDeces },
      });
    }
  };

  useEffect(() => {
    if (isSG) {
      setIsDeathInsuranceRateInputValid(true);
    }
    dispatch({
      type: 'setGarantieDeces',
      payload: { person, value: 'O' },
    });
    dispatch({
      type: 'setQuotiteDeces',
      payload: { person, value: 100 },
    });
    dispatch({
      type: 'setGarantiePTIA',
      payload: { person, value: 'O' },
    });
    dispatch({
      type: 'setQuotitePTIA',
      payload: { person, value: 100 },
    });
    dispatch({
      type: 'setGarantieITT',
      payload: { person, value: 'O' },
    });
    dispatch({
      type: 'setQuotiteITT',
      payload: { person, value: 100 },
    });
    dispatch({
      type: 'setGarantiePerteEmploi',
      payload: { person, value: 'O' },
    });
    dispatch({
      type: 'setQuotitePerteEmploi',
      payload: { person, value: 100 },
    });
    setIsDeathInsuranceRateInputValid(true);
  }, []);

  const isDeathInsuranceChecked = (): boolean | undefined => {
    return getPersonWarranty?.garantieDeces === 'O' ? true : undefined;
  };

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <BaseCheckbox
        checked={isDeathInsuranceChecked()}
        className="assurance-checkbox"
        disabled
      />
      <CustomInput
        name="death-insurance"
        label="Garantie décès"
        isDisabled={isSG}
        value={getPersonWarranty.quotiteDeces}
        onChange={newValue => {
          handleDeathInsuranceRateChange(newValue);
          setIsDeathInsuranceRateInputFocused(true);
        }}
        isValid={isDeathInsuranceRateInputValid}
        isFocused={isDeathInsuranceRateInputFocused}
        onBlur={handleDeathInsuranceRateBlur}
        onFocus={() => {
          setIsDeathInsuranceRateInputFocused(true);
        }}
        after="%"
        inputWidth="17.2rem"
        placeholder="0"
        className="bareme-input"
        error={deathInsuranceRateInputError}
      />
    </div>
  );
};

export default DeathInsurance;
