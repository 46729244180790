import * as React from 'react';

const IdentityIcon: React.FC = () => (
  <svg width={54} height={41} xmlns="http://www.w3.org/2000/svg">
    <title>40C6437A-0769-4A83-B116-DEC7C66F8B37</title>
    <g fill="#292C2E" fillRule="evenodd">
      <path d="M47 .333A6.667 6.667 0 0 1 53.667 7v26.667A6.667 6.667 0 0 1 47 40.333H7a6.667 6.667 0 0 1-6.667-6.666V7A6.667 6.667 0 0 1 7 .333Zm3.333 31.111H3.666v2.223a3.333 3.333 0 0 0 3.151 3.328L7 37h40a3.333 3.333 0 0 0 3.333-3.333v-2.223ZM47 3.667H7A3.333 3.333 0 0 0 3.667 7v21.11h46.666V7a3.333 3.333 0 0 0-3.15-3.328L47 3.667Z" />
      <g fillRule="nonzero">
        <path d="M10.333 22.556s-1.11 0-1.11-1.112c0-1.11 1.11-4.444 6.666-4.444 5.555 0 6.667 3.333 6.667 4.444 0 1.112-1.112 1.112-1.112 1.112h-11.11ZM19.222 12.556a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.666 0Z" />
      </g>
    </g>
  </svg>
);

export default IdentityIcon;
