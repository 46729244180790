import { formatDateFr } from 'utils/DateUtils';
import jsPDF from 'jspdf';
import { InterlocutorState } from 'reducers/interlocutor/type';
import { IdentityState } from 'reducers/identity/types';
import { UserInfos } from 'types';
import { PropositionOutDtoResp } from 'types/FaisabiliteDTO';
import * as messages from '../messages';

const transmissionPdf = (): File => {
  const interlocutor = JSON.parse(
    sessionStorage.getItem('interlocutor') || '{}',
  ) as InterlocutorState;

  const userInfos = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;

  const borrowerIdentity = JSON.parse(
    sessionStorage.getItem('borrowerIdentity') || '{}',
  ) as IdentityState;

  const coBorrowerIdentity = JSON.parse(
    sessionStorage.getItem('coBorrowerIdentity') || '{}',
  ) as IdentityState;

  const simulationResult = JSON.parse(
    sessionStorage.getItem('simulationResult') || '{}',
  ) as PropositionOutDtoResp;

  const getWarranpty =
    simulationResult.Resultat.Proposition.idSecteurLegislatif === 3 ? 'LS2' : 'LS1';

  const getMortgage =
    simulationResult.Resultat.Proposition.typeGarantie === 'HYP'
      ? messages.TRANSMI_SHEET.warranty
      : messages.TRANSMI_SHEET.wo_warranty;

  const img = new Image();
  img.src = `${process.env.PUBLIC_URL}/logo_sygma.jpg`;

  // eslint-disable-next-line new-cap
  const doc = new jsPDF();

  // Logo
  doc.addImage(img, 'jpeg', 0, 0, 60, 25);
  // Titre page PDF
  doc.setFontSize(20);
  doc.setFont('Helvetica', 'bold');
  doc.text(`${messages.TRANSMI_SHEET.mainTitle}`, 140, 25, {
    maxWidth: 160,
    align: 'center',
  });

  // Date du PDF
  doc.setFontSize(10);
  doc.setFont('Helvetica', 'italic');
  doc.text(`${messages.TRANSMI_SHEET.editedDate} ${formatDateFr(new Date())}`, 170, 50);

  // Création premier rectangle d'infos
  doc.rect(10, 60, 180, 60);

  // Contenu premier rectangle
  doc.setFontSize(14);
  doc.setFont('Helvetica', 'italic');
  doc.text(`${messages.TRANSMI_SHEET.provider}`, 15, 70);
  const textWidth = doc.getTextWidth(`${messages.TRANSMI_SHEET.provider}`);
  doc.line(15, 71, 15 + textWidth, 71);

  doc.setFontSize(12);
  doc.setFont('Helvetica', 'normal');
  doc.text(`N° ${userInfos?.numContremarque}`, 42, 80);
  doc.text(`${userInfos?.nomCourtier}`, 42, 85);

  doc.text(`${messages.TRANSMI_SHEET.editor}`, 15, 95);
  doc.text(`${interlocutor.monteurName}`, 42, 95);

  doc.text(`${messages.TRANSMI_SHEET.seller}`, 15, 105);
  doc.text(`${interlocutor.interlocutorName?.libelle}`, 42, 105);

  if (interlocutor.analysteName) {
    doc.text(`${messages.TRANSMI_SHEET.analyst}`, 15, 115);
    doc.text(`${interlocutor.analysteName}`, 42, 115);
  }

  // Création deuxième rectange d'infos
  doc.rect(10, 130, 180, 35);

  // Contenu deuxième rectangle
  doc.setFontSize(14);
  doc.setFont('Helvetica', 'italic');
  doc.text(`${messages.COMMON_PDF.file}`, 15, 140);
  const textWidthFile = doc.getTextWidth(`${messages.COMMON_PDF.file}`);
  doc.line(15, 141, 15 + textWidthFile, 141);

  doc.setFont('Helvetica', 'normal');
  doc.text(`${sessionStorage.getItem('numeroProjet')}`, 42, 150);
  doc.text(
    `${borrowerIdentity.civility} ${borrowerIdentity.lastName} ${borrowerIdentity.firstName}`,
    42,
    156,
  );
  if (coBorrowerIdentity.civility !== undefined) {
    doc.text(
      `${coBorrowerIdentity.civility} ${coBorrowerIdentity.lastName} ${coBorrowerIdentity.firstName}`,
      42,
      161,
    );
  }

  // Création troisième rectange d'infos
  doc.rect(10, 175, 180, 60);

  // Contenu troisièmre rectange d'infos
  doc.setFontSize(14);
  doc.setFont('Helvetica', 'italic');
  doc.text(`${messages.TRANSMI_SHEET.caracteristics}`, 15, 185);
  const textWidthCarac = doc.getTextWidth(`${messages.TRANSMI_SHEET.caracteristics}`);
  doc.line(15, 186, 15 + textWidthCarac, 186);

  doc.setFont('Helvetica', 'normal');
  doc.text(`${messages.TRANSMI_SHEET.fileType}`, 42, 195);
  doc.text(`${getWarranpty}`, 42, 201);
  doc.text(`${messages.TRANSMI_SHEET.rateType}`, 42, 207);
  doc.text(`${getMortgage}`, 42, 213);
  doc.text(`${simulationResult.Resultat.Proposition.montantTotalPret} €`, 42, 219);
  doc.text(`${simulationResult.Resultat.Proposition.libelleSegmentation}`, 42, 225);
  doc.text(`${simulationResult.Resultat.Proposition.processTraitement}`, 42, 231);

  // Contenu légal
  doc.setFontSize(10);
  doc.text(`${messages.TRANSMI_SHEET.legal_mention_1}`, 50, 260);
  doc.text(`${messages.TRANSMI_SHEET.legal_mention_2}`, 35, 265);
  doc.text(`${messages.TRANSMI_SHEET.legal_mention_3}`, 60, 270);

  doc.save(
    `${messages.TRANSMI_SHEET.dlName}${userInfos.prenomTiersAPA}-${
      userInfos.nomTiersAPA
    }_${sessionStorage.getItem('numeroProjet')}.pdf`,
  );

  return new File(
    [doc.output('blob')],
    `${messages.TRANSMI_SHEET.dlName}${userInfos.prenomTiersAPA}-${
      userInfos.nomTiersAPA
    }_${sessionStorage.getItem('numeroProjet')}.pdf`,
  );
};

export default transmissionPdf;
