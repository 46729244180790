import { GetSimulationResultApiResponse, Resultat } from 'api/simulationService';
import Space from 'components/space/Space';
import { useEffect, useReducer, useState } from 'react';
import {
  borrowerIdentityReducer,
  getBorrowerInitialState,
} from 'reducers/identity/borrowerIdentity';
import {
  coBorrowerIdentityReducer,
  getCoBorrowerInitialState,
} from 'reducers/identity/coBorrowerIdentity';
import { IdentityState } from 'reducers/identity/types';
import { OperationState } from 'reducers/operationClient/types';
import { LoanInformation } from 'types';
import {
  determineProjectType,
  formatDisplayedFirstname,
  formatNumberByThousand,
} from 'utils/commun';
import { Line, StyledLabelValue, SummaryWrapper, StyledHorizontalWrapper } from './style';

export enum SummaryBlockEnum {
  Identity = 1,
  Operation,
  Simulation,
  SimulationResult,
  Finalisation,
  TransmissionDocuments,
}

export type SummaryBlock =
  | 'Identity'
  | 'Operation'
  | 'Simulation'
  | 'SimulationResult'
  | 'Finalisation'
  | 'TransmissionDocuments';

interface SummaryProps {
  step: SummaryBlock;
  data?: {
    loanInformation?: LoanInformation;
    hideTotalFinancement?: boolean;
    operationsClientState?: OperationState;
  };
}
const Summary: React.FC<SummaryProps> = ({ step, data }) => {
  const getSimulationResultFromSessionStorage = (): Resultat | undefined => {
    const result = sessionStorage.getItem('simulationResult');
    if (result) {
      return (JSON.parse(result) as GetSimulationResultApiResponse).Resultat;
    }
    return undefined;
  };
  const getNumeroProjetFromSessionStorage = () => {
    const numeroProjet = sessionStorage.getItem('numeroProjet');
    if (numeroProjet) {
      return JSON.parse(numeroProjet) as number;
    }
    return undefined;
  };
  const [borrowerState] = useReducer(
    borrowerIdentityReducer,
    getBorrowerInitialState('borrowerIdentity'),
  );
  const [coBorrowerState] = useReducer(
    coBorrowerIdentityReducer,
    getCoBorrowerInitialState('coBorrowerIdentity'),
  );
  const [borrowerData, setBorrowerData] = useState<IdentityState | undefined>(
    borrowerState,
  );
  const [coBorrowerData, setCoBorrowerData] = useState<IdentityState | undefined>(
    coBorrowerState,
  );
  const [loanData, setLoanData] = useState<LoanInformation | undefined>(
    data?.loanInformation,
  );

  const [operationsClientData, setOperationsClientData] = useState<
    OperationState | undefined
  >();
  const [simulationResult] = useState<Resultat | undefined>(
    getSimulationResultFromSessionStorage(),
  );
  const [numeroProjet] = useState<number | undefined>(
    getNumeroProjetFromSessionStorage(),
  );
  const [totalNeeds, setTotalNeeds] = useState<number>(0);

  useEffect(() => {
    // si les infos du borrower ne sont pas disponibles dans le reducer (Ex: l'utilisateur a rafraichi sa page), alors on essaie
    // de les récupérer depuis la session storage
    if (!borrowerState.isDataOk) {
      try {
        const result = sessionStorage.getItem('borrowerIdentity');
        if (result) {
          setBorrowerData(JSON.parse(result) as IdentityState);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [borrowerState]);

  useEffect(() => {
    // si les infos du coBorrower ne sont pas disponibles dans le reducer (Ex: l'utilisateur a rafraichi sa page), alors on essaie
    // de les récupérer depuis la session storage
    if (!coBorrowerState.isDataOk) {
      try {
        const result = sessionStorage.getItem('coBorrowerIdentity');
        if (result) {
          setCoBorrowerData(JSON.parse(result) as IdentityState);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [coBorrowerState]);

  useEffect(() => {
    // si les infos du prêt ne sont pas disponibles dans le reducer (Ex: l'utilisateur a rafraichi sa page), alors on essaie
    // de les récupérer depuis la session storage
    try {
      const result = sessionStorage.getItem('loanInformationsByIntermediationFee');
      if (result) {
        setLoanData(JSON.parse(result) as LoanInformation);
      }
    } catch (error) {
      console.error(error);
    }
  }, [data?.loanInformation]);

  const setOperationClientFromSessionStorage = () => {
    const result = sessionStorage.getItem('operationsClient');
    if (result) {
      setOperationsClientData(JSON.parse(result) as OperationState);
    }
  };

  useEffect(() => {
    // si les infos de "Operations client" ne sont pas disponible dans le reducer (Ex: l'utilisateur a rafraichi sa page), alors on essaie
    // de les récupérer depuis la session storage
    if (data?.operationsClientState) {
      const { totalOtherNeedsAmount, totalDebtValue, buybackLoansTotal } =
        data.operationsClientState;
      if (!totalOtherNeedsAmount && !totalDebtValue && !buybackLoansTotal) {
        try {
          setOperationClientFromSessionStorage();
        } catch (error) {
          console.error(error);
        }
      } else {
        setOperationsClientData(data.operationsClientState);
      }
    } else {
      setOperationClientFromSessionStorage();
    }
  }, [
    data?.operationsClientState?.totalOtherNeedsAmount,
    data?.operationsClientState?.totalDebtValue,
    data?.operationsClientState?.buybackLoansTotal,
  ]);

  useEffect(() => {
    // Besoin du client = dettes à racheter + totalOtherNeedsAmount + total des prêts à racheter
    let needs = 0;
    if (operationsClientData?.totalOtherNeedsAmount) {
      needs += operationsClientData.totalOtherNeedsAmount;
    }
    if (operationsClientData?.totalDebtValue) {
      needs += operationsClientData.totalDebtValue;
    }
    if (operationsClientData?.buybackLoansTotal) {
      needs += operationsClientData.buybackLoansTotal;
    }
    setTotalNeeds(needs);
  }, [operationsClientData]);

  const renderValue = (value?: string | number) => {
    if (value !== null && value !== undefined) {
      return `${formatNumberByThousand(value.toString())} €`;
    }
    return '';
  };

  const renderCivility = (value?: string): string => {
    if (!value) {
      return '';
    }
    if (value.toLowerCase() === 'mr') {
      return 'M.';
    }
    if (value.toLowerCase() === 'mme') {
      return 'Mme';
    }
    return value;
  };

  const removeDecimal = (value: unknown): number => {
    const parsed = Number.parseInt(value as string, 10);
    if (Number.isNaN(parsed)) {
      return 0;
    }
    return parsed;
  };

  const getTypeProjet = (operation?: OperationState): string | undefined => {
    if (operation) {
      return determineProjectType(
        operation.hasInsurance,
        simulationResult?.Proposition.idSecteurLegislatif,
      );
    }
    return undefined;
  };

  return (
    <div
      style={{ height: '46.2rem', position: 'sticky', top: '0', paddingLeft: '2.4rem' }}>
      <Space marginBottom="10rem" />
      <SummaryWrapper>
        <StyledLabelValue>Emprunteur</StyledLabelValue>
        <StyledLabelValue
          style={{
            fontWeight: 'bold',
          }}>{`${renderCivility(borrowerData?.civility)} ${
          borrowerData?.lastName
        } ${formatDisplayedFirstname(borrowerData?.firstName || '')}`}</StyledLabelValue>
        <>
          {coBorrowerData?.firstName && (
            <>
              <Space marginBottom="1.2rem" />
              <StyledLabelValue>Co-emprunteur</StyledLabelValue>
              <StyledLabelValue style={{ fontWeight: 'bold' }}>
                {`${renderCivility(coBorrowerData?.civility)} ${
                  coBorrowerData?.lastName
                } ${formatDisplayedFirstname(coBorrowerData?.firstName)}`}
              </StyledLabelValue>
            </>
          )}
        </>
        <Space marginBottom="1.6rem" />
        <Line />
        <Space marginBottom="1.6rem" />
        <StyledHorizontalWrapper>
          <StyledLabelValue>N° dossier</StyledLabelValue>
          <StyledLabelValue style={{ fontWeight: 'bold' }}>
            {data?.loanInformation?.numeroProjet || numeroProjet || '00 00 00 00'}
          </StyledLabelValue>
        </StyledHorizontalWrapper>

        <>
          {SummaryBlockEnum[step] > 2 && getTypeProjet(operationsClientData) && (
            <>
              <Space marginBottom="1.2rem" />
              <StyledHorizontalWrapper>
                <StyledLabelValue>Type de projet</StyledLabelValue>
                <StyledLabelValue style={{ fontWeight: 'bold', textAlign: 'right' }}>
                  {getTypeProjet(operationsClientData)}
                </StyledLabelValue>
              </StyledHorizontalWrapper>
            </>
          )}

          <>
            {SummaryBlockEnum[step] > 2 && loanData?.libelleSegmentation && (
              <>
                <Space marginBottom="1.2rem" />
                <StyledHorizontalWrapper>
                  <StyledLabelValue>Segmentation</StyledLabelValue>
                  <StyledLabelValue style={{ fontWeight: 'bold' }}>
                    {loanData.libelleSegmentation}
                  </StyledLabelValue>
                </StyledHorizontalWrapper>
              </>
            )}
          </>
          <>
            {SummaryBlockEnum[step] > 3 &&
              simulationResult?.Proposition?.processTraitement !== undefined && (
                <>
                  <Space marginBottom="1.2rem" />
                  <StyledHorizontalWrapper>
                    <StyledLabelValue>Traitement</StyledLabelValue>
                    <StyledLabelValue style={{ fontWeight: 'bold', textAlign: 'end' }}>
                      {simulationResult?.Proposition?.processTraitement}
                    </StyledLabelValue>
                  </StyledHorizontalWrapper>
                </>
              )}
          </>
        </>
      </SummaryWrapper>
      {SummaryBlockEnum[step] > 1 && (
        <>
          <Space marginBottom="1.4rem" />
          <SummaryWrapper>
            <StyledHorizontalWrapper>
              <StyledLabelValue>Besoin du client</StyledLabelValue>
              <StyledLabelValue
                style={{
                  fontWeight: 'bold',
                }}>
                {renderValue(totalNeeds)}
              </StyledLabelValue>
            </StyledHorizontalWrapper>
            <>
              <>
                {SummaryBlockEnum[step] > 2 && (
                  <>
                    <>
                      <Space marginBottom="1.2rem" />
                      <Line />
                      <Space marginBottom="1.2rem" />
                      <StyledHorizontalWrapper>
                        <StyledLabelValue>Frais de dossier</StyledLabelValue>
                        <StyledLabelValue
                          style={{
                            fontWeight: 'bold',
                          }}>
                          {renderValue(removeDecimal(loanData?.fraisComOuverture))}
                        </StyledLabelValue>
                      </StyledHorizontalWrapper>
                    </>
                    <>
                      <Space marginBottom="1.2rem" />
                      <StyledHorizontalWrapper>
                        <div>
                          <StyledLabelValue>{`Frais d'intermédiation`}</StyledLabelValue>
                          <p
                            style={{
                              margin: '0',
                              color: '#6F757C',
                              fontSize: '1.2rem',
                            }}>
                            {`${loanData?.pourcentageFraisIntermediation || 0}%`}
                          </p>
                        </div>
                        <StyledLabelValue
                          style={{
                            fontWeight: 'bold',
                          }}>{`${renderValue(
                          removeDecimal(loanData?.fraisIntermediation),
                        )}`}</StyledLabelValue>
                      </StyledHorizontalWrapper>
                    </>
                    <>
                      <Space marginBottom="1.2rem" />
                      <StyledHorizontalWrapper>
                        <StyledLabelValue>Frais de garantie</StyledLabelValue>
                        <StyledLabelValue style={{ fontWeight: 'bold' }}>
                          {renderValue(removeDecimal(loanData?.fraisActe))}
                        </StyledLabelValue>
                      </StyledHorizontalWrapper>
                    </>
                    <>
                      <Space marginBottom="1.2rem" />
                      <Line />
                      <Space marginBottom="1.2rem" />
                      <StyledHorizontalWrapper>
                        <StyledLabelValue>Montant à financer</StyledLabelValue>
                        <StyledLabelValue style={{ fontWeight: 'bold' }}>
                          {renderValue(
                            removeDecimal(loanData?.montantTotalPret).toString(),
                          )}
                        </StyledLabelValue>
                      </StyledHorizontalWrapper>
                    </>
                  </>
                )}
              </>
            </>
          </SummaryWrapper>
        </>
      )}
    </div>
  );
};

export default Summary;
