import CustomInput from 'components/customInput/CustomInput';
import { useState } from 'react';
import {
  AssuranceEmprunteur,
  CalculetteFinanciereAction,
} from 'reducers/calculetteFinanciere/type';

interface QuotiteProps {
  person: 'borrower' | 'coBorrower';
  dispatch: React.Dispatch<CalculetteFinanciereAction>;
  getPersonWarranty: AssuranceEmprunteur;
}

const Quotite: React.FC<QuotiteProps> = ({ person, dispatch, getPersonWarranty }) => {
  const [isCoverageRateInputFocused, setIsCoverageRateInputFocused] =
    useState<boolean>(false);
  const [isCoverageRateInputValid, setIsCoverageRateInputValid] = useState<boolean>();
  const [coverageRateInputError, setCoverageRateInputError] = useState<string>('');

  const handleCoverageRateChange = (value: string) => {
    const rate = parseInt(value, 10);
    if (Number.isNaN(rate)) {
      dispatch({
        type: 'setQuotiteDeces',
        payload: { person, value: undefined },
      });
      dispatch({
        type: 'setGarantieDeces',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setQuotiteDeces',
        payload: { person, value: rate },
      });
      dispatch({
        type: 'setGarantieDeces',
        payload: { person, value: 'O' },
      });
    }
  };
  const handleCoverageRateBlur = () => {
    setIsCoverageRateInputFocused(false);
    if (
      Number.isNaN(getPersonWarranty.quotiteDeces) ||
      (getPersonWarranty.quotiteDeces !== undefined &&
        getPersonWarranty.quotiteDeces <= 0)
    ) {
      setIsCoverageRateInputValid(false);
      setCoverageRateInputError('Saisie incorrecte.');
    } else {
      setIsCoverageRateInputValid(true);
      setCoverageRateInputError('');
    }
  };

  return (
    <CustomInput
      name="coverage-rate"
      label="Quotité couverture"
      value={getPersonWarranty.quotiteDeces}
      onChange={newValue => {
        handleCoverageRateChange(newValue);
        setIsCoverageRateInputFocused(true);
      }}
      isValid={isCoverageRateInputValid}
      isFocused={isCoverageRateInputFocused}
      onBlur={handleCoverageRateBlur}
      onFocus={() => {
        setIsCoverageRateInputFocused(true);
      }}
      after="%"
      inputWidth="16.4rem"
      placeholder="0"
      className="bareme-input"
      error={coverageRateInputError}
    />
  );
};

export default Quotite;
