import { StyledHorizontalBar } from 'containers/consultation/style';
import PowerUpIcon from 'icons/PowerUpIcon';
import ProfilIcon from 'icons/ProfilIcon';
import SmallChevronDown from 'icons/SmallChevronDown';
import SmallChevronUp from 'icons/SmallChevronUp';
import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { useNavigate } from 'react-router';
import { UserInfos } from 'types';
import Space from 'components/space/Space';
import AuthorizationService from 'api/AuthorizationService';
import {
  updateNumContremarque,
  userCanChangeApa,
  userHasSelectedApa,
} from 'utils/commun';
import * as messages from './messages';
import {
  StyledAdditionalInfosContainer,
  StyledAdditionalInfosContremarqueLabel,
  StyledAdditionalInfosContremarqueValue,
  StyledAdditionalInfosGroupLabel,
  StyledAdditionalInfosLockedGroupContainer,
  StyledAdditionalInfosLockedGroupLabel,
  StyledAdditionalInfosLogoutLabel,
  StyledAdditionalInfosTitle,
  StyledClickableBackground,
  StyledProfilWrapper,
} from './style';

interface LabelValue {
  label: string;
  value: string;
  numeroAPA: number;
}

const Profil: React.FC = () => {
  const [userInfos, setUserInfos] = useState<UserInfos>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isGroupEditable, setIsGroupEditable] = useState<boolean>(false);
  const [authorizedApas, setAuthorizedApas] = useState<LabelValue[]>([]);
  const [selectedApa, setSelectedApa] = useState<SingleValue<LabelValue>>();

  useEffect(() => {
    const userInfo = sessionStorage.getItem('authenticatedUser');

    if (userInfo) {
      setUserInfos(JSON.parse(userInfo) as UserInfos);
    }
    if (
      (window.location.pathname.includes('/dashboard') ||
        window.location.pathname.includes('/recherche')) &&
      userCanChangeApa()
    ) {
      setIsGroupEditable(true);
    } else {
      setIsGroupEditable(false);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (!selectedApa) {
      const userInfo = sessionStorage.getItem('authenticatedUser');

      if (userInfo) {
        setUserInfos(JSON.parse(userInfo) as UserInfos);
      }
    }
  }, [selectedApa]);

  useEffect(() => {
    if (userCanChangeApa() && window.location.href.includes('dashboard')) {
      AuthorizationService.getAuthorizedApas().then(result => {
        setAuthorizedApas(
          result.data.Resultat.map(apa => {
            return {
              label: apa.libAPA,
              value: apa.numContremarqueAPA.toString(),
              numeroAPA: apa.idAPA,
            } as LabelValue;
          }),
        );

        const previouslySelectedApa = sessionStorage.getItem('selectedApa');
        if (previouslySelectedApa) {
          result.data.Resultat.forEach(apa => {
            if (apa.numContremarqueAPA.toString() === previouslySelectedApa) {
              setSelectedApa({
                label: apa.libAPA,
                value: apa.numContremarqueAPA.toString(),
                numeroAPA: apa.idAPA,
              });
              updateNumContremarque(apa.numContremarqueAPA, apa.nomAPA, apa.idAPA);
            }
          });
        }
      });
    }
  }, [isGroupEditable]);

  useEffect(() => {
    if (!userCanChangeApa() || !window.location.href.includes('dashboard')) {
      setSelectedApa({
        label: userInfos?.nomCourtier || '',
        value: userInfos?.idAPA.toString() || '',
        numeroAPA: userInfos?.idAPADossier || 0,
      });
    }
  }, [userInfos]);

  const navigate = useNavigate();

  const renderAdditionalInfos = () => {
    return (
      <>
        <StyledAdditionalInfosContainer>
          <StyledAdditionalInfosTitle>{` ${userInfos?.nomTiersAPA} ${userInfos?.prenomTiersAPA}`}</StyledAdditionalInfosTitle>
          <div style={{ marginBottom: '1.55rem' }}>
            {(!userCanChangeApa() || userHasSelectedApa()) && (
              <>
                <StyledAdditionalInfosContremarqueLabel>
                  {messages.PROFIL_CONTREMARQUE}
                </StyledAdditionalInfosContremarqueLabel>
                <StyledAdditionalInfosContremarqueValue>
                  {userInfos?.numContremarque}
                </StyledAdditionalInfosContremarqueValue>
              </>
            )}
          </div>

          <StyledHorizontalBar
            color="#DCDEE0"
            style={{ marginBottom: '1.55rem', marginRight: '0' }}
          />

          <StyledAdditionalInfosGroupLabel>
            {messages.PROFIL_GROUP}
          </StyledAdditionalInfosGroupLabel>
          {isGroupEditable ? (
            <>
              <Select
                options={authorizedApas}
                value={selectedApa}
                placeholder="Choisissez un APA..."
                onChange={newValue => {
                  setSelectedApa(newValue);
                  if (newValue?.value) {
                    sessionStorage.setItem('selectedApa', newValue.value);
                    if (userInfos?.uid) {
                      AuthorizationService.getAuthorization(
                        userInfos.uid.toString(),
                        newValue.value,
                      ).then(response => {
                        let newUserInfos = {} as UserInfos;
                        newUserInfos = {
                          ...response.data.Resultat,
                          uid: userInfos.uid,
                          numContremarque: Number(newValue.value),
                          idAPADossier: newValue.numeroAPA,
                        };

                        sessionStorage.setItem(
                          'authenticatedUser',
                          JSON.stringify(newUserInfos),
                        );
                      });
                    }
                    setUserInfos(
                      updateNumContremarque(
                        Number(newValue.value),
                        newValue.label.split('-')[1].trim(),
                        newValue.numeroAPA,
                      ),
                    );
                    navigate(window.location.pathname);
                    setIsExpanded(false);
                  }
                }}
              />
              <Space marginBottom="2.4rem" />
            </>
          ) : (
            <StyledAdditionalInfosLockedGroupContainer>
              <StyledAdditionalInfosLockedGroupLabel>
                {selectedApa?.label}
              </StyledAdditionalInfosLockedGroupLabel>
            </StyledAdditionalInfosLockedGroupContainer>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              width: 'fit-content',
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              AuthorizationService.disconnect(navigate);
            }}
            onKeyDown={() => {
              AuthorizationService.disconnect(navigate);
            }}>
            <PowerUpIcon />
            <StyledAdditionalInfosLogoutLabel>
              {messages.PROFIL_DISCONECT}
            </StyledAdditionalInfosLogoutLabel>
          </div>
        </StyledAdditionalInfosContainer>
        <StyledClickableBackground
          onClick={() => {
            setIsExpanded(false);
          }}
        />
      </>
    );
  };

  const renderChevron = () => {
    return isExpanded ? <SmallChevronUp /> : <SmallChevronDown />;
  };

  return (
    <div style={{ position: 'absolute', right: '5.6rem' }}>
      <StyledProfilWrapper
        style={
          userCanChangeApa() && !userHasSelectedApa() && !isExpanded
            ? { border: '2px solid #FF9E00', boxShadow: '0 2px 10px 0 #FF9E00' }
            : {}
        }
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}>
        <div style={{ width: '4rem' }}>
          <ProfilIcon />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '16rem' }}>
          <span
            style={{
              fontSize: '1.4rem',
              color: '#6F757C',
            }}>{` ${userInfos?.nomTiersAPA} ${userInfos?.prenomTiersAPA}`}</span>
          <span
            style={{
              fontWeight: 'bold',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>
            {(!userCanChangeApa() || userHasSelectedApa()) && userInfos?.nomCourtier}
          </span>
        </div>
        {userCanChangeApa() && renderChevron()}
      </StyledProfilWrapper>

      {isExpanded && renderAdditionalInfos()}
    </div>
  );
};

export default Profil;
