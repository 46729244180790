import styled from 'styled-components';

export const StyledInfoBubbleTitle = styled.p`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin-top: 4rem;
  margin-bottom: 0.4rem;
`;

export const StyledInfoBubble = styled.div`
  height: 5.6rem;
  border-radius: 0.8rem;
  background-color: #eff8f0;
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
`;
