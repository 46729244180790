import { useEffect, useState } from 'react';
import InformationArea from 'components/informationArea/InformationArea';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import ChemiseConsultation from 'components/chemise/chemiseConsultation';
import ListPJNonTransmis from 'components/listPJNonTransmis/ListPJNonTransmis';
import BankIcon from 'icons/BankIcon';
import CreditIcon from 'icons/CreditIcon';
import FolderIcon from 'icons/FolderIcon';
import GuaranteeIcon from 'icons/GuaranteeIcon';
import HouseIcon from 'icons/HouseIcon';
import IdentityIcon from 'icons/IdentityIcon';
import IncomeIcon from 'icons/IncomeIcon';
import InsuranceIcon from 'icons/InsuranceIcon';
import LightIcon from 'icons/LightIcon';
import MoneyIcon from 'icons/MoneyIcon';
import {
  TransmissionPiecesAction,
  TransmissionPiecesState,
} from 'reducers/transmissionPieces/types';
import {
  CodeLibelle,
  ListePiecesJustificative,
  ListePiecesJustificativesPropCialUploadable,
  PJWithStatus,
} from 'types';
import * as messages from '../messages';

interface TransmissionPJForAGProps {
  chemisesUploadables: ListePiecesJustificativesPropCialUploadable[];
  chemisesNonUploadables: ListePiecesJustificative[];
  state: TransmissionPiecesState;
  dispatch: React.Dispatch<TransmissionPiecesAction>;
  disableUpload: boolean;
  allUploadAreError: string;
}
const TransmissionPJForAG: React.FC<TransmissionPJForAGProps> = ({
  chemisesNonUploadables,
  chemisesUploadables,
  state,
  dispatch,
  disableUpload,
  allUploadAreError,
}) => {
  const ACCEPTED_FILES_FORMAT = ['application/pdf', 'image/jpeg'];
  const ONE_MO_TO_BYTE = 1_048_576;
  const MAX_FILE_SIZE_IN_MO = 19 * ONE_MO_TO_BYTE; // 19 Mo maximum

  const getChemiseIcon = (chemiseId: string) => {
    switch (chemiseId) {
      case '002':
        return <IdentityIcon />;
      case '003':
        return <HouseIcon />;
      case '005':
        return <IncomeIcon />;
      case '004':
        return <BankIcon />;
      case '006':
        return <CreditIcon />;
      case '007':
        return <MoneyIcon />;
      case '008':
        return <GuaranteeIcon />;
      case '009':
        return <InsuranceIcon />;
      case '010':
        return <FolderIcon />;
      default:
        return null;
    }
  };

  const [listePJNonTransmis, setListPJNonTransmis] = useState<string[]>([]);

  useEffect(() => {
    const listPJNonTransmisFromSession = sessionStorage.getItem('listePJNonTransmis');
    if (listPJNonTransmisFromSession) {
      const listPJNonTransmisObject = JSON.parse(
        listPJNonTransmisFromSession,
      ) as string[];

      setListPJNonTransmis(listPJNonTransmisObject);
    }
  }, [disableUpload]);

  const getChemiseFilesName = (id: string) => {
    const result: CodeLibelle[] = [];
    state.chemises.forEach(item => {
      if (item.id === id) {
        item.files.forEach(el => {
          result.push({ code: el.id, libelle: el.content.name });
        });
      }
    });
    return result;
  };

  const renderErrorMessage = () => {
    return (
      <>
        <span>Votre document n’a pas pu être téléchargé.</span>
        <br />
        <span>
          Veuillez vérifier le format ou la taille de votre document. Formats et taille
          acceptés : PDF, JPEG - 19 Mo.
        </span>
      </>
    );
  };

  const renderChemisesUploadables = () => {
    return chemisesUploadables.map(item => {
      const listPiece: PJWithStatus[] = [];
      if (!item || item.listePiecesJustificatives.length === 0) return null;
      item.listePiecesJustificatives.forEach(piece => {
        const pieceName = `${piece.pceJusLib[0]}${piece.pceJusLib
          .substring(1)
          .toLowerCase()} ${piece.cpltPceJusLib}`;
        listPiece.push({ libelle: pieceName });
      });

      return (
        <div key={item.chemId}>
          <ChemiseConsultation
            id={item.chemId}
            libelle={item.chemLib}
            icon={getChemiseIcon(item.chemId)}
            acceptedFileFormat={ACCEPTED_FILES_FORMAT}
            maxFileSize={MAX_FILE_SIZE_IN_MO}
            listPiece={listPiece}
            listPJNonTransmis={listePJNonTransmis}
            isUploadable={!disableUpload}
            onUploadSuccess={value => {
              dispatch({
                type: 'setAddPieces',
                payload: { value },
              });
            }}
            onDelete={(chemiseId, fileId) => {
              dispatch({
                type: 'setDeletePieces',
                payload: { chemiseId, fileId },
              });
            }}
            uploadedFilesName={getChemiseFilesName(item.chemId)}
            errorMessage={renderErrorMessage()}
          />

          <Space marginTop="3.2rem" />
        </div>
      );
    });
  };

  const renderChemisesNonUploadables = () => {
    const listPiece: PJWithStatus[] = [];
    chemisesNonUploadables.forEach(item => {
      listPiece.push({
        libelle: `${item.pceJusLib[0]}${item.pceJusLib.substring(1).toLowerCase()} ${
          item.cpltPceJusLib
        }`,
      });
    });
    return <ChemiseConsultation listPiece={listPiece} isUploadable={false} />;
  };

  return (
    <>
      <StepTitles
        title={messages.TITLE_CHEMISE_UPLOADABLE}
        subTitle={messages.SUB_TITLE_CHEMISE_UPLOADABLE}
      />
      <Space marginBottom="2.7rem" />
      <InformationArea icon={<LightIcon />} backgroundColor="#E5F5FD">
        <b>L’exhaustivité</b> des pièces justificatives à fournir favorise{' '}
        <b>le délai de traitement du dossier</b>.
      </InformationArea>
      <Space marginBottom="2.4rem" />
      <span style={{ color: '#6F757C' }}>{messages.ACCEPTED_FORMAT_TEXT}</span>
      <Space marginBottom="0.8rem" />
      {renderChemisesUploadables()}
      {!allUploadAreError && listePJNonTransmis && listePJNonTransmis.length > 0 && (
        <>
          <Space marginTop="2rem" />
          <ListPJNonTransmis listPJ={listePJNonTransmis} />
        </>
      )}
      <Space marginTop="8rem" />
      <StepTitles
        title={messages.TITLE_CHEMISE_NON_UPLOADABLE}
        subTitle={messages.SUB_TITLE_CHEMISE_NON_UPLOADABLE}
      />
      <Space marginBottom="2.4rem" />
      {renderChemisesNonUploadables()}
    </>
  );
};

export default TransmissionPJForAG;
