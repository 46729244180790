import CancelButton from 'components/buttons/cancelButton/CancelButton';
import Space from 'components/space/Space';
import DeleteButton from 'components/buttons/deleteButton/DeleteButton';
import { Operation } from 'reducers/operationClient/types';
import Modal from '../Modal';
import { StyledBlackTitle, StyledGeneralModalContentContainer } from '../style';

interface DeleteConfirmationModalProps {
  isVisible: boolean;
  item?: Operation;
  accept: (item?: Operation) => void;
  close: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isVisible,
  item,
  accept,
  close,
}) => {
  return (
    <Modal isVisible={isVisible} height="16rem" width="49.6rem" onClose={close}>
      <StyledGeneralModalContentContainer
        style={{ marginLeft: '8.8rem', paddingBottom: '1.7rem' }}>
        <Space marginBottom="3.2rem" />

        <StyledBlackTitle style={{ fontSize: '2rem' }}>
          Confirmez-vous la suppression ?
        </StyledBlackTitle>

        <Space marginBottom="2.4rem" />

        <div
          style={{
            display: 'flex',
            height: '4.8rem',
            marginLeft: '1rem',
          }}>
          <div style={{ marginRight: '1.6rem' }}>
            <CancelButton handleonClick={close} />
          </div>

          <DeleteButton
            isDisabled={false}
            handleonClick={() => {
              accept(item);
            }}
          />
        </div>
      </StyledGeneralModalContentContainer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
