interface PaginationProps {
  totalItemsToPaginate: number;
  activePage: number;
  nbItemsPerPageOptions: number[];
  nbItemsPerPage: number;
  onPageChange: (pageNumber: number) => void;
  onNbItemsToDisplayChange: (nbItemsToDisplay: number) => void;
  firstDisplayedItemIndex: number;
}
const Pagination: React.FC<PaginationProps> = ({
  totalItemsToPaginate,
  activePage,
  nbItemsPerPageOptions,
  nbItemsPerPage,
  onPageChange,
  onNbItemsToDisplayChange,
  firstDisplayedItemIndex,
}) => {
  const nbPaginationPages = Math.ceil(totalItemsToPaginate / nbItemsPerPage);
  const renderPagination = (): React.ReactNode => {
    if (nbPaginationPages === 1) {
      return null;
    }
    const pagination: React.ReactNode[] = [
      <button
        type="button"
        className="styledBorder"
        key={0}
        onClick={() => {
          const previousPageNumber =
            activePage - 1 < 1 ? nbPaginationPages : activePage - 1;
          onPageChange(previousPageNumber);
        }}>
        &#60;
      </button>,
    ];
    for (let i = 1; i <= nbPaginationPages; i += 1) {
      pagination.push(
        <button
          type="button"
          tabIndex={0}
          className={`styledBorder ${activePage === i ? 'active' : ''}`}
          key={i}
          onClick={() => {
            onPageChange(i);
          }}>
          {i}
        </button>,
      );
    }
    pagination.push(
      <button
        type="button"
        className="styledBorder"
        key={nbPaginationPages + 1}
        onClick={() => {
          const nextPageNumber = activePage + 1 > nbPaginationPages ? 1 : activePage + 1;
          onPageChange(nextPageNumber);
        }}>
        &#62;
      </button>,
    );
    return pagination;
  };

  const renderSelectInput = () => {
    if (nbItemsPerPageOptions.length === 0) {
      return null;
    }
    return (
      <>
        <select
          onChange={e => {
            onNbItemsToDisplayChange(parseInt(e.target.value, 10));
          }}>
          {nbItemsPerPageOptions.map(item => {
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </select>
        <span>&nbsp;lignes par page</span>
      </>
    );
  };

  const render = () => {
    if (totalItemsToPaginate === 0) {
      return <p>Aucun résultat</p>;
    }
    return (
      <>
        <div>
          <span>
            Affichage des lignes de {firstDisplayedItemIndex} à{' '}
            {nbItemsPerPage * activePage > totalItemsToPaginate
              ? totalItemsToPaginate
              : nbItemsPerPage * activePage}{' '}
            sur {totalItemsToPaginate} lignes au total &nbsp;
          </span>
          {renderSelectInput()}
        </div>
        <div>
          <div className="pagination">{renderPagination()}</div>
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        margin: '1rem 0',
        fontSize: '1.1rem',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      {render()}
    </div>
  );
};

export default Pagination;
