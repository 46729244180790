export const formatNumber = (num?: number | string): string | undefined => {
  if (num === undefined) {
    return undefined;
  }
  const parsed = Number.parseFloat(num.toString());
  if (Number.isNaN(parsed)) {
    return undefined;
  }

  if (parsed === 0) {
    return parsed.toString();
  }
  if (parsed % 1 === 0) {
    // pas de décimales
    return parsed.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '); // use ' ' as a separator
  }
  return parsed
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '); // use ' ' as a separator
};

export const getIntegerFromString = (value: string): number => {
  try {
    const valWithoutSpace = value.replace(/ /g, '');
    const parsedValue = parseInt(valWithoutSpace, 10);
    if (Number.isNaN(parsedValue)) {
      return 0;
    }
    return parsedValue;
  } catch (error) {
    console.error(error);
    return 0;
  }
};
