export const REASSURANCE_TEXT =
  'Détaillez le projet de votre client pour une offre précise';
export const MAIN_TITLE_1 = 'Inventaire des prêts';
export const SUB_TITLE_1 =
  'Les informations sur les prêts vont nous permettre d’établir un bilan financier.';

const TOTAL_INFO_BUBBLE_TITLE = 'Cumul des prêts à';
export const TOTAL_INFO_BUBBLE_TITLE_1 = `${TOTAL_INFO_BUBBLE_TITLE} racheter`;
export const TOTAL_INFO_BUBBLE_TITLE_2 = `${TOTAL_INFO_BUBBLE_TITLE} conserver`;

export const MODAL_MAIN_TITLE = 'Veuillez renseigner les informations du prêt';

export const MODAL_RADIO_BUTTONS_TITLE = 'Est-ce un prêt à racheter ?';
export const MODAL_RADIO_BUTTON_TRUE = 'Oui';
export const MODAL_RADIO_BUTTON_FALSE = 'Non';

export const MODAL_CREDIT_TYPE = 'Type de crédit';
export const MODAL_CREDIT_TYPE_1 = 'Consommation';
export const MODAL_CREDIT_TYPE_2 = 'Immobilier';
export const MODAL_CREDIT_TYPE_3 = 'Renouvelable';
export const MODAL_CREDIT_BALANCE_INFO =
  'Dont les Indemnités de Remboursements par Anticipation ';
export const MODAL_CREDIT_RESTRUCTURE_TITLE =
  'Ce prêt est-il un regroupement de crédits ?';
export const MODAL_CREDIT_MORTGAGE_TITLE = 'Ce prêt est-il garanti par une hypothèque ?';
export const MODAL_ASSET_PATRI_INFO = 'Veuillez renseigner les informations du bien';
export const MODAL_ASSET_RESIDENCE_PRINCIPALE = 'Résidence principale';
export const MODAL_ASSET_RESIDENCE_SECONDAIRE = 'Résidence secondaire';
export const MODAL_ASSET_LOCATIF = 'Locatif';
export const MODAL_ASSET_PATRIMOINE_ADRESSE =
  "L'adresse du bien est-elle identique à celle de l'emprunteur ?";

export const MODAL_ADD_LOAN = 'Ajouter le prêt';
export const MODAL_MODIFY_BUTTON = 'Valider la modification';
export const MODAL_ADD_ASSET = 'Ajouter le bien';
export const MODAL_CLOSE = 'Fermer';

export const INCIDENTS_IMPAYES_TITLE = 'Incidents et impayés';
export const INCIDENTS_IMPAYES_SUBTITLE = '';

export const LOAN_FORM = {
  LOAN_START_DATE: 'Date de début du prêt',
  LOAN_DURATION: 'Durée totale du prêt',
  LOAN_INITIAL_CAPITAL: 'Capital Initial du prêt',
  LOAN_CREDIT_ORGANISATION: 'Organisme de crédit',
  LOAN_TYPE: 'Type de crédit',
  LOAN_MENSUALITY: 'Mensualité du prêt',
  LOAN_REMAINING: 'Solde',
  LOAN_REBUY_INFORMATION: 'Informations des prêts à racheter',
  LOAN_TOTAL_MENSUALITY: 'Total des mensualités : ',
  LOAN_TOTAL_REMAINING: 'Total des soldes : ',
  LOAN_KEEPING_INFORMATION: 'Information des prêts à conserver',
};

export const ASSET_FORM = {
  ASSET_TYPE: 'Type de bien',
  ASSET_USAGE: 'Usage du bien',
  ASSET_VALUE: 'Valeur du bien',
  ASSET_BUTTON_ADD: 'Ajouter un nouveau bien',
  ASSET_TOTAL: 'Total du bien : ',
  ASSET_STEPTITLE_WARRANPTY: 'Garantie',
  ASSET_SUBTITLE_WARRANPTY:
    'La prise de garantie prend effet sur des biens de pleine proprieté.',
  ASSET_WARRANPTY: 'Une Garantie',
};
