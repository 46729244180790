import { getReferential } from 'api/referentialService';
import { GetSimulationResultApiResponse } from 'api/simulationService';
import BaseButton from 'components/designSystem/BaseButton';
import Paginator from 'components/paginator/Paginator';
import ResultCard from 'components/resultCard/ResultCard';
import { StyledResultCardCategoryTitle } from 'components/resultCard/style';
import Space from 'components/space/Space';
import { StyledResumeButtonText } from 'containers/consultation/style';
import ContractPaper from 'icons/ContractPaper';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import theme from 'styles/theme';
import { CodeLibelle } from 'types';
import { clientFilesResult } from 'types/clientFileDtos';
import { determineCurrentProjectStep, resumeProject } from 'utils/commun';
import { getExistingClientFile } from 'utils/mapping/mapping';
import * as messages from '../messages';
import {
  StyledResultCounter,
  StyledResultFoundText,
  StyledSecondaryTitle,
} from '../style';

interface SearchResultProps {
  results?: clientFilesResult[];
  fileStatusItems: CodeLibelle[];
  setIsLoading: (isLoading: boolean) => void;
  searchFlag: boolean;
}

const SearchResult: React.FC<SearchResultProps> = ({
  results,
  fileStatusItems,
  setIsLoading,
  searchFlag,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [paginatedResults, setPaginatedResults] = useState<clientFilesResult[]>();
  const [niveauxProcess, setNiveauxProcess] = useState<CodeLibelle[]>();
  const itemsPerPage = 25;

  const navigate = useNavigate();

  useEffect(() => {
    setPaginatedResults(
      results?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage),
    );
  }, [results, currentPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [results]);

  useEffect(() => {
    getReferential('niveauProcess').then(result => {
      if (result && result.Success) setNiveauxProcess(result.Resultat);
    });
  }, []);

  const getFileDetailsAndNavigate = (
    numeroProjet: number,
    projetDetail: clientFilesResult,
  ) => {
    setIsLoading(true);

    getExistingClientFile(numeroProjet, projetDetail)
      .then(() => {
        navigate('/consultation', { state: { prevPath: window.location.pathname } });
      })
      .catch(error => {
        console.error(error);
        alert(
          "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const displayCards = (itemsToDisplay: clientFilesResult[]) => {
    return (
      <>
        {itemsToDisplay &&
          itemsToDisplay.map((result, index) => {
            return (
              <React.Fragment key={result.IdProjet}>
                <div style={{ display: 'flex', gap: '1.6rem', alignItems: 'center' }}>
                  <ResultCard
                    file={result}
                    niveauxProcess={niveauxProcess}
                    onClick={getFileDetailsAndNavigate}
                  />

                  {result.StatutMaitre === 'E' && (
                    <BaseButton
                      onClick={() => {
                        setIsLoading(true);

                        getExistingClientFile(result.IdProjet, result)
                          .then(() => {
                            const simulationResultObject =
                              sessionStorage.getItem('simulationResult');
                            const simulationResult = JSON.parse(
                              simulationResultObject || '{}',
                            ) as GetSimulationResultApiResponse;
                            resumeProject(
                              !simulationResult?.Resultat?.Proposition
                                ? 1
                                : determineCurrentProjectStep(),
                              navigate,
                            );
                          })
                          .catch(error => {
                            console.error(error);
                            alert(
                              "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
                            );
                          })
                          .finally(() => {
                            setIsLoading(false);
                          });
                      }}
                      style={{
                        width: '32rem',
                        height: '4.8rem',
                        display: 'flex',
                        alignItems: 'center',
                        background: theme.colors.chartColors.mainGreenBnp500,
                      }}>
                      <ContractPaper />
                      <StyledResumeButtonText>
                        {messages.RESUME_BUTTON_LABEL}
                      </StyledResumeButtonText>
                    </BaseButton>
                  )}
                </div>

                <Space marginBottom="2.4rem" />

                {index === itemsToDisplay.length - 1 && <Space marginBottom="3.2rem" />}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  const displayWithCategories = () => {
    return (
      <>
        {fileStatusItems?.map(fileStatus => {
          if (
            paginatedResults?.find(item => {
              return item.StatutMaitre === fileStatus.code;
            })
          ) {
            const categorizedResults = paginatedResults.filter(result => {
              return result.StatutMaitre === fileStatus.code;
            });
            return (
              <>
                <StyledResultCardCategoryTitle>
                  {fileStatus.libelle}
                </StyledResultCardCategoryTitle>
                {displayCards(categorizedResults)}
              </>
            );
          }
          return <></>;
        })}
      </>
    );
  };

  return (
    <>
      {searchFlag && paginatedResults && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledSecondaryTitle>{messages.SEARCH_RESULT_TITLE}</StyledSecondaryTitle>
            <StyledResultCounter>{results?.length || 0}</StyledResultCounter>
            <StyledResultFoundText>{messages.FILES_FOUND_LABEL}</StyledResultFoundText>
          </div>

          {paginatedResults.length > 0 && (
            <>
              <Space marginBottom="3.2rem" />
              <div>
                {niveauxProcess
                  ? displayWithCategories()
                  : displayCards(paginatedResults)}

                <Paginator
                  currentpage={currentPage}
                  setCurrentPage={setCurrentPage}
                  maxpage={results ? Math.ceil(results.length / itemsPerPage) : 0}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

SearchResult.defaultProps = {
  results: [],
};

export default SearchResult;
