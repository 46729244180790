import InformationArea from 'components/informationArea/InformationArea';
import Space from 'components/space/Space';
import LightIcon from 'icons/LightIcon';
import { useEffect, useState } from 'react';
import { PiecesJustificatives } from 'types';
import NavBarPJTile from '../components/NavBarPJTile';
import * as messages from '../messages';
import { StyledMainTitle, StyleNavBarPJContainer } from '../style';
import OngletAutresJustificatives from './ongletAutresJustificatives';
import OngletPiecesJustificatives from './ongletPiecesJustificatives';

const TransmissionPiecesConsultation: React.FC = () => {
  const [isPiecesJustificatives, setIsPiecesJustificatives] = useState<boolean>(true);
  const [hasAutreJustificatif, setHasAutreJustificatif] = useState<boolean>(false);
  useEffect(() => {
    const objectFromSession = sessionStorage.getItem('piecesJustificatives');

    if (objectFromSession) {
      const piecesJustificativesFromSession = JSON.parse(
        objectFromSession,
      ) as PiecesJustificatives;

      const autreJustifcatif =
        piecesJustificativesFromSession.listePiecesJustificativesPropCialUploadable.listeChemises.find(
          item => item.chemId === '010',
        );

      if (autreJustifcatif) setHasAutreJustificatif(true);
    }
  }, []);

  const renderTitle = () => {
    return (
      <div>
        <StyledMainTitle>
          {isPiecesJustificatives
            ? messages.TRANSMISSION_PJ_MAIN_TITLE
            : messages.TRANSMISSION_AUTRE_PJ_MAIN_TITLE}
        </StyledMainTitle>
        <Space marginTop="0.4rem" />
        <span style={{ color: '#6F757C' }}>{messages.SUB_TITLE_CHEMISE_UPLOADABLE}</span>
        <Space marginBottom="2.7rem" />
        <InformationArea icon={<LightIcon />} backgroundColor="#E5F5FD">
          <b>L’exhaustivité</b> des pièces justificatives à fournir favorise{' '}
          <b>le délai de traitement du dossier</b>.
        </InformationArea>
        <Space marginBottom="2.7rem" />
      </div>
    );
  };

  const renderNavBlock = () => {
    return (
      <StyleNavBarPJContainer>
        <NavBarPJTile
          label={messages.PJ_NAVBAR_TAB_1}
          isSelected={isPiecesJustificatives}
          isEnabled
          onclick={() => {
            setIsPiecesJustificatives(true);
          }}
        />
        <NavBarPJTile
          label={messages.PJ_NAVBAR_TAB_2}
          isSelected={!isPiecesJustificatives}
          isEnabled={hasAutreJustificatif}
          onclick={() => {
            setIsPiecesJustificatives(false);
          }}
        />
      </StyleNavBarPJContainer>
    );
  };

  return (
    <>
      {renderTitle()}
      {renderNavBlock()}
      {isPiecesJustificatives ? (
        <OngletPiecesJustificatives />
      ) : (
        <OngletAutresJustificatives />
      )}
    </>
  );
};

export default TransmissionPiecesConsultation;
