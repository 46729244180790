import CustomInput from 'components/customInput/CustomInput';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import {
  FinalisationProjetAction,
  FinalisationProjetState,
} from 'reducers/finalisationProjet/types';
import {
  ValidationRules,
  formatPhoneValue,
  isCellPhoneNumberValid,
  isHomePhoneNumberValid,
} from 'utils/InputValidation';
import {
  addInInvalidInputs,
  isSansGarantie,
  removeFromInvalidInputs,
} from 'containers/communs/utils';
import Space from 'components/space/Space';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from '../../messages';

interface PhoneNumberProps {
  state: FinalisationProjetState;
  dispatch: React.Dispatch<FinalisationProjetAction>;
  person: 'borrower' | 'coBorrower';
  checkInputs: boolean;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  state,
  dispatch,
  person,
  checkInputs,
}) => {
  const [isCellPhoneNumberInputFocused, setIsCellPhoneNumberInputFocused] =
    useState<boolean>(false);
  const [isHomePhoneNumberInputFocused, setIsHomePhoneNumberInputFocused] =
    useState<boolean>(false);

  const [isCellPhoneNumberInputValid, setIsCellPhoneNumberInputValid] = useState<
    boolean | undefined
  >();
  const [isHomePhoneNumberInputValid, setIsHomePhoneNumberInputValid] = useState<
    boolean | undefined
  >();
  const [cellPhoneNumberInputError, setCellPhoneNumberInputError] = useState<string>('');
  const [homePhoneNumberInputError, setHomePhoneNumberInputError] = useState<string>('');

  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('');
  const [homePhoneNumber, setHomePhoneNumber] = useState<string>('');

  const [phoneNumbersAreDifferent, setPhoneNumbersAreDifferent] = useState<boolean>();

  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);

  const checkSameCellNumberError = (currentInput?: string) => {
    if (
      person === 'coBorrower' &&
      currentInput &&
      currentInput === state.borrower.cellPhoneNumber
    ) {
      setPhoneNumbersAreDifferent(false);
      setCellPhoneNumberInputError(messagesCheckInput.PHONE_NUMBER_IDENTICAL);
    } else {
      setPhoneNumbersAreDifferent(true);
      if (isCellPhoneNumberInputValid) {
        setCellPhoneNumberInputError('');
        removeFromInvalidInputs(
          state,
          dispatch,
          person === 'borrower' ? 'phoneBorrower' : 'phoneCoBorrower',
        );
      }
    }
  };

  const handlePhoneNumberChange = (value: string, cellPhone: boolean) => {
    const phoneNumber = cellPhone ? cellPhoneNumber : homePhoneNumber;
    if (!onlyNumbers.test(value.replaceAll(' ', ''))) return;

    let phone = phoneNumber.replaceAll('  ', ' ');
    phone = formatPhoneValue(phoneNumber, value).slice(0, 14);
    if (cellPhone) {
      dispatch({
        type: 'setCellPhoneNumber',
        payload: { person, value: phone },
      });
      setCellPhoneNumber(phone);
    } else {
      dispatch({
        type: 'setHomePhoneNumber',
        payload: { person, value: phone },
      });
      setHomePhoneNumber(phone);
    }
  };

  const cleanCellPhoneInput = () => {
    setCellPhoneNumberInputError('');
    removeFromInvalidInputs(
      state,
      dispatch,
      person === 'borrower' ? 'cellPhoneBorrower' : 'cellPhoneCoBorrower',
    );
  };

  const cleanHomePhoneInput = () => {
    setHomePhoneNumberInputError('');
    removeFromInvalidInputs(
      state,
      dispatch,
      person === 'borrower' ? 'homePhoneBorrower' : 'homePhoneCoBorrower',
    );
  };

  const handleCellPhoneNumberBlur = () => {
    setIsCellPhoneNumberInputFocused(false);
    const cellPhoneInput =
      person === 'borrower'
        ? state.borrower.cellPhoneNumber
        : state.coBorrower.cellPhoneNumber;
    const homePhoneInput =
      person === 'borrower'
        ? state.borrower.homePhoneNumber
        : state.coBorrower.homePhoneNumber;

    if (cellPhoneInput && isCellPhoneNumberValid(cellPhoneInput)) {
      setIsCellPhoneNumberInputValid(true);
      cleanCellPhoneInput();
      if (
        person === 'borrower' &&
        (state.editionType === 'papier' || !isSansGarantie())
      ) {
        if (!homePhoneInput) {
          setIsHomePhoneNumberInputValid(undefined);
          cleanHomePhoneInput();
        }
      }
    } else if (!cellPhoneInput) {
      if (state.editionType !== 'e-signature' || !isSansGarantie()) {
        if (person === 'borrower') {
          if (homePhoneInput && isHomePhoneNumberValid(homePhoneInput)) {
            setIsCellPhoneNumberInputValid(undefined);
            cleanCellPhoneInput();
          } else {
            setIsCellPhoneNumberInputValid(false);
            setCellPhoneNumberInputError(messagesCheckInput.PHONE_NUMBER_REQUIRED);
            addInInvalidInputs(state, dispatch, 'cellPhoneBorrower');
          }
        } else {
          setIsCellPhoneNumberInputValid(undefined);
          cleanCellPhoneInput();
        }
      } else {
        setIsCellPhoneNumberInputValid(false);
        setCellPhoneNumberInputError(messagesCheckInput.REQUIRED_VALUE);
        addInInvalidInputs(
          state,
          dispatch,
          person === 'borrower' ? 'cellPhoneBorrower' : 'cellPhoneCoBorrower',
        );
      }
    } else {
      setIsCellPhoneNumberInputValid(false);
      setCellPhoneNumberInputError(messagesCheckInput.PHONE_NUMBER_CELL_ERROR);
      addInInvalidInputs(
        state,
        dispatch,
        person === 'borrower' ? 'cellPhoneBorrower' : 'cellPhoneCoBorrower',
      );
    }
    checkSameCellNumberError(cellPhoneInput);
  };

  const handleHomePhoneNumberBlur = () => {
    const homePhoneInput =
      person === 'borrower'
        ? state.borrower.homePhoneNumber
        : state.coBorrower.homePhoneNumber;
    setIsHomePhoneNumberInputFocused(false);
    if (homePhoneInput && isHomePhoneNumberValid(homePhoneInput)) {
      setIsHomePhoneNumberInputValid(true);
      cleanHomePhoneInput();
      if (
        person === 'borrower' &&
        (state.editionType === 'papier' || !isSansGarantie())
      ) {
        if (!state.borrower.cellPhoneNumber) {
          setIsCellPhoneNumberInputValid(undefined);
          cleanCellPhoneInput();
        }
      }
    } else if (!homePhoneInput) {
      if (state.editionType === 'papier' || !isSansGarantie()) {
        if (
          (state.borrower.cellPhoneNumber &&
            isCellPhoneNumberValid(state.borrower.cellPhoneNumber)) ||
          person === 'coBorrower'
        ) {
          setIsHomePhoneNumberInputValid(undefined);
          cleanHomePhoneInput();
        } else {
          setIsHomePhoneNumberInputValid(false);
          setHomePhoneNumberInputError(messagesCheckInput.PHONE_NUMBER_REQUIRED);
          addInInvalidInputs(state, dispatch, 'homePhoneBorrower');
        }
      } else {
        setIsHomePhoneNumberInputValid(undefined);
        cleanHomePhoneInput();
      }
    } else {
      setIsHomePhoneNumberInputValid(false);
      setHomePhoneNumberInputError(messagesCheckInput.PHONE_NUMBER_HOME_ERROR);
      addInInvalidInputs(state, dispatch, 'homePhoneBorrower');
    }
  };

  useEffect(() => {
    checkSameCellNumberError(state.coBorrower.cellPhoneNumber);
  }, [state.borrower.cellPhoneNumber]);

  const renderLabelCellPhone = () => {
    if (person === 'borrower' || state.editionType === 'e-signature') {
      return messages.CELL_PHONE_LABEL;
    }
    return `${messages.CELL_PHONE_LABEL} <span className="lightText">(Facultatif)</span>`;
  };

  const renderLabelHomePhone = () => {
    if (
      person === 'borrower' &&
      (state.editionType !== 'e-signature' || !isSansGarantie())
    ) {
      return messages.HOME_PHONE_LABEL;
    }
    return `${messages.HOME_PHONE_LABEL} <span className="lightText">(Facultatif)</span>`;
  };

  useEffect(() => {
    if (checkInputs) {
      handleCellPhoneNumberBlur();
      // handleHomePhoneNumberBlur();
    }
  }, [checkInputs, state.editionType]);

  return (
    <>
      <CustomInput
        name={`${person}-cell-phone`}
        type="phone"
        label={parse(renderLabelCellPhone())}
        value={
          person === 'borrower'
            ? state.borrower.cellPhoneNumber
            : state.coBorrower.cellPhoneNumber
        }
        onChange={newValue => {
          handlePhoneNumberChange(newValue, true);
          setIsCellPhoneNumberInputFocused(true);
        }}
        isValid={isCellPhoneNumberInputValid && phoneNumbersAreDifferent}
        isFocused={isCellPhoneNumberInputFocused}
        onBlur={handleCellPhoneNumberBlur}
        onFocus={() => {
          setIsCellPhoneNumberInputFocused(true);
        }}
        inputWidth="64.8rem"
        placeholder="ex: 06 00 00 00 00"
        error={cellPhoneNumberInputError}
      />
      <Space marginTop="2.4rem" />
      <CustomInput
        name={`${person}-home-phone`}
        type="phone"
        label={parse(renderLabelHomePhone())}
        value={
          person === 'borrower'
            ? state.borrower.homePhoneNumber
            : state.coBorrower.homePhoneNumber
        }
        onChange={newValue => {
          handlePhoneNumberChange(newValue, false);
          setIsHomePhoneNumberInputFocused(true);
        }}
        isValid={isHomePhoneNumberInputValid}
        isFocused={isHomePhoneNumberInputFocused}
        onBlur={handleHomePhoneNumberBlur}
        onFocus={() => {
          setIsHomePhoneNumberInputFocused(true);
        }}
        inputWidth="64.8rem"
        placeholder="ex: 01 00 00 00 00"
        error={homePhoneNumberInputError}
      />
    </>
  );
};

export default PhoneNumber;
