import * as React from 'react';

interface SimulationIconProps {
  color?: string;
}
const SimulationIcon: React.FC<SimulationIconProps> = ({ color }) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
    <title>C03C647E-6499-4EEF-B996-5765505274A4</title>
    <g fill={color} fillRule="nonzero">
      <path d="M16.55 2h-9.1C4.44 2 2 4.45 2 7.45v9.1A5.45 5.45 0 0 0 7.45 22h9.1A5.45 5.45 0 0 0 22 16.55v-9.1c0-3-2.44-5.45-5.45-5.45ZM21 16.55C21 19 19 21 16.55 21h-9.1C5 21 3 19 3 16.55v-9.1C3 5 5 3 7.45 3h9.1C19 3 21 5 21 7.45v9.1Z" />
      <path d="M10.59 7.94H8.78V6.13c0-.33-.27-.6-.6-.6-.33 0-.6.27-.6.6v1.82H5.76c-.33 0-.6.27-.6.6 0 .33.27.6.6.6h1.82v1.82c0 .33.27.6.6.6.33 0 .6-.27.6-.6V9.14h1.82c.33 0 .6-.27.6-.6a.617.617 0 0 0-.61-.6ZM18.04 7.94h-4.09c-.33 0-.6.27-.6.6 0 .33.27.6.6.6h4.09c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6ZM18.04 13.73h-4.09c-.33 0-.6.27-.6.6 0 .33.27.6.6.6h4.09c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6ZM18.04 16.11h-4.09c-.33 0-.6.27-.6.6 0 .33.27.6.6.6h4.09c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6ZM10.47 13.22a.61.61 0 0 0-.85 0l-1.45 1.45-1.45-1.45a.61.61 0 0 0-.85 0 .61.61 0 0 0 0 .85l1.45 1.45-1.45 1.45a.61.61 0 0 0 0 .85.592.592 0 0 0 .84.01l1.45-1.45 1.45 1.45c.12.12.27.17.42.17.15 0 .31-.06.42-.18a.61.61 0 0 0 0-.85L9 15.52l1.45-1.45c.25-.23.25-.61.02-.85Z" />
    </g>
  </svg>
);

SimulationIcon.defaultProps = {
  color: '#3A913F',
};

export default SimulationIcon;
