import styled, { css } from 'styled-components';

const getVariantCss = (isChecked: boolean) => {
  if (isChecked) {
    return css`
      color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
      border-color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
      background-color: ${({ theme }) => theme.colors.chartColors.mainGreen50};
    `;
  }

  return css`
    border-color: ${({ theme }) => theme.colors.chartColors.neutral400};
    color: ${({ theme }) => theme.colors.chartColors.neutral500};
    background-color: transparent;

    &:focus {
      border-color: ${({ theme }) => theme.colors.chartColors.mainGreen500};
    }
  `;
};

const StyledButtonCheck = styled.button<{
  isChecked: boolean;
  height?: string;
  width?: string;
}>`
  font-size: 1.6rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  border-radius: 0.4rem;
  border: 0.1rem solid;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;

  height: ${({ height }) => height || '4.8rem'};
  width: ${({ width }) => width || '8rem'};

  ${({ isChecked }) => getVariantCss(isChecked)}
`;

export default StyledButtonCheck;
