import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import {
  AssuranceEmprunteur,
  CalculetteFinanciereAction,
} from 'reducers/calculetteFinanciere/type';

interface PTIAInsuranceProps {
  dispatch: React.Dispatch<CalculetteFinanciereAction>;
  person: 'borrower' | 'coBorrower';
  getPersonWarranty: AssuranceEmprunteur;
}

const PTIAInsurance: React.FC<PTIAInsuranceProps> = ({
  person,
  dispatch,
  getPersonWarranty,
}) => {
  /**
   * La quotité est égale à la valeur de l'assurance décés
   * @returns
   */
  const getQuotite = () => {
    const value = getPersonWarranty.quotitePTIA;
    if (value) {
      return `${value}%`;
    }
    return '0%';
  };

  const uncheckITTAndJobLossIfSG = () => {
    // le décoche de PTIA entraine automatiquement
    // le décoche des assurances ITT et perte emploi
    dispatch({
      type: 'setGarantieITT',
      payload: { person, value: 'N' },
    });
    dispatch({
      type: 'setQuotiteITT',
      payload: { person, value: undefined },
    });
    dispatch({
      type: 'setGarantiePerteEmploi',
      payload: { person, value: 'N' },
    });
    dispatch({
      type: 'setQuotitePerteEmploi',
      payload: { person, value: undefined },
    });
  };

  const isPTIAChecked = (): boolean | undefined => {
    return getPersonWarranty?.garantiePTIA === 'O' ? true : undefined;
  };

  return (
    <div style={{ marginTop: '4.5rem', display: 'flex', alignContent: 'center' }}>
      <BaseCheckbox
        checked={isPTIAChecked()}
        onCheckedChanged={e => {
          if ((e as CustomEvent)?.detail?.value) {
            dispatch({
              type: 'setGarantiePTIA',
              payload: { person, value: 'O' },
            });
            dispatch({
              type: 'setQuotitePTIA',
              payload: { person, value: getPersonWarranty.quotiteDeces },
            });
          } else {
            dispatch({
              type: 'setGarantiePTIA',
              payload: { person, value: 'N' },
            });
            dispatch({
              type: 'setQuotitePTIA',
              payload: { person, value: undefined },
            });
            uncheckITTAndJobLossIfSG();
          }
        }}
      />
      <span
        style={{
          marginLeft: '0.8rem',
          color: '#4B4F54',
        }}>
        {`Garantie PTIA ${getQuotite()}`}
      </span>
    </div>
  );
};

export default PTIAInsurance;
