import * as React from 'react';

const ListDocumentIcon: React.FC = () => (
  <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
    <title>292E29E3-D63A-4D70-A56F-5FF2279719F5</title>
    <g fill="#4B4F54" fillRule="nonzero">
      <path d="M52 6.934c0-.88-.717-1.6-1.592-1.6H25.355c-.424 0-.822.16-1.14.48L13.81 16.272c-.318.294-.478.72-.478 1.147v39.647c0 .88.717 1.6 1.593 1.6H50.38c.876 0 1.592-.72 1.592-1.6v-21.45c0-.108 0-.188-.026-.268L52 6.934Zm-3.185 33.137v15.394h-32.27V19.02h8.571c.876 0 1.593-.72 1.593-1.6V8.534h22.106L48.79 39.75c0 .106 0 .213.026.32Z" />
      <path d="m42.367 43.592-19.4.054c-.85 0-1.513.72-1.513 1.6 0 .881.69 1.601 1.513 1.601l19.4-.053c.849 0 1.512-.72 1.512-1.6.027-.908-.663-1.602-1.512-1.602ZM23.02 37.776l19.347-.053c.822 0 1.512-.72 1.512-1.6 0-.881-.69-1.602-1.512-1.602l-19.347.054c-.823 0-1.513.72-1.513 1.6 0 .88.664 1.601 1.513 1.601ZM42.393 24.97H23.02c-.85 0-1.54.72-1.54 1.6 0 .881.69 1.601 1.54 1.601h19.347c.849 0 1.539-.72 1.539-1.6 0-.88-.69-1.601-1.513-1.601Z" />
    </g>
  </svg>
);

export default ListDocumentIcon;
