import FormFooter from 'components/formFooter/FormFooter';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import StepContentWrapper from 'components/wrapper/StepContentWrapper';
import { useEffect, useReducer, useState } from 'react';
import {
  getSituationProfessionnelleInitialState,
  situationProfessionnelleReducer,
} from 'reducers/situationProfessionnelle/situationProfessionnelle';
import saveOnlyGoodData from 'reducers/situationProfessionnelle/utils';
import { CodeLibelle, Country, StatusControlInput, TypeContrat } from 'types';
import CoBorrowerTitle from 'components/titles/CoBorrowerTitle';
import { updateSessionStorage } from 'utils/storage';
import ContentTitle from 'components/titles/ContentTitle';
import GeneralConfirmationModal from 'components/modals/ConfirmationModals/GeneralConfirmationModal';
import { useNavigate } from 'react-router';
import {
  borrowerIdentityReducer,
  getBorrowerInitialState,
} from 'reducers/identity/borrowerIdentity';
import { getCodeAPE, getJobContracts, getJobs } from 'api/referentialService';
import useInputValidation from 'utils/useInputValidation';
import { ValidationRules } from 'utils/InputValidation';
import {
  isEmployedWorker,
  isOtherJob,
  isSelfEmployedWorker,
  isUnEmployed,
} from 'containers/communs/utils';
import CustomInput from 'components/customInput/CustomInput';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import WarningIcon from 'icons/WarningIcon';
import { StyledInfo } from 'containers/conditionsFinancieres/style';
import { ErrorMessage } from 'types/FaisabiliteDTO';
import { handleSaveAndExit, scrollToTopAndShowError } from 'utils/commun';
import Loader from 'components/Loader';
import TextArea from 'components/textArea/TextArea';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import * as messages from './messages';
import ProfessionOrSituation from './ProfessionOrSituation';
import JobContract from './JobContract';
import SituationStartingDate from './SituationStartingDate';
import EmployerCountry from './EmployerCountry';

interface SituationProfessionnelleFormProps {
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  countryList: Country[];
}

const SituationProfessionnelleForm: React.FC<SituationProfessionnelleFormProps> = ({
  onNextButtonClick,
  onBackButtonClick,
  countryList,
}) => {
  const navigate = useNavigate();
  const [isGeneralConfirmationModalVisible, setIsGeneralConfirmationModalVisible] =
    useState<boolean>(false);
  const [typeContratList, setTypeContratList] = useState<TypeContrat[]>([]);

  const [isSirenNumberBorrowerInputFocused, setIsSirenNumberBorrowerInputFocused] =
    useState<boolean>(false);
  const [statusSirenNumberBorrower, setStatusSirenNumberBorrower] =
    useState<StatusControlInput>();
  const [isSirenNumberCoBorrowerInputFocused, setIsSirenNumberCoBorrowerInputFocused] =
    useState<boolean>(false);
  const [statusSirenNumberCoBorrower, setStatusSirenNumberCoBorrower] =
    useState<StatusControlInput>();

  const [isApeNumberBorrowerInputFocused, setIsApeNumberBorrowerInputFocused] =
    useState<boolean>(false);
  const [statusApeNumberBorrower, setStatusApeNumberBorrower] =
    useState<StatusControlInput>();
  const [isApeNumberCoBorrowerInputFocused, setIsApeNumberCoBorrowerInputFocused] =
    useState<boolean>(false);
  const [statusApeNumberCoBorrower, setStatusApeNumberCoBorrower] =
    useState<StatusControlInput>();

  const [hasError, setHasError] = useState<boolean>(false);
  const [checkInputs, setCheckInputs] = useState<boolean>(false);

  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [situationProfessionnelleState, situationProfessionnelleReducerDispatch] =
    useReducer(
      situationProfessionnelleReducer,
      getSituationProfessionnelleInitialState('situationProfessionnelle'),
    );
  const [borrowerState] = useReducer(
    borrowerIdentityReducer,
    getBorrowerInitialState('borrowerIdentity'),
  );

  const [jobContractsReferential, setJobContractsReferential] = useState<TypeContrat[]>(
    [],
  );
  const [listCodeApe, setListCodeApe] = useState<string[]>([]);

  type Job = CodeLibelle & {
    ordreString: string;
  };
  const [jobsReferential, setJobsReferential] = useState<Job[]>([]);

  useInputValidation('situationProfessionnelle', 'show-input-ds-validation', [
    jobsReferential,
    jobContractsReferential,
    typeContratList,
  ]);

  const apeRegexp = new RegExp(ValidationRules.apeRegexp);
  const sirenRegexp = new RegExp(ValidationRules.sirenRegexp);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
  const onlyNumbersAndAlphabeticChars = new RegExp(
    ValidationRules.onlyNumbersAndAlphabeticChars,
  );

  const checkStatusSirenNumberBorrower = () => {
    if (situationProfessionnelleState.borrower.sirenNumber === undefined) {
      setStatusSirenNumberBorrower({
        status: false,
        errorMessage: messagesCheckInput.SIREN_REQUIRED,
      });
    } else if (!sirenRegexp.test(situationProfessionnelleState.borrower.sirenNumber)) {
      setStatusSirenNumberBorrower({
        status: false,
        errorMessage: messagesCheckInput.SIREN_FORMAT,
      });
    } else if (situationProfessionnelleState.borrower.sirenNumber[0] === '0') {
      setStatusSirenNumberBorrower({
        status: false,
        errorMessage: messagesCheckInput.SIREN_NO_START_ZERO,
      });
    } else {
      setStatusSirenNumberBorrower({
        status: true,
        errorMessage: '',
      });
    }
  };

  const checkStatusSirenNumberCoBorrower = () => {
    if (situationProfessionnelleState.coBorrower.sirenNumber === undefined) {
      setStatusSirenNumberCoBorrower({
        status: false,
        errorMessage: messagesCheckInput.SIREN_REQUIRED,
      });
    } else if (!sirenRegexp.test(situationProfessionnelleState.coBorrower.sirenNumber)) {
      setStatusSirenNumberCoBorrower({
        status: false,
        errorMessage: messagesCheckInput.SIREN_FORMAT,
      });
    } else if (situationProfessionnelleState.coBorrower.sirenNumber.charAt(0) === '0') {
      setStatusSirenNumberCoBorrower({
        status: false,
        errorMessage: messagesCheckInput.SIREN_NO_START_ZERO,
      });
    } else {
      setStatusSirenNumberCoBorrower({
        status: true,
        errorMessage: '',
      });
    }
  };

  const checkStatusApeNumberBorrower = () => {
    if (situationProfessionnelleState.borrower.apeNumber === undefined) {
      setStatusApeNumberBorrower({
        status: false,
        errorMessage: messagesCheckInput.APE_REQUIRED,
      });
    } else if (!apeRegexp.test(situationProfessionnelleState.borrower.apeNumber)) {
      setStatusApeNumberBorrower({
        status: false,
        errorMessage: messagesCheckInput.APE_FORMAT,
      });
    } else if (
      listCodeApe.length > 0 &&
      !listCodeApe.includes(situationProfessionnelleState.borrower.apeNumber)
    ) {
      setStatusApeNumberBorrower({
        status: false,
        errorMessage: messagesCheckInput.APE_VALUE_ERROR,
      });
    } else {
      setStatusApeNumberBorrower({
        status: true,
        errorMessage: '',
      });
    }
  };

  const checkStatusApeNumberCoBorrower = () => {
    if (situationProfessionnelleState.coBorrower.apeNumber === undefined) {
      setStatusApeNumberCoBorrower({
        status: false,
        errorMessage: messagesCheckInput.APE_REQUIRED,
      });
    } else if (!apeRegexp.test(situationProfessionnelleState.coBorrower.apeNumber)) {
      setStatusApeNumberCoBorrower({
        status: false,
        errorMessage: messagesCheckInput.APE_FORMAT,
      });
    } else if (
      listCodeApe.length > 0 &&
      !listCodeApe.includes(situationProfessionnelleState.coBorrower.apeNumber)
    ) {
      setStatusApeNumberCoBorrower({
        status: false,
        errorMessage: messagesCheckInput.APE_VALUE_ERROR,
      });
    } else {
      setStatusApeNumberCoBorrower({
        status: true,
        errorMessage: '',
      });
    }
  };

  const isApeOK = (): boolean => {
    if (
      !isUnEmployed(situationProfessionnelleState.borrower.profession) &&
      situationProfessionnelleState.borrower.employerCountry?.code === 'FR' &&
      !statusApeNumberBorrower?.status
    ) {
      return false;
    }
    if (
      borrowerState.hasCoBorrower &&
      !isUnEmployed(situationProfessionnelleState.coBorrower.profession) &&
      situationProfessionnelleState.coBorrower.employerCountry?.code === 'FR' &&
      !statusApeNumberCoBorrower?.status
    )
      return false;
    return true;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      situationProfessionnelleState.borrower.sirenNumber !== undefined &&
      situationProfessionnelleState.borrower.sirenNumber !== ''
    ) {
      checkStatusSirenNumberBorrower();
    }
    if (
      situationProfessionnelleState.coBorrower.sirenNumber !== undefined &&
      situationProfessionnelleState.coBorrower.sirenNumber !== ''
    ) {
      checkStatusSirenNumberCoBorrower();
    }
    if (
      situationProfessionnelleState.borrower.apeNumber !== undefined &&
      situationProfessionnelleState.borrower.apeNumber !== ''
    ) {
      checkStatusApeNumberBorrower();
    }
    if (
      situationProfessionnelleState.coBorrower.apeNumber !== undefined &&
      situationProfessionnelleState.coBorrower.apeNumber !== ''
    ) {
      checkStatusApeNumberCoBorrower();
    }
  }, []);

  useEffect(() => {
    const fetchJobContracts = async () => {
      try {
        const response = await getJobContracts();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: TypeContrat[] = [];
          Resultat.forEach(item => {
            temp.push({
              code: item.code,
              libelle: item.libelle,
              ordreString: item.ordreString,
            });
          });
          setJobContractsReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    const fetchCodeAPE = async () => {
      try {
        const response = await getCodeAPE();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: string[] = [];
          Resultat.forEach(item => {
            temp.push(item.code);
          });
          setListCodeApe(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchJobContracts();
    fetchCodeAPE();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getJobs();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: Job[] = [];
          Resultat.forEach(item => {
            temp.push({
              code: item.code,
              libelle: item.libelle,
              ordreString: item.ordreString,
            });
          });
          setJobsReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchJobs();
  }, []);

  useEffect(() => {
    situationProfessionnelleReducerDispatch({
      type: 'setHasCoBorrower',
      payload: borrowerState.hasCoBorrower || false,
    });
  }, [borrowerState]);

  useEffect(() => {
    setTypeContratList(jobContractsReferential);
  }, [jobContractsReferential]);

  useEffect(() => {
    if (checkInputs) {
      checkStatusApeNumberBorrower();
      checkStatusApeNumberCoBorrower();
      checkStatusSirenNumberBorrower();
      checkStatusSirenNumberCoBorrower();
    }
  }, [checkInputs]);

  const saveInSessionStorage = (): void => {
    updateSessionStorage(
      'situationProfessionnelle',
      saveOnlyGoodData(situationProfessionnelleState),
    );
  };

  const onSaveButtonClick = (): void => {
    setIsGeneralConfirmationModalVisible(true);
  };

  const closeModal = (): void => {
    setIsGeneralConfirmationModalVisible(false);
  };

  const renderSiren = (person: 'borrower' | 'coBorrower') => {
    const personState =
      person === 'borrower'
        ? situationProfessionnelleState.borrower
        : situationProfessionnelleState.coBorrower;

    if (
      !isUnEmployed(personState.profession) &&
      ['FR', '', 'err'].includes(personState.employerCountry?.code || '')
    ) {
      return (
        <div style={{ display: 'flex', gap: '1.6rem' }}>
          {isSelfEmployedWorker(personState.profession) && (
            <div style={{ width: '30rem' }}>
              <CustomInput
                name="siren-number"
                label="N° SIREN"
                placeholder="ex: 000 000 000"
                onChange={newValue => {
                  if (person === 'borrower') setIsSirenNumberBorrowerInputFocused(true);
                  else setIsSirenNumberCoBorrowerInputFocused(true);
                  let value =
                    person === 'borrower'
                      ? situationProfessionnelleState.borrower.sirenNumber
                      : situationProfessionnelleState.coBorrower.sirenNumber;
                  if (onlyNumbers.test(newValue)) value = newValue;
                  situationProfessionnelleReducerDispatch({
                    type: 'setSirenNumber',
                    payload: { person, value: value?.slice(0, 9) },
                  });
                }}
                onBlur={() => {
                  if (person === 'borrower') checkStatusSirenNumberBorrower();
                  else checkStatusSirenNumberCoBorrower();
                }}
                onFocus={() => {
                  if (person === 'borrower') setIsSirenNumberBorrowerInputFocused(true);
                  else setIsSirenNumberCoBorrowerInputFocused(true);
                }}
                inputWidth="30rem"
                isValid={
                  person === 'borrower'
                    ? statusSirenNumberBorrower?.status
                    : statusSirenNumberCoBorrower?.status
                }
                isFocused={
                  person === 'borrower'
                    ? isSirenNumberBorrowerInputFocused
                    : isSirenNumberCoBorrowerInputFocused
                }
                value={
                  person === 'borrower'
                    ? situationProfessionnelleState.borrower.sirenNumber
                    : situationProfessionnelleState.coBorrower.sirenNumber
                }
                error={
                  person === 'borrower'
                    ? statusSirenNumberBorrower?.errorMessage
                    : statusSirenNumberCoBorrower?.errorMessage
                }
              />
            </div>
          )}
          <div style={{ width: '14rem' }}>
            <CustomInput
              name="ape-number"
              label="N° APE"
              placeholder="ex: 0000F"
              inputWidth="14rem"
              onChange={newValue => {
                if (person === 'borrower') setIsApeNumberBorrowerInputFocused(true);
                else setIsApeNumberCoBorrowerInputFocused(true);
                let value =
                  person === 'borrower'
                    ? situationProfessionnelleState.borrower.apeNumber
                    : situationProfessionnelleState.coBorrower.apeNumber;
                if (onlyNumbersAndAlphabeticChars.test(newValue)) value = newValue;
                situationProfessionnelleReducerDispatch({
                  type: 'setApeNumber',
                  payload: { person, value: value?.toUpperCase().slice(0, 5) },
                });
              }}
              onBlur={() => {
                if (person === 'borrower') checkStatusApeNumberBorrower();
                else checkStatusApeNumberCoBorrower();
              }}
              onFocus={() => {
                if (person === 'borrower') setIsApeNumberBorrowerInputFocused(true);
                else setIsApeNumberCoBorrowerInputFocused(true);
              }}
              isValid={
                person === 'borrower'
                  ? statusApeNumberBorrower?.status
                  : statusApeNumberCoBorrower?.status
              }
              isFocused={
                person === 'borrower'
                  ? isApeNumberBorrowerInputFocused
                  : isApeNumberCoBorrowerInputFocused
              }
              value={
                person === 'borrower'
                  ? situationProfessionnelleState.borrower.apeNumber
                  : situationProfessionnelleState.coBorrower.apeNumber
              }
              error={
                person === 'borrower'
                  ? statusApeNumberBorrower?.errorMessage
                  : statusApeNumberCoBorrower?.errorMessage
              }
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const displayContractType = (person: 'borrower' | 'coBorrower') => {
    const prof =
      person === 'borrower'
        ? situationProfessionnelleState.borrower.profession
        : situationProfessionnelleState.coBorrower.profession;
    if (isEmployedWorker(prof) || isOtherJob(prof)) {
      return (
        <JobContract
          person={person}
          state={situationProfessionnelleState}
          dispatch={situationProfessionnelleReducerDispatch}
          typeContratList={typeContratList}
          checkInputs={checkInputs}
        />
      );
    }
    return null;
  };

  const displayCurrentSituationStartingDate = (person: 'borrower' | 'coBorrower') => {
    const prof =
      person === 'borrower'
        ? situationProfessionnelleState.borrower.profession
        : situationProfessionnelleState.coBorrower.profession;
    if (isUnEmployed(prof)) {
      return null;
    }
    return (
      <SituationStartingDate
        state={situationProfessionnelleState}
        dispatch={situationProfessionnelleReducerDispatch}
        person={person}
        checkInputs={checkInputs}
      />
    );
  };

  const displayEmployerCountry = (person: 'borrower' | 'coBorrower') => {
    const prof =
      person === 'borrower'
        ? situationProfessionnelleState.borrower.profession
        : situationProfessionnelleState.coBorrower.profession;
    if (isUnEmployed(prof)) {
      return null;
    }
    return (
      <EmployerCountry
        state={situationProfessionnelleState}
        dispatch={situationProfessionnelleReducerDispatch}
        person={person}
        checkInputs={checkInputs}
        countryList={countryList}
      />
    );
  };

  return (
    <>
      {withSummaryCard(
        'Identity',
        <>
          <StepTitles subTitle={messages.SUB_TITLE} />
          {hasError && (
            <>
              <Space marginTop="2.4rem" />
              <TextArea
                title={messagesCheckInput.CHECK_ERROR_TITLE}
                type="error"
                includeIcon>
                <span> {messagesCheckInput.CHECK_ERROR}</span>
              </TextArea>
            </>
          )}
          <Space marginTop="2.4rem" />
          <ContentTitle text={messages.SITUATION_PRO} />
          <StepContentWrapper width="94rem">
            <Space marginBottom="1rem" />
            <ProfessionOrSituation
              state={situationProfessionnelleState}
              dispatch={situationProfessionnelleReducerDispatch}
              person="borrower"
              jobsReferential={jobsReferential}
              checkInputs={checkInputs}
            />
            <Space marginBottom="2.4rem" />
            <>{displayContractType('borrower')}</>
            <Space marginBottom="2.4rem" />
            {displayCurrentSituationStartingDate('borrower')}
            <Space marginBottom="2.4rem" />
            {displayEmployerCountry('borrower')}
            <Space marginBottom="2.4rem" />
            {renderSiren('borrower')}

            {borrowerState.hasCoBorrower && (
              <>
                <Space marginBottom="5.6rem" />
                <CoBorrowerTitle />
                <p style={{ fontWeight: 'bold', margin: '0', marginBottom: '1.6rem' }}>
                  {messages.SITUATION_PRO_CoBORROWER}
                </p>
                <ProfessionOrSituation
                  state={situationProfessionnelleState}
                  dispatch={situationProfessionnelleReducerDispatch}
                  person="coBorrower"
                  jobsReferential={jobsReferential}
                  checkInputs={checkInputs}
                />
                <Space marginBottom="2.4rem" />
                <>{displayContractType('coBorrower')}</>
                <Space marginBottom="2.4rem" />
                {displayCurrentSituationStartingDate('coBorrower')}
                <Space marginBottom="2.4rem" />
                {displayEmployerCountry('coBorrower')}
                <Space marginBottom="2.4rem" />
                {renderSiren('coBorrower')}
              </>
            )}
            <Space marginBottom="2.4rem" />
          </StepContentWrapper>
        </>,
      )}

      <GeneralConfirmationModal
        isVisible={isGeneralConfirmationModalVisible}
        isDataOK={situationProfessionnelleState.isDataOk}
        accept={() => {
          handleSaveAndExit({
            setErrorMessages,
            setIsLoading,
            setIsModalVisible: setIsGeneralConfirmationModalVisible,
            saveInSessionStorage,
            navigate,
          });
        }}
        close={closeModal}
      />

      {errorMessages.length > 0 && (
        <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '10.4rem' }}>
          <WarningIcon />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {errorMessages.map(message => {
              return (
                <p
                  style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}
                  key={message.MessageId}>
                  {message.MessageLib}
                </p>
              );
            })}
          </div>
        </StyledInfo>
      )}

      <FormFooter
        showPreviousButton
        onSaveButtonClick={onSaveButtonClick}
        onNextButtonClick={() => {
          if (situationProfessionnelleState.isDataOk && isApeOK()) {
            saveInSessionStorage();
            onNextButtonClick();
          } else {
            scrollToTopAndShowError({ setHasError, setCheckInputs });
          }
        }}
        onPreviousButtonClick={onBackButtonClick}
      />
      {isLoading ? <Loader animationDuration={2} /> : null}
    </>
  );
};

export default SituationProfessionnelleForm;
