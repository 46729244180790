import { isUnEmployedExceptRetired } from 'containers/communs/utils';
import { SituationProfessionnelleState } from 'reducers/situationProfessionnelle/types';
import { Bank, Profession, SessionStorageKeys } from 'types';
import { getDateFromInput, isDateIntheFuture, isRightAge } from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import { getExistingStorageByKey } from 'utils/storage';
import { getBirthYearOlderBorrower } from 'utils/commun';
import { Amount, RessourcesMensuellesAction, RessourcesMensuellesState } from './types';

const firstDayOfMonth = '01/';
const firstMonth = '01/';

const yearRegexp = new RegExp(ValidationRules.yearRegexp);
const nonZeroNumbers = new RegExp(ValidationRules.nonZeroNumbers);
const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
const notEmpty = new RegExp(ValidationRules.notEmpty);

export const getRessourcesMensuellesInitialState = (
  storageKey: SessionStorageKeys,
): RessourcesMensuellesState => {
  const ressourcesMensuellesDefaultValues = {
    borrowerIncome: {
      monthlyIncomes: '',
      retirementMonthlyIncomes: '',
    },
    coBorrowerIncome: {
      monthlyIncomes: '',
      retirementMonthlyIncomes: '',
    },
    hasOtherIncomes: undefined,
    otherIncomes: [],
    hasMonthlyCharges: undefined,
    monthlyCharges: [],
    banks: [],
    invalidInputs: [],
    isDataOk: false,
  };
  return getExistingStorageByKey(
    storageKey,
    ressourcesMensuellesDefaultValues,
  ) as RessourcesMensuellesState;
};

const isIncomeZeroAcceptedValueOk = (value: string | undefined): boolean => {
  if (!value) return false;
  if (!notEmpty.test(value)) return false;
  if (!onlyNumbers.test(value.replaceAll(' ', ''))) return false;
  return true;
};

const isIncomeValueOk = (value: string | undefined): boolean => {
  if (!value) return false;
  if (!notEmpty.test(value)) return false;
  if (!nonZeroNumbers.test(value.replaceAll(' ', ''))) return false;
  return true;
};

const isYearOpenBankOkay = (year: string | undefined): boolean => {
  if (year === undefined) return false;
  if (!yearRegexp.test(year.toString())) return false;
  if (!isDateIntheFuture(getDateFromInput(`${firstDayOfMonth}${firstMonth}${year}`)))
    return false;
  if (
    !isRightAge(
      getDateFromInput(`${firstDayOfMonth}${firstMonth}${getBirthYearOlderBorrower()}`),
      getDateFromInput(`${firstDayOfMonth}${firstMonth}${year}`),
      15,
    )
  )
    return false;
  return true;
};

const checkIfAmountsAreOk = (amounts: Amount[]): boolean => {
  let result = true;
  for (let i = 0; i < amounts.length; i += 1) {
    if (
      !amounts[i].code ||
      !amounts[i].libelle ||
      amounts[i].value === '' ||
      Number.isNaN(Number(amounts[i].value?.replaceAll(' ', ''))) ||
      Number(amounts[i].value) < 0
    ) {
      result = false;
      break;
    }
  }
  return result;
};

const checkIfBanksInformationAreOk = (banks: Bank[]): boolean => {
  if (banks.length === 0) {
    return false;
  }
  for (let i = 0; i < banks.length; i += 1) {
    if (
      !banks[i].code ||
      banks[i].code === '' ||
      banks[i].code === 'err' ||
      !banks[i].libelle ||
      banks[i].libelle === '' ||
      !isYearOpenBankOkay(banks[i].year)
    ) {
      return false;
    }
  }
  return true;
};

const checkIfDatasAreOk = (state: RessourcesMensuellesState): boolean => {
  if (state.invalidInputs.length > 0) {
    return false;
  }
  const {
    borrowerIncome,
    coBorrowerIncome,
    hasOtherIncomes,
    otherIncomes,
    hasMonthlyCharges,
    monthlyCharges,
    banks,
    hasCoBorrower,
  } = state;
  const situationProfessionnelle = getExistingStorageByKey(
    'situationProfessionnelle',
  ) as SituationProfessionnelleState;
  let professionBorrower: Profession | undefined;
  let professionCoBorrower: Profession | undefined;
  if (situationProfessionnelle) {
    professionBorrower = situationProfessionnelle.borrower.profession;
    professionCoBorrower = situationProfessionnelle.coBorrower.profession;
  }

  if (professionBorrower?.code === 'IRE') {
    if (
      !isIncomeZeroAcceptedValueOk(borrowerIncome?.monthlyIncomes) ||
      !isIncomeValueOk(borrowerIncome.retirementMonthlyIncomes)
    ) {
      return false;
    }
  } else if (
    !isUnEmployedExceptRetired(professionBorrower) &&
    !isIncomeValueOk(borrowerIncome?.monthlyIncomes)
  ) {
    return false;
  }

  if (professionCoBorrower?.code === 'IRE') {
    if (
      !isIncomeZeroAcceptedValueOk(coBorrowerIncome?.monthlyIncomes) ||
      !isIncomeValueOk(coBorrowerIncome.retirementMonthlyIncomes)
    ) {
      return false;
    }
  } else if (
    hasCoBorrower &&
    !isUnEmployedExceptRetired(professionCoBorrower) &&
    !isIncomeValueOk(coBorrowerIncome?.monthlyIncomes)
  ) {
    return false;
  }

  if (hasOtherIncomes) {
    if (!checkIfAmountsAreOk(otherIncomes)) {
      return false;
    }
  }
  if (hasMonthlyCharges) {
    if (!checkIfAmountsAreOk(monthlyCharges)) {
      return false;
    }
  }

  if (!checkIfBanksInformationAreOk(banks)) {
    return false;
  }

  return true;
};

export const ressourcesMensuellesReducer = (
  state: RessourcesMensuellesState,
  action: RessourcesMensuellesAction,
): RessourcesMensuellesState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setMonthlyIncomes':
      if (action.payload?.person === 'borrower') {
        newState.borrowerIncome.monthlyIncomes = action.payload.value;
      } else {
        newState.coBorrowerIncome.monthlyIncomes = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setRetirementMonthlyIncomes':
      if (action.payload?.person === 'borrower') {
        newState.borrowerIncome.retirementMonthlyIncomes = action.payload.value;
      } else {
        newState.coBorrowerIncome.retirementMonthlyIncomes = action.payload.value;
      }
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasCoBorrower': {
      newState.hasCoBorrower = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setHasOtherIncomes':
      newState.hasOtherIncomes = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setOtherIncomes':
      newState.otherIncomes = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setHasMonthlyCharges':
      newState.hasMonthlyCharges = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setMonthlyCharges':
      newState.monthlyCharges = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setBanks':
      newState.banks = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setAmountForCharge': {
      const updatedCharges = state.monthlyCharges.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, value: action.payload.value };
        }
        return item;
      });
      newState.monthlyCharges = updatedCharges;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setAmountForIncome': {
      const updatedIncomes = state.otherIncomes.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, value: action.payload.value };
        }
        return item;
      });
      newState.otherIncomes = updatedIncomes;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    }
    case 'setInvalidInputs':
      newState.invalidInputs = action.payload;
      newState.isDataOk = checkIfDatasAreOk(newState);
      return newState;
    case 'setSwitchRetirement':
      return action.payload;
    default:
      return state;
  }
};
