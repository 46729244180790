import * as React from 'react';

interface ChevronDownProps {
  color?: string;
}

const ChevronDown: React.FC<ChevronDownProps> = ({ color }) => (
  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg">
    <title>Ic/24px/Navigation/chevron-down24px</title>
    <path
      d="M2.226 7.21A.8.8 0 0 1 2.771 7a.8.8 0 0 1 .544.21L12 15.275l8.685-8.065a.811.811 0 0 1 .744-.185.745.745 0 0 1 .545.505.68.68 0 0 1-.2.691l-9.23 8.57A.8.8 0 0 1 12 17a.8.8 0 0 1-.544-.21l-9.23-8.569A.69.69 0 0 1 2 7.715a.69.69 0 0 1 .226-.505Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

ChevronDown.defaultProps = {
  color: '#292C2E',
};

export default ChevronDown;
