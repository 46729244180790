import { ChildrenBirthYear } from 'reducers/situationFoyer/types';
import CrossCircle from 'icons/CrossCircle';
import { useEffect, useState } from 'react';
import DeleteConfirmationModal from 'components/modals/ConfirmationModals/DeleteConfirmationModal';
import CustomInput from 'components/customInput/CustomInput';
import { StatusControlInput } from 'types';
import { ValidationRules } from 'utils/InputValidation';
import { getDateFromInput, isDateIntheFuture } from 'utils/DateUtils';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import { getBirthYearOlderBorrower } from 'utils/commun';

interface ChildrenInChargeProps {
  child?: ChildrenBirthYear;
  checkInputs: boolean;
  updateState: (year: string) => void;
  onDelete: (childId: number) => void;
}
const ChildrenInCharge: React.FC<ChildrenInChargeProps> = ({
  child,
  checkInputs,
  updateState,
  onDelete,
}) => {
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState<boolean>(false);
  const [isYearInputFocused, setIsYearInputFocused] = useState<boolean>(false);
  const [statusYear, setstatusYear] = useState<StatusControlInput>();
  const [value, setValue] = useState<string>(child?.birthYear || '');

  const firstDayOfMonth = '01/';
  const firstMonth = '01/';

  const yearRegexp = new RegExp(ValidationRules.yearRegexp);
  const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);

  const checkBirthYear = () => {
    const olderBorrower = getBirthYearOlderBorrower();

    setIsYearInputFocused(true);
    if (!value) {
      setstatusYear({
        status: false,
        errorMessage: messagesCheckInput.REQUIRED_VALUE,
      });
    } else if (!yearRegexp.test(value)) {
      setstatusYear({
        status: false,
        errorMessage: messagesCheckInput.YEAR_FORMAT,
      });
    } else if (
      !isDateIntheFuture(getDateFromInput(`${firstDayOfMonth}${firstMonth}${value}`))
    ) {
      setstatusYear({
        status: false,
        errorMessage: messagesCheckInput.YEAR_FUTUR,
      });
    } else if (Number(value) <= 1970) {
      setstatusYear({
        status: false,
        errorMessage: messagesCheckInput.BIRTH_CHILDREN_YEAR_BEFORE_1970,
      });
    } else if (olderBorrower > Number(value)) {
      setstatusYear({
        status: false,
        errorMessage: messagesCheckInput.DATE_BEFORE_BORN,
      });
    } else {
      setstatusYear({
        status: true,
        errorMessage: '',
      });
    }
    updateState(value.slice(0, 4));
  };

  useEffect(() => {
    if (value) {
      checkBirthYear();
    }
  }, []);

  useEffect(() => {
    if (checkInputs) {
      checkBirthYear();
    }
  }, [checkInputs]);

  return (
    <div>
      <div style={{ width: '10.6rem', textAlign: 'left' }}>
        <CustomInput
          name="child-birth-date"
          className="child-birth-date-input"
          label=""
          placeholder="aaaa"
          onChange={newValue => {
            setValue(onlyNumbers.test(newValue) ? newValue.slice(0, 4) : value);
            setIsYearInputFocused(true);
          }}
          isValid={statusYear?.status}
          isFocused={isYearInputFocused}
          onBlur={checkBirthYear}
          onFocus={() => {
            setIsYearInputFocused(true);
          }}
          inputWidth="10.6rem"
          value={value}
          error={statusYear?.errorMessage}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '1rem',
          fontWeight: 'bold',
          fontSize: '1.2rem',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          setIsDeleteConfirmationVisible(true);
        }}
        role="button"
        tabIndex={0}
        onKeyDown={event => {
          if (
            event.code === 'Enter' ||
            event.code === 'Space' ||
            event.code === 'NumpadEnter'
          )
            setIsDeleteConfirmationVisible(true);
        }}>
        <CrossCircle />
        <span style={{ marginTop: '0.3rem', marginLeft: '0.5rem', fontSize: '1.4rem' }}>
          Supprimer
        </span>
      </div>

      <DeleteConfirmationModal
        isVisible={isDeleteConfirmationVisible}
        accept={() => {
          if (child?.childId) {
            onDelete(child?.childId);
          }
        }}
        close={() => {
          setIsDeleteConfirmationVisible(false);
        }}
      />
    </div>
  );
};

export default ChildrenInCharge;
