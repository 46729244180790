import {
  checkFormatDate,
  getDateFromInput,
  getMonthsRemaining,
  isDateIntheFuture,
} from 'utils/DateUtils';
import { ValidationRules } from 'utils/InputValidation';
import { Asset, Loan, OperationState } from './types';

const onlyNumbers = new RegExp(ValidationRules.onlyNumbers);
const zipCode = new RegExp(ValidationRules.zipCode);
const zipCodeNoZero = new RegExp(ValidationRules.zipCodeNoZero);
const notEmpty = new RegExp(ValidationRules.notEmpty);

const isLoanStartDateOkay = (loanStartDateParam: string | undefined): boolean => {
  if (loanStartDateParam === '' || loanStartDateParam === undefined) return false;
  const loanStartDateString = loanStartDateParam as string;
  const loanStartDate = getDateFromInput(loanStartDateString);

  if (loanStartDate && !checkFormatDate(loanStartDateString)) return false;
  if (!isDateIntheFuture(loanStartDate)) return false;
  if (loanStartDate.getFullYear() < 1900) return false;

  return true;
};

const isLoanDurationOkay = (
  loanDurationParam: string | undefined,
  loanStartDateParam: string | undefined,
): boolean => {
  const loanDuration = loanDurationParam ? loanDurationParam.replace(/ /g, '') : '';
  if (!notEmpty.test(loanDuration)) return false;
  if (!onlyNumbers.test(loanDuration)) return false;
  if (Number(loanDuration) > 360) return false;
  const value =
    Number(loanDuration) -
    getMonthsRemaining(getDateFromInput(loanStartDateParam || ''), new Date());
  if (value <= 0) return false;
  return true;
};

const checkBuyBackConsommationCase = (state: Loan): boolean => {
  if (
    state.balance &&
    state.buyBack === true &&
    state.creditType?.code === 'C' &&
    state.loaner &&
    isLoanStartDateOkay(state.loanStartDate) &&
    isLoanDurationOkay(state.totalLoanDuration, state.loanStartDate) &&
    state.monthlyPayment &&
    state.initialLoanCapital &&
    state.restructure !== undefined &&
    (state.restructure === false || state.mortgage !== undefined)
  ) {
    return true;
  }
  return false;
};

const checkBuyBackRenouvelableCase = (state: Loan): boolean => {
  if (
    state.creditType?.code === 'R' &&
    state.loaner &&
    state.monthlyPayment &&
    state.balance &&
    state.initialLoanCapital
  ) {
    return true;
  }
  return false;
};

const isBuybackCaseValid = (state: Loan): boolean => {
  if (
    checkBuyBackRenouvelableCase(state) ||
    checkBuyBackConsommationCase(state) ||
    (state.balance &&
      state.buyBack === true &&
      state.creditType &&
      state.loaner &&
      isLoanStartDateOkay(state.loanStartDate) &&
      isLoanDurationOkay(state.totalLoanDuration, state.loanStartDate) &&
      state.monthlyPayment &&
      state.initialLoanCapital &&
      state.restructure !== undefined &&
      state.mortgage !== undefined)
  ) {
    return true;
  }

  return false;
};

const isKeepCaseValid = (state: Loan): boolean => {
  if (state.totalLoanDuration && Number(state.totalLoanDuration) > 360) return false;

  if (
    state.creditType?.code === 'C' &&
    state.loaner &&
    state.monthlyPayment &&
    state.balance
  ) {
    if (
      state.initialLoanCapital &&
      Number(state.initialLoanCapital) < Number(state.balance)
    )
      return false;
    return true;
  }
  if (
    state.creditType?.code === 'I' &&
    state.loaner &&
    state.monthlyPayment &&
    state.balance &&
    state.mortgage !== undefined
  ) {
    if (
      state.initialLoanCapital &&
      Number(state.initialLoanCapital) < Number(state.balance)
    )
      return false;
    return true;
  }
  if (
    state.creditType?.code === 'R' &&
    state.monthlyPayment &&
    state.loaner &&
    state.balance &&
    state.initialLoanCapital &&
    Number(state.initialLoanCapital) >= Number(state.balance)
  ) {
    return true;
  }
  return false;
};

export const checkIfCurrentLoanDatasAreOk = (state: Loan): boolean => {
  if (
    (state.buyBack === true &&
      isBuybackCaseValid(state) &&
      Number(state.initialLoanCapital) >= Number(state.balance)) ||
    (state.buyBack === false && isKeepCaseValid(state))
  ) {
    return true;
  }
  return false;
};

export const saveOnlygoodLoanData = (loan: Loan): Loan => {
  const loanToSave: Loan = {
    code: loan.code,
    buyBack: loan.buyBack,
    creditType: loan.creditType,
    loaner: loan.loaner,
    isDataOk: loan.isDataOk,
  };

  if (loan.buyBack) {
    switch (loan.creditType?.code) {
      case 'C':
        loanToSave.loanStartDate = loan.loanStartDate;
        loanToSave.monthlyPayment = loan.monthlyPayment;
        loanToSave.totalLoanDuration = loan.totalLoanDuration;
        loanToSave.balance = loan.balance;
        loanToSave.initialLoanCapital = loan.initialLoanCapital;
        loanToSave.restructure = loan.restructure;
        if (loanToSave.restructure) loanToSave.mortgage = loan.mortgage;
        break;
      case 'I':
        loanToSave.loanStartDate = loan.loanStartDate;
        loanToSave.monthlyPayment = loan.monthlyPayment;
        loanToSave.totalLoanDuration = loan.totalLoanDuration;
        loanToSave.balance = loan.balance;
        loanToSave.initialLoanCapital = loan.initialLoanCapital;
        loanToSave.restructure = loan.restructure;
        loanToSave.mortgage = loan.mortgage;
        break;
      case 'R':
        loanToSave.monthlyPayment = loan.monthlyPayment;
        loanToSave.balance = loan.balance;
        loanToSave.initialLoanCapital = loan.initialLoanCapital;
        break;
      default:
    }
  } else {
    switch (loan.creditType?.code) {
      case 'C':
        loanToSave.monthlyPayment = loan.monthlyPayment;
        if (loan.totalLoanDuration && loan.totalLoanDuration !== '') {
          loanToSave.totalLoanDuration = loan.totalLoanDuration;
        }
        loanToSave.balance = loan.balance;
        if (loan.initialLoanCapital && loan.initialLoanCapital !== '') {
          loanToSave.initialLoanCapital = loan.initialLoanCapital;
        }
        break;
      case 'I':
        loanToSave.monthlyPayment = loan.monthlyPayment;
        if (loan.totalLoanDuration && loan.totalLoanDuration !== '') {
          loanToSave.totalLoanDuration = loan.totalLoanDuration;
        }
        loanToSave.balance = loan.balance;
        if (loan.initialLoanCapital && loan.initialLoanCapital !== '') {
          loanToSave.initialLoanCapital = loan.initialLoanCapital;
        }
        loanToSave.mortgage = loan.mortgage;
        break;
      case 'R':
        loanToSave.monthlyPayment = loan.monthlyPayment;
        if (loan.totalLoanDuration && loan.totalLoanDuration !== '') {
          loanToSave.totalLoanDuration = loan.totalLoanDuration;
        }
        loanToSave.balance = loan.balance;
        loanToSave.initialLoanCapital = loan.initialLoanCapital;
        break;
      default:
    }
  }

  return loanToSave;
};

const isZipCodeOkay = (zipCodeParam: string | undefined): boolean => {
  if (zipCodeParam === undefined) return false;
  if (zipCodeParam === '') return false;
  if (!zipCode.test(zipCodeParam)) return false;
  if (!zipCodeNoZero.test(zipCodeParam)) return false;
  return true;
};

export const checkIfCurrentAssetDatasAreOk = (state: Asset): boolean => {
  if (
    state.currentValue &&
    Number(state.currentValue.replaceAll(' ', '')) > 0 &&
    state.inscriptionRank &&
    Number(state.inscriptionRank) > 0 &&
    state.isAssetAddressBorrowerAddress !== undefined &&
    state.type &&
    state.usage
  ) {
    if (Number(state.inscriptionRank) > 1) {
      if (!state.keptMortagagesValue) return false;
      const remainingValue =
        Number(state.currentValue.replace(/ /g, '')) -
        Number(state.keptMortagagesValue.replace(/ /g, ''));
      if (remainingValue < 0) return false;
    }
    if (state.isAssetAddressBorrowerAddress === false && !isZipCodeOkay(state.zipCode))
      return false;

    return true;
  }
  return false;
};

export const checkIfDatasAreOk = (state: OperationState): boolean => {
  if (
    state.loanList &&
    state.loanList.length > 0 &&
    !state.loanList.find(loan => !loan.isDataOk) &&
    (state.assetList.length === 0 || !state.assetList.find(asset => !asset.isDataOk)) &&
    // if has insurance toggled then you need at least one insurance
    (!state.hasInsurance || (state.assetList && state.assetList.length > 0)) &&
    // if has other needs then you need more than 0€'s worth of other needs
    (state.otherNeedsList.length === 0 ||
      !state.otherNeedsList.find(otherNeed => otherNeed.amount <= 0)) &&
    // if has buyback debts then you need more than 0€'s worth of debt
    (state.buybackDebtsList.length === 0 ||
      !state.buybackDebtsList.find(debt => debt.amount <= 0)) &&
    // if has payment incident then you need at least late payment checked or seizure checked
    (!state.hasPaymentIncident ||
      (state.incidentPaymentList && state.incidentPaymentList.length > 0)) &&
    // if has payment default then you need at least 1 instance of payment default
    (!state.hasPaymentDefault ||
      (state.nbPaymentDefault > 0 && state.nbPaymentDefault < 4)) &&
    state.otherNeedsList.length <= 15 &&
    state.buybackDebtsList.length <= 15
  ) {
    return true;
  }
  return false;
};
