import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import Tooltip from 'components/tooltip/Tooltip';
import { PlainText } from 'containers/communs/style';
import WarningIcon from 'icons/WarningIcon';
import { useEffect, useState } from 'react';
import {
  ConditionsFinancieresAction,
  ConditionsFinancieresState,
} from 'reducers/conditionsFinancieres/types';
import { IdentityState } from 'reducers/identity/types';
import { MinMax } from 'types';
import checkPreviousInputValidation from 'utils/commun';
import { getDateFromInput, getMonthsRemaining } from 'utils/DateUtils';
import {
  addInInvalidInputs,
  hasCoBorrower,
  removeFromInvalidInputs,
} from '../communs/utils';

interface LoanDurationProps {
  state: ConditionsFinancieresState;
  dispatch: React.Dispatch<ConditionsFinancieresAction>;
  loanDurationInterval?: MinMax;
  clearReccomendedInsuranceData: () => void;
  checkInputs: boolean;
}

const LoanDuration: React.FC<LoanDurationProps> = ({
  state,
  dispatch,
  loanDurationInterval,
  clearReccomendedInsuranceData,
  checkInputs,
}) => {
  const [isLoanDurationInputFocused, setIsLoanDurationInputFocused] =
    useState<boolean>(false);
  const [isLoanDurationInputValid, setIsLoanDurationInputValid] = useState<
    boolean | undefined
  >(checkPreviousInputValidation('conditionsFinancieres', state.loanDuration));
  const [loanDurationInputError, setLoanDurationInputError] = useState<string>('');

  const checkLoanDurationInputValue = () => {
    if (loanDurationInterval) {
      if (
        state.loanDuration === undefined ||
        state.loanDuration < parseInt(loanDurationInterval.min, 10) ||
        state.loanDuration > parseInt(loanDurationInterval.max, 10)
      ) {
        setLoanDurationInputError(
          `La durée doit être comprise entre ${loanDurationInterval.min} et ${loanDurationInterval.max} mois.`,
        );
        addInInvalidInputs(state, dispatch, 'loanDuration');
        setIsLoanDurationInputValid(false);
      } else {
        setIsLoanDurationInputValid(true);
        setLoanDurationInputError('');
        removeFromInvalidInputs(state, dispatch, 'loanDuration');
      }
    }
  };

  const handleLoanDurationChange = (value: string) => {
    const duration = parseInt(value.slice(0, 3), 10);
    if (Number.isNaN(duration)) {
      dispatch({
        type: 'setLoanDuration',
        payload: undefined,
      });
    } else {
      clearReccomendedInsuranceData();
      dispatch({
        type: 'setLoanDuration',
        payload: duration,
      });
    }
  };

  const handleLoanDurationBlur = () => {
    setIsLoanDurationInputFocused(false);
    checkLoanDurationInputValue();
  };

  const getMinMaxDuration = (): string => {
    if (!loanDurationInterval) {
      return 'Durée totale du prêt';
    }
    return `Durée totale du prêt ${loanDurationInterval.min} à ${loanDurationInterval.max} mois`;
  };

  const renderAgeAtLoanEnd = () => {
    if (state.loanDuration) {
      let ageMessage = '';

      const borrowerIdentity = JSON.parse(
        sessionStorage.getItem('borrowerIdentity') || '{}',
      ) as IdentityState;

      let endOfLoanDate = new Date();
      endOfLoanDate = new Date(
        endOfLoanDate.setMonth(endOfLoanDate.getMonth() + state.loanDuration),
      );

      const borrowerEndOfLoanAge = getMonthsRemaining(
        getDateFromInput(borrowerIdentity.birthDate || ''),
        endOfLoanDate,
      );

      ageMessage = `Age de fin de prêt : ${Math.floor(borrowerEndOfLoanAge / 12)} ans ${
        borrowerEndOfLoanAge % 12 > 0 ? `  ${borrowerEndOfLoanAge % 12} mois` : ''
      }`;

      let messageSecondLine: string | undefined;

      if (hasCoBorrower()) {
        const coBorrowerIdentity = JSON.parse(
          sessionStorage.getItem('coBorrowerIdentity') || '{}',
        ) as IdentityState;

        const coBorrowerEndOfLoanAge = getMonthsRemaining(
          getDateFromInput(coBorrowerIdentity.birthDate || ''),
          endOfLoanDate,
        );

        ageMessage = `Age de fin de prêt :`;
        messageSecondLine = `Emprunteur : ${Math.floor(borrowerEndOfLoanAge / 12)} ans${
          borrowerEndOfLoanAge % 12 > 0 ? ` ${borrowerEndOfLoanAge % 12} mois` : ''
        } | Co-emprunteur : ${Math.floor(coBorrowerEndOfLoanAge / 12)} ans${
          coBorrowerEndOfLoanAge % 12 > 0 ? ` ${coBorrowerEndOfLoanAge % 12} mois` : ''
        }`;
      }

      return (
        <div style={{ marginTop: '0.8rem', display: 'flex', flexDirection: 'column' }}>
          <PlainText>{ageMessage}</PlainText>
          {messageSecondLine ? <PlainText>{messageSecondLine}</PlainText> : <></>}
        </div>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (checkInputs) {
      checkLoanDurationInputValue();
    }
  }, [loanDurationInterval]);

  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <p style={{ fontSize: '1.6rem', fontWeight: 'bold', margin: '0' }}>
          Durée applicable
        </p>
        <Tooltip
          topPosition={-5}
          leftPosition={2}
          width="40rem"
          icon={<WarningIcon color="#2275D7" />}>
          {`Age maxi fin de prêt :\n 84 ans et 6 mois (emprunteur et co-emprunteur).`}
        </Tooltip>
      </div>
      <Space marginTop="2.4rem" />
      <CustomInput
        name="loan-duration"
        label={getMinMaxDuration()}
        value={state.loanDuration !== undefined ? state.loanDuration : ''}
        onChange={newValue => {
          handleLoanDurationChange(newValue.replaceAll(' ', ''));
          setIsLoanDurationInputFocused(true);
        }}
        isValid={isLoanDurationInputValid}
        isFocused={isLoanDurationInputFocused}
        onBlur={handleLoanDurationBlur}
        onFocus={() => {
          setIsLoanDurationInputFocused(true);
        }}
        after="mois"
        inputWidth="46.4rem"
        placeholder="0"
        error={loanDurationInputError}
      />
      {renderAgeAtLoanEnd()}
    </>
  );
};

export default LoanDuration;
