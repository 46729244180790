import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import Tooltip from 'components/tooltip/Tooltip';
import WarningIcon from 'icons/WarningIcon';
import { useState } from 'react';
import { IbanAction, IbanState } from 'reducers/iban/type';
import { StyledInputLabel } from '../style';

interface BicProps {
  state: IbanState;
  dispatch: React.Dispatch<IbanAction>;
}

const Bic: React.FC<BicProps> = ({ state, dispatch }) => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [inputError, setInputError] = useState<string>('');
  const [value, setValue] = useState<string>(state.bic || '');

  const handleChange = (val: string) => {
    setValue(val.toUpperCase().slice(0, 11));
  };
  const handleBlur = () => {
    setIsInputFocused(false);
    if (value) {
      setIsInputValid(true);
      setInputError('');
      dispatch({
        type: 'setIban',
        payload: { partToUpdate: 'bic', value },
      });
    } else {
      dispatch({
        type: 'setIban',
        payload: { partToUpdate: 'bic', value: undefined },
      });
      setIsInputValid(false);
      setInputError('Bic incorrect.');
    }
  };
  return (
    <>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <StyledInputLabel>BIC</StyledInputLabel>
        <Tooltip topPosition={-3} leftPosition={2} icon={<WarningIcon color="#2275D7" />}>
          Important pour compléter le mandat SEPA
        </Tooltip>
      </div>
      <Space marginTop=".8rem" />
      <CustomInput
        name="bic"
        value={value}
        onChange={newValue => {
          handleChange(newValue);
          setIsInputFocused(true);
        }}
        isValid={isInputValid}
        isFocused={isInputFocused}
        onBlur={handleBlur}
        onFocus={() => {
          setIsInputFocused(true);
        }}
        inputWidth="18rem"
        error={inputError}
      />
    </>
  );
};

export default Bic;
