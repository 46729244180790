import { v4 as uuidv4 } from 'uuid';
import { getBanks } from 'api/referentialService';
import CustomInput from 'components/customInput/CustomInput';
import Space from 'components/space/Space';
import React, { useEffect, useState } from 'react';
import {
  DebtsAdditionalInformationAction,
  DebtsAdditionalInformationState,
  DebtsCompleteData,
} from 'reducers/debtsAdditionalInformation/type';
import { Bank, CodeLibelle } from 'types';
import { formatNumberByThousand } from 'utils/commun';
import TextButton from 'components/buttons/textButton/TextButton';
import PlusCircleIcon from 'icons/PlusCircleIcon';
import { StyledInfo } from 'containers/conditionsFinancieres/style';
import WarningIcon from 'icons/WarningIcon';
import { updateSessionStorage } from 'utils/storage';
import * as messagesCheckInput from 'utils/messagesCheckInput';
import BankAccount from './BankAccount';

interface BankDebtProps {
  debt: DebtsCompleteData;
  state: DebtsAdditionalInformationState;
  dispatch: React.Dispatch<DebtsAdditionalInformationAction>;
  checkInputs: boolean;
}

const BankDebt: React.FC<BankDebtProps> = ({ debt, state, dispatch, checkInputs }) => {
  const [banksReferential, setBanksReferential] = useState<Bank[]>([]);

  const totalBankDebtsMatchesDebtAmount = (): void => {
    const tempDebtState = { ...state };

    const tempDebt = tempDebtState.debts.find(debts => {
      return debts.code === 'DB';
    });
    if (!tempDebt) return;

    let totalBankDebts = 0;

    tempDebt.banks?.forEach(bank => {
      totalBankDebts += Number(bank.amount);
    });

    tempDebt.totalBankDebts = totalBankDebts;
    tempDebt.doesTotalBankDebtsMatchAmount = Number(tempDebt.amount) === totalBankDebts;

    tempDebtState.debts = tempDebtState.debts.map(item => {
      if (item.code === tempDebt.code) {
        const newDebt = { ...item, totalBankDebts };
        return newDebt;
      }
      return item;
    });

    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data: tempDebtState.debts, debtCode: tempDebt.code },
    });

    updateSessionStorage('debtAdditionalInformation', tempDebtState);
  };

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await getBanks();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: Bank[] = [];
          Resultat.forEach(item => {
            const bank: Bank = {
              code: item.code,
              libelle: item.libelle,
              year: '',
            };
            temp.push(bank);
          });
          setBanksReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchBanks();
  }, []);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const response = await getBanks();
        const { Resultat, Success } = response;
        if (Success) {
          const temp: CodeLibelle[] = [];
          Resultat.forEach(item => {
            const newBank: CodeLibelle = {
              code: item.code,
              libelle: item.libelle,
            };
            temp.push(newBank);
          });
          setBanksReferential(temp);
        }
      } catch (error) {
        //  TODO: à gérer lors du sprint de la gestion des erreurs
        console.error(error);
      }
    };
    fetchBanks();
  }, []);

  useEffect(() => {
    totalBankDebtsMatchesDebtAmount();
  }, []);

  const addBankAccount = () => {
    const result = state.debts.map(debtFromState => {
      if (debtFromState.code === debt.code) {
        const uniqueId = uuidv4();
        const updatedBanksList = debtFromState.banks || [];
        updatedBanksList.push({ id: uniqueId } as Bank);
        return { ...debtFromState, banks: updatedBanksList };
      }
      return debtFromState;
    });

    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data: result, debtCode: debt.code },
    });
  };

  const onDelete = (bankCode: string | undefined) => {
    const result = state.debts.map(debtFromState => {
      if (debtFromState.code === debt.code) {
        const updatedBanksList = debtFromState.banks?.filter(bank => {
          return bank.id !== bankCode;
        });
        return { ...debtFromState, banks: updatedBanksList };
      }
      return debtFromState;
    });

    totalBankDebtsMatchesDebtAmount();

    dispatch({
      type: 'setUpdateDebtsInformation',
      payload: { data: result, debtCode: debt.code },
    });
  };

  const render = () => {
    return (
      <>
        <CustomInput
          name="bankDebt"
          label={debt.libelle}
          type="price"
          isDisabled
          value={formatNumberByThousand(debt.amount.toString() || '')}
          after="€"
          inputWidth="50.4rem"
          isFocused={false}
        />
        {!debt.doesTotalBankDebtsMatchAmount && checkInputs && (
          <>
            <StyledInfo style={{ paddingLeft: '1.8rem', marginTop: '2.4rem' }}>
              <WarningIcon />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ color: '#4B4F54', fontSize: '14px', marginLeft: '1rem' }}>
                  {messagesCheckInput.DEBT_TOTAL_ERROR_MESSAGE}
                </p>
              </div>
            </StyledInfo>
          </>
        )}
        {debt.banks &&
          debt.banks.map((bank, index) => {
            return (
              <React.Fragment key={bank.id}>
                <Space marginBottom="2.4rem" />
                <BankAccount
                  banksReferential={banksReferential}
                  itemBank={bank}
                  debt={debt}
                  state={state}
                  totalBankDebtsMatchesDebtAmount={totalBankDebtsMatchesDebtAmount}
                  onDelete={onDelete}
                  dispatch={dispatch}
                  checkInputs={checkInputs}
                />
              </React.Fragment>
            );
          })}
        <Space marginBottom="3.6rem" />
        <TextButton
          label="Ajouter le détail du ou des découvert(s) bancaire(s)"
          width="fit-content"
          onClick={addBankAccount}>
          <PlusCircleIcon />{' '}
        </TextButton>
      </>
    );
  };

  return <>{render()}</>;
};

export default BankDebt;
