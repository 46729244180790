import CustomInput from 'components/customInput/CustomInput';
import { BaseCheckbox } from 'components/designSystem/BaseCheckbox';
import { useEffect, useState } from 'react';
import {
  AssuranceEmprunteur,
  CalculetteFinanciereAction,
} from 'reducers/calculetteFinanciere/type';

interface JobLossInsuranceProps {
  person: 'borrower' | 'coBorrower';
  dispatch: React.Dispatch<CalculetteFinanciereAction>;
  getPersonWarranty: AssuranceEmprunteur;
  isSG: boolean;
}

const JobLossInsurance: React.FC<JobLossInsuranceProps> = ({
  person,
  dispatch,
  getPersonWarranty,
  isSG,
}) => {
  const [isJobLossInsuranceRateInputFocused, setIsJobLossInsuranceRateInputFocused] =
    useState<boolean>(false);
  const [jobLossInsuranceRateInputError, setJobLossInsuranceRateInputError] =
    useState<string>('');
  const [isJobLossInsuranceRateInputValid, setIsJobLossInsuranceRateInputValid] =
    useState<boolean | undefined>();

  const handleJobLossInsuranceRateBlur = () => {
    if (isSG) {
      return;
    }
    setIsJobLossInsuranceRateInputFocused(false);
    if (
      getPersonWarranty.quotitePerteEmploi === undefined ||
      Number.isNaN(getPersonWarranty.quotitePerteEmploi) ||
      (!isSG && getPersonWarranty.quotitePerteEmploi <= 0)
    ) {
      setIsJobLossInsuranceRateInputValid(false);
      setJobLossInsuranceRateInputError('Saisie incorrecte.');
    } else {
      setIsJobLossInsuranceRateInputValid(true);
      setJobLossInsuranceRateInputError('');
    }
  };

  const handleJobLossInsuranceRateChange = (value: string) => {
    // si l'utilisateur rempli le champ sans avoir coché la case, on peut le cocher automatiquement en vérifiant
    // que les autres conditions pour cocher la case "perte emploi" sont respectées (çàd PTIA et ITT cochés)
    const rate = parseInt(value, 10);
    if (
      value &&
      value.trim().length > 0 &&
      getPersonWarranty?.garantiePerteEmploi === 'N' &&
      getPersonWarranty?.garantiePTIA === 'O' &&
      getPersonWarranty?.garantieITT === 'O'
    ) {
      dispatch({
        type: 'setGarantiePerteEmploi',
        payload: { person, value: 'O' },
      });
    }
    if (Number.isNaN(rate)) {
      dispatch({
        type: 'setGarantiePerteEmploi',
        payload: { person, value: undefined },
      });
      dispatch({
        type: 'setQuotitePerteEmploi',
        payload: { person, value: undefined },
      });
    } else {
      dispatch({
        type: 'setGarantiePerteEmploi',
        payload: { person, value: 'O' },
      });
      dispatch({
        type: 'setQuotitePerteEmploi',
        payload: { person, value: rate },
      });
    }
  };

  useEffect(() => {
    if (isSG) {
      dispatch({
        type: 'setGarantiePerteEmploi',
        payload: { person, value: 'O' },
      });
      dispatch({
        type: 'setQuotitePerteEmploi',
        payload: { person, value: 100 },
      });
    }
  }, []);

  const isJobLossChecked = (): boolean | undefined => {
    return getPersonWarranty?.garantiePerteEmploi === 'O' ? true : undefined;
  };

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <BaseCheckbox
        checked={isJobLossChecked()}
        className="assurance-checkbox"
        onCheckedChanged={e => {
          if ((e as CustomEvent)?.detail?.value) {
            dispatch({
              type: 'setGarantiePerteEmploi',
              payload: { person, value: 'O' },
            });
            dispatch({
              type: 'setQuotitePerteEmploi',
              payload: { person, value: 100 },
            });
          } else {
            dispatch({
              type: 'setGarantiePerteEmploi',
              payload: { person, value: 'N' },
            });
            dispatch({
              type: 'setQuotitePerteEmploi',
              payload: { person, value: undefined },
            });
          }
        }}
      />
      <CustomInput
        name="jobLoss-insurance"
        label="Garantie perte d'emploi"
        isDisabled={isSG ? true : undefined}
        value={getPersonWarranty.quotitePerteEmploi}
        onChange={newValue => {
          handleJobLossInsuranceRateChange(newValue);
          setIsJobLossInsuranceRateInputFocused(true);
        }}
        isValid={isJobLossInsuranceRateInputValid}
        isFocused={isJobLossInsuranceRateInputFocused}
        onBlur={handleJobLossInsuranceRateBlur}
        onFocus={() => {
          setIsJobLossInsuranceRateInputFocused(true);
        }}
        after="%"
        inputWidth="17.2rem"
        placeholder="0"
        error={jobLossInsuranceRateInputError}
      />
    </div>
  );
};

export default JobLossInsurance;
