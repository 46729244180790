import Space from 'components/space/Space';
import { StyledTitle } from 'containers/communs/style';
import { Line } from 'containers/summary/style';
import React, { useEffect, useState } from 'react';
import {
  DebtsAdditionalInformationAction,
  DebtsAdditionalInformationState,
} from 'reducers/debtsAdditionalInformation/type';
import { Operation, OperationState } from 'reducers/operationClient/types';
import DebtAdditionalInformation from './additionalInformation/AdditionalInformation';

interface DebtsProps {
  state: DebtsAdditionalInformationState;
  dispatch: React.Dispatch<DebtsAdditionalInformationAction>;
  checkInputs: boolean;
}

const Debts: React.FC<DebtsProps> = ({ state, dispatch, checkInputs }) => {
  const [debtList, setDebtList] = useState<Operation[]>([]);

  const getDebts = (): Operation[] => {
    let result: Operation[] = [];
    const operationsClientFromSessionStorage = sessionStorage.getItem('operationsClient');
    if (operationsClientFromSessionStorage) {
      try {
        result = (JSON.parse(operationsClientFromSessionStorage) as OperationState)
          .buybackDebtsList;
      } catch (error) {
        return result;
      }
    }
    return result;
  };

  useEffect(() => {
    setDebtList(getDebts());
  }, []);

  const render = () => {
    const nbItemsToDisplay = state.debts.length;
    if (state.debts.length === 0) {
      return null;
    }

    return state.debts.map((item, index) => {
      const debt = debtList.find(element => element.code === item.code);
      if (debt) {
        return (
          <React.Fragment key={item.uid}>
            <DebtAdditionalInformation
              key={debt.uid}
              debt={item}
              state={state}
              dispatch={dispatch}
              checkInputs={checkInputs}
            />
            {index < nbItemsToDisplay - 1 && (
              <>
                <Space marginBottom="2.4rem" />
                <Line style={{ width: 'auto' }} />
              </>
            )}
            <Space marginBottom="2.4rem" />
          </React.Fragment>
        );
      }
      return null;
    });
  };

  return (
    <>
      {state.debts.length > 0 && (
        <>
          <StyledTitle>Informations complémentaires des dettes à racheter</StyledTitle>
          <Space marginTop="2.4rem" />
        </>
      )}
      {render()}
    </>
  );
};

export default Debts;
