import * as React from 'react';
import GenericIconProps from './GenericIcon';

const BinIcon: React.FC<GenericIconProps> = ({ width, color }) => {
  const baseWidth = 24;
  const baseHeight = 24;

  const scaleRatio = width ? width / baseWidth : 1;
  const scaledHeight = baseHeight * scaleRatio;

  return (
    <svg
      width={width || baseWidth}
      height={scaledHeight}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>Bin Icon</title>
      <g
        id="Consultation-dossier-📑"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Consultation-d'un-dossier/transferer-a-l'octroi/PJ/AG/1"
          transform="translate(-1008.000000, -3070.000000)">
          <g id="chemise/banque" transform="translate(264.000000, 2518.000000)">
            <g id="historique/pj/2" transform="translate(120.000000, 500.000000)">
              <g id="Group" transform="translate(0.000000, 52.000000)">
                <g
                  id="Icon/24px/Action/trash"
                  transform="translate(624.000000, 0.000000)">
                  <g id="POUBELLE" />
                  <path
                    d="M13.3846154,2 C14.1493174,2 14.7692308,2.59695367 14.7692308,3.33333334 L19.6153847,3.33333334 C20.3800866,3.33333334 21,3.93028701 21,4.66666668 L21,6 C21,6.73637969 20.3800866,7.33333336 19.6153847,7.33333336 L18.923077,7.33333336 L18.923077,19.3333334 C18.923077,20.8060927 17.6832501,22 16.1538462,22 L7.84615387,22 C6.31674994,22 5.07692309,20.8060927 5.07692309,19.3333334 L5.07692309,7.33333336 L4.38461539,7.33333336 C3.61991343,7.33333336 3,6.73637969 3,6 L3,4.66666668 C3,3.93028701 3.61991343,3.33333334 4.38461539,3.33333334 L9.23076926,3.33333334 C9.23076926,2.59695367 9.85068268,2 10.6153846,2 L13.3846154,2 Z M17.375077,7.33333336 L6.62492309,7.33333336 L6.46153848,7.41200002 L6.46153848,19.3333334 C6.46153848,20.0697131 7.0814519,20.6666667 7.84615387,20.6666667 L16.1538462,20.6666667 C16.9185482,20.6666667 17.5384616,20.0697131 17.5384616,19.3333334 L17.5384616,7.41200002 L17.375077,7.33333336 Z M8.53846156,9.33333336 C8.92081254,9.33333336 9.23076926,9.6318102 9.23076926,10 L9.23076926,18.0000001 C9.23076926,18.3681899 8.92081254,18.6666667 8.53846156,18.6666667 C8.15611058,18.6666667 7.84615387,18.3681899 7.84615387,18.0000001 L7.84615387,10 C7.84615387,9.6318102 8.15611058,9.33333336 8.53846156,9.33333336 Z M12,9.33333336 C12.382351,9.33333336 12.6923077,9.6318102 12.6923077,10 L12.6923077,18.0000001 C12.6923077,18.3681899 12.382351,18.6666667 12,18.6666667 C11.6176491,18.6666667 11.3076923,18.3681899 11.3076923,18.0000001 L11.3076923,10 C11.3076923,9.6318102 11.6176491,9.33333336 12,9.33333336 Z M15.4615385,9.33333336 C15.8438895,9.33333336 16.1538462,9.6318102 16.1538462,10 L16.1538462,18.0000001 C16.1538462,18.3681899 15.8438895,18.6666667 15.4615385,18.6666667 C15.0791875,18.6666667 14.7692308,18.3681899 14.7692308,18.0000001 L14.7692308,10 C14.7692308,9.6318102 15.0791875,9.33333336 15.4615385,9.33333336 Z M19.6153847,4.66666668 L4.38461539,4.66666668 L4.38461539,6.00000002 L19.6153847,6.00000002 L19.6153847,4.66666668 Z"
                    id="Combined-Shape"
                    fill="#4B4F54"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BinIcon;
