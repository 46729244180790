import NextButton from 'components/buttons/nextButton/NextButton';
import CancelButton from 'components/buttons/cancelButton/CancelButton';
import GeneralIllustrationDocuments from 'components/modals/ConfirmationModals/Assets/GeneralIllustrationDocuments';
import Modal from 'components/modals/Modal';
import {
  PlainModalText,
  StyledBlackTitle,
  StyledGeneralModalContentContainer,
} from 'components/modals/style';
import Space from 'components/space/Space';
import React from 'react';
import CheckInCircle from 'icons/CheckInCircle';

interface HabilitationModalProps {
  title?: string;
  body?: React.ReactNode;
  isVisible: boolean;
  accept: () => void;
  close: () => void;
}

const HabilitationModal: React.FC<HabilitationModalProps> = ({
  isVisible,
  accept,
  close,
  title,
  body,
}) => {
  return (
    <Modal isVisible={isVisible} width="60rem" onClose={close}>
      <StyledGeneralModalContentContainer style={{ marginBottom: '4rem' }}>
        <div style={{ marginLeft: '12.7rem' }}>
          <GeneralIllustrationDocuments />
        </div>

        <Space marginBottom="3.6rem" />

        <StyledBlackTitle>{title}</StyledBlackTitle>

        <Space marginBottom="1.6rem" />

        <PlainModalText>{body}</PlainModalText>

        <Space marginBottom="4.8rem" />

        <div
          style={{
            display: 'flex',
            height: '4.8rem',
            justifyContent: 'flex-end',
          }}>
          <div style={{ marginRight: '1.6rem' }}>
            <CancelButton handleonClick={close} />
          </div>

          <NextButton
            isDisabled={false}
            handleonClick={accept}
            icon={<CheckInCircle color="#fff" />}
            label="Confirmer"
          />
        </div>
      </StyledGeneralModalContentContainer>
    </Modal>
  );
};

HabilitationModal.defaultProps = {
  title: '',
  body: null,
};

export default HabilitationModal;
