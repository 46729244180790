import { Dispatch, useEffect, useState } from 'react';
import { ListPiece } from 'containers/consultation/transmissionPiecesConsultation/zoneCommentaire';
import { ArrowLeftCircleIconAJ } from 'icons/ArrowLeftCircleIcon';
import ArrowRightCircleIcon from 'icons/ArrowRightCircleIcon';
import ActionCrossIcon from 'icons/ActionCrossIcon';
import {
  StyledDeleteButtonContainer,
  StyledNavArrowContainer,
  StyledPiecesSelectedContainer,
  StyledTagLabel,
  StyledTagsContainer,
} from './style';

interface CommentNavPieceJustificatifProps {
  selectedPiece: readonly ListPiece[];
  setSelectedPiece: Dispatch<React.SetStateAction<readonly ListPiece[]>>;
}
const CommentNavPieceJustificatif: React.FC<CommentNavPieceJustificatifProps> = ({
  ...props
}) => {
  const [showPiece, setShowPiece] = useState<ListPiece[]>([]);
  const [navIndice, SetNavIndice] = useState<number>(0);

  useEffect(() => {
    const nexPieceShow: ListPiece[] = props.selectedPiece.slice(
      0 + 4 * navIndice,
      4 + 4 * navIndice,
    );
    if (nexPieceShow.length === 0 && navIndice > 0) SetNavIndice(navIndice - 1);
    setShowPiece(nexPieceShow);
  }, [navIndice, props.selectedPiece]);

  const handleDeleteTag = (piece: string) => {
    const newSelectedPiece = props.selectedPiece.filter(item => item.value !== piece);
    props.setSelectedPiece(newSelectedPiece);
  };

  const handleClickNavArrow = (nav: number) => {
    SetNavIndice(navIndice + nav);
  };

  const renderTag = (label: string) => {
    return (
      <StyledPiecesSelectedContainer>
        <StyledTagLabel>{label}</StyledTagLabel>
        <StyledDeleteButtonContainer
          role="button"
          tabIndex={0}
          onClick={() => handleDeleteTag(label)}
          onKeyPress={() => handleDeleteTag(label)}>
          <ActionCrossIcon />
        </StyledDeleteButtonContainer>
      </StyledPiecesSelectedContainer>
    );
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {navIndice !== 0 && (
        <StyledNavArrowContainer
          style={{ paddingRight: '1.5rem' }}
          onClick={() => handleClickNavArrow(-1)}>
          <ArrowLeftCircleIconAJ width={28} />
        </StyledNavArrowContainer>
      )}
      <StyledTagsContainer>
        {props.selectedPiece?.length > 0 &&
          showPiece.map(item => {
            return renderTag(item.label);
          })}
      </StyledTagsContainer>
      {props.selectedPiece.length > (navIndice + 1) * 4 && showPiece.length === 4 && (
        <StyledNavArrowContainer
          style={{ paddingLeft: '1.5rem' }}
          onClick={() => handleClickNavArrow(1)}>
          <ArrowRightCircleIcon width={28} color="#292C2E" />
        </StyledNavArrowContainer>
      )}
    </div>
  );
};

export default CommentNavPieceJustificatif;
