import { SituationProfessionnelleState } from 'reducers/situationProfessionnelle/types';
import { Amount, RessourcesMensuellesState } from './types';

export const switchRetirementIncome = (
  situationProfessionel: SituationProfessionnelleState,
  RessourcesMensuelles: RessourcesMensuellesState,
): RessourcesMensuellesState => {
  const newRessourcesMensuelles = { ...RessourcesMensuelles };

  if (
    situationProfessionel.borrower.profession?.code !== 'IRE' &&
    RessourcesMensuelles.borrowerIncome.retirementMonthlyIncomes
  ) {
    const newRetirementOtherIncome: Amount = {
      code: 'RE',
      libelle: 'Retraite',
      value: newRessourcesMensuelles.borrowerIncome.retirementMonthlyIncomes,
      id: RessourcesMensuelles.otherIncomes?.length + 1,
      titulaire: 1,
    };

    newRessourcesMensuelles.hasOtherIncomes = true;
    newRessourcesMensuelles.otherIncomes.push(newRetirementOtherIncome);
    newRessourcesMensuelles.borrowerIncome.retirementMonthlyIncomes = '';
  }

  if (
    situationProfessionel.hasCoBorrower &&
    situationProfessionel.coBorrower.profession?.code !== 'IRE' &&
    RessourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes
  ) {
    const newRetirementOtherIncome: Amount = {
      code: 'RE',
      libelle: 'Retraite',
      value: newRessourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes,
      id: RessourcesMensuelles.otherIncomes?.length + 1,
      titulaire: 2,
    };

    newRessourcesMensuelles.hasOtherIncomes = true;
    newRessourcesMensuelles.otherIncomes.push(newRetirementOtherIncome);
    newRessourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes = '';
  }

  // switch retirement from otherIncomes to retirementMonthlyIncomes
  const otherincomeBorrowerRetirement = RessourcesMensuelles.otherIncomes.find(
    income => income.titulaire === 1 && income.code === 'RE',
  );
  if (
    situationProfessionel.borrower.profession?.code === 'IRE' &&
    otherincomeBorrowerRetirement
  ) {
    const newOtherIncomes = newRessourcesMensuelles.otherIncomes.filter(
      income => !(income.titulaire === 1 && income.code === 'RE'),
    );

    newRessourcesMensuelles.otherIncomes = newOtherIncomes;
    newRessourcesMensuelles.borrowerIncome.retirementMonthlyIncomes =
      otherincomeBorrowerRetirement.value;
  }

  const otherincomeCoBorrowerRetirement = RessourcesMensuelles.otherIncomes.find(
    income => income.titulaire === 2 && income.code === 'RE',
  );
  if (
    situationProfessionel.borrower.profession?.code === 'IRE' &&
    otherincomeCoBorrowerRetirement
  ) {
    const newOtherIncomes = newRessourcesMensuelles.otherIncomes.filter(
      income => !(income.titulaire === 2 && income.code === 'RE'),
    );

    newRessourcesMensuelles.otherIncomes = newOtherIncomes;
    newRessourcesMensuelles.coBorrowerIncome.retirementMonthlyIncomes =
      otherincomeCoBorrowerRetirement.value;
  }

  return newRessourcesMensuelles;
};

export default switchRetirementIncome;
