import styled from 'styled-components';

export const StyledProfilWrapper = styled.div`
  width: 25rem;
  height: 8.3rem;
  background-color: ${({ theme }) => theme.colors.chartColors.neutral50};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;
  margin: 0 1.6rem;
  padding: 2.2rem 1.2rem;
  border-radius: 0 0 0.8rem 0.8rem;
  overflow: hidden;
`;

export const StyledAdditionalInfosContainer = styled.div`
  width: 42rem;
  height: 23.6rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 1rem;
  top: 10rem;
  right: 1.5rem;
  background: white;
  z-index: 10;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.1);
  padding: 2.4rem 3.2rem 1.8rem;
  cursor: auto;
`;

export const StyledAdditionalInfosTitle = styled.span`
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
`;

export const StyledAdditionalInfosContremarqueLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: 0.8rem;
`;

export const StyledAdditionalInfosContremarqueValue = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
`;

export const StyledAdditionalInfosGroupLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0.4rem;
`;

export const StyledAdditionalInfosLockedGroupContainer = styled.div`
  padding: 1rem;
  height: 34px;
  border-radius: 8px;
  background-color: #f2f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const StyledAdditionalInfosLockedGroupLabel = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledAdditionalInfosLogoutLabel = styled.span`
  margin-left: 0.8rem;
  color: #292c2e;
  font-family: 'Open Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
`;

export const StyledClickableBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;
