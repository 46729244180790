import styled from 'styled-components';

export const StyledTextLabel = styled.span`
  min-width: 31.6rem;
  margin-top: 1.8rem;
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`;

export default StyledTextLabel;
