import getClientFile from 'api/clientFileService';
import { GetSimulationResultApiResponse } from 'api/simulationService';
import InformationArea from 'components/informationArea/InformationArea';
import Loader from 'components/Loader';
import Space from 'components/space/Space';
import StepTitles from 'components/titles/StepTitles';
import GeneralConfirmationModal from 'components/modals/ConfirmationModals/GeneralConfirmationModal';
import { withSummaryCard } from 'containers/communs/higherOrderComponentsUtils';
import { hasCoBorrower } from 'containers/communs/utils';
import HomeIcon from 'icons/HomeIcon';
import WarningIcon from 'icons/WarningIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Assurance, CodeLibelle, Echeancier } from 'types';
import { mappingGetClientFiles } from 'utils/mapping/mapping';
import { cleanFileSessionStorageData, formatFloatString } from 'utils/commun';
import StyledSecondaryButton from 'components/commun/StyledSecondaryButton';
import ArrowLeftCircleIcon from 'icons/ArrowLeftCircleIcon';
import CloudArrowIcon from 'icons/CloudArrowIcon';
import color from 'styles/color';
import NextButton from 'components/buttons/nextButton/NextButton';
import ArrowRightCircleIcon from 'icons/ArrowRightCircleIcon';
import { StyledSaveLaterText, StyledSaveLaterWrapper } from 'components/formFooter/style';
import theme from 'styles/theme';
import { cancelFaisabilite } from 'api/subscription';
import * as messages from './messages';
import FinancingForm from './Sections/FinancingForm';
import InsuranceForm, { InsuranceFormDataView } from './Sections/InsuranceForm';
import StopLightForm from './Sections/StopLightForm';
import { FooterText, StyledContentContainer } from './style';

export enum ScoreResult {
  VERT = 'VERT',
  ORANGE = 'ORANGE',
  ROUGE = 'ROUGE',
}

const getFinancingDataView = (simulationResponse: GetSimulationResultApiResponse) => {
  const proposition = simulationResponse?.Resultat.Proposition;
  const isStepLoan =
    (Number(proposition.dureeFranchise) === 0 &&
      proposition.echeancierSansAssurance.length === 2) ||
    proposition.echeancierSansAssurance.length >= 3;
  const firstStepIndex =
    Number(proposition.echeancierSansAssurance.at(0)?.echeance) !== 0 ? 0 : 1;

  const dataList: CodeLibelle[] = [];

  dataList.push({
    libelle: 'Durée totale du prêt',
    code: `${Number(proposition.dureeTotalPret).toLocaleString('FR-fr')} mois`,
  } as CodeLibelle);

  dataList.push({
    libelle: 'Durée de la franchise',
    code: `${Number(proposition.dureeFranchise).toLocaleString('FR-fr')} mois`,
  } as CodeLibelle);

  if (isStepLoan) {
    dataList.push({
      libelle: 'Durée du premier palier',
      code: `${Number(
        proposition.echeancierSansAssurance.at(firstStepIndex)?.duree,
      ).toLocaleString('FR-fr')} mois`,
    } as CodeLibelle);

    dataList.push({
      libelle: 'Mensualités sans assurance du premier palier',
      code: `${formatFloatString(
        Number(
          proposition.echeancierSansAssurance.at(firstStepIndex)?.echeance,
        ).toLocaleString('FR-fr'),
      )} €`,
    } as CodeLibelle);

    dataList.push({
      libelle: 'Durée du second palier',
      code: `${Number(
        proposition.echeancierSansAssurance.at(firstStepIndex + 1)?.duree,
      ).toLocaleString('FR-fr')} mois`,
    } as CodeLibelle);

    dataList.push({
      libelle: 'Mensualités sans assurance du second palier',
      code: `${formatFloatString(
        Number(
          proposition.echeancierSansAssurance.at(firstStepIndex + 1)?.echeance,
        ).toLocaleString('FR-fr'),
      )} €`,
    } as CodeLibelle);
  } else {
    dataList.push({
      libelle: 'Mensualités sans assurance',
      code: `${formatFloatString(
        Number(
          proposition.echeancierSansAssurance.at(firstStepIndex)?.echeance,
        ).toLocaleString('FR-fr'),
      )} € / mois`,
    } as CodeLibelle);
  }

  dataList.push({
    libelle: "Taux d'intérêt nominal",
    code: `${Number(proposition.tauxNominal).toLocaleString('FR-fr')} %`,
  } as CodeLibelle);

  dataList.push({
    libelle: 'TAEG fixe',
    code: `${Number(proposition.taeg).toLocaleString('FR-fr')} %`,
  } as CodeLibelle);

  dataList.push({
    libelle: 'Coût total du crédit sans assurance',
    code: `${formatFloatString(
      Number(proposition.coutTotalCredit).toLocaleString('FR-fr'),
    )} €`,
  } as CodeLibelle);

  dataList.push({
    libelle: 'Part Immobilier',
    code: `${Number(proposition.partImmo).toLocaleString('FR-fr')} %`,
  } as CodeLibelle);

  return dataList;
};

const getComplimentaryInsuranceDataView = (
  simulationResponse: GetSimulationResultApiResponse,
): CodeLibelle[] => {
  const proposition = simulationResponse?.Resultat.Proposition;
  const isStepLoan =
    (Number(proposition.dureeFranchise) === 0 &&
      proposition.echeancierAvecAssurance.length === 2) ||
    proposition.echeancierAvecAssurance.length >= 3;
  const firstStepIndex = Number(proposition.dureeFranchise) === 0 ? 0 : 1;

  const monthlyPayment =
    proposition.echeancierAvecAssurance.length > 1
      ? proposition.echeancierAvecAssurance[1].echeance
      : proposition.echeancierAvecAssurance[0].echeance;

  const totalCostWithInsurance =
    Number(proposition.coutTotalCredit) + Number(proposition.coutTotalAssurance);

  const dataList = [] as CodeLibelle[];

  if (isStepLoan) {
    dataList.push({
      libelle: 'Mensualités avec assurance du premier palier',
      code: `${formatFloatString(
        Number(
          proposition.echeancierAvecAssurance.at(firstStepIndex)?.echeance,
        ).toLocaleString('FR-fr'),
      )} €`,
    } as CodeLibelle);

    dataList.push({
      libelle: 'Mensualités avec assurance du second palier',
      code: `${formatFloatString(
        Number(
          proposition.echeancierAvecAssurance.at(firstStepIndex + 1)?.echeance,
        ).toLocaleString('FR-fr'),
      )} €`,
    } as CodeLibelle);
  } else {
    dataList.push({
      code: `${formatFloatString(Number(monthlyPayment).toLocaleString('FR-fr'))} €`,
      libelle: 'Mensualités avec assurance',
    });
  }

  dataList.push({
    code: `${formatFloatString(Number(proposition.taea).toLocaleString('FR-fr'))} %`,
    libelle: 'TAEA',
  });

  dataList.push({
    code: `${formatFloatString(
      Number(proposition.coutTotalAssurance).toLocaleString('FR-fr'),
    )} €`,
    libelle: 'Coût total de l’assurance',
  });

  dataList.push({
    code: `${formatFloatString(
      Number(totalCostWithInsurance).toLocaleString('FR-fr'),
    )} €`,
    libelle: 'Coût total du crédit avec assurance',
  });

  return dataList;
};

const getGroupInsuranceData = (
  insurance: Assurance,
  echeancier: Echeancier[],
): CodeLibelle[] => {
  const primeMensuelle =
    echeancier.length > 1 ? echeancier[1].echeance : echeancier[0].echeance;

  const formatedDelaiFranchise: number = insurance.delaiFranchise === '01' ? 30 : 90;

  return [
    {
      code: `${Number(insurance.quotiteDeces).toLocaleString('FR-fr')} %`,
      libelle: 'Assurance décès',
    },
    {
      code: `${Number(insurance.quotitePTIA).toLocaleString('FR-fr')} %`,
      libelle: 'Assurance PTIA',
    },
    {
      code: `${Number(insurance.quotiteITT).toLocaleString('FR-fr')} %`,
      libelle: 'Assurance ITT/IPT',
    },
    {
      code: `${Number(insurance.quotitePerteEmploi).toLocaleString('FR-fr')} %`,
      libelle: 'Perte d’emploi',
    },
    {
      code: `${formatedDelaiFranchise.toLocaleString('FR-fr')} jours`,
      libelle: 'Délai de franchise',
    },
    {
      code: `${formatFloatString(
        Number(primeMensuelle).toLocaleString('FR-fr'),
      )} € / mois`,
      libelle: 'Prime mensuelle',
    },
  ] as CodeLibelle[];
};

const getIndividualInsuranceData = (isBorrower: boolean): CodeLibelle[] => {
  const sessionObject = sessionStorage.getItem('conditionsFinancieres');
  let coverageRate: number | undefined;
  let primeMensuelle: number | undefined;

  if (sessionObject) {
    const simulation = JSON.parse(sessionObject);
    coverageRate = isBorrower
      ? simulation.borrowerInsurance.coverageRate
      : simulation.coBorrowerInsurance.coverageRate;

    primeMensuelle = isBorrower
      ? simulation.borrowerInsurance.monthlyEstimatedBonus
      : simulation.coBorrowerInsurance.monthlyEstimatedBonus;
  }

  const renderCoverage = (value?: number) => {
    if (value !== undefined) {
      return `${value} %`;
    }
    return '%';
  };

  return [
    {
      code: renderCoverage(coverageRate),
      libelle: 'Quotité couverture',
    },
    {
      code: `${formatFloatString(
        Number(primeMensuelle).toLocaleString('FR-fr'),
      )} € / mois`,
      libelle: 'Prime mensuelle estimée',
    },
  ] as CodeLibelle[];
};

const getInsuranceDataView = (
  isBorrower: boolean,
  insurance: Assurance,
  echeancier: Echeancier[],
): InsuranceFormDataView => {
  const insuranceDataView = {
    type: insurance.typeAssuranceChoisie,
  } as InsuranceFormDataView;

  if (insuranceDataView.type === 'G') {
    insuranceDataView.insuranceData = getGroupInsuranceData(insurance, echeancier);
  } else if (insuranceDataView.type === 'D') {
    insuranceDataView.insuranceData = getIndividualInsuranceData(isBorrower);
  }

  return insuranceDataView;
};

const renderBody = (simulationResponse: GetSimulationResultApiResponse) => {
  return (
    <>
      <Space marginBottom="5.6rem" />

      <FinancingForm view={getFinancingDataView(simulationResponse)} />

      <Space marginBottom="5.6rem" />

      <InsuranceForm
        ComplementaryInsuranceDataView={getComplimentaryInsuranceDataView(
          simulationResponse,
        )}
        borrowerInsurance={
          simulationResponse?.Resultat?.Proposition?.assuranceEmprunteur
            ? getInsuranceDataView(
                true,
                simulationResponse?.Resultat.Proposition
                  .assuranceEmprunteur as unknown as Assurance,
                simulationResponse?.Resultat.Proposition
                  .echeancierAssuranceEmprunteur as unknown as Echeancier[],
              )
            : ({} as InsuranceFormDataView)
        }
        coBorrowerInsurance={
          hasCoBorrower() &&
          simulationResponse?.Resultat?.Proposition?.assuranceCoEmprunteur
            ? getInsuranceDataView(
                false,
                simulationResponse?.Resultat.Proposition
                  .assuranceCoEmprunteur as unknown as Assurance,
                simulationResponse?.Resultat.Proposition
                  .echeancierAssuranceCoEmprunteur as unknown as Echeancier[],
              )
            : ({} as InsuranceFormDataView)
        }
      />

      <Space marginBottom="4rem" />

      <FooterText>{messages.RESULT_FOOTER}</FooterText>
    </>
  );
};

const renderResultInformation = (result: GetSimulationResultApiResponse) => {
  if (
    result.ListeMessages.find(message => {
      return message.MessageType === 'I';
    })
  ) {
    return (
      <>
        <Space marginBottom="5.6rem" />
        <InformationArea icon={<WarningIcon color="#2275D7" />} backgroundColor="blue">
          {result.ListeMessages.map(message => {
            if (message.MessageType === 'I') {
              return (
                <React.Fragment key={message.MessageId}>
                  <span style={{ fontSize: '1.4rem', color: '#4B4F54' }}>
                    {message.MessageLib}
                    <br />
                  </span>
                </React.Fragment>
              );
            }
            return null;
          })}
        </InformationArea>
      </>
    );
  }
  return null;
};

export const renderResultBody = (
  simulationResponse: GetSimulationResultApiResponse,
): JSX.Element | null => {
  return (
    <>
      <StyledContentContainer>
        <StepTitles title={messages.TITLE} />

        <StopLightForm
          profile={
            simulationResponse?.Resultat.profil as
              | ScoreResult.VERT
              | ScoreResult.ORANGE
              | ScoreResult.ROUGE
          }
          message={simulationResponse.Resultat.listeAlertes}
        />

        {renderResultInformation(simulationResponse)}

        {simulationResponse?.Resultat.profil !== ScoreResult.ROUGE ? (
          renderBody(simulationResponse)
        ) : (
          <></>
        )}
      </StyledContentContainer>
    </>
  );
};

const ResultForm: React.FC = () => {
  const navigate = useNavigate();

  const [simulationResponse, setSimulationResponse] =
    useState<GetSimulationResultApiResponse>({} as GetSimulationResultApiResponse);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isGeneralConfirmationModalVisible, setIsGeneralConfirmationModalVisible] =
    useState<boolean>(false);

  // TODO : Delete after backend integration
  useEffect(() => {
    const simulationResultObject = sessionStorage.getItem('simulationResult');

    if (simulationResultObject) {
      const simulationResult = JSON.parse(
        simulationResultObject,
      ) as GetSimulationResultApiResponse;
      setSimulationResponse(simulationResult);
    }
  }, []);

  const quitterDossier = () => {
    cancelFaisabilite().then(response => {
      if (response.data.Success) {
        cleanFileSessionStorageData();
        navigate('/dashboard');
      } else {
        alert(`Le dossier n'a pas pu etre fermé`);
      }
    });
  };

  const renderFakeSaveBouton = () => {
    if (simulationResponse.Resultat.profil === ScoreResult.ROUGE) return <div />;
    return (
      <StyledSaveLaterWrapper
        onClick={() => {
          setIsGeneralConfirmationModalVisible(true);
        }}
        tabIndex={0}
        role="button"
        onKeyDown={event => {
          if (
            event.code === 'Enter' ||
            event.code === 'Space' ||
            event.code === 'NumpadEnter'
          )
            setIsGeneralConfirmationModalVisible(true);
        }}>
        <div style={{ margin: '1.5rem 0.8rem 0 0' }}>
          <CloudArrowIcon />
        </div>
        <StyledSaveLaterText>Sauvegarder et quitter</StyledSaveLaterText>
      </StyledSaveLaterWrapper>
    );
  };

  const renderbackButton = () => {
    return (
      <StyledSecondaryButton
        onClick={() => {
          navigate('/conditions_financieres');
        }}>
        <ArrowLeftCircleIcon color={color.chartColors.mainGreen500} />
        <span style={{ marginLeft: '1rem' }}>{messages.PREVIOUS_BUTTON_LABEL}</span>
      </StyledSecondaryButton>
    );
  };

  const renderQuitButton = () => {
    return (
      simulationResponse.Resultat.profil !== ScoreResult.VERT && (
        <NextButton
          icon={<HomeIcon />}
          handleonClick={quitterDossier}
          label={messages.EXIT_BUTTON_LABEL}
        />
      )
    );
  };

  const onNextButtonClick = () => {
    setIsLoading(true);
    try {
      const identifiantPropositionCommercialeProtect =
        simulationResponse.Resultat.identifiantPropositionCommerciale;

      getClientFile(
        simulationResponse.Resultat.numeroProjet ||
          Number(sessionStorage.getItem('numeroProjet')),
      )
        .then(clientFile => {
          cleanFileSessionStorageData(true);
          const newClientFile = { ...clientFile };

          newClientFile.Resultat.identifiantPropositionCommerciale =
            identifiantPropositionCommercialeProtect;
          mappingGetClientFiles(newClientFile.Resultat, 'E')
            .finally(() => {
              setIsLoading(false);
              navigate('/finalisation_projet');
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch(error => {
          console.error(error);
          alert(
            "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      alert(
        "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
      );
      console.error(error);
    }
  };

  const renderNextButton = () => {
    return (
      simulationResponse.Resultat.profil !== ScoreResult.ROUGE &&
      (simulationResponse.Resultat.profil === ScoreResult.VERT ? (
        <NextButton
          icon={<ArrowRightCircleIcon color={theme.colors.chartColors.neutral0} />}
          handleonClick={onNextButtonClick}
          label={messages.NEXT_BUTTON_LABEL}
        />
      ) : (
        <StyledSaveLaterWrapper
          onClick={onNextButtonClick}
          tabIndex={0}
          role="button"
          onKeyDown={onNextButtonClick}>
          <div style={{ margin: '1.5rem 0.8rem 0 0' }}>
            <ArrowRightCircleIcon color={theme.colors.chartColors.mainGreen500} />
          </div>
          <StyledSaveLaterText>{messages.NEXT_BUTTON_LABEL}</StyledSaveLaterText>
        </StyledSaveLaterWrapper>
      ))
    );
  };

  const renderFooter = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10.4rem',
          paddingBottom: '11.6rem',
        }}>
        {renderFakeSaveBouton()}
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '3.2rem' }}>
          {simulationResponse?.Resultat?.Proposition?.nbAppelsRestants !== 0 &&
            renderbackButton()}
          {renderQuitButton()}
          {renderNextButton()}
        </div>
      </div>
    );
  };

  if (!simulationResponse.Resultat) {
    return null;
  }

  return (
    <>
      {withSummaryCard(
        'SimulationResult',
        <div style={{ minHeight: '50rem' }}>
          <Space marginBottom="11.2rem" />
          {renderResultBody(simulationResponse)}
          <>{isLoading && <Loader animationDuration={0.9} />}</>
          <GeneralConfirmationModal
            isVisible={isGeneralConfirmationModalVisible}
            isDataOK
            accept={() => {
              cleanFileSessionStorageData();
              navigate('/dashboard');
            }}
            close={() => {
              setIsGeneralConfirmationModalVisible(false);
            }}
          />
        </div>,
      )}

      {renderFooter()}
    </>
  );
};

export default ResultForm;
