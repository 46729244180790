import ConditionsFinancieresForm from 'containers/conditionsFinancieres/ConditionsFinancieres';
import { useNavigate } from 'react-router';

const ConditionsFinancieres: React.FC = () => {
  const navigate = useNavigate();
  return (
    <ConditionsFinancieresForm
      onNextButtonClick={() => {
        navigate('/resultat');
      }}
      onBackButtonClick={() => {
        navigate('/operation_client');
      }}
    />
  );
};

export default ConditionsFinancieres;
