import sauvegardeDonnees from 'api/sauvegarde';
import { hasCoBorrower } from 'containers/communs/utils';
import { getIntegerFromString } from 'containers/donneesClient/ressourcesMensuelles/utils';
import { NavigateFunction, NavigateOptions, To } from 'react-router';
import { IdentityState } from 'reducers/identity/types';
import { CalculetteFinanciereState } from 'reducers/calculetteFinanciere/type';
import { RessourcesMensuellesState } from 'reducers/ressourcesMensuelles/types';
import { ChildrenBirthYear } from 'reducers/situationFoyer/types';
import { FullAddress, SessionStorageKeys, UserInfos } from 'types';
import {
  AdditionalFaisabiliteData,
  ErrorMessage,
  EtatCivil,
  PropositionOutDtoResp,
} from 'types/FaisabiliteDTO';
import { getDateFromInput } from './DateUtils';
import mappingSauvegardeDonnees from './mapping/mappingSauvegarde';

const checkPreviousInputValidation = (
  step: SessionStorageKeys,
  inputValue: unknown,
): boolean | undefined => {
  if (sessionStorage.getItem(step)) {
    // modiff for ressource  retraite mensuelle
    if (inputValue === '' || inputValue === undefined) {
      return undefined;
    }
    if (inputValue !== null && inputValue !== undefined) {
      return true;
    }
    return false;
  }
  return undefined;
};

export const getBirthYearOlderBorrower = (): number => {
  const borrowerIdentity = JSON.parse(
    sessionStorage.getItem('borrowerIdentity') || '',
  ) as IdentityState;

  let olderBorrower = getDateFromInput(borrowerIdentity.birthDate || '').getFullYear();
  if (hasCoBorrower()) {
    const coBorrowerIdentity = JSON.parse(
      sessionStorage.getItem('coBorrowerIdentity') || '',
    ) as IdentityState;
    if (
      getDateFromInput(coBorrowerIdentity.birthDate || '').getFullYear() < olderBorrower
    )
      olderBorrower = getDateFromInput(coBorrowerIdentity.birthDate || '').getFullYear();
  }
  return olderBorrower;
};

export const formatValues = (value: string | undefined, unit: string): string => {
  return `${Number(value).toLocaleString('fr-FR')} ${unit}`;
};

export const formatNumberByThousand = (value: string): string => {
  if (value.length <= 3) {
    return value;
  }
  let result = '';
  let innerCounter = 0;
  const valueWithoutSpace = value.replace(/ /g, '');
  for (let i = valueWithoutSpace.length; i >= 0; i -= 1) {
    if (innerCounter === 4) {
      result = `${valueWithoutSpace.charAt(i)} ${result}`;
      innerCounter = 1;
    } else {
      result = `${valueWithoutSpace.charAt(i)}${result}`;
    }
    if (valueWithoutSpace.charAt(i) === ',' || valueWithoutSpace.charAt(i) === '.') {
      innerCounter = 1;
    } else {
      innerCounter += 1;
    }
  }
  return result;
};

export const formatFloatString = (floatNumber: string): string => {
  const afterCommaString = floatNumber.split(',')[1];
  return afterCommaString?.length === 1 ? `${floatNumber}0` : floatNumber;
};

export const formatPhoneValue = (value: string): string => {
  if (value.length <= 2) {
    return value;
  }
  return value.replace(new RegExp(`.{${2}}`, 'g'), '$& ');
};

export const displayNumber = (numberToFormat: number): string => {
  return formatNumberByThousand(numberToFormat.toString());
};

export const formatDisplayedFirstname = (inputText: string): string => {
  let enteredTextArray: string[] = [];
  if (inputText) {
    enteredTextArray = inputText.trim().split(' ');
  }
  let firstname = '';
  enteredTextArray.forEach(item => {
    if (firstname.length > 0) {
      firstname += ` ${item.substring(0, 1).toUpperCase()}${item
        .substring(1)
        .toLowerCase()}`;
    } else {
      firstname += `${item.substring(0, 1).toUpperCase()}${item
        .substring(1)
        .toLowerCase()}`;
    }
  });
  return firstname;
};

export const isUndefinedOrNull = (val: unknown): boolean => {
  if (val === undefined || val === null) {
    return true;
  }
  return false;
};

export const determineProjectType = (
  hasGarantee: boolean,
  secteurLegislatif?: string,
): string => {
  let projectType = hasGarantee ? 'Avec Garantie' : 'Sans Garantie';

  if (secteurLegislatif === '2') projectType += ' - Consommation';
  if (secteurLegislatif === '3') projectType += ' - Immobilier';

  return projectType;
};

export const determineCurrentProjectStep = (): number => {
  if (sessionStorage.getItem('simulationResult')) return 10;
  if (sessionStorage.getItem('conditionsFinancieres')) return 9;
  if (sessionStorage.getItem('operationsClient')) return 8;
  if (sessionStorage.getItem('ressourcesMensuelles')) return 4;
  if (sessionStorage.getItem('situationProfessionnelle')) return 3;
  if (sessionStorage.getItem('situationFoyer')) return 2;
  if (sessionStorage.getItem('borrowerIdentity')) return 1;
  return 0;
};

export const calculateTotalIncomes = (state: RessourcesMensuellesState): number => {
  let total = 0;

  if (state.hasOtherIncomes && state.otherIncomes.length > 0) {
    state.otherIncomes.forEach(item => {
      if (item.value) {
        total += getIntegerFromString(item.value);
      }
    });
  }
  if (state.borrowerIncome.monthlyIncomes) {
    total += getIntegerFromString(state.borrowerIncome.monthlyIncomes);
  }
  if (state.borrowerIncome.retirementMonthlyIncomes) {
    total += getIntegerFromString(state.borrowerIncome.retirementMonthlyIncomes);
  }
  if (state.coBorrowerIncome.monthlyIncomes) {
    total += getIntegerFromString(state.coBorrowerIncome.monthlyIncomes);
  }
  if (state.coBorrowerIncome.retirementMonthlyIncomes) {
    total += getIntegerFromString(state.coBorrowerIncome.retirementMonthlyIncomes);
  }

  return total;
};

export const calculateTotalReliableIncomes = (
  state: RessourcesMensuellesState,
): number | undefined => {
  if (state.borrowerIncome.reliableIncome === '0') return 0;

  if (hasCoBorrower() && state.coBorrowerIncome.reliableIncome === '0') return 0;

  let total = 0;

  if (state.hasOtherIncomes && state.otherIncomes.length > 0) {
    state.otherIncomes.forEach(item => {
      if (item.value) {
        total += getIntegerFromString(item.value);
      }
    });
  }

  if (state.borrowerIncome.retirementMonthlyIncomes) {
    total += getIntegerFromString(state.borrowerIncome.retirementMonthlyIncomes);
  }

  if (state.coBorrowerIncome.retirementMonthlyIncomes) {
    total += getIntegerFromString(state.coBorrowerIncome.retirementMonthlyIncomes);
  }

  if (
    state.borrowerIncome.reliableIncome &&
    state.borrowerIncome.reliableIncome !== '0'
  ) {
    total += getIntegerFromString(state.borrowerIncome.reliableIncome);
  }

  if (
    state.coBorrowerIncome.reliableIncome &&
    state.coBorrowerIncome.reliableIncome !== '0'
  ) {
    total += getIntegerFromString(state.coBorrowerIncome.reliableIncome);
  }

  return total;
};

export const calculateTotalCharges = (state: RessourcesMensuellesState): number => {
  let total = 0;

  if (state.hasMonthlyCharges && state.monthlyCharges.length > 0) {
    state.monthlyCharges.forEach(item => {
      if (item.value) {
        try {
          total += getIntegerFromString(item.value);
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  return total;
};

export const getNumeroContremarque = (): number | undefined => {
  const authenticatedUser = sessionStorage.getItem('authenticatedUser');
  const SelectedApa = sessionStorage.getItem('selectedApa');
  if (SelectedApa) {
    return Number(SelectedApa);
  }
  if (authenticatedUser) {
    const userObject: UserInfos = JSON.parse(authenticatedUser);
    return userObject.numContremarque;
  }
  return undefined;
};

export const getNumeroAPA = (): number | undefined => {
  const authenticatedUser = sessionStorage.getItem('authenticatedUser');
  if (authenticatedUser) {
    const userObject: UserInfos = JSON.parse(authenticatedUser);
    return userObject.idAPADossier ? userObject.idAPADossier : userObject.idAPA;
  }
  return undefined;
};

export const customReplaceAll = (
  targetString: string,
  stringToReplace: string,
  replaceBy: string,
): string => {
  let newString = targetString.toString();

  while (newString.includes(stringToReplace)) {
    const targetIndex = newString.indexOf(stringToReplace);

    newString = `${newString.substring(0, targetIndex)}${replaceBy}${newString.substring(
      targetIndex + stringToReplace.length,
    )}`;
  }

  return newString;
};

export const resumeProject = (
  currentProjectStep: number,
  navigate: NavigateFunction,
): void => {
  switch (currentProjectStep) {
    case 1:
      sessionStorage.setItem('clientDataCurrentStep', '1');
      navigate('/donnees_client');
      break;
    case 2:
      sessionStorage.setItem('clientDataCurrentStep', '2');
      navigate('/donnees_client');
      break;
    case 3:
      sessionStorage.setItem('clientDataCurrentStep', '3');
      navigate('/donnees_client');
      break;
    case 4:
      sessionStorage.setItem('clientDataCurrentStep', '4');
      navigate('/donnees_client');
      break;
    case 5:
      navigate('/operation_client');
      break;
    case 6:
      navigate('/operation_client');
      break;
    case 7:
      navigate('/operation_client');
      break;
    case 8:
      navigate('/operation_client');
      break;
    case 9:
      navigate('/conditions_financieres');
      break;
    case 10:
      navigate('/resultat');
      break;
    case 11:
      navigate('/finalisation_projet');
      break;
    default:
      console.log('Failed to resume project edition');
      break;
  }
};

export const cleanFileSessionStorageData = (isCreation?: boolean): void => {
  // Données client
  sessionStorage.removeItem('borrowerIdentity');
  sessionStorage.removeItem('coBorrowerIdentity');
  sessionStorage.removeItem('coBorrowerExists');
  sessionStorage.removeItem('clientDataCurrentStep');
  sessionStorage.removeItem('situationFoyer');
  sessionStorage.removeItem('situationProfessionnelle');
  sessionStorage.removeItem('ressourcesMensuelles');
  sessionStorage.removeItem('coBorrowerExists');

  // Opérations client
  sessionStorage.removeItem('operationsClient');

  // Conditions financières
  sessionStorage.removeItem('loanInformationsByIntermediationFee');
  if (!isCreation) sessionStorage.removeItem('numeroProjet');
  sessionStorage.removeItem('baremeEnVigeur');
  sessionStorage.removeItem('conditionsFinancieres');
  if (!isCreation) {
    sessionStorage.removeItem('borrowerId');
    sessionStorage.removeItem('coBorrowerId');
  }
  sessionStorage.removeItem('projetDetail');
  sessionStorage.removeItem('pourcentageFraisIntermediation');
  sessionStorage.removeItem('contexteArrivee');
  sessionStorage.removeItem('etudieur');

  // Finalisation
  sessionStorage.removeItem('simulationResult');
  sessionStorage.removeItem('transmissionOctroiInformation');
  sessionStorage.removeItem('finalisationProjet');
  sessionStorage.removeItem('interlocutor');
  sessionStorage.removeItem('loanAdditionalInformation');
  sessionStorage.removeItem('iban');
  sessionStorage.removeItem('guaranteeAdditionalInformation');
  sessionStorage.removeItem('debtAdditionalInformation');
  sessionStorage.removeItem('documentsHaveBeenSent');
  sessionStorage.removeItem('piecesJustificatives');
  sessionStorage.removeItem('listePJNonTransmis');
};

// Only use after scoring (faisabilité)
export const projectHasGarantee = (): boolean => {
  const simulationResultObj = sessionStorage.getItem('simulationResult');

  if (simulationResultObj) {
    const simulationResult = JSON.parse(simulationResultObj) as PropositionOutDtoResp;
    return simulationResult.Resultat.Proposition.typeGarantie !== 'SSG';
  }

  return false;
};

export const getAuthenticatedUserHabilitations = (): {
  dmatInd?: boolean;
  habiESignInd?: boolean;
  habiPackInd?: boolean;
} => {
  const authenticatedUserObj = sessionStorage.getItem('authenticatedUser');

  if (authenticatedUserObj) {
    const authenticatedUser = JSON.parse(authenticatedUserObj) as UserInfos;

    const dmatInd: boolean = authenticatedUser.dmatInd === 'O';
    const habiESignInd: boolean = authenticatedUser.habiESignInd === 'O';
    const habiPackInd: boolean = authenticatedUser.habiPackInd === 'O';

    return { dmatInd, habiESignInd, habiPackInd };
  }

  return {};
};

export const getNumeroProjet = (): number | undefined => {
  try {
    if (sessionStorage.getItem('numeroProjet')) {
      return Number(sessionStorage.getItem('numeroProjet'));
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getIdentifiant = (id: 'borrowerId' | 'coBorrowerId'): number | undefined => {
  try {
    if (sessionStorage.getItem('numeroProjet')) {
      return Number(sessionStorage.getItem(id));
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const getProjectInformation = (): AdditionalFaisabiliteData => {
  const projectInformation = {} as AdditionalFaisabiliteData;

  const numeroProjetFromSession = sessionStorage.getItem('numeroProjet');
  if (numeroProjetFromSession) {
    projectInformation.numeroProjet = Number(numeroProjetFromSession);
  }

  const borrowerIdFromSession = sessionStorage.getItem('borrowerId');
  if (borrowerIdFromSession) {
    projectInformation.identifiantEmprunteur = Number(borrowerIdFromSession);
  }

  const coBorrowerIdFromSession = sessionStorage.getItem('coBorrowerId');
  if (coBorrowerIdFromSession) {
    projectInformation.identifiantCoEmprunteur = Number(coBorrowerIdFromSession);
  }

  return projectInformation;
};

export const truncateWithDots = (string: string, maxLength: number): string => {
  const trimmedString = string.trim();
  if (trimmedString.length <= maxLength) return trimmedString;

  return `${trimmedString.slice(0, maxLength)}...`;
};

export const userCanChangeApa = (): boolean => {
  const userInfo = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;
  if (userInfo) {
    if (userInfo.codeHabilitation === 'E' || userInfo.codeHabilitation === 'M') {
      return true;
    }
  }
  return false;
};

export const userHasSelectedApa = (): boolean => {
  return !!sessionStorage.getItem('selectedApa');
};

export const updateNumContremarque = (
  newContremarque: number,
  nomCourtier: string,
  idAPADossier: number,
): UserInfos => {
  const userInfo = JSON.parse(
    sessionStorage.getItem('authenticatedUser') || '{}',
  ) as UserInfos;
  if (userInfo) {
    userInfo.numContremarque = newContremarque;
    userInfo.nomCourtier = nomCourtier;
    userInfo.idAPADossier = idAPADossier;
    sessionStorage.setItem('authenticatedUser', JSON.stringify(userInfo));
  }
  return userInfo;
};

export const getFullAddress = (address: FullAddress): string => {
  let fullAddress = ``;

  if (address?.streetNumber) fullAddress = `${address.streetNumber} `;
  if (address?.streetType) fullAddress = `${fullAddress}${address.streetType.libelle} `;
  if (address?.street) fullAddress = `${fullAddress}${address.street} `;
  if (address?.additionalInformation && !address?.street)
    fullAddress = `${fullAddress}${address.additionalInformation} `;
  if (address?.POBoxOrHamlet && address?.street && !address.additionalInformation)
    fullAddress = `${fullAddress}${address.POBoxOrHamlet} `;

  return fullAddress;
};

// for zipcode departement format 0X
export const zipcodeNumbertoString = (zipcode: number | undefined): string => {
  if (zipcode === undefined || zipcode === null || zipcode === 0) return '';
  return zipcode < 10000 ? `0${zipcode?.toString()}` : zipcode?.toString();
};

export default checkPreviousInputValidation;

export const getUserInfos = (): UserInfos => {
  const userIdObject = sessionStorage.getItem('authenticatedUser');
  return JSON.parse(userIdObject || '{}') as unknown as UserInfos;
};

export const getEtatCivil = (person: 'borrower' | 'coBorrower'): EtatCivil => {
  const etatCivil = {} as EtatCivil;
  const result = sessionStorage.getItem(
    person === 'borrower' ? 'borrowerIdentity' : 'coBorrowerIdentity',
  );
  if (result) {
    const parsedResult = JSON.parse(result) as IdentityState;
    etatCivil.civilite = parsedResult.civility || '';
    etatCivil.communeNaissance = parsedResult.birthCity?.libelle || '';
    etatCivil.dateNaissance = parsedResult.birthDate
      ? getDateFromInput(parsedResult.birthDate)
      : undefined;
    etatCivil.departementNaissance = parsedResult.birthDepartment?.code || '';
    etatCivil.nationalite = parsedResult.nationality?.code || '';
    etatCivil.nom = parsedResult.lastName || '';
    etatCivil.nomNaissance = parsedResult.isBirthNameSameAsName
      ? parsedResult.lastName || ''
      : parsedResult.birthName || '';
    etatCivil.paysNaissance = parsedResult.birthCountry?.code || '';
    etatCivil.prenom = parsedResult.firstName || '';
  }
  return etatCivil;
};

export interface SaveAndExitParams {
  setErrorMessages: (value: React.SetStateAction<ErrorMessage[]>) => void;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setIsModalVisible: (value: React.SetStateAction<boolean>) => void;
  saveInSessionStorage: () => void;
  navigate: (to: To, options?: NavigateOptions | undefined) => void;
}

export const handleSaveAndExit = async (params: SaveAndExitParams): Promise<void> => {
  const {
    setErrorMessages,
    setIsLoading,
    setIsModalVisible,
    saveInSessionStorage,
    navigate,
  } = params;
  try {
    setIsLoading(true);
    saveInSessionStorage();
    setIsModalVisible(false);
    const projectInformation = getProjectInformation();
    const reqBody = mappingSauvegardeDonnees(projectInformation);
    const result = await sauvegardeDonnees(reqBody);
    if (result.Success === false) {
      setErrorMessages(
        result.data.ListeMessages.filter(message => {
          return message.MessageType === 'E' || 'T';
        }),
      );
      setIsLoading(false);
    } else {
      navigate('/dashboard');
    }
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export interface ScrollToTopAndShowErrorParams {
  setHasError: (value: React.SetStateAction<boolean>) => void;
  setCheckInputs: (value: React.SetStateAction<boolean>) => void;
}

export const scrollToTopAndShowError = (params: ScrollToTopAndShowErrorParams): void => {
  window.scroll(0, 0);
  params.setHasError(true);
  params.setCheckInputs(true);
};

export function removeUndefined(
  obj: CalculetteFinanciereState,
): CalculetteFinanciereState {
  const t = obj;
  Object.keys(t).forEach((v: any) => {
    if (typeof t[v] === 'object') {
      removeUndefined(t[v]);
    } else if (t[v] === undefined) {
      if (typeof v === 'string') t[v] = 'N';
      if (v === 'besoinNatureImmobiliere') t[v] = 0;
      if (v === 'besoinNatureConsommation') t[v] = 0;
      if (v === 'fraisDossier') t[v] = 0;
      if (v === 'valeurFraisIntermediation') t[v] = 0;
      if (v === 'tauxNominal') t[v] = 0;
      if (v === 'dureeSouhaitee') t[v] = 0;
      if (v === 'dureeFranchise') t[v] = 0;
      if (v === 'quotiteDeces') t[v] = 0;
      if (v === 'quotiteITT') t[v] = 0;
      if (v === 'quotitePTIA') t[v] = 0;
      if (v === 'quotitePerteEmploi') t[v] = 0;
      if (v === 'echeanceAssuranceDeleguee') t[v] = 0;
    }
  });

  return t;
}

export const sortChildrenByAge = (
  listChildren: ChildrenBirthYear[],
): ChildrenBirthYear[] => {
  const newListChildren = listChildren;
  newListChildren.sort((a, b) => {
    if (a.birthYear < b.birthYear) {
      return 1;
    }
    if (a.birthYear > b.birthYear) {
      return -1;
    }
    return 0;
  });

  return newListChildren.map((children, index) => {
    return { ...children, childId: index + 1 };
  });
};
