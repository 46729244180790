import React, { useReducer, useState } from 'react';
import Space from 'components/space/Space';
import {
  calculFinanciereReducer,
  getCalculetteFinanciereState,
} from 'reducers/calculetteFinanciere/calculetteFinanciere';
import Loader from 'components/Loader';
import { ErrorMessage, CalculetteDtoResp } from 'types/FaisabiliteDTO';
import { getSimulationAutonome } from 'api/subscription';
import { removeUndefined } from 'utils/commun';
import { useNavigate } from 'react-router-dom';
import { CalculetteFinanciereState } from 'reducers/calculetteFinanciere/type';
import NavBack from 'components/navBack/NavBack';
import { StyledMainTitle } from './style';
import * as messages from './messages';
import CalculetteForm from './CalculetteForm';
import CalculetteResult from './CalculetteResult';

const CalculetteFinanciere: React.FC = () => {
  const [calculetteFinanciereState, calculetteFinanciereDispatch] = useReducer(
    calculFinanciereReducer,
    getCalculetteFinanciereState(),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayForm, setDisplayForm] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([]);
  const [simulationResult, setSimulationResult] = useState<CalculetteDtoResp>();

  const navigate = useNavigate();

  const getSimulation = async () => {
    if (
      calculetteFinanciereState.projet.typeGarantie !== undefined &&
      calculetteFinanciereState.proposition.assuranceEmprunteur.typeAssuranceChoisie !==
        undefined &&
      calculetteFinanciereState.proposition.assuranceCoEmprunteur.typeAssuranceChoisie !==
        undefined
    ) {
      setErrorMessages([]);
      try {
        setIsLoading(true);
        const response = await getSimulationAutonome(
          removeUndefined(calculetteFinanciereState) as CalculetteFinanciereState,
        );
        if (
          response &&
          response.data.Resultat &&
          response.statusText === 'OK' &&
          response.data.Success
        ) {
          setSimulationResult(response.data);
          setDisplayForm(false);
        } else if (
          response &&
          response.statusText === 'OK' &&
          response.data.Success === false
        ) {
          setErrorMessages(
            response.data.ListeMessages.filter(message => {
              return message.MessageType === 'E' || 'T';
            }),
          );
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error); // TODO: handle error
        setIsLoading(false);
      }
    } else {
      setErrorMessages([
        {
          MessageId: 0,
          MessageLib:
            "Il est nécessaire de choisir une garantie ainsi qu'une assurance de prêt emprunteur et co-emprunteur",
        },
      ] as ErrorMessage[]);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Space marginTop="6.3rem" />
      <StyledMainTitle>{messages.CALCULATOR_MAIN_TITLE}</StyledMainTitle>
      <Space marginTop="4.8rem" />
      <NavBack navigate={navigate} />
      <Space marginTop="4.8rem" />
      {displayForm ? (
        <CalculetteForm
          calculetteFinanciereDispatch={calculetteFinanciereDispatch}
          calculetteFinanciereState={calculetteFinanciereState}
          getSimulation={getSimulation}
          errorMessages={errorMessages}
        />
      ) : null}
      {!displayForm && simulationResult !== undefined ? (
        <CalculetteResult
          simulationResult={simulationResult.Resultat.Proposition}
          setDisplayForm={setDisplayForm}
        />
      ) : null}
      {isLoading ? <Loader animationDuration={2} /> : null}
    </div>
  );
};

export default CalculetteFinanciere;
