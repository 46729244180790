import * as React from 'react';

const LightIcon: React.FC = () => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
    <title>E93731F6-77E6-467A-9D7C-BF9D6D5EE494</title>
    <g fill="#1068D0" fillRule="nonzero">
      <path d="M11.833 18.571h-3.93c-.352 0-.937-.14-.937-1.082 0-.11 0-.335.007-.608.006-.62.025-1.904 0-2.092-.05-.329-.698-1.393-1.088-2.025-.214-.353-.421-.681-.553-.93-.83-1.52-.698-3.667.308-4.993 1.584-2.086 3.238-2.353 4.508-2.353 1.836 0 3.451 1.1 4.244 2.189.54.742 1.653 2.73.39 5.071-.466.864-.837 1.441-1.1 1.861-.36.566-.504.803-.542 1.088-.05.371-.05 1.26 0 2.633 0 .092.007.146.007.159-.02.662-.528 1.082-1.314 1.082Zm-3.534-.949h3.534c.314 0 .314-.079.314-.127 0-.012 0-.061-.006-.128-.02-.53-.076-2.153.006-2.785.063-.499.296-.864.68-1.466.263-.407.622-.972 1.068-1.806.98-1.812.214-3.338-.327-4.086-.478-.663-1.742-1.782-3.44-1.782-1.577 0-2.652.572-3.709 1.965-.691.906-.962 2.65-.232 3.989.125.225.32.547.528.887.666 1.089 1.15 1.898 1.22 2.378.031.2.025.912.012 2.238-.006.267-.006.486-.006.596 0 .054 0 .097.006.127H8.3Z" />
      <path d="M7.677 14.168h4.734v1H7.677zM7.677 16.862v-.948l4.734-.019v.949zM11.745 18.273c-.088.432-.447 1.727-1.786 1.727-1.427 0-1.61-1.727-1.61-1.727h3.396ZM3.345 16.589a.5.5 0 0 1-.346-.14.463.463 0 0 1 0-.675l1.34-1.295a.494.494 0 0 1 .69 0 .463.463 0 0 1 0 .675l-1.332 1.295a.526.526 0 0 1-.352.14ZM2.452 10.106H.49A.483.483 0 0 1 0 9.632c0-.261.22-.474.49-.474h1.968c.27 0 .49.213.49.474a.493.493 0 0 1-.496.474ZM4.684 4.926a.5.5 0 0 1-.346-.14L3 3.49a.463.463 0 0 1 0-.675.494.494 0 0 1 .692 0L5.03 4.111a.463.463 0 0 1 0 .675.5.5 0 0 1-.346.14ZM10.047 2.767a.483.483 0 0 1-.49-.475V.474c0-.261.22-.474.49-.474s.49.213.49.474v1.812c0 .268-.22.48-.49.48ZM15.404 4.926a.5.5 0 0 1-.346-.14.463.463 0 0 1 0-.675l1.34-1.296a.494.494 0 0 1 .69 0 .463.463 0 0 1 0 .675L15.75 4.786a.5.5 0 0 1-.346.14ZM19.516 10.106h-1.88a.483.483 0 0 1-.49-.474c0-.261.22-.474.49-.474h1.873c.27 0 .49.213.49.474a.474.474 0 0 1-.483.474ZM16.73 16.546a.494.494 0 0 1-.352-.146L15.071 15.1a.46.46 0 0 1 .012-.67.5.5 0 0 1 .692.013l1.308 1.301a.46.46 0 0 1-.013.67.49.49 0 0 1-.34.133Z" />
    </g>
  </svg>
);

export default LightIcon;
