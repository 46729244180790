import ActionCrossIcon from 'icons/ActionCrossIcon';
import ErrorIcon from 'icons/ErrorIcon';
import { StyledTextAreaContainer, StyledTextAreaTitle } from './style';

interface TextAreaProps {
  type: 'info' | 'warning' | 'error';
  title?: string;
  children: React.ReactNode;
  includeIcon?: boolean;
  onClose?: () => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  type,
  title,
  children,
  includeIcon,
  onClose,
}) => {
  const getBackgroundColor = () => {
    switch (type) {
      case 'error':
        return '#FEF2F4';
      case 'warning':
        return '#FFEFD6';
      default:
        return '#000';
    }
  };

  return (
    <>
      <StyledTextAreaContainer color={getBackgroundColor()}>
        {includeIcon && (
          <div style={{ marginRight: '1rem', paddingTop: '0.1rem' }}>
            {type === 'error' ? <ErrorIcon /> : <ErrorIcon color="#FF9E00" />}
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.8rem' }}>
          {title && <StyledTextAreaTitle>{title}</StyledTextAreaTitle>}
          {children}
        </div>

        {onClose && (
          <div
            role="button"
            style={{ cursor: 'pointer', marginLeft: '2.4rem' }}
            tabIndex={0}
            onClick={onClose}
            onKeyDown={e => {
              if (e.key === 'enter') {
                onClose();
              }
            }}>
            <ActionCrossIcon />
          </div>
        )}
      </StyledTextAreaContainer>
    </>
  );
};

export default TextArea;
