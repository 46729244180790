import { CalculetteFinanciereState, CalculetteFinanciereAction } from './type';

export const getCalculetteFinanciereState = (): CalculetteFinanciereState => {
  const calculetteFinanciereDefaultValues = {
    projet: {
      besoinNatureImmobiliere: undefined,
      besoinNatureConsommation: undefined,
      fraisDossier: undefined,
      valeurFraisIntermediation: undefined,
      typeValeurFraisIntermediation: 'P',
      typeGarantie: undefined,
    },
    proposition: {
      tauxNominal: undefined,
      dureeSouhaitee: undefined,
      dureeFranchise: undefined,
      assuranceEmprunteur: {
        typeAssuranceChoisie: undefined,
        garantieDeces: undefined,
        quotiteDeces: undefined,
        garantiePTIA: undefined,
        quotitePTIA: undefined,
        garantieITT: undefined,
        quotiteITT: undefined,
        garantiePerteEmploi: undefined,
        quotitePerteEmploi: undefined,
        delaiFranchise: undefined,
        echeanceAssuranceDeleguee: undefined,
      },
      assuranceCoEmprunteur: {
        typeAssuranceChoisie: undefined,
        garantieDeces: undefined,
        quotiteDeces: undefined,
        garantiePTIA: undefined,
        quotitePTIA: undefined,
        garantieITT: undefined,
        quotiteITT: undefined,
        garantiePerteEmploi: undefined,
        quotitePerteEmploi: undefined,
        delaiFranchise: undefined,
        echeanceAssuranceDeleguee: undefined,
      },
    },
  };
  return calculetteFinanciereDefaultValues as CalculetteFinanciereState;
};

export const calculFinanciereReducer = (
  state: CalculetteFinanciereState,
  action: CalculetteFinanciereAction,
): CalculetteFinanciereState => {
  const newState = { ...state };
  switch (action.type) {
    case 'setBesoinNatureImmobiliere':
      newState.projet.besoinNatureImmobiliere = action.payload;
      return newState;
    case 'setBesoinNatureConsommation':
      newState.projet.besoinNatureConsommation = action.payload;
      return newState;
    case 'setFraisDossier':
      newState.projet.fraisDossier = action.payload;
      return newState;
    case 'setValeurFraisIntermediation':
      newState.projet.valeurFraisIntermediation = action.payload;
      return newState;
    case 'setTypeValeurFraisIntermediation':
      newState.projet.typeValeurFraisIntermediation = action.payload;
      return newState;
    case 'setTypeGarantie':
      newState.projet.typeGarantie = action.payload;
      return newState;
    case 'setTauxNominal':
      newState.proposition.tauxNominal = action.payload;
      return newState;
    case 'setDureeSouhaitee':
      newState.proposition.dureeSouhaitee = action.payload;
      return newState;
    case 'setDureeFranchise':
      newState.proposition.dureeFranchise = action.payload;
      return newState;
    case 'setTypeAssuranceChoisie':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.typeAssuranceChoisie =
          action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.typeAssuranceChoisie =
          action.payload?.value;
      }
      return newState;
    case 'setGarantieDeces':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.garantieDeces = action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.garantieDeces = action.payload?.value;
      }
      return newState;
    case 'setQuotiteDeces':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.quotiteDeces = action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.quotiteDeces = action.payload?.value;
      }
      return newState;
    case 'setGarantiePTIA':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.garantiePTIA = action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.garantiePTIA = action.payload?.value;
      }
      return newState;
    case 'setQuotitePTIA':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.quotitePTIA = action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.quotitePTIA = action.payload?.value;
      }
      return newState;
    case 'setGarantieITT':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.garantieITT = action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.garantieITT = action.payload?.value;
      }
      return newState;
    case 'setQuotiteITT':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.quotiteITT = action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.quotiteITT = action.payload?.value;
      }
      return newState;
    case 'setGarantiePerteEmploi':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.garantiePerteEmploi =
          action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.garantiePerteEmploi =
          action.payload?.value;
      }
      return newState;
    case 'setQuotitePerteEmploi':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.quotitePerteEmploi =
          action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.quotitePerteEmploi =
          action.payload?.value;
      }
      return newState;
    case 'setDelaiFranchise':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.delaiFranchise = action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.delaiFranchise = action.payload?.value;
      }
      return newState;
    case 'setEcheanceAssuranceDeleguee':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur.echeanceAssuranceDeleguee =
          action.payload.value;
      } else {
        newState.proposition.assuranceCoEmprunteur.echeanceAssuranceDeleguee =
          action.payload?.value;
      }
      return newState;
    case 'reinitilizeInsuranceByPerson':
      if (action.payload?.person === 'borrower') {
        newState.proposition.assuranceEmprunteur = {
          typeAssuranceChoisie: undefined,
          garantieDeces: undefined,
          quotiteDeces: undefined,
          garantiePTIA: undefined,
          quotitePTIA: undefined,
          garantieITT: undefined,
          quotiteITT: undefined,
          garantiePerteEmploi: undefined,
          quotitePerteEmploi: undefined,
          delaiFranchise: undefined,
          echeanceAssuranceDeleguee: undefined,
        };
      } else {
        newState.proposition.assuranceCoEmprunteur = {
          typeAssuranceChoisie: undefined,
          garantieDeces: undefined,
          quotiteDeces: undefined,
          garantiePTIA: undefined,
          quotitePTIA: undefined,
          garantieITT: undefined,
          quotiteITT: undefined,
          garantiePerteEmploi: undefined,
          quotitePerteEmploi: undefined,
          delaiFranchise: undefined,
          echeanceAssuranceDeleguee: undefined,
        };
      }
      return newState;
    case 'reinitilizeInsurance':
      newState.proposition.assuranceEmprunteur = {
        typeAssuranceChoisie: undefined,
        garantieDeces: undefined,
        quotiteDeces: undefined,
        garantiePTIA: undefined,
        quotitePTIA: undefined,
        garantieITT: undefined,
        quotiteITT: undefined,
        garantiePerteEmploi: undefined,
        quotitePerteEmploi: undefined,
        delaiFranchise: undefined,
        echeanceAssuranceDeleguee: undefined,
      };
      newState.proposition.assuranceCoEmprunteur = {
        typeAssuranceChoisie: undefined,
        garantieDeces: undefined,
        quotiteDeces: undefined,
        garantiePTIA: undefined,
        quotitePTIA: undefined,
        garantieITT: undefined,
        quotiteITT: undefined,
        garantiePerteEmploi: undefined,
        quotitePerteEmploi: undefined,
        delaiFranchise: undefined,
        echeanceAssuranceDeleguee: undefined,
      };
      return newState;

    default:
      return state;
  }
};
